import React from "react";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import {
  Button,
  Container,
  Stack,
  Typography,
  IconButton,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Switch,
  Avatar,
  styled,
  CircularProgress,
  Link,
  Badge,
  Autocomplete, Chip, MenuItem, FormHelperText
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import {useLocation, useNavigate} from "react-router-dom";
import {AuthUserContext} from "../../../providers/auth-provider";
import {useFormik} from "formik";
import FounderProfileValidation from "../../../validations/startup/founder-profile";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
const preTags = ['Co-founder', 'CEO', 'CTO', 'CCO']
export default function FounderProfile() {
  const Input = styled('input')({
    display: 'none',
  })
  const { user, setProfile} = React.useContext(AuthUserContext);

  const [loading, setLoading] = React.useState(false)
  const [tags, setTags] = React.useState([]);

  const [profileImage, setProfileImage] = React.useState();
  const location = useLocation()
  const navigator = useNavigate();
  const uploadFile = () => {

    setLoading(true)

    const file = profileImage?.file
    const path = `startups/${user.uid}/founder/${file.name}`

    const storage = getStorage();
    const storageRef = ref(storage, path);

    const uploadTask = uploadBytesResumable(storageRef, file); 

// Register three observers:
// 1. 'state_changed' observer, called any time the state changes
// 2. Error observer, called on failure
// 3. Completion observer, called on successful completion
    uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
         switch (snapshot.state) {
          case 'paused':
            // console.log('Upload is paused');
            break;
          case 'running':
            // console.log('Upload is running');
            break;
           default:

        }
      },
      (error) => {
        setLoading(false)

        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        setProfileImage({...profileImage,path, progress:100})
        setLoading(false)

        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log('File available at', downloadURL);
        });
      }
    );

  }
  const formik = useFormik({
    initialValues:FounderProfileValidation.initialValues,
    validationSchema:FounderProfileValidation.schema,
    onSubmit: async (values) => {
      try{
        const userRef = doc(DB, 'users', user.uid);
        const founder = {
          ...values,
          photoURL: profileImage?.path ? profileImage.path : '',
          position: tags,
        };
        await setDoc(userRef, {startup: {founder}, onboard: true}, {merge: true});
        const getdoc = await getDoc(userRef);
        await setProfile(getdoc.data());
        navigator('/startup/home')
        // navigator('/on-boarding/founder-profile-setup', {state: {startupName: values.name}})

      } catch (e) {
        debugger
        console.log(e)
      }
    },
  })
  return (
    <>
      <Container maxWidth={'full-width'} sx={{marginY: 5}}>
        <Stack direction={'row'} justifyContent="flex-start"
               alignItems="center"
               spacing={2} width={'100%'} >
          <IconButton onClick={() => navigator(-1)}>
              <ArrowBackIcon sx={{fontSize: 40}} />
          </IconButton>
          <Typography variant={'h5'} sx={{textAlign:'left', lineHeight: 1}}>
            Startup <br/> <small style={{fontSize: 14, color: '#8F8F8F'}}>Founder Profile</small>
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={'md'} sx={{mb: 5}}>
        <Stack direction={'column'} spacing={5}
               component="form" onSubmit={e => formik.handleSubmit(e)}>
          <Stack direction="column"
                 justifyContent="center"
                 alignItems="center"
                 spacing={1} sx={{mb: 5}}>
            <label htmlFor="profile-image">
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                <IconButton color={"primary"} component={'span'} >
                  <AddCircleIcon/>

                </IconButton>
                }
              >
                <Avatar alt={'startup-picture'}   src={profileImage && profileImage?.src ? profileImage?.src :'/assets/default-avatar.png'} sx={{width: 128, height: 128, cursor: 'pointer', backgroundColor: 'transparent', border: '1px solid #E0E3FF' }}
                         component={'span'} />

              </Badge>
              <Input accept="image/*" id="profile-image" multiple={false} type="file" onChange={(e)=>{
                const fileReader = new FileReader();
                fileReader.readAsDataURL(e.target.files[0]);
                fileReader.addEventListener("load", async function () {
                  setProfileImage({
                    progress:0,
                    src: this.result,
                    file: e.target.files[0],
                    size:  (e.target.files[0]?.size / 1024 / 1024).toFixed(2)


                  });
                });
              }} />

            </label>
            <Button variant={'text'}  color={'secondary'}  sx={{paddingX: 4}} component="span" onClick={uploadFile} disabled={!profileImage}>Upload {profileImage?.progress === 100 && <CheckCircleIcon color={'primary'} style={{marginLeft: '15px'}}/>} {loading && <CircularProgress sx={{ml: 2}} size={20} color="primary"/>}</Button>
            {profileImage && profileImage?.src && <Link color={'secondary'} onClick={() => setProfileImage(undefined)}>Remove picture</Link>}
          </Stack>
          <Stack direction={'row'} justifyContent="start"
                 alignItems="center"
                 spacing={5} width={'100%'} >
            <TextField
              name={'firstName'}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              fullWidth label={'Founder’s First Name'} />
            <TextField
              name={'lastName'}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              fullWidth label={'Founder’s Last Name'}/>

          </Stack>
          <TextField
            name={'email'}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth label={'Email'}/>
          <Stack direction={'column'} justifyContent="start"
                 alignItems="start"
                 spacing={2} width={'100%'} >
            <label style={{textAlign:'left'}} >
              Startup name
            </label>

            <Typography variant={'body1'} color={'secondary'} sx={{textAlign:'left',
            }} >
              &nbsp;&nbsp;&nbsp;&nbsp;{location?.state && location?.state?.startupName}
            </Typography>
          </Stack>


          <Stack
            direction={"column"}
            justifyContent="flex-start"
            spacing={5}
            sx={{ width: "100%" }}>
          
          </Stack>
         
          <TextField
            name={'website'}
            value={formik.values.website}
            onChange={formik.handleChange}
            error={formik.touched.website && Boolean(formik.errors.website)}
            helperText={formik.touched.website && formik.errors.website}
            fullWidth label={'Website URL'}
            onBlur={(e) => {
              const value = e.target.value
              if (value !== '') {
                const checkValid = /https:\/\/|http:\/\//
                if (!checkValid.test(value)) formik.setFieldValue('website', 'https://'+ value)
              }
            }}/>
          <TextField
            name={'linkedIn'}
            value={formik.values.linkedIn}
            onChange={formik.handleChange}
            error={formik.touched.linkedIn && Boolean(formik.errors.linkedIn)}
            helperText={formik.touched.linkedIn && formik.errors.linkedIn}
            fullWidth label={'LinkedIn URL'}
            onBlur={(e) => {
              const value = e.target.value
              if (value !== '') {
                const checkValid = /https:\/\/|http:\/\//
                if (!checkValid.test(value)) formik.setFieldValue('linkedIn', 'https://'+ value)
              }
            }}/>
          <TextField
            name={'twitter'}
            value={formik.values.twitter}
            onChange={formik.handleChange}
            error={formik.touched.twitter && Boolean(formik.errors.twitter)}
            helperText={formik.touched.twitter && formik.errors.twitter}
            fullWidth label={'Twitter Handle'}
            onBlur={(e) => {
              const value = e.target.value
              if (value !== '') {
                const checkValid = /https:\/\/|http:\/\//
                if (!checkValid.test(value)) formik.setFieldValue('twitter', 'https://'+ value)
              }
            }}
          />

          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend"  sx={{textAlign: 'left', mb: 2}}>Repeat Founder</FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch  name={'repeatFounder'}
                           value={formik.values.repeatFounder}
                           onChange={formik.handleChange}
                  />
                }
              />
              <Typography>Yes</Typography>
              <FormHelperText
                error={formik.touched.repeatFounder && Boolean(formik.errors.repeatFounder)}
                children={formik.touched.repeatFounder && formik.errors.repeatFounder}
              />

            </Stack>

          </FormControl>


          <Stack direction={'row'} justifyContent="center"
                 alignItems="center"
                 spacing={5} width={'100%'} >
            <Button variant={'outlined'} color={'primary'} sx={{px: 5}} 
					style={{paddingLeft:"15%",paddingRight:"15%"}}
            onClick={() => navigator(-1)}>
              Back
            </Button>
            <Button 
					style={{paddingLeft:"15%",paddingRight:"15%"}}
            variant={'contained'} color={'primary'} sx={{px: 5}} type={'submit'}>
              Next
            </Button>
          </Stack>
        </Stack>
      </Container>

      </>
  )
}