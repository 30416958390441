import * as React from 'react';
import Typography from "@mui/material/Typography/Typography";
import Stack from "@mui/material/Stack/Stack";
import moment from "moment/moment";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import {IconButton, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {DownloadDoneOutlined, DownloadingOutlined, DownloadOutlined, InsertDriveFile} from "@mui/icons-material";
import DownloadFileHelper from "../../../../../../utils/download-file-helper";
export default function SidePannelFileMessageComponent({msg, type}){
  return (

    <Stack direction={'row-reverse'} spacing={1}>
      <Stack direction={'row'} spacing={1} sx={{ paddingRight:"10px",paddingLeft:"5px", backgroundColor: '#fff', color:'#000',  borderRadius: '5px', width:"350px",marginBottom:"5px",marginTop:"5px",marginRight:"10px"}} justifyContent="flex-end"
             alignItems="center">
        <Stack direction={"row"} spacing={2} >
          <InsertDriveFile  />
          <Typography style={{width:"200px"}} >
            {msg?.fileName}
          </Typography>

        </Stack>
        <IconButton onClick={() => msg.path && msg.path !== '' && DownloadFileHelper(msg.path)}>
          <DownloadOutlined sx={{}}/>
        </IconButton>
        <Stack direction={'row'} spacing={.5}>

          <Typography variant={'caption'}>
            {msg.createdAt && moment(msg.createdAt.seconds * 1000).format('HH:mm')}
          </Typography>



        </Stack>
      </Stack>
    </Stack>
  )
}