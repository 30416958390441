import React from "react";
import {
	Button,
	Container,
	Stack,
	IconButton,
	TextField,
	Avatar,
	CircularProgress,
	Link,
	Badge,
	styled,
	Typography,
} from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExportOnboardingValidation from "../../../../validations/expert/onboarding-profile";
// import DragDropFiles, {DragDropItem} from "../../../../components/common/drag-drop-files";
import UploadFile from "../../../../utils/upload-file";
import {AuthUserContext} from "../../../../providers/auth-provider";
export default function ExpertFirstForm({ cbBack, cbNext, data }) {
	const Input = styled("input")({
		display: "none",
	});
	const { user } = React.useContext(AuthUserContext);

	const [profileImage, setProfileImage] = React.useState({
		src: data && data?.photoURL !== "" ? data?.photoURL :user.photoURL ? user.photoURL : null,
		photoURL:data &&  data?.photoURL !== '' ? data?.photoURL :user.photoURL ? user.photoURL : null,
	});
	const [loading, setLoading] = React.useState(false);
	const formikFirst = useFormik({
		initialValues: data
			? data
			: {...ExportOnboardingValidation.ExpertFirstFormValues, displayName: user?.displayName},
		validationSchema:
		ExportOnboardingValidation.ExpertFirstForm,
		onSubmit: async (values) => {
			// console.log("working");
			await formikFirst.setFieldValue(
				"photoURL",
				profileImage?.photoURL ? profileImage.photoURL : ""
			);
			cbNext({
				...values,
				photoURL: profileImage?.photoURL ? profileImage.photoURL : "",
			});
		},
	});

	return (
			<Stack
				direction={"column"}
				spacing={10}
				component="form"
				onSubmit={(e) => formikFirst.handleSubmit(e)}>
				<Container maxWidth={"md"}>
					<Stack direction={"column"} spacing={2}>
						<Stack
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={1}
							sx={{ mb: 5 }}>
							<label htmlFor="profile-image">
								<Badge
									overlap="circular"
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
									badgeContent={
										<IconButton
											color={"primary"}
											component={"span"}>
											<AddCircleIcon />
										</IconButton>
									}>
									<Avatar
										alt={"startup-picture"}
										src={
											profileImage && profileImage?.src
												? profileImage?.src
												: "/assets/default-avatar.png"
										}
										sx={{
											width: 80,
											height: 80,
											cursor: "pointer",
											backgroundColor: "transparent",
											border: "1px solid #E0E3FF",
										}}
										imgProps={{
											sx: { objectFit: "cover" },
										}}
										component={"span"}
									/>
								</Badge>
								<Input
									accept="image/*"
									id="profile-image"
									multiple={false}
									type="file"
									onChange={(e) => {
										const fileReader = new FileReader();
										fileReader.readAsDataURL(e.target.files[0]);
										fileReader.addEventListener(
											"load",
											async function () {
												setLoading(true);
												const file = e.target.files[0];
												const path = `startups/${user.uid}/logo/${file.name}`;
												const imageObject = {
													progress: 0,
													src: this.result,
													path,
													file: e.target.files[0],
													size: (
														e.target.files[0]?.size /
														1024 /
														1024
													).toFixed(2),
												};
												setProfileImage(imageObject);
												UploadFile(file, path).then(
													(url) => {
														setProfileImage({
															...imageObject,
															photoURL: url,
														});
														setLoading(false);
													}
												);
											}
										);
									}}
								/>
							</label>
							{loading && (
								<CircularProgress
									sx={{ ml: 2 }}
									size={20}
									color="primary"
								/>
							)}
							{profileImage && profileImage?.src && (
								<Link
									color={"secondary"}
									onClick={() => setProfileImage(undefined)}>
									Remove picture
								</Link>
							)}
						</Stack>

						<Stack direction={'column'} spacing={1} >
						<Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  textAlign="start"
				
                >
                  Your full name*
                </Typography>
							<TextField fullWidth

								name={"displayName"}
								value={formikFirst.values.displayName}
								onChange={formikFirst.handleChange}
								error={
									formikFirst.touched.displayName &&
									Boolean(formikFirst.errors.displayName)
								}
								helperText={
									formikFirst.touched.displayName &&
									formikFirst.errors.displayName
								}
								//label={"Your full name *"}
							/>
						</Stack>
						<Stack direction={'column'} spacing={1} >
						<Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  textAlign="start"
				
                >
                 Tell us about yourself
                </Typography>
						<TextField
							name={"description"}
							value={formikFirst.values.description}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.description &&
								Boolean(formikFirst.errors.description)
							}
							helperText={
								formikFirst.touched.description &&
								formikFirst.errors.description
							}
							//label={"Tell us about yourself"}
							multiline={true}
							inputProps={{maxLength:150}}
							rows={3}
						/>
						<Typography align="right" sx={{color:"#233DFF"}}>{150-formikFirst.values.description.length}/150</Typography>
						</Stack>
						<Stack direction={'column'} spacing={1} >
						<Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  textAlign="start"
				
                >
               Linkedin URL *
                </Typography>
						<TextField fullWidth

								   name={"linkedinURL"}
								   value={formikFirst.values.linkedinURL}
								   onChange={formikFirst.handleChange}
								   error={
									   formikFirst.touched.linkedinURL &&
									   Boolean(formikFirst.errors.linkedinURL)
								   }
								   helperText={
									   formikFirst.touched.linkedinURL &&
									   formikFirst.errors.linkedinURL
								   }
								   //label={"Linkedin URL"}
						/>
</Stack>

					</Stack>
				</Container>
				<Stack
					direction={"row"}
					justifyContent="center"
					alignItems="center"
					spacing={3}
					width={"100%"}>
					<Button
						variant={"outlined"}
						color={"primary"}
						sx={{ px: 7 }}
						onClick={cbBack}>
						Back
					</Button>
					<Button
						variant={"contained"}
						color={"primary"}
						sx={{ px: 7 }}
						type={"submit"}>
						Next
					</Button>
				</Stack>
			</Stack>
	);
}
