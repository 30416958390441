import React from 'react';
import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import OnboardingStep1 from "../screens/on-boarding/onboarding-step-1";
import ExpertsOnboardingStep3 from "../screens/on-boarding/expert-onboarding-step-3";
import ExpertsOnboardingStep2 from "../screens/on-boarding/expert-onboarding-step-2";
import StartupProfile from "../screens/startups/startup-profile";
import FounderProfile from "../screens/startups/founder-profile";
import InvestorProfile from "../screens/investor/investor-profile";
import BusinessDeveloperProfile from "../screens/business-developer/business-developer-onboard";
import ServiceProviderProfile from "../screens/service-provider/service-provider-profile";
import TalentProfileDetails from "../screens/talent/talent-profile";
import OpenToCoFounder from "../screens/startups/open-to-co-founder";
import ExpertOnboard from "../screens/expert/expert-onboard";
import BusinessDeveloperOnboard from "../screens/business-developer/business-developer-onboard";




const OnBoardingRouter = () => {
  return(
    <Routes>
      <Route
        path="*"
        element={<Navigate to="/on-boarding/type" replace />}
      />
      <Route  path='on-boarding'  element={<Outlet />}>
        <Route  path={'type'} element={<OnboardingStep1/>}/>
        <Route  path={'expert-profile'} element={<ExpertOnboard/>}/>
        <Route  path={'your-working-experiences'} element={<ExpertsOnboardingStep3/>}/>
        <Route  path={'startup-profile-setup'} element={<StartupProfile/>}/>
        <Route  path={'open-to-cofounder'} element={<OpenToCoFounder/>}/>
        <Route  path={'founder-profile-setup'} element={<FounderProfile/>}/>
        <Route  path={'investor-profile-setup'} element={<InvestorProfile/>}/>
        <Route  path={'business-developer-profile-setup'} element={<BusinessDeveloperOnboard/>}/>
        <Route  path={'service-provider-profile-setup'} element={<ServiceProviderProfile/>}/>
        <Route  path={'talent-profile-setup'} element={<TalentProfileDetails/>}/>

      </Route>
    </Routes>

  )
}


export default OnBoardingRouter;