import React, { useEffect } from "react";
import Portal from "@mui/material/Portal";
import Dialog from "@mui/material/Dialog/Dialog";
import {
	getAuth,
	GoogleAuthProvider,
	updateProfile,
	updateEmail,
	sendEmailVerification,
} from "firebase/auth";
import {
	Box,
	Button,
	Container,
	Stack,
	TextField,
	Typography,
	InputAdornment,
	FormHelperText,
	useMediaQuery,
	Divider,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate } from "react-router-dom";
import {
	auth,
	DB,
	loginWithGoogle,
	loginWithEmail,
	loginWithLinkedin,
} from "../../config/firebase/firebase";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { AuthUserContext } from "../../providers/auth-provider";
import { useFormik } from "formik";
import SignInValidation from "../../validations/auth/signin-form-validation";
import referralCodeGenerator from "referral-code-generator";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import { LinkedIn, useLinkedIn } from "react-linkedin-login-oauth2";
import LinkedinAccessToken from "../../api/linkedin-access-token";
import Spinner from "../../components/common/spinner";
import GenerateReferralCode from "../../api/generate-referral-code";
import CloseIcon from "@mui/icons-material/Close";
import Timer from "../../components/counter";
import SendVerificationEmailApi from "../../api/send-verification-email-api";

export const callbackGoogleWithSignin = (navigator, setProfile, setOpen) => {
	loginWithGoogle()
		.then(async () => {
			try {
				const userRef = doc(DB, "users", auth.currentUser.uid);
				const data = auth.currentUser.toJSON();
				debugger
				for (let prop in data) {
					if (data[prop] === undefined) data[prop] = null;
					if (
						prop === "stsTokenManager" ||
						prop === "apiKey" ||
						prop === "appName"
					)
						delete data[prop];
				}

				GenerateReferralCode({uid:auth.currentUser.uid, displayName:data?.displayName})
					.then()
				const getdoc = await getDoc(userRef);
				if (!getdoc.exists()) {
					navigator('/auth/referral', {state: {type: 'google', data: {
								...data,
								earning: 100,
								countryState: '',
								searchName: data?.displayName?.toLowerCase(),
								createdAt: new Date().getTime(),
							}}});
				} else {
					await setDoc(userRef, { ...data, createdAt:  +data?.createdAt, updatedAt: new Date().getTime()}, { merge: true });
				}

				getdoc.exists() && (await setProfile(getdoc.data()));
				/*if (!getdoc.data()) {
					navigator("/on-boarding/type");

				}
				else*/ 
				if(getdoc.data() && !getdoc?.data()?.emailVerified) {
					setOpen(true)
				}
				else if (getdoc.data() && !getdoc?.data()?.onboard && getdoc?.data()?.emailVerified) {
					navigator("/on-boarding/type");
				} else {
					if (getdoc.data().userType === "expert") {
						navigator("/expert/timeline");
					}
					if (getdoc.data().userType === "startup") {
						navigator("/startup/timeline");
					}
					if (getdoc.data().userType === "investor") {
						navigator("/investor/timeline");
					}
					if (getdoc.data().userType === "service-provider") {
						navigator("/service-provider/timeline");
					}
					if (getdoc.data().userType === "business-developer") {
						navigator("/business-developer/timeline");
					}
					if (getdoc.data().userType === "talent") {
						navigator("/talent/timeline");
					}
				}
			} catch (e) {
				console.log(e.message);
			}
		})
		.catch((error) => {
			// Handle Errors here.
			const errorCode = error.code;
			const errorMessage = error.message;
			// The email of the user's account used.
			const email = error.customData.email;
			// The AuthCredential type that was used.
			const credential = GoogleAuthProvider.credentialFromError(error);
			console.log("ERROR::", errorCode, errorMessage, email, credential);
			// ...
		});
};
export default function Signin() {
	const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
	const navigator = useNavigate();
	const callback = callbackGoogleWithSignin;
	const navigate = useNavigate();
	const [timer, setTimer] = React.useState(false);
	const { setProfile, userBlock, profile } = React.useContext(AuthUserContext);
	const [userinit, setUserInit] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [invalidPass, setInvalidPass] = React.useState(false);
	const [notExists, setNotExists] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	//SHOW PASSWORD!
	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const [values, setValues] = React.useState({
		password: "",
		showpassword: false,
	});

	const handleClickPassword = () => {
		setValues({
			...values,
			showpassword: !values.showpassword,
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	let once = false;
	const { linkedInLogin } = useLinkedIn({
		clientId: "78pgzp570kouvv",
		redirectUri: `${window.location.origin}/auth/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
		scope: "r_liteprofile r_emailaddress",
		onSuccess: (code) => {
			if (!once) {
				setLoading(true);
				// console.log(code);
				once = !once;
				LinkedinAccessToken(code)
					.then((userCredential) => {
						// Signed in
						debugger;
						// console.log(userCredential, "userCredential");
						loginWithLinkedin(userCredential.data.customToken)
							.then(async (d) => {
								const { displayName, email, photoURL, id } =
									userCredential.data;
								debugger;
								try {
									const userRef = doc(
										DB,
										"users",
										auth.currentUser.uid
									);
									const data = auth.currentUser.toJSON();
									for (let prop in data) {
										if (data[prop] === undefined)
											data[prop] = null;
										if (
											prop === "stsTokenManager" ||
											prop === "apiKey" ||
											prop === "appName"
										)
											delete data[prop];
									}
									await updateProfile(auth.currentUser, {
										displayName,
										photoURL,
									});
									await updateEmail(auth.currentUser, email);
								/*	GenerateReferralCode({uid:auth.currentUser.uid, displayName})
										.then()*/
									const getdoc = await getDoc(userRef);
									if (!getdoc.exists()) {

										navigator('/auth/referral', {state: {type: 'linkedin', data: {
													...data,
													earning: 100,
													displayName,
													email,
													photoURL,
													countryState: '',
													createdAt: new Date().getTime(),
												}}});

									} else {
										await setDoc(
											userRef,
											{
												...data,
												displayName,
												email,
												photoURL,
												createdAt:+data?.createdAt,
												updatedAt: new Date().getTime(),
											},
											{ merge: true }
										);
									}
									const getdocc = await getDoc(userRef);
									// await setProfile(getdocc.data());
									getdoc.exists() && (await setProfile(getdoc.data()));
								/*	if (!getdoc.data()) {
										navigator("/on-boarding/type");

									}
									else*/ 
									if(getdoc.data() && !getdoc?.data()?.emailVerified) {
										setOpen(true)
									}
									else if (
										getdocc.data() &&
										!getdocc.data().onboard &&
										getdoc?.data()?.emailVerified
									) {
										navigator("/on-boarding/type");
									} else {
										if (
											getdoc.data().userType === "expert"
										) {
											navigator("/expert/timeline");
										}
										if (
											getdoc.data().userType === "startup"
										) {
											navigator("/startup/timeline");
										}
										if (
											getdoc.data().userType ===
											"investor"
										) {
											navigator("/investor/timeline");
										}
										if (
											getdoc.data().userType ===
											"service-provider"
										) {
											navigator(
												"/service-provider/timeline"
											);
										}
										if (
											getdoc.data().userType ===
											"business-developer"
										) {
											navigator(
												"/business-developer/timeline"
											);
										}
										if (
											getdoc.data().userType === "talent"
										) {
											navigator("/talent/timeline");
										}
									}
									setLoading(false);
								} catch (e) {
									setLoading(false);

									debugger;
								}
							})
							.catch((err) => {
								debugger;
								setLoading(false);
							});
						// ...
					})
					.catch((error) => {
						debugger;
						setLoading(false);
					});
			}
		},
		onError: (error) => {
			console.log(error);
		},
	});

	const formik = useFormik({
		initialValues: SignInValidation.initialValues,
		validationSchema: SignInValidation.schema,
		onSubmit: async (values) => {
			// console.log("values", values);
			try {
				const { email, password } = values;
				await loginWithEmail(email, password);
				const auth = getAuth();
				const userRef = doc(DB, "users", auth.currentUser.uid);
				setUserInit(auth.currentUser)
				const getdoc = await getDoc(userRef);
				getdoc.exists() && (await setProfile(getdoc.data()));
				
				if(getdoc.data() && !getdoc?.data()?.emailVerified) {
					setOpen(true)
				}
				else if (getdoc.data() && !getdoc?.data()?.onboard && getdoc?.data()?.emailVerified) {
					navigator("/on-boarding/type");
				} else {
					if (getdoc.data().userType === "expert") {
						navigator("/expert/timeline");
					}
					if (getdoc.data().userType === "startup") {
						navigator("/startup/timeline");
					}

					if (getdoc.data().userType === "investor") {
						navigator("/investor/timeline");
					}

					if (getdoc.data().userType === "service-provider") {
						navigator("/service-provider/timeline");
					}
					if (getdoc.data().userType === "business-developer") {
						navigator("/business-developer/timeline");
					}
					if (getdoc.data().userType === "talent") {
						navigator("/talent/timeline");
					}
					if (getdoc.data().userType === "admin") {
						navigator("/admin/dashboard");
					}
				}
				
				const {lastLoginAt} = auth.currentUser.toJSON()
				setDoc(userRef, {lastLoginAt}, {merge:true}).then()
			} catch (e) {
				console.log(e.message);
				if (e.message === "Firebase: Error (auth/wrong-password).") {
					setInvalidPass(true);
				}
				if (e.message === "Firebase: Error (auth/user-not-found).") {
					setNotExists(true);
				}
			}
		},
	});
	if (loading) return <Spinner />;
	return (
		<Stack justifyContent="center"
		alignItems="center" sx={{maxWidth:"500px", margin:"auto"}}>
			<Container maxWidth={"sm"} sx={{ mt: 10 }}>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					spacing={4}
					component="form"
					onSubmit={(e) => formik.handleSubmit(e)}
					sx={{
						width: "100%",
						// marginLeft: "50px",
					}}>
					<Typography
						variant={"h4"}
						sx={{ textAlign: "center", width: "100%" }}>
						Sign in
					</Typography>
					<Typography
						variant={"body2"}
						sx={{ textAlign: "center", width: "100%" }}>
						The world's most exclusive community of GTM experts
					</Typography>

					<TextField
						name={"email"}
						value={formik.values.email}
						onChange={(e) => {
							formik.handleChange(e);
							setNotExists(false);
						}}
						error={
							formik.touched.email && Boolean(formik.errors.email)
						}
						helperText={formik.touched.email && formik.errors.email}
						variant={"outlined"}
						type={"email"}
						placeholder={"xyz@example.com"}
						label={"Email"}
						fullWidth
					/>
					<FormControl
						fullWidth
						sx={{ width: "100%" }}
						variant="outlined">
						<InputLabel htmlFor="outlined-adornment-password">
							Password
						</InputLabel>

						<OutlinedInput
							name="password"
							value={formik.values.password}
							onChange={(e) => {
								formik.handleChange(e);
								setInvalidPass(false);
								setNotExists(false);
							}}
							error={
								(formik.touched.password &&
									Boolean(formik.errors.password)) ||
								invalidPass
							}
							variant={"outlined"}
							type={values.showpassword ? "text" : "password"}
							placeholder={"xxxxxxxx"}
							label={"Password"}
							fullWidth
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end">
										{!values.password ? (
											<img
												src="/assets/eye_close.png"
												alt=""
											/>
										) : (
											<img
												src="/assets/eye_open.png"
												alt=""
											/>
										)}
									</IconButton>
								</InputAdornment>
							}
						/>
						{
							<FormHelperText error id="password-error">
								{formik.values.password.length == 0 &&
								formik.values.email.length != 0
									? "Password is required"
									: (formik.values.password.length < 8 &&
											formik.values.email.length != 0) ||
									  formik.errors.password
									? "Please enter a valid password"
									: invalidPass
									? "The email/password you entered is invalid. "
									: notExists
									? "The account does not exist"
									: ""}
							</FormHelperText>
						}

						<Typography
							align="left"
							sx={{
								color: "#ED1C24",
								fontWeight: "500",
								fontSize: "10px",
							}}>
							{formik.touched.password &&
								Boolean(formik.errors.password)}
							&nbsp;
						</Typography>
					</FormControl>

					<Stack width={"100%"} justifyContent="flex-end" alignItems="end">
						<Typography
							sx={{
								textAlign:"right",
								cursor: "pointer",
								fontSize: "12px",
								color: "#505050",
								// marginLeft: "300px",
								marginTop: "-16px",
							}}
							onClick={() =>
								navigator("/auth/forget-your-password")
							}>
							Forgot Password
						</Typography>
					</Stack>
					{/* <Stack alignItems="flex-end">
            <Typography variant="body" color="#505050" component="p">
              <Button
                variant="text"
                color={"secondary"}
                justifyContent= {"right"}
                sx={{
                  marginLeft: "400px",
                  float: "right",
                  fontWeight: 400,
                  textTransform: "none",

                  }}
                onClick={() => navigator("/auth/forget-your-password")}
              >
                Forgot Password
              </Button>
            </Typography>
          </Stack> */}
					<Button
						fullWidth
						variant={"contained"}
						color={"primary"}
						type={"submit"}>
						Sign in
					</Button>
				</Stack>
			</Container>
			<Container maxWidth="sm" sx={{ mt: "35px" }}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
					<Divider style={{ flexGrow: 1, background:"#000"}} />
					<Typography variant="body1" sx={{ px: 2 }}>
						OR
					</Typography>
					<Divider style={{ flexGrow: 1, background:"#000"}} />
    		</div>
			</Container>
			<Container maxWidth="sm" sx={{ mb: "20px" }}>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					spacing={2}
					sx={{
						width: "100%",
					}}>
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={0.5}>
						<Button
							variant={"text"}
							color={"secondary"}
							startIcon={
								<img
									style={{ height: "20px"}}
									src={"/assets/google.png"}></img>
							}  >
							<Typography
								color={"secondary"}
								sx={{ fontSize: "14px", color:'#8F8F8F' }}

								onClick={() => callback(navigator, setProfile)}>
								<span style={{ textTransform: "capitalize" }}>
									{" "}
									Continue{" "}
								</span>
								<span style={{ textTransform: "lowercase" }}>
									with
								</span>
								<span style={{ textTransform: "capitalize" }}>
									{" "}
									Google
								</span>
							</Typography>
						</Button>
						<Button

							onClick={linkedInLogin}
							variant={"text"}
							color={"secondary"}
							startIcon={
								<LinkedInIcon style={{ color: "#204488" }} />
							}

						>
							<Typography
								color={"secondary"}
								sx={{ fontSize: "14px" , color:'#8F8F8F' }}>
								<span style={{ textTransform: "capitalize" }}>
									{" "}
									Continue{" "}
								</span>
								<span style={{ textTransform: "lowercase" }}>
									with
								</span>
								<span style={{ textTransform: "capitalize" }}>
									{" "}
									Linked
								</span>
								<span style={{ textTransform: "capitalize" }}>
									In
								</span>
							</Typography>
						</Button>
					</Stack>
					{/*<LinkedIn*/}
					{/*  clientId="78pgzp570kouvv"*/}
					{/*  redirectUri={`${window.location.origin}/auth/signin`}*/}
					{/*  onSuccess={(code) => {*/}
					{/*  */}
					{/*  }}*/}
					{/*  onError={(error) => {*/}
					{/*    debugger*/}
					{/*    console.log(error);*/}
					{/*  }}*/}
					{/*>*/}
					{/*  {({ linkedInLogin }) => (*/}
					{/*    <Button                 onClick={linkedInLogin}*/}
					{/*                            variant={'text'} color={'secondary'} startIcon={<LinkedInIcon />}>*/}
					{/*      <Typography color={'secondary'}>*/}
					{/*        Continue with Linkedin*/}
					{/*      </Typography>*/}
					{/*    </Button>*/}
					{/*  )}*/}
					{/*</LinkedIn>*/}

					<Typography variant={"body1"} color={"secondary"}>
					New to AstraNova?
						<Button
							variant={"text"}
							color={"primary"}
							onClick={() =>
								navigator("/auth/where-are-you-located")
							}>
							Join Now
						</Button>
					</Typography>
				</Stack>
			</Container>

			<Portal>
				<Dialog
					open={open}
					aria-labelledby="alert-dialog-achive"
					aria-describedby="alert-dialog-achive"
					maxWidth={"sm"}>
					<DialogContent
						sx={{ minWidth: 300, padding: "0px 0px", mt: 2 }}>
						<Stack
							direction={"column"}
							sx={{ width: "99%" }}
							justifyContent="center"
							alignItems="flex-end">
							<IconButton
								disabled={userBlock}
								onClick={() => setOpen(false)}>
								<CloseIcon />
							</IconButton>
						</Stack>
						<Stack
							direction={"column"}
							sx={{ width: "100%" }}
							alignItems="center"
							spacing={3}>
							<Typography
								component="span"
								variant="h5"
								color="text.primary"
								sx={{
									textAlign: "center",
									flex: 1,
									padding: 0,
									px: 5,
									lineHeight: 1.3,
									fontWeight: 700,
								}}>
								Confirm your email to activate your account{" "}
								<br />
								<font size={2} style={{ fontWeight: 500 }}>
									We sent the verification email to{" "}
									<font color={"#233DFF"}>
										{profile && profile?.email}
									</font>
								</font>
							</Typography>
							<Typography
								variant={"body2"}
								sx={{ lineHeight: 1.3, textAlign: "center" }}>
								<>
									Didn’t receive the email?
									<br />{" "}
									<Button
										variant={"text"}
										style={{
											textDecoration: "underline",
											fontWeight: 600,
											cursor: "pointer",
										}}
										onClick={() => {
											const regYahoo = /@yahoo|@ymail/
											debugger
											if (regYahoo.test(userinit?.email)) {
												sendEmailVerification(userinit, {
													url: `${window.location.origin}/auth/signin`,
												}).then(() => setTimer(true))
												
											} else {
												SendVerificationEmailApi({ email: userinit?.email })
													.then(() => setTimer(true));
											}
											
											/*sendEmailVerification(userinit, {
												url: `${window.location.origin}/auth/signin`,
											})
												.then(() => {
												})
												.catch((err) => {});*/
										}}>
										{" "}
										resend email.
									</Button>
								</>

								{timer && (
									<Typography
										sx={{
											textAlign: "center",
											fontSize: "14px",
											width: "100%",
										}}>
										Resend email in{" "}
										<b>
											{" "}
											00 : <Timer max={60} />
										</b>
									</Typography>
								)}
							</Typography>

							<Stack
								direction={"column"}
								sx={{ width: "100%", px: 5 }}
								justifyContent="center"
								alignItems="center">
								{/* <Button variant={"contained"} fullWidth size={"small"} sx={{px: 2}}  onClick={() => {
                  logout().then(() => {
                    setProfile(null)
                    setUser(null)
                    setOpen(false)
                    {
                      matches ? navigate('/auth/signin') : navigate('/m/auth/signin')
                    }
                   
                  })
                  // setOpenRewards(!openRewards)
                }} disabled={userBlock} endIcon={userBlock && <CircularProgress size={20}/>}>
                  Verify
                </Button> */}
							</Stack>

							<Box />
						</Stack>
					</DialogContent>
				</Dialog>
			</Portal>
		</Stack>
	);
}
