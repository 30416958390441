import React from "react";
import { DB } from "../../config/firebase/firebase";
import {
  styled,
  Container,
  Stack,
  Typography,
  Avatar,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Badge,
  Grid,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { getAuth, getIdToken, updateProfile } from "firebase/auth";
import { a11yProps, TabPanel } from "../../components/common/tabs-custom";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import { EditSharp } from "@mui/icons-material";
import EditInvestorOrganizationDetails from "../../components/common/profile/edit/investor/edit-investor-organization-details";
import EditInvestorOrganizationOtherDetails from "../../components/common/profile/edit/investor/edit-investor-organization-other-details";
import PostNotification from "../../api/post-notification";
import UploadFile from "../../utils/upload-file";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
const StyledTabs = styled((props) => (
  <Tabs
    sx={{ position: "relative", left: 0, top: 15 }}
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "none",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    borderRadius: "30px",
    color: "#000",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#233DFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
export default function MyOrganisationProfile({}) {
  const styleFont = {
    backgroundColor: "#F6F8FA",
    padding: "8px 15px",
    borderRadius: "5px",
    fontSize: 14,
  };
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { setUser, user } = React.useContext(AuthUserContext);
  const userContext = React.useContext(AuthUserContext);
  const location = useLocation();
  const { state } = location;
  const { organisation } = state;
  const [profile, setProfile] = React.useState(state?.profile);
  const [imageURL, setImageURL] = React.useState();
  const confirmed = React.useRef(false);
  const [value, setValue] = React.useState(0);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [openOtherDetails, setOpenOtherDetails] = React.useState(false);
  const profileImage = React.useRef();

  const navigator = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    setDialogOpen(false);
  }
  const Input = styled("input")({
    display: "none",
  });
  async function updateProfilePicture(url) {
    try {
      const auth = getAuth();
      await updateProfile(auth.currentUser, {
        logoURL: url,
      });
      const userRef = await doc(DB, "users", auth.currentUser.uid);
      let dat;
      dat = await updateDoc(userRef, {
        logoURL: url,
      });
      setProfile({ ...profile, logoURL: url });
      userContext.setProfile({ ...profile, logoURL: url });
      setImageURL("");
      PostNotification({
        type: "update-profile-picture",
        imageURL: url,
        message: `Your organisation profile picture has been updated`,
        uid: user.uid,
      }).then();
      confirmed.current = true;
    } catch (err) {
      console.log(err.message);
    }
  }
  function UploadProfile() {
    debugger;
    return (
      <div>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {organisation.nameOfOrganization}
          </DialogTitle>
          <DialogContent sx={{ height: 400, width: 600 }}>
            <Avatar
              key={imageURL}
              src={
                imageURL
                  ? imageURL
                  : profile?.logoURL !== ""
                  ? profile?.logoURL
                  : ""
              }
              alt={organisation?.nameOfOrganization}
              sx={{
                width: 152,
                height: 152,
                ml: 25,
              }}
              component={"span"}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "start", height: 100 }}>
            <Stack direction={"row"} spacing={-5}>
              <IconButton
                sx={{ width: 150, "&:hover": { backgroundColor: "white" } }}
                variant="outlined"
              >
                <label htmlFor={"profile-image"}>
                  <Stack direction={"column"}>
                    <CameraAltIcon
                      fontSize="large"
                      sx={{ ml: 2, mb: 2, cursor: "pointer" }}
                    />
                    <Input
                      accept="image/*"
                      id="profile-image"
                      multiple={false}
                      type="file"
                      onChange={(e) => {
                        confirmed.current = false;
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(e.target.files[0]);
                        fileReader.addEventListener("load", async function () {
                          const file = e.target.files[0];

                          const path = `profile-image/${file.name}`;
                          const imageObject = {
                            progress: 0,
                            src: this.result,
                            path,
                            file: e.target.files[0],
                            size: (
                              e.target.files[0]?.size /
                              1024 /
                              1024
                            ).toFixed(2),
                          };
                          profileImage.current = imageObject;
                          UploadFile(file, path).then((url) => {
                            console.log(url);
                            setImageURL(url);
                          });
                        });
                      }}
                    />
                    <DialogContentText sx={{ fontSize: 15 }}>
                      Add Photo
                    </DialogContentText>
                  </Stack>
                </label>
              </IconButton>
              <IconButton
                sx={{
                  width: 150,
                  mr: 12,
                  "&:hover": { backgroundColor: "white" },
                }}
                variant="outlined"
                onClick={() => {
                  profileImage.current = "";
                  setImageURL(" ");

                  // updateProfilePicture("");
                  confirmed.current = true;
                  //handleDialogClose();
                }}
              >
                <Stack direction={"column"}>
                  <DeleteIcon fontSize="large" sx={{ ml: 4, mb: 2 }} />

                  <DialogContentText sx={{ fontSize: 15 }}>
                    Remove Photo
                  </DialogContentText>
                </Stack>
              </IconButton>
            </Stack>
            <Stack />
            <Button
              sx={{ width: 150, ml: 20 }}
              onClick={() => {
                profileImage.current = {
                  ...profileImage,
                  logoURL: imageURL,
                };

                updateProfilePicture(imageURL);

                profileImage.current = "";

                handleDialogClose();
                //updateProfilePicture("");
              }}
              autoFocus
              variant="contained"
            >
              Save Photo
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return (
    <Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
      {organisation && (
        <Stack direction={"column"} spacing={5}>
          <Stack
            direction={"column"}
            spacing={0.5}
            sx={{
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
              backgroundColor: "#fff",
              minHeight: 100,
              p: 2,
              pb: 0,
            }}
          >
            <Box
              sx={{
                height: 120,
                width: "100%",
                backgroundColor: "#F6F8FA",
                borderRadius: "15px",
              }}
            >
              <Box
                sx={{
                  border: "1px solid #204488",
                  p: 0.75,
                  borderRadius: "50%",
                  width: "fit-content",
                  position: "relative",
                  left: 35,
                  top: 60,
                }}
              >
                <label>
                  <Badge
                    overlap="circular"
                    sx={{ width: 92, height: 92 }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={
                      profile?.uid === user?.uid && (
                        <Button
                          onClick={handleDialogOpen}
                          sx={{ ml: 1, mt: 1 }}
                          color={"primary"}
                          component={"span"}
                        >
                          <AddCircleIcon />
                        </Button>
                      )
                    }
                  >
                    {profile?.uid === user?.uid && <UploadProfile />}
                    <Avatar
                      alt={profile?.logoURL}
                      src={profile?.logoURL}
                      sx={{ width: 92, height: 92 }}
                    >
                      <Typography variant={"h3"}>
                        {organisation?.nameOfOrganization.charAt(0)}
                      </Typography>
                    </Avatar>
                  </Badge>
                </label>
              </Box>
            </Box>
            <Stack
              direction={"row"}
              spacing={3}
              sx={{ position: "relative", top: 50 }}
            >
              <div />
              <Stack direction={"column"} spacing={2} sx={{ width: "100%" }}>
                <Stack direction={"row"} spacing={3}>
                  <Typography variant={"h5"} sx={{ marginTop: "2.5px" }}>
                    {organisation?.nameOfOrganization}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#E0E3FF",
                      px: 3,
                      py: 1,
                      borderRadius: "19.5px",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        textTransform: "capitalize",
                      }}
                    >
                      {"Investor"}
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{ position: "relative", top: -10 }}
                >
                  <Typography
                    variant={"body2"}
                    color={"grey"}
                    style={{ flex: 1 }}
                  >
                    {profile?.countryState}
                  </Typography>
                </Stack>

                {organisation && organisation?.organizationDescription && (
                  <Typography
                    variant={"body1"}
                    color={"secondary"}
                    sx={{
                      width: "calc(100% - 10%)",
                      position: "relative",
                      top: -10,
                    }}
                  >
                    {organisation?.organizationDescription}
                  </Typography>
                )}
              </Stack>
              <IconButton
                sx={{
                  display: "grid",
                  justifyContent: "right",
                  position: "absolute",
                  right: 22,
                }}
                onClick={() => {
                  setOpenDetails(true);
                }}
              >
                <EditSharp />
              </IconButton>
              <EditInvestorOrganizationDetails
                profile={profile}
                open={openDetails}
                handleClose={() => setOpenDetails(false)}
                callback={(s) => {
                  setOpenDetails(false);
                  setProfile(s);
                  const path = `/admin/organisation-profile-investor`;
                  navigator(path, {
                    state: {
                      profile: s,
                      organisation: {
                        organizationDescription:
                          s?.investor?.organizationDescription,
                        investorType: s?.investor?.investorType,
                        website: s?.investor?.website,
                        nameOfOrganization: s?.investor?.nameOfOrganization,
                        logoURL: s?.investor?.logoURL,
                      },
                    },
                  });
                }}
              />
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <StyledTabs
                sx={{
                  paddingLeft: "35px",
                }}
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
              >
                <StyledTab label="ABOUT" {...a11yProps(0)} />
              </StyledTabs>

              <div style={{ flex: 10 }} />
              <Stack
                direction={"column"}
                spacing={1}
                justifyContent="center"
                sx={{
                  position: "relative",
                  minWidth: 120,
                  cursor: "pointer",
                  paddingTop: "70px",
                }}
                alignItems="center"
                onClick={() => {
                  if (userContext.profile.userType === "admin") {
                    navigator("/admin/user-details", { state: { profile } });
                  } else {
                    navigator("/investor/profile");
                  }
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #204488",
                    p: 0.75,
                    borderRadius: "50%",
                    width: "fit-content",
                  }}
                >
                  <Avatar
                    alt={profile?.displayName}
                    src={
                      profile?.photoURL !== ""
                        ? profile?.photoURL
                        : profile.photoURL
                    }
                    sx={{ width: 51, height: 51 }}
                  >
                    <Typography variant={"h4"}>
                      {profile?.displayName.charAt(0)}
                    </Typography>
                  </Avatar>
                </Box>
                <Typography variant={"h6"} sx={{ textAlign: "center" }}>
                  {profile?.displayName}
                  <br />
                  <font
                    size={2}
                    style={{
                      backgroundColor: "#F6F8FA",
                      padding: "5px 10px",
                      borderRadius: "6px",
                      fontWeight: 300,
                    }}
                  >
                    {profile?.investor?.role}
                  </font>
                </Typography>
              </Stack>
            </Stack>
            <Box sx={{ height: 30 }} />
          </Stack>

          <TabPanel index={0} value={value}>
            <Stack
              direction={"column"}
              spacing={3}
              sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                px: 5.5,
                py: 6,
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <Typography fontSize={20} fontWeight={600} style={{ flex: 1 }}>
                  About Organization
                </Typography>
                <IconButton onClick={() => setOpenOtherDetails(true)}>
                  <EditSharp />
                </IconButton>
              </Stack>
              <EditInvestorOrganizationOtherDetails
                profile={profile}
                open={openOtherDetails}
                handleClose={() => setOpenOtherDetails(false)}
                callback={(s) => {
                  setOpenOtherDetails(false);

                  setProfile(s);
                  const path = `/admin/organisation-profile-investor`;
                  navigator(path, {
                    state: {
                      profile: s,
                      organisation: {
                        organizationDescription:
                          s?.investor?.organizationDescription,
                        investorType: s?.investor?.investorType,
                        website: s?.investor?.website,
                        nameOfOrganization: s?.investor?.nameOfOrganization,
                        logoURL: s?.investor?.logoURL,
                      },
                    },
                  });
                }}
              />
              <Stack direction={"column"} spacing={4}>
                <Stack direction={"column"} spacing={2}>
                  <Typography variant={"h6"} sx={{ lineHeight: 2 }}>
                    Website
                    <br />{" "}
                    <font style={styleFont}>
                      <a
                        href={
                          !organisation?.website || organisation?.website === ""
                            ? "javascript:;"
                            : organisation?.website
                        }
                      >
                        {!organisation?.website || organisation?.website === ""
                          ? "N/A"
                          : organisation?.website}
                      </a>
                    </font>
                  </Typography>
                  <hr
                    style={{ border: "1px solid rgba(175, 175, 175, 0.4)" }}
                  />
                </Stack>

                <Stack direction={"column"} spacing={2}>
                  <Typography variant={"h6"} sx={{ lineHeight: 2 }}>
                    Investor type
                    <br />{" "}
                    <font style={styleFont}>
                      {!organisation?.investorType ||
                      organisation?.investorType === ""
                        ? "N/A"
                        : organisation?.investorType}{" "}
                    </font>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </TabPanel>
        </Stack>
      )}
    </Container>
  );
}
