import * as yup from "yup";

const ForgetPasswordSchema = yup.object({
  email: yup
    .string("Email")
    .email("Enter a valid email")
    .required("Email is required"),
});
const initialValues = {
  email: "",
};

const ForgetPasswordValidation = {
  initialValues,
  schema: ForgetPasswordSchema,
};

export default ForgetPasswordValidation;
