import * as React from "react";
import Typography from "@mui/material/Typography/Typography";
import Stack from "@mui/material/Stack/Stack";
import moment from "moment/moment";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList";
import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListItemAvatar,
	Avatar,
	ImageListItem,
	Box,
	Paper,
	Dialog,
	IconButton,
	CardMedia,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import { InsertDriveFile } from "@mui/icons-material";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import DownloadFileHelper from "../../../../utils/download-file-helper";
import CloseIcon from "@mui/icons-material/Close";
export default function SidePannelImageMessageComponent({ msg, type }) {
	const storage = getStorage();
	const [mediaUrl, setMediaUrl] = React.useState();
	const imagePattern = /.png|.jpg|.jpeg|.gif/;
	const moviePattern = /.mov|.mp4/;
	const [open, setOpen] = React.useState(false);
	function handleOpen() {
		setOpen(true);
	}
	function handleClose() {
		setOpen(false);
	}
	function FileModal() {
		return (
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					{imagePattern.test(msg.fileName) ? (
						<Stack direction={"row"}>
							<img src={mediaUrl} width={450} height={450} />
							<IconButton
								sx={{
									backgroundColor: "white",
									width: 20,
									height: 20,
									ml: -4,
									mt: 1,
									mr: 1,
									padding: 1.5,
									color: "black",
								}}
								onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Stack>
					) : (
						<Stack direction={"row"}>
							<CardMedia
								component="video"
								image={mediaUrl}
								controls
							/>

							<IconButton
								sx={{
									backgroundColor: "white",
									width: 20,
									height: 20,
									ml: -4,
									mt: 1,
									mr: 1,
									padding: 1.5,
								}}
								onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Stack>
					)}
				</Dialog>
			</div>
		);
	}
	React.useEffect(() => {
		if (msg.path && msg.path !== "") {
			getDownloadURL(ref(storage, msg.path))
				.then((url) => {
					setMediaUrl(url);
				})
				.catch((error) => {
					// Handle any errors
				});
		}
	}, []);

	return (
		<Stack direction={"row"} spacing={1} alignItems={"center"}>
			{/* <div style={{border:"1px blue solid",width:"100%",display:"flex",flexWrap:"wrap"}}> */}
			{/* <Stack
          direction={"column"}
          spacing={0}
          sx={{ color: "#fff", borderRadius: "30px", maxWidth: "70%" }}
        > */}
			<FileModal></FileModal>
			{imagePattern.test(msg.fileName) ? (
				<ImageListItem>
					<img
						src={mediaUrl}
						srcSet={mediaUrl}
						alt={msg?.fileName}
						loading="eager"
						style={{
							objectFit: "cover",
							height: "86px",
							width: "76px",
							borderRadius: "10px",
							cursor: "pointer",
						}}
						onClick={() => handleOpen()}
					/>
				</ImageListItem>
			) : (
				<video
					src={mediaUrl}
					alt={msg?.fileName}
					style={{
						objectFit: "cover",
						borderRadius: "10px",
						height: "86px",
						width: "76px",
						margin: "2px",
						cursor: "pointer",
					}}
					onClick={() => handleOpen()}
				/>
			)}

			{/* </Stack> */}
			{/* </div> */}
		</Stack>
	);
}
