import React from "react";
import {collection, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import {
	Container,
	Stack,
	Typography,
	Button,
	Box,
	Portal, Skeleton, Autocomplete, TextField,
	Checkbox, Chip, Popper,List, Paper, InputAdornment, ClickAwayListener, Grow} from "@mui/material";
import { DB } from "../config/firebase/firebase";
// import moment from "moment";
// import StartConversation from "../components/common/start-conversation";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../providers/auth-provider";
import PostRequestDialog from "../components/post-request-dialog";
import OpenRequestListItem from "../components/common/request/open-request-list-item";
import dataJson from "../const/data.json";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';

import { Search } from "@mui/icons-material";

const filterButtonStyle ={
	border:"none", boxShadow: "0 0 0 0.5px #8F8F8F !important", borderRadius: '30px', fontSize:'14px', fontWeight:400, textTransform:"none"
	,'&:hover': {
		border:"none", outline:"none" // Change the text color on hover
	  },
}
const chipStyle = {
	borderRadius: "24px", height:"35px", minWidth:"50px",cursor: 'pointer',  fontSize:14, fontWeight:300, background:"#2841fc", color:"#FFF",
}
export const HomeComponent = () => {
	const { profile } = React.useContext(AuthUserContext);
	const [searchQuery, setSearchQuery] = React.useState("");
	const [requests, setRequests] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [inputbtn,setinputbtn]=React.useState('');
	const [inputValue, setInputValue] = React.useState('');
	const [search, setSearch] = React.useState("");
	const[lastDoc, setLastDoc]=React.useState();
	const[loading, setLoading]=React.useState(false);
	const navigator = useNavigate();
	
	const [jobTypes, setJobTypes] = React.useState([]);
	const [onsites, setOnsites] = React.useState([]);
	const [userType, setUserType] = React.useState([]);
	const [locations, setLocations] = React.useState([]);
	const [languages, setLanguages] = React.useState([]);
	const [sectors, setSectors] = React.useState([]);
	const [markets, setMarkets] = React.useState([]);
	const [experiences, setExperiences] = React.useState([]);
	
	const [freeSearch, setFreeSearch] = React.useState([]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selected, setSelected] = React.useState({})
	const selectedFilters = React.useRef([])
	
	/*const filter = async () => {
		setLoading(true)
		if (jobTypes.length > 0) {
			const q = query(
				
				collection(DB, "help-requests"),
				where('free_search', 'array-contains-any', [...jobTypes, 'Invetsor', 'Industry Expert']),
				orderBy("createdAt", "desc"),
				startAfter(lastDoc),
				limit(20)
			);
			getDocs(
				q
			).then((querySnapshot) => {
				if (querySnapshot.size > 0) {
					const list = [];
					querySnapshot.forEach((doc) => {
						list.push({ id: doc.id, ...doc.data() });
						
						// console.log(doc.id, " => ", doc.data());
					});
					setRequests(list);
					setLastDoc(querySnapshot.docs[querySnapshot.docs.length-1])
					setLoading(false)
					
					
				}
				
			});
		}
		
	}*/
	/*React.useEffect(() => {
		debugger
		if (jobTypes.length > 0) {
			filter().then()
			
		} else{
			load()
		}
	}, [jobTypes])*/
	const [isFilters, setFilters] = React.useState(false)
	const clearFilter = () => {
		setJobTypes([]);
		setOnsites([]);
		setUserType([]);
		setLocations([]);
		setLanguages([]);
		setFreeSearch([]);
	}
	const filters = () => {
		setRequests([])
		debugger
		setFreeSearch([...new Set([
			...userType.map((type) => type === 'Growth Expert' ? 'Business Developer' : type === 'Industry Expert' ? 'Expert' : type),
			...locations,
			...onsites,
			...jobTypes,
			...languages,
			...experiences,
			...sectors,
			...markets
			
			])])
	}
	const loadMore = async () => {
		setLoading(true)
		
		let q;
		if (freeSearch?.length > 0) {
			q = query(
				
				collection(DB, "help-requests"),
				where('free_search', 'array-contains-any', [...freeSearch]),
				// where('deadline', '>', moment().format('YYYY-MM-DD')),
				orderBy('createdAt', 'desc'),
				startAfter(lastDoc),
				limit(10)
			);
		} else {
			q = query(
				
				collection(DB, "help-requests"),
				// where('deadline', '>', moment().format('YYYY-MM-DD')),
				orderBy('createdAt', 'desc'),
				
				startAfter(lastDoc),
				limit(10)
			);
		}
		
		const querySnap = await getDocs(q);
		if (querySnap.size > 0) {
			const list = [...requests]
			querySnap.docs.forEach((doc) => {
				list.push({id: doc.id, ...doc.data()})
			});
			// console.log(list)
			setRequests(list)
			querySnap.size < 10 ? setLastDoc(undefined) :setLastDoc(querySnap.docs[querySnap.docs.length-1]);
		} else {
			setLastDoc(undefined)
		}
		setLoading(false)

	}
	const load = () => {
		setLastDoc(undefined)
		
		let q;
		if (freeSearch?.length > 0) {
			 q = query(
				
				collection(DB, "help-requests"),
				where('free_search', 'array-contains-any', [...freeSearch]),
				// where('deadline', '>', moment().format('YYYY-MM-DD')),
				 orderBy('createdAt', 'desc'),
				limit(30)
			);
		} else {
			q = query(
				collection(DB, "help-requests"),
				// where('deadline', '>', moment().format('YYYY-MM-DD')),
				orderBy('createdAt', 'desc'),
				limit(30)
			)
		}
		setLoading(true)
		getDocs(
			q
		).then((querySnapshot) => {
			if (querySnapshot.size > 0) {
				const list = [];
				querySnapshot.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });
					
					// console.log(doc.id, " => ", doc.data());
				});
				setRequests(list);
				
				setLastDoc(querySnapshot?.size < 30 ? undefined :   querySnapshot.docs[querySnapshot.docs.length-1])
				setLoading(false)
				
				
			} else {
				setLoading(false)
				setRequests([])
			}
			
		});
	}
	React.useEffect(() => {
		load()

	}, [freeSearch]);
	React.useEffect(() => {
		filters()
		selectedFilters.current = userType.concat(jobTypes,onsites,locations, languages, sectors, markets, experiences)

	}, [userType,jobTypes,onsites,locations, languages, sectors, markets, experiences]);

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				minWidth:"260px",
			
			}
		},
		anchorOrigin:{vertical: 'bottom', horizontal : 'right'}
	};
	
	const toggleAnchor = (event) =>{
				if(anchorEl===event.currentTarget){
					setAnchorEl(null)
				}
				else{
					setAnchorEl(event.currentTarget)
				}
			}
	
	const FilterPopup = ({data}) => {

		const [searchValue, setSearchValue] = React.useState('');
		const [filteredOptions, setFilteredOptions] = React.useState(data?.options || []);
		const [selectedOptions, setSelectedOptions] = React.useState([])
		
		const handleSearchChange = (event) => {
		  const value = event.target.value;
		  setSearchValue(value);
		  // Filter options based on search value
		  if(searchValue!==""){

			  const filtered = data?.options.filter((option) =>
			  option.toLowerCase().includes(value.toLowerCase())
			  );
			  setFilteredOptions(filtered);
			}
			
		};
		React.useEffect(()=>{
			setSelectedOptions(data?.value || [])
		  },[data?.value])
		
		// React.useEffect(()=>{
		// 	if(data?.cbValue && selectedOptions.length<10){data.cbValue(selectedOptions)}
		// }, [selectedOptions])
		
		return (
			<ClickAwayListener onClickAway={()=>{setAnchorEl(null)}}>
			
			<Popper  open={Boolean(anchorEl)} sx={{zIndex:2000, py:1}} 
			anchorEl={anchorEl} 
			// onClose={()=>{data.cbValue(selectedOptions)}} 
			// anchorOrigin={{vertical:"bottom", horizontal:"right"}}
			placement="bottom-start"
			transition
			>
 			{({ TransitionProps }) => (
			  <Grow {...TransitionProps} timeout={150}>		
			  <Paper sx={{padding:2,}}>
				{data?.options && data.options.length > 11 &&
				<TextField
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
						<Search/>
					  </InputAdornment>
					),
				}}
				//   label="Search"
				placeholder="Search"
				value={searchValue}
				onChange={handleSearchChange}
				fullWidth
				size="small"
				margin="dense"
				sx={{ color: "#000000", borderRadius:1, background:"#e4eaf5",  
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						border: 'none',
					},
				},
				input:{
					"&::placeholder":{
						opacity:1,
						fontSize:12,
					}
				},
				
			}}
			/>}
				<List sx={{maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width:"260px", overflowY:"auto", textOverflow:"ellipsis"}}>
				   {/* {selectedOptions.} */}
				  {filteredOptions.map((option) => (
					<Stack key={option} direction={'row'} sx={{cursor: 'pointer',}} alignItems={'center'} onClick={()=>{
						
						if (selectedOptions.includes(option)) {
							setSelectedOptions(selectedOptions.filter((chip) => chip !== option));
						} else {
							if(selectedOptions.length<10-selectedFilters.current.length){
								setSelectedOptions([...selectedOptions, option])
							}
						}
					}}>
				<Checkbox  checked={selectedOptions.includes(option)}/>	
				<Typography sx={{ opacity:selectedOptions.length<10-selectedFilters.current.length ? 1 : 0.4, color:selectedOptions.length<10-selectedFilters.current.length ? "#000000 !important" : "#8F8F8F"}}
				>{option}</Typography>
				</Stack>
				  ))}
				</List>
				{(selectedOptions.length>0 || selectedFilters.current.length>0) && <Button variant="contained" size="small" color="primary" sx={{width:"50%", ml:"25%"}} onClick={()=>{setAnchorEl(null);data.cbValue(selectedOptions)}}
            >
            Show Results
          </Button>}
			  </Paper>
			  </Grow>
			)}
			</Popper>
				  </ClickAwayListener>
		  
		);
	  };
	  
	return (
		<Container maxWidth={"lg"} sx={{ mt: 5, textAlign: "left" }}>
			<Stack direction={"column"} spacing={3}>
				<Typography variant={"h4"} style={{ fontWeight: "bold" }}>
					Open requests and responses
				</Typography>
				<Stack
					direction={"row"}
					spacing={2}
					justifyContent="flex-start"
					alignItems="center">
				
					<Button
						onClick={() => {
							// if (isFilters) clearFilter()
							setFilters(!isFilters)
						}}
						variant={"outlined"}
						sx={{ px: 1, py: .5, borderRadius: "6px" }}
						size={"small"} endIcon={isFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownSharpIcon />}>
						Filter
					</Button>
			
					<Button
						variant={"contained"}
						sx={{ px: 3, py:  .5, borderRadius: "6px" }}
						size={"small"}
						onClick={() =>
							profile?.userType === "startup" || profile?.userType==="expert" || profile?.userType==="business-developer" ||profile?.userType==="service-provider" ||profile?.userType==="investor"
								? navigator(
										`/${profile.userType}/open-requests-post`
								  )
								: setOpen(true)
						}>
						Post a Request
					</Button>
					<div style={{ flex: 1 }} />
					<Button
						variant={"contained"}
						sx={{ px: 3, py:  .5, borderRadius: "6px" }}
						size={"small"}
						onClick={() =>
							navigator(`/${profile.userType}/my-requests`)
						}>
						My Requests
					</Button>
					{/*<Button variant={'contained'} color={'success'} sx={{textTransform:'Lowercase'}}><span style={{textTransform:"capitalize"}}>Post&nbsp;</span> a request</Button>*/}
					{/* <Tooltip
						title={<h3 style={{ color: '#E4EAF5' }}>Coming soon</h3>}
						placement="bottom" >*/}
					<>
					<Box onSubmit={(event) => event.preventDefault()}
						component="form"
						sx={{
							p: "2px 10px",
							display: "flex",
							alignItems: "center",
							width: 337,
							height: 40,
							borderRadius: "8px",
							backgroundColor: "#E4EAF5",
						}}>

						<IconButton
							type="button"
							sx={{ p: "10px" }}
							aria-label="search"
							onClick={(e) => setSearch(inputbtn)}
							disabled={true}
							>
							<Search sx={{ color: "#051E40BF" }} />
						</IconButton>
						<Autocomplete
						freeSolo
						disableClearable
						sx={{flex:1,"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
							border: 'none',
						  },
			  }}
						value={inputbtn}
						options={[...dataJson.searchQueries].map((option) => option)}
						onChange={(event, newValue) => {
						  setinputbtn(newValue);
						}}
						onInputChange={(event, value) => {
							if (event.type === 'change') {
								setinputbtn(value);
							}
						}}
						onKeyDown={(e)=>{
							if(e.code==="Enter"){
								setSearch(inputbtn)
							}
						}}
						renderInput={(params) => (
							<TextField
							{...params}
							// onChange={(e) =>{ setinputbtn(e.target.value); console.log(inputbtn)} }
							sx={{ flex: 1, color: "#051E40BF", input:{
								"&::placeholder":{
								  opacity:1
								}
							  }, }}
							placeholder='Search'
							
						/>
						)}
						/>
						{/* <InputBase
							onChange={(e) => setinputbtn(e.target.value)}
							value={inputbtn}
							onKeyDown={(e)=>{
								if(e.code==="Enter"){
									setSearch(inputbtn)
								}
							}}
							sx={{ ml: 1, flex: 1, color: "#051E40BF",input:{
								"&::placeholder":{
								  opacity:1
								}
							  }, }}
							placeholder="Search"
							inputProps={{ "aria-label": "search post", "aria-autocomplete":"both"}}
							// disabled={true}
						/> */}
					</Box>
					</>
				</Stack>
				
				
				<FilterPopup data={selected} />
				{isFilters && <Stack direction={"row"}
						spacing={1}
						m={0.2}
						justifyContent="flex-start"
						alignItems="center">
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle, whiteSpace:'nowrap',  color:userType.length > 0 ? "#FFF":"#8F8F8F"}} variant={userType.length > 0 ? "contained" : "outlined"}  
				onClick={(event)=>
					{
						setSelected({
							
							options: ['Industry Expert', 'Investor', 'Service provider', 'Growth Expert', 'Startup'],
							value: userType,
							
							cbValue: setUserType,
						})
						toggleAnchor(event)
					}}>
					User Type</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle, color:markets.length > 0 ? "#FFF":"#8F8F8F"}} variant={markets.length > 0 ? "contained" : "outlined"} onClick={(e)=>
				{
					setSelected({
						options: [...dataJson.markets],
						value: markets,
						cbValue: setMarkets,
					})
					toggleAnchor(e)
				}}>Market</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle, color:sectors.length > 0 ? "#FFF":"#8F8F8F"}} variant={sectors.length > 0 ? "contained" : "outlined"} onClick={(e)=>
				{
					setSelected({
						options: [...dataJson.sector],
						value: sectors,
						cbValue: setSectors
					})
					toggleAnchor(e)
				}}>Sector</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle,color:locations.length > 0 ? "#FFF":"#8F8F8F"}} variant={locations.length>0 ? "contained" : "outlined"} onClick={(e)=>
				{
					setSelected({
						options: [...dataJson.cities],
						value: locations,
						cbValue: setLocations
					})
					toggleAnchor(e)
				}}>Location</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle,color:experiences.length>0 ? "#FFF":"#8F8F8F"}} variant={experiences.length>0 ? "contained" : "outlined"} onClick={(e)=>
				{
					setSelected({
						options: [.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
						value: experiences,
						cbValue: setExperiences
					})
					toggleAnchor(e)
				}}>Experience</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle, color:languages.length>0  ? "#FFF":"#8F8F8F"}} variant={languages.length>0  ? "contained" : "outlined"} onClick={(e)=>
				{
					setSelected({
						options: [...dataJson.languages],
						value: languages,
						cbValue: setLanguages
					})
					toggleAnchor(e)
				}}>Languages</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle,color:jobTypes.length > 0 ? "#FFF":"#8F8F8F"}} variant={jobTypes.length > 0 ? "contained" : "outlined"} onClick={(e)=>
				{
					setSelected({
						title:'Job Type',
						options: ["part-time", "full-time", "contract"],
						value: jobTypes,
						cbValue: setJobTypes
					})
					toggleAnchor(e)
				}}>Job Type</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle, color:onsites.length > 0 ? "#FFF":"#8F8F8F", textOverflow:"ellipsis"}} variant={onsites.length > 0 ? "contained" : "outlined"} onClick={(e)=>
				{
					setSelected({
						title:'On-site/Remote',
						options: ["Onsite", "Hybrid", "Remote"],
						value: onsites,
						cbValue: setOnsites
					})
					toggleAnchor(e)
				}}>Onsite/Remote</Button>
				</Stack>
				}
				{/* {<FilterMenu data={selected} open={openDrawer} cbDrawer={setOpenDrawer} selectedFilters={selectedFilters.current}/>} */}
				
		  {selectedFilters.current.length>0 ? <div style={{ display: 'flex', width:"100%",gap: 8,marginBottom: 1, flexWrap:'wrap'}}>
          { 
		  <>
		  {userType.map((option)=>( <Chip key={option} 
		  sx={{...chipStyle,
			'&:hover':{textDecoration:'line-through',backgroundColor:'#2841fc' }}} 
			label={option} onClick={()=>{setUserType(userType.filter((chip)=>chip!==option))}}
		  /> 
		   )) }
		  {markets.map((option)=>( <Chip key={option} 
		  sx={{...chipStyle,
			'&:hover':{textDecoration:'line-through',backgroundColor:'#2841fc' }}} 
			label={option} onClick={()=>{setMarkets(markets.filter((chip)=>chip!==option))}}
		  /> 
		   )) }
		  {sectors.map((option)=>( <Chip key={option} 
		  sx={{...chipStyle,
			'&:hover':{textDecoration:'line-through',backgroundColor:'#2841fc' }}} 
			label={option} onClick={()=>{setSectors(sectors.filter((chip)=>chip!==option))}}
		  /> 
		   )) }
		  {locations.map((option)=>( <Chip key={option} 
		  sx={{...chipStyle,
			'&:hover':{textDecoration:'line-through',backgroundColor:'#2841fc' }}} 
			label={option} onClick={()=>{setLocations(locations.filter((chip)=>chip!==option))}}
		  /> 
		   )) }
		  {experiences.map((option)=>( <Chip key={option} 
		  sx={{...chipStyle,
			'&:hover':{textDecoration:'line-through',backgroundColor:'#2841fc' }}} 
			label={option} onClick={()=>{setExperiences(experiences.filter((chip)=>chip!==option))}}
		  /> 
		   )) }
		  {languages.map((option)=>( <Chip key={option} 
		  sx={{...chipStyle,
			'&:hover':{textDecoration:'line-through',backgroundColor:'#2841fc' }}} 
			label={option} onClick={()=>{setLanguages(languages.filter((chip)=>chip!==option))}}
		  /> 
		   )) }
		  {jobTypes.map((option)=>( <Chip key={option} 
		  sx={{...chipStyle,
			'&:hover':{textDecoration:'line-through',backgroundColor:'#2841fc' }}} 
			label={option} onClick={()=>{setJobTypes(jobTypes.filter((chip)=>chip!==option))}}
		  /> 
		   )) }
		  {onsites.map((option)=>( <Chip key={option} 
		  sx={{...chipStyle,
			'&:hover':{textDecoration:'line-through',backgroundColor:'#2841fc' }}} 
			label={option} onClick={()=>{setOnsites(onsites.filter((chip)=>chip!==option))}}
		  /> 
		   )) }
		  </>
		   }
		   
          
        </div> : <div style={{height:"35px"}}/>}
				{requests &&
					requests.length > 0 &&
					requests
					.filter((requests) => {
									if (search !== "") {
										const reg = new RegExp(search.replaceAll('\\', '').toLowerCase());
										// console.log(requests);
										return (reg.test(requests.searchFor?.toString().toLowerCase()) ||reg.test(requests.skills?.toString().toLowerCase()) || reg.test(requests.requestFor?.toLowerCase()))
										 ;
									}
									else {
										return requests;
									}
								}
								)
						.sort(
							(a, b) =>
								b.createdAt.seconds * 1000 -
								a.createdAt.seconds * 1000
						)
						.map((request) => (
							<OpenRequestListItem
								request={request}
								key={request.id}
								searchQuery={searchQuery}
							/>

						))}
				{loading && <Skeleton variant="rectangular" animation="pulse" width={'100%'} sx={{borderRadius: '12px'}} height={130} /> }

				{lastDoc &&
					<Stack direction={"row"} sx={{width: '100%'}} py={5}  justifyContent="center"
						   alignItems="center">

						<Button size={"small"} onClick={loadMore}>
							Load more
						</Button>
					</Stack>
				}
				<Box sx={{ height: 40 }}></Box>



				{/*<TableContainer  sx={{ boxShadow: 'none'}}>
          <Table sx={{ minWidth: 650 }} aria-label="Open requests and responses data table ">
            <TableHead>
              <TableRow sx={{textTransform: 'uppercase'}}>
                <TableCell>Requests</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Budget</TableCell>
                <TableCell align="center">Due date</TableCell>
                <TableCell align="center">Job type</TableCell>
                <TableCell align="center"> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{border: '1px solid #ddd'}}>



              {

                requests && requests.length > 0 && requests.sort((a, b) => b.createdAt.seconds *1000 - a.createdAt.seconds *1000 ).map((request, i) => (

                  <TableRow
                    key={i}
                    sx={{'&:last-child td, &:last-child th': { border: 0 }, backgroundColor: '#F6F8FA', cursor: 'pointer'}}
                  >
                    <TableCell component="th" scope="row" onClick={() => navigator(`/${profile.userType}/open-requests-details`, {state: {request}})}>
                      <Typography  variant={'body1'} color={'secondary'} noWrap={true} sx={{maxWidth: 500}}>
                        {request.requestFor}

                      </Typography>
                      <font color={'#8F8F8F'}>
                        {request.location}
                      </font>

                    </TableCell>
                    <TableCell align="center"  onClick={() => navigator(`/${profile.userType}/open-requests-details`, {state: {request}})} ><Badge variant={'dot'} invisible={false} color={(!request.stage || request.stage === 'activate')  ? 'primary' : request.stage === 'closed' ? "error" : 'warning'}/>&nbsp;&nbsp;&nbsp;{request.stage && request.stage === 'activate' ? 'Open' : !request.stage ? 'Open' : request.stage}</TableCell>

                    <TableCell align="center"  onClick={() => navigator(`/${profile.userType}/open-requests-details`, {state: {request}})}><font style={{textTransform:'uppercase'}}>{request.currency}</font> {request?.amount} {request.minBudget && `${request.minBudget} - ${request.maxBudget}`}</TableCell>
                    <TableCell align="center"  onClick={() => navigator(`/${profile.userType}/open-requests-details`, {state: {request}})}>{moment(request.fundingCloseDate || request.to).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="center"  onClick={() => navigator(`/${profile.userType}/open-requests-details`, {state: {request}})}>{request?.jobType}</TableCell>
                    <TableCell align="center">
                      {request.uid !== profile?.uid && <StartConversation data={request} key={request.id}/>}


                    </TableCell>
                  </TableRow>
                ))

              }
            </TableBody>
          </Table>
        </TableContainer>*/}
			</Stack>
	
			<Portal>
				<PostRequestDialog
					data={open}
					callback={(res) => {
						setOpen(res.close);
						if (
							res.selected &&
							res.selected !== "requestForFunding"
						) {
							navigator(
								`/${profile.userType}/open-requests-post`
							);
						} else if (
							res.selected &&
							res.selected === "requestForFunding"
						) {
							navigator(
								`/${profile.userType}/post-funding-request`
							);
						}
						// console.log("callback", res);
					}}
				/>
			</Portal>
		</Container>
	);
};
