import * as React from "react";
import {
  Autocomplete,
  Button,
  Chip,
  Container,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik } from "formik";
import RequestAssistanceValidation from "../../validations/common/request-assistance";
import CloseIcon from "@mui/icons-material/Close";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { auth, DB } from "../../config/firebase/firebase";
import { AuthUserContext } from "../../providers/auth-provider";
import { useNavigate, useParams } from "react-router-dom";
import dataJson from "../../const/data.json";
import { Add, HelpOutlineOutlined } from "@mui/icons-material";
import RequestMatchesProfiles from "../../api/request-matches-profiles";
import PostNotification from "../../api/post-notification";
import {
  DEFAULT_LANGUAGE,
  DEFAULT_MARKET,
  USERS_NAME_MAPPING,
} from "../../utils/enums/labels.enum";
import InfoIcon from "@mui/icons-material/Info";

export default function RequestAssistanceScreen() {
  const { requestId } = useParams();
  const { profile } = React.useContext(AuthUserContext);
  const [loading, setLoading] = React.useState(false);
  const navigator = useNavigate();
  const formik = useFormik({
    initialValues: RequestAssistanceValidation.requestAssistanceInitialValues,
    validationSchema: RequestAssistanceValidation.requestAssistanceSchema,
    onSubmit: async (values) => {
      debugger
      setLoading(true);
      
      console.log(JSON.stringify(values));
      const un = /-/;

      const requestObj = await lookingForFundingRequest({
        ...values,
        type: "request-for-assistance",
        creatorData: profile,
      });
      
      const document = await getDoc(doc(DB, "help-requests", requestId ? requestId : requestObj?.id));
      console.log("document", document.data());
      sendNotificationOfProfileMatches({
        id: document.id,
        ...document.data(),
      });
      setLoading(false);
      navigator(`/${profile?.userType}/open-requests`);
    },
  });
  const lookingForFundingRequest = (request) => {
    console.log(request.searchFor);
    request.searchFor = request.searchFor.map(
      (data) =>
        (data =
          data === "Industry Expert"
            ? "Expert"
            : data === "Growth Expert"
            ? "Business Developer"
            : data)
    );
    let free_search = [
      ...new Set([
        request?.jobType,
        request?.location,
        request?.typeOfJob,
        ...request?.searchFor,
        ...request?.skills,
        ...request?.languages,
        ...request?.sector,
        ...request?.market,
        request?.experience,
      ]),
    ];
    free_search.forEach(
      (d, i) => (d === undefined || d === "") && free_search.splice(i, 1)
    );
    if (requestId) {
      const requestRef = doc(DB, "help-requests", requestId);
      return setDoc(
        requestRef,
        { ...request, free_search, updatedAt: serverTimestamp() },
        { merge: true }
      );
    } else {
      const savedRequest = addDoc(collection(DB, "help-requests"), {
        ...request,
        free_search,
        createdAt: serverTimestamp(),
        uid: profile?.uid,
      });

      return savedRequest;
    }
  };
  const sendNotificationOfProfileMatches = async (request) => {
    const matches = await RequestMatchesProfiles(request);
    debugger
    if (matches && matches.length > 0) {
      const length = matches.length;
      const name = matches[0].displayName;
      debugger
      PostNotification({
        type: "request-profile-matches",
        imageURL: profile?.photoURL ? profile?.photoURL : "",
        message: `we've identified the best matches for you based on your preference and requirements:`,
        matches: matches,
        uid: profile?.uid,
        name: profile?.displayName,
      }).then();
    }
  };
  function commaAmount(amount) {
    let result = "";
    let counter = 0;
    for (let i = amount.length - 1; i >= 0; i -= 1) {
      if (counter != 0 && counter % 3 === 0) {
        result = `,${result}`;
      }
      counter += 1;
      result = `${amount[i]}${result}`;
    }
    return result;
  }
  React.useEffect(() => {
    if (requestId) {
      const reqRef = doc(DB, "help-requests", requestId);
      getDoc(reqRef).then((doc) => {
        const request = doc.data();
        request.searchFor = request.searchFor.map(
          (data) => USERS_NAME_MAPPING[data]
        );
        if (!request.hasOwnProperty("languages")) {
          request["languages"] = [DEFAULT_LANGUAGE];
        }
        if (!request.hasOwnProperty("market")) {
          request["market"] = [DEFAULT_MARKET];
        }
        if (!request.hasOwnProperty("sector")) {
          request["sector"] = [];
        }
        if (!request.hasOwnProperty("experience")) {
          request["experience"] = 0;
        }
        formik.setValues({
          ...request,
        });
      });
    }
  }, []);
  /*let free_search =[...new Set([data?.jobType, data?.location, typeOfJob, ...searchFor, ...skills, ...languages ])]
  free_search.forEach((d, i) => (d === undefined || d === "") && free_search.splice(i, 1))
  */
  return (
    <Container maxWidth={"md"} sx={{ py: 5 }}>
      <Stack
        direction={"column"}
        spacing={4}
        sx={{ width: "100%" }}
        component="form"
        onSubmit={(e) => formik.handleSubmit(e)}
      >
        <Typography
          variant={"h4"}
          sx={{ textAlign: "center" }}
          fontWeight={600}
        >
          Request For Assistance
        </Typography>

        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Typography variant={"h6"} textAlign={"left"}>
            I am looking for help with *{" "}
            <font color={"#A9ABAE"}>(e.g. setting up a company)</font>
          </Typography>
          <TextField
            placeholder={"Type here..."}
            name={"requestFor"}
            value={formik.values.requestFor}
            onChange={formik.handleChange}
            error={
              formik.touched.requestFor && Boolean(formik.errors.requestFor)
            }
            helperText={formik.touched.requestFor && formik.errors.requestFor}
          />
        </Stack>
        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Typography variant={"h6"} textAlign={"left"}>
            Searching for *{" "}
            <font color={"#A9ABAE"}>
              ( eg: Industry Expert, Growth Expert, etc)
            </font>
          </Typography>

          <Autocomplete
            multiple
            required
            options={[
              "Industry Expert",
              "Investor",
              "Service provider",
              "Growth Expert",
            ].map((option) => option)}
            onChange={(e, newValue) => {
              formik.setFieldValue("searchFor", newValue);
            }}
            name={"searchFor"}
            value={formik.values.searchFor}
            // error={formik.touched.searchFor && Boolean(formik.errors.searchFor)}
            // helperText={formik.touched.searchFor && formik.errors.searchFor}
            renderTags={(value, getTagProps) =>
              formik.values.searchFor.map((option, index) => (
                <Chip
                  variant="contained"
                  sx={{
                    backgroundColor: "#B8FC3C",
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginBottom: "10px",
                  }}
                  deleteIcon={<CloseIcon />}
                  onDelete={() =>
                    formik.setFieldValue(
                      "searchFor",
                      formik.values.searchFor.filter((f) => f !== option)
                    )
                  }
                  label={option}
                  {...getTagProps({ index })}
                ></Chip>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                readOnly={true}
                // variant={'standard'}
                label="Search For"
                error={Boolean(formik.errors.searchFor)}
                helperText={
                  formik.errors.searchFor
                    ? "Search for is a required field"
                    : ""
                }
                placeholder="Add"
                sx={{
                  transition: "0.1s linear",
                }}
              />
            )}
            disableTextInput={true}
          />
        </Stack>
        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Typography variant={"h6"} textAlign={"left"}>
            Skills & Expertise
            <font color={"#A9ABAE"}>
              ( e.g. Revenue Growth, Pricing, Law firm etc.)
            </font>
          </Typography>

          <Autocomplete
            multiple
            required
            freeSolo
            options={[
              "Graphic Designer",
              "Full-Stack Developer",
              "Hr",
              "Content Creator",
              "SEO",
              "Digital Marketing",
              "Strategy",
              "ROI",
            ].map((option) => option)}
            onChange={(e, newValue) => {
              formik.setFieldValue("skills", newValue);
            }}
            name={"skills"}
            value={formik.values.skills}
            renderTags={(value, getTagProps) =>
              formik.values.skills.map((option, index) => (
                <Chip
                  variant="contained"
                  sx={{
                    backgroundColor: "#5CE5D5",
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginBottom: "10px",
                  }}
                  deleteIcon={<CloseIcon />}
                  onDelete={() =>
                    formik.setFieldValue(
                      "skills",
                      formik.values.skills.filter((f) => f !== option)
                    )
                  }
                  label={option}
                  {...getTagProps({ index })}
                ></Chip>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                // variant={'standard'}
                // label="Skills"
                error={Boolean(formik.errors.skills)}
                helperText={
                  formik.errors.skills ? "Skills is a required field" : ""
                }
                placeholder="Enter keywords or select from dropdown"
                sx={{
                  transition: "0.1s linear",
                }}
              />
            )}
          />
        </Stack>
        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Typography variant={"h6"} textAlign={"left"}>
            Location *
          </Typography>
          <Autocomplete
            options={[...dataJson.cities].map((option) => option)}
            freeSolo
            onChange={(e, newValue) => {
              formik.setFieldValue("location", newValue);
            }}
            name={"location"}
            value={formik.values.location}
            renderTags={(value, getTagProps) =>
              formik.values.location.map((option, index) => (
                <Chip
                  variant="contained"
                  sx={{
                    backgroundColor: "#E3E8F0",
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginBottom: "10px",
                  }}
                  deleteIcon={<CloseIcon />}
                  onDelete={() =>
                    formik.setFieldValue(
                      "location",
                      formik.values.location.filter((f) => f !== option)
                    )
                  }
                  label={option}
                  {...getTagProps({ index })}
                ></Chip>
              ))
            }
            renderInput={(params) => (
              <TextField
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
                required={
                  formik.values.location && formik.values.location.length === 0
                }
                {...params}
                // variant={'standard'}

                placeholder="Location"
                sx={{
                  transition: "0.1s linear",
                }}
              />
            )}
          />
          {/* <Typography variant={"body1"} textAlign={'left'}>
              Location
            </Typography>
            <TextField fullWidth  name={'location'}
                       value={formik.values.location}
                       onChange={formik.handleChange}
                       error={formik.touched.location && Boolean(formik.errors.location)}
                       helperText={formik.touched.location && formik.errors.location}/> */}
        </Stack>
        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Typography variant={"h6"} textAlign={"left"}>
            Sector*
          </Typography>
          <Autocomplete
            multiple
            fullWidth
            options={[
              "Adtech",
              "Advanced manufacturing",
              "Agtech",
              "Artificial intelligence and machine learning (AI/ML)",
              "Audiotech",
              "Augmented reality (AR)",
              "Autonomous cars",
              "B2B payments",
              "Beauty",
              "Big Data",
              "Cannabis",
              "Carsharing",
              "Cleantech",
              "Climate tech",
              "Cloudtech and DevOps",
              "Construction technology",
              "Cryptocurrency and blockchain",
              "Cybersecurity",
              "Digital health",
              "Ecommerce",
              "Edtech",
              "Ephemeral content",
              "eSports",
              "Femtech",
              "Fintech",
              "Foodtech",
              "Gaming",
              "Healthtech",
              "HRtech",
              "Impact investing",
              "Industrials",
              "Infrastructure",
              "Insurtech",
              "Internet of Things (IoT)",
              "Legal tech",
              "Life sciences",
              "Lifestyles of Health and Sustainability (LOHAS) and wellness",
              "Manufacturing",
              "Marketing tech",
              "Micro-mobility",
              "Mobile",
              "Mobile commerce",
              "Mobility tech",
              "Mortgage tech",
              "Nanotechnology",
              "Oil and gas",
              "Oncology",
              "Pet tech",
              "Real estate tech",
              "Restaurant tech",
              "Ridesharing",
              "Robotics and drones",
              "Software as a service (SaaS)",
              "Space tech",
              "Supply chain technology",
              "Technology, media and telecommunications (TMT)",
              "Virtual reality (VR)",
              "Wearables and quantified self",
              "3D printing",
            ].map((option) => option)}
            value={formik.values?.sector}
            freeSolo
            renderOption={(props, option) => (
              <li {...props} style={{ alignItems: "center" }}>
                {option.includes("Add ") ? (
                  <>
                    <Add fontSize="medium" />
                    {option}
                  </>
                ) : (
                  option
                )}
              </li>
            )}
            onChange={(e, newValue) => {
              if (newValue.length !== 0) {
                const newOption = newValue.slice(-1)[0].replace("Add ", "");
                newValue.splice(-1, 1, newOption);
              }

              formik.setFieldValue("sector", newValue);
            }}
            renderTags={(value, getTagProps) =>
              formik.values?.sector &&
              formik.values?.sector.length > 0 &&
              formik.values?.sector.map((option, index) => (
                <Chip
                  variant="contained"
                  sx={{
                    backgroundColor: "#E3E8F0",
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginBottom: "10px",
                  }}
                  deleteIcon={<CloseIcon />}
                  onDelete={() =>
                    formik.setFieldValue(
                      "sector",
                      formik.values?.sector.filter((f) =>
                        f !== option.value ? option.value : option
                      )
                    )
                  }
                  label={option.value ? option.value : option}
                  {...getTagProps({
                    index,
                  })}
                ></Chip>
              ))
            }
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  // variant={'standard'}

                  fullWidth
                  // required={formikFirst.values.sector.length === 0 }
                  error={formik.touched.sector && Boolean(formik.errors.sector)}
                  helperText={formik.touched.sector && formik.errors.sector}
                  //label="Sector *"
                  placeholder="Type here..."
                  sx={{
                    transition: "0.1s linear",
                  }}
                />
              </>
            )}
          />
        </Stack>
        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Typography variant={"h6"} textAlign={"left"}>
            Targeting Market*
          </Typography>
          <Autocomplete
            multiple
            required
            fullWidth
            options={[...dataJson.markets].map((option) => option)}
            freeSolo
            onChange={(e, newValue) => formik.setFieldValue("market", newValue)}
            value={formik.values?.market}
            renderTags={(value, getTagProps) =>
              formik.values?.market &&
              formik.values?.market.length > 0 &&
              formik.values?.market.map((option, index) => (
                <Chip
                  variant="contained"
                  sx={{
                    backgroundColor: "#E3E8F0",
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginBottom: "10px",
                    borderRadius: "10px",
                  }}
                  deleteIcon={<CloseIcon />}
                  onDelete={() =>
                    formik.setFieldValue(
                      "market",
                      formik.values?.market.filter((f) => f !== option)
                    )
                  }
                  label={option}
                  {...getTagProps({
                    index,
                  })}
                ></Chip>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                // variant={'standard'}
                error={Boolean(formik.errors.market)}
                helperText={
                  formik.errors.market ? "Market is a required field" : ""
                }
                placeholder="Type here..."
                sx={{
                  transition: "0.1s linear",
                }}
              />
            )}
          />
        </Stack>

        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Typography variant={"h6"} textAlign={"left"}>
            Languages*
          </Typography>
          <Autocomplete
            multiple
            // required
            fullWidth
            options={[...dataJson.languages].map((option) => option)}
            onChange={(e, newValue) =>
              formik.setFieldValue("languages", newValue)
            }
            value={formik.values?.languages}
            renderTags={(value, getTagProps) =>
              formik.values?.languages &&
              formik.values?.languages?.length > 0 &&
              formik.values?.languages?.map((option, index) => (
                <Chip
                  variant="contained"
                  sx={{
                    backgroundColor: "#E3E8F0",
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginBottom: "10px",
                  }}
                  deleteIcon={<CloseIcon />}
                  onDelete={() =>
                    formik.setFieldValue(
                      "languages",
                      formik.values?.languages?.filter((f) => f !== option)
                    )
                  }
                  label={option}
                  {...getTagProps({
                    index,
                  })}
                ></Chip>
              ))
            }
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  error={Boolean(formik.errors.languages)}
                  helperText={
                    formik.errors.languages ? "Language is required" : ""
                  }
                  sx={{
                    transition: "0.1s linear",
                  }}
                />
              </>
            )}
          />
        </Stack>
        <Stack direction="column" spacing="1">
          <Typography variant={"h6"} textAlign="start">
            Relevant Experience
          </Typography>
          <TextField
            name={"experience"}
            value={formik.values.experience}
            onChange={formik.handleChange}
            error={
              formik.touched.experience && Boolean(formik.errors.experience)
            }
            fullWidth
            helperText={formik.touched.experience && formik.errors.experience}
            //label={"Relevant Years of Experience *"}
            sx={{ textAlign: "left" }}
            type={"number"}
          />
        </Stack>

        <Stack direction={"row"} spacing={3}>
          <Stack direction={"column"} spacing={1} sx={{ flex: 1 }}>
            <Typography
              variant={"h6"}
              textAlign={"left"}
              style={{ marginRight: "40px" }}
            >
              Job type*
            </Typography>
            <Stack direction={"row"} spacing={3}>
              <TextField
                select
                sx={{
                  width: 1,
                  textAlign: "left",
                  textTransform: "capitalize",
                }}
                name={"typeOfJob"}
                value={formik.values.typeOfJob}
                onChange={formik.handleChange}
                error={
                  formik.touched.jobType && Boolean(formik.errors.typeOfJob)
                }
                helperText={formik.touched.typeOfJob && formik.errors.typeOfJob}
              >
                <MenuItem
                  value={"part-time"}
                  sx={{ textTransform: "capitalize" }}
                >
                  part-time
                </MenuItem>
                <MenuItem
                  value={"full-time"}
                  sx={{ textTransform: "capitalize" }}
                >
                  full-time
                </MenuItem>
                <MenuItem
                  value={"contract"}
                  sx={{ textTransform: "capitalize" }}
                >
                  contract
                </MenuItem>
              </TextField>

              <TextField
                select
                sx={{ width: 1, textAlign: "left" }}
                name={"jobType"}
                value={formik.values.jobType}
                onChange={formik.handleChange}
                error={formik.touched.jobType && Boolean(formik.errors.jobType)}
                helperText={formik.touched.jobType && formik.errors.jobType}
                placeholder={"On-site/remote"}
              >
                <MenuItem value={"Onsite"}>Onsite</MenuItem>
                <MenuItem value={"Remote"}>Remote</MenuItem>
                <MenuItem value={"Hybrid"}>Hybrid</MenuItem>
              </TextField>
            </Stack>
          </Stack>
          <Stack direction={"column"} spacing={1} sx={{ flex: 1 }}>
            <Typography variant={"h6"} textAlign={"left"}>
              Timeline
            </Typography>
            <Stack direction={"row"} spacing={3}>
              <TextField
                fullWidth
                type={"date"}
                InputProps={{
                  inputProps: { min: "1900-01-00", max: "2150-12-31" },
                }}
                label={"From"}
                InputLabelProps={{ shrink: true }}
                name={"from"}
                value={formik.values.from}
                onChange={formik.handleChange}
                error={formik.touched.from && Boolean(formik.errors.from)}
                helperText={formik.touched.from && formik.errors.from}
              />
              <TextField
                fullWidth
                InputProps={{
                  inputProps: { min: "1900-01-00", max: "2150-12-31" },
                }}
                type={"date"}
                label={"To"}
                InputLabelProps={{ shrink: true }}
                name={"to"}
                value={formik.values.to}
                onChange={formik.handleChange}
                error={formik.touched.to && Boolean(formik.errors.to)}
                helperText={formik.touched.to && formik.errors.to}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={3}>
          <Stack direction={"column"} spacing={1} sx={{ flex: 1 }}>
            <Typography variant={"h6"} textAlign={"left"}>
              My budget ranges from*
            </Typography>
            <Stack direction={"row"} spacing={3}>
              <TextField
                placeholder={"$"}
                select
                sx={{ width: 0.5, textAlign: "left" }}
                name={"currency"}
                value={formik.values.currency}
                onChange={formik.handleChange}
                error={
                  formik.touched.currency && Boolean(formik.errors.currency)
                }
                helperText={formik.touched.currency && formik.errors.currency}
              >
                <MenuItem value={"USD"}>USD</MenuItem>
                <MenuItem value={"GBP"}>GBP</MenuItem>
                <MenuItem value={"EUR"}>EUR</MenuItem>
              </TextField>
              <TextField
                placeholder={"Min"}
                onChange={(e) => {
                  let tar = e.target.value;
                  let result = "";
                  for (let i = tar.length - 1; i >= 0; i -= 1) {
                    if (tar[i] != ",") {
                      result = `${tar[i]}${result}`;
                    }
                  }
                  if (tar.length == 0) {
                    e.target.value = "";
                  } else {
                    e.target.value = parseInt(result);
                  }

                  formik.handleChange(e);
                }}
                name={"minBudget"}
                value={formik.values.minBudget}
                value={commaAmount(formik.values.minBudget.toString())}
                error={
                  formik.touched.minBudget && Boolean(formik.errors.minBudget)
                }
                helperText={formik.touched.minBudget && formik.errors.minBudget}
                sx={{ width: 1 }}
              />
              <TextField
                placeholder={"Max"}
                onChange={(e) => {
                  let tar = e.target.value;
                  let result = "";
                  for (let i = tar.length - 1; i >= 0; i -= 1) {
                    if (tar[i] != ",") {
                      result = `${tar[i]}${result}`;
                    }
                  }
                  if (tar.length == 0) {
                    e.target.value = "";
                  } else {
                    e.target.value = parseInt(result);
                  }

                  formik.handleChange(e);
                }}
                value={commaAmount(formik.values.maxBudget.toString())}
                name={"maxBudget"}
                sx={{ width: 1 }}
                error={
                  formik.touched.maxBudget && Boolean(formik.errors.maxBudget)
                }
                helperText={formik.touched.maxBudget && formik.errors.maxBudget}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Typography variant={"h6"} textAlign={"left"}>
            Additional information
          </Typography>
          <TextField
            placeholder={"Type here..."}
            multiline={true}
            rows={3}
            name={"description"}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </Stack>
        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Typography variant={"h6"} textAlign={"left"}>
            Application Due Date*
          </Typography>
          <TextField
            fullWidth
            type={"date"}
            InputProps={{
              inputProps: { min: "1900-01-00", max: "2150-12-31" },
            }}
            name={"deadline"}
            value={formik.values.deadline}
            onChange={formik.handleChange}
            error={formik.touched.deadline && Boolean(formik.errors.deadline)}
            helperText={formik.touched.deadline && formik.errors.deadline}
          />
        </Stack>

        {!requestId ? (
          <Stack
            direction={"row"}
            spacing={4}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Button
              sx={{ px: 4, border: "1px solid #204488", borderRadius: "53px" }}
              onClick={() => navigator(-1)}
            >
              Back
            </Button>
            <Button
              variant={"contained"}
              sx={{ px: 4 }}
              type={"submit"}
              endIcon={
                loading ? (
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                ) : (
                  ""
                )
              }
            >
              Submit
            </Button>
          </Stack>
        ) : (
          <Stack
            direction={"row"}
            spacing={3}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Button variant={"outlined"} onClick={() => navigator(-1)}>
              Back
            </Button>
            <Button
              variant={"contained"}
              sx={{ px: 2 }}
              type={"submit"}
              endIcon={
                loading && <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
            >
              Save & update
            </Button>
          </Stack>
        )}
      </Stack>
    </Container>
  );
}
