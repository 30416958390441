import React from "react";
import {
	Container,
	Typography,
	Stack,
	Box,
	Avatar,
	Badge,
} from "@mui/material";
import { AuthUserContext } from "../../../providers/auth-provider";
import {
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	setDoc,
	where,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import moment from "moment";
import NotificationItem from "../../../screens/mobile/components/common/notification-item";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";


export default function NotificationScreen() {
	const { profile } = React.useContext(AuthUserContext);
	const [notifications, setNotifications] = React.useState([]);
	const [searchQuery, setSearchQuery] = React.useState("");
	const navigator = useNavigate();

	React.useEffect(() => {
		const q = query(
			collection(DB, "notifications"),
			orderBy("createdAt", "desc"),
			where("uid", "==", profile?.uid)
		);
		const unSubs = onSnapshot(q, async (querySnap) => {
			if (querySnap.size > 0) {
				const list = [];
				querySnap.forEach((docc) => {
					list.push({ id: docc.id, ...docc.data() });
				});
				setNotifications(list);
			}
		});
		return () => unSubs;
	}, []);
	return (
		<Container maxWidth={"xl"} sx={{ my: 5 }}>
			<Stack direction={"column"} spacing={1}>
				<Stack
					direction={"row"}
					justifyContent="flex-start"
					alignItems="center"
					spacing={5}>
					<Typography
						variant={"h5"}
						style={{ fontWeight: 500, flex: 1 }}>
						  <IconButton  onClick={() => navigator(-1)}>
            <ArrowBackIcon sx={{ fontSize: 35, color: '#000' }} />
          </IconButton>
						Notifications
					</Typography>
				
				</Stack>
				<Stack
					direction={"row"}
					justifyContent="flex-start"
					alignItems="center"
					spacing={5}>
					
					<Box
					onSubmit={(e)=> e.preventDefault()}
						component="form"
						sx={{
							p: "5px 10px",
							display: "flex",
							alignItems: "center",
							width: "100%",
							
							borderRadius: "12px",
							backgroundColor: "#E4EAF5",
							mt: 2,
						}}>
						<IconButton type="button" aria-label="search">
							<SearchIcon sx={{ color: "#051E40BF" }} />
						</IconButton>
						<InputBase
							value={searchQuery}
							onChange={(e) => {
								setSearchQuery(e.target.value);
							}}
							sx={{ ml: 1, flex: 1 ,color:'#051E40BF', input:{
								"&::placeholder":{
								  opacity:1
								}
								} }}
							placeholder="Search"
							inputProps={{ "aria-label": "search post" }}
						/>
					</Box>
				</Stack>
				<Stack
					direction={"column"}
					spacing={3}
					sx={{
						minHeight: "75vh",
						width: "100%",
						backgroundColor: "#fff",
						borderRadius: "15px",
						paddingRight:1,
						paddingLeft:1,
						paddingTop:2,
						
					}}>
					<Box />
					{/*
          password update
          */}
		  {notifications.length > 0 ? 
			<Typography  variant={"h5"}>All</Typography>
:<></>}
          {notifications.length > 0 ? (
            notifications.map((notification, i) => {
              return notification.message
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ? (
					<>
					
					<NotificationItem notification={notification} key={i} />
					</>
               
              ) : null;
            })
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    alignItems: "center",
                    borderRadius: "12px",
                    border: "1px solid #233DFF",
                    padding: "25px",
					marginTop:"15%"
                  }}
                >
                  <Typography variant="body1" style={{ color: "#233DFF" }}>
				
                    You're all caught up! There are no new notifications to show at the moment. Check back later to see if there's  anything new.
                  </Typography>
               
                
                </Box>
              </Stack>
            </>
          )}
				</Stack>
			</Stack>
		</Container>
	);
}
