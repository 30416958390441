import React from "react";
import {
  Container,
  Typography,
  Stack,
  Box,
  Avatar,
  Badge,
  LinearProgress, Divider
} from "@mui/material";
import { AuthUserContext } from "../../providers/auth-provider";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import moment from "moment";
import NotificationItem from "../../components/common/notification-item";

export default function NotificationScreen() {
  const { profile } = React.useContext(AuthUserContext);
  const [notifications, setNotifications] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    const q = query(
      collection(DB, "notifications"),
      orderBy("createdAt", "desc"),
      where("uid", "==", profile?.uid)
    );
    const unSubs = onSnapshot(q, async (querySnap) => {
      if (querySnap.size > 0) {
        const list = [];
        querySnap.forEach((docc) => {
          list.push({ id: docc.id, ...docc.data() });
        });
        setNotifications(list);
        setLoading(false)
      }
      if(notifications?.length===0){
        setLoading(false);
      }
    });
    return () => unSubs;
  }, []);
  
  if(loading) return(
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  )
  
  return (
    <Container maxWidth={"xl"} sx={{ my: 5, textAlign: "left" }}>
      <Stack direction={"column"} spacing={5}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={5}
        >
          <Typography variant={"h4"} style={{ fontWeight: 600, flex: 1 }}>
            Notifications
          </Typography>
          <Box
            onSubmit={(e)=> e.preventDefault()}
            component="form"
            sx={{
              p: "5px 10px",
              display: "flex",
              alignItems: "center",
              width: 337,
              
              borderRadius: "12px",
              backgroundColor: "#E4EAF5",
              mt: 2,
            }}
          >
            <IconButton type="button" aria-label="search">
              <SearchIcon sx={{ color: "#051E40BF" }} />
            </IconButton>
            <InputBase
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              sx={{ ml: 1, flex: 1 ,color:'#051E40BF', input:{
                  "&::placeholder":{
                    opacity:1
                  }
                } }}
              placeholder="Search"
              inputProps={{ "aria-label": "search post" }}
            />
          </Box>
        </Stack>
        
        <Stack
          direction={"column"}
          spacing={3}
          sx={{
            minHeight: "75vh",
            width: "100%",
            backgroundColor: "#fff",
            borderRadius: "15px",
            boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
            px: 4,
          }}
        >
          <Box />
          {/*
          password update
          */}
          
          {notifications.length > 0 ?
          <>
            <Typography variant={"h5"}>All</Typography>
            <Divider variant="fullWidth" /></>
            :<></>}
          
          {!(notifications && notifications?.length>0) ?
            <>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    alignItems: "center",
                    borderRadius: "12px",
                    border: "1px solid #233DFF",
                    padding: "30px",
                  }}
                >
                  <Typography variant="h5" style={{ color: "#233DFF" }}>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    
                    You're all caught up! There are no <br></br>
                  </Typography>
                  <Typography variant="h5" style={{ color: "#233DFF" }}>
                    new notifications to show at the moment. <br></br>
                  </Typography>
                  <Typography variant="h5" style={{ color: "#233DFF" }}>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    Check back later to see if there's <br></br>
                  </Typography>
                  <Typography variant="h5" style={{ color: "#233DFF" }}>
                    &nbsp;
                    &nbsp;
                    &nbsp; &nbsp;
                    &nbsp;
                    &nbsp; &nbsp;
                    &nbsp;
                    &nbsp; &nbsp;
                    &nbsp;
                    &nbsp; &nbsp;
                    
                    anything new.
                  </Typography>
                </Box>
              </Stack>
            </>: (
              notifications.map((notification, i) => {
                return notification.message
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ? (
                  <>
                    
                    <NotificationItem notification={notification} key={i} />
                  </>
                
                ) : null;
              })
            )}
        </Stack>
      </Stack>
    </Container>
  );
}
