import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { DB } from "../../../../config/firebase/firebase";
import {
  Button,
  Stack,
  Box,
  InputBase,
  IconButton,
  Skeleton,
} from "@mui/material";
import moment from "moment";
import CommentLikeMenu from "./../comment/comment-like-menu";
import CommentsLikesCountCompnent from "./../comment/comments-likes-count-compnent";
import ReplyLikeMenu from "./reply-like-menu";
import ReplyLikesCountCompnent from "./reply-likes-count-compnent";
import { AuthUserContext } from "../../../../providers/auth-provider";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PostNotification from "../../../../api/post-notification";
import { stringAvatar, stringToColor } from "../../../../utils/avatarGenerator";
import LinkWithThumbnail from "../../link-embed-thumbnail";
import ItemText from "../item-text";
import { useNavigate } from 'react-router-dom'

export default function CommentReplyItem({
  reply,
  timelineId,
  commentId,
  postuid,
  count, mobile
}) {
  const [user, setUser] = React.useState();
  const [show, setShow] = React.useState(false);
  const [text, setText] = React.useState("");
  const { profile } = React.useContext(AuthUserContext);
  const [likesCount, setLikesCount] = React.useState();
const navigator = useNavigate()


  const postComment = async () => {
    if (text !== "") {
      const addRef = collection(
        DB,
        "timelines",
        timelineId,
        "comments",
        commentId,
        "replies"
      );
      const add = await addDoc(addRef, {
        replyTo: {
          displayName: user?.displayName,
          uid: user?.uid,
          replyId: reply?.id,
        },
        uid: profile?.uid,
        text,
        createdAt: serverTimestamp(),
      });
      console.log("add comment", add?.id);
      const q = query(
        collection(DB, "notifications"),
        where("uid", "==", postuid),
        where("type", "==", "post-comment-notification"),
        where("postId", "==", timelineId),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      const notifications = await getDocs(q);
      if (profile?.uid !== user?.uid) {
        if (notifications.size > 0) {
          await updateDoc(doc(DB, "notifications", notifications.docs[0].id), {
            displayName:
              `
              
              ${count === 0 ? `${profile?.displayName} replied to ${user?.displayName}’s`
                : count > 1 ? `${profile?.displayName} replied to ${user?.displayName}’s, and ${count} others.` :
                  `${profile?.displayName} replied to ${user?.displayName}’s, and ${count} other.`}`,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            imageURL: profile?.photoURL,
            read: false,
          });
        } else {
          PostNotification({
            type: "post-comment-notification",
            imageURL: profile?.photoURL,
            message: "",
            displayName: `${profile?.displayName} replied to ${user?.displayName}’s `,
            uid: postuid,
            postId: timelineId,
          }).then();
        }
      }
      setText("");
      setShow(!show);
    }
  };
  React.useEffect(() => {
    const userRef = doc(DB, "users", reply?.uid);
    getDoc(userRef).then((doc) =>
      setUser({
        id: doc?.id,
        ...doc?.data(),
      })
    );
  }, [reply?.uid]);
  if(user){
  return (
    <ListItem alignItems="flex-start" sx={{ px: 1, py: 1 }}>
      <ListItemAvatar>
        {user?.displayName ? (
          <Avatar
            sx={{ bgcolor: user?.photoURL ? 'transparent' : stringToColor(user?.displayName) }}
            src={user?.photoURL}
            {...stringAvatar(user?.displayName)}
          />
        ) : (
          <Avatar src={user?.photoURL} />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          <React.Fragment>
            {user?.displayName ? (
              <Typography fontSize={mobile ? 14: 16} fontWeight={500} color="#051E40">
                {user?.displayName}
              </Typography>
            ) : (
              <Skeleton variant="text" sx={{ width: "10%" }} />
            )}
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <Stack direction={"column"} spacing={mobile ? 1 :2}>
              <Typography
                sx={{
                  display: "block",
                  marginTop: "10px",
                  borderRadius: "5px",
                  p: "10px",
                  backgroundColor: "#e8eef991",
                }}
                variant="body2"
                color="#333333"
              >
                {reply?.replyTo && (
                  <Button variant={"text"} size={"small"} color={"secondary"} onClick={() => {navigator(`/${profile.userType}/${user.userType}-details/${reply.replyTo.uid}`)}}>
                    <Typography
                      variant={"caption"}
                      fontWeight={600}
                      sx={{ textDecoration: "underline" }}
                    >
                      {reply?.replyTo?.displayName}
                    </Typography>
                  </Button>
                )}
                <ItemText limit={150} text={reply?.text} />
              </Typography>
              <Stack sx={{ width:  mobile?"100%" : "55%" }}>
                <LinkWithThumbnail data={reply} limit={100} small />
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Stack width={mobile && "20%"}>
                <ReplyLikeMenu
                  mobile={mobile}
                  data={reply}
                  timelineId={timelineId}
                  commentId={commentId}
                  postuid={postuid}
                  replyuser={{ displayName: user?.displayName, uid: user?.uid }}
                  likesCount={likesCount}
                  />
                </Stack>
                
                {/* <CommentLikeMenu data={reply} timelineId={timelineId} />
                <CommentsLikesCountCompnent data={reply} timelineId={timelineId} />*/}
                {/*<Button variant={"text"} size={"small"} color={"secondary"} sx={{color: '#8F8F8F'}} onClick={() => setShow(!show)}>Reply</Button>*/}
                {/*<Button variant={"text"} size={"small"} sx={{color:'#8F8F8F'}}>Like</Button>*/}
                <Button
                  variant={"text"}
                  size={"small"}
                  sx={{padding:0,color: '#8F8F8F', fontSize:mobile && 12 }}
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  Reply
                </Button>
                <Typography variant={"body2"} sx={{width:"15%"}} fontSize={mobile && 12}>
                  {reply?.createdAt?.seconds &&
                    moment(reply?.createdAt?.seconds * 1000).fromNow(true)}{" "}
                  {/*{ moment(new Date(reply?.seconds * 1000)).max(new Date())}*/}
                </Typography>
                {mobile && <Box flex={1}/>}
                <ReplyLikesCountCompnent
                  data={reply}
                  timelineId={timelineId}
                  commentId={commentId}
                  cb={(data) => setLikesCount(data)}
                />
              </Stack>
              {show && (
                <Box
                  sx={{
                    p: mobile ? "20px 10px" : "30px 10px",
                    display: "flex",
                    alignItems: "center",
                    width: '100%',
                    height: mobile ? 20 : 25,
                    borderRadius: "30px",
                    border: ".5px solid #8F8F8F",
                  }}
                >
                  <Typography variant={"caption"} fontWeight={600}>
                    {user?.displayName}
                  </Typography>
                  <InputBase
                    sx={{
                      ml: 1,
                      flex: 1,
                      color: "#000000",
                      fontSize:mobile ?12 : 14,
                      input: {
                        "&::placeholder": {
                          opacity: 1,
                          color:"#8F8F8F",
                          fontSize:mobile ?12 : 14,
                        },
                      },
                    }}
                    value={text}
                    autoFocus={true}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    onKeyDown={(e) => e.key === "Enter" && postComment()}
                    placeholder="Reply..."
                    inputProps={{ "aria-label": "send-button" }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="send-button"
                    onClick={postComment}
                  >
                    <img src="/assets/send_icon.png" alt="ico" width={mobile && 24} />
                  </IconButton>
                </Box>
              )}
            </Stack>
          </React.Fragment>
        }
      />
    </ListItem>
    );
  }
}
