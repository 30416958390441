import * as yup from "yup";
import { DEFAULT_MARKET } from "../../utils/enums/labels.enum";

const InvestorProfileSchema = yup.object({
	description: yup
		.string("Description")
		.required("Description required.")
		.max(150, "Description should be of maximum 150 words"),
	investorType: yup
		.string("Investor Type")
		.required("*Investor Type is required."),
	investorStage: yup
		.string("Investor Stage")
		.required("*Investor Stage is required."),
	leadRounds: yup.boolean("Lead Rounds").required("Lead Rounds is Required"),
	focusVertical:
		yup.array("Focus Vertical") /*.required("Focus Vertical is Required")*/,
	market: yup.array(
		"Market Geographies"
	) /*.required("Market (where are your customers?) is Required")*/,
	ticketCurrency: yup.string("Currency").required("*Currency is Required"),
	ticketStartingAt: yup
		.number("Starting at")
		.required("Starting at is Required"),
	ticketUpto: yup.number("Upto").required("Upto is Required").moreThan(yup.ref("ticketStartingAt"), "Invalid Currency Range"),
	topPriorities: yup.array(
		"Looking for Funding"
	) /*.required("Looking for Funding is Required")*/,
	imageURL: yup.string("logo"),
	postRevenue: yup.boolean(),
	preProduct: yup.boolean(),
	productLive: yup.boolean(),
	PMF: yup.boolean(),
	soleFounder: yup.boolean(),
	portfolio: yup.array().of(
		yup.object().shape({
			logo: yup.string(),
			name: yup.string(),
			description: yup.string(),
			focusVertical: yup.array("Focus Vertical"),
			stage: yup.string("Stage"),
			valuation: yup.string("Valuation"),
			currency: yup.string("Currency"),
			amount: yup.number("Amount"),
			currencyInvestment: yup.string("Currency"),
		})
	),
});
const initialValues = {
	description: "",
	investorType: "",
	investorStage: "",
	leadRounds: false,
	focusVertical: "",
	market: "",
	ticketCurrency: "GBP",
	ticketStartingAt: 0,
	ticketUpto: 0,
	topPriorities: "",
	imageURL: "",
	postRevenue: false,
	preProduct: false,
	productLive: false,
	PMF: false,
	soleFounder: false,
	portfolio: [
		{
			logo: "",
			name: "",
			focusVertical: [],
			stage: "",
			date: "",
			valuation: "",
			currency: "GBP",
			amount: "",
			description: "",
		},
	],
};

const InvestorFirstForm = yup.object({
	investorType: yup
		.string("Investor type")
		.required("Please select investor type."),
});
const investorOrganizationDetailsForm = yup.object({
	logoURL: yup.string("Logo URL"),
	investorType: yup
		.string("Investor type")
		.required("Please select investor type."),
	nameOfOrganization: yup.string("Name of organization").required("Name of organization is a required field"),
	website: yup.string("Website").url('Please enter valid website url'),
	organizationDescription: yup.string("Organization description").max(
		150,
		"Character Limit Exceeded i.e. 150 Characters"
	).required("Organization description is a required field"),
})
const investorOrganizationDetailsValues = {
	logoURL:'',
	investorType: '',
	nameOfOrganization: '',
	website: '',
	organizationDescription: '',
}
const InvestorSecondForm = yup.object({
	photoURL: yup.string("Photo URL"),
	role: yup.string("Role"),
	displayName: yup.string("Name of organization").min(3, 'Name should be of minimum 3 characters length')
    .max(35, 'Name should be of maximum 35 characters length'),
	linkedinURL: yup
		.string("Linkedin URL")
		.matches(
			"^(http(s)?://)?([w]+.)?linkedin.com/(pub|in|profile)",
			"Invalid Linkedin URL"
		),
	description: yup.string("Description").max(
		150,
		"Character Limit Exceeded i.e. 150 Characters"
	),
});
const EditInvestorDetailsForm = yup.object({
	displayName: yup.string("Full name"),
	description: yup.string("Description"),
	countryState: yup.string("Location")
});
const EditInvestorDetailsFormValues = {
	displayName: '',
	description: '',
	countryState: ''
};
const InvestorThirdForm = yup.object({
	investmentStage: yup
		.array("Investment Stage")
		.of(yup.string(""))
		.required("Investment Stage*")
		.min(1),
	sector: yup.array("Sector").of(yup.string("")).required("Sector*").min(1),
	additionalDetails: yup.array("Additional details").of(yup.string("")),
	geographies: yup
		.array("Geographies")
		.of(yup.string(""))
		.required("Geographies*")
		.min(1),
	languages: yup
		.array("Languages")
		.of(yup.string(""))
		.required("Languages")
		.min(1),
	leadOrFollow: yup.string("Lead or follow").required("Lead or follow is a required field*"),
	currency: yup.string("Currency").required("Currency*"),
	startingAt: yup.number("Starting at").required("Starting at*"),
	upto: yup.number("Upto").required("Upto*").moreThan(yup.ref("startingAt"), "Invalid Currency Range"),
});
const InvestorFourForm = yup.object({
	preRevenue: yup.boolean(),
	preProduct: yup.boolean(),
	productLive: yup.boolean(),
	PMF: yup.boolean(),
	soleFounder: yup.boolean(),
});
const InvestorFiveForm = yup.object({
  logo: yup.string(),
  name: yup.string().required('Startup name required'),
  description: yup.string(),
  website: yup.string('Website'),
  link: yup.string('link'),
  investmentStage: yup.string('Investment Stage').required('Investment Stage*'),
  investmentDate: yup.date('Investment Date').required('Investment Date*'),
  valuation: yup.string('Valuation'),
  valuationCurrency: yup.string('Currency'),
  currency: yup.string('Currency'),
  amount: yup.number('Amount'),
  socialProof: yup.string('Social Proof').required('Social Proof'),

})

const InvestorFirstFormValues = {
	investorType: "",

};
const InvestorSecondFormValues = {
	photoURL: "",
	displayName: "",
	linkedinURL: "",
	description: "",
	role: "",
};


const InvestorThirdFormValues = {
	investmentStage: [],
	sector: [],
	additionalDetails: [],
	geographies: [DEFAULT_MARKET],
	languages: [],
	leadOrFollow: "",
	currency: "USD",
	startingAt: "",
	upto: "",
};

const InvestorFourFormValues = {
	preRevenue: false,
	preProduct: false,
	productLive: false,
	PMF: false,
	soleFounder: false,
};
const InvestorFiveFormValues = {
  logo: '',
  name: '',
  description:'',
  website: '',
  investmentStage : '',
  investmentDate: '',
  valuation: '',
  currency: 'GBP',
  amount: '',
  valuationCurrency:'GBP',
  socialProof: '',
  link: ''
}

const InvestorAboutEditForm = yup.object({
	investorType: yup
		.string("Investor type")
		.required("Please select investor type."),
	investmentStage: yup
		.array("Investment Stage")
		.of(yup.string(""))
		.required("Investment Stage*")
		.min(1),
	sector: yup.array("Sector").of(yup.string("")).required("Sector*").min(1),
	additionalDetails: yup.array("Additional details").of(yup.string("")),
	geographies: yup
		.array("Geographies")
		.of(yup.string(""))
		.required("Geographies*")
		.min(1),
	leadOrFollow: yup.string("Lead or follow").required("Lead or follow is a required field*"),
	currency: yup.string("Currency").required("Currency*"),
	startingAt: yup.number("Starting at").required("Starting at*"),
	upto: yup.number("Upto").required("Upto*").moreThan(yup.ref("startingAt"), "Invalid Currency Range"),
	role: yup.string("Role"),
	preRevenue: yup.boolean(),
	preProduct: yup.boolean(),
	productLive: yup.boolean(),
	PMF: yup.boolean(),
	soleFounder: yup.boolean(),

})
const InvestorAboutEditFormValues ={
	role: '',
	...InvestorFirstFormValues,
	...InvestorThirdFormValues,
	...InvestorFourFormValues
}
const CommonInvestorDetails = yup.object({
	investmentStage: yup
		.array("Investment Stage")
		.of(yup.string(""))
		.required("Investment Stage*")
		.min(1),
	sector: yup.array("Sector").of(yup.string("")).required("Sector*").min(1),
	additionalDetails: yup.array("Additional details").of(yup.string("")),
	geographies: yup
		.array("Geographies")
		.of(yup.string(""))
		.required("Geographies*")
		.min(1),
	leadOrFollow: yup.string("Lead or follow").required("Lead or follow is a required field*"),
	currency: yup.string("Currency").required("Currency*"),
	startingAt: yup.number("Starting at").required("Starting at*"),
	upto: yup.number("Upto").required("Upto*").moreThan(yup.ref("startingAt"), "Invalid Currency Range"),
	preRevenue: yup.boolean(),
	preProduct: yup.boolean(),
	productLive: yup.boolean(),
	PMF: yup.boolean(),
	soleFounder: yup.boolean(),
})
const CommonInvestorDetailsValues = {
	investmentStage: [],
	sector: [],
	additionalDetails: [],
	geographies: [],
	leadOrFollow: "",
	currency: "USD",
	startingAt: "",
	upto: "",
	preRevenue: false,
	preProduct: false,
	productLive: false,
	PMF: false,
	soleFounder: false,

}
const InvestorProfileValidation = {
	initialValues,
	schema: InvestorProfileSchema,
	InvestorFirstForm,
	InvestorSecondForm,
	InvestorThirdForm,
	InvestorFourForm,
	InvestorFiveForm,
	InvestorFirstFormValues,
	InvestorSecondFormValues,
	InvestorThirdFormValues,
	InvestorFourFormValues,
	InvestorFiveFormValues,
	InvestorAboutEditForm,
	InvestorAboutEditFormValues,
	EditInvestorDetailsForm,
	EditInvestorDetailsFormValues,
	CommonInvestorDetails,
	CommonInvestorDetailsValues,
	investorOrganizationDetailsForm,
	investorOrganizationDetailsValues
};

export default InvestorProfileValidation;
