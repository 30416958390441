import React from "react";
import {
  Button,
  Container, MenuItem,
  Stack,Chip ,Autocomplete,
  TextField,Switch,FormControlLabel, Typography
} from "@mui/material";
import { useFormik } from "formik";
import ExportOnboardingValidation from "../../../../validations/expert/onboarding-profile";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
export default function Expectations({ cbBack, cbNext, data }) {


  const formikFirst = useFormik({
    initialValues: data
      ? data
      : ExportOnboardingValidation.ExpectationsFormValues,
    validationSchema:
    ExportOnboardingValidation.ExpectationsForm,
    onSubmit: async (values) => {

      cbNext({
        ...values
      });
    },
  }); 

  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>

          <Stack direction={"column"} spacing={5}>
            <Stack
              direction={"column"}
              spacing={1.5}>
              {/* <Typography
                variant={
                  "body2"
                }
                sx={{
                  textAlign:
                    "left",
                }}>
                Support Startup at stage*
              </Typography> */}
              <Autocomplete
						multiple
						required
						options={[
							"Pre-seed",
							"Seed",
							"Seed+",
							"Series A",
							"Series A+",
							"Series B",
							"Series B+",
							"Series C and above",
						].map((option) => option)}
						value={formikFirst.values?.supportStage}
						onChange={(e, newValue) =>
							formikFirst.setFieldValue(
								"supportStage",
								newValue
							)
						} 
						renderTags={(value, getTagProps) =>
							formikFirst.values?.supportStage &&
							formikFirst.values?.supportStage.length > 0 &&
							formikFirst.values?.supportStage.map(
								(option, index) => (
									<Chip
										variant="contained"
                    style={{textTransform:"capitalize"}}
										sx={{
											backgroundColor: "#E0E3FF",
											marginLeft: "5px",
											marginRight: "5px",
											marginBottom: "10px",
											borderRadius: "10px"
										}}
										deleteIcon={<CloseIcon />}
										onDelete={() =>
											formikFirst.setFieldValue(
												"supportStage",
												formikFirst.values?.supportStage.filter(
													(f) => f !== option
												)
											)
										}
										label={option}
										{...getTagProps({
											index,
										})}></Chip>
								)
							)
						}
						renderInput={(params) => (
              <>
              <Typography fontWeight={400} fontSize={"16px"} textAlign="start" >Support Startup at Stage*</Typography>
							<TextField
								{...params}
								// variant={'standard'}
								//label="Support Startup at Stage*"
								name={"supportStage"}
								error={Boolean(
									formikFirst.errors.supportStage
								)}
								helperText={
									formikFirst.errors.supportStage
										? "Support Startup at Stage is required"
										: ""
								}
								//placeholder="Add"
								sx={{
									transition: "0.1s linear",
								}}
							/>
              </>
						)}
					/>
              {/* <TextField name={"supportStage"}
                         required
                         value={formikFirst.values.supportStage}
                         onChange={formikFirst.handleChange}
                         error={
                           formikFirst.touched.supportStage &&
                           Boolean(formikFirst.errors.supportStage)
                         }
                         helperText={
                           formikFirst.touched.supportStage &&
                           formikFirst.errors.supportStage
                         } select sx={{ textAlign: "left" }} >
                <MenuItem value={'Pre-Seed'}>
                  Pre-Seed
                </MenuItem>
                <MenuItem value={'Seed'}>
                  Seed
                </MenuItem>
                <MenuItem value={'Seed+'}>
                  Seed+
                </MenuItem>
                <MenuItem value={'Series A'}>
                  Series A
                </MenuItem>
              </TextField> */}
            </Stack>
          </Stack>

          <Stack direction={"column"} spacing={5}>
            <Stack
              direction={"column"}
              spacing={1.5}>
              <Typography
                variant={
                  "body2"
                }
                sx={{
                  textAlign:
                    "left",
                }}>
                Happy to provide service for some share of the equity?
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center">
                <Typography>
                  No
                </Typography>
                <FormControlLabel
                  label={""}
                  control={
                    <Switch
                      name={
                        "happyToProvideServiceForSomeShareOfTheEquity"
                      }
                      checked={
                        formikFirst
                          .values
                          .happyToProvideServiceForSomeShareOfTheEquity
                      }
                      onChange={
                        formikFirst.handleChange
                      }
                      error={
                        formikFirst
                          .touched
                          .happyToProvideServiceForSomeShareOfTheEquity &&
                        Boolean(
                          formikFirst
                            .errors
                            .happyToProvideServiceForSomeShareOfTheEquity
                        )
                      }
                    />
                  }
                />
                <Typography>
                  Yes
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>


      </Container>
      <Box/>
      <Box/>
      <Box/>
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems="flex-end"
        spacing={3}
        width={"100%"}>
        <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 7 }}
          onClick={cbBack}>
          Back
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{ px: 7 }}
          type={"submit"}>
          Next
        </Button>
      </Stack>
    </Stack>
  );
}
