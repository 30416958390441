import * as React from 'react';
import {
  Toolbar,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton
} from "@mui/material";

import {AuthUserContext} from "../../../../../providers/auth-provider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadFileHelper from "../../../../../utils/download-file-helper";
export default function MessagesTopbar({callback}) {
  const {roomData, setRoomId,
    setRoomData} = React.useContext(AuthUserContext);
  const navigator=useNavigate();
  return(

      <Toolbar sx={{backgroundColor:'#fff', mt: 1,borderBottom: '1px solid #ecebeb', paddingLeft: '5px!important', paddingRight: '5px!important', paddingBottom: '10px'}} >

        <ListItem alignItems="center">
        <IconButton onClick={async ()=>{
          await setRoomId(null);
          await setRoomData(null);
          navigator(-1)
        }}
								>
									<ArrowBackIcon sx={{ fontSize: 30,color: '#000' }} />
								</IconButton>
          <ListItemAvatar >
            <Avatar alt={roomData?.title} src={roomData?.image} sx={{height: 50, width:50, mr:2}}  imgProps={{sx: {
            p: roomData?.type && roomData?.type === 'support' ? 1 : 0
            }}}/>
          </ListItemAvatar>
          <ListItemText  primary={<Typography fontSize={14}>{roomData?.title}</Typography>}
                         secondary={<Typography color={"#888888"} fontSize={12}>{roomData?.subTitle}</Typography>}/>
          <IconButton  onClick={callback}>
            <MoreHorizIcon/>
          </IconButton>
        </ListItem>

      </Toolbar>

  )

}