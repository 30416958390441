import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { Autocomplete, Box, Stack, SwipeableDrawer,  Typography, TextField,} from '@mui/material';
import { Search } from '@mui/icons-material';


const drawerStyle = {
    '& .MuiDrawer-paper': {
        minHeight: '15vh', // Adjust the height of the drawer
        bottom: 0,
        borderRadius: "15px 15px 0px 0px",
        background: "#FFF",
        boxShadow: "0px 3.5px 15px 2px rgba(0, 0, 0, 0.15)",
        overflowX: 'hidden',
        padding:0,
        alignItems: 'center',
      },
} 
const chipStyle ={
    borderRadius: "24px",
    height:"35px",
    boxShadow: "0 0 0 0.5px #8F8F8F !important",cursor: 'pointer',  fontSize:14, fontWeight:300,
}
const paperContainer = {
    py: 2,
    px: 4,
    width:"100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}
const FilterDrawer = ({data, open, cbDrawer, selectedFilters}) => {
  const [selectedChips , setSelectedChips] = useState([])
  const [search, setSearch] = useState("")
  const [inputbtn, setinputbtn] = useState("")
  const toggleDrawer = (open) =>  {
    cbDrawer(open)
  };


  const handleChipSelection = (chipValue) => {
    if(selectedChips.length<10-selectedFilters.length){

      if (selectedChips.includes(chipValue)) {
        setSelectedChips(selectedChips.filter((chip) => chip !== chipValue));
      } else {
        setSelectedChips([...selectedChips, chipValue]);
      }
    }
  };
  React.useEffect(()=>{
    setSelectedChips(data?.value || [])   
  },[data?.value])
  return (
    <div>
      <SwipeableDrawer anchor="bottom" open={open} 
      onClose={()=>{toggleDrawer(!open);}} sx={{...drawerStyle}}>
        <Stack padding={2} alignItems={'center'} justifyContent={'center'}>
        <div style={{width: 90, height: 5, background: '#A9ABAE', borderRadius: 4}} />
        <Typography variant='h6' sx={{ mt:2, fontWeight: '500', wordWrap: 'break-word'}}>{data?.title}</Typography>
        </Stack>
       {selectedChips && selectedChips.length>0 && 
       <Typography textAlign={'right'} width={"100%"} px={4} variant='body2' onClick={()=>setSelectedChips([])}>Reset</Typography>
       }
        <hr style={{width:"100%"}}/>
        <Stack sx={{...paperContainer}} spacing={2}>
     
		{data?.options && data.options.length > 11 && <Box onSubmit={(event) => event.preventDefault()}
						component="form"
						sx={{
              p: "2px 10px",
							display: "flex",
							alignItems: "center",
							width: "100%",
							height: 50,
							borderRadius: "12px",
							backgroundColor: "#E4EAF5",
						}}>
							<Search sx={{ color: "#051E40BF" }} />
            			<Autocomplete
						freeSolo
						disableClearable
						sx={{flex:1,"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
							border: 'none',
						  },
			                 }}
						value={search}
						options={data?.options.map((option) => option)}
						onChange={(event, newValue) => {
						  setinputbtn(newValue);
						}}
						onInputChange={(event, value) => {
							if (event.type === 'change') {
								setinputbtn(value);
							}
						}}
						onKeyDown={(e)=>{
							if(e.code==="Enter" && inputbtn!==''){
                setinputbtn("")
                setSearch("")
								handleChipSelection(inputbtn)
							}
						}}
						renderInput={(params) => (
							<TextField
							{...params}
							sx={{ flex: 1, color: "#051E40BF", input:{
								"&::placeholder":{
								  opacity:1
								}
							  }, }}
							placeholder='Search'
							
						/>
						)}
						/>
					
		</Box>}
        <div style={{ display: 'flex', width:"100%",gap: 8,marginBottom: 1, flexWrap:'wrap'}}>
          { selectedChips && selectedChips.length>0 && selectedChips.map((option)=>(<Chip key={option} sx={{...chipStyle, background:"#2841fc", color:"#FFF"}} label={option} onClick={(event)=>{
                   handleChipSelection(option)
                  }}/>))}
          {
              data?.options && data?.options.slice(0,11).filter((option)=>!selectedChips.includes(option)).map((option)=>(
                  <Chip key={option} sx={{...chipStyle, opacity:selectedChips.length<10-selectedFilters.length? 1 : 0.4,  background:selectedChips.includes(option) ? "#2841fc !important" :"#FFF", color:selectedChips.includes(option) ? "#FFF !important" : "#8F8F8F",}} label={option} 
                  onClick={(event)=>{ handleChipSelection(option)}} />
                  ))
                }
           
        </div>
          <Button variant="contained" color="primary" sx={{width:"90%"}} onClick={()=>{toggleDrawer(false); setSelectedChips([]); data.cbValue(selectedChips)}}
            >
            Show Results
          </Button>
        </Stack>
        
      </SwipeableDrawer>
    </div>
  );
};

export default FilterDrawer;