import React from "react";
import {
  Container,
  Stack,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import ExpertFirstForm from "./../../expert/onboarding-forms/expert-first-form";
import ExpertSecondForm from "./../../expert/onboarding-forms/expert-second-form";
import InvestorPortfolioForm from "./../../investor/onboarding-forms/portfolio-form";
import Expectations from "./../../expert/onboarding-forms/expectations";
import InvestorDetails from "./../../expert/onboarding-forms/investor-details";
import PortfoliosListView from "./../../investor/onboarding-forms/portfolios-list-view";
import InvestorThirdForm from "./../../investor/onboarding-forms/investor-third-form";
import InvestorFourForm from "./../../investor/onboarding-forms/investor-four-form";
import { BUSINESS_DEVELOPER, EXPERT } from "../../../utils/enums/labels.enum";
import InvestorCard from "./investor-card";
import PostProfileSuccessDialog from "./post-profile-success-dialog";
import ExpertCard from "./expert-card";
import { getIdToken } from "firebase/auth";
import AdminPostOnboardProfileData from "../../../api/admin-post-onboard-profile-data";
import BusinessDeveloperCard from "./business-developer-card";
import SaveAsDraft from "../../../api/save-as-draft";
import ExperienceFormGrowthExpert from "../../business-developer/onboarding-forms/experience-form-growth-expert";
import ExperienceListViewGrowthExpert from "../../business-developer/onboarding-forms/experience-list-view-growth-expert";

export default function FillBusinessDeveloperProfile() {
  const { state } = useLocation();
  const { uid } = useParams();
  const { user } = React.useContext(AuthUserContext);
  const selectedUser = useLocation();
  const userType = selectedUser.state.type;
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [formFirstData, setFormFirstData] = React.useState();
  const [formSecondData, setFormSecondData] = React.useState();

  const [formFiveData, setFormFiveData] = React.useState();
  const [formThirdData, setFormThirdData] = React.useState();
  const [formFourData, setFormFourData] = React.useState();
  const [experienceIndex, setExperienceIndex] = React.useState();
  const [experiences, setExperiences] = React.useState([]);
  // const [draftExperiences, setDraftExperiences] = React.useState([]);
  // const [portfolios, setPortfolios] = React.useState([]);
  // const [portfolioIndex, setPortfolioIndex] = React.useState();
  const [formSixData, setFormSixData] = React.useState();

  const [steps, setSteps] = React.useState(0);

  const navigator = useNavigate();
  const submitData = async (values) => {
    try {
      setLoading(true);
      const copyValue = {
        ...values,
        investorDetails: { ...values?.investorDetails, portfolio: [] },
      };
      const copyExperiences = [];
      if (experiences.length > 0) {
        for (let experience of experiences) {
          const attachments = [];
          if (experience.attachments.length > 0) {
            for (let file of experience.attachments) {
              const path = `experts/${user.uid}/experiences/attachments/${file.file.name}`;
              attachments.push(path);
            }
          }
          copyExperiences.push({ ...experience, attachments });
        }
      }
      const {linkedinURL} = state.contactData
      const businessDeveloper = {
        ...copyValue,
        experiences: copyExperiences,
        photoURL: values?.photoURL ? values.photoURL : "",linkedinURL
      };

      const postData = {
        createdById: user?.uid,
        createdBy: "admin",
        type: state?.type,
        ...state.contactData,
        photoURL: values?.photoURL ? values.photoURL : "",
        businessDeveloper,
        onboard: true,
        profileStatus: "complete",
        remainderStatus: "",
        searchName: state.contactData?.displayName.toLowerCase(),
      };
      const idToken = await getIdToken(user);
      await AdminPostOnboardProfileData(postData, idToken);
      setLoading(false);
      setOpen(true);
      // const userRef = doc(DB, "users", user.uid);
    } catch (e) {}
  };

  return (
    <>
      <Container maxWidth={"full-width"} sx={{ marginY: 5 }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}
        >
          <IconButton
            onClick={() =>
              steps === 0
                ? navigator(
                    uid
                      ? `/admin/on-boarding/contact-info/${uid}`
                      : "/admin/on-boarding/contact-info",
                    { state }
                  )
                : steps === 6 && !formSixData?.areYouAnAngelInvestor
                ? setSteps(steps - 3)
                : setSteps(steps - 1)
            }
          >
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography variant={"h5"} sx={{ textAlign: "left", lineHeight: 1 }}>
            {steps !== 6 ? BUSINESS_DEVELOPER.SINGULAR_LABEL : "Final step"}
            <br />{" "}
            <small style={{ fontSize: 14, color: "#8F8F8F" }}>
              {steps === 0 && BUSINESS_DEVELOPER.BUSINESS_DEVELOPER_PROFILE}
              {steps === 1 && BUSINESS_DEVELOPER.EXPERTISE}
              {steps === 2 && BUSINESS_DEVELOPER.EXPECTATIONS}
              {steps === 3 && BUSINESS_DEVELOPER.EXPERIENCE}
              {steps === 4 && BUSINESS_DEVELOPER.ADD_EXPERIENCE}
              {steps === 5 && BUSINESS_DEVELOPER.EXPECTATIONS}
              {steps > 5 && steps < 9 && BUSINESS_DEVELOPER.INVESTOR_DETAILS}
              {steps === 9 && "send profile details via email"}
            </small>
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={"lg"} sx={{ mb: 5 }}>
        {steps === 0 && (
          <ExpertFirstForm
            uid={uid}
            data={formFirstData}
            cbBack={() =>
              navigator(
                uid
                  ? `/admin/on-boarding/contact-info/${uid}`
                  : "/admin/on-boarding/contact-info",
                { state }
              )
            }
            cbNext={(data) => {
              setFormFirstData({ ...data });
              setSteps(steps + 1);
              uid &&
                SaveAsDraft(
                  { ...state, formFirstData: data },
                  uid,
                  user
                ).then();
            }}
            cbDraft={(data) => {
              SaveAsDraft({ ...state, formFirstData: data }, uid, user).then(
                () => navigator("/admin/draft-profiles")
              );
            }}
          />
        )}
        {steps === 1 && (
          <ExpertSecondForm
            data={formSecondData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormSecondData({ ...data });
              setSteps(steps + 1);
              uid &&
                SaveAsDraft(
                  { ...state, formFirstData, formSecondData: data },
                  uid,
                  user
                ).then();
            }}
            cbDraft={(data) => {
              SaveAsDraft(
                { ...state, formFirstData, formSecondData: data },
                uid,
                user
              ).then(() => navigator("/admin/draft-profiles"));
            }}
            uid={uid}
          />
        )}

        {steps === 2 && (
          <Expectations
            uid={uid}
            data={formFiveData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormFiveData({ ...data });
              setSteps(steps + 1);
              uid &&
                SaveAsDraft(
                  {
                    ...state,
                    formFirstData,
                    formSecondData,
                    expectation: data,
                  },
                  uid,
                  user
                ).then();
            }}
            cbDraft={(data) => {
              SaveAsDraft(
                { ...state, formFirstData, formSecondData, expectation: data },
                uid,
                user
              ).then(() => navigator("/admin/draft-profiles"));
            }}
          />
        )}

        {steps === 3 && (
          <ExperienceListViewGrowthExpert
            uid={uid}
            userType={userType}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setSteps(steps + 1);
              const copyExperiences = [];
              if (data.length > 0) {
                for (let experience of data) {
                  const attachments = [];
                  if (experience.attachments.length > 0) {
                    for (let file of experience.attachments) {
                      const path = file?.file
                        ? `experts/${user.uid}/experiences/attachments/${file.file.name}`
                        : file;
                      attachments.push(path);
                    }
                  }
                  copyExperiences.push({ ...experience, attachments });
                }
              }
              uid &&
                SaveAsDraft(
                  {
                    ...state,
                    formFirstData,
                    formSecondData,
                    formFiveData,
                    experiences: [...copyExperiences],
                  },
                  uid,
                  user
                ).then();
            }}
            add={(data) => {
              setExperienceIndex(undefined);
              setSteps(steps + 1);
            }}
            edit={(ind, ex) => {
              setExperiences([...ex]);
              setExperienceIndex(ind);
              setSteps(steps + 1);
            }}
            remove={(ind, exp) => {
              debugger;
              const experiences_copy = [...experiences];
              experiences_copy.splice(ind, 1);
              const experiences_removed_copy = [];
              if (experiences_copy.length > 0) {
                for (let experience of experiences_copy) {
                  const attachments = [];
                  if (experience.attachments.length > 0) {
                    for (let file of experience.attachments) {
                      const path = file?.file
                        ? `experts/${user.uid}/experiences/attachments/${file.file.name}`
                        : file;
                      attachments.push(path);
                    }
                  }
                  experiences_removed_copy.push({ ...experience, attachments });
                }
              }
              SaveAsDraft(
                {
                  ...state,
                  formFirstData,
                  formSecondData,
                  formFiveData,
                  experiences: experiences_removed_copy,
                },
                uid,
                user
              ).then(() => {});
              setExperiences(experiences_removed_copy);
            }}
            data={experiences}
            cbDraft={(data) => {
              const copyExperiences = [];
              if (data.length > 0) {
                for (let experience of data) {
                  const attachments = [];
                  if (experience.attachments.length > 0) {
                    for (let file of experience.attachments) {
                      const path = file?.file
                        ? `experts/${user.uid}/experiences/attachments/${file.file.name}`
                        : file;
                      attachments.push(path);
                    }
                  }
                  copyExperiences.push({ ...experience, attachments });
                }
              }
              SaveAsDraft(
                {
                  ...state,
                  formFirstData,
                  formSecondData,
                  formFiveData,
                  experiences: [...copyExperiences],
                },
                uid,
                user
              )
                .then(() => navigator("/admin/draft-profiles"))
                .catch((error) => {
                  console.log(error);
                });
            }}
            submit={() => {
              setSteps(steps + 2);
              console.log("submit");
            }}
          />
        )}

        {steps === 4 && (
          <ExperienceFormGrowthExpert
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data, index) => {
              debugger;
              const port = [...experiences];
              if (index !== undefined && index > -1) {
                port.splice(index, 1, { ...data });
              } else {
                port.push({ ...data });
              }
              const copyExperiences = [];
              if (port.length > 0) {
                for (let experience of port) {
                  const attachments = [];
                  if (experience.attachments.length > 0) {
                    for (let file of experience.attachments) {
                      const path = file?.file
                        ? `experts/${user.uid}/experiences/attachments/${file.file.name}`
                        : file;
                      attachments.push(path);
                    }
                  }
                  copyExperiences.push({ ...experience, attachments });
                }
              }
              uid &&
                SaveAsDraft(
                  {
                    ...state,
                    formFirstData,
                    formSecondData,
                    formFiveData,
                    experiences: [...copyExperiences],
                  },
                  uid,
                  user
                ).then(() => {});
              setExperiences([...port]);
              setSteps(steps - 1);
              setExperienceIndex(undefined);
            }}
            data={
              experienceIndex !== undefined && experienceIndex > -1
                ? experiences[experienceIndex]
                : undefined
            }
            index={experienceIndex}
          />
        )}

        {steps === 5 && (
          <InvestorDetails
            uid={uid}
            data={formSixData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormSixData({ ...data });
              if (data?.areYouAnAngelInvestor === true) {
                setSteps(steps + 1);
              } else {
                setSteps(steps + 3);
                /* submitData({
                     investorDetails: {
                       ...formSixData,
                     },
                     ...formFirstData,
                     ...formSecondData,
                     ...formFiveData,
                   }).then()*/
              }
              uid &&
                SaveAsDraft(
                  {
                    ...state,
                    formFirstData,
                    formSecondData,
                    expectation: formFiveData,
                    investorFormOne: data,
                  },
                  uid,
                  user
                ).then();
            }}
            cbDraft={(data) => {
              SaveAsDraft(
                {
                  ...state,
                  formFirstData,
                  formSecondData,
                  expectation: formFiveData,
                  investorFormOne: data,
                },
                uid,
                user
              ).then(() => navigator("/admin/draft-profiles"));
            }}
          />
        )}
        {steps === 6 && (
          <InvestorThirdForm
            uid={uid}
            data={formThirdData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormThirdData({ ...data });
              setSteps(steps + 1);
              uid &&
                SaveAsDraft(
                  {
                    ...state,
                    formFirstData,
                    formSecondData,
                    expectation: formFiveData,
                    investorFormOne: formSixData,
                    investorFormTwo: data,
                  },
                  uid,
                  user
                ).then();
            }}
            cbDraft={(data) => {
              SaveAsDraft(
                {
                  ...state,
                  formFirstData,
                  formSecondData,
                  expectation: formFiveData,
                  investorFormOne: formSixData,
                  investorFormTwo: data,
                },
                uid,
                user
              ).then(() => navigator("/admin/draft-profiles"));
            }}
          />
        )}
        {steps === 7 && (
          <InvestorFourForm
            uid={uid}
            data={formFourData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormFourData({ ...data });
              setSteps(steps + 1);
              uid &&
                SaveAsDraft(
                  {
                    ...state,
                    formFirstData,
                    formSecondData,
                    expectation: formFiveData,
                    investorFormOne: formSixData,
                    investorFormTwo: formThirdData,
                    investorFormThree: data,
                  },
                  uid,
                  user
                ).then();
            }}
            cbDraft={(data) => {
              SaveAsDraft(
                {
                  ...state,
                  formFirstData,
                  formSecondData,
                  expectation: formFiveData,
                  investorFormOne: formSixData,
                  investorFormTwo: formThirdData,
                  investorFormThree: data,
                },
                uid,
                user
              ).then(() => navigator("/admin/draft-profiles"));
            }}
          />
        )}

        {steps === 8 && (
          <BusinessDeveloperCard
            data={{
              ...formSixData,
              ...formThirdData,
              ...formFourData,
              ...formFirstData,
              ...formSecondData,
              ...formFiveData,
            }}
            cbBack={() =>
              formSixData?.areYouAnAngelInvestor === true
                ? setSteps(steps - 1)
                : setSteps(steps - 3)
            }
            send={() => {
              uid && deleteDoc(doc(DB, "users", uid)).then();
              submitData({
                investorDetails: {
                  ...formSixData,
                  ...formThirdData,
                  ...formFourData,
                },
                ...formFirstData,
                ...formSecondData,
                ...formFiveData,
              }).then();
            }}
          />
        )}

        {open && (
          <PostProfileSuccessDialog
            open={open}
            setOpen={(bool) => {
              setOpen(bool);
              navigator("/admin/dashboard");
            }}
          />
        )}
        {loading && (
          <Backdrop
            open={loading}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {/*
        setSteps(formSixData?.areYouAnAngelInvestor ? steps-1 :steps -3)}
        
        */}
        {/* {
          steps === 6 && (<PortfoliosListView
              cbBack={() => setSteps(steps - 1)}
              add={(data) => {
                setPortfolioIndex();
                setSteps(steps + 1);
              }}
              edit={(ind) => {
                setPortfolioIndex(ind);
                setSteps(steps + 1);
              }}
              remove={(ind) => {
                debugger
                const port = [...portfolios]
                port.splice(ind, 1)
                setPortfolios([...port])
              }}
              portfolios={portfolios}
              submit={() => {
                debugger
                submitData({
                  investorDetails: {
                    ...formSixData,
                    ...formThirdData,
                    ...formFourData,
                  },
                  ...formFirstData,
                  ...formSecondData,
                  ...formFiveData,
                }).then()
                console.log('submit')
              }}
            />
          )
        }
        
        {steps === 7 && (
          <InvestorPortfolioForm
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data, index) => {
              const port = [...portfolios]
              if (index !== undefined && index > -1) {
                port.splice(index, 1, { ...data})
              } else {
                port.push({ ...data })
              }
              setPortfolios([...port])
              setSteps(steps - 1);
              setPortfolioIndex(undefined);
              
            }}
            data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}
            
            index={portfolioIndex}
          />)
          
          
        }*/}
      </Container>
    </>
  );
}
