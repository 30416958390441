import React from "react";
import {
  query,
  collection,
  where,
  orderBy,
  onSnapshot,
  getDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { AuthUserContext } from "../../../providers/auth-provider";
import { useLocation, useNavigate } from "react-router-dom";
import ExpertCard from "./best-match-card";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ApplicantCard from "./applicant-card";
export const ApplicantsList = () => {
  const [search, setSearch] = React.useState("");
  const [lastDoc, setLastDoc] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [counts, setCounts] = React?.useState(0);
  const { profile } = React.useContext(AuthUserContext);
  const location = useLocation();
  const navigator = useNavigate();
  const { request } = location?.state;
  const [applicants, setApplicants] = React.useState();

  React.useEffect(() => {
    const fetchData = () => {
      const q = query(
        collection(DB, "applicants"),
        where("requestId", "==", request.id),
        orderBy("createdAt", "desc")
      );

      getDocs(q)
        .then((snapshot) => {
          const promises = snapshot.docs.map((applicant) => {
            const applicantData = applicant.data();
            const userDocRef = doc(
              collection(DB, "users"),
              applicantData.applicantUid
            );

            return getDoc(userDocRef).then((userDoc) => {
              const userData = userDoc.exists() ? userDoc.data() : null;

              if (userData) {
                return { id: doc.id, ...applicantData, ...userData };
              } else {
                return { id: doc.id, ...applicantData };
              }
            });
          });

          return Promise.all(promises);
        })
        .then((list) => {
          setApplicants(list);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching applicants:", error);
        });
    };

    fetchData();
  }, []);

  let filteredSearch;
  const MemberSearch = ({ array }) => {
    console.log("array", array);
    const { profile } = React.useContext(AuthUserContext);
    //   const [search, setSearch] =React.useState('')
    filteredSearch = array
      .filter((member) => {
        debugger;
        if (search !== "") {
          const reg = new RegExp(search.toLowerCase());
          return reg.test(member?.displayName?.toLowerCase()) && member;
        } else {
          return member;
        }
      })
      .sort((a, b) => b?.createdAt - a?.createdAt);
    if (filteredSearch.length === 0) {
      return (
        <Stack
          direction={"row"}
          sx={{ width: "100%" }}
          py={5}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4" mt={10}>
            No results
          </Typography>
        </Stack>
      );
    } else {
      debugger;
      return filteredSearch.map((record) => (
        <ApplicantCard
          data={record}
          key={record?.uid}
          request={request}
          href={`/${profile?.userType}/user/details/${record?.uid}`}
        />
      ));
    }
  };

  return (
    <>
      <Container maxWidth={"xl"} sx={{ my: 5, textAlign: "left" }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}
        >
          <IconButton onClick={() => navigator(-1)}>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography variant={"h5"} sx={{ textAlign: "left", lineHeight: 1 }}>
            Applicants
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={"lg"} sx={{ my: 5, textAlign: "left" }}>
        <Grid container spacing={5}>
          {applicants && applicants.length > 0 && (
            <MemberSearch array={applicants} />
          )}
        </Grid>
        {loading && (
          <Grid container spacing={5} my={5}>
            <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
          </Grid>
        )}

        {lastDoc && (
          <Stack
            direction={"row"}
            sx={{ width: "100%" }}
            py={5}
            justifyContent="center"
            alignItems="center"
          >
            <Button size={"small"}>Load more</Button>
          </Stack>
        )}
      </Container>
    </>
  );
};
