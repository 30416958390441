import React from "react";
import {
  Avatar,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
  Button,
} from "@mui/material";
import {
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import { USER_TYPES_TO_NAMEC } from "../../../utils/enums/labels.enum";
import { SUGGESTED_STATUS } from "../../../utils/enums/suggestions-state";
import NotificationEmail from "../../../api/notification-email";
import PostNotification from "../../../api/post-notification";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  backgroundColor: "#fff",
  color: theme.palette.text.secondary,
  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
  borderRadius: "15px",
  minHeight: 348,
}));
export default function ExpertCard({ data, key, request, href }) {
  const navigator = useNavigate();
  const { profile } = React.useContext(AuthUserContext);
  const [suggested, setSuggested] = React.useState(false);
  const suggest = (user) => {
    console.log("userId", user.uid);
    console.log("requestId", request.id);
    console.log("user", user);
    console.log("request", request);
    const colRef = doc(
      DB,
      "help-requests",
      request?.id,
      "users-suggested",
      user?.uid
    );
    setDoc(
      colRef,
      {
        status: SUGGESTED_STATUS.PENDING,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      },
      { merge: true }
    ).then(async () => {
      const colRef = doc(
        DB,
        "users",
        user?.uid,
        "requests-suggested",
        request?.id
      );
      setDoc(
        colRef,
        {
          status: SUGGESTED_STATUS.PENDING,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      ).then(() => {
        console.log(user?.email)
        PostNotification({
          type: 'suggest-request',
          imageURL: '/assets/system-noti-icon.png',
          message: `Great news! The admin has recommended your profile for a job post.`,
          uid: user?.uid,
          requestFor: request?.requestFor,
          request: request?.id
        }).then();
        //email and notification
        const data = {
          type: "suggest",
          email: user?.email,
          displayName: request?.creatorData?.displayName,
          title: USER_TYPES_TO_NAMEC[request?.creatorData?.userType],
          url: `/${user.userType}/open-requests-details/${request.id}`,
          photoURL: request?.creatorData?.photoURL,
          requestInfo: {
            description: request?.requestFor,
            requestId: request?.id,
            location: request?.location,
          },
        };
        NotificationEmail({ ...data }).then();
      });
      setSuggested(true);
    });
  };
  React.useEffect(() => {
    console.log("requestId", request.id);
    console.log("userId", data.uid);
    setSuggested(false);
    let unsubs;
    unsubs = getDoc(
      doc(DB, "help-requests", request?.id, "users-suggested", data?.uid)
    ).then((getdoc) => {
      if (getdoc.exists()) {
        setSuggested(true);
      }
    });
    return () => unsubs;
  }, [data?.uid]);

  return (
    <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
      <Item>
        <Stack
          direction={"column"}
          spacing={2}
          sx={{ width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              position: "relative",
              top: "40px",
              bottom: "20px",
              width: "280px",
              height: "200px",
              background: "#F6F8FA",
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
            }}
          >
            <Stack
              direction={"column"}
              spacing={2}
              justifyContent="center"
              style={{ cursor: "pointer" }}
              alignItems="center"
              onClick={() => navigator(href)}
            >
              <Box
                sx={{
                  border: "1px solid #204488",
                  p: 0.75,
                  borderRadius: "50%",
                  marginTop: "-40px",
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={data?.photoURL}
                  sx={{ width: 92, height: 92 }}
                >
                  <Typography variant={"h5"}>
                    {data?.displayName && data?.displayName.charAt(0)}
                  </Typography>
                </Avatar>
              </Box>
              <Typography
                variant={"h6"}
                sx={{ fontWeight: 600, mb: 0, lineHeight: 1.2 }}
              >
                {data?.displayName} <br />
                <font size={3} style={{ fontWeight: 300 }}>
                  {USER_TYPES_TO_NAMEC[data.userType]}
                </font>
              </Typography>

              <Box />
            </Stack>
            {!suggested ? (
              <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                sx={{ px: 2, width: 120 }}
                onClick={() => {
                  suggest(data);
                }}
              >
                Suggest
              </Button>
            ) : (
              <Button
                size={"small"}
                variant={"disabled"}
                color={"secondary"}
                sx={{ px: 2, width: 120 }}
              >
                Suggested
              </Button>
            )}
          </Box>

          <Box />
          <Box />
          <Box />
        </Stack>
      </Item>
    </Grid>
  );
}
