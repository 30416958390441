
import { TextareaAutosize } from "@mui/material";
// note : 
// Textarea Autosize is a part of the standalone Base UI component library. 
// It is currently re-exported from @mui/material for your convenience, 
// but it will be removed from this package in a future major version, after @mui/base gets a stable release.

import React from "react";


export const ResizableTextarea = (props) => {
    const [height, setHeight] = React.useState('auto');
  
    const handleChange = (event) => {
      setHeight(event.target.scrollHeight);
      if (props.onChange) {
        props.onChange(event);
      }
    };
  
    return (
      <TextareaAutosize
        {...props}
        onChange={handleChange}
        style={{
          
          resize: 'vertical',
          width: '100%',
          minHeight: 50,
          // paddingInline:"10px",
          paddingLeft:"30px",
          fontFamily: 'inherit',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          border: 'none',
          outline: 'none',
          }}
      />
    );
  };