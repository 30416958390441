import React from "react";
import {
    Button,
    Container,
    Stack,
    TextField,
    Chip,
    Autocomplete,
    Typography,
    FormControlLabel,
    Switch,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormHelperText,
    useMediaQuery, CircularProgress, createFilterOptions
} from "@mui/material";
import { useFormik } from "formik";
import StartupOnBoardingValidation from "../../../validations/startup/startup-profile";
import CloseIcon from "@mui/icons-material/Close";
import dataJson from '../../../const/data.json';
import { Add } from "@mui/icons-material";
const filter = createFilterOptions()
export default function AboutEditStartupForm({ cbBack, cbNext, data, isLoading }) {
    const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const formikFirst = useFormik({
    initialValues: data
      ? data
      : StartupOnBoardingValidation.EditStartupAboutDetailsFormValues,
    validationSchema:
    StartupOnBoardingValidation.EditStartupAboutDetailsForm,
    onSubmit: async (values) => {

      cbNext({
        ...values
      });
    },
  });

  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>
        <TextField
            name={'website'}
            value={formikFirst.values.website}
            onChange={formikFirst.handleChange}
            error={formikFirst.touched.website && Boolean(formikFirst.errors.website)}
            helperText={formikFirst.touched.website && formikFirst.errors.website}
            fullWidth label={'Website URL'} />
          <TextField
            required
            InputProps={{inputProps: { min: "1900-01-00", max: "2150-12-31"} }}
            name={"foundingDate"}
            value={formikFirst.values.foundingDate}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.foundingDate &&
              Boolean(formikFirst.errors.foundingDate)
            }
            helperText={
              formikFirst.touched.foundingDate &&
              formikFirst.errors.foundingDate
            }
            label={"Founding date"}
            type={"date"}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            // required
            InputProps={{inputProps: { min: "1900-01-00", max: "2150-12-31"} }}
            name={"registeredDate"}
            value={formikFirst.values.registeredDate}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.registeredDate &&
              Boolean(formikFirst.errors.registeredDate)
            }
            helperText={
              formikFirst.touched.registeredDate &&
              formikFirst.errors.registeredDate
            }
            label={"Formally registered date"}
            type={"date"}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Autocomplete
            multiple
            options={[
              "Adtech",
              "Advanced manufacturing",
              "Agtech",
              "Artificial intelligence and machine learning (AI/ML)",
              "Audiotech",
              "Augmented reality (AR)",
              "Autonomous cars",
              "B2B payments",
              "Beauty",
              "Big Data",
              "Cannabis",
              "Carsharing",
              "Cleantech",
              "Climate tech",
              "Cloudtech and DevOps",
              "Construction technology",
              "Cryptocurrency and blockchain",
              "Cybersecurity",
              "Digital health",
              "Ecommerce",
              "Edtech",
              "Ephemeral content",
              "eSports",
              "Femtech",
              "Fintech",
              "Foodtech",
              "Gaming",
              "Healthtech",
              "HRtech",
              "Impact investing",
              "Industrials",
              "Infrastructure",
              "Insurtech",
              "Internet of Things (IoT)",
              "Legal tech",
              "Life sciences",
              "Lifestyles of Health and Sustainability (LOHAS) and wellness",
              "Manufacturing",
              "Marketing tech",
              "Micro-mobility",
              "Mobile",
              "Mobile commerce",
              "Mobility tech",
              "Mortgage tech",
              "Nanotechnology",
              "Oil and gas",
              "Oncology",
              "Pet tech",
              "Real estate tech",
              "Restaurant tech",
              "Ridesharing",
              "Robotics and drones",
              "Software as a service (SaaS)",
              "Space tech",
              "Supply chain technology",
              "Technology, media and telecommunications (TMT)",
              "Virtual reality (VR)",
              "Wearables and quantified self",
              "3D printing",
            ].map((option) => option)}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
          
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option);
              if (inputValue !== '' && !isExisting) {
                filtered.push('Add ' + inputValue);
              }
          
              return filtered;
              }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option
              }
              // Add "xxx" option created dynamically
              if (option.value) {
                return option.value;
                }
                // Regular option
              return option
              }}
            renderOption={(props, option) => 
            <li {...props} style={{alignItems:"center"}}>
              {option.includes("Add ") 
              ? 
              <><Add fontSize="medium"/>{option}</> 
              : option}
              </li>}
           onChange={(
            e,
            newValue
          ) =>{
            if(newValue.length!==0)
              {const newOption = newValue.slice(-1)[0].replace("Add ", "")
              newValue.splice(-1,1,newOption)}
              
            formikFirst.setFieldValue('sector', newValue)
          }
          }
            name={"sector"}
            value={formikFirst.values.sector}
            renderTags={(value, getTagProps) =>
              formikFirst.values.sector.map(
                (option, index) => (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor: "#E3E8F0",
                      marginLeft: "5px",
                      marginRight: "5px",
                      marginBottom: "10px",
                    }}
                    deleteIcon={<CloseIcon />}
                    onDelete={() =>
                      formikFirst.setFieldValue(
                        "sector",
                        formikFirst.values.sector.filter(
                          (f) => f !== option.value ? option.value : option
                        )
                      )
                    }
                    label={option.value ? option.value : option}
                    {...getTagProps({ index })}></Chip>
                )
              )
            }
            renderInput={(params) => (
              <TextField
                required={
                  formikFirst.values.sector.length ===
                  0
                }
                {...params}
                fullWidth
                // variant={'standard'}
                label="Sector"
                placeholder="Add"
                sx={{
                  transition: "0.1s linear",
                }}
              />
            )}
            fullWidth
          />

          <Autocomplete
            fullWidth
            freeSolo
            multiple
            options={[
              // ''
            ].map((option) => option)}
            onChange={(e, newValue) => {
              formikFirst.setFieldValue(
                "additionalDetails",
                newValue
              );
            }}
            name={"additionalDetails"}
            value={formikFirst.values.additionalDetails}
            renderTags={(value, getTagProps) =>
              formikFirst.values.additionalDetails.map(
                (option, index) => (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor: "#E3E8F0",
                      marginLeft: "5px",
                      marginRight: "5px",
                      marginBottom: "10px",
                    }}
                    deleteIcon={<CloseIcon />}
                    onDelete={() =>
                      formikFirst.setFieldValue(
                        "additionalDetails",
                        formikFirst.values.additionalDetails.filter(
                          (f) => f !== option
                        )
                      )
                    }
                    label={option}
                    {...getTagProps({ index })}
                  ></Chip>
                )
              )
            }
            renderInput={(params) => (
              <TextField
                // required={
                // 	formikFirst.values.additionalDetails.length ===
                // 	0
                // }
                {...params}
                // variant={'standard'}
                label="Additional details"
                fullWidth
                placeholder="Add"
                sx={{
                  transition: "0.1s linear",
                }}
              />
            )}
          />
          <Autocomplete
            options={[...dataJson.cities].map((option) => option)}
            freeSolo
            onChange={(e, newValue) => {
              formikFirst.setFieldValue("location", newValue);
            }}
            name={"location"}
            value={formikFirst.values.location}
            renderTags={(value, getTagProps) =>
              formikFirst.values.location.map((option, index) => (
                <Chip
                  variant="contained"
                  sx={{
                    backgroundColor: "#E3E8F0",
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginBottom: "10px",
                  }}
                  deleteIcon={<CloseIcon />}
                  onDelete={() =>
                    formikFirst.setFieldValue(
                      "location",
                      formikFirst.values.location.filter(
                        (f) => f !== option
                      )
                    )
                  }
                  label={option}
                  {...getTagProps({ index })}></Chip>
              ))
            }
            renderInput={(params) => (
              <TextField
                required={
                  formikFirst.values.location &&
                  formikFirst.values.location.length === 0
                }
                {...params}
                // variant={'standard'}
                label="Location (HQ)"
                placeholder="Add"
                sx={{
                  transition: "0.1s linear",
                }}
              />
            )}
          />
          <Autocomplete
            multiple
            options={[...dataJson.markets].map(
              (option) => option
            )}
            freeSolo
            onChange={(e, newValue) => {
              formikFirst.setFieldValue("market", newValue);
            }}
            name={"market"}
            value={formikFirst.values.market}
            renderTags={(value, getTagProps) =>
              formikFirst.values.market.map((option, index) => (
                <Chip
                  variant="contained"
                  sx={{
                    backgroundColor: "#E3E8F0",
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginBottom: "10px",
                  }}
                  deleteIcon={<CloseIcon />}
                  onDelete={() =>
                    formikFirst.setFieldValue(
                      "market",
                      formikFirst.values.market.filter(
                        (f) => f !== option
                      )
                    )
                  }
                  label={option}
                  {...getTagProps({ index })}></Chip>
              ))
            }
            renderInput={(params) => (
              <TextField
                required={
                  formikFirst.values.market.length === 0
                }
                {...params}
                // variant={'standard'}
                label="Market (where are your customers?)"
                placeholder="Add"
                sx={{
                  transition: "0.1s linear",
                }}
              />
            )}
          />

          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              sx={{ textAlign: "left", mb: 2 }}>
              Select type of business*
            </FormLabel>
            <RadioGroup
              row
              aria-label="businessType"
              name={"businessType"}
              value={formikFirst.values.businessType}
              onChange={formikFirst.handleChange}
              error={
                formikFirst.touched.businessType &&
                Boolean(formikFirst.errors.businessType)
              }
              // helperText={formik.touched.businessType && formik.errors.businessType}
            >
              <FormControlLabel
                value="B2B"
                control={<Radio />}
                label="B2B"
              />
              <FormControlLabel
                value="B2C"
                control={<Radio />}
                label="B2C"
              />
              <FormHelperText
                error={
                  formikFirst.touched.businessType &&
                  Boolean(formikFirst.errors.businessType)
                }
                children={
                  formikFirst.touched.businessType &&
                  formikFirst.errors.businessType
                }
              />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" variant="standard">
            <FormLabel
              component="legend"
              sx={{ textAlign: "left", mb: 2 }}>
              Women in founding team?
            </FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch
                    name={"femaleInFoundingTeam"}
                    checked={
                      formikFirst.values
                        .femaleInFoundingTeam
                    }
                    onChange={formikFirst.handleChange}
                  />
                }
              />
              <Typography>Yes</Typography>

              <FormHelperText
                error={
                  formikFirst.touched.femaleInFoundingTeam &&
                  Boolean(
                    formikFirst.errors.femaleInFoundingTeam
                  )
                }
                children={
                  formikFirst.touched.femaleInFoundingTeam &&
                  formikFirst.errors.femaleInFoundingTeam
                }
              />
            </Stack>
          </FormControl>
          <FormControl component="fieldset" variant="standard">
            <FormLabel
              component="legend"
              sx={{ textAlign: "left", mb: 2 }}>
              Ethnic minority in founding team?
            </FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch
                    name={"ethnicMinorityInFundingTeam"}
                    checked={
                      formikFirst.values
                        .ethnicMinorityInFundingTeam
                    }
                    onChange={formikFirst.handleChange}
                  />
                }
              />
              <Typography>Yes</Typography>

              <FormHelperText
                error={
                  formikFirst.touched
                    .ethnicMinorityInFundingTeam &&
                  Boolean(
                    formikFirst.errors
                      .ethnicMinorityInFundingTeam
                  )
                }
                children={
                  formikFirst.touched
                    .ethnicMinorityInFundingTeam &&
                  formikFirst.errors
                    .ethnicMinorityInFundingTeam
                }
              />
            </Stack>
          </FormControl>
          <FormControl component="fieldset" variant="standard">
            <FormLabel
              component="legend"
              sx={{ textAlign: "left", mb: 2 }}>
              Immigrant in founding team?
            </FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch
                    name={"immigrantInFundingTeam"}
                    checked={
                      formikFirst.values
                        .immigrantInFundingTeam
                    }
                    onChange={formikFirst.handleChange}
                  />
                }
              />
              <Typography>Yes</Typography>

              <FormHelperText
                error={
                  formikFirst.touched
                    .immigrantInFundingTeam &&
                  Boolean(
                    formikFirst.errors
                      .immigrantInFundingTeam
                  )
                }
                children={
                  formikFirst.touched
                    .immigrantInFundingTeam &&
                  formikFirst.errors.immigrantInFundingTeam
                }
              />
            </Stack>
          </FormControl>
          <FormControl component="fieldset" variant="standard">
            <FormLabel
              component="legend"
              sx={{ textAlign: "left", mb: 2 }}>
              Distributed team?
            </FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch
                    name={"distributedTeam"}
                    checked={
                      formikFirst.values.distributedTeam
                    }
                    onChange={formikFirst.handleChange}
                  />
                }
              />
              <Typography>Yes</Typography>

              <FormHelperText
                error={
                  formikFirst.touched.distributedTeam &&
                  Boolean(formikFirst.errors.distributedTeam)
                }
                children={
                  formikFirst.touched.distributedTeam &&
                  formikFirst.errors.distributedTeam
                }
              />
            </Stack>
          </FormControl>



        </Stack>
      </Container>
        {
            matches ? <Stack
                    direction={"row"}
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={3}
                    width={"100%"}>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        sx={{ px: 5 }}
                        onClick={cbBack}>
                        Back
                    </Button>
                    {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        sx={{ px: 5 }}
                        type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
                        save
                    </Button>
                </Stack> :
                <Stack
                    direction={"row"}
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={3}
                    width={"100%"}
                    sx={{px: !matches ? 2 : ''}}
                >
                    {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        sx={{ px: 5 }}
                        type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
                        save
                    </Button>
                </Stack>
        }
    </Stack>
  );
}
