import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
import {Button, Stack, Box, Skeleton} from "@mui/material";
import moment from "moment";
import CommentLikeMenu from "./comment/comment-like-menu";
import CommentsLikesCountCompnent from "./comment/comments-likes-count-compnent";
import CommentReplies from "./comment/comment-replies";
import ItemText, {ItemMentioned} from "./item-text";
import { stringAvatar, stringToColor } from '../../../utils/avatarGenerator';
import LinkWithThumbnail from '../link-embed-thumbnail';

export default function PostCommentItem({comment, timelineId, postuid, mobile}) {
  const [user, setUser] = React.useState()
  const [commentsCount, setCommentsCount] = React.useState(0)
  const [likesCount, setLikesCount] = React.useState();
  const [show, setShow] = React.useState(false)
  React.useEffect(() => {
    const userRef = doc(DB, "users", comment?.uid);
    getDoc(userRef).then((doc) => setUser({
      id: doc?.id,
      ...doc?.data()
    }));
  }, [comment?.uid])
  return (

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          {user?.displayName
          ?<Avatar sx={{ bgcolor:user?.photoURL ? 'transparent' :  stringToColor(user?.displayName)}} src={user?.photoURL} {...stringAvatar(user?.displayName)}/>
          : <Avatar src={user?.photoURL} />
        }
        </ListItemAvatar>
        <ListItemText
          primary={ <React.Fragment>
             {user?.displayName ?
            <Typography
            fontSize={mobile ? 14 : 16}
            fontWeight={500}
            color="#051E40"
            >
              {user?.displayName}
            </Typography>
              :
              <Skeleton variant='text' sx={{width:"10%"}}/>
              }
      
          </React.Fragment>}
          secondary={
            <React.Fragment>
              <Stack direction={"column"} spacing={mobile? 1 : 2}>
              <Typography
                sx={{ display: 'block', marginTop:'10px',borderRadius:'5px', p:'10px', backgroundColor:'#e8eef991'}}
                variant="body2"
                color="#333333"
              >
               {comment?.mentioned && comment?.mentioned?.length > 0 ? <ItemMentioned post={comment} limit={0} /> : <ItemText limit={400} text={comment?.text} />}
              </Typography>
              <Stack sx={{width: mobile?"100%" : "55%"}}>
              <LinkWithThumbnail data={comment} limit={100} small/>
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={'center'} justifyContent={'flex-start'} >
                <Stack width={mobile && "15%"}>
                <CommentLikeMenu mobile={mobile} data={{...comment, user}} timelineId={timelineId}  postuid={postuid} likesCount={likesCount}/>
                </Stack>
                <Button variant={"text"} size={"small"} color={"secondary"}  disableRipple sx={{fontSize: mobile && 12, p:0, color: '#8F8F8F',"&:hover": {textDecoration:"underline"}}} onClick={() => setShow(!show)}>Reply</Button>
                <Typography variant={"body2"} fontSize={mobile ? 12 : 14}>
                  {comment?.createdAt?.seconds && moment(comment?.createdAt?.seconds * 1000).fromNow(true)}
                  {/*{ moment(new Date(comment?.seconds * 1000)).max(new Date())}*/}
                </Typography>
                {mobile && <Box flex={1}/>}
                <CommentsLikesCountCompnent data={{...comment, user}} timelineId={timelineId} postuid={postuid} cb={(data) => setLikesCount(data)}/>
              </Stack>
              {commentsCount > 0 && !show && <Typography fontSize={mobile&&12} onClick={() => setShow(true)} sx={{cursor:"pointer", "&:hover": {textDecoration:"underline"}, }} variant={"caption"} color={'#888888'} noWrap={true} paragraph={true} pr={0}>{`${commentsCount} ${commentsCount === 1 ? "Reply" : "Replies"}`}</Typography>}
              
              </Stack>
              {comment && <CommentReplies mobile={mobile} commentsCount={commentsCount} comment={{...comment, user}} timelineId={timelineId} postuid={postuid} key={'comment-reply' + comment?.id}
                               countCallback={(count) => {
                                 setCommentsCount(count)
                                 // setShow(count > 0)
                               }}
                               show={show}/>}
              
            </React.Fragment>
          }
        />
      </ListItem>

  );
}