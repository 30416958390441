import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

export default function Spinner() {
  return (
    <Stack sx={{ flex: 1, backgroundColor: '#fff', height: '100vh', width:'100%'}}  direction="column"
           justifyContent="center"
           alignItems="center">
      <CircularProgress color="primary"/>
    </Stack>
  );
}