import axios from "axios";


export default function ReferAFriend(data) {

  return axios.post(
    'https://us-central1-astranovaapp.cloudfunctions.net/api/refer-a-friend',
    // 'http://localhost:5001/astranovaapp/us-central1/api/refer-a-friend',
    {...data},
    {headers: {'Content-Type': 'application/json'}}
  )

}