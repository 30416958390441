import React from "react";
import {collection, getDocs, onSnapshot, orderBy, query, where} from "firebase/firestore";
import  {DB} from "../../../../config/firebase/firebase";

import {Box, Container, Grid, Stack, Typography} from "@mui/material";
import InvestorListItem from "../../../../screens/mobile/components/common/investor-list-item";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import {AuthUserContext} from "../../../../providers/auth-provider";
import {useNavigate} from "react-router-dom";
import ExpertListItem from "../../../../screens/mobile/components/common/expert-list-item";
import BusinessDeveloperListItem from "../../../../screens/mobile/components/common/business-developer-list-item";


export const BusinessDeveloperList = () => {
  const [investors, setInvestor] = React.useState([])
  const [search, setSearch] =React.useState('')
  const {profile} = React.useContext(AuthUserContext);
  React.useEffect(() => {
    debugger
    const q = query(
      collection(DB, "users"),
      where('userType', '==', 'business-developer'),
      where('onboard', '==', true),
      orderBy("createdAt", "desc")
    );
    const unSubs = onSnapshot(q, (querySnap) => {
      // debugger
      if (querySnap.size > 0) {
        const list = []
        querySnap.forEach((doc) => {
          list.push({id: doc.id, ...doc.data()})
        });

        setInvestor(list)
      }
    });
    return () => unSubs;
  }, [])
  return(
    <>
      <Container maxWidth={'xl'}  sx={{my: 5, textAlign: 'left'}} >
        <Stack direction={'row'} spacing={5} sx={{width: '100%', mb: 5}}>
          <div style={{flex: 1}}>
            <Typography variant={'h4'} sx={{fontWeight: 700}}>Hello {profile?.displayName} &nbsp; 👋</Typography>
            <Typography variant={"body1"} color={'secondary'}>Welcome to  ASTRANOVA</Typography>
          </div>
          <Box
            component="form"
            sx={{ p: '2px 10px', display: 'flex', alignItems: 'center', width: 337, height: 50, borderRadius: '12px',backgroundColor: '#E4EAF5' }}
          >
            <IconButton type="button" sx={{ p: '10px', }} aria-label="search">
              <SearchIcon sx={{ color:'#051E40BF'}}/>
            </IconButton>
            <InputBase
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              sx={{ ml: 1, flex: 1 ,color:'#051E40BF',  }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search post' }}
            />
          </Box>
        </Stack></Container>
      <Container maxWidth={'lg'}  sx={{my: 5, textAlign: 'left'}} >

        <Grid container spacing={5}>
          {investors.length > 0 &&
            investors.length > 0 && investors.filter((post) => {
              if (search !== '') {
                const reg = new RegExp(search);
                return reg.test(post.displayName) &&  post
              } else {
                return post
              }

            }).sort((a, b) => b.createdAt - a.createdAt).map( (investor) => (        <BusinessDeveloperListItem data={investor} key={investor?.id} href={`/m/${profile?.userType}/business-developer-details/${investor?.id}`}/>

            ))

          }
        </Grid>
      </Container>

    </>
  )

}