import {Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import React from "react";
import moment from "moment";
import {EditSharp} from "@mui/icons-material";
import AboutStartupComponent from "./edit/startup/about-startup-component";
const styleFont = {
	backgroundColor: "#F6F8FA",
	padding: "8px 15px",
	borderRadius: "5px",
	fontSize: 14,
	marginRight: "5px",
};

export default function StartupAbout({ profile , cb}) {
	const [seeMore, setSeeMore] = React.useState(false)
	const [open,setOpen] = React.useState(false)
	const profileRegex = /profile|admin\/user-details/
	return (
		<>

			{profile.userType === "startup" && 
			<>
			<Stack
                direction={"column"}
                spacing={3}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}>
				  <Stack direction={"row"} spacing={2} >
					  <Typography fontSize={20} fontWeight={600} style={{flex: 1}}>About Startup</Typography>   {profileRegex.test(window.location.pathname) &&  <IconButton onClick={() => setOpen(true)}>
					  <EditSharp />
				  </IconButton>}
				  </Stack>
				<Stack direction={"column"} spacing={4}>
				<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Website
								<br />{" "}
								<font style={styleFont}>
									{!profile?.startup?.website || profile?.startup?.website === '' ? 'N/A' : profile?.startup?.website }{" "}
								</font>
							</Typography>
						<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Founding Date
								<br />{" "}
								<font style={styleFont}>
									{profile?.startup?.foundingDate &&
										moment(
											profile?.startup?.foundingDate
										).format("DD-MM-YYYY")}{" "}
								</font>
							</Typography>
							<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>
					{console.log(profile)}

						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Formally registered date
								<br />{" "}
								<font style={styleFont}>
									{profile?.startup?.registeredDate &&
										moment(
											profile?.startup?.registeredDate
										).format("DD-MM-YYYY")}
								</font>
							</Typography>
							<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>

			<Stack direction={"column"} spacing={2}>
				<Stack direction={'row'} spacing={2}>
					<Typography variant={'h6'} sx={{lineHeight:2}}>
					Sector<br/> 
					{profile?.startup?.sector &&
						profile?.startup?.sector.length > 0 &&
						profile?.startup?.sector.map(
							(ex, i) => (
								<font style={styleFont}>
													{ex}


													</font>
												)
												)}
					</Typography>
				</Stack>
				<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>

		{profile?.startup?.additionalDetails && profile?.startup?.additionalDetails.length > 0 &&
						<Stack direction={'column'} spacing={1}>
							<Typography variant={'h6'} sx={{lineHeight:2}}>
								Additional Details<br/>
								<Grid container gap={1}
								>
									{profile?.startup?.additionalDetails &&
										profile?.startup?.additionalDetails.length > 0 &&
										profile?.startup?.additionalDetails.map(
											(ex, i) => (<Grid key={i}>
												<font style={styleFont}>
													{ex}


												</font>
											</Grid>))

									}
								</Grid>

							</Typography>
							<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>
}
			{/*<Grid >
			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				Additional Details<br/>
				{profile?.startup?.additionalDetails &&
					profile?.startup?.additionalDetails
				}
				

				</Typography>
			  </Stack>
			</Grid>*/}

			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				Markets<br/>
					<Grid container gap={1}
						>
						{profile?.startup?.market &&
							profile?.startup?.market.length > 0 &&
							profile?.startup?.market.map(
								(ex, i) => (<Grid key={i}>
									<font style={styleFont}>
										{ex}


									</font>
								</Grid>))

						}
					</Grid>

				</Typography>
				<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
			
			{seeMore?
			<>
			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				Business Type<br/> 
				<font style={styleFont}>
				{profile?.startup?.businessType}
				</font>
				

				</Typography>
				<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
			

					
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Female in founding team
								<br />{" "}
								<font style={styleFont}>
									{profile?.startup?.femaleInFoundingTeam
										? "Yes"
										: "No"}
								</font>
							</Typography>
							<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>
					

					
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Ethnic Minority in founding team
								<br />{" "}
								<font style={styleFont}>
									{profile?.startup
										?.ethnicMinorityInFundingTeam
										? "Yes"
										: "No"}
								</font>
							</Typography>
							<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>
					

					
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Immigrant in funding team
								<br />{" "}
								<font style={styleFont}>
									{profile?.startup?.immigrantInFundingTeam
										? "Yes"
										: "No"}
								</font>
							</Typography><hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>
					
					
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Distributed team
								<br />{" "}
								<font style={styleFont}>
									{profile?.startup?.distributedTeam
										? "Yes"
										: "No"}
								</font>
							</Typography>
						</Stack>
						<Stack alignItems={'center'}>
			<Box>
			<Button size={"small"} variant='outlined' color="secondary"  sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See Less</Button>
			</Box>
				 </Stack> 
			</>:
			<Stack alignItems={'center'}>
			<Box>
			<Button size={"small"} variant='outlined' color="secondary"  sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See More</Button>
			</Box>
				 </Stack> 
			}
					
				</Stack>
				</Stack>
			</>
			}

			<AboutStartupComponent profile={profile?.startup} open={open} startupProfile={profile} callback={(data) => {
				cb(data);
				setOpen(false)}} handleClose={() => setOpen(false)}/>
		</>
	);
}
