import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton, Portal, useMediaQuery} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import AboutEditFormExpert from "../../../../../screens/expert/edit/about-edit-form";
import {ArrowBack} from "@mui/icons-material";
import { BUSINESS_DEVELOPER } from '../../../../../utils/enums/labels.enum';
import PostNotification from "../../../../../api/post-notification";

export default function AboutBusinessDeveloperComponent({profile,open, handleClose, businessDeveloperProfile, callback }) {
  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  const regexAdmin = /admin\/user-details/
  const copyProfile = regexAdmin.test(window.location.pathname) ? businessDeveloperProfile : {...context.profile}
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? businessDeveloperProfile.uid : user.uid);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [isLoading, setLoading] = React.useState(false);
  return (
    <Portal>
      <Dialog
        fullScreen={!matches ? true: false}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
          {!matches && <IconButton onClick={() => handleClose()}>
            <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
          </IconButton>}
          {`Edit ${BUSINESS_DEVELOPER.SINGULAR_LABEL} Details`}
        </DialogTitle>
        <DialogContent sx={{px: !matches ? 0 : ''}}>
          <br/>
          <AboutEditFormExpert
            data={profile}
            cbBack={handleClose}
            cbNext={async (data) => {
              setLoading(true)
              await setDoc(
                userRef,
                {
                  businessDeveloper: {...profile,...data},
                  updatedById:context?.user?.uid,
                  updatedBy: regexAdmin.test(window.location.pathname) ? 'admin' : 'user',
                  updatedAt: new Date().getTime()
                },
                { merge: true }
              )
  
              if (!regexAdmin.test(window.location.pathname)) {
                await setProfile({...copyProfile, businessDeveloper  : {...profile,...data}})
  
                handleClose()
              } else{
                /*Todo: FOR ADMIN*/
                callback({...copyProfile, businessDeveloper  : {...profile,...data}})
                debugger
                PostNotification({
                  type: 'admin-update-profile',
                  imageURL: '/assets/system-noti-icon.png',
                  section: 'Growth Expert',
                  message: `information has been updated by the Admin.`,
                  uid: businessDeveloperProfile.uid ,
                  createdBy: user?.uid
    
                }).then()
              }
              setLoading(false)
  
  
            }}
            isLoading={isLoading}
          />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
