import React from "react";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import {
  Container,
  Stack,
  Typography,
  Button,
  Box,
  Portal,
  Skeleton,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { DB } from "../../../config/firebase/firebase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import PostRequestDialog from "../../../components/post-request-dialog";
import dataJson from "../../../const/data.json";
import RequestListItemAdmin from "./request-list-item";
import moment from "moment/moment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import OpenRequestListItem from "../../../components/common/request/open-request-list-item";

export const RequestsListAdmin = () => {
  const { profile } = React.useContext(AuthUserContext);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [requests, setRequests] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [inputbtn, setinputbtn] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [lastDoc, setLastDoc] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const navigator = useNavigate();
  const [jobTypes, setJobTypes] = React.useState([]);
  const [onsites, setOnsites] = React.useState([]);
  const [userType, setUserType] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);
  const [sectors, setSectors] = React.useState([]);
  const [markets, setMarkets] = React.useState([]);
  const [experiences, setExperiences] = React.useState([]);
  const [freeSearch, setFreeSearch] = React.useState([]);

  /*const filter = async () => {
      setLoading(true)
      if (jobTypes.length > 0) {
          const q = query(
              
              collection(DB, "help-requests"),
              where('free_search', 'array-contains-any', [...jobTypes, 'Invetsor', 'Industry Expert']),
              orderBy("createdAt", "desc"),
              startAfter(lastDoc),
              limit(20)
          );
          getDocs(
              q
          ).then((querySnapshot) => {
              if (querySnapshot.size > 0) {
                  const list = [];
                  querySnapshot.forEach((doc) => {
                      list.push({ id: doc.id, ...doc.data() });
                      
                      // console.log(doc.id, " => ", doc.data());
                  });
                  setRequests(list);
                  setLastDoc(querySnapshot.docs[querySnapshot.docs.length-1])
                  setLoading(false)
                  
                  
              }
              
          });
      }
      
  }*/
  /*React.useEffect(() => {
      debugger
      if (jobTypes.length > 0) {
          filter().then()
          
      } else{
          load()
      }
  }, [jobTypes])*/
  const [isFilters, setFilters] = React.useState(false);
  const clearFilter = () => {
    setJobTypes([]);
    setOnsites([]);
    setUserType([]);
    setLocations([]);
    setLanguages([]);
    setFreeSearch([]);
  };
  const filters = () => {
    setRequests([]);
    setFreeSearch([
      ...new Set([
        ...userType.map((type) =>
          type === "Growth Expert"
            ? "Business Developer"
            : type === "Industry Expert"
            ? "Expert"
            : type
        ),
        ...locations,
        ...onsites,
        ...jobTypes,
        ...languages,
        ...experiences,
        ...sectors,
        ...markets,
      ]),
    ]);
  };
  const loadMore = async () => {
    setLoading(true);

    let q;
    if (freeSearch?.length > 0) {
      q = query(
        collection(DB, "help-requests"),
        where("free_search", "array-contains-any", [...freeSearch]),
        where("deadline", ">", moment().format("YYYY-MM-DD")),
        startAfter(lastDoc),
        limit(10)
      );
    } else {
      q = query(
        collection(DB, "help-requests"),
        where("deadline", ">", moment().format("YYYY-MM-DD")),
        startAfter(lastDoc),
        limit(10)
      );
    }

    const querySnap = await getDocs(q);
    if (querySnap.size > 0) {
      const list = [...requests];
      querySnap.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      // console.log(list)
      setRequests(list);
      querySnap.size < 10
        ? setLastDoc(undefined)
        : setLastDoc(querySnap.docs[querySnap.docs.length - 1]);
    } else {
      setLastDoc(undefined);
    }
    setLoading(false);
  };
  const load = () => {
    setLastDoc(undefined);

    let q;
    if (freeSearch?.length > 0) {
      q = query(
        collection(DB, "help-requests"),
        where("free_search", "array-contains-any", [...freeSearch]),
        where("deadline", ">", moment().format("YYYY-MM-DD")),
        limit(30)
      );
    } else {
      q = query(
        collection(DB, "help-requests"),
        where("deadline", ">", moment().format("YYYY-MM-DD")),
        limit(30)
      );
    }
    setLoading(true);
    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });

          // console.log(doc.id, " => ", doc.data());
        });
        setRequests(list);

        setLastDoc(
          querySnapshot?.size < 30
            ? undefined
            : querySnapshot.docs[querySnapshot.docs.length - 1]
        );
        setLoading(false);
      } else {
        setLoading(false);
        setRequests([]);
      }
    });
  };
  React.useEffect(() => {
    load();
  }, [freeSearch]);
  React.useEffect(() => {
    filters();
  }, [
    userType,
    jobTypes,
    onsites,
    locations,
    languages,
    sectors,
    markets,
    experiences,
  ]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    anchorOrigin: { vertical: "bottom", horizontal: "center" },
  };
  return (
    <Container maxWidth={"lg"} sx={{ mt: 5, textAlign: "left" }}>
      <Stack direction={"column"} spacing={3}>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            onClick={() => {
              // if (isFilters) clearFilter()
              setFilters(!isFilters);
            }}
            variant={"outlined"}
            sx={{ px: 1, py: 0.5, borderRadius: "6px" }}
            size={"small"}
            endIcon={
              isFilters ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownSharpIcon />
              )
            }
          >
            Filter
          </Button>

          <div style={{ flex: 1 }} />
          {/*<Button variant={'contained'} color={'success'} sx={{textTransform:'Lowercase'}}><span style={{textTransform:"capitalize"}}>Post&nbsp;</span> a request</Button>*/}
          {/* <Tooltip
						title={<h3 style={{ color: '#E4EAF5' }}>Coming soon</h3>}
						placement="bottom" >*/}
          <>
            <Box
              onSubmit={(event) => event.preventDefault()}
              component="form"
              sx={{
                p: "2px 10px",
                display: "flex",
                alignItems: "center",
                width: 337,
                height: 40,
                borderRadius: "8px",
                backgroundColor: "#E4EAF5",
              }}
            >
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={(e) => setSearch(inputbtn)}
                disabled={true}
              >
                <SearchIcon sx={{ color: "#051E40BF" }} />
              </IconButton>
              <Autocomplete
                freeSolo
                disableClearable
                sx={{
                  flex: 1,
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                value={inputbtn}
                options={[...dataJson.searchQueries].map((option) => option)}
                onChange={(event, newValue) => {
                  setinputbtn(newValue);
                }}
                onInputChange={(event, value) => {
                  if (event.type === "change") {
                    setinputbtn(value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    setSearch(inputbtn);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // onChange={(e) =>{ setinputbtn(e.target.value); console.log(inputbtn)} }
                    sx={{
                      flex: 1,
                      color: "#051E40BF",
                      input: {
                        "&::placeholder": {
                          opacity: 1,
                        },
                      },
                    }}
                    placeholder="Search"
                  />
                )}
              />
              {/* <InputBase
							onChange={(e) => setinputbtn(e.target.value)}
							value={inputbtn}
							onKeyDown={(e)=>{
								if(e.code==="Enter"){
									setSearch(inputbtn)
								}
							}}
							sx={{ ml: 1, flex: 1, color: "#051E40BF",input:{
								"&::placeholder":{
								  opacity:1
								}
							  }, }}
							placeholder="Search"
							inputProps={{ "aria-label": "search post", "aria-autocomplete":"both"}}
							// disabled={true}
						/> */}
            </Box>
          </>
        </Stack>
        {isFilters && (
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <FormControl
              sx={{
                my: 1,
                flex: 1,
                backgroundColor: "#fff",
                borderRadius: "30px",
              }}
              size={"small"}
            >
              <InputLabel id="user-type-multiple-checkbox-label">
                User Type
              </InputLabel>
              <Select
                labelId="user-type-multiple-checkbox-label"
                id="user-type-multiple-checkbox"
                multiple
                value={userType}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setUserType(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                sx={{ borderRadius: "30px" }}
                input={
                  <OutlinedInput
                    inputProps={{ sx: { borderRaduis: "30px!important" } }}
                    label="User Type"
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {[
                  "Industry Expert",
                  "Investor",
                  "Service provider",
                  "Growth Expert",
                ].map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={userType.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                my: 1,
                flex: 1,
                backgroundColor: "#fff",
                borderRadius: "30px",
              }}
              size={"small"}
            >
              <InputLabel id="market-multiple-checkbox-label">
                Market
              </InputLabel>
              <Select
                labelId="market-multiple-checkbox-label"
                id="market-multiple-checkbox"
                multiple
                value={markets}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setMarkets(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                sx={{ borderRadius: "30px" }}
                input={
                  <OutlinedInput
                    inputProps={{ sx: { borderRaduis: "30px!important" } }}
                    label="Markets"
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {[...dataJson.markets].map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={markets.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                my: 1,
                flex: 1,
                backgroundColor: "#fff",
                borderRadius: "30px",
              }}
              size={"small"}
            >
              <InputLabel id="sector-multiple-checkbox-label">
                Sectors
              </InputLabel>
              <Select
                labelId="sector-multiple-checkbox-label"
                id="sector-multiple-checkbox"
                multiple
                value={sectors}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setSectors(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                sx={{ borderRadius: "30px" }}
                input={
                  <OutlinedInput
                    inputProps={{ sx: { borderRaduis: "30px!important" } }}
                    label="Sectors"
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {[
                  "Adtech",
                  "Advanced manufacturing",
                  "Agtech",
                  "Artificial intelligence and machine learning (AI/ML)",
                  "Audiotech",
                  "Augmented reality (AR)",
                  "Autonomous cars",
                  "B2B payments",
                  "Beauty",
                  "Big Data",
                  "Cannabis",
                  "Carsharing",
                  "Cleantech",
                  "Climate tech",
                  "Cloudtech and DevOps",
                  "Construction technology",
                  "Cryptocurrency and blockchain",
                  "Cybersecurity",
                  "Digital health",
                  "Ecommerce",
                  "Edtech",
                  "Ephemeral content",
                  "eSports",
                  "Femtech",
                  "Fintech",
                  "Foodtech",
                  "Gaming",
                  "Healthtech",
                  "HRtech",
                  "Impact investing",
                  "Industrials",
                  "Infrastructure",
                  "Insurtech",
                  "Internet of Things (IoT)",
                  "Legal tech",
                  "Life sciences",
                  "Lifestyles of Health and Sustainability (LOHAS) and wellness",
                  "Manufacturing",
                  "Marketing tech",
                  "Micro-mobility",
                  "Mobile",
                  "Mobile commerce",
                  "Mobility tech",
                  "Mortgage tech",
                  "Nanotechnology",
                  "Oil and gas",
                  "Oncology",
                  "Pet tech",
                  "Real estate tech",
                  "Restaurant tech",
                  "Ridesharing",
                  "Robotics and drones",
                  "Software as a service (SaaS)",
                  "Space tech",
                  "Supply chain technology",
                  "Technology, media and telecommunications (TMT)",
                  "Virtual reality (VR)",
                  "Wearables and quantified self",
                  "3D printing",
                ].map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={sectors.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                my: 1,
                flex: 1,
                backgroundColor: "#fff",
                borderRadius: "30px",
              }}
              size={"small"}
            >
              <InputLabel id="location-multiple-checkbox-label">
                Location
              </InputLabel>
              <Select
                labelId="location-multiple-checkbox-label"
                id="location-multiple-checkbox"
                multiple
                value={locations}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setLocations(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                sx={{ borderRadius: "30px" }}
                input={
                  <OutlinedInput
                    inputProps={{ sx: { borderRaduis: "30px!important" } }}
                    label="Location"
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {[...dataJson.cities].map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={locations.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                my: 1,
                flex: 1,
                backgroundColor: "#fff",
                borderRadius: "30px",
              }}
              size={"small"}
            >
              <InputLabel id="Experience-multiple-checkbox-label">
                Experience
              </InputLabel>
              <Select
                labelId="Experience-multiple-checkbox-label"
                id="Experience-multiple-checkbox"
                multiple
                value={experiences}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setExperiences(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                sx={{ borderRadius: "30px" }}
                input={
                  <OutlinedInput
                    inputProps={{ sx: { borderRaduis: "30px!important" } }}
                    label="Location"
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {[0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={experiences.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                my: 1,
                flex: 1,
                backgroundColor: "#fff",
                borderRadius: "30px",
              }}
              size={"small"}
            >
              <InputLabel id="language-multiple-checkbox-label">
                Languages
              </InputLabel>
              <Select
                labelId="language-multiple-checkbox-label"
                id="language-multiple-checkbox"
                multiple
                value={languages}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setLanguages(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                sx={{ borderRadius: "30px" }}
                input={
                  <OutlinedInput
                    inputProps={{ sx: { borderRaduis: "30px!important" } }}
                    label="Location"
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {[...dataJson.languages].map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={languages.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              sx={{
                my: 1,
                flex: 1,
                backgroundColor: "#fff",
                borderRadius: "30px",
              }}
              size={"small"}
            >
              <InputLabel id="job-type-multiple-checkbox-label">
                Job Type
              </InputLabel>
              <Select
                labelId="job-type-multiple-checkbox-label"
                id="job-type-multiple-checkbox"
                multiple
                value={jobTypes}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setJobTypes(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                sx={{ borderRadius: "30px" }}
                input={
                  <OutlinedInput
                    inputProps={{ sx: { borderRaduis: "30px!important" } }}
                    label="Job Type"
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                <MenuItem key={"part-time"} value={"part-time"}>
                  <Checkbox checked={jobTypes.indexOf("part-time") > -1} />
                  <ListItemText
                    primary={"part-time"}
                    sx={{ textTransform: "capitalize" }}
                  />
                </MenuItem>
                <MenuItem key={"full-time"} value={"full-time"}>
                  <Checkbox checked={jobTypes.indexOf("full-time") > -1} />
                  <ListItemText
                    primary={"full-time"}
                    sx={{ textTransform: "capitalize" }}
                  />
                </MenuItem>
                <MenuItem key={"contract"} value={"contract"}>
                  <Checkbox checked={jobTypes.indexOf("contract") > -1} />
                  <ListItemText
                    primary={"contract"}
                    sx={{ textTransform: "capitalize" }}
                  />
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl
              sx={{
                my: 1,
                flex: 1,
                backgroundColor: "#fff",
                borderRadius: "30px",
              }}
              size={"small"}
            >
              <InputLabel id="onsites-multiple-checkbox-label">
                On-site/Remote
              </InputLabel>
              <Select
                labelId="onsites-multiple-checkbox-label"
                id="onsites-multiple-checkbox"
                multiple
                value={onsites}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setOnsites(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                sx={{ borderRadius: "30px" }}
                input={
                  <OutlinedInput
                    inputProps={{ sx: { borderRaduis: "30px!important" } }}
                    label="On-site/Remote"
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                <MenuItem key={"On-site"} value={"Onsite"}>
                  <Checkbox checked={onsites.indexOf("Onsite") > -1} />
                  <ListItemText primary={"On-site"} />
                </MenuItem>
                <MenuItem key={"Hybrid"} value={"Hybrid"}>
                  <Checkbox checked={onsites.indexOf("Hybrid") > -1} />
                  <ListItemText primary={"Hybrid"} />
                </MenuItem>
                <MenuItem key={"Remote"} value={"Remote"}>
                  <Checkbox checked={onsites.indexOf("Remote") > -1} />
                  <ListItemText primary={"Remote"} />
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        )}

        {requests &&
          requests.length > 0 &&
          requests
            .filter((requests) => {
              if (search !== "") {
                const reg = new RegExp(search.toLowerCase());
                // console.log(search);
                return (
                  reg.test(requests.searchFor?.toString().toLowerCase()) ||
                  reg.test(requests.skills?.toString().toLowerCase()) ||
                  reg.test(requests.requestFor?.toLowerCase())
                );
              } else {
                return requests;
              }
            })
            .sort(
              (a, b) => b.createdAt.seconds * 1000 - a.createdAt.seconds * 1000
            )
            .map((request) => (
              <RequestListItemAdmin
                request={request}
                key={request.id}
                searchQuery={searchQuery}
              />
            ))}
        {loading && (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            sx={{ borderRadius: "12px" }}
            height={130}
          />
        )}

        {lastDoc && (
          <Stack
            direction={"row"}
            sx={{ width: "100%" }}
            py={5}
            justifyContent="center"
            alignItems="center"
          >
            <Button size={"small"} onClick={loadMore}>
              Load more
            </Button>
          </Stack>
        )}
        <Box sx={{ height: 40 }}></Box>

        {/*<TableContainer  sx={{ boxShadow: 'none'}}>
          <Table sx={{ minWidth: 650 }} aria-label="Open requests and responses data table ">
            <TableHead>
              <TableRow sx={{textTransform: 'uppercase'}}>
                <TableCell>Requests</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Budget</TableCell>
                <TableCell align="center">Due date</TableCell>
                <TableCell align="center">Job type</TableCell>
                <TableCell align="center"> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{border: '1px solid #ddd'}}>



              {

                requests && requests.length > 0 && requests.sort((a, b) => b.createdAt.seconds *1000 - a.createdAt.seconds *1000 ).map((request, i) => (

                  <TableRow
                    key={i}
                    sx={{'&:last-child td, &:last-child th': { border: 0 }, backgroundColor: '#F6F8FA', cursor: 'pointer'}}
                  >
                    <TableCell component="th" scope="row" onClick={() => navigator(`/${profile.userType}/open-requests-details`, {state: {request}})}>
                      <Typography  variant={'body1'} color={'secondary'} noWrap={true} sx={{maxWidth: 500}}>
                        {request.requestFor}

                      </Typography>
                      <font color={'#8F8F8F'}>
                        {request.location}
                      </font>

                    </TableCell>
                    <TableCell align="center"  onClick={() => navigator(`/${profile.userType}/open-requests-details`, {state: {request}})} ><Badge variant={'dot'} invisible={false} color={(!request.stage || request.stage === 'activate')  ? 'primary' : request.stage === 'closed' ? "error" : 'warning'}/>&nbsp;&nbsp;&nbsp;{request.stage && request.stage === 'activate' ? 'Open' : !request.stage ? 'Open' : request.stage}</TableCell>

                    <TableCell align="center"  onClick={() => navigator(`/${profile.userType}/open-requests-details`, {state: {request}})}><font style={{textTransform:'uppercase'}}>{request.currency}</font> {request?.amount} {request.minBudget && `${request.minBudget} - ${request.maxBudget}`}</TableCell>
                    <TableCell align="center"  onClick={() => navigator(`/${profile.userType}/open-requests-details`, {state: {request}})}>{moment(request.fundingCloseDate || request.to).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="center"  onClick={() => navigator(`/${profile.userType}/open-requests-details`, {state: {request}})}>{request?.jobType}</TableCell>
                    <TableCell align="center">
                      {request.uid !== profile?.uid && <StartConversation data={request} key={request.id}/>}


                    </TableCell>
                  </TableRow>
                ))

              }
            </TableBody>
          </Table>
        </TableContainer>*/}
      </Stack>

      <Portal>
        <PostRequestDialog
          data={open}
          callback={(res) => {
            setOpen(res.close);
            if (res.selected && res.selected !== "requestForFunding") {
              navigator(`/${profile.userType}/open-requests-post`);
            } else if (res.selected && res.selected === "requestForFunding") {
              navigator(`/${profile.userType}/post-funding-request`);
            }
            console.log("callback", res);
          }}
        />
      </Portal>
    </Container>
  );
};
