import * as React from "react";
import { AuthUserContext } from "../../../providers/auth-provider";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  Typography,
  Box,
  styled,
  Portal,
  Dialog,
  DialogContent,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import { doc, getDoc, deleteDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckIcon from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import OffersRightPanel from "../../../screens/mobile/components/common/timeline/offer-right-panel"
import OfferClaimCardC from "./offers/offer-claim-card copy";
import RemainingHoursOrDate from "./offers/offer-date-format";
import OfferClaimCard from "./offers/offer-claim-card";
import OfferFavorite from "./offers/offer-fav";
const FilledCircleCheckedIcon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 21px;
  border-radius: 50%;
  background-color: blue; 
`;

export default function OfferDetailsClaim() {
  const { profile } = React.useContext(AuthUserContext);
  const [open, setOpen] = React.useState(false);
  const { offerId } = useParams();
  const [offer, setOffer] = React?.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigator = useNavigate();
  const [selectedOption, setSelectedOption] = React.useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedOption(offer && offer.isActive ? 'active' : 'close');
  };
  React?.useEffect(() => {
    getOffers(offerId);
  }, [offerId]);

  const getOffers = async (offerId) => {
    const offerRef = doc(DB, "offers", offerId);
    const myOffer = await getDoc(offerRef)
    await setOffer({ id: offerId, ...myOffer.data() });
    debugger
    setSelectedOption(offer && offer.isActive ? 'active' : 'close');
  }
  return (
    <>
      <Container sx={{ textAlign: "left", overflowX: "hidden" }}>
        <Stack direction={'row'} spacing={3} justifyContent={'flex-start'} sx={{ width: '100%' }}>
          <div style={{ flex: 1 }}>
            <Typography style={{ fontSize: "18px" }} sx={{ fontWeight: 700 }}>Hello {profile?.displayName} &nbsp; 👋</Typography>
            <Typography style={{ fontSize: "12px" }} color={'secondary'}>Discover amazing deals and exclusive offers just for you!</Typography>
          </div>
        </Stack>
      </Container>


      {offer && <Container sx={{ py: "5%", overflowX: "hidden" }}>

        <Stack spacing={2} justifyContent={"center"}>


          <Box
            sx={{
              // px: "15%",
              py: "1%",
              flex: 1,
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              background: "#fff",
              borderRadius: "15px",
              textAlign: "left",

            }}
          >

            <Stack
              justifyContent="flex-start"
              alignItems="flex-start"

              sx={{ ml: 3 , mt : 2}}
            > <IconButton sx={{ width: 15, height: 19, fontWeight: 600 }} onClick={() => navigator(-1)}>
                <ArrowBackIcon sx={{ fontSize: 28, fontWeight: 600 }} />
              </IconButton></Stack>
            {/* {(Math.floor((new Date().getTime() -  new Date(offer?.from).getTime())/(1000 * 60 *60)))<=24 && //to check if the offer is live for 24 hours
                  <Chip
                  variant="contained"
                  sx={{
                    my: 0,
                    backgroundColor: "#E0E3FF",
                    px: 0.5,
                    maxWidth: 125,
                    height: 24,
                    borderRadius: "5px",
                  }}
                  label={"New"}
                  
                />
          } */}
            <Stack justifyContent="center"
              alignItems="center">
              {offer &&
                <OfferClaimCardC
                  sx={{ maxWidth: '100%' }}
                  offer={offer}
                  callback={(e) => {
                    e.stopPropagation();
                  }}
                  details="true"

                />
              } </Stack>
            {/* <OfferFavorite data={offer} /> */}
            {/* {offer.isHotOffer &&
                  <IconButton color="primary" style={{marginLeft:"-17px",marginTop:"-26px"}}>
                        <img src={'/assets/Fire.png'} alt="Custom Icon" />
                  </IconButton>
              } */}


            <Stack mt={2} p={2} direction={'row'}  alignItems={'flex-start'} >
              <Stack direction={"column"}>
                <Typography style={{ fontSize: "12px" }} fontWeight={600} >
                  {offer?.headingOne}
                </Typography>
                <Typography style={{ fontSize: "12px" }} fontWeight={400} >
                  {offer?.headingTwo}
                </Typography>
              </Stack>


            </Stack>

            <Stack
              direction={"column"}
              justifyContent="flex-start"
              alignItems="flex-start"
              p={2}
            >
              {offer &&
                offer.section &&
                offer.section.map((sec, i) => (
                  <>
                    <Stack
                      justifyContent="flex-start"
                      alignItems="flex-start"
                     
                    >
                      <Stack
                        direction={"column"}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                       
                      >
                        <Typography sx={{ textAlign: "left" }} style={{ fontSize: "12px" }} fontWeight={600}>
                          {sec.title}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"column"}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      
                      >
                        <Typography
                          style={{ fontSize: "12px" }}
                          fontWeight={400}
                          component="pre"
                          sx={{
                            textAlign: "left",
                            wordBreak: "break-word",
                            display: "inline-block",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {sec.description}
                        </Typography>
                      </Stack>
                    </Stack>
                    {i !== offer.section.length - 1 ? (
                      <hr style={{ padding: 0, opacity: 0.5, width: "100%" }} />
                    ) : (
                      ""
                    )}
                  </>
                ))}
            </Stack>


          </Box>


          {/* <Stack direction={"column"} spacing={3} sx={{ width: 330 }}>
           
            <OffersRightPanel sx={{ maxWidth: '100%' }} screen="offer-details" width="true" />

          </Stack> */}


        </Stack>
      </Container>}
      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"sm"}
        >
          <DialogContent sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={2}>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                alignItems={"center"}
              >
                <img
                  src={"/assets/delete-confirmation.png"}
                  height={74}
                  width={80}
                ></img>
              </Stack>
              <Typography
                component="span"
                variant="h6"
                color="text.primary"
                sx={{
                  textAlign: "center",
                  flex: 1,
                  padding: 0,
                  fontWeight: 500,
                }}
              >
                Delete Offer
              </Typography>
              <Typography variant={"body2"} sx={{ textAlign: "center" }}>
                Are you sure you want to delete this offer? <br />
                This action cannot be undone.
              </Typography>
              <br />
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Button
                  variant={"outlined"}
                  sx={{
                    px: 5,
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  variant={"contained"}
                  sx={{
                    px: 5,
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  yes
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </>
  );
}
