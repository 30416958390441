import React from "react";
import { Button, Container, MenuItem, Stack, TextField,Typography } from "@mui/material";
import { useFormik } from "formik";
import InvestorProfileValidation from "../../../../validations/investor/investor-profile";

export default function InvestorFirstForm({ cbBack, cbNext, data }) {
  const formikFirst = useFormik({
    initialValues: data
      ? data
      : InvestorProfileValidation.InvestorFirstFormValues,
    validationSchema: InvestorProfileValidation.InvestorFirstForm,
    onSubmit: async (values) => {
      cbNext({
        ...values,
      });
    },
  });

  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}
    >
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={2}>
        <Stack direction={"column"} >
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Investor type *

							  </Typography>
          <TextField
            name={"investorType"}
            value={formikFirst.values.investorType}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.investorType &&
              Boolean(formikFirst.errors.investorType)
            }
            helperText={
              formikFirst.touched.investorType &&
              formikFirst.errors.investorType
            }
            //label={"Investor type"}
            select
            sx={{ textAlign: "left" }}
          >
            <MenuItem value={"Venture Capital Fund"}>
              Venture Capital Fund
            </MenuItem>
            <MenuItem value={"Angel Investor"}>Angel Investor</MenuItem>
            <MenuItem value={"CVC"}>CVC</MenuItem>
            <MenuItem value={"PE Fund"}>PE Fund</MenuItem>
            <MenuItem value={"Family Office"}>Family Office</MenuItem>
          </TextField>
          </Stack>

        </Stack>
      </Container>
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems="center"
        spacing={3}
        width={"100%"}
      >
        <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 5 }}
          style={{ paddingLeft: "15%", paddingRight: "15%" }}
          onClick={cbBack}
        >
          Back
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{ px: 5 }}
          style={{ paddingLeft: "15%", paddingRight: "15%" }}
          type={"submit"}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
}
