import * as React from "react";
import Typography from "@mui/material/Typography/Typography";
import Stack from "@mui/material/Stack/Stack";
import moment from "moment/moment";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import {
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
	DownloadDoneOutlined,
	DownloadingOutlined,
	DownloadOutlined,
	InsertDriveFile,
} from "@mui/icons-material";
import { AuthUserContext } from "../../../../providers/auth-provider";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { DB } from "../../../../config/firebase/firebase";
import DownloadFileHelper from "../../../../utils/download-file-helper";
export default function FileMessageComponent({ msg, type }) {
	const { profile } = React.useContext(AuthUserContext);

	React.useEffect(() => {
		// console.log(msg);
		debugger;
		if (msg && msg.receiver === profile?.uid && msg.read === false) {
			const msgRef = doc(DB, "messages", msg.id);
			setDoc(
				msgRef,
				{
					read: true,
					status: "seen",
					updatedAt: serverTimestamp(),
				},
				{ merge: true }
			).then();
		}
	}, [msg]);
	if (type === "r") {
		return (
			<Stack direction={"column"} spacing={1}>
				<Stack direction={"row-reverse"} spacing={1}>
					<Stack
						direction={"row"}
						spacing={1}
						sx={{
							p: 1,
							paddingX: 2,
							backgroundColor: "rgba(35, 61, 255, 0.6)",
							color: "#fff",
							borderRadius: "12px 0px 12px 12px",
							maxWidth: "70%",
						}}
						justifyContent="flex-end"
						alignItems="center">
						<Stack
							direction={"row"}
							spacing={2}
							color={"secondary"}>
							<InsertDriveFile />
							<Typography>{msg?.fileName}</Typography>
						</Stack>
						<IconButton
							onClick={() => {
								// console.log(msg.path);
								msg.path &&
									msg.path !== "" &&
									DownloadFileHelper(msg.path);
							}}>
							<DownloadOutlined sx={{ color: "#fff" }} />
						</IconButton>
					</Stack>
				</Stack>
				<Stack
					direction={"row"}
					spacing={0.5}
					alignItems="center"
					justifyContent="flex-end">
					<Typography sx={{ fontSize: "12px" }}>
						{msg.createdAt &&
							moment(msg.createdAt.seconds * 1000).format(

								`${moment(msg.createdAt.seconds * 1000).isSame(new Date(), "day") ? 'hh:mm a' : 'D-M-YYYY | hh:mm a'}`
							)}
					</Typography>

					{msg.read === true ? (
						<DoneAllOutlinedIcon
							style={{ color: "#4acfee", fontSize: 18 }}
						/>
					) : (
						<DoneAllOutlinedIcon
							style={{ color: "#ccc", fontSize: 18 }}
						/>
					)}
				</Stack>
			</Stack>
		);
	} else {
		return (
			<Stack direction={"column"} spacing={1}>
				<Stack direction={"row"} spacing={1}>
					<Stack
						direction={"row"}
						spacing={1}
						sx={{
							p: 1,
							paddingX: 2,
							backgroundColor: "#F6F8FA",
							borderRadius: "0px 12px 12px 12px",
							maxWidth: "70%",
						}}
						justifyContent="flex-end"
						alignItems="center">
						<Stack direction={"row"} spacing={2}>
							<InsertDriveFile />
							<Typography color={"secondary"}>
								{msg?.fileName}
							</Typography>
						</Stack>
						<IconButton
							onClick={() => {
								// console.log(msg.path);
								msg.path &&
									msg.path !== "" &&
									DownloadFileHelper(msg.path);
							}}>
							<DownloadOutlined color={"secondary"} />
						</IconButton>
					</Stack>
				</Stack>

				<Stack
					direction={"row"}
					spacing={0.5}
					alignItems="center"
					justifyContent="flex-start">
					<Typography sx={{ fontSize: "12px" }} color={"secondary"}>
						{msg.createdAt &&
							moment(msg.createdAt.seconds * 1000).format(

								`${moment(msg.createdAt.seconds * 1000).isSame(new Date(), "day") ? 'hh:mm a' : 'D-M-YYYY | hh:mm a'}`
							)}
					</Typography>
				</Stack>
			</Stack>
		);
	}
}
