import axios from "axios";


export default function getMetaDatafromURL(data) {

  return axios.post(
    'https://us-central1-astranovaapp.cloudfunctions.net/api/meta-data',
    // 'http://localhost:5001/systema-nova/us-central1/api/generate-referral-code',
    {...data},
    {timeout: 7000,}, //timeout of 7 secs
    {headers: {'Content-Type': 'application/json'}}
  )

}
