import React from "react";
import {
	Avatar,
	Grid,
	Paper,
	Stack,
	styled,
	Typography,
	Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import { AuthUserContext } from "../../../../providers/auth-provider";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import ListItemStartConversationC from "./list-item-start-conversation copy";
import MemberFavourite from "../../common/MemberFav";
import ListItemStartConversation from "./list-item-start-conversation";
const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "center",
	backgroundColor: "#fff",
	color: theme.palette.text.secondary,
	boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
	borderRadius: "10px",
	height: 250,
}));
export default function FounderListItem({ data, href }) {
	const navigator = useNavigate();
	const { profile } = React.useContext(AuthUserContext);
	

	return (
		<Grid
			item
			lg={4}
			md={4}
			sm={6}
			xs={6}

			wrap={"nowrap"}>
			<Item>
				<Stack direction={"column"} spacing={2} sx={{ width: "100%" }}
					   justifyContent="center"
					   alignItems="center"
				>
				<Stack sx={{ ml: "90%", mb:"-15%", mt:"-2.5%" }}>
						<MemberFavourite data={{ ...data, card: "founder" }}  />
					</Stack>
					<Box
					sx={{
						position: "relative",
							top:"40px",
							width: "150px",
							height: "110px",
							background: "#F6F8FA",
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px"
						}}
					>
					<Stack
						direction={"column"}
						spacing={1}
						justifyContent="center"
						alignItems="center" style={{cursor:"pointer"}}	onClick={() => navigator(href,  {
						state:
							{
								profile:data,
								founder: {
									...data?.startup.founder,
									linkedIn: data?.startup.founder?.linkedIn,
									website: data?.startup.founder?.website,
									twitter:data?.startup.founder?.twitter,
									repeatFounder:data?.startup.founder?.repeatFounder,

									startupName:
										data?.startup.founder?.startupName !== ""
											? data?.startup.founder?.startupName
											: data?.startup?.startupName,
									photoURL:
										data?.startup.founder.photoURL !== ""
											? data?.startup.founder.photoURL
											: data?.photoURL,
								},
							},
					})}>
						<Box
							sx={{
								border: "1px solid #204488",
								p: 0.75,
								borderRadius: "50%",
								marginTop : "-40px"
							}}>
							<Avatar
								alt={data?.startup?.founder?.founderName}
								src={data?.startup?.founder?.photoURL}
								sx={{ width: 40, height: 40 }}>
								<Typography  style={{fontSize:"12px",overflow:"hidden"}}>
									{data?.startup?.founder?.founderName &&
										data?.startup?.founder?.founderName.charAt(0)}
								</Typography>
							</Avatar>
						</Box>
						<Typography
							style={{fontSize:"12px",overflow:"hidden"}}
							sx={{ fontWeight: 650, mb: 0, lineHeight: 1.2,color:"black" }}>
							{(data?.startup?.founder?.founderName && data.startup.founder.founderName.length > 14) ? `${data.startup.founder.founderName.substr(0, 14)}...` : data?.startup?.founder?.founderName}
 <br />
							<font size={3} style={{fontSize:"10px",overflow:"hidden",fontWeight:"300"}}>
								Founder {data?.startup?.investorDetails?.areYouAnAngelInvestor && '| Investor'}
							</font>
						</Typography>
						<Box />
					</Stack>
					<Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}
            >
					{
						(data.uid !== profile?.uid) ?  (
							<ListItemStartConversation data={{...data, creatorData: data}}/>

						):<Button size={'small'} variant={"outlined"} color={'secondary'} sx={{px: 2, width:120}} 
						onClick={() => navigator(href,  {
							state:
								{
									profile:data,
									founder: {
										...data?.startup.founder,
										linkedIn: data?.startup.founder?.linkedIn,
										website: data?.startup.founder?.website,
										twitter:data?.startup.founder?.twitter,
										repeatFounder:data?.startup.founder?.repeatFounder,
	
										startupName:
											data?.startup.founder?.startupName !== ""
												? data?.startup.founder?.startupName
												: data?.startup?.startupName,
										photoURL:
											data?.startup.founder.photoURL !== ""
												? data?.startup.founder.photoURL
												: data?.photoURL,
									},
								},
						})}
								>My profile</Button>
					}
					</Box>
					</Box>
					
					<Box />
					<Box />
					<Box />
					{
						data?.startup?.founder?.website &&
						<>
					<Stack direction={"row"} spacing={0} sx={{width: '100%'}} style={{marginBottom:"-10px",marginTop:"-1px"}}>
						<Typography
					style={{fontSize:"10px"}}
								sx={{
									fontWeight: "600",
									textAlign: "left",
									width:"100%"
								}}>
							Website
						</Typography>
						<Typography
						style={{fontSize:"8px",overflow:"hidden"}}
						noWrap={true}>
							<a href={data?.startup?.founder?.website} style={{color: 'rgba(0, 0, 0, 0.6)'}} target={"_blank"} rel="noreferrer">{data?.startup?.founder?.website}</a>
						</Typography>
					</Stack>
							</>
							}
					<Stack direction={"row"} spacing={0} sx={{width: '100%'}}>
						<Typography
							style={{fontSize:"10px"}}
								sx={{
									fontWeight: "600",
									textAlign: "left",
									width:"80%"
								}}>
							Repeat Founder
						</Typography>
						<Typography
							 style={{fontSize:"8px",overflow:"hidden"}}
							noWrap={true}>
							{data?.startup?.founder?.repeatFounder ? 'Yes' : 'No'}
						</Typography>
					</Stack>





				</Stack>
			</Item>
		</Grid>
	);
}
