import React from "react";

import {
	Box,
	CircularProgress,
	Portal,
	Stack,
	Typography,
	Alert,
	Snackbar,IconButton
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { CheckCircleOutline, InsertDriveFile } from "@mui/icons-material";
import { getStorage, uploadBytesResumable } from "firebase/storage";
import { AuthUserContext } from "../../providers/auth-provider";
import { getDownloadURL, ref } from "firebase/storage";
import CloseIcon from '@mui/icons-material/Close';
export default function DragDropFiles({
	types,
	limit,
	multiple,
	label,
	onChangeCallback,
}) {
	const [open, setOpen] = React.useState(false);
	const [error, setError] = React.useState("");
	return (
		<>
			<FileUploader
				multiple={multiple}
				handleChange={(e) => onChangeCallback(e)}
				maxSize={limit}
				name="file"
				types={types}
				onTypeError={(err) => {
					setError(err);
					setOpen(true);
				}}
				onSizeError={(fileError) => {
					setError(fileError);
					setOpen(true);
				}}
				children={
					<Box
						sx={{
							boxSizing: "border-box",
							border: "2px dashed #7D7D7D",
							borderRadius: "8px",
							p: 3.5,
						}}>
						<Typography variant={"body2"} color={"#7D7D7D"} textAlign={"center"}>
							{label?.text}
							<>
								<br />
								<small>{label.caption}</small>
							</>
						</Typography>
					</Box>
				}
			/>
			<Portal>
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={() => setOpen(false)}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}>
					<Alert
						onClose={() => setOpen(false)}
						severity="error"
						sx={{ width: "100%" }}>
						{error}
					</Alert>
				</Snackbar>
			</Portal>
		</>
	);
}
export function DragDropItem({ file, i, path, remove}) {

	const { user } = React.useContext(AuthUserContext);
	const [progress, setProgress] = React.useState(0);
	React.useEffect(() => {
		const storage = getStorage();
		const storageRef = ref(storage, path);
		// console.log(path);
		const uploadTask = uploadBytesResumable(storageRef, file.file);
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				// Observe state change events such as progress, pause, and resume
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				const p =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				// setProgress(p)
				// console.log("Upload is " + progress + "% done");
				switch (snapshot.state) {
					case "paused":
						// console.log("Upload is paused");
						break;
					case "running":
						// console.log("Upload is running");
						break;
					default:
				}
			},
			(error) => {
				// Handle unsuccessful uploads
			},
			() => {
				// Handle successful uploads on complete
				// For instance, get the download URL: https://firebasestorage.googleapis.com/...
				debugger;
				setProgress(100);
				getDownloadURL(ref(storage, path)).then((url) => {
					// console.log(path);
					// console.log(url);
				});
			}
		);
	}, []);
	return (
		<Stack
			key={i}
			direction={"row"}
			justifyContent="flex-start"
			alignItems="center"
			spacing={1.5}
			sx={{
				backgroundColor: "#F6F8FA",
				p: 2,
				borderRadius: "8px",
				width: '100%',
			}}>
			{progress >= 0 && progress < 100 && (
				<CircularProgress sx={{ ml: 2 }} size={20} color="primary" />
			)}
			<InsertDriveFile sx={{ fontSize: 28 }} color={"secondary"} />
			<Typography
				variant={"body2"}
				sx={{ flex: 1, textAlign: "left" }}
				noWrap={true}
				paragraph={true}>
				{file?.file ? file?.file?.name : file.split('/')[file.split('/').length - 1]}
			</Typography>
			<Typography variant={"body2"}>
				{file?.file && (file?.file?.size / 1024 / 1024).toFixed(2) + 'MB'}
			</Typography>
			{progress === 100 && (
				<CheckCircleOutline
					color={"primary"}
					sx={{ fontSize: 18, color: "#4c9d00" }}
				/>
			)}
			{
				remove && <IconButton onClick={ () => remove(i) } >
					<CloseIcon />
				</IconButton>
			}
		</Stack>
	);
}
