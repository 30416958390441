import React from "react";
import {
  Avatar, Button, Container,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  backgroundColor: "#fff",
  color: theme.palette.text.secondary,
  minHeight:"320px",
  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
  borderRadius: "15px",
}));
export default function InvestorCard({ data, cbBack, send }) {
  
  
  return (
    
    <Container maxWidth={'md'} sx={{py:5}}>
      
      <Stack direction={"column"} spacing={5}
             justifyContent="center"
             alignItems="center" sx={{width:'100%'}}>
        <Typography variant={"h4"} fontWeight={600}>User Profile is ready to be shared</Typography>
        
        <Grid container  direction="row"
              justifyContent="center"
              alignItems="center" sx={{}}>
          <Grid
            item
            lg={5}
            md={6}
            sm={6}
            xs={12}
      
            wrap={"nowrap"}>
            <Item>
              <Stack direction={"column"} spacing={2} sx={{ width: "100%" }}
                     justifyContent="center"
                     alignItems="center"
              >
                <Box
                  sx={{
                    position: "relative",
                    top:"40px",
                    bottom : "20px",
                    width: "100%",
                    height: "150px",
                    background: "#F6F8FA",
                    boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                    borderRadius: "15px"
                  }}
                >
                  <Stack
                    direction={"column"}
                    spacing={2}
                    justifyContent="center"
                    alignItems="center">
                    <Box
                      sx={{
                        border: "1px solid #204488",
                        p: 0.75,
                        borderRadius: "50%",
                        marginTop : "-40px"
                      }}>
                      <Avatar
                        alt={data?.displayName}
                        src={data?.photoURL}
                        sx={{ width: 70, height: 70 }}>
                        <Typography variant={"h5"}>
                          {data?.displayName &&
                            data?.displayName.charAt(0)}
                        </Typography>
                      </Avatar>
                    </Box>
                    <Typography
                      variant={"h6"}
                      sx={{ fontWeight: 600, mb: 0, lineHeight: 1.2 }}>
                      {data?.displayName} <br />
                      <font size={3} style={{ fontWeight: 300 }}>
                       Investor
                      </font>
                    </Typography>
                    <Box />
                  </Stack>
                </Box>
          
                <Box />
                <Box />
                <Box />
                {data && data?.investorType && (
                  <Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
                    <Typography
                      variant={"body2"}
                      sx={{
                        fontWeight: "bold",
                        flex: 1,
                        textAlign: "left",
                        width:"30%"
                      }}>
                      Investor Type
                    </Typography>
                    <Typography variant={"body2"} >
                      {data?.investorType}
                    </Typography>
                  </Stack>
                )}
                {data&&
                  data?.investmentStage&&
                  data?.investmentStage.length > 0 &&  (
                    <Stack  direction={"row"} spacing={2} sx={{width: '100%'}}>
                      <Typography
                        variant={"body2"}
                        sx={{
                          fontWeight: "bold",
                          flex: 1,
                          textAlign: "left",
            
            
                        }}>
                        Investor Stage
                      </Typography>
                      <Typography variant={"body2"} sx={{ width: 130 }}
                                  noWrap={true}>
                        {/* {data?.investor?.investmentStage} */}
                        {data?.investmentStage.map((d, i) =>
                          d !==
                          data?.investmentStage[
                          data?.investmentStage.length - 1
                            ]
                            ? `${d}, `
                            : d
                        )}
                      </Typography>
                    </Stack>
                  )}
        
        
        
        
        
              </Stack>
            </Item>
          </Grid>
        </Grid>
  
        <Stack
          direction={"row"}
          justifyContent="center"
          alignItems="center"
          spacing={3}
          width={"100%"}>
          <Button
            variant={"outlined"}
            color={"primary"}
            sx={{ px: 5 }}
            onClick={cbBack}>
            Back
          </Button>
          {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{ px: 5 }}
            onClick={send}>
            Send
          </Button>
        </Stack>
      </Stack>
      
     
      
     
    </Container>
  );
}
