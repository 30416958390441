import axios from "axios";


export default function AddAdmin(data, token) {

  return axios.post(
    'https://us-central1-astranovaapp.cloudfunctions.net/api/admin/add-admin',
    // 'http://localhost:5001/systema-nova/us-central1/api/admin/add-admin',
    {...data},
    {headers: { 'Content-Type': 'application/json','Id-Token': token} }
  )

}