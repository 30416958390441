import React from "react";
import {
	Button,
	Container, MenuItem,
	Stack,
	TextField,Switch,FormControlLabel, Typography
} from "@mui/material";
import { useFormik } from "formik";
import InvestorProfileValidation from "../../../../validations/investor/investor-profile";

export default function InvestorFourForm({ cbBack, cbNext, data }) {


	const formikFirst = useFormik({
		initialValues: data
			? data
			: InvestorProfileValidation.InvestorFourFormValues,
		validationSchema:
		InvestorProfileValidation.InvestorFourForm,
		onSubmit: async (values) => {

			cbNext({
				...values
			});
		},
	});

	return (
		<Stack
			direction={"column"}
			spacing={10}
			component="form"
			onSubmit={(e) => formikFirst.handleSubmit(e)}>
			<Container maxWidth={"md"}>
				<Stack direction={"column"} spacing={5}>
					<Stack
						direction={"column"}
						spacing={1.5}>
						<Typography
							variant={
								"body2"
							}
							sx={{
								textAlign:
									"left",
							}}>
							Do you invest in Pre-product startups?
						</Typography>
						<Stack
							direction="row"
							spacing={1}
							alignItems="center">
							<Typography>
								No
							</Typography>
							<FormControlLabel
								label={""}
								control={
									<Switch
										name={
											"preProduct"
										}
										checked={
											formikFirst
												.values
												.preProduct
										}
										onChange={
											formikFirst.handleChange
										}
										error={
											formikFirst
												.touched
												.preProduct &&
											Boolean(
												formikFirst
													.errors
													.preProduct
											)
										}
									/>
								}
							/>
							<Typography>
								Yes
							</Typography>
						</Stack>
					</Stack>
					<Stack
						direction={"column"}
						spacing={1.5}>
						<Typography
							variant={
								"body2"
							}
							sx={{
								textAlign:
									"left",
							}}>
							Do you invest in Product Pre-launch startups?
						</Typography>
						<Stack
							direction="row"
							spacing={1}
							alignItems="center">
							<Typography>
								No
							</Typography>
							<FormControlLabel
								label={""}
								control={
									<Switch
										name={
											"productLive"
										}
										checked={
											formikFirst
												.values
												.productLive
										}
										onChange={
											formikFirst.handleChange
										}
										error={
											formikFirst
												.touched
												.productLive &&
											Boolean(
												formikFirst
													.errors
													.productLive
											)
										}
									/>
								}
							/>
							<Typography>
								Yes
							</Typography>
						</Stack>
					</Stack>
					<Stack
						direction={"column"}
						spacing={1.5}>
						<Typography
							variant={
								"body2"
							}
							sx={{
								textAlign:
									"left",
							}}>
							Do you invest in Pre-revenue startups?
						</Typography>
						<Stack
							direction="row"
							spacing={1}
							alignItems="center">
							<Typography>
								No
							</Typography>
							<FormControlLabel
								label={""}
								control={
									<Switch
										name={
											"preRevenue"
										}
										checked={
											formikFirst
												.values
												.preRevenue
										}
										onChange={
											formikFirst.handleChange
										}
										error={
											formikFirst
												.touched
												.preRevenue &&
											Boolean(
												formikFirst
													.errors
													.preRevenue
											)
										}
									/>
								}
							/>
							<Typography>
								Yes
							</Typography>
						</Stack>
					</Stack>
					<Stack
						direction={"column"}
						spacing={1.5}>
						<Typography
							variant={
								"body2"
							}
							sx={{
								textAlign:
									"left",
							}}>
							Do you invest in Pre-PMF startups?
						</Typography>
						<Stack
							direction="row"
							spacing={1}
							alignItems="center">
							<Typography>
								No
							</Typography>
							<FormControlLabel
								label={""}
								control={
									<Switch
										name={
											"PMF"
										}
										checked={
											formikFirst
												.values
												.PMF
										}
										onChange={
											formikFirst.handleChange
										}
										error={
											formikFirst
												.touched
												.PMF &&
											Boolean(
												formikFirst
													.errors
													.PMF
											)
										}
									/>
								}
							/>
							<Typography>
								Yes
							</Typography>
						</Stack>
					</Stack>
					<Stack
						direction={"column"}
						spacing={1.5}>
						<Typography
							variant={
								"body2"
							}
							sx={{
								textAlign:
									"left",
							}}>
							Do you invest in Sole Founder startups?
						</Typography>
						<Stack
							direction="row"
							spacing={1}
							alignItems="center">
							<Typography>
								No
							</Typography>
							<FormControlLabel
								label={""}
								control={
									<Switch
										name={
											"soleFounder"
										}
										checked={
											formikFirst
												.values
												.soleFounder
										}
										onChange={
											formikFirst.handleChange
										}
										error={
											formikFirst
												.touched
												.soleFounder &&
											Boolean(
												formikFirst
													.errors
													.soleFounder
											)
										}
									/>
								}
							/>
							<Typography>
								Yes
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Container>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3}
				width={"100%"}>
				<Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 7 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
					onClick={cbBack}>
					Back
				</Button>
				<Button
					variant={"contained"}
					color={"primary"}
					sx={{ px: 7 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
					type={"submit"}>
					Next
				</Button>
			</Stack>
		</Stack>
	);
}
