import React, { useEffect } from "react";
import { AuthUserContext } from "../../providers/auth-provider";
import {
  Container,
  Typography,
  Stack,
  Avatar,
  styled,
  Badge,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle, Portal,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UploadFile from "../../utils/upload-file";
import {getAuth, getIdToken, updateProfile} from "firebase/auth";
import { a11yProps, TabPanel } from "../../components/common/tabs-custom";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  updateDoc, setDoc,
} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import PostItem from "../../components/common/timeline/post-item";
import {useLocation, useNavigate} from "react-router-dom";
import InvestorDescription from "../../components/common/profile/investor-description";
import ExpertDescription from "../../components/common/profile/expert-description";
import InvestorAbout from "../../components/common/profile/investor-about";
import ExpertAbout from "../../components/common/profile/expert-about";
import BusinessDeveloperAbout from "../../components/common/profile/business-developer-about";
import BusinessDeveloperDescription from "../../components/common/profile/business-developer-description";
import ServiceProviderDescription from "../../components/common/profile/service-provider-description";
import ServiceProviderAbout from "../../components/common/profile/service-provider-about";
import StartupDescription from "../../components/common/profile/startup-description";
import StartupAbout from "../../components/common/profile/startup-about";
import TalentAbout from "../../components/common/profile/talent-about";
import TalentDescription from "../../components/common/profile/talent-description";
import PostNotification from "../../api/post-notification";
import StartupTopPriorities from "../../components/common/profile/startup-top-priorities";
import StartupTeam from "../../components/common/profile/startup-team";
import InvestmentPortfolioView from "../../components/common/profile/investor-portfolio-view";
import WorkExperienceView from "../../components/common/profile/work-experience-view";
import CommonInvestorDetails from "../../components/common/profile/common-investor-details";
import PortfoliosListView from "../investor/onboarding-forms/portfolios-list-view";
import InvestorPortfolioForm from "../investor/onboarding-forms/portfolio-form";
import ExperienceListView from "../mobile/expert/onboarding-forms/experience-list-view";
import ExperienceForm from "../mobile/expert/onboarding-forms/experience-form";
import ReferralAvatar from "../../components/common/profile/referal-avatar";
import { USER_TYPES } from "../../utils/enums/userTypes.enum";
import { BUSINESS_DEVELOPER, USER_TYPES_TO_NAME } from "../../utils/enums/labels.enum";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteUserApi from "../../api/get-users";
import CloseIcon from "@mui/icons-material/Close";
import UpdateUser from "../../api/update-user";
import StartChat from "../../components/common/start-chat";

const StyledTabs = styled((props) => (
  <Tabs
    sx={{ position: "relative", left: 30 }}
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "none",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    borderRadius: "30px",
    color: "#000",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#233DFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function UserProfileView() {
  const [index, setIndex] = React.useState(-1);
  const [key , setKey] = React.useState();
  const [ k , setK ] = React.useState();
  const location = useLocation()
  const {state} = location
  const [profile, setProfile] = React.useState(state?.profile)
  const { setUser, user } =
    React.useContext(AuthUserContext);
  const [value, setValue] = React.useState(0);
  const [posts, setPosts] = React.useState([]);
  const profileImage = React.useRef();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [imageURL, setImageURL] = React.useState();
  const confirmed = React.useRef(false);
  const [portfolioIndex, setPortfolioIndex] = React.useState();
  const [steps, setSteps] = React.useState(0);
  const userRef = doc(DB, "users", profile?.uid);
  debugger
  const [portfolios, setPortfolios] = React.useState(
    
    profile?.userType === 'investor' ? profile?.investor?.portfolio : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
      : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.portfolio
  );
  const [experiences, setExperiences] = React.useState((
    
    profile?.userType === 'investor' && profile?.userType === 'startup' && profile?.userType === 'talent' ? []
      : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
      : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.experiences || []
  ));
  const [experienceIndex, setExperienceIndex] = React.useState();
  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    setDialogOpen(false);
  }
  const navigator = useNavigate();
  const Input = styled("input")({
    display: "none",
  });
  async function updateProfilePicture(url) {
    try {
      debugger
/*      let startup = profile["startup"];
      const auth = getAuth();
      console.log(url);
      const displayName = profile?.displayName;
      if(profile?.userType !== "startup") {
       /!*
       * Todo: update data
       * *!/
        /!* await updateProfile(auth.currentUser, {
          displayName,
          photoURL: url,
        });*!/
      }
      const data = auth.currentUser.toJSON();
      // setUser({ ...user });
      const userRef = doc(DB, "users", profile?.uid);
      let dat;
      
      if (profile?.userType === "startup") {
        dat = await updateDoc(userRef, {
          "startup.logoURL": url,
        });
        // setUser({ ...user});
        
        setProfile({
          ...profile,
          startup: { ...startup, logoURL: url },
        });
        setImageURL("")
      } else {
        dat = await updateDoc(userRef, {
          photoURL: data?.photoURL ? data.photoURL : " ",
        });
        setUser({ ...user, photoURL: url });
        setProfile({ ...profile, photoURL: url });
        setImageURL("")
      }
      PostNotification({
        type: 'update-profile-picture',
        imageURL: url,
        message: `Your profile picture has been updated`,
        uid: user.uid
      }).then()*/
      confirmed.current = true;
    } catch (err) {
      console.log(err.message);
    }
  }
 
  function UploadProfile() {
    return (
      <div>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {profile?.userType === "startup"
              ? profile?.startup?.startupName
              : profile?.displayName}
          </DialogTitle>
          <DialogContent sx={{ height: 400, width: 600 }}>
            <Avatar
              key={imageURL}
              src={ imageURL ? imageURL : profile?.userType === "startup" ? profile?.startup?.logoURL!==''? profile?.startup?.logoURL : '' : profile?.photoURL}
              alt={
                profile?.userType === "startup"
                  ? profile?.startup?.startupName
                  : profile?.displayName
              }
              sx={{
                width: 152,
                height: 152,
                ml: 25,
              }}
              component={"span"}
            />
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "start", height: 100 }}>
            <Stack direction={"row"} spacing={-5}>
              <IconButton sx={{ width: 150,"&:hover": {backgroundColor: "white"} ,}} variant="outlined">
                <label htmlFor={"profile-image"}>
                  <Stack direction={"column"}>
                    <CameraAltIcon
                      fontSize="large"
                      sx={{ ml: 2, mb: 2, cursor: "pointer" }}
                    />
                    <Input
                      accept="image/*"
                      id="profile-image"
                      multiple={false}
                      type="file"
                      onChange={(e) => {
                        confirmed.current = false;
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(
                          e.target.files[0]
                        );
                        fileReader.addEventListener(
                          "load",
                          async function () {
                            const file =
                              e.target.files[0];
                            
                            const path = `profile-image/${file.name}`;
                            const imageObject = {
                              progress: 0,
                              src: this.result,
                              path,
                              file: e.target.files[0],
                              size: (
                                e.target.files[0]
                                  ?.size /
                                1024 /
                                1024
                              ).toFixed(2),
                            };
                            profileImage.current =
                              imageObject;
                            UploadFile(file, path).then(
                              (url) => {
                                console.log(url);
                                setImageURL(url);
                              }
                            );
                          }
                        );
                      }}
                    />
                    <DialogContentText sx={{ fontSize: 15 }}>
                      Add Photo
                    </DialogContentText>
                  </Stack>
                </label>
              </IconButton>
              <IconButton
                sx={{ width: 150, mr: 12,"&:hover": {backgroundColor: "white"} }}
                variant="outlined"
                onClick={() => {
                  profileImage.current = "";
                  setImageURL(" ");
                  
                  // updateProfilePicture("");
                  confirmed.current = true;
                  //handleDialogClose();
                }}>
                <Stack direction={"column"}>
                  <DeleteIcon
                    fontSize="large"
                    sx={{ ml: 4, mb: 2 }}
                  />
                  
                  <DialogContentText
                    sx={{ fontSize: 15 }}>
                    Remove Photo
                  </DialogContentText>
                </Stack>
              </IconButton>
            </Stack>
            
            <Button
              sx={{ width: 150, ml: 20 }}
              onClick={async () => {
                
                
                profileImage.current = {
                  ...profileImage,
                  photoURL: imageURL,
                };
                
                // updateProfilePicture(imageURL);
  
                const userRef = doc(DB, "users", profile?.uid);
                if (profile?.userType === "startup") {
                 updateDoc(userRef, {
                    "startup.logoURL": imageURL,
                  }).then();
                  setProfile({...profile,startup: {...profile?.startup , logoURL: imageURL}})
  
                  setImageURL("")
                } else {
                  const auth = getAuth();
                  const idToken = await getIdToken(auth.currentUser);
                  UpdateUser({ uid: profile?.uid,
                    formData: {photoURL: imageURL},
                    reqType: 'LOL'}, idToken).then()
                 updateDoc(userRef, {
                    photoURL: imageURL,
                    updatedById:auth?.currentUser?.uid,
                    updatedBy: 'admin',
                    updatedAt: new Date().getTime(),
                  }).then();
                  setProfile({...profile, photoURL: imageURL})
              
                  setImageURL("")
                }
                profileImage.current = "";
                
                handleDialogClose();
                //updateProfilePicture("");
              }}
              autoFocus
              variant="contained">
              Save Photo
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSteps(0)
  };
  
  
  const [open,setOpen] = React.useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };
  const deleteUser = async () =>
  {
    try {
      const auth = getAuth();
      const idToken = await getIdToken(auth.currentUser);
      console.log(idToken, 'ID - TOKEn')
      const data = await DeleteUserApi(profile, idToken);
      
      await setOpen(!open)
      
      navigator('/admin/dashboard');
    } catch (e) {
      
      debugger
    }
  }
  
  React.useEffect(() => {
    debugger
    if (!imageURL) {
      setImageURL(
        imageURL ? imageURL : profile?.userType === "startup" ? profile?.startup?.logoURL !== '' ? profile?.startup?.logoURL : '' : profile?.photoURL
      );
    }
    
    const q = query(
      collection(DB, "timelines"),
      where("uid", "==", profile?.uid)
    );
    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          
          console.log(doc.id, " => ", doc.data());
        });
        setPosts(list);
      }
    });
  }, []);
  if (index === -1){
    return (
      <Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
        <Stack direction={"column"} spacing={5}>
          <Stack direction="row" alignItems={'center'} alignContent={'flex-start'} >
            <IconButton onClick={() => navigator(-1)}>
              <ArrowBackIcon sx={{ fontSize: 40 }} />
            </IconButton>
            <Box flex={1}/>
            <Button variant={'contained'}  onClick={() => setOpen(!open)}>Delete user</Button>
            
          </Stack>
          <Stack
            direction={"column"}
            spacing={0.5}
            sx={{
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
              backgroundColor: "#fff",
              minHeight: 100,
              p: 2,
              pb: 0,
            }}>
            <Box
              sx={{
                height: 100,
                width: "100%",
                backgroundColor: "#F6F8FA",
                borderRadius: "15px",
              }}
            />
            <Box
              sx={{
                border: "1px solid #204488",
                p: 0.75,
                borderRadius: "50%",
                width: "fit-content",
                position: "relative",
                left: 30,
                top: -50,
              }}>
              <label>
                <Badge
                  overlap="circular"
                  sx={{ width: 92, height: 92 }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Button
                      onClick={handleDialogOpen}
                      sx={{ ml: 1, mt: 1 }}
                      color={"primary"}
                      component={"span"}>
                      <AddCircleIcon />
                    </Button>
                  }>
                  <UploadProfile />
                  <Avatar
                    key={imageURL}
                    src={
                      imageURL && confirmed.current
                        ? imageURL
                        : profile?.userType === "startup" ? profile?.startup?.logoURL !== ''? profile?.startup?.logoURL : '' : profile?.photoURL
                    }
                    alt={
                      profile?.userType === "startup"
                        ? profile?.startup?.startupName
                        : profile?.displayName
                    }
                    sx={{
                      width: 92,
                      height: 92,
                      cursor: "pointer",
                    }}
                    component={"span"}
                  />
                </Badge>
              </label>
            </Box>
            
            <Stack
              direction={"row"}
              spacing={3}
              sx={{ position: "relative", top: -40 }}
              alignItems={"flex-start"}>
              <div />
              <Stack direction={"column"} spacing={2}>
                <Stack direction={"row"} spacing={3}>
                  <Typography variant={"h5"}>
                    {profile?.userType === "startup"
                      ? profile?.startup?.startupName
                      : profile?.displayName}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#E0E3FF",
                      px: 3,
                      py: 1,
                      borderRadius: "19.5px",
                    }}>
                    <Typography
                      style={{
                        fontWeight: 500,
                        textTransform: "capitalize",
                      }}>
                      {USER_TYPES_TO_NAME[profile?.userType]}
                    </Typography>
                  </Box>
                  
                  {
                    profile[profile?.userType === 'service-provider' ? 'serviceProvider'
                      : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.areYouAnAngelInvestor &&
                    
                    <Box
                      sx={{
                        backgroundColor: "#B8FC3C",
                        px: 3,
                        py: 1,
                        borderRadius: "19.5px",
                      }}>
                      <Typography
                        style={{
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}>
                        {"Investor"}
                      </Typography>
                    </Box>
                  }
                  <StyledTabs
                    sx={{marginTop:'-1px',
                      display: 'grid',
                      justifyContent: 'right',
                      position: 'absolute' ,
                      right: 0
      
      
                    }}
  
                  >
                    {profile?.uid  !== user?.uid && profile?.userType !== "startup" &&   <StartChat
                      sx={{color:'white',backgroundColor: "#233DFF",
                        display: 'grid',
                        justifyContent: 'right',
                        position: 'absolute' ,
                        right: 0
                      }}
                      data={{...profile, creatorData:profile }} props={{...a11yProps(4)}}/>
                    }
                  </StyledTabs>
                </Stack>
                {profile?.userType === "investor" && (
                  <InvestorDescription profile={profile} cb={(d) => setProfile(d)} />
                )}
                {profile?.userType === "expert" && (
                  <ExpertDescription profile={profile} cb={(d) => setProfile(d)} />
                )}
                {profile?.userType === "business-developer" && (
                  <BusinessDeveloperDescription
                    profile={profile} cb={(d) => setProfile(d)}
                  />
                )}
                {profile?.userType === "service-provider" && (
                  <ServiceProviderDescription profile={profile}  cb={(d) => setProfile(d)}/>
                )}
                {profile?.userType === "startup" && (
                  <StartupDescription profile={profile}  cb={(d) => setProfile(d)}/>
                )}
                {profile?.userType === "talent" && (
                  <TalentDescription profile={profile}  cb={(d) => setProfile(d)}/>
                )}
              </Stack>
              
              <div style={{ flex: 1 }} />
            </Stack>
            <Stack direction={"row"} sx={{width:'100%'}} justifyContent="center"
                   alignItems="flex-end">
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example">
                <StyledTab label="ABOUT" {...a11yProps(0)} />
                {profile?.userType === "startup" && (
                  <StyledTab label={"TEAM"} {...a11yProps(1)} />
                )}
                {profile?.userType !== "investor" && profile?.userType !== "startup" &&(
                  <StyledTab label={"EXPERIENCE"} {...a11yProps(1)} />
                )}
                {
                  (profile?.userType === "expert"
                    && profile?.expert.investorDetails.areYouAnAngelInvestor &&
                    profile?.expert.investorDetails.areYouAnAngelInvestor === true)  ?
                    (<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />)   :
                    
                    
                    (profile?.userType === "business-developer"
                      && profile?.businessDeveloper.investorDetails.areYouAnAngelInvestor &&
                      profile?.businessDeveloper.investorDetails.areYouAnAngelInvestor === true) ?
                      (<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />)   :
                      
                      (profile?.userType === "service-provider"
                        && profile?.serviceProvider.investorDetails.areYouAnAngelInvestor &&
                        profile?.serviceProvider.investorDetails.areYouAnAngelInvestor === true)  ?
                        (<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />)   :
                        
                        (profile?.userType === "investor") ?
                          (<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />)   :
                          null
                  
                }
                
                
                
                {/* {(
                  profile?.userType === "service-provider" ||
                  profile?.userType === "talent") && (
                  <StyledTab label={"SOCIAL PROOF"} {...a11yProps(3)} />

                )} */}
                {
                  profile?.userType !== "startup" &&
                  <StyledTab label="POST" {...a11yProps( profile?.userType !== 'investor' ? 3: 2)} />
                }
                
                {/* <StyledTab
							label="CHAT"
							onClick={() =>
								navigator(`/${profile?.userType}/chat`)
							}
							{...a11yProps(4)}
						/> */}
              </StyledTabs>
              <div style={{ flex: 10}} />
              
              {profile?.userType === "service-provider" &&
                profile?.serviceProvider?.organisationType === "Organisation" && profile?.serviceProvider?.organisationDetails && (
                  <Stack
                    direction={"column"}
                    spacing={1}
                    justifyContent="center"
                    sx={{
                      position: "relative",
                      right: 15,
                      minWidth: 120,
                      cursor: 'pointer'
                    }}
                    alignItems="center">
                    <Box
                      sx={{
                        border: "1px solid #204488",
                        p: 0.75,
                        borderRadius: "50%",
                        width: "fit-content",
                      }}
                      onClick={() => {
                        
                        debugger
                        navigator(
                          `/admin/organisation-profile`,
                          {
                            state: {
                              profile,
                              organisation: {
                                ...profile?.serviceProvider
                                  .organisationDetails,
                                nameOfOrganisation:
                                  profile?.serviceProvider
                                    .organisationDetails
                                    ?.nameOfOrganization !==
                                  ""
                                    ? profile
                                      ?.serviceProvider
                                      .organisation
                                      ?.nameOfOrganization
                                    : profile
                                      ?.serviceProvider
                                      ?.organisationDetails
                                      ?.nameOfOrganization,
                                logoURL:
                                  profile?.serviceProvider
                                    .organisationDetails
                                    .logoURL !==
                                  ""
                                    ? profile
                                      ?.serviceProvider
                                      .organisationDetails
                                      .logoURL
                                    : '',
                              },
                            },
                          }
                        )
                      }
                      }>
                      <Avatar
                        alt={
                          profile?.serviceProvider?.organisationDetails
                            .nameOfOrganization
                        }
                        src={profile.userType === 'service-provider' && profile?.serviceProvider?.organisationDetails?.logoURL !== '' ?  profile?.serviceProvider?.organisationDetails?.logoURL: ''}
                        sx={{ width: 43 , height: 43 }}>
                        <Typography variant={"h4"}>
                          {profile?.serviceProvider?.organisationDetails.nameOfOrganization.charAt(
                            0
                          )}
                        </Typography>
                      </Avatar>
                    </Box>
                    <Typography
                      variant={"h6"}
                      // sx={{ textAlign: "center" }}
                    >
                      {profile?.serviceProvider?.organisationDetails.nameOfOrganization}
                    </Typography>
                    <Typography variant={'body2'}>
                      
                      <font
                        size={2}
                        style={{
                          backgroundColor: "#F6F8FA",
                          padding: "5px 10px",
                          borderRadius: "6px",
                          fontWeight: 300,
                        }}>
                        Organisation
                      </font>
                    </Typography>
                  </Stack>
                )}
              {profile?.userType === "startup" &&
                profile?.startup?.founder && (
                  <Stack
                    direction={"column"}
                    spacing={1}
                    justifyContent="center"
                    sx={{
                      position: "relative",
                      mr: -5,
                      right: 15,
                      minWidth: 120,
                      cursor: 'pointer'
                    }}
                    alignItems="center">
                    <Box
                      sx={{
                        border: "1px solid #204488",
                        p: 0.75,
                        borderRadius: "50%",
                        width: "fit-content",
                      }}
                      onClick={() =>
                        navigator(
                          `/admin/startup/startup-founder-profile`,
                          {
                            state: {
                              profile,
                              founder: {
                                ...profile?.startup
                                  .founder,
                                startupName:
                                  profile?.startup
                                    .founder
                                    ?.startupName !==
                                  ""
                                    ? profile
                                      ?.startup
                                      .founder
                                      ?.startupName
                                    : profile
                                      ?.startup
                                      ?.startupName,
                                photoURL:
                                  profile?.startup
                                    .founder
                                    .photoURL !==
                                  ""
                                    ? profile
                                      ?.startup
                                      .founder
                                      .photoURL
                                    : profile?.photoURL,
                              },
                            },
                          }
                        )
                      }>
                      <Avatar
                        alt={
                          profile?.startup?.founder
                            .founderName
                        }
                        src={profile.userType === 'startup' && profile?.photoURL !=='' ? profile?.photoURL: profile?.startup?.founder?.photoURL !== ''?  profile?.startup?.founder?.photoURL: profile?.photoURL}
                        sx={{ width: 43 , height: 43 }}>
                        <Typography variant={"h4"}>
                          {profile?.startup?.founder.founderName.charAt(
                            0
                          )}
                        </Typography>
                      </Avatar>
                    </Box>
                    <Typography
                      variant={"h6"}
                      // sx={{ textAlign: "center" }}
                    >
                      {profile?.startup?.founder.founderName}
                    </Typography>
                    <Typography variant={'body2'}>
                      
                      <font
                        size={2}
                        style={{
                          backgroundColor: "#F6F8FA",
                          padding: "5px 10px",
                          borderRadius: "6px",
                          fontWeight: 300,
                        }}>
                        Founder
                      </font>
                    </Typography>
                  </Stack>
                )}
              <Box sx={{flex:1}}/>
              { profile?.userType === 'investor' && profile?.investor?.investorType !== 'Angel Investor' &&
                
                
                <Stack direction={"column"} spacing={1} justifyContent="center" alignItems="center"
                       sx={{position: "relative", minWidth: 120,cursor:'pointer'  }}
                       
                
                       onClick={() => {
                         const path = `/admin/organisation-profile-investor`
                         navigator(path, {state:{
                             profile:profile,
                             organisation: {
                               organizationDescription: profile?.investor?.organizationDescription,
                               investorType: profile?.investor?.investorType,
                               website: profile?.investor?.website,
                               nameOfOrganization:  profile?.investor?.nameOfOrganization,
                               logoURL:
                               profile?.investor?.logoURL,
                             },
                           }});
                  
                       }}>
                  <Avatar sx={{width: 57, height:57, border: '1px solid #f5f5f5' }} src={profile?.investor?.logoURL} alt={'profile-pic'}  imgProps={{style: {objectFit: 'contain', backgroundColor: '#fff'}}}>
                    <Typography variant={"h3"}>{profile?.investor?.nameOfOrganization}</Typography>
                  </Avatar>
                  <Typography variant={"h6"}
                  >
                    <font
                    
                    >
                      {profile?.investor?.nameOfOrganization}
                    </font>
                  </Typography>
                  <Typography variant={"body2"}
                  >
                    <font
                      size={2}
                      style={{
                        backgroundColor: "#F6F8FA",
                        padding: "5px 10px",
                        borderRadius: "6px",
                        fontWeight: 300,
                      }}
                    >Organization</font></Typography>
                </Stack>
              }
              <ReferralAvatar profile={profile}/>
            </Stack>
            
            <Box sx={{ height: 20 }} />
          </Stack>
          <TabPanel index={0} value={value}>
            {profile?.userType === "investor" ? (
              <InvestorAbout profile={profile} cb={(data) => setProfile(data)}/>
            ) : profile?.userType === "expert" ? (
              <ExpertAbout profile={profile} cb={(data) => setProfile(data)}/>
            ) : profile?.userType === "business-developer" ? (
              <BusinessDeveloperAbout profile={profile} cb={(data) => setProfile(data)}/>
            ) : profile?.userType === "service-provider" ? (
              <ServiceProviderAbout profile={profile} cb={(data) => setProfile(data)}/>
            ) : profile?.userType === "startup" ? (
              <StartupAbout profile={profile}  cb={(data) => setProfile(data)}/>
            ) : profile?.userType === "talent" ? (
              <TalentAbout profile={profile} />
            ) : (
              ""
            )}
          
          </TabPanel>
          { profile?.userType !== 'investor' && profile[profile?.userType === 'service-provider' ? 'serviceProvider'
            : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.areYouAnAngelInvestor  && <TabPanel index={0} value={value}>
            <Stack
              direction={"column"}
              spacing={3}
              sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                px: 5.5,
                py: 6,
              }}>
              
              <CommonInvestorDetails profile={profile} cb={(data) => setProfile(data)}/>
            
            </Stack>
          </TabPanel>}
          
          <TabPanel index={profile?.userType === "startup" ? 1 : null} value={value}>
            <Stack
              direction={"column"}
              spacing={3}
              sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                px: 5.5,
                py: 6,
              }}
            >
              {profile?.userType === "startup" ? (
                <StartupTeam profile={profile} />
              ) : null
              }
            </Stack>
          </TabPanel>
          
          <TabPanel
            index={profile?.userType === 'investor'? 1 : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
              : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.areYouAnAngelInvestor ? 2 : -1}
            value={value}
          >
            <Stack
              direction={"column"}
              spacing={6}
              sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                px: 5.5,
                py: 6,
              }}
            >
              
              {steps === 1 ?<>
                  {(portfolioIndex !== undefined && portfolioIndex > -1) ?
                    <Typography variant={"h5"}>Update portfolio</Typography> :  <Typography variant={"h5"}>Add portfolio</Typography>}
                  <InvestorPortfolioForm
                    cbBack={() => {
                      setSteps(0)
                      setPortfolioIndex()
                    }}
                    cbNext={(data, index) => {
                      const port = [...portfolios]
                      if (index !== undefined && index > -1) {
                        port.splice(index, 1, { ...data})
                      } else {
                        port.push({ ...data })
                      }
                      const copyPortfolio = []
                      debugger
                      if (port?.length > 0) {
                        for (let portfolio of port) {
                          const attachments =[]
                          if (portfolio?.attachments?.length > 0) {
                            for (let file of portfolio?.attachments) {
                              if (file?.file) {
                                const path = `investors/${profile.uid}/attachments/${file.file.name}`
                                attachments.push(path)
                              } else {
                                attachments.push(file)
                              }
                            }
                          }
                          copyPortfolio.push({...portfolio, attachments})
                        }
                      }
                      const objectUpdate = {}
                      if (profile?.userType === 'service-provider') {
                        objectUpdate['serviceProvider'] = {...profile['serviceProvider']}
                      } else if (profile?.userType === 'business-developer'){
                        objectUpdate['businessDeveloper'] = {...profile['businessDeveloper']}
                      } else {
                        debugger
                        objectUpdate[profile?.userType] = {...profile[profile?.userType]}
                      }
                      if (profile?.userType !== 'investor') {
                        debugger
                        objectUpdate[
                          profile?.userType === 'service-provider' ? 'serviceProvider'
                            : profile?.userType === 'business-developer' ? 'businessDeveloper'
                              : profile?.userType].investorDetails.portfolio = [...copyPortfolio];
                      } else {
                        debugger
                        objectUpdate[profile?.userType].portfolio = [...copyPortfolio] ;
                      }
                      setPortfolios([...copyPortfolio])
                      setDoc(
                        userRef,
                        {...objectUpdate,
                        updatedById:user?.uid,
                        updatedBy:  'admin' ,
                        updatedAt: new Date().getTime()},
                        { merge: true }
                      ).then(() => {
                        setProfile({...profile,...objectUpdate })
                      });
                      PostNotification({
                        type: 'admin-update-profile',
                        imageURL: '/assets/system-noti-icon.png',
                        section: 'Investment Portfolio',
                        message: `information has been updated by the Admin.`,
                        uid: profile.uid,
                        createdBy: user?.uid
    
                      }).then()
                      
                      setSteps(0)
                      
                      
                      
                      setPortfolioIndex();
                      
                    }}
                    data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}
                    uid={profile?.uid}
                    index={portfolioIndex}
                  />
                </>
                : <>
                  <Typography fontSize={20} fontWeight={600}>Investment portfolio</Typography>
                  
                  <PortfoliosListView
                    
                    add={(data) => {
                      setPortfolioIndex();
                      setSteps(1);
                    }}
                    edit={async (ind) => {
                      debugger
                      await setPortfolioIndex(ind);
                      setSteps(1);
                      
                    }}
                    remove={(ind) => {
                      const port = [...portfolios]
                      port.splice(ind, 1)
                      setPortfolios([...port])
                      const objectUpdate = {}
                      if (profile?.userType === 'service-provider') {
                        objectUpdate['serviceProvider'] = {...profile['serviceProvider']}
                      } else if (profile?.userType === 'business-developer'){
                        objectUpdate['businessDeveloper'] = {...profile['businessDeveloper']}
                      } else {
                        debugger
                        objectUpdate[profile?.userType] = {...profile[profile?.userType]}
                      }
                      if (profile?.userType !== 'investor') {
                        debugger
                        objectUpdate[
                          profile?.userType === 'service-provider' ? 'serviceProvider'
                            : profile?.userType === 'business-developer' ? 'businessDeveloper'
                              : profile?.userType].investorDetails.portfolio = [...port];
                      } else {
                        debugger
                        objectUpdate[profile?.userType].portfolio = [...port] ;
                      }
                      setDoc(
                        userRef,
                        {...objectUpdate,
                          updatedById:user?.uid,
                          updatedBy:  'admin' ,
                          updatedAt: new Date().getTime()},
                        { merge: true }
                      ).then(() => {
                        setProfile({...profile,...objectUpdate })
                      });
                      setSteps(0);
                      
                    }}
                    portfolios={portfolios}
                  />
                </>
                
              }
              {/*{portfolioIndex || portfolioIndex  === 0  &&<InvestorPortfolioForm
								cbBack={() => setPortfolioIndex(undefined)}
								cbNext={(data, index) => {
									const port = [...portfolios]
									if (index !== undefined && index > -1) {
										port.splice(index, 1, { ...data})
									} else {
										port.push({ ...data })
									}
									setPortfolios([...port])
									// setSteps(steps - 1);
									setPortfolioIndex();

								}}
								data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}

								index={portfolioIndex}
							/> }*/}
              {/*{
								(!portfolioIndex && portfolioIndex  !== 0)   &&
								<>
									<Typography variant={'h5'}>Investment portfolio</Typography>

									<PortfoliosListView

										add={(data) => {
											setPortfolioIndex(-1);
											// setSteps(steps + 1);
										}}
										edit={async (ind) => {
											debugger

											console.log(ind)
											await setPortfolioIndex(ind);
											// setSteps(steps + 1);
										}}
										remove={(ind) => {
											debugger
											const port = [...portfolios]
											port.splice(ind, 1)
											setPortfolios([...port])
										}}
										portfolios={portfolios}
									/>
								</>

							}*/}
              
              
              
              {/*<InvestorPortfolio profile={profile} setIndex={setIndex} setKey={setKey}/>*/}
            </Stack>
          </TabPanel>
          {profile?.userType !== 'investor' &&  profile?.userType !== 'startup'?
            <TabPanel
              index={1}
              value={value}
            >
              <Stack
                direction={"column"}
                spacing={6}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}
              >
                {steps === 1 ? <>
                    {(experienceIndex !== undefined && experienceIndex > -1) ?
                      <Typography variant={"h5"}>Update Experience</Typography> :  <Typography variant={"h5"}>Add Experience</Typography>}
                    <ExperienceForm
                      cbBack={() => setSteps(0)}
                      cbNext={(data, index) => {
                                                const port = [...experiences]
                        if (index !== undefined && index > -1) {
                          port.splice(index, 1, { ...data})
                        } else {
                          port.push({ ...data })
                        }
                        setExperiences([...port])
                        
                        const copyExperiences = []
                        if (port?.length > 0) {
                          for (let experience of port) {
                            const attachments =[]
                            if (experience?.attachments?.length > 0) {
                              for (let file of experience?.attachments) {
                                if (file?.file) {
                                  const path = `experts/${profile.uid}/experiences/attachments/${file.file.name}`
                                  attachments.push(path);
                                } else {
                                  attachments.push(file);
                                }
                                
                              }
                            }
                            copyExperiences.push({...experience, attachments})
                          }
                        }
                        const objectUpdate = {}
                        if (profile?.userType === 'service-provider') {
                          objectUpdate['serviceProvider'] = {...profile['serviceProvider']}
                        } else if (profile?.userType === 'business-developer'){
                          objectUpdate['businessDeveloper'] = {...profile['businessDeveloper']}
                        } else {
                          debugger
                          objectUpdate[profile?.userType] = {...profile[profile?.userType]}
                        }
                        objectUpdate[profile?.userType === 'service-provider' ? 'serviceProvider'
                          : profile?.userType === 'business-developer' ? 'businessDeveloper'
                            : profile?.userType].experiences = [...copyExperiences]
                        setDoc(
                          userRef,
                          {...objectUpdate,
                            updatedById:user?.uid,
                            updatedBy:  'admin' ,
                            updatedAt: new Date().getTime()},
                          { merge: true }
                        ).then(() => {
                          setProfile({...profile,...objectUpdate })
                        })
                        PostNotification({
                          type: 'admin-update-profile',
                          imageURL: '/assets/system-noti-icon.png',
                          section: 'Experience',
                          message: `information has been updated by the Admin.`,
                          uid: profile.uid,
                          createdBy: user?.uid
    
                        }).then()
                        setSteps(0);
                        
                        setExperienceIndex(undefined);
                        
                      }}
                      data={(experienceIndex !== undefined && experienceIndex > -1)  ? experiences[experienceIndex] : undefined}
                      index={experienceIndex}
                      uid={profile?.uid}
                    /></>
                  :<>
                    <Typography fontSize={20} fontWeight={600}>Work Experience</Typography>
                    <ExperienceListView
                      
                      add={(data) => {
                        setExperienceIndex(undefined)
                        setSteps(1)
                        /*		setPortfolioIndex();
                                setSteps(steps + 1);*/
                      }}
                      edit={(ind) => {
                        setExperienceIndex(ind)
                        setSteps(1)
                        /*	setPortfolioIndex(ind);
                            setSteps(steps + 1);*/
                      }}
                      remove={(ind) => {
                        const experiences_copy =   [...experiences]
                        experiences_copy.splice(ind, 1)
                        setExperiences(experiences_copy)
                        const objectUpdate = {}
                        if (profile?.userType === 'service-provider') {
                          objectUpdate['serviceProvider'] = {...profile['serviceProvider']}
                        } else if (profile?.userType === 'business-developer'){
                          objectUpdate['businessDeveloper'] = {...profile['businessDeveloper']}
                        } else {
                          debugger
                          objectUpdate[profile?.userType] = {...profile[profile?.userType]}
                        }
                        debugger
                        objectUpdate[profile?.userType === 'service-provider' ? 'serviceProvider'
                          : profile?.userType === 'business-developer' ? 'businessDeveloper'
                            : profile?.userType].experiences = [...experiences_copy]
                        setDoc(
                          userRef,
                          {...objectUpdate,
                            updatedById:user?.uid,
                            updatedBy:  'admin' ,
                            updatedAt: new Date().getTime()},
                          { merge: true }
                        ).then(() => {
                          setProfile({...profile,...objectUpdate })
                        })
                        
                        /*	debugger
                            const port = [...portfolios]
                            port.splice(ind, 1)
                            setPortfolios([...port])*/
                      }}
                      experiences={experiences}
                    />
                  </>
                  
                }
                
                {/*	{profile?.userType === "expert" ? (
									<ExpertWorkExperience profile={profile} setIndex={setIndex} setK={setK}/>
								) : profile?.userType === "business-developer" ? (
									<BusinessDeveloperWorkExperience profile={profile} setIndex={setIndex} setK={setK} />
								) :profile?.userType === "service-provider" ? (
									<ServiceProviderWorkExperience profile={profile} setIndex={setIndex} setK={setK}/>
								):null
								}*/}
                
                {/* {profile?.userType === "expert" ? (
                  <ExpertWorkExperience profile={profile} setIndex={setIndex}/>
                ) : profile?.userType === "business-developer" ? (
                  <BusinessDeveloperWorkExperience profile={profile} setIndex={setIndex}/>
                ) :profile?.userType === "service-provider" ? (
                  <ServiceProviderSocialProof profile={profile} />
                ) : profile?.userType === "startup" ? (
                  <FinanceAbout profile={profile} />
                ) : profile?.userType === "talent" ? (
                  <TalentSocialProof profile={profile} />
                ) : (
                  <InvestorPortfolio profile={profile} setIndex={setIndex} />
                )} */}
              </Stack>
            </TabPanel> :
            null}
          {console.log(profile)}
          <TabPanel
            index={profile?.userType === 'investor'? 2 :  profile[profile?.userType === 'service-provider' ? 'serviceProvider'
              : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.areYouAnAngelInvestor ? 3 : 2}
            value={value}
          >
            <Stack >
              <Stack direction={"column"} spacing={5}>
                {posts?.length === 0  ? <>
                  <Stack
                    direction={"column"}
                    spacing={6}
                    sx={{
                      boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                      borderRadius: "15px",
                      backgroundColor: "#fff",
                      minHeight: 100,
                      px: 5.5,
                      py: 6,
                      textAlign:"center"
                    }}
                  >
                    
                    {/* <Stack direction="row" justifyContent="center"> */}
                    
                    <Typography>There are no posts to be shown</Typography>
                    {/* </Stack> */}
                  </Stack>
                </>:null
                }
                {posts?.length > 0 &&
                  posts.map((post, i) => <PostItem data={post} key={i} />)}
              </Stack>
            </Stack>
          </TabPanel>
          
          {profile && profile?.userType === "startup" && value === 0 && (
            <Stack
              direction={"column"}
              spacing={3}
              sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                px: 5.5,
                py: 6,
              }}>
              <StartupTopPriorities profile={profile} cb={(data) => setProfile(data)}/>
            </Stack>
          )}
        </Stack>
  
  
  
        <Portal>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-achive"
            aria-describedby="alert-dialog-achive"
            maxWidth={"sm"}>
            <DialogContent
              sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="flex-end">
                <IconButton
                  onClick={() => {
                    setOpen(false);
                  }}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                spacing={2}>
                <Stack
                  direction={"column"}
                  sx={{ width: "100%", }} alignItems={"center"}>
                  <img src={"/assets/delete-confirmation.png"} height={74} width={80}></img>
                </Stack>
                <Typography
                  component="span"
                  variant="h6"
                  color="text.primary"
                  sx={{
                    textAlign: "center",
                    flex: 1,
                    padding: 0,
                    fontWeight: 500,
                  }}>
                  Delete User
                </Typography>
                <Typography variant={"body2"} sx={{textAlign:'center'}}>
                  Are you sure you want to delete this user? <br/>
                  This action cannot be undone.
                </Typography>
                <br/>
                <Stack
                  direction={"row"}
                  sx={{ width: "100%" }}
                  justifyContent="center"
                  alignItems="center" spacing={2}>
                  <Button
                    variant={"outlined"}
                    sx={{ px: 5,width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
                    onClick={handleClose}>
                    No
                  </Button>
                  <Button
                    variant={"contained"}
                    sx={{ px: 5,width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
                    onClick={()=> {
                      deleteUser().then();
                
                    }}>
                    yes
                  </Button>
                </Stack>
          
                <Box />
              </Stack>
            </DialogContent>
          </Dialog>
        </Portal>
      </Container>
    )
    
  } else {
    
    return ( <>
      {
        profile?.userType !== 'investor' && profile[profile?.userType === 'service-provider' ? 'serviceProvider'
          : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ] &&
        profile[profile?.userType === 'service-provider' ? 'serviceProvider'
          : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ].investorDetails.portfolio &&
        profile[profile?.userType === 'service-provider' ? 'serviceProvider'
          : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ].investorDetails?.portfolio?.length > 0 &&
        profile[profile?.userType === 'service-provider' ? 'serviceProvider'
          : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ].investorDetails?.portfolio?.map((p, i) => (
          (i === key) ? (<InvestmentPortfolioView profile={profile} i = {i}/> ) : null
        
        ))
      }
      {
        profile?.userType === "investor" &&
        profile?.investor &&
        profile?.investor.portfolio &&
        profile?.investor?.portfolio?.length > 0 &&
        profile?.investor.portfolio.map((p, i) => (
          (i === key) ? (<InvestmentPortfolioView profile={profile} i = {i}/> ) : null
        
        ))
      }
      
      {
        profile[profile?.userType === 'service-provider' ? 'serviceProvider'
          : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ] &&
        profile[profile?.userType === 'service-provider' ? 'serviceProvider'
          : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ].experiences &&
        profile[profile?.userType === 'service-provider' ? 'serviceProvider'
          : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ].experiences?.length > 0 &&
        profile[profile?.userType === 'service-provider' ? 'serviceProvider'
          : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ].experiences.map((p, i) => (
          (i === k) ? (<WorkExperienceView profile={profile} i = {i}/> ) : null
        
        ))
      }
      {/*
		profile[profile?.userType === 'service-provider' ? 'serviceProvider'
											: profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]
		*/}
    
    
    </>)
    
  }
}
