import * as React from "react";
import {Button, IconButton, Menu, Stack, Typography} from "@mui/material";
import {CelebrateIcon, FunnyIcon, InsightFulIcon, LikeIcon, PunchIcon} from "../../../../utils/svg-icons";
import { DB } from "../../../../config/firebase/firebase";
import {collection, setDoc, doc, getDoc, deleteDoc, serverTimestamp} from "firebase/firestore";
import { AuthUserContext } from "../../../../providers/auth-provider";
import {useEffect} from "react";
import FavoriteBorderSharpIcon from '@mui/icons-material/FavoriteBorderSharp';
import FavoriteSharpIcon from '@mui/icons-material/FavoriteSharp';
export default function OfferFavorite({data}) {
  const {profile} = React.useContext(AuthUserContext);
  const [favorite, setFavorite] = React.useState(false)
  const [favoriteDoc, setFavoriteDoc] = React.useState()
  const colors = {
    'undefined': '#8F8F8F',
    'Like':'#233DFF',
    'Punch':'#F85353',
    'Celebrate':'#02C875',
    'LOL':'#FF7A00',
    'Insightful':'#CE41F6',
    'Puke':'#AAC15A'
  }


  const favoriteCallback = () => {
debugger
    const colRef = doc(DB, "users", profile?.uid, "favoriteOffers", data?.id);
    setDoc(colRef, {
      createdAt: serverTimestamp()
    }, {merge: true}).then(()=> {
      setFavorite(true)
      setFavoriteDoc({id: data?.id});
    }).catch(() => {
      debugger
    });
  }
  useEffect(() => {
    setFavorite(false)
    setFavoriteDoc(undefined)
   let unsubs;
    unsubs =  getDoc(doc(DB, "users", profile?.uid, "favoriteOffers", data?.id))
      .then((getdoc) => {
        if (getdoc.exists() ) {
          setFavorite(true);
          setFavoriteDoc({id : data?.id,...getdoc.data() })
          console.log('id', data?.id + JSON.stringify(getdoc.data()))
        }
        
      });
    return() => unsubs
  }, [data?.id])
  return(

    <>
      <IconButton onClick={() => {
        if (favoriteDoc) {
          const colRef = doc(DB, "users", profile?.uid, "favoriteOffers", data?.id);
          deleteDoc(colRef).then(()=> {
            setFavorite(false)
            setFavoriteDoc(undefined);
          })
        } else {
          favoriteCallback();
        }
      }}>
        {favoriteDoc ? <FavoriteSharpIcon style={{fontSize:"20px", ml:-5}} sx={{color:'#FF3C3C'}} /> : <FavoriteBorderSharpIcon style={{fontSize:"20px", ml:-8}} color={'disabled'} />}
        
      </IconButton>
      
    </>
  )
}