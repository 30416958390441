import React from "react";
import { getAuth, updateProfile, sendEmailVerification } from "firebase/auth";
import {
  collection,
  doc,
  setDoc,
  where,
  getDocs,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  GlobalStyles,
  Box,
  Portal,
  InputAdornment,
  Badge,
  CircularProgress,
  Link,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { query } from "firebase/firestore";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import SignupValidation from "../../../validations/auth/signup-form-validation";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  auth,
  DB,
  loginWithGoogle,
  loginWithEmail,
  loginWithLinkedin,
} from "../../../config/firebase/firebase";
import { getDoc } from "firebase/firestore";

import { callbackGoogleWithSignin } from "./signin";
import { AuthUserContext } from "../../../providers/auth-provider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputBase from "@mui/material/InputBase";
import { EmailOutlined } from "@mui/icons-material";
import OtpInput from "react-otp-input";
import { Grid } from "@material-ui/core";
import Avatar from "@mui/material/Avatar/Avatar";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import referralCodeGenerator from "referral-code-generator";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFile from "../../../utils/upload-file";
import LinkedinAccessToken from "../../../api/linkedin-access-token";
import { GoogleAuthProvider, updateEmail } from "firebase/auth";
import { logout, registerWithEmail } from "../../../config/firebase/firebase";
import { LinkedIn, useLinkedIn } from "react-linkedin-login-oauth2";
import NotificationEmail from "../../../api/notification-email";
import PostNotification from "../../../api/post-notification";
import SendVerificationEmailApi from "../../../api/send-verification-email-api";
import InfoIcon from "@mui/icons-material/Info";
import GenerateReferralCode from "../../../api/generate-referral-code";

const queryFirebase = query;

export default function Signup() {
  const { state } = useLocation();

  const [openmodal, setOpenmodal] = React.useState(false);

  const handleClickOpenmodal = () => {
    setOpenmodal(true);
  };

  const handleClosemodal = () => {
    setOpenmodal(false);
  };
  const [firstCheck, setFirstCheck] = React.useState(false);
  const [secondCheck, setSecondCheck] = React.useState(false);
  const [firstDialogOpen, setFirstDialogOpen] = React.useState(false);
  const [sameEmailError, setSameEmailError] = React.useState(false);
  const handleFirstDialogOpen = () => {
    setFirstDialogOpen(true);
  };

  const handleFirstDialogClose = () => {
    setFirstDialogOpen(false);
  };
  const [secondDialogOpen, setSecondDialogOpen] = React.useState(false);
  const handleSecondDialogOpen = () => {
    setSecondDialogOpen(true);
  };

  const handleSecondDialogClose = () => {
    setSecondDialogOpen(false);
  };
  function handleFirstChange(value) {
    setFirstCheck(value);
  }
  function handleSecondChange(value) {
    setSecondCheck(value);
  }
  function FirstDialog() {
    return (
      <div>
        <Dialog
          open={firstDialogOpen}
          onClose={handleFirstDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Age Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              To access and use this website, you must confirm that you are over
              16 by clicking the below 'Confirm' button.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ width: 100, mr: 2 }}
              style={{ textTransform: "capitalize" }}
              onClick={handleFirstDialogClose}
              variant="outlined"
            >
              Go Back
            </Button>
            <Button
              sx={{ width: 100 }}
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                setFirstCheck(true);
                handleFirstDialogClose();
              }}
              autoFocus
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  function SecondDialog() {
    return (
      <div>
        <Dialog
          open={secondDialogOpen}
          onClose={handleSecondDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Terms of Service and Privacy Policy"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              To use our website, you must review and agree to our{" "}
              <b>
                <u>terms of service</u>
              </b>{" "}
              and{" "}
              <b>
                <u>privacy policy</u>
              </b>{" "}
              by clicking the provided link and clicking on agree button
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{ width: 100, mr: 2 }}
              style={{ textTransform: "capitalize" }}
              onClick={handleSecondDialogClose}
            >
              Go Back
            </Button>
            <Button
              sx={{ width: 100 }}
              style={{ textTransform: "capitalize" }}
              autoFocus
              variant="contained"
              onClick={() => {
                setSecondCheck(true);
                handleSecondDialogClose();
              }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  const navigator = useNavigate();
  const [query] = useSearchParams();

  const { setProfile, setUser, setUserBlock } =
    React.useContext(AuthUserContext);
  const [open, setOpen] = React.useState(query.get("refer") ? false : false);
  const [referralCode, setReferralCode] = React.useState(
    query.get("refer") || ""
  );
  const [profileImage, setProfileImage] = React.useState();
  const [loading, setLoading] = React.useState(false);
  let once = false;
  const Input = styled("input")({
    display: "none",
  });

  //SHOW PASSWORD!
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [values, setValues] = React.useState({
    password: "",
    showpassword: false,
  });
  const handleClickPassword = () => {
    setValues({
      ...values,
      showpassword: !values.showpassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const uploadFile = () => {
    console.log("working");
  };
  const verifyReferral = async (displayName) => {
    debugger;
    const q = queryFirebase(
      collection(DB, "users"),
      where("referralCode", "==", formik?.values?.referralCode)
    );
    debugger;
    const querySnapshot = await getDocs(q);
    if (querySnapshot?.size > 0) {
      const auth = getAuth();
      const doco = {
        ...querySnapshot?.docs[0]?.data(),
        id: querySnapshot?.docs[0]?.id,
      };
      const userRef = doc(DB, "users", doco.id);
      const earning = doco?.earning ? doco?.earning + 50 : 50;
      const referList = doco?.referList
        ? [...doco?.referList, auth?.currentUser?.uid]
        : [auth?.currentUser?.uid];
      await updateDoc(userRef, {
        earning,
        referList,
        updatedAt: new Date().getTime(),
      });
      await NotificationEmail({
        type: "earning",
        email: doco.email,
        name: displayName,
      });
      PostNotification({
        type: "earned-points",
        imageURL: profileImage?.photoURL ? profileImage.photoURL : "",
        //message: `Congrats! You earned 50 points since your friend ${displayName} just joined NOVA.`,
       // message: `Congrats! You earned 50 points since your friend ${displayName} just joined AstraNova.`,
       message: `Congrats! You earned 50 points since your friend ${displayName} just joined AstraNova.`,
       
       uid: doco.id,
        name: displayName,
      }).then();
    }
  };
  const { linkedInLogin } = useLinkedIn({
    clientId: "78pgzp570kouvv",
    redirectUri: `${window.location.origin}/auth/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    scope: "r_liteprofile r_emailaddress",
    onSuccess: (code) => {
      if (!once) {
        setLoading(true);
        console.log(code);
        once = !once;
        LinkedinAccessToken(code)
          .then((userCredential) => {
            // Signed in
            debugger;
            console.log(userCredential, "userCredential");
            loginWithLinkedin(userCredential.data.customToken)
              .then(async (d) => {
                const { displayName, email, photoURL, id } =
                  userCredential.data;
                debugger;
                try {
                  const userRef = doc(DB, "users", auth.currentUser.uid);
                  const data = auth.currentUser.toJSON();
                  for (let prop in data) {
                    if (data[prop] === undefined) data[prop] = null;
                    if (
                      prop === "stsTokenManager" ||
                      prop === "apiKey" ||
                      prop === "appName"
                    )
                      delete data[prop];
                  }
                  await updateProfile(auth.currentUser, {
                    displayName,
                    photoURL,
                  });
                  await updateEmail(auth.currentUser, email);
                  // GenerateReferralCode({uid:auth.currentUser.uid, displayName})
                  //   .then()
                  const getdoc = await getDoc(userRef);
                  if (!getdoc.exists()) {
                    debugger
                    navigator('/m/auth/referral', {state: {type: 'linkedin', data: {
                          ...data,
                          earning: 100,
                          displayName,
                          email,
                          photoURL,countryState: state ? state?.country : "",
                          createdAt: new Date().getTime(),
                        }}});
                  } else {
                    await setDoc(
                      userRef,
                      {
                        ...data,
                        displayName,
                        email,
                        photoURL,
                        createdAt: +data?.createdAt,
                        updatedAt: new Date().getTime(),
                      },
                      { merge: true }
                    );
                  }
                  const getdocc = await getDoc(userRef);
                  getdoc.exists() &&  await setProfile(getdocc.data());
                  if (getdocc.data() && !getdocc.data().onboard) {
                    navigator("/m/on-boarding/type");
                  } else {
                    if (getdoc.data().userType === "expert") {
                      navigator("/m/expert/timeline");
                    }
                    if (getdoc.data().userType === "startup") {
                      navigator("/m/startup/timeline");
                    }
                    if (getdoc.data().userType === "investor") {
                      navigator("/m/investor/timeline");
                    }
                    if (getdoc.data().userType === "service-provider") {
                      navigator("/m/service-provider/timeline");
                    }
                    if (getdoc.data().userType === "business-developer") {
                      navigator("/m/business-developer/timeline");
                    }
                    if (getdoc.data().userType === "talent") {
                      navigator("/m/talent/timeline");
                    }
                  }
                  setLoading(false);
                } catch (e) {
                  setLoading(false);

                  debugger;
                }
              })
              .catch((err) => {
                debugger;
                setLoading(false);
              });
            // ...
          })
          .catch((error) => {
            debugger;
            setLoading(false);
          });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const formik = useFormik({
    initialValues: SignupValidation.initialValues,
    validationSchema: SignupValidation.schema,
    onSubmit: async (values) => {
      setSameEmailError(false);
      if (!firstCheck || !secondCheck) {
        return;
      }
      console.log("values", values);
      try {
        console.log("trying");
        debugger;
        const {
          email,
          password,
          displayName = values.name,
          referralCode,
        } = values;
        const q = queryFirebase(
          collection(DB, "users"),
          where("referralCode", "==", referralCode)
        );
        debugger;
        const querySnapshot = await getDocs(q);
        if (querySnapshot?.size > 0) {
          setLoading(true);
          await registerWithEmail(email, password);
          setUserBlock(true);
          const auth = getAuth();
          SendVerificationEmailApi({ email }).then();
          // await sendEmailVerification(auth.currentUser, {
          // 	url: `${window.location.origin}/auth/signin`,
          // });
          await updateProfile(auth.currentUser, {
            displayName,
            photoURL: profileImage?.photoURL ? profileImage.photoURL : "",
          });
          await verifyReferral(displayName);
          const userRef = doc(DB, "users", auth.currentUser.uid);
          const data = auth.currentUser.toJSON();
          for (let prop in data) {
            if (data[prop] === undefined) data[prop] = null;
            if (
              prop === "stsTokenManager" ||
              prop === "apiKey" ||
              prop === "appName"
            )
              delete data[prop];
          }
          GenerateReferralCode({uid:auth.currentUser.uid, displayName})
            .then()
          const dat = await setDoc(
            userRef,
            {
              ...data,
              earning: 100,
              createdAt: new Date().getTime(),
              countryState: state ? state?.country : "",
            },
            { merge: true }
          );

          // await setProfile(data);
          setLoading(false);
          setUserBlock(false);
          logout().then(() => {
            setProfile(null);
            setUser(null);
            navigator("/m/auth/signin");
          });
        } else {
          formik.setFieldError(
            "referralCode",
            "Please enter the valid referral code."
          );
          /*alert ('cannot found this code')*/
        }

        // navigator('/on-boarding/type')
      } catch (e) {
        console.log("caught em");
        if (e.message === "Firebase: Error (auth/email-already-in-use).") {
          setSameEmailError(true);
        }
        setLoading(false);
      }
    },
  });
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <FormikProvider value={formik}>
        <Stack
          direction="column"
          spacing={3}
          justifyContent="center"
          sx={{ mt: 20 }}
        >
          <Stack>
            <Container maxWidth={"sm"}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                component="form"
                onSubmit={(e) => formik.handleSubmit(e)}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  sx={{ mb: 5, mx: "auto" }}
                >
                  <label htmlFor="profile-image">
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <IconButton color={"primary"} component={"span"}>
                          <AddCircleIcon />
                        </IconButton>
                      }
                    >
                      <Avatar
                        alt={"startup-picture"}
                        src={
                          profileImage && profileImage?.src
                            ? profileImage?.src
                            : "/assets/default-avatar.png"
                        }
                        sx={{
                          width: 128,
                          height: 128,
                          cursor: "pointer",
                          backgroundColor: "transparent",
                          border: "1px solid #E0E3FF",
                        }}
                        component={"span"}
                      />
                    </Badge>
                    <Input
                      accept="image/*"
                      id="profile-image"
                      multiple={false}
                      type="file"
                      onChange={(e) => {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(e.target.files[0]);
                        fileReader.addEventListener("load", async function () {
                          setLoading(true);
                          const file = e.target.files[0];
                          const path = `profile-image/${file.name}`;
                          const imageObject = {
                            progress: 0,
                            src: this.result,
                            path,
                            file: e.target.files[0],
                            size: (
                              e.target.files[0]?.size /
                              1024 /
                              1024
                            ).toFixed(2),
                          };
                          setProfileImage(imageObject);
                          UploadFile(file, path).then((url) => {
                            setProfileImage({
                              ...imageObject,
                              photoURL: url,
                            });
                            setLoading(false);
                          });
                        });
                      }}
                    />
                  </label>
                  {loading && (
                    <CircularProgress
                      sx={{ ml: 2 }}
                      size={20}
                      color="primary"
                    />
                  )}
                  {profileImage && profileImage?.src && (
                    <Link
                      color={"secondary"}
                      onClick={() => setProfileImage(undefined)}
                    >
                      Remove picture
                    </Link>
                  )}
                </Stack>

                {/* <Grid container justify="center" alignItems="center">
                <AddCircleRoundedIcon
                  onClick={uploadFile}
                  sx={{ color: "#233DFF", mt: "-100px", ml: "64px"}}
                ></AddCircleRoundedIcon>
              </Grid>*/}
                <Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                  textAlign="start"
                >
                  Full Name*
                </Typography>
                <TextField
                  name={"name"}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant={"outlined"}
                  type={"text"}
                  placeholder={"John Doe"}
                  // label={"Full name"}
                  fullWidth
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                />
                <Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  textAlign="start"
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                >
                  Email*
                </Typography>
                <TextField
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                  name={"email"}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={
                    (formik.touched.email && Boolean(formik.errors.email)) ||
                    sameEmailError
                  }
                  helperText={
                    sameEmailError
                      ? "Your email address is already exists."
                      : formik.touched.email && Boolean(formik.errors.email)
                      ? "Email is required."
                      : ""
                  }
                  variant={"outlined"}
                  type={"email"}
                  placeholder={"xyz@example.com"}
                  // label={"Email"}
                  fullWidth
                />
                <Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  textAlign="start"
                  style={{ marginLeft: "5px" }}
                >
                  Password*
                </Typography>
                <FormControl
                  fullWidth
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  {/* <InputLabel htmlFor="outlined-adornment-password">
										Password
									</InputLabel> */}

                  <OutlinedInput
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    variant={"outlined"}
                    type={values.showpassword ? "text" : "password"}
                    placeholder={"xxxxxxxx"}
                    // label={"Password"}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {!values.password ? (
                            <img src="/assets/eye_close.png" alt="" />
                          ) : (
                            <img src="/assets/eye_open.png" alt="" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {Boolean(formik.errors.password) && (
                    <FormHelperText error id="password-error">
                      {formik.values.email &&
                      formik.values.name &&
                      formik.values.password.length === 0
                        ? "The password is a required field."
                        : formik.values.password.length === 0
                        ? "Password is required"
                        : !/[A-Z]/.test(formik.values.password)
                        ? "Your password must have atleast one upper case character."
                        : !/[a-z]/.test(formik.values.password)
                        ? "Your password must have atleast one lowercase character."
                        : !/[0-9]/.test(formik.values.password)
                        ? "Your password must have atleast one number."
                        : !/[-+_!@#$%^&*.,?]/.test(formik.values.password)
                        ? "Your password must have atleast one special character."
                        : formik.values.password.length < 8
                        ? "Your password must have atleast 8 characters."
                        : ""}
                    </FormHelperText>
                  )}
                  <Typography
                    align="left"
                    sx={{
                      color: "#ED1C24",
                      fontWeight: "500",
                      fontSize: "10px",
                    }}
                  >
                    {formik.touched.password && Boolean(formik.errors.password)}
                    {/* &nbsp; */}
                  </Typography>
                </FormControl>
                <Stack direction="row" spacing={2}>
                  <Box
                    style={{
                      backgroundColor: "#233DFF",
                      borderRadius: "10px",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "#fff",
                    }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      {!/[a-z]/.test(formik.values.password) ? (
                        <CancelIcon sx={{ fontSize: 16 }} />
                      ) : (
                        <DoneIcon sx={{ fontSize: 16 }} />
                      )}

                      <Typography
                        variant={"caption"}
                        textAlign={"left"}
                        fontWeight={400}
                      >
                        A lower case character
                      </Typography>
                    </Stack>
                  </Box>

                  <Box
                    style={{
                      backgroundColor: "#233DFF",
                      borderRadius: "10px",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "#fff",
                    }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      {!/[A-Z]/.test(formik.values.password) ? (
                        <CancelIcon sx={{ fontSize: 16 }} />
                      ) : (
                        <DoneIcon sx={{ fontSize: 16 }} />
                      )}
                      <Typography
                        variant={"caption"}
                        textAlign={"left"}
                        fontWeight={400}
                      >
                        A uppercase character
                      </Typography>
                    </Stack>
                  </Box>
                  </Stack>
                  <Stack direction="row" spacing={1.5}>
                  <Box
                    style={{
                      backgroundColor: "#233DFF",
                      borderRadius: "10px",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "#fff",
                    }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      {!/[0-9]/.test(formik.values.password) ? (
                        <CancelIcon sx={{ fontSize: 16 }} />
                      ) : (
                        <DoneIcon sx={{ fontSize: 16 }} />
                      )}
                      <Typography
                        variant={"caption"}
                        textAlign={"left"}
                        fontWeight={400}
                      >
                        A number
                      </Typography>
                    </Stack>
                  </Box>
                  <Box
                    style={{
                      backgroundColor: "#233DFF",
                      borderRadius: "10px",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "#fff",
                    }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      {formik.values.password.length < 8 ? (
                        <CancelIcon sx={{ fontSize: 16 }} />
                      ) : (
                        <DoneIcon sx={{ fontSize: 16 }} />
                      )}
                      <Typography
                        variant={"caption"}
                        textAlign={"left"}
                        fontWeight={400}
                      >
                        Minimum 8 characters
                      </Typography>
                    </Stack>
                  </Box>
                
                 
                  </Stack>
                  <Stack direction={"row"} spacing={1}>
                  <Box
                    style={{
                      backgroundColor: "#233DFF",
                      borderRadius: "10px",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "#fff",
                    }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      {!/[-+_!@#$%^&*.,?]/.test(formik.values.password) ? (
                        <CancelIcon sx={{ fontSize: 16 }} />
                      ) : (
                        <DoneIcon sx={{ fontSize: 16 }} />
                      )}
                      <Typography
                        variant={"caption"}
                        textAlign={"left"}
                        fontWeight={400}
                      >
                        A symbol
                      </Typography>
                    </Stack>
                  </Box>
                  </Stack>
                  <Stack>
                 
                </Stack>
                <Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  textAlign="start"
                  style={{ marginLeft: "5px" }}
                >
                  Referral Code*
                  <Tooltip
                    enterTouchDelay={5}
                    style={{ marginLeft: "-9%" }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          color: "white",
                          maxWidth: "250px",
                          height: 110,
                          fontSize: 10,
                          textAlign: "start",
                          marginRight: "20%",

                          bgcolor: "#233DFF",
                          "& .MuiTooltip-arrow": {
                            color: "#E3E8F0",
                          },
                        },
                      },
                    }}
                    title="Get in touch with support@astranova.io if you do not have a referral code provided to you. Please note that this is a members-only community and is only possible to join through a referral."
                    arrow
                    sx={{
                      width: "50px",
                    }}
                  >
                    <IconButton>
                      <HelpOutlineIcon
                        style={{
                          fontSize: 20,

                          color: "#A9ABAE",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <TextField
                  name={"referralCode"}
                  value={formik.values.referralCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.referralCode &&
                    Boolean(formik.errors.referralCode)
                  }
                  helperText={
                    formik.touched.referralCode && formik.errors.referralCode
                  }
                  variant={"outlined"}
                  type={"text"}
                  placeholder={"895633"}
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                  // label={"Referral code*"}
                  fullWidth
                />

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                        size="small"
                        checked={firstCheck}
                        onChange={() => handleFirstChange(!firstCheck)}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="I am 16 or over."
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginTop: "-20px",
                        }}
                        size="small"
                        checked={secondCheck}
                        onChange={() => handleSecondChange(!secondCheck)}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    // href="https://astranova.io/terms-of-use/" target="_blank"
                    // href="https://astranova.io/privacy-policy/" target="_blank"
                    label={
                      <label>
                        I accept the
                        <span sx={{ textDecorationLine: "underline" }}>
                          {" "}
                          <u>
                            <a
                              href="https://astranova.io/terms-of-use/"
                              target="_blank"
                            >
                              Terms of Service
                            </a>
                          </u>{" "}
                          and{" "}
                          <u>
                            <a
                              href="https://astranova.io/terms-of-use/"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                          </u>
                        </span>
                      </label>
                    }
                  />
                </FormGroup>
                <Dialog open={openmodal} onClose={handleClosemodal}>
                  <DialogActions
                    style={{
                      border: "10px solid #233DFF",
                      borderBottom: "none",
                    }}
                  >
                    <IconButton
                      style={{ marginRight: "10px" }}
                      onClick={handleClosemodal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogActions>
                  <DialogContent
                    style={{
                      border: "10px solid #233DFF",
                      borderTop: "none",
                      marginTop: "-53px",
                      height: "50%",
                    }}
                  >
                    <DialogContentText>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "700", color: "black" }}
                      >
                        Terms of Service
                      </Typography>
                      <Typography style={{ color: "black", fontSize: "15px" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation
                      </Typography>
                      <br></br>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "700", color: "black" }}
                      >
                        Privacy Policy
                      </Typography>
                      <Typography style={{ color: "black", fontSize: "15px" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>

                <Stack
                  direction={"row"}
                  sx={{ width: "100%" }}
                  alignContent="center"
                  alignItems="center"
                  spacing={5}
                >
                  {/*<Button
									fullWidth
									variant={"outlined"}
									color={"primary"}
									onClick={() => setOpen(!open)}>
									Referral code
								</Button>*/}
                  <Button
                    sx={{ width: 500 }}
                    type={"submit"}
                    variant={"contained"}
                    color={"primary"}
                    disabled={loading}
                    endIcon={
                      loading && <CircularProgress size={18}></CircularProgress>
                    }
                    onClick={() => {
                      console.log(firstCheck);
                      console.log(secondCheck);
                      if (!firstCheck) {
                        handleFirstDialogOpen();
                        return;
                      }
                      if (!secondCheck) {
                        handleSecondDialogOpen();
                        return;
                      }
                    }}
                  >
                    Agree and join
                  </Button>
                </Stack>
              </Stack>
            </Container>
            <FirstDialog />
            <SecondDialog />
            <Container maxWidth="sm" sx={{ mt: "35px" }}>
              <div
                style={{
                  backgroundColor: "#000",
                  width: "100%",
                  height: 1,
                }}
              />
              <div
                style={{
                  width: "75px",
                  margin: "auto",
                  top: "-13px",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: "#FBFBFB",
                    textAlign: "center",
                  }}
                >
                  OR
                </Typography>
              </div>
            </Container>
            <Container maxWidth="sm" sx={{ mb: "20px" }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Button
                    style={{ textTransform: "lowercase" }}
                    variant={"text"}
                    color={"secondary"}
                    startIcon={
                      <img
                        style={{ height: "20px" }}
                        src={"/assets/google.png"}
                      ></img>
                    }
                    onClick={() =>
                      callbackGoogleWithSignin(navigator, setProfile)
                    }
                  >
                    <Typography color={"#8F8F8F"}>
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        Continue{" "}
                      </span>
                      <span
                        style={{
                          textTransform: "lowercase",
                        }}
                      >
                        with
                      </span>
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        Google
                      </span>
                    </Typography>
                  </Button>
                  <Button
                    onClick={linkedInLogin}
                    style={{ textTransform: "lowercase" }}
                    variant={"text"}
                    color={"secondary"}
                    startIcon={<LinkedInIcon style={{ color: "#204488" }} />}
                  >
                    <Typography color={"#8F8F8F"}>
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        Continue{" "}
                      </span>
                      <span
                        style={{
                          textTransform: "lowercase",
                        }}
                      >
                        with
                      </span>
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        Linked
                      </span>
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        In
                      </span>
                    </Typography>
                  </Button>
                </Stack>
                <Typography variant={"body1"} color={"secondary"}>
                  Already on AstraNova?{" "}
                  <Button
                    variant={"text"}
                    color={"primary"}
                    onClick={() => navigator("/m/auth/signin")}
                  >
                    Sign In
                  </Button>
                </Typography>
              </Stack>
            </Container>
          </Stack>
        </Stack>
      </FormikProvider>

      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
        >
          <DialogContent sx={{ minWidth: 500, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              alignItems="center"
              spacing={5}
            >
              <Typography
                component="span"
                variant="body2"
                color="text.primary"
                sx={{
                  textAlign: "center",
                  flex: 1,
                  padding: 0,
                  lineHeight: 1.3,
                }}
              >
                <font size={5}>Enter Referral Code</font>
                <br />
                Enter the refer code that your friend sent
                <br />
                you to give them proper credit
              </Typography>
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                alignContent="center"
                alignItems="center"
                spacing={5}
              >
                <Box sx={{ flex: 1 }} />
                <OtpInput
                  value={referralCode}
                  onChange={(value) => setReferralCode(value)}
                  numInputs={6}
                  inputStyle={{
                    width: 40,
                    height: 50,
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                  }}
                  separator={<span>&nbsp;-&nbsp;</span>}
                />
                <Box sx={{ flex: 1 }} />
              </Stack>
              <Button
                sx={{ width: 200 }}
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                Agree and join
              </Button>
              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </React.Fragment>
  );
}
