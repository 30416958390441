import React from "react";
import { Container, Typography, Stack, IconButton, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BUSINESS_DEVELOPER } from "../../../../../utils/enums/labels.enum";

const commonStyles = {
  bgcolor: "background.paper",
  m: 1,
  border: 1,
  width: "1198px",
  height: "1100px",
};
export default function WorkExperience() {
  return (
    <Container maxWidth={"lg"} sx={{ textAlign: "left" }}>
      <Stack
        direction={"column"}
        sx={{ width: "100%", textAlign: "left", marginTop: "10px" }}
        spacing={5}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          // spacing={2}
        >
          <IconButton onClick={() => navigator(-1)}>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>

          <Typography variant={"h6"} sx={{fontWeight:'600'}}> Work Experience</Typography>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ ...commonStyles, borderColor: "black" }}>
            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 120,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack direction={"row"} spacing={14} sx={{ marginTop: "-8px" }}>
                <Typography variant={"h6"} color={"black"}>
                  Company Name:
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                  Astra Nova
                </Typography>
              </Stack>
              <br></br>
              <Stack direction={"row"} spacing={8} sx={{ marginTop: "-10px" }}>
                <Typography variant={"h6"} color={"black"}>
                  Company Website URL:
                </Typography>
                <Typography variant={"h6"} color={"#233DFF"}>
                  www.astranova.com
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 120,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack direction={"row"} spacing={14} sx={{ marginTop: "-8px" }}>
                <Typography variant={"h6"} color={"black"}>
                  Role:
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                  General Manager
                </Typography>
              </Stack>
              <br></br>
              <Stack direction={"row"} spacing={8} sx={{ marginTop: "-10px" }}>
                <Typography variant={"h6"} color={"black"}>
                  Starting Date:
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                  12-12-2022 - Present
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-8px" }}
              >
                <Typography variant={"h5"} color={"black"}>
                  <br></br>
                  Experience
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                  <br></br>I am currently seeking assistance with sales for my
                  fintech SaaS product. Our platform offers innovative solutions
                  for financial management and is designed to streamline
                  processes and increase efficiency for businesses in the
                  industry. I am looking for a sales professional or team with
                  experience in the fintech industry and a proven track record
                  of success in SaaS sales. If you or your team possess the
                  necessary skills and experience, I would greatly appreciate
                  your help in expanding our customer base and driving revenue
                  for our business. Please contact me to discuss further details
                  and opportunities for collaboration.
                  <br></br>
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-8px" }}
              >
                <Typography variant={"h5"} color={"black"}>
                  <br></br>
                  Social Proof
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                  <br></br>
                  I've had the pleasure of working with “James Walker” for
                  several years, and I can confidently say that they are an
                  exceptional {BUSINESS_DEVELOPER.SINGULAR_LABEL}. They have a keen eye for
                  identifying new opportunities and building strong
                  relationships with clients, and their strategic thinking and
                  problem-solving skills have helped our company achieve
                  significant growth. “James Walker” consistently goes above and
                  beyond to ensure that clients are satisfied and that all
                  projects are executed seamlessly. Their dedication and passion
                  for their work is evident in everything they do, and I would
                  highly recommend them to anyone looking for a talented and
                  results-driven {BUSINESS_DEVELOPER.SINGULAR_LABEL}.
                  <br></br>
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-30px" }}
              >
                <Typography variant={"h5"} color={"black"}>
                  <br></br>
                  Attachment
                </Typography>
                <Stack direction="row">
                  <Box
                    sx={{
                      p: "2px 10px",
                      display: "flex",
                      alignItems: "center",
                      width: 337,
                      height: 43,
                      borderRadius: "12px",
                      backgroundColor: "#E0E3FF",
                      marginTop: "33px",
                    }}
                  >
                    <IconButton type="button" aria-label="search">
                      <img src="/assets/image5.png" alt="" />
                    </IconButton>
                    Revenue Model.pdf
                    <Stack
                      sx={{
                        marginLeft: "60px",
                      }}
                    >
                      <img src="/assets/img2.png" alt="" />
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      p: "2px 10px",
                      display: "flex",
                      alignItems: "center",
                      width: 337,
                      height: 43,
                      borderRadius: "12px",
                      backgroundColor: "#E0E3FF",
                      marginTop: "33px",
                      marginLeft: "13px",
                    }}
                  >
                    <IconButton type="button" aria-label="search">
                      <img src="/assets/image5.png" alt="" />
                    </IconButton>
                    Pitch Dech.pdf
                    <Stack
                      sx={{
                        marginLeft: "60px",
                      }}
                    >
                      <img src="/assets/img2.png" alt="" />
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                // borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-30px" }}
              >
                <Typography variant={"h5"} color={"black"}>
                  <br></br>
                  Additional Link
                </Typography>
                <Stack direction="row">
                  <Box
                    sx={{
                      p: "2px 10px",
                      display: "flex",
                      alignItems: "center",
                      width: 204,
                      height: 43,
                      borderRadius: "12px",
                      backgroundColor: "#E0E3FF",
                      marginTop: "33px",
                    }}
                  >
                    www.additionallink.com
                  </Box>
                  <Box
                    sx={{
                      p: "2px 10px",
                      display: "flex",
                      alignItems: "center",
                      width: 170,
                      height: 43,
                      borderRadius: "12px",
                      backgroundColor: "#E0E3FF",
                      marginTop: "33px",
                      marginLeft: "13px",
                    }}
                  >
                    www.example.com
                  </Box>
                </Stack>
              </Stack>
            </Stack>

          </Box>
        </Box>
      </Stack>
    </Container>
  );
}
