import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton, Portal, useMediaQuery} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import EditInvestorDetailsForm from "../../../../../screens/investor/edit/edit-investor-details-form";
import {getAuth, updateProfile} from "firebase/auth";
import EditStartupDetailsForm from "../../../../../screens/startups/edit/edit-startup-details-form";
import EditStartupFounderDetailsForm from "../../../../../screens/startups/edit/edit-startup-founder-details-form";
import {useNavigate} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import EditServiceProviderOrganisationDetailsForm from '../../../../../screens/service-provider/edit/edit-service-provider-organisation-details-form';
import PostNotification from "../../../../../api/post-notification";

export default function EditOrganisationDetailsComponent({profile,open, handleClose, callback}) {
  debugger
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  const regexAdmin = /admin\/organisation-profile/
  const copyProfile = regexAdmin.test(window.location.pathname) ? profile : {...context.profile}
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? profile.uid : user.uid);
  const auth = getAuth();
  const navigator = useNavigate()
  const [isLoading, setLoading] = React.useState(false);
  return (
    <Portal>
        <Dialog
            fullScreen={!matches ? true: false}
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
                {!matches && <IconButton onClick={() => handleClose()}>
                    <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
                </IconButton>}
                {"Edit Organisation Details"}
            </DialogTitle>
            <DialogContent sx={{px: !matches ? 0 : ''}}>
          <br/>
          <EditServiceProviderOrganisationDetailsForm
            data={{
              nameOfOrganization: profile?.serviceProvider?.organisationDetails?.nameOfOrganization || '',
              organizationDescription: profile?.serviceProvider?.organisationDetails?.organizationDescription || '',
              countryState: profile?.countryState || ''
          }}
            cbBack={handleClose}
            cbNext={async (data) => {
              const {nameOfOrganization, organizationDescription, countryState} = data
              await setDoc(
                userRef,
                {
                  serviceProvider: {
                    ...profile?.serviceProvider ,
                    organisationDetails: {
                      ...profile?.serviceProvider?.organisationDetails,
                      nameOfOrganization,
                      organizationDescription
                    } },
                  countryState
                },
                { merge: true }
              )
              if (regexAdmin.test(window.location.pathname)) {
                callback({
                  ...copyProfile,
                  serviceProvider: {
                    ...profile?.serviceProvider ,
                    organisationDetails: {
                      ...profile?.serviceProvider?.organisationDetails,
                      nameOfOrganization,
                      organizationDescription
                    } },
                  countryState
                })
                setLoading(false)
    
                PostNotification({
                  type: 'admin-update-profile',
                  imageURL: '/assets/system-noti-icon.png',
                  section: 'Organization',
                  message: `information has been updated by the Admin.`,
                  uid: profile.uid,
                  createdBy: user?.uid
                }).then()
    
              } else {
                // await updateProfile(auth?.currentUser, {displayName: founderName})
                await setProfile({
                  ...copyProfile,
                  serviceProvider: {
                    ...profile?.serviceProvider ,
                    organisationDetails: {
                      ...profile?.serviceProvider?.organisationDetails,
                      nameOfOrganization,
                      organizationDescription
                    } },
                  countryState
                })
                handleClose()
                navigator(`${!matches ? '/m' : ''}/${profile?.userType}/organisation-profile`, {
                  state: {
                    profile,
                    organisation:  {
                      ...profile?.serviceProvider?.organisationDetails,
                      nameOfOrganization,
                      organizationDescription
                    }
                  }
                })
              }
          
            }}
            isLoading={isLoading}
          />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
