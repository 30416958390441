import * as React from "react";
import {
	Avatar, Button,
	Container,
	IconButton, Portal,
	Stack,
	Typography, Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText, styled, Box, Fab, CircularProgress
} from "@mui/material";
import RoomsList from "../../components/common/chat/rooms-list";
import MessageList from "../../components/common/chat/message-list";
import { AuthUserContext } from "../../providers/auth-provider";
import InputMessageComponent from "../../components/common/chat/input-message-component";
import MessagesTopbar from "../../components/common/chat/messages-topbar";
import FilesPreviews from "../../components/common/chat/files-previews";
import SidePanelRight from "../../components/common/chat/side-panel-right";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useLocation} from "react-router-dom";
import UploadFile from "../../utils/upload-file";

import CloseIcon from '@mui/icons-material/Close';
import InputBase from "@mui/material/InputBase";
import AttachmentButton from "../../components/common/chat/attachment-button";
import {addDoc, collection, doc, getDoc, serverTimestamp, setDoc} from "firebase/firestore";
import {DB} from "../../config/firebase/firebase";
import PostNotification from "../../api/post-notification";
export default function ChatScreen() {
	const { roomId, setRoomId, roomData, setRoomData, user, profile } =
		React.useContext(AuthUserContext);
	const [files, setFiles] = React.useState([]);
	const [sidePanel, setSidePanel] = React.useState(false);
	const [messageList, setMessageList] = React.useState();
	const [contractFile, setContractFile] = React.useState();
	const {state, pathname} = useLocation()
	const [isEnter, setIsEnter] = React.useState(false);
	const [isUploadSign, setUploadSign] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [textInput, setTextInput] = React.useState('');
	const onEnter = async  (e) => {
		
		if (e.key === 'Enter' && !isEnter) {
			setIsEnter(true)
			await sendContract()
			setIsEnter(false)
			
		}
	}
	const [loading , setLoading] = React.useState(false)
	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClose = () => {
		setOpen(false);
		// setTimeout(() => setUploadSign(false), 200)
	};
	const setMediaPenal = () => {
		setSidePanel(!sidePanel);
	};
	const adminPattern = /admin/

	React.useEffect(() => {

		if (state && state?.roomId) {
			setRoomId(state?.roomId)
			setRoomData(state?.roomData)
		};
		return () => {
			setRoomId(null);
			setRoomData(null);
		};
	}, [setRoomId,
		setRoomData]);
	const Input = styled("input")({
		display: "none",
	});
	const postContractFile = (file) => new Promise((resolve, reject) => {
		const path = `chat/contracts/${roomData?.title}/${roomData?.id}/${file.name}`
		UploadFile(file, path)
			.then(() => {
				debugger
				resolve({
					path,
					fileName: file.name,
					ext: file
						.name
						.split('.')[file
						.name
						.split('.')
						.length - 1
						]
				})
			})
			.catch((err) =>  reject(err));
	});
	const postMessage = (path, fileName, ext, contractId) => new Promise(async (resolve, reject) =>{
		try {
			
			let message = await addDoc(collection(DB, "messages"),
				{
					type: isUploadSign ? 'contract-document-signed': 'contract-document',
					ext,
					fileName,
					path,
					text: textInput,
					sender: user?.uid,
					receiver: roomData.participates.find((d) => d !== user?.uid),
					createdAt:serverTimestamp(),
					roomId: roomData?.id,
					read:false,
					status: 'send',
				});
			const room = doc(DB, 'rooms', roomData?.id)
			await setDoc(room, { lastMsg: {
					text: textInput,
					type: 'document',
					ext,
					fileName,
					status: 'send'
				},contractId, updatedAt: serverTimestamp()}, {merge: true}
			)
			setRoomData({...roomData, contractId})
			
			resolve(true)
		} catch (e) {
			reject(e)
		}
	})
	const sendContract = async () => {
		try {
			
			setLoading(true)
			if (isUploadSign) {
				const {path, fileName, ext} = await postContractFile(contractFile);
				const setContract = await setDoc(
					doc(DB, 'contracts', roomData?.contractId), {
						partyB: {uid: profile?.uid, displayName: profile?.displayName, location: profile?.countryState, signed: true, contractPath : path}, updatedAt: serverTimestamp()
					}, {merge: true})
				await postMessage(path, fileName, ext, roomData?.contractId)
				const requestSnap = await getDoc(doc(DB, 'help-requests', roomData?.requestId))
				PostNotification({
					type: 'admin',
					imageURL: profile?.photoURL ? profile?.photoURL : '/assets/system-noti-icon.png',
					message: `'${profile?.displayName}' has uploaded a contract in response to the '${requestSnap?.data()?.requestFor}' request. Click to view it.`,
					uid: profile.uid ,
					createdBy: profile?.uid
				}).then()
			} else {
				const partyBdoc = await getDoc(doc(DB, 'users',roomData?.hiredUid))
				const partyBData = partyBdoc?.data()
				const {path, fileName, ext} = await postContractFile(contractFile);
				const postData  = {
					partyA: {uid: profile?.uid, displayName: profile?.displayName, location: profile?.countryState, signed: true, contractPath : path},
					partyB: {uid: partyBData?.uid, displayName: partyBData?.displayName, location: partyBData?.countryState, signed: false, contractPath: ''},
					requestId: roomData?.requestId,
					uid: profile?.uid,
					createdAt: serverTimestamp(),
					updatedAt: serverTimestamp(),
					applicantId: roomData?.applicantId,
					roomId: roomData?.id
				}
				const addContract = await addDoc(collection(DB, 'contracts'), postData)
				await postMessage(path, fileName, ext, addContract?.id)
			}
			setLoading(false)
			setUploadSign(false)
			setTextInput('')
			setContractFile(null)
			
		} catch (e) {
			console.log(e)
			setLoading(false)
			
		}
		
	
	}
	React.useEffect(() => {
		if (isUploadSign) handleClickOpen();
	}, [isUploadSign])

	return (
		<React.Fragment>
			<Container maxWidth={adminPattern.test(pathname) ? "full-width" : 'xl'} sx={{ textAlign: "left", paddingY: 2 }}>
				<Stack direction={"row"} sx={{ height: "1" }} spacing={2}>
					<RoomsList callbackRightPanel={(bool) => setSidePanel(bool) } />
					<Stack
						direction={"column"}
						sx={{ flex: sidePanel ? 0.5 : 0.75, height: 'calc(100vh - 150px)' , borderRadius: '15px 15px 0px 0px',  boxShadow:'0px 0px 13px -2px rgba(0, 0, 0, 0.15)'}}>
						{roomId ? (
							<Stack
								direction={"column"}
								sx={{
									height: "calc(100vh - 150px)",
									backgroundColor: "#fff",
									borderRadius: "15px 15px 0px 0px",	
									scrollbarColor: "#cdcdcd #FFFFFF",
									"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
										backgroundColor: "#FFFFFF",
									},
								}}>
								<MessagesTopbar callback={setMediaPenal} />
								{files.length > 0 || contractFile ? (
									<>
										{files.length > 0 &&
											<FilesPreviews
												files={files}
												callback={() => {
													setFiles([]);
													document
														.getElementById("msg-container")
														.scrollTo({
															top: document.getElementById(
																"msg-container"
															).scrollHeight,
															behavior: "smooth",
														});
												}}
											/>
										}
										{contractFile &&
											<Stack direction={"column"} sx={{height: 'calc(100vh - 250px)'}}
												   justifyContent="flex-end"
												   alignItems="flex-end"
												   spacing={1}
											>
												<Stack direction={"row"}  justifyContent="flex-start"  alignItems="center" spacing={2}
													   sx={{p:2,px:5, border: '.1px solid #8F8F8F', borderColor:'#8F8F8F', borderRight: 'none', borderLeft: 'none', width: '100%'}} >
													<Box sx={{height: 58,py:1.5, width: 100,color:'#fff', backgroundColor: '#F95655',fontSize: 24,textTransform:'uppercase',  textAlign: 'center'}}>
														{contractFile?.name.split('.')[contractFile?.name.split('.').length - 1]}
													</Box>
													<Stack direction={"column"} spacing={0} flex={1}>
														<Typography variant={"body1"} sx={{color:'#051E40'}}>
															{contractFile && contractFile?.name}
														</Typography>
														<Typography variant={"caption"} sx={{color:'#8F8F8F'}}>
															Attached
														</Typography>
													</Stack>
													<IconButton onClick={() => setContractFile(null)}>
														<CloseIcon />
													</IconButton>
													
													
												
												</Stack>
												
												
												<Stack direction={'row'} spacing={1} sx={{width:'100%'}}>
													<Stack direction={'row'} spacing={1} p={2}
														   justifyContent="flex-start"
														   alignItems="center" sx={{width:'100%',backgroundColor:  '#F6F8FA', borderRadius: '12px', height: 61, marginY : 'auto !important', mx: '15px !important'}}>
														
														<InputBase
															sx={{  flex: 1, color: "#051E40BF" }}
															
															inputProps={{ "aria-label": "search post" }}
															value={textInput} placeholder={'Type here...'}
															onKeyDown={(e) => onEnter(e)}
															onChange={(e) => setTextInput(e.target.value)}
														/>
														<Typography variant={'h3'} fontWeight={100} sx={{color: '#8F8F8F'}}>|</Typography>
														<Fab size={"medium"} variant={'extended'}  color={loading ? '':'primary'} onClick={sendContract} sx={{boxShadow: 'none', borderRadius: '8px', width: 50, height:43}}
														>
															{
																loading ?
																	<CircularProgress size={18}/> :
																	<img src={'/assets/sendicon.png'}/>
															}
														
														</Fab>
													
													</Stack>
												</Stack>
											</Stack>
										}
									</>
									
								) : (
									<Stack
										direction={"column"}
										justifyContent="space-between"
										alignItems="center"
										spacing={2}>
										{user.uid === roomData?.uid && roomData?.hired &&
											
											<Stack direction={"row"} alignItems={"center"} alignContent={"start"} spacing={3} sx={{backgroundColor: 'rgba(217, 217, 217, 0.2)',width:'100%', height: '100px', px: 2}} >
												<Typography variant={"body1"} fontWeight={300} flex={1}>
													Looks like you found someone you want to work with. <br/>
													Now, let's proceed with setting up the contract.
												</Typography>
												<Button size={"small"} variant={"contained"} disabled={roomData?.contractId} sx={{px: 2}} onClick={handleClickOpen}>{roomData?.contractId ? 'Sent contract' :'Setup contract'}</Button>
											</Stack>
										}
										
										<MessageList
											roomId={roomId}
											callback={async (list) => {
												await setMessageList(list);
											}}
											callbackSignContract={(bool) => setUploadSign(bool)}
										/>
										<InputMessageComponent
											callback={(files) => {
												setSidePanel(false)
												setFiles(files);
											}}
										/>
									</Stack>
								)}
							</Stack>
						) : (
							<Stack
								direction={"column"}
								sx={{
									height: "100vh",
									width: "100%",
									backgroundColor: "#fff",
									paddingX: 5,
									textAlign: "center",
									borderRadius: "15px 15px 0px 0px",
								}}
								justifyContent="center"
								alignItems="center"
								spacing={1}>
								<img
									src={"/assets/nova-logo.png"}
									aria-label={"logo"}
									style={{ height: 65, width: 214 }}
								/>

								<Typography variant={"body1"} color={"gray"}>
									Now send and receive messages.
									<br />
								</Typography>
								<Typography variant={"body2"} color={"gray"}>
									Welcome to the AstraNova Chatroom <br />
									You can message those contactable here. Go
									ahead give it a whirl.
								</Typography>
							</Stack>
						)}

						{/*<TextField variant={"outlined"} fullWidth value={textInput} onKeyDown={onEnter} onChange={(e) => setTextInput(e.target.value)} size={'small'} sx={{backgroundColor:'#fff', }}/>*/}
					</Stack>
					{sidePanel && (
						<Stack
							direction={"column"}
							spacing={1}
							sx={{
								flex: sidePanel ? 0.25 : 0,
								height: "calc(100vh - 150px)",
								borderRadius: "15px 15px 0px 0px",
								backgroundColor: "#fff",
								boxShadow:'0px 0px 13px -2px rgba(0, 0, 0, 0.15)',
								scrollbarColor: "#cdcdcd #FFFFFF",
									"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
										backgroundColor: "#FFFFFF",
									},
							}}>
							<Stack direction={"row"} spacing={1} m={1}>
								<IconButton
									onClick={() => setSidePanel(!sidePanel)}>
									<ArrowBackIcon sx={{ fontSize: 24 }} />
								</IconButton>
							</Stack>
							<Stack
								direction={"row"}
								spacing={1}
								justifyContent="center"
								alignItems="center"
								sx={{}}>
								<Avatar
									alt={roomData?.title}
									src={roomData?.logo}
									sx={{ height: 90, width: 90, mb: 5 }}
								/>
							</Stack>
							<SidePanelRight messages={messageList} />
						</Stack>
					)}
					{/* list of rooms*/}
					{/* conversation */}
					{/* file, media and links */}
				</Stack>
			</Container>
			<Portal>
				<Dialog
					fullWidth={true}
					maxWidth={"xs"}
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title" textAlign={"center"} fontSize={16}>
						{!isUploadSign ? "Do you want to Upload the Contract." : 'Do you want to Sign and Upload Contract'}
					</DialogTitle>
					<DialogContent>
						<Stack direction={"row"} spacing={2} sx={{width: '100%'}} justifyContent="center"
							   alignItems="center">
							<label htmlFor="party-a-upload-document">
								<Input
									accept=".pdf, .doc, .docx"
									id="party-a-upload-document"
									multiple={false}
									type="file"
									onChange={(e) => {
										handleClose()
										const file = e.target.files[0];
										debugger
										setContractFile(file);
									}} />
								<Button variant={"contained"} sx={{px:2, textTransform: 'capitalize'}}  component={"span"}>Upload contract</Button>
							</label>
							{/*{!isUploadSign && <Button variant={"outlined"} sx={{px:2, textTransform: 'capitalize'}}  onClick={handleClose}>Use Template</Button>}*/}
						</Stack>
						{/*<DialogContentText id="alert-dialog-description" textAlign={"center"} fontSize={14}>
							<br/>
							The template enables you to e-sign the Contract.
						</DialogContentText>*/}
					</DialogContent>
				</Dialog>
			</Portal>
			
		</React.Fragment>
	);
}
