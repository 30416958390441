import { Box, Button, IconButton, Stack, Typography} from "@mui/material";
import React from "react";

import {EditSharp} from "@mui/icons-material";
import ComonInvestorAboutComponent from "./edit/common/comon-investor-about-component";
import {AuthUserContext} from "../../../providers/auth-provider";
const styleFont = {
  marginRight: "5px",
  backgroundColor: "#F6F8FA",
  padding: "8px 15px",
  borderRadius: "5px",
  fontSize: 14,
};

export default function CommonInvestorDetails({ profile, cb }) {
  const [open,setOpen] = React.useState(false)
  const [seeMore, setSeeMore] = React.useState(false)
  const {user} = React.useContext(AuthUserContext);
  const profileRegex = /profile/
  const regexAdmin = /admin\/user-details/
  
  
  const investorDetails = profile[profile?.userType === 'service-provider' ? 'serviceProvider'
    : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails;
  return (
      <>
      <Stack direction={"row"} spacing={2} >
        <Typography fontSize={20} fontWeight={600} style={{flex: 1}}>About Investor</Typography>
        {
          user?.uid === profile?.uid && profileRegex.test(window.location.pathname)  &&
          <IconButton onClick={() => setOpen(true)}>
            <EditSharp />
          </IconButton>
        }{
        regexAdmin.test(window.location.pathname)  &&
          <IconButton onClick={() => setOpen(true)}>
            <EditSharp />
          </IconButton>
        }
      </Stack>

      {/* <Grid container  gap={8} sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}> */}
      <Stack direction={"column"} spacing={4}>
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Investor type<br/>
              <font style={styleFont}>
                {investorDetails?.areYouAnAngelInvestor === true? 'Angel':null}
              </font>
           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            
          </Stack>

      </Stack>
      {/* {console.log('NEW TEST')} */}
      {/* <Grid container  gap={8} sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}> */}
      <Stack direction={"column"} spacing={4}> 
        
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Investment Stage<br/>
              {investorDetails?.investmentStage &&
                investorDetails?.investmentStage.length > 0 &&
                investorDetails?.investmentStage.map(
                  (ex, i) => (
                    <font style={styleFont}>
                      {ex}


                    </font>
                  )
                )}

              {/* <font style={styleFont}>{
                investorDetails?.investmentStage}
              </font> */}
           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
        
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Sector<br/>
              {investorDetails?.sector &&
                investorDetails?.sector.length > 0 &&
                investorDetails?.sector.map(
                  (ex, i) => (
                    <font style={styleFont}>
                      {ex}


                    </font>
                  )
                )}

              {/* <font style={styleFont}>{
                investorDetails?.sector}
              </font> */}
           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
      </Stack>

      {/* <Grid container  gap={8}  sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}> */}
      <Stack direction={"column"} spacing={4}>
        
          {/* {investorDetails?.additionalDetails &&
                investorDetails?.additionalDetails.length > 0 &&
            <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Additional Details<br/>
              {investorDetails?.additionalDetails &&
                investorDetails?.additionalDetails.length > 0 &&
                investorDetails?.additionalDetails.map(
                  (ex, i) => (
                    <font style={styleFont}>
                      {ex}


                    </font>
                  )
                )}

           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>} */}
        
        
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Geographies<br/>
              {investorDetails?.geographies &&
                investorDetails?.geographies.length > 0 &&
                investorDetails?.geographies.map(
                  (ex, i) => (
                    <font style={styleFont}>
                      {ex}


                    </font>
                  )
                )}
              {/* <font style={styleFont}>{
                investorDetails?.geographies}
              </font> */}
           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
      </Stack>
      {/* <Grid container  gap={8}  sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}> */}
      {seeMore ?
        <>
        
        <Stack direction={"column"} spacing={4}>  
        
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Lead Or Follow<br/> <font style={styleFont}>{
              investorDetails?.leadOrFollow}
            </font>
           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
        
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Ticket size<br/>
              <font style={styleFont}>
                {investorDetails?.currency === "USD" ? "$"
                  : investorDetails?.currency === "GBP" ? "£"
                    : "€"
                }
                {investorDetails?.startingAt}
                -
                {investorDetails?.currency === "USD" ? "$"
                  : investorDetails?.currency === "GBP" ? "£"
                    : "€"
                }
                {investorDetails?.upto}
              </font>
           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
      </Stack>
      {/* <Grid container  gap={8}  sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' }}> */}
      <Stack direction={"column"} spacing={4}>
        
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Pre-product <br/> <font style={styleFont}>{
              investorDetails?.preProduct ? 'Yes': 'No'}
            </font>
           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
        
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Product Pre-launch<br/> <font style={styleFont}>{
              investorDetails?.productLive? 'Yes': 'No'}
            </font>
           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
        
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Pre-revenue<br/> <font style={styleFont}>{
              investorDetails?.preRevenue? 'Yes': 'No'}
            </font>
           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
        
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Pre-PMF<br/> <font style={styleFont}>{
              investorDetails?.PMF ? 'Yes': 'No'}
            </font>
           </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
        
          <Stack direction={'column'} spacing={2}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Sole Founder<br/> <font style={styleFont}>{
              investorDetails?.soleFounder ? 'Yes': 'No'}
            </font>
           </Typography>
             
          </Stack>
        


          </Stack>
          <Stack alignItems={'center'}>
                          <Box>
                          <Button size={"small"} variant='outlined' color="secondary" sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See Less</Button>
                          </Box>
			                   </Stack> 
      </> : <Stack alignItems={'center'}>
                          <Box>
                          <Button size={"small"} variant='outlined' color="secondary" sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See More</Button>
                          </Box>
			                   </Stack> 

}

      <ComonInvestorAboutComponent profile={profile} open={open} handleClose={() => setOpen(false)}  callback={(data) => {
        cb(data)
        setOpen(false)
      }} />
      </>

    
  )

}
