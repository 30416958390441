import React, { useState } from "react";
import {
  Button, CircularProgress,
  Container,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate , useSearchParams} from "react-router-dom";
import { useFormik } from "formik";
import ForgetPasswordValidation from "../../validations/auth/forget-password-validation";
import { Grid } from "@material-ui/core";
import {collection, doc, getDocs, query, where} from "firebase/firestore";
import { auth, DB } from "../../config/firebase/firebase";
import { getAuth, confirmPasswordReset } from "firebase/auth";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import NotificationEmail from "../../api/notification-email";
import PostNotification from "../../api/post-notification";
const queryFirebase = query;

export default function ResetPassword() {
  const navigator = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const auth = getAuth();
  const [query] = useSearchParams()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = useState(false);

  const [values, setValues] = React.useState({
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',
    showoldPassword: false,
    shownewPassword: false,
    showconfirmPassword: false,
  });
  const [newPasswordError,setNewPasswordError] = React.useState("");
  const [confirmPasswordError,setConfirmPasswordError] = React.useState("");
  const [updateStatus, setUpdateStatus] = React.useState("")
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showoldPassword: !values.showoldPassword,

    });
  };

  const handleClickNewShowPassword = () => {
    setValues({
      ...values,
      shownewPassword: !values.shownewPassword,
    });
  };

  const handleClickConfirmShowPassword = () => {
    setValues({
      ...values,
      showconfirmPassword: !values.showconfirmPassword,
    });
  };


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  console.log(query.get('oobCode'))
  const handleValidation = (event) => {
    event.preventDefault();
    const value= event.target.value.trim();
    const passwordInputFieldName = event.target.name;

    // for new password

    if(passwordInputFieldName === 'newpassword'){
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/;
      const minLengthRegExp   = /.{8,}/;

      const passwordLength =      value.length;
      const uppercasePassword =   uppercaseRegExp.test(value);
      const lowercasePassword =   lowercaseRegExp.test(value);
      const digitsPassword    = digitsRegExp.test(value);
      const specialCharPassword = specialCharRegExp.test(value);
      const minLengthPassword =   minLengthRegExp.test(value);


      if(passwordLength===0)
      {setNewPasswordError("Your Password is empty.")}
      else if(!uppercasePassword)
      {setNewPasswordError("Your password must have at least one uppercase.")}
      else if(!lowercasePassword)
      {setNewPasswordError("Your password must have at least one lowercase.")}
      else if(!digitsPassword)
      {setNewPasswordError("Your password must have at least one number.")}
      else if(!specialCharPassword)
      {setNewPasswordError("Your password must have at least one special character.")}
      else if(!minLengthPassword)
      {setNewPasswordError("Your password must have at least 8 characters.")}
      else{
        setNewPasswordError("");
        // sethasError(false);
      }

    }

    // Confirm Password

    if(passwordInputFieldName=== "confirmpassword" || (passwordInputFieldName==="newpassword" && values.confirmpassword.length>0)){

      if(values.confirmpassword!==values.newpassword)
      { setConfirmPasswordError("Please make sure your passwords match.")
      }
      else{
        setConfirmPasswordError("");
      }
    }
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    setUpdateStatus("")
    if(!newPasswordError && !confirmPasswordError){
      setLoading(true)
       confirmPasswordReset( auth , query.get('oobCode'), values.newpassword)
         .then(async () => {
           NotificationEmail({type: 'password', email: query.get('email')})
             .then();
           const q = queryFirebase(
             collection(DB, "users"),
             where("email", "==", query.get('email'))
           );
           debugger;
           const querySnapshot = await getDocs(q);
           if (querySnapshot.size > 0) {
             PostNotification({
               type: 'change-password',
               imageURL: '/assets/system-noti-icon.png',
               message: `Your password has been successfully changed.`,
               uid: querySnapshot.docs[0].id
             }).then()
           }
           setLoading(false)

           setOpen(true)
           setCurrentStep(2)
         })
         .catch((err) => {
           setLoading(false)
  
           console.log(err)
         })
    }
  }

  return (
    <>
      {currentStep === 1 && (
        <Container maxWidth={"sm"} sx={{ mt: 10 }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={5}
            component="form"
            onSubmit={handleSubmit}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "32px",
                width: "100%",
                fontWeight: "600",
              }}
            >
              Create new password
              <Typography sx={{ mt: 2, fontSize: "18px" }}>
                Your new password must be different to
                <br></br>
                previously used passwords
              </Typography>
            </Typography>

            <FormControl fullWidth sx={{ width: '100%'}} variant="outlined" >
            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
            <OutlinedInput
              required
              error={newPasswordError}
              type={values.shownewPassword ? 'text' : 'password'}
              value={values.newpassword}
              onChange={handleChange('newpassword')}
              onKeyUp={handleValidation}
              name="newpassword"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickNewShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!values.shownewPassword ? <img src="/assets/eye_close.png" alt=""/> : <img src="/assets/eye_open.png" alt=""/>}
                  </IconButton>
                </InputAdornment>
              }
              label={"New Password"}
            />

            <Typography align='left' sx={{color:"#ED1C24", fontWeight:"500", fontSize:"16px"}}>{newPasswordError}&nbsp;</Typography>
          </FormControl>

          <FormControl fullWidth sx={{width: '100%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
            <OutlinedInput
              required
              error={confirmPasswordError}
              type={values.showconfirmPassword ? 'text' : 'password'}
              value={values.confirmpassword}
              onKeyUp={handleValidation}
              onChange={handleChange('confirmpassword')}
              name="confirmpassword"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickConfirmShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!values.showconfirmPassword ? <img src="/assets/eye_close.png" alt=""/> : <img src="/assets/eye_open.png" alt=""/>}
                  </IconButton>
                </InputAdornment>
              }
              label={"Confirm Password"}
            />
            <Typography align='left' sx={{color:"#ED1C24", fontWeight:"500", fontSize:"16px"}}>{confirmPasswordError}&nbsp;</Typography>
          </FormControl>
            {/* <TextField
              name={"password"}
              variant={"outlined"}
              type={"password"}
              placeholder={"xxxxxxxx"}
              label={"New Password"}
              fullWidth
            /> */}

            {/* <TextField
              name={"password"}
              variant={"outlined"}
              type={"password"}
              placeholder={"xxxxxxxx"}
              label={"Confirm Password"}
              fullWidth
            /> */}
            <Grid container justify="center" alignItems="center">
              <Button
                sx={{ width: "100%" }}
                variant={"contained"}
                color={"primary"}
                onClick={handleSubmit}
                disabled={ newPasswordError || confirmPasswordError  || values.confirmpassword.length===0 || loading}
                endIcon={loading && <CircularProgress color={"secondary"} size={16}/>}
              >
                Reset Password
              </Button>
            </Grid>

            {/* <Typography
            sx={{ textAlign: "center", fontSize: "14px", width: "100%" }}
            onClick={() => navigator("/auth/signin")}
          >
            Back to Sign In
          </Typography> */}
          </Stack>
        </Container>
      )}

      {currentStep === 2 && (
        <Container maxWidth={"sm"} sx={{ mt: 10 }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={5}
            component="form"
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "32px",
                width: "100%",
                fontWeight: "600",
              }}
            >
              Password reset
              <Typography sx={{ mt: 2, fontSize: "18px" }}>
                Your password has been successfully reset
                <br></br>
                Click below to log in magically
              </Typography>
            </Typography>

            <Grid container justify="center" alignItems="center">
              <Button
                sx={{ width: "70%" }}
                variant={"contained"}
                color={"primary"}
                onClick={() => navigator("/auth/signin")}
              >
                continue
              </Button>
            </Grid>
          </Stack>
        </Container>
      )}
    </>
  );
}
