import * as React from 'react';
import {collection, doc, getDoc, limit, onSnapshot, orderBy, query, startAfter, where} from "firebase/firestore";
import {DB} from "../../../../config/firebase/firebase";
import {Avatar, Button, Portal, Skeleton, Stack, Typography} from "@mui/material";
import ProposalRequestDialog from "./proposal-request-dialog";
import {AuthUserContext} from "../../../../providers/auth-provider";
import {useNavigate} from "react-router-dom";


export default function RequestUserAvatar({request}){
  const {profile} = React.useContext(AuthUserContext);
  const navigator = useNavigate()
  const [user, setUser] = React?.useState();
  const [loading, setLoading] = React?.useState(false);
  const [open, setOpen] = React?.useState(false); 
  const [applied, setApplied] = React?.useState(false);
  const [counts, setCounts] = React?.useState(0);
  React.useEffect(() => {
    setLoading(true)
    const userRef = doc(DB, "users", request?.uid);
    getDoc(userRef).then((doc) => {
      setUser(doc?.data())
      setLoading(false)
  
    });
  }, [request?.id])
  React.useEffect(() => {
    setLoading(true)
   const q =  query(
      collection(DB, "applicants"),
      where('requestId', '==', request?.id),
      orderBy("createdAt", "desc")
    )
    let unsub ;
    unsub = onSnapshot(
      q,
      (snapshot)=> {
        setCounts(snapshot?.size)
        snapshot.forEach(
          (doc) => {
            doc?.data()?.applicantUid === profile?.uid && setApplied(true)
          }
        )
      });
    return () => unsub
  }, [request?.id])
  return(
    <React.Fragment>
      {!loading ? <Stack direction={"column"} spacing={.5} width={'90%'} justifyContent="center"
                         alignItems="center">
        {/* <Avatar aria-label={user?.displayName} src={user?.photoURL} sx={{width:51, height:51}}>
          <Typography variant={"h5"}>{user?.displayName?.charAt(0)}</Typography>
        </Avatar> */}
        {/* <Typography variant={"body1"} fontWeight={400} color={'#363636'} maxWidth={97} noWrap={true}>{user?.displayName}</Typography> */}
  
        {request.uid !== profile?.uid && !applied &&       <Button fullWidth variant={"contained"}  size={"small"} sx={{px: 2}} onClick={() => setOpen(!open)} disabled={request?.stage === "Closed"}>Apply now</Button>
        }
        { applied === true &&
          <Button variant={"contained"} color={"secondary"} size={"small"} sx={{px: 2}} onClick={() => setOpen(!open)} disabled>Applied</Button>
        }
          { request.uid ===profile?.uid &&
            <Stack direction={"row"} alignItems={"center"} width={'100%'}> <Button
              onClick={()=>{
                if (
                  request.type === "looking-for-funding" ||
                  request.requestFor === "Looking for funding"
                ) {
                  navigator(
                    `/m/${profile.userType}/update-funding-request/${request.id}`
                  );
                } else {
                  navigator(
                    `/m/${profile.userType}/open-requests-update/${request.id}`
                  );
                }
              }}
              fullWidth
              size={"small"}
              variant={"contained"}
              color={"primary"}
             
              // sx={{ px: 2, color:"blue", boxShadow:"none", backgroundColor:"white", "&:hover" :{backgroundColor:"white"} }}
            >
              Edit
            </Button></Stack>}
        <Typography variant={"caption"} color={'#8F8F8F'}>{counts > 0 && `${counts === 1 ? '1 applicant' : `Over ${counts} applicants`}`}</Typography>
      </Stack>
      :
        <Stack direction={"column"} spacing={.5} width={150}  justifyContent="flex-start"
               alignItems="center">
          <Skeleton variant={"circular"} height={51} width={51} />
          <Skeleton variant={'text'}/>
        </Stack>
      }
      
      <Portal>
        {request && <ProposalRequestDialog request={request} open={open} cb={(bool) => setOpen(bool)} key={'applicant-' + request?.id}/>}
      </Portal>
      
    </React.Fragment>
  )
}
