import React from "react";
import { Button, Container, Stack, Typography } from "@mui/material";
import { AuthUserContext } from "../../../providers/auth-provider";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import { BUSINESS_DEVELOPER } from "../../../utils/enums/labels.enum";
import ExperienceCardGrowthExpert from "../../../components/common/experience-card-growth-expert";
import { doc, getDoc } from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
export default function ExperienceListViewGrowthExpert({
  userType,
  data,
  add,
  cbBack,
  cbNext,
  submit,
  edit,
  remove,
  cbDraft,
  uid,
}) {
  const isAdmin = /admin/;
  const [experiences, setExperiences] = React.useState([]);
  React.useEffect(() => {
    if (uid && (!data || data?.length === 0)) {
      setTimeout(() => {
        getDoc(doc(DB, "users", uid)).then((document) => {
          if (document?.data()?.experiences) {
            setExperiences(document?.data()?.experiences);
          }
        });
      }, 100);
    } else setExperiences(data);
  }, [data]);
  return (
    <Container maxWidth={"lg"}>
      <Stack
        direction={"column"}
        spacing={5}
        sx={{ width: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        {experiences?.length === 0 ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              width: "696px",
              height: 252,
              border: "1px dashed rgba(0, 0, 0, 0.45)",
              marginX: "auto",
              boxSizing: "border-box15",
            }}
          >
            <Typography variant={"h5"} fontWeight={500} color={"#6E6E6E"}>
              <font color={"#A9ABAE"}>You have not added any</font>
              <br />
              {userType === "expert"
                ? "Expert"
                : userType === "business-developer"
                ? `${BUSINESS_DEVELOPER.SINGULAR_LABEL} `
                : userType === "service-provider"
                ? "Service Providing"
                : ""}{" "}
              Experience
              <font color={"#A9ABAE"}> yet.</font>
            </Typography>
          </Stack>
        ) : (
          experiences?.length > 0 && (
            <Grid container gap={4}>
              {experiences.map((po, i) => (
                <ExperienceCardGrowthExpert
                  data={po}
                  key={i}
                  edit={() => edit(i, experiences)}
                  remove={() => remove(i, experiences)}
                />
              ))}
            </Grid>
          )
        )}
        <Button
          variant={"contained"}
          color={"success"}
          startIcon={<AddIcon />}
          onClick={add}
        >
          Add experience
        </Button>

        <span />
        <span />
        <Stack
          direction={"row"}
          justifyContent="center"
          alignItems="center"
          spacing={3}
          width={"100%"}
        >
          {isAdmin.test(window.location.pathname) && (
            <Button
              variant={"outlined"}
              color={"primary"}
              sx={{ px: 5 }}
              onClick={() => cbDraft(experiences)}
            >
              Save draft
            </Button>
          )}
          <Button
            variant={"outlined"}
            color={"primary"}
            sx={{ px: 5 }}
            onClick={cbBack}
          >
            Back
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{ px: 5 }}
            onClick={() => submit()}
          >
            Next
          </Button>
        
        </Stack>
      </Stack>
    </Container>
  );
}
