import React from "react";

import axios from "axios";


export default function DeleteUserApi(data, token) {
  return axios.post(
    'https://us-central1-astranovaapp.cloudfunctions.net/api/admin/user-delete',
    // 'http://localhost:5001/systema-nova/us-central1/api/admin/user-delete',
    {...data},
    {headers: { 'Content-Type': 'application/json','Id-Token': token} }
  )

}
