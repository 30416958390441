import React from 'react';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import { AuthUserContext } from "../../providers/auth-provider";
import {
  Container, Stack, Typography, Menu, Button, IconButton, Box, MenuItem, Portal, Dialog,
  DialogContent,
  DialogContentText, DialogTitle, TextField, DialogActions, CircularProgress
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import PostItem from "../../components/common/timeline/post-item";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CommonAvatar from "../../components/common-avatar";
import { CloseSharp, InsertDriveFile } from "@mui/icons-material";
import DownloadFileHelper from "../../utils/download-file-helper";
import Grid from "@mui/material/Grid";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ListItemStartConversation from '../../components/common/list-item-start-conversation';
import PostNotification from "../../api/post-notification";
export default function Applicants() {
  const { user,profile } = React.useContext(AuthUserContext);
  const [declineOpen, setDecline] = React?.useState(false);
  const [rejection, setRejection] = React?.useState(false);
  const [hiredOpen, setHiredOpen] = React?.useState(false);
  const [hiredOpen2, setHiredOpen2] = React?.useState(false);
  const [hired, setHired] = React?.useState(false);
  const [applicantName, setApplicantName] = React?.useState();
  const [rejectionSuccess, setRejectionSuccess] = React?.useState(false);
  const [reason, setReason] = React?.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElRating, setAnchorElRating] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState('Rating');
  const open = Boolean(anchorEl);
  const openFilterRating = Boolean(anchorElRating);
  const navigator = useNavigate()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }; const handleClickRating = (event) => {
    setAnchorElRating(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseRating = () => {
    setAnchorElRating(null);
  };
  const [filter, setFilter] = React.useState('')
  const [applicants, setApplicants] = React.useState([])
  const [request, setRequest] = React.useState()
  const [counts, setCounts] = React?.useState(0);
  const [index, setIndex] = React?.useState(0);
  const [selected, setSelected] = React?.useState('');
  const [loading, setLoading] = React?.useState(false);
  const { requestId } = useParams()
  const navigate = useNavigate()
  const cbFilter = (appl) => filter === '' ? appl : filter === appl?.rating
  React.useEffect(() => {
    setLoading(true)
    getDoc(doc(DB, 'help-requests', requestId))
      .then((snapshot) => setRequest(snapshot?.data()))

  }, [requestId])
  React.useEffect(() => {
    setLoading(true)
    const q = query(
      collection(DB, "applicants"),
      where('requestId', '==', requestId),
      orderBy("createdAt", "desc")
    )
    let unsub;
    unsub = onSnapshot(
      q,
      (snapshot) => {
        setCounts(snapshot?.size)
        const list = []
        snapshot.forEach(
          (doc) => {
            list.push({ id: doc.id, ...doc.data() })
          }
        )
        setApplicants(list)
        setLoading(false)
      });
    return () => unsub
  }, [requestId])
  const updateRequest = async (updateRequest) => {
    const applicantRef = doc(DB, "applicants", selected);
    const update = await updateDoc(applicantRef, { ...updateRequest, updatedAt: serverTimestamp() })
    if (updateRequest?.declined === true){
      debugger
      PostNotification({
        type: 'decline-applicant',
        imageURL: 'https://firebasestorage.googleapis.com/v0/b/systema-nova.appspot.com/o/Cross.png?alt=media&token=e6ee0780-7950-4acf-a542-d94eb4567fdf',
        message: `Sorry, your proposal for the '${request?.requestFor}' job request has been declined!`,
        uid: applicants.find((f) => f?.id === selected)?.applicantUid,
        requestFor: request?.requestFor,
        request: requestId
      }).then()
    }
    if (updateRequest?.hired === true){
      debugger
      PostNotification({
        type: 'accept-applicant',
        imageURL: 'https://firebasestorage.googleapis.com/v0/b/systema-nova.appspot.com/o/Check.png?alt=media&token=e99fdd00-505e-498d-911d-5e84767c2281',
        message: `Your proposal for the '${request?.requestFor}' job request has been accepted!`,
        uid: applicants.find((f) => f?.id === selected)?.applicantUid,
        requestFor: request?.requestFor,
        request: requestId
      }).then()
    }
  }
  const createChat = async (data) => {
    debugger
    setLoading(true)
    const userType =data.creatorData.userType;
    if (data?.uid !== profile?.uid) {
      try {
        const q = query(
          collection(DB, "rooms"),
          where('participates', 'in', [[data?.uid, profile?.uid], [profile?.uid,data?.uid]]),
          where('requestId', '==', requestId)
        );
        const getRoom = await getDocs(q);
        let room = getRoom.size > 0 ? {id: getRoom.docs[0].id, ...getRoom.docs[0].data()} :
          await addDoc(collection(DB, "rooms"),
            {
              title: userType === 'startup' ? data.creatorData?.startup?.startupName : userType === 'service-provider'  ? data.creatorData?.serviceProvider?.displayName: data.creatorData?.displayName,
              subTitle: `${applicantName}, ${profile?.displayName}`,
              participates:[data?.uid, user.uid],
              uid: user?.uid,
              logo: userType === 'startup' ? data.creatorData?.startup?.logoURL : userType === 'service-provider'  ? data.creatorData?.serviceProvider?.photo || data.creatorData?.photoURL :  data.creatorData?.photoURL,
              updatedAt: serverTimestamp(),
              createdAt: serverTimestamp(),
              requestId,
              hiredUid: data?.uid,
              applicantId: data?.id,
              hired: true,
              lastMsg: {
                text: 'Looks like you found someone you want to work with.\n Now, let\'s proceed with setting up the contract.',
                status: 'send'
              }
            });
        // await setRoomId(room.id)
        if (getRoom.size === 0) {
          let message = await addDoc(collection(DB, "messages"),
            {
              type: 'system-generated',
              text: 'Looks like you found someone you want to work with.\n Now, let\'s proceed with setting up the contract.',
              sender:  user.uid,
              receiver: data?.uid,
              createdAt:serverTimestamp(),
              roomId: room?.id,
              read:false,
              status: 'send'
            });
        }
        setLoading(false)
        
        navigator(`/${profile.userType}/chat`)
      } catch (e) {
        
        setLoading(false)
        
      }
    } else{
      setLoading(false)
    }
    
    
    
    
  }
  return (<Container maxWidth={'xl'} sx={{ p: 5 }}>
    <Stack direction={"column"} pl={2} spacing={0}>
      <Typography variant={"h4"} fontWeight={600} textAlign={"left"}><IconButton ><ArrowBackIcon
        onClick={() => {
          navigate(-1)
        }}
        sx={{ fontSize: 40, color: '#000' }}
      /></IconButton> Applicants</Typography>


    </Stack>
    <Container maxWidth={'xl'}>
      <Stack direction={"column"} spacing={5} sx={{ width: '100%', textAlign: 'left' }}>
        <span />
        <Stack direction={"row"} spacing={2}>
       {/*   <Button size={"small"} variant={"outlined"} sx={{ borderRadius: '5px' }}>Filter</Button>*/}

          <Button size={"small"} variant={"outlined"} sx={{ borderRadius: '5px' }} endIcon={<KeyboardArrowDownIcon />}
            id="demo2-positioned-button"
            aria-controls="demo2-positioned-menu"
            aria-haspopup="true"
            aria-expanded={openFilterRating ? 'true' : undefined}
            onClick={handleClickRating}

          >{filter !== '' ? filter : 'Rating'}</Button>
          <Menu
            id="demo2-positioned-menu"
            aria-labelledby="demo2-positioned-button"
            anchorEl={anchorElRating}
            open={openFilterRating}
            onClose={handleCloseRating}

            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}

          >
            <MenuItem onClick={() => {
              setFilter('')
              setSelected('')

              handleCloseRating()
            }} selected={filter === ''}>All</MenuItem>
            <MenuItem onClick={() => {
              setFilter('Best fit')
              setSelected('')

              handleCloseRating()
            }} selected={filter === 'Best fit'}>Best fit</MenuItem>
            <MenuItem onClick={() => {
              setFilter('Maybe')
              setSelected('')

              handleCloseRating()
            }} selected={filter === 'Maybe'}>Maybe</MenuItem>
            <MenuItem onClick={() => {
              setFilter('Decline')
              setSelected('')


              handleCloseRating()
            }} selected={filter === 'Decline'}>Decline</MenuItem>
          </Menu>
        </Stack>

        <Stack direction={"row"} spacing={3.5}
         sx={{ height: 'calc(100vh - 70px)' }}
        >
          {
            applicants.length === 0 ?
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                textAlign={'center'}
                mt={"10%"}
                sx={{
                  width: "60%"
                  , height: 155, padding: 2, marginX: 'auto',
                }}
              >
                <img src={"/assets/blanks/blank_applicant.svg"} alt='none'/>
                <Typography variant={'h5'} fontWeight={600} textAlign={'center'}>
                  <font>No applicants yet! <br/>We'll let you know as soon as we have any updates..</font>

                </Typography>
              </Stack> :
              <Stack direction={"column"} flex={selected !== '' ? .35 : .35} spacing={1.8} sx={{
                p: 1.5,
                background: '#FFFFFF',
                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1), -2px -2px 4px rgba(0, 0, 0, 0.1)',
                borderRadius: '15px 15px 0px 0px',
                overflowY: 'scroll'
              }} >
                {

                  applicants && applicants.length > 0 && 
                  applicants.filter(cbFilter).length!==0 ? applicants.filter(cbFilter).map((pid, i) => (
                    <ApplicantItem id={pid?.applicantUid} applicant={pid} selected={pid?.id === selected} cb={(id) => {
                      setSelected(id)
                      applicants[i]?.rating ? setSelectedStatus(applicants.filter(cbFilter)[i]?.rating) : setSelectedStatus('Rating')
                      setIndex(i)
                    }} />
                  ))
                  :
                  <Stack direction={'column'} alignItems={'center'} spacing={2} textAlign={'center'} margin={'auto'} mt={"40%"} width={"60%"}>
                    <img src={"/assets/blanks/blank_applicant_filter.svg"} width={"75%"} alt='none'/>
                    <Typography fontWeight={600} width={"75%"} variant='h5'>
                    Nothing Yet!
                    </Typography>
                  </Stack>
                }
              </Stack>}
          {
            selected !== '' && <Stack direction={"column"} flex={.70} spacing={2} sx={{

              background: '#FFFFFF',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1), -2px -2px 4px rgba(0, 0, 0, 0.1)',
              borderRadius: '15px 15px 0px 0px',
              overflowY: 'hidden'
            }} >
              {console.log(applicants.filter(cbFilter)[index])}
              <Stack direction={"row"} justifyContent="flex-start"
                alignItems="center" spacing={1} sx={{ height: 70, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)', p: 1.5 }}>
                {applicants?.length > 0 && <CommonAvatar uid={applicants.filter(cbFilter)[index]?.applicantUid} cb={(name) => setApplicantName(name)} />}
                <Box flex={1} />
                <Button size={"small"} variant={"outlined"} sx={{ width: 100 }} onClick={() => setDecline(!declineOpen)}  disabled={applicants.filter(cbFilter)[index]?.hired || applicants.filter(cbFilter)[index]?.declined}>
                  {applicants.filter(cbFilter)[index]?.declined ? "Declined" : "Decline"} 
                  </Button>
                <ListItemStartConversation data={{ ...applicants[index], uid: applicants[index].applicantUid, creatorData: profile, applicant: true }} />
                {
                  applicants.filter(cbFilter)[index]?.hired &&
                  <Button size={"small"} variant={"contained"} sx={{ width: 100 }} disabled>Hired</Button>
                }
                {
                  (!applicants.filter(cbFilter)[index]?.hired) &&
                  <Button size={"small"} variant={"contained"} sx={{ width: 100 }} disabled={applicants.filter(cbFilter)[index]?.declined} onClick={() => { setHiredOpen(true); }}>Hire</Button>
                }
              </Stack>

              <Stack flex={1} sx={{ borderBottom: '1px solid #ccc', overflowY: 'hidden' }}>
                <Stack direction={"column"} spacing={3} p={1.5} pt={0}>
                  <Button size={"small"} variant={"outlined"} color={"secondary"} sx={{ borderRadius: '5px', width: 'fit-content' }} endIcon={<KeyboardArrowDownIcon />}
                    id="demo-positioned-button"
                    aria-controls="demo-positioned-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}

                  >{selectedStatus}</Button>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}

                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}

                  >
                    <MenuItem onClick={() => {
                      setSelectedStatus('Best fit')
                      if ('Best fit' !== filter) {
                        setSelected('')
                      }
                      updateRequest({ rating: 'Best fit' }).then()

                      handleClose()
                    }} selected={selectedStatus === 'Best fit'}>Best fit</MenuItem>
                    <MenuItem onClick={() => {
                      setSelectedStatus('Maybe')
                      if ('Maybe' !== filter) {
                        setSelected('')
                      }
                      updateRequest({ rating: 'Maybe' }).then()
                      handleClose()
                    }} selected={selectedStatus === 'Maybe'}>Maybe</MenuItem>
                    <MenuItem onClick={() => {
                      setSelectedStatus('Decline')
                      if ('Decline' !== filter) {
                        setSelected('')
                      }
                      updateRequest({ rating: 'Decline' }).then()

                      handleClose()
                    }} selected={selectedStatus === 'Decline'}>Decline</MenuItem>
                  </Menu>
                  <Typography variant={"subtitle1"} lineHeight={1.7} paragraph={true} textAlign={"left"} color={'#363636'}>
                    {applicants[index]?.additionalNotes}
                  </Typography>
                  <Grid container gap={1} >
                    {
                      applicants[index]?.attachments && applicants[index]?.attachments.length > 0
                      && applicants[index]?.attachments?.map((d, i) => (<AttachmentItemDownloadable width={d.length > 30 ? 500 : 200} pathURL={d} key={d.split("/").splice(-1)} downloadAble={true} />))
                    }
                    {/*{applicants[index]?.attachments[0]?.split("/").splice(-1).toString().length}*/}

                  </Grid>

                </Stack>
              </Stack>

            </Stack>

          }




        </Stack>

      </Stack>
    </Container>
    <Portal>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={declineOpen}
        onClose={() => setDecline(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 3 }}>
          <Stack direction={"column"} spacing={3} justifyContent="center"
            alignItems="center">
            <img src={'/assets/image 30.png'} height={92} width={92} />
            <Typography variant={"h5"} fontWeight={500}>Decline Proposal</Typography>
            <Typography variant={"body2"} textAlign={"center"}>Are you sure you want to decline this proposal? <br />
              This action can not be undone.</Typography>
            <Stack direction={"row"} spacing={5}>
              <Button variant={"outlined"} sx={{ width: 120 }} onClick={() => setDecline(!declineOpen)}>No</Button>
              <Button variant={"contained"} sx={{ width: 120 }} onClick={() => {
                setDecline(!declineOpen)
                updateRequest({ declined: true }).then();
                setRejection(!rejection)
              }}>Yes</Button>
            </Stack>
          </Stack>
        </DialogContent>


        {/*   <DialogActions>
          <Button onClick={() => setDecline(false)}>Disagree</Button>
          <Button onClick={() => setDecline(false)} autoFocus>
            Agree
          </Button>
        </DialogActions>*/}
      </Dialog>
    </Portal>


    <Portal>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={rejection}
        onClose={() => setRejection(false)}
      >

        <DialogTitle sx={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <Stack direction={"row"} spacing={3} alignItems={"center"} sx={{ width: '100%' }}>
            <Typography variant={"h5"} fontWeight={500} flex={1} >
              Rejection Message
            </Typography>
            <IconButton onClick={() => setRejection(!rejection)}>
              <CloseSharp ></CloseSharp>
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction={"column"} spacing={3} justifyContent="flex-start"
            alignItems="start" sx={{ p: 3, px: 2 }}>
            {applicants?.filter(cbFilter).length > 0 && <CommonAvatar uid={applicants?.filter(cbFilter)[index]?.applicantUid} />}

            <Box sx={{
              p: 2,
              width: '100%',
              borderRadius: "12px",
              backgroundColor: "#E4EAF5",
              maxHeight: 250,
              overflowY: 'scroll',

            }}>
              <InputBase autoFocus={true} multiline={true} minRows={10} color={"primary"} fullWidth style={{ fontSize: 14 }} />

            </Box>


          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3, boxShadow: '0px -3px 4px rgba(0, 0, 0, 0.1)' }}>
          <Button variant={"contained"} sx={{ width: 120 }} onClick={() => {
            setRejection(!rejection)
            setRejectionSuccess(!rejectionSuccess)
          }}>Send</Button>
        </DialogActions>

      </Dialog>


    </Portal>
    {/*Hire Popup */}
    <Portal>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={hiredOpen}
        // onClose={() => setDecline(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 3 }}>
          <Stack direction={"column"} spacing={3} justifyContent="center"
            alignItems="center">
            <img src={'/assets/image 30.png'} height={92} width={92} alt="icon"/>
            <Typography variant={"h5"} fontWeight={500}>Confirm Hiring?</Typography>
            <Typography variant={"body2"} textAlign={"center"}>Are you sure you want to confirm this proposal?<br />
              This action can not be undone.</Typography>
            <Stack direction={"row"} spacing={5}>
              <Button variant={"outlined"} sx={{ width: 120 }} onClick={() => setHiredOpen(!hiredOpen)}>No</Button>
              <Button variant={"contained"} sx={{ width: 120 }} onClick={() => {
                setHiredOpen(false);
                updateRequest({ hired: true }).then();
            
                
                
                setHiredOpen2(true);
              }} endIcon={() => loading && <CircularProgress size={18} />} disabled={loading}>Yes</Button>
            </Stack>
          </Stack>
        </DialogContent>


        {/*   <DialogActions>
          <Button onClick={() => setDecline(false)}>Disagree</Button>
          <Button onClick={() => setDecline(false)} autoFocus>
            Agree
          </Button>
        </DialogActions>*/}
      </Dialog>
    </Portal>
    <Portal>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={hiredOpen2}
        onClose={() => setHiredOpen2(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 3 }}>
          <Stack direction={"column"} spacing={3} justifyContent="center"
            alignItems="center">
            <img src={'/assets/smile-smiley.png'} height={92} width={92} alt="icon"/>
            <Typography variant={"h5"} fontWeight={600}>Awesome</Typography>
            <Typography variant={"body2"} textAlign={"center"}>cheers to acceptance! Lets bring our best ideas and  <br />
              creativity to the table</Typography>
            <Stack direction={"row"} spacing={5}>
              <Button variant={"contained"} sx={{ width: "100%" }} onClick={() => {
                setHiredOpen2(false)
                createChat({...applicants[index], uid: applicants[index].applicantUid, creatorData: profile, id: applicants[index]?.id}).then()
              }}>Continue</Button>
            </Stack>
          </Stack>
        </DialogContent>


        {/*   <DialogActions>
          <Button onClick={() => setDecline(false)}>Disagree</Button>
          <Button onClick={() => setDecline(false)} autoFocus>
            Agree
          </Button>
        </DialogActions>*/}
      </Dialog>
    </Portal>
    {/* <Portal>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={rejectionSuccess}
        onClose={() => setRejectionSuccess(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{p:3}}>
          <Stack direction={"column"} spacing={3} justifyContent="center"
                 alignItems="center">
            <img src={'/assets/image 34.png'} height={92} width={92}/>
            <Typography variant={"h5"} fontWeight={500}>Proposal Denied!</Typography>
            <Typography variant={"body2"} textAlign={"center"}>Proposal has been successfully denied.<br/>
              Thank you for your submission</Typography>
  
  
            <Stack direction={"row"} spacing={5} sx={{width:'100%'}}>
              <Button variant={"contained"} fullWidth onClick={() => {
      
                setRejectionSuccess(!rejectionSuccess)
              }}>continue</Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Portal> */}

  </Container>)
}

function ApplicantItem({ applicant, selected, cb }) {

  if (applicant) return (

    <Stack direction={"column"} spacing={.5} sx={
      {
        width: '100%',
        boxShadow: selected ? 'none' : '1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: selected ? '#F6F8FA' : '#fff',
        borderRadius: '5.7553px',
        maxHeight: 400,
   
        p: 1
      }} onClick={() => {
        cb(applicant?.id)
      }}>
      <CommonAvatar uid={applicant?.applicantUid} />
      <Typography variant={"caption"} textAlign={"left"} style={{marginBottom:"-10px"}} color={'#363636'} paragraph={true} maxHeight={40} sx={{ overflowY: 'hidden' }}>{applicant?.additionalNotes}</Typography>
      {applicant?.attachments && applicant?.attachments.length > 0 &&
       <AttachmentItemDownloadable  pathURL={applicant.attachments[0]}/> }
      {/* {
        applicant?.attachments && applicant?.attachments.length > 0
        && applicant?.attachments?.map((d, i) => (
          <>
          <AttachmentItemDownloadable pathURL={d} key={d.split("/").splice(-1)} />
          </>
  
          ))


      } */}
 

    </Stack>
  )
}


function AttachmentItemDownloadable({ pathURL, width, downloadAble }) {


  return (
    <Grid    sx={{ backgroundColor: downloadAble ? '#F6F8FA' : 'none', borderRadius: '0px 12px 12px 12px', p: 1, width: width && 'fit-content' }}>

      <Stack
        direction={"row"}
        spacing={1}
        justifyContent="flex-start"
        alignItems="center" >
        
          <img src="/assets/image5.png" alt="ico"/>
          <Typography noWrap={true} sx={{ width: width ? '100%' : 250 }}> {pathURL?.split("/").splice(-1)}</Typography>
        
        {
          downloadAble &&
          <>
            <Box flex={1} />
            <IconButton
              onClick={() => {
                DownloadFileHelper(pathURL);
              }}>
              <CloudDownloadIcon sx={{ color: "#363636" }} />
            </IconButton>
          </>
        }
      </Stack>
    </Grid>
  )

}
