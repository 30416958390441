import React from "react";
import { Container, Typography, Stack, IconButton, Box , Avatar } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {  useNavigate } from "react-router-dom";
import DownloadFileHelper from '../../../../../utils/download-file-helper';
import ScrollToTop from "../../../../../utils/scrolling-top";
import { shortenURL } from "../../../../../utils/shortenURL";
const boxstyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4.97213px 6.62951px 4.97213px 9.94426px',
  gap: '6.63px',
  color: '#1D192B',
  // position: 'absolute',
  // width: '116.57px',
  // height: '26.94px',
  backgroundColor: '#E0E3FF',
  borderRadius: '6.62951px',
  fontSize:'14px'
}

const commonStyles = {
  // bgcolor: "background.paper",
  // m: 1,
  // border: 1,
  // width: "1198px",
  // height: "1200px",
};
export default function InvestmentPortfolioView({profile , i }) {
  const navigator = useNavigate();
  const amforinvestor=profile?.investor?.portfolio[i]?.amount;
  const amforbusiness=profile?.businessDeveloper?.investorDetails?.portfolio[i].amount;
  const amforexpert= profile?.expert?.investorDetails?.portfolio[i].amount;
  const amforservice= profile?.serviceProvider?.investorDetails?.portfolio[i].amount;
  const valforinvestor=profile?.investor?.portfolio[i]?.valuation;
  const valforbusiness=profile?.businessDeveloper?.investorDetails?.portfolio[i].valuation;
  const valforexpert=profile?.expert?.investorDetails?.portfolio[i].valuation;
  const valforservice= profile?.serviceProvider?.investorDetails?.portfolio[i].valuation;
  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  }
  let user = profile?.userType === 'business-developer' ?
    profile['businessDeveloper'].investorDetails :
    profile?.userType === 'expert'?
      profile['expert'].investorDetails :
      profile?.userType === 'service-provider'?
        profile['serviceProvider'].investorDetails:
        profile?.userType === 'investor' ?
          profile['investor'] :  profile?.userType === 'startup' ? profile['startup'] : null
  return (

    <>
      <ScrollToTop/>
      {/* {console.log(profile?.businessDeveloper?.investorDetails?.portfolio[0].name)} */}
      {/* {profile.userType  === 'investor' && */}
      <Container maxWidth={"lg"} sx={{
        textAlign: "left" , position: 'absolute',
        left: '0px',
        top: '65px',
        backgroundColor: '#F6F8FA',
        boxShadow: '0px 0px 13px -2px rgba(0, 0, 0, 0.15)' ,
        display:'flex',
        flexDirection:'column',

      }}>
        <Stack
          direction={"column"}
          sx={{ width: "100%", textAlign: "left", marginTop: "10px" }}
          spacing={5}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            // spacing={2}
          >
            <IconButton onClick={() => navigator(-.5)}>
              <ArrowBackIcon sx={{ fontSize: 40 }}/>
            </IconButton>

            <Typography fontSize={20} fontWeight={600}> Investment Portfolio</Typography>
          </Stack>
          {profile?.userType === "investor" &&
          profile?.investor &&
          profile?.investor.portfolio &&
          profile?.investor.portfolio.length > 0 ?
            <Box sx={{marginLeft:'400px'}}>
              <Avatar
                sx={{
                  width: 72,
                  height: 72,
                  border: "3px solid #E0E3FF",
                  position: "relative",
                  top: -50,
                  marginLeft:"40%"
                }}
                src={
                  profile?.investor?.portfolio[i]?.photoURL
                    ? profile?.investor?.portfolio[i]?.photoURL

                    : "/assets/startup-placeholder-image.png"
                }
              ></Avatar>
            </Box>
            :null }

          { profile?.userType === "business-developer" &&
          profile?.businessDeveloper &&
          profile?.businessDeveloper.investorDetails.portfolio &&
          profile?.businessDeveloper.investorDetails.portfolio.length > 0 ?
            <Box sx={{marginLeft:'400px'}}>
              <Avatar
                sx={{
                  width: 70,
                  height: 70,
                  border: "1px solid #E0E3FF",
                  position:
                    "relative",
                  top: -40
                  , marginBottom:"-9px",
                  marginLeft:"400px",
                  margin:'0 auto'
                }}
                src={
                  profile.businessDeveloper.investorDetails.portfolio[i].photoURL
                    ? profile.businessDeveloper.investorDetails.portfolio[i].photoURL
                    : "/assets/startup-placeholder-image.png"
                }/>
            </Box>
            : null}

          { profile?.userType === "service-provider" &&
          profile?.serviceProvider &&
          profile?.serviceProvider.investorDetails.portfolio &&
          profile?.serviceProvider.investorDetails.portfolio.length > 0 ?
            <Box sx={{marginLeft:'400px'}}>
              <Avatar
                sx={{
                  width: 70,
                  height: 70,
                  border: "1px solid #E0E3FF",
                  position:
                    "relative",
                  top: -40
                  , marginBottom:"-9px",
                  marginLeft:"40%"
                  // marginLeft:"8px"
                }}
                src={
                  profile.serviceProvider.investorDetails.portfolio[i].photoURL
                    ? profile.serviceProvider.investorDetails.portfolio[i].photoURL
                    : "/assets/startup-placeholder-image.png"
                }/>
            </Box>
            : null}


          { profile?.userType === "expert" &&
          profile?.expert &&
          profile?.expert.investorDetails.portfolio &&
          profile?.expert.investorDetails.portfolio.length > 0 ?
            <Box sx={{marginLeft:'400px'}}>
              <Avatar
                sx={{
                  width: 70,
                  height: 70,
                  border: "1px solid #E0E3FF",
                  position:
                    "relative",
                  top: -40
                  , marginBottom:"-9px",
                  marginLeft:"40%"
                  // marginLeft:"8px"
                }}
                src={
                  profile.expert.investorDetails.portfolio[i].photoURL
                    ? profile.expert.investorDetails.portfolio[i].photoURL
                    : "/assets/startup-placeholder-image.png"
                }/>
            </Box>
            : null}



          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ ...commonStyles, borderColor: "black" }}>
              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 120,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack direction={"column"} spacing={2} sx={{ marginTop: "-8px" }}>
                  <Typography variant={"p"} color={"black"}>
                    Startup Name:
                  </Typography>
                  <Typography  color={"black"} sx={boxstyle}>
                    {profile?.userType === 'investor' ?
                      profile?.investor?.portfolio[i]?.name :
                      profile?.userType === 'business-developer' ?
                        profile?.businessDeveloper?.investorDetails?.portfolio[i].name:
                        profile?.userType === 'expert'?
                          profile?.expert?.investorDetails?.portfolio[i].name:
                          profile?.userType === 'service-provider'?
                            profile?.serviceProvider?.investorDetails?.portfolio[i].name:
                            null

                    }
                  </Typography>
                </Stack>
                <br></br>
                <Stack direction={"column"} spacing={2} sx={{ marginTop: "-10px" }}>
                  <Typography variant={"p"} color={"black"}>
                    Startup Website URL:
                  </Typography>
                  <Typography color={"#233DFF"}  sx={boxstyle}>
                <a href={
                  profile?.userType === 'investor' ? 
                   profile?.investor?.portfolio[i]?.website:
                   profile?.userType === 'business-developer' ?
                   profile?.businessDeveloper?.investorDetails?.portfolio[i].website:
                   profile?.userType === 'expert'? 
                   profile?.expert?.investorDetails?.portfolio[i].website:
                   profile?.userType === 'service-provider'? 
                   profile?.serviceProvider?.investorDetails?.portfolio[i].website:
                   null

                 }
                target={'_blank'}>
                {
                  profile?.userType === 'investor' ? 
                   profile?.investor?.portfolio[i]?.website:
                   profile?.userType === 'business-developer' ?
                   profile?.businessDeveloper?.investorDetails?.portfolio[i].website:
                   profile?.userType === 'expert'? 
                   profile?.expert?.investorDetails?.portfolio[i].website:
                   profile?.userType === 'service-provider'? 
                   profile?.serviceProvider?.investorDetails?.portfolio[i].website:
                   null


                }
                </a>
                </Typography>
                </Stack>
              </Stack>

              <Stack
                direction={"column"}
                justifyContent="left"
                alignItems="flex-start"
                sx={{
                  minHeight: 140,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack
                  direction={"column"}
                  // spacing={14}
                  sx={{ marginTop: "-8px" }}
                >
                  <Typography variant={"p"} color={"black"}>
                    <br></br>
                    Description
                  </Typography>
                  <Typography sx={{fontSize:'14px'}} color={"black"}>
                    <br></br>
                    {profile?.userType === 'investor' ?
                      profile?.investor?.portfolio[i]?.description:
                      profile?.userType === 'business-developer' ?
                        profile?.businessDeveloper?.investorDetails?.portfolio[i].description:
                        profile?.userType === 'expert'?
                          profile?.expert?.investorDetails?.portfolio[i].description:
                          profile?.userType === 'service-provider'?
                            profile?.serviceProvider?.investorDetails?.portfolio[i].description:
                            null

                    }
                    <br></br>
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 120,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack direction={"column"} spacing={2} sx={{ marginTop: "-8px" }}>
                  <Typography variant={"p"} color={"black"}>
                    Investment Stage:
                  </Typography>
                  <Typography  sx={boxstyle} color={"black"}>
                    {profile?.userType === 'investor' ?
                      profile?.investor?.portfolio[i]?.investmentStage:
                      profile?.userType === 'business-developer' ?
                        profile?.businessDeveloper?.investorDetails?.portfolio[i].investmentStage:
                        profile?.userType === 'expert'?
                          profile?.expert?.investorDetails?.portfolio[i].investmentStage:
                          profile?.userType === 'service-provider'?
                            profile?.serviceProvider?.investorDetails?.portfolio[i].investmentStage:
                            null

                    }
                  </Typography>
                </Stack>
                <br></br>
                <Stack direction={"column"} spacing={2}>
                  <Typography variant={"p"} color={"black"}>
                    Investment Date:
                  </Typography>
                  <Typography  sx={boxstyle} color={"black"}>
                    {profile?.userType === 'investor' ?
                      profile?.investor?.portfolio[i]?.investmentDate:
                      profile?.userType === 'business-developer' ?
                        profile?.businessDeveloper?.investorDetails?.portfolio[i].investmentDate:
                        profile?.userType === 'expert'?
                          profile?.expert?.investorDetails?.portfolio[i].investmentDate:
                          profile?.userType === 'service-provider'?
                            profile?.serviceProvider?.investorDetails?.portfolio[i].investmentDate:
                            null

                    }
                  </Typography>
                </Stack>
                <br></br>
                <Stack direction={"column"} spacing={2} >
                  <Typography variant={"p"} color={"black"}>
                    Pre-money Valuation:
                  </Typography>
                  <Typography  sx={boxstyle} color={"black"}>
                    {profile?.userType === 'investor' ?
                      commify(valforinvestor):
                      profile?.userType === 'business-developer' ?
                        commify(valforbusiness):
                        profile?.userType === 'expert'?
                          commify(valforexpert):
                          profile?.userType === 'service-provider'?
                            commify(valforservice):
                            null

                    }
                  </Typography>
                </Stack>
                <br></br>
                <Stack direction={"column"} spacing={2}>
                  <Typography variant={"p"} color={"black"}>
                    Investment Amount:
                  </Typography>
                  <Typography v sx={boxstyle} color={"black"}>
                    {profile?.userType === 'investor' ?
                      commify(amforinvestor):
                      profile?.userType === 'business-developer' ?
                        commify(amforbusiness):
                        profile?.userType === 'expert'?
                          commify(amforexpert):
                          profile?.userType === 'service-provider'?
                            commify(amforservice):
                            null

                    }
                  </Typography>
                </Stack>
              </Stack>



              <Stack
                direction={"column"}
                justifyContent="left"
                alignItems="flex-start"
                sx={{
                  minHeight: 140,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack
                  direction={"column"}
                  // spacing={14}
                  sx={{ marginTop: "-8px" }}
                >
                  <Typography variant={"p"} color={"black"}>
                    <br></br>
                    Social Proof
                  </Typography>
                  <Typography sx={{fontSize:'14px'}} color={"black"}>
                    <br></br>
                    {profile?.userType === 'investor' ?
                      profile?.investor?.portfolio[i]?.socialProof:
                      profile?.userType === 'business-developer' ?
                        profile?.businessDeveloper?.investorDetails?.portfolio[i].socialProof:
                        profile?.userType === 'expert'?
                          profile?.expert?.investorDetails?.portfolio[i].socialProof:
                          profile?.userType === 'service-provider'?
                            profile?.serviceProvider?.investorDetails?.portfolio[i].socialProof:
                            null

                    }
                    <br></br>
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 140,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack
                  direction={"column"}
                  // spacing={14}
                  sx={{ marginTop: "-30px" }}
                >
                  <Typography variant={"p"} color={"black"}>
                    <br></br>
                    Attachment
                  </Typography>
                  {user.portfolio[0].attachments.map((attachment) =>
                  {debugger
                    return<>
                      <Stack direction="row">
                        <Box
                          sx={{
                            p: "2px 10px",
                            display: "flex",
                            alignItems: "center",
                            width: 337,
                            height: 43,
                            borderRadius: "12px",
                            backgroundColor: "#E0E3FF",
                            marginTop: "33px",
                          }}
                        >
                          <IconButton type="button" aria-label="search">
                            <img src="/assets/image5.png" alt="" />
                          </IconButton>
                          {attachment.split("/").splice(-1)}
                          <Stack
                            sx={{
                              marginLeft: "60px",
                            }}
                          >
                            <img src="/assets/img2.png" alt="" onClick={()=>{
                              DownloadFileHelper(attachment)
                            }
                            }/>
                          </Stack>
                        </Box>
                      </Stack>
                    </>
                  })}
                </Stack>
              </Stack>
              {/* {console.log(profile?.investor?.portfolio[0]?.link)} */}

              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 140,
                  width: "100%",
                  // borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack
                  direction={"column"}
                  // spacing={14}
                  sx={{ marginTop: "-30px" }}
                >
                  <Typography variant={"p"} color={"black"}>
                    <br></br>
                    Additional Link
                  </Typography>
                  <Stack direction="row">
                    {(profile?.businessDeveloper?.investorDetails?.portfolio[i]?.link||
                        profile?.expert?.investorDetails?.portfolio[i]?.link||
                        profile?.serviceProvider?.investorDetails?.portfolio[i]?.link||
                        profile?.investor?.portfolio[i]?.link)&&
                      <Box>
                      <Typography  variant={'caption'}  sx={boxstyle}>
                        <a href={
                          profile?.userType === 'business-developer' ?
                            profile?.businessDeveloper?.investorDetails?.portfolio[i]?.link:
                            profile?.userType === 'expert'?
                              profile?.expert?.investorDetails?.portfolio[i]?.link:
                              profile?.userType === 'service-provider'?
                                profile?.serviceProvider?.investorDetails?.portfolio[i]?.link:
                                profile?.userType === 'investor' ?
                                  profile?.investor?.portfolio[i]?.link:
                                  null
                        }
                           target={'_blank'}>
                            {
                             shortenURL(profile?.businessDeveloper?.investorDetails?.portfolio[i]?.link, 30)
                          || shortenURL(profile?.expert?.investorDetails?.portfolio[i]?.link, 30) 
                          || shortenURL(profile?.investor?.portfolio[i]?.link, 30)
                          || shortenURL(profile?.serviceProvider?.investorDetails?.portfolio[i]?.link, 30)
                        }
                          {/* {
                            profile?.userType === 'business-developer' &&
                            profile?.businessDeveloper?.investorDetails?.portfolio[i]?.link.length < 30
                              ? profile?.businessDeveloper?.investorDetails?.portfolio[i]?.link
                              : `${profile?.businessDeveloper?.investorDetails?.portfolio[i]?.link.slice(0 , 30)}...`


                              ||

                              profile?.userType === 'expert' &&
                              profile?.expert?.investorDetails?.portfolio[i]?.link.length < 30
                                ? profile?.expert?.investorDetails?.portfolio[i]?.link
                                : `${profile?.expert?.investorDetails?.portfolio[i]?.link.slice(0 , 30)}...`

                                ||

                                profile?.userType === 'service-provider' &&
                                profile?.serviceProvider?.investorDetails?.portfolio[i]?.link.length < 30
                                  ? profile?.serviceProvider?.investorDetails?.portfolio[i]?.link
                                  : `${profile?.serviceProvider?.investorDetails?.portfolio[i]?.link.slice(0 , 30)}...`

                                  ||

                                  profile?.userType === 'investor' &&
                                  profile?.investor?.portfolio[i]?.link.length < 30
                                    ? profile?.investor?.portfolio[i]?.link
                                    : `${profile?.investor?.portfolio[i]?.link.slice(0 , 30)}...`


                          } */}
                        </a>
                      </Typography>
                    </Box>}
                  </Stack>
                </Stack>
              </Stack>

            </Box>
          </Box>
        </Stack>
      </Container>
      {/* } */}
    </>
  );
}
