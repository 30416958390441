import React from "react";
import { DB } from "../../config/firebase/firebase";
import {
	styled,
	Container,
	Stack,
	Typography,
	Avatar,
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogContent, DialogActions, DialogContentText, Badge, Grid
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { a11yProps, TabPanel } from "../../components/common/tabs-custom";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import {useLocation, useNavigate} from "react-router-dom";
import {AuthUserContext} from "../../providers/auth-provider";

const StyledTabs = styled((props) => (
	<Tabs
		sx={{ position: "relative", left: 0, top: 15 }}
		{...props}
		TabIndicatorProps={{
			children: <span className="MuiTabs-indicatorSpan" />,
		}}
	/>
))({
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
	},
	"& .MuiTabs-indicatorSpan": {
		maxWidth: 40,
		width: "100%",
		backgroundColor: "none",
	},
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: "none",
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(16),
		marginRight: theme.spacing(1),
		borderRadius: "30px",
		color: "#000",
		"&.Mui-selected": {
			color: "#fff",
			backgroundColor: "#233DFF",
		},
		"&.Mui-focusVisible": {
			backgroundColor: "rgba(100, 95, 228, 0.32)",
		},
	})
);
export default function CommonOrganisationProfile({}) {
	const styleFont = {
		backgroundColor: "#F6F8FA",
		padding: "8px 15px",
		borderRadius: "5px",
		fontSize: 14,
	};
	const { setProfile, setUser, user } =
		React.useContext(AuthUserContext);
	const userContext =
		React.useContext(AuthUserContext);
	const { organisation, profile } = useLocation().state;




	const [value, setValue] = React.useState(0);


	const navigator = useNavigate()
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
			{/* {console.log(startup)} */}
			{organisation && (
				<Stack direction={"column"} spacing={5}>
					<Stack
						direction={"column"}
						spacing={0.5}
						sx={{
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px",
							backgroundColor: "#fff",
							minHeight: 100,
							p: 2,
							pb: 0,
						}}>
						<Box
							sx={{
								height: 120,
								width: "100%",
								backgroundColor: "#F6F8FA",
								borderRadius: "15px",
							}}
						>
							<Box
								sx={{
									border: "1px solid #204488",
									p: 0.75,
									borderRadius: "50%",
									width: "fit-content",
									position: "relative",
									left: 35,
									top: 60,
								}}>
								<Avatar
									alt={organisation?.nameOfOrganization}
									src={organisation?.logoURL}
									sx={{ width: 92, height: 92 }}  imgProps={{style: {objectFit: 'contain', backgroundColor: '#fff'}}}>
									<Typography variant={"h3"}>
										{organisation?.nameOfOrganization.charAt(0)}
									</Typography>
								</Avatar>
							</Box>
						</Box>
						

						<Stack
							direction={"row"}
							spacing={3}
							sx={{ position: "relative", top: 50 }}>
							<div />
							<Stack direction={"column"} spacing={2} sx={{width: '100%'}}>
								<Stack direction={"row"} spacing={3}>
									<Typography
										variant={"h5"}
										sx={{ marginTop: "2.5px" }}>
										{organisation?.nameOfOrganization}
									</Typography>
									<Box
										sx={{
											backgroundColor: "#E0E3FF",
											px: 3,
											py: 1,
											borderRadius: "19.5px",
										}}>
										<Typography
											style={{
												fontWeight: 500,
												textTransform: "capitalize",
											}}>
											{"Investor"}
										</Typography>
									</Box>


								</Stack>
								<Stack
									direction={"row"}
									spacing={1}
									sx={{ position: "relative", top: -10 }}>
									<Typography variant={'body2'} color={"grey"} style={{flex: 1}}>
										{profile?.countryState}
									</Typography>
								</Stack>

								{
								organisation && organisation?.organizationDescription && (
									<Typography
										variant={"body1"}
										color={"secondary"}
										sx={{
											width: "calc(100% - 10%)",
											position: "relative",
											top: -10,
										}}>
										{organisation?.organizationDescription}
									</Typography>
								)}

							</Stack>
						</Stack>
						<Stack direction={"row"} sx={{width:'100%'}} justifyContent="center"
                     alignItems="flex-end">

							<StyledTabs
							sx={{
								paddingLeft:'35px'
							}}
								value={value}
								onChange={handleChange}
								aria-label="styled tabs example"
							>
								<StyledTab label="ABOUT" {...a11yProps(0)} />
  
							</StyledTabs>
						
						<div style={{flex: 10}}/>
							<Stack
								direction={"column"}
								spacing={1}
								justifyContent="center"
								sx={{ position: "relative", minWidth: 120, cursor: 'pointer' 
								,paddingTop:'70px'}}
								alignItems="center"

								onClick={() => navigator(`/${userContext?.profile?.userType}/investor-details/${profile?.uid}`)
								}
							>
								<Box
									sx={{
										border: "1px solid #204488",
										p: 0.75,
										borderRadius: "50%",
										width: "fit-content",
									}}
								>
									<Avatar
										alt={profile?.displayName}
										src={
											profile?.photoURL !== ""
												? profile?.photoURL
												: profile.photoURL
										}
										sx={{ width: 51, height: 51 }}
									>
										<Typography variant={"h4"} >
											{profile?.displayName.charAt(0)}
										</Typography>
									</Avatar>
								</Box>
								<Typography variant={"h6"} sx={{ textAlign: "center" }}>
									{profile?.displayName}
									<br />
									<font
										size={2}
										style={{
											backgroundColor: "#F6F8FA",
											padding: "5px 10px",
											borderRadius: "6px",
											fontWeight: 300,
										}}
									>
										{profile?.investor?.role}
									</font>
								</Typography>
							</Stack>
						</Stack>
						<Box sx={{height: 30}}/>
					</Stack>

					<TabPanel index={0} value={value}>
						<Stack
							direction={"column"}
							spacing={3}
							sx={{
								boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
								borderRadius: "15px",
								backgroundColor: "#fff",
								minHeight: 100,
								px: 5.5,
								py: 6,
							}}
						>
							<Stack direction={'row'} spacing={2}>
									<Typography fontSize={20} fontWeight={600} style={{flex: 1}}>About Organisation</Typography>
							</Stack>
							<Stack direction={"column"} spacing={4}>
								
								<Stack direction={"column"} spacing={2}>
									<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
										Website
										<br />{" "}
										<font style={styleFont}>
										<a
											href=	
											{!organisation?.website || organisation?.website === '' ? 'javascript:;' : organisation?.website }
											>
												{!organisation?.website || organisation?.website === '' ? 'N/A' : organisation?.website }
											</a>
										</font>
									</Typography>
									<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
								</Stack>
							
							
								<Stack direction={"column"} spacing={2}>
									<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
										Investor type
										<br />{" "}
										<font style={styleFont}>
											{!organisation?.investorType || organisation?.investorType === '' ? 'N/A' : organisation?.investorType }{" "}
										</font>
									</Typography>
								</Stack>
							
						</Stack>
						</Stack>
					</TabPanel>


										{/* <FounderAbout founder={founder} /> */}

				</Stack>
			)}

		</Container>
	);
}
