import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
	Box,
	Container,
	Grid,
	MenuItem,
	Stack,
	TextField,
	Typography,
	Select, Button,
} from "@mui/material";
import {
	collection, limit,
	onSnapshot,
	orderBy,
	query,
	where,
} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {BUSINESS_DEVELOPER, EXPERT, SERVICE_PROVIDER, USER_TYPES_TO_NAME} from "../../utils/enums/labels.enum";

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}
const rows = [
	createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
	createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
	createData("Eclair", 262, 16.0, 24, 6.0),
	createData("Cupcake", 305, 3.7, 67, 4.3),
	createData("Gingerbread", 356, 16.0, 49, 3.9),
];
export default function AdminDashboard() {
	const { profile } = React.useContext(AuthUserContext);
	const[inputbtn,setinputbtn]=React.useState();

  const navigator = useNavigate()
  const [users, setUsers] = React.useState([])
  const [filter, setFilter] = React.useState('-')
  const getCount = (type) => {
    const length = users.filter((u) => u.userType === type).length
    return length;
  }
	const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    const q = query(
      collection(DB, "users"),
      where("onboard", "==", true),
			orderBy("createdAt", "desc")
		);
		const unSubs = onSnapshot(q, (querySnap) => {
			// debugger
			if (querySnap.size > 0) {
				const list = [];
				querySnap.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });
				});

				setUsers(list);
			}
		});
		return () => unSubs;
	}, []);

	return (
		<Container
			maxWidth={"full-width"}
			sx={{ height: 1}}>
			<Stack direction="row" justifyContent="flex-end">
    
          </Stack>
          <br></br>
      <Grid container gap={5}>
				<Grid>
					<Stack
						direction={"column"}
						spacing={1}
						sx={{ textAlign: "center" }}>
						<Box
							sx={{
								width: 160,
								height: 132,
								backgroundColor: "#F6F8FA",
								borderRadius: "20px",
								py: 5.5,
							}}>
							<Typography variant={"h4"} fontWeight={600}>
								{users.length > 0 && getCount("startup")}
							</Typography>
						</Box>
						<Typography
							variant={"h6"}
							paragraph={true}
							width={140}
							textAlign={"center"}
							sx={{ pl: 2 }}>
							Startups
						</Typography>
					</Stack>
				</Grid>
				<Grid>
					<Stack
						direction={"column"}
						spacing={1}
						sx={{ textAlign: "center" }}>
						<Box
							sx={{
								width: 160,
								height: 132,
								backgroundColor: "#F6F8FA",
								borderRadius: "20px",
								py: 5.5,
							}}>
							<Typography variant={"h4"} fontWeight={600}>
								{users.length > 0 && getCount("investor")}
							</Typography>
						</Box>
						<Typography
							variant={"h6"}
							paragraph={true}
							width={140}
							textAlign={"center"}
							sx={{ pl: 2 }}>
							Investors
						</Typography>
					</Stack>
				</Grid>
				{/* <Grid>
					<Stack
						direction={"column"}
						spacing={1}
						sx={{ textAlign: "center" }}>
						<Box
							sx={{
								width: 160,
								height: 132,
								backgroundColor: "#F6F8FA",
								borderRadius: "20px",
								py: 5.5,
							}}>
							<Typography variant={"h4"} fontWeight={600}>
								{users.length > 0 && getCount("talent")}
							</Typography>
						</Box>
						<Typography
							variant={"h6"}
							paragraph={true}
							width={140}
							textAlign={"center"}
							sx={{ pl: 2 }}>
							Talents
						</Typography>
					</Stack>
				</Grid> */}
				{/* <Grid>
					<Stack
						direction={"column"}
						spacing={1}
						sx={{ textAlign: "center" }}>
						<Box
							sx={{
								width: 160,
								height: 132,
								backgroundColor: "#F6F8FA",
								borderRadius: "20px",
								py: 5.5,
							}}>
							<Typography variant={"h4"} fontWeight={600}>
								{users.length > 0 && getCount("distributor")}
							</Typography>
						</Box>
						<Typography
							variant={"h6"}
							paragraph={true}
							width={140}
							textAlign={"center"}
							sx={{ pl: 2 }}>
							Distributors
						</Typography>
					</Stack>
				</Grid> */}
				<Grid>
					<Stack
						direction={"column"}
						spacing={1}
						sx={{ textAlign: "center" }}>
						<Box
							sx={{
								width: 160,
								height: 132,
								backgroundColor: "#F6F8FA",
								borderRadius: "20px",
								py: 5.5,
							}}>
							<Typography variant={"h4"} fontWeight={600}>
								{users.length > 0 &&
									getCount("business-developer")}
							</Typography>
						</Box>
						<Typography
							variant={"h6"}
							paragraph={true}
							width={140}
							textAlign={"center"}
							sx={{ pl: 2 }}>
							{BUSINESS_DEVELOPER.NAME}
						</Typography>
					</Stack>
				</Grid>
				<Grid>
					<Stack
						direction={"column"}
						spacing={1}
						sx={{ textAlign: "center" }}>
						<Box
							sx={{
								width: 160,
								height: 132,
								backgroundColor: "#F6F8FA",
								borderRadius: "20px",
								py: 5.5,
							}}>
							<Typography variant={"h4"} fontWeight={600}>
								{users.length > 0 &&
									getCount("service-provider")}
							</Typography>
						</Box>
						<Typography
							variant={"h6"}
							paragraph={true}
							width={140}
							textAlign={"center"}
							sx={{ pl: 2 }}>
							{SERVICE_PROVIDER.NAME}
						</Typography>
					</Stack>
				</Grid>
				<Grid>
					<Stack
						direction={"column"}
						spacing={1}
						sx={{ textAlign: "center" }}>
						<Box
							sx={{
								width: 160,
								height: 132,
								backgroundColor: "#F6F8FA",
								borderRadius: "20px",
								py: 5.5,
							}}>
							<Typography variant={"h4"} fontWeight={600}>
								{users.length > 0 && getCount("expert")}
							</Typography>
						</Box>
						<Typography
							variant={"h6"}
							paragraph={true}
							width={140}
							textAlign={"center"}
							sx={{ pl: 2 }}>
							{EXPERT.NAME}
						</Typography>
					</Stack>
				</Grid>
				<Grid
					sx={{
						width: "100%",
						height: "1px",
						backgroundColor: "#ccc",
					}}></Grid>
				<Grid sx={{ width: "100%" }}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={4}>
						<Typography variant={"h6"} fontWeight={600}>
							Filter by &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<Select
							value={filter}
							style={{
								color: "#000",
								borderRadius: "30px",
								backgroundColor: "#eee",
								paddingLeft: "10px",
								paddingRight: "10px",
								width: "fit-content",
								height: "48px",
							}}
							IconComponent={() => <ArrowDropDownIcon />}
							onChange={(e) => setFilter(e.target.value)}>
							<MenuItem value={"-"}>
								{filter === "-" && "User type"}
							</MenuItem>
							<MenuItem value={"all"}>
								{filter === "all" && "User type: "} All
							</MenuItem>
							<MenuItem value={"investor"}>
								{filter === "investor" && "User type: "}
								Investors
							</MenuItem>
							<MenuItem value={"startup"}>
								{filter === "startup" && "User type: "}Startups
							</MenuItem>
							<MenuItem value={"business-developer"}>
								{filter === "business-developer" &&
									"User type: "}
								{BUSINESS_DEVELOPER.NAME}
							</MenuItem>
							<MenuItem value={"service-provider"}>
								{filter === "service-provider" && "User type: "}
								{SERVICE_PROVIDER.NAME}
							</MenuItem>
							<MenuItem value={"expert"}>
								{filter === "expert" && "User type: "}
								{EXPERT.NAME}
							</MenuItem>
						</Select>
						</Typography>
						<Box flex={1}/>
						<Button variant={"contained"} onClick={() => navigator('/admin/on-boarding/type')}>+ Add New User</Button>
						

						<Box
						component="form"  onSubmit={(event) => event.preventDefault()} >

    <Container maxWidth={'full-width'} sx={{
		p: "2px 10px",
		display: "flex",
		alignItems: "center",
		width: 337,
		height: 43,
		borderRadius: "12px",
		backgroundColor: "#E4EAF5",
	}}>

						<IconButton
							type="button"
							sx={{ p: "10px" }}
							aria-label="search"
							onClick={(e) => setSearch(inputbtn)}>
							<SearchIcon sx={{ color: "#051E40BF" }} />
						</IconButton>
						<InputBase
								onChange={(e) => setinputbtn(e.target.value)}
							value={inputbtn}
							onKeyDown={(e)=>{
								if(e.code==="Enter"){
									setSearch(inputbtn)
								}
							}}
							sx={{ ml: 1, flex: 1, color: "#051E40BF" }}
							placeholder="Search"
							inputProps={{ "aria-label": "search post" }}
						/>
	</Container>
					</Box>
					</Stack>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography
											variant={"body1"}
											fontWeight={600}>
											Full name
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography
											variant={"body1"}
											fontWeight={600}>
											User type
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography
											variant={"body1"}
											fontWeight={600}>
											Email
										</Typography>
									</TableCell>
									
									<TableCell align="center">
										<Typography
											variant={"body1"}
											fontWeight={600}>
											Linkedin
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography
											variant={"body1"}
											fontWeight={600}>
											Updated by
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography
											variant={"body1"}
											fontWeight={600}>
											Created by
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography
											variant={"body1"}
											fontWeight={600}>
											Joining date
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users.length > 0 &&
									users
										.sort(
											(a, b) => b.createdAt - a.createdAt
										)
										.filter((uf) =>
										
											filter && (filter !== "-" && filter !== 'all')
												? filter === uf.userType
												: uf
										
										
											
										)
										.filter((user)=>{
											if (search !== "") {
										const reg = new RegExp(search);
										console.log(user)
										return reg.test(user.displayName) ||reg.test(user.userType)  
										||reg.test(user.email)  ||reg.test(user.createdAt)
										
										 && user;
									} else {
										return user;
									
										}})
										.map((row) => row?.userType !== 'admin' && (
											<TableRow
												hover
												key={row?.id}
												sx={{
													"&:last-child td, &:last-child th":
														{ border: 0 },
												}} onClick={() => navigator(
												"/admin/user-details", {state: {profile: row}}
											)}>
												<TableCell
													component="th"
													scope="row" sx={{color:'#233DFF', fontWeight: 500}}>
													{row?.displayName}
												</TableCell>
												<TableCell align="center" style={{textTransform: "capitalize"}}>
												{USER_TYPES_TO_NAME[row?.userType]}
												{/*{row?.userType.replace('-', ' ')}*/}
											</TableCell>
												<TableCell align="center">
													{row?.email}
												</TableCell>
												
												<TableCell align="center" sx={{color:'#233DFF', fontWeight: 500}}>
													{
														
														row[
															row.userType === 'business-developer' ? 'businessDeveloper'
																: row.userType === 'service-provider' ? 'serviceProvider'
																	: row?.userType]?.linkedinURL && <a href={row[
															row.userType === 'business-developer' ? 'businessDeveloper'
																: row.userType === 'service-provider' ? 'serviceProvider'
																	: row?.userType]?.linkedinURL} target={'_blank'}>
															
															{ row[
																row.userType === 'business-developer' ? 'businessDeveloper'
																	: row.userType === 'service-provider' ? 'serviceProvider'
																		: row?.userType]?.displayName
															}
														
														</a>
														
													}
													
												</TableCell>
												<TableCell align="center">
													{row?.updatedBy  && row?.updatedBy === 'admin' ? 'Admin' : 'User'} <br/><font size={2}>{row?.updatedAt && moment(
													+row?.updatedAt
												).format("DD/MM/YYYY")}</font>
												</TableCell>
												<TableCell
													align="center"
													sx={{ cursor: "pointer" }}
													/*onClick={() =>
														navigator(
															"/admin/user-details", {state: {profile: row}}
														)
													}*/>
													{row?.createdBy ? 'Admin' : 'User'} <br/><font size={2}>{moment(
													+row?.createdAt
												).format("DD/MM/YYYY")}</font>
												</TableCell>
												<TableCell align="center">
													{moment(
														+row?.createdAt
													).format("DD/MMM/YYYY")}
												</TableCell>
											</TableRow>
										))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Container>
	);
}
