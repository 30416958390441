import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton, Portal, useMediaQuery} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import EditInvestorDetailsForm from "../../../../../screens/investor/edit/edit-investor-details-form";
import {getAuth, getIdToken, updateProfile} from "firebase/auth";
import {ArrowBack} from "@mui/icons-material";
import { EXPERT } from '../../../../../utils/enums/labels.enum';
import UpdateUser from "../../../../../api/update-user";
import PostNotification from "../../../../../api/post-notification";

export default function EditExpertDetails({profile,open, handleClose ,callback }) {
  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  const regexAdmin = /admin\/user-details/
  const copyProfile = regexAdmin.test(window.location.pathname) ? profile : {...context.profile}
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? profile.uid : user.uid);
  const auth = getAuth();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [isLoading, setLoading] = React.useState(false);

  return (
    <Portal>
      <Dialog
        fullScreen={!matches ? true: false}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
          {!matches && <IconButton onClick={() => handleClose()}>
            <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
          </IconButton>}
          {`Edit ${EXPERT.SINGULAR_LABEL} Details`}
        </DialogTitle>
        <DialogContent sx={{px: !matches ? 0 : ''}}>
          <br/>
          <EditInvestorDetailsForm
            data={{
              displayName: profile?.expert?.displayName,
              description: profile?.expert?.description,
              countryState: profile?.countryState
          }}
            cbBack={handleClose}
            cbNext={async (data) => {
              const {displayName, description, countryState} = data
              setLoading(true)
              await setDoc(
                userRef,
                {
                  expert: {...profile?.expert , displayName, description},
                  displayName,
                  description,
                  countryState,
                  updatedById:auth?.currentUser?.uid,
                  updatedBy: regexAdmin.test(window.location.pathname) ? 'admin' : 'user',
                  updatedAt: new Date().getTime(),
                  searchName: displayName.toLowerCase()
                },
                { merge: true }
              )
              if (!regexAdmin.test(window.location.pathname)) {
                await updateProfile(auth.currentUser, {
                  displayName
                });
                await setProfile({
                  ...copyProfile,
                  expert: {...profile?.expert , displayName, description},
                  displayName,
                  description,
                  countryState})
                handleClose()
  
              } else {
                const idToken = await getIdToken(auth.currentUser);
                UpdateUser({ uid: profile?.uid,
                  formData: {displayName},
                  reqType: 'LOL'}, idToken).then()
  
                callback({
                  ...copyProfile,
                  expert: {...profile?.expert , displayName, description},
                  displayName,
                  description,
                  countryState})
                PostNotification({
                  type: 'admin-update-profile',
                  imageURL: '/assets/system-noti-icon.png',
                  section: 'Industry Expert',
                  message: `information has been updated by the Admin.`,
                  uid: profile.uid,
                  createdBy: user?.uid
    
                }).then()
              }
          
              setLoading(false)
            }}
            isLoading={isLoading}
          />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
