import * as React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  setDoc,
  serverTimestamp,
  getDocs,
  startAfter,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import { AuthUserContext } from "../../../providers/auth-provider";
import RoomListItem from "./room-list-item";
import TextMessageComponent from "./message/TextMessageComponent";
import FileMessageComponent from "./message/FileMessageComponent";
import ImageMessageComponent from "./message/ImageMessageComponent";
import SystemGeneratedMessageComponent from "./message/SystemGeneratedMessageComponent";
import ContractDocumentComponent from "./message/ContractDocumentComponent";
// DoneAllOutlinedIcon

export default function MessageList({ roomId, callback, callbackSignContract }) {
  const { profile, roomData, user } = React.useContext(AuthUserContext);
  const [messages, setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [lastDoc, setLastDoc] = React.useState();

  /*For Snapshot*/
  React.useEffect(() => {
    if (roomId) {
      // debugger
      setLoading(true);
      const q = query(
        collection(DB, "messages"),
        orderBy("createdAt", "desc"),
        where("roomId", "==", roomId),
        limit(30)
      );

      const unSubs = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.size > 0) {
          const list = [];
          // const findId = list.find((msg) => msg.id === querySnapshot.docs[0]. )

          /*	querySnapshot.docChanges().forEach((change) => {
						debugger
						if (!change.doc.metadata.hasPendingWrites && change.type === "added") {
							console.log(`Add message`, change.doc.data());
							list.push({id: change.doc.id, ...change.doc.data()})
						}
						if (!change.doc.metadata.hasPendingWrites && change.type === "modified") {
							console.log(`Update message`, change.doc.data());
							list.forEach((d, i) => {
								if (d.id === change.doc.id) {
									list.splice(i, 1, {id: change.doc.id, ...change.doc.data()})
								}
							})
						}
					});*/
          querySnapshot.forEach((document) => {
            list.push({ id: document.id, ...document.data() });
          });
          querySnapshot.size < 30
            ? setLastDoc(undefined)
            : setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

          setMessages(list);
          callback(list);
        } else {
          setLastDoc(undefined);
        }
      });
      return () => unSubs;
    }
  }, [roomId]);

  const loadMore = async () => {
    setLoading(true);
    const q = query(
      collection(DB, "messages"),
      orderBy("createdAt", "desc"),
      where("roomId", "==", roomId),
      startAfter(lastDoc),
      limit(30)
    );
    const querySnap = await getDocs(q);
    if (querySnap.size > 0) {
      const list = [...messages];
      querySnap.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      // console.log(list);
      setMessages(list);
      querySnap.size < 30
        ? setLastDoc(undefined)
        : setLastDoc(querySnap.docs[querySnap.docs.length - 1]);
    } else {
      setLastDoc(undefined);
    }
    setLoading(false);
  };

  return (
    <Stack
      direction={"column-reverse"}
      spacing={0.5}
      sx={{
        width: "100%",
        height:user.uid === roomData?.uid && roomData?.hired ? "calc(100vh - 446px)" :  "calc(100vh - 330px)",
        p: 2,
        overflowY: "scroll",
        backgroundColor: "#fff",
      }}
      id={"msg-container"}
    >
      {messages.length > 0 &&
        messages.map((msg, i) => {
          if (msg?.type === "system-generated") {
            return <SystemGeneratedMessageComponent msg={msg} key={i} />;
          } else if (msg?.type === "text") {
            if (msg?.sender === profile.uid) {
              return <TextMessageComponent key={i} msg={msg} type={"r"} />;
            } else return <TextMessageComponent key={i} msg={msg} type={"l"} />;
          } else if (msg?.type === "document") {
            if (msg?.sender === profile.uid) {
              return <FileMessageComponent key={i} msg={msg} type={"r"} />;
            } else return <FileMessageComponent key={i} msg={msg} type={"l"} />;
          } else if (msg?.type === "contract-document" || msg?.type === "contract-document-signed") {
            if (msg?.sender === profile.uid) {
              return <ContractDocumentComponent key={i} msg={msg} type={"r"} />;
            } else return <ContractDocumentComponent key={i} msg={msg} type={"l"} callback={(bool) => callbackSignContract(bool)}/>;
          } else if (msg?.type === "media") {
            if (msg?.sender === profile.uid) {
              return <ImageMessageComponent key={i} msg={msg} type={"r"} />;
            } else
              return <ImageMessageComponent key={i} msg={msg} type={"l"} />;
          }
        })}

      {lastDoc && (
        <Stack
          direction={"row"}
          sx={{ width: "100%" }}
          py={5}
          justifyContent="center"
          alignItems="center"
        >
          <Button size={"small"} onClick={loadMore}>
            Load more
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
