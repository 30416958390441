import React, { useState } from "react";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import ForgetPasswordValidation from "../../../validations/auth/forget-password-validation";
import { doc, setDoc } from "firebase/firestore";
import { auth, DB } from "../../../config/firebase/firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Timer from "../../../screens/mobile/components/counter";
import ResetPasswordEmailApi from "../../../api/reset-password-email-api";


function ForgetPassword() {
  const navigator = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [timer, setTimer] = useState(false);
  const auth = getAuth();
  const formik = useFormik({
    initialValues: ForgetPasswordValidation.initialValues,
    validationSchema: ForgetPasswordValidation.schema,
    onSubmit: async (values) => {
      const { email } = values;
      console.log(email);
      ResetPasswordEmailApi({email})
        .then(() => {
          console.log("check email");
          currentStep === 1
            ? setCurrentStep(currentStep + 1)
            : setCurrentStep(currentStep);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  function handleNext() {
    setCurrentStep(currentStep + 1);
  }

  function resendEmail() {
    console.log("working");
    setTimer(true);
    formik.handleSubmit();
    console.log("working 2");
  }
  return (
    <>
      {currentStep === 1 && (
        <Container maxWidth={"sm"} sx={{ mt: 20 }}>
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={5}
            component="form"
            onSubmit={(e) => formik.handleSubmit(e)}
            sx={{
              width: "100%",
               justifyContent:"center",
               marginRight:"5px",
               marginLeft:"5px"
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "25px",
                width: "100%",
                fontWeight: "600",
              }}
            >
              Forgot Password?
              <Typography sx={{ mt: 2, fontSize: "15px" }}>
                Enter your registered email below to receive
                password reset instruction
              </Typography>
            </Typography>

            <TextField
              name={"email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              variant={"outlined"}
              type={"email"}
              placeholder={"xyz@example.com"}
              label={"Email"}
              fullWidth
            />
            <Grid container justify="center" alignItems="center">
              <Button
                sx={{ width: "100%" }}
                variant={"contained"}
                color={"primary"}
                type="submit"
              >
                Reset Password
              </Button>
            </Grid>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "14px",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => navigator("/m/auth/signin")}
            >
              Back to Sign In
            </Typography>
          </Stack>
        </Container>
      )}

      {currentStep === 2 && (
        <Container maxWidth={"sm"} sx={{ mt: 20 }}>
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            component="form"
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "25px",
                width: "100%",
                fontWeight: "600",
              }}
            >
              Check your Email
              <Typography sx={{ mt: 2, fontSize: "15px" }}>
                We have sent a password recovery
              <br></br>
                instruction to your email
              </Typography>
            </Typography>

            <Grid container justify="center" alignItems="center">
              <Button
                sx={{ width: "70%" }}
                variant={"contained"}
                color={"primary"}
                onClick={() => navigator("/m/auth/reset-password")}
              >
                {/* OK */}
                Confirm
              </Button>
            </Grid>

            <Typography
              sx={{ textAlign: "center", fontSize: "14px", width: "100%" }}
            >
              Didn’t receive the email?{" "}
              <Link onClick={resendEmail}>Click to resend</Link>
            </Typography>
            {timer && (
              <Typography
                sx={{ textAlign: "center", fontSize: "14px", width: "100%" }}
              >
                Resend email in <b> 00 : <Timer  max={60} /></b>
              </Typography>
            )}
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "14px",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => navigator("/m/auth/signin")}
            >
              Back to Sign In
            </Typography>
          </Stack>
        </Container>
      )}

      {/* {currentStep === 3 && (
        <Container maxWidth={"sm"} sx={{ mt: 10 }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={5}
            component="form"
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "32px",
                width: "100%",
                fontWeight: "600",
              }}
            >
              Create new password
              <Typography sx={{ mt: 2, fontSize: "18px" }}>
                Your new password must be different to
                <br></br>
                previously used passwords
              </Typography>
            </Typography>

            <TextField
              name={"password"}
              variant={"outlined"}
              type={"password"}
              placeholder={"xxxxxxxx"}
              label={"New Password"}
              fullWidth
            />

            <TextField
              name={"password"}
              variant={"outlined"}
              type={"password"}
              placeholder={"xxxxxxxx"}
              label={"Confirm Password"}
              fullWidth
            />
            <Grid container justify="center" alignItems="center">
              <Button
                sx={{ width: "100%" }}
                variant={"contained"}
                color={"primary"}
                onClick={handleNext}
              >
                Create
              </Button>
            </Grid>

            <Typography
              sx={{ textAlign: "center", fontSize: "14px", width: "100%" }}
              onClick={() => navigator("/auth/signin")}
            >
              Back to Sign In
            </Typography>
          </Stack>
        </Container>
      )} */}

      {/* {currentStep === 4 && (
        <Container maxWidth={"sm"} sx={{ mt: 10 }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={5}
            component="form"
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "32px",
                width: "100%",
                fontWeight: "600",
              }}
            >
              Password reset
              <Typography sx={{ mt: 2, fontSize: "18px" }}>
                Your password has been successfully reset
                <br></br>
                Click below to log in magically
              </Typography>
            </Typography>

            <Grid container justify="center" alignItems="center">
              <Button
                sx={{ width: "70%" }}
                variant={"contained"}
                color={"primary"}
                onClick={() => navigator("/auth/signin")}
              >
                continue
              </Button>
            </Grid>
          </Stack>
        </Container>
      )} */}
    </>
  );
}

export default ForgetPassword;
