import React from "react";
import {
	Button,
	Container,
	Stack,
	IconButton,
	TextField,
	Avatar,
	CircularProgress,
	Link,
	Badge,
	styled,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import UploadFile from "../../../utils/upload-file";
import StartupOnBoardingValidation from "../../../validations/startup/startup-profile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AuthUserContext } from "../../../providers/auth-provider";

export default function StartupProfileFirstForm({ cbBack, cbNext, data }) {
	const Input = styled("input")({
		display: "none",
	});
	const { user } = React.useContext(AuthUserContext);

	const [profileImage, setProfileImage] = React.useState({
		src: data?.logoURL || "",
		photoURL: data?.logoURL || "",
	});
	const [loading, setLoading] = React.useState(false);
	const formikFirst = useFormik({
		initialValues: data
			? data
			: StartupOnBoardingValidation.StartupOnBoardingProfileInitValue,
		validationSchema:
			StartupOnBoardingValidation.StartupOnBoardingProfileSchema,
		onSubmit: async (values) => {
			await formikFirst.setFieldValue(
				"logoURL",
				profileImage?.photoURL ? profileImage.photoURL : ""
			);
			cbNext({
				...values,
				logoURL: profileImage?.photoURL ? profileImage.photoURL : "",
			});
		},
	});

	return (
		<Stack 
			direction={"column"}
			spacing={10}
			component="form"
			onSubmit={(e) => formikFirst.handleSubmit(e)}>
			<Container maxWidth={"md"}>
				<Stack direction={"column"} spacing={3}>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						spacing={1}
						sx={{ mb: 5 }}>
						<label htmlFor="profile-image">
							<Badge
								overlap="circular"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								badgeContent={
									<IconButton
										color={"primary"}
										component={"span"}>
										<AddCircleIcon />
									</IconButton>
								}>
								<Avatar
									alt={"startup-picture"}
									src={
										profileImage && profileImage?.src
											? profileImage?.src
											: "/assets/startup-placeholder-image.png"
									}
									sx={{
										width: 128,
										height: 128,
										cursor: "pointer",
										backgroundColor: "transparent",
										border: "1px solid #E0E3FF",
									}}
									imgProps={{
										sx: { objectFit: "scale-down" },
									}}
									component={"span"}
								/>
							</Badge>
							<Input
								accept="image/*"
								id="profile-image"
								multiple={false}
								type="file"
								onChange={(e) => {
									const fileReader = new FileReader();
									fileReader.readAsDataURL(e.target.files[0]);
									fileReader.addEventListener(
										"load",
										async function () {
											setLoading(true);
											const file = e.target.files[0];
											const path = `startups/${user.uid}/logo/${file.name}`;
											const imageObject = {
												progress: 0,
												src: this.result,
												path,
												file: e.target.files[0],
												size: (
													e.target.files[0]?.size /
													1024 /
													1024
												).toFixed(2),
											};
											setProfileImage(imageObject);
											UploadFile(file, path).then(
												(url) => {
													setProfileImage({
														...imageObject,
														photoURL: url,
													});
													setLoading(false);
												}
											);
										}
									);
								}}
							/>
						</label>
						{loading && (
							<CircularProgress
								sx={{ ml: 2 }}
								size={20}
								color="primary"
							/>
						)}
						{profileImage && profileImage?.src && (
							<Link
								color={"secondary"}
								onClick={() => setProfileImage(undefined)}>
								Remove picture
							</Link>
						)}
						{profileImage && profileImage?.src ? ""
						: <Typography color="GrayText">Upload Logo</Typography>}
					</Stack>
					<Stack direction={"column"} spacing={0} >
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Startup Name *
							  </Typography>
					<TextField
					
					name={"startupName"}
						value={formikFirst.values.startupName}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.startupName &&
							Boolean(formikFirst.errors.startupName)
						}
						helperText={
							formikFirst.touched.startupName &&
							formikFirst.errors.startupName
						}
						//label={"Startup Name"}
					/>
					</Stack>
					<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Tell us about the startup *
							  </Typography>
					<TextField
					
						name={"description"}
						value={formikFirst.values.description}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.description &&
							Boolean(formikFirst.errors.description)
						}
						helperText={
							formikFirst.touched.description &&
							formikFirst.errors.description
						}
						//label={"Tell us about the startup"}
						multiline={true}
						rows={3}
					/>
					</Stack>
					<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Website URL 
							  </Typography>
          <TextField
            name={'website'}
            value={formikFirst.values.website}
            onChange={formikFirst.handleChange}
            error={formikFirst.touched.website && Boolean(formikFirst.errors.website)}
            helperText={formikFirst.touched.website && formikFirst.errors.website}
            fullWidth 
            //label={'Website URL'} 

            />
            </Stack>
			<Stack direction={"column"}>
            <Typography
              fontWeight={400}
              fontSize={"16px"}
              textAlign="start"
              sx={{ marginBottom: "5px" }}
            >
              LinkedIn URL
            </Typography>
            <TextField
              name={"linkedIn"}
              value={formikFirst.values.linkedIn}
              onChange={formikFirst.handleChange}
              error={
                formikFirst.touched.linkedIn &&
                Boolean(formikFirst.errors.linkedIn)
              }
              helperText={
                formikFirst.touched.linkedIn && formikFirst.errors.linkedIn
              }
              fullWidth
              // label={'LinkedIn URL'}
            />
          </Stack>
					<Stack direction={"column"} spacing={0}>
                {/* <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Founding date *
							  </Typography>
					<TextField
						InputProps={{inputProps: { min: "1900-01-00", max: new Date().toISOString().split("T")[0]} }}
						required
						name={"foundingDate"}
						value={formikFirst.values.foundingDate}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.foundingDate &&
							Boolean(formikFirst.errors.foundingDate)
						}
						helperText={
							formikFirst.touched.foundingDate &&
							formikFirst.errors.foundingDate
						}
						//label={"Founding date"}
						type={"date"}
					/> */}
					</Stack>
					{/* <Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Formally registered date
							  </Typography>
					<TextField
						// required
						InputProps={{inputProps: { min: "1900-01-00", max: "2150-12-31"} }}
						name={"registeredDate"}
						value={formikFirst.values.registeredDate}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.registeredDate &&
							Boolean(formikFirst.errors.registeredDate)
						}
						helperText={
							formikFirst.touched.registeredDate &&
							formikFirst.errors.registeredDate
						}
						//label={"Formally registered date"}
						type={"date"}
					/>
					</Stack> */}
				</Stack>
			</Container>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3}
				width={"100%"}>
				<Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
					onClick={cbBack}>
					Back
				</Button>
				<Button
					variant={"contained"}
					color={"primary"}
					sx={{ px: 5 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
					type={"submit"}>
					Next
				</Button>
			</Stack>
		</Stack>
	);
}
