import React from "react";
import { Button, Container, MenuItem, Stack, TextField,Typography } from "@mui/material";
import { useFormik } from "formik";
import InvestorProfileValidation from "../../../validations/investor/investor-profile";
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";

export default function InvestorFirstForm({ cbBack, cbNext, data, uid, cbDraft }) {
  const isAdmin = /admin/;
  
  const formikFirst = useFormik({
    initialValues: data
      ? data
      : InvestorProfileValidation.InvestorFirstFormValues,
    validationSchema: InvestorProfileValidation.InvestorFirstForm,
    onSubmit: async (values) => {
      cbNext({
        ...values,
      });
    },
  });
  React.useEffect(() => {
    if (uid) {
      setTimeout(() => {
        getDoc(doc(DB, 'users', uid))
          .then((document) => {
            if (document?.data()?.formFirstData) {
              formikFirst.setValues(document?.data()?.formFirstData);
            }
          })
      }, 500 )
    }
    
  }, [data])
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}
    >
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={2}>
        <Stack direction={"column"} spacing={0}>
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Investor type *
							  </Typography>
          <TextField
            name={"investorType"}
            value={formikFirst.values.investorType}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.investorType &&
              Boolean(formikFirst.errors.investorType)
            }
            helperText={
              formikFirst.touched.investorType &&
              formikFirst.errors.investorType
            }
            //label={"Investor type *"}
            select
            sx={{ textAlign: "left" }}
          >
            <MenuItem value={"Venture Capital Fund"}>
              Venture Capital Fund
            </MenuItem>
            <MenuItem value={"Angel Investor"}>Angel Investor</MenuItem>
            <MenuItem value={"CVC"}>CVC</MenuItem>
            <MenuItem value={"PE Fund"}>PE Fund</MenuItem>
            <MenuItem value={"Family Office"}>Family Office</MenuItem>
          </TextField>
          </Stack>
      {/*    {formikFirst?.values?.investorType === "Venture Capital Fund" && (
            <>
            <Stack direction={"column"} spacing={0}>
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Name of organization *
                </Typography> 
              <TextField
                // required={
                //   formikFirst?.values?.investorType === "Venture Capital Fund"
                // }
                name={"nameOfOrganization"}
                value={formikFirst.values.nameOfOrganization}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.nameOfOrganization &&
                  Boolean(formikFirst.errors.nameOfOrganization)
                }
                helperText={
                  formikFirst.touched.nameOfOrganization &&
                  formikFirst.errors.nameOfOrganization
                }
                //label={"Name of organization"}
              />
              </Stack>
              <Stack direction={"column"} spacing={0}>
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Website</Typography>
              <TextField
                name={"website"}
                value={formikFirst.values.website}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.website &&
                  Boolean(formikFirst.errors.website)
                }
                helperText={
                  formikFirst.touched.website && formikFirst.errors.website
                }
                //label={"Website"}
              />
              </Stack>
              <Stack direction={"column"} spacing={0}>
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Description of Organisation</Typography>
              <TextField
                // required
                name={"organizationDescription"}
                value={formikFirst.values.organizationDescription}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.organizationDescription &&
                  Boolean(formikFirst.errors.organizationDescription)
                }
                helperText={
                  formikFirst.touched.organizationDescription &&
                  formikFirst.errors.organizationDescription
                }
                //label={"Description of Organisation"}
                multiline={true}
                rows={3}
              />
              </Stack>
            </>
          )}

          {formikFirst?.values?.investorType === "CVC" && (
            <>
              <TextField
                // required={formikFirst?.values?.investorType === "CVC"}
                name={"nameOfOrganization"}
                value={formikFirst.values.nameOfOrganization}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.nameOfOrganization &&
                  Boolean(formikFirst.errors.nameOfOrganization)
                }
                helperText={
                  formikFirst.touched.nameOfOrganization &&
                  formikFirst.errors.nameOfOrganization
                }
                label={"Name of organization"}
              />
              <TextField
                name={"website"}
                value={formikFirst.values.website}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.website &&
                  Boolean(formikFirst.errors.website)
                }
                helperText={
                  formikFirst.touched.website && formikFirst.errors.website
                }
                label={"Website"}
              />
              <TextField
                // required
                name={"organizationDescription"}
                value={formikFirst.values.organizationDescription}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.organizationDescription &&
                  Boolean(formikFirst.errors.organizationDescription)
                }
                helperText={
                  formikFirst.touched.organizationDescription &&
                  formikFirst.errors.organizationDescription
                }
                label={"Description of Organisation"}
                multiline={true}
                rows={3}
              />
            </>
          )}
          {formikFirst?.values?.investorType === "PE Fund" && (
            <>
              <TextField
                // required={formikFirst?.values?.investorType === "PE Fund"}
                name={"nameOfOrganization"}
                value={formikFirst.values.nameOfOrganization}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.nameOfOrganization &&
                  Boolean(formikFirst.errors.nameOfOrganization)
                }
                helperText={
                  formikFirst.touched.nameOfOrganization &&
                  formikFirst.errors.nameOfOrganization
                }
                label={"Name of organization"}
              />
              <TextField
                name={"website"}
                value={formikFirst.values.website}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.website &&
                  Boolean(formikFirst.errors.website)
                }
                helperText={
                  formikFirst.touched.website && formikFirst.errors.website
                }
                label={"Website"}
              />
              <TextField
                // required
                name={"organizationDescription"}
                value={formikFirst.values.organizationDescription}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.organizationDescription &&
                  Boolean(formikFirst.errors.organizationDescription)
                }
                helperText={
                  formikFirst.touched.organizationDescription &&
                  formikFirst.errors.organizationDescription
                }
                label={"Description of Organisation"}
                multiline={true}
                rows={3}
              />
            </>
          )}

          {formikFirst?.values?.investorType === "Family Office" && (
            <>
              <TextField
                // required={formikFirst?.values?.investorType === "Family Office"}
                name={"nameOfOrganization"}
                value={formikFirst.values.nameOfOrganization}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.nameOfOrganization &&
                  Boolean(formikFirst.errors.nameOfOrganization)
                }
                helperText={
                  formikFirst.touched.nameOfOrganization &&
                  formikFirst.errors.nameOfOrganization
                }
                label={"Name of organization"}
              />
              <TextField
                name={"website"}
                value={formikFirst.values.website}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.website &&
                  Boolean(formikFirst.errors.website)
                }
                helperText={
                  formikFirst.touched.website && formikFirst.errors.website
                }
                label={"Website"}
              />
              <TextField
                // required
                name={"organizationDescription"}
                value={formikFirst.values.organizationDescription}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.organizationDescription &&
                  Boolean(formikFirst.errors.organizationDescription)
                }
                helperText={
                  formikFirst.touched.organizationDescription &&
                  formikFirst.errors.organizationDescription
                }
                label={"Description of Organisation"}
                multiline={true}
                rows={3}
              />
            </>
          )}*/}
        </Stack>
      </Container>
      <Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3} 
				width={"100%"}>
        {isAdmin.test(window.location.pathname) && <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 5 }}
          onClick={() => {
            cbDraft(formikFirst.values)
          }}>
          Save draft
        </Button>}
				<Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Back
				</Button>
				<Button
					variant={"contained"}
					color={"primary"}
					sx={{ px: 5 }}
					type={"submit"}>
					Next
				</Button>
    
			</Stack>

    </Stack>
  );
}
