import React from "react";
import {
	Button,
	Container,
	Stack,
	IconButton,
	TextField,
	Avatar,
	CircularProgress,
	Link,
	Badge,
	styled,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import UploadFile from "../../../../utils/upload-file";
import ServiceProviderDetailsValidation from "../../../../validations/service-provider/provider-profile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AuthUserContext } from "../../../../providers/auth-provider";

export default function ServiceProviderSecondForm({ cbBack, cbNext, data, isVC }) {
	const Input = styled("input")({
		display: "none",
	});
	const { user } = React.useContext(AuthUserContext);

	const [profileImage, setProfileImage] = React.useState({
		src: data && data?.photoURL !== "" ? data?.photoURL :user.photoURL ? user.photoURL : null,
		photoURL:data &&  data?.photoURL !== '' ? data?.photoURL :user.photoURL ? user.photoURL : null,
	});
	const [loading, setLoading] = React.useState(false);
	const formikFirst = useFormik({
		initialValues: data
			? data
			: {...ServiceProviderDetailsValidation.ProviderSecondFormValues, displayName: user?.displayName},
		validationSchema:
		ServiceProviderDetailsValidation.ProviderSecondForm,
		onSubmit: async (values) => {
			await formikFirst.setFieldValue(
				"photoURL",
				profileImage?.photoURL ? profileImage.photoURL : ""
			);
			cbNext({
				...values,
				photoURL: profileImage?.photoURL ? profileImage.photoURL : "",
			});
		},
	});

	return (
		<Stack
			direction={"column"}
			spacing={10}
			component="form"
			onSubmit={(e) => formikFirst.handleSubmit(e)}>
			<Container maxWidth={"md"}>
				<Stack direction={"column"} spacing={5}>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						spacing={1}
						sx={{ mb: 5 }}>
						<label htmlFor="profile-image">
							<Badge
								overlap="circular"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								badgeContent={
									<IconButton
										color={"primary"}
										component={"span"}>
										<AddCircleIcon />
									</IconButton>
								}>
								<Avatar
									alt={"startup-picture"}
									src={
										profileImage && profileImage?.src
											? profileImage?.src
											: 
											// "/assets/startup-placeholder-image.png"
											"/assets/investor-placeholder.png"
									}
									sx={{
										width: 128,
										height: 128,
										cursor: "pointer",
										backgroundColor: "transparent",
										border: "1px solid #E0E3FF",
									}}
									imgProps={{
										sx: { objectFit: "cover" },
									}}
									component={"span"}
								/>
							</Badge>
							<Input
								accept="image/*"
								id="profile-image"
								multiple={false}
								type="file"
								onChange={(e) => {
									const fileReader = new FileReader();
									fileReader.readAsDataURL(e.target.files[0]);
									fileReader.addEventListener(
										"load",
										async function () {
											setLoading(true);
											const file = e.target.files[0];
											const path = `startups/${user.uid}/logo/${file.name}`;
											const imageObject = {
												progress: 0,
												src: this.result,
												path,
												file: e.target.files[0],
												size: (
													e.target.files[0]?.size /
													1024 /
													1024
												).toFixed(2),
											};
											setProfileImage(imageObject);
											UploadFile(file, path).then(
												(url) => {
													setProfileImage({
														...imageObject,
														photoURL: url,
													});
													setLoading(false);
												}
											);
										}
									);
								}}
							/>
						</label>
						{loading && (
							<CircularProgress
								sx={{ ml: 2 }}
								size={20}
								color="primary"
							/>
						)}
						{profileImage && profileImage?.src && (
							<Link
								color={"secondary"}
								onClick={() => setProfileImage(undefined)}>
								Remove picture
							</Link>
						)}
					</Stack>

					<Stack direction={'column'} spacing={2} >
					<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Your full name *
							  </Typography>
						<TextField fullWidth
							// required
							name={"displayName"}
							value={formikFirst.values.displayName}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.displayName &&
								Boolean(formikFirst.errors.displayName)
							}
							helperText={
								formikFirst.touched.displayName &&
								formikFirst.errors.displayName
							}
							//label={"Your full name"}
						/>
						</Stack>
						<Stack direction={"column"} spacing={0}>
             
						{
							
							isVC && <>
							<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Role *
							  </Typography>
							  <TextField fullWidth
											   required={isVC}
											   name={"role"}
											   value={formikFirst.values.role}
											   onChange={formikFirst.handleChange}
											   error={
												   formikFirst.touched.role &&
												   Boolean(formikFirst.errors.role)
											   }
											   helperText={
												   formikFirst.touched.role &&
												   formikFirst.errors.role
											   }
											   //label={"Role"}
							/>
							</>
							
						}
</Stack>
					</Stack>
					<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Tell us about yourself
							  </Typography>
					<TextField
						// required
						name={"description"}
						value={formikFirst.values.description}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.description &&
							Boolean(formikFirst.errors.description)
						}
						helperText={
							formikFirst.touched.description &&
							formikFirst.errors.description
						}
						// label={"Tell us about startup"}
						//label={"Tell us about yourself"}

						inputProps={{maxLength:150}}
						multiline={true}
						rows={3}
					/>
					<Typography align="right" sx={{color:"#233DFF"}}>{150-formikFirst.values.description.length}/150</Typography>
					</Stack>
					<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >LinkedIn URL *
							  </Typography>
					<TextField fullWidth
							//    required
							   name={"linkedinURL"}
							   value={formikFirst.values.linkedinURL}
							   onChange={formikFirst.handleChange}
							   error={
								   formikFirst.touched.linkedinURL &&
								   Boolean(formikFirst.errors.linkedinURL)
							   }
							   helperText={
								   formikFirst.touched.linkedinURL &&
								   formikFirst.errors.linkedinURL
							   }
							   //label={"LinkedIn URL"}
					/>
</Stack>
				</Stack>
			</Container>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3}
				width={"100%"}>
				<Button
					variant={"outlined"}
					color={"primary"}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Back
				</Button>
				<Button
					variant={"contained"}
					color={"primary"}
					sx={{ px: 5 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
					type={"submit"}>
					Next
				</Button>
			</Stack>
		</Stack>
	);
}
