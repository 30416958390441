import { getStorage, ref, getDownloadURL } from "firebase/storage";

import {saveAs} from "file-saver";

const storage = getStorage();


const downloadURL = (url) => new Promise(((resolve, reject) => {

// Get the download URL
  getDownloadURL(ref(storage, url))
    .then((url) => {
      resolve(url);
    })
    .catch((error) => {
      reject(error)
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    });
}))
export default async function DownloadFileHelper(path) {

  try {

    const url =  await downloadURL(path);
    console.log(`URL:  ${url, path.split('/')[path.split('/').length -1]}`)
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      let blob = xhr.response;
      // console.log(blob, 'blob')
      saveAs(URL.createObjectURL(blob), path.split('/')[path.split('/').length -1])
    };
    xhr.open('GET', url);
    xhr.send();
  } catch (e) {

    console.log(e)
  }


}


