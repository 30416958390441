import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import moment from "moment";
import firebase, { DB } from "../../config/firebase/firebase";
import {
  query,
  collection,
  onSnapshot,
  orderBy,
  getDocs,
  getDoc,
  doc,
  limit,
  startAfter,
} from "firebase/firestore";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import AddOfferValidations from "../../validations/admin/offers/add-offer";
import { USER_TYPES_TO_NAME } from "../../utils/enums/labels.enum";
import { AuthUserContext } from "../../providers/auth-provider";
import TablePagination from "@mui/material/TablePagination";

export default function OffersDashboard() {
  const [inputbtn, setinputbtn] = React.useState();
  const navigator = useNavigate();
  const [users, setUsers] = React.useState([]);
  const [filter, setFilter] = React.useState("-");
  const [search, setSearch] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [hasDocuments, setHasDocuments] = React.useState(false);
  const [collectionExists, setCollectionExists] = React.useState(false);
  const [offers, setOffers] = React.useState([]);
  const { profile } = React.useContext(AuthUserContext);
  const [lastDocument, setLastDocument] = React.useState();
  const [more, setMore] = React.useState(1);

  const loadMore = () => {
    console.log(more);
    setMore(more + 1);
  };

  React.useEffect(() => {
    const fetchOffers = async () => {
      try {
        setLoading(true);
        const offersRef = collection(DB, "offers");
        let queryRef = query(offersRef, limit(1), orderBy("createdAt", "desc"));

        if (lastDocument) {
          queryRef = query(
            offersRef,
            orderBy("createdAt", "desc"),
            startAfter(lastDocument),
            limit(1)
          );
        }

        const querySnapshot = await getDocs(queryRef);

        setHasDocuments(querySnapshot.size > 0);
        console.log("hasDocument", hasDocuments);
        if (querySnapshot.size > 0) {
          const list = [...offers];
          let lastDoc = null;

          for (const docd of querySnapshot.docs) {
            const offerData = docd.data();
            const offerId = docd.id;
            const usersRef = collection(DB, "offers", offerId, "users");
            const usersQuerySnapshot = await getDocs(usersRef);
            const usersList = [];
            if(usersQuerySnapshot.size > 0) {
            for (const userDoc of usersQuerySnapshot.docs) {
              const userId = userDoc.id;
              const userDataDoc = await getDoc(doc(DB, "users", userId));
              const userData = userDataDoc.data();
              usersList.push({ id: userId, ...userData });
            }
            debugger;
            list.push({ id: offerId, ...offerData, users: usersList });

            lastDoc = docd; // Update the last document reference
          } else {
            setOffers([]);
            setLastDocument(null);
          }
          }

          setOffers(list);
          setLastDocument(lastDoc); // Save the last document reference for pagination
        } else {
          setOffers([]);
          setLastDocument(null); // Reset the last document reference if there are no more documents
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching offers:", error);
        setLoading(false);
      }
    };

    fetchOffers();
  }, [more]);

  const checkCollection = async () => {
    try {
      const collectionRef = query(collection(DB, "offers"));
      debugger;
      const collectionSnapshot = await collectionRef.limit(1);
      setCollectionExists(!collectionSnapshot.empty);
      setHasDocuments(collectionSnapshot.size > 0);
    } catch (error) {
      console.error("Error checking collection:", error);
    }
  };
  return (
    <Container maxWidth={"full-width"} sx={{ height: 1 }}>
      {console.log(offers)}
      <Grid container gap={5}>
        <Grid sx={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Typography variant={"h4"} fontWeight={600}>
              Offers
            </Typography>
            <Box flex={1} />
            {hasDocuments ? (
              <Button
                sx={{ borderRadius: "8px" }}
                variant={"outlined"}
                color={"primary"}
                onClick={() => navigator("/admin/offers/list")}
              >
                View offers
              </Button>
            ) : (
              ""
            )}
            <Button
              sx={{ borderRadius: "8px" }}
              variant={"contained"}
              color={"primary"}
              onClick={() => navigator("/admin/offers/add-offer")}
            >
              + Add New offer
            </Button>
            <Box component="form" onSubmit={(event) => event.preventDefault()}>
              <Container
                maxWidth={"full-width"}
                sx={{
                  p: "2px 10px",
                  display: "flex",
                  alignItems: "right",
                  width: 337,
                  height: 43,
                  borderRadius: "12px",
                  backgroundColor: "#E4EAF5",
                }}
              >
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={(e) => setSearch(inputbtn)}
                >
                  <SearchIcon sx={{ color: "#051E40BF" }} />
                </IconButton>
                <InputBase
                  onChange={(e) => setinputbtn(e.target.value)}
                  value={inputbtn}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      setSearch(inputbtn);
                    }
                  }}
                  sx={{ ml: 1, flex: 1, color: "#051E40BF" }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search post" }}
                />
              </Container>
            </Box>
          </Stack>

          {hasDocuments ? (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant={"body1"} fontWeight={600}>
                        Full name
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant={"body1"} fontWeight={600}>
                        User type
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant={"body1"} fontWeight={600}>
                        Email
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant={"body1"} fontWeight={600}>
                        Offer Name
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant={"body1"} fontWeight={600}>
                        Claim Date
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offers && offers.length > 0 &&
                    offers
                      .sort((a, b) => b.createdAt - a.createdAt)
                      .map(
                        (offer) =>
                          offer.users?.length > 0 &&
                          offer.users?.map((user) => (
                            <TableRow
                              hover
                              key={offer?.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {user?.displayName}
                              </TableCell>
                              <TableCell align="center">
                                {USER_TYPES_TO_NAME[user?.userType]}
                              </TableCell>
                              <TableCell align="center">
                                {user?.email}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  cursor: "pointer",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "31ch",
                                }}
                                onClick={() => {
                                  console.log("test");
                                  navigator(
                                    `/${profile.userType}/offers/details/${offer.id}`
                                  );
                                }}
                              >
                                <u>{offer?.name}</u>
                              </TableCell>
                              <TableCell align="center">
                                {new Date(
                                  offer.createdAt.seconds * 1000
                                ).toLocaleString()}
                              </TableCell>
                              {/* <TableCell
              align="center"
              sx={{ cursor: "pointer" }}
              onClick={() => navigator("/admin/user-details/" + row.id)}
            >
              <u>View</u>
            </TableCell> */}
                            </TableRow>
                          ))
                      )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ mb: 5, mx: "auto", height: "50vh" }}
        >
          <Stack
            sx={{ border: "1px dashed rgba(0, 0, 0, 0.45)" }}
            p={5}
            direction={"row"}
            spacing={2}
          >
            <Typography variant={"h5"}>
              You have not added any <b>Offers</b> yet.{" "}
              <b>
                Add
                <br /> Now
              </b>{" "}
              to treat your users with exciting deals <br />
              and discounts!
            </Typography>
          </Stack>
          </Stack>
          )}
        </Grid>
      </Grid>
      {lastDocument && (
        <Stack
          direction={"row"}
          sx={{ width: "100%" }}
          py={5}
          justifyContent="center"
          alignItems="center"
        >
          <Button size={"small"} onClick={loadMore}>
            Load more
          </Button>
        </Stack>
      )}
    </Container>
  );
}
