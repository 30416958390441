import React from "react";
import {
	Container,
	Stack,
	Typography,
} from "@mui/material";
export default function DistributionList() {
  return(
    <Container maxWidth={'xl'}  sx={{my: 5, textAlign: 'left'}} >
      <Stack  direction={'column'} spacing={5}>

        <Stack  direction={'row'}  justifyContent="flex-start"
                alignItems="center"
                spacing={5}>
          <Typography variant={'h4'} style={{fontWeight: 600, flex: 1}}>
            Coming Soon
          </Typography>
         
      </Stack>
      </Stack>
    </Container>
  )
}