import * as yup from 'yup';

const FounderProfileSchema = yup.object({
  firstName: yup.string('Founder’s First Name').required('*Founder’s First Name is required.'),
  lastName: yup.string('Founder’s Last Name').required('*Founder’s Last Name is required.'),
  email: yup.string('Email').email().required('*Email is required.'),
  position: yup.string('Your Position / Role'),
  roleType: yup.string('Role type').required('Role type is required.'),
  website: yup.string('Website URL').url("The link must be a valid url"),
  linkedIn: yup.string('LinkedIn URL').matches(/linkedin.com/, "Please enter valid Linkedin url").url("The link must be a valid url"),
  twitter: yup.string('Twitter Handle').matches(/twitter.com/, "Please enter valid Twitter Handle").url("The link must be a valid url"),
  repeatFounder: yup.boolean('Repeat Founder'),
  photoURL: yup.string('Photo URL')
});
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  position: '',
  roleType: '',
  website: '',
  linkedIn: '',
  twitter: '',
  repeatFounder: false,
  photoURL: '',
};

const FounderProfileValidation = {
  initialValues,
  schema : FounderProfileSchema
};

export default  FounderProfileValidation;