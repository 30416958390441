import React, { useEffect } from "react";
import { AuthUserContext } from "../../providers/auth-provider";
import {
  Container,
  Typography,
  Stack,
  Avatar,
  styled,
  Badge,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  Portal,
  List,
  ListItem,
  ListItemText,
  TextField,
  Chip,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UploadFile from "../../utils/upload-file";
import { getAuth, updateProfile } from "firebase/auth";
import { a11yProps, TabPanel } from "../../components/common/tabs-custom";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  updateDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import PostItem from "../../components/common/timeline/post-item";
import { useNavigate } from "react-router-dom";
import InvestorDescription from "../../components/common/profile/investor-description";
import ExpertDescription from "../../components/common/profile/expert-description";
import InvestorAbout from "../../components/common/profile/investor-about";
import ExpertAbout from "../../components/common/profile/expert-about";
import BusinessDeveloperAbout from "../../components/common/profile/business-developer-about";
import BusinessDeveloperDescription from "../../components/common/profile/business-developer-description";
import ServiceProviderDescription from "../../components/common/profile/service-provider-description";
import ServiceProviderAbout from "../../components/common/profile/service-provider-about";
import StartupDescription from "../../components/common/profile/startup-description";
import StartupAbout from "../../components/common/profile/startup-about";
import TalentAbout from "../../components/common/profile/talent-about";
import TalentDescription from "../../components/common/profile/talent-description";
import PostNotification from "../../api/post-notification";
import StartupTopPriorities from "../../components/common/profile/startup-top-priorities";
import StartupTeam from "../../components/common/profile/startup-team";
import InvestmentPortfolioView from "../../components/common/profile/investor-portfolio-view";
import WorkExperienceView from "../../components/common/profile/work-experience-view";
import CommonInvestorDetails from "../../components/common/profile/common-investor-details";
import PortfoliosListView from "../investor/onboarding-forms/portfolios-list-view";
import InvestorPortfolioForm from "../investor/onboarding-forms/portfolio-form";
import ExperienceListView from "../mobile/expert/onboarding-forms/experience-list-view";
import ExperienceForm from "../mobile/expert/onboarding-forms/experience-form";
import ReferralAvatar from "../../components/common/profile/referal-avatar";
import { USER_TYPES } from "../../utils/enums/userTypes.enum";
import {
  BUSINESS_DEVELOPER,
  USER_TYPES_TO_NAME,
} from "../../utils/enums/labels.enum";
import { CloseRounded } from "@mui/icons-material";
import ExperienceListViewGrowthExpert from "../business-developer/onboarding-forms/experience-list-view-growth-expert";
import ExperienceFormGrowthExpert from "../business-developer/onboarding-forms/experience-form-growth-expert";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import InputBase from "@mui/material/InputBase";
const StyledTabs = styled((props) => (
  <Tabs
    sx={{ position: "relative", left: 30 }}
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "none",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    borderRadius: "30px",
    color: "#000",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#233DFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function ProfileScreen() {
  const [index, setIndex] = React.useState(-1);
  const [key, setKey] = React.useState();
  const [k, setK] = React.useState();

  const { profile, setProfile, setUser, user } =
    React.useContext(AuthUserContext);
  const [value, setValue] = React.useState(0);
  const [posts, setPosts] = React.useState([]);
  const profileImage = React.useRef();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [imageURL, setImageURL] = React.useState();
  const confirmed = React.useRef(false);
  const [portfolioIndex, setPortfolioIndex] = React.useState();
  const [steps, setSteps] = React.useState(0);
  const userRef = doc(DB, "users", user.uid);
  debugger;
  const [portfolios, setPortfolios] = React.useState(
    profile?.userType === "investor"
      ? profile?.investor?.portfolio
      : profile[
          profile?.userType === "service-provider"
            ? "serviceProvider"
            : profile?.userType === "business-developer"
            ? "businessDeveloper"
            : profile?.userType
        ]?.investorDetails?.portfolio
  );
  const [experiences, setExperiences] = React.useState(
    profile?.userType === "investor" &&
      profile?.userType === "startup" &&
      profile?.userType === "talent"
      ? []
      : profile[
          profile?.userType === "service-provider"
            ? "serviceProvider"
            : profile?.userType === "business-developer"
            ? "businessDeveloper"
            : profile?.userType
        ]?.experiences || []
  );
  const [experienceIndex, setExperienceIndex] = React.useState();
  const [postData, setPostData] = React.useState();
  const [text, setText] = React.useState("");
  const [image, setImage] = React.useState("");
  const [video, setVideo] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [file, setFile] = React.useState();
  const [otherFiles, setOtherFiles] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [URL, setURL] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const arr = ["Success! Your Post has been Uploaded."];
  const arr2 = ["Success! Your Post has been Edited."];
  const [tag, setTag] = React.useState("");
  const [posted, setPosted] = React.useState(false);
  const [imageDimensions, setImageDimensions] = React.useState({});

  const storage = getStorage();
  const resetAll = async () => {
    await setImage("");
    await setVideo("");
    await setFile();
    await setURL();
    await setLoading(false);
    await setOtherFiles("");
    await setPostData();
    // window.document.getElementById('attachment-media')
    // window.document.getElementById('attachment-video').empty()
    // window.document.getElementById('attachment-file').empty()
  };
  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    setDialogOpen(false);
  }
  const navigator = useNavigate();
  const Input = styled("input")({
    display: "none",
  });
  async function updateProfilePicture(url) {
    try {
      let startup = profile["startup"];
      const auth = getAuth();
      console.log(url);
      const displayName = profile?.displayName;
      if (profile?.userType !== "startup") {
        await updateProfile(auth.currentUser, {
          displayName,
          photoURL: url,
        });
      }
      const data = auth.currentUser.toJSON();
      setUser({ ...user });
      const userRef = doc(DB, "users", auth.currentUser.uid);
      let dat;

      if (profile?.userType === "startup") {
        dat = await updateDoc(userRef, {
          "startup.logoURL": url,
          updatedById: auth?.currentUser?.uid,
          updatedBy: "user",
          updatedAt: new Date().getTime(),
        });
        setUser({ ...user });

        setProfile({
          ...profile,
          startup: { ...startup, logoURL: url },
        });
        setImageURL("");
      } else {
        dat = await updateDoc(userRef, {
          photoURL: data?.photoURL ? data.photoURL : " ",
          updatedById: auth?.currentUser?.uid,
          updatedBy: "user",
          updatedAt: new Date().getTime(),
        });
        setUser({ ...user, photoURL: url });
        setProfile({ ...profile, photoURL: url });
        setImageURL("");
      }
      PostNotification({
        type: "update-profile-picture",
        imageURL: url,
        message: `Your profile picture has been updated`,
        uid: user.uid,
      }).then();
      confirmed.current = true;
    } catch (err) {
      console.log(err.message);
    }
  }
  useEffect(() => {
    console.log(confirmed.current);
  });
  function UploadProfile() {
    return (
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6" fontWeight={600}>
              Profile Photo
            </Typography>
            <IconButton onClick={() => setDialogOpen(false)}>
              <CloseRounded></CloseRounded>
            </IconButton>
          </Stack>
          <hr style={{ opacity: 0.3 }} />
          {/* {profile?.userType === "startup"
							? profile?.startup?.startupName
							: profile?.displayName} */}
        </DialogTitle>

        <DialogContent
          sx={{
            height: 400,
            width: 600,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            key={imageURL}
            src={
              imageURL
                ? imageURL
                : profile?.userType === "startup"
                ? profile?.startup?.logoURL !== ""
                  ? profile?.startup?.logoURL
                  : ""
                : profile?.photoURL
            }
            alt={
              profile?.userType === "startup"
                ? profile?.startup?.startupName
                : profile?.displayName
            }
            sx={{
              width: 280,
              height: 280,
            }}
            component={"span"}
          />
        </DialogContent>

        <DialogActions sx={{ justifyContent: "start" }}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              <IconButton
                sx={{
                  width: 100,
                  "&:hover": { backgroundColor: "white" },
                  cursor: "pointer",
                }}
                variant="outlined"
              >
                <label htmlFor={"profile-image"}>
                  <Stack
                    direction={"column"}
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <CameraAltIcon fontSize="large" />
                    <Input
                      accept="image/*"
                      id="profile-image"
                      multiple={false}
                      type="file"
                      onChange={(e) => {
                        confirmed.current = false;
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(e.target.files[0]);
                        fileReader.addEventListener("load", async function () {
                          const file = e.target.files[0];

                          const path = `profile-image/${file.name}`;
                          const imageObject = {
                            progress: 0,
                            src: this.result,
                            path,
                            file: e.target.files[0],
                            size: (
                              e.target.files[0]?.size /
                              1024 /
                              1024
                            ).toFixed(2),
                          };
                          profileImage.current = imageObject;
                          UploadFile(file, path).then((url) => {
                            console.log(url);
                            setImageURL(url);
                          });
                        });
                      }}
                    />
                    <DialogContentText sx={{ fontSize: 15 }}>
                      Add Photo
                    </DialogContentText>
                  </Stack>
                </label>
              </IconButton>
              <IconButton
                sx={{
                  width: 100,
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "white" },
                }}
                variant="outlined"
                onClick={() => {
                  profileImage.current = "";
                  setImageURL(" ");

                  // updateProfilePicture("");
                  confirmed.current = true;
                  //handleDialogClose();
                }}
              >
                <Stack
                  direction={"column"}
                  sx={{ justifyContent: "center", alignItems: "center" }}
                >
                  <DeleteIcon fontSize="large" />
                  <DialogContentText sx={{ fontSize: 15 }}>
                    Delete
                  </DialogContentText>
                </Stack>
              </IconButton>
            </div>

            <Button
              sx={{ width: 150, height: 50 }}
              onClick={() => {
                profileImage.current = {
                  ...profileImage,
                  photoURL: imageURL,
                };

                updateProfilePicture(imageURL);

                profileImage.current = "";

                handleDialogClose();
                //updateProfilePicture("");
              }}
              autoFocus
              variant="contained"
            >
              Save Photo
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    );
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSteps(0);
  };
  React.useEffect(() => {
    debugger;
    if (!imageURL) {
      setImageURL(
        imageURL
          ? imageURL
          : profile?.userType === "startup"
          ? profile?.startup?.logoURL !== ""
            ? profile?.startup?.logoURL
            : ""
          : profile?.photoURL
      );
    }

    const q = query(
      collection(DB, "timelines"),
      where("uid", "==", profile?.uid),
      orderBy("createdAt", 'desc')
    );
    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });

          console.log(doc.id, " => ", doc.data());
        });
        setPosts(list);
      }
    });
  }, [open]);
  const deletedPost = () => {
    debugger;
    const q = query(
      collection(DB, "timelines"),
      where("uid", "==", profile?.uid)
    );
    getDocs(q).then((querySnapshot) => {
      const list = [];
      if (querySnapshot.size > 0) {
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });

          console.log(doc.id, " => ", doc.data());
        });
      }
      setPosts(list);
    });
  };
  if (index === -1) {
    return (
      <Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
        <Stack direction={"column"} spacing={5}>
          <Stack
            direction={"column"}
            spacing={0.5}
            sx={{
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
              backgroundColor: "#fff",
              minHeight: 100,
              p: 2,
              pb: 0,
            }}
          >
            <Box
              sx={{
                height: 100,
                width: "100%",
                backgroundColor: "#F6F8FA",
                borderRadius: "15px",
              }}
            />
            <Box
              sx={{
                border: "1px solid #204488",
                p: 0.75,
                borderRadius: "50%",
                width: "fit-content",
                position: "relative",
                left: 30,
                top: -50,
              }}
            >
              <label>
                <Badge
                  overlap="circular"
                  sx={{ width: 92, height: 92 }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Button
                      onClick={handleDialogOpen}
                      sx={{ ml: 1, mt: 1 }}
                      color={"primary"}
                      component={"span"}
                    >
                      <AddCircleIcon />
                    </Button>
                  }
                >
                  <UploadProfile />
                  <Avatar
                    key={imageURL}
                    src={
                      imageURL && confirmed.current
                        ? imageURL
                        : profile?.userType === "startup"
                        ? profile?.startup?.logoURL !== ""
                          ? profile?.startup?.logoURL
                          : ""
                        : profile?.photoURL
                    }
                    alt={
                      profile?.userType === "startup"
                        ? profile?.startup?.startupName
                        : profile?.displayName
                    }
                    sx={{
                      width: 92,
                      height: 92,
                      cursor: "pointer",
                    }}
                    component={"span"}
                  />
                </Badge>
              </label>
            </Box>

            <Stack
              direction={"row"}
              spacing={3}
              sx={{ position: "relative", top: -40 }}
              alignItems={"flex-start"}
            >
              <div />
              <Stack direction={"column"} spacing={2}>
                <Stack direction={"row"} spacing={3}>
                  <Typography variant={"h5"}>
                    {profile?.userType === "startup"
                      ? profile?.startup?.startupName
                      : profile?.displayName}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#E0E3FF",
                      px: 3,
                      py: 1,
                      borderRadius: "19.5px",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        textTransform: "capitalize",
                      }}
                    >
                      {USER_TYPES_TO_NAME[profile?.userType]}
                    </Typography>
                  </Box>

                  {profile[
                    profile?.userType === "service-provider"
                      ? "serviceProvider"
                      : profile?.userType === "business-developer"
                      ? "businessDeveloper"
                      : profile?.userType
                  ]?.investorDetails?.areYouAnAngelInvestor && (
                    <Box
                      sx={{
                        backgroundColor: "#B8FC3C",
                        px: 3,
                        py: 1,
                        borderRadius: "19.5px",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                      >
                        {"Investor"}
                      </Typography>
                    </Box>
                  )}
                </Stack>
                {profile?.userType === "investor" && (
                  <InvestorDescription profile={profile} />
                )}
                {profile?.userType === "expert" && (
                  <ExpertDescription profile={profile} />
                )}
                {profile?.userType === "business-developer" && (
                  <BusinessDeveloperDescription profile={profile} />
                )}
                {profile?.userType === "service-provider" && (
                  <ServiceProviderDescription profile={profile} />
                )}
                {profile?.userType === "startup" && (
                  <StartupDescription profile={profile} />
                )}
                {profile?.userType === "talent" && (
                  <TalentDescription profile={profile} />
                )}
              </Stack>

              <div style={{ flex: 1 }} />
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
              >
                <StyledTab label="ABOUT" {...a11yProps(0)} />
                {profile?.userType === "startup" && (
                  <StyledTab label={"TEAM"} {...a11yProps(1)} />
                )}
                {profile?.userType !== "investor" &&
                  profile?.userType !== "startup" && (
                    <StyledTab label={"EXPERIENCE"} {...a11yProps(1)} />
                  )}
                {profile?.userType === "expert" &&
                profile?.expert.investorDetails.areYouAnAngelInvestor &&
                profile?.expert.investorDetails.areYouAnAngelInvestor ===
                  true ? (
                  <StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />
                ) : profile?.userType === "business-developer" &&
                  profile?.businessDeveloper.investorDetails
                    .areYouAnAngelInvestor &&
                  profile?.businessDeveloper.investorDetails
                    .areYouAnAngelInvestor === true ? (
                  <StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />
                ) : profile?.userType === "service-provider" &&
                  profile?.serviceProvider.investorDetails
                    .areYouAnAngelInvestor &&
                  profile?.serviceProvider.investorDetails
                    .areYouAnAngelInvestor === true ? (
                  <StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />
                ) : profile?.userType === "investor" ? (
                  <StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />
                ) : null}

                {/* {(
                  profile?.userType === "service-provider" ||
                  profile?.userType === "talent") && (
                  <StyledTab label={"SOCIAL PROOF"} {...a11yProps(3)} />

                )} */}
                {profile?.userType !== "startup" && (
                  <StyledTab
                    label="POST"
                    {...a11yProps(profile?.userType !== "investor" ? 3 : 2)}
                  />
                )}

                {/* <StyledTab
							label="CHAT"
							onClick={() =>
								navigator(`/${profile?.userType}/chat`)
							}
							{...a11yProps(4)}
						/> */}
              </StyledTabs>
              <div style={{ flex: 10 }} />

              {profile?.userType === "service-provider" &&
                profile?.serviceProvider?.organisationType === "Organisation" &&
                profile?.serviceProvider?.organisationDetails && (
                  <Stack
                    direction={"column"}
                    spacing={1}
                    justifyContent="center"
                    sx={{
                      position: "relative",
                      right: 15,
                      minWidth: 120,
                      cursor: "pointer",
                    }}
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        border: "1px solid #204488",
                        p: 0.75,
                        borderRadius: "50%",
                        width: "fit-content",
                      }}
                      onClick={() =>
                        navigator(
                          `/${profile?.userType}/organisation-profile`,
                          {
                            state: {
                              profile,
                              organisation: {
                                ...profile?.serviceProvider.organisationDetails,
                                nameOfOrganisation:
                                  profile?.serviceProvider.organisationDetails
                                    ?.nameOfOrganization !== ""
                                    ? profile?.serviceProvider.organisation
                                        ?.nameOfOrganization
                                    : profile?.serviceProvider
                                        ?.organisationDetails
                                        ?.nameOfOrganization,
                                logoURL:
                                  profile?.serviceProvider.organisationDetails
                                    .logoURL !== ""
                                    ? profile?.serviceProvider
                                        .organisationDetails.logoURL
                                    : "",
                              },
                            },
                          }
                        )
                      }
                    >
                      <Avatar
                        alt={
                          profile?.serviceProvider?.organisationDetails
                            .nameOfOrganization
                        }
                        src={
                          profile.userType === "service-provider" &&
                          profile?.serviceProvider?.organisationDetails
                            ?.logoURL !== ""
                            ? profile?.serviceProvider?.organisationDetails
                                ?.logoURL
                            : ""
                        }
                        sx={{ width: 43, height: 43 }}
                      >
                        <Typography variant={"h4"}>
                          {profile?.serviceProvider?.organisationDetails.nameOfOrganization.charAt(
                            0
                          )}
                        </Typography>
                      </Avatar>
                    </Box>
                    <Typography
                      variant={"h6"}
                      // sx={{ textAlign: "center" }}
                    >
                      {
                        profile?.serviceProvider?.organisationDetails
                          .nameOfOrganization
                      }
                    </Typography>
                    <Typography variant={"body2"}>
                      <font
                        size={2}
                        style={{
                          backgroundColor: "#F6F8FA",
                          padding: "5px 10px",
                          borderRadius: "6px",
                          fontWeight: 300,
                        }}
                      >
                        Organisation
                      </font>
                    </Typography>
                  </Stack>
                )}
              {profile?.userType === "startup" && profile?.startup?.founder && (
                <Stack
                  direction={"column"}
                  spacing={1}
                  justifyContent="center"
                  sx={{
                    position: "relative",
                    mr: -5,
                    right: 15,
                    minWidth: 120,
                    cursor: "pointer",
                  }}
                  alignItems="center"
                >
                  <Box
                    sx={{
                      border: "1px solid #204488",
                      p: 0.75,
                      borderRadius: "50%",
                      width: "fit-content",
                    }}
                    onClick={() =>
                      navigator(`/${profile?.userType}/profile/founder`, {
                        state: {
                          profile,
                          founder: {
                            ...profile?.startup.founder,
                            startupName:
                              profile?.startup.founder?.startupName !== ""
                                ? profile?.startup.founder?.startupName
                                : profile?.startup?.startupName,
                            photoURL:
                              profile?.startup.founder.photoURL !== ""
                                ? profile?.startup.founder.photoURL
                                : profile?.photoURL,
                          },
                        },
                      })
                    }
                  >
                    <Avatar
                      alt={profile?.startup?.founder.founderName}
                      src={
                        profile.userType === "startup" &&
                        profile?.photoURL !== ""
                          ? profile?.photoURL
                          : profile?.startup?.founder?.photoURL !== ""
                          ? profile?.startup?.founder?.photoURL
                          : profile?.photoURL
                      }
                      sx={{ width: 43, height: 43 }}
                    >
                      <Typography variant={"h4"}>
                        {profile?.startup?.founder.founderName.charAt(0)}
                      </Typography>
                    </Avatar>
                  </Box>
                  <Typography
                    variant={"h6"}
                    // sx={{ textAlign: "center" }}
                  >
                    {profile?.startup?.founder.founderName}
                  </Typography>
                  <Typography variant={"body2"}>
                    <font
                      size={2}
                      style={{
                        backgroundColor: "#F6F8FA",
                        padding: "5px 10px",
                        borderRadius: "6px",
                        fontWeight: 300,
                      }}
                    >
                      Founder
                    </font>
                  </Typography>
                </Stack>
              )}
              <Box sx={{ flex: 1 }} />
              {profile?.userType === "investor" &&
                profile?.investor?.investorType !== "Angel Investor" && (
                  <Stack
                    direction={"column"}
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      position: "relative",
                      minWidth: 120,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const path = `/${profile?.userType}/my-organisation-profile`;
                      navigator(path, {
                        state: {
                          profile: profile,
                          organisation: {
                            organizationDescription:
                              profile?.investor?.organizationDescription,
                            investorType: profile?.investor?.investorType,
                            website: profile?.investor?.website,
                            nameOfOrganization:
                              profile?.investor?.nameOfOrganization,
                            logoURL: profile?.logoURL,
                          },
                        },
                      });
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 57,
                        height: 57,
                        border: "1px solid #f5f5f5",
                      }}
                      src={profile?.logoURL}
                      alt={"profile-pic"}
                      imgProps={{
                        style: {
                          objectFit: "contain",
                          backgroundColor: "#fff",
                        },
                      }}
                    >
                      <Typography variant={"h3"}>
                        {profile?.investor?.nameOfOrganization}
                      </Typography>
                    </Avatar>
                    <Typography variant={"h6"}>
                      <font>{profile?.investor?.nameOfOrganization}</font>
                    </Typography>
                    <Typography variant={"body2"}>
                      <font
                        size={2}
                        style={{
                          backgroundColor: "#F6F8FA",
                          padding: "5px 10px",
                          borderRadius: "6px",
                          fontWeight: 300,
                        }}
                      >
                        Organization
                      </font>
                    </Typography>
                  </Stack>
                )}
              <ReferralAvatar profile={profile} />
            </Stack>

            <Box sx={{ height: 20 }} />
          </Stack>
          <TabPanel index={0} value={value}>
            {profile?.userType === "investor" ? (
              <InvestorAbout profile={profile} />
            ) : profile?.userType === "expert" ? (
              <ExpertAbout profile={profile} />
            ) : profile?.userType === "business-developer" ? (
              <BusinessDeveloperAbout profile={profile} />
            ) : profile?.userType === "service-provider" ? (
              <ServiceProviderAbout profile={profile} />
            ) : profile?.userType === "startup" ? (
              <StartupAbout profile={profile} />
            ) : profile?.userType === "talent" ? (
              <TalentAbout profile={profile} />
            ) : (
              ""
            )}
          </TabPanel>
          {profile?.userType !== "investor" &&
            profile[
              profile?.userType === "service-provider"
                ? "serviceProvider"
                : profile?.userType === "business-developer"
                ? "businessDeveloper"
                : profile?.userType
            ]?.investorDetails?.areYouAnAngelInvestor && (
              <TabPanel index={0} value={value}>
                <Stack
                  direction={"column"}
                  spacing={3}
                  sx={{
                    boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                    borderRadius: "15px",
                    backgroundColor: "#fff",
                    minHeight: 100,
                    px: 5.5,
                    py: 6,
                  }}
                >
                  <CommonInvestorDetails profile={profile} />
                </Stack>
              </TabPanel>
            )}

          <TabPanel
            index={profile?.userType === "startup" ? 1 : null}
            value={value}
          >
            <Stack
              direction={"column"}
              spacing={3}
              sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                px: 5.5,
                py: 6,
              }}
            >
              {profile?.userType === "startup" ? (
                <StartupTeam profile={profile} />
              ) : null}
            </Stack>
          </TabPanel>

          <TabPanel
            index={
              profile?.userType === "investor"
                ? 1
                : profile[
                    profile?.userType === "service-provider"
                      ? "serviceProvider"
                      : profile?.userType === "business-developer"
                      ? "businessDeveloper"
                      : profile?.userType
                  ]?.investorDetails?.areYouAnAngelInvestor
                ? 2
                : -1
            }
            value={value}
          >
            <Stack
              direction={"column"}
              spacing={6}
              sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                px: 5.5,
                py: 6,
              }}
            >
              {steps === 1 ? (
                <>
                  {portfolioIndex !== undefined && portfolioIndex > -1 ? (
                    <Typography variant={"h5"}>Update portfolio</Typography>
                  ) : (
                    <Typography variant={"h5"}>Add portfolio</Typography>
                  )}
                  <InvestorPortfolioForm
                    cbBack={() => {
                      setSteps(0);
                      setPortfolioIndex();
                    }}
                    cbNext={(data, index) => {
                      const port = [...portfolios];
                      if (index !== undefined && index > -1) {
                        port.splice(index, 1, { ...data });
                      } else {
                        port.push({ ...data });
                      }
                      const copyPortfolio = [];
                      debugger;
                      if (port?.length > 0) {
                        for (let portfolio of port) {
                          const attachments = [];
                          if (portfolio?.attachments?.length > 0) {
                            for (let file of portfolio?.attachments) {
                              if (file?.file) {
                                const path = `investors/${user.uid}/attachments/${file.file.name}`;
                                attachments.push(path);
                              } else {
                                attachments.push(file);
                              }
                            }
                          }
                          copyPortfolio.push({ ...portfolio, attachments });
                        }
                      }
                      const objectUpdate = {};
                      if (profile?.userType === "service-provider") {
                        objectUpdate["serviceProvider"] = {
                          ...profile["serviceProvider"],
                        };
                      } else if (profile?.userType === "business-developer") {
                        objectUpdate["businessDeveloper"] = {
                          ...profile["businessDeveloper"],
                        };
                      } else {
                        debugger;
                        objectUpdate[profile?.userType] = {
                          ...profile[profile?.userType],
                        };
                      }
                      if (profile?.userType !== "investor") {
                        debugger;
                        objectUpdate[
                          profile?.userType === "service-provider"
                            ? "serviceProvider"
                            : profile?.userType === "business-developer"
                            ? "businessDeveloper"
                            : profile?.userType
                        ].investorDetails.portfolio = [...copyPortfolio];
                      } else {
                        debugger;
                        objectUpdate[profile?.userType].portfolio = [
                          ...copyPortfolio,
                        ];
                      }
                      setPortfolios([...copyPortfolio]);
                      setDoc(
                        userRef,
                        {
                          ...objectUpdate,
                          updatedById: user?.uid,
                          updatedBy: "user",
                          updatedAt: new Date().getTime(),
                        },
                        { merge: true }
                      ).then(() => {
                        setProfile({ ...profile, ...objectUpdate });
                      });

                      setSteps(0);

                      setPortfolioIndex();
                    }}
                    data={
                      portfolioIndex !== undefined && portfolioIndex > -1
                        ? portfolios[portfolioIndex]
                        : undefined
                    }
                    index={portfolioIndex}
                  />
                </>
              ) : (
                <>
                  <Typography fontSize={20} fontWeight={600}>
                    Investment portfolio
                  </Typography>

                  <PortfoliosListView
                    add={(data) => {
                      setPortfolioIndex();
                      setSteps(1);
                    }}
                    edit={async (ind) => {
                      debugger;
                      await setPortfolioIndex(ind);
                      setSteps(1);
                    }}
                    remove={(ind) => {
                      const port = [...portfolios];
                      port.splice(ind, 1);
                      setPortfolios([...port]);
                      const objectUpdate = {};
                      if (profile?.userType === "service-provider") {
                        objectUpdate["serviceProvider"] = {
                          ...profile["serviceProvider"],
                        };
                      } else if (profile?.userType === "business-developer") {
                        objectUpdate["businessDeveloper"] = {
                          ...profile["businessDeveloper"],
                        };
                      } else {
                        debugger;
                        objectUpdate[profile?.userType] = {
                          ...profile[profile?.userType],
                        };
                      }
                      if (profile?.userType !== "investor") {
                        debugger;
                        objectUpdate[
                          profile?.userType === "service-provider"
                            ? "serviceProvider"
                            : profile?.userType === "business-developer"
                            ? "businessDeveloper"
                            : profile?.userType
                        ].investorDetails.portfolio = [...port];
                      } else {
                        debugger;
                        objectUpdate[profile?.userType].portfolio = [...port];
                      }
                      setDoc(
                        userRef,
                        {
                          ...objectUpdate,
                          updatedById: user?.uid,
                          updatedBy: "user",
                          updatedAt: new Date().getTime(),
                        },
                        { merge: true }
                      ).then(() => {
                        setProfile({ ...profile, ...objectUpdate });
                      });
                      setSteps(0);
                    }}
                    portfolios={portfolios}
                  />
                </>
              )}
              {/*{portfolioIndex || portfolioIndex  === 0  &&<InvestorPortfolioForm
								cbBack={() => setPortfolioIndex(undefined)}
								cbNext={(data, index) => {
									const port = [...portfolios]
									if (index !== undefined && index > -1) {
										port.splice(index, 1, { ...data})
									} else {
										port.push({ ...data })
									}
									setPortfolios([...port])
									// setSteps(steps - 1);
									setPortfolioIndex();

								}}
								data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}

								index={portfolioIndex}
							/> }*/}
              {/*{
								(!portfolioIndex && portfolioIndex  !== 0)   &&
								<>
									<Typography variant={'h5'}>Investment portfolio</Typography>

									<PortfoliosListView

										add={(data) => {
											setPortfolioIndex(-1);
											// setSteps(steps + 1);
										}}
										edit={async (ind) => {
											debugger

											console.log(ind)
											await setPortfolioIndex(ind);
											// setSteps(steps + 1);
										}}
										remove={(ind) => {
											debugger
											const port = [...portfolios]
											port.splice(ind, 1)
											setPortfolios([...port])
										}}
										portfolios={portfolios}
									/>
								</>

							}*/}

              {/*<InvestorPortfolio profile={profile} setIndex={setIndex} setKey={setKey}/>*/}
            </Stack>
          </TabPanel>
          {profile?.userType !== "investor" &&
          profile?.userType !== "startup" ? (
            <TabPanel index={1} value={value}>
              <Stack
                direction={"column"}
                spacing={6}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}
              >
                {steps === 1 ? (
                  <>
                    {experienceIndex !== undefined && experienceIndex > -1 ? (
                      <Typography variant={"h5"}>Update Experience</Typography>
                    ) : (
                      <Typography variant={"h5"}>Add Experience</Typography>
                    )}
                    {profile?.userType !== "business-developer" ? (
                      <ExperienceForm
                        cbBack={() => setSteps(0)}
                        cbNext={(data, index) => {
                          const port = [...experiences];
                          if (index !== undefined && index > -1) {
                            port.splice(index, 1, { ...data });
                          } else {
                            port.push({ ...data });
                          }
                          setExperiences([...port]);

                          const copyExperiences = [];
                          if (port?.length > 0) {
                            for (let experience of port) {
                              const attachments = [];
                              if (experience?.attachments?.length > 0) {
                                for (let file of experience?.attachments) {
                                  if (file?.file) {
                                    const path = `experts/${user.uid}/experiences/attachments/${file.file.name}`;
                                    attachments.push(path);
                                  } else {
                                    attachments.push(file);
                                  }
                                }
                              }
                              copyExperiences.push({
                                ...experience,
                                attachments,
                              });
                            }
                          }
                          const objectUpdate = {};
                          if (profile?.userType === "service-provider") {
                            objectUpdate["serviceProvider"] = {
                              ...profile["serviceProvider"],
                            };
                          } else if (
                            profile?.userType === "business-developer"
                          ) {
                            objectUpdate["businessDeveloper"] = {
                              ...profile["businessDeveloper"],
                            };
                          } else {
                            debugger;
                            objectUpdate[profile?.userType] = {
                              ...profile[profile?.userType],
                            };
                          }
                          objectUpdate[
                            profile?.userType === "service-provider"
                              ? "serviceProvider"
                              : profile?.userType === "business-developer"
                              ? "businessDeveloper"
                              : profile?.userType
                          ].experiences = [...copyExperiences];
                          setDoc(
                            userRef,
                            {
                              ...objectUpdate,
                              updatedById: user?.uid,
                              updatedBy: "user",
                              updatedAt: new Date().getTime(),
                            },
                            { merge: true }
                          ).then(() => {
                            setProfile({ ...profile, ...objectUpdate });
                          });
                          setSteps(0);

                          setExperienceIndex(undefined);
                        }}
                        data={
                          experienceIndex !== undefined && experienceIndex > -1
                            ? experiences[experienceIndex]
                            : undefined
                        }
                        index={experienceIndex}
                      />
                    ) : (
                      <ExperienceFormGrowthExpert
                        cbBack={() => setSteps(0)}
                        cbNext={(data, index) => {
                          debugger;
                          const port = [...experiences];
                          if (index !== undefined && index > -1) {
                            port.splice(index, 1, { ...data });
                          } else {
                            port.push({ ...data });
                          }
                          setExperiences([...port]);

                          const copyExperiences = [];
                          if (port?.length > 0) {
                            for (let experience of port) {
                              const attachments = [];
                              if (experience?.attachments?.length > 0) {
                                for (let file of experience?.attachments) {
                                  if (file?.file) {
                                    const path = `experts/${user.uid}/experiences/attachments/${file.file.name}`;
                                    attachments.push(path);
                                  } else {
                                    attachments.push(file);
                                  }
                                }
                              }
                              copyExperiences.push({
                                ...experience,
                                attachments,
                              });
                            }
                          }
                          const objectUpdate = {};
                          if (profile?.userType === "service-provider") {
                            objectUpdate["serviceProvider"] = {
                              ...profile["serviceProvider"],
                            };
                          } else if (
                            profile?.userType === "business-developer"
                          ) {
                            objectUpdate["businessDeveloper"] = {
                              ...profile["businessDeveloper"],
                            };
                          } else {
                            debugger;
                            objectUpdate[profile?.userType] = {
                              ...profile[profile?.userType],
                            };
                          }
                          objectUpdate[
                            profile?.userType === "service-provider"
                              ? "serviceProvider"
                              : profile?.userType === "business-developer"
                              ? "businessDeveloper"
                              : profile?.userType
                          ].experiences = [...copyExperiences];
                          setDoc(
                            userRef,
                            {
                              ...objectUpdate,
                              updatedById: user?.uid,
                              updatedBy: "user",
                              updatedAt: new Date().getTime(),
                            },
                            { merge: true }
                          ).then(() => {
                            setProfile({ ...profile, ...objectUpdate });
                          });
                          setSteps(0);

                          setExperienceIndex(undefined);
                        }}
                        data={
                          experienceIndex !== undefined && experienceIndex > -1
                            ? experiences[experienceIndex]
                            : undefined
                        }
                        index={experienceIndex}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography fontSize={20} fontWeight={600}>
                      Work Experience
                    </Typography>
                    {profile?.userType !== "business-developer" ? (
                      <ExperienceListView
                        add={(data) => {
                          setExperienceIndex(undefined);
                          setSteps(1);
                          /*		setPortfolioIndex();
                                                        setSteps(steps + 1);*/
                        }}
                        edit={(ind) => {
                          setExperienceIndex(ind);
                          setSteps(1);
                          /*	setPortfolioIndex(ind);
                                                    setSteps(steps + 1);*/
                        }}
                        remove={(ind) => {
                          const experiences_copy = [...experiences];
                          experiences_copy.splice(ind, 1);
                          setExperiences(experiences_copy);
                          const objectUpdate = {};
                          if (profile?.userType === "service-provider") {
                            objectUpdate["serviceProvider"] = {
                              ...profile["serviceProvider"],
                            };
                          } else if (
                            profile?.userType === "business-developer"
                          ) {
                            objectUpdate["businessDeveloper"] = {
                              ...profile["businessDeveloper"],
                            };
                          } else {
                            debugger;
                            objectUpdate[profile?.userType] = {
                              ...profile[profile?.userType],
                            };
                          }
                          debugger;
                          objectUpdate[
                            profile?.userType === "service-provider"
                              ? "serviceProvider"
                              : profile?.userType === "business-developer"
                              ? "businessDeveloper"
                              : profile?.userType
                          ].experiences = [...experiences_copy];
                          setDoc(
                            userRef,
                            {
                              ...objectUpdate,
                              updatedById: user?.uid,
                              updatedBy: "user",
                              updatedAt: new Date().getTime(),
                            },
                            { merge: true }
                          ).then(() => {
                            setProfile({ ...profile, ...objectUpdate });
                          });

                          /*	debugger
                                                    const port = [...portfolios]
                                                    port.splice(ind, 1)
                                                    setPortfolios([...port])*/
                        }}
                        experiences={experiences}
                      />
                    ) : (
                      <ExperienceListViewGrowthExpert
                        add={(data) => {
                          setExperienceIndex(undefined);
                          setSteps(1);
                          /*		setPortfolioIndex();
                                                      setSteps(steps + 1);*/
                        }}
                        edit={(ind) => {
                          setExperienceIndex(ind);
                          setSteps(1);
                          /*	setPortfolioIndex(ind);
                                                  setSteps(steps + 1);*/
                        }}
                        remove={(ind) => {
                          const experiences_copy = [...experiences];
                          experiences_copy.splice(ind, 1);
                          setExperiences(experiences_copy);
                          const objectUpdate = {};
                          if (profile?.userType === "service-provider") {
                            objectUpdate["serviceProvider"] = {
                              ...profile["serviceProvider"],
                            };
                          } else if (
                            profile?.userType === "business-developer"
                          ) {
                            objectUpdate["businessDeveloper"] = {
                              ...profile["businessDeveloper"],
                            };
                          } else {
                            debugger;
                            objectUpdate[profile?.userType] = {
                              ...profile[profile?.userType],
                            };
                          }
                          debugger;
                          objectUpdate[
                            profile?.userType === "service-provider"
                              ? "serviceProvider"
                              : profile?.userType === "business-developer"
                              ? "businessDeveloper"
                              : profile?.userType
                          ].experiences = [...experiences_copy];
                          setDoc(
                            userRef,
                            {
                              ...objectUpdate,
                              updatedById: user?.uid,
                              updatedBy: "user",
                              updatedAt: new Date().getTime(),
                            },
                            { merge: true }
                          ).then(() => {
                            setProfile({ ...profile, ...objectUpdate });
                          });

                          /*	debugger
                                                  const port = [...portfolios]
                                                  port.splice(ind, 1)
                                                  setPortfolios([...port])*/
                        }}
                        data={experiences}
                      />
                    )}
                  </>
                )}

                {/*	{profile?.userType === "expert" ? (
									<ExpertWorkExperience profile={profile} setIndex={setIndex} setK={setK}/>
								) : profile?.userType === "business-developer" ? (
									<BusinessDeveloperWorkExperience profile={profile} setIndex={setIndex} setK={setK} />
								) :profile?.userType === "service-provider" ? (
									<ServiceProviderWorkExperience profile={profile} setIndex={setIndex} setK={setK}/>
								):null
								}*/}

                {/* {profile?.userType === "expert" ? (
                  <ExpertWorkExperience profile={profile} setIndex={setIndex}/>
                ) : profile?.userType === "business-developer" ? (
                  <BusinessDeveloperWorkExperience profile={profile} setIndex={setIndex}/>
                ) :profile?.userType === "service-provider" ? (
                  <ServiceProviderSocialProof profile={profile} />
                ) : profile?.userType === "startup" ? (
                  <FinanceAbout profile={profile} />
                ) : profile?.userType === "talent" ? (
                  <TalentSocialProof profile={profile} />
                ) : (
                  <InvestorPortfolio profile={profile} setIndex={setIndex} />
                )} */}
              </Stack>
            </TabPanel>
          ) : null}
          {console.log(profile)}
          <TabPanel
            index={
              profile?.userType === "investor"
                ? 2
                : profile[
                    profile?.userType === "service-provider"
                      ? "serviceProvider"
                      : profile?.userType === "business-developer"
                      ? "businessDeveloper"
                      : profile?.userType
                  ]?.investorDetails?.areYouAnAngelInvestor
                ? 3
                : 2
            }
            value={value}
          >
            <Stack>
              <Stack direction={"column"} spacing={5}>
                {posts?.length === 0 ? (
                  <>
                    <Stack
                      direction={"column"}
                      spacing={6}
                      sx={{
                        boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        minHeight: 100,
                        px: 5.5,
                        py: 6,
                        textAlign: "center",
                      }}
                    >
                      {/* <Stack direction="row" justifyContent="center"> */}

                      <Typography>There are no posts to be shown</Typography>
                      {/* </Stack> */}
                    </Stack>
                  </>
                ) : null}
                <Stack
                      direction={"column"}
                      spacing={6}
                      sx={{
                        boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        minHeight: 100,
                        px: 20,
                        py: 6,
                      }}
                    >  
                {posts?.length > 0 &&
                  posts.map((post, i) => (
                    <PostItem
                      data={post}
                      key={i}
                      callback={(data, image) => {
                        if (data == "deleted") {
                          deletedPost();
                        } else {
                          setPostData(data);
                          setTags([...data?.tags]);
                          setImage(data?.image);
                          setVideo(data?.video);
                          setText(data?.text);
                          setOtherFiles(data?.otherFiles);
                          setTags(data?.tags);
                          setFile(data?.metadata);
                          console.log("image is ", image);

                          if (data?.type !== "" && data?.type !== "file") {
                            getDownloadURL(
                              ref(
                                storage,
                                data?.type === "image"
                                  ? data?.image
                                  : data?.video
                              )
                            )
                              .then((url) => {
                                data?.type === "image"
                                  ? setURL(url)
                                  : setVideo(url);
                              })
                              .catch((error) => {
                                // Handle any errors
                              });
                          }
                          setOpen(true);
                        }
                      }}
                    />
                  ))}
                     
                </Stack>
              </Stack>
            </Stack>
          </TabPanel>

          {profile && profile?.userType === "startup" && value === 0 && (
            <Stack
              direction={"column"}
              spacing={3}
              sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                px: 5.5,
                py: 6,
              }}
            >
              <StartupTopPriorities profile={profile} />
            </Stack>
          )}
        </Stack>
        <Portal>
          <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
            open={posted}
          >
            <DialogTitle>
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
                sx={{ pt: 0, pb: 0, px: 0 }}
              >
                <Typography sx={{ fontWeight: "500" }}>
                  {updated
                    ? arr2[(Math.random() * arr.length) | 0]
                    : arr[(Math.random() * arr.length) | 0]}
                </Typography>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Stack
                direction={"column"}
                justifyContent={"centre"}
                alignItems={"center"}
                sx={{ pt: 7 }}
              >
                <img src="/assets/post_success.png" alt="none" width={"156"} />
              </Stack>

              <Typography
                type={"h3"}
                textAlign={"center"}
                sx={{ fontWeight: "600", pl: 10, pr: 10 }}
              >
                {updated
                  ? "Thanks for that edit! We’re glad you took the time to improve the post even more."
                  : "Thank you for taking the time to share your thoughts and ideas with us."}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Stack sx={{ pt: 7 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    setPosted(false);
                    setUpdated(false);
                  }}
                >
                  Continue
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog
            open={open}
            onClose={() => {
              setFile();
              setOpen(false);
              resetAll();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
          >
            <DialogTitle id="alert-dialog-title">
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
                sx={{ pt: 0, pb: 0, px: 0 }}
              >
                <Avatar
                  sx={{ height: 70, width: 70 }}
                  imgProps={{ sx: { objectFit: "cover" } }}
                  src={profile?.photoURL || profile?.startup?.founder?.photoURL}
                >
                  <Typography variant={"h3"}>
                    {profile.userType === "startup"
                      ? profile?.startup?.founder?.founderName?.charAt(0) ||
                        profile?.displayName?.charAt(0)
                      : profile?.displayName?.charAt(0)}
                  </Typography>
                </Avatar>
                <List sx={{ width: "100%", py: 0, my: 0 }}>
                  <ListItem alignItems="flex-start" sx={{ pb: 2 }}>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body1"
                            color="text.primary"
                          >
                            {" "}
                            {profile.userType === "startup"
                              ? profile?.startup?.founder?.founderName ||
                                profile?.displayName
                              : profile?.displayName}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
                <Stack
                  direction={"column"}
                  sx={{ height: 83 }}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => {
                      setFile();
                      setTag("");
                      setTags([]);
                      setText("");
                      setURL("");
                      setOpen(false);
                      resetAll();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <TextField
                variant={"standard"}
                fullWidth
                rows={8}
                multiline={true}
                onChange={(e) => setText(e.target.value)}
                sx={{
                  minWidth: 500,
                  borderBottom: "none !important",
                }}
                placeholder={"What do you want to talk about?"}
                defaultValue={postData && postData.text}
              />

              {/*<InputBase
              sx={{ ml: 1, width: 600 ,color:'#051E40BF',  }}
              placeholder="What do you want to talk about?"
              inputProps={{ 'aria-label': 'search post' }}
            />*/}
              <Stack direction={"row"} sx={{ pt: 3 }} spacing={3}>
                {file && file?.name && file?.type.includes("image") ? (
                  <Stack direction={"row"}>
                    <img
                      alt="none"
                      src={URL}
                      style={{ width: "100%", height: "100%" }}
                    />

                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        width: 20,
                        height: 20,
                        ml: -4,
                        mt: 1,
                        mr: 1,
                        padding: 1.5,
                        color: "black",
                      }}
                      onClick={() => {
                        setFile();
                        setURL("");
                        resetAll();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                ) : file?.type.includes("video") ? (
                  <Stack direction={"row"}>
                    <CardMedia component="video" image={URL} controls />
                    {/* <video
										controls
										src={URL}
										width="500"
										height="250"
									/> */}
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        width: 20,
                        height: 20,
                        ml: -4,
                        mt: 1,
                        mr: 1,
                        padding: 1.5,
                      }}
                      onClick={() => {
                        setFile();
                        setURL("");
                        resetAll();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                ) : (
                  <></>
                )}{" "}
                {loading && <CircularProgress size={20} />}
              </Stack>
              <br></br>
              <div style={{ width: "100%" }}>
                {tags.length > 0 &&
                  tags.map((option, index) => (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginBottom: "10px",
                      }}
                      onDelete={() =>
                        setTags(
                          tags.filter((f) =>
                            f !== option.value ? option.value : option
                          )
                        )
                      }
                      label={option.value ? option.value : option}
                    />
                  ))}

                <InputBase
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      tag !== "" &&
                      !tags.find((tg) => tg === tag)
                    ) {
                      setTags([...tags, `#${tag}`]);
                      setTag("");
                    } else if (
                      e.key === " " &&
                      tag !== "" &&
                      !tags.find((tg) => tg === tag)
                    ) {
                      setTags([...tags, `#${tag}`]);
                      setTag("");
                    }
                  }}
                  size="medium"
                  placeholder={tags.length > 0 ? null : "Add hashtag"}
                  sx={{
                    transition: "0.1s linear",
                    border: "none!important",
                    outline: "none!important",
                    outlineColor: "#fff",
                    width: "100%",
                  }}
                />

                {otherFiles !== "" && file && (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography variant={"subtitle1"}>{file?.name}</Typography>
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        width: 20,
                        height: 20,
                        ml: -4,
                        mt: 1,
                        mr: 1,
                        padding: 1.5,
                      }}
                      onClick={() => {
                        setFile();
                        setURL("");
                        resetAll();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                )}
              </div>
            </DialogContent>
            <DialogActions sx={{ px: 2, py: 2 }}>
              <label htmlFor={"attachment-media"}>
                <Input
                  id={"attachment-media"}
                  type={"file"}
                  onClick={(e) => (e.target.value = null)}
                  accept={"image/*"}
                  // accept={".jpg,.jpeg,.png,.gif,.webp"}
                  onChange={async (e) => {
                    const list = [];
                    setLoading(true);
                    for (let file of e.target.files) {
                      console.log(file);
                      setFile(file);
                      const url = await UploadFile(
                        file,
                        `timeline/${profile?.uid}/${file?.name}`
                      );
                      const image = new Image();
                      image.src = url;
                      console.log(image);
                      image.onload = function () {
                        console.log(this.width);
                        console.log(this.height);
                        setImageDimensions({
                          width:
                            this.width > 500
                              ? this.width / 2 < 300
                                ? 300
                                : 500
                              : this.width,
                          height:
                            this.height > 250
                              ? this.height / 2 < 125
                                ? 125
                                : 250
                              : this.height,
                        });
                      };
                      // console.log(URL);
                      setURL(url);
                      setLoading(false);
                      console.log(file);
                      list.push(`timeline/${profile?.uid}/${file?.name}`);
                    }
                    setImage(list[0]);
                    console.log(imageDimensions);
                  }}
                />
                <IconButton component={"span"}>
                  <img src={"/assets/Vector (3).png"} />
                </IconButton>
              </label>

              <label htmlFor={"attachment-video"}>
                <Input
                  id={"attachment-video"}
                  type={"file"}
                  accept={"video/*"}
                  // accept={".mov,.mp4"}
                  onClick={(e) => (e.target.value = null)}
                  onChange={async (e) => {
                    const list = [];
                    setLoading(true);
                    for (let file of e.target.files) {
                      setFile(file);
                      const url = await UploadFile(
                        file,
                        `timeline/${profile?.uid}/${file?.name}`
                      );
                      setURL(url);
                      setLoading(false);
                      console.log(file);

                      list.push(`timeline/${profile?.uid}/${file?.name}`);
                    }

                    setVideo(list[0]);
                  }}
                />
                <IconButton component={"span"}>
                  <img src={"/assets/Vector (4).png"} />
                </IconButton>
              </label>

              <label htmlFor={"attachment-files"}>
                <Input
                  id={"attachment-files"}
                  accept={".pdf, .doc, .docx, .csv"}
                  type={"file"}
                  onClick={(e) => (e.target.value = null)}
                  onChange={async (e) => {
                    const list = [];
                    setLoading(true);
                    for (let file of e.target.files) {
                      setFile(file);
                      const url = await UploadFile(
                        file,
                        `timeline/${profile?.uid}/${file?.name}`
                      );
                      setURL(url);
                      setLoading(false);
                      console.log(file);

                      list.push(`timeline/${profile?.uid}/${file?.name}`);
                    }

                    setOtherFiles(list[0]);
                  }}
                />
                <IconButton component={"span"}>
                  <AttachFileIcon />
                </IconButton>
              </label>
              {/* <IconButton>
							<img src={"/assets/Group 1193.png"} />
						</IconButton> */}
              <Tooltip
                title={<h3 style={{ color: "#E4EAF5" }}>Coming soon</h3>}
                placement="bottom"
              >
                <IconButton onClick={() => {}}>
                  <SentimentSatisfiedAltIcon />
                </IconButton>
              </Tooltip>

              {/* {state?	<Picker onEmojiClick={(emojiData, event)=> setChosenEmoji(emojiData) && setState(false)} disableAutoFocus={true} native />:null}
						{chosenEmoji? <p>Symbol: {chosenEmoji.emoji}</p>:null} */}
              <Box sx={{ flex: 1 }} />
              <Button
                variant={"contained"}
                onClick={async () => {
                  if (!postData) {
                    await addDoc(collection(DB, "timelines"), {
                      current: { likes: 0, comments: 0 },
                      previous: { likes: 0, comments: 0 },
                      text: text,
                      uid: profile?.uid,
                      creatorData: profile,
                      createdAt: serverTimestamp(),
                      type:
                        image !== ""
                          ? "image"
                          : video !== ""
                          ? "video"
                          : otherFiles !== ""
                          ? "file"
                          : "",
                      metadata: {
                        name:
                          image !== "" || video !== "" || otherFiles !== ""
                            ? file?.name
                            : "",
                        type:
                          image !== "" || video !== "" || otherFiles !== ""
                            ? file?.type
                            : "",
                      },
                      image,
                      video,
                      otherFiles,
                      tags: tags,
                      // chosenEmoji:chosenEmoji.emoji
                    });
                  } else {
                    await updateDoc(
                      doc(collection(DB, "timelines"), postData.id),
                      {
                        current: { likes: 0, comments: 0 },
                        previous: { likes: 0, comments: 0 },
                        text: text,
                        uid: profile?.uid,
                        creatorData: profile,
                        updatedAt: serverTimestamp(),
                        type:
                          image !== ""
                            ? "image"
                            : video !== ""
                            ? "video"
                            : otherFiles !== ""
                            ? "file"
                            : "",
                        metadata: {
                          name:
                            image !== "" || video !== "" || otherFiles !== ""
                              ? file?.name
                              : "",
                          type:
                            image !== "" || video !== "" || otherFiles !== ""
                              ? file?.type
                              : "",
                        },
                        image,
                        video,
                        otherFiles,
                        tags: tags,
                        // chosenEmoji:chosenEmoji.emoji
                      },
                      { merge: true }
                    );
                    setUpdated(true);
                  }
                  setImage("");
                  setVideo("");
                  setText("");
                  setTags([]);
                  setFile();
                  setPosted(true);
                  setOpen(false);
                  resetAll();
                }}
                disabled={text === "" && image == "" && video === ""}
              >
                {postData ? "UPDATE" : "POST"}
              </Button>
            </DialogActions>
          </Dialog>
        </Portal>
      </Container>
    );
  } else {
    return (
      <>
        {profile?.userType !== "investor" &&
          profile[
            profile?.userType === "service-provider"
              ? "serviceProvider"
              : profile?.userType === "business-developer"
              ? "businessDeveloper"
              : profile?.userType
          ] &&
          profile[
            profile?.userType === "service-provider"
              ? "serviceProvider"
              : profile?.userType === "business-developer"
              ? "businessDeveloper"
              : profile?.userType
          ].investorDetails.portfolio &&
          profile[
            profile?.userType === "service-provider"
              ? "serviceProvider"
              : profile?.userType === "business-developer"
              ? "businessDeveloper"
              : profile?.userType
          ].investorDetails?.portfolio?.length > 0 &&
          profile[
            profile?.userType === "service-provider"
              ? "serviceProvider"
              : profile?.userType === "business-developer"
              ? "businessDeveloper"
              : profile?.userType
          ].investorDetails?.portfolio?.map((p, i) =>
            i === key ? (
              <InvestmentPortfolioView profile={profile} i={i} />
            ) : null
          )}
        {profile?.userType === "investor" &&
          profile?.investor &&
          profile?.investor.portfolio &&
          profile?.investor?.portfolio?.length > 0 &&
          profile?.investor.portfolio.map((p, i) =>
            i === key ? (
              <InvestmentPortfolioView profile={profile} i={i} />
            ) : null
          )}

        {profile[
          profile?.userType === "service-provider"
            ? "serviceProvider"
            : profile?.userType === "business-developer"
            ? "businessDeveloper"
            : profile?.userType
        ] &&
          profile[
            profile?.userType === "service-provider"
              ? "serviceProvider"
              : profile?.userType === "business-developer"
              ? "businessDeveloper"
              : profile?.userType
          ].experiences &&
          profile[
            profile?.userType === "service-provider"
              ? "serviceProvider"
              : profile?.userType === "business-developer"
              ? "businessDeveloper"
              : profile?.userType
          ].experiences?.length > 0 &&
          profile[
            profile?.userType === "service-provider"
              ? "serviceProvider"
              : profile?.userType === "business-developer"
              ? "businessDeveloper"
              : profile?.userType
          ].experiences.map((p, i) =>
            i === k ? <WorkExperienceView profile={profile} i={i} /> : null
          )}
        {/*
		profile[profile?.userType === 'service-provider' ? 'serviceProvider'
											: profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]
		*/}
      </>
    );
  }
}
