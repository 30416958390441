import React from "react";
import {
  Container,
  Typography,
  Stack,
  Button,
  Avatar,
  styled,
  TextField, Portal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress
} from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps, TabPanel } from "../../../screens/mobile/components/common/tabs-custom";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  getDoc,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";

import { useNavigate, useParams } from "react-router-dom";
import StartChat from "../../../components/common/start-chat";
import ServiceProviderDescription from "../../../screens/mobile/components/common/profile/service-provider-description";
import ServiceProviderAbout from "../../../screens/mobile/components/common/profile/service-provider-about";
import ServiceProviderPortfolio from '../../../screens/mobile/components/common/profile/service-provider-portfolio'
import ServiceProviderWorkExperience from '../../../screens/mobile/components/common/profile/service-provider-work-experience'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getAuth, getIdToken } from "firebase/auth";
import DeleteUserApi from "../../../api/get-users";
import { AuthUserContext } from "../../../providers/auth-provider";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import TalentAbout from "../components/common/profile/talent-about";
import TalentDescription from "../components/common/profile/talent-description";
import CommonInvestorDetails from "../components/common/profile/common-investor-details";

import WorkExperienceView from '../../../screens/mobile/components/common/profile/work-experience-view';
import InvestmentPortfolioView from "../../../screens/mobile/components/common/profile/investor-portfolio-view";
import { fontWeight } from "@mui/system";
import StartupAbout from "../components/common/profile/startup-about";
import StartupDescription from "../components/common/profile/startup-description";
import StartupTeam from "../components/common/profile/startup-team";
import StartupTopPriorities from "../components/common/profile/startup-top-priorities";
import InvestmentPortfolio from "../components/common/profile/investment-portfolio";
import ExpertWorkExperience from "../components/common/profile/expert-work-experience";
import BusinessDeveloperWorkExperience from "../components/common/profile/business-developer-work-experience";
import InvestorAbout from '../components/common/profile/investor-about'
import BusinessDeveloperAbout from '../components/common/profile/business-developer-about'
import ExpertAbout from "../components/common/profile/expert-about";
import ExpertDescription from '../components/common/profile/expert-description'
import BusinessDeveloperDescription from "../components/common/profile/business-developer-description";
import InvestorDescription from "../components/common/profile/investor-description";
import ReferralAvatar from "../../../screens/mobile/components/common/profile/referal-avatar";
import { USER_TYPES } from "../../../utils/enums/userTypes.enum";
import { BUSINESS_DEVELOPER, USER_TYPES_TO_NAME } from "../../../utils/enums/labels.enum";
import InvestorPortfolio from "../components/common/profile/investor-portfolio";
import PostItem from "../../../components/common/timeline/post-item";
// import InvestmentPortfolio from "../components/common/profile/investment-portfolio";

const StyledTabs = styled((props) => (
  <Tabs
    sx={{ left: 50 }}
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "none",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    borderRadius: "30px",
    color: "#000",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#233DFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
export default function CommonDetails() {
  const userContext = React.useContext(AuthUserContext);
  const [val, setVal] = React.useState(false)

  const { uid } = useParams();
  const [removeAdminLoading, setLoadingRemove] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [key, setKey] = React.useState();
  const [k, setK] = React.useState();
  const [index, setIndex] = React.useState(-1);
  const [profile, setProfile] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [posts, setPosts] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [start, setStart] = React.useState(false)

  const navigator = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const deleteUser = async () => {
    try {
      setLoadingRemove(true)
      const auth = getAuth();
      const idToken = await getIdToken(auth.currentUser);
      // console.log(idToken, 'ID - TOKEn')
      const data = await DeleteUserApi(profile, idToken);
      setLoadingRemove(false)

      await setOpen(!open)

      navigator('/admin/dashboard');
    } catch (e) {
      setLoadingRemove(false)

      debugger
    }
  }
  React.useEffect(() => {
    // console.log(userContext && userContext.profile);
    if (uid) {
      const userRef = doc(DB, "users", uid);
      getDoc(userRef).then((doc) => {
        setProfile({ ...doc?.data(), id: uid, uid });
      });
      const q = query(collection(DB, "timelines"), where("uid", "==", uid));

      getDocs(q).then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          const list = [];
          querySnapshot.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });

            // console.log(doc.id, " => ", doc.data());
          });
          setPosts(list);
        }
      });
    }
  }, [uid]);
  if (index === -1) {
    return (
      <Container maxWidth={"lg"} sx={{ textAlign: "left" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" >
            <IconButton onClick={() => navigator(-1)}>
              <ArrowBackIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </Stack>


        </Stack>
        <br></br>
        {/*<Stack direction={'row'} spacing={5} sx={{width: '100%', mb: 5}}>
        <div style={{flex: 1}}>
          <Typography variant={'h4'} sx={{fontWeight: 700}}>Hello {profile?.displayName} &nbsp; 👋</Typography>
          <Typography variant={"body1"} color={'secondary'}>Welcome to NOVA</Typography>
        </div>
      </Stack>*/}

        {profile && (
          <Stack direction={"column"} spacing={3}>
            <Stack
              direction={"column"}
              spacing={0}
              sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                p: 2,
                pb: 0,
              }}
            >
              <Box
                sx={{
                  height: 100,
                  width: "100%",
                  backgroundColor: "#F6F8FA",
                  borderRadius: "15px",
                }}
              />


              {/* Startup Picture */}

              <Box
                sx={{
                  border: "1px solid #204488",
                  p: 0.75,
                  borderRadius: "50%",
                  width: "fit-content",
                  position: "relative",
                  left: 16,
                  top: -50,
                }}
              >
                <Avatar
                  alt={
                    profile?.userType === "startup"
                      ? profile?.startup?.startupName
                      : profile?.displayName
                  }
                  src={
                    profile?.userType === "startup"
                      ? profile?.startup?.logoURL
                      : profile?.photoURL
                  }
                  sx={{ width: 68, height: 68 }}
                >
                  <Typography variant={"h6"}>
                    {profile?.userType === "startup"
                      ? profile?.startup?.startupName &&
                      profile?.startup?.startupName.charAt(0)
                      : profile?.displayName && profile?.displayName.charAt(0)}
                  </Typography>
                </Avatar>
              </Box>


              <Stack
                direction={"row"}
                spacing={2}
                sx={{ position: "relative", top: -40 }}
                alignItems={"flex-start"}
              >
                <div />
                <Stack direction={"column"} spacing={0.5}>
                  <Stack direction={"row"} spacing={1} sx={{ width: '100%' }}>

                    {/* Startup Name */}

                    <Typography sx={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '20px',
                      marginTop: "2.5px",
                      width: "200px",
                      overflow: "hidden"

                    }}>
                      {profile?.userType === "startup"
                        ? profile?.startup?.startupName
                        : profile?.displayName}
                    </Typography>
                    {profile?.uid !== userContext.user?.uid && profile?.userType !== "startup" && <StartChat
                      sx={{
                        color: 'white', backgroundColor: "#233DFF",
                        display: 'grid',
                        justifyContent: 'right',
                        position: 'absolute',
                        right: 0
                      }}
                      data={{ ...profile, creatorData: profile }} props={{ ...a11yProps(4) }} />
                    }
                  </Stack>
                  <Stack direction={"row"} spacing={1} >
                    <Box
                      sx={{
                        backgroundColor: "#E0E3FF",
                        px: 1.5,
                        py: 1,
                        borderRadius: "19.5px",
                        width: "fit-content",
                        ml: -1,
                        height: "30px"
                      }}
                    >

                      <Typography
                        style={{ fontSize: "10px", fontWeight: 400, textTransform: "capitalize" }}
                      >
                        {USER_TYPES_TO_NAME[profile?.userType]}
                      </Typography>
                    </Box>



                    {
                      profile?.userType !== 'startup' && profile[profile?.userType === 'service-provider' ? 'serviceProvider'
                        : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType]?.investorDetails?.areYouAnAngelInvestor &&

                      <Box
                        sx={{
                          backgroundColor: "#FFE0CA",

                          px: 1.5,
                          py: 1,
                          borderRadius: "19.5px",
                          width: "fit-content",
                          ml: -1,
                          height: "30px"

                        }}>
                        <Typography

                          style={{ fontSize: "10px", fontWeight: 400, textTransform: "capitalize" }}
                        >
                          {"Investor"}
                        </Typography>
                      </Box>
                    }
                    <Box sx={{ maxWidth: { xs: 320, sm: 480 }, marginTop: '5px', marginLeft: "-15px" }}>
                      <StyledTabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="styled tabs example"

                      >

                      </StyledTabs>
                    </Box>



                    <span style={{ flex: 1 }} />
                    {userContext.profile.userType === 'admin' && <Button variant={'outlined'} color={'warning'} onClick={() => setOpen(!open)}>Delete user</Button>}
                  </Stack>
                  {profile?.userType === "investor" && (
                    <InvestorDescription profile={profile} />
                  )}
                  {profile?.userType === "expert" && (
                    <ExpertDescription profile={profile} />
                  )}
                  {profile?.userType === "business-developer" && (
                    <BusinessDeveloperDescription profile={profile} />
                  )}
                  {profile?.userType === "service-provider" && (
                    <ServiceProviderDescription profile={profile} />
                  )}
                  {profile?.userType === "startup" && (
                    <StartupDescription profile={profile} />
                  )}
                  {profile?.userType === "talent" && (
                    <TalentDescription profile={profile} />
                  )}
                </Stack>

                {/* Side Founder part */}


                {/* <div style={{ flex: 1 }} />
                {profile?.userType === "startup" &&
                  profile?.startup?.founder && (
                    <Stack
                      direction={"column"}
                      spacing={1}
                      justifyContent="center"
                      sx={{ position: "relative", right: 15, minWidth: 120, cursor: 'pointer' }}
                      alignItems="center"

                      onClick={() =>
                      // setVal(true)
                        navigator(
                          profile && profile.userType === 'admin'
                            ? `/admin/${profile?.userType}/startup-founder-profile`
                            : userContext && userContext.profile &&  userContext.profile.uid === profile.uid
                            ? `/${profile?.userType}/startup-founder-profile`
                            : `/${userContext?.profile?.userType}/startup-founder-profile`,

                          {
                            state: 
                            {
                              profile,
                              founder: {
                                ...profile?.startup.founder,
                                linkedIn: profile?.startup.founder?.linkedIn,
                                website: profile?.startup.founder?.website,
                                twitter:profile?.startup.founder?.twitter,
                                repeatFounder:profile?.startup.founder?.repeatFounder,

                                startupName:
                                  profile?.startup.founder?.startupName !== ""
                                    ? profile?.startup.founder?.startupName
                                    : profile?.startup?.startupName,
                                photoURL:
                                  profile?.startup.founder.photoURL !== ""
                                    ? profile?.startup.founder.photoURL
                                    : profile?.photoURL,
                              },
                            },
                          }
                        )
                      }
                    >
                      <Box
                        sx={{
                          border: "1px solid #204488",
                          p: 0.75,
                          borderRadius: "50%",
                          width: "fit-content",
                        }}
                      >
                        <Avatar
                          alt={profile?.startup?.founder.founderName}
                          src={
                            profile?.startup?.founder.photoURL !== ""
                              ? profile?.startup?.founder.photoURL
                              : profile?.photoURL
                          }
                          sx={{ width: 51, height: 51 }}
                        >
                          <Typography variant={"h4"} >
                            {profile?.startup?.founder.founderName.charAt(0)}
                          </Typography>
                        </Avatar>
                      </Box>
                      <Typography variant={"h6"} sx={{ textAlign: "center" }}>
                        {profile?.startup?.founder.founderName}
                        <br />
                        <font
                          size={2}
                          style={{
                            backgroundColor: "#F6F8FA",
                            padding: "5px 10px",
                            borderRadius: "6px",
                            fontWeight: 300,
                          }}
                        >
                          Founder
                        </font>
                      </Typography>
                    </Stack>
                  )} */}
              </Stack>

              <Box sx={{ flex: 1 }} />

              {profile?.userType === "service-provider" &&
                profile?.serviceProvider?.organisationType === "Organisation" && profile?.serviceProvider?.organisationDetails && (
                  <Stack
                    direction={"column"}
                    spacing={1}
                    justifyContent="center"
                    sx={{
                      position: "relative",
                      left: 15,
                      minWidth: 120,
                      cursor: 'pointer'
                    }}
                    alignItems="start">
                    <Box
                      sx={{
                        border: "1px solid #204488",
                        p: 0.75,
                        borderRadius: "50%",
                        width: "fit-content",
                      }}
                      onClick={() =>
                        navigator(
                          `/m/${userContext?.profile?.userType}/organisation-profile`,
                          {
                            state: {
                              profile,
                              organisation: {
                                ...profile?.serviceProvider
                                  .organisationDetails,
                                nameOfOrganisation:
                                  profile?.serviceProvider
                                    .organisationDetails
                                    ?.nameOfOrganization !==
                                    ""
                                    ? profile
                                      ?.serviceProvider
                                      .organisation
                                      ?.nameOfOrganization
                                    : profile
                                      ?.serviceProvider
                                      ?.organisationDetails
                                      ?.nameOfOrganization,
                                logoURL:
                                  profile?.serviceProvider
                                    .organisationDetails
                                    .logoURL !==
                                    ""
                                    ? profile
                                      ?.serviceProvider
                                      .organisationDetails
                                      .logoURL
                                    : '',
                              },
                            },
                          }
                        )
                      }>
                      <Avatar
                        alt={
                          profile?.serviceProvider?.organisationDetails
                            .nameOfOrganization
                        }
                        src={profile.userType === 'service-provider' && profile?.serviceProvider?.organisationDetails?.logoURL !== '' ? profile?.serviceProvider?.organisationDetails?.logoURL : ''}
                        sx={{ width: 48, height: 48 }}>
                        <Typography variant={"h4"}>
                          {profile?.serviceProvider?.organisationDetails.nameOfOrganization.charAt(
                            0
                          )}
                        </Typography>
                      </Avatar>
                    </Box>
                    <Typography
                      variant={"h6"}
                    // sx={{ textAlign: "center" }}
                    >
                      {profile?.serviceProvider?.organisationDetails.nameOfOrganization}
                    </Typography>
                    <Typography variant={'body2'}>

                      <font
                        size={2}
                        style={{
                          backgroundColor: "#F6F8FA",
                          padding: "5px 10px",
                          borderRadius: "6px",
                          fontWeight: 300,
                        }}>
                        Organisation
                      </font>
                    </Typography>
                  </Stack>
                )
              }

              {profile?.userType === 'investor' && profile?.investor?.investorType !== 'Angel Investor' &&


                <Stack direction={"row"} spacing={1} justifyContent="flex-start" alignItems="center"
                  sx={{ position: "relative", minWidth: 120, cursor: 'pointer', left: 20 }}

                  onClick={() => {
                    const path = `/m/${userContext?.profile?.userType}/common-organisation-profile`
                    navigator(path, {
                      state: {
                        profile: profile,
                        organisation: {
                          organizationDescription: profile?.investor?.organizationDescription,
                          investorType: profile?.investor?.investorType,
                          website: profile?.investor?.website,
                          nameOfOrganization: profile?.investor?.nameOfOrganization,
                          logoURL:
                            profile?.investor?.logoURL,
                        },
                      }
                    });

                  }}>
                  <Avatar sx={{ width: 57, height: 57, border: '1px solid #f5f5f5' }} src={profile?.investor?.logoURL} alt={'profile-pic'} imgProps={{ style: { objectFit: 'contain', backgroundColor: '#fff' } }}>
                    <Typography variant={"h3"}>{profile?.investor?.nameOfOrganization}</Typography>
                  </Avatar>
                  <Stack direction="column">
                    <Typography variant={"h6"}
                    >
                      <font

                      >
                        {profile?.investor?.nameOfOrganization}
                      </font>
                    </Typography>

                    <Typography variant={"body2"}
                    >
                      <font
                        size={2}
                        style={{
                          backgroundColor: "#F6F8FA",
                          padding: "5px 10px",
                          borderRadius: "6px",
                          fontWeight: 300,
                        }}
                      >Organization</font></Typography>
                  </Stack>
                </Stack>
              }
              {profile?.userType === "startup" &&
                profile?.startup?.founder && (
                  <Stack style={{ marginLeft: "15px" }}
                    direction={"row"} spacing={1} justifyContent="flex-start" alignItems="center"
                    sx={{ minWidth: 120, cursor: 'pointer' }}


                    onClick={() =>
                      // setVal(true)
                      navigator(
                        profile && profile.userType === 'admin'
                          ? `/admin/${profile?.userType}/startup-founder-profile`
                          : userContext && userContext.profile && userContext.profile.uid === profile.uid
                            ? `/m/${profile?.userType}/startup-founder-profile`
                            : `/m/${userContext?.profile?.userType}/startup-founder-profile`,

                        {
                          state:
                          {
                            profile,
                            founder: {
                              ...profile?.startup.founder,
                              linkedIn: profile?.startup.founder?.linkedIn,
                              website: profile?.startup.founder?.website,
                              twitter: profile?.startup.founder?.twitter,
                              repeatFounder: profile?.startup.founder?.repeatFounder,

                              startupName:
                                profile?.startup.founder?.startupName !== ""
                                  ? profile?.startup.founder?.startupName
                                  : profile?.startup?.startupName,
                              photoURL:
                                profile?.startup.founder.photoURL !== ""
                                  ? profile?.startup.founder.photoURL
                                  : profile?.photoURL,
                            },
                          },
                        }
                      )
                    }
                  >

                    <Box
                      sx={{
                        border: "1px solid #204488",
                        p: 0.75,
                        borderRadius: "50%",
                        width: "fit-content",
                      }}
                    >
                      <Avatar
                        alt={profile?.startup?.founder.founderName}
                        src={
                          profile?.startup?.founder.photoURL !== ""
                            ? profile?.startup?.founder.photoURL
                            : profile?.photoURL
                        }
                        sx={{ width: 48, height: 48 }}
                      >
                        <Typography variant={"h4"}>
                          {profile?.startup?.founder.founderName.charAt(0)}
                        </Typography>
                      </Avatar>

                    </Box>
                    <Stack direction={'column'}>
                      <Typography variant={"h6"}
                      // sx={{ textAlign: "center"}}
                      >
                        {profile?.startup?.founder.founderName}
                      </Typography>

                      <Typography variant={"body2"}>
                        <font
                          size={2}
                          style={{
                            backgroundColor: "#F6F8FA",
                            padding: "5px 10px",
                            borderRadius: "6px",
                            fontWeight: 300,
                          }}
                        >
                          Founder
                        </font>
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              <ReferralAvatar profile={profile} />
              <br></br>

              <Box sx={{ marginTop: '5px', marginLeft: "4px" }}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="styled tabs example"
                
                >
                  <StyledTab sx={{
                    fontSize: '13px',
                  }} label="ABOUT" {...a11yProps(0)} />
                  {profile?.userType === "startup" && (
                    <StyledTab label={"TEAM"} {...a11yProps(1)} />
                  )}
                  {profile?.userType !== "investor" && profile?.userType !== "startup" && (
                    <StyledTab sx={{
                      fontSize: '13px',
                    }} label={"EXPERIENCE"} {...a11yProps(1)} />
                  )}
                  {
                    (profile?.userType === "expert"
                      && profile?.expert?.investorDetails?.areYouAnAngelInvestor &&
                      profile?.expert.investorDetails.areYouAnAngelInvestor === true) ?
                      (<StyledTab sx={{
                        fontSize: '13px',
                      }} label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />) :


                      (profile?.userType === "business-developer"
                        && profile?.businessDeveloper?.investorDetails?.areYouAnAngelInvestor &&
                        profile?.businessDeveloper.investorDetails.areYouAnAngelInvestor === true) ?
                        (<StyledTab sx={{
                          fontSize: '13px',
                        }} label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />) :

                        (profile?.userType === "service-provider"
                          && profile?.serviceProvider?.investorDetails?.areYouAnAngelInvestor &&
                          profile?.serviceProvider.investorDetails.areYouAnAngelInvestor === true) ?
                          (<StyledTab sx={{
                            fontSize: '13px',
                          }} label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />) :

                          (profile?.userType === "investor") ?
                            (<StyledTab sx={{
                              fontSize: '13px',
                            }} label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />) :
                            null

                  }

                  {
                    profile?.userType !== "startup" &&
                    <StyledTab sx={{
                      fontSize: '13px',
                    }} label="POST" {...a11yProps(profile?.userType !== 'investor' ? 3 : 2)} />
                  }

                  {/* <StartChat data={{...profile, creatorData:profile }} props={{...a11yProps(3)}}/> */}


                  {/* {profile?.uid  !== userContext.user?.uid && profile?.userType !== "startup" &&   <StartChat
                  sx={{marginLeft: 'auto' , marginRight: '5%',color:'white',backgroundColor: "#233DFF"}}
                  data={{...profile, creatorData:profile }} props={{...a11yProps(4)}}/>
                } */}


                </StyledTabs>
              </Box>
              {/* <div style={{ flex: 10}} /> */}




              <Box sx={{ height: 20 }} />
            </Stack>

            <TabPanel index={0} value={value}>
              {profile?.userType === "investor" ? (
                <InvestorAbout profile={profile} />
              ) : profile?.userType === "expert" ? (
                <ExpertAbout profile={profile} />
              ) : profile?.userType === "business-developer" ? (
                <BusinessDeveloperAbout profile={profile} />
              ) : profile?.userType === "service-provider" ? (
                <ServiceProviderAbout profile={profile} />
              ) : profile?.userType === "startup" ? (
                <StartupAbout profile={profile} />
              ) : profile?.userType === "talent" ? (
                <TalentAbout profile={profile} />
              ) : (
                ""
              )}

            </TabPanel>

            {profile?.userType !== 'investor' &&
              profile?.userType !== 'startup' &&
              profile[profile?.userType === 'service-provider' ? 'serviceProvider'
                : profile?.userType === 'business-developer' ? 'businessDeveloper'
                  : profile?.userType]?.investorDetails?.areYouAnAngelInvestor
              ? <TabPanel index={0} value={value}>
                <Stack
                  direction={"column"}
                  spacing={3}
                  sx={{
                    boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                    borderRadius: "15px",
                    backgroundColor: "#fff",
                    minHeight: 100,
                    px: 5.5,
                    py: 6,
                  }}>

                  <CommonInvestorDetails profile={profile} />

                </Stack>
              </TabPanel>
              : null
            }

            <TabPanel index={profile?.userType === "startup" ? 1 : null} value={value}>
              <Stack
                direction={"column"}
                spacing={3}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}
              >
                {profile?.userType === "startup" ? (
                  <StartupTeam profile={profile} />
                ) : null
                }
              </Stack>
            </TabPanel>

            <TabPanel
              index={profile?.userType === 'investor' ? 1 : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
                : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType]?.investorDetails?.areYouAnAngelInvestor ? 2 : -1}
              value={value}
            >
              <Stack
                direction={"column"}
                spacing={6}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}
              >
                <InvestorPortfolio profile={profile} setIndex={setIndex} setKey={setKey} />
              </Stack>
            </TabPanel>
            {profile?.userType !== 'investor' && profile?.userType !== 'startup' ?
              <TabPanel
                index={1}
                value={value}
              >
                <Stack
                  direction={"column"}
                  spacing={6}
                  sx={{
                    boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                    borderRadius: "15px",
                    backgroundColor: "#fff",
                    minHeight: 100,
                    px: 5.5,
                    py: 6,
                  }}
                >
                  {profile?.userType === "expert" ? (
                    <ExpertWorkExperience profile={profile} setIndex={setIndex} setK={setK} />
                  ) : profile?.userType === "business-developer" ? (
                    <BusinessDeveloperWorkExperience profile={profile} setIndex={setIndex} setK={setK} />
                  ) : profile?.userType === "service-provider" ? (
                    <ServiceProviderWorkExperience profile={profile} setIndex={setIndex} setK={setK} />
                  ) : null
                  }

                </Stack>
              </TabPanel> :
              null}
            {console.log(profile)}
            <TabPanel
              index={profile?.userType === 'investor' ? 2 : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
                : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType]?.investorDetails?.areYouAnAngelInvestor ? 3 : 2}
              value={value}
            >
              <Stack >
                <Stack direction={"column"} spacing={5}>
                  {posts.length === 0 ? <>
                    <Stack
                      direction={"column"}
                      spacing={6}
                      sx={{
                        boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        minHeight: 100,
                        px: 0,
                        py: 0,
                        textAlign: "center"

                      }}
                    >

                      {/* <Stack direction="row" justifyContent="center"> */}

                      <Typography>There are no posts to be shown</Typography>
                      {/* </Stack> */}
                    </Stack>
                  </> : null
                  }
                  {posts.length > 0 &&
                    posts.map((post, i) => <PostItem mobile={true}  data={post} key={i} />)}
                </Stack>
              </Stack>
            </TabPanel>

            {profile && profile?.userType === "startup" && value === 0 && (
              <Stack
                direction={"column"}
                spacing={3}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}>
                <StartupTopPriorities profile={profile} />
              </Stack>
            )}
          </Stack>
        )}
        <Portal>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-achive"
            aria-describedby="alert-dialog-achive"
            maxWidth={"sm"}>
            <DialogContent
              sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="flex-end">
                <IconButton
                  onClick={() => {
                    setOpen(false);
                  }}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                spacing={2}>
                <Stack
                  direction={"column"}
                  sx={{ width: "100%", }} alignItems={"center"}>
                  <img src={"/assets/delete-confirmation.png"} height={74} width={80}></img>
                </Stack>
                <Typography
                  component="span"
                  variant="h6"
                  color="text.primary"
                  sx={{
                    textAlign: "center",
                    flex: 1,
                    padding: 0,
                    fontWeight: 500,
                  }}>
                  Delete User
                </Typography>
                <Typography variant={"body2"} sx={{ textAlign: 'center' }}>
                  Are you sure you want to delete this user? <br />
                  This action cannot be undone.
                </Typography>
                <br />
                <Stack
                  direction={"row"}
                  sx={{ width: "100%" }}
                  justifyContent="center"
                  alignItems="center" spacing={2}>
                  <Button
                    variant={"outlined"}
                    sx={{ px: 5, width: "100px", height: "40px", fontSize: "12px", fontWeight: "500" }}
                    onClick={handleClose}>
                    No
                  </Button>
                  <Button
                    variant={"contained"}
                    sx={{ px: 5, width: "100px", height: "40px", fontSize: "12px", fontWeight: "500" }}
                    onClick={() => {
                      deleteUser().then();

                    }}>
                    yes
                  </Button>
                </Stack>

                <Box />
              </Stack>
            </DialogContent>
          </Dialog>
        </Portal>
        {/* <Portal>
          <div>

            <Dialog
              open={open}
              onClose={handleClose}
              fullWidth={true}
              maxWidth={'sm'}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" color={'primary'}>
                Before you move forward...
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This action will permanently remove the user.
                  Do you want to proceed with this action?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Go back</Button>
                <Button onClick={()=> {
                  deleteUser().then();

                }} variant={'contained'} color={'warning'} size={'small'} sx={{px: 3}} endIcon={removeAdminLoading ? <CircularProgress size={20}/> : '' } disabled={removeAdminLoading}>
                  Remove user
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Portal> */}
      </Container>
    );
  }
  else {
    return (
      <>
        {
          profile?.userType === "business-developer" &&
          profile?.businessDeveloper &&
          profile?.businessDeveloper.investorDetails.portfolio &&
          profile?.businessDeveloper.investorDetails.portfolio.length > 0 &&
          profile?.businessDeveloper.investorDetails.portfolio.map((p, i) => (
            (i === key) ? (<InvestmentPortfolioView profile={profile} i={i} />) : null

          ))
        }

        {
          profile?.userType === "service-provider" &&
          profile?.serviceProvider &&
          profile?.serviceProvider.investorDetails.portfolio &&
          profile?.serviceProvider.investorDetails.portfolio.length > 0 &&
          profile?.serviceProvider.investorDetails.portfolio.map((p, i) => (
            (i === key) ? (<InvestmentPortfolioView profile={profile} i={i} />) : null

          ))
        }

        {
          profile?.userType === "expert" &&
          profile?.expert &&
          profile?.expert.investorDetails.portfolio &&
          profile?.expert.investorDetails.portfolio.length > 0 &&
          profile?.expert.investorDetails.portfolio.map((p, i) => (
            (i === key) ? (<InvestmentPortfolioView profile={profile} i={i} />) : null

          ))
        }

        {
          profile?.userType === "investor" &&
          profile?.investor &&
          profile?.investor.portfolio &&
          profile?.investor.portfolio.length > 0 &&
          profile?.investor.portfolio.map((p, i) => (
            (i === key) ? (<InvestmentPortfolioView profile={profile} i={i} />) : null

          ))
        }
        {
          profile?.userType === "business-developer" &&
          profile?.businessDeveloper &&
          profile?.businessDeveloper.experiences &&
          profile?.businessDeveloper.experiences.length > 0 &&
          profile?.businessDeveloper.experiences.map((p, i) => (
            (i === k) ? (<WorkExperienceView profile={profile} i={i} />) : null

          ))
        }

        {
          profile?.userType === "service-provider" &&
          profile?.serviceProvider &&
          profile?.serviceProvider.experiences &&
          profile?.serviceProvider.experiences.length > 0 &&
          profile?.serviceProvider.experiences.map((p, i) => (
            (i === k) ? (<WorkExperienceView profile={profile} i={i} />) : null

          ))
        }

        {
          profile?.userType === "expert" &&
          profile?.expert &&
          profile?.expert.experiences &&
          profile?.expert.experiences.length > 0 &&
          profile?.expert.experiences.map((p, i) => (
            (i === k) ? (<WorkExperienceView profile={profile} i={i} />) : null

          ))
        }

      </>
    )
  }
}
