import * as React from "react";
import {
  Stack, Badge, styled,
  Avatar, Typography
} from "@mui/material";
import {DB} from "../config/firebase/firebase";
import { doc, getDoc, deleteDoc} from "firebase/firestore";
import { USER_TYPES_TO_NAME } from "../utils/enums/labels.enum";

export default function CommonAvatar({uid, callback}) {
  const [user, setUser] = React.useState()
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: '#fff'
  }));
  React.useEffect(() => {
    if (uid) {
  
      const userRef = doc(DB, "users",uid);
      getDoc(userRef).then((doc) => setUser(doc?.data()));
    }
  }, [uid])
  
  return (<Stack direction={"row"} spacing={2} alignItems={"center"} sx={{cursor: callback ? 'pointer' : ''}} onClick={() => {
    if (callback) {
      callback({...user, id : user?.uid})
    }
  }}>
    <Avatar alt={user && user?.displayName} src={user && user?.photoURL} sx={{height:50, width:50}} />
    <Stack direction={"column"} spacing={0}>
      <Typography variant={"body1"} color={'#051E40'} textTransform={"capitalize"}>{user?.displayName}</Typography>
      <Typography variant={"caption"} textTransform={'uppercase'} color={'#000'}>{USER_TYPES_TO_NAME[user?.userType]}</Typography>
    </Stack>
  </Stack>)
}
