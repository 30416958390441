import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { DB } from "../../../../../../config/firebase/firebase";
import { Button, Stack, Box, InputBase, IconButton } from "@mui/material";
import moment from "moment";
import CommentLikeMenu from "./../comment/comment-like-menu";
import CommentsLikesCountCompnent from "./../comment/comments-likes-count-compnent";
import ReplyLikeMenu from "./reply-like-menu";
import ReplyLikesCountCompnent from "./reply-likes-count-compnent";
import { AuthUserContext } from "../../../../../../providers/auth-provider";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PostNotification from "../../../../../../api/post-notification";
import ItemText from "../../../../../../components/common/timeline/item-text";
import LinkWithThumbnail from "../../../../../../components/common/link-embed-thumbnail";

export default function CommentReplyItem({
  reply,
  timelineId,
  commentId,
  postuid,
  count,
}) {
  const [user, setUser] = React.useState();
  const [show, setShow] = React.useState(false);
  const [text, setText] = React.useState("");
  const { profile } = React.useContext(AuthUserContext);
  const [likesCount, setLikesCount] = React.useState();

  const postComment = async () => {
    if (text !== "") {
      const addRef = collection(
        DB,
        "timelines",
        timelineId,
        "comments",
        commentId,
        "replies"
      );
      const add = await addDoc(addRef, {
        replyTo: {
          displayName: user?.displayName,
          uid: user?.uid,
          replyId: reply?.id,
        },
        uid: profile?.uid,
        text,
        createdAt: serverTimestamp(),
      });
      console.log("add comment", add?.id);
      const q = query(
        collection(DB, "notifications"),
        where("uid", "==", postuid),
        where("type", "==", "post-comment-notification"),
        where("postId", "==", timelineId),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      const notifications = await getDocs(q);
      if (profile?.uid !== user?.uid) {
        if (notifications.size > 0) {
          await updateDoc(doc(DB, "notifications", notifications.docs[0].id), {
            displayName: `${profile?.displayName} replied to ${user?.displayName}’s, and ${count} others `,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            imageURL: profile?.photoURL,
            read: false,
          });
        } else {
          PostNotification({
            type: "post-comment-notification",
            imageURL: profile?.photoURL,
            message: "",
            displayName: `${profile?.displayName} replied to ${user?.displayName}’s `,
            uid: postuid,
            postId: timelineId,
          }).then();
        }
      }
      setText("");
      setShow(!show);
    }
  };
  React.useEffect(() => {
    const userRef = doc(DB, "users", reply?.uid);
    getDoc(userRef).then((doc) =>
      setUser({
        id: doc?.id,
        ...doc?.data(),
      })
    );
  }, [reply?.uid]);
  return (
    <ListItem alignItems="flex-start" sx={{ p: 0 }}>
      <ListItemAvatar>
        <Avatar alt={user?.displayName} src={user?.photoURL} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <React.Fragment>
            <Typography sx={{fontSize: "13px", fontWeight: 500}} color="#051E40">
              {user?.displayName}
            </Typography>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <Stack direction={"column"} spacing={0}>
              <Typography
                sx={{
                  display: "block",
                  marginTop: "10px",
                  borderRadius: "5px",
                  p: "10px",
                  backgroundColor: "#e8eef991", fontSize: "12px",
                }}
                variant="body2"
                color="#333333"
              >
                {reply?.replyTo && (
                  <Button variant={"text"} size={"small"} color={"secondary"}>
                    <Typography
                      variant={"caption"}
                      fontWeight={600}
                      sx={{ textDecoration: "underline", fontSize:"12px" }}
                    >
                      {/* {reply?.replyTo?.displayName} */}
                    </Typography>
                  </Button>
                )}

                <ItemText limit={150} text={reply?.text} />
              </Typography>

              <LinkWithThumbnail data={reply} limit={50} small />

              <Stack direction={"row"} spacing={-3} alignItems={"center"}>
                <ReplyLikeMenu
                  data={reply}
                  timelineId={timelineId}
                  commentId={commentId}
                  postuid={postuid}
                  replyuser={{ displayName: user?.displayName, uid: user?.uid }}
                  likesCount={likesCount}
                />
                <ReplyLikesCountCompnent
                  data={reply}
                  timelineId={timelineId}
                  commentId={commentId}
                  cb={(data) => setLikesCount(data)}
                />
            
                <Button
                  variant={"text"}
                  // size={"small"}
                  sx={{ color: "#8F8F8F", fontSize: "12px",  }}
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                Reply
                </Button>
                <Box sx={{ flex: 1 }} />
                <Typography variant={"caption"}>
                  {/* {reply?.createdAt?.seconds && moment(reply?.createdAt?.seconds * 1000).fromNow(true)} ago */}
                  {/*{ moment(new Date(reply?.seconds * 1000)).max(new Date())}*/}
                </Typography>
              </Stack>
              {show && (
                <Box
                  sx={{
                    p: "20px 10px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 23,
                    borderRadius: "30px",
                    border: ".5px solid #8F8F8F",
                  }}
                >
                  {/* <Typography variant={'caption'} fontWeight={600}>
                      {user?.displayName}
                    </Typography> */}
                  <InputBase
                    sx={{
                      ml: 1,
                      flex: 1,
                      color: "#8F8F8F",
                      input: {
                        "&::placeholder": {
                          opacity: 1, fontSize:"13px"
                        },
                      },
                      
                    }} 
                    value={text}
                    autoFocus={true}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    onKeyDown={(e) => e.key === "Enter" && postComment()}
                    placeholder="Reply..."
                    inputProps={{ "aria-label": "send-button" }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="send-button"
                    onClick={postComment}
                  >
                    <img src="/assets/send_icon.png" alt="ico" />
                  </IconButton>
                </Box>
              )}
            </Stack>
          </React.Fragment>
        }
      />
    </ListItem>
  );
}
