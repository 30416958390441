import * as React from 'react';
import Typography from "@mui/material/Typography/Typography";
import Stack from "@mui/material/Stack/Stack";
import moment from "moment/moment";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import {doc, serverTimestamp, setDoc} from "firebase/firestore";
import { AuthUserContext } from "../../../../../../providers/auth-provider";
import { DB } from "../../../../../../config/firebase/firebase";

export default function TextMessageComponent({msg, type}){
  const { profile } = React.useContext(AuthUserContext);

  React.useEffect(() => {
    debugger;
    if (msg && msg.receiver === profile?.uid && msg.read === false) {
      const msgRef = doc(DB, "messages", msg.id);
      setDoc(
        msgRef,
        {
          read: true,
          status: "seen",
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      ).then();
    }
  }, [msg]);

  const detectLinks = (text, side) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      if (side === "left")
        return `<a style=" text-decoration: underline; transition: transform 0.3s; cursor: pointer; word-wrap: break-word;" href="${url}" target="_blank">${url}</a>`;
      return `<a style="color: #fff; text-decoration: underline; transition: transform 0.3s; cursor: pointer; word-wrap: break-word;" href="${url}" target="_blank">${url}</a>`;
    });
  };

  const renderTextWithLinks = (text, side = "right") => {
    const processedText = detectLinks(text, side);
    const lines = processedText.split("\n");

    return lines.map((line, index) => (
      <React.Fragment key={index}>
        <span dangerouslySetInnerHTML={{ __html: line }} />
        {index !== lines.length - 1 && <br />} {/* Preserve line breaks */}
      </React.Fragment>
    ));
  };

  if (type === "r") {
    return (
      <Stack direction={"column"} spacing={1}>
        <Stack direction={"row-reverse"} spacing={1}>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{
              p: 2,
              minWidth: 50,
              backgroundColor: "#233DFF",
              color: "#fff",
              borderRadius: "12px 0px 12px 12px",
              maxWidth: "70%",
            }}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Typography
              sx={{ width: "100%", wordWrap: "break-word", color: "white" }}
              variant={"body2"}
              align={"justify"}
            >
              {renderTextWithLinks(msg?.text)}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          spacing={0.5}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography sx={{ fontSize: "12px" }}>
            {msg.createdAt &&
              moment(msg.createdAt.seconds * 1000).format(
                `${
                  moment(msg.createdAt.seconds * 1000).isSame(new Date(), "day")
                    ? "hh:mm a"
                    : "D-M-YYYY | hh:mm a"
                }`
              )}
          </Typography>
          {msg.read === true ? (
            <DoneAllOutlinedIcon style={{ color: "#4acfee", fontSize: 18 }} />
          ) : (
            <DoneAllOutlinedIcon style={{ color: "#ccc", fontSize: 18 }} />
          )}
        </Stack>
      </Stack>
    );
  } else {
    return (
      <Stack direction={"column"} spacing={1}>
        <Stack direction={"row"} spacing={1}>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              p: 2,
              backgroundColor: "#F6F8FA",
              borderRadius: "0px 12px 12px 12px",
              maxWidth: "70%",
            }}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Typography
              variant={"body2"}
              sx={{
                width: "100%",
                wordWrap: "break-word",
                textAlign: "left",
                mb: 1,
              }}
            >
              {renderTextWithLinks(msg?.text, "left")}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          spacing={0.5}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography sx={{ fontSize: "12px" }} color={"secondary"}>
            {msg.createdAt &&
              moment(msg.createdAt.seconds * 1000).format(
                `${
                  moment(msg.createdAt.seconds * 1000).isSame(new Date(), "day")
                    ? "hh:mm a"
                    : "D-M-YYYY | hh:mm a"
                }`
              )}
          </Typography>
        </Stack>
      </Stack>
    );
  }
}
