import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import React from "react";
import fireIcon from "./Fire.png"; // Import your custom SVG icon
import {
  Grid,
  Stack,
  Typography,
  Box,
  styled,
  Paper,
  Button,
  Select,
  MenuItem,
  Portal,
  Dialog,
  DialogContent,
  Skeleton,
  Chip,
} from "@mui/material";
import Avatar from "@mui/material/Avatar/Avatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  increment,
  getDoc,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import IconButton from "@mui/material/IconButton";
import RemainingHoursOrDate from "./offer-date-format";
import OfferFavorite from "./offer-fav";
import { black } from "material-ui/styles/colors";

export default function OfferClaimCard({
  offer,
  callback,
  remove,
  details = false,
  loading
}) {
  const navigator = useNavigate();
  const { profile } = React.useContext(AuthUserContext);
  const [claimed, setClaimed] = React.useState(false);
  const [claimedPopUp, setClaimedPopUp] = React.useState(false);
  const claim = () => {
    const colRef = doc(DB, "offers", offer?.id, "users", profile?.uid);
    setDoc(
      colRef,
      {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      },
      { merge: true }
    )
      .then(async () => {
        await updateDoc(doc(DB, "offers", offer?.id), {
          claimed: increment(1),
        });
        setClaimedPopUp(true);
        setClaimed(true);
      })
      .catch(() => {
        debugger;
      });
  };
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "#fff",
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    minHeight: 442,
  }));
  React.useEffect(() => {
    console.log(offer?.users);
    if (offer?.users?.includes(profile.uid)) setClaimed(true);
  }, []);
  React.useEffect(() => {
    setClaimed(false);
    let unsubs;
    unsubs = getDoc(doc(DB, "offers", offer?.id, "users", profile?.uid)).then(
      (getdoc) => {
        if (getdoc.exists()) {
          setClaimed(true);
        }
      }
    );
    return () => unsubs;
  }, [offer?.id]);
  return (
    <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
      <Item>
        <Stack
          direction={"column"}
          spacing={1}
          sx={{ width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            {(Math.floor((new Date().getTime() -  new Date(offer?.from).getTime())/(1000 * 60 *60)))<=24 && //to check if the offer is live for 24 hours
            <Chip
            variant="contained"
            sx={{
              my: 0,
              backgroundColor: "#E0E3FF",
              px: 0.5,
              maxWidth: 250,
              height:24,
              borderRadius: "5px",
            }}
            label={"New"}
          />
          }
            <OfferFavorite data={offer} />
          </Stack>
          <Box
            textOverflow={"ellipsis"}
            onClick={() => {
              navigator(`/${profile.userType}/offers/details/${offer.id}`);
            }}
            sx={{
              position: "relative",
              top: "35px",
              bottom: "20px",
              width: "280px",
              height: "200px",
              background: "#F6F8FA",
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
            }}
          >
            <Stack
              direction={"column"}
              spacing={2}
              justifyContent="center"
              alignItems="center"
              onClick={() => navigator()}
            >
              <Box
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                sx={{
                  border: "1px solid #204488",
                  p: 0.75,
                  borderRadius: "50%",
                  marginTop: "-40px",
                }}
              >
                {loading ? <Skeleton animation={'wave'} variant="circular" sx={{width:92, height:92}}/> :
                <Avatar
                  alt={"Offer Image"}
                  src={
                    offer.offerImage
                      ? offer.offerImage
                      : "/assets/offer-default-image.png"
                  }
                  sx={{ width: 92, height: 92 }}
                ></Avatar>}
              </Box>
             {loading ? <>
              <Skeleton animation={'wave'} variant="rounded" width={"75%"}/> 
              <Skeleton animation={'wave'} variant="rounded" width={"75%"} sx={{mb:5}}/> 
             </>
              : 
             <Typography
                variant={"h6"}
                sx={{
                  fontWeight: 600, mb: 0, lineHeight: 1.2, width: 250, overflow: "hidden", color:"black",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "31ch",
                }}
              >
                {offer?.companyName?.slice(0, 20) +
                  (offer?.companyName?.length > 20 ? "..." : "")}
                <br />
                <font size={3} style={{ fontWeight: 300 }}>
                  {offer?.name?.slice(0, 20) +
                    (offer?.name?.length > 20 ? "..." : "")}
                </font>
              </Typography>}
              {!claimed  && !loading && (
                <Button
                  variant={"text"}
                  size={"small"}
                  color={"primary"}
                  sx={{
                    color: "black",
                    fontWeight: "400",
                    padding: "5px 15px",
                    backgroundColor: "#E0E3FF",
                    "&:hover": {
                      backgroundColor: "#233DFF",
                      color: "white",
                    },
                  }}
                  onClick={(e) => {
                    
                    e.stopPropagation();
                    claim();
                  }}
                >
                  {" "}
                  Claim{" "}
                </Button>
              )}
              {claimed && !loading && (
                <Typography variant={"h6"} sx={{ fontWeight: 600, mb: 0 }}>
                  Coupon:{" "}
                  {offer?.couponCode?.slice(0, 20) +
                    (offer?.couponCode?.length > 20 ? "..." : "")}
                </Typography>
              )}
              <Box />
              <Stack
                sx={{ top: "0" }}
                direction={"column"}
                spacing={0}
                justifyContent="flex-start"
                alignItems="flex-start"
                width={"100%"}
              >
                <Stack
                  sx={{ top: "4", mt:2,  }}
                  direction={"column"}
                  spacing={0}
                  justifyContent="center"
                  alignItems="center"
                  width={"100%"}
                >
                  {loading ? <Skeleton animation={'wave'} sx={{mt:6}} variant="rounded" width={"75%"}/> :<>
                  <Typography sx={{
                    fontSize:18,
                    fontWeight: 600, mb: 0, lineHeight: 1.2, width: 300, overflow: "hidden", color:'black',
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "35ch",
                  }} >
                    
                    {offer?.headingOne.slice(0, 25) +
                      (offer?.headingOne?.length > 25 ? "..." : "")}
                  </Typography>
                  <Typography variant={"body2"} sx={{ fontWeight: 400, mb: 0,width: 300, overflow: "hidden", color:'black',
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "35ch", }}>
                    {offer?.headingTwo.slice(0, 30) +
                      (offer?.headingTwo?.length > 30 ? "..." : "")}
                  </Typography>
                      </>
                    }
                </Stack>
                {details ? (
                  <>
                    <Stack
                      direction="column"
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <Stack
                        direction={"column"}
                        spacing={0}
                        mb={2}
                        justifyContent="center"
                        alignItems="center"
                        width={"100%"}
                      >
                        <Typography
                          color={'#000000'}
                          variant={"h6"}
                          sx={{fontWeight: 600, mt: 1.5 }}
                        >
                          Additional link
                        </Typography>
                        <a
                          // style={{
                          //   borderRadius: "19px",
                          //   backgroundColor: "#E0E3FF",
                          //   padding: "3px 8px",
                          // }}
                          style={{
                            textDecoration:'none',
                            color: '#000000',
                            display: "inline-block",
                            borderRadius: "19px",
                            backgroundColor: "#E0E3FF",
                            padding: "3px 8px",
                            maxWidth: "75%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          href={offer?.offerLink}
                          target={"_blank"} rel="noreferrer"
                        >
                          {offer?.offerLink}
                        </a>
                      </Stack>
                    </Stack>
                  </>
                ) : (
                  <>
                    {loading ? <Skeleton animation={'wave'} variant="rounded" sx={{mt:2,width:"100%"}}/> : <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%", marginTop: "20px" }}
                    >
                      <RemainingHoursOrDate dateString={offer.to} />

                      {offer.isHotOffer ? (
                        <IconButton color="primary">
                          <img src={fireIcon} alt="Custom Icon" />
                        </IconButton>
                      ) : (
                        <div style={{ width: 48, height: 48   }} /> // Placeholder with the same width as IconButton
                      )}
                    </Stack>}
                  </>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Item>
      <Portal>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={claimedPopUp}
          onClose={() => setClaimedPopUp(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ p: 3 }}>
            <Stack justifyContent="flex-start" alignItems="flex-start">
              <Typography sx={{ mb: 3 }} variant={"h6"} fontWeight={600}>
                Successfully Claimed the Offer
              </Typography>
            </Stack>
            <Stack
              direction={"column"}
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >

              <img
                src={"/assets/successfully-applied.png"}
                height={92}
                width={92}
              />

              {offer.couponCode && (
                <>
                  <Typography variant={"body2"} textAlign={"center"}>
                    <font size={3} style={{ fontWeight: 300 }}>
                      Here`s your promo code for future usage
                    </font>
                  </Typography>
                  <Typography variant={"h6"} textAlign={"center"}>
                    {offer && offer.couponCode}
                  </Typography>
                </>
              )}

              <Typography variant={"body2"} textAlign={"center"}>
                Congratulations on claiming this offer! Feel free to check out{" "}
                <br />
                other exciting offers available on our platform.
              </Typography>


            </Stack>
            <Stack
              direction={"row"}
              spacing={5}
              justifyContent="flex-end"
              sx={{ mt: 3 }}
            >
              <Button
                variant={"contained"}
                // sx={{ width: "100%" }}
                onClick={() => {
                  setClaimedPopUp(false);
                  navigator(`/${profile?.userType}/offers/list`);
                }}
              >
                Continue
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </Grid>
  );
}
