import React from "react";
import {
  Button,
  Container, MenuItem,
  Stack,
  TextField, Typography, useMediaQuery,
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import { AuthUserContext } from "../../../../providers/auth-provider";
import PortfolioCard from "../../components/common/portfolio-card";
import Grid from "@mui/material/Grid";
import ExperienceCard from "../../components/common/experience-card";
export default function ExperienceListView({userType, experiences, add, cbBack, submit, edit, remove}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const { profile } = React.useContext(AuthUserContext);
  // console.log(profile)
  console.log(userType )
  return(
    <Container maxWidth={"lg"}>
      <Stack direction={"column"} spacing={5} sx={{width: '100%'}}  justifyContent="center"
             alignItems="center">

        {

          experiences.length === 0 ? 
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2} 
              sx={{
                
                // width: '500px'
                width: "95%"
              , height: 252, border:'1px dashed rgba(0, 0, 0, 0.45)', padding: 2 , marginX: 'auto', boxSizing: 'border-box15'}}
            >
              {/* <Typography variant={'h6'} fontWeight={500} color={'#6E6E6E'} textAlign={'center'}>
                <font color={'#A9ABAE'}>You have not added any</font>
                <br />{userType === "expert" ? "Expert" : (userType === "business-developer" ? "Business Developer " : (userType === "service-provider" ? "Service Providing" : ""))} Experience
                <font color={'#A9ABAE'}> yet.</font>
              </Typography> */}
              <Typography variant={'h6'} fontWeight={500} color={'#6E6E6E'} textAlign={'center'}>
                <font color={'#A9ABAE'}>You have not added any</font>
                <br />Experience
                <font color={'#A9ABAE'}> yet.</font>
              </Typography>
            </Stack> : experiences?.length > 0 &&
            <Grid  container gap={ !matches ? 2 : 2} >
              {experiences.map((po, i) => (
                <ExperienceCard data={po} key={i} edit={() => edit(i)} remove={() => remove(i)}/>

              ))}</Grid>
        }
        <Button sx={{textTransform : "capitalize"}} variant={"contained"} color={"success"} startIcon={<AddIcon />} onClick={add}>Add experience</Button>

        <span />
        <span />
        {cbBack && <Stack
          direction={"row"}
          justifyContent="center"
          alignItems="center"
          spacing={3}
          width={"100%"}>
          <Button
            variant={"outlined"}
            color={"primary"}
            sx={{ px: 7 }}
            onClick={cbBack}>
            Back
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{ px: 7 }} onClick={() => submit()}>
            Next
          </Button>
        </Stack>}

      </Stack>
    </Container>


  )
}