import {Box, Button, IconButton,Stack, Typography} from "@mui/material";
import React from "react";
import {EditSharp} from "@mui/icons-material";
import AboutExpertComponent from "./edit/expert/about-expert-component";
import { EXPERT } from "../../../utils/enums/labels.enum";
const styleFont = {
	backgroundColor: "#F6F8FA",
	padding: "8px 15px",
	marginRight: "5px",
	borderRadius: "5px",
	fontSize: 14,
};


export default function ExpertAbout({ profile , cb}){
    const [seeMore, setSeeMore] = React.useState(false)
	const [open,setOpen] = React.useState(false)
	
	const handleClose = () => {
		setOpen(false)
	}
	const profileRegex = /profile|admin\/user-details/

	return (
  
	  <>
  
		{profile.userType  === 'expert' &&
		<>
		<Stack
                direction={"column"}
                spacing={3}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}> <Stack direction={"row"} spacing={2} >
			<Typography fontSize={20} fontWeight={600} style={{flex: 1}}>About {EXPERT.SINGULAR_LABEL}</Typography>

			{profileRegex.test(window.location.pathname) &&
				<IconButton onClick={() => setOpen(true)}>
				<EditSharp />
			</IconButton>}
		</Stack>
		  {/* <Grid container  gap={1}  sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}> */}
		  <Stack direction={"column"} spacing={4}>	
			
			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Expertise<br/>{profile?.expert?.expertise && profile?.expert?.expertise.length > 0 &&
				  profile?.expert?.expertise.map((ex, i) => 
				  <font style={styleFont}>{ex}</font>

				  )}
				  
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
			
			
			  <Stack direction={'column'} spacing={2}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Sector<br/> 
				  {profile?.expert?.sector &&
					profile?.expert?.sector.length > 0 &&
					profile?.expert?.sector.map((element,index)=>(
					      <font style={styleFont}>{element} </font>
				         )

				       )}
				  {/* <font style={styleFont}>{profile?.expert?.sector} </font> */}
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
			
			{/* {console.log(profile)} */}
			
			 { profile?.expert?.additionalDetails &&
					profile?.expert?.additionalDetails.length > 0 &&
			 <Stack direction={'column'} spacing={2}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				Additional Details<br/> 
				{profile?.expert?.additionalDetails &&
					profile?.expert?.additionalDetails.length > 0 &&
					profile?.expert?.additionalDetails.map(
											(ex, i) => (
												<font style={styleFont}>
												{ex}


												</font>
											)
										)}
				
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>}
			
			{/* 
			  <Stack direction={'column'} spacing={2}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Geographies<br/> <font style={styleFont}>{profile?.expert?.geographies} </font>
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
			 */}
			</Stack>
			{/* <Grid container  gap={12} sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}> */}
			<Stack direction={"column"} spacing={4}>	
			
			  <Stack direction={'column'} spacing={2}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Geographies<br/> 
				  {profile?.expert?.geographies?.map((element,index)=>(
					      <font style={styleFont}>{element} </font>
				         )
    
		      		  ) }
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
			
			
			  <Stack direction={'column'} spacing={2}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Relevant years of experience<br/> <font style={styleFont}>{profile?.expert?.relevantYearOfExperience} </font>
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
			
			
			  {seeMore?
				<>
				<Stack direction={'column'} spacing={2}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Support startup at stage<br/> 
				  {profile?.expert?.supportStage  && Array.isArray(profile?.expert?.supportStage) &&
					profile?.expert?.supportStage?.length > 0 &&
					profile?.expert?.supportStage?.map((element,index)=>(
						<font style={styleFont}>{element}</font>
						)
						
						) }
				  {/* {profile?.expert?.supportStage  &&
					profile?.expert?.supportStage.length > 0 &&
					profile?.expert?.supportStage.map((element,index)=>(
						<font style={styleFont}>{element}</font>
						)
						
					)} */}
				  {/* <font style={styleFont}>{profile?.expert?.supportStage}</font> */}
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
			
					  
			  <Stack direction={'column'} spacing={2}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Happy to provide service for some share of the equity?<br/> <font style={styleFont}>{
					  profile?.expert?.happyToProvideServiceForSomeShareOfTheEquity ? 'Yes': 'No' }
				</font>
				</Typography>
			  </Stack>
			  
			  <Stack alignItems={'center'}>
                          <Box>
                          <Button size={"small"} variant='outlined'  sx={{borderRadius:"5px"}} color="secondary" onClick={()=> setSeeMore(!seeMore)}>See Less</Button>
                          </Box>
			                   </Stack> 
			  </>
			  :
			  <Stack alignItems={'center'}>
                          <Box>
                          <Button size={"small"} variant='outlined' sx={{borderRadius:"5px"}} color="secondary" onClick={()=> setSeeMore(!seeMore)}>See More</Button>
                          </Box>
			                   </Stack> 
			  }
			
		  </Stack>
		  </Stack>
		  </>
		}
		  <AboutExpertComponent profile={profile?.expert} open={open} expertProfile={profile} callback={(data) => {
			  cb(data)
			  handleClose()
		  }}  handleClose={handleClose} />
	  </>
	)
  }
