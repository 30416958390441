import React from 'react';
import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {HomeComponent} from "../../screens/mobile/default-screen";
import TimelineScreen from "../../screens/mobile/common/timeline-screen";
import {InvestorList} from "../../screens/mobile/common/investor/investor-list";
import ProfileScreen from "../../screens/mobile/common/profile-screen";
import CommonDetails from "../../screens/mobile/common/common-details";
import {ExpertList} from "../../screens/mobile/common/expert/expert-list";
import ChatScreen from "../../screens/mobile/common/chat-screen";
import {ServiceProviderList} from "../../screens/mobile/common/service-provider/service-provider-list";
import {BusinessDeveloperList} from "../../screens/mobile/common/business-developer/business-developer-list";
import {StartupList} from "../../screens/mobile/common/startup/startup-list";
import StartupFounderProfile from "../../screens/mobile/common/startup/founder-profile";
import {TalentList} from "../../screens/mobile/common/talent/talent-list";
import RequestAssistanceScreen from "../../screens/mobile/common/request-assistance-screen";
import RequestDetails from "../../screens/mobile/common/request-details";
import MyRequests from "../../screens/mobile/common/my-requests";
import NotificationScreen from "../../screens/mobile/common/notification-screen";
import SettingScreen from "../../screens/mobile/common/setting-screen";
import ChangePasswordScreen from "../../screens/mobile/common/change-password-screen";
import { MembersList } from '../../screens/mobile/common/members-list';
import Implementfunctionality from '../../screens/mobile/components/implementfunctionality';
import ChatScreen1 from '../../screens/mobile/common/chat-screen1';
import ServiceProviderOrganisationProfile from '../../screens/mobile/common/service-provider/organisation-profile';
import CommonOrganisationProfile from "../../screens/mobile/common/investor/common-organisation-profile";
import MyFavorites from '../../screens/mobile/common/my-favorites';
import PostDetails from '../../screens/mobile/common/post-details';
import { RequestMatchesList } from '../../screens/mobile/common/reques-matches-list';
import Applicants from '../../screens/mobile/common/applicants';
import OffersList from '../../screens/mobile/common/offers/offers-list-users';
import OfferDetailsClaim from '../../screens/mobile/common/offer-details-claim';
import { RequestMatchesUser } from '../../screens/mobile/common/request-matches-user';



const MobileExpertRouter = () => {
  return(
    <Routes>
      <Route
        path="*"
        element={<Navigate to="/m/expert/timeline" replace />}
      />
      <Route  path='m/expert'  element={<Outlet />}>
      <Route  path={'post-details/:postId'} element={<PostDetails/>}/>
      <Route  path={'request-matches-list'} element={<RequestMatchesList/>}/>
      <Route  path={'applicants/:requestId'} element={<Applicants/>}/> 
      <Route  path={'open-requests'} element={<HomeComponent/>}/>
        <Route  path={'open-requests-post'} element={<RequestAssistanceScreen/>}/>
        <Route  path={'open-requests-details'} element={<RequestDetails/>}/>
        <Route  path={'open-requests-details/:requestId'} element={<RequestDetails/>}/>

        <Route  path={'open-requests-update/:requestId'} element={<RequestAssistanceScreen/>}/>
        <Route  path={'timeline'} element={<TimelineScreen/>}/>
        <Route  path={'investor-list'} element={<InvestorList/>}/>
        <Route  path={'profile'} element={<ProfileScreen/>}/>
        <Route  path={'chat'} element={<ChatScreen/>}/>
        <Route  path={'chatscreen1'} element={<ChatScreen1/>}/>

        <Route  path={'notification'} element={<NotificationScreen/>}/>

        <Route  path={'expert-list'} element={<ExpertList/>}/>
        <Route  path={'investor-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'expert-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'service-provider-list'} element={<ServiceProviderList/>}/>
        <Route  path={'service-provider-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'business-developer-list'} element={<BusinessDeveloperList/>}/>
        <Route  path={'business-developer-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'startup-list'} element={<StartupList/>}/>
        <Route  path={'startup-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'startup-founder-profile'} element={<StartupFounderProfile/>}/>
        <Route  path={'investor-list/founder-profile'} element={<StartupFounderProfile/>}/>
        <Route  path={'startup-list/startup-founder-profile'} element={<StartupFounderProfile/>}/>
        <Route  path={'talent-list'} element={<TalentList/>}/>
        <Route  path={'my-requests'} element={<MyRequests/>}/>

        <Route  path={'talent-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'members-list'} element={<MembersList/>}/>
        <Route  path={'implementing-functionality'} element={<Implementfunctionality/>}/>

        <Route  path={'settings'} element={<SettingScreen/>}/>
        <Route  path={'change-password'} element={<ChangePasswordScreen />}/>
        <Route  path={'organisation-profile'} element={<ServiceProviderOrganisationProfile/>}/>
        <Route  path={'common-organisation-profile'} element={<CommonOrganisationProfile/>}/>
        <Route  path={'my-favorites'} element={<MyFavorites/>}/>
        <Route  path={'offers/list'} element={<OffersList/>}/>
        <Route  path={'offers/details/:offerId'} element={<OfferDetailsClaim/>}/>
        <Route  path={'matches-user/:requestId'} element={<RequestMatchesUser/>}/>
        <Route  path={'request-matches-list'} element={<RequestMatchesList/>}/>
      </Route>
    </Routes>

  )
}


export default MobileExpertRouter;
