import * as yup from 'yup';

const BasicFormSchema = yup.object({
 country: yup.string('country/region').required('*country/region is required.'),
 state: yup.string('province/state').required('*province/state'),
 city : yup.string('city').required("*city is Required")

});
const initialValues = {
  country: '',
  state: '',
  city: '',
};

const BasicFormValidation = {
  initialValues,
  schema : BasicFormSchema
};

export default  BasicFormValidation;