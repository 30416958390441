import {
	Grid,
	Link,
	Stack,
	Typography,
	Box,
	Avatar,
	Portal,
	Dialog,
	DialogContent,
	Button,
	DialogTitle, TextField
} from "@mui/material";
import React from "react";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {AuthUserContext} from "../../../providers/auth-provider";
import {doc, updateDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
import AddIcon from '@mui/icons-material/Add';
import {useFormik} from "formik";
import StartupOnBoardingValidation from "../../../validations/startup/startup-profile";
import PostNotification from "../../../api/post-notification";
const styleFont = {
	backgroundColor: "#F6F8FA",
	padding: "8px 15px",
	borderRadius: "5px",
	fontSize: 14,
};

export default function StartupTeam({ profile }) {
	const userContext = React.useContext(AuthUserContext)
	const [open, setOpen] = React.useState(false);
	const [openAdd, setOpenAdd] = React.useState(false);
	const showEditPattren = new RegExp('/startup/profile');
	const regexAdmin = /admin\/user-details/
	
	const formikFirst = useFormik({
		initialValues:  StartupOnBoardingValidation.TeamMemberInitialValues,
		validationSchema:
		StartupOnBoardingValidation.TeamMemberSchema,
		onSubmit: async (values) => {
			profile?.startup?.teamMembers?.push(values);
			const userRef = doc(DB, "users",regexAdmin.test(window?.location?.pathname) ? profile?.uid :  userContext?.profile?.uid);
			await updateDoc(userRef, {
				"startup.teamMembers": profile?.startup?.teamMembers,
				updatedById:userContext?.user?.uid,
				updatedBy: regexAdmin.test(window.location.pathname) ? 'admin' : 'user',
				updatedAt: new Date().getTime()
			});
			if (regexAdmin.test(window?.location?.pathname)) {
				PostNotification({
					type: 'admin-update-profile',
					imageURL: '/assets/system-noti-icon.png',
					section: 'Team',
					message: `information has been updated by the Admin.`,
					uid: profile.uid,
					createdBy: userContext?.profile?.uid
					
				}).then()
			}
			setOpenAdd(false)
			formikFirst.resetForm({...StartupOnBoardingValidation.TeamMemberInitialValues})

		},
	});
	return (
		<>

			<Stack
				direction={"column"}
				spacing={3}
				sx={{


					borderRadius: "15px",
					backgroundColor: "#fff",
					minHeight: 100,
					width: '100%'

				}}
			>
				<Typography fontSize={20} fontWeight={600}>Team</Typography>
				<Grid container gap={8} >
					<Grid>
						<Stack
							direction={"column"}
							spacing={1}
							sx={{ position: "relative", right: 15, minWidth: 200, cursor: 'pointer' }}
							alignItems="center"

						>
							
							<Box
								sx={{
									border: "1px solid #204488",
									p: 0.75,
									borderRadius: "50%",
									width: "fit-content",
								}}
							>
								<Avatar
									alt={profile?.startup?.founder.founderName}
									src={
										profile?.photoURL || profile?.startup?.founder?.photoURL
									}
									sx={{ width: 51, height: 51 }}
								>
									<Typography variant={"h4"} >
										{profile?.startup?.founder.founderName.charAt(0)}
									</Typography>
								</Avatar>
							</Box>
							<Typography variant={"h6"} sx={{ textAlign: "center" }}>
								{profile?.startup?.founder.founderName}
								<br />
								<font
									size={2}
									style={{
										backgroundColor: "#F6F8FA",
										padding: "5px 10px",
										borderRadius: "6px",
										fontWeight: 300,
									}}
								>
									Founder
								</font>
							</Typography>
						</Stack>
					</Grid>
					{profile?.startup?.teamMembers && profile?.startup?.teamMembers?.length > 0 && profile?.startup?.teamMembers?.map &&
						profile?.startup?.teamMembers?.map((member, i) => (
							<Grid key={i}>
								<Stack
									direction={"column"}
									spacing={1}
									sx={{ position: "relative", right: 15, minWidth: 200, cursor: 'pointer' }}
									alignItems="center"

								>
									{
										(userContext?.profile?.uid === profile?.uid && showEditPattren.test(window.location.pathname)) || regexAdmin.test(window?.location?.pathname) &&
										<Stack direction={'row'} spacing={1} alignItems={"flex-start"} sx={{width: '100%', position: 'absolute', top: -10}}>
											<Box sx={{flex:1}}/>
											<Button variant={"text"} size={'small'} color={"secondary"} sx={{color:'#8F8F8F'}} onClick={() => setOpen(true)}>
												delete
											</Button>
										</Stack>
									}

									<Box

										sx={{
											border: "1px solid #204488",
											p: 0.75,
											borderRadius: "50%",
											width: "fit-content",
										}}
									>
										<Avatar
											alt={member?.name}
											src={''}
											sx={{ width: 51, height: 51 }}
										>
											<Typography variant={"h4"} >
												{member?.name.charAt(0)}
											</Typography>
										</Avatar>
									</Box>
									<Typography variant={"h6"} sx={{ textAlign: "center" }}>
										{member?.name}
										<br />
										<font
											size={2}
											style={{
												backgroundColor: "#F6F8FA",
												padding: "5px 10px",
												borderRadius: "6px",
												fontWeight: 300,
											}}
										>
											{member?.role}
										</font>
									</Typography>
								</Stack>

								<Portal>
									<Dialog
										open={open}
										onClose={() => {
											setOpen(false);
										}}
										aria-labelledby="alert-dialog-achive"
										aria-describedby="alert-dialog-achive"
										maxWidth={"sm"}>
										<DialogContent
											sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
											<Stack
												direction={"column"}
												sx={{ width: "100%" }}
												justifyContent="center"
												alignItems="flex-end">
												<IconButton
													onClick={() => {
														setOpen(false);
													}}>
													<CloseIcon />
												</IconButton>
											</Stack>
											<Stack
												direction={"column"}
												sx={{ width: "100%" }}
												spacing={2}>
												<Stack
													direction={"column"}
													sx={{ width: "100%", }} alignItems={"center"}>
													<img src={"/assets/delete-confirmation.png"} height={74} width={80}></img>
												</Stack>
												<Typography
													component="span"
													variant="h6"
													color="text.primary"
													sx={{
														textAlign: "center",
														flex: 1,
														padding: 0,
														fontWeight: 500,
													}}>
													Delete Team Member
												</Typography>
												<Typography variant={"body2"} sx={{textAlign:'center'}}>
													Are you sure you want to delete this team member? <br/>
													This action cannot be undone.
												</Typography>
												<br/>
												<Stack
													direction={"row"}
													sx={{ width: "100%" }}
													justifyContent="center"
													alignItems="center" spacing={2}>
													<Button
														variant={"outlined"}
														sx={{ px: 5,  width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
														onClick={() => {
															setOpen(false)
														}}>
														No
													</Button>
													<Button
														variant={"contained"}
														sx={{ px: 5,  width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
														onClick={async () => {
															profile?.startup?.teamMembers?.splice(i, 1);
															const userRef = doc(DB, "users", regexAdmin.test(window?.location?.pathname) ? profile?.uid : userContext?.profile?.uid);
															await updateDoc(userRef, {
																"startup.teamMembers": profile?.startup?.teamMembers
															});

															// remove()
															setOpen(false)
														}}>
														yes
													</Button>
												</Stack>

												<Box />
											</Stack>
										</DialogContent>
									</Dialog>
								</Portal>
							</Grid>

						))
					}


				</Grid>
				<Portal>
					<Dialog
						open={openAdd}
						onClose={() => {
							setOpenAdd(false);
						}}
						aria-labelledby="alert-dialog-achive"
						aria-describedby="alert-dialog-achive"
						maxWidth={"md"}>
						<DialogTitle sx={{boxShadow:'0px 2px 4px rgba(0, 0, 0, 0.1)'}}>
							<Stack
								direction={"row"}
								sx={{ width: "100%" }}
								justifyContent="flex-start"
								alignItems="center">
								<Typography variant={"h6"} sx={{flex: 1}}>Add New Team Member</Typography>  <IconButton
								onClick={() => {
									setOpenAdd(false);
									formikFirst.resetForm({...StartupOnBoardingValidation.TeamMemberInitialValues})

								}}>
								<CloseIcon />
							</IconButton>
							</Stack>

						</DialogTitle>
						<DialogContent
							sx={{ minWidth: 487,  mt: 2 }} >
							<Stack
								direction={"column"}
								sx={{ width: "100%" }}
								spacing={2} component={"form"} onSubmit={(e) => formikFirst.handleSubmit(e)}>
								<Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
									<br/>
									<Typography variant={'body1'}>
										Email (Add important people of the team that are part of this startup)
									</Typography>

									<TextField name={'email'}
											   value={formikFirst.values.email}
											   onChange={formikFirst.handleChange}
											   error={
												   formikFirst.touched.email &&
												   Boolean(formikFirst.errors.email)
											   }
											   helperText={
												   formikFirst.touched.email &&
												   formikFirst.errors.email
											   }
											   variant={'outlined'} fullWidth/>
								</Stack>


								<Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
									<Typography variant={'body1'}>
										Name*
									</Typography>
									<TextField name={'name'}
											   value={formikFirst.values.name}
											   onChange={formikFirst.handleChange}
											   error={
												   formikFirst.touched.name &&
												   Boolean(formikFirst.errors.name)
											   }
											   helperText={
												   formikFirst.touched.name &&
												   formikFirst.errors.name
											   }
											   variant={'outlined'} fullWidth/>
								</Stack>

								<Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
									<Typography variant={'body1'}>
										Role*
									</Typography>

									<TextField name={'role'}
											   value={formikFirst.values.role}
											   onChange={formikFirst.handleChange}
											   error={
												   formikFirst.touched.role &&
												   Boolean(formikFirst.errors.role)
											   }
											   helperText={
												   formikFirst.touched.role &&
												   formikFirst.errors.role
											   }
											   variant={'outlined'} fullWidth/>
								</Stack>

								<br/>
								{/*<Stack
									direction={"row"}
									sx={{ width: "100%" }}
									justifyContent="center"
									alignItems="center" spacing={2}>
									<Button
										variant={"outlined"}
										sx={{ px: 5,  width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
										onClick={() => {
											setOpen(false)
										}}>
										No
									</Button>
									<Button
										variant={"contained"}
										sx={{ px: 5,  width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
										onClick={async () => {
											profile?.startup?.teamMembers?.splice(i, 1);
											const userRef = doc(DB, "users", userContext?.profile?.uid);
											await updateDoc(userRef, {
												"startup.teamMembers": profile?.startup?.teamMembers
											});

											// remove()
											setOpen(false)
										}}>
										yes
									</Button>
								</Stack>*/}

								<Box />
								<Stack
									direction={"row"}
									justifyContent="flex-end"
									alignItems="center"
									spacing={3}
									width={"100%"}>
									<Button
										variant={"contained"}
										color={"primary"}
										sx={{ px: 5 }}
										type={"submit"}>
										save
									</Button>
								</Stack>
							</Stack>
						</DialogContent>
					</Dialog>
				</Portal>
				{
					(userContext?.profile?.uid === profile?.uid && showEditPattren.test(window.location.pathname)) || regexAdmin.test(window?.location?.pathname) &&
					<Stack direction={"row"} justifyContent="flex-end"
						   alignItems="center" sx={{width: '100%'}} >
						<Button variant={"contained"} color={"success"} size={"small"} startIcon={<AddIcon/>} onClick={() => {
							setOpenAdd(true);
						}} sx={{px:2}}>Add More</Button>

					</Stack>

				}

			</Stack>

		</>
	);
}
