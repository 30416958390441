import React from "react";
import { Container, Typography, Stack, IconButton, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import DownloadFileHelper from "../../../utils/download-file-helper";
import ScrollToTop from "../../../utils/scrolling-top";
import { shortenURL } from "../../../utils/shortenURL";

const commonStyles = {
  bgcolor: "background.paper",
  m: 1,
  border: 1,
  width: "1198px",
  height: "1525px",
};
export default function WorkExperienceView({ profile, i }) {
  const navigator = useNavigate();
  let user =
    profile?.userType === "business-developer"
      ? "businessDeveloper"
      : profile?.userType === "expert"
      ? "expert"
      : profile?.userType === "service-provider"
      ? "serviceProvider"
      : null;
  commonStyles.height =
    profile?.userType === "business-developer" ? "1525px" : "700px";
  return (
    <>
      <ScrollToTop />
      <Container maxWidth={"lg"} sx={{ textAlign: "left" }}>
        <Stack
          direction={"column"}
          sx={{ width: "100%", textAlign: "left", marginTop: "10px" }}
          spacing={5}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            // spacing={2}
          >
            <IconButton onClick={() => navigator(-0.5)}>
              <ArrowBackIcon sx={{ fontSize: 40 }} />
            </IconButton>

            <Typography variant={"h4"}> Work Experience</Typography>
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ ...commonStyles, borderColor: "black" }}>
              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 120,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  spacing={11}
                >
                  <Typography variant={"h6"} color={"black"}>
                    Company Name:
                  </Typography>
                  <Typography variant={"h6"} color={"black"}>
                    {profile?.userType === "business-developer"
                      ? profile?.businessDeveloper?.experiences[i]?.companyName
                      : profile?.userType === "expert"
                      ? profile?.expert?.experiences[i]?.companyName
                      : profile?.userType === "service-provider"
                      ? profile?.serviceProvider?.experiences[i]?.companyName
                      : null}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  sx={{ marginTop: "10px" }}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  spacing={4}
                >
                  <Typography variant={"h6"} color={"black"}>
                    Company Website URL:
                  </Typography>
                  <Typography variant={"h6"} color={"#233DFF"}>
                    <a
                      href={
                        profile?.userType === "business-developer"
                          ? profile?.businessDeveloper?.experiences[i]?.website
                          : profile?.userType === "expert"
                          ? profile?.expert?.experiences[i]?.website
                          : profile?.userType === "service-provider"
                          ? profile?.serviceProvider?.experiences[i]?.website
                          : null
                      }
                      target={"_blank"}
                    >
                      {shortenURL(
                        profile?.userType === "business-developer"
                          ? profile?.businessDeveloper?.experiences[i]?.website
                          : profile?.userType === "expert"
                          ? profile?.expert?.experiences[i]?.website
                          : profile?.userType === "service-provider"
                          ? profile?.serviceProvider?.experiences[i]?.website
                          : null,
                        50
                      )}
                    </a>
                  </Typography>
                </Stack>
                {profile?.userType === "business-developer" && (
                  <Stack direction={"row"} sx={{ marginTop: "10px" }}>
                    <Typography
                      sx={{ width: "250px" }}
                      variant={"h6"}
                      color={"black"}
                    >
                      Company Current Stage:
                    </Typography>
                    <Typography variant={"h6"} color={"black"}>
                      {profile?.businessDeveloper?.experiences[
                        i
                      ]?.companyCurrentStage.map((stage) => `${stage} `)}
                    </Typography>
                  </Stack>
                )}
              </Stack>

              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 120,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  spacing={24.5}
                >
                  <Typography variant={"h6"} color={"black"}>
                    Role:
                  </Typography>
                  <Typography variant={"h6"} color={"black"}>
                    {profile?.userType === "business-developer"
                      ? profile?.businessDeveloper?.experiences[i]?.role
                      : profile?.userType === "expert"
                      ? profile?.expert?.experiences[i]?.role
                      : profile?.userType === "service-provider"
                      ? profile?.serviceProvider?.experiences[i]?.role
                      : null}
                  </Typography>
                </Stack>
                {profile?.userType === "business-developer" && (
                  <Stack
                    direction={"row"}
                    spacing={16}
                    sx={{ marginTop: "8px" }}
                  >
                    <Typography variant={"h6"} color={"black"}>
                      Type of Role:
                    </Typography>
                    <Typography variant={"h6"} color={"black"}>
                      {profile?.businessDeveloper?.experiences[
                        i
                      ]?.typeOfRole.map((type) => `${type} `)}
                    </Typography>
                  </Stack>
                )}
                <br></br>
                <Stack direction={"row"} spacing={21.5}>
                  <Typography variant={"h6"} color={"black"}>
                    Tenure:
                  </Typography>
                  <Typography variant={"h6"} color={"black"}>
                    {profile?.userType === "business-developer"
                      ? `${
                          profile?.businessDeveloper?.experiences[i]?.startDate
                        } - ${
                          profile?.businessDeveloper?.experiences[i]
                            ?.currentlyWorkingInThisRole
                            ? "Present"
                            : profile?.businessDeveloper?.experiences[i]
                                ?.endDate
                        }`
                      : profile?.userType === "expert"
                      ? `${profile?.expert?.experiences[i]?.startDate} - ${
                          profile?.expert?.experiences[i]
                            ?.currentlyWorkingInThisRole
                            ? "Present"
                            : profile?.expert?.experiences[i]?.endDate
                        }`
                      : profile?.userType === "service-provider"
                      ? `${
                          profile?.serviceProvider?.experiences[i]?.startDate
                        } - ${
                          profile?.serviceProvider?.experiences[i]
                            ?.currentlyWorkingInThisRole
                            ? "Present"
                            : profile?.serviceProvider?.experiences[i]?.endDate
                        }`
                      : null}
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems="flex-start"
                sx={{
                  minHeight: 140,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack
                  // direction={"row"}
                  // spacing={14}
                  sx={{ marginTop: "-8px" }}
                >
                  <Typography variant={"h5"} color={"black"}>
                    <br></br>
                    Experience
                  </Typography>
                  <Typography variant={"h6"} color={"black"}>
                    <br></br>
                    {profile?.userType === "business-developer"
                      ? profile?.businessDeveloper?.experiences[i]?.experience
                      : profile?.userType === "expert"
                      ? profile?.expert?.experiences[i]?.experience
                      : profile?.userType === "service-provider"
                      ? profile?.serviceProvider?.experiences[i]?.experience
                      : null}
                  </Typography>
                </Stack>
              </Stack>
              {profile?.userType === "business-developer" && (
                <Stack
                  direction={"column"}
                  justifyContent="left"
                  alignItems="flex-start"
                  sx={{
                    minHeight: 140,
                    width: "100%",
                    borderBottom: "1px solid #ccc",
                    p: 1,
                    px: 3,
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={10}
                    sx={{ marginTop: "8px" }}
                  >
                    <Typography variant={"h6"} color={"black"}>
                      Company stage during your employment:
                    </Typography>
                    <Typography variant={"h6"} color={"black"}>
                      {profile?.userType === "business-developer"
                        ? profile?.businessDeveloper?.experiences[
                            i
                          ]?.companyStageDuringEmployment.map(
                            (csde) => `${csde} `
                          )
                        : null}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent="left"
                    alignItems="flex-start"
                  >
                    <Stack
                    // direction={"row"}
                    // spacing={14}
                    >
                      <Typography variant={"h5"} color={"black"}>
                        <br></br>
                        Product Description
                      </Typography>
                      <Typography variant={"h6"} color={"black"}>
                        <br></br>
                        {
                          profile?.businessDeveloper?.experiences[i]
                            ?.productDescription
                        }
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={20}
                    sx={{ marginTop: "12px" }}
                  >
                    <Typography variant={"h6"} color={"black"}>
                      Business Model
                    </Typography>
                    <Typography variant={"h6"} color={"black"}>
                      {profile?.businessDeveloper?.experiences[
                        i
                      ]?.businessModel.map((csde) => `${csde} `)}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={6.5}
                    sx={{ marginTop: "12px" }}
                  >
                    <Typography
                      variant={"h6"}
                      sx={{ width: "450px" }}
                      color={"black"}
                    >
                      Who do you sell to?*
                    </Typography>
                    <Typography variant={"h6"} color={"black"}>
                      {
                        profile?.businessDeveloper?.experiences[i]
                          ?.whoDoYouSellTo
                      }
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={12}
                    sx={{ marginTop: "12px" }}
                  >
                    <Typography variant={"h6"} color={"black"}>
                      Where do you sell to?*
                    </Typography>
                    <Typography variant={"h6"} color={"black"}>
                      {profile?.businessDeveloper?.experiences[
                        i
                      ]?.whereDoYouSell.map((csde) => `${csde} `)}
                    </Typography>
                  </Stack>
                </Stack>
              )}
              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 140,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack
                  direction={"column"}
                  // spacing={14}
                  sx={{ marginTop: "-30px" }}
                >
                  <Typography variant={"h5"} color={"black"}>
                    <br></br>
                    Attachments
                  </Typography>
                  {profile[user].experiences[0].attachments.map(
                    (attachment) => {
                      return (
                        <>
                          <Stack direction="row">
                            <Box
                              sx={{
                                p: "2px 10px",
                                display: "flex",
                                alignItems: "center",
                                width: 337,
                                height: 43,
                                borderRadius: "12px",
                                backgroundColor: "#E0E3FF",
                                marginTop: "33px",
                              }}
                            >
                              <IconButton type="button" aria-label="search">
                                <img src="/assets/image5.png" alt="" />
                              </IconButton>
                              {attachment.split("/").splice(-1)}
                              <Stack
                                sx={{
                                  marginLeft: "60px",
                                }}
                              >
                                <IconButton type="button" aria-label="search">
                                  <img
                                    src="/assets/img2.png"
                                    alt=""
                                    onClick={() => {
                                      DownloadFileHelper(attachment);
                                    }}
                                  />
                                </IconButton>
                              </Stack>
                            </Box>
                          </Stack>
                        </>
                      );
                    }
                  )}
                </Stack>
              </Stack>

              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 140,
                  width: "100%",
                  // borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Stack
                  direction={"column"}
                  // spacing={14}
                  sx={{ marginTop: "-30px" }}
                >
                  <Typography variant={"h5"} color={"black"}>
                    <br></br>
                    Additional Link
                  </Typography>
                  <Stack direction="row">
                    {(profile?.businessDeveloper?.experiences[i]?.link ||
                      profile?.expert?.experiences[i]?.link ||
                      profile?.serviceProvider?.experiences[i]?.link) && (
                      <Box
                        sx={{
                          p: "2px 10px",
                          display: "flex",
                          alignItems: "center",
                          width: 337,
                          height: 43,
                          borderRadius: "12px",
                          backgroundColor: "#E0E3FF",
                          marginTop: "33px",
                        }}
                      >
                        <Typography
                          variant={"caption"}
                          sx={{ textAlign: "left", fontSize: "16px" }}
                        >
                          <a
                            href={
                              profile?.userType === "business-developer"
                                ? profile?.businessDeveloper?.experiences[i]
                                    ?.link
                                : profile?.userType === "expert"
                                ? profile?.expert?.experiences[i]?.link
                                : profile?.userType === "service-provider"
                                ? profile?.serviceProvider?.experiences[i]?.link
                                : null
                            }
                            target={"_blank"}
                          >
                            {
                              shortenURL(
                                profile?.businessDeveloper?.experiences[i]
                                  ?.link,
                                30
                              ) ||
                                shortenURL(
                                  profile?.expert?.experiences[i]?.link,
                                  30
                                ) ||
                                shortenURL(
                                  profile?.serviceProvider?.experiences[i]
                                    ?.link,
                                  30
                                )

                              //   profile?.userType === 'business-developer' &&
                              //   profile?.businessDeveloper?.experiences[i]?.link.length < 30
                              //   ? profile?.businessDeveloper?.experiences[i]?.link
                              //   : `${profile?.businessDeveloper?.experiences[i]?.link.slice(0 , 30)}...`

                              //  ||

                              //   profile?.userType === 'expert' &&
                              //   profile?.expert?.experiences[i]?.link.length < 30
                              //   ? profile?.expert?.experiences[i]?.link
                              //   : `${profile?.expert?.experiences[i]?.link.slice(0 , 30)}...`

                              //   ||

                              //   profile?.userType === 'service-provider' &&
                              //   profile?.serviceProvider?.experiences[i]?.link.length < 30
                              //   ? profile?.serviceProvider?.experiences[i]?.link
                              //   : `${profile?.serviceProvider?.experiences[i]?.link.slice(0 , 30)}...`
                            }
                          </a>
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
