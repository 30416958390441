import React from "react";
import {
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate,
  Navigate, useParams,
} from "react-router-dom";
import {
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import AdminDashboard from "../screens/admin/dashboard";
import CommonDetails from "../screens/common/common-details";
import StartupFounderProfile from "../screens/common/startup/founder-profile";
import SettingScreen from "../screens/common/setting-screen";
import ChangePasswordScreen from "../screens/common/change-password-screen";
import ChatScreen from "../screens/common/chat-screen";
import ManageAdmin from "../screens/admin/manage-admin";
import ServiceProviderOrganisationProfile from "../screens/common/service-provider/organisation-profile";
import SelectUserType from "../screens/admin/onboarding/select-user-type";
import ContactInformation from "../screens/admin/onboarding/contact-info";
import FillStartupProfile from "../screens/admin/onboarding/fill-startup-profile";
import FillInvestorProfile from "../screens/admin/onboarding/fill-inverstor-profile";
import FillExpertProfile from "../screens/admin/onboarding/fill-expert-profile";
import FillBusinessDeveloperProfile from "../screens/admin/onboarding/fill-business-developer-profile";
import FillServiceProviderProfile from "../screens/admin/onboarding/fill-service-provider-profile";
import OffersDashboard from "../screens/admin/offers-dashboard";
import AddOffer from "../screens/common/offers/add-offers";
import OffersListCards from "../screens/admin/offers-list-cards";
import OfferDetails from "../screens/common/offer-details";
import UserProfileView from "../screens/admin/user-profile-view";
import FounderProfileView from "../screens/common/startup/founder-profile-view";
import MyOrganisationProfile from "../screens/investor/my-organisation-profile";
import { RequestsListAdmin } from "../screens/admin/request/requests-list.js";
import RequestDetailsAdmin from "../screens/admin/request/request-details";
import { BestMatchesList } from "../screens/admin/request/best-matches-list";
import { ApplicantsList } from "../screens/admin/request/applicants-list";
import StickyHeadTable from "../screens/admin/request/suggest-users-list";
import DraftProfiles from "../screens/admin/draft-profiles";
import {Dashboard} from "@mui/icons-material";
import ContractsList from "../screens/admin/contracts-list";
import ContractDetails from "../screens/admin/contract-details";

const AdminRoutes = () => {
  const location = useLocation();
  const navigator = useNavigate();
  
  const dashboardRegx =
    /dashboard|user-details|startup-founder-profile|on-boarding/;
  const chatRegx = /chat/;
  const adminManageRegx = /manage-admin/;
  const contractsRegx = /contracts|contract-details/;
  const adminRequestRegx = /request/;
  const adminOffersRegx = /offers/
  const adminDraftRegx = /draft-profiles/
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
      <Route
        path="admin"
        element={
          <Container
            maxWidth={"full-width"}
            sx={{ paddingLeft: "0px" }}
            disableGutters={true}
          >
            <Grid container direction="row" justifyContent="flex-start">
              <Grid
                sx={{
                  backgroundColor: "#F5F5F5",
                  width: 300,
                  height: "calc(100vh - 85px)",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 300,
                    fontWeight: 700,
                    pl: 0.5,
                    py: 5,
                  }}
                  component="nav"
                >
                  <ListItemButton
                    sx={{
                      borderLeft: dashboardRegx.test(location.pathname)
                        ? "8px solid #000"
                        : "none",
                    }}
                    onClick={() => navigator("/admin/dashboard")}
                  >
                    <ListItemText
                      primary={
                        <Typography variant={"h6"} fontWeight={600} ml={3}>
                          Dashboard
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      borderLeft: contractsRegx.test(location.pathname)
                        ? "8px solid #000"
                        : "none",
                    }}
                    onClick={() => navigator("/admin/contracts")}
                  >
                    <ListItemText
                      primary={
                        <Typography variant={"h6"} fontWeight={600} ml={3}>
                          Contracts
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      borderLeft: chatRegx.test(location.pathname)
                        ? "8px solid #000"
                        : "none",
                    }}
                    onClick={() => navigator("/admin/chat")}
                  >
                    <ListItemText
                      primary={
                        <Typography variant={"h6"} fontWeight={600} ml={3}>
                          Chat&nbsp;/&nbsp;Support
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      borderLeft: adminManageRegx.test(location.pathname)
                        ? "8px solid #000"
                        : "none",
                    }}
                    onClick={() => navigator("/admin/manage-admin")}
                  >
                    <ListItemText
                      primary={
                        <Typography variant={"h6"} fontWeight={600} ml={3}>
                          + New admin
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      borderLeft: adminOffersRegx.test(location.pathname)
                        ? "8px solid #000"
                        : "none",
                    }}
                    onClick={() => navigator("/admin/offers/claims")}
                  >
                    <ListItemText
                      primary={
                        <Typography variant={"h6"} fontWeight={600} ml={3}>
                          Offers
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      borderLeft: adminRequestRegx.test(location.pathname)
                        ? "8px solid #000"
                        : "none",
                    }}
                    onClick={() => navigator("/admin/request/list")}
                  >
                    <ListItemText
                      primary={
                        <Typography variant={"h6"} fontWeight={600} ml={3}>
                          Matches/Post Requests
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      borderLeft:  adminDraftRegx.test(location.pathname)
                        ? "8px solid #000"
                        : "none",
                    }}
                    onClick={() => navigator("/admin/draft-profiles")}
                  >
                    <ListItemText
                      primary={
                        <Typography variant={"h6"} fontWeight={600} ml={3} >
                          Draft Profiles
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              </Grid>
              <Grid
                sx={{
                  width: "calc(100% - 300px)",
                  backgroundColor: "#fff",
                  mt: 0.2,
                  py: 1,
                  height: "calc(100vh - 85px)",
                  overflowY: "scroll",
                }}
              >
                <Outlet />
              </Grid>
            </Grid>
          </Container>
        }
      >
        <Route path={"dashboard"} element={<AdminDashboard />} />
        <Route path={"draft-profiles"} element={<DraftProfiles />} />
        <Route path={"offers/claims"} element={<OffersDashboard />} />
        <Route path={"offers/add-offer"} element={<AddOffer />} />
        <Route path={"offers/list"} element={<OffersListCards />} />
        <Route path={"offers/details/:offerId"} element={<OfferDetails />} />
        <Route path={"offers/edit/:offerId"} element={<AddOffer />} />
        <Route path={"request/list"} element={<RequestsListAdmin />} />
        <Route path={"request/applicants/list"} element={<ApplicantsList />} />
        <Route path={"request/details"} element={<RequestDetailsAdmin />} />
        <Route path={"request/matches/list"} element={<BestMatchesList />} />
        <Route path={'request/suggest/user/list'} element={<StickyHeadTable />} />
        <Route path={"manage-admin"} element={<ManageAdmin />} />
        <Route path={"user-details"} element={<UserProfileView />} />
        <Route path={"user/details/:uid"} element={<CommonDetails />} />
        <Route
          path={"startup/startup-founder-profile"}
          element={<FounderProfileView />}
        />
        <Route path={"settings"} element={<SettingScreen />} />
        <Route path={"contracts"} element={<ContractsList />} />
        <Route path={"contract-details"} element={<ContractDetails />} />
        <Route path={"change-password"} element={<ChangePasswordScreen />} />
        <Route path={"chat"} element={<ChatScreen />} />
        <Route
          path={"organisation-profile"}
          element={<ServiceProviderOrganisationProfile />}
        />
        <Route
          path={"organisation-profile-investor"}
          element={<MyOrganisationProfile />}
        />
        <Route path={"on-boarding/type"} element={<SelectUserType />} />
        <Route path={"on-boarding/type/:uid"} element={<SelectUserType />} />
        <Route
          path={"on-boarding/contact-info"}
          element={<ContactInformation />}
        />
        <Route
          path={"on-boarding/contact-info/:uid"}
          element={<ContactInformation />}
        />
        <Route
          path={"on-boarding/startup/profile"}
          element={<FillStartupProfile />}
        />
        <Route
          path={"on-boarding/startup/profile/:uid"}
          element={<FillStartupProfile />}
        />
        <Route
          path={"on-boarding/investor/profile"}
          element={<FillInvestorProfile />}
        />
        <Route
          path={"on-boarding/investor/profile/:uid"}
          element={<FillInvestorProfile />}
        />
        <Route
          path={"on-boarding/expert/profile"}
          element={<FillExpertProfile />}
        />
        <Route
          path={"on-boarding/expert/profile/:uid"}
          element={<FillExpertProfile />}
        />
        <Route
          path={"on-boarding/business-developer/profile"}
          element={<FillBusinessDeveloperProfile />}
        />
        <Route
          path={"on-boarding/business-developer/profile/:uid"}
          element={<FillBusinessDeveloperProfile />}
        />
        <Route
          path={"on-boarding/service-provider/profile"}
          element={<FillServiceProviderProfile />}
        />
        <Route
          path={"on-boarding/service-provider/profile/:uid"}
          element={<FillServiceProviderProfile />}
        />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
