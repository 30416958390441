import * as React from "react";
import { AuthUserContext } from "../../providers/auth-provider";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  Typography,
  Box,
  styled,
  Portal,
  Dialog,
  DialogContent,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import { doc, getDoc, deleteDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckIcon from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import OffersRightPanel from "../../components/common/timeline/offer-right-panel";
import OfferClaimCard from "../../components/common/offer/offer-claim-card";
import RemainingHoursOrDate from "../../components/common/offer/offer-date-format";
const FilledCircleCheckedIcon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 21px;
  border-radius: 50%;
  background-color: blue; 
`;

export default function OfferDetailsClaim() {
  const { profile } = React.useContext(AuthUserContext);
  const [open, setOpen] = React.useState(false);
  const { offerId } = useParams();
  const [offer, setOffer] = React?.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigator = useNavigate();
  const [selectedOption, setSelectedOption] = React.useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedOption(offer && offer?.isActive ? 'active' : 'close');
  };
  React?.useEffect(() => {
    getOffers(offerId);
  }, [offerId]);

  const getOffers = async (offerId) => {
    const offerRef = doc(DB, "offers", offerId);
    const myOffer = await getDoc(offerRef)
    await setOffer({ id: offerId, ...myOffer.data() });
    debugger
    setSelectedOption(offer && offer?.isActive ? 'active' : 'close');
  }
  return (
    <>
    <Container maxWidth={'xl'} sx={{ my: 5, textAlign: "left" }}>
      <Stack direction={'row'} spacing={5} justifyContent={'flex-start'} sx={{width: '100%', mb: 5}}>
          <div style={{flex: 1}}>
            <Typography variant={'h4'} sx={{fontWeight: 700}}>Hello {profile?.displayName} &nbsp; 👋</Typography>
            <Typography variant={"body1"} color={'secondary'}>Discover amazing deals and exclusive offers just for you!</Typography>
          </div>
        </Stack>
      </Container>


      {offer && <Container maxWidth={"xl"} sx={{ py: 5 }}>
       <Stack direction={"row"} spacing={10} justifyContent={"center"}>
       <Box maxWidth={230} />
        
       <Box
            sx={{
              px:3,
              py:4,
              flex: 1,
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              background: "#fff",
              borderRadius: "15px",
              textAlign: "left",

            }}
          >

              <Stack
                direction={"row"}
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                  <IconButton sx={{width:32, height:32}} onClick={() => navigator(-1)}>
                  <ArrowBackIcon sx={{ fontSize: 32 }} />
                  </IconButton>
                  <Stack
                      sx={{ml:2}}
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start">

                    <Typography
                      variant={"h5"}
                       fontWeight={600}
                      sx={{ textAlign: "left", lineHeight: 1 }}>
                          {offer?.companyName}
                    </Typography>
                    <font  style={{fontWeight:500}}>{offer?.name}</font>
                  </Stack>
                  <Box flex={1} />
                  <RemainingHoursOrDate dateString={offer?.to} />
              </Stack>
              <Stack mt={4} direction={'row'} spacing={1} alignItems={'flex-start'} justifyContent={'space-between'}>
              <Stack direction={"column"}>
                <Typography  fontWeight={600} >
                  {offer?.headingOne}
                </Typography>
                <Typography fontWeight={400} >
                  {offer?.headingTwo}
                </Typography>
              </Stack>
              <Box flex={1}/>
              {(Math.floor((new Date().getTime() -  new Date(offer?.from).getTime())/(1000 * 60 *60)))<=24 && //to check if the offer is live for 24 hours
                  <Chip
                  variant="contained"
                  sx={{
                    my: 0,
                    backgroundColor: "#E0E3FF",
                    px: 0.5,
                    maxWidth: 125,
                    height: 24,
                    borderRadius: "5px",
                  }}
                  label={"New"}
                />
          }
              {offer?.isHotOffer &&
                  <IconButton color="primary" sx={{padding:0}}>
                        <img src={'/assets/Fire.png'} alt="Custom Icon" />
                  </IconButton>
              }
              </Stack>

              <Stack
                  direction={"column"}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                 
                >
                  {offer &&
                    offer?.section &&
                    offer?.section?.map((sec, i) => (
                      <>
                        <Stack
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          py={3}
                        >
                          <Stack
                            direction={"column"}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            py={1}
                          >
                            <Typography sx={{ textAlign: "left" }}  fontWeight={600}>
                              {sec.title}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"column"}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            py={1}
                          >
                            <Typography
                              
                              fontWeight={400}
                              component="pre"
                              sx={{
                                textAlign: "left",
                                wordBreak: "break-word",
                                display: "inline-block",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {sec.description}
                            </Typography>
                          </Stack>
                        </Stack>
                        {i !== offer?.section.length - 1 ? (
                          <hr style={{padding:0,opacity:0.5, width:"100%"}}/>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </Stack>
             
            
          </Box>


          <Stack direction={"column"} spacing={3} sx={{ width: 330 }}>
            {offer &&
              <OfferClaimCard
                sx={{ maxWidth: '100%' }}
                offer={offer}
                callback={(e) => {
                  e.stopPropagation();
                }}
                details="true"

              />
            }
            <OffersRightPanel sx={{ maxWidth: '100%' }} screen="offer-details" width="true" />

          </Stack>

        
        </Stack>
      </Container>}
      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"sm"}
        >
          <DialogContent sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={2}>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                alignItems={"center"}
              >
                <img
                  src={"/assets/delete-confirmation.png"}
                  height={74}
                  width={80}
                ></img>
              </Stack>
              <Typography
                component="span"
                variant="h6"
                color="text.primary"
                sx={{
                  textAlign: "center",
                  flex: 1,
                  padding: 0,
                  fontWeight: 500,
                }}
              >
                Delete Offer
              </Typography>
              <Typography variant={"body2"} sx={{ textAlign: "center" }}>
                Are you sure you want to delete this offer? <br />
                This action cannot be undone.
              </Typography>
              <br />
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Button
                  variant={"outlined"}
                  sx={{
                    px: 5,
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  variant={"contained"}
                  sx={{
                    px: 5,
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  yes
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </>
  );
}
