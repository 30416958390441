import React from "react";
import {
	Button,
	Container, MenuItem,
	Stack,
	TextField,Typography
} from "@mui/material";
import { useFormik } from "formik";
import ServiceProviderDetailsValidation from "../../../validations/service-provider/provider-profile";
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";

export default function ServiceProviderFirstForm({ cbBack, cbNext, data, cbDraft, uid }) {
	
	const isAdmin = /admin/;

	const formikFirst = useFormik({
		initialValues: data
			? data
			: ServiceProviderDetailsValidation.ProviderFirstFormValues,
		validationSchema:
		ServiceProviderDetailsValidation.ProviderFirstForm,
		onSubmit: async (values) => {

			cbNext({
				...values
			});
		},
	});
	React.useEffect(() => {
		if (uid) {
			setTimeout(() => {
				getDoc(doc(DB, 'users', uid))
					.then((document) => {
						if (document?.data()?.formFirstData) {
							formikFirst.setValues(document?.data()?.formFirstData);
						}
					})
			}, 500 )
		}
		
	}, [data])
	return (
		<Stack
			direction={"column"}
			spacing={10}
			component="form"
			onSubmit={(e) => formikFirst.handleSubmit(e)}>
			<Container maxWidth={"md"}>
				<Stack direction={"column"} spacing={2}>
				<Stack direction={'column'}>
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Service Provider Type *
							  </Typography>
					<TextField
						// required
						name={"organisationType"}
						value={formikFirst.values.organisationType}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.organisationType &&
							Boolean(formikFirst.errors.organisationType)
						}
						helperText={
							formikFirst.touched.organisationType &&
							formikFirst.errors.organisationType
						}
						//label={"Service Provider Type"}
						select
						sx={{textAlign: 'left'}}
					>
						<MenuItem value={'Organisation'} >
							Organisation
						</MenuItem>
						<MenuItem value={'Individual'} >
							Individual
						</MenuItem>
					</TextField>
					</Stack>

				</Stack>
			</Container>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3}
				width={"100%"}>
				{isAdmin.test(window.location.pathname) && <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={() => {
						cbDraft(formikFirst.values)
					}}>
					Save draft
				</Button>}
				<Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Back
				</Button>
				<Button
					variant={"contained"}
					color={"primary"}
					sx={{ px: 5 }}
					type={"submit"}>
					Next
				</Button>
				
				
			</Stack>
		</Stack>
	);
}
