import React, { useEffect } from "react";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Tooltip, Typography, FormControlLabel, Switch, FormControl, FormLabel, FormHelperText, useMediaQuery, CircularProgress
} from "@mui/material";
import { useFormik } from "formik";
import StartupOnBoardingValidation from "../../../validations/startup/startup-profile";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';
import dataJson from '../../../const/data.json';
import { BUSINESS_DEVELOPER } from "../../../utils/enums/labels.enum";
export default function EditStartupTopPrioritiesDetailsForm({ cbBack, cbNext, data, isLoading }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const formikFirst = useFormik({
    initialValues: data
      ? data
      : StartupOnBoardingValidation.TopPrioritiesValues,
    validationSchema: StartupOnBoardingValidation.TopPrioritiesSchema,
    onSubmit: async (values) => {
      debugger
      cbNext({
        ...values,
      });
    }
  });
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>

          <FormControl component="fieldset" variant="standard">
            <FormLabel
              component="legend"
              sx={{ textAlign: "left", mb: 2 }}>
              Looking for {BUSINESS_DEVELOPER.SINGULAR_LABEL}?
            </FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch
                    name={"lookingForBusinessDeveloper"}
                    checked={
                      formikFirst.values
                        .lookingForBusinessDeveloper
                    }
                    onChange={formikFirst.handleChange}
                  />
                }
              />
              <Typography>Yes</Typography>

              <FormHelperText
                error={
                  formikFirst.touched.lookingForBusinessDeveloper &&
                  Boolean(
                    formikFirst.errors.lookingForBusinessDeveloper
                  )
                }
                children={
                  formikFirst.touched.lookingForBusinessDeveloper &&
                  formikFirst.errors.lookingForBusinessDeveloper
                }
              />
            </Stack>
          </FormControl>
          <FormControl component="fieldset" variant="standard">
            <FormLabel
              component="legend"
              sx={{ textAlign: "left", mb: 2 }}>
              Looking for Experts?
            </FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch
                    name={"lookingForExperts"}
                    checked={
                      formikFirst.values
                        .lookingForExperts
                    }
                    onChange={formikFirst.handleChange}
                  />
                }
              />
              <Typography>Yes</Typography>

              <FormHelperText
                error={
                  formikFirst.touched
                    .lookingForExperts &&
                  Boolean(
                    formikFirst.errors
                      .lookingForExperts
                  )
                }
                children={
                  formikFirst.touched
                    .lookingForExperts &&
                  formikFirst.errors
                    .lookingForExperts
                }
              />
            </Stack>
          </FormControl>
          <FormControl component="fieldset" variant="standard">
            <FormLabel
              component="legend"
              sx={{ textAlign: "left", mb: 2 }}>
              Looking for Service Provider?
            </FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch
                    name={"lookingForServiceProvider"}
                    checked={
                      formikFirst.values
                        .lookingForServiceProvider
                    }
                    onChange={formikFirst.handleChange}
                  />
                }
              />
              <Typography>Yes</Typography>

              <FormHelperText
                error={
                  formikFirst.touched
                    .lookingForServiceProvider &&
                  Boolean(
                    formikFirst.errors
                      .lookingForServiceProvider
                  )
                }
                children={
                  formikFirst.touched
                    .lookingForServiceProvider &&
                  formikFirst.errors.lookingForServiceProvider
                }
              />
            </Stack>
          </FormControl>
          <FormControl component="fieldset" variant="standard">
            <FormLabel
              component="legend"
              sx={{ textAlign: "left", mb: 2 }}>
              Looking for Funding?
            </FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch
                    name={"lookingForFunding"}
                    checked={
                      formikFirst.values.lookingForFunding
                    }
                    onChange={formikFirst.handleChange}
                  />
                }
              />
              <Typography>Yes</Typography>

              <FormHelperText
                error={
                  formikFirst.touched.lookingForFunding &&
                  Boolean(formikFirst.errors.lookingForFunding)
                }
                children={
                  formikFirst.touched.lookingForFunding &&
                  formikFirst.errors.lookingForFunding
                }
              />
            </Stack>
          </FormControl>

        </Stack>
      </Container>
      <Stack
        direction={"row"}
        justifyContent="flex-end"
        alignItems="center"
        spacing={3}
        width={"100%"}>
        {
          matches ? <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={3}
                  width={"100%"}>
                <Button
                    variant={"outlined"}
                    color={"primary"}
                    sx={{ px: 5 }}
                    onClick={cbBack}>
                  Back
                </Button>
                {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={{ px: 5 }}
                    type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
                  save
                </Button>
              </Stack> :
              <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={3}
                  width={"100%"}
                  sx={{px: !matches ? 2 : ''}}
              >
                {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={{ px: 5 }}
                    type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
                  save
                </Button>
              </Stack>
        }
      </Stack>
    </Stack>
  );
}
