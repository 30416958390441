import React from "react";
import {
  getStorage,
  ref,deleteObject
} from "firebase/storage";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Badge,
  IconButton,
  FormControlLabel,
  useMediaQuery,
  styled,
  CircularProgress,
  Link,
  Grid,
  Checkbox,
  Portal
} from "@mui/material";
import { useFormik, FieldArray, getIn, FormikProvider} from "formik";
import ExportOnboardingValidation from "../../../../validations/expert/onboarding-profile";
import DragDropFiles, {DragDropItem} from "../../../../components/common/drag-drop-files";
import UploadFile from "../../../../utils/upload-file";
import {AuthUserContext} from "../../../../providers/auth-provider";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {ArrowBack} from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
export default function ExperienceForm({ cbBack, cbNext, data, index, open, handleClose, uid }) {
  const { user } = React.useContext(AuthUserContext);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const Input = styled("input")({
    display: "none",
  });

  const fileTypes = [
    "JPG",
    "JPEG",
    "PNG",
    "GIF",
    "DOC",
    "DOCX",
    "PDF",
    "MOV",
    "XLS",
    "CSV",
  ];
  const [files, setFiles] = React.useState(data && data?.attachments ? data?.attachments : []);


  const formikFirst = useFormik({
    initialValues: data
      ? data
      : ExportOnboardingValidation.ExperienceFormValues,
    validationSchema:
    ExportOnboardingValidation.ExperienceForm,
    onSubmit: async (values) => {
      debugger
      cbNext({
        ...values, attachments:files.length > 0 ? files : []
      }, index);
    },
  });
  const [profileImage, setProfileImage] = React.useState({
    src: data?.photoURL || "",
    photoURL: data?.photoURL || "",
  });
  const [loading, setLoading] = React.useState(false);
  if (matches) {
    return (
      <FormikProvider value={formikFirst}>

        <Stack
          direction={"column"}
          spacing={10}
          component="form"
          onSubmit={(e) => formikFirst.handleSubmit(e)}>
          <Container maxWidth={"md"}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={
                3
              }>
              <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                <Typography variant={'body1'}>
                  Company Name*
                </Typography>
                <TextField name={'companyName'}
                           value={formikFirst.values.companyName}
                           onChange={formikFirst.handleChange}
                           error={
                             formikFirst.touched.companyName &&
                             Boolean(formikFirst.errors.companyName)
                           }
                           helperText={
                             formikFirst.touched.companyName &&
                             formikFirst.errors.companyName
                           }
                           variant={'outlined'} fullWidth/>
              </Stack>

              <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                <Typography variant={'body1'}>
                  Company website URL
                </Typography>
                <TextField variant={'outlined'} name={'website'} fullWidth
                           value={formikFirst.values.website}
                           onChange={formikFirst.handleChange}
                           error={
                             formikFirst.touched.website &&
                             Boolean(formikFirst.errors.website)
                           }
                           helperText={
                             formikFirst.touched.website &&
                             formikFirst.errors.website
                           }
                />
              </Stack>
              <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                <Typography variant={'body1'}>
                  Role*
                </Typography>
                <TextField variant={'outlined'} name={'role'} fullWidth
                           value={formikFirst.values.role}
                           onChange={formikFirst.handleChange}
                           error={
                             formikFirst.touched.role &&
                             Boolean(formikFirst.errors.role)
                           }
                           helperText={
                             formikFirst.touched.role &&
                             formikFirst.errors.role
                           }
                />
                <FormControlLabel control={<Checkbox name={'currentlyWorkingInThisRole'}
                                                     onChange={formikFirst.handleChange}
                                                     checked={formikFirst?.values?.currentlyWorkingInThisRole} />}
                                                     label="I am currently working in this role"
                                                      />


                {/* {console.log(formikFirst.values.currentlyWorkingInThisRole)} */}
              </Stack>
              <Stack direction={'column'} spacing={3} alignItems={"flex-start"} sx={{width: '100%'}}>
                <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                  <Typography variant={'body1'}>
                    Start date
                  </Typography>
                  <TextField variant={'outlined'} name={'startDate'} fullWidth
                             value={formikFirst.values.startDate}
                             onChange={formikFirst.handleChange}
                             error={
                               formikFirst.touched.startDate &&
                               Boolean(formikFirst.errors.startDate)
                             }
                             helperText={
                               formikFirst.touched.startDate &&
                               formikFirst.errors.startDate
                             }
                             type={'date'}
                  />
                </Stack>
                <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                  <Typography variant={'body1'}>
                    End date
                  </Typography>
                  <TextField variant={'outlined'} name={'endDate'} fullWidth
                             value={formikFirst.values.endDate}
                             onChange={formikFirst.handleChange}
                             error={
                               formikFirst.touched.endDate &&
                               Boolean(formikFirst.errors.endDate)
                             }
                             helperText={
                               formikFirst.touched.endDate &&
                               formikFirst.errors.endDate
                             }
                             type={'date'}
                             disabled={formikFirst?.values?.currentlyWorkingInThisRole}
                  />
                </Stack>
              </Stack>

              <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                <Typography variant={'body1'}>
                  Experience*
                </Typography>
                <TextField multiline rows={4} variant={'outlined'} name={'experience'} fullWidth
                           value={formikFirst.values.experience}
                           onChange={formikFirst.handleChange}
                           error={
                             formikFirst.touched.experience &&
                             Boolean(formikFirst.errors.experience)
                           }
                           helperText={
                             formikFirst.touched.experience &&
                             formikFirst.errors.experience
                           }/>
              </Stack>

              {/* <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
              <Typography variant={'body1'}>
                Social Proof*
              </Typography>
              <TextField multiline rows={4} variant={'outlined'} name={'socialProof'} fullWidth


                         value={formikFirst.values.socialProof}
                         onChange={formikFirst.handleChange}
                         error={
                           formikFirst.touched.socialProof &&
                           Boolean(formikFirst.errors.socialProof)
                         }
                         helperText={
                           formikFirst.touched.socialProof &&
                           formikFirst.errors.socialProof
                         }
              />
            </Stack> */}


              {/*attachment*/}
              <Stack direction={"column"} sx={{width: '100%'}} spacing={5}>

                <Stack direction={"column"} spacing={2}>
                  <Typography sx={{ textAlign: "left" }}>
                    Attachments{" "}
                    <font style={{ fontWeight: 300 }}>
                      <i> (References, Recommendations etc.)</i>
                    </font>
                  </Typography>

                  <DragDropFiles
                    label={{
                      text: "Drop here or choose from files",
                      caption:
                        "(max file size: 50MB)",
                    }}
                    multiple={true}
                    limit={50}
                    types={fileTypes}
                    onChangeCallback={(fileList) => {
                      let list = [];
                      if (files.length > 0) {
                        list = [...files];
                      }
                      for (let file of fileList) {
                        list.push({ file });
                      }
                      setFiles([...list]);
                    }}
                  />
                  <span />
                  <Grid container
                        gap={2}>
                    {files.length > 0 &&
                      files.map((file, i) => (
                        <Grid sx={{width:'48.5%'}}>
                          <DragDropItem
                            file={file}
                            i={i}
                            key={i}
                            path={file?.file ? `experts/${uid ? uid :user.uid}/experiences/attachments/${file.file.name}` : file}
                            remove={(i) => {
                              setFiles([...files.filter((d, ind) => ind !== i)])
                              // console.log(i);
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Stack>


              </Stack>

              <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                <Typography variant={'body1'}>
                  Additional links
                </Typography>
                <TextField variant={'outlined'} name={'link'} fullWidth
                           value={formikFirst.values.link}
                           onChange={formikFirst.handleChange}
                           error={
                             formikFirst.touched.link &&
                             Boolean(formikFirst.errors.link)
                           }
                           helperText={
                             formikFirst.touched.link &&
                             formikFirst.errors.link
                           }
                />
              </Stack>



            </Stack>


          </Container>
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems="center"
            spacing={3}
            width={"100%"}>
            <Button
              variant={"outlined"}
              color={"primary"}
              sx={{ px: 7 }}
              onClick={cbBack}>
              Back
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{ px: 7 }}
              type={"submit"}>
              save
            </Button>
          </Stack>
        </Stack>
      </FormikProvider>
    );
  } else {
    return (
      <Portal>
        <Dialog
          fullScreen={!matches ? true: false}
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >

          <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
            {!matches && <IconButton onClick={() => {
              cbBack()
              handleClose()
            }}>
              <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
            </IconButton>}
            {`${data ? 'Edit' : 'Add'} Experience Details`}
          </DialogTitle>
          <DialogContent sx={{px: !matches ? 0 : ''}}>
            <FormikProvider value={formikFirst}>

              <Stack
                direction={"column"}
                spacing={10}
                component="form"
                onSubmit={(e) => formikFirst.handleSubmit(e)}>
                <Container maxWidth={"md"}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={
                      3
                    }>
                    <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                      <Typography variant={'body1'}>
                        Company Name*
                      </Typography>
                      <TextField name={'companyName'}
                                 value={formikFirst.values.companyName}
                                 onChange={formikFirst.handleChange}
                                 error={
                                   formikFirst.touched.companyName &&
                                   Boolean(formikFirst.errors.companyName)
                                 }
                                 helperText={
                                   formikFirst.touched.companyName &&
                                   formikFirst.errors.companyName
                                 }
                                 variant={'outlined'} fullWidth/>
                    </Stack>

                    <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                      <Typography variant={'body1'}>
                        Company website URL
                      </Typography>
                      <TextField variant={'outlined'} name={'website'} fullWidth
                                 value={formikFirst.values.website}
                                 onChange={formikFirst.handleChange}
                                 error={
                                   formikFirst.touched.website &&
                                   Boolean(formikFirst.errors.website)
                                 }
                                 helperText={
                                   formikFirst.touched.website &&
                                   formikFirst.errors.website
                                 }
                      />
                    </Stack>
                    <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                      <Typography variant={'body1'}>
                        Role*
                      </Typography>
                      <TextField variant={'outlined'} name={'role'} fullWidth
                                 value={formikFirst.values.role}
                                 onChange={formikFirst.handleChange}
                                 error={
                                   formikFirst.touched.role &&
                                   Boolean(formikFirst.errors.role)
                                 }
                                 helperText={
                                   formikFirst.touched.role &&
                                   formikFirst.errors.role
                                 }
                      />
                      <FormControlLabel control={<Checkbox name={'currentlyWorkingInThisRole'}
                                                           onChange={formikFirst.handleChange}
                                                           checked={formikFirst?.values?.currentlyWorkingInThisRole} />} label="I am currently working in this role" />


                      {/* {console.log(formikFirst.values.currentlyWorkingInThisRole)} */}
                    </Stack>
                    <Stack direction={'column'} spacing={3} alignItems={"flex-start"} sx={{width: '100%'}}>
                      <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                        <Typography variant={'body1'}>
                          Start date
                        </Typography>
                        <TextField variant={'outlined'} name={'startDate'} fullWidth
                                   value={formikFirst.values.startDate}
                                   onChange={formikFirst.handleChange}
                                   error={
                                     formikFirst.touched.startDate &&
                                     Boolean(formikFirst.errors.startDate)
                                   }
                                   helperText={
                                     formikFirst.touched.startDate &&
                                     formikFirst.errors.startDate
                                   }
                                   type={'date'}
                        />
                      </Stack>
                      <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                        <Typography variant={'body1'}>
                          End date
                        </Typography>
                        <TextField variant={'outlined'} name={'endDate'} fullWidth
                                   value={formikFirst.values.endDate}
                                   onChange={formikFirst.handleChange}
                                   error={
                                     formikFirst.touched.endDate &&
                                     Boolean(formikFirst.errors.endDate)
                                   }
                                   helperText={
                                     formikFirst.touched.endDate &&
                                     formikFirst.errors.endDate
                                   }
                                   type={'date'}
                                   disabled={formikFirst?.values?.currentlyWorkingInThisRole}
                        />
                      </Stack>
                    </Stack>

                    <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                      <Typography variant={'body1'}>
                        Experience*
                      </Typography>
                      <TextField multiline rows={4} variant={'outlined'} name={'experience'} fullWidth
                                 value={formikFirst.values.experience}
                                 onChange={formikFirst.handleChange}
                                 error={
                                   formikFirst.touched.experience &&
                                   Boolean(formikFirst.errors.experience)
                                 }
                                 helperText={
                                   formikFirst.touched.experience &&
                                   formikFirst.errors.experience
                                 }/>
                    </Stack>

                    {/* <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
              <Typography variant={'body1'}>
                Social Proof*
              </Typography>
              <TextField multiline rows={4} variant={'outlined'} name={'socialProof'} fullWidth


                         value={formikFirst.values.socialProof}
                         onChange={formikFirst.handleChange}
                         error={
                           formikFirst.touched.socialProof &&
                           Boolean(formikFirst.errors.socialProof)
                         }
                         helperText={
                           formikFirst.touched.socialProof &&
                           formikFirst.errors.socialProof
                         }
              />
            </Stack> */}


                    {/*attachment*/}
                    <Stack direction={"column"} sx={{width: '100%'}} spacing={5}>

                      <Stack direction={"column"} spacing={2}>
                        <Typography sx={{ textAlign: "left" }}>
                          Attachments{" "}
                          <font style={{ fontWeight: 300 }}>
                            <i> (References, Recommendations etc.)</i>
                          </font>
                        </Typography>

                        <DragDropFiles
                          label={{
                            text: "Drop here or choose from files",
                            caption:
                              "(max file size: 50MB)",
                          }}
                          multiple={true}
                          limit={50}
                          types={fileTypes}
                          onChangeCallback={(fileList) => {
                            let list = [];
                            if (files.length > 0) {
                              list = [...files];
                            }
                            for (let file of fileList) {
                              list.push({ file });
                            }
                            setFiles([...list]);
                          }}
                        />
                        <span />
                        <Grid container
                              gap={2}>
                          {files.length > 0 &&
                            files.map((file, i) => (
                              <Grid sx={{width:matches ? '48.5%' : '100%'}}>
                                <DragDropItem
                                  file={file}
                                  i={i}
                                  key={i}
                                  path={file?.file ? `experts/${uid ? uid : user.uid}/experiences/attachments/${file.file.name}` : file}
                                  remove={(i) => {
                                    setFiles([...files.filter((d, ind) => ind !== i)])
                                    console.log(i);
                                  }}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Stack>


                    </Stack>

                    <Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
                      <Typography variant={'body1'}>
                        Additional links
                      </Typography>
                      <TextField variant={'outlined'} name={'link'} fullWidth
                                 value={formikFirst.values.link}
                                 onChange={formikFirst.handleChange}
                                 error={
                                   formikFirst.touched.link &&
                                   Boolean(formikFirst.errors.link)
                                 }
                                 helperText={
                                   formikFirst.touched.link &&
                                   formikFirst.errors.link
                                 }
                      />
                    </Stack>



                  </Stack>


                </Container>
                <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={3}
                  width={"100%"}
                  sx={{pr: 2}}
                >
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={{ px: 7 }}
                    type={"submit"}>
                    {data ? 'Update' : 'save'}
                  </Button>
                </Stack>
              </Stack>
            </FormikProvider>
          </DialogContent>

        </Dialog>
      </Portal>
    )
  }

}
