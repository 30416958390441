import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Container,
  Autocomplete,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import { DB } from "../../../config/firebase/firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { AuthUserContext } from "../../../providers/auth-provider";
import { useLocation, useNavigate } from "react-router-dom";
import PostNotification from "../../../api/post-notification";
import NotificationEmail from "../../../api/notification-email";
import { SUGGESTED_STATUS } from "../../../utils/enums/suggestions-state";
import { USER_TYPES_TO_NAMEC } from "../../../utils/enums/labels.enum";
import SuggestUserDialogue from "./suggest-users-dialogue";
import GetAdminSuuggestedProfilesForAdmin from "../../../api/get-all-admin-suggest-for-admin";

const columns = [
  { id: "full_name", label: "Full Name", minWidth: 170, align: "center" },
  { id: "user_type", label: "User Type", minWidth: 170, align: "center" },
  { id: "email", label: "Email", minWidth: 170, align: "center" },
  { id: "profile", label: "Profile", minWidth: 170, align: "center" },
];

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [inputbtn, setinputbtn] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [users, setUsers] = React.useState();
  const [usersCopy, setUsersCopy] = React.useState();
  const [tempUsers, setTempUsers] = React.useState();
  const [totalCount, setTotalCount] = React.useState(0);
  const { profile } = React.useContext(AuthUserContext);
  const location = useLocation();
  const navigator = useNavigate();
  const { request } = location?.state;
  const [isLoading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const fieldAliases = {
    full_name: "displayName",
    user_type: "userType",
    email: "email",
  };
  const suggest = () => {
    setLoading(true);
    if (selected && selected.length > 0) {
      const selectedLength = selected.length - 1;
      selected.map((userId, index) => {
        if (selectedLength == index) {
          setLoading(false);
          setSelected([]);
          setOpen(true);
        }

        const userRef = doc(DB, "users", userId);
        getDoc(userRef).then((doc) => {
          sendSuggestEmailAndNotification(doc?.data(), request);
        });
      });
    } else {
      setLoading(false);
    }
  };

  const sendSuggestEmailAndNotification = (user, request) => {
    const colRef = doc(
      DB,
      "help-requests",
      request?.id,
      "users-suggested",
      user?.uid
    );
    setDoc(
      colRef,
      {
        status: SUGGESTED_STATUS.PENDING,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      },
      { merge: true }
    ).then(async () => {
      const colRef = doc(
        DB,
        "users",
        user?.uid,
        "requests-suggested",
        request?.id
      );
      setDoc(
        colRef,
        {
          status: SUGGESTED_STATUS.PENDING,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      ).then(() => {
        console.log(user?.email);
        PostNotification({
          type: "suggest-request",
          imageURL: "/assets/system-noti-icon.png",
          message: `Great news! The admin has recommended your profile for a job post.`,
          uid: user?.uid,
          requestFor: request?.requestFor,
          request: request?.id,
        }).then();
        //email and notification
        const data = {
          type: "suggest",
          email: user?.email,
          displayName: request?.creatorData?.displayName,
          title: USER_TYPES_TO_NAMEC[request?.creatorData?.userType],
          url: `/${user.userType}/open-requests-details/${request.id}`,
          photoURL: request?.creatorData?.photoURL,
          requestInfo: {
            description: request?.requestFor,
            requestId: request?.id,
            location: request?.location,
          },
        };
        NotificationEmail({ ...data }).then();
      });
    });
  };
  React.useEffect(() => {
    console.log("a");
    const fetchData = async () => {
      // const [querySnapshot, totalCountSnapshot] = await Promise.all([
      //   getDocs(collection(DB, "users")),
      //   getDocs(collection(DB, "users")),
      // ]);
      const q = query(
        collection(DB, "users"),
        where("uid", "!=", request?.creatorData?.uid),
        where("onboard", "==", true)
      );
      let unsub;
      unsub = onSnapshot(q, (snapshot) => {
        setTotalCount(snapshot?.size);
        let list = [];
        snapshot.forEach((doc) => {
          const userData = doc.data();
          const selectedFields = {};
          Object.entries(fieldAliases).forEach(([alias, fieldPath]) => {
            selectedFields[alias] = userData[fieldPath];
          });
          list.push({
            userId: doc.id,
            ...selectedFields,
            profile: `/${profile.userType}/user/details/${doc.id}`,
          });
        });
        setTempUsers(list);
      });
      return () => unsub;
      // if (!querySnapshot.empty) {
      //   const list = querySnapshot.docs.map((doc) => {
      //     const userData = doc.data();
      //     const selectedFields = {};
      //     Object.entries(fieldAliases).forEach(([alias, fieldPath]) => {
      //       selectedFields[alias] = userData[fieldPath];
      //     });
      //     return {
      //       userId: doc.id,
      //       ...selectedFields,
      //       profile: `/${profile.userType}/user/details/${doc.id}`,
      //     };
      //   });

      //   setTempUsers(list);

      //   if (!totalCountSnapshot.empty) {
      //     setTotalCount(totalCountSnapshot.size);
      //   }
      // }
    };

    fetchData();
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    const fetchProfileMatchesData = async () => {
      const data = await GetAdminSuuggestedProfilesForAdmin(request?.id, request?.creatorData?.uid);
      console.log("suggestProfilesdata", data);
      const list = tempUsers?.filter(
        (user) => !data?.some((item) => item?.id === user?.userId)
      );
      console.log("list after filter", list);
      setUsers(list);
      setUsersCopy(list);
    };

    // Only fetch the profile matches data if the users data is available
    if (tempUsers && tempUsers.length > 0) {
      fetchProfileMatchesData().catch(console.error);
    }
  }, [tempUsers]);
  React.useEffect(() => {
    if (users && users.length > 0 && inputbtn.length > 0) {
      setUsers((prevUsers) => {
        // Make a copy of the users array
        const usersCopy = [...prevUsers];
        const filteredUsers = usersCopy.filter((user) => {
          const full_name = user.full_name || "";
          console.log("full_name check: ", full_name);
          const matchCount =
            full_name.toLowerCase().split(inputbtn.toLowerCase()).length - 1;
          return matchCount > 0;
        });
        return filteredUsers;
      });
    } else if (inputbtn.length === 0) {
      setUsers(usersCopy);
    }
  }, [inputbtn]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((row) => row.userId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, full_name) => {
    const selectedIndex = selected?.indexOf(full_name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, full_name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (full_name) => selected?.indexOf(full_name) !== -1;

  return (
    <>
      <Container maxWidth={"xl"} sx={{ my: 5, textAlign: "left" }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}
        >
          <IconButton onClick={() => navigator(-1)}>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography variant={"h5"} sx={{ textAlign: "left", lineHeight: 1 }}>
            Add More Suggestions
          </Typography>
          <Box flex={1}></Box>
          <Button
            sx={{ borderRadius: "6px" }}
            variant={selected.length > 0 ? "contained" : "disabled"}
            color={selected.length > 0 ? "primary" : "secondary"}
            onClick={() => suggest()}
          >
            Update
          </Button>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}
        >
          <Box
            onSubmit={(event) => event.preventDefault()}
            component="form"
            sx={{
              p: "2px 10px",
              top: "10px",
              display: "flex",
              alignItems: "center",
              width: 500,
              height: 50,
              borderRadius: "8px",
              backgroundColor: "#E4EAF5",
            }}
          >
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={(e) => setSearch(inputbtn)}
              disabled={true}
            >
              <SearchIcon sx={{ color: "#051E40BF" }} />
            </IconButton>
            <Autocomplete
              freeSolo
              disableClearable
              options={[]}
              sx={{
                flex: 1,
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              value={inputbtn}
              onChange={(event, newValue) => {
                setinputbtn(newValue);
              }}
              onInputChange={(event, value) => {
                if (event.type === "change") {
                  setinputbtn(value);
                }
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  setSearch(inputbtn);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    flex: 1,
                    color: "#051E40BF",
                    input: {
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                  }}
                  placeholder="Search"
                />
              )}
            />
          </Box>
        </Stack>
      </Container>

      <TableContainer sx={{ maxHeight: 440, px: 3 }}>
        <Table>
          <TableHead
            sx={{
              background: "#F6F8FA",
              border: "0.997496px solid #8F8F8F",
              borderRadius: "7.97997px",
              marginBottom: "20px",
              py: 3,
            }}
          >
            <TableRow>
              <TableCell>
                <Checkbox
                  indeterminate={
                    selected?.length > 0 && selected?.length < users?.length
                  }
                  checked={selected?.length === users?.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users.length > 0 &&
              users
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  console.log("row", row);
                  const isRowSelected = isSelected(row.userId);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={isRowSelected}
                      sx={{
                        background: "#F6F8FA",
                        border: "0.997496px solid #8F8F8F",
                        borderRadius: "7.97997px",
                        py: 3,
                        opacity: request.users?.includes(row.userId) ? 0.5 : 1,
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          checked={isRowSelected}
                          onClick={(event) => handleClick(event, row.userId)}
                        />
                      </TableCell>
                      {columns &&
                        columns?.length > 0 &&
                        columns?.map((column) => {
                          const value = row[column.id];
                          console.log("profile Id", column.id);
                          if (column.id != "profile") {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <a href={value}>nova/fh.com</a>
                              </TableCell>
                            );
                          }
                        })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {open && (
        <SuggestUserDialogue
          open={open}
          setOpen={(bool) => {
            setOpen(bool);
            navigator(-1);
          }}
        />
      )}
    </>
  );
}
