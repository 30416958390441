import { Skeleton } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';

const LazyImage = (props) => {
  const imageRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            setIsIntersecting(true);
            observer.unobserve(entry.target);
          }
        },
        { rootMargin: '250px' } // Increase the range by setting a root margin
      );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);
  
  return (
    <div ref={imageRef} style={{width:"100%", minHeight:200}}>
    {isIntersecting ? (
      <img {...props} style={{...props.style}} alt='none' />
    ) : (
      <Skeleton animation={'wave'} variant='rectangular' sx={{width:"100%",height:500}}/>
    )}
  </div>
  );
};

export default LazyImage;