import * as React from "react";
import { getAuth, reauthenticateWithCredential, updatePassword, EmailAuthProvider } from "firebase/auth";
import {
  Container,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment, IconButton, Grid, Tooltip, FormControlLabel,
  Checkbox, Button, Snackbar, Alert
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";
import {AuthUserContext} from "../../providers/auth-provider";
import { HelpOutlineOutlined} from '@mui/icons-material';
import PostNotification from "../../api/post-notification";

export default function ChangePasswordScreen() {

  const { profile } =
    React.useContext(AuthUserContext);
  const [values, setValues] = React.useState({
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',
    showoldPassword: false,
    shownewPassword: false,
    showconfirmPassword: false,
  });
  const [newPasswordError,setNewPasswordError] = React.useState("");
  const [oldPasswordError,setoldPasswordError] = React.useState("");
  const [confirmPasswordError,setConfirmPasswordError] = React.useState("");
  const [updateStatus, setUpdateStatus] = React.useState("")
  const navigator = useNavigate()


  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showoldPassword: !values.showoldPassword,

    });
  };

  const handleClickNewShowPassword = () => {
    setValues({
      ...values,
      shownewPassword: !values.shownewPassword,
    });
  };

  const handleClickConfirmShowPassword = () => {
    setValues({
      ...values,
      showconfirmPassword: !values.showconfirmPassword,
    });
  };


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleValidation = (event) => {
    event.preventDefault();
    const value= event.target.value.trim();
    const passwordInputFieldName = event.target.name;

    // for new password

    if(passwordInputFieldName === 'newpassword'){
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/;
      const minLengthRegExp   = /.{8,}/;

      const passwordLength =      value.length;
      const uppercasePassword =   uppercaseRegExp.test(value);
      const lowercasePassword =   lowercaseRegExp.test(value);
      const digitsPassword    = digitsRegExp.test(value);
      const specialCharPassword = specialCharRegExp.test(value);
      const minLengthPassword =   minLengthRegExp.test(value);


      if(passwordLength===0)
      {setNewPasswordError("Your Password is empty.")}
      else if(!uppercasePassword)
      {setNewPasswordError("Your password must have at least one uppercase.")}
      else if(!lowercasePassword)
      {setNewPasswordError("Your password must have at least one lowercase.")}
      else if(!digitsPassword)
      {setNewPasswordError("Your password must have at least one number.")}
      else if(!specialCharPassword)
      {setNewPasswordError("Your password must have at least one special character.")}
      else if(!minLengthPassword)
      {setNewPasswordError("Your password must have at least 8 characters.")}
      else{
        setNewPasswordError("");
        // sethasError(false);
      }

    }

    // Confirm Password

    if(passwordInputFieldName=== "confirmpassword" || (passwordInputFieldName==="newpassword" && values.confirmpassword.length>0)){

      if(values.confirmpassword!==values.newpassword)
      { setConfirmPasswordError("Please make sure your passwords match.")
      }
      else{
        setConfirmPasswordError("");
      }
    }
  }
  const reauthenticate = (oldPassword) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user?.email, oldPassword);
    return reauthenticateWithCredential(user, credential);
  }
  const handleSubmit = async (e) => {
    reauthenticate(values.oldpassword)
      .then((done) => {
        const auth = getAuth();
        const user = auth.currentUser;
        updatePassword(user, values.newpassword)
          .then(() => {
            setValues({
              oldpassword: '',
              newpassword: '',
              confirmpassword: '',
              showoldPassword: false,
              shownewPassword: false,
              showconfirmPassword: false,
            })
            setOpen(true)
            setoldPasswordError('')
            PostNotification({
              type: 'change-password',
              imageURL: '/assets/system-noti-icon.png',
              message: `Your password has been successfully changed.`,
              uid: profile.uid
            }).then()

        }).catch((err) => {
          console.log(err)
        })
      })
      .catch((err) => {
        setoldPasswordError('Old password ')
      })

  }

  /*
  *
  * const auth = getAuth();
const user = auth.currentUser;

// TODO(you): prompt the user to re-provide their sign-in credentials
const credential = promptForCredentials();
  * */
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {


    setOpen(false);
  };
  return(
    <React.Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Successfully change password
        </Alert>
      </Snackbar>
      <Container
        maxWidth={"xl"}
        sx={{ textAlign: "left", paddingY: 5}}>
        <Stack direction={"column"} spacing={5}
        >
          <Stack
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            width={"100%"}>
            <IconButton onClick={() => navigator(-1)}>
              <ArrowBackIcon sx={{ fontSize: 36 , color: '#000'}} />
            </IconButton>
            <Typography
              variant={"h4"} sx={{lineHeight: 1}}
            >
              Change password <br/> <font color={'#8F8F8F'} size={4}> Create a new password that is at least  8 characters long.</font>
            </Typography>

          </Stack>

        </Stack>
      </Container>
      <Container maxWidth={'md'}  >

        <Grid container gap={3}>
          <Grid item xs={11} />
          <Grid item xs={11}>
            <FormControl sx={{ width: '100%'}} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Old Password</InputLabel>
              <OutlinedInput
                type={values.showoldPassword ? 'text' : 'password'}
                value={values.oldpassword}
                required
                onChange={handleChange('oldpassword')
                }

                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!values.showoldPassword ? <img src="/assets/eye_close.png" alt=""/> : <img src="/assets/eye_open.png" alt=""/>}
                    </IconButton>
                  </InputAdornment>
                }
                label="oldpassword"
              />
              <Typography align='left' sx={{color:"#ED1C24", fontWeight:"500", fontSize:"16px"}}>&nbsp;{oldPasswordError}</Typography>
            </FormControl>
          </Grid>
          <Grid item xs={11}>
            <FormControl sx={{ width: '100%'}} variant="outlined" >
              <InputLabel htmlFor="outlined-adornment-password" sx={{display:"flex", flexDirection:"row"}}>New Password
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: "150px",
                        bgcolor: '#204488',
                        '& .MuiTooltip-arrow': {
                          color: '#204488',
                        },
                      },
                    },
                  }}
                  title={<>Password must have : <br/> 1 Uppercase <br/>1 Lowercase <br/>1 Number <br/>1 Special character <br/>8 Characters </>} arrow sx={{width:"100px"}} placement="top-start">
                  <HelpOutlineOutlined sx={{borderRadius:"100%", ml:1, fontSize:"24px", backgroundColor:"#FFFFFF"}}/>
                </Tooltip>
              </InputLabel>
              <OutlinedInput
                required
                error={newPasswordError}
                type={values.shownewPassword ? 'text' : 'password'}
                value={values.newpassword}
                onChange={handleChange('newpassword')}
                onKeyUp={handleValidation}
                name="newpassword"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickNewShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!values.shownewPassword ? <img src="/assets/eye_close.png" alt=""/> : <img src="/assets/eye_open.png" alt=""/>}
                    </IconButton>
                  </InputAdornment>
                }
                label="newpassword"

              />
              <Typography align='left' sx={{color:"#ED1C24", fontWeight:"500", fontSize:"16px"}}>{newPasswordError}&nbsp;</Typography>
            </FormControl>
          </Grid>
          <Grid item xs={11}>
            <FormControl sx={{width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                required
                error={confirmPasswordError}
                type={values.showconfirmPassword ? 'text' : 'password'}
                value={values.confirmpassword}
                onKeyUp={handleValidation}
                onChange={handleChange('confirmpassword')}
                name="confirmpassword"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickConfirmShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!values.showconfirmPassword ? <img src="/assets/eye_close.png" alt=""/> : <img src="/assets/eye_open.png" alt=""/>}
                    </IconButton>
                  </InputAdornment>
                }
                label="confirmpassword"
              />
              <Typography align='left' sx={{color:"#ED1C24", fontWeight:"500", fontSize:"16px"}}>{confirmPasswordError}&nbsp;</Typography>
            </FormControl>

          </Grid>
          <Grid item xs={11}>
            <FormControlLabel sx={{width: '100%'}} control={<Checkbox defaultChecked />} label="Require all devices to sign in with new password" />
          </Grid>
          <Stack
            direction={"column"}
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}>
            <Button variant={"contained"} sx={{width: 200}} onClick={handleSubmit} disabled={newPasswordError || confirmPasswordError || values.oldpassword.length===0 || values.confirmpassword.length===0}>Save password</Button>
            {/* <Button variant={"outlined"} sx={{width: 200}}>Forget password</Button> */}
          </Stack>
        </Grid>
    </Container>
    </React.Fragment>
  )
}
