import React from 'react';
import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import Signup from "../screens/auth/signup";
import Signin from "../screens/auth/signin";
import Location from '../screens/auth/location';
import ForgetPassword from '../screens/auth/forget-password';
import ResetPassword from "../screens/auth/reset-password";


import { LinkedInCallback } from "react-linkedin-login-oauth2";
import LoginWithSocialReferralScreen from "../screens/auth/login-with-social-referral-screen";

const AuthRouter = () => {
  return(
    <Routes>

      <Route  path='auth'  element={<Outlet />}>
        <Route  path={'where-are-you-located'} element={<Location/>}/>
        <Route  path={'referral'} element={<LoginWithSocialReferralScreen/>}/>
        <Route  path={'signup'} element={<Signup/>}/>
        <Route  path={'signin'} element={<Signin/>}/>
        <Route  path={'linkedin'} element={<LinkedInCallback/>}/>
        <Route  path={'forget-your-password'} element={<ForgetPassword/>}/>
        <Route  path={'reset-password'} element={<ResetPassword/>}/>
      </Route>
    </Routes>

  )
}


export default AuthRouter;