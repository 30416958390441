// import React from 'react';
// import { Routes, Route, Outlet } from 'react-router-dom'
// import SigninMobile from '../../screens/mobile/signin';
// import SignupMobile from '../../screens/mobile/signup';
// import ForgetPassword from '../../screens/mobile/forgetPassword';
import React from 'react';
import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import Signup from "../../screens/mobile/auth/signup";
import Signin from "../../screens/mobile/auth/signin";
import Location from '../../screens/mobile/auth/location';
import ForgetPassword from '../../screens/mobile/auth/forget-password';
import ResetPassword from "../../screens/mobile/auth/reset-password";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import HomeScreen from "../../screens/mobile/auth/home-screen";
import Splashscreen from '../../screens/mobile/auth/splashscreen';
import LoginWithSocialReferralScreen from "../../screens/auth/login-with-social-referral-screen";

const MobileAuthRouter = () => {
  return(
    <Routes>
      <Route
        path="*"
        element={<Navigate to="/m/auth/signin" replace />}
      />
      <Route  path='m/auth'  element={<Outlet />}>
        {/* <Route  path={'signup'} element={<SignupMobile/>}/>
        <Route  path={'signin'} element={<SigninMobile/>}/>
        <Route  path={'forget-your-password'} element={<ForgetPassword/>}/> */}
        <Route  path={'splashscreen'} element={<Splashscreen/>}/>
        <Route  path={'referral'} element={<LoginWithSocialReferralScreen/>}/>
        <Route  path={'where-are-you-located'} element={<Location/>}/>
        <Route  path={'home'} element={<HomeScreen/>}/>
        <Route  path={'signup'} element={<Signup/>}/>
        <Route  path={'signin'} element={<Signin/>}/>
        <Route  path={'linkedin'} element={<LinkedInCallback/>}/>
        <Route  path={'forget-your-password'} element={<ForgetPassword/>}/>
        <Route  path={'reset-password'} element={<ResetPassword/>}/>
      </Route>
    </Routes>

  )
}


export default MobileAuthRouter;