import * as React from "react";
import { Button, IconButton, Menu, Stack, Typography } from "@mui/material";
import { CelebrateIcon, FunnyIcon, InsightFulIcon, LikeIcon, PunchIcon } from "../../../../../../../src/utils/svg-icons";
import { DB } from "../../../../../../config/firebase/firebase";
import {
  collection,
  setDoc,
  doc,
  getDoc,
  deleteDoc,
  serverTimestamp,
  query,
  where,
  orderBy,
  limit, getDocs, updateDoc
} from "firebase/firestore";
import { AuthUserContext } from "../../../../../../providers/auth-provider";
import { useEffect } from "react";
import PostNotification from "../../../../../../api/post-notification";

export default function CommentLikeMenu({ data, timelineId, postuid, likesCount, hideicon }) {
  const { profile } = React.useContext(AuthUserContext);
  const [liked, setLiked] = React.useState(false)
  const [likedDoc, setLikedDoc] = React.useState()
  const colors = {
    'undefined': '#8F8F8F',
    'Like': '#233DFF',
    'Yeah!': '#F85353',
    'Celebrate': '#02C875',
    'LOL': '#FF7A00',
    'Insightful': '#CE41F6',
    'Puke': '#AAC15A'
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const like = (text) => {

    const colRef = doc(DB, "timelines", timelineId, 'comments', data?.id, "likes", profile?.uid);
    setDoc(colRef, {
      impression: text,
      createdAt: serverTimestamp()
    }, { merge: true }).then(async () => {
      setLiked(true)
      setLikedDoc({ id: profile?.uid, impression: text });
      const q = query(
        collection(DB, "notifications"),
        where("uid", "==", postuid),
        where("postId", "==", timelineId),
        where("react", "==", text),
        orderBy("createdAt", "desc"),
        limit(1),

      );
      const notifications = await getDocs(q)

      if (notifications.size > 0) {
        await updateDoc(doc(DB, "notifications", notifications.docs[0].id), {
          displayName: `${profile?.displayName}, and ${likesCount} others reacted to ${data?.user?.displayName} comment`,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          imageURL: profile?.photoURL,
          read: false
        })
      } else {
        PostNotification({
          type: 'reaction-time-line-post',
          imageURL: profile?.photoURL,
          message: '',
          displayName: `${profile?.displayName} reacted to ${data?.user?.displayName} comment`,
          react: text,
          uid: postuid,
          postId: timelineId
        }).then()
      }
      /*   PostNotification({
           type: 'reaction-time-line-post',
           imageURL: profile?.photoURL,
           message: '',
           displayName: `${profile?.displayName} reacted to ${data?.user?.displayName}`,
           react:text,
           uid: data?.user?.uid,
           postId:  timelineId
         }).then()*/
      handleClose();
    }).catch(() => {
      debugger
    });
  }
  useEffect(() => {
    setLiked(false)
    setLikedDoc(undefined)
    let unsubs;
    unsubs = getDoc(doc(DB, "timelines", timelineId, 'comments', data?.id, "likes", profile?.uid))
      .then((getdoc) => {
        if (getdoc.exists()) {
          setLiked(true);
          setLikedDoc({ id: profile?.uid, ...getdoc.data() })
          console.log('id', data?.id + JSON.stringify(getdoc.data()))
        }

      });
    return () => unsubs
  }, [data?.id])


  return (

    <>
      <Menu
        id="comment-like-menu"
        MenuListProps={{
          'aria-labelledby': 'comment-like-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ top: -85 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {

            borderRadius: '5px'
          },
        }}
      >
        <Stack direction={"row"} spacing={0} p={.2} px={1}>
          <Button sx={{ borderRadius: '5px' }} style={{ fontSize: "12px" }} onClick={() => {
            like('Like')
          }}>

            <Stack direction={"column"} spacing={.2} alignItems={"center"}>
              <img src={'/assets/likes-icons-set/Like.png'} height={28} alt="Like Icon" />



              <Typography variant={"body2"} color={'primary'}>Like</Typography>
            </Stack>
          </Button>
          <Button sx={{ borderRadius: '5px' }} onClick={() => like('Yeah!')}>

            <Stack direction={"column"} spacing={.2} alignItems={"center"}>
              <img src={'/assets/likes-icons-set/Punch.png'} height={28} />
              <Typography variant={"body2"} color={'#F85353'}>Yeah!</Typography>
            </Stack>
          </Button>
          <Button sx={{ borderRadius: '5px' }} onClick={() => like('Celebrate')}>

            <Stack direction={"column"} spacing={.2} alignItems={"center"}>
              <img src={'/assets/likes-icons-set/Celebrate.png'} height={28} />

              <Typography variant={"body2"} color={'#02C875'}>Celebrate</Typography>
            </Stack>
          </Button>
          <Button sx={{ borderRadius: '5px' }} onClick={() => like('LOL')}>

            <Stack direction={"column"} spacing={.2} alignItems={"center"}>
              <img src={'/assets/likes-icons-set/LOL.png'} height={28} />
              <Typography variant={"body2"} color={'#FF7A00'} >LOL</Typography>
            </Stack>
          </Button>
          <Button sx={{ borderRadius: '5px' }} onClick={() => like('Insightful')}>

            <Stack direction={"column"} spacing={.2} alignItems={"center"}>
              <img src={'/assets/likes-icons-set/Insightful.png'} height={28} />
              <Typography variant={"body2"} color={'#CE41F6'}>Insightful</Typography>
            </Stack>
          </Button>
          <Button sx={{ borderRadius: '5px' }} onClick={() => like('Puke')}>

            <Stack direction={"column"} spacing={.2} alignItems={"center"}>
              <img src={'/assets/likes-icons-set/Puke.png'} height={28} />
              <Typography variant={"body2"} color={'#AAC15A'}>Puke</Typography>
            </Stack>
          </Button>

        </Stack>

      </Menu>
      <Button
        startIcon={
          liked && likedDoc ? (
            likedDoc?.impression === 'Like' ? (
              <img src={'/assets/likes-icons-set/Like.png'} height={24} />
            ) : likedDoc?.impression === 'Punch' || likedDoc?.impression === 'Yeah!' ? (
              <img src={'/assets/likes-icons-set/Punch.png'} height={24} />
            ) : likedDoc?.impression === 'Celebrate' ? (
              <img src={'/assets/likes-icons-set/Celebrate.png'} height={24} />
            ) : likedDoc?.impression === 'LOL' ? (
              <img src={'/assets/likes-icons-set/LOL.png'} height={24} />
            ) : likedDoc?.impression === 'Insightful' ? (
              <img src={'/assets/likes-icons-set/Insightful.png'} height={24} />
            ) : likedDoc?.impression === 'Puke' ? (
              <img src={'/assets/likes-icons-set/Puke.png'} height={24} />
            ) : null
          ) : null
        }
        color={'secondary'}
        variant={'text'}
        // size={'small'} 
        sx={{
          color: liked ? (likedDoc && colors[likedDoc?.impression]) : '#8F8F8F', ml: -2, fontSize: "13px"
        }}
        id="comment-like-button"
        aria-controls="comment-like-menu"
        aria-expanded={open ? 'true' : undefined}

        aria-haspopup="true"
        onClick={(event) => {
          debugger
          if (likedDoc) {
            const colRef = doc(DB, "timelines", timelineId, 'comments', data?.id, "likes", profile?.uid);
            deleteDoc(colRef).then(() => {
              setLiked(false)
              setLikedDoc(undefined);
            });
          } else {
            handleClick(event)
          }

        }}
      >
        {likedDoc && likedDoc?.impression} {(likedDoc === undefined || !likedDoc || !likedDoc?.impression) && 'Like'}
      </Button>
    </>
  )
}