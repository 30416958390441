import axios from "axios";


export default function GenerateReferralCode(data) {

  return axios.post(
    'https://us-central1-astranovaapp.cloudfunctions.net/api/generate-referral-code',
    // 'http://localhost:5001/systema-nova/us-central1/api/generate-referral-code',
    {...data},
    {headers: {'Content-Type': 'application/json'}}
  )

}