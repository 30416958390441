import React from "react";
import {Avatar, Stack, Typography} from "@mui/material";
import {collection, getDocs, limit, query, where } from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
import {AuthUserContext} from "../../../providers/auth-provider";
import {useNavigate} from "react-router-dom";


export default function ReferralAvatar({profile}){
  const navigator = useNavigate()
  const userContext = React.useContext(AuthUserContext)
  const [referalDetails, setReferDetails] = React.useState()
  React.useEffect(() => {

    const q = query(

      collection(DB, "users"),
      limit(1),
      where('referList', 'array-contains', profile?.uid)
    );
    getDocs(q).then((data) => {
      data?.size > 0 ? setReferDetails({...data?.docs[0].data()}) : setReferDetails()
    });

  }, [profile])
  return(
    <>
      {referalDetails &&

        <Stack direction={"column"} spacing={1} justifyContent="center" alignItems="center" 
        sx={{position: "relative", minWidth: 120,cursor:'pointer'  }}

               onClick={() => {
  
                 if (userContext?.profile?.userType === 'admin') {
                   
                   navigator(
                     "/admin/user-details", {state: {profile: referalDetails}}
                   )
                   window.location.reload(true)
                 } else {
                   const path = `/${userContext?.profile?.userType}/${referalDetails?.userType}-details/${referalDetails?.uid}`
                   if (referalDetails?.userType !== 'admin') {
                     navigator(path);
                   }
                   else {
                     window.open('https://astranova.io/');
                   }
                 }
                 
                

               }}>
          <Avatar sx={{width: 57, height:57 }} src={referalDetails?.photoURL} alt={'profile-pic'}>
            <Typography variant={"h3"}>{referalDetails?.displayName}</Typography>
          </Avatar>
          <Typography variant={"h6"}>Referral by</Typography>
          <Typography variant={"body2"}  
          >
          <font
                          size={2}
                          style={{
                            backgroundColor: "#F6F8FA",
                            padding: "5px 10px",
                            borderRadius: "6px",
                            fontWeight: 300,
                          }}
                        >
          {referalDetails?.displayName}
          </font>
          </Typography>
        </Stack>
      }
    </>


  )
}
