import React from "react";
import { Container, Typography, Stack, IconButton, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import DownloadFileHelper from '../../../../../utils/download-file-helper';
import ScrollToTop from "../../../../../utils/scrolling-top";
import { shortenURL } from "../../../../../utils/shortenURL";
const boxstyle = {
display: 'flex',
flexDirection: 'row',
alignItems: 'center',
padding: '4.97213px 6.62951px 4.97213px 9.94426px',
gap: '6.63px',
color: '#1D192B',
// position: 'absolute',
// width: '116.57px',
// height: '26.94px',
backgroundColor: '#E0E3FF',
borderRadius: '6.62951px',
fontSize:'14px'
}

const commonStyles = {
  // bgcolor: "background.paper",
  // m: 1,
  // border: 1, 
  // width: "1198px",
  // height: "1100px",
};
export default function WorkExperienceView({profile , i }) {
  const navigator = useNavigate();
    let user = profile?.userType === 'business-developer' ?
        'businessDeveloper' :
        profile?.userType === 'expert'?
            'expert' :
            profile?.userType === 'service-provider'?
                'serviceProvider':
                null
  return (
    <>
    <ScrollToTop/>
    <Container maxWidth={"lg"} sx={{ textAlign: "left" , position: 'absolute',
    left: '0px',
    top: '65px',
    backgroundColor: '#F6F8FA',
    boxShadow: '0px 0px 13px -2px rgba(0, 0, 0, 0.15)' ,
    display:'flex',
    flexDirection:'column',

    }}
    >
      <Stack
        direction={"column"}
        sx={{ width: "100%", textAlign: "left", marginTop: "10px"  }}
        spacing={5}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <IconButton onClick={() => navigator(-.5)}>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>

          <Typography variant={"h6"} sx={{fontWeight:'600'}}> Work Experience</Typography>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "center",  }}>
          <Box sx={{ ...commonStyles, borderColor: "black" }}>
            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 120,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                // px: 3,
              }}
            >
              <Stack direction={"column"} spacing={2} sx={{ marginTop: "-8px" }}>
                <Typography variant={"p"} color={"black"}>
                  Company Name:
                </Typography>
                <Typography color={"black"} sx={boxstyle}>
                {
                   profile?.userType === 'business-developer' ?
                   profile?.businessDeveloper?.experiences[i]?.companyName:
                   profile?.userType === 'expert'? 
                   profile?.expert?.experiences[i]?.companyName : 
                   profile?.userType === 'service-provider'? 
                   profile?.serviceProvider?.experiences[i]?.companyName:
                   null

                   }
                </Typography>
              </Stack>
              <br></br>
              <Stack direction={"column"} spacing={2} sx={{ marginTop: "-10px" }}>
                <Typography variant={"p"} color={"black"}>
                  Company Website URL:
                </Typography>
                <Typography   color={"#233DFF"} sx={boxstyle}>
                <a href={
                      profile?.userType === 'business-developer' ?
                   profile?.businessDeveloper?.experiences[i]?.website:
                   profile?.userType === 'expert'? 
                   profile?.expert?.experiences[i]?.website: 
                   profile?.userType === 'service-provider'? 
                   profile?.serviceProvider?.experiences[i]?.website:
                   null
 
                 }
                target={'_blank'}>
                {
                 shortenURL(profile?.userType === 'business-developer' ?
                 profile?.businessDeveloper?.experiences[i]?.website:
                 profile?.userType === 'expert'? 
                 profile?.expert?.experiences[i]?.website: 
                 profile?.userType === 'service-provider'? 
                 profile?.serviceProvider?.experiences[i]?.website:
                 null, 50)
                }
                 </a>
                  
                </Typography>

              </Stack>
            </Stack>
            {console.log(profile)}

            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 120,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                // px: 3,
              }}
            >
              <Stack direction={"column"} spacing={2} sx={{ marginTop: "-8px" }}>
                <Typography variant={"p"} color={"black"}>
                  Role:
                </Typography>
                <Typography color={"black"} sx={boxstyle}>
                {
                   profile?.userType === 'business-developer' ?
                   profile?.businessDeveloper?.experiences[i]?.role:
                   profile?.userType === 'expert'? 
                   profile?.expert?.experiences[i]?.role: 
                   profile?.userType === 'service-provider'? 
                   profile?.serviceProvider?.experiences[i]?.role:
                   null

                   }
                </Typography>
              </Stack>
              <br></br>
              {console.log(profile)}
              <Stack direction={"column"} spacing={2} sx={{ marginTop: "-10px" }}>
                <Typography variant={"p"} color={"black"}>
                  Starting Date:
                </Typography>
                <Typography color={"black"} sx={boxstyle}>
                {
                   profile?.userType === 'business-developer' ?
                   `${profile?.businessDeveloper?.experiences[i]?.startDate} - ${profile?.businessDeveloper?.experiences[i]?.endDate}`:
                   profile?.userType === 'expert'? 
                   `${profile?.expert?.experiences[i]?.startDate} - ${profile?.expert?.experiences[i]?.endDate}`:
                   profile?.userType === 'service-provider'? 
                   `${profile?.serviceProvider?.experiences[i]?.startDate} - ${profile?.serviceProvider?.experiences[i]?.endDate}`:
                   null

                   }
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent="left"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                // px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-8px" }}
              >
                <Typography variant={"p"} color={"black"}>
                  <br></br>
                  Experience
                </Typography>
                <Typography sx={{fontSize:'14px'}} color={"black"}>
                  <br></br>
                  {
                   profile?.userType === 'business-developer' ?
                   profile?.businessDeveloper?.experiences[i]?.experience:
                   profile?.userType === 'expert'? 
                   profile?.expert?.experiences[i]?.experience: 
                   profile?.userType === 'service-provider'? 
                   profile?.serviceProvider?.experiences[i]?.experience:
                   null

                   }

          
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent="left"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                // px: 3,
              }}
            >
              <Stack
              direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-8px" }}
              >
                <Typography variant={"p"} color={"black"}>
                  <br></br>
                  Social Proof
                </Typography>
                <Typography sx={{fontSize:'14px'}} color={"black"}>
                  <br></br>
                  {
                   profile?.userType === 'business-developer' ?
                   profile?.businessDeveloper?.experiences[i]?.socialProof:
                   profile?.userType === 'expert'? 
                   profile?.expert?.experiences[i]?.socialProof: 
                   profile?.userType === 'service-provider'? 
                   profile?.serviceProvider?.experiences[i]?.socialProof:
                   null

                   }

                  <br></br>
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                // px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-30px" }}
              >
                <Typography variant={"p"} color={"black"}>
                  <br></br>
                  Attachment
                </Typography>
                      {profile[user].experiences[0].attachments.map((attachment) =>
                  {
                      return<>
                          <Stack direction="row">
                              <Box
                                  sx={{
                                      p: "2px 10px",
                                      display: "flex",
                                      alignItems: "center",
                                      width: 337,
                                      height: 43,
                                      borderRadius: "12px",
                                      backgroundColor: "#E0E3FF",
                                      marginTop: "33px",
                                  }}
                              >
                                  <IconButton type="button" aria-label="search">
                                      <img src="/assets/image5.png" alt="" />
                                  </IconButton>
                                  {attachment.split("/").splice(-1)}
                                  <Stack
                                      sx={{
                                          marginLeft: "60px",
                                      }}
                                  >
                                      <img src="/assets/img2.png" alt="" onClick={()=>{
                                          DownloadFileHelper(attachment)
                                      }
                                      }/>
                                  </Stack>
                              </Box>
                          </Stack>
                      </>
                  })}
              </Stack>
            </Stack>

            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                // borderBottom: "1px solid #ccc",
                p: 1,
                // px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-30px" }}
              >
                <Typography variant={"p"} color={"black"}>
                  <br></br>
                  Additional Link
                </Typography>
                <Stack direction="row">
                { (profile?.businessDeveloper?.experiences[i]?.link 
                  || profile?.expert?.experiences[i]?.link 
                  || profile?.serviceProvider?.experiences[i]?.link )
                  &&
                  <Box
                    sx={{
                      // p: "2px 10px",
                      // display: "flex",
                      // alignItems: "center",
                      // width: 400,
                      // height: 60,
                      // borderRadius: "12px",
                      // backgroundColor: "#E0E3FF",
                      // marginTop: "33px",

                    }}
                  >
                      <Typography  variant={'caption'}  
                       sx={boxstyle}
                      // sx={{textAlign:'left',fontSize:'16px'}}
                      >
                      <a href={
                        profile?.userType === 'business-developer' ?
                        profile?.businessDeveloper?.experiences[i]?.link:
                        profile?.userType === 'expert'?
                        profile?.expert?.experiences[i]?.link:
                        profile?.userType === 'service-provider'?
                        profile?.serviceProvider?.experiences[i]?.link:
                        null
                        }
                      target={'_blank'}>
                        {
                             shortenURL(profile?.businessDeveloper?.experiences[i]?.link, 30)
                          || shortenURL(profile?.expert?.experiences[i]?.link, 30) 
                          || shortenURL(profile?.serviceProvider?.experiences[i]?.link, 30)
                        }
                      {/* {
                        profile?.userType === 'business-developer' &&
                        profile?.businessDeveloper?.experiences[i]?.link.length < 30
                        ? profile?.businessDeveloper?.experiences[i]?.link
                        : `${profile?.businessDeveloper?.experiences[i]?.link.slice(0 , 30)}...`


                       ||

                        profile?.userType === 'expert' &&
                        profile?.expert?.experiences[i]?.link.length < 30
                        ? profile?.expert?.experiences[i]?.link
                        : `${profile?.expert?.experiences[i]?.link.slice(0 , 30)}...`

                        ||

                        profile?.userType === 'service-provider' &&
                        profile?.serviceProvider?.experiences[i]?.link.length < 30
                        ? profile?.serviceProvider?.experiences[i]?.link
                        : `${profile?.serviceProvider?.experiences[i]?.link.slice(0 , 30)}...`


                        } */}
                       </a>
                      </Typography>
                  </Box>}
                </Stack>
              </Stack>
            </Stack>

          </Box>
        </Box>
      </Stack>
    </Container>
     </>
  );
}