import * as yup from 'yup';

const SignupSchema = yup.object({
  email: yup
    .string('Email')
    .email('Enter a valid email')
    .required('Email is required')

  ,
  password: yup
    .string('Enter your password')
    .matches(/(?=.*?[A-Z])/, 'Your password must have at least one uppercase.')
    .matches(/(?=.*?[a-z])/, 'Your password must have at least one lowercase.')
    .matches(/(?=.*?[0-9])/, 'Your password must have at least one number.')
    .matches(/(?=.*?[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/, 'Your password must have atleast one special character')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required')
  ,
  name: yup
    .string('Name')
    .required('Name is required.')
    .min(3, 'Name should be of minimum 3 characters length')
    .max(35, 'Name should be of maximum 35 characters length')
  ,
  referralCode: yup
    .string('Referral code')
    .required('Referral code is required')
    .min(6, 'Referral code should be of minimum 6 characters length')
});
const initialValues = {
  email: '',
  password: '',
  name: '',referralCode: ''
};

const SignupValidation = {
  initialValues,
  schema : SignupSchema
};

export default  SignupValidation;
