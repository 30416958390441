import React from "react";
import {IconButton, Stack, Typography} from "@mui/material";
import {EditSharp} from "@mui/icons-material";
import EditExpertDetails from "./edit/expert/edit-expert-details";

export default function ExpertDescription({profile, cb}) {
  const [open,setOpen] = React.useState(false)
  const profileRegex = /profile|admin\/user-details/
  
  return (
    <>
      <Stack direction={"row"} spacing={2}  justifyContent="flex-end"
             alignItems="center" sx={{width: '100%'}}>
        {profile.countryState ? <Typography variant={'body2'} color={"grey"} sx={{flex: 1}}>
          {profile.countryState}
        </Typography> : null}
        {profileRegex.test(window.location.pathname) &&
          <IconButton sx={{                display: 'grid',
                justifyContent: 'right',
                position: 'absolute' ,
                right: 22}} onClick={() => setOpen(true)}>
          <EditSharp />
        </IconButton>}
      </Stack>

      {profile?.expert && profile?.expert.description &&
        <Typography variant={'body1'} color={'secondary'} sx={{width: 'calc(100% - 10%)', whiteSpace:"pre-line"}}>

        {profile?.expert.description}
      </Typography>}

      <EditExpertDetails  profile={profile} open={open} callback={(d) => {
        cb(d);
        setOpen(false)
      }} handleClose={() => setOpen(false)}/>

    </>
  )
}
