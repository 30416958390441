import * as React from "react";
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import { DoneAllOutlined } from "@mui/icons-material";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import moment from "moment";
import { AuthUserContext } from "../../../../../providers/auth-provider";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { DB } from "../../../../../config/firebase/firebase";

export default function RoomListItem({ data }) {
  // debugger
  const { roomId, setRoomId, setRoomData, profile } =
    React.useContext(AuthUserContext);

  const [room, setRoom] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [unreadMessages, setUnreadMessages] = React.useState();
  const storage = getStorage();
  const navigator = useNavigate();
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };
  React.useEffect(() => {
    const q = query(
      collection(DB, "messages"),
      orderBy("createdAt", "desc"),
      where("receiver", "==", profile?.uid),
      where("roomId", "==", data?.id),
      where("read", "==", false)
    );

    const userRef = doc(
      DB,
      "users",
      data?.participates.find((f) => f !== profile.uid)
    );
    getDoc(userRef).then((doc) => {
      if (data.title !== "Support" && data.type !== "support") {
        setRoom({
          ...data,
          title: doc?.data()?.displayName,
          subTitle: `${doc?.data()?.displayName},
          ${profile?.displayName}`,
          image: doc?.data()?.photoURL,
        });
        setImage(doc?.data()?.photoURL || "");
      } else {
        setRoom({
          ...data,
          subTitle: `${doc?.data()?.displayName}`,
          image: data?.logo,
        });
        setImage(data?.logo || "");
      }
      /*title
      subTitle
      logo*/
      /*  setRoom(data);
      console.log(data)*/
    });
    getDocs(q)
      .then((data) => {
        console.log("number of messages", data.size);
        setUnreadMessages(data.size);
      })
      .catch((err) => {
        console.log(err.messages);
      });
  }, [data]);
  return (
    <ListItemButton
      alignItems="flex-start"
      sx={{
        borderRadius: "10px",
        mb: 1,
        py: 2,
        maxHeight: 120,
        backgroundColor: roomId === room?.id ? "#F6F8FA" : "#fff",
      }}
      onClick={async () => {
        await setRoomId(null);
        await setRoomData(null);
        await setRoomId(room?.id);
        setUnreadMessages(0);
        setRoomData({ ...room, image });
        navigator(`/m/${profile?.userType}/chatscreen1`);
        // {
        //   state:{
        //     setRoomId(room?.id),
        //     setRoomData({...room, image})
        //   }
        // }
      }}
    >
      <ListItemAvatar sx={{ mr: 2 }}>
        {loading ? (
          <Skeleton variant="circular" width={50} height={50} />
        ) : (
          <Avatar
            sx={{ height: 50, width: 50 }}
            alt={room?.title}
            src={image}
            imgProps={{
              sx: { p: room?.type && room?.type === "support" ? 1 : 0 },
            }}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          <React.Fragment>
            <Stack direction={"row"} spacing={1}>
              <font style={{ flex: 1, fontSize: "13px", fontWeight: "600" }}>
                {room?.title}
              </font>
              <Typography
                variant={"caption"}
                style={{ display: "inline-block" }}
              >
                {room?.updatedAt &&
                  moment(room?.updatedAt?.seconds * 1000).format("hh:mm a")}
              </Typography>
            </Stack>
          </React.Fragment>
        }
        secondary={
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              sx={{ overflowY: "hidden", maxHeight: 58 }}
              variant="body2"
              color="text.secondary"
              paragraph={true}
            >
              {room?.lastMsg?.text !== ""
                ? truncateText(room?.lastMsg?.text, 30)
                : truncateText(room?.lastMsg?.fileName, 30)}
            </Typography>
            {unreadMessages > 0 && (
              <Typography
                style={{
                  borderRadius: "50%",
                  width: "22px",
                  height: "22px",
                  padding: "1px",
                  background: "#233DFF",
                  border: "3px soldi #000",
                  color: "#fff",
                  textAlign: "center",
                  // font: "12px Arial, sans-serif"
                }}
                variant="body2"
              >
                {unreadMessages}
              </Typography>
            )}
          </Stack>
        }
      />
    </ListItemButton>
  );
}
