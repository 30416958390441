import React from "react";
import {
	Button,
	Container,
	Stack,
	TextField,
	Autocomplete,
	Chip,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormHelperText,
	FormControl,Typography, createFilterOptions
} from "@mui/material";
import { useFormik } from "formik";
import StartupOnBoardingValidation from "../../../validations/startup/startup-profile";
import CloseIcon from "@mui/icons-material/Close";
import GetCitiesData from "../../../api/get-cities-data";
import dataJson from '../../../const/data.json';
import { Add } from "@mui/icons-material";
const filter_option = createFilterOptions()
export default function StartupProfileSecondForm({ cbBack, cbNext, data }) {
	const cities = ["London, United Kingdom"];
	const [filter, setFilter] = React.useState([...cities]);
	const [searchLoaction, setSearchLoaction] = React.useState("");
	React.useEffect(() => {
		if (searchLoaction !== "") {
			GetCitiesData().then((res) => {
				const te = [...new Set(res?.data?.cities)];
				setFilter([
					...te.filter((post) => {
						if (searchLoaction !== "") {
							const reg = new RegExp(searchLoaction);
							return reg.test(post) && post;
						}
					}),
				]);
				/*debugger
				setcities(te)*/
			});
		} else {
			setFilter([...cities]);
		}
	}, [searchLoaction]);

	const formikFirst = useFormik({
		initialValues: data
			? data
			: StartupOnBoardingValidation.StartupOnBoardingProfileSecondInitValue,
		validationSchema:
			StartupOnBoardingValidation.StartupOnBoardingProfileSecondSchema,
		onSubmit: async (values) => {
			cbNext({ ...values });
		},
	});

	return (
		<Stack
			direction={"column"}
			spacing={10}
			component="form"
			onSubmit={(e) => formikFirst.handleSubmit(e)}>
			<Container maxWidth={"md"}>
				<Stack direction={"column"} spacing={2}>

					<Stack direction={"column"}  spacing={2} sx={{width: '100%'}}>

						<Autocomplete
							multiple
							options={[
								"Adtech",
								"Advanced manufacturing",
								"Agtech",
								"Artificial intelligence and machine learning (AI/ML)",
								"Audiotech",
								"Augmented reality (AR)",
								"Autonomous cars",
								"B2B payments",
								"Beauty",
								"Big Data",
								"Cannabis",
								"Carsharing",
								"Cleantech",
								"Climate tech",
								"Cloudtech and DevOps",
								"Construction technology",
								"Cryptocurrency and blockchain",
								"Cybersecurity",
								"Digital health",
								"Ecommerce",
								"Edtech",
								"Ephemeral content",
								"eSports",
								"Femtech",
								"Fintech",
								"Foodtech",
								"Gaming",
								"Healthtech",
								"HRtech",
								"Impact investing",
								"Industrials",
								"Infrastructure",
								"Insurtech",
								"Internet of Things (IoT)",
								"Legal tech",
								"Life sciences",
								"Lifestyles of Health and Sustainability (LOHAS) and wellness",
								"Manufacturing",
								"Marketing tech",
								"Micro-mobility",
								"Mobile",
								"Mobile commerce",
								"Mobility tech",
								"Mortgage tech",
								"Nanotechnology",
								"Oil and gas",
								"Oncology",
								"Pet tech",
								"Real estate tech",
								"Restaurant tech",
								"Ridesharing",
								"Robotics and drones",
								"Software as a service (SaaS)",
								"Space tech",
								"Supply chain technology",
								"Technology, media and telecommunications (TMT)",
								"Virtual reality (VR)",
								"Wearables and quantified self",
								"3D printing",
							].map((option) => option)}
							filterOptions={(options, params) => {
								const filtered = filter_option(options, params);
						
								const { inputValue } = params;
								// Suggest the creation of a new value
								const isExisting = options.some((option) => inputValue === option);
								if (inputValue !== '' && !isExisting) {
									filtered.push('Add ' + inputValue);
								}
						
								return filtered;
							  }}
							getOptionLabel={(option) => {
								// Value selected with enter, right from the input
								if (typeof option === 'string') {
								  return option
								}
								// Add "xxx" option created dynamically
								if (option.value) {
									return option.value;
								  }
								  // Regular option
								return option
							  }}
							renderOption={(props, option) => 
							<li {...props} style={{alignItems:"center"}}>
								{option.includes("Add ")  
								? 
								<><Add fontSize="medium"/>{option}</> 
								: option}
								</li>}
							onChange={(
								e,
								newValue
							) =>{
								if(newValue.length!==0)
									{const newOption = newValue.slice(-1)[0].replace("Add ", "")
									newValue.splice(-1,1,newOption)}
								  
								formikFirst.setFieldValue('sector', newValue)
							}
							}
							name={"sector"}
							value={formikFirst.values.sector}
							renderTags={(value, getTagProps) =>
								formikFirst.values.sector.map(
									(option, index) => (
										<Chip
											variant="contained"
											sx={{
												background: " #E0E3FF",
												// backgroundColor: "#E3E8F0",
												marginLeft: "5px",
												marginRight: "5px",
												marginBottom: "10px",
											}}
											deleteIcon={<CloseIcon />}
											onDelete={() =>
												formikFirst.setFieldValue(
													"sector",
													formikFirst.values.sector.filter(
														(f) => f !== option.value ? option.value : option
													)
												)
											}
											label={option.value ? option.value : option}
											{...getTagProps({ index })}></Chip>
									)
								)
							}
							renderInput={(params) => (
								<>
									<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Sector *
							  </Typography>
								<TextField
									error={Boolean(formikFirst.errors.sector)}
									helperText={
										formikFirst.errors.sector
											? "Sector is a required field"
											: ""
									}
									{...params}
									fullWidth
									// variant={'standard'}
									//label="Sector"
									//placeholder="Add"
									sx={{
										transition: "0.1s linear",
									}}
								/>
								</Stack>
								</>
							)}
							fullWidth
						/>

						{/* <Autocomplete
							fullWidth
							freeSolo
							multiple
							options={[
								// ''
							].map((option) => option)}
							onChange={(e, newValue) => {
								formikFirst.setFieldValue(
									"additionalDetails",
									newValue
								);
							}}
							name={"additionalDetails"}
							value={formikFirst.values.additionalDetails}
							renderTags={(value, getTagProps) =>
								formikFirst.values.additionalDetails.map(
									(option, index) => (
										<Chip
											variant="contained"
											sx={{
												backgroundColor: "#E3E8F0",
												marginLeft: "5px",
												marginRight: "5px",
												marginBottom: "10px",
											}}
											deleteIcon={<CloseIcon />}
											onDelete={() =>
												formikFirst.setFieldValue(
													"additionalDetails",
													formikFirst.values.additionalDetails.filter(
														(f) => f !== option
													)
												)
											}
											label={option}
											{...getTagProps({ index })}></Chip>
									)
								)
							}
							renderInput={(params) => (
								<>
									<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Additional details
							  </Typography>
							  <TextField
									// required={
									// 	formikFirst.values.additionalDetails.length ===
									// 	0
									// }
									{...params}
									// variant={'standard'}
									//label="Additional details"
									fullWidth
									//placeholder="Add"
									sx={{
										transition: "0.1s linear",
									}}
								/>
								</Stack>

								</>
							
							)}
						/> */}

					</Stack>
					<Autocomplete
						options={[...dataJson.cities].map((option) => option)}
						freeSolo
						onChange={(e, newValue) => {
							formikFirst.setFieldValue("location", newValue);
						}}
						name={"location"}
						value={formikFirst.values.location}
						renderTags={(value, getTagProps) =>
							formikFirst.values.location.map((option, index) => (
								<Chip
									variant="contained"
									sx={{
										backgroundColor: "#E3E8F0",
										marginLeft: "5px",
										marginRight: "5px",
										marginBottom: "10px",
									}}
									deleteIcon={<CloseIcon />}
									onDelete={() =>
										formikFirst.setFieldValue(
											"location",
											formikFirst.values.location.filter(
												(f) => f !== option
											)
										)
									}
									label={option}
									{...getTagProps({ index })}></Chip>
							))
						}
						renderInput={(params) => (
							<>
									<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Location (HQ) *
							  </Typography>
							<TextField
							error={Boolean(formikFirst.errors.location)}
									helperText={
										formikFirst.errors.location
											? "Location is required"
											: ""
									}
								{...params}
								// variant={'standard'}
							//	label="Location (HQ)"
							//	placeholder="Add"
								sx={{
									transition: "0.1s linear",
								}}
							/>
							</Stack>
							</>
						)}
					/>
					
					{/* <Autocomplete 
						options={[...filter].map((option) => option)}
						freeSolo
						onChange={(e, newValue) => {
							formikFirst.setFieldValue("location", newValue);
						}}
						name={"location"}
						value={formikFirst.values.location}
						renderTags={(value, getTagProps) =>
							formikFirst.values.location.map((option, index) => (
								<Chip
									variant="contained"
									sx={{
										backgroundColor: "#E3E8F0",
										marginLeft: "5px",
										marginRight: "5px",
										marginBottom: "10px",
									}}
									deleteIcon={<CloseIcon />}
									onDelete={() =>
										formikFirst.setFieldValue(
											"location",
											formikFirst.values.location.filter(
												(f) => f !== option
											)
										)
									}
									label={option}
									{...getTagProps({ index })}></Chip>
							))
						}
						renderInput={(params) => (
							<TextField
								required={
									formikFirst.values.location &&
									formikFirst.values.location.length === 0
								}
								onChange={(e) =>
									setSearchLoaction(e.target.value)
								}
								{...params}
								// variant={'standard'}
								label="Location (HQ)"
								placeholder="Add"
								sx={{
									transition: "0.1s linear",
								}}
							/>
						)}
					/> */}

					<Autocomplete
						multiple
						options={[...dataJson.markets].map(
							(option) => option
						)}
						onChange={(e, newValue) => {
							formikFirst.setFieldValue("market", newValue);
						}}
						name={"market"}
						value={formikFirst.values.market}
						renderTags={(value, getTagProps) =>
							formikFirst.values.market.map((option, index) => (
								<Chip
									variant="contained"
									sx={{
										background: " #E0E3FF",
										marginLeft: "5px",
										marginRight: "5px",
										marginBottom: "10px",
									}}
									deleteIcon={<CloseIcon />}
									onDelete={() =>
										formikFirst.setFieldValue(
											"market",
											formikFirst.values.market.filter(
												(f) => f !== option
											)
										)
									}
									label={option}
									{...getTagProps({ index })}></Chip>
							))
						}
						renderInput={(params) => (
							<>
									<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Market * <span style={{color:"#818181"}}>(where are your customers?) </span>
							  </Typography>
							<TextField
								error={Boolean(formikFirst.errors.market)}
								helperText={
									formikFirst.errors.market
										? "Market is required"
										: ""
								}
								{...params}
								// variant={'standard'}
								//label="Market (where are your customers?)"
								//placeholder="Add"
								sx={{
									transition: "0.1s linear",
								}}
							/>
							</Stack>
							</>
						)}
					/>

					<FormControl component="fieldset">
						<FormLabel
						style={{color:"black"}}
							component="legend"
							sx={{ textAlign: "left" ,mt:3}}>
							Select type of business*
						</FormLabel>
						<RadioGroup
							row
							aria-label="businessType"
							name={"businessType"}
							value={formikFirst.values.businessType}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.businessType &&
								Boolean(formikFirst.errors.businessType)
							}
							// helperText={formik.touched.businessType && formik.errors.businessType}
						>
							<FormControlLabel
								value="B2B"
								control={<Radio />}
								label="B2B"
							/>
							<FormControlLabel
								value="B2C"
								control={<Radio />}
								label="B2C"
							/>
							<FormHelperText
								error={
									formikFirst.touched.businessType &&
									Boolean(formikFirst.errors.businessType)
								}
								children={
									formikFirst.touched.businessType &&
									formikFirst.errors.businessType
								}
							/>
						</RadioGroup>
					</FormControl>
				</Stack>
			</Container>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3}
				width={"100%"}>
				<Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}

					onClick={cbBack}>
					Back
				</Button>
				<Button
					variant={"contained"}
					color={"primary"}
					sx={{ px: 5 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}

					type={"submit"}>
					Next
				</Button>
			</Stack>
		</Stack>
	);
}
