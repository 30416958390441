import * as React from "react";
import Typography from "@mui/material/Typography/Typography";
import Stack from "@mui/material/Stack/Stack";
import moment from "moment/moment";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import { AuthUserContext } from "../../providers/auth-provider";
import { Avatar, Badge, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Circle } from "@mui/icons-material";
import { stringAvatar, stringToColor } from "../../utils/avatarGenerator";
export default function NotificationItem({ notification }) {
  const { profile } = React.useContext(AuthUserContext);
  const navigator = useNavigate();

  React.useEffect(() => {
    debugger;
    if (
      notification &&
      notification.uid === profile?.uid &&
      notification.read === false
    ) {
      const msgRef = doc(DB, "notifications", notification.id);
      setDoc(
        msgRef,
        {
          read: true,
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      ).then();
    }
  }, [notification]);

  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
    >
      <Avatar
        src={notification?.imageURL}
        imgProps={{
          sx: {
            objectFit:
              notification?.type === "unread-message" ? "scale-down" : "cover",
            p: notification?.type === "unread-message" ? 1 : 0,
          },
        }}
        alt={"System notification"}
        sx={{ border: "1px solid #ccc", height: 50, width: 50 }}
      />
      <Stack
        sx={{ width: "70%" }}
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={0.5}
      >
        <Stack
          direction={"row"}
          variant={"body2"}
          color={"#595959"}
          alignItems={"center"}
          sx={{ flex: 2 }}
        >
          {notification?.type === "admin-update-profile" && (
            <>
              <b style={{ fontWeight: 600 }}>{`${notification?.section}`}</b>
              &nbsp;{notification?.message}
              <Button
                variant={"text"}
                color={"primary"}
                size={"small"}
                onClick={() => navigator(`/${profile?.userType}/profile`)}
              >
                <span
                  style={{
                    textDecoration: "underline",
                    fontWeight: 600,
                    cursor: "pointer",
                    color: "#0000FF",
                  }}
                >
                  {" "}
                  Click to View
                </span>
              </Button>
            </>
          )}

          {/*  for all notifications */}
          {notification?.message !== "" &&
            (notification?.type !== "reaction-time-line-post" ||
              notification?.type !== "post-comment-notification" ||
              notification?.type !== "mentioned-comment" ||
              notification?.type !== "mentioned-social-post") &&
            notification?.type !== "admin-update-profile" && (
              <>
                {!(
                  notification?.type === "decline-applicant" ||
                  notification?.type === "accept-applicant"||
                  notification?.type === "contract-approved" ||
                  notification?.type === "contract-rejected"
                ) && (
                  <b style={{ fontWeight: 600 }}>{`${profile?.displayName}`}</b>
                )}
                &nbsp;
                {notification?.message}
                {notification?.type === "unread-message" && (
                  <Button
                    variant={"text"}
                    color={"primary"}
                    size={"small"}
                    onClick={() => navigator(`/${profile?.userType}/chat`)}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        fontWeight: 600,
                        cursor: "pointer",
                        color: "#0000FF",
                      }}
                    >
                      {" "}
                      Click to View
                    </span>
                  </Button>
                )}
                {(notification?.type === "contract-approved" ||
                  notification?.type === "contract-rejected") && (
                  <Button
                    variant={"text"}
                    color={"primary"}
                    size={"small"}
                    onClick={() => navigator(`/${profile?.userType}/contract-details/${notification?.contractId}`)}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        fontWeight: 600,
                        cursor: "pointer",
                        color: "#0000FF",
                      }}
                    >
                      {" "}
                      Click to View
                    </span>
                  </Button>
                )}
              </>
            )}
          {/* for post engagement */}
          {notification?.message === "" &&
            (notification?.type === "reaction-time-line-post" ||
              notification?.type === "post-comment-notification") && (
              <>
                {notification?.type === "post-comment-notification" ? (
                  <>
                    <b
                      style={{ fontWeight: 600 }}
                    >{`${notification?.displayName}`}</b>
                    &nbsp; commented on your post. &nbsp;
                    <Button
                      variant={"text"}
                      color={"primary"}
                      size={"small"}
                      onClick={() =>
                        navigator(
                          `/${profile?.userType}/post-details/${notification?.postId}`
                        )
                      }
                    >
                      <span
                        style={{
                          textDecoration: "underline",
                          fontWeight: 600,
                          cursor: "pointer",
                          color: "#0000FF",
                        }}
                      >
                        {" "}
                        Click to View
                      </span>
                    </Button>
                  </>
                ) : (
                  <>
                    <b
                      style={{ fontWeight: 600 }}
                    >{`${notification?.displayName} `}</b>
                    &nbsp; reacted with &nbsp;
                    {notification?.react === "Like" ? (
                      <img
                        src={"/assets/likes-icons-set/Like.png"}
                        height={20}
                      />
                    ) : notification?.react === "Punch" ||
                      notification?.react === "Yeah!" ? (
                      <img
                        src={"/assets/likes-icons-set/Punch.png"}
                        height={20}
                      />
                    ) : notification?.react === "Celebrate" ? (
                      <img
                        src={"/assets/likes-icons-set/Celebrate.png"}
                        height={20}
                      />
                    ) : notification?.react === "LOL" ? (
                      <img
                        src={"/assets/likes-icons-set/LOL.png"}
                        height={20}
                      />
                    ) : notification?.react === "Insightful" ? (
                      <img
                        src={"/assets/likes-icons-set/Insightful.png"}
                        height={20}
                      />
                    ) : (
                      <img
                        src={"/assets/likes-icons-set/Puke.png"}
                        height={20}
                      />
                    )}
                    &nbsp;
                    <b style={{ fontWeight: 600 }}>{notification?.react}</b>
                    &nbsp;
                    {`on your post. `}
                    <Button
                      variant={"text"}
                      color={"secondary"}
                      size={"small"}
                      onClick={() =>
                        navigator(
                          `/${profile?.userType}/post-details/${notification?.postId}`
                        )
                      }
                    >
                      <span
                        style={{
                          textDecoration: "underline",
                          fontWeight: 600,
                          cursor: "pointer",
                          color: "#0000FF",
                        }}
                      >
                        {" "}
                        Click to View{" "}
                      </span>
                    </Button>
                  </>
                )}
              </>
            )}
          {notification?.message === "" &&
            notification?.type === "mentioned-comment" && (
              <>
                <b
                  style={{ fontWeight: 600 }}
                >{`${notification?.displayName}`}</b>
                &nbsp;mentioned you in a comment.
                <Button
                  variant={"text"}
                  color={"primary"}
                  size={"small"}
                  onClick={() =>
                    navigator(
                      `/${profile?.userType}/post-details/${notification?.postId}`
                    )
                  }
                >
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: 600,
                      cursor: "pointer",
                      color: "#0000FF",
                    }}
                  >
                    {" "}
                    Click to View
                  </span>
                </Button>
              </>
            )}
          {/* for mentioned in social post*/}
          {notification?.message === "" &&
            notification?.type === "mentioned-social-post" && (
              <>
                <b
                  style={{ fontWeight: 600 }}
                >{`${notification?.displayName}`}</b>
                &nbsp;mentioned you in a social post.
                <Button
                  variant={"text"}
                  color={"primary"}
                  size={"small"}
                  onClick={() =>
                    navigator(
                      `/${profile?.userType}/post-details/${notification?.postId}`
                    )
                  }
                >
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: 600,
                      cursor: "pointer",
                      color: "#0000FF",
                    }}
                  >
                    {" "}
                    Click to View
                  </span>
                </Button>
              </>
            )}
          {/* for application received*/}

          {notification?.type === "apply-applicant" && (
            <>
              <Button
                variant={"text"}
                style={{
                  textDecoration: "underline",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                size={"small"}
                onClick={() =>
                  navigator(
                    `/${profile.userType}/applicants/${notification?.request}`
                  )
                }
              >
                View Now
              </Button>
            </>
          )}
          {/* for profile matches */}
        </Stack>
        {notification?.type === "request-profile-matches" && (
          <>
            <Button
              variant={"text"}
              size={"small"}
              color={"secondary"}
              onClick={() =>
                navigator(`/${profile?.userType}/request-matches-list`, {
                  state: { members: notification?.matches },
                })
              }
            >
              {`${notification.matches[0].displayName} and ${
                notification.matches.length > 1
                  ? `${notification.matches.length} others`
                  : `${notification.matches.length} other`
              }.`}
              &nbsp;
              <span
                style={{
                  textDecoration: "underline",
                  fontWeight: 600,
                  cursor: "pointer",
                  color: "#0000FF",
                }}
              >
                {" "}
                Click to View
              </span>
            </Button>
          </>
        )}
        {notification?.type === "suggest-request" && (
          <>
            <Button
              variant={"text"}
              style={{
                textDecoration: "underline",
                fontWeight: 600,
                cursor: "pointer",
              }}
              size={"small"}
              onClick={() =>
                navigator(
                  `/${profile.userType}/open-requests-details/${notification?.request}`
                )
              }
            >
              Click to View
            </Button>
          </>
        )}
        {notification?.type === "accept-suggest-request" && (
          <>
            <Button
              variant={"text"}
              style={{
                textDecoration: "underline",
                fontWeight: 600,
                cursor: "pointer",
              }}
              size={"small"}
              onClick={() =>
                navigator(
                  `/${profile.userType}/open-requests-details/${notification?.request}?suggestedUserId=${notification.suggestedUserId}`
                )
              }
            >
              Click to View
            </Button>
          </>
        )}
      </Stack>

      {/* general */}
      <Box flex={1} />
      <Typography variant={"body2"} color={"#595959"}>
        {moment(notification?.createdAt?.seconds * 1000).format("MMM DD, YYYY")}{" "}
        at {moment(notification?.createdAt?.seconds * 1000).format("h:mm A")}
      </Typography>
    </Stack>
  );
}
