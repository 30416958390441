import React from "react";
import { DB } from "../../../config/firebase/firebase";
import {
	styled,
	Container,
	Stack,
	Typography,
	Avatar,
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogContent, DialogActions, DialogContentText, Badge
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { a11yProps, TabPanel } from "../../../components/common/tabs-custom";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PostItem from "../../../components/common/timeline/post-item";
import UploadFile from "../../../utils/upload-file";
// import {AuthUserContext} from "../../providers/auth-provider";
import { getAuth, getIdToken, updateProfile } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import InvestorPortfolio from "../../../components/common/profile/investor-portfolio";
import StartupAbout from "../../../components/common/profile/startup-about";
import InvestorDescription from "../../../components/common/profile/investor-description";
import ExpertDescription from "../../../components/common/profile/expert-description";
import BusinessDeveloperDescription from "../../../components/common/profile/business-developer-description";
import ServiceProviderDescription from "../../../components/common/profile/service-provider-description";
import StartupDescription from "../../../components/common/profile/startup-description";
import FounderAbout from "../../../components/common/profile/founder-about";
import {
	collection,
	getDocs,
	where,
	query,
	doc,
	getDoc,
	setDoc, updateDoc, orderBy
} from "firebase/firestore";
import StartChat from "../../../components/common/start-chat";
import FounderInvestorAbout from "../../../components/common/profile/founder-investor-about";
import InvestorPortfolioForm from "../../investor/onboarding-forms/portfolio-form";
import PortfoliosListView from "../../investor/onboarding-forms/portfolios-list-view";
import { EditSharp } from "@mui/icons-material";
import EditFounderDetailsComponent
	from "../../../components/common/profile/edit/startup/edit-founder-details-component";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import PostNotification from "../../../api/post-notification";
import InvestmentPortfolioView from "../../../components/common/profile/investor-portfolio-view";

const StyledTabs = styled((props) => (
	<Tabs
		sx={{ position: "relative", left: 0, top: 15 }}
		{...props}
		TabIndicatorProps={{
			children: <span className="MuiTabs-indicatorSpan" />,
		}}
	/>
))({
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
	},
	"& .MuiTabs-indicatorSpan": {
		maxWidth: 40,
		width: "100%",
		backgroundColor: "none",
	},
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: "none",
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(16),
		marginRight: theme.spacing(1),
		borderRadius: "30px",
		color: "#000",
		"&.Mui-selected": {
			color: "#fff",
			backgroundColor: "#233DFF",
		},
		"&.Mui-focusVisible": {
			backgroundColor: "rgba(100, 95, 228, 0.32)",
		},
	})
);
export default function FounderProfileView() {
	const { setUser, user, setFounder } =
		React.useContext(AuthUserContext);
	const contextUser = React.useContext(AuthUserContext)
	const {state} = useLocation()
	const [profile, setProfile] = React.useState(state?.profile)
	
	const { founder } = {founder: {...profile?.startup?.founder, photoURL: profile?.photoURL}};
	const [portfolioIndex, setPortfolioIndex] = React.useState();
	const [imageURL, setImageURL] = React.useState();
	const confirmed = React.useRef(false);
	const profileImage = React.useRef();
	const [open, setOpen] = React.useState(false);
	const [steps, setSteps] = React.useState(0);
	const [key, setKey] = React.useState();

	const userRef = doc(DB, "users", profile.uid);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [portfolios, setPortfolios] = React.useState(
		profile?.userType === 'investor' ? profile?.investor?.portfolio : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
			: profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType]?.investorDetails?.portfolio
	);
	const regexMatch = new RegExp('/startup/profile/founder')
	const [posts, setPosts] = React.useState([]);
	const [index, setIndex] = React.useState(-1);
	// const [profile, setProfile] = React.useState(null);
	const [value, setValue] = React.useState(0);

	function handleDialogOpen() {
		setDialogOpen(true);
	}
	function handleDialogClose() {
		setDialogOpen(false);
	}
	const navigator = useNavigate()
	const Input = styled("input")({
		display: "none",
	});
	async function updateProfilePicture(url) {
		try {
			const auth = getAuth();
			/*console.log(url);
			const displayName = founder?.founderName;
			await updateProfile(auth.currentUser, {
				displayName: founder.founderName,
				photoURL: url
			});
			const data = auth.currentUser.toJSON();
			debugger
			setUser({ ...user });*/
			const userRef = await doc(DB, "users", profile);
			let dat;
			dat = await updateDoc(userRef, {
				"startup.founder.photoURL": url,
				photoURL: url
			});
			setProfile({ ...profile, photoURL: url });
			Object.assign(profile, { founder: { ...founder, photoURL: url } })
			Object.assign(profile, { photoURL: url })
			setProfile({ ...profile, photoURL: url });
			setImageURL("")
			/*PostNotification({
				type: 'update-profile-picture',
				imageURL: url,
				message: `Your profile picture has been updated`,
				uid: user.uid
			}).then()*/
			confirmed.current = true;
		} catch (err) {
			console.log(err.message);
		}
	}
	function UploadProfile() {
		debugger
		return (

			<div>
				<Dialog
					open={dialogOpen}
					onClose={handleDialogClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">
						{founder.founderName}
					</DialogTitle>
					<DialogContent sx={{ height: 400, width: 600 }}>
						<Avatar
							key={imageURL}
							src={imageURL ? imageURL : profile?.photoURL !== '' ? profile?.photoURL : founder?.photoURL}
							alt={founder?.founderName}
							sx={{
								width: 152,
								height: 152,
								ml: 25,
							}}
							component={"span"}
						/>
					</DialogContent>
					<DialogActions
						sx={{ justifyContent: "start", height: 100 }}>

						<Stack direction={"row"} spacing={-5}>
							<IconButton sx={{ width: 150, "&:hover": { backgroundColor: "white" } }} variant="outlined">
								<label htmlFor={"profile-image"}>
									<Stack direction={"column"}>
										<CameraAltIcon
											fontSize="large"
											sx={{ ml: 2, mb: 2, cursor: "pointer" }}
										/>
										<Input
											accept="image/*"
											id="profile-image"
											multiple={false}
											type="file"
											onChange={(e) => {
												confirmed.current = false;
												const fileReader = new FileReader();
												fileReader.readAsDataURL(
													e.target.files[0]
												);
												fileReader.addEventListener(
													"load",
													async function () {
														const file =
															e.target.files[0];

														const path = `profile-image/${file.name}`;
														const imageObject = {
															progress: 0,
															src: this.result,
															path,
															file: e.target.files[0],
															size: (
																e.target.files[0]
																	?.size /
																1024 /
																1024
															).toFixed(2),
														};
														profileImage.current =
															imageObject;
														UploadFile(file, path).then(
															(url) => {
																console.log(url);
																setImageURL(url);
															}
														);
													}
												);
											}}
										/>
										<DialogContentText sx={{ fontSize: 15 }}>
											Add Photo
										</DialogContentText>
									</Stack>
								</label>
							</IconButton>
							<IconButton
								sx={{ width: 150, mr: 12, "&:hover": { backgroundColor: "white" } }}
								variant="outlined"
								onClick={() => {
									profileImage.current = "";
									setImageURL(" ");

									// updateProfilePicture("");
									confirmed.current = true;
									//handleDialogClose();
								}}>
								<Stack direction={"column"}>
									<DeleteIcon
										fontSize="large"
										sx={{ ml: 4, mb: 2 }}
									/>

									<DialogContentText
										sx={{ fontSize: 15 }}>
										Remove Photo
									</DialogContentText>
								</Stack>
							</IconButton>
						</Stack>
						<Stack />
						<Button
							sx={{ width: 150, ml: 20 }}
							onClick={() => {


								profileImage.current = {
									...profileImage,
									photoURL: imageURL,
								};

								updateProfilePicture(imageURL);


								profileImage.current = "";

								handleDialogClose();
								//updateProfilePicture("");
							}}
							autoFocus
							variant="contained">
							Save Photo
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	React.useEffect(() => {
		debugger
		if (!imageURL) {
			setImageURL(imageURL ? imageURL : profile?.photoURL !== '' ? profile.photoURL : founder?.photoURL);
		}

		const q =
			query(
				collection(DB, "timelines"),
				where("uid", "==", profile?.uid),
				orderBy("createdAt", "desc")
			)

		getDocs(q).then((querySnapshot) => {
			if (querySnapshot.size > 0) {
				const list = [];
				querySnapshot.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });

					console.log(doc.id, " => ", doc.data());
				});
				setPosts(list);
			}
		});

	}, []);
	if (index === -1) {
		return (
			<Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
				{/* {console.log(startup)} */}
				{founder && (
					<Stack direction={"column"} spacing={5}>
						<Stack
							direction={"column"}
							spacing={0.5}
							sx={{
								boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
								borderRadius: "15px",
								backgroundColor: "#fff",
								minHeight: 100,
								p: 2,
								pb: 0,
							}}>
							<Box
								sx={{
									height: 120,
									width: "100%",
									backgroundColor: "#F6F8FA",
									borderRadius: "15px",
								}}
							>
								<Box
									sx={{
										border: "1px solid #204488",
										p: 0.75,
										borderRadius: "50%",
										width: "fit-content",
										position: "relative",
										left: 35,
										top: 60,
									}}>
									<label>

										<Badge
											overlap="circular"
											sx={{ width: 92, height: 92 }}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "right",
											}}
											badgeContent={
												profile?.uid === user?.uid &&
												<Button
													onClick={handleDialogOpen}
													sx={{ ml: 1, mt: 1 }}
													color={"primary"}
													component={"span"}>
													<AddCircleIcon />
												</Button>

											}>
											{profile?.uid === user?.uid &&
												<UploadProfile />
											}
											<Avatar
												alt={founder?.founderName}
												src={imageURL ? imageURL : profile?.photoURL !== '' ? profile.photoURL : founder?.photoURL}
												sx={{ width: 92, height: 92 }}>
												<Typography variant={"h3"}>
													{founder?.founderName.charAt(0)}
												</Typography>
											</Avatar>
										</Badge>
									</label>
								</Box>
							</Box>


							<Stack
								direction={"row"}
								spacing={3}
								sx={{ position: "relative", top: 50 }}>
								<div />
								<Stack direction={"column"} spacing={2} sx={{ width: '100%' }}>
									<Stack direction={"row"} spacing={3}>
										<Typography
											variant={"h5"}
											sx={{ marginTop: "2.5px" }}>
											{founder?.founderName}
										</Typography>
										<Box
											sx={{
												backgroundColor: "#E0E3FF",
												px: 3,
												py: 1,
												borderRadius: "19.5px",
											}}>
											<Typography
												style={{
													fontWeight: 500,
													textTransform: "capitalize",
												}}>
												{"Founder"}
											</Typography>
										</Box>
										{
											profile?.startup?.investorDetails?.areYouAnAngelInvestor &&

											<Box
												sx={{
													backgroundColor: "#B8FC3C",
													px: 3,
													py: 1,
													borderRadius: "19.5px",
												}}>
												<Typography
													style={{
														fontWeight: 500,
														textTransform: "capitalize",
													}}>
													{"Investor"}
												</Typography>
											</Box>
										}
										
									</Stack>
									<Stack
										direction={"row"}
										spacing={1}
										sx={{ position: "relative", top: -10 }}
										>
										<Typography variant={'body2'} color={"grey"} style={{ flex: 1 }}>
											{profile?.countryState}
										</Typography>
										{ <IconButton onClick={() => setOpen(true)}>
											<EditSharp style={{}}/>
										</IconButton>
										}
										{profile?.uid !== user?.uid && <StartChat
											sx={{ color: 'white', backgroundColor: "#233DFF" }}
											data={{ ...profile, creatorData: profile }} props={{ ...a11yProps(4) }} />
										}


									</Stack>

									{founder && founder?.description && (
										<Typography
											variant={"body1"}
											color={"secondary"}
											sx={{
												width: "calc(100% - 10%)",
												position: "relative",
												top: -10,
											}}>
											{founder?.description}
										</Typography>
									)}

								</Stack>
							</Stack>
							<EditFounderDetailsComponent profile={profile} open={open}
								handleClose={() => setOpen(false)} cb={(d) => {
									setOpen(false);
									setProfile(d);
							}} />
							{/* <Box sx={{p: 2, marginTop: '50px!important'}}> */}
							<Stack direction={"row"} sx={{ width: '100%' }} justifyContent="center"
								alignItems="flex-end">

								<StyledTabs
									sx={{
										paddingLeft: '35px'
									}}
									value={value}
									onChange={handleChange}
									aria-label="styled tabs example" 
								>
									<StyledTab label="ABOUT" {...a11yProps(0)} />
									{
										(profile?.userType === "startup"
											&& profile?.startup.investorDetails?.areYouAnAngelInvestor &&
											profile?.startup.investorDetails.areYouAnAngelInvestor === true) ?
											<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(1)} />
											: null

									}
									<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(1)} />
									<StyledTab label="POST" {...a11yProps(2)} />
									{/* {profile?.uid !== user?.uid &&   <StartChat
									sx={{marginLeft: 'auto' , marginRight: '5%',color:'white',backgroundColor: "#233DFF"}}
									data={{...profile, creatorData:profile }} props={{...a11yProps(4)}}/>
								} */}


								</StyledTabs>

								<div style={{ flex: 10 }} />
								<Stack
									direction={"column"}
									spacing={1}
									justifyContent="center"
									sx={{
										position: "relative", minWidth: 120, cursor: 'pointer'
										, paddingTop: '70px'
									}}
									alignItems="center"

									onClick={() => {
										if (contextUser?.profile?.userType === 'admin') {
											navigator(
												"/admin/user-details", {state: {profile: profile}}
											)
										} else {
											navigator(regexMatch.test(window.location.pathname) ? -1 : `/${contextUser?.profile?.userType}/startup-details/${profile?.uid}`)
											
										}
									}
									}
								>
									<Box
										sx={{
											border: "1px solid #204488",
											p: 0.75,
											borderRadius: "50%",
											width: "fit-content",
										}}
									>
										<Avatar
											alt={profile?.startup?.startupName}
											src={
												profile?.startup?.logoURL !== ""
													? profile?.startup?.logoURL
													: ''
											}
											sx={{ width: 51, height: 51 }}
										>
											<Typography variant={"h4"}>
												{profile?.startup?.startupName.charAt(0)}
											</Typography>
										</Avatar>
									</Box>
									<Typography variant={"h6"} sx={{ textAlign: "center" }}>
										{profile?.startup?.startupName}
										<br />
										<font
											size={2}
											style={{
												backgroundColor: "#F6F8FA",
												padding: "5px 10px",
												borderRadius: "6px",
												fontWeight: 300,
											}}
										>
											Startup
										</font>
									</Typography>
								</Stack>
							</Stack>
							<Box sx={{ height: 30 }} />
						</Stack>

						<TabPanel index={0} value={value}>
							<Stack
								direction={"column"}
								spacing={3}
								sx={{
									boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
									borderRadius: "15px",
									backgroundColor: "#fff",
									minHeight: 100,
									px: 5.5,
									py: 6,
								}}
							>
								{profile?.userType === "startup" ?
									<FounderAbout profile={profile} founder={founder} callback={(d) => setProfile(d) } /> :
									null}

							</Stack>
						</TabPanel>

						{profile?.userType === 'startup' &&
							profile?.startup?.investorDetails?.areYouAnAngelInvestor &&
							profile?.startup?.investorDetails?.areYouAnAngelInvestor === true &&
							<TabPanel
								index={1}
								value={value}
							>
								<Stack
									direction={"column"}
									spacing={6}
									sx={{
										boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
										borderRadius: "15px",
										backgroundColor: "#fff",
										minHeight: 100,
										px: 5.5,
										py: 6,
									}}
								>
									{steps === 1 ?<>
											{(portfolioIndex !== undefined && portfolioIndex > -1) ?
												<Typography variant={"h5"}>Update portfolio</Typography> :  <Typography variant={"h5"}>Add portfolio</Typography>}
											<InvestorPortfolioForm
												cbBack={() => {
													setSteps(0)
													setPortfolioIndex()
												}}
												cbNext={(data, index) => {
													const port = [...portfolios]
													if (index !== undefined && index > -1) {
														port.splice(index, 1, { ...data})
													} else {
														port.push({ ...data })
													}
													const copyPortfolio = []
													debugger
													if (port?.length > 0) {
														for (let portfolio of port) {
															const attachments =[]
															if (portfolio?.attachments?.length > 0) {
																for (let file of portfolio?.attachments) {
																	if (file?.file) {
																		const path = `investors/${profile.uid}/attachments/${file.file.name}`
																		attachments.push(path)
																	} else {
																		attachments.push(file)
																	}
																}
															}
															copyPortfolio.push({...portfolio, attachments})
														}
													}
													const objectUpdate = {}
													if (profile?.userType === 'service-provider') {
														objectUpdate['serviceProvider'] = {...profile['serviceProvider']}
													} else if (profile?.userType === 'business-developer'){
														objectUpdate['businessDeveloper'] = {...profile['businessDeveloper']}
													} else {
														debugger
														objectUpdate[profile?.userType] = {...profile[profile?.userType]}
													}
													if (profile?.userType !== 'investor') {
														debugger
														objectUpdate[
															profile?.userType === 'service-provider' ? 'serviceProvider'
																: profile?.userType === 'business-developer' ? 'businessDeveloper'
																	: profile?.userType].investorDetails.portfolio = [...copyPortfolio];
													} else {
														debugger
														objectUpdate[profile?.userType].portfolio = [...copyPortfolio] ;
													}
													setPortfolios([...copyPortfolio])
													setDoc(
														userRef,
														{...objectUpdate,
															updatedById:user?.uid,
															updatedBy:  'admin' ,
															updatedAt: new Date().getTime()},
														{ merge: true }
													).then(() => {
														setProfile({...profile,...objectUpdate })
													});
													PostNotification({
														type: 'admin-update-profile',
														imageURL: '/assets/system-noti-icon.png',
														section: 'Investment Portfolio',
														message: `information has been updated by the Admin.`,
														uid: profile.uid,
														createdBy: user?.uid
														
													}).then()
													setSteps(0)
													
													
													
													setPortfolioIndex();
													
												}}
												data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}
												uid={profile?.uid}
												index={portfolioIndex}
											/>
										</>
										: <>
											<Typography fontSize={20} fontWeight={600}>Investment portfolio</Typography>
											
											<PortfoliosListView
												
												add={(data) => {
													setPortfolioIndex();
													setSteps(1);
												}}
												edit={async (ind) => {
													debugger
													await setPortfolioIndex(ind);
													setSteps(1);
													
												}}
												remove={(ind) => {
													const port = [...portfolios]
													port.splice(ind, 1)
													setPortfolios([...port])
													const objectUpdate = {}
													if (profile?.userType === 'service-provider') {
														objectUpdate['serviceProvider'] = {...profile['serviceProvider']}
													} else if (profile?.userType === 'business-developer'){
														objectUpdate['businessDeveloper'] = {...profile['businessDeveloper']}
													} else {
														debugger
														objectUpdate[profile?.userType] = {...profile[profile?.userType]}
													}
													if (profile?.userType !== 'investor') {
														debugger
														objectUpdate[
															profile?.userType === 'service-provider' ? 'serviceProvider'
																: profile?.userType === 'business-developer' ? 'businessDeveloper'
																	: profile?.userType].investorDetails.portfolio = [...port];
													} else {
														debugger
														objectUpdate[profile?.userType].portfolio = [...port] ;
													}
													setDoc(
														userRef,
														{...objectUpdate,
															updatedById:user?.uid,
															updatedBy:  'admin' ,
															updatedAt: new Date().getTime()},
														{ merge: true }
													).then(() => {
														setProfile({...profile,...objectUpdate })
													});
													setSteps(0);
													
												}}
												portfolios={portfolios}
											/>
										</>
										
									}
								</Stack>
								{console.log('TEST')}
							</TabPanel>
						}
						{profile?.userType === 'startup' &&
							profile?.startup?.investorDetails?.areYouAnAngelInvestor &&
							profile?.startup?.investorDetails?.areYouAnAngelInvestor === true ?

							<TabPanel
								index={0}
								value={value}
							>
								<Stack
									direction={"column"}
									spacing={6}
									sx={{
										boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
										borderRadius: "15px",
										backgroundColor: "#fff",
										minHeight: 100,
										px: 5.5,
										py: 6,
									}}
								>
									<FounderInvestorAbout profile={profile} callback={(d) => setProfile(d)}/>
								</Stack>
							</TabPanel>
							: null
						}
						<TabPanel
							index={profile?.startup?.investorDetails?.areYouAnAngelInvestor ? 2 : 1}
							value={value}
						>
							<Stack sx={{
								boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
								borderRadius: "15px",
								backgroundColor: "#fff",
								minHeight: 100,
								px: 5.5,
								py: 6,
								textAlign: "center",
							}}>
								<Stack direction={"column"} spacing={5} >
									{posts.length > 0 ?
										posts.map((post, i) => <PostItem data={post} key={i} />
										)
										: <Typography>There are no posts to be shown</Typography>
									}
								</Stack>
							</Stack>
						</TabPanel>
						{/* <FounderAbout founder={founder} /> */}

					</Stack>
				)}
			</Container>
		)
	} else {
		return (<>
			{profile?.startup?.investorDetails?.portfolio?.map((p, i) => (
				(i === key) ? (<InvestmentPortfolioView profile={profile} i={i} />) : null

			))}


		</>)
	};
}
