import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { DB } from "../config/firebase/firebase";
import { SUGGESTED_STATUS } from "../utils/enums/suggestions-state";

const GetAdminSuuggestedProfiles = (requestId) =>
  new Promise(async (resolve, reject) => {
    try {
      const suggestedProfiles = await getSuggestedProfiles(requestId);
      resolve(suggestedProfiles);
    } catch (e) {
      reject(null);
    }
  });
const getSuggestedProfiles = async (requestId) => {
  const usersRef = collection(
    DB,
    "help-requests",
    requestId,
    "users-suggested"
  );
  const usersQuerySnapshot = await getDocs(
    query(usersRef, where("status", "==", SUGGESTED_STATUS.ACCEPTED))
  );
  const suggestUserList = [];

  for (const userDoc of usersQuerySnapshot.docs) {
    const userId = userDoc.id;
    const userDataSnapshot = await getDoc(doc(DB, "users/" + userId));
    const userData = userDataSnapshot.data();
    suggestUserList.push({ id: userId, adminSuggested: true, ...userData });
  }
  console.log("suggestUserList", suggestUserList);
  return suggestUserList;
};
export default GetAdminSuuggestedProfiles;
