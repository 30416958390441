import {Button, Container, Stack, Typography} from "@mui/material";
import {callbackGoogleWithSignin} from "./signin";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import React from "react";
import {useNavigate} from "react-router-dom";


export default function HomeScreen(){
  const navigator = useNavigate();

  return(

    <Container maxWidth={'xs'}>
      <Stack direction={"column"} spacing={3} justifyContent="center"
             alignItems="center" sx={{height: '92vh'}}>
        <div style={{flex: 1}}/>
        <img src={'/assets/imoji-1.png'}/>
        <Typography variant={'h6'} fontWeight={600} textAlign={"center"}>Oops! Looks like you’re on Mobile!</Typography>
        <Typography variant={'body1'} textAlign={"center"}>
          We are just launching our MVP and our mobile
          experience isn’t all that we would want it to be</Typography>
        <Typography variant={'body1'} textAlign={"center"}> If you want a better experience, you can check on Laptop/Desktop 🙏🏼🙏🏼</Typography>
        <Button variant={"contained"} color={'primary'} fullWidth onClick={() => navigator('/m/auth/where-are-you-located')}>Sign up</Button>
        <div/>
        <div
          style={{
            backgroundColor: "#000",
            width: "100%",
            height: 1,
          }}
        />
        <div
          style={{
            width: "75px",
            margin: "auto",
            top: "-13px",
            position: "relative",
          }}>
          <Typography
            sx={{
              backgroundColor: "#FBFBFB",
              textAlign: "center",
            }}>
            OR
          </Typography>
        </div>
        <Container maxWidth="sm" sx={{}}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}>
              <Button
                style={{ textTransform: "lowercase" }}
                variant={"text"}
                color={"secondary"}
                startIcon={
                  <img
                    style={{ height: "20px" }}
                    src={"/assets/google.png"}></img>
                }
                disabled={true}
                onClick={() =>{}
                 /* callbackGoogleWithSignin(
                    navigator,
                    setProfile
                  )*/
                }>
                <Typography color={"#8F8F8F"}>
										<span
                                          style={{
                                            textTransform: "capitalize",
                                          }}>
											{" "}
                                          Continue{" "}
										</span>
                  <span
                    style={{
                      textTransform: "lowercase",
                    }}>
											with
										</span>
                  <span
                    style={{
                      textTransform: "capitalize",
                    }}>
											{" "}
                    Google
										</span>
                </Typography>
              </Button>
              <Button
                style={{ textTransform: "lowercase" }}
                variant={"text"}
                color={"secondary"}
                disabled={true}

                startIcon={
                  <LinkedInIcon
                    style={{ color: "#204488" }}
                  />
                }>
                <Typography color={"#8F8F8F"}>
										<span
                                          style={{
                                            textTransform: "capitalize",
                                          }}>
											{" "}
                                          Continue{" "}
										</span>
                  <span
                    style={{
                      textTransform: "lowercase",
                    }}>
											with
										</span>
                  <span
                    style={{
                      textTransform: "capitalize",
                    }}>
											{" "}
                    Linked
										</span>
                  <span
                    style={{
                      textTransform: "capitalize",
                    }}>
											In
										</span>
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Container>
        <div style={{flex: 1}}/>
        <Typography variant={"body1"} color={"secondary"}>
          Already on AstraNova?{" "}
          <Button
            variant={"text"}
            color={"primary"}
            onClick={() => navigator("/m/auth/signin")}>
            Sign In
          </Button>
        </Typography>
      </Stack>

    </Container>
  )
}