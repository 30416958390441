import React from "react";
import {
	Button,
	Container,
	Stack,
	Typography,
	IconButton,
	TextField,
	FormControlLabel,
	FormControl,
	FormLabel,
	Switch,
	Avatar,
	styled,
	CircularProgress,
	Link,
	Badge,
	Autocomplete,
	Chip,
	Box,
	InputAdornment,
} from "@mui/material";
import { getStorage, ref, deleteObject } from "firebase/storage";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import { useFormik, FieldArray, getIn, FormikProvider } from "formik";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFile from "../../../utils/upload-file";
import DragDropFiles, {
	DragDropItem,
} from "../../../screens/mobile/components/common/drag-drop-files";
import TalentDetailsValidation from "../../../validations/talent/talent-profile";

function valuetext(value) {
	return `$${value}`;
}
export default function TalentProfile() {
	const Input = styled("input")({
		display: "none",
	});
	const { user, setProfile } = React.useContext(AuthUserContext);

	const [loading, setLoading] = React.useState(false);
	const [steps, setSteps] = React.useState(0);
	const fileTypes = [
		"JPG",
		"JPEG",
		"PNG",
		"GIF",
		"DOC",
		"DOCX",
		"PDF",
		"MOV",
		"XLS",
		"CSV",
	];
	const [files, setFiles] = React.useState([]);

	const [profileImage, setProfileImage] = React.useState({
		src: user.photoURL ? user.photoURL : null,
		photoURL: user.photoURL,
	});

	const navigator = useNavigate();

	const formikFirst = useFormik({
		initialValues: TalentDetailsValidation.TalentFirstFormInitialValues,
		validationSchema: TalentDetailsValidation.TalentFirstFormSchema,
		onSubmit: async (values) => {
			try {
				debugger;
				const userRef = doc(DB, "users", user.uid);
				const talent = {
					...values,
					photoURL: profileImage?.photoURL
						? profileImage.photoURL
						: "",
				};
				await setDoc(
					userRef,
					{
						photoURL: profileImage?.photoURL
							? profileImage.photoURL
							: "",
						talent,
					},
					{ merge: true }
				);
				setSteps(1);
			} catch (e) {
				debugger;
				console.log(e);
			}
		},
	});
	const formikSecond = useFormik({
		initialValues: TalentDetailsValidation.TalentSecondFormInitialValues,
		validationSchema: TalentDetailsValidation.TalentSecondFormSchema,
		onSubmit: async (values) => {
			try {
				const socialProof = [];
				if (files.length > 0) {
					for (let file of files) {
						const path = `talents/${user.uid}/attachments/${file.file.name}`;
						socialProof.push(path);
					}
				}
				formikSecond.setFieldValue("socialProof", socialProof);
				const userRef = doc(DB, "users", user.uid);
				await setDoc(
					userRef,
					{ talent: { ...formikFirst.values, values, socialProof } },
					{ merge: true }
				);
				setSteps(2);
			} catch (e) {
				debugger;
				console.log(e);
			}
		},
	});
	const formikThird = useFormik({
		initialValues: TalentDetailsValidation.TalentThirdFormInitialValues,
		validationSchema: TalentDetailsValidation.TalentThirdFormSchema,
		onSubmit: async (values) => {
			const {
				doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue,
			} = values;
			try {
				const userRef = doc(DB, "users", user.uid);
				await setDoc(
					userRef,
					{
						talent: {
							...formikSecond.values,
							...formikFirst.values,
							...values,
							photoUrl: profileImage?.photoURL
								? profileImage.photoURL
								: "",
						},
						onboard: true,
						doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue,
					},
					{ merge: true }
				);
				const getdoc = await getDoc(userRef);
				await setProfile(getdoc.data());
				navigator("/talent/timeline");
				// setSteps(2);
			} catch (e) {
				debugger;
				console.log(e);
			}
		},
	});

	return (
		<>
			<Container maxWidth={"full-width"} sx={{ marginY: 5 }}>
				<Stack
					direction={"row"}
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					width={"100%"}>
					<IconButton
						onClick={() =>
							steps === 0 ? navigator(-1) : setSteps(steps - 1)
						}>
						<ArrowBackIcon sx={{ fontSize: 40 }} />
					</IconButton>
					<Typography
						variant={"h5"}
						sx={{ textAlign: "left", lineHeight: 1 }}>
						Talent
						<br />{" "}
						<small style={{ fontSize: 14, color: "#8F8F8F" }}>
							Talent Profile
						</small>
					</Typography>
				</Stack>
			</Container>
			<Container maxWidth={"lg"} sx={{ mb: 5 }}>
				{steps === 0 && (
					<Stack
						direction={"column"}
						spacing={10}
						component="form"
						onSubmit={(e) => formikFirst.handleSubmit(e)}>
						<Container maxWidth={"md"}>
							<Stack direction={"column"} spacing={5}>
								<Stack
									direction="column"
									justifyContent="center"
									alignItems="center"
									spacing={1}
									sx={{ mb: 5 }}>
									<label htmlFor="profile-image">
										<Badge
											overlap="circular"
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "right",
											}}
											badgeContent={
												<IconButton
													color={"primary"}
													component={"span"}>
													<AddCircleIcon />
												</IconButton>
											}>
											<Avatar
												alt={"startup-picture"}
												src={
													profileImage &&
													profileImage?.src
														? profileImage?.src
														: "/assets/default-avatar.png"
												}
												sx={{
													width: 128,
													height: 128,
													cursor: "pointer",
													backgroundColor:
														"transparent",
													border: "1px solid #E0E3FF",
												}}
												component={"span"}
											/>
										</Badge>
										<Input
											accept="image/*"
											id="profile-image"
											multiple={false}
											type="file"
											onChange={(e) => {
												const fileReader =
													new FileReader();
												fileReader.readAsDataURL(
													e.target.files[0]
												);
												fileReader.addEventListener(
													"load",
													async function () {
														setLoading(true);
														const file =
															e.target.files[0];
														const path = `profile-image/${user.uid}/${file.name}`;
														const imageObject = {
															progress: 0,
															src: this.result,
															path,
															file: e.target
																.files[0],
															size: (
																e.target
																	.files[0]
																	?.size /
																1024 /
																1024
															).toFixed(2),
														};
														setProfileImage(
															imageObject
														);
														UploadFile(
															file,
															path
														).then((url) => {
															setProfileImage({
																...imageObject,
																photoURL: url,
															});
															setLoading(false);
														});
													}
												);
											}}
										/>
									</label>
									{loading && (
										<CircularProgress
											sx={{ ml: 2 }}
											size={20}
											color="primary"
										/>
									)}
									{profileImage && profileImage?.src && (
										<Link
											color={"secondary"}
											onClick={() =>
												setProfileImage(undefined)
											}>
											Remove picture
										</Link>
									)}
								</Stack>

								<TextField
									required
									name={"description"}
									value={formikFirst.values.description}
									onChange={formikFirst.handleChange}
									error={
										(formikFirst.touched.description &&
											Boolean(
												formikFirst.errors.description
											)) ||
										formikFirst.values.description.length >
											150
									}
									helperText={
										formikFirst.touched.description &&
										formikFirst.errors.description
									}
									label={"Tell us about yourself"}
									multiline={true}
									rows={3}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Stack
													direction={"column"}
													sx={{ height: 85 }}
													justifyContent="flex-end"
													alignItems="flex-end">
													<Typography
														variant={"caption"}
														color={
															formikFirst.values
																.description
																.length > 150
																? "error"
																: "secondary"
														}>
														{
															formikFirst.values
																.description
																.length
														}
														/150
													</Typography>
												</Stack>
											</InputAdornment>
										),
									}}
								/>

								<Autocomplete
									multiple
									required
									options={[
										"Graphic Designer",
										"Full-Stack Developer",
										"Hr",
										"Content Creator",
										"SEO",
										"Digital Marketing",
										"Strategy",
										"ROI",
									].map((option) => option)}
									freeSolo
									onChange={(e, newValue) => {
										formikFirst.setFieldValue(
											"skills",
											newValue
										);
									}}
									name={"skills"}
									value={formikFirst.values.skills}
									renderTags={(value, getTagProps) =>
										formikFirst.values.skills.map(
											(option, index) => (
												<Chip
													variant="contained"
													sx={{
														backgroundColor:
															"#E3E8F0",
														marginLeft: "5px",
														marginRight: "5px",
														marginBottom: "10px",
													}}
													deleteIcon={<CloseIcon />}
													onDelete={() =>
														formikFirst.setFieldValue(
															"skills",
															formikFirst.values.skills.filter(
																(f) =>
																	f !== option
															)
														)
													}
													label={option}
													{...getTagProps({
														index,
													})}></Chip>
											)
										)
									}
									renderInput={(params) => (
										<TextField
											required={
												formikFirst.values.skills
													.length === 0
											}
											{...params}
											// variant={'standard'}
											label="Skills"
											placeholder="Add"
											sx={{
												transition: "0.1s linear",
											}}
										/>
									)}
								/>
								{/* <TextField name={'supportStage'} value={formikFirst.values.supportStage}
                         onChange={formikFirst.handleChange}
                         error={formikFirst.touched.supportStage && Boolean(formikFirst.errors.supportStage)}
                         helperText={formikFirst.touched.supportStage && formikFirst.errors.supportStage}
                         label={'Support startup at stage'} select sx={{textAlign: 'left'}}>
                <MenuItem value={'Very early stage'}>
                  Very early stage
                </MenuItem>
                <MenuItem value={'Early stage'}>
                  Early stage
                </MenuItem>
                <MenuItem value={'Late stage'}>
                  Late stage
                </MenuItem>
              </TextField>*/}

								<Autocomplete
									multiple
									required
									options={[
										"Pre-Seed",
										"Seed",
										"Series A",
										"Series B",
										"Series C+",
									].map((option) => option)}
									freeSolo
									onChange={(e, newValue) => {
										formikFirst.setFieldValue(
											"supportStage",
											newValue
										);
									}}
									name={"supportStage"}
									value={formikFirst.values.supportStage}
									renderTags={(value, getTagProps) =>
										formikFirst.values.supportStage.map(
											(option, index) => (
												<Chip
													variant="contained"
													sx={{
														backgroundColor:
															"#E3E8F0",
														marginLeft: "5px",
														marginRight: "5px",
														marginBottom: "10px",
													}}
													deleteIcon={<CloseIcon />}
													onDelete={() =>
														formikFirst.setFieldValue(
															"supportStage",
															formikFirst.values.supportStage.filter(
																(f) =>
																	f !== option
															)
														)
													}
													label={option}
													{...getTagProps({
														index,
													})}></Chip>
											)
										)
									}
									renderInput={(params) => (
										<TextField
											{...params}
											// variant={'standard'}
											label="Support startup at stage"
											placeholder="Add"
											sx={{
												transition: "0.1s linear",
											}}
										/>
									)}
								/>
							</Stack>
						</Container>
						<Stack
							direction={"row"}
							justifyContent="flex-end"
							alignItems="center"
							spacing={3}
							width={"100%"}>
							<Button
								variant={"outlined"}
								color={"primary"}
								sx={{ px: 5 }}
								onClick={() => navigator(-1)}>
								Back
							</Button>
							<Button
								variant={"contained"}
								color={"primary"}
								sx={{ px: 5 }}
								type={"submit"}>
								Next
							</Button>
						</Stack>
					</Stack>
				)}
				{steps === 1 && (
					<FormikProvider value={formikSecond}>
						<Stack
							direction={"column"}
							spacing={10}
							component="form"
							onSubmit={(e) => formikSecond.handleSubmit(e)}>
							<Container maxWidth={"md"}>
								<Stack direction={"column"} spacing={4}>
									<Autocomplete
										multiple
										required
										options={[
											"Fintech",
											"Climate",
											"Edtech",
										].map((option) => option)}
										freeSolo
										onChange={(e, newValue) => {
											formikSecond.setFieldValue(
												"focusVertical",
												newValue
											);
										}}
										name={"focusVertical"}
										value={
											formikSecond.values.focusVertical
										}
										renderTags={(value, getTagProps) =>
											formikSecond.values.focusVertical.map(
												(option, index) => (
													<Chip
														variant="contained"
														sx={{
															backgroundColor:
																"#E3E8F0",
															marginLeft: "5px",
															marginRight: "5px",
															marginBottom:
																"10px",
														}}
														deleteIcon={
															<CloseIcon />
														}
														onDelete={() =>
															formikSecond.setFieldValue(
																"focusVertical",
																formikSecond.values.focusVertical.filter(
																	(f) =>
																		f !==
																		option
																)
															)
														}
														label={option}
														{...getTagProps({
															index,
														})}></Chip>
												)
											)
										}
										renderInput={(params) => (
											<TextField
												{...params}
												// variant={'standard'}
												label="Focus Vertical"
												placeholder="Add"
												sx={{
													transition: "0.1s linear",
												}}
											/>
										)}
									/>

									<TextField
										name={"socialProofNotes"}
										value={
											formikSecond.values.socialProofNotes
										}
										onChange={formikSecond.handleChange}
										error={
											formikSecond.touched
												.socialProofNotes &&
											Boolean(
												formikSecond.errors
													.socialProofNotes
											)
										}
										helperText={
											formikSecond.touched
												.socialProofNotes &&
											formikSecond.errors.socialProofNotes
										}
										label={"Social proof"}
										sx={{ textAlign: "left" }}
									/>

									<Stack direction={"column"} spacing={2}>
										<Typography sx={{ textAlign: "left" }}>
											Attachments{" "}
											<font style={{ fontWeight: 300 }}>
												<i>
													(References for specific
													kinds of expertise)
												</i>
											</font>
										</Typography>

										<DragDropFiles
											label={{
												text: "Drop here or choose from files",
												caption:
													"(max file size: 50MB)",
											}}
											multiple={true}
											limit={50}
											types={fileTypes}
											onChangeCallback={(fileList) => {
												let list = [];
												if (files.length > 0) {
													list = [...files];
												}
												for (let file of fileList) {
													list.push({ file });
												}
												setFiles([...list]);
											}}
										/>
										<Stack
											direction={"column-reverse"}
											spacing={2}>
											{files.length > 0 &&
												files.map((file, i) => (
													<>
														<DragDropItem
															file={file}
															i={i}
															key={i}
															path={`talents/${user.uid}/attachments/${file.file.name}`}
														/>
														<Button
															onClick={() => {
																let path = `talents/${user.uid}/attachments/${file.file.name}`;
																const storage =
																	getStorage();
																const storageRef =
																	ref(
																		storage,
																		path
																	);
																deleteObject(
																	storageRef
																)
																	.then(
																		() => {
																			if (
																				files.length ===
																				1
																			) {
																				setFiles(
																					[]
																				);
																			} else {
																				setFiles(
																					(
																						current
																					) =>
																						current.filter(
																							(
																								f
																							) =>
																								f !==
																								file
																						)
																				);
																			}
																		}
																	)
																	.catch(
																		(
																			error
																		) => {
																			console.log(
																				error.message
																			);
																		}
																	);
															}}>
															<CancelIcon />
														</Button>
													</>
												))}
										</Stack>
									</Stack>

									<FieldArray
										name={"links"}
										render={(arrayHelpers) => {
											return (
												<Stack
													direction={"column"}
													spacing={2}>
													<Typography
														sx={{
															textAlign: "left",
														}}>
														Additional links
													</Typography>
													{arrayHelpers?.form.values.links.map(
														(p, i) => (
															<Stack
																key={i}
																direction={
																	"column"
																}
																spacing={2}>
																{arrayHelpers
																	?.form
																	.values
																	.links
																	.length >
																	1 && (
																	<Stack
																		direction={
																			"row"
																		}
																		spacing={
																			5
																		}
																		alignItems={
																			"center"
																		}
																		justifyContent={
																			"flex-end"
																		}>
																		<Button
																			variant={
																				"text"
																			}
																			size={
																				"small"
																			}
																			color={
																				"error"
																			}
																			onClick={() =>
																				arrayHelpers.remove(
																					i
																				)
																			}
																			endIcon={
																				<DeleteIcon />
																			}></Button>
																	</Stack>
																)}

																<TextField
																	fullWidth
																	name={`links.${i}`}
																	value={
																		formikSecond
																			.values
																			.links[
																			i
																		]
																	}
																	onChange={
																		formikSecond.handleChange
																	}
																	error={
																		getIn(
																			formikSecond.touched,
																			`links.${i}`
																		) &&
																		getIn(
																			formikSecond.errors,
																			`links.${i}`
																		)
																	}
																	helperText={
																		getIn(
																			formikSecond.touched,
																			`links.${i}`
																		) &&
																		getIn(
																			formikSecond.errors,
																			`links.${i}`
																		) &&
																		formikSecond
																			.errors
																			.links[
																			i
																		]
																	}
																/>
															</Stack>
														)
													)}
													<Box />
													<Button
														sx={{
															width: 130,
															boxShadow: "none",
														}}
														size={"small"}
														color={"success"}
														variant={"contained"}
														startIcon={<AddIcon />}
														onClick={() =>
															arrayHelpers.push(
																""
															)
														}>
														Add More
													</Button>
												</Stack>
											);
										}}
									/>
								</Stack>
							</Container>
							<Stack
								direction={"row"}
								justifyContent="flex-end"
								alignItems="center"
								spacing={3}
								width={"100%"}>
								<Button
									variant={"outlined"}
									color={"primary"}
									sx={{ px: 5 }}
									onClick={() => setSteps(steps - 1)}>
									Back
								</Button>
								<Button
									variant={"contained"}
									color={"primary"}
									sx={{ px: 5 }}
									type={"submit"}>
									Next
								</Button>
							</Stack>
						</Stack>
					</FormikProvider>
				)}

				{steps === 2 && (
					<Stack
						direction={"column"}
						spacing={10}
						component="form"
						onSubmit={(e) => formikThird.handleSubmit(e)}>
						<Container maxWidth={"md"}>
							<Stack direction={"column"} spacing={4}>
								<Stack direction={"column"} spacing={2}>
									<Typography sx={{ textAlign: "left" }}>
										Keen for startup assessment
									</Typography>
									<FormControl
										component="fieldset"
										variant="standard">
										<Stack
											direction="row"
											spacing={1}
											alignItems="center">
											<Typography>No</Typography>
											<FormControlLabel
												control={
													<Switch
														name={"assesment"}
														checked={
															formikThird.values
																.assesment
														}
														onChange={
															formikThird.handleChange
														}
														error={
															formikThird.touched
																.assesment &&
															Boolean(
																formikThird
																	.errors
																	.assesment
															)
														}
													/>
												}
											/>
											<Typography>Yes</Typography>
										</Stack>
									</FormControl>
								</Stack>
								<Stack direction={"column"} spacing={2}>
									<Typography sx={{ textAlign: "left" }}>
										Reachable to startups on chat
									</Typography>
									<FormControl
										component="fieldset"
										variant="standard">
										<Stack
											direction="row"
											spacing={1}
											alignItems="center">
											<Typography>No</Typography>
											<FormControlLabel
												control={
													<Switch
														name={"reachable"}
														checked={
															formikThird.values
																.reachable
														}
														onChange={
															formikThird.handleChange
														}
														error={
															formikThird.touched
																.reachable &&
															Boolean(
																formikThird
																	.errors
																	.reachable
															)
														}
													/>
												}
											/>
											<Typography>Yes</Typography>
										</Stack>
									</FormControl>
								</Stack>
								<Stack direction={"column"} spacing={2}>
									<Typography sx={{ textAlign: "left" }}>
										Do you want to help startups with
										Business Development to increase
										revenue?
									</Typography>
									<FormControl
										component="fieldset"
										variant="standard">
										<Stack
											direction="row"
											spacing={1}
											alignItems="center">
											<Typography>No</Typography>
											<FormControlLabel
												control={
													<Switch
														name={
															"doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue"
														}
														checked={
															formikThird.values
																.doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue
														}
														onChange={
															formikThird.handleChange
														}
														error={
															formikThird.touched
																.doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue &&
															Boolean(
																formikThird
																	.errors
																	.doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue
															)
														}
													/>
												}
											/>
											<Typography>Yes</Typography>
										</Stack>
									</FormControl>
								</Stack>
							</Stack>
						</Container>
						<Stack
							direction={"row"}
							justifyContent="flex-end"
							alignItems="center"
							spacing={3}
							width={"100%"}>
							<Button
								variant={"outlined"}
								color={"primary"}
								sx={{ px: 5 }}
								onClick={() => setSteps(steps - 1)}>
								Back
							</Button>
							<Button
								variant={"contained"}
								color={"primary"}
								sx={{ px: 5 }}
								type={"submit"}>
								Submit
							</Button>
						</Stack>
					</Stack>
				)}
			</Container>
		</>
	);
}
