import {
  getDocs,
  collection,
  query,
  doc,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { DB } from "../config/firebase/firebase";
import moment from "moment/moment";

const GetOffers = (filter, userType, screen) =>
  new Promise(async (resolve, reject) => {
    try {
      const offers = await getAllOffers(filter, userType, screen);
      resolve(offers);
    } catch (e) {
      reject(null);
    }
  });
const getAllOffers = async (filter, userType, screen) => {
  const offerCollection = collection(DB, "offers");
  let q = query((offerCollection),
       orderBy('createdAt', 'desc'));

  if (userType !== "admin") {
    console.log("received in condition", screen);
    q = query(offerCollection, where(filter.key, filter.operand, filter.value));
    if (screen?.screen == "timeline") {
      console.log("here in condition", screen);
      q = query(q, limit(3));
    }
  }

  const offersSnapshot = await getDocs(q);
  let offers = offersSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  if (offers && userType !== "admin") {
    offers = offers.filter((doc) => {
      //const parsedDate = moment(doc.to);
      const today = moment().format('YYYY-MM-DD');
      return doc.to >= today;
    });
  }
  return offers;
};
export default GetOffers;
