import {Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import React from "react";
import {AuthUserContext} from "../../../providers/auth-provider";
import ComonInvestorAboutComponent from "./edit/common/comon-investor-about-component";
import {EditSharp} from "@mui/icons-material";

export default function FounderInvestorAbout({profile, callback}) {
  const [open,setOpen] = React.useState(false)
  const {user} = React.useContext(AuthUserContext);
  const [seeMore, setSeeMore] = React.useState(false)
  const investorDetails = profile?.userType === "business-developer" ? profile['businessDeveloper']?.investorDetails || {}
    : profile?.userType === "service-provider" ? profile['serviceProvider']?.investorDetails || {}
    : profile[profile?.userType]?.investorDetails || {}
  const styleFont = {
    backgroundColor: '#F6F8FA', 
    padding: '8px 15px',
    borderRadius: '5px', 
    fontSize: 14,
    marginRight: "5px",
  }
  const regexMatch = new RegExp('/startup/profile/founder')
  const regexAdmin = /admin\/startup\/startup-founder-profile/
  
  
  return (

    <>

      <Stack
        direction={"column"}
        spacing={3}
        sx={{
          borderRadius: "15px",
          backgroundColor: "#fff",
          minHeight: 100,
        }}
      >
        <Stack direction={"row"} spacing={2} >
          <Typography fontSize={20} fontWeight={600} style={{flex: 1}}>About Investor</Typography>
          {
            regexMatch.test(window.location.pathname) &&  user?.uid === profile?.uid  &&
            <IconButton onClick={() => setOpen(true)}>
              <EditSharp />
            </IconButton>
          } {
            regexAdmin.test(window.location.pathname)   &&
            <IconButton onClick={() => setOpen(true)}>
              <EditSharp />
            </IconButton>
          }
        </Stack>

        <Stack direction={"column"} spacing={4}>
          
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Investor Type <br/> <font style={styleFont}>{investorDetails?.areYouAnAngelInvestor ? 'Angel': 'N/A'}</font>
              </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            </Stack>
         
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Investment Stage <br/>

                <Grid container gap={1}
                >
                  {investorDetails?.investmentStage &&
                    investorDetails?.investmentStage.length > 0 &&
                    investorDetails?.investmentStage.map(
                      (ex, i) => (<Grid key={i}>
                        <font style={styleFont}>
                          {ex}


                        </font>
                      </Grid>))

                  }
                </Grid>

              </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            </Stack>
          
          
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Sector <br/>

                <Grid container gap={1}
                >
                  {investorDetails?.sector &&
                    investorDetails?.sector.length > 0 &&
                    investorDetails?.sector.map(
                      (ex, i) => (<Grid key={i}>
                        <font style={styleFont}>
                          {ex}


                        </font>
                      </Grid>))

                  }
                </Grid>

              </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            </Stack>
          
            {investorDetails?.additionalDetails &&
                    investorDetails?.additionalDetails.length > 0 &&
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Additional Details <br/>
                <Grid container gap={1}
                >
                  {investorDetails?.additionalDetails &&
                    investorDetails?.additionalDetails.length > 0 &&
                    investorDetails?.additionalDetails.map(
                      (ex, i) => (<Grid key={i}>
                        <font style={styleFont}>
                          {ex}


                        </font>
                      </Grid>))

                  }

                </Grid>

              </Typography>
            </Stack>}

         
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Geographies <br/>

                <Grid container gap={1}
                >
                  {investorDetails?.geographies &&
                    investorDetails?.geographies.length > 0 &&
                    investorDetails?.geographies.map(
                      (ex, i) => (<Grid key={i}>
                        <font style={styleFont}>
                          {ex}


                        </font>
                      </Grid>))

                  }
                </Grid>

              </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            </Stack>
         

          { seeMore?
               <>
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Lead or Follow <br/> <font style={styleFont}>{investorDetails?.leadOrFollow ? investorDetails?.leadOrFollow: 'N/A'}</font>
              </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            </Stack>
         
         
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Ticket Size <br/> <font style={styleFont}>{`${investorDetails?.currency} ${investorDetails?.startingAt} - ${investorDetails?.upto}`}</font>
              </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            </Stack>
         
         
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Pre-product <br/> <font style={styleFont}>{investorDetails?.preProduct ? 'Yes' : 'No'}</font>
              </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            </Stack>
         
         
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Product Pre-launch <br/> <font style={styleFont}>{investorDetails?.productLive ? 'Yes' : 'No'}</font>
              </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            </Stack>
         
         
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Pre-revenue <br/> <font style={styleFont}>{investorDetails?.preRevenue ? 'Yes' : 'No'}</font>
              </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            </Stack>
         
         
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Pre-PMF <br/> <font style={styleFont}>{investorDetails?.PMF ? 'Yes' : 'No'}</font>
              </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
            </Stack>
         
         
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h6'} sx={{lineHeight:2}}>
                Sole Founder <br/> <font style={styleFont}>{investorDetails?.soleFounder ? 'Yes' : 'No'}</font>
              </Typography>
            </Stack>
            <Stack alignItems={'center'}>
                          <Box>
                          <Button size={"small"} variant='outlined' color="secondary"  sx={{borderRadius:"5px"}}onClick={()=> setSeeMore(!seeMore)}>See Less</Button>
                          </Box>
			                   </Stack> 
                </>
            :
            <Stack alignItems={'center'}>
                          <Box>
                          <Button size={"small"} variant='outlined' color="secondary"  sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See More</Button>
                          </Box>
			                   </Stack>     }
         
        </Stack>
      </Stack>

      <ComonInvestorAboutComponent profile={profile} open={open} handleClose={() => setOpen(false)} callback={(s) => callback(s)}/>

    </>
  )
}

// nameOfOrganization
