import * as React from 'react';

import {Button, CircularProgress, styled, useMediaQuery} from '@mui/material';
import {addDoc, collection, doc, getDocs, query, serverTimestamp, setDoc, where} from "firebase/firestore";
import {DB} from "../../config/firebase/firebase";
import {AuthUserContext} from "../../providers/auth-provider";
import {useNavigate} from "react-router-dom";
import Tab from "@mui/material/Tab";
import {a11yProps} from "./tabs-custom";


const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    borderRadius: "30px",
    color: "#fff",
    opacity: 1,
    backgroundColor: "#233DFF",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#233DFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function StartChat({data, props}) {
    const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user, setRoomId, profile} = React.useContext(AuthUserContext);
  const navigator =  useNavigate()
  const [isLoading, setLoading] = React.useState(false)
  const create = async () => {
    setLoading(true)
    const userType =data.creatorData.userType;
    if (data?.uid !== profile?.uid) {
      try {
        const q = query(
          collection(DB, "rooms"),
          where('participates', 'in', [[data?.uid, profile?.uid], [profile?.uid,data?.uid]])
        );
        const getRoom = await getDocs(q);
        let room = getRoom.size > 0 ? {id: getRoom.docs[0].id, ...getRoom.docs[0].data()} :
          await addDoc(collection(DB, "rooms"),
            {
              title: userType === 'startup' ? data.creatorData?.startup?.startupName : userType === 'service-provider'  ? data.creatorData?.serviceProvider?.displayName: data.creatorData?.displayName,
              subTitle: `${data?.displayName}, ${profile?.displayName}`,
              participates:[data?.uid, user.uid],
              uid: user?.uid,
              logo: userType === 'startup' ? data.creatorData?.startup?.logoURL : userType === 'service-provider'  ? data.creatorData?.serviceProvider?.logoURL || data.creatorData?.serviceProvider?.photoURL :  data.creatorData?.photoURL,
              updatedAt: serverTimestamp(),
              createdAt: serverTimestamp(),
              lastMsg: {
                text: 'Please go ahead and start conversation',
                status: 'send'
              }
            });
        // await setRoomId(room.id)
        if (getRoom.size === 0) {
          let message = await addDoc(collection(DB, "messages"),
            {
              type: 'system-generated',
              text: 'Please go ahead and start conversation',
              sender:  user.uid,
              receiver: data?.uid,
              createdAt:serverTimestamp(),
              roomId: room?.id,
              read:false,
              status: 'send'
            });
        } else {
          await setDoc(doc(DB, "rooms", getRoom.docs[0].id), {updatedAt: serverTimestamp()}, {merge:true})
        }
        setLoading(false)

        navigator(`${!matches ?'/m': ''}/${profile.userType}/chat`)
      } catch (e) {

        setLoading(false)

      }
    } else {

      setLoading(false)

    }




  }
  return (

    matches ?
    <StyledTab
      label="CHAT" sx={{marginLeft: 'auto' , marginRight: '5%',color:'white'}}
      onClick={create}
      {...a11yProps(4)}
    />: <StyledTab
            label="CHAT" sx={{color:'white',backgroundColor: "#233DFF" , minWidth: 70 , width: 70 ,
            minHeight: 30, height: 30,
            fontSize:'13px' ,
        }}
            onClick={create}
            // onClick={() => navigator(`/${profile?.userType}/chat`)}
            {...a11yProps(4)}
        />



   /*<Button  size={'small'} variant={"outlined"} color={'secondary'} sx={{px: 2, width:100}} onClick={create} endIcon= {isLoading && <CircularProgress size={20}/>}>
     Chat
   </Button>*/
  );
}
