import React from "react";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
  limit,
  startAfter,
  getDoc,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Skeleton,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { AuthUserContext } from "../../../providers/auth-provider";
import StartupListItem from "../components/common/startup-list-item";
import InvestorListItem from "../components/common/investor-list-item";
import ServiceProviderListItem from "../components/common/service-provider-list-item";
import ExpertListItem from "../components/common/expert-list-item";
import BusinessDeveloperListItem from "../components/common/business-developer-list-item";
import TalentListItem from "../components/common/talent-list-item";
import FounderListItem from "../components/common/founder-list-item";
import OrganisationListItem from "../components/common/organisation-list-item";
import ServiceProviderInvestorListItem from "../components/common/service-provider-investor-list-item";
import OrganisationInvestorListItem from "../components/common/organisation-investor-list-item";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import usePersistentState from "../../../api/persistant-state";
export const RequestMatchesList = () => {
  const [search, setSearch] = React.useState("");
  const [inputbtn, setinputbtn] = React.useState("");
  const [lastDoc, setLastDoc] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { profile } = React.useContext(AuthUserContext);
  const [members, setMembers] = usePersistentState("membersData", null);
  const [adminSuggested, setAdminSuggested] = usePersistentState(
    "adminSuggestedData",
    null
  );
  const location = useLocation();
  const systemSuggestedMembers = location?.state?.members;
  debugger;
  const adminSuggestedMembers = location?.state?.adminSuggested;
  React.useEffect(() => {
    if (systemSuggestedMembers && systemSuggestedMembers.length > 0) {
      setMembers(systemSuggestedMembers);
      console.log("set members", members);
    }
    if (adminSuggestedMembers && adminSuggestedMembers.length > 0)
      setAdminSuggested(adminSuggestedMembers);
  }, [location?.state]);
  
  const navigator = useNavigate();
  let filteredSearch;
  const MemberSearch = ({ array }) => {
    const { profile } = React.useContext(AuthUserContext);
    //   const [search, setSearch] =React.useState('')
    filteredSearch = array
      .filter((member) => {
        if (search !== "") {
          const reg = new RegExp(search.toLowerCase());
          return reg.test(member?.displayName?.toLowerCase()) && member;
        } else {
          return member;
        }
      })
      .sort((a, b) => b?.createdAt - a?.createdAt);
    if (filteredSearch?.length === 0) {
      return (
        <Stack
          direction={"row"}
          sx={{ width: "100%" }}
          py={5}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4" mt={10}>
            No results
          </Typography>
        </Stack>
      );
    } else {
      return filteredSearch.map((record) =>
        record.userType === "startup" ? (
          <>
            <StartupListItem
              data={record}
              key={record?.uid}
              href={`/m/${profile?.userType}/startup-details/${record?.uid}`}
            />
            {record?.startup?.founder && (
              <FounderListItem
                data={record}
                key={record?.uid + record?.createdAt}
                href={`/m/${profile?.userType}/startup-founder-profile`}
              />
            )}
          </>
        ) : record.userType === "investor" ? (
          <>
            {record?.investor?.investorType !== "Angel Investor" && (
              <OrganisationInvestorListItem
                data={record}
                key={record?.uid + record?.createdAt}
                href={`/m/${profile?.userType}/common-organisation-profile`}
              />
            )}

            <InvestorListItem
              data={record}
              key={record?.uid}
              href={`/m/${profile?.userType}/investor-details/${record?.uid}`}
            />
          </>
        ) : record.userType === "expert" ? (
          <ExpertListItem
            data={record}
            key={record?.uid}
            href={`/m/${profile?.userType}/expert-details/${record?.uid}`}
          />
        ) : record.userType === "talent" ? (
          <TalentListItem
            data={record}
            key={record?.uid}
            href={`/m/${profile?.userType}/talent-details/${record?.uid}`}
          />
        ) : record.userType === "service-provider" ? (
          <>
            <ServiceProviderListItem
              data={record}
              key={record?.uid}
              href={`/m/${profile?.userType}/service-provider-details/${record?.uid}`}
            />
            {record?.serviceProvider?.organisationType === "Organisation" &&
              record?.serviceProvider?.organisationDetails && (
                <OrganisationListItem
                  data={record}
                  key={record?.uid + "organisation"}
                  href={`/m/${profile?.userType}/organisation-profile`}
                />
              )}
          </>
        ) : record.userType === "business-developer" ? (
          <BusinessDeveloperListItem
            data={record}
            key={record?.uid}
            href={`/m/${profile?.userType}/business-developer-details/${record?.uid}`}
          />
        ) : (
          false
        )
      );
    }
  };

  return (
    <>
      {adminSuggested && adminSuggested?.length > 0 &&  (
        <Container maxWidth={"xl"} sx={{ my: 5, textAlign: "left" }}>
          <Stack direction={"row"} spacing={5} sx={{ width: "100%", mb: 5 }}>
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontSize: "15px", marginBottom: "-20px" }}
                sx={{ fontWeight: 600 }}
              >
                <IconButton onClick={() => navigator(-1)}>
                  <ArrowBackIcon sx={{ fontSize: 20 }} />
                </IconButton>{" "}
                Admin Suggested
              </Typography>

              {/* <Typography variant={"body2"} color={'secondary'}>The expert shows below are our best matches based on your request </Typography> */}
            </div>
            <></>
            {/* </Tooltip> */}
          </Stack>
        </Container>
      )}
      <Container maxWidth={"lg"} sx={{ my: 2, textAlign: "left" }}>
        <Grid container spacing={1.5}>
          {adminSuggested?.length > 0 && (
            <>
              <MemberSearch array={adminSuggested} />
            </>
          )}
        </Grid>
        {loading && (
          <Grid container spacing={5} my={5}>
            <Grid item lg={4} md={4} sm={6} xs={6} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
          </Grid>
        )}

        {lastDoc && (
          <Stack
            direction={"row"}
            sx={{ width: "100%" }}
            py={5}
            justifyContent="center"
            alignItems="center"
          >
            <Button size={"small"}>Load more</Button>
          </Stack>
        )}
      </Container>
{members?.length > 0 && (
      <Container sx={{ my: 5, textAlign: "left" }}>
        <Stack
          direction={"row"}
          spacing={5}
          sx={{ width: "100%", mb: 5, mt: -4 }}
        >
          <div style={{ flex: 1 }}>
            <Typography style={{ fontSize: "15px" }} sx={{ fontWeight: 600 }}>
              <IconButton onClick={() => navigator(-1)}>
                <ArrowBackIcon sx={{ fontSize: 20 }} />
              </IconButton>{" "}
              A few recommendations just for you!
            </Typography>
            <Typography
              style={{ fontSize: "10px", marginLeft: "10%" }}
              // sx={{ fontWeight: 600 }}
            >
              Below are our best matches based on your request
            </Typography>
            {/* <Typography variant={"h4"} sx={{ fontWeight: 600 }}>
              Best Matches
            </Typography> */}
            {/* <Typography variant={"body2"} color={'secondary'}>The expert shows below are our best matches based on your request </Typography> */}
          </div>
          <></>
          {/* </Tooltip> */}
        </Stack>
      </Container>)}
      <Container maxWidth={"lg"} sx={{ my: 5, textAlign: "left" }}>
        <Grid container spacing={1.5}>
          {members?.length > 0 && (
            <>
              <MemberSearch array={members} />
            </>
          )}
        </Grid>
        {loading && (
          <Grid container spacing={1.5} my={5}>
            <Grid item lg={4} md={4} sm={6} xs={6} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
          </Grid>
        )}

        {lastDoc && (
          <Stack
            direction={"row"}
            sx={{ width: "100%" }}
            py={5}
            justifyContent="center"
            alignItems="center"
          >
            <Button size={"small"}>Load more</Button>
          </Stack>
        )}
      </Container>
    </>
  );
};
