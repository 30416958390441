export const BUSINESS_DEVELOPER  = {
    NAME : 'Growth Experts',
    SINGULAR_LABEL : 'Growth Expert',
    BUSINESS_DEVELOPER_PROFILE : 'Growth Expert Profile',
    EXPERTISE: 'Expertise',
    EXPERIENCE : 'Experience',
    ADD_EXPERIENCE : 'Add Experience',
    EXPECTATIONS: 'Expectations',
    INVESTOR_DETAILS: 'Your details an Investor',
    INVESTMENT_PORTFOLIO: 'Investment portfolio',
    ADD_PORTFOLIO: 'Add portfolio'
  }

export const EXPERT = {
  NAME: 'Industry Experts',
  SINGULAR_LABEL : 'Industry Expert',
  EXPERT_PROFILE : 'Industry Expert Profile',
  EXPERTISE: 'Expertise',
  EXPERIENCE : 'Experience',
  ADD_EXPERIENCE : 'Add Experience',
  EXPECTATIONS: 'Expectations',
  INVESTOR_DETAILS: 'Your details an Investor',
  INVESTMENT_PORTFOLIO: 'Investment portfolio',
  ADD_PORTFOLIO: 'Add portfolio'
}
export const SERVICE_PROVIDER = {
  NAME: 'Service Providers',
  SINGULAR_LABEL : 'Service Provider',
  SERVICE_PROVIDER_PROFILE : 'Service Provider Profile',
  EXPERTISE: 'Expertise',
  EXPERIENCE : 'Experience',
  ADD_EXPERIENCE : 'Add Experience',
  EXPECTATIONS: 'Expectations',
  INVESTOR_DETAILS: 'Your details an Investor',
  INVESTMENT_PORTFOLIO: 'Investment portfolio',
  ADD_PORTFOLIO: 'Add portfolio'
}
export const USER_TYPES_TO_NAME = {
  "business-developer": "growth expert",
  "service-provider": "service provider",
  "investor": "investor",
  "expert": "industry expert",
  "startup": "startup",
  "talent": "talent",
  "admin": "admin"
}
export const USER_TYPES_TO_NAMEC = {
  "business-developer": "Growth Expert",
  "service-provider": "Service Provider",
  "investor": "Investor",
  "expert": "Industry Expert",
  "startup": "Startup",
  "talent": "Talent",
  "admin": "Admin"
}
export const USERS_NAME_MAPPING = {
  "Business Developer": BUSINESS_DEVELOPER.SINGULAR_LABEL,
  "Service provider": "Service provider",
  "Investor": "Investor",
  "Expert": EXPERT.SINGULAR_LABEL,
  "Startup": "Startup",
  "Talent": "Talent"
}
export const ACCOUNT_CREATION_NOTE = 'Almost there...please set up your account'

export const DEFAULT_MARKET = "Global"
export const DEFAULT_LANGUAGE = "English"