import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Typography,
  Chip, IconButton,
  Select, MenuItem
} from "@mui/material";
import moment from "moment";
import { AuthUserContext } from "../../providers/auth-provider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { USERS_NAME_MAPPING } from "../../utils/enums/labels.enum";
import CommonAvatar from "../../components/common-avatar";
import {ArrowDropDown, CheckCircle, DownloadOutlined} from "@mui/icons-material";
import DownloadFileHelper from "../../utils/download-file-helper";
import {doc, updateDoc} from "firebase/firestore";
import {DB} from "../../config/firebase/firebase";
import PostNotification from "../../api/post-notification";
export default function ContractDetails() {
  const { profile } = React.useContext(AuthUserContext);
  const location = useLocation();
  const navigator = useNavigate();
  const { request, contract } = location?.state;
  function currencyToSymbol(currency) {
    if (currency == "USD") {
      return "$";
    } else if (currency == "GBP") {
      return "£";
    } else {
      return "€";
    }
  }
  const updateContract = async (status) => {
    const refContract = doc(DB, 'contracts', contract?.id)
    try {
      await updateDoc(refContract, {status})
      if (status !== 'pending'){
        PostNotification({
          type: `contract-${status === 'approved' ? 'approved' : 'rejected'}`,
          imageURL: '/assets/system-noti-icon.png',
          message: `${status === 'rejected' ? 'Sorry! The admin has declined your contract.' : 'Great news! The admin has approved your contract.'}`,
          uid: contract.uid ,
          contractId: contract.id ,
          createdBy: profile?.uid
        }).then()
      }
   
    } catch (e) {
      alert(JSON.stringify(e));
    }
  }
  return (
    <Container maxWidth={"lg"} sx={{ py: 5 }}>
      {request && (
        <Stack direction={"row"}>
          <Box
            sx={{
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              flex: 1,
              top: 5,
              background: "#fff",
              borderRadius: "15px",
            }}
          >
            <Stack direction={"column"} sx={{ flex: 0.7 }}>
              <Stack
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  minHeight: 80,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
                direction={"row"}
                spacing={4}
              >
                <IconButton onClick={() => navigator(-1)}>
                  <ArrowBackIcon
    
                    sx={{ fontSize: 40 }}
                  />
                </IconButton>
               
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Typography variant={"h6"}>{request.requestFor}</Typography>
                  <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                    Posted{" "}
                    {moment()
                      .from(request?.createdAt.seconds * 1000)
                      .slice(2)}{" "}
                    ago
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 140,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Typography
                  variant={"h6"}
                  color={"primary"}
                  sx={{ fontWeight: "500" }}
                >
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Round"
                    : "Searching For"}
                </Typography>
                <Box container>
                  {!(
                    request?.requestFor === "Looking for funding" ||
                    request.type === "looking-for-funding"
                  ) ? (
                    request?.searchFor &&
                    request?.searchFor.map((t) => (
                      <Chip
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginRight: "8px",
                          marginBottom: "10px",
                          maxWidth: 250,
                          borderRadius: "10px",
                          fontWeight: "500",
                        }}
                        component={"p"}
                        label={USERS_NAME_MAPPING[t]}
                      />
                    ))
                  ) : (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginRight: "5px",
                        marginBottom: "10px",
                        maxWidth: 250,
                      }}
                      component={"p"}
                      label={request?.round}
                    />
                  )}
                </Box>
              </Stack>
              {/* Stack for location, job type, due date */}
              <Stack
                sx={{
                  minHeight: 70,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {request?.location}
                </Typography>
                <span />
                
                <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                  Job Type
                </Typography>
                
                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {request?.jobType}
                </Typography>
                
                <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                  Due Date:
                </Typography>
                
                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {moment(request?.deadline).format("D MMM YYYY")}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  minHeight: 150,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography
                  variant={"body2"}
                  textAlign={"justify"}
                  sx={{ color: "#363636" }}
                >
                  {request.description}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  minHeight: 70,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <img src={"/assets/budget-req.png"} />
                <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Amount to raise"
                    : "Budget"}{" "}
                </Typography>
                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? `${request?.currency} ${request?.amount}`
                    : `${currencyToSymbol(request?.currency)} ${
                      request?.minBudget
                    } - ${currencyToSymbol(request?.currency)} ${
                      request?.maxBudget
                    }`}
                </Typography>
                <span />
                {request?.requestFor === "Looking for funding" ||
                  (request.type === "looking-for-funding" && (
                    <>
                      {" "}
                      <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                        Valuation
                      </Typography>
                      <Typography variant={"body2"} sx={{ color: "#363636" }}>
                        {request?.valuationCurrency} ${request?.valuation}
                      </Typography>
                      <span />
                    </>
                  ))}
                
                <img src={"/assets/timeline-cal.png"} />
                <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Close Date"
                    : "Timeline"}
                </Typography>
                
                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? moment(request.fundingCloseDate).format("MMM D, YYYY")
                    : `${moment(request.from).format("MMM D, YYYY")} - ${moment(
                      request.to
                    ).format("MMM D, YYYY")}`}
                </Typography>
              </Stack>
              <Stack
                sx={{ minHeight: 150, width: "100%", p: 1, px: 3 }}
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                <Typography variant={"h6"} sx={{ fontWeight: "500" }}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Revenue Model"
                    : "Skills and expertise"}{" "}
                </Typography>
                <Box container>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding" ? (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginRight: "5px",
                        marginBottom: "10px",
                        maxWidth: 250,
                      }}
                      component={"p"}
                      label={request?.revenueModel}
                    />
                  ) : (
                    request?.skills &&
                    request?.skills.map((t) => (
                      <Chip
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginRight: "8px",
                          marginBottom: "10px",
                          maxWidth: 250,
                          borderRadius: "10px",
                          fontWeight: "500",
                        }}
                        component={"p"}
                        label={t}
                      />
                    ))
                  )}
                </Box>
              </Stack>
              
              <Stack
                sx={{ minHeight: 150, width: "100%", p: 1, px: 3 }}
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                <Typography variant={"h6"} sx={{ fontWeight: "500" }}>
                  Languages
                </Typography>
                <Box container>
                  {request?.languages &&
                    request?.languages?.map((t) => (
                      <Chip
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginRight: "8px",
                          marginBottom: "10px",
                          maxWidth: 250,
                          borderRadius: "10px",
                          fontWeight: "500",
                        }}
                        component={"p"}
                        label={t}
                      />
                    ))}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      )}
      <br/>
      <br/>
      {
        contract && (
          <Stack direction={"row"} spacing={2}>
            <Box sx={{
              height:173,
              width: 301,
              borderRadius: '12px',
              backgroundColor: '#F6F8FA'
              
            }}>
              <Stack direction={"column"} spacing={1} flex={1} sx={{textAlign: 'left', p:1.5}}>
                <Typography
                  variant={"body1"}
                  noWrap={true}
                  sx={{ width: '100%', lineHeight: 1.15}}>
                  Party A
                </Typography>
                <CommonAvatar uid={contract?.partyA?.uid} callback={(row) => navigator(
                  "/admin/user-details", {state: {profile: row}}
                )}/>
                <Box flex={1}/>
  
                <font size={2} color={'#000'} >Contract {
                  contract?.partyA?.signed &&
                  <CheckCircle color={'success'} sx={{fontSize: 16}}/>}
                </font>
                {
                  contract?.partyA?.signed && <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography variant={"caption"} noWrap={true} maxWidth={200}>
      
                      { contract?.partyA?.contractPath?.split('/')
                        [contract?.partyA?.contractPath?.split('/')?.length - 1]}
                    </Typography>
                    <IconButton color={"primary"} size={"small"}
                                onClick={() => {
                                  console.log(contract?.partyA?.contractPath);
                                  contract?.partyA?.contractPath &&
                                  contract?.partyA?.contractPath !== "" &&
                                  DownloadFileHelper(contract?.partyA?.contractPath);
                                }}>
                      <DownloadOutlined  />
                    </IconButton>
                  </Stack>
                }
                
               
              </Stack>
              
            </Box>
            <Box sx={{
              height:173,
              width: 301,
              borderRadius: '12px',
              backgroundColor: '#F6F8FA'
              
            }}>
  
              <Stack direction={"column"} spacing={1} flex={1} sx={{textAlign: 'left', p:1.5}}>
                <Typography
                  variant={"body1"}
                  noWrap={true}
                  sx={{ width: '100%', lineHeight: 1.15}}>
                  Party B
                </Typography>
                <CommonAvatar uid={contract?.partyB?.uid} callback={(row) => navigator(
                  "/admin/user-details", {state: {profile: row}}
                )}/>
                <Box flex={1}/>
                <font size={2} color={'#000'} >Contract {
                  contract?.partyB?.signed &&
                  <CheckCircle color={'success'} sx={{fontSize: 16}}/>}
                </font>
                {
                  contract?.partyB?.signed && <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography variant={"caption"} noWrap={true} maxWidth={200}>
        
                      { contract?.partyB?.contractPath?.split('/')
                        [contract?.partyB?.contractPath?.split('/')?.length - 1]}
                    </Typography>
                    <IconButton color={"primary"} size={"small"}
                                onClick={() => {
                                  console.log(contract?.partyB?.contractPath);
                                  contract?.partyB?.contractPath &&
                                  contract?.partyB?.contractPath !== "" &&
                                  DownloadFileHelper(contract?.partyB?.contractPath);
                                }}>
                      <DownloadOutlined  />
                    </IconButton>
                  </Stack>
                }

              </Stack>
              
            </Box>
            <Select value={contract?.status ? contract?.status : 'pending'}
                    style={{borderRadius:"12px",backgroundColor:"#F6F8FA",paddingLeft:"10px",paddingRight:"10px",width:"172px",height:"50px", borderColor:'#F6F8FA !important'}}
                    IconComponent={() => <ArrowDropDown  />}
                    multiple={false} onChange={(e) => {
                      updateContract(e.target.value).then(
                        () => navigator('/admin/contract-details', { state: { request, contract:{...contract, status: e.target.value} }})
                      )
                    
              
            }}>
    
              <MenuItem value={'pending'}>
                Pending
              </MenuItem>
              <MenuItem value={'approved'}>
                Approved
              </MenuItem>
              <MenuItem value={'rejected'}>
                Rejected
              </MenuItem>
            </Select>
            
          </Stack>
        )
      }
    </Container>
  );
}
