import React, { useEffect } from "react";
import {
	Button,
	Container,
	MenuItem,
	Stack,
	TextField,
	Chip,
	Tooltip,
	Autocomplete,Typography, createFilterOptions
} from "@mui/material";
import { useFormik } from "formik";
import InvestorProfileValidation from "../../../../validations/investor/investor-profile";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';
import dataJson from "../../../../const/data.json";
import { Add } from "@mui/icons-material";
const filter = createFilterOptions()

export default function InvestorThirdForm({ cbBack, cbNext, data }) {
	const formikFirst = useFormik({
		initialValues: data
			? data
			: InvestorProfileValidation.InvestorThirdFormValues,
		validationSchema: InvestorProfileValidation.InvestorThirdForm,
		onSubmit: async (values) => {
			cbNext({
				...values,
			});
		},
	});
	function commaAmount(amount) {
		let result = "";
		let counter = 0;
		for (let i = amount.length - 1; i >= 0; i -= 1) {
			if (counter != 0 && counter % 3 === 0) {
				result = `,${result}`;
			}
			counter += 1;
			result = `${amount[i]}${result}`;
		}
		return result;
	}
	return (
		<Stack
			direction={"column"}
			spacing={10}
			component="form"
			onSubmit={(e) => formikFirst.handleSubmit(e)}>
			<Container maxWidth={"md"}>
				<Stack direction={"column"} spacing={2}>
					<Autocomplete
						multiple
						required
						options={[
							"Pre-Seed",
							"Seed",
							"Seed+",
							"Series A",
							"Series A+",
							"Series B",
							"Series B+",
							"Series C and above",
						].map((option) => option)}
						value={formikFirst.values?.investmentStage}
						onChange={(e, newValue) =>
							formikFirst.setFieldValue(
								"investmentStage",
								newValue
							)
						}
						renderTags={(value, getTagProps) =>
							formikFirst.values?.investmentStage &&
							formikFirst.values?.investmentStage.length > 0 &&
							formikFirst.values?.investmentStage.map(
								(option, index) => (
									<Chip
										variant="contained"
										sx={{
											backgroundColor: "#E0E3FF",
											marginLeft: "5px",
											marginRight: "5px",
											marginBottom: "10px",
											borderRadius: "10px"
										}}
										deleteIcon={<CloseIcon />}
										onDelete={() =>
											formikFirst.setFieldValue(
												"investmentStage",
												formikFirst.values?.investmentStage.filter(
													(f) => f !== option
												)
											)
										}
										label={option}
										{...getTagProps({
											index,
										})}></Chip>
								)
							)
						}
						renderInput={(params) => (
							<>	
					<Typography
					fontWeight={400}
					fontSize={"16px"}
					textAlign="start"
					sx={{marginBottom:"4px"}}
				  >
				  Investment stage*
				  </Typography>
							<TextField
								{...params}
								// variant={'standard'}
								// label="Investment stage*"
								name={"investmentStage"}
								error={Boolean(
									formikFirst.errors.investmentStage
								)}
								helperText={
									formikFirst.errors.investmentStage
										? "Investment stage is a required field"
										: ""
								} 
								// placeholder="Add"
								sx={{
									transition: "0.1s linear",
								}}
							/>
								</>
						)}
					/>

					<Stack direction={"column"} spacing={2}>
						<Autocomplete
							multiple
							required
							fullWidth
							options={[
								"Adtech",
								"Advanced manufacturing",
								"Agtech",
								"Artificial intelligence and machine learning (AI/ML)",
								"Audiotech",
								"Augmented reality (AR)",
								"Autonomous cars",
								"B2B payments",
								"Beauty",
								"Big Data",
								"Cannabis",
								"Carsharing",
								"Cleantech",
								"Climate tech",
								"Cloudtech and DevOps",
								"Construction technology",
								"Cryptocurrency and blockchain",
								"Cybersecurity",
								"Digital health",
								"Ecommerce",
								"Edtech",
								"Ephemeral content",
								"eSports",
								"Femtech",
								"Fintech",
								"Foodtech",
								"Gaming",
								"Healthtech",
								"HRtech",
								"Impact investing",
								"Industrials",
								"Infrastructure",
								"Insurtech",
								"Internet of Things (IoT)",
								"Legal tech",
								"Life sciences",
								"Lifestyles of Health and Sustainability (LOHAS) and wellness",
								"Manufacturing",
								"Marketing tech",
								"Micro-mobility",
								"Mobile",
								"Mobile commerce",
								"Mobility tech",
								"Mortgage tech",
								"Nanotechnology",
								"Oil and gas",
								"Oncology",
								"Pet tech",
								"Real estate tech",
								"Restaurant tech",
								"Ridesharing",
								"Robotics and drones",
								"Software as a service (SaaS)",
								"Space tech",
								"Supply chain technology",
								"Technology, media and telecommunications (TMT)",
								"Virtual reality (VR)",
								"Wearables and quantified self",
								"3D printing",
							].map((option) => option)}
							value={formikFirst.values?.sector}
							freeSolo
							filterOptions={(options, params) => {
								const filtered = filter(options, params);
						
								const { inputValue } = params;
								// Suggest the creation of a new value
								const isExisting = options.some((option) => inputValue === option);
								if (inputValue !== '' && !isExisting) {
									filtered.push('Add ' + inputValue);
								}
						
								return filtered;
							  }}
							getOptionLabel={(option) => {
								// Value selected with enter, right from the input
								if (typeof option === 'string') {
								  return option
								}
								// Add "xxx" option created dynamically
								if (option.value) {
									return option.value;
								  }
								  // Regular option
								return option
							  }}
							renderOption={(props, option) => 
							<li {...props} style={{alignItems:"center"}}>
								{option.includes("Add ")  
								? 
								<><Add fontSize="medium"/>{option}</> 
								: option}
								</li>}
							onChange={(
								e,
								newValue
							) =>{
								if(newValue.length!==0)
									{const newOption = newValue.slice(-1)[0].replace("Add ", "")
									newValue.splice(-1,1,newOption)}
								  
								formikFirst.setFieldValue('sector', newValue)
							}
							}
							renderTags={(value, getTagProps) =>
								formikFirst.values?.sector &&
								formikFirst.values?.sector.length > 0 &&
								formikFirst.values?.sector.map(
									(option, index) => (
										<Chip
											variant="contained"
											sx={{
												backgroundColor: "#E0E3FF",
												marginLeft: "5px",
												marginRight: "5px",
												marginBottom: "10px",
												borderRadius: "10px"
											}}
											deleteIcon={<CloseIcon />}
											onDelete={() =>
												formikFirst.setFieldValue(
													"sector",
													formikFirst.values?.sector.filter(
														(f) => f !== option.value ? option.value : option
													)
												)
											}
											label={option.value ? option.value : option}
											{...getTagProps({
												index,
											})}></Chip>
									)
								)
							}
							renderInput={(params) => (
								<>	
								<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >
							 Sector *
							  </Typography>
								<TextField
									{...params}
									// variant={'standard'}
									// label="Sector*"
									error={Boolean(formikFirst.errors.sector)}
									helperText={
										formikFirst.errors.sector
											? "Sector is a required field"
											: ""
									}
									// placeholder="Add"
									sx={{
										transition: "0.1s linear",
									}}
								/></>
							)}
						/>
						{/* <Autocomplete
							multiple
							required
							fullWidth
							freeSolo
							options={[
								// " "
						].map((option) => option)}
							onChange={(e, newValue) =>
								formikFirst.setFieldValue(
									"additionalDetails",
									newValue
								)
							}
							// name={"additionalDetails"}
							value={formikFirst.values?.additionalDetails}
							renderTags={(value, getTagProps) =>
								formikFirst.values?.additionalDetails &&
								formikFirst.values?.additionalDetails.length >
									0 &&
								formikFirst.values?.additionalDetails.map(
									(option, index) => (
										<Chip
											variant="contained"
											sx={{
												backgroundColor: "#F6F8FA",
												marginLeft: "5px",
												marginRight: "5px",
												marginBottom: "10px",
												borderRadius: "10px"
											}}
											deleteIcon={<CloseIcon />}
											onDelete={() =>
												formikFirst.setFieldValue(
													"additionalDetails",
													formikFirst.values?.additionalDetails.filter(
														(f) => f !== option
													)
												)
											}
											label={option}
											{...getTagProps({
												index,
											})}></Chip>
									)
								)
							}
							renderInput={(params) => (
								<>	
								<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >
							Additional Details
							  </Typography>
								<TextField
									{...params}
									// variant={'standard'}

									// label="Additional Details"
									name={"additionalDetails"}
									// placeholder="Add"
									sx={{
										transition: "0.1s linear",
									}}
								/></>
							)}
						/> */}
						{/* <TextField
							name={"additionalDetails"}
							value={formikFirst.values.additionalDetails}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.additionalDetails &&
								Boolean(formikFirst.errors.additionalDetails)
							}
							fullWidth
							helperText={
								formikFirst.touched.additionalDetails &&
								formikFirst.errors.additionalDetails
							}
							label={"Additional details"}
						/> */}
					</Stack>

					<Autocomplete
						multiple
						required
						fullWidth
						options={[...dataJson.markets].map((option) => option)}
						freeSolo
						onChange={(e, newValue) =>
							formikFirst.setFieldValue("geographies", newValue)
						}
						value={formikFirst.values?.geographies}
						renderTags={(value, getTagProps) =>
							formikFirst.values?.geographies &&
							formikFirst.values?.geographies.length > 0 &&
							formikFirst.values?.geographies.map(
								(option, index) => (
									<Chip
										variant="contained"
										sx={{
											backgroundColor: "#E0E3FF",
											marginLeft: "5px",
											marginRight: "5px",
											marginBottom: "10px",
											borderRadius: "10px"
										}}
										deleteIcon={<CloseIcon />}
										onDelete={() =>
											formikFirst.setFieldValue(
												"geographies",
												formikFirst.values?.geographies.filter(
													(f) => f !== option
												)
											)
										}
										label={option}
										{...getTagProps({
											index,
										})}></Chip>
								)
							)
						}
						renderInput={(params) => (
							<>	
								<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >
						<>
										Geographies *
										<Tooltip title="Countries and regions" arrow placement="top-start">

										<InfoIcon   fontSize="small" style={{ marginLeft:"5px",color:"#D9D9D9"}}/></Tooltip>
									</>
							  </Typography>
							<TextField
								{...params}
								// variant={'standard'}
								error={Boolean(formikFirst.errors.geographies)}
								helperText={
									formikFirst.errors.geographies
										? "Geography is a required field"
										: ""
								}
								// label={
								//   	<>
								// 		Geographies*
								// 		<Tooltip title="Countries and regions" arrow placement="top-start">

								// 		<InfoIcon color="#D9D9D9" fontSize="small" style={{ marginLeft:"6px"}}/></Tooltip>
								// 	</>
								// }
								// placeholder="Add"
								sx={{
									transition: "0.1s linear",
								}}
							/>	</>
						)}
					/>
<Stack direction={'row'} spacing={5}>
					<Autocomplete
						multiple
						// required
						fullWidth
						options={[
							...dataJson.languages
						].map(
							(option) => option
						)}
						onChange={(
							e,
							newValue
						) =>
							formikFirst.setFieldValue('languages', newValue)
						}
						value={formikFirst.values?.languages}
						renderTags={(
							value,
							getTagProps
						) =>
							formikFirst.values?.languages &&
							formikFirst.values?.languages.length > 0
							&&	formikFirst.values?.languages.map(
								(
									option,
									index
								) => (
									<Chip
										variant="contained"
										sx={{
											backgroundColor:
												"#E3E8F0",
											marginLeft:
												"5px",
											marginRight:
												"5px",
											marginBottom:
												"10px",
										}}
										deleteIcon={
											<CloseIcon />
										}
										onDelete={() =>
											formikFirst.setFieldValue('langauages',
												formikFirst.values?.languages.filter(
													(
														f
													) =>
														f !==
														option
												)
											)
										}
										label={
											option
										}
										{...getTagProps(
											{
												index,
											}
										)}></Chip>
								)
							)
						}
						renderInput={(
							params
						) => (
							<>
							<Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  textAlign="start"

                >
                 Fluent Languages *
                </Typography>
							<TextField
								{...params}
								error={Boolean(formikFirst.errors.languages)}
								helperText={
									formikFirst.errors.languages
										? "Language is required"
										: ""
								}
								sx={{
									transition:
										"0.1s linear",
								}}
							/>
							</>
						)}
					/>
					</Stack>
					{/* <Autocomplete
						multiple
						required
						fullWidth
						options={['English', 'Hindi', 'Japanese'].map((option) => option)}
						freeSolo
						onChange={(e, newValue) =>
							formikFirst.setFieldValue("languages", newValue)
						}
						value={formikFirst.values?.languages}
						renderTags={(value, getTagProps) =>
							formikFirst.values?.languages &&
							formikFirst.values?.languages.length > 0 &&
							formikFirst.values?.languages.map(
								(option, index) => (
									<Chip
										variant="contained"
										sx={{
											backgroundColor: "#E0E3FF",
											marginLeft: "5px",
											marginRight: "5px",
											marginBottom: "10px",
											borderRadius: "10px"
										}}
										deleteIcon={<CloseIcon />}
										onDelete={() =>
											formikFirst.setFieldValue(
												"languages",
												formikFirst.values?.languages.filter(
													(f) => f !== option
												)
											)
										}
										label={option}
										{...getTagProps({
											index,
										})}></Chip>
								)
							)
						}
						renderInput={(params) => (
							<>
								<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >
									Languages *
								
								</Typography>
							<TextField
								{...params}
								// variant={'standard'}
								error={Boolean(formikFirst.errors.languages)}
								helperText={
									formikFirst.errors.languages
										? "languages is a required field"
										: ""
								}
								// label={
								//   	<>
								// 		Geographies*
								// 		<Tooltip title="Countries and regions" arrow placement="top-start">

								// 		<InfoIcon color="#D9D9D9" fontSize="small" style={{ marginLeft:"6px"}}/></Tooltip>
								// 	</>
								// }
								// placeholder="Add"
								sx={{
									transition: "0.1s linear",
								}}
							/>	</>
						)}
					/> */}
					

					<Stack direction={"column"} >
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Lead or follow*

							  </Typography>
					<TextField
						name={"leadOrFollow"}
						value={formikFirst.values.leadOrFollow}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.leadOrFollow &&
							Boolean(formikFirst.errors.leadOrFollow)
						}
						fullWidth
						helperText={
							formikFirst.touched.leadOrFollow &&
							formikFirst.errors.leadOrFollow
						}
						// label={"Lead or follow*"}
						select
						sx={{ textAlign: "left" }}>
						<MenuItem value={"Lead"}>Lead</MenuItem>
						<MenuItem value={"Follow"}>Follow</MenuItem>
						<MenuItem value={"Both"}>Both</MenuItem>
					</TextField></Stack>
					<Stack direction={"column"} spacing={2}>
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"-2px"}}
							  >Ticket Size*

							  </Typography>
					{/* <Stack direction={"row"} spacing={2}> */}
						<TextField
							name={"currency"}
							value={formikFirst.values.currency}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.currency &&
								Boolean(formikFirst.errors.currency)
							}
							fullWidth
							helperText={
								formikFirst.touched.currency &&
								formikFirst.errors.currency
							}
							label={"Currency*"}
							select
							sx={{ textAlign: "left" }}>
							<MenuItem value={"USD"}>USD</MenuItem>
							<MenuItem value={"GBP"}>GBP</MenuItem>
							<MenuItem value={"EUR"}>EUR</MenuItem>
						</TextField>
						
						<TextField
							name={"startingAt"}
							value={commaAmount(
								formikFirst?.values?.startingAt.toString()
							)}
							error={
								formikFirst.touched.startingAt &&
								Boolean(formikFirst.errors.startingAt)
							}
							fullWidth
							helperText={
								formikFirst.touched.startingAt &&
								formikFirst.errors.startingAt
							}
							label={"Starting at"}
							onChange={(e) => {
								let tar = e.target.value;
								let result = "";
								for (let i = tar.length - 1; i >= 0; i -= 1) {
									if (tar[i] != ",") {
										result = `${tar[i]}${result}`;
									}
								}
								if (tar.length == 0) {
									e.target.value = "";
								} else {
									e.target.value = parseInt(result);
								}

								formikFirst.handleChange(e);
							}}
							sx={{ textAlign: "left" }}
						/>

						<TextField
							name={"upto"}
							value={commaAmount(
								formikFirst?.values?.upto?.toString()
							)}
							onChange={(e) => {
								let tar = e.target.value;
								let result = "";
								for (let i = tar.length - 1; i >= 0; i -= 1) {
									if (tar[i] != ",") {
										result = `${tar[i]}${result}`;
									}
								}
								if (tar.length == 0) {
									e.target.value = "";
								} else {
									e.target.value = parseInt(result);
								}

								formikFirst.handleChange(e);
							}}
							error={
								formikFirst.touched.upto &&
								Boolean(formikFirst.errors.upto)
							}
							fullWidth
							helperText={
								formikFirst.touched.upto &&
								formikFirst.errors.upto
							}
							label={"Upto"}
							sx={{ textAlign: "left" }}></TextField>
					</Stack>
				</Stack>
				{/*<Stack direction={"column"} spacing={5}>
					<TextField
						required
						name={"investorType"}
						value={formikFirst.values.investorType}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.investorType &&
							Boolean(formikFirst.errors.investorType)
						}
						helperText={
							formikFirst.touched.investorType &&
							formikFirst.errors.investorType
						}
						label={"Investor type"}
						select
						sx={{textAlign: 'left'}}
					>
						<MenuItem value={'Venture Capital Fund'} >
							Venture Capital Fund
						</MenuItem>
						<MenuItem value={'Angel Investor'} >
							Angel Investor
						</MenuItem>
					</TextField>
					{formikFirst?.values?.investorType === 'Venture Capital Fund' && <>


						<TextField
							required={formikFirst?.values?.investorType === 'Venture Capital Fund'}
							name={"nameOfOrganization"}
							value={formikFirst.values.nameOfOrganization}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.nameOfOrganization &&
								Boolean(formikFirst.errors.nameOfOrganization)
							}
							helperText={
								formikFirst.touched.nameOfOrganization &&
								formikFirst.errors.nameOfOrganization
							}
							label={"Name of organization"}
						/>
						<TextField
							name={"website"}
							value={formikFirst.values.website}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.website &&
								Boolean(formikFirst.errors.website)
							}
							helperText={
								formikFirst.touched.website &&
								formikFirst.errors.website
							}
							label={"Website"}
						/>
						<TextField
							required
							name={"organizationDescription"}
							value={formikFirst.values.organizationDescription}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.organizationDescription &&
								Boolean(formikFirst.errors.organizationDescription)
							}
							helperText={
								formikFirst.touched.organizationDescription &&
								formikFirst.errors.organizationDescription
							}
							label={"Description of Organisation"}
							multiline={true}
							rows={3}
						/>

					</>}
				</Stack>*/}
			</Container>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3}
				width={"100%"}>
				<Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Back
				</Button>
				{/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
				<Button
					variant={"contained"}
					color={"primary"}
					sx={{ px: 5 }}
					type={"submit"}>
					Next
				</Button>
			</Stack>
		</Stack>
	);
}
