import * as React from "react";
import {
	Avatar,
	Container,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import RoomsList from "../../../screens/mobile/components/common/chat/rooms-list"
import MessageList from "../../../screens/mobile/components/common/chat/message-list";
import { AuthUserContext } from "../../../providers/auth-provider";
import InputMessageComponent from "../../../screens/mobile/components/common/chat/input-message-component";
import MessagesTopbar from "../../../screens/mobile/components/common/chat/messages-topbar";
import FilesPreviews from "../../../screens/mobile/components/common/chat/files-previews";
import SidePanelRight from "../../../screens/mobile/components/common/chat/side-panel-right";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function ChatScreen() {
	const { roomId, setRoomId, roomData, setRoomData } =
		React.useContext(AuthUserContext);
		const [steps, setSteps] = React.useState(0);

	const [files, setFiles] = React.useState([]);
	const [sidePanel, setSidePanel] = React.useState(false);
	const [messageList, setMessageList] = React.useState();
	const setMediaPenal = () => {
		setSidePanel(!sidePanel);
	};

	React.useEffect(() => {
		return () => {
			// setRoomId(null);
			// setRoomData(null);
		};
	}, [roomId]);

	return (
		<React.Fragment>
			
				
					<RoomsList  />
				
				
				
			
				     
					
				
					{/* list of rooms*/}
					{/* conversation */}
					{/* file, media and links */}
				
			
		</React.Fragment>
	);
}
