import { Avatar, Grid, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import moment from "moment";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { useState } from "react";


export default function ServiceProviderWorkExperience({ profile, setIndex , setK }) {
  function intToString(num) {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: "K" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    );
  }
  return (
    <>
                <Stack
                direction={"column"}
                spacing={6}
                sx={{
                  // border: '0.5px solid #000000',
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 3,
                  py: 3,
                }}
              >
          {profile?.userType === "service-provider" &&
          profile?.serviceProvider &&
          profile?.serviceProvider.experiences &&
          profile?.serviceProvider.experiences.length > 0 &&(
        <Typography variant={"h6"} sx={{fontWeight:'600'}}>Work Experience</Typography>
      )} 
      {profile?.userType === "service-provider" &&
          profile?.serviceProvider &&
          profile?.serviceProvider.experiences &&
          profile?.serviceProvider.experiences.length === 0  ? <>
        <Typography variant={"h6"} style={{fontWeight:"600"}}>Work Experience</Typography>

          <Stack direction="row" justifyContent="center">

            <Typography variant={"body2"}>There are no work experience to be shown</Typography>
          </Stack></>:null 
        }
      
            {profile?.userType === "service-provider" &&
          profile?.serviceProvider &&
          profile?.serviceProvider.experiences &&
          profile?.serviceProvider.experiences.length > 0 &&
          profile?.serviceProvider.experiences.map((p, i) => (
            <>
<Box key = {i} 
  onClick={() => {
  setIndex(i+1);
  setK(i)
  }}
sx={{width: "100%",
 backgroundColor: '#F6F8FA',
  // border: '0.5px solid #000000',
   borderRadius: '5px',
    p:5,
    px: 2 }}>

<Stack direction={'column'} spacing={.5} sx={{width:'100%'}}>
  <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
    <Box sx={{flex:1}}/>

  </Stack> 
  <Stack direction={'column'} spacing={.5} sx={{width:'100%'}}>
  {console.log('test')}

    <Typography variant={'body1'}  sx={{textAlign:'left',marginTop:"-32px", fontWeight:"600", fontSize:"16px"}}>
    {
            p?.companyName < 10 ? p?.companyName : `${p?.companyName.slice(0 , 10)}...`
          }
    </Typography>
    <Typography  variant={'caption'}  sx={{textAlign:'left'}}>

      <a href={
        p?.website
      } target={'_blank'}>{
        p?.website.length < 30 ? p?.website : `${p?.website.slice(0 , 30)}...`
      }</a>
    </Typography>
    <span/>
    <span/>
    <span/>
    <span/>
    <span style={{flex:1}}/>
    <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
      <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500", fontSize:"14px"}}>
        {p?.role}
      </Typography>
      <span style={{flex:1}}/>
      <Typography  variant={'caption'}  sx={{textAlign:'left'}}>
        {moment(p?.startDate).format("MMM Do")} - {moment(p?.endDate).format("MMM Do")}
      </Typography>
    </Stack>
    <Typography  variant={'caption'} paragraph={true} sx={{textAlign:'left', color:'#8F8F8F',width: 275, overflowY: 'hidden'}}>
      {
        p?.experience.length < 200 ? p?.experience :  `${p?.experience.slice(0 , 200)}...`
      }
    </Typography>
  </Stack>
</Stack>

</Box>

</>
          ))}
          </Stack>
    </>
  );
}