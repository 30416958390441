import * as React from "react";
import { Button, IconButton, Menu, Portal, Stack, Typography } from '@mui/material'
// import {CelebrateIcon, FunnyIcon, InsightFulIcon, LikeIcon, PunchIcon} from "../../../utils/svg-icons";
import {DB} from "../../config/firebase/firebase";
import {collection, setDoc, doc, getDoc, deleteDoc, serverTimestamp,updateDoc,deleteField, arrayUnion,onSnapshot} from "firebase/firestore";
import { AuthUserContext } from '../../providers/auth-provider'
import {useEffect} from "react";
import FavoriteBorderSharpIcon from '@mui/icons-material/FavoriteBorderSharp';
import FavoriteSharpIcon from '@mui/icons-material/FavoriteSharp';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
export default function MemberFavourite({data}) {
  const {profile} = React.useContext(AuthUserContext);
  const [favorite, setFavorite] = React.useState(false)
  const [favoriteDoc, setFavoriteDoc] = React.useState({})
  const [remove, setRemove] = React.useState(false);
  // const arr = ["Remove from favorites?"];

  const favoriteCallback = () => {
    {console.log(data)}
    const colRef = doc(DB, "users", profile?.uid, "favoriteMembers", data?.uid);
    setDoc(colRef, {
      createdAt: serverTimestamp(),
       favorites: arrayUnion(data?.card)
    }, {merge: true}).then(()=> {
      setFavorite(true)
      setFavoriteDoc((prev) => {
        if (prev && prev.favorites) {
          prev.favorites.push(data?.card);
          return prev;
        } else {
          return {
            id: data?.uid,
            favorites: [data?.card],
          };
        }})
    }).catch(() => {
      debugger
    });
  }

  useEffect(() => {
    setFavorite(false);
    setFavoriteDoc(undefined);
    try {
      const unsubscribe = onSnapshot(
        doc(DB, "users", profile?.uid, "favoriteMembers", data?.uid),
        (snapshot) => {
          if (snapshot.exists()) {
            setFavorite(true);
            setFavoriteDoc({ id: data?.uid, ...snapshot.data() });
            console.log("id", data?.uid + JSON.stringify(snapshot.data()));
          }
        }
      );
      return () => unsubscribe();
    }
    catch(error){
      console.log("the error is in useEffect", error)
    }


  }, [data?.uid]);
  return(
    // <>Bassam</>
    <>
      <IconButton onClick={() => {
        if (favoriteDoc && favoriteDoc.favorites.some(item => item === data?.card) )
        {
          setRemove(true)
        } else {
          favoriteCallback();
        }
      }}>
        {favoriteDoc && favoriteDoc.favorites && favoriteDoc.favorites.some(item => item === data?.card) ? <FavoriteSharpIcon sx={{color:'#FF3C3C'}} /> : <FavoriteBorderSharpIcon color={'disabled'} />}
      </IconButton>
      <Portal>
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
          open={remove}
        >
          <DialogContent>
            <Stack
              direction={"column"}
              justifyContent={"centre"}
              alignItems={"center"}
              sx={{ pt: 1 }}
              // spacing={3}
            >
              <FavoriteSharpIcon sx={{color:'#FF3C3C', height:"10%", width:"10%"}} />
            </Stack>

            <Typography
              type={"h1"}
              variant={"h5"}
              textAlign={"center"}
              sx={{ fontWeight: "600", pl: 10, pr: 10, pt:2 }}
            >
              Remove from favorites?
            </Typography>
            <Typography
              type={"h5"}
              variant={"subtitle1"}

              textAlign={"center"}
              sx={{ pt:2 }}
            >
              Are you sure you want to remove from favorites?<br/>
              This action can not be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack direction="row"
                   justifyContent="center"
                   alignItems="center"
                   spacing={2}
            sx={{width:"100%", pb:2}}>
              <Button
                variant="outlined"
                color="primary"
                sx={{ textTransform: "none", width:"25%" }}
                onClick={() => {setRemove(false)}}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none", width:"25%" }}
                onClick={() => {
                  const colRef = doc(DB, "users", profile?.uid, "favoriteMembers", data?.uid);
                  if (favoriteDoc.favorites.length < 2){
                    deleteDoc(colRef).then(()=> {
                      setFavorite(false)
                      setFavoriteDoc(undefined);
                    })
                  }else{
                    const index = favoriteDoc.favorites.findIndex((e) => e === data?.card)
                    const favorites = favoriteDoc.favorites
                    favorites.splice(index,1)
                    updateDoc(colRef, {
                      favorites: [...favoriteDoc.favorites]
                    }).then(() => {
                      setFavorite(true)
                      setFavoriteDoc(
                        {
                          id: data?.uid,
                          favorites: [ ...favorites ]
                        }
                      );
                    })
                  }
                setRemove(false)
                }}

              >
                Yes
              </Button>

            </Stack>
          </DialogActions>
        </Dialog>
      </Portal>
    </>
  )
}