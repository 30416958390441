import React from "react";
import {
	Avatar,
	Grid,
	Paper,
	Stack,
	styled,
	Typography,
	Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import { AuthUserContext } from "../../../../providers/auth-provider";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ListItemStartConversationC from "./list-item-start-conversation copy";
import MemberFavourite from "../../common/MemberFav";
import ListItemStartConversation from "./list-item-start-conversation";


const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "center",
	backgroundColor: "#fff",
	color: theme.palette.text.secondary,
	boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
	borderRadius: "10px",
	height: 250,
	

}));
export default function OrganisationInvestorListItem({ data, href,itemWidth }) {
	const navigator = useNavigate();
	const { profile } = React.useContext(AuthUserContext);
	

	return (
		<Grid
			item
			lg={4}
			md={4}
			sm={6}
			xs={6}

			wrap={"nowrap"}>
			<Item style={{ width: itemWidth }}>
				<Stack direction={"column"} spacing={2} sx={{ width: "100%" }}
					   justifyContent="center"
					   alignItems="center"
				><Stack sx={{ ml: "90%", mb:"-15%", mt:"-2.5%" }}>
					<MemberFavourite data={{ ...data,card: "investor-organization" }} />
				</Stack>
					<Box
					sx={{
						position: "relative",
							top:"40px",
							width: "150px",
							height: "110px",
							background: "#F6F8FA",
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px"
						}}
					>
					<Stack
						direction={"column"}
						spacing={1}
						justifyContent="center"
						alignItems="center"  style={{cursor:"pointer"}}	onClick={() => navigator(href,  {
						state:
							{
								profile:data,
								organisation: {
									organizationDescription: data?.investor?.organizationDescription,
									investorType: data?.investor?.investorType,
									website: data?.investor?.website,
									nameOfOrganization:  data?.investor?.nameOfOrganization,
									logoURL:
										data?.investor.logoURL,
								},
							},
					})}>
						<Box
							sx={{
								border: "1px solid #204488",
								p: 0.75,
								borderRadius: "50%",
								marginTop : "-40px"
							}}>
							<Avatar
								alt={data?.investor?.nameOfOrganization}
								src={data?.investor.logoURL}
								sx={{ width: 40, height: 40 }} imgProps={{style: {objectFit: 'contain', backgroundColor: '#fff'}}}>
								<Typography  style={{fontSize:"12px",overflow:"hidden"}}>
									{data?.investor?.nameOfOrganization &&
										data?.investor?.nameOfOrganization.charAt(0)}
								</Typography>
							</Avatar>
						</Box>
						<Typography
							style={{fontSize:"12px",overflow:"hidden"}}
							sx={{ fontWeight: 650, mb: 0, lineHeight: 1.2,color:"black"}}>
							{(data?.investor?.nameOfOrganization && data.investor.nameOfOrganization.length > 14) ? `${data.investor.nameOfOrganization.substr(0, 14)}...` : data?.investor?.nameOfOrganization}
 <br />
							<font size={3} style={{fontSize:"10px",overflow:"hidden",fontWeight:"300"}}>
								Investor - {'Organisation'}
							</font>
						</Typography>
						<Box />
					</Stack>
					<Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}
            >
					{
						(data.uid !== profile?.uid) ?  (
							<ListItemStartConversation data={{...data, creatorData: data}}/>

						):<Button size={'small'} variant={"outlined"} color={'secondary'} sx={{px: 2, width:120}} 
						onClick={() => navigator(href,   {
							state:
								{
									profile:data,
									organisation: {
										organizationDescription: data?.investor?.organizationDescription,
										investorType: data?.investor?.investorType,
										website: data?.investor?.website,
										nameOfOrganization:  data?.investor?.nameOfOrganization,
										logoURL:
										data?.investor.logoURL,
									},
								},
						})}
								>My profile</Button>
					}
					</Box>
					</Box>
					
					<Box />
					<Box />
					<Box />
					<Stack direction={"row"} spacing={0} sx={{width: '100%'}} style={{marginBottom:"-10px",marginTop:"-1px"}}>
						{data?.investor && data?.investor?.investorType && (
							<Stack  direction={"row"} spacing={0} sx={{width: '100%'}}>
								<Typography
								style={{fontSize:"10px"}}
								sx={{
									fontWeight: "600",
									textAlign: "left",
									width:"72%",
								}}>
									Investor Type
								</Typography>
								<Typography style={{fontSize:"8px",overflow:"hidden"}} >
								{data?.investor?.investorType.substr(0, 7)}
								</Typography>
							</Stack>
						)}
					</Stack>
						{
						data?.investor?.website &&
						<>
					<Stack direction={"row"} spacing={0} sx={{width: '100%'}} >
						<Typography
						style={{fontSize:"10px"}}
								sx={{
									fontWeight: "600",
									textAlign: "left",
									width:"100%",
								}}>
							Website
						</Typography>
						<Typography
						variant={"body2"}
						style={{fontSize:"8px",overflow:"hidden"}}
						sx={{ width: 'fit-content', textAlign: "right" }}
						noWrap={true}>
							<a href={data?.investor?.website} style={{color: 'rgba(0, 0, 0, 0.6)'}} target={"_blank"} rel="noreferrer">{data?.investor?.website}</a>
						</Typography>
					</Stack>
							</>
							}
							
				</Stack>
			</Item>
		</Grid>
	);
}
