import React from "react";
import {
  Avatar,
  Badge,
  Button, CircularProgress,
  Container,
  IconButton, Link,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography, useMediaQuery
} from "@mui/material";
import { useFormik } from "formik";
import InvestorProfileValidation from "../../../validations/investor/investor-profile";
import {AuthUserContext} from "../../../providers/auth-provider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFile from "../../../utils/upload-file";
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";

export default function InvestorOrganizationForm({ cbBack, cbNext, data, uid, cbDraft }) {
  
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const isAdmin = /admin/;

  const Input = styled("input")({
    display: "none",
  });
  const { user } = React.useContext(AuthUserContext);

  const [profileImage, setProfileImage] = React.useState({
    src: data?.logoURL || "",
    logoURL: data?.logoURL || "",
  });
  const [loading, setLoading] = React.useState(false);

  const formikFirst = useFormik({
    initialValues: data
      ? data
      :{...InvestorProfileValidation.investorOrganizationDetailsValues},
    validationSchema: InvestorProfileValidation.investorOrganizationDetailsForm,
    onSubmit: async (values) => {
      await formikFirst.setFieldValue(
        "logoURL",
        profileImage?.logoURL ? profileImage?.logoURL : ""
      );
      cbNext({

        ...values,
        logoURL: profileImage?.logoURL ? profileImage?.logoURL : "",

      });
    },
  });
  React.useEffect(() => {
    if (uid) {
      setTimeout(() => {
        getDoc(doc(DB, 'users', uid))
          .then((document) => {
            if (document?.data()?.formFirstData) {
              formikFirst.setValues(document?.data()?.formFirstData);
            }
          })
      }, 500 )
    }
    
  }, [data])
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}
    >
      <Container maxWidth={"md"}>

        <Stack direction={"column"} spacing={3}>

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ mb: 5 }}>
            <label htmlFor="profile-image">
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  <IconButton
                    color={"primary"}
                    component={"span"}>
                    <AddCircleIcon />
                  </IconButton>
                }>
                <Avatar
                  alt={"startup-picture"}
                  src={
                    profileImage && profileImage?.src
                      ? profileImage?.src
                      : "/assets/startup-placeholder-image.png"
                  }
                  sx={{
                    width: 128,
                    height: 128,
                    cursor: "pointer",
                    backgroundColor: "transparent",
                    border: "1px solid #E0E3FF",
                  }}
                  imgProps={{
                    sx: { objectFit: "scale-down" },
                  }}
                  component={"span"}
                />
              </Badge>
              <Input
                accept="image/*"
                id="profile-image"
                multiple={false}
                type="file"
                onChange={(e) => {
                  const fileReader = new FileReader();
                  fileReader.readAsDataURL(e.target.files[0]);
                  fileReader.addEventListener(
                    "load",
                    async function () {
                      setLoading(true);
                      const file = e.target.files[0];
                      const path = isAdmin.test(window.location.pathname) ? `admin/${user.uid}/investor/logo/${file.name}`:`investors/${user.uid}/logo/${file.name}`;
                      const imageObject = {
                        progress: 0,
                        src: this.result,
                        path,
                        file: e.target.files[0],
                        size: (
                          e.target.files[0]?.size /
                          1024 /
                          1024
                        ).toFixed(2),
                        logoURL: ''
                      };
                      setProfileImage(imageObject);
                      UploadFile(file, path).then(
                        (url) => {
                          setProfileImage({
                            ...imageObject,
                            logoURL: url,
                          });
                          setLoading(false);
                        }
                      );
                    }
                  );
                }}
              />
            </label>
            {loading && (
              <CircularProgress
                sx={{ ml: 2 }}
                size={20}
                color="primary"
              />
            )}
            {profileImage && profileImage?.src && (
              <Link
                color={"secondary"}
                onClick={() => setProfileImage(undefined)}>
                Remove picture
              </Link>
            )}
            {/* <Typography color="GrayText">Upload Logo</Typography> */}
            {profileImage && profileImage?.src ? ""
              : <Typography color="GrayText">Upload Logo</Typography>}
          </Stack>
          <Stack direction="column" spacing={0}>
          <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Investor type
							  </Typography>
          <TextField
            name={"investorType"}
            value={formikFirst.values.investorType}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.investorType &&
              Boolean(formikFirst.errors.investorType)
            }
            helperText={
              formikFirst.touched.investorType &&
              formikFirst.errors.investorType
            }
            //label={"Investor type *"}
            select
            sx={{ textAlign: "left" }}
          >
            <MenuItem value={"Venture Capital Fund"}>
              Venture Capital Fund
            </MenuItem>
            <MenuItem value={"CVC"}>CVC</MenuItem>
            <MenuItem value={"PE Fund"}>PE Fund</MenuItem>
            <MenuItem value={"Family Office"}>Family Office</MenuItem>
          </TextField>
          </Stack>
          <Stack direction="column" spacing={0}>
          <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Name of organization *
							  </Typography>
          <TextField
            // required={formikFirst?.values?.investorType === "Family Office"}
            name={"nameOfOrganization"}
            value={formikFirst.values.nameOfOrganization}
            onChange={formikFirst.handleChange}
            error={Boolean(
              formikFirst.errors.nameOfOrganization
            )}
            helperText={
              formikFirst.errors.nameOfOrganization
                ? "Name of organization is required"
                : ""
            }
            //label={"Name of Organisation *"}
          />
          </Stack>
          <Stack direction="column" spacing={0}>
          <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Website
							  </Typography>
          <TextField
            name={"website"}
            value={formikFirst.values.website}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.website &&
              Boolean(formikFirst.errors.website)
            }
            helperText={
              formikFirst.touched.website && formikFirst.errors.website
            }
            //label={"Website"}
          />
          </Stack>
          <Stack direction="column" spacing={0}>
          <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Description of Organisation *
							  </Typography>
          <TextField
            // required
            name={"organizationDescription"}
            value={formikFirst.values.organizationDescription}
            onChange={formikFirst.handleChange}
            error={Boolean(
              formikFirst.errors.organizationDescription
            )}
            helperText={
              formikFirst.errors.organizationDescription
                ? "Description of organization is required"
                : ""
            }
            //label={"Description of Organisation *"}
            multiline={true}
            rows={3}
          />
          </Stack>
        </Stack>
      </Container>
      {matches ?  <Stack
        direction={"row"}
        justifyContent="flex-end"
        alignItems="center"
        spacing={3}
        width={"100%"}>
        <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 5 }}
          onClick={cbBack}>
          Back
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{ px: 5 }}
          type={"submit"}>
          Next
        </Button>
        {isAdmin.test(window.location.pathname) && <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 5 }}
          onClick={() => {
            cbDraft({...formikFirst.values, logoURL: profileImage?.logoURL ? profileImage?.logoURL : ""})
          }}>
          Save as draft
        </Button>}
      </Stack> : 	<Stack
        direction={"row"}
        justifyContent="center"
        alignItems="center"
        spacing={3}
        width={"100%"}>
        {isAdmin.test(window.location.pathname) && <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 5 }}
          onClick={() => {
            cbDraft({...formikFirst.values, logoURL: profileImage?.logoURL ? profileImage?.logoURL : ""})
          }}>
          Save draft
        </Button>}
        <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 5 }}
          style={{paddingLeft:"15%",paddingRight:"15%"}}

          onClick={cbBack}>
          Back
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{ px: 5 }}
          style={{paddingLeft:"15%",paddingRight:"15%"}}

          type={"submit"}>
          Next
        </Button>
      
      </Stack>}


    </Stack>
  );
}
