import React, { useEffect } from "react";
import { Stack, Badge, Grid, Chip, Paper, Box, Button,Avatar} from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import StartConversationList from "../start-conversation-listview";
import { AuthUserContext } from "../../../../../providers/auth-provider";
import { useNavigate } from "react-router-dom";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { USERS_NAME_MAPPING } from "../../../../../utils/enums/labels.enum";
import RequestUserAvatar from "../request-user-avatar";
import RequestUserAvatarPic from "../request-user-avatarpic";

export default function OpenRequestListItem({ request, searchQuery }) {
  const [user, setUser] = React?.useState();
  const { profile } = React.useContext(AuthUserContext);
  const limit = 2;
  let mappedElements =0;
 
  const navigator = useNavigate();
 
  return (
    <>
      {request?.requestFor?.toLowerCase().includes(searchQuery) ||
      request?.type?.toLowerCase().includes(searchQuery) ||
      request?.skills?.find((a) =>
        a.toLowerCase().includes(searchQuery.toLowerCase())
      ) !== undefined ||
      request?.searchFor?.find((a) =>
        a.toLowerCase().includes(searchQuery.toLowerCase())
      ) !== undefined ? (
    
         
        
         <Box style={{
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",height:"480px",
          borderRadius:"12px",paddingRight:"20px",paddingLeft:"20px",paddingTop:"20px", backgroundColor:  request.uid ===profile?.uid ? "#E5F5FC":"#F6F8FA",
          opacity: request.stage === "Closed" ||  request.stage === "Deactivated"  ? '0.5' : "1",}}>

              <Paper
                elevation={2}
                style={{
                  justifyContent: "space-between",
                  display:"flex", flexDirection:"column",
                  height: "208px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  position: 'relative'
                }}
              >
                <Stack>
                <RequestUserAvatarPic request={request} key={'avatar-'+request?.id}/>
                <Typography
                  variant={"body1"}
                  noWrap={true}
                  onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated")  &&
                  navigator(`/m/${profile.userType}/open-requests-details`, {
                    state: { request },
                  })
                }
                sx={{
                    width: 220,
                    lineHeight: 1.3,
                    cursor: "pointer",
                  }}
                  >
                  {request.requestFor}<br />
                 
                </Typography>
                <font color={"#8F8F8F"} size={2}>
                    {request?.location
                      ? request?.location
                      : request?.creatorData?.startup &&
                      request?.creatorData?.startup.location}
                  </font>
                </Stack>
                <br />
                <Grid
                  container
                  onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated")  &&
                    navigator(`/m/${profile.userType}/open-requests-details`, {
                      state: { request },
                    })
                  }
                  sx={{}}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={.5}
                >
                  {(request?.requestFor === "Looking for funding" ||
                    request?.type === "looking-for-funding") && (
                    <Grid item>
                      <Chip
                        variant="contained"
                        sx={{
                          my: 0,
                          backgroundColor: "#B2FFFA",
                          px: 0.1,
                          maxWidth: 120,
                          borderRadius: "5px",
                        }}
                        component={"p"}
                        label={"Looking for funding"}
                      />
                    </Grid>
                  )}
                  {request?.searchFor &&
                    request?.searchFor.length > 0 &&
                    request?.searchFor?.map((s, i) => {
                      
                      if (mappedElements <= limit) {
                        mappedElements+=1
                        return (
                          <Grid item key={i}>
                            <Chip
                              variant="contained"
                              sx={{
                                my: 0,
                                backgroundColor: "#B8FC3C",
                                px: 0.1,
                                maxWidth: 120,
                                borderRadius: "5px",
                              }}
                              component={"p"}
                              label={USERS_NAME_MAPPING[s]}
                              key={i}
                            />
                          </Grid>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  {request?.skills &&
                    request?.skills.length > 0 &&
                    request?.skills?.map((s, i) => {
                      
                      if (mappedElements <= limit) {
                        mappedElements+=1
                        return (
                          <Grid item key={i}>
                            <Chip
                              variant="contained"
                              sx={{
                                my: 0,
                                backgroundColor: "#5CE5D5",
                                px: 0.1,
                                maxWidth: 120,
                                borderRadius: "5px",
                              }}
                              component={"p"}
                              label={s}
                              key={i}
                            />
                          </Grid>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  {request?.skills && request?.searchFor && request?.skills.length + request?.searchFor.length - mappedElements !==0 &&
						<Typography sx={{fontWeight:500, fontSize:15, color:"#8F8F8F"}}>
							+  {String((request?.skills.length + request?.searchFor.length) - mappedElements)}  more
						</Typography>}
                </Grid>
              </Paper>
              <br></br>
              <Stack direction="colum"
              justifyContent="center"
              alignItems="center"
              
              sx={{
             
              borderRadius: "6px",
              width:"99%",
              padding:"5px",
              boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset"
              }}
            >
              <Stack
                direction={"row"}
                spacing={4}

                onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated")  &&
                  navigator(`/m/${profile.userType}/open-requests-details`, {
                    state: { request },
                  })
                }
                sx={{
                  textAlign: "left",
                  cursor: "pointer",
                  width: "85%",
                  height:"21px"
                }}
              >
                <Typography variant={"body2"} fontWeight={500}>
                  Status
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                  sx={{ color: "#8F8F8F", fontSize: 14 }}
                >
                  <Badge
                    variant={"dot"}
                    invisible={false}
                    color={
                      !request.stage || request.stage === "activate"
                        ? "primary"
                        : request.stage === "closed"
                        ? "error"
                        : "warning"
                    }
                  />
                  &nbsp;&nbsp;&nbsp;
                  {request.stage && request.stage === "activate"
                    ? "Open"
                    : !request.stage
                    ? "Open"
                    : request.stage}
                </Stack>
              </Stack>
            </Stack>
            
        <Box style={{paddingRight:"10px",paddingLeft:"20px",paddingTop:'10px'}} onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated")  &&
                navigator(`/m/${profile.userType}/open-requests-details`, {
                  state: { request },
                })
              }>
            <Box style={{borderBottom:"1px solid #8F8F8F"}}>
            <Stack direction="row" spacing={7}>
                <Stack direction="row" spacing={1}>
                <Typography style={{width:"15px"}}>  <img alt="none"
                  src={"/assets/budget.png"}
                  style={{ height: "13px" }}
                ></img></Typography>
                <Typography variant={"body2"}
                  fontWeight={500}
                  sx={{ color: "#8F8F8F" }}> {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Amount to raise"
                    : "Budget"}</Typography>
                    </Stack>
                <Stack>
                  
                    <Typography sx={{ fontSize: "13px" }} fontWeight={500}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? `${request?.currency} ${request?.amount}`
                    : `${request?.currency} ${request?.minBudget} - ${request?.currency} ${request?.maxBudget}`}
                </Typography>
                </Stack>

               </Stack>
            

            </Box>
        </Box>
        <Box style={{paddingRight:"10px",paddingLeft:"20px",paddingTop:'10px'}} onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated")  &&
                navigator(`/m/${profile.userType}/open-requests-details`, {
                  state: { request },
                })
              }>
            <Box style={{borderBottom:"1px solid #8F8F8F"}}>
            <Stack direction="row" spacing={5}>
                <Stack direction="row" spacing={1}>
                <Typography style={{width:"15px"}}> 
                <CalendarMonthIcon
                  sx={{ fontSize: "16px", mt: 0.25, color: "#8F8F8F" }}
                ></CalendarMonthIcon>
                </Typography>
                <Typography
                  variant={"body2"}
                  fontWeight={500}
                  sx={{ color: "#8F8F8F" }}
                >
                  Due Date
                </Typography>
                    </Stack>
                <Stack>
                  
                <Typography variant={"body2"} fontWeight={500}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? moment(request.fundingCloseDate).format("MMM D, YYYY")
                    : `${moment(request.from).format("MMM D, YYYY")} `}
                </Typography>
                </Stack>

               </Stack>
             

            </Box>
        </Box>
        <Box style={{paddingRight:"10px",paddingLeft:"20px",paddingTop:'10px'}} onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated")  &&
                navigator(`/m/${profile.userType}/open-requests-details`, {
                  state: { request },
                })
              }>
            <Box style={{borderBottom:"1px solid #8F8F8F"}}>
            <Stack direction="row" spacing={5}>
                <Stack direction="row" spacing={1}>
                <Typography style={{width:"15px"}}> 
                <BusinessCenterIcon
                  sx={{ fontSize: "16px", mt: 0.25, color: "#8F8F8F" }}
                ></BusinessCenterIcon>
                </Typography>
                <Typography
                  variant={"body2"}
                  fontWeight={500}
                  sx={{ color: "#8F8F8F" }}
                >
               {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Round"
                    : "Job type"}
                </Typography>
                    </Stack>
                <Stack>
                  
                <Typography variant={"body2"} fontWeight={500}>
                {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? request?.round
                    : request?.jobType}
                </Typography>
                </Stack>

               </Stack>
            

            </Box>
        </Box>
         <br></br>
         <Stack direction={'row'} justifyContent={'center'}>
					
						<RequestUserAvatar request={request} key={'avatar-'+request?.id}/>
					

						{/*{
							// request.stage !== "Closed" &&
							// request.stage !== "Deactivated" &&
							request.uid !== profile?.uid &&
							<StartConversationList
							data={request}
							key={request.id}
							/>
						}*/}
						
					</Stack>
					</Box>
		
			) : (
				<></>
			)}
		</>
	);
}
