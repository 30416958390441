import * as React from 'react';

import {addDoc, collection, serverTimestamp,doc,
  setDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
import {
  Stack, FormControl,
  InputLabel,
  Input, IconButton, InputAdornment, TextField, Box, Typography, Button, CircularProgress, Fab
} from "@mui/material";
import {AuthUserContext} from "../../../providers/auth-provider";
import AttachmentButton from "./attachment-button";
import InputBase from "@mui/material/InputBase";
import ReferAFriend from "../../../api/refer-a-friend";
import SendIcon from "@mui/icons-material/Send";
export default function InputMessageComponent({callback}){
  const {user, roomData} = React.useContext(AuthUserContext);

  const [textInput, setTextInput] = React.useState();
  const [isEnter, setIsEnter] = React.useState(false);
  const messagePost = async () => {
    try {
      if (textInput !== ''){
        setLoading(true);
        let message = await addDoc(collection(DB, "messages"),
          {
            type: 'text',
            text: textInput,
            sender: user?.uid,
            receiver: roomData.participates.find((d) => d !== user?.uid),
            createdAt:serverTimestamp(),
            roomId: roomData?.id,
            read:false,
            status: 'send'
          });
        const room = doc(DB, 'rooms', roomData?.id)
        setTextInput('')
        await setDoc(room, { lastMsg: {
            text: textInput,
            status: 'send'
          }, updatedAt: serverTimestamp()}, {merge: true})
        document.getElementById("msg-container").scrollTo({
          top: document.getElementById("msg-container").scrollHeight,
          behavior: 'smooth',
        });
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)

    }


  }
  const onEnter = async  (e) => {

    if (e.key === 'Enter' && !isEnter) {
       setIsEnter(true)
       await messagePost()
       setIsEnter(false)
      
    }
  }
  const [loading , setLoading] = React.useState(false)
  return(
    <Stack direction={'row'} spacing={1} sx={{width:'100%'}}>
      <Stack direction={'row'} spacing={1} p={2}
             justifyContent="flex-start"
             alignItems="center" sx={{width:'100%',backgroundColor:  '#F6F8FA', borderRadius: '12px', height: 61, marginY : 'auto !important', mx: '15px !important'}}>

        <InputBase
          sx={{  flex: 1, color: "#051E40BF" }}
      
          inputProps={{ "aria-label": "search post" }}
          value={textInput}
          onKeyDown={onEnter} placeholder={'Type here...'}  onChange={(e) => setTextInput(e.target.value)}
        />
        <AttachmentButton callback={(files) => callback(files)} />
        <Typography variant={'h3'} fontWeight={100} sx={{color: '#8F8F8F'}}>|</Typography>
        <Fab size={"medium"} variant={'extended'}  color={loading ? '':'primary'} onClick={messagePost} sx={{boxShadow: 'none', borderRadius: '8px', width: 50, height:43}}
        >
          {
            loading ?
              <CircularProgress size={18}/> :
              <img src={'/assets/sendicon.png'}/>
          }

        </Fab>

      </Stack>
      </Stack>
   /*<>
     <Stack direction={'row'} spacing={1} sx={{px:2}} >

       <AttachmentButton callback={(files) => callback(files)} />
       <TextField value={textInput}
                  onKeyDown={onEnter} placeholder={'Type here...'}  onChange={(e) => setTextInput(e.target.value)} variant={'outlined'}    sx={{flex: 1, border:'none', textAlign:'left'}} size={"small"}/>


     </Stack>

   </>*/

  )
}
