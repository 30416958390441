import React from 'react';

import {
    Button,
    Container, MenuItem,
    Stack,
    TextField, Typography,
  } from "@mui/material";
import { fontWeight } from '@mui/system';
import {  useNavigate } from "react-router-dom";
const Implementfunctionality = () => {
    const navigator = useNavigate();
    return (
      <>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Stack direction="row" justifyContent="center" alignItems="center" style={{marginBottom:"10px"}}>
         <Typography variant="h5" style={{fontWeight:"bold"}}>It's not you....it's us!</Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography style={{fontWeight:"400",fontSize:"16px",color:"black"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Please note that we are working on
          <br></br>&nbsp;implementing this functionality over the course <br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of the next release. Watch this space and 
          <br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;thanks for your patience 👀👀🙏🏽</Typography>
        </Stack>
        <br></br>
        <br></br>
     <Stack direction="row" justifyContent="center" alignItems="center">
     <Button
                  sx={{ width: "70%" }}
                  variant={"contained"}
                  color={"primary"}
                 onClick={()=>{navigator(-1)}}
                >
                  Go Back
                </Button>
     </Stack>
      </>
    );
}

export default Implementfunctionality;
