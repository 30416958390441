import {Grid, IconButton, Link, Stack, Typography} from "@mui/material";
import React from "react";
import moment from "moment";
import {EditSharp} from "@mui/icons-material";
import AboutServiceProviderOrganisationComponent from "./edit/service-provider/about-service-provider-organisation-component";
import { AuthUserContext } from "../../../providers/auth-provider";
import { useLocation } from "react-router-dom";
const styleFont = {
	backgroundColor: "#F6F8FA",
	padding: "6px 10px",
	marginLeft:'3px',
	borderRadius: "5px",
	fontSize: 14,
};

export default function OrganisationAbout({profile, organisation, callback }) {
	debugger
	const match = useLocation();
	const {user} = React.useContext(AuthUserContext);
	const contaxtUser = React.useContext(AuthUserContext);
	const [open, setOpen] = React.useState(false);
	const profileRegex = /profile/
	const mobileRegex = /m/
	return (
		<>
			<Stack direction={"row"} spacing={2} >
				{mobileRegex.test(window.location.pathname) 
				?
				<Typography variant={"h6"} sx={{fontWeight:"600"}} style={{flex: 1}}>About Organisation</Typography>
				: 
				<Typography fontSize={20} fontWeight={600} style={{flex: 1}}>About Organisation</Typography>
				}
				{
					profile?.uid === user?.uid && profileRegex.test(window.location.pathname) &&
					<IconButton onClick={() => setOpen(true)}>
						<EditSharp />
					</IconButton>
				}
				{
					contaxtUser?.profile?.userType === 'admin' &&
					<IconButton onClick={() => setOpen(true)}>
						<EditSharp />
					</IconButton>
				}
			</Stack>

			{organisation && (
				<Stack direction={"column"} spacing={4}>
					
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Website
								<br />{" "}
								<font style={styleFont}>
								<a
											href=	
											{!organisation?.website || organisation?.website === '' ? 'javascript:;' : organisation?.website }
											>
												{!organisation?.website || organisation?.website === '' ? 'N/A' : organisation?.website }
											</a>
								</font>
							</Typography>
							<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>
					
					
					<Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Service type<br/>
				  {profile?.serviceProvider?.serviceType.map((element,index)=>(
					<font style={styleFont}>{element} </font>
				  )

				  ) }
				</Typography>
				<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
							
							
							<Stack direction={'column'} spacing={1}>
								<Typography variant={'h6'} sx={{lineHeight:2}}>
								Sector<br/>
								{profile?.serviceProvider?.sector.map((element,index)=>(
									<font style={styleFont}>{element} </font>
								)

								) }
								</Typography>	
			  </Stack>
							

				</Stack>
			)}
			<AboutServiceProviderOrganisationComponent profile={profile} open={open} handleClose={() => setOpen(false)} callback={(d) => {
				callback(d)
				setOpen(false)
			}} />
		</>
	);
}
