import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Typography,
  Chip,
} from "@mui/material";
import moment from "moment";
import { AuthUserContext } from "../../../providers/auth-provider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { USERS_NAME_MAPPING } from "../../../utils/enums/labels.enum";

export default function RequestDetailsAdmin() {
  const { profile } = React.useContext(AuthUserContext);
  const location = useLocation();
  const navigator = useNavigate();
  const { request, members } = location?.state;
  function currencyToSymbol(currency) {
    if (currency == "USD") {
      return "$";
    } else if (currency == "GBP") {
      return "£";
    } else {
      return "€";
    }
  }

  return (
    <Container maxWidth={"lg"} sx={{ py: 5 }}>
      <Stack
        sx={{ flex: 0.7, marginBottom: "12px" }}
        direction={"row"}
        spacing={2}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
      >
        <Button
          variant={"outlined"}
          onClick={() =>
            navigator(`/${profile.userType}/request/matches/list`, {
              state: {request, members},
            })
          }
        >
          Best Matches
        </Button>
        <Button
          color={"primary"}
          variant={"contained"}
          onClick={() =>
            navigator(`/${profile.userType}/request/applicants/list`, {
              state: {request, members},
            })
          }
        >
          View Applicants
        </Button>
      </Stack>
      {request && (
        <Stack direction={"row"}>
          <Box
            sx={{
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              flex: 1,
              top: 5,
              background: "#fff",
              borderRadius: "15px",
            }}
          >
            <Stack direction={"column"} sx={{ flex: 0.7 }}>
              <Stack
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  minHeight: 80,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
                direction={"row"}
                spacing={4}
              >
                <ArrowBackIcon
                  onClick={() => navigator(-1)}
                  sx={{ fontSize: 40 }}
                />
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Typography variant={"h6"}>{request.requestFor}</Typography>
                  <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                    Posted{" "}
                    {moment()
                      .from(request?.createdAt.seconds * 1000)
                      .slice(2)}{" "}
                    ago
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 140,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Typography
                  variant={"h6"}
                  color={"primary"}
                  sx={{ fontWeight: "500" }}
                >
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Round"
                    : "Searching For"}
                </Typography>
                <Box container>
                  {!(
                    request?.requestFor === "Looking for funding" ||
                    request.type === "looking-for-funding"
                  ) ? (
                    request?.searchFor &&
                    request?.searchFor.map((t) => (
                      <Chip
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginRight: "8px",
                          marginBottom: "10px",
                          maxWidth: 250,
                          borderRadius: "10px",
                          fontWeight: "500",
                        }}
                        component={"p"}
                        label={USERS_NAME_MAPPING[t]}
                      />
                    ))
                  ) : (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginRight: "5px",
                        marginBottom: "10px",
                        maxWidth: 250,
                      }}
                      component={"p"}
                      label={request?.round}
                    />
                  )}
                </Box>
              </Stack>
              {/* Stack for location, job type, due date */}
              <Stack
                sx={{
                  minHeight: 70,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {request?.location}
                </Typography>
                <span />

                <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                  Job Type
                </Typography>

                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {request?.jobType}
                </Typography>

                <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                  Due Date:
                </Typography>

                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {moment(request?.deadline).format("D MMM YYYY")}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  minHeight: 150,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography
                  variant={"body2"}
                  textAlign={"justify"}
                  sx={{ color: "#363636" }}
                >
                  {request.description}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  minHeight: 70,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <img src={"/assets/budget-req.png"} />
                <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Amount to raise"
                    : "Budget"}{" "}
                </Typography>
                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? `${request?.currency} ${request?.amount}`
                    : `${currencyToSymbol(request?.currency)} ${
                        request?.minBudget
                      } - ${currencyToSymbol(request?.currency)} ${
                        request?.maxBudget
                      }`}
                </Typography>
                <span />
                {request?.requestFor === "Looking for funding" ||
                  (request.type === "looking-for-funding" && (
                    <>
                      {" "}
                      <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                        Valuation
                      </Typography>
                      <Typography variant={"body2"} sx={{ color: "#363636" }}>
                        {request?.valuationCurrency} ${request?.valuation}
                      </Typography>
                      <span />
                    </>
                  ))}

                <img src={"/assets/timeline-cal.png"} />
                <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Close Date"
                    : "Timeline"}
                </Typography>

                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? moment(request.fundingCloseDate).format("MMM D, YYYY")
                    : `${moment(request.from).format("MMM D, YYYY")} - ${moment(
                        request.to
                      ).format("MMM D, YYYY")}`}
                </Typography>
              </Stack>
              <Stack
                sx={{ minHeight: 150, width: "100%", p: 1, px: 3 }}
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                <Typography variant={"h6"} sx={{ fontWeight: "500" }}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Revenue Model"
                    : "Skills and expertise"}{" "}
                </Typography>
                <Box container>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding" ? (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginRight: "5px",
                        marginBottom: "10px",
                        maxWidth: 250,
                      }}
                      component={"p"}
                      label={request?.revenueModel}
                    />
                  ) : (
                    request?.skills &&
                    request?.skills.map((t) => (
                      <Chip
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginRight: "8px",
                          marginBottom: "10px",
                          maxWidth: 250,
                          borderRadius: "10px",
                          fontWeight: "500",
                        }}
                        component={"p"}
                        label={t}
                      />
                    ))
                  )}
                </Box>
              </Stack>

              <Stack
                sx={{ minHeight: 150, width: "100%", p: 1, px: 3 }}
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                <Typography variant={"h6"} sx={{ fontWeight: "500" }}>
                  Languages
                </Typography>
                <Box container>
                  {request?.languages &&
                    request?.languages?.map((t) => (
                      <Chip
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginRight: "8px",
                          marginBottom: "10px",
                          maxWidth: 250,
                          borderRadius: "10px",
                          fontWeight: "500",
                        }}
                        component={"p"}
                        label={t}
                      />
                    ))}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      )}
    </Container>
  );
}
