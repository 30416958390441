import * as firebase  from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import firebaseConfig from './firebase.config';

const provider = new GoogleAuthProvider();

const app = firebase.initializeApp(firebaseConfig)

export const auth = getAuth(app);

export const DB = getFirestore(app);

export const storage = getStorage(app);

export const loginWithEmail = (email, password) =>
  signInWithEmailAndPassword(auth, email, password);

export const registerWithEmail = (email, password) =>
  createUserWithEmailAndPassword(auth, email, password);

export const loginWithGoogle = () => signInWithPopup(auth, new GoogleAuthProvider())

export const logout = () => signOut(auth);

export const loginWithLinkedin = (token) => signInWithCustomToken(auth, token);

export default firebase;
