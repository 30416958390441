import React from "react";
import {
  Container,
  Stack,
  Typography,
  IconButton, Backdrop, CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import {addDoc, collection, deleteDoc, doc, getDoc, serverTimestamp, setDoc} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import ExpertFirstForm from "./../../expert/onboarding-forms/expert-first-form";
import ExpertSecondForm from "./../../expert/onboarding-forms/expert-second-form";
import InvestorPortfolioForm from "./../../investor/onboarding-forms/portfolio-form";
import Expectations from "./../../expert/onboarding-forms/expectations";
import InvestorDetails from "./../../expert/onboarding-forms/investor-details";
import PortfoliosListView from "./../../investor/onboarding-forms/portfolios-list-view";
import InvestorThirdForm from "./../../investor/onboarding-forms/investor-third-form";
import InvestorFourForm from "./../../investor/onboarding-forms/investor-four-form";
import { EXPERT } from "../../../utils/enums/labels.enum";
import InvestorCard from "./investor-card";
import PostProfileSuccessDialog from "./post-profile-success-dialog";
import ExpertCard from "./expert-card";
import {getIdToken} from "firebase/auth";
import AdminPostOnboardProfileData from "../../../api/admin-post-onboard-profile-data";
import SaveAsDraft from "../../../api/save-as-draft";

export default function FillExpertProfile() {
  const {state} = useLocation()
  const {uid} = useParams()
  
  const { user } = React.useContext(AuthUserContext);
  const selectedUser = useLocation();
  const userType = selectedUser.state.type
  console.log(userType)
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [formFirstData, setFormFirstData] = React.useState();
  const [formSecondData, setFormSecondData] = React.useState();

  const [formFiveData, setFormFiveData] = React.useState();
  const [formThirdData, setFormThirdData] = React.useState();
  const [formFourData, setFormFourData] = React.useState();
  
  
  const [formSixData, setFormSixData] = React.useState();
  
  
  const [steps, setSteps] = React.useState(0);
  

  
  const navigator = useNavigate();
  const submitData = async (values) => {
    try {
      setLoading(true)
      const copyValue = {...values, investorDetails: {...values?.investorDetails,portfolio: []}}
      const {linkedinURL} = state.contactData
      const expert = {
        ...copyValue,
        experiences: [],
        photoURL: values?.photoURL
          ? values.photoURL
          : "",
        linkedinURL
      };
  
      const postData = {
        createdById:user?.uid,
        createdBy:'admin',
        type: state?.type,
        ...state.contactData,
        photoURL:values?.photoURL
          ? values.photoURL
          : "",
        expert,
        onboard: true,
        profileStatus: 'complete',
        remainderStatus:'',
        searchName: state.contactData?.displayName.toLowerCase()
    
      }
      const idToken = await getIdToken(user);
      await AdminPostOnboardProfileData(postData, idToken)
      setLoading(false)
      setOpen(true)
      // const userRef = doc(DB, "users", user.uid);
    } catch (e) {
    
    }
    
  }
  
  return (
    <>
      <Container maxWidth={"xl"} sx={{ marginY: 5 }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}>
          <IconButton
            onClick={() =>
              steps === 0 ? navigator(uid ? `/admin/on-boarding/contact-info/${uid}`:'/admin/on-boarding/contact-info', {state}) : setSteps(steps - 1)
            }>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography
            variant={"h5"}
            sx={{ textAlign: "left", lineHeight: 1 }}>
            {steps !== 6 ? EXPERT.NAME : 'Final step'}
            <br />{" "}
            <small style={{ fontSize: 14, color: "#8F8F8F" }}>
              {steps === 0 && EXPERT.EXPERT_PROFILE}
              {steps === 1 && EXPERT.EXPERTISE}
              {/* {steps === 2 && EXPERT.EXPERIENCE}
							{steps === 3 && EXPERT.ADD_EXPERIENCE} */}
              {steps === 2 && EXPERT.EXPECTATIONS}
              {steps > 2 && steps < 6 && EXPERT.INVESTOR_DETAILS}
              {steps === 6 && 'send profile details via email'}
            </small>
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={"lg"} sx={{ mb: 5 }}>
        {steps === 0 && (
          <ExpertFirstForm
            uid={uid}
            data={formFirstData}
            cbBack={() => navigator(uid ? `/admin/on-boarding/contact-info/${uid}`:'/admin/on-boarding/contact-info', {state})}
            cbNext={(data) => {
              setFormFirstData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData: data}, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            } }
          />)
          
          
        }
        {steps === 1 && (
          <ExpertSecondForm
            data={formSecondData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              debugger
              setFormSecondData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData, formSecondData: data}, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData, formSecondData: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            } }
            uid={uid}
          />)
          
          
        }
      
        {
          steps === 2 && (
            <Expectations
                uid={uid}
              data={formFiveData}
              cbBack={() => setSteps(steps - 1)}
              cbNext={(data) => {
                setFormFiveData({ ...data });
                setSteps(steps + 1);
                 uid && SaveAsDraft({...state,formFirstData,  formSecondData, expectation: data}, uid, user)
                  .then()
              }}
               cbDraft={(data) => {
                SaveAsDraft({...state,formFirstData,  formSecondData, expectation: data}, uid, user)
                  .then(() => navigator('/admin/draft-profiles'))
  
              }}
            />)
        }
        {
          steps === 3 && (
            <InvestorDetails
              uid={uid}
              data={formSixData}
              cbBack={() => setSteps(steps - 1)}
              cbNext={(data) => {
                setFormSixData({ ...data });
                if (data?.areYouAnAngelInvestor === true) {
                  setSteps(steps + 1);
                } else {
                  setSteps(steps + 3)
                 /* submitData({
                    investorDetails: {
                      ...formSixData,
                    },
                    ...formFirstData,
                    ...formSecondData,
                    ...formFiveData,
                  }).then()*/
                }
                uid && SaveAsDraft({...state,formFirstData,  formSecondData, expectation: formFiveData,  investorFormOne: data}, uid, user)
                  .then()
                
              }}
              cbDraft={(data) => {
                SaveAsDraft({...state,formFirstData,  formSecondData, expectation:formFiveData, investorFormOne: data}, uid, user)
                  .then(() => navigator('/admin/draft-profiles'))
  
              }}
            />)
        }
        {steps === 4 && (
          
          <InvestorThirdForm
            uid={uid}
            data={formThirdData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormThirdData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData,  formSecondData, expectation: formFiveData,
                investorFormOne: formSixData,investorFormTwo : data
              }, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData,  formSecondData, expectation:formFiveData,
                investorFormOne: formSixData,investorFormTwo : data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />
        )}
        {steps === 5 && (
          
          <InvestorFourForm
            uid={uid}
            data={formFourData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              debugger
              setFormFourData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData,  formSecondData, expectation: formFiveData,
                investorFormOne: formSixData,investorFormTwo : formThirdData, investorFormThree: data
              }, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData,  formSecondData, expectation: formFiveData,
                investorFormOne: formSixData,investorFormTwo : formThirdData, investorFormThree: data
              }, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />
        )}
  
  
        {steps === 6  &&
          <ExpertCard
            data={{
              ...formSixData,
              ...formThirdData,
              ...formFourData,
              ...formFirstData,
              ...formSecondData,
              ...formFiveData}}
            cbBack={() => formSixData?.areYouAnAngelInvestor === true ? setSteps(steps - 1) : setSteps(steps - 3)}
            send={() => {
              uid && deleteDoc(doc(DB, 'users', uid)).then();
              submitData({
                investorDetails: {
                  ...formSixData,
                  ...formThirdData,
                  ...formFourData,
                },
                ...formFirstData,
                ...formSecondData,
                ...formFiveData,
              }).then()
            }}
          />}
  
        {
          open && <PostProfileSuccessDialog open={open} setOpen={(bool) => {
      
            setOpen(bool)
            navigator('/admin/dashboard')
          }} />
        }
        {
          loading &&
          <Backdrop  open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        
        
        {/*
        setSteps(formSixData?.areYouAnAngelInvestor ? steps-1 :steps -3)}
        
        */}
       {/* {
          steps === 6 && (<PortfoliosListView
              cbBack={() => setSteps(steps - 1)}
              add={(data) => {
                setPortfolioIndex();
                setSteps(steps + 1);
              }}
              edit={(ind) => {
                setPortfolioIndex(ind);
                setSteps(steps + 1);
              }}
              remove={(ind) => {
                debugger
                const port = [...portfolios]
                port.splice(ind, 1)
                setPortfolios([...port])
              }}
              portfolios={portfolios}
              submit={() => {
                debugger
                submitData({
                  investorDetails: {
                    ...formSixData,
                    ...formThirdData,
                    ...formFourData,
                  },
                  ...formFirstData,
                  ...formSecondData,
                  ...formFiveData,
                }).then()
                console.log('submit')
              }}
            />
          )
        }
        
        {steps === 7 && (
          <InvestorPortfolioForm
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data, index) => {
              const port = [...portfolios]
              if (index !== undefined && index > -1) {
                port.splice(index, 1, { ...data})
              } else {
                port.push({ ...data })
              }
              setPortfolios([...port])
              setSteps(steps - 1);
              setPortfolioIndex(undefined);
              
            }}
            data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}
            
            index={portfolioIndex}
          />)
          
          
        }*/}
      
      </Container>
    </>
  );
}
