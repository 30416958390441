import { Skeleton, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, {useEffect, useState} from 'react'
import getMetaDatafromURL from "../../api/get-metadata";


export default function LinkWithThumbnail({data, limit, small}) {

    let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let url
    const [thumbnail, setThumbnail] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(true)
    const text = data?.text ? data?.text : data
    if(typeof text === "string"){
      text.replace(urlRegex, function (link) {
      let hyperlink = link;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      url = link;
    })}
    


    useEffect(()=>{
      setLoading(true)
      if(url){

        getMetaDatafromURL({url})
        .then((metadata)=>{
          setLoading(false)
          setDescription(metadata?.data?.['description'])
          setThumbnail(metadata?.data?.['og:image'])
          setTitle(metadata?.data?.title)
          
        })
        .catch((error)=>{
          setLoading(false)
          console.log(error);
        })
      }
      }, [url/*, title, thumbnail, description*/])
      
   
   
    const extractSiteURL = (link) =>{
      try {
        const url = new URL(link);
        const hostname = url.hostname;
        const wwwIndex = hostname.indexOf('www.');
        const siteUrl = wwwIndex !== -1 ? hostname.slice(wwwIndex + 4) : hostname;
        return siteUrl;
        // return url.origin;
      } catch (error) {
        console.error('Error extracting site URL:', error);
        return null;
      }
    };

   return (
      <>
      {url &&
      <a style={{textDecoration:"none", color:"#232323"}}  href={url} target="_blank" rel="noopener noreferrer">
        <div>
        <Stack direction={'column'} sx={{borderBottom:"0.5px solid #d8d8d8", cursor:"pointer"}} >
            {thumbnail && 
              <img src={thumbnail} style={{width: '100%', maxHeight: 500, objectFit:'cover'}} alt="Thumbnail" />
            }
          {/* {loading && thumbnail==='' && <Skeleton animation={'wave'} variant={'rectangular'} sx={{width: '100%', minHeight: small? 200 : 500}}/>} */}
            <Stack sx={{px:5, py:2, background:"#f2f5fb"}}>
              <Typography variant="caption" textTransform={"uppercase"}>{extractSiteURL(url)}</Typography>
                {loading && title === '' &&
                <>
                   <Skeleton variant={'text'} animation={'wave'}/>
                   <Skeleton variant={'text'} animation={'wave'}/>
               </>
                 }
                 
                {title && 
                <Typography  sx={{ fontWeight:500, fontSize:small ? 12 : 18}} variant={'h6'} >{title}</Typography> }
                {description && <Typography variant="caption" sx={{fontSize:small && 10}}>{description.substring(0,limit)}...</Typography>}
            </Stack>
           

        </Stack>
        
      </div>
      </a>
    }
    </>
    );

}

