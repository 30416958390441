import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Typography,
  Grid,
  Chip,
} from "@mui/material";
import moment from "moment";
import { AuthUserContext } from "../../../providers/auth-provider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { fontWeight } from "@mui/system";
import { DB } from "../../../config/firebase/firebase";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import RequestMatches from "../components/common/request-matches";
import RequestMatchesProfiles from "../../../api/request-matches-profiles";
import { USERS_NAME_MAPPING } from "../../../utils/enums/labels.enum";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import IconButton from "@mui/material/IconButton";
import { SUGGESTED_STATUS } from "../../../utils/enums/suggestions-state";
import PostNotification from "../../../api/post-notification";
import AdminSuggestedMatches from "../components/common/admin-suggested";
import GetAdminSuuggestedProfiles from "../../../api/get-admin-suggested-profiles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ListItemStartConversation from "../components/common/list-item-start-conversation";
import ListItemStartConversationC from "../components/common/list-item-start-conversation copy";
import usePersistentState from "../../../api/persistant-state";

export default function RequestDetails() {
  const { profile } = React.useContext(AuthUserContext);
  const [matches, setMatches] = React.useState(null);
  const [request, setRequest] = usePersistentState("requestData", null);
  const [suggested, setSuggested] = usePersistentState("suggestedData", null);
  const [suggestedProfiles, setSuggestedProfiles] = React.useState();
  const location = useLocation();
  const navigator = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const suggestedUserId = searchParams.get("suggestedUserId");
const valuewidth=175
  const req = location?.state?.request;
  const { requestId } = useParams();

  const getUniqueAndSortedMatches = (matches, sortByKey) => {
    const uniqueMatches = [];
    const sortedMatches = [];

    // Remove duplicates based on a specific key
    matches.forEach((match) => {
      const existingMatch = uniqueMatches.find(
        (item) => item.email === match.email
      );
      if (!existingMatch) {
        uniqueMatches.push(match);
      }
    });

    // Sort the array by the key (if it exists in the objects)
    if (sortByKey) {
      sortedMatches.push(
        ...uniqueMatches.filter((item) => item.hasOwnProperty(sortByKey))
      );
      const unsortedMatches = uniqueMatches.filter(
        (item) => !item.hasOwnProperty(sortByKey)
      );
      sortedMatches.push(
        ...unsortedMatches.sort((a, b) => {
          if (a[sortByKey] < b[sortByKey]) {
            return -1;
          } else if (a[sortByKey] > b[sortByKey]) {
            return 1;
          }
          return 0;
        })
      );
    } else {
      sortedMatches.push(...uniqueMatches);
    }

    return sortedMatches;
  };

  const handleReload = (status) => {
    if (status == SUGGESTED_STATUS.DECLINED)
      navigator(`/m/${profile.userType}/open-requests`);
    else window.location.reload();
  };
  const upateSuggestStatus = (reqId, userId, status) => {
    console.log(reqId, userId, status);
    const colRef = doc(DB, "help-requests", reqId, "users-suggested", userId);
    setDoc(
      colRef,
      {
        status: SUGGESTED_STATUS[status],
        updatedAt: serverTimestamp(),
      },
      { merge: true }
    ).then(async () => {
      const colRef = doc(DB, "users", userId, "requests-suggested", reqId);
      setDoc(
        colRef,
        {
          status: SUGGESTED_STATUS[status],
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      ).then(() => {
        if (status == SUGGESTED_STATUS.ACCEPTED) {
          PostNotification({
            type: "accept-suggest-request",
            imageURL: "/assets/system-noti-icon.png",
            message: `An admin has proposed a candidate for your job request.`,
            uid: request?.creatorData?.uid,
            suggestedUserId: userId,
            requestFor: request?.requestFor,
            request: reqId,
          }).then();
        }
        handleReload(status);
      });
    });
  };
  React.useEffect(() => {
    console.log(location?.state?.request);
    if (req) setRequest(req);
    if (!request && requestId) {
      const reqRef = doc(DB, "help-requests", requestId);
      getDoc(reqRef).then((doc) => {
        setRequest({ id: requestId, ...doc.data() });
      });
    }
  }, [requestId]);
  React.useEffect(() => {
    console.log(location?.state?.request);
    if (requestId && request?.creatorData?.uid !== profile?.uid) {
      let unsubs = getDoc(
        doc(DB, "help-requests", requestId, "users-suggested", profile?.uid)
      ).then((getdoc) => {
        if (getdoc.exists()) {
          setSuggested({ id: profile?.uid, ...getdoc.data() });
        }
      });
      return () => unsubs;
    }
  }, [requestId]);
  React.useEffect(() => {
    console.log(location?.state?.request);
    if (request?.creatorData?.uid === profile?.uid) {
      const fetchProfileMatchesData = async () => {
        const data = await RequestMatchesProfiles(request);
        setMatches(data);
      };
      fetchProfileMatchesData().catch(console.error);
    }
  }, [request]);

  React.useEffect(() => {
    console.log(location?.state?.request);
    if (request?.creatorData?.uid === profile?.uid && request?.id) {
      const fetchProfileMatchesData = async () => {
        const data = await GetAdminSuuggestedProfiles(request?.id);
        setSuggestedProfiles(data);
      };
      fetchProfileMatchesData().catch(console.error);
    }
  }, [request]);
  function currencyToSymbol(currency) {
    if (currency == "USD") {
      return "$";
    } else if (currency == "GBP") {
      return "£";
    } else {
      return "€";
    }
  }

  return (
    <Container maxWidth={"lg"} sx={{ py: 0 }}>
      {suggested && suggested.status == SUGGESTED_STATUS.PENDING && (
        <Stack py={2} direction="row" spacing={0} sx={{ flex: 1 }}>
          <Box
            sx={{
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              background: "#fff",
              borderRadius: "15px",
              height: "70px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            <Typography style={{ fontSize: "12px" }} fontWeight={"500"}>
              Do you want to accept this request suggested by the Admin?
            </Typography>

            <IconButton
              onClick={() =>
                upateSuggestStatus(
                  requestId,
                  profile?.uid,
                  SUGGESTED_STATUS.DECLINED
                )
              }
            >
              <HighlightOffRoundedIcon fontSize={"medium"} color="primary" />
            </IconButton>
            <IconButton
              onClick={() =>
                upateSuggestStatus(
                  requestId,
                  profile?.uid,
                  SUGGESTED_STATUS.ACCEPTED
                )
              }
            >
              <CheckCircleRoundedIcon fontSize={"medium"} color="primary" />
            </IconButton>
          </Box>
        </Stack>
      )}

      {request && (
        <Stack direction={"column"}>
          <Stack
            direction="row"
            alignItems={"center"}
            spacing={3}
            style={{ marginBottom: 25 }}
          >
            <ArrowBackIcon
              onClick={() => navigator(-1)}
              sx={{ fontSize: 18 }}
            />
            <Typography style={{ fontSize: 18, fontWeight: "600" }}>
              Requests details
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack alignItems="flex-start" justifyContent="space-between">
              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {request.requestFor}
              </Typography>
            </Stack>
            <Stack alignItems="flex-end" justifyContent="flex-end">
              {request.uid !== profile?.uid && (
                <ListItemStartConversationC
                  data={{
                    ...request.creatorData,
                    creatorData: request.creatorData,
                  }}
                />
              )}
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent={"flex-start"}>
            <Typography sx={{ fontSize: 12, color: "#8F8F8F" }}>
              Posted{" "}
              {moment()
                .from(request?.createdAt.seconds * 1000)
                .slice(2)}{" "}
              ago
            </Typography>
          </Stack>
          <Box
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
              marginTop: "2%",
              paddingBottom: ".2%",
            }}
          >
            <Stack direction="row">
              <Typography style={{ fontSize: 12, marginBottom: "2%" }}>
                {request?.location
                  ? request?.location
                  : request?.creatorData?.startup &&
                    request?.creatorData?.startup.location}
              </Typography>
            </Stack>
            <Stack direction="row">
              {request?.requestFor === "Looking for funding" ||
              request.type === "looking-for-funding" ? (
                <>
                  <Stack direction={"row"} spacing={3} sx={{ width: "100%" }}>
                    <Typography
                      variant={"body2"}
                      fontWeight={500}
                      sx={{ flex: 1, textAlign: "left" }}
                    >
                      Female in founding team
                    </Typography>
                    <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                      {request?.femaleInFoundingTeam ? "Yes" : "No"}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
                    <Typography
                      variant={"body2"}
                      fontWeight={500}
                      sx={{ flex: 1, textAlign: "left" }}
                    >
                      Ethnic Minority in founding team
                    </Typography>
                    <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                      {request?.ethnicMinorityInFundingTeam ? "Yes" : "No"}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
                    <Typography
                      variant={"body2"}
                      fontWeight={500}
                      sx={{ flex: 1, textAlign: "left" }}
                    >
                      Immigrant in founding team
                    </Typography>
                    <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                      {request?.immigrantInFundingTeam ? "Yes" : "No"}
                    </Typography>
                  </Stack>
                </>
              ) : (
                <>
                  <Typography style={{ marginBottom: "2%", fontSize: 12 }}>
                    <b>Job type : </b>
                    {request?.jobType}
                  </Typography>
                </>
              )}
            </Stack>
          </Box>
          <Box
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.2)",

              marginTop: "5%",
              paddingBottom: "5%",
            }}
          >
            <Stack direction="row">
              <Typography fontSize={16} color={"primary"}>
                {request?.requestFor === "Looking for funding" ||
                request.type === "looking-for-funding"
                  ? "Round"
                  : "Searching For"}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Box container>
                {!(
                  request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                ) ? (
                  request?.searchFor &&
                  request?.searchFor.map((t) => (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginRight: "5px",
                        borderRadius: "6px",
                        fontWeight: 500,
                        marginBottom: "10px",
                        maxWidth: 250,
                      }}
                      component={"p"}
                      label={USERS_NAME_MAPPING[t]}
                    />
                  ))
                ) : (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor: "#E0E3FF",
                      marginRight: "5px",
                      marginBottom: "10px",
                      maxWidth: 250,
                    }}
                    component={"p"}
                    label={request?.round}
                  />
                )}
              </Box>
            </Stack>
          </Box>
          <Box
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.2)",

              marginTop: "2%",
              paddingBottom: "7%",
              paddingTop: "7%",
            }}
          >
            <Stack direction="row">
              <Typography
                fontSize={12}
                textAlign={"justify"}
                sx={{ color: "#363636" }}
              >
                {request.description}
              </Typography>
            </Stack>
          </Box>
          <Box
            style={{
              marginTop: "2%",
              paddingBottom: "2%",
              paddingTop: "3%",
            }}
          >
            <Stack direction="row" spacing={1} style={{ marginBottom: "5px" }}>
              <img
                src={"/assets/budget-req.png"}
                style={{ width: "20px", height: "20px" }}
              />
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ color: "#8F8F8F" }}
              >
                {request?.requestFor === "Looking for funding" ||
                request.type === "looking-for-funding"
                  ? "Amount to raise"
                  : "Budget"}{" "}
              </Typography>
              <Box flex={0.5} />
              <Typography variant={"body2"} sx={{ color: "#363636" }}>
                {request?.requestFor === "Looking for funding" ||
                request.type === "looking-for-funding"
                  ? `${request?.currency} ${request?.amount}`
                  : `${currencyToSymbol(request?.currency)} ${
                      request?.minBudget
                    } - ${currencyToSymbol(request?.currency)} ${
                      request?.maxBudget
                    }`}
              </Typography>

              <span />
              {request?.requestFor === "Looking for funding" ||
                (request.type === "looking-for-funding" && (
                  <>
                    {" "}
                    <Typography variant={"body1"} sx={{ color: "#8F8F8F" }}>
                      Valuation
                    </Typography>
                    <Typography variant={"body1"} sx={{ color: "#363636" }}>
                      {request?.valuationCurrency} ${request?.valuation}
                    </Typography>
                    <span />
                  </>
                ))}
            </Stack>
          </Box>
          <Box
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.2)",

              marginTop: "2%",
              paddingBottom: "2%",
              paddingTop: "3%",
            }}
          >
            <Stack direction={"row"} spacing={1}>
              <img
                src={"/assets/timeline-cal.png"}
                style={{ width: "20px", height: "20px" }}
              />
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ color: "#8F8F8F" }}
              >
                {request?.requestFor === "Looking for funding" ||
                request.type === "looking-for-funding"
                  ? "Close Date"
                  : "Timeline"}
              </Typography>
              <Box flex={0.5} />
              <Typography variant={"body2"} sx={{ color: "#363636" }}>
                {request?.requestFor === "Looking for funding" ||
                request.type === "looking-for-funding"
                  ? moment(request.fundingCloseDate).format("MMM D, YYYY")
                  : `${moment(request.from).format("MMM D, YYYY")} - ${moment(
                      request.to
                    ).format("MMM D, YYYY")}`}
              </Typography>
            </Stack>
          </Box>
          <Box
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
              marginTop: "2%",
              paddingBottom: "2%",
              paddingTop: "2%",
            }}
          >
            <Stack direction={"row"} spacing={1}>
              <Typography fontSize={14} sx={{ fontWeight: "600" }}>
                {request?.requestFor === "Looking for funding" ||
                request.type === "looking-for-funding"
                  ? "Revenue Model"
                  : "Skills and expertise"}{" "}
              </Typography>
            </Stack>

            <Stack direction="row">
              <Box container>
                {request?.requestFor === "Looking for funding" ||
                request.type === "looking-for-funding" ? (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor: "#E0E3FF",
                      marginRight: "5px",
                      marginBottom: "10px",
                      maxWidth: 250,
                    }}
                    component={"p"}
                    label={request?.revenueModel}
                  />
                ) : (
                  request?.skills &&
                  request?.skills.map((t) => (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginRight: "8px",
                        marginBottom: "10px",
                        maxWidth: 250,
                        borderRadius: "6px",
                        fontWeight: "500",
                      }}
                      component={"p"}
                      label={t}
                    />
                  ))
                )}
              </Box>
            </Stack>
          </Box>
          <Box
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.2)",

              marginTop: "2%",
              paddingBottom: "2%",
              paddingTop: "2%",
            }}
          >
            <Stack direction={"row"} spacing={1}>
              <Typography fontSize={14} sx={{ fontWeight: "600" }}>
                Languages
              </Typography>
            </Stack>
            <Stack direction="row">
              <Box container>
                {request?.languages &&
                  request?.languages.map((t) => (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginRight: "8px",
                        borderRadius: "6px",
                        marginBottom: "10px",
                        maxWidth: 250,
                        fontWeight: "500",
                      }}
                      component={"p"}
                      label={t}
                    />
                  ))}
              </Box>
            </Stack>
          </Box>

          {suggestedProfiles && suggestedProfiles.length > 0 && (
            <>
              <Box
                style={{
                  marginTop: "2%",
                  paddingBottom: "2%",
                  paddingTop: "2%",
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={14} sx={{ fontWeight: "600" }}>
                    Suggested By Admin
                  </Typography>
                </Stack>
              </Box>
              <Box
                style={{
                  marginTop: "2%",
                  paddingBottom: "2%",
                  paddingTop: "2%",
                  overflowY: "hidden",
                }}
              >
                <Stack direction={"row"} spacing={1.5}>
                  {suggestedProfiles && suggestedProfiles.length > 0 && (
                    <AdminSuggestedMatches
                      matches={matches ? matches : []}
                      moreMatches={suggestedProfiles ? suggestedProfiles : []}
                      suggestedUserId={suggestedUserId}
                      itemWidth={175}
                    />
                  )}
                </Stack>
              </Box>
              <Button
                color={"secondary"}
                size={"small"}
                fullWidth
                sx={{ justifyContent: "right", px: 1.8, fontSize: 12 }}
                onClick={() => {
                  navigator(`/m/${profile?.userType}/request-matches-list`, {
                    state: {
                    
                      adminSuggested: suggestedProfiles,
                    },
                  });
                  window.scrollTo(0, 0);
                }}
              >
                View all Suggestions{" "}
                <ArrowForwardIcon sx={{ fontSize: 16, ml: 1 }} />
              </Button>
              <Box
                style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
              ></Box>
            </>
          )}

          {matches && matches.length > 0 && (
            <>
              <Box
                style={{
                  marginTop: "2%",
                  paddingBottom: "2%",
                  paddingTop: "2%",
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={14} sx={{ fontWeight: "600" }}>
                    Best Matches
                  </Typography>
                </Stack>
              </Box>
              <Box
                style={{
                  marginTop: "2%",
                  paddingBottom: "2%",
                  paddingTop: "2%",
                  overflowY: "hidden",
                }}
              >
                <Stack direction={"row"} spacing={1.5}>
                  {matches && matches.length > 0 && (
                    <RequestMatches
                      matches={matches ? matches : []}
                      moreMatches={suggestedProfiles ? suggestedProfiles : []}
                      itemWidth={175}
                    />
                  )}
                </Stack>
              </Box>
              <Button
                color={"secondary"}
                size={"small"}
                fullWidth
                sx={{ justifyContent: "right", px: 1.8, fontSize: 12 }}
                onClick={() => {
                  navigator(`/m/${profile?.userType}/request-matches-list`, {
                    state: { members: matches },
                  });
                  window.scrollTo(0, 0);
                }}
              >
                View all matches{" "}
                <ArrowForwardIcon sx={{ fontSize: 16, ml: 1 }} />
              </Button>
              <Box
                style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
              ></Box>
            </>
          )}

          <Stack style={{ marginBottom: "50px", marginTop: "20px" }}>
            {request?.creatorData?.uid === profile?.uid && (
              <Stack
                direction={"column"}
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  size={"small"}
                  variant={"outlined"}
                  sx={{ width: 280, backgroundColor: "#fff" }}
                  onClick={() =>
                    navigator(
                      `/m/${profile.userType}/open-requests-update/${request.id}`
                    )
                  }
                >
                  Edit
                </Button>
                <Button
                  size={"small"}
                  variant={"contained"}
                  sx={{ width: 280 }}
                  onClick={() =>
                    navigator(`/m/${profile.userType}/applicants/${request.id}`)
                  }
                >
                  View applicants
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </Container>
  );
}
