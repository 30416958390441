import * as React from "react";
import {
  Stack,
  Typography,
  Box,
  Avatar,
  Button,
  Portal,
  List,
  ListItem,
  ListItemText,
  TextField,
  styled,
  CircularProgress,
  Chip,
  CardMedia,
} from "@mui/material";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { AuthUserContext } from "../../../providers/auth-provider";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import StartPost from "../../mobile/components/common/timeline/start-post";
import LeftPanel from "../../mobile/components/common/timeline/left-panel";
import PostItem from "../../../components/common/timeline/post-item";
// import PostItem from "../components/common/timeline/post-item";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@material-ui/core/Tooltip";
import UploadFile from "../../../utils/upload-file";
import {
  getDoc,
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
  limit,
  startAfter,
  getDocs,
  updateDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
//import Picker from 'emoji-picker-react';
import { DB } from "../../../config/firebase/firebase";
const Input = styled("input")({
  display: "none",
});
export default function TimelineScreen() {
  debugger;
  const { user, setRoomData, setRoomId } = React.useContext(AuthUserContext);
  const navigator = useNavigate();
  const [URL, setURL] = React.useState();
  const [updated, setUpdated] = React.useState();
  const [posted, setPosted] = React.useState(false);
  const [imageDimensions, setImageDimensions] = React.useState({});
  const [chosenEmoji, setChosenEmoji] = React.useState(null);
  const { profile } = React.useContext(AuthUserContext);
  const myprofile = { profile };
  const [state, setState] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [tag, setTag] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openSupport, setOpenSupport] = React.useState(false);
  const [text, setText] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [image, setImage] = React.useState("");
  const [video, setVideo] = React.useState("");
  const [file, setFile] = React.useState();
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [inputbtn, setinputbtn] = React.useState();
  const [lastDoc, setLastDoc] = React.useState();
  const [postData, setPostData] = React.useState();
  const [otherFiles, setOtherFiles] = React.useState("");
  const [showTooltip, setShowTooltip] = React.useState(false);

  const arr = ["Success! Your Post has been Uploaded."];
  const arr2 = ["Success! Your Post has been Edited."];
  const storage = getStorage();
  const handleIconButtonClick = () => {
    setShowTooltip(true);
  };
  const handleTooltipClose = () => {
    setShowTooltip(false);
  };
  const create = async () => {
    const getAdminSnaps = await getDocs(
      query(
        collection(DB, "users"),
        where("userType", "==", "admin"),
        orderBy("createdAt", "asc")
      )
    );
    debugger;
    const uid = getAdminSnaps?.docs[0].id;
    setLoading(true);
    const userType = "";
    try {
      const q = query(
        collection(DB, "rooms"),
        where("participates", "in", [
          [uid, profile?.uid],
          [profile?.uid, uid],
        ])
      );
      await setRoomId(null);
      await setRoomData(null);

      const getRoom = await getDocs(q);
      let room;
      if (getRoom.size > 0) {
        room = { id: getRoom.docs[0].id, ...getRoom.docs[0].data() };
      } else {
        const ro = await addDoc(collection(DB, "rooms"), {
          title: "Support",
          type: "support",
          subTitle: `${"Admin"}, ${profile?.displayName}`,
          participates: [uid, user.uid],
          uid: user?.uid,
          logo: "/assets/support-icon.png",
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
          lastMsg: {
            text: "Please go ahead and start conversation",
            status: "send",
          },
        });
        room = {
          id: ro?.id,
          title: "Support",
          type: "support",
          subTitle: `${"Admin"}, ${profile?.displayName}`,
          participates: [uid, user.uid],
          uid: user?.uid,
          logo: "/assets/support-icon.png",
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
          lastMsg: {
            text: "Please go ahead and start conversation",
            status: "send",
          },
        };
      }
      // await setRoomId(room.id)
      if (getRoom.size === 0) {
        let message = await addDoc(collection(DB, "messages"), {
          type: "system-generated",
          text: "Please go ahead and start conversation",
          sender: user.uid,
          receiver: uid,
          createdAt: serverTimestamp(),
          roomId: room?.id,
          read: false,
          status: "send",
        });
        const newRoom = await getDoc(doc(DB, "rooms", room?.id));
        setRoomData({ id: room?.id, ...newRoom?.data() });
      } else {
        setRoomData({ id: room?.id, ...getRoom?.docs[0]?.data(), ...room });
        await setDoc(
          doc(DB, "rooms", getRoom.docs[0].id),
          {
            ...room,
            title: "Support",
            type: "support",
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        );
      }
      setLoading(false);
      setOpenSupport(!openSupport);
      setRoomId(room?.id);
      navigator(`/m/${profile.userType}/chatscreen1`);
    } catch (e) {
      setLoading(false);
    }
  };
  const loadMore = async () => {
    setLoading(true);
    const q = query(
      collection(DB, "timelines"),
      orderBy("createdAt", "desc"),
      startAfter(lastDoc),
      limit(20)
    );
    const querySnap = await getDocs(q);
    if (querySnap.size > 0) {
      const list = [...posts];
      querySnap.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      // console.log(list);
      setPosts(list);
      querySnap.size < 20
        ? setLastDoc(undefined)
        : setLastDoc(querySnap.docs[querySnap.docs.length - 1]);
    } else {
      setLastDoc(undefined);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    console.log(profile);
    const q = query(
      collection(DB, "timelines"),
      orderBy("createdAt", "desc"),
      limit(30)
    );

    const unSubs = onSnapshot(q, (querySnap) => {
      // debugger
      if (querySnap.size > 0) {
        const list = [];
        querySnap.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        querySnap.size < 20
          ? setLastDoc(undefined)
          : setLastDoc(querySnap.docs[querySnap.docs.length - 1]);
        setPosts(list);
      } else {
        setLastDoc(undefined);
      }
    });
    return () => unSubs;
  }, []);
  const resetAll = async () => {
    debugger;
		await setImage("");
    await setVideo("");
    await setFile();
		await setURL();
    await setLoading(false);
    await setOtherFiles("");
    await setPostData();
    // window.document.getElementById('attachment-media').empty()
    // window.document.getElementById('attachment-video').empty()
    // window.document.getElementById('attachment-file').empty()
  };
  return (
    <React.Fragment>
      <Stack style={{ overflow: "hidden" }}>
        <Stack
          direction={"row"}
          spacing={3}
          sx={{ width: "90%", margin: "auto" }}
        >
          <Stack direction="column" style={{ width: "10%" }}>
            <Avatar
              sx={{ height: 50, width: 50 }}
              src={profile?.photoURL || profile?.startup?.founder?.photoURL}
            ></Avatar>
          </Stack>
          <Stack direction="column" style={{ width: "90%" }}>
            <Box
              onSubmit={(event) => event.preventDefault()}
              component="form"
              sx={{
                p: "2px 10px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 50,
                borderRadius: "12px",
                backgroundColor: "#E4EAF5",
              }}
            >
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={(e) => setSearch(inputbtn)}
              >
                <SearchIcon sx={{ color: "#051E40BF" }} />
              </IconButton>
              <InputBase
                onChange={(e) => setinputbtn(e.target.value)}
                value={inputbtn}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    setSearch(inputbtn);
                  }
                }}
                sx={{
                  color: "#051E40BF",
                  input: {
                    "&::placeholder": {
                      opacity: 1,
                    },
                  },
                }}
                placeholder="Search"
                inputProps={{ "aria-label": "search post" }}
              />
            </Box>
          </Stack>
        </Stack>
        <LeftPanel />
        <br></br>

        <Stack
          direction={"column"}
          spacing={3}
          sx={{ flex: 1, mb: 5, width: "90%", margin: "auto" }}
        >
          <StartPost callback={() => setOpen(true)} />
          {posts.length > 0 &&
            posts
              .filter((post) => {
                if (search !== "") {
                  const reg = new RegExp(search.toLowerCase());

                  return (
                    reg.test(post.text.toLowerCase()) ||
                    reg.test(post.creatorData?.displayName.toLowerCase()) ||
                    (reg.test(
                      post.tags.map((item) => {
                        return item.toLowerCase();
                      })
                    ) &&
                      post)
                  );
                } else {
                  return post;
                }
              })
              .map((post, i) => (
                <PostItem
                  data={post}
                  key={i}
                  callback={(data, image) => {
                    if (data != "deleted") {
                      setPostData(data);
                      if (data?.reposterData) {
                        setText(data?.repostText);
                      } else {
                        setTags([...data?.tags]);
                        setImage(data?.image);
                        setVideo(data?.video);
                        setText(data?.text);
                        setOtherFiles(data?.otherFiles);
                        setTags(data?.tags);
                        setFile(data?.metadata);
                      }
                      console.log("image is ", image);

                      if (data?.type !== "" && data?.type !== "file") {
                        getDownloadURL(
                          ref(
                            storage,
                            data?.type === "image" ? data?.image : data?.video
                          )
                        )
                          .then((url) => {
                            data?.type === "image"
                              ? setURL(url)
                              : setVideo(url);
                          })
                          .catch((error) => {
                            // Handle any errors
                          });
                      }
                      setOpen(true);
                    }
                  }}
                  mobile={true}
                  // myprofile={myprofile}
                />
              ))}
          {lastDoc && (
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              py={5}
              justifyContent="center"
              alignItems="center"
            >
              <Button size={"small"} onClick={loadMore}>
                Load more
              </Button>
            </Stack>
          )}
          <div />
        </Stack>
        <Portal>
          {profile && profile !== "admin" && profile?.onboard && (
            <Stack direction={"column"}>
              <IconButton
                sx={{
                  position: "fixed",
                  bottom: "10%",
                  right: "6%",
                  transition: "0.5s linear",
                }}
                onClick={() => setOpenSupport(!openSupport)}
                // disabled={isLoading}
              >
                <img
                  src={"/assets/nav-bar/support-icon.svg"}
                  style={{ width: "40px" }}
                  alt="support"
                />
              </IconButton>
            </Stack>
          )}
        </Portal>
        <Portal>
          <Dialog
            open={openSupport}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="alert-dialog-achive"
            aria-describedby="alert-dialog-achive"
          >
            <DialogContent sx={{ padding: "0px 0px", mt: 2 }}>
              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-end"
              >
                <IconButton
                  onClick={() => {
                    setOpenSupport(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack direction={"column"} spacing={5}>
                <Typography
                  component="span"
                  variant="h5"
                  color="text.primary"
                  sx={{
                    textAlign: "left",
                    flex: 1,
                    px: 5,
                    fontWeight: 700,
                  }}
                >
                  Need help?
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{ lineHeight: 1.4, textAlign: "left", px: 5 }}
                >
                  Whether you need help with your account, have a technical
                  issue, or just need some advice, we're here to help.
                  <br /> <br />
                  Chat with AstraNova Support or email at{" "}
                  <b>hello@astranova.io</b>.
                </Typography>
                <Stack
                  direction={"column"}
                  sx={{ width: "96%" }}
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Button
                    variant={"contained"}
                    sx={{ px: 3 }}
                    onClick={create}
                    disabled={loading}
                  >
                    Chat
                  </Button>
                </Stack>

                <Box />
              </Stack>
            </DialogContent>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
            open={posted}
          >
            <DialogTitle>
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
                sx={{ pt: 0, pb: 0, px: 0 }}
              >
                <Typography sx={{ fontWeight: "500" }}>
                  {updated
                    ? arr2[(Math.random() * arr.length) | 0]
                    : arr[(Math.random() * arr.length) | 0]}
                </Typography>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Stack
                direction={"column"}
                justifyContent={"centre"}
                alignItems={"center"}
                sx={{ pt: 1, pb: 1 }}
              >
                <img src="/assets/post_success.png" alt="none" width={"90"} />
              </Stack>

              <Typography
                type={"h5"}
                textAlign={"center"}
                sx={{ fontWeight: "600", pl: 1, pr: 1 }}
              >
                {updated
                  ? "Thanks for that edit! We’re glad you took the time to improve the post even more."
                  : "Thank you for taking the time to share your thoughts and ideas with us."}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Stack sx={{ pt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    setPosted(false);
                    setUpdated(false);
                  }}
                >
                  Continue
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog
            open={open}
            onClose={() => {
              setFile();
              setOpen(false);
              resetAll();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
            PaperProps={{
              style: {
                width: 600, // Customize the maximum width of the dialog content
                maxHeight: 800, // Customize the maximum height of the dialog content
                margin: "0 auto", // Center the dialog horizontally
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
                sx={{ pt: 0, pb: 0, px: 0 }}
              >
                <Avatar
                  sx={{ height: 70, width: 70 }}
                  imgProps={{ sx: { objectFit: "cover" } }}
                  src={
                    profile.userType === "startup" &&
                    profile?.startup?.founder?.photoURL !== ""
                      ? profile?.startup?.founder?.photoURL
                      : profile?.photoURL
                  }
                >
                  <Typography variant={"h3"}>
                    {profile.userType === "startup"
                      ? profile?.startup?.founder?.founderName?.charAt(0) ||
                        profile?.displayName?.charAt(0)
                      : profile?.displayName?.charAt(0)}
                  </Typography>
                </Avatar>
                <List sx={{ width: "100%", py: 0, my: 0 }}>
                  <ListItem alignItems="flex-start" sx={{ pb: 2 }}>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body1"
                            color="text.primary"
                          >
                            {" "}
                            {profile.userType === "startup"
                              ? profile?.startup?.founder?.founderName ||
                                profile?.displayName
                              : profile?.displayName}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
                <Stack
                  direction={"column"}
                  sx={{ height: 83 }}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => {
                      setFile();
                      setTag("");
                      setTags([]);
                      setText("");
                      setURL("");
                      setOpen(false);
                      resetAll();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <TextField
                variant={"standard"}
                fullWidth
                rows={8}
                multiline={true}
                onChange={(e) => setText(e.target.value)}
                sx={{
                  minWidth: 500,
                  borderBottom: "none !important",
                }}
                placeholder={"What do you want to talk about?"}
                defaultValue={text}
              />

              {/*<InputBase
              sx={{ ml: 1, width: 600 ,color:'#051E40BF',  }}
              placeholder="What do you want to talk about?"
              inputProps={{ 'aria-label': 'search post' }}
            />*/}
              <Stack direction={"row"} sx={{ pt: 3 }} spacing={3}>
                {file && file?.name && file?.type.includes("image") ? (
                  <Stack direction={"row"}>
                    <img
                      alt="none"
                      src={URL}
                      style={{ width: "100%", height: "100%" }}
                    />

                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        width: 20,
                        height: 20,
                        ml: -4,
                        mt: 1,
                        mr: 1,
                        padding: 1.5,
                        color: "black",
                      }}
                      onClick={() => {
                        setFile();
                        setURL("");
                        setImage("");
                        setLoading(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                ) : file?.type.includes("video") ? (
                  <Stack direction={"row"}>
                    <CardMedia component="video" image={URL} controls />
                    {/* <video
										controls
										src={URL}
										width="500"
										height="250"
									/> */}
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        width: 20,
                        height: 20,
                        ml: -4,
                        mt: 1,
                        mr: 1,
                        padding: 1.5,
                      }}
                      onClick={() => {
                        setFile();
                        setURL("");
                        setVideo("");
                        setLoading(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                ) : (
                  <></>
                )}{" "}
                {loading && <CircularProgress size={20} />}
              </Stack>
              <br></br>
              <div style={{ width: "100%" }}>
                {tags.length > 0 &&
                  tags.map((option, index) => (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginBottom: "10px",
                      }}
                      onDelete={() =>
                        setTags(
                          tags.filter((f) =>
                            f !== option.value ? option.value : option
                          )
                        )
                      }
                      label={option.value ? option.value : option}
                    />
                  ))}

                <InputBase
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      tag !== "" &&
                      !tags.find((tg) => tg === tag)
                    ) {
                      setTags([...tags, `#${tag}`]);
                      setTag("");
                    } else if (
                      e.key === " " &&
                      tag !== "" &&
                      !tags.find((tg) => tg === tag)
                    ) {
                      setTags([...tags, `#${tag}`]);
                      setTag("");
                    }
                  }}
                  size="medium"
                  placeholder={tags.length > 0 ? null : "Add hashtag"}
                  sx={{
                    transition: "0.1s linear",
                    border: "none!important",
                    outline: "none!important",
                    outlineColor: "#fff",
                    width: "100%",
                  }}
                />

                {otherFiles !== "" && file && (
                  <Typography variant={"caption"}>{file?.name}</Typography>
                )}
              </div>
            </DialogContent>
            <DialogActions sx={{ px: 2, py: 2 }}>
              {!postData && (
                <>
                  <label htmlFor={"attachment-media"}>
                    <Input
                      id={"attachment-media"}
                      type={"file"}
                      onClick={(e) => (e.target.value = null)}
                      accept={"image/*"}
                      // accept={".jpg,.jpeg,.png,.gif,.webp"}
                      onChange={async (e) => {
                        const list = [];
                        setLoading(true);
                        for (let file of e.target.files) {
                          console.log(file);
                          setFile(file);
                          const url = await UploadFile(
                            file,
                            `/m/timeline/${profile?.uid}/${file?.name}`
                          );
                          const image = new Image();
                          image.src = url;
                          console.log(image);
                          image.onload = function () {
                            console.log(this.width);
                            console.log(this.height);
                            setImageDimensions({
                              width:
                                this.width > 500
                                  ? this.width / 2 < 300
                                    ? 300
                                    : 500
                                  : this.width,
                              height:
                                this.height > 250
                                  ? this.height / 2 < 125
                                    ? 125
                                    : 250
                                  : this.height,
                            });
                          };
                          // console.log(URL);
                          setURL(url);
                          setLoading(false);
                          console.log(file);
                          list.push(
                            `/m/timeline/${profile?.uid}/${file?.name}`
                          );
                        }
                        setImage(list[0]);
                        console.log(imageDimensions);
                      }}
                    />
                    <IconButton component={"span"}>
                      <img src={"/assets/Vector (3).png"} />
                    </IconButton>
                  </label>

                  <label htmlFor={"attachment-video"}>
                    <Input
                      id={"attachment-video"}
                      type={"file"}
                      accept={"video/*"}
                      // accept={".mov,.mp4"}
                      onClick={(e) => (e.target.value = null)}
                      onChange={async (e) => {
                        const list = [];
                        setLoading(true);
                        for (let file of e.target.files) {
                          setFile(file);
                          const url = await UploadFile(
                            file,
                            `/m/timeline/${profile?.uid}/${file?.name}`
                          );
                          setURL(url);
                          setLoading(false);
                          console.log(file);

                          list.push(
                            `/m/timeline/${profile?.uid}/${file?.name}`
                          );
                        }

                        setVideo(list[0]);
                      }}
                    />
                    <IconButton component={"span"}>
                      <img src={"/assets/Vector (4).png"} />
                    </IconButton>
                  </label>

                  <label htmlFor={"attachment-files"}>
                    <Input
                      id={"attachment-files"}
                      accept={".pdf, .doc, .docx, .csv"}
                      type={"file"}
                      onClick={(e) => (e.target.value = null)}
                      onChange={async (e) => {
                        const list = [];
                        setLoading(true);
                        for (let file of e.target.files) {
                          setFile(file);
                          const url = await UploadFile(
                            file,
                            `/m/timeline/${profile?.uid}/${file?.name}`
                          );
                          setURL(url);
                          setLoading(false);
                          console.log(file);

                          list.push(
                            `/m/timeline/${profile?.uid}/${file?.name}`
                          );
                        }

                        setOtherFiles(list[0]);
                      }}
                    />
                    <IconButton component={"span"}>
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                </>
              )}
              {/* <IconButton>
							<img src={"/assets/Group 1193.png"} />
						</IconButton> */}
              <Tooltip
                title={<h3 style={{ color: "#E4EAF5" }}>Coming soon</h3>}
                placement="bottom"
                open={showTooltip}
                onClose={handleTooltipClose} // This will handle the close event when the tooltip is hidden
              >
                <IconButton onClick={handleIconButtonClick}>
                  <SentimentSatisfiedAltIcon />
                </IconButton>
              </Tooltip>

              {/* {state?	<Picker onEmojiClick={(emojiData, event)=> setChosenEmoji(emojiData) && setState(false)} disableAutoFocus={true} native />:null}
						{chosenEmoji? <p>Symbol: {chosenEmoji.emoji}</p>:null} */}
              <Box sx={{ flex: 1 }} />
              <Button
                variant={"contained"}
                onClick={async () => {
                  if (!postData) {
                    await addDoc(collection(DB, "timelines"), {
                      current: { likes: 0, comments: 0 },
                      previous: { likes: 0, comments: 0 },
                      text: text,
                      uid: profile?.uid,
                      creatorData: profile,
                      createdAt: serverTimestamp(),
                      type:
                        image !== ""
                          ? "image"
                          : video !== ""
                          ? "video"
                          : otherFiles !== ""
                          ? "file"
                          : "",
                      metadata: {
                        name:
                          image !== "" || video !== "" || otherFiles !== ""
                            ? file?.name
                            : "",
                        type:
                          image !== "" || video !== "" || otherFiles !== ""
                            ? file?.type
                            : "",
                      },
                      image,
                      video,
                      otherFiles,
                      tags: tags,
                      // chosenEmoji:chosenEmoji.emoji
                    });
                    setUpdated(false);
                  } else {
                    let postObj = {
                      current: { likes: 0, comments: 0 },
                      previous: { likes: 0, comments: 0 },
                      text: text,
                      updatedAt: serverTimestamp(),
                      type:
                        image !== ""
                          ? "image"
                          : video !== ""
                          ? "video"
                          : otherFiles !== ""
                          ? "file"
                          : "",
                      metadata: {
                        name:
                          image !== "" || video !== "" || otherFiles !== ""
                            ? file?.name
                            : "",
                        type:
                          image !== "" || video !== "" || otherFiles !== ""
                            ? file?.type
                            : "",
                      },
                      image: image,
                      video: video,
                      otherFiles: otherFiles,
                      tags: tags,
                      // chosenEmoji:chosenEmoji.emoji
                    };
                    if (postData.reposterData) {
                      postObj = {
                        repostText: text,
                        updatedAt: serverTimestamp(),
                      };
                    }
                    await updateDoc(
                      doc(collection(DB, "timelines"), postData.id),
                      postObj,
                      { merge: true }
                    );

                    setUpdated(true);
                  }
                  setImage("");
                  setVideo("");
                  setText("");
                  setTags([]);
                  setFile();
                  setPosted(true);
                  setOpen(false);
                  resetAll();
                }}
                disabled={
                  text === "" &&
                  image == "" &&
                  video === "" &&
                  otherFiles === ""
                }
              >
                {postData ? "UPDATE" : "POST"}
              </Button>
            </DialogActions>
          </Dialog>
        </Portal>
      </Stack>
    </React.Fragment>
  );
}
