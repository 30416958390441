import { Box, Button, IconButton, Stack, Typography} from "@mui/material";
import React from "react";
import {EditSharp} from "@mui/icons-material";
import AboutInvestorForm from "./edit/investor/about-investor-form";

export default function InvestorAbout({profile, cb}) {
  const [seeMore, setSeeMore] = React.useState(false)
  const [open,setOpen] = React.useState(false)
  const profileRegex = /profile|admin\/user-details/
  
  const styleFont = {
    backgroundColor: '#F6F8FA',
    padding: '8px 15px',
    borderRadius: '5px',
    fontSize: 14,
    marginRight: "5px",
  }

  return (

    <>


      {profile.userType  === 'investor' &&
        profile.investor.investorType !== 'Angel Investor' &&

        <>
        </>
      }


      {/* <Typography variant={'h5'}>About Investor</Typography> */}

      {profile.userType  === 'investor' &&
        <>
        <Stack
                direction={"column"}
                spacing={3}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}>
            <Stack direction={"row"} spacing={2} >
              <Typography fontSize={20} fontWeight={600} style={{flex: 1}}>About Investor</Typography>
              {profileRegex.test(window.location.pathname) &&  <IconButton onClick={() => setOpen(true)}>
              <EditSharp />
            </IconButton>}
            </Stack>
            {/* <Grid container  gap={1}  sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}> */}
            <Stack direction={"column"} spacing={4}>
              
                <Stack direction={'column'} spacing={1}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Investor Type <br/> <font style={styleFont}>{profile?.investor?.investorType}</font>
                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
              

              {profile?.investor?.investorType !== 'Angel Investor'  &&
                
                  <Stack direction={'column'} spacing={1}>
                    <Typography variant={'h6'} sx={{lineHeight:2}}>
                      Role <br/> <font style={styleFont}>{profile?.investor?.role}</font>
                    </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                  </Stack>
                }
            </Stack>

            {/* <Grid container  gap={1}  sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}> */}
            <Stack direction={"column"} spacing={4}>  
              
                <Stack direction={'column'} spacing={1}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Investment Stage <br/>
                    {profile?.investor?.investmentStage &&
                      profile?.investor?.investmentStage.length > 0 &&
                      profile?.investor?.investmentStage.map(
                        (ex, i) => (
                          <font style={styleFont}>
                            {ex}


                          </font>
                        )
                      )}

                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
              
                <Stack direction={'column'} spacing={1}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Sector <br/>
                    {profile?.investor?.sector &&
                      profile?.investor?.sector.length > 0 &&
                      profile?.investor?.sector.map(
                        (ex, i) => (
                          <font style={styleFont}>
                            {ex}


                          </font>
                        )
                      )}
                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>

            </Stack>
            {/* <Grid container  gap={1}  sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}> */}
            <Stack direction={"column"} spacing={4}>
            { profile?.investor?.additionalDetails &&
                      profile?.investor?.additionalDetails.length > 0 &&
              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Additional Details <br/>
                    {profile?.investor?.additionalDetails &&
                      profile?.investor?.additionalDetails.length > 0 &&
                      profile?.investor?.additionalDetails.map(
                        (ex, i) => (
                          <font style={styleFont}>
                            {ex}


                          </font>
                        )
                      )}

                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
                }
              
              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Geographies<br/>
                    {profile?.investor?.geographies &&
                      profile?.investor?.geographies.length > 0 &&
                      profile?.investor?.geographies.map(
                        (ex, i) => (
                          <font style={styleFont}>
                            {ex}


                          </font>
                        )
                      )}

                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
              
            </Stack>
            {/* <Grid container  gap={1}  sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}> */}
            {seeMore ? <>
            <Stack direction={"column"} spacing={4}>
              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Lead or Follow <br/> <font style={styleFont}>{profile?.investor?.leadOrFollow}</font>
                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>

              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Ticket Size <br/>
                    <font style={styleFont}>
                      {profile?.investor?.currency === "USD" ? "$"
                        : profile?.investor?.currency === "GBP" ? "£"
                        : "€"
                      }
                      {profile?.investor?.startingAt}
                      -
                      {profile?.investor?.currency === "USD" ? "$"
                        : profile?.investor?.currency === "GBP" ? "£"
                        : "€"
                      }
                      {profile?.investor?.upto}
                    </font>
                    {/* <font  style={styleFont}>{`${profile?.investor?.ticketCurrency}${profile?.investor?.ticketStartingAt} - ${profile?.investor?.ticketCurrency}${profile?.investor?.ticketUpto}`}</font> */}
                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
            </Stack>
            {/* <Grid container  gap={1}  sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' }}> */}
            <Stack direction={"column"} spacing={4}>
              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Pre-product <br/> <font  style={styleFont}>{profile?.investor?.preProduct ? 'Yes': 'No'}</font>
                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Product Pre-launch <br/> <font  style={styleFont}>{profile?.investor?.productLive? 'Yes': 'No'}</font>
                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Pre-revenue <br/> <font  style={styleFont}>{profile?.investor?.preRevenue? 'Yes': 'No'}</font>
                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Pre-PMF <br/> <font  style={styleFont}>{profile?.investor?.PMF ? 'Yes': 'No'}</font>
                  </Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>

              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Sole Founder <br/> <font  style={styleFont}>{profile?.investor?.soleFounder ? 'Yes': 'No'}</font>
                  </Typography>
                </Stack>

            </Stack>
            <Stack alignItems={'center'}>
			<Box>
			<Button size={"small"} variant='outlined' color="secondary"  sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See Less</Button>
			</Box>
				 </Stack> 
          </>:
          <Stack alignItems={'center'}>
          <Box>
          <Button size={"small"} variant='outlined' color="secondary"  sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See More</Button>
          </Box>
             </Stack> 
          }
          </Stack>
        </>
      }

      <AboutInvestorForm profile={profile?.investor} investorProfile={profile} open={open} callback={(data) => {
        cb(data);
        setOpen(false)
      } }  handleClose={() => setOpen(false)}/>
    </>
  )
}

// nameOfOrganization
