import { useState, useEffect } from "react";

const usePersistentState = (key, initialState) => {
  const [state, setState] = useState(() => {
    let storedState = localStorage.getItem(key);
    storedState =
      storedState == "undefined" || storedState == undefined
        ? null
        : storedState;
    return storedState ? JSON.parse(storedState) : initialState;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

export default usePersistentState;
