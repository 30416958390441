import * as React from "react";
import {
	Avatar,
	Button,
	Container,
	Stack,
	Typography,
	MenuItem,
	Menu,
	Icon,
	SvgIcon,
	Box,
} from "@mui/material";
import Link from '@mui/material/Link';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import { logout } from "../../config/firebase/firebase";
import { makeStyles } from '@material-ui/core/styles';
import {
	ExpertIcon,
	HomeIcon,
	InvestorIcon,
	StartupIcon,
	BusinessDeveloperIcon,
	MoreIcon,
	MessageIcon,
	NotificationIcon,
} from "../../utils/svg-icons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { ChatBadge , NotificationBadge } from "../../components/common/chat/chat-bedge";
import { USER_TYPES } from "../../utils/enums/userTypes.enum";
import { BUSINESS_DEVELOPER, USER_TYPES_TO_NAME } from "../../utils/enums/labels.enum";
//import {ChatBadge, NotificationBadge} from "./chat/chat-bedge";

const useStyles = makeStyles({
	root: {
	  boxShadow: 'none',
	  '&:hover': {
		boxShadow: 'none',
	  },
	  '&:active': {
		boxShadow: 'none',
	  },
	},
  });
  
// UNUSED COMPONENT

export default function TopNavbarMobile() {
	const [openmodal, setOpenmodal] = React.useState(false);

	const handleClickOpenmodal = () => {
		setOpenmodal(true);
	};

	const handleClosemodal = () => {
		setOpenmodal(false);
	};
	const signinPattern = /signin/,
		signupPattern =
			/signup|auth\/type|auth\/where-are-you-located|auth\/your-working-experiences/;
	const disablePattern =
		/\/type|\/where-are-you-located|\/your-working-experiences/;
	const location = useLocation();
	const navigator = useNavigate();
	const { user, setUser, setProfile, profile } =
		React.useContext(AuthUserContext);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorMoreEl, setAnchorMoreEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const openMore = Boolean(anchorMoreEl);
	const handleMoreClose = () => {
		setAnchorMoreEl(null);
	};
	const handleMoreClick = (event) => {
		setAnchorMoreEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const homePattern = /timeline/;
	const startupPattern =
		/members-list|startup-details|startup-founder-profile|investor-details|expert-details|business-developer-details|open-requests-details|talent-details|service-provider-details|distribution-details/;
	const investorPattern = /investor-list|investor-details/;
	const expertPattern = /expert-list|expert-details/;
	const businessDeveloperPattern =
		/business-developer-list|business-developer-details/;
	const messagePattern = /chat/;
	const morePattern =
		/service-provider-list|talent-list|open-requests|distribution-list|service-provider-details|talent-details|open-requests-details|distribution-details/;
	const openRequestsPattern = /open-requests/;
	const telentPattern = /talent-list|talent-details/;
	const serviceProviderPattern =
		/service-provider-list|service-provider-details/;
	const distributionPattern = /distribution-list|distribution-details/;
	const notificationPattern = /notification/;
	React.useEffect(() => {
		console.log(profile);
		console.log(user);
	});
	const classes = useStyles();
	return (
		<Container
			maxWidth={"full-width"}
			sx={{
				backgroundColor: "#fff",
				boxShadow:
					"0px 2px 4px -1px rgb(0 0 0 / 4%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 10px 0px rgb(0 0 0 / 4%)",
			}}>
			<Container
				maxWidth={
					profile && profile.userType === "admin"
						? "full-width"
						: "xl"
				}>
				<Stack
					direction={"row"}
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					sx={{ width: "100%", pt: 1.5, pb: 1.5 }}>
					{
						<Box sx={{ mt: -0.5 }}>
							
							<Link  style={{cursor:"pointer"}}
							onClick={() => {
								profile?.userType=="admin" ? navigator(`/admin/dashboard`)
								:navigator(`/${profile?.userType}/timeline`)
                              
							}
								}
							>
							<img
								src={"/assets/nova-logo.png"}
								aria-label={"logo"}
								style={{ height: "50px" }}
							/>
                            </Link>
						</Box>
					}
				

					<div style={{ flex: 1 }} />
					{!user ? (
						<>
							<Button
								onClick={() => navigator("/auth/signin")}
								variant={
									signinPattern.test(location.pathname)
										? "contained"
										: "text"
								}
								color={
									signinPattern.test(location.pathname)
										? "primary"
										: "secondary"
								}
								sx={{ textTransform: "uppercase", px: 3 }}>
								Sign in
							</Button>
							<Button
								onClick={() =>
									navigator("/auth/where-are-you-located")
								}
								color={
									signupPattern.test(location.pathname)
										? "primary"
										: "secondary"
								}
								variant={
									signupPattern.test(location.pathname)
										? "contained"
										: "text"
								}
								sx={{ textTransform: "uppercase", px: 3 }}>
								Sign up
							</Button>
						</>
					) : profile && profile.userType === "admin" ? (
						<>
							<Button
								aria-controls="basic-menu"
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								onClick={handleClick}
								disabled={disablePattern.test(
									location.pathname
								)}
								sx={{ minWidth: 150 }}>
								<Avatar src={user.photoURL}>
									{" "}
									{user.displayName &&
										user.displayName.charAt(0)}
								</Avatar>
								<Typography
									variant={"body2"}
									color={"secondary"}>
									&nbsp;&nbsp;
									{user.displayName && user.displayName}
								</Typography>
							</Button>
						</>
					) : (
						<>
							{profile && profile?.onboard && (
								<Stack
									direction={"row"}
									justifyContent="flex-end"
									alignItems="flex-start"
									spacing={2}
									sx={{ width: "100%", pt: 1 }}>
									<Button
										color={"secondary"}
										variant={"text"}
										sx={{ px: 2 }}
										onClick={() =>
											navigator(
												`/${profile?.userType}/timeline`
											)
										}>
										<Stack
											direction={"column"}
											justifyContent="center"
											alignItems="center"
											spacing={0.1}>
											<HomeIcon
												color={"primary"}
												colorIcon={
													homePattern.test(
														window.location.pathname
													)
														? "#233DFF"
														: "#A9ABAE"
												}></HomeIcon>
											<Typography
												variant={"body2"}
												sx={{
													color: homePattern.test(
														window.location.pathname
													)
														? "#233DFF"
														: "#A9ABAE",
													fontWeight: 500,
												}}>
												Home
											</Typography>
										</Stack>
									</Button>
									<Button
										color={"secondary"}
										variant={"text"}
										sx={{ px: 2 }}
										onClick={() =>
											navigator(
												`/${profile?.userType}/open-requests`
											)
										}>
										<Stack
											direction={"column"}
											justifyContent="center"
											alignItems="center"
											spacing={0.1}>
											<InvestorIcon
												color={"primary"}
												colorIcon={
													openRequestsPattern.test(
														window.location.pathname
													)
														? "#233DFF"
														: "#A9ABAE"
												}></InvestorIcon>
											<Typography
												variant={"body2"}
												sx={{
													color: openRequestsPattern.test(
														window.location.pathname
													)
														? "#233DFF"
														: "#A9ABAE",
													fontWeight: 500,

												}}>
												Marketplace
											</Typography>
										</Stack>
									</Button>
									<Button
										color={"secondary"}
										variant={"text"}
										sx={{ px: 2 }}
										onClick={() =>
											navigator(
												`/${profile?.userType}/members-list`
											)
										}>
										<Stack
											direction={"column"}
											justifyContent="center"
											alignItems="center"
											spacing={0.1}>
											<StartupIcon
												color={"primary"}
												colorIcon={
													startupPattern.test(
														window.location.pathname
													)
														? "#233DFF"
														: "#A9ABAE"
												}></StartupIcon>
											<Typography
												variant={"body2"}
												sx={{
													color: startupPattern.test(
														window.location.pathname
													)
														? "#233DFF"
														: "#A9ABAE",
													fontWeight: 500,

												}}>
												Members
											</Typography>
										</Stack>
									</Button>

									<Button
										color={"secondary"}
										variant={"text"}
										sx={{ px: 2 }}
										onClick={() =>
											navigator(
												`/${profile?.userType}/chat`
											)
										}>
										<Stack
											direction={"column"}
											justifyContent="center"
											alignItems="center"
											spacing={0.1}>
											<Stack direction={'row'} spacing={1} sx={{width: '100%'}} justifyContent="flex-end" alignItems="center">

												<ChatBadge />

											</Stack>
											<MessageIcon
												color={"primary"}
												colorIcon={
													messagePattern.test(
														window.location.pathname
													)
														? "#233DFF"
														: "#A9ABAE"
												}></MessageIcon>
											<Typography
												variant={"body2"}
												sx={{
													color: messagePattern.test(
														window.location.pathname
													)
														? "#233DFF"
														: "#A9ABAE",
													fontWeight: 500,
												}}>
												Message
											</Typography>
										</Stack>
									</Button>
									<Button
										color={"secondary"}
										variant={"text"}
										sx={{ px: 2 }}
										onClick={() =>
											navigator(
												`/${profile?.userType}/notification`
											)
										}>
										<Stack
											direction={"column"}
											justifyContent="center"
											alignItems="center"
											spacing={0.1}>

											<Stack direction={'row'} spacing={1} sx={{width: '100%'}} justifyContent="flex-end" alignItems="center">
												<NotificationBadge />
											</Stack>
											<NotificationIcon
												color={"primary"}
												colorIcon={
													notificationPattern.test(
														window.location.pathname
													)
														? "#233DFF"
														: "#A9ABAE"
												}></NotificationIcon>
											<Typography
												variant={"body2"}
												sx={{
													color: notificationPattern.test(
														window.location.pathname
													)
														? "#233DFF"
														: "#A9ABAE",
													fontWeight: 500,
												}}>
												Notification
											</Typography>
										</Stack>
									</Button>
								</Stack>
							)}

							<Button
								aria-controls="basic-menu"
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								onClick={handleClick}
								disabled={disablePattern.test(
									location.pathname
								)}
								sx={{ minWidth: 150 }}>
								<Avatar src={user.photoURL} key={user.photoURL}>
									{" "}
									{user.displayName &&
										user.displayName.charAt(0)}
								</Avatar>
								<Typography
									variant={"body2"}
									color={"secondary"}>
									&nbsp;&nbsp;
									{user.displayName && user.displayName}
								</Typography>
							</Button>
						</>
					)}

					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}>
						{profile && (
							<MenuItem
								sx={{
									width: 300,
									marginTop: "10px",
									marginBottom: "10px",
								}}>
								<Avatar
									sx={{ height: 60, width: 60 }}
									style={{ marginLeft: "15px" }}
									src={user.photoURL}></Avatar>
								<Typography
									variant={"h6"}
									style={{
										color: "black",
										marginLeft: "20px",
									}}>
									{profile?.displayName} <br></br>
									<span style={{ color: "#A9ABAE" }}>
										{" "}
										{profile &&
											profile?.userType &&
											USER_TYPES_TO_NAME[profile?.userType]}
									</span>
								</Typography>
							</MenuItem>
						)}
						{profile && (
							<MenuItem
								sx={{ width: 300 }}
								onClick={() => {
									handleClose();
									navigator(`${profile?.userType}/profile`);
								}}>
								<Button
									variant="contained"
									style={{
										width: "90%",
										marginLeft: "10px",
									}}>
									View Profile
								</Button>
							</MenuItem>
						)}
						<MenuItem
							sx={{ width: 300 }}
							style={{
								color: "black",
								marginTop: "10px",
								borderTop: "0.5px solid #A9ABAE",
							}} onClick={() => {
							handleClose();
							navigator(`/${profile?.userType}/settings`)
						}}>
							<span style={{ marginTop: "50px" }}></span>
							Settings
						</MenuItem>
						<MenuItem
							sx={{ width: 300 }}
							cstyle={{ color: "black" }}
							// onClick={handleClickOpenmodal}
							>
							<a style={{textDecoration:'none' , color:'black'}}
									href="https://astranova.io/terms-of-use/" target="_blank">
									Terms of Service</a>
						</MenuItem>
						<MenuItem
							sx={{ width: 300 }}
							cstyle={{ color: "black" }}
							// onClick={handleClickOpenmodal}
							>
							 <a style={{textDecoration:'none' , color:'black'}}
							href="https://astranova.io/privacy-policy/" target="_blank">
							Privacy Policy</a>
						</MenuItem>

						<MenuItem
							sx={{
								width: 300,
								marginTop: "10px",
								marginBottom: "5px",
								borderTop: "0.5px solid #A9ABAE",
								color: "black",
							}}
							onClick={() => {
								navigator("/auth/signin");
								logout().then(() => {
									handleClose();
									setUser(null);
									setProfile(null);
									navigator("/auth/signin");
								});
							}}>
							Sign Out
						</MenuItem>
					</Menu>

					<Menu
						id="basic-menu-more"
						anchorEl={anchorMoreEl}
						open={openMore}
						onClose={handleMoreClose}
						MenuListProps={{
							"aria-labelledby": "basic-button-more",
						}}>
						<MenuItem
							
							sx={{
								width: 150,
								color: openRequestsPattern.test(
									window.location.pathname
								)
									? "#233DFF"
									: "black",
							}}
							onClick={() => {
								navigator(
									`/${profile?.userType}/open-requests`
								);
								handleMoreClose();
							}}>
							Open requests
						</MenuItem>
						<MenuItem
							
							sx={{
								width: 150,
								color: telentPattern.test(
									window.location.pathname
								)
									? "#233DFF"
									: "black",
							}}
							onClick={() => {
								navigator(`/${profile?.userType}/talent-list`);
								handleMoreClose();
							}}>
							Talents
						</MenuItem>
						<MenuItem
					
							sx={{
								width: 150,
								color: serviceProviderPattern.test(
									window.location.pathname
								)
									? "#233DFF"
									: "black",
							}}
							onClick={() => {
								navigator(
									`/${profile?.userType}/service-provider-list`
								);
								handleMoreClose();
							}}>
							Service Providers
						</MenuItem>
						<MenuItem
							
							sx={{
								width: 150 ,
								color: distributionPattern.test(
									window.location.pathname
								)
									? "#233DFF"
									: "black",
							}}
							onClick={() => {
								navigator(
									`/${profile?.userType}/distribution-list`
								);
								handleMoreClose();
							}}>
							Distribution
						</MenuItem>
						{/* <MenuItem
							sx={{ width: 150 }}
							sx={{
								color: distributionPattern.test(
									window.location.pathname
								)
									? "#233DFF"
									: "black",
							}}>
							Coming Soon*
						</MenuItem> */}
					</Menu>
				</Stack>
				<Dialog open={openmodal} onClose={handleClosemodal}>
					<DialogActions
						style={{
							border: "10px solid #233DFF",
							borderBottom: "none",
						}}>
						<IconButton
							style={{ marginRight: "10px" }}
							onClick={handleClosemodal}>
							<CloseIcon />
						</IconButton>
					</DialogActions>
					<DialogContent
						style={{
							border: "10px solid #233DFF",
							borderTop: "none",
							marginTop: "-53px",
						}}>
						<DialogContentText>
							<Typography
								variant="h6"
								style={{ fontWeight: "700", color: "black" }}>
								Terms of Service
							</Typography>
							<Typography
								style={{ color: "black", fontSize: "15px" }}>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation
								ullamco laboris nisi ut aliquip ex ea commodo
								consequat. Duis aute irure dolor in
								reprehenderit in voluptate velit esse cillum
								dolore eu fugiat nulla pariatur. Excepteur sint
								occaecat cupidatat non proident, sunt in culpa
								qui officia deserunt mollit anim id est laborum.
								Sed ut perspiciatis unde omnis iste natus error
								sit voluptatem accusantium doloremque
								laudantium, totam rem aperiam, eaque ipsa quae
								ab illo inventore veritatis et quasi architecto
								beatae vitae dicta sunt explicabo. Nemo enim
								ipsam voluptatem quia voluptas sit aspernatur
								aut odit aut fugit, sed quia consequuntur magni
								dolores eos qui ratione voluptatem sequi
								nesciunt. Neque porro quisquam est, qui dolorem
								ipsum quia dolor sit amet, consectetur, adipisci
								velit, sed quia non numquam eius modi tempora
								incidunt ut labore et dolore magnam aliquam
								quaerat voluptatem. Ut enim ad minima veniam,
								quis nostrum exercitationem ullam corporis
								suscipit laboriosam, nisi ut aliquid ex ea
								commodi consequatur.
							</Typography>
							<br></br>
							<Typography
								variant="h6"
								style={{ fontWeight: "700", color: "black" }}>
								Privacy Policy
							</Typography>
							<Typography
								style={{ color: "black", fontSize: "15px" }}>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation
								ullamco laboris nisi ut aliquip ex ea commodo
								consequat. Duis aute irure dolor in
								reprehenderit in voluptate velit esse cillum
								dolore eu fugiat nulla pariatur. Excepteur sint
								occaecat cupidatat non proident, sunt in culpa
								qui officia deserunt mollit anim id est laborum.
								Sed ut perspiciatis unde omnis iste natus error
								sit voluptatem accusantium doloremque
								laudantium, totam rem aperiam, eaque ipsa quae
								ab illo inventore veritatis et quasi architecto
								beatae vitae dicta sunt explicabo. Nemo enim
								ipsam voluptatem quia voluptas sit aspernatur
								aut odit aut fugit, sed quia consequuntur magni
								dolores eos qui ratione voluptatem sequi
								nesciunt. Neque porro quisquam est, qui dolorem
								ipsum quia dolor sit amet, consectetur, adipisci
								velit, sed quia non numquam eius modi tempora
								incidunt ut labore et dolore magnam aliquam
								quaerat voluptatem. Ut enim ad minima veniam,
								quis nostrum exercitationem ullam corporis
								suscipit laboriosam, nisi ut aliquid ex ea
								commodi consequatur.
							</Typography>
						</DialogContentText>
					</DialogContent>
				</Dialog>
			</Container>
		</Container>
	);
}
