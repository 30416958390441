import React from "react";
import { Container, Typography, Stack, IconButton, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import DownloadFileHelper from "../../../utils/download-file-helper";
import ScrollToTop from "../../../utils/scrolling-top";
import {shortenURL} from "../../../utils/shortenURL";

const commonStyles = {
  bgcolor: "background.paper",
  m: 1,
  border: 1,
  width: "1198px",
  height: "1200px",
};
export default function InvestmentPortfolioView({profile , i }) {
  const navigator = useNavigate();
  const portfolio_show = profile?.userType === 'investor' ? profile?.investor?.portfolio
    :profile[profile?.userType === 'service-provider' ? 'serviceProvider'
      : profile?.userType === 'business-developer' ? 'businessDeveloper'
        : profile?.userType].investorDetails?.portfolio
  const amforinvestor=portfolio_show[i]?.amount;
  const amforbusiness=portfolio_show[i].amount;
  const amforexpert= portfolio_show[i].amount;
  const amforservice= portfolio_show[i].amount;
  const valforinvestor=portfolio_show[i]?.valuation;
  const valforbusiness=portfolio_show[i].valuation;
  const valforexpert=portfolio_show[i].valuation;
  const valforservice= portfolio_show[i].valuation;


  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  }


    let a ;
    let user = profile?.userType === 'business-developer' ?
        profile['businessDeveloper'].investorDetails :
        profile?.userType === 'expert'?
            profile['expert'].investorDetails :
            profile?.userType === 'service-provider'?
                profile['serviceProvider'].investorDetails:
                profile?.userType === 'investor' ?
                    profile['investor'] :
                null
  return (
    
    <>
    <ScrollToTop/>
    {/* {console.log(profile?.businessDeveloper?.investorDetails?.portfolio[0].name)} */}
    {/* {profile.userType  === 'investor' && */}
    <Container maxWidth={"lg"} sx={{ textAlign: "left" }}>
      <Stack
        direction={"column"}
        sx={{ width: "100%", textAlign: "left", marginTop: "10px" }}
        spacing={5}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"    
          // spacing={2}
        >
          <IconButton onClick={() => navigator(-.5)}>
            <ArrowBackIcon sx={{ fontSize: 40 }}/>
          </IconButton>

          <Typography fontSize={20} fontWeight={600} > Investment Portfolio</Typography>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ ...commonStyles, borderColor: "black" }}>
            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 120,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack direction={"row"} spacing={14} sx={{ marginTop: "-8px" }}>
                <Typography variant={"h6"} color={"black"}>
                Startup Name:
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                {portfolio_show[i]?.name}
                </Typography>
              </Stack>
              <br></br>
              <Stack direction={"row"} spacing={8} sx={{ marginTop: "-10px" }}>
                <Typography variant={"h6"} color={"black"}>
                Startup Website URL:
                </Typography>
                <Typography variant={"h6"} color={"#233DFF"}>
                <a href={
                  portfolio_show[i]?.website
                 }
                target={'_blank'}>
                {
                  portfolio_show[i]?.website


                }
                </a>
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent="left"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-8px" }}
              >
                <Typography variant={"h5"} color={"black"}>
                  <br></br>
                  Description
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                  <br></br>
                  {portfolio_show[i]?.description
                  }
                  <br></br>
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 120,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack direction={"row"} spacing={14} sx={{ marginTop: "-8px" }}>
                <Typography variant={"h6"} color={"black"}>
                Investment Stage:
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                {portfolio_show[i]?.investmentStage}
                </Typography>
              </Stack>
              <br></br>
              <Stack direction={"row"} spacing={14}>
                <Typography variant={"h6"} color={"black"}>
                Investment Date:
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                {portfolio_show[i]?.investmentDate}
                </Typography>
              </Stack>
              <br></br>
              <Stack direction={"row"} spacing={8} >
                <Typography variant={"h6"} color={"black"}>
                Pre-money Valuation:
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                {commify(valforservice)

                   }
                </Typography>
              </Stack>
              <br></br>
              <Stack direction={"row"} spacing={8}>
                <Typography variant={"h6"} color={"black"}>
                Investment Amount:
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                {commify(amforservice)
                   }
                </Typography>
              </Stack>
            </Stack>

            

            <Stack
              direction={"row"}
              justifyContent="left"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-8px" }}
              >
                <Typography variant={"h5"} color={"black"}>
                  <br></br>
                  Social Proof
                </Typography>
                <Typography variant={"h6"} color={"black"}>
                  <br></br>
                  {portfolio_show[i]?.socialProof}
                  <br></br>
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-30px" }}
              >
                <Typography variant={"h5"} color={"black"}>
                  <br></br>
                  Attachment  
                </Typography>
                  {portfolio_show[i]?.attachments.map((attachment) =>
                  {debugger
                      return<>
                          <Stack direction="row">
                              <Box
                                  sx={{
                                      p: "2px 10px",
                                      display: "flex",
                                      alignItems: "center",
                                      width: 337,
                                      height: 43,
                                      borderRadius: "12px",
                                      backgroundColor: "#E0E3FF",
                                      marginTop: "33px",
                                  }}
                              >
                                  <IconButton type="button" aria-label="search">
                                      <img src="/assets/image5.png" alt="" />
                                  </IconButton>
                                  
                                  {attachment.length < 15 ?
                                    attachment.split("/").splice(-1)
                                    :  attachment.split("/").splice(-1)
                                    
                                    }
                                    {/* console.log(`${a.slice(0 , 15)}...}`) */}
                                  
                                  {/* {attachment.split("/").splice(-1)} */}
                                  <Stack
                                      sx={{
                                          marginLeft: "60px",
                                      }}
                                  >
                                      <IconButton type="button" aria-label="search">
                                      <img src="/assets/img2.png" alt="" onClick={()=>{
                                          DownloadFileHelper(attachment)
                                      }
                                      }/>
                                      </IconButton>
                                  </Stack>
                              </Box>
                          </Stack>
                      </>
                  })}
              </Stack>
            </Stack>

            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                minHeight: 140,
                width: "100%",
                // borderBottom: "1px solid #ccc",
                p: 1,
                px: 3,
              }}
            >
              <Stack
                direction={"column"}
                // spacing={14}
                sx={{ marginTop: "-30px" }}
              >
                <Typography variant={"h5"} color={"black"}>
                  <br></br>
                  Additional Link
                </Typography>
                <Stack direction="row">
                  {  (portfolio_show[i]?.link)&&

                    <Box
                    sx={{
                      p: "2px 10px",
                      display: "flex",
                      alignItems: "center",
                      width: 337,
                      height: 43,
                      borderRadius: "12px",
                      backgroundColor: "#E0E3FF",
                      marginTop: "33px",
                      
                    }}
                    >
                      <Typography  variant={'caption'}  sx={{textAlign:'left',fontSize:'16px'}}>
                      <a href={
                        portfolio_show[i]?.link
                      } 
                      target={'_blank'}>
                        {
                             shortenURL(portfolio_show[i]?.link, 30)
                        }
                      {/* { 
                       ( profile?.userType === 'business-developer' &&
                        profile?.businessDeveloper?.investorDetails?.portfolio[i]?.link.length < 30
                        ? profile?.businessDeveloper?.investorDetails?.portfolio[i]?.link
                        : `${profile?.businessDeveloper?.investorDetails?.portfolio[i]?.link.slice(0 , 30)}...`
                       )

                       || 
                        
                        (profile?.userType === 'expert' &&
                        profile?.expert?.investorDetails?.portfolio[i]?.link.length < 30
                        ? profile?.expert?.investorDetails?.portfolio[i]?.link
                        : `${profile?.expert?.investorDetails?.portfolio[i]?.link.slice(0 , 30)}...`
)
                        ||

                       ( profile?.userType === 'service-provider' &&
                        profile?.serviceProvider?.investorDetails?.portfolio[i]?.link.length < 30
                        ? profile?.serviceProvider?.investorDetails?.portfolio[i]?.link
                        : `${profile?.serviceProvider?.investorDetails?.portfolio[i]?.link.slice(0 , 30)}...`
)
                        ||

                        (profile?.userType === 'investor' &&
                        profile?.investor?.portfolio[i]?.link.length < 30
                        ? profile?.investor?.portfolio[i]?.link.toString()
                        : `${profile?.investor?.portfolio[i]?.link.substring(0 , 30)}...`
                        )
                        
                        } */}
                       </a>
                      </Typography>
                  </Box>
                  }
                </Stack>
                {/* <Stack direction="row">
                  <Box
                    sx={{
                      p: "2px 10px",
                      display: "flex",
                      alignItems: "center",
                      width: 204,
                      height: 43,
                      borderRadius: "12px",
                      backgroundColor: "#E0E3FF",
                      marginTop: "33px",
                    }}
                  >
                  {profile?.userType === 'investor' ? 
                   profile?.investor?.portfolio[0]?.link: 
                   profile?.userType === 'business-developer' ?
                   profile?.businessDeveloper?.investorDetails?.portfolio[0].link:
                   profile?.userType === 'expert'? 
                   profile?.expert?.investorDetails?.portfolio[0].link: 
                   profile?.userType === 'service-provider'? 
                   profile?.serviceProvider?.investorDetails?.portfolio[0].link:
                   null
                   
                   }
                  </Box>
                </Stack> */}
              </Stack>
            </Stack>

          </Box>
        </Box>
      </Stack>
    </Container>
    {/* } */}
    </>
  );
}
