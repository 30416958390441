import * as React from "react";
import Typography from "@mui/material/Typography/Typography";
import Stack from "@mui/material/Stack/Stack";
import moment from "moment/moment";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListItemAvatar,
	Avatar,
	ImageListItem,
	Box,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { InsertDriveFile } from "@mui/icons-material";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
  } from "@mui/material";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";

import { AuthUserContext } from "../../../../../../providers/auth-provider";
import { DB } from "../../../../../../config/firebase/firebase";
import DownloadFileHelper from "../../../../../../utils/download-file-helper";
export default function ImageMessageComponent({ msg, type }) {
	const storage = getStorage();
	const [mediaUrl, setMediaUrl] = React.useState();
	const imagePattern = /.png|.jpg|.jpeg|.gif/;
	const moviePattern = /.mov|.mp4/;
	const { profile } = React.useContext(AuthUserContext);
	const [dialogOpen, setDialogOpen] = React.useState(false);
  
	React.useEffect(() => {
	  debugger;
	  if (msg && msg.receiver === profile?.uid && msg.read === false) {
		const msgRef = doc(DB, "messages", msg.id);
		setDoc(
		  msgRef,
		  {
			read: true,
			status: "seen",
			updatedAt: serverTimestamp(),
		  },
		  { merge: true }
		).then();
	  }
	}, [msg]);
  
	React.useEffect(() => {
	  if (msg.path && msg.path !== "") {
		getDownloadURL(ref(storage, msg.path))
		  .then((url) => {
			setMediaUrl(url);
		  })
		  .catch((error) => {
			// Handle any errors
		  });
	  }
	}, [msg]);
  
	const openDialog = () => {
	  setDialogOpen(true);
	};
  
	const closeDialog = () => {
	  setDialogOpen(false);
	};
  
	if (type === "r") {
	  return (
		<Stack direction="column" spacing={1}>
		  <Stack direction="row-reverse" spacing={1}>
			<Stack
			  direction="column"
			  spacing={1}
			  sx={{ color: "#fff", maxWidth: "70%" }}
			  justifyContent="flex-end"
			  alignItems="flex-end"
			>
			  {imagePattern.test(msg.fileName) ? (
				<ImageListItem>
				  <img
					src={mediaUrl}
					srcSet={mediaUrl}
					alt={msg?.fileName}
					loading="lazy"
					style={{
					  objectFit: "contain",
					  width: 321,
					  height: 200,
					  borderRadius: "12px 0px 12px 12px",
					  cursor: "pointer",
					  border: "1px solid #ddd",
					}}
					width="90%"
					onClick={openDialog}
				  />
				</ImageListItem>
			  ) : (
				<video
				  src={mediaUrl}
				  alt={msg?.fileName}
				  controls
				  style={{
					objectFit: "contain",
					width: 321,
					borderRadius: "12px 0px 12px 12px",
					border: "1px solid #ddd",
				  }}
				  height={200}
				/>
			  )}
			</Stack>
		  </Stack>
		  <Stack
			direction="row"
			spacing={0.5}
			alignItems="center"
			justifyContent="flex-end"
		  >
			<Typography sx={{ fontSize: "12px" }}>
			  {msg.createdAt &&
				moment(msg.createdAt.seconds * 1000).format(
				  `${
					moment(msg.createdAt.seconds * 1000).isSame(new Date(), "day")
					  ? "hh:mm a"
					  : "D-M-YYYY | hh:mm a"
				  }`
				)}
			</Typography>
			{msg.read === true ? (
			  <DoneAllOutlinedIcon style={{ color: "#4acfee", fontSize: 18 }} />
			) : (
			  <DoneAllOutlinedIcon style={{ color: "#ccc", fontSize: 18 }} />
			)}
		  </Stack>
		  <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth>
			<DialogContent>
			  <img
				src={mediaUrl}
				alt={msg?.fileName}
				style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
			  />
			</DialogContent>
			<DialogActions>
			  <Button onClick={closeDialog}>Close</Button>
			</DialogActions>
		  </Dialog>
		</Stack>
	  );
	} else {
	  return (
		<Stack direction="column" spacing={1}>
		  <Stack direction="row" spacing={1}>
			<Stack
			  direction="column"
			  spacing={1}
			  sx={{ maxWidth: "70%" }}
			//   justifyContent="flex-end"
			//   alignItems="flex-end"
			  
			>
			  {imagePattern.test(msg.fileName) ? (
				<ImageListItem>
				  <img
					src={mediaUrl}
					srcSet={mediaUrl}
					alt={msg?.fileName}
					loading="lazy"
					style={{
    objectFit: "contain",
	width: 321,
	height: 200,
    borderRadius: "0px 12px 12px 12px",
    border: "1px solid #ddd",
    cursor: "pointer",
  }}
					onClick={openDialog}
				  />
				</ImageListItem>
			  ) : (
				<video
				  src={mediaUrl}
				  alt={msg?.fileName}
				  controls
				  style={{
					objectFit: "contain",
					width: 321,
					height: 200,
					borderRadius: "0px 12px 12px 12px",
					border: "1px solid #ddd",
				  }}
				  height={300}
				/>
			  )}
			</Stack>
		  </Stack>
		  <Stack
			direction="row"
			spacing={0.5}
			alignItems="center"
			justifyContent="flex-start"
		  >
			<Typography variant="caption" color="secondary">
			  {msg.createdAt &&
				moment(msg.createdAt.seconds * 1000).format(
				  `${
					moment(msg.createdAt.seconds * 1000).isSame(new Date(), "day")
					  ? "hh:mm a"
					  : "D-M-YYYY | hh:mm a"
				  }`
				)}
			</Typography>
		  </Stack>
		  <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth>
			<DialogContent>
			  <img
				src={mediaUrl}
				alt={msg?.fileName}
				style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
			  />
			</DialogContent>
			<DialogActions>
			  <Button onClick={closeDialog}>Close</Button>
			</DialogActions>
		  </Dialog>
		</Stack>
	  );
	}
  }
  