import {
	Grid,
	Link,
	Stack,
	Typography,
	Box,
	Avatar,
	Portal,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField, Button
} from "@mui/material";
import React from "react";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import StartupOnBoardingValidation from "../../../../../validations/startup/startup-profile";
import CloseIcon from "@mui/icons-material/Close";
import {useFormik} from "formik";
import {doc, updateDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {ArrowBack} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
const styleFont = {
	backgroundColor: "#F6F8FA",
	padding: "8px 15px",
	borderRadius: "5px",
	fontSize: 14,
};

export default function StartupTeam({ profile }) {
	const userContext = React.useContext(AuthUserContext)
	const [open, setOpen] = React.useState(false);
	const showEditPattren = new RegExp('/startup/profile');
	const [openAdd,
		setOpenAdd] = React.useState(false);
	const formikFirst = useFormik({
		initialValues:  StartupOnBoardingValidation.TeamMemberInitialValues,
		validationSchema:
		StartupOnBoardingValidation.TeamMemberSchema,
		onSubmit: async (values) => {
			profile?.startup?.teamMembers?.push(values);
			const userRef = doc(DB, "users", userContext?.profile?.uid);
			await updateDoc(userRef, {
				"startup.teamMembers": profile?.startup?.teamMembers
			});
			setOpenAdd(false)
			formikFirst.resetForm({...StartupOnBoardingValidation.TeamMemberInitialValues})

		},
	});
	return (
		<>

			<Stack
				direction={"column"}
				spacing={3}
				sx={{


					borderRadius: "15px",
					backgroundColor: "#fff",
					minHeight: 100,

				}}
			>
				{
					userContext?.profile?.uid === profile?.uid && showEditPattren.test(window.location.pathname) ?
					<Stack direction={"row"} justifyContent="flex-end"
						   alignItems="center" sx={{width: '100%'}} >
						<Typography variant={"h6"} sx={{fontWeight:'600'}} flex={1}>Team</Typography>

						<Button variant={"contained"} color={"success"} size={"small"} startIcon={<AddIcon/>} onClick={() => {
							setOpenAdd(true);
						}} sx={{px:2}}>Add More</Button>

					</Stack> : <Typography variant={"h6"} sx={{fontWeight:'600'}} flex={1}>Team</Typography>

				}
				<Grid container gap={4} direction={'column'} alignItems={"flex-start"}>
					<Grid>
						<Stack
							direction={"column"}
							spacing={1}
							sx={{ position: "relative", right: -2, minWidth: 300, cursor: 'pointer' }}
							alignItems="flex-start"
							maxWidth={'80px'}
						>
							{
								userContext?.profile?.uid === profile?.uid && showEditPattren.test(window.location.pathname) &&
								<Stack direction={'row'} />
								}
							<Box
								sx={{
									border: "1px solid #204488",
									p: 0.75,
									borderRadius: "50%",
									width: "fit-content",
								}}
							>
								<Avatar
									alt={profile?.startup?.founder.founderName}
									src={
										profile?.photoURL || profile?.startup?.founder?.photoURL
									}
									sx={{ width: 51, height: 51 }}
								>
									<Typography variant={"h4"} >
										{profile?.startup?.founder.founderName.charAt(0)}
									</Typography>
								</Avatar>
							</Box>
							<Typography variant={"h6"} sx={{ textAlign: "flex-start" }}>
								{profile?.startup?.founder.founderName}
								<br />
								<font
									size={2}
									style={{
										backgroundColor: "#F6F8FA",
										// padding: "5px 10px",
										borderRadius: "6px",
										fontWeight: 300,
									}}
								>
									Founder
								</font>
							</Typography>
						</Stack>
					</Grid>
					{profile?.startup?.teamMembers && profile?.startup?.teamMembers?.length > 0 && profile?.startup?.teamMembers?.map &&
						profile?.startup?.teamMembers?.map((member, i) => (
							<Grid key={i}>
								<Stack
									maxWidth={'70px'}
									direction={"column"}
									spacing={1}
									sx={{ position: "relative", right: 15, minWidth: 200, cursor: 'pointer' }}
									alignItems="center"

								>
									
									<Box

										sx={{
											border: "1px solid #204488",
											p: 0.75,
											borderRadius: "50%",
											width: "fit-content",
										}}
									>
										<Avatar
											alt={member?.name}
											src={''}
											sx={{ width: 51, height: 51 }}
										>
											<Typography variant={"h4"} >
												{member?.name.charAt(0)}
											</Typography>
										</Avatar>
									</Box>
									<Typography variant={"h6"} sx={{ textAlign: "center" }}>
										{member?.name}
										<br />
										<font
											size={2}
											style={{
												backgroundColor: "#F6F8FA",
												// padding: "5px 10px",
												borderRadius: "6px",
												fontWeight: 300,
											}}
										>
											{member?.role}
										</font>
									</Typography>
									{
											userContext?.profile?.uid === profile?.uid && showEditPattren.test(window.location.pathname) &&
											<Stack direction={'row'} alignItems={"flex-start"} sx={{}}>
												<Box sx={{flex:1}}/>
												<Button variant={"text"} size={'small'} color={"warning"}  onClick={() => setOpen(true)}>
													delete
												</Button>
											</Stack>
										}
								</Stack>

								<Portal>
									<Dialog
										open={open}
										onClose={() => {
											setOpen(false);
										}}
										aria-labelledby="alert-dialog-achive"
										aria-describedby="alert-dialog-achive"
										maxWidth={"sm"}>
										<DialogContent
											sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
											<Stack
												direction={"column"}
												sx={{ width: "100%" }}
												justifyContent="center"
												alignItems="flex-end">
												<IconButton
													onClick={() => {
														setOpen(false);
													}}>
													<CloseIcon />
												</IconButton>
											</Stack>
											<Stack
												direction={"column"}
												sx={{ width: "100%" }}
												spacing={2}>
												<Stack
													direction={"column"}
													sx={{ width: "100%", }} alignItems={"center"}>
													<img src={"/assets/delete-confirmation.png"} height={74} width={80}></img>
												</Stack>
												<Typography
													component="span"
													variant="h6"
													color="text.primary"
													sx={{
														textAlign: "center",
														flex: 1,
														padding: 0,
														fontWeight: 500,
													}}>
													Delete Team Member
												</Typography>
												<Typography variant={"body2"} sx={{textAlign:'center'}}>
													Are you sure you want to delete this team member? <br/>
													This action cannot be undone.
												</Typography>
												<br/>
												<Stack
													direction={"row"}
													sx={{ width: "100%" }}
													justifyContent="center"
													alignItems="center" spacing={2}>
													<Button
														variant={"outlined"}
														sx={{ px: 5,  width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
														onClick={() => {
															setOpen(false)
														}}>
														No
													</Button>
													<Button
														variant={"contained"}
														sx={{ px: 5,  width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
														onClick={async () => {
															profile?.startup?.teamMembers?.splice(i, 1);
															const userRef = doc(DB, "users", userContext?.profile?.uid);
															await updateDoc(userRef, {
																"startup.teamMembers": profile?.startup?.teamMembers
															});

															// remove()
															setOpen(false)
														}}>
														yes
													</Button>
												</Stack>

												<Box />
											</Stack>
										</DialogContent>
									</Dialog>
								</Portal>
							</Grid>

						))
					}


				</Grid>



			</Stack>
			<Portal>
				<Dialog
					open={openAdd}
					fullScreen={true}
					fullWidth={true}
					onClose={() => {
						setOpenAdd(false);
					}}
					aria-labelledby="alert-dialog-achive"
					aria-describedby="alert-dialog-achive"
					maxWidth={"md"}>
					{/*<DialogTitle sx={{boxShadow:'0px 2px 4px rgba(0, 0, 0, 0.1)'}}>*/}
						<DialogTitle id="alert-dialog-title" sx={{px: 0, textTransform: 'capitalize'}}>
							 <IconButton onClick={() => setOpenAdd(false)}>
								<ArrowBack sx={{fontSize: 30}} color={'#000'}/>
							</IconButton>
							{`Add New Team Member`}
						</DialogTitle>
						{/*<Stack
							direction={"row"}
							sx={{ width: "100%" }}
							justifyContent="flex-start"
							alignItems="center">
							<Typography variant={"h6"} sx={{flex: 1}}>Add New Team Member</Typography>  <IconButton
							onClick={() => {
								setOpenAdd(false);
								formikFirst.resetForm({...StartupOnBoardingValidation.TeamMemberInitialValues})

							}}>
							<CloseIcon />
						</IconButton>
						</Stack>*/}

					{/*</DialogTitle>*/}
					<DialogContent
						sx={{ mt: 2 }} >
						<Stack
							direction={"column"}
							sx={{ width: "100%" }}
							spacing={2} component={"form"} onSubmit={(e) => formikFirst.handleSubmit(e)}>
							<Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
								<br/>
								<Typography variant={'body1'}>
									Email (Add important people of the team that are part of this startup)
								</Typography>

								<TextField name={'email'}
										   value={formikFirst.values.email}
										   onChange={formikFirst.handleChange}
										   error={
											   formikFirst.touched.email &&
											   Boolean(formikFirst.errors.email)
										   }
										   helperText={
											   formikFirst.touched.email &&
											   formikFirst.errors.email
										   }
										   variant={'outlined'} fullWidth/>
							</Stack>


							<Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
								<Typography variant={'body1'}>
									Name*
								</Typography>
								<TextField name={'name'}
										   value={formikFirst.values.name}
										   onChange={formikFirst.handleChange}
										   error={
											   formikFirst.touched.name &&
											   Boolean(formikFirst.errors.name)
										   }
										   helperText={
											   formikFirst.touched.name &&
											   formikFirst.errors.name
										   }
										   variant={'outlined'} fullWidth/>
							</Stack>

							<Stack direction={'column'} spacing={1} alignItems={"flex-start"} sx={{width: '100%'}}>
								<Typography variant={'body1'}>
									Role*
								</Typography>

								<TextField name={'role'}
										   value={formikFirst.values.role}
										   onChange={formikFirst.handleChange}
										   error={
											   formikFirst.touched.role &&
											   Boolean(formikFirst.errors.role)
										   }
										   helperText={
											   formikFirst.touched.role &&
											   formikFirst.errors.role
										   }
										   variant={'outlined'} fullWidth/>
							</Stack>

							<br/>
							{/*<Stack
									direction={"row"}
									sx={{ width: "100%" }}
									justifyContent="center"
									alignItems="center" spacing={2}>
									<Button
										variant={"outlined"}
										sx={{ px: 5,  width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
										onClick={() => {
											setOpen(false)
										}}>
										No
									</Button>
									<Button
										variant={"contained"}
										sx={{ px: 5,  width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
										onClick={async () => {
											profile?.startup?.teamMembers?.splice(i, 1);
											const userRef = doc(DB, "users", userContext?.profile?.uid);
											await updateDoc(userRef, {
												"startup.teamMembers": profile?.startup?.teamMembers
											});

											// remove()
											setOpen(false)
										}}>
										yes
									</Button>
								</Stack>*/}

							<Box />
							<Stack
								direction={"row"}
								justifyContent="flex-end"
								alignItems="center"
								spacing={3}
								width={"100%"}>
								<Button
									variant={"contained"}
									color={"primary"}
									sx={{ px: 5 }}
									type={"submit"}>
									save
								</Button>
							</Stack>
						</Stack>
					</DialogContent>
				</Dialog>
			</Portal>

		</>
	);
}


// import { Grid, Link, Stack, Typography,Box , Avatar } from "@mui/material";
// import React from "react";
// import moment from "moment";
// const styleFont = {
// 	backgroundColor: "#F6F8FA",
// 	padding: "8px 15px",
// 	borderRadius: "5px",
// 	fontSize: 14,
// };

// export default function StartupTeam({ profile }) {
// 	return (
// 		<>
//         {console.log(profile)}
// 			<Typography variant={"h5"}>Team</Typography>

// 			{profile.userType === "startup" && (
//                 <Stack
//                       direction={"column"}
//                       spacing={1}
//                       justifyContent="center"
//                       sx={{ position: "relative", right: 15, minWidth: 120, cursor: 'pointer' }}
//                       alignItems="center"

//                     >
//                       <Box
//                         sx={{
//                           border: "1px solid #204488",
//                           p: 0.75,
//                           borderRadius: "50%",
//                           width: "fit-content",
//                         }}
//                       >
//                         <Avatar
//                           alt={profile?.startup?.founder.founderName}
//                           src={
//                             profile?.startup?.founder.photoURL !== ""
//                               ? profile?.startup?.founder.photoURL
//                               : profile?.photoURL
//                           }
//                           sx={{ width: 51, height: 51 }}
//                         >
//                           <Typography variant={"h4"} >
//                             {profile?.startup?.founder.founderName.charAt(0)}
//                           </Typography>
//                         </Avatar>
//                       </Box>
//                       <Typography variant={"h6"} sx={{ textAlign: "center" }}>
//                         {profile?.startup?.founder.founderName}
//                         <br />
//                         <font
//                           size={2}
//                           style={{
//                             backgroundColor: "#F6F8FA",
//                             padding: "5px 10px",
//                             borderRadius: "6px",
//                             fontWeight: 300,
//                           }}
//                         >
//                           Founder
//                         </font>
//                       </Typography>
//                     </Stack>
// 			)}
// 		</>
// 	);
// }
