import axios from "axios";


export default function LinkedinAccessToken(code) {
    return axios.post(
    'https://us-central1-astranovaapp.cloudfunctions.net/api/access-linkedin',
    // 'http://localhost:5001/astranovaapp/us-central1/api/access-linkedin',
    {code},
    {headers: {'Content-Type': 'application/json'}}
  )

}