import React from "react";
import {
  Button,
  Container, MenuItem,
  Stack,
  TextField,Switch,FormControlLabel, Typography
} from "@mui/material";
import { useFormik } from "formik";
import ExportOnboardingValidation from "../../../../validations/expert/onboarding-profile";
import { Box } from "@mui/system";

export default function InvestorDetails({ cbBack, cbNext, data }) {


  const formikFirst = useFormik({
    initialValues: data
      ? data
      : ExportOnboardingValidation.InvestorDetailsFormValues,
    validationSchema:
    ExportOnboardingValidation.InvestorDetailsForm,
    onSubmit: async (values) => {

      cbNext({
        ...values
      });
    },
  });

  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>

        <Stack direction={"column"} spacing={5}>

          <Stack direction={"column"} sx={{paddingLeft : "60px"}} spacing={5}>
              <Stack
                direction={"column"}
                spacing={1.5}>
                <Typography
                  variant={
                    "body2"
                  }
                  sx={{
                    textAlign:
                      "left",
                  }}>
                  Are you an Angel Investor?
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center">
                  <Typography>
                    No
                  </Typography>
                  <FormControlLabel
                    label={""}
                    control={
                      <Switch
                        name={
                          "areYouAnAngelInvestor"
                        }
                        checked={
                          formikFirst
                            .values
                            .areYouAnAngelInvestor
                        }
                        onChange={
                          formikFirst.handleChange
                        }
                        error={
                          formikFirst
                            .touched
                            .areYouAnAngelInvestor &&
                          Boolean(
                            formikFirst
                              .errors
                              .areYouAnAngelInvestor
                          )
                        }
                      />
                    }
                  />
                  <Typography>
                    Yes
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
        </Stack>


      </Container>
      <Box/>
      <Box/>
      <Box/>
      <Box/>
      <Box/>
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems="center"
        spacing={3}
        width={"100%"}>
        <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 7 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
          onClick={cbBack}>
          Back
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{ px: 7 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
          type={"submit"}>
         {formikFirst.values.areYouAnAngelInvestor ? 'Next' : 'Submit'}
        </Button>
      </Stack>
    </Stack>
  );
}
 