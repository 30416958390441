import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton, Portal, useMediaQuery} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import EditInvestorDetailsForm from "../../../../../screens/investor/edit/edit-investor-details-form";
import {getAuth, getIdToken, updateProfile} from "firebase/auth";
import EditStartupDetailsForm from "../../../../../screens/startups/edit/edit-startup-details-form";
import EditStartupFounderDetailsForm from "../../../../../screens/startups/edit/edit-startup-founder-details-form";
import {useNavigate} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import UpdateUser from "../../../../../api/update-user";

export default function EditFounderDetailsComponent({profile,open, handleClose, cb }) {
  const regexAdmin = /admin\/startup\/startup-founder-profile/
  
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  
  const copyProfile = regexAdmin.test(window.location.pathname) ? profile : {...context.profile}
  
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? profile.uid : user.uid);

  const auth = getAuth();
  const navigator = useNavigate()
  const [isLoading, setLoading] = React.useState(false);
  return (
    <Portal>
        <Dialog
            fullScreen={!matches ? true: false}
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
                {!matches && <IconButton onClick={() => handleClose()}>
                    <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
                </IconButton>}
                {"Edit Founder Details"}
            </DialogTitle>
            <DialogContent sx={{px: !matches ? 0 : ''}}>
          <br/>
          <EditStartupFounderDetailsForm
            data={{
              founderName: profile?.startup?.founder?.founderName,
              description: profile?.startup?.founder?.description,
              countryState: profile?.countryState
          }}
            cbBack={handleClose}
            cbNext={async (data) => {
              setLoading(true);
              const {founderName, description, countryState} = data
              await setDoc(
                userRef,
                {
                  displayName: founderName,
                  startup: {
                    ...profile?.startup ,
                    founder: {
                      ...profile?.startup?.founder,
                      founderName,
                      description
                    }, countryState
                  },
                  countryState,
                  updatedById:auth?.currentUser?.uid,
                  updatedBy: regexAdmin.test(window.location.pathname) ? 'admin' : 'user',
                  updatedAt: new Date().getTime()
                },
                { merge: true }
              )
              if (regexAdmin.test(window.location.pathname)) {
                const idToken = await getIdToken(auth.currentUser);
                UpdateUser({ uid: profile?.uid,
                  formData: {displayName: founderName},
                  reqType: 'LOL'}, idToken).then()
                cb ({
                  ...copyProfile,
                  displayName: founderName,
                  startup: {
                    ...profile?.startup ,
                    founder: {
                      ...profile?.startup?.founder,
                      founderName,
                      description
                    } },
                  countryState
                })
              } else {
                await updateProfile(auth.currentUser, {displayName: founderName})
                await setProfile({
                  ...copyProfile,
                  displayName: founderName,
                  startup: {
                    ...profile?.startup ,
                    founder: {
                      ...profile?.startup?.founder,
                      founderName,
                      description
                    } },
                  countryState
                })
                handleClose()
                navigator(`${!matches ? '/m' : ''}/${profile?.userType}/profile/founder`, {
                  state: {
                    profile:{
                      ...context.profile,
                      displayName: founderName,
                      startup: {
                        ...profile?.startup ,
                        founder: {
                          ...profile?.startup?.founder,
                          founderName,
                          description
                        } },
                      countryState
                    },
                    founder:  {
                      ...profile?.startup?.founder,
                      founderName,
                      description
                    }
                  }
                })
              }
              setLoading(false)
              
            }}
            isLoading={isLoading}
          />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
