import React from "react";
import {
	Avatar,
	Grid,
	Paper,
	Stack,
	styled,
	Typography,
	Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import ListItemStartConversation from "./list-item-start-conversation";
import MemberFavourite from '../../screens/common/MemberFav'

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "center",
	backgroundColor: "#fff",
	color: theme.palette.text.secondary,
	boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
	borderRadius: "15px",
	minHeight: 348,
}));
export default function InvestorListItem({ data, href }) {
	const navigator = useNavigate();
	const { profile } = React.useContext(AuthUserContext);

	return (
		<Grid
			item
			lg={4}
			md={4}
			sm={6}
			xs={12}>
			<Item>
				<Stack direction={"column"} spacing={2} sx={{ width: "100%" }} justifyContent="center"
					   alignItems="center">
					<Stack sx={{ ml: "90%", mb:"-15%", mt:"-2.5%" }}>
						<MemberFavourite data={{ ...data,card: "investor" }} />
					</Stack>
					<Box
					sx={{
							position: "relative",
							top:"40px",
							bottom : "20px",
							width: "280px",
							height: "200px",
							background: "#F6F8FA",
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px"
						}}
					>
					<Stack

						onClick={() => {
							if (data.userType === "investor") navigator(href);
							else {
								navigator(
									`/${profile?.userType}/investor-list/founder-profile`,
									{
										state: {
											founder: {
												...data?.startup.founder,
												startupName:
													data?.startup.founder?.startupName !==
													""
														? data?.startup.founder?.startupName
														: data?.startup?.startupName,
												photoURL:
													data?.startup.founder.photoURL !== ""
														? data?.startup.founder.photoURL
														: data?.photoURL,
											},
										},
									}
								);
							}
						}}
						direction={"column"}
						spacing={2}
						justifyContent="center"
						alignItems="center"
						style={{cursor:"pointer"}}
						>
						<Box
							sx={{
								border: "1px solid #204488",
								p: 0.75,
								borderRadius: "50%",
								marginTop : "-40px"
							}}>
							<Avatar
								alt="Remy Sharp"
								src={
									data?.userType === "startup" &&
									data?.startup?.founder?.photoURL !== ""
										? data?.startup?.founder?.photoURL
										: data?.photoURL
								}
								sx={{ width: 92, height: 92 }}>
								<Typography variant={"h5"}>
									{data?.userType === "startup"
										? data?.startup?.founder?.founderName?.charAt(
												0
										  )
										: data?.displayName &&
										  data?.displayName.charAt(0)}
								</Typography>
							</Avatar>
						</Box>
						<Typography
							variant={"h6"}
							sx={{ fontWeight: 600, mb: 0, lineHeight: 1.2 }}>
							{data?.userType === "startup"
								? data?.startup?.founder?.founderName
								: data?.displayName}{" "}
							<br />
							<font size={3} style={{ fontWeight: 300 }}>
								Investor
							</font>
						</Typography>

						<Box />


					</Stack>
					{
						(data.uid !== profile?.uid) ?  (
							<ListItemStartConversation data={{...data, creatorData: data}}/>

						):<Button size={'small'} variant={"outlined"} color={'secondary'} sx={{px: 2, width:120}} onClick={() => {navigator(`/${profile?.userType}/profile`)
								}}>My profile</Button>
					}
					</Box>
					<Box />
					<Box />
					<Box />
					{data?.investor && data?.investor?.investorType && (
						<Stack  direction={"row"} spacing={2} sx={{width: '100%'}}>
							<Typography
								variant={"body2"}
								sx={{
									fontWeight: "bold",
									flex: 1,
									textAlign: "left",
									width:"30%"
								}}>
								Investor Type
							</Typography>
							<Typography variant={"body2"} >
								{data?.investor?.investorType}
							</Typography>
						</Stack>
					)}
					{data?.investor &&
						data?.investor?.investmentStage&&
						data?.investor?.investmentStage.length > 0 &&  (
						<Stack  direction={"row"} spacing={2} sx={{width: '100%'}}>
							<Typography
								variant={"body2"}
								sx={{
									fontWeight: "bold",
									flex: 1,
									textAlign: "left",
									
									
								}}>
								Investor Stage
							</Typography>
							<Typography variant={"body2"} sx={{ width: 130 }}
									noWrap={true}>
								{/* {data?.investor?.investmentStage} */}
								{data?.investor?.investmentStage.map((d, i) =>
									d !==
									data?.investor?.investmentStage[
										data?.investor?.investmentStage.length - 1
									]
										? `${d}, `
										: d
								)}
							</Typography>
						</Stack>
					)}
					{data?.startup && (
						<Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
							<Typography
								variant={"body2"}
								sx={{
									fontWeight: "bold",
									flex: 1,
									textAlign: "left",
								}}>
								Investor Type
							</Typography>
							<Typography variant={"body2"} sx={{}}>
								{"Angel"}
							</Typography>
						</Stack>
					)}
					{data?.startup && data?.startup?.founder?.stage && (
						<Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
							<Typography
								variant={"body2"}
								sx={{
									fontWeight: "bold",
									flex: 1,
									textAlign: "left",
								}}>
								Investor Stage
							</Typography>
							<Typography
								variant={"body2"}
								sx={{ width: 180, textAlign: "right" }}
								noWrap={true}>
								{data?.startup?.founder?.stage.map((d, i) =>
									d !==
									data?.startup?.founder?.stage[
										data?.startup?.founder?.stage.length - 1
									]
										? `${d}, `
										: d
								)}
							</Typography>
						</Stack>
					)}

					{data?.investor && data?.investor?.investorStage && (
						<Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
							<Typography
								variant={"body2"}
								sx={{
									fontWeight: "bold",
									flex: 1,
									textAlign: "left",
								}}>
								Investor Stage
							</Typography>
							<Typography variant={"body2"} sx={{}}>
								{data?.investor?.investorStage}
							</Typography>
						</Stack>
					)}
				</Stack>
			</Item>
		</Grid>
	);
}
