import React from "react";
import { DB } from "../../../config/firebase/firebase";
import {
	styled,
	Container,
	Stack,
	Typography,
	Avatar,
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogContent, DialogActions, DialogContentText, Badge
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { a11yProps, TabPanel } from "../../../components/common/tabs-custom";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PostItem from "../../../components/common/timeline/post-item";
import UploadFile from "../../../utils/upload-file";
// import {AuthUserContext} from "../../providers/auth-provider";
import {getAuth, getIdToken, updateProfile} from "firebase/auth";
import {useLocation, useNavigate} from "react-router-dom";
import {AuthUserContext} from "../../../providers/auth-provider";
import InvestorPortfolio from "../../../components/common/profile/investor-portfolio";
import StartupAbout from "../../../components/common/profile/startup-about";
import InvestorDescription from "../../../components/common/profile/investor-description";
import ExpertDescription from "../../../components/common/profile/expert-description";
import BusinessDeveloperDescription from "../../../components/common/profile/business-developer-description";
import ServiceProviderDescription from "../../../components/common/profile/service-provider-description";
import StartupDescription from "../../../components/common/profile/startup-description";
import FounderAbout from "../../../components/common/profile/founder-about";
import {
	collection,
	getDocs,
	where,
	query,
	doc,
	getDoc,
	setDoc, updateDoc
} from "firebase/firestore";
import StartChat from "../../../components/common/start-chat";
import FounderInvestorAbout from "../../../components/common/profile/founder-investor-about";
import InvestorPortfolioForm from "../../investor/onboarding-forms/portfolio-form";
import PortfoliosListView from "../../investor/onboarding-forms/portfolios-list-view";
import {EditSharp} from "@mui/icons-material";
import EditFounderDetailsComponent
	from "../../../components/common/profile/edit/startup/edit-founder-details-component";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import PostNotification from "../../../api/post-notification";
import OrganisationAbout from "../../../components/common/profile/organisation-about";
import EditOrganisationDetailsComponent from "../../../components/common/profile/edit/service-provider/edit-organisation-details-component";

const StyledTabs = styled((props) => (
	<Tabs
		sx={{ position: "relative", left: 0, top: 15 }}
		{...props}
		TabIndicatorProps={{
			children: <span className="MuiTabs-indicatorSpan" />,
		}}
	/>
))({
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
	},
	"& .MuiTabs-indicatorSpan": {
		maxWidth: 40,
		width: "100%",
		backgroundColor: "none",
	},
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: "none",
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(16),
		marginRight: theme.spacing(1),
		borderRadius: "30px",
		color: "#000",
		"&.Mui-selected": {
			color: "#fff",
			backgroundColor: "#233DFF",
		},
		"&.Mui-focusVisible": {
			backgroundColor: "rgba(100, 95, 228, 0.32)",
		},
	})
);
export default function ServiceProviderOrganisationProfile({ data, href }) {
	debugger;
	const userContext = React.useContext(AuthUserContext);
	const { setProfile, setUser, user } =
		React.useContext(AuthUserContext);
	const contextUser = React.useContext(AuthUserContext)
	const { organisation, profile } = useLocation().state;
	const [portfolioIndex, setPortfolioIndex] = React.useState();
	const [imageURL, setImageURL] = React.useState();
	const confirmed = React.useRef(false);
	const profileImage = React.useRef();
	const [open, setOpen] = React.useState(false);
	const [steps, setSteps] = React.useState(0);
	const userRef = doc(DB, "users", user.uid);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [portfolios, setPortfolios] = React.useState(
		profile?.userType === 'investor' ? profile?.investor?.portfolio : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
			: profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.portfolio
	);
	const [posts, setPosts] = React.useState([]);
	const [index, setIndex] = React.useState(-1);
	// const [profile, setProfile] = React.useState(null);
	const [value, setValue] = React.useState(0);

	function handleDialogOpen() {
		setDialogOpen(true);
	}
	function handleDialogClose() {
		setDialogOpen(false);
	}
	const navigator = useNavigate()
	const Input = styled("input")({
		display: "none",
	});
	async function updateProfilePicture(url) {
		try {
			const auth = getAuth();
			console.log(url);
			const displayName = organisation?.nameOfOrganization;
			await updateProfile(auth.currentUser, {
				displayName: organisation.nameOfOrganization,
				logoURL: url
			});
			const data = auth.currentUser.toJSON();
			debugger
			setUser({ ...user });
			const userRef = await doc(DB, "users", auth.currentUser.uid);
			let dat;
			dat = await updateDoc(userRef, {
				"serviceProvider.organisationDetails.logoURL": url
			});
			Object.assign(organisation, {...organisation, logoURL: url})
			Object.assign(profile, { ...profile, serviceProvider:{...profile.serviceProvider, organisationDetails: {...profile.serviceProvider.organisationDetails,logoURL: url}} })
			await setProfile({ ...profile, serviceProvider:{...profile.serviceProvider, organisationDetails: {...profile.serviceProvider.organisationDetails,logoURL: url}} });
			PostNotification({
				type: 'update-profile-picture',
				imageURL: url,
				message: `Your profile picture has been updated`,
				uid: user.uid
			}).then()
			confirmed.current = true;
		} catch (err) {
			console.log(err.message);
		}
	}
	function UploadProfile() {
		debugger
		return (

			<div>
				<Dialog
					open={dialogOpen}
					onClose={handleDialogClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">
						{organisation.nameOfOrganization}
					</DialogTitle>
					<DialogContent sx={{ height: 400, width: 600 }}>
						<Avatar
							key={imageURL}
							src={imageURL ? imageURL : organisation?.logoURL}
							alt={organisation?.organisationName}
							sx={{
								width: 152,
								height: 152,
								ml: 25,
							}}
							component={"span"}
						/>
					</DialogContent>
					<DialogActions
						sx={{ justifyContent: "start", height: 100 }}>
						<IconButton sx={{ width: 150 }} variant="outlined">
							<label htmlFor={"profile-image"}>
								<Stack direction={"column"}>
									<CameraAltIcon
										fontSize="large"
										sx={{ ml: 2, mb: 2, cursor: "pointer" }}
									/>
									<Input
										accept="image/*"
										id="profile-image"
										multiple={false}
										type="file"
										onChange={(e) => {
											confirmed.current = false;
											const fileReader = new FileReader();
											fileReader.readAsDataURL(
												e.target.files[0]
											);
											fileReader.addEventListener(
												"load",
												async function () {
													const file =
														e.target.files[0];

													const path = `profile-image/${file.name}`;
													const imageObject = {
														progress: 0,
														src: this.result,
														path,
														file: e.target.files[0],
														size: (
															e.target.files[0]
																?.size /
															1024 /
															1024
														).toFixed(2),
													};
													profileImage.current =
														imageObject;
													UploadFile(file, path).then(
														(url) => {
															console.log(url);
															setImageURL(url);
														}
													);
												}
											);
										}}
									/>
									<DialogContentText sx={{ fontSize: 15 }}>
										Add Logo
									</DialogContentText>
								</Stack>
							</label>
						</IconButton>
						<IconButton
							sx={{ width: 150, mr: 12 }}
							variant="outlined"
							onClick={() => {
								//Faisal-Comment - profileImage.current = "";
								setImageURL(" ");

								// updateProfilePicture("");
								confirmed.current = true;
								//handleDialogClose();
							}}>
							<Stack direction={"column"}>
								<DeleteIcon
									fontSize="large"
									sx={{ ml: -5, mb: 2 }}
								/>

								<DialogContentText
									sx={{ fontSize: 15, ml: -9 }}>
									Remove Logo
								</DialogContentText>
							</Stack>
						</IconButton>
						<Button
							sx={{ width: 150, ml: 20 }}
							onClick={() => {


								//Faisal-Comment - //profileImage.current = {
								// 	...profileImage,
								// 	photoURL: imageURL,
								// };

								updateProfilePicture(imageURL);


								//profileImage.current = "";

								handleDialogClose();
								//updateProfilePicture("");
							}}
							autoFocus
							variant="contained">
							Save Logo
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	React.useEffect(() => {
		debugger
		if (!imageURL) {
			setImageURL(imageURL ? imageURL : organisation?.logoURL !=='' ? organisation.logoURL : profile.serviceProvider.organisationDetails.logoURL );
		}

			const q =
				query(
				collection(DB, "timelines"),
				where("uid", "==", profile?.uid));

			getDocs(q).then((querySnapshot) => {
				if (querySnapshot.size > 0) {
					const list = [];
					querySnapshot.forEach((doc) => {
						list.push({ id: doc.id, ...doc.data() });

						console.log(doc.id, " => ", doc.data());
					});
					setPosts(list);
				}
			});

	}, []);
	return (
		<Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
			{/* {console.log(startup)} */}
			{organisation && (
				<Stack direction={"column"} spacing={5}>
					<Stack
						direction={"column"}
						spacing={0.5}
						sx={{
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px",
							backgroundColor: "#fff",
							minHeight: 100,
							p: 2,
							pb: 0,
						}}>
						<Box
							sx={{
								height: 120,
								width: "100%",
								backgroundColor: "#F6F8FA",
								borderRadius: "15px",
							}}
						>
							<Box
								sx={{
									border: "1px solid #204488",
									p: 0.75,
									borderRadius: "50%",
									width: "fit-content",
									position: "relative",
									left: 35,
									top: 60,
								}}>
								<label>

									<Badge
										overlap="circular"
										sx={{ width: 92, height: 92 }}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "right",
										}}
										badgeContent={
											profile?.uid === user?.uid &&
											<Button
												onClick={handleDialogOpen}
												sx={{ml: 1, mt: 1}}
												color={"primary"}
												component={"span"}>
												<AddCircleIcon/>
											</Button>

										}>
										{profile?.uid === user?.uid &&
											<UploadProfile/>
										}
								<Avatar
									alt={organisation?.nameOfOrganization}
									src={imageURL ? imageURL : organisation?.logoURL}
									sx={{ width: 92, height: 92 }}>
									<Typography variant={"h3"}>
										{organisation?.nameOfOrganization.charAt(0)}
									</Typography>
								</Avatar>
									</Badge>
								</label>
							</Box>
						</Box>
						{console.log(profile)}
						

						<Stack
							direction={"row"}
							spacing={3}
							sx={{ position: "relative", top: 50 }}>
							<div />
							<Stack direction={"column"} spacing={2} sx={{width: '100%'}}>
								<Stack direction={"row"} spacing={3}>
									<Typography
										variant={"h5"}
										sx={{ marginTop: "2.5px" }}>
										{organisation?.nameOfOrganization}
									</Typography>
									<Box
										sx={{
											backgroundColor: "#E0E3FF",
											px: 3,
											py: 1,
											borderRadius: "19.5px",
										}}>
										<Typography
											style={{
												fontWeight: 500,
												textTransform: "capitalize",
											}}>
											{"Service Provider"}
										</Typography>
									</Box>

									<StyledTabs
                                sx={{
								
                                display: 'grid',
                                justifyContent: 'right',
                                position: 'absolute' ,
                                right: 0
								}}

                                >
								{profile?.uid !== user?.uid &&   <StartChat
									sx={{color:'white',backgroundColor: "#233DFF"}}
									data={{...profile, creatorData:profile }} props={{...a11yProps(4)}}/>
								}
								</StyledTabs>
								</Stack>
								<Stack
									direction={"row"}
									spacing={1}
									sx={{ position: "relative", top:0 }}>
									<Typography variant={'body2'} color={"grey"} style={{flex: 1}}>
										{profile?.countryState}
									</Typography>
									{profile?.uid === user?.uid  && 	<IconButton onClick={() => setOpen(true)}>
										<EditSharp />
									</IconButton>
									}	{contextUser?.profile?.userType === 'admin' && 	<IconButton onClick={() => setOpen(true)}>
										<EditSharp />
									</IconButton>
									}
								</Stack>

								{
								organisation && organisation?.organizationDescription && (
									<Typography
										variant={"body1"}
										color={"secondary"}
										sx={{
											width: "calc(100% - 10%)",
											position: "relative",
											top: -10,
										}}>
										{organisation?.organizationDescription}
									</Typography>
								)}

							</Stack>
						</Stack>
						{ <EditOrganisationDetailsComponent profile={profile} open={open} handleClose={() => setOpen(false)}
															callback={(s) => {
																
																setOpen(false);
																const path = `/admin/organisation-profile`
																navigator(path,{
																	state: {
																		profile: s,
																		organisation: {
																			...s?.serviceProvider
																				.organisationDetails,
																			nameOfOrganisation:
																				s?.serviceProvider
																					.organisationDetails
																					?.nameOfOrganization !==
																				""
																					? s
																						?.serviceProvider
																						.organisation
																						?.nameOfOrganization
																					: s
																						?.serviceProvider
																						?.organisationDetails
																						?.nameOfOrganization,
																			logoURL:
																				s?.serviceProvider
																					.organisationDetails
																					.logoURL !==
																				""
																					? s
																						?.serviceProvider
																						.organisationDetails
																						.logoURL
																					: '',
																		},
																	},
																});
															}}
						
						/>}
						<Stack direction={"row"} sx={{width:'100%'}} justifyContent="center"
                     alignItems="flex-end">

							<StyledTabs
							sx={{
								paddingLeft:'35px'
							}}
								value={value}
								onChange={handleChange}
								aria-label="styled tabs example"
							>
								<StyledTab label="ABOUT" {...a11yProps(0)} />
  
							</StyledTabs>
						
						<div style={{flex: 10}}/>
							<Stack
								direction={"column"}
								spacing={1}
								justifyContent="center"
								sx={{ position: "relative", minWidth: 120, cursor: 'pointer' 
								,paddingTop:'70px'}}
								alignItems="center"

								onClick={() => {
									if (userContext.profile.uid !== profile.uid && userContext?.profile?.userType !== 'admin') {
										navigator(`/${userContext.profile.userType}/service-provider-details/${profile?.uid}`)
									}
										else {
											const path = userContext?.profile?.userType === 'admin' ? `/admin/user-details`:`/${profile?.userType}/profile`
												navigator(path, {
													state: {
														profile:profile,
														organisation: {
															...data?.serviceProvider.organisationDetails,
															linkedIn: data?.serviceProvider.organisationDetails?.linkedIn,
															website: data?.serviceProvider.organisationDetails?.website,
															nameOfOrganization:
																data?.serviceProvider.organisationDetails?.nameOfOrganization !== ""
																	? data?.serviceProvider.organisationDetails?.nameOfOrganization
																	: data?.startup?.serviceProvider,
															logoURL:
																data?.serviceProvider.organisationDetails.logoURL !== ""
																	? data?.serviceProvider.organisationDetails.logoURL
																	: '',
														},
													},
												});
										}
									}
								}
							>
								<Box
									sx={{
										border: "1px solid #204488",
										p: 0.75,
										borderRadius: "50%",
										width: "fit-content",
									}}
								>
									<Avatar
										alt={profile?.serviceProvider?.name}
										src={
											profile?.serviceProvider?.photoURL !== ""
												? profile?.serviceProvider?.photoURL
												: profile.photoURL
										}
										sx={{ width: 51, height: 51 }}
									>
										<Typography variant={"h4"} >
											{profile?.displayName.charAt(0)}
										</Typography>
									</Avatar>
								</Box>
								<Typography variant={"h6"} sx={{ textAlign: "center" }}>
									{profile?.displayName}
									<br />
									<font
										size={2}
										style={{
											backgroundColor: "#F6F8FA",
											padding: "5px 10px",
											borderRadius: "6px",
											fontWeight: 300,
										}}
									>
										{profile?.serviceProvider?.role}
									</font>
								</Typography>
							</Stack>
						</Stack>
						<Box sx={{height: 30}}/>
					</Stack>

					<TabPanel index={0} value={value}>
						<Stack
							direction={"column"}
							spacing={3}
							sx={{
								boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
								borderRadius: "15px",
								backgroundColor: "#fff",
								minHeight: 100,
								px: 5.5,
								py: 6,
							}}
						>
							{ profile?.userType === "service-provider" ?
								<OrganisationAbout profile={profile} organisation={organisation}
												   callback={(s) => {
									
													   const path = `/admin/organisation-profile`
													   navigator(path,{
														   state: {
															   profile: s,
															   organisation: {
																   ...s?.serviceProvider
																	   .organisationDetails,
																   nameOfOrganisation:
																	   s?.serviceProvider
																		   .organisationDetails
																		   ?.nameOfOrganization !==
																	   ""
																		   ? s
																			   ?.serviceProvider
																			   .organisation
																			   ?.nameOfOrganization
																		   : s
																			   ?.serviceProvider
																			   ?.organisationDetails
																			   ?.nameOfOrganization,
																   logoURL:
																	   s?.serviceProvider
																		   .organisationDetails
																		   .logoURL !==
																	   ""
																		   ? s
																			   ?.serviceProvider
																			   .organisationDetails
																			   .logoURL
																		   : '',
															   },
														   },
													   });
												   }}
								
								/> :
								null }

						</Stack>
					</TabPanel>

										{/* <FounderAbout founder={founder} /> */}

				</Stack>
			)}
		</Container>
	);
}
