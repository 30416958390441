import React from "react";
import {IconButton, Stack, Typography} from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import {EditSharp} from "@mui/icons-material";
import EditInvestorDetails from "../../../../../components/common/profile/edit/investor/edit-investor-details";

export default function InvestorDescription({profile}) {
  const [open,setOpen] = React.useState(false)
  const profileRegex = /profile/
  return (

    <>


      <Stack direction={"row"} spacing={2}  justifyContent="flex-end"
             alignItems="center" sx={{width: '100%'}}>
        {profile.countryState ? <Typography variant={'body2'} color={"grey"} sx={{flex: 1}}>
          {profile.countryState}
        </Typography> : null}
        {profileRegex.test(window.location.pathname) &&  <IconButton onClick={() => setOpen(true)}>
          <EditSharp />
        </IconButton>}
      </Stack>
        {/* <Stack direction={'row'} spacing={1}  sx={{ position: 'relative'}}   justifyContent="flex-start"
               alignItems="center">
          <PublicIcon color={'primary'}/> {profile?.investor && profile?.investor.market &&
          profile?.investor.market.map((m, i) => (<Typography key={i} variant={"body2"} color={'primary'}>{m}</Typography>)) }
        </Stack> */}

        {profile?.investor && profile?.investor.description && 
        <Typography variant={'body1'} color={'secondary'} sx={{width: 'calc(100% - 10%)', fontSize:'12px', whiteSpace:"pre-line"}}>

          {profile?.investor.description}
        </Typography>}

      <EditInvestorDetails profile={profile} open={open} handleClose={() => setOpen(false)}/>



    </>
  )
}
// import React from "react";
// import {Stack, Typography} from "@mui/material";
// import PublicIcon from "@mui/icons-material/Public";

// export default function InvestorDescription({profile}) {

//   return (

//     <>


//         <Stack direction={'row'} spacing={1}  sx={{ position: 'relative'}}>
//           {
//             profile?.investor && profile?.investor.focusVertical && profile?.investor.focusVertical.map((d, i) => (<Typography key={i} variant={"body2"} color={'#A9ABAE'}>{'#'+d}</Typography>))
//           }
//         </Stack>
//         <Stack direction={'row'} spacing={1}  sx={{ position: 'relative'}}   justifyContent="flex-start"
//                alignItems="center">
//           <PublicIcon color={'primary'}/> {profile?.investor && profile?.investor.market &&
//           profile?.investor.market.map((m, i) => (<Typography key={i} variant={"body2"} color={'primary'}>{m}</Typography>)) }
//         </Stack>

//         {profile?.investor && profile?.investor.description && <Typography variant={'body1'} color={'secondary'} sx={{width: 'calc(100% - 10%)'}}>

//           {profile?.investor.description}
//         </Typography>}




//     </>
//   )
// }