import {createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {

    primary: {
      main: '#233DFF',
    },
    secondary: {
      main: '#505050',
    },
    success:{
      main: '#5CE6D6',
    },
    background: {
      default: '#fff'
    },
    warning: {
      main: '#ed8f02'
    }
  },
  components: {

    MuiTextField: {
      rounded:{
        borderRadius: 10
      }
    },
    MuiButton: {
      styleOverrides: {
        root:{
          borderRadius: 30,

        },
        sizeLarge: {
          fontSize: 18
        },
        sizeMedium: {
          fontSize: 16,
          textTransform: 'uppercase'
        },
        sizeSmall: {
          fontSize: 14
        },
        textSizeMedium: {
          fontSize: 16,
          textTransform: 'capitalize'

        },
        textSecondary:{
          color: '#000000'
        },
        containedSuccess: {
          color: ''
        },
        containedWarning: {
          color: '#fff'
        }


      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiAvatarGroup:{
      styleOverrides:{
        avatar: {
          border: 'none',
          backgroundColor: '#ffffff80'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded:{
          borderRadius: 10
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "#F5F5F5",
          scrollbarColor: "#cdcdcd #F5F5F5",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#F5F5F5",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#cdcdcd",
            minHeight: 48,
            border: "3px solid #FFFFFF",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#a7a7a7",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#a7a7a7",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#a7a7a7",
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#FFFFFF",
          },
        },
      },
    },
    // MuiInputBase:{
    //   styleOverrides:{
    //     input:{
    //       "&::placeholder":{
    //         opacity: 1
    //       },
    //     }
    //   }
    // }
  },
  shape: {borderRadius: 8},
  typography: {
    fontFamily: ['Poppins'],
    body2: {
      fontSize: 14,
    },
    body1: {
      fontSize: 16,
    },
    h6:{
      fontSize: 18,
      fontWeight:400
    },
    button: {
      textTransform: "capitalize"
    }
  },
})

export const NovaTheme = theme;
