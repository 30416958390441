import React, {useContext} from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from "moment";
import AddAdminValidation from "../../validations/add-admin";
import {useFormik} from "formik";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {Alert, Grid} from "@mui/material";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {AuthUserContext} from "../../providers/auth-provider";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Portal from "@mui/material/Portal";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import {getAuth, getIdToken, fetchSignInMethodsForEmail} from "firebase/auth";
import AddAdmin from "../../api/add-admin";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {DB} from "../../config/firebase/firebase";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import {Close, CloseSharp} from "@mui/icons-material";
import DeleteUserApi from "../../api/get-users";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

export default function ManageAdmin(){
  const navigator = useNavigate()
  const {profile} = useContext(AuthUserContext);
  const [name, setName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [removeAdminLoading,setLoadingRemove] = React.useState(false);
  const [selectProfile,setSelectProfile] = React.useState();
  const [openSuccess, setOpenSuccess] = React.useState(false);

  const handleClickOpen = () => {
    setOpenSuccess(true);
  };

  const handleClose = () => {
    setOpenSuccess(false);
  };
  const [admins, setAdmins] = React.useState([]);
  const [searchAdmin , setSearchAdmin] = React.useState('')
  const [filterData, setFilterData] = React.useState([])
  const [isAddForm, setAddForm] = React.useState(false)
  const auth = getAuth();
  const formik = useFormik({
    initialValues:  AddAdminValidation.initialValues,
    validationSchema: AddAdminValidation.AddAdminSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        debugger
        const isEmailExists = await fetchSignInMethodsForEmail(auth, values.email)
        if(isEmailExists && isEmailExists.length === 0) {
          const idToken = await getIdToken(auth.currentUser);

          await AddAdmin({...values, type: 'admin', super: values.userType === 'super-admin'}, idToken)
          setName(values?.displayName)
          setAddForm(!isAddForm)
          loadData()
          handleClickOpen()
          setLoading(false)
        } else {
          setLoading(false)
          formik.setFieldError('email', 'Your email address is already exists.')

        }

        // alert(JSON.stringify(response))
      } catch (e) {
        setLoading(false)
        console.log(e)
      }
    },
  });

 const showAddForm = () => {
   formik.handleReset(formik);
   setAddForm(!isAddForm)

 }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'none',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F4F8',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const deleteUser = async () =>
  {
    try {
      setLoadingRemove(true)
      const auth = getAuth();
      const idToken = await getIdToken(auth.currentUser);
      console.log(idToken, 'ID - TOKEn')
      const data = await DeleteUserApi(selectProfile, idToken);
      setLoadingRemove(false)

      await setOpen(false)
      loadData()

    } catch (e) {
      await setOpen(false)

      setLoadingRemove(false)

      debugger
    }
  }
  const loadData = () => {
    const q = query(
      collection(DB, "users"),
      where("userType", "==", 'admin'),
      orderBy("createdAt", "desc")
    );
    getDocs(q).then((snaps) =>  {
      if (snaps.size > 0) {
        const data = [];
        snaps.docs.forEach((d) => {
          data.push({id:d.id ,...d.data()});
        })
        setAdmins(data);
      }
    })
   /* const statsRef = firebase
      .firestore()
      .collection('users')
      .orderBy('createdAt', 'desc')
      .limit(10)
      .where('userType', '==', 'admin' )
      .get()
      .then((snaps) => {
        if (snaps.size > 0) {
          const data = [];
          snaps.docs.forEach((d) => {
            data.push({id:d.id ,...d.data()});
          })
          setAdmins(data);
        }
      })*/
  }
  const search = () => {
     
    if (searchAdmin === '') {
      setFilterData([...admins])
    } else {
      const regex = new RegExp(searchAdmin);
      setFilterData(admins.filter((u) => regex?.test(u.displayName) || regex?.test(u.email) ));
    }
  }
  React.useEffect(() => {
    loadData()
  }, [])

  const filteredAdmin = searchAdmin === "" ? admins : admins.filter((item) => {return (item.displayName.toLowerCase()).startsWith(searchAdmin.toLowerCase())})
  return (
    <Container maxWidth={'full-width'}  sx={{height: 'calc(100vh - 120px)', p: 5, textAlign: 'left',overflowY: 'scroll'}}>
      <Stack direction={'column'} spacing={5}>
        {!isAddForm ? <Stack direction={'row'} spacing={3}>
          <Typography variant={'h4'} fontWeight={600} >
            All Admins
          </Typography>

          <div style={{flex: 1}}/>
          <Container maxWidth={'full-width'}  sx={{
            p: "2px 10px",
            display: "flex",
            alignItems: "center",
            width: 337,
            height: 43,
            borderRadius: "12px",
            backgroundColor: "#E4EAF5",
          }}>

            <IconButton
              type="button"
              sx={{p: "10px"}}
              aria-label="search"
              onClick={(e) => search}>
              <SearchIcon sx={{color: "#051E40BF"}}/>
            </IconButton>
            <InputBase
              onChange={(e) => setSearchAdmin(e.target.value)}
              value={searchAdmin}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  search()
                }
              }}
              sx={{ml: 1, flex: 1, color: "#051E40BF"}}
              placeholder="Search"
              inputProps={{"aria-label": "search post"}}
            />
          </Container>
        </Stack> : <Stack direction={'row'} spacing={3} sx={{width: '100%'}}>
          <Typography variant={'h4'} fontWeight={600} sx={{flex: 1}}>
           Add Sub Admin
          </Typography >  <Button onClick={() => setAddForm(!isAddForm)} variant={'contained'}>View all admins</Button></Stack>}
        <Stack direction={'row'} spacing={3} sx={{width: '100%'}}>
          <div style={{flex: 1}}/>
          {!isAddForm &&
            <Button variant={'contained'} color={'primary'} onClick={() => showAddForm()}>
              Add admin
            </Button>}
        </Stack>

        {
          !isAddForm ?
            <Grid container gap={3}>
              {admins.length > 0 && admins?.map((adm, i) =>
                (<Grid sx={{backgroundColor:'#F6F8FA', width:312, height:175, border:'0.468336px solid #000000', borderRadius: '4.5px', p:1}}>
                  <Stack direction={"column"}   justifyContent="space-evenly"
                         alignItems="flex-end"
                         spacing={2}>
                          {console.log(adm)}
                    { !adm?.super ?
                      <Button variant={"text"} color={"secondary"} size={"small"} sx={{textDecoration: 'underline'}} onClick={() => {
                        setOpen(true);
                        setSelectProfile(adm)
                      }}>Delete</Button> : <br/>}
                    <Stack direction={"row"} spacing={3} sx={{width: '100%'}}>
                      <Typography variant={"body1"} fontWeight={600} sx={{flex: 1}}>Email</Typography>
                      <Typography variant={"body2"} fontWeight={400} noWrap={true} paragraph={true} sx={{width: 220}}>{adm?.email}</Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={3} sx={{width: '100%'}}>
                      <Typography variant={"body1"} fontWeight={600} sx={{flex: 1}}>Name</Typography>
                      <Typography variant={"body2"} fontWeight={400} noWrap={true} paragraph={true} sx={{width: 220}}>{adm?.displayName}</Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={3} sx={{width: '100%'}}>
                      <Typography variant={"body1"} fontWeight={600} sx={{flex: 1}}>Type</Typography>
                      <Typography variant={"body2"} fontWeight={400} noWrap={true} paragraph={true} sx={{width: 220}}>{adm?.super ? 'Super Admin' : 'Admin'}</Typography>
                    </Stack>
                  </Stack>
                </Grid>)

              )}

            </Grid>
          /*<TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{fontWeight: 'bolder', fontSize:16, }}>Name</StyledTableCell>
                <StyledTableCell align="left" sx={{fontWeight: 'bolder', fontSize:16, }}>Email</StyledTableCell>
                <StyledTableCell align="left" sx={{fontWeight: 'bolder', fontSize:16, }}>Created date</StyledTableCell>
                <StyledTableCell align="left" sx={{fontWeight: 'bolder', fontSize:16, }}>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins.length > 0
                && filteredAdmin.map((row) => (
                <StyledTableRow
                  key={row.displayName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.displayName}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.email}</StyledTableCell>
                  <StyledTableCell align="left">{moment(row?.createdAt).format('DD/MM/YYYY')}</StyledTableCell>
                  <StyledTableCell align="left">
                    { !row?.super &&
                      <Button variant={'text'} color={"secondary"} sx={{textDecoration: 'underline'}} onClick={() => {
                      setOpen(true);
                      setSelectProfile(row)
                    }}>Delete</Button>}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>*/
            :
            <Container maxWidth={"sm"}>

              <Stack component={'form'} onSubmit={formik.handleSubmit} direction="column"
                     justifyContent="flex-start"
                     alignItems="center"
                     spacing={3}>

                <TextField value={formik.values.displayName}
                           onChange={formik.handleChange}
                           error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                           helperText={formik.touched.displayName && formik.errors.displayName}
                           label={'Name'} name="displayName" variant="outlined" sx={{width:'100%'}} />

                <TextField value={formik.values.email}
                           onChange={formik.handleChange}
                           error={formik.touched.email && Boolean(formik.errors.email)}
                           helperText={formik.touched.email && formik.errors.email}
                           label={'Email'} name="email" variant="outlined" sx={{width:'100%'}} />


                <TextField value={formik.values.userType}
                           onChange={formik.handleChange}
                           error={formik.touched.userType && Boolean(formik.errors.userType)}
                           helperText={formik.touched.userType && formik.errors.userType}
                           label={'User Type'} name="userType" variant="outlined" sx={{width:'100%'}} select >

                  <MenuItem value={'admin'}>
                    Admin
                  </MenuItem>
                  {
                    profile?.super &&
                    <MenuItem value={'super-admin'}>
                      Super Admin
                    </MenuItem>
                  }
                </TextField>
                <TextField value={formik.values.password}
                           onChange={formik.handleChange}
                           error={formik.touched.password && Boolean(formik.errors.password)}
                           helperText={formik.touched.password && formik.errors.password}
                           label={'Create password'} name="password" variant="outlined" sx={{width:'100%'}} type={'password'} autoComplete={false}/>


                <Stack direction={'row'} spacing={5}  justifyContent="flex-end"
                       alignItems="center" sx={{width: '100%'}}>


                  <Button variant={'contained'} sx={{px: 3}} type={'submit'} endIcon={isLoading ? <CircularProgress size={20}/> : '' } disabled={isLoading}>
                    Invite
                  </Button>
                </Stack>

              </Stack>
            </Container>
        }

      </Stack>

      <Portal>
        <div>

          <Dialog
            open={openSuccess}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'xs'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" textAlign={"center"} fontWeight={600}>
              Invitation Sent
              <IconButton onClick={()=> {
                handleClose();

              }} variant={'contained'} size={'small'} sx={{ position: 'absolute', top: 2, right: 10}}>
                <CloseSharp />
              </IconButton>

            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description"  textAlign={"center"}>
                Congratulations! You have successfully <br/>
                sent an invitation to {name}.
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </Portal>
      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"sm"}>
          <DialogContent
            sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end">
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              spacing={2}>
              <Stack
                direction={"column"}
                sx={{ width: "100%", }} alignItems={"center"}>
                <img src={"/assets/delete-confirmation.png"} height={74} width={80}></img>
              </Stack>
              <Typography
                component="span"
                variant="h6"
                color="text.primary"
                sx={{
                  textAlign: "center",
                  flex: 1,
                  padding: 0,
                  fontWeight: 500,
                }}>
                Delete Admin
              </Typography>
              <Typography variant={"body2"} sx={{textAlign:'center'}}>
                Are you sure you want to delete this Admin?<br/>
                This action can not be undone.
              </Typography>
              <br/>
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="center" spacing={2}>
                <Button
                  variant={"outlined"}
                  sx={{ px: 5,width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
                  onClick={() => {

                    setOpen(false);
                    setSelectProfile(null)
                  }}>
                  No
                </Button>
                <Button
                  variant={"contained"}
                  sx={{ px: 5,width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
                  disabled={removeAdminLoading}
                  onClick={()=> {
                    deleteUser().then();

                  }} endIcon={removeAdminLoading && <CircularProgress size={20}/>}>
                  yes
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </Container>
  )
}
