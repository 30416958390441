import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { DB } from "../config/firebase/firebase";
import { SUGGESTED_STATUS } from "../utils/enums/suggestions-state";

const GetAdminSuuggestedProfilesForAdmin = (requestId, creatorId) =>
  new Promise(async (resolve, reject) => {
    try {
      const suggestedProfiles = await getSuggestedProfiles(
        requestId,
        creatorId
      );
      resolve(suggestedProfiles);
    } catch (e) {
      reject(null);
    }
  });
const getSuggestedProfiles = async (requestId, creatorId) => {
  const usersRef = collection(
    DB,
    "help-requests",
    requestId,
    "users-suggested"
  );
  const usersQuerySnapshot = await getDocs(query(usersRef));
  let suggestUserList = [];

  for (const userDoc of usersQuerySnapshot.docs) {
    const userId = userDoc.id;
    if(userId != creatorId) {
    const userDataSnapshot = await getDoc(doc(DB, "users/" + userId));
    const userData = userDataSnapshot.data();

    suggestUserList.push({
      id: userId,
      adminSuggested: true,
      ...userData,
      userStatusDoc: { ...userDoc.data() },
    });
    if (suggestUserList?.length > 0) {
      suggestUserList = suggestUserList.filter(
        (list) =>
          list?.userStatusDoc?.status !== SUGGESTED_STATUS.DECLINED
      );
    }
  }
  }
  return suggestUserList;
};
export default GetAdminSuuggestedProfilesForAdmin;
