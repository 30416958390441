

import {Avatar, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";

import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import InvestmentPortfolioView from "./investor-portfolio-view";
import moment from "moment";
export default function InvestorPortfolio({ profile, setIndex , setKey }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  function currencyToSymbol(currency){
    if(currency == "USD"){
      return "$"
    }
    else if (currency == "GBP"){
      return "£"
    }
    else{
      return "€"
    }
  }
  function intToString(num) {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: "K" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    );
  }
  return (
    <>
          {profile?.userType === "startup" &&
          profile?.startup &&
          profile?.startup.investorDetails.portfolio &&
          (profile?.startup.investorDetails.portfolio.length  === 0  ? <>
        <Typography fontSize={20} fontWeight={600} >Investment Portfolio</Typography>

          <Stack direction="row" justifyContent="center"  >

            <Typography>There are no investment portfolio to be shown</Typography>
          </Stack></>:<Typography fontSize={20} fontWeight={600}>Investment Portfolio</Typography>)
        }

          {profile?.userType === "investor" &&
          profile?.investor &&
          profile?.investor.portfolio &&
         ( profile?.investor.portfolio.length  === 0  ? <>
        <Typography fontSize={20} fontWeight={600}>Investment Portfolio</Typography>

          <Stack direction="row" justifyContent="center"  >

            <Typography>There are no investment portfolio to be shown</Typography>
          </Stack></>:<Typography fontSize={20} fontWeight={600}>Investment Portfolio</Typography> )
        }

        {profile?.userType === "expert" &&
          profile?.expert &&
          profile?.expert.investorDetails.portfolio &&
         ( profile?.expert.investorDetails.portfolio.length  === 0  ? <>
        <Typography fontSize={20} fontWeight={600} >Investment Portfolio</Typography>

          <Stack direction="row" justifyContent="center"  >

            <Typography>There are no investment portfolio to be shown</Typography>
          </Stack></>:<Typography fontSize={20} fontWeight={600}>Investment Portfolio</Typography>)
        }

        {profile?.userType === "business-developer" &&
          profile?.businessDeveloper &&
          profile?.businessDeveloper.investorDetails.portfolio &&
         ( profile?.businessDeveloper.investorDetails.portfolio.length  === 0  ? <>
        <Typography fontSize={20} fontWeight={600} >Investment Portfolio</Typography>

          <Stack direction="row" justifyContent="center"  >

            <Typography>There are no investment portfolio to be shown</Typography>
          </Stack></>:<Typography fontSize={20} fontWeight={600}>Investment Portfolio</Typography> )
        }

        {profile?.userType === "service-provider" &&
          profile?.serviceProvider &&
          profile?.serviceProvider.investorDetails.portfolio &&
          (profile?.serviceProvider.investorDetails.portfolio.length  === 0  ? <>
        <Typography fontSize={20} fontWeight={600} >Investment Portfolio</Typography>

          <Stack direction="row" justifyContent="center">

            <Typography>There are no investment portfolio to be shown</Typography>
          </Stack></>:<Typography fontSize={20} fontWeight={600} >Investment Portfolio</Typography>)
        }

          {/* <Typography fontSize={20} fontWeight={600}>Investment Portfolio</Typography> */}


      { profile?.userType === "investor" ?
        profile['investor']?.portfolio &&
        profile['investor']?.portfolio?.length > 0 &&
        profile['investor']?.portfolio?.map((p, i) => (
          <>

          {/* <Typography fontSize={20} fontWeight={600}>Investment Portfolio</Typography> */}

          <Grid
            sx={{width: "100%",
              backgroundColor: '#F6F8FA',
              border: '0.5px solid #000000',
              borderRadius: '5px',
              p:2,
              px: 2 }}
            onClick={() => {
              setIndex(i)
              setKey(i)
            }}
            key={i}
          >

            <Stack direction={'column'} spacing={.5} sx={{width:'100%'}}>
              <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                <Avatar
                  sx={{
                    width: 70,
                    height: 70,
                    border: "1px solid #E0E3FF",
                    position:
                      "relative",
                    top: -40
                    , marginBottom:"-9px",
                    marginLeft:"8px"
                  }}
                  src={
                    p?.photoURL
                      ? p?.photoURL
                      : "/assets/startup-placeholder-image.png"
                  }/>
                <Box sx={{flex:1}}/>
              </Stack>
              <Stack direction={'column'} spacing={.5} sx={{width:'100%',  position:
                  "relative",
                top: -23}}>

                <Typography variant={'body1'}  sx={{textAlign:'left', fontWeight:"500", fontSize:"16px", top: "-14px"  }}>
                  {
                    p?.name
                  }
                </Typography>
                <Typography  variant={'caption'}  sx={{textAlign:'left',}}>
                  <a href={
                    p?.website
                  } target={'_blank'}>{
                    p?.website.length < 30 ? p?.website : `${p?.website.slice(0 , 30)}...`
                  }</a>
                </Typography>
                <span/>
                <span/>
                <span style={{flex:1}}/>
                <Typography  variant={'caption'} paragraph={true} sx={{textAlign:'left', color:'#8F8F8F', width: 275, overflowY: 'hidden'}}>
                  {
                    p?.description.length < 80 ? p?.description :  `${p?.description.slice(0 , 80)}...`
                  }
                </Typography>
                <span style={{flex:1}}/>
                <span style={{flex:1}}/>
                <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                    Invested date:
                  </Typography>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                    {moment(p?.investmentDate).format("DD-MM-YY")}
                  </Typography>
                  <span style={{flex:1}}/>
                  <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                    Amount
                  </Typography>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                    {currencyToSymbol(p?.currency)}
                   { p?.amount.length < 6 ? p?.amount : `${p?.amount.slice(0 , 6)}...` }

                  </Typography>
                </Stack>
                <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                    Invested stage:
                  </Typography>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                  { p?.investmentStage.length < 6 ? p?.investmentStage : `${p?.investmentStage.slice(0 , 6)}...` }
                    {/* {p?.investmentStage} */}
                  </Typography>
                  <span style={{flex:1}}/>
                  <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                    Pre-val:
                  </Typography>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                    {currencyToSymbol(p?.currency)}
                    { p?.valuation.length < 6 ? p?.valuation : `${p?.valuation.slice(0 , 6)}...` }
                    {/* {p?.valuation} */}
                  </Typography>
                </Stack>
              </Stack>



            </Stack>
          </Grid>
          </>

        )) :  profile?.userType === "business-developer" ?
        profile['businessDeveloper']?.investorDetails?.portfolio &&
        profile['businessDeveloper']?.investorDetails?.portfolio?.length > 0 &&
        profile['businessDeveloper']?.investorDetails?.portfolio?.map((p, i) => (

          <>

          {/* <Typography fontSize={20} fontWeight={600}>Investment Portfolio</Typography> */}

          <Grid
            sx={{width: "100%",
              backgroundColor: '#F6F8FA',
              // border: '0.5px solid #000000',
              borderRadius: '5px',
              p:2,
              px: 2 }}
            onClick={() => {
              setIndex(i)
              setKey(i)
            }}
            key={i}
          >

            <Stack direction={'column'} spacing={.5} sx={{width:'100%'}}>
              <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                <Avatar
                  sx={{
                    width: 70,
                    height: 70,
                    border: "1px solid #E0E3FF",
                    position:
                      "relative",
                    top: -40
                    , marginBottom:"-9px",
                    marginLeft:"8px"
                  }}
                  src={
                    p?.photoURL
                      ? p?.photoURL
                      : "/assets/startup-placeholder-image.png"
                  }/>
                <Box sx={{flex:1}}/>
              </Stack>
              <Stack direction={'column'} spacing={.5} sx={{width:'100%',  position:
                  "relative",
                top: -23}}>

                <Typography variant={'body1'}  sx={{textAlign:'left', fontWeight:"500", fontSize:"16px", top: "-14px"  }}>
                  {
                    p?.name
                  }
                </Typography>
                <Typography  variant={'caption'}  sx={{textAlign:'left',}}>
                  <a href={
                    p?.website
                  } target={'_blank'}>{
                    p?.website.length < 30 ? p?.website : `${p?.website.slice(0 , 30)}...`
                  }</a>
                </Typography>
                <span/>
                <span/>
                <span style={{flex:1}}/>
                <Typography  variant={'caption'} paragraph={true} sx={{textAlign:'left', color:'#8F8F8F', width: 275, overflowY: 'hidden'}}>
                  {
                    p?.description.length < 80 ? p?.description :  `${p?.description.slice(0 , 80)}...`
                  }
                </Typography>
                <span style={{flex:1}}/>
                <span style={{flex:1}}/>
                <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                    Invested date:
                  </Typography>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                    {moment(p?.investmentDate).format("DD-MM-YY")}
                  </Typography>
                  <span style={{flex:1}}/>
                  <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                    Amount
                  </Typography>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                    {currencyToSymbol(p?.currency)}
                      { p?.amount.length < 6 ? p?.amount : `${p?.amount.slice(0 , 6)}...` }
                  </Typography>
                </Stack>
                <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                    Invested stage:
                  </Typography>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                    { p?.investmentStage.length < 6 ? p?.investmentStage : `${p?.investmentStage.slice(0 , 6)}...` }
                  </Typography>
                  <span style={{flex:1}}/>
                  <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                    Pre-val:
                  </Typography>
                  <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                    {currencyToSymbol(p?.currency)}
                      { p?.valuation.length < 6 ? p?.valuation : `${p?.valuation.slice(0 , 6)}...` }
                  </Typography>
                </Stack>
              </Stack>



            </Stack>
          </Grid>
          </>

        ))
        :  profile?.userType === "service-provider" ?
          profile['serviceProvider']?.investorDetails?.portfolio &&
          profile['serviceProvider']?.investorDetails?.portfolio?.length > 0 &&
          profile['serviceProvider']?.investorDetails?.portfolio?.map((p, i) => (
            <>

            {/* <Typography variant={"h5"}>Investment Portfolio</Typography> */}

            <Grid
              sx={{width: "100%",
                backgroundColor: '#F6F8FA',
                border: '0.5px solid #000000',
                borderRadius: '5px',
                p:2,
                px: 2 }}
              onClick={() => {
                setIndex(i)
                setKey(i)
              }}
              key={i}
            >

              <Stack direction={'column'} spacing={.5} sx={{width:'100%'}}>
                <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                  <Avatar
                    sx={{
                      width: 70,
                      height: 70,
                      border: "1px solid #E0E3FF",
                      position:
                        "relative",
                      top: -40
                      , marginBottom:"-9px",
                      marginLeft:"8px"
                    }}
                    src={
                      p?.photoURL
                        ? p?.photoURL
                        : "/assets/startup-placeholder-image.png"
                    }/>
                  <Box sx={{flex:1}}/>
                </Stack>
                <Stack direction={'column'} spacing={.5} sx={{width:'100%',  position:
                    "relative",
                  top: -23}}>

                  <Typography variant={'body1'}  sx={{textAlign:'left', fontWeight:"500", fontSize:"16px", top: "-14px"  }}>
                    {
                      p?.name
                    }
                  </Typography>
                  <Typography  variant={'caption'}  sx={{textAlign:'left',}}>
                    <a href={
                      p?.website
                    } target={'_blank'}>{
                      p?.website.length < 30 ? p?.website : `${p?.website.slice(0 , 30)}...`
                    }</a>
                  </Typography>
                  <span/>
                  <span/>
                  <span style={{flex:1}}/>
                  <Typography  variant={'caption'} paragraph={true} sx={{textAlign:'left', color:'#8F8F8F', width: 275, overflowY: 'hidden'}}>
                    {
                      p?.description.length < 80 ? p?.description :  `${p?.description.slice(0 , 80)}...`
                    }
                  </Typography>
                  <span style={{flex:1}}/>
                  <span style={{flex:1}}/>
                  <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                      Invested date:
                    </Typography>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                      {moment(p?.investmentDate).format("DD-MM-YY")}
                    </Typography>
                    <span style={{flex:1}}/>
                    <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                      Amount
                    </Typography>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                      {currencyToSymbol(p?.currency)}
                        { p?.amount.length < 6 ? p?.amount : `${p?.amount.slice(0 , 6)}...` }
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                      Invested stage:
                    </Typography>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                      { p?.investmentStage.length < 6 ? p?.investmentStage : `${p?.investmentStage.slice(0 , 6)}...` }
                    </Typography>
                    <span style={{flex:1}}/>
                    <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                      Pre-val:
                    </Typography>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                      {currencyToSymbol(p?.currency)}
                        { p?.valuation.length < 6 ? p?.valuation : `${p?.valuation.slice(0 , 6)}...` }
                    </Typography>
                  </Stack>
                </Stack>



              </Stack>
            </Grid>
            </>

          ))
          :
          profile[profile?.userType]?.investorDetails?.portfolio &&
          profile[profile?.userType]?.investorDetails?.portfolio?.length > 0 &&
          profile[profile?.userType]?.investorDetails?.portfolio?.map((p, i) => (

            <>

            {/* <Typography variant={"h5"}>Investment Portfolio</Typography> */}

            <Grid
              sx={{width: matches ? "100%" : '100%',
                backgroundColor: '#F6F8FA',
                border: '0.5px solid #000000',
                borderRadius: '5px',
                p:2,
                px: 2 }}
              onClick={() => {
                setIndex(i)
                setKey(i)
              }}
              key={i}
            >

              <Stack direction={'column'} spacing={.5} sx={{width:'100%'}}>
                <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                  <Avatar
                    sx={{
                      width: 70,
                      height: 70,
                      border: "1px solid #E0E3FF",
                      position:
                        "relative",
                      top: -40
                      , marginBottom:"-9px",
                      marginLeft:"8px"
                    }}
                    src={
                      p?.photoURL
                        ? p?.photoURL
                        : "/assets/startup-placeholder-image.png"
                    }/>
                  <Box sx={{flex:1}}/>
                </Stack>
                <Stack direction={'column'} spacing={.5} sx={{width:'100%',  position:
                    "relative",
                  top: -23}}>

                  <Typography variant={'body1'}  sx={{textAlign:'left', fontWeight:"500", fontSize:"16px", top: "-14px"  }}>
                    {
                      p?.name
                    }
                  </Typography>
                  <Typography  variant={'caption'}  sx={{textAlign:'left',}}>
                    <a href={
                      p?.website
                    } target={'_blank'}>{
                      p?.website.length < 30 ? p?.website : `${p?.website.slice(0 , 30)}...`
                    }</a>
                  </Typography>
                  <span/>
                  <span/>
                  <span style={{flex:1}}/>
                  <Typography  variant={'caption'} paragraph={true} sx={{textAlign:'left', color:'#8F8F8F', width: 275, overflowY: 'hidden'}}>
                    {
                      p?.description.length < 80 ? p?.description :  `${p?.description.slice(0 , 80)}...`
                    }
                  </Typography>
                  <span style={{flex:1}}/>
                  <span style={{flex:1}}/>
                  <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                      Invested date:
                    </Typography>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                      {moment(p?.investmentDate).format("DD-MM-YY")}
                    </Typography>
                    <span style={{flex:1}}/>
                    <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                      Amount
                    </Typography>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                      {currencyToSymbol(p?.currency)}
                      { p?.amount.length < 6 ? p?.amount : `${p?.amount.slice(0 , 6)}...` }
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                      Invested stage:
                    </Typography>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                    { p?.investmentStage.length < 6 ? p?.investmentStage : `${p?.investmentStage.slice(0 , 6)}...` }
                    </Typography>
                    <span style={{flex:1}}/>
                    <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
                      Pre-val:
                    </Typography>
                    <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
                      {currencyToSymbol(p?.currency)}
                      { p?.valuation.length < 6 ? p?.valuation : `${p?.valuation.slice(0 , 6)}...` }
                    </Typography>
                  </Stack>
                </Stack>



              </Stack>
            </Grid>
            </>

          ))
      }


    </>
  );
}


    

// import { Avatar, Grid, Stack, Typography } from "@mui/material";
// import Box from "@mui/material/Box";
// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { useState } from "react";
// import InvestmentPortfolioView from "./investor-portfolio-view";

// export default function InvestorPortfolio({ profile, setIndex }) {
//   function intToString(num) {
//     num = num.toString().replace(/[^0-9.]/g, "");
//     if (num < 1000) {
//       return num;
//     }
//     let si = [
//       { v: 1e3, s: "K" },
//       { v: 1e6, s: "M" },
//       { v: 1e9, s: "B" },
//       { v: 1e12, s: "T" },
//       { v: 1e15, s: "P" },
//       { v: 1e18, s: "E" },
//     ];
//     let index;
//     for (index = si.length - 1; index > 0; index--) {
//       if (num >= si[index].v) {
//         break;
//       }
//     }
//     return (
//       (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
//       si[index].s
//     );
//   }
//   return (
//     <>
//       {profile?.userType === "investor" && (
//         <Typography variant={"h5"}>Portfolio</Typography>
//       )}

//       <Grid container gap={3}>
//         {profile?.userType === "investor" &&
//           profile?.investor &&
//           profile?.investor.portfolio &&
//           profile?.investor.portfolio.length > 0 &&
//           profile?.investor.portfolio.map((p, i) => (
//             <Grid key={i}>
//               {p?.name && (
//                 <>
//                   <Box
//                     onClick={() => {
//                       setIndex(i);
//                     }}
//                     sx={{
//                       width: "320px",
//                       backgroundColor: "#E4EAF5",
//                       padding: 2,
//                       borderRadius: "5px",
//                       border: "0.5px solid black",
//                     }}
//                     key={i}
//                   >
//                       <Avatar
//                         sx={{
//                           width: 72,
//                           height: 72,
//                           border: "3px solid #E0E3FF",
//                           position: "relative",
//                           top: -50,
//                         }}
//                         src={
//                           p?.logoURL
//                             ? p?.logoURL
//                             : "/assets/nova.png"
//                         }
//                       ></Avatar>

//                     <Stack
//                       direction="row"
//                       justifyContent="space-between"
//                       alignItems="flex-start"
//                     >
//                       <Typography
//                         variant={"h6"}
//                         style={{
//                           fontWeight: "500",
//                           textTransform: "capitalize",
//                         }}
//                       >
//                         {p?.name ? p?.name : ""}
//                       </Typography>
//                       <Typography
//                         variant={"h6"}
//                         style={{
//                           color: "#051E40",
//                           textTransform: "capitalize",
//                         }}
//                       >
//                         {p?.stage ? p?.stage : ""}
//                       </Typography>
//                     </Stack>
//                     <Typography
//                       variant="subtitle1"
//                       color={"grey"}
//                       style={{
//                         marginTop: "12px",
//                         textAlign: "left",
//                         fontSize: " 16px",
//                       }}
//                       nowrap={false}
//                     >
//                       {p?.description
//                         ? p?.description.length >= 100
//                           ? `${p?.description.substring(0, 100)} ....`
//                           : p?.desciption
//                         : ""}
//                     </Typography>
//                     <Stack
//                       direction="row"
//                       justifyContent="flex-end"
//                       alignItems="center"
//                     >
//                       <Typography
//                         variant="subtitle1"
//                         color={"#363636"}
//                         style={{
//                           fontSize: "14px",
//                         }}
//                         nowrap={false}
//                       >
//                         {p?.date ? p?.date : ""}
//                       </Typography>
//                     </Stack>

//                     <Stack
//                       direction="row"
//                       justifyContent="space-between"
//                       alignItems="flex-start"
//                     >
//                       <Typography
//                         variant={"body2"}
//                         style={{ fontWeight: "500" }}
//                       >
//                         {p?.currency ? p?.currency : ""}&nbsp;
//                         {p?.amount ? intToString(p?.amount) : ""} Invested
//                       </Typography>
//                       <Typography
//                         variant={"body2"}
//                         sx={{ flex: 1, textAlign: "right" }}
//                         style={{ fontWeight: "500" }}
//                       >
//                         {p?.valuation ? intToString(p?.valuation) : ""}{" "}
//                         Valuation
//                       </Typography>
//                     </Stack>
//                   </Box>
//                 </>
//               )}
//             </Grid>
//           ))}
//       </Grid>
//     </>
//   );
// }

