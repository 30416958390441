import React from "react";
import { Stack, Badge, Grid, Chip, Select, MenuItem ,Box,Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import StartConversation from "../start-conversation";
import { AuthUserContext } from "../../../../../providers/auth-provider";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { USERS_NAME_MAPPING } from "../../../../../utils/enums/labels.enum";

export default function MyRequestListItem({ request, callback, callbackNav }) {
	const { profile } = React.useContext(AuthUserContext);
	const navigator = useNavigate();
	return (
		<>
         <Box style={{boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",height:"480px",borderRadius:"12px",paddingRight:"20px",paddingLeft:"20px",paddingTop:"20px", backgroundColor: "#F6F8FA"}}>
         <Paper
                elevation={2}
                style={{
                  height: "237px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  paddingTop: "10px",
				
                }}
              >
			  <Stack direction="row" spacing={2} style={{marginBottom:"5px"}}>
			  <Typography
                  variant={"body1"}
                  noWrap={true}
					onClick={() => callbackNav()}
                
                  sx={{
                    width: 230,
                    lineHeight: 1.3,
                    cursor: "pointer",
                  }}
                >
                  {request.requestFor}  <br></br>    <font color={"#8F8F8F"} size={2}>
                    {request?.location
                      ? request?.location
                      : request?.creatorData?.startup &&
                        request?.creatorData?.startup.location}
                  </font> 
				</Typography>
				   <Select
					f
					size={"small"}
					value={request?.stage ? request?.stage : "activate"}
					style={{
						color: "#000",
						borderRadius: "30px",
						backgroundColor: "#eeeeee10",
						width: 150,
						height: "30px",
						fontSize: 14,
					}}
					IconComponent={() => <ArrowDropDownIcon />}
					onChange={(e) => callback(e)}>
					<MenuItem value={"Closed"}>Close</MenuItem>
					<MenuItem value={"Deactivated"}>Deactivate</MenuItem>
					<MenuItem value={"activate"}>Activate</MenuItem>
				</Select>
                 
               
			  </Stack>
			 
			  <Grid
				container
				onClick={() => callbackNav()}
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				gap={.5}>
				{(request?.requestFor === "Looking for funding" ||
					request?.type === "looking-for-funding") && (
					<Grid item>
						<Chip
							variant="contained"
							sx={{
								my: 0,
								backgroundColor: "#B2FFFA",
								
								maxWidth: 100,
								borderRadius: "5px",
							}}
							component={"p"}
							label={"Looking for funding"}
						/>
					</Grid>
				)}
				{request?.searchFor &&
					request?.searchFor.length > 0 &&
					request?.searchFor?.map((s, i) => (
						<Grid item>
							<Chip
								variant="contained"
								sx={{
									my: 0,
									backgroundColor: "#B8FC3C",
									
									maxWidth: 100,
									borderRadius: "5px",
								}}
								component={"p"}
								label={USERS_NAME_MAPPING[s]}
								key={i}
							/>
						</Grid>
					))}
				{request?.skills &&
					request?.skills.length > 0 &&
					request?.skills?.map((s, i) => (
						<Grid item>
							<Chip
								variant="contained"
								sx={{
									my: 0,
									backgroundColor: "#5CE5D5",
									
									maxWidth: 100,
									borderRadius: "5px",
								}}
								component={"p"}
								label={s}
								key={i}
							/>
						</Grid>
					))}
			</Grid>
             
              </Paper>
			  <br></br>
			  <Stack direction="colum"
              justifyContent="center"
              alignItems="center"
              
              sx={{
             
              borderRadius: "6px",
              width:"99%",
              padding:"5px",
              boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset"
              }}
            >
              <Stack
                direction={"row"}
                spacing={4}

				onClick={() => callbackNav()}
                sx={{
                  textAlign: "left",
                  cursor: "pointer",
                  width: "85%",
                  height:"21px"
                }}
              >
                <Typography variant={"body2"} fontWeight={500}>
                Stage
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                  sx={{ color: "#8F8F8F", fontSize: 14 }}
                >
                 <Badge
							variant={"dot"}
							invisible={false}
							color={
								!request.stage || request.stage === "activate"
									? "primary"
									: request.stage === "closed"
									? "error"
									: "warning"
							}
						/>
						&nbsp;&nbsp;&nbsp;
						{request.stage && request.stage === "activate"
							? "Open"
							: !request.stage
							? "Open"
							: request.stage}
                </Stack>
              </Stack>
            </Stack>
			<Box style={{paddingRight:"10px",paddingLeft:"20px",paddingTop:'10px'}} 
								onClick={() => callbackNav()}

			  >
            <Box style={{borderBottom:"1px solid #8F8F8F"}}>
            <Stack direction="row" spacing={7}>
                <Stack direction="row" spacing={1}>
                <Typography style={{width:"15px"}}>  <img
                  src={"/assets/budget.png"}
                  style={{ height: "13px" }}
                ></img></Typography>
                <Typography variant={"body2"}
                  fontWeight={500}
                  sx={{ color: "#8F8F8F" }}> {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Amount to raise"
                    : "Budget"}</Typography>
                    </Stack>
                <Stack>
                  
                    <Typography sx={{ fontSize: "13px" }} fontWeight={500}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? `${request?.currency} ${request?.amount}`
                    : `${request?.currency} ${request?.minBudget} - ${request?.currency} ${request?.maxBudget}`}
                </Typography>
                </Stack>

               </Stack>
            

            </Box>
        </Box>
        <Box style={{paddingRight:"10px",paddingLeft:"20px",paddingTop:'10px'}}
							onClick={() => callbackNav()}

			  >
            <Box style={{borderBottom:"1px solid #8F8F8F"}}>
            <Stack direction="row" spacing={5}>
                <Stack direction="row" spacing={1}>
                <Typography style={{width:"15px"}}> 
                <CalendarMonthIcon
                  sx={{ fontSize: "16px", mt: 0.25, color: "#8F8F8F" }}
                ></CalendarMonthIcon>
                </Typography>
                <Typography
                  variant={"body2"}
                  fontWeight={500}
                  sx={{ color: "#8F8F8F" }}
                >
                  Due Date
                </Typography>
                    </Stack>
                <Stack>
                  
                <Typography variant={"body2"} fontWeight={500}>
                  {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? moment(request.fundingCloseDate).format("MMM D, YYYY")
                    : `${moment(request.from).format("MMM D, YYYY")} `}
                </Typography>
                </Stack>

               </Stack>
            

            </Box>
        </Box>
        <Box style={{paddingRight:"10px",paddingLeft:"20px",paddingTop:'10px'}} 
					onClick={() => callbackNav()}
	
			  >
            <Box style={{borderBottom:"1px solid #8F8F8F"}}>
            <Stack direction="row" spacing={5}>
                <Stack direction="row" spacing={1}>
                <Typography style={{width:"15px"}}> 
                <BusinessCenterIcon
                  sx={{ fontSize: "16px", mt: 0.25, color: "#8F8F8F" }}
                ></BusinessCenterIcon>
                </Typography>
                <Typography
                  variant={"body2"}
                  fontWeight={500}
                  sx={{ color: "#8F8F8F" }}
                >
               {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? "Round"
                    : "Job type"}
                </Typography>
                    </Stack>
                <Stack>
                  
                <Typography variant={"body2"} fontWeight={500}>
                {request?.requestFor === "Looking for funding" ||
                  request.type === "looking-for-funding"
                    ? request?.round
                    : request?.jobType}
                </Typography>
                </Stack>

               </Stack>
            

            </Box>
        </Box>
		 </Box>
		 
		</>
		// <Stack
		// 	direction="column"
		// 	justifyContent="center"
		// 	alignItems="flex-start"
		// 	spacing={1}
		// 	sx={{
		// 		py: 1.5, 
		// 		px: 3,
		// 		width: "100%",
		// 		minHeight: 100,
		// 		backgroundColor: "#F6F8FA",
		// 		border: "1px solid #000",
		// 		borderRadius: "8px",
		// 	}}>
		// 	<Stack
		// 		direction="row"
		// 		justifyContent="flex-start"
		// 		alignItems="flex-start"
		// 		spacing={3}>
		// 		<Typography
		// 			variant={"body1"}
		// 			noWrap={true}
		// 			onClick={() => callbackNav()}
		// 			sx={{ width: 400, lineHeight: 1.3, cursor: "pointer" }}>
		// 			{request.requestFor} <br />
		// 			<font color={"#8F8F8F"} size={2}>
		// 				{request?.location
		// 					? request?.location
		// 					: request?.creatorData?.startup &&
		// 					  request?.creatorData?.startup.location}
		// 			</font>
		// 		</Typography>
		// 		<Stack
		// 			direction={"column"}
		// 			spacing={1}
		// 			onClick={() => callbackNav()}
		// 			sx={{ textAlign: "left", cursor: "pointer", width: 85 }}>
		// 			<Typography variant={"body2"} fontWeight={500}>
		// 				Stage
		// 			</Typography>
		// 			<Stack
		// 				direction="row"
		// 				justifyContent="flex-start"
		// 				alignItems="center"
		// 				spacing={3}
		// 				sx={{ color: "#8F8F8F", fontSize: 14 }}>
		// 				<Badge
		// 					variant={"dot"}
		// 					invisible={false}
		// 					color={
		// 						!request.stage || request.stage === "activate"
		// 							? "primary"
		// 							: request.stage === "closed"
		// 							? "error"
		// 							: "warning"
		// 					}
		// 				/>
		// 				&nbsp;&nbsp;&nbsp;
		// 				{request.stage && request.stage === "activate"
		// 					? "Open"
		// 					: !request.stage
		// 					? "Open"
		// 					: request.stage}
		// 			</Stack>
		// 		</Stack>
		// 		<Stack
		// 			direction={"column"}
		// 			spacing={1}
		// 			onClick={() => callbackNav()}
		// 			sx={{ textAlign: "left", width: 120, cursor: "pointer" }}>
		// 			<Typography variant={"body2"} fontWeight={500}>
		// 				{request?.requestFor === "Looking for funding" ||
		// 				request.type === "looking-for-funding"
		// 					? "Amount to raise"
		// 					: "Budget"}
		// 			</Typography>
		// 			<Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
		// 				{request?.requestFor === "Looking for funding" ||
		// 				request.type === "looking-for-funding"
		// 					? `${request?.currency} ${request?.amount}`
		// 					: `${request?.currency} ${request?.minBudget} - ${request?.currency} ${request?.maxBudget}`}
		// 			</Typography>
		// 		</Stack>
		// 		<Stack
		// 			direction={"column"}
		// 			spacing={1}
		// 			onClick={() => callbackNav()}
		// 			sx={{ textAlign: "left", width: 120, cursor: "pointer" }}>
		// 			<Typography variant={"body2"} fontWeight={500}>
		// 				Due Date
		// 			</Typography>
		// 			<Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
		// 				{request?.requestFor === "Looking for funding" ||
		// 				request.type === "looking-for-funding"
		// 					? moment(request.fundingCloseDate).format(
		// 							"D MMM YYYY"
		// 					  )
		// 					: `${moment(request.from).format(
		// 							"D MMM YYYY"
		// 					  )} - ${moment(request.to).format("D MMM YYYY")}`}
		// 			</Typography>
		// 		</Stack>

		// 		<Stack
		// 			direction={"column"}
		// 			spacing={1}
		// 			onClick={() => callbackNav()}
		// 			sx={{ textAlign: "left", width: 70, cursor: "pointer" }}>
		// 			<Typography variant={"body2"} fontWeight={500}>
		// 				{request?.requestFor === "Looking for funding" ||
		// 				request.type === "looking-for-funding"
		// 					? "Round"
		// 					: "Job type"}
		// 			</Typography>
		// 			<Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
		// 				{request?.requestFor === "Looking for funding" ||
		// 				request.type === "looking-for-funding"
		// 					? request?.round
		// 					: request?.jobType}
		// 			</Typography>
		// 		</Stack>
		// 		<div sx={{ flex: 1 }} />
		// 		<Select
		// 			f
		// 			size={"small"}
		// 			value={request?.stage ? request?.stage : "activate"}
		// 			style={{
		// 				color: "#000",
		// 				borderRadius: "30px",
		// 				backgroundColor: "#eeeeee10",
		// 				width: 167,
		// 				paddingLeft: "10px",
		// 				paddingRight: "10px",
		// 				height: "40px",
		// 				fontSize: 14,
		// 			}}
		// 			IconComponent={() => <ArrowDropDownIcon />}
		// 			onChange={(e) => callback(e)}>
		// 			<MenuItem value={"Closed"}>Close</MenuItem>
		// 			<MenuItem value={"Deactivated"}>Deactivate</MenuItem>
		// 			<MenuItem value={"activate"}>Activate</MenuItem>
		// 		</Select>
		// 	</Stack>

		// 	<Grid
		// 		container
		// 		onClick={() => callbackNav()}
		// 		direction="row"
		// 		justifyContent="flex-start"
		// 		alignItems="center"
		// 		gap={1}>
		// 		{(request?.requestFor === "Looking for funding" ||
		// 			request?.type === "looking-for-funding") && (
		// 			<Grid item>
		// 				<Chip
		// 					variant="contained"
		// 					sx={{
		// 						my: 0,
		// 						backgroundColor: "#B2FFFA",
		// 						px: 0.5,
		// 						maxWidth: 250,
		// 						borderRadius: "5px",
		// 					}}
		// 					component={"p"}
		// 					label={"Looking for funding"}
		// 				/>
		// 			</Grid>
		// 		)}
		// 		{request?.searchFor &&
		// 			request?.searchFor.length > 0 &&
		// 			request?.searchFor?.map((s, i) => (
		// 				<Grid item>
		// 					<Chip
		// 						variant="contained"
		// 						sx={{
		// 							my: 0,
		// 							backgroundColor: "#FFE0CA",
		// 							px: 0.5,
		// 							maxWidth: 250,
		// 							borderRadius: "5px",
		// 						}}
		// 						component={"p"}
		// 						label={s}
		// 						key={i}
		// 					/>
		// 				</Grid>
		// 			))}
		// 		{request?.skills &&
		// 			request?.skills.length > 0 &&
		// 			request?.skills?.map((s, i) => (
		// 				<Grid item>
		// 					<Chip
		// 						variant="contained"
		// 						sx={{
		// 							my: 0,
		// 							backgroundColor: "#E5FF9C",
		// 							px: 0.5,
		// 							maxWidth: 250,
		// 							borderRadius: "5px",
		// 						}}
		// 						component={"p"}
		// 						label={s}
		// 						key={i}
		// 					/>
		// 				</Grid>
		// 			))}
		// 	</Grid>
		// </Stack>
	);
}
