import React from 'react';
import {collection, doc, getDoc, getDocs, onSnapshot, orderBy, query} from "firebase/firestore";
import {DB} from "../../config/firebase/firebase";
import {AuthUserContext} from "../../providers/auth-provider";
import { Container, Stack, Typography, ButtonGroup, Button, Grid, Skeleton } from '@mui/material'
import PostItem from "../../components/common/timeline/post-item";
import StartupListItem from '../../components/common/startup-list-item'
import FounderListItem from '../../components/common/founder-list-item'
import OrganisationInvestorListItem from '../../components/common/organisation-investor-list-item'
import InvestorListItem from '../../components/common/investor-list-item'
import ExpertListItem from '../../components/common/expert-list-item'
import TalentListItem from '../../components/common/talent-list-item'
import ServiceProviderListItem from '../../components/common/service-provider-list-item'
import OrganisationListItem from '../../components/common/organisation-list-item'
import BusinessDeveloperListItem from '../../components/common/business-developer-list-item'

export default function MyFavorites(){
  const {profile} = React.useContext(AuthUserContext);
  
  const [postIds, setPostIds] = React.useState([])
  const [memberIds, setMemberIds] = React.useState([])
  const [showMembers, setShowMembers] = React.useState(false)

  React.useEffect(() => {
    let unsub;
    const q = query(
      collection(DB, "users", profile?.uid, "favoritePosts"),
      orderBy("createdAt", "desc")
    );
    unsub = onSnapshot(q, (docs) => {
      const list =[]
      if (docs.size > 0) {
        docs.forEach((doc) => list.push(doc?.id))
      }
      setPostIds(list);
    })
    return () => unsub
  }, [])

  React.useEffect(() => {
    let unsub;
    const q = query(
      collection(DB, "users", profile?.uid, "favoriteMembers"),
      orderBy("createdAt", "desc")
    );
    unsub = onSnapshot(q, (docs) => {
      const list =[]
      if (docs.size > 0) {
        docs.forEach(
          (doc) => {
            return list.push({id: doc?.id, ...doc.data()
          })})
      }
      setMemberIds(list);
    })
    return () => unsub
  }, [])



  return (<Container maxWidth={'xl'} sx={{p:5}}>
    <Stack direction={"column"} pl={2} spacing={0}>
      <Typography variant={"h4"} fontWeight={600} textAlign={"left"}>Favorites Tab</Typography>
      <Typography variant={"caption"} fontWeight={500} fontSize={12.4} textAlign={"left"}>your most visited and saved content</Typography>
      

    </Stack>
    <Container maxWidth={!showMembers && 'md'}>
      <ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{textTransform:'capitalize'}}>
        <Button variant={showMembers ? "outlined" : "contained"} sx={{width:115, textTransform:'capitalize',}} onClick={() => {setShowMembers(false)}}>Posts</Button>
        <Button variant={showMembers ? "contained" : "outlined"} sx={{width:115, textTransform:'capitalize',}} onClick={() => {setShowMembers(true)}}>Members</Button>
      </ButtonGroup>
      {showMembers === false ?
        <Stack direction={"column"} spacing={5} sx={{width:'100%',  textAlign:'left'}}>
          <Stack direction={"column"} spacing={5} sx={{width: '100%'}}  justifyContent="center"
                                alignItems="center">
            <span/>
            {
              postIds.length === 0 ?
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    width: "75%"
                    , height: 150, border:'1px solid #3F56FF', padding: 2 , marginX: 'auto', boxSizing: 'border-box15'}}
                >
                  <Typography variant={'h6'} fontWeight={500} color={'#3F56FF'} textAlign={'center'}>
                    <font color={'#3F56FF'}>You have not added any</font>
                    <br />Favorites
                    <font color={' #3F56FF'}> yet.</font>
                  </Typography>
                </Stack> :
                 postIds.length > 0 && postIds.map((pid) => (<MyFavoritePostItem id={pid} key={'pid' + pid}/>)) }

        </Stack>
        </Stack>


        : memberIds.length === 0 ?
            <Stack direction={"column"} spacing={5} sx={{ width: '100%' }} justifyContent="center"
                   alignItems="center">

              <span/>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                  width: "75%"
                  , height: 150, border: '1px solid #3F56FF', padding: 2, marginX: 'auto', boxSizing: 'border-box15'
                }}
              >
                <Typography variant={'h6'} fontWeight={500} color={'#3F56FF'} textAlign={'center'}>
                  <font color={'#3F56FF'}>You have not made any member</font>
                  <br/>Favorite
                  <font color={' #3F56FF'}> yet.</font>
                </Typography>
              </Stack>
            </Stack>
            :
            <Container maxWidth={'lg'} sx={{ my: 5, textAlign: 'left' }}>
              <Grid container spacing={5}>
                {memberIds.map((pid) => (<MyFavoriteMemberItem id={pid} key={'pid' + pid.id}/>))}
              </Grid>
            </Container>
        }
    </Container>
  </Container>)
}

function MyFavoritePostItem({id}) {
  const [post, setPost] = React.useState()
  React.useEffect(() => {
    const timelineRef = doc(DB, "timelines", id);
     getDoc(timelineRef).then((doc) => {
       setPost({...doc.data(), id})
     });
    
  }, [id])
  
 if (post && post?.createdAt) return (<PostItem
    data={post}
    key={id}
  />)
}

function MyFavoriteMemberItem({id}) {
  const {profile} = React.useContext(AuthUserContext);
  const [member, setMember] = React.useState()
  React.useEffect(() => {
    if(id && id.id) {
    const memberRef = doc(DB, "users", id.id);
    getDoc(memberRef).then((doc) => {
      setMember({...doc.data(), ...id})
    });
  }
  }, [id])

  if (member && member.userType){
    console.log("member",member)
    return (member && member.userType &&
      member.userType === 'startup' && member.favorites.some(item => item === member.userType) && member.favorites.some(item => item === "founder") ? <>
          <StartupListItem data={member} key={member?.id} href={`/${profile?.userType}/startup-details/${member?.id}`}/>

          <FounderListItem data={member} key={member?.id+member?.createdAt} href={`/${profile?.userType}/startup-founder-profile`}/>
        </> :
      member.userType === 'startup' && member.favorites.some(item => item === member.userType) ? <>
        <StartupListItem data={member} key={member?.id} href={`/${profile?.userType}/startup-details/${member?.id}`}/></>
        :
          member?.startup?.founder && member.favorites.some(item => item === "founder") ?<>
          <FounderListItem data={member} key={member?.id+member?.createdAt} href={`/${profile?.userType}/startup-founder-profile`}/>
            </>:
        member.userType === 'investor' && member.favorites.some(item => item === member.userType) && member.favorites.some(item => item === "investor-organization") ? <>
              <OrganisationInvestorListItem data={member} key={member?.id+member?.createdAt} href={`/${profile?.userType}/common-organisation-profile`}/>
            <InvestorListItem data={member} key={member?.id} href={`/${profile?.userType}/investor-details/${member?.id}`}/>
          </> :
          member.userType === 'investor' && member.favorites.some(item => item === member.userType)? <>
              <InvestorListItem data={member} key={member?.id} href={`/${profile?.userType}/investor-details/${member?.id}`}/>
            </> :
            member.userType === 'investor' && member.favorites.some(item => item === "investor-organization")? <>
                <OrganisationInvestorListItem data={member} key={member?.id+member?.createdAt} href={`/${profile?.userType}/common-organisation-profile`}/>
              </> :

          member.userType === 'expert' ? <ExpertListItem data={member} key={member?.id} href={`/${profile?.userType}/expert-details/${member?.id}`}/> :

            member.userType === 'talent' ? <TalentListItem data={member} key={member?.id} href={`/${profile?.userType}/talent-details/${member?.id}`}/> :

              member.userType === 'service-provider' && member.favorites.some(item => item === member.userType) && member.favorites.some(item => item === "service-provider-organization") ? <>
                  <ServiceProviderListItem data={member} key={member?.id} href={`/${profile?.userType}/service-provider-details/${member?.id}`}/>
                    <OrganisationListItem data={member} key={member?.id+'organisation'} href={`/${profile?.userType}/organisation-profile`}/>
                </>:
                member.userType === 'service-provider' && member.favorites.some(item => item === member.userType) ? <>
                    <ServiceProviderListItem data={member} key={member?.id} href={`/${profile?.userType}/service-provider-details/${member?.id}`}/>
                  </>:
                  member.userType === 'service-provider' && member.favorites.some(item => item === "service-provider-organization") ? <>
                      <OrganisationListItem data={member} key={member?.id+'organisation'} href={`/${profile?.userType}/organisation-profile`}/>
                    </>:

                member.userType === 'business-developer' ? <BusinessDeveloperListItem data={member} key={member?.id} href={`/${profile?.userType}/business-developer-details/${member?.id}`}/> : false


    )
  }


}
