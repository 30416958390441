import * as React from "react";

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fab,
  Portal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  fetchSignInMethodsForEmail,
  getAuth,
  EmailAuthProvider,
} from "firebase/auth";
import { AuthUserContext } from "../../../providers/auth-provider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputBase from "@mui/material/InputBase";
import { EmailOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ReferAFriend from "../../../api/refer-a-friend";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SendIcon from "@mui/icons-material/Send";
import { doc, setDoc } from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import { USER_TYPES } from "../../../utils/enums/userTypes.enum";
import { BUSINESS_DEVELOPER, USER_TYPES_TO_NAME, USER_TYPES_TO_NAMEC } from "../../../utils/enums/labels.enum";
export default function LeftPanel() {
  const { profile, setProfile } = React.useContext(AuthUserContext);
  const [email, setEmail] = React.useState([]);
  const [openRewards, setOpenRewards] = React.useState(false);
  const [open, setOpen] = React.useState(!(profile && profile?.firstReward));
  const [openTooltip, setTooltip] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("");
  const [alreadyExists, setAlreadyExists] = React.useState(false);
  React.useEffect(() => {}, [alreadyExists]);
  const setFirstReward = async () => {
    debugger;
    const userRef = doc(DB, "users", profile.uid);
    await setDoc(userRef, { firstReward: true }, { merge: true });
  };
  const [nextScreen, setNextScreen] = React.useState(false);
  return (
    <Stack
      position={"static"}
      sx={{
        boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
        background: "#fff",
        borderRadius: "15px",
        height: 346,
        width: 230,
      }}
      direction={"column"}
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        direction={"column"}
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{ borderBottom: "0.5px solid #a9abae52", width: "100%" }}
      >
        <Box />

        <Avatar
          sx={{ height: 83, width: 83 }}
          imgProps={{ sx: { objectFit: "cover" } }}
          src={profile?.photoURL || profile?.startup?.founder?.photoURL
            // profile.userType !== "startup"
            //   ? profile?.photoURL
            //   : profile?.photoURL !== ""
            //   ? profile?.photoURL
            //   : profile?.startup?.founder?.photoURL !== ""
            //   ? profile?.startup?.founder?.photoURL
            //   : profile?.photoURL
          }
        >
          <Typography variant={"h3"}>
            {profile.userType === "startup"
              ? profile?.startup?.founder?.founderName?.charAt(0) ||
                profile?.displayName?.charAt(0)
              : profile?.displayName?.charAt(0)}
          </Typography>
        </Avatar>
        <Typography
          variant={"h6"}
          noWrap={true}
          sx={{
            maxWidth: 180,
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          {profile.userType === "startup"
            ? profile?.startup?.founder?.founderName || profile?.displayName
            : profile?.displayName}
          <br />
          <Typography
            variant={"body1"}
            sx={{
              textAlign: "center",
              
            }}
            color={"primary"}
          >
            {profile?.userType === "startup"
              ? "Founder at "+ (profile?.startup?.startupName)
              : USER_TYPES_TO_NAMEC[profile?.userType]}
          </Typography>
        </Typography>
        <Box />
        <Box />
        <Box />
      </Stack>
      <Stack
        direction={"column"}
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          py: 1.5,
          borderBottom: "0.5px solid #a9abae52",
          width: "100%",
        }}
      >
        <Stack direction={"row"} spacing={1} sx={{ width: "100%", px: 1.5 }}>
          <Typography variant={"body2"} sx={{ flex: 1 }}>
            Referral
          </Typography>
          <Typography variant={"body2"} color={"primary"}>
            {profile?.referList ? profile?.referList?.length : 0}
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={1} sx={{ width: "100%", px: 1.5 }}>
          <Typography variant={"body2"} sx={{ flex: 1 }}>
            Your Points
          </Typography>
          <Typography variant={"body2"} color={"primary"}>
            {profile?.earning || 0}
          </Typography>
        </Stack>
      </Stack>
      <Box />
      <Button
        color={"primary"}
        size={"large"}
        variant="contained"
        sx={{
          justifyContent: "center",
          fontSize: 10,
          borderRadius: "32px",
          py: 1.2,
        }}
        onClick={() => setOpenRewards(!openRewards)}
      >
        <img src={"/assets/referral_star.png"} alt="none" />
        &nbsp;
        <Typography
          sx={{ fontSize: 10, fontWeight: 400, textTransform: "none" }}
        >
          Refer and earn points
        </Typography>
      </Button>

      <Portal>
        <Dialog
          open={openRewards}
          onClose={() => {
            setOpenRewards(false);
            setTimeout(() => {
              setShow(false);
              setNextScreen(false);
            }, 200);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
        >
          <DialogContent sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  setOpenRewards(false);
                  setTimeout(() => {
                    setShow(false);
                    setNextScreen(false);
                  }, 200);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={5}>
              {nextScreen ? null : (
                <>
                  <Typography
                    component="span"
                    variant="h5"
                    color="text.primary"
                    sx={{
                      textAlign: "center",
                      flex: 1,
                      padding: 0,
                      fontWeight: 700,
                    }}
                  >
                    Refer a friend
                  </Typography>
                </>
              )}

              {nextScreen ? (
                <>
                  <Typography
                    component="span"
                    variant="h5"
                    color="text.primary"
                    sx={{
                      textAlign: "center",
                      flex: 1,
                      padding: 0,
                      fontWeight: 700,
                    }}
                  >
                    Referral code sent!
                  </Typography>
                  <Typography
                    variant={"body2"}
                    sx={{
                      lineHeight: 1.3,
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {email.slice(-1)}
                    </span>{" "}
                    <br></br>will receive an email with your referral code.
                  </Typography>
                  <Box
                    style={{
                      backgroundColor: "#E0E3FF",
                      height: "60px",
                      textAlign: "center",
                      padding: "20px",
                    }}
                  >
                    <Typography>What’s in it for me, you ask?</Typography>
                  </Box>
                  <Typography
                    component="span"
                    variant="h5"
                    color="text.primary"
                    sx={{
                      textAlign: "center",
                      flex: 1,
                      padding: 0,
                      fontWeight: 600,
                    }}
                  >
                    You can earn 50 points <br></br>{" "}
                    <span
                      style={{
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      when your friend joins the AstraNOVA community!
                    </span>
                  </Typography>
                </>
              ) : null}

              {!show && !nextScreen && (
                <Stack
                  direction={"column"}
                  sx={{ width: "100%", height: 179.24 }}
                >
                  <img src={"/assets/yellow.jpeg"}></img>
                </Stack>
              )}

              {!show && !nextScreen && (
                <Typography
                  variant={"body2"}
                  sx={{
                    lineHeight: 1.3,
                    textAlign: "center",
                  }}
                >
                  <>
                    You can refer a friend now
                    <br />
                    to earn <font size={6}>50</font> more points!
                  </>
                </Typography>
              )}

              {show && !nextScreen ? (
                <>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{ height: 100 }}
                  >
                    <Typography
                      variant={"body2"}
                      sx={{
                        lineHeight: 1.5,
                        textAlign: "center",
                      }}
                    >
                      Your referral code <br />
                      <font size={6} style={{ fontWeight: 600 }}>
                        {profile?.referralCode}
                      </font>
                    </Typography>

                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setTooltip(!openTooltip)}
                      open={openTooltip}
                      title="Copy"
                      placement="top"
                    >
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(profile?.referralCode);
                          setTooltip(!openTooltip);
                        }}
                      >
                        <ContentCopyIcon sx={{ fontSize: 30 }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>

                  <Stack
                    direction={"column"}
                    sx={{ width: "100%", px: 8 }}
                    spacing={1}
                  >
                    <Typography variant={"body2"} color={"secondary"}>
                      Enter email address to share referral code via email.
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%" }}
                      spacing={2}
                    >
                      <TextField
                        type={"email"}
                        value={text}
                        error={alreadyExists}
                        helperText={
                          alreadyExists
                            ? "Oops! This user already exists on the system."
                            : ""
                        }
                        placeholder={"Email address"}
                        onChange={(e) => {
                          setText(e.target.value);
                          setAlreadyExists(false);
                        }}
                        size={"small"}
                        fullWidth
                      />
                      <Fab
                        size={"medium"}
                        variant={"extended"}
                        color={loading ? "" : "primary"}
                        sx={{
                          boxShadow: "none",
                          borderRadius: "5px",
                          py: 2.7,
                        }}
                        disabled={text === "" || alreadyExists}
                        onClick={async () => {
                          try {
                            setLoading(true);
                            const auth = getAuth();
                            await fetchSignInMethodsForEmail(auth, text)
                              .then(async (signInMethods) => {
                                if (
                                  signInMethods.indexOf(
                                    EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
                                  ) != -1
                                ) {
                                  setAlreadyExists(true);
                                  // console.log(
																		// 	alreadyExists
																		// );

                                  setLoading(false);
                                } else {
                                  await ReferAFriend({
                                    email: text,
                                    refer: profile?.referralCode,
                                    username: profile?.displayName,
                                  });
                                  setLoading(false);
                                  // await setOpenRewards(!openRewards)
                                  await setShow(!show);
                                  setNextScreen(!nextScreen);
                                  setEmail([...email, text]);
                                  setText("");
                                }
                              })
                              .catch((error) => {
                                console.log(error.message);
                              });

                            //  await setText('')
                          } catch (e) {
                            console.log(e);
                          }
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={18} />
                        ) : (
                          <SendIcon
                            sx={{
                              rotate: "-37deg",
                              position: "relative",
                              top: -3,
                            }}
                          />
                        )}
                      </Fab>
                    </Stack>
                  </Stack>
                </>
              ) : !nextScreen ? (
                <Stack
                  direction={"column"}
                  sx={{ width: "100%" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    variant={"contained"}
                    size={"small"}
                    sx={{ px: 2 }}
                    onClick={() => {
                      setShow(!show);
                    }}
                  >
                    Refer a friend
                  </Button>
                </Stack>
              ) : null}

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"sm"}
        >
          <DialogContent sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  if (!profile?.setFirstReward) {
                    setFirstReward();
                    setProfile({
                      ...profile,
                      firstReward: true,
                    });
                  }
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={5}>
              <Typography
                component="span"
                variant="h5"
                color="text.primary"
                sx={{
                  textAlign: "center",
                  flex: 1,
                  padding: 0,
                  fontWeight: 700,
                }}
              >
                You’ve achieved your first goal!
              </Typography>
              <Stack
                direction={"column"}
                sx={{ width: "100%", height: 179.24 }}
              >
                <img src={"/assets/yellow.jpeg"}></img>
              </Stack>
              <Typography
                variant={"body2"}
                sx={{ lineHeight: 1.3, textAlign: "center" }}
              >
                <>
                  Nice work!
                  <br />
                  You have signed up and earned <font size={6}>100</font> points
                </>
              </Typography>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant={"contained"}
                  size={"small"}
                  sx={{ px: 2 }}
                  onClick={() => {
                    if (!profile?.setFirstReward) {
                      setFirstReward();
                      setProfile({
                        ...profile,
                        setFirstReward: true,
                      });
                    }
                    setOpen(!open);
                    setOpenRewards(!openRewards);
                  }}
                >
                  Earn more points
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </Stack>
  );
}
