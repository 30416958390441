import React from "react";
import {
	Button,
	Container,
	Stack,
	IconButton,
	TextField,
	Avatar,
	CircularProgress,
	Link,
	Badge,
	styled,
	Typography,
	FormControl,
	FormLabel,
	FormControlLabel,
	Switch,
	FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import UploadFile from "../../../utils/upload-file";
import StartupOnBoardingValidation from "../../../validations/startup/startup-profile";

import { AuthUserContext } from "../../../providers/auth-provider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
export default function FounderProfileFirstForm({
	cbBack,
	cbNext,
	data,
	startupName,
}) {
	const Input = styled("input")({
		display: "none",
	});
	const { user } = React.useContext(AuthUserContext);

	const [profileImage, setProfileImage] = React.useState({
		src: data && data?.photoURL !== '' ? data?.photoURL : user?.photoURL || "",
		photoURL: data && data?.photoURL !== '' ? data?.photoURL : user?.photoURL || "",
	});
	const [loading, setLoading] = React.useState(false);
	const formikFirst = useFormik({
		initialValues: data
			? data
			: {
				founderName: user?.displayName,
				email: user?.email,
				description: "",
				startupName: "",
				photoURL: "",
				role: "",
				linkedIn: "",
				repeatFounder: ""
			},
			// : StartupOnBoardingValidation.FounderProfileInitialValues,
		validationSchema: StartupOnBoardingValidation.FounderProfileSchema,
		onSubmit: async (values) => {
			await formikFirst.setFieldValue(
				"photoURL",
				profileImage?.photoURL ? profileImage.photoURL : ""
			);
			cbNext({ ...values, "photoURL":
					profileImage?.photoURL ? profileImage.photoURL : "" });
		},
	}); 
	return (
		<Stack
			direction={"column"}
			spacing={10}
			component="form"
			onSubmit={(e) => formikFirst.handleSubmit(e)}>
			<Container maxWidth={"md"}>
				<Stack direction={"column"} spacing={5}>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						spacing={1}
						sx={{ mb: 5 }}>
						<label htmlFor="profile-image">
							<Badge
								overlap="circular"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								badgeContent={
									<IconButton
										color={"primary"}
										component={"span"}>
										<AddCircleIcon />
									</IconButton>
								}>
								<Avatar
									alt={"startup-picture"}
									src={
										profileImage && profileImage?.src
											? profileImage?.src
											: "/assets/default-avatar.png"
									}
									sx={{
										width: 128,
										height: 128,
										cursor: "pointer",
										backgroundColor: "transparent",
										border: "1px solid #E0E3FF",
									}}
									component={"span"}
								/>
							</Badge>
							<Input
								accept="image/*"
								id="profile-image"
								multiple={false}
								type="file"
								onChange={(e) => {
									const fileReader = new FileReader();
									fileReader.readAsDataURL(e.target.files[0]);
									fileReader.addEventListener(
										"load",
										async function () {
											setLoading(true);
											const file = e.target.files[0];
											const path = `startups/${user.uid}/founder-profile/${file.name}`;
											const imageObject = {
												progress: 0,
												src: this.result,
												path,
												file: e.target.files[0],
												size: (
													e.target.files[0]?.size /
													1024 /
													1024
												).toFixed(2),
											};
											setProfileImage(imageObject);
											UploadFile(file, path).then(
												(url) => {
													setProfileImage({
														...imageObject,
														photoURL: url,
													});
													setLoading(false);
												}
											);
										}
									);
								}}
							/>
						</label>
						{loading && (
							<CircularProgress
								sx={{ ml: 2 }}
								size={20}
								color="primary"
							/>
						)}
						{profileImage && profileImage?.src && (
							<Link
								color={"secondary"}
								onClick={() => setProfileImage(undefined)}>
								Remove picture
							</Link>
						)}
					</Stack>
					<Stack direction={"column"} spacing={2}>
					<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Founder’s Name * 
							  </Typography>
						<TextField
							required
							name={"founderName"}
							value={formikFirst.values.founderName}
							onChange={formikFirst.handleChange}
							error={
								(formikFirst.touched.founderName &&
									Boolean(formikFirst.errors.founderName)) ||
								formikFirst.values.founderName.length > 30 ||
								(formikFirst.values.founderName.length > 0 &&
									formikFirst.values.founderName.length < 3)
							}
							helperText={
								formikFirst.values.founderName.length > 0 &&
								formikFirst.values.founderName.length < 3
									? "The Founder Name should be atleast 3 characters long"
									: formikFirst.touched.founderName &&
									  formikFirst.errors.founderName
									? "The Founder Name is Required"
									: formikFirst.values.founderName.length > 35
									? "The Founder Name cant be more than 35 characters long"
									: ""
							}
							//label={"Founder’s Name"}
							fullWidth
							disabled
						/>
						</Stack>
						<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Email *
							  </Typography>
						<TextField
							required
							name={"email"}
							value={formikFirst.values.email}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.email &&
								Boolean(formikFirst.errors.email)
							}
							helperText={
								formikFirst.touched.email &&
								formikFirst.errors.email
							}
							//label={"Email"}
							fullWidth
							disabled
						/>
						</Stack>
					</Stack>
					<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Tell us about yourself *
							  </Typography>
					<TextField
						name={"description"}
						value={formikFirst.values.description}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.description &&
							Boolean(formikFirst.errors.description)
						}
						helperText={
							formikFirst.touched.description &&
							formikFirst.errors.description
						}
						//label={"Tell us about yourself"}
						inputProps={{maxLength:150}}
						multiline={true}
						rows={3}
					/>
					</Stack>
					<Typography align="right" sx={{color:"#233DFF"}}>{150-formikFirst.values.description.length}/150</Typography>
					{/* <Stack
						direction={"column"}
						spacing={2}
						sx={{ textAlign: "left" }}>
						<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
							Startup name
							<br />{" "}
							<font
								style={{
									backgroundColor: "#F6F8FA",
									padding: "8px 15px",
									borderRadius: "5px",
									fontSize: 14,
								}}>
								{startupName}
							</font>
						</Typography>
					</Stack> */}
					<Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >LinkedIn URL
							  </Typography>
          <TextField
            name={'linkedIn'}
            value={formikFirst.values.linkedIn}
            onChange={formikFirst.handleChange}
            error={formikFirst.touched.linkedIn && Boolean(formikFirst.errors.linkedIn)}
            helperText={formikFirst.touched.linkedIn && formikFirst.errors.linkedIn}
            fullWidth 
            //label={'LinkedIn URL'} 

            />
            </Stack>
			<FormControl component="fieldset" variant="standard">
            <FormLabel component="legend"  sx={{textAlign: 'left', mb: 2}} style={{color:"black"}}>Repeat Founder</FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch    name={'repeatFounder'}
                             checked={formikFirst.values.repeatFounder}
                             onChange={formikFirst.handleChange} />
                }
              />
              <Typography>Yes</Typography>

              <FormHelperText
                error={formikFirst.touched.repeatFounder && Boolean(formikFirst.errors.repeatFounder)}
                children={formikFirst.touched.repeatFounder && formikFirst.errors.repeatFounder}
              />
            </Stack>

          </FormControl>

					{/* <Stack direction={"column"} spacing={0}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Role *
							  </Typography>
					<TextField
					required
					name={"role"}
						value={formikFirst.values.role}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.role &&
							Boolean(formikFirst.errors.role)
						}
						helperText={
							formikFirst.touched.role &&
							formikFirst.errors.role
						}
						//label={"Role"}
						fullWidth
					/>
					</Stack> */}
				</Stack>
			</Container>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3}
				width={"100%"}>
				<Button
					variant={"outlined"}
					color={"primary"}
					style={{paddingLeft:"15%",paddingRight:"15%"}}

					sx={{ px: 5 }}
					onClick={cbBack}>
					Back
				</Button>
				<Button
					style={{paddingLeft:"15%",paddingRight:"15%"}}

					variant={"contained"}
					color={"primary"}
					sx={{ px: 5 }}
					type={"submit"}>
					Next
				</Button>
			</Stack>
		</Stack>
	);
}
