import React, {useContext} from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from "@mui/material/Stack";
import {useNavigate} from "react-router-dom";
import {AuthUserContext} from "../../providers/auth-provider";
import SearchIcon from '@mui/icons-material/Search';
import {collection, doc, getDoc, getDocs, orderBy, query} from "firebase/firestore";
import {DB} from "../../config/firebase/firebase";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import {
  CheckCircle,
  DownloadOutlined} from "@mui/icons-material";
import CommonAvatar from "../../components/common-avatar";
import DownloadFileHelper from "../../utils/download-file-helper";
import { Box } from '@mui/material';

export default function ContractsList(){
  const navigator = useNavigate()
  const {profile} = useContext(AuthUserContext);
  const [admins, setAdmins] = React.useState([]);
  const [searchAdmin , setSearchAdmin] = React.useState('')
  const [filterData, setFilterData] = React.useState([])

  
  const loadData = () => {
    const q = query(
      collection(DB, "contracts"),
      orderBy("createdAt", "desc")
    );
    getDocs(q).then((snaps) =>  {
      if (snaps.size > 0) {
        const data = [];
        snaps.docs.forEach((d) => {
          data.push({id:d.id ,...d.data()});
        })
        setAdmins(data);
      }
    })
  }
  const search = () => {
    
    if (searchAdmin === '') {
      setFilterData([...admins])
    } else {
      const regex = new RegExp(searchAdmin);
      setFilterData(admins.filter((u) => regex?.test(u.displayName) || regex?.test(u.email) ));
    }
  }
  React.useEffect(() => {
    loadData()
  }, [])
  
  const filteredAdmin = searchAdmin === "" ? admins : admins.filter((item) => {return (item.displayName.toLowerCase()).startsWith(searchAdmin.toLowerCase())})
  return (
    <Container maxWidth={'full-width'}  sx={{height: 'calc(100vh - 120px)', p: 5, textAlign: 'left',overflowY: 'scroll'}}>
      <Stack direction={'column'} spacing={5}>
        <Stack direction={'row'} spacing={3}>
          <Typography variant={'h4'} fontWeight={600} >
            Contracts <sup><font size={2}>({admins?.length})</font></sup>
          </Typography>
          
          <div style={{flex: 1}}/>
          <Container maxWidth={'full-width'}  sx={{
            p: "2px 10px",
            display: "flex",
            alignItems: "center",
            width: 337,
            height: 43,
            borderRadius: "12px",
            backgroundColor: "#E4EAF5",
          }}>
            
            <IconButton
              type="button"
              sx={{p: "10px"}}
              aria-label="search"
              onClick={(e) => search}>
              <SearchIcon sx={{color: "#051E40BF"}}/>
            </IconButton>
            <InputBase
              onChange={(e) => setSearchAdmin(e.target.value)}
              value={searchAdmin}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  search()
                }
              }}
              sx={{ml: 1, flex: 1, color: "#051E40BF"}}
              placeholder="Search"
              inputProps={{"aria-label": "search post"}}
            />
          </Container>
        </Stack>
        
        <Stack direction={"column"} spacing={3}>
          {admins?.length > 0 && admins?.map((contract) => (<ContractListItem contract={contract} key={contract?.id}/> ))}
        </Stack>
       
      </Stack>
      
    </Container>
  )
}

const ContractListItem = ({contract}) => {
  const navigator = useNavigate()
  
  const [request, setRequest] = React?.useState()
  const fetchData = async () => {
    const request = await getDoc(doc(DB, 'help-requests', contract?.requestId))
    setRequest(request?.data());
  }
  React.useEffect(() => {
    let unsub;
    if (contract) {
      unsub = fetchData().then()
    }
    return () => unsub
  }, [contract])
  return (
    <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="flex-start"
    spacing={8}
    sx={{
      py: 1.5,
      px: 2,
      width: "100%",
      minHeight: 100,
      backgroundColor: "rgba(246,248,250,0.50)",
      border: ".3px solid rgba(246,248,250,1)",
      borderRadius: "8px",
    }}>
      {request &&
        <Typography
          variant={"h6"}
          noWrap={true}
          flex={1}
          sx={{ lineHeight: 1.15, cursor: "pointer" }} onClick={() => navigator('/admin/contract-details', { state: { request, contract }})}>
          {request?.requestFor} <br />
          <font color={"#8F8F8F"} size={2}>
            {request?.location
              ? request?.location
              : request?.creatorData?.startup &&
              request?.creatorData?.startup.location}
          </font>
          <br/>
          <font  size={2}>{request?.requestFor === "Looking for funding" ||
          request?.type === "looking-for-funding"
            ? request?.round
            : request?.jobType}</font>
          <br/>
          <font  size={2}>Status: {contract?.status ? contract?.status : 'Pending'}</font>
        </Typography>}
      <Stack direction={"column"} spacing={1} flex={1}>
        <Typography
          variant={"body1"}
          noWrap={true}
          sx={{ width: '100%', lineHeight: 1.15, cursor: "pointer" }} onClick={() => navigator('/admin/contract-details', { state: { request, contract }})}>
          Party A
        </Typography>
        <Stack
          direction={"row"}
          spacing={3}
          sx={{
            width: '100%'
          }}
          justifyContent="flex-start"
          alignItems="flex-start">
            <Stack width={"50%"}>
          <CommonAvatar uid={contract?.partyA?.uid} />
            </Stack>
          
          {contract?.partyA?.signed &&
            <Stack
              direction={"row"}
              spacing={2}
              color={"secondary"} alignItems="center">
              <Typography variant={"body2"} noWrap={true} sx={{width: 120}}>
                <font size={2} color={'#8F8F8F'}>Contract {
                  contract?.partyA?.signed &&
        
                  <CheckCircle color={'success'} sx={{fontSize: 12}}/>} </font>
                <br/>
                {contract?.partyA?.contractPath?.split('/')
                  [contract?.partyA?.contractPath?.split('/')?.length - 1]}
              </Typography>
            </Stack>
          
          }
          <Box flex={1}></Box>
          {contract?.partyA?.signed &&
            <IconButton color={"primary"}
                        onClick={() => {
                          console.log(contract?.partyA?.contractPath);
                          contract?.partyA?.contractPath &&
                          contract?.partyA?.contractPath !== "" &&
                          DownloadFileHelper(contract?.partyA?.contractPath);
                        }}>
              <DownloadOutlined  />
            </IconButton>
          }
          
        </Stack>


      </Stack>
      <Stack direction={"column"} spacing={1} flex={1}>
        <Typography
          variant={"body1"}
          noWrap={true}
          sx={{ width: '100%', lineHeight: 1.15, cursor: "pointer" }} onClick={() => navigator('/admin/contract-details', { state: { request, contract }})}>
          Party B
        </Typography>
        <Stack
          direction={"row"}
          spacing={3}
          sx={{
         width: '100%'
          }}
          justifyContent="flex-start"
          alignItems="center">
             <Stack width={"50%"}> 
          <CommonAvatar uid={contract?.partyB?.uid} />
             </Stack>
          
          {contract?.partyB?.signed &&
          <>
            <Stack
              direction={"row"}
              spacing={2}
              color={"secondary"} alignItems="center">
              <Typography variant={"body2"} sx={{width: 120}}>
                <font size={2} color={'#8F8F8F'}>Contract {
                  contract?.partyB?.signed &&
        
                  <CheckCircle color={'success'} sx={{fontSize: 12}}/>} </font>
                <br/>
                {contract?.partyB?.contractPath?.split('/')
                  [contract?.partyB?.contractPath?.split('/')?.length - 1]}
              </Typography>
            </Stack>
            <Box flex={1}></Box>
            <IconButton color={"primary"}
                        onClick={() => {
                          console.log(contract?.partyB?.contractPath);
                          contract?.partyB?.contractPath &&
                          contract?.partyB?.contractPath !== "" &&
                          DownloadFileHelper(contract?.partyB?.contractPath);
                        }}>
              <DownloadOutlined  />
            </IconButton>
          </>
          }
         
        </Stack>
       {/* <Typography
          variant={"body2"}>
          {contract?.partyB?.signed ? 'Signed':'Not-signed'}
        </Typography>*/}


      </Stack>
    </Stack>
  )

}
