import * as yup from 'yup';
import {DEFAULT_LANGUAGE, DEFAULT_MARKET} from '../../utils/enums/labels.enum';

const requestAssistanceSchema = yup.object({
  type: yup.string('type'),

  description: yup.string('Additional information '),
  searchFor: yup.array("Search for").of(
    yup.string("")
  ).required("Search for").min(1),
  location: yup.string('Location').required('Location is a required field'),
  from: yup.date('Timeline From ').required('From is a required field'),
  to: yup.date('Timeline to').required('To is a required field').when("from",
  (from, yup) => from && yup.min(from, "End date cannot be before Start date")),
  
  currency:  yup.string('Currency').required(),
  typeOfJob:  yup.string('On-site/remote').required('On-site/remote'),
  minBudget:  yup.number('Min Budget').required('Min budget is a required field'),
  maxBudget:  yup.number('Max Budget').required('Max budget is a required field'),
  experience:  yup.number('Experience'),
  jobType: yup.string('Job type').required('Job type is a required field'),
  requestFor: yup.string('I am looking for help with  (e.g. setting up a company)').required('Request for is a required field'),
  skills: yup.array("Skills").of(
    yup.string("")
  ).required("Skills").min(1),
  sector: yup.array("Sector").of(
    yup.string("")
  ).required("Sector is required").min(1, "Sector is required"),
  market: yup.array("Market").of(
    yup.string("")
  ).required("Market is required").min(1, "Targeting Market is required"),
  languages:  yup
    .array('languages')
    .of(yup.string('')).required("Languages is required").min(1, "Languages is required"),
  deadline : yup.date('Application deadline').required('Application deadline is a required field'),
});
const requestAssistanceInitialValues = {
  type: '',
  description: '',
  searchFor: ['Industry Expert', 'Investor'],
  experience:0,
  sector: [],
  market: [DEFAULT_MARKET],
  location: '',
  from: '',
  to: '',
  currency: 'USD',
  minBudget: '',
  maxBudget: '',
  typeOfJob:'',
  jobType: '',/*On-site/remote*/
  requestFor: '',
  skills: [],
  deadline : '',
  languages:[DEFAULT_LANGUAGE],
}



const requestFundingSchema = yup.object({
  description: yup.string('description'),
  requestFor: yup.string('requestFor').required('Request for is a required field.'),
  type: yup.string('type'),
  round: yup.string('Round').required('Round is a required field.'),
  currency: yup.string('Currency').required(),
  amount: yup.number('Starting at').required('Amount is a required field.'),
  valuation: yup.string('valuation').required('Valuation is a required field.'),
  revenueModel: yup.string('RevenueModel').required('Revenue Model is a required field.'),
  femaleInFoundingTeam: yup.boolean('Female in founding team'),
  ethnicMinorityInFundingTeam: yup.boolean('ethnic minority in funding team'),
  immigrantInFundingTeam: yup.boolean('immigrant in funding team'),
  fundingCloseDate: yup.date('Funding close date').required('Funding close date is a required field.'),

})
const requestFundingInitialValues = {
  description: '',
  requestFor: '',
  type: '',
  round: '',
  currency: 'GBP',
  amount: '',
  valuation: '',
  valuationCurrency: 'GBP',
  revenueModel: '',
  femaleInFoundingTeam: false,
  ethnicMinorityInFundingTeam:false,
  immigrantInFundingTeam: false,
  fundingCloseDate: '',
}

const RequestAssistanceValidation = {
  requestAssistanceSchema,
  requestAssistanceInitialValues,
  requestFundingSchema,
  requestFundingInitialValues
}
export default RequestAssistanceValidation;
