import React, { useEffect } from "react";
import {
	doc,
	setDoc,
	serverTimestamp,
	addDoc,
	collection,
	getDoc,
} from "firebase/firestore";
import {
	Container,
	Stack,
	Typography,
	IconButton,
	TextField,
	Button,
} from "@mui/material";
import { getStorage, ref, deleteObject } from "firebase/storage";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import StartupProfileFirstForm from "./startup-profile-first-form";
import StartupProfileSecondForm from "./startup-profile-second-form";
import StartupProfileThirdForm from "./startup-profile-third-form";
import StartupProfileFourForm from "./startup-profile-four-form";
import LookingForFundingFirstForm from "./looking-for-funding-first-form";
import LookingForFundingSecondForm from "./looking-for-funding-second-form";
// import LookingForFundingThirdForm from "./looking-for-funding-third-form";
import { useFormik } from "formik";
import StartupOnBoardingValidation from "../../../validations/startup/startup-profile";
import DragDropFiles, {
	DragDropItem,
} from "../../../screens/mobile/components/common/drag-drop-files";
import FounderProfileFirstForm from "./founder-profile-first-form";
import FounderProfileSecondForm from "./founder-profile-second-form";
import { DB } from "../../../config/firebase/firebase";
import NotificationEmail from "../../../api/notification-email";
import PostNotification from "../../../api/post-notification";
import TopPriorities from "./top-priorities";
import TeamListView from "./onboarding-form/team-member-list-view";
import ExperienceForm from "../expert/onboarding-forms/experience-form";
import MemberForm from "./onboarding-form/member-form";
import InvestorDetails from "../expert/onboarding-forms/investor-details";
import InvestorThirdForm from "../investor/onboarding-forms/investor-third-form";
import InvestorFourForm from "../investor/onboarding-forms/investor-four-form";
import PortfoliosListView from "../investor/onboarding-forms/portfolios-list-view";
import InvestorPortfolioForm from "../investor/onboarding-forms/portfolio-form";
import {getAuth, updateProfile} from "firebase/auth";
export default function StartupProfile() {
	const { user, setProfile } = React.useContext(AuthUserContext);
	const [teamMembers, setTeamMembers] = React.useState([]);

/*	const formikFirst = useFormik({
		initialValues:
			StartupOnBoardingValidation.LookingForFundingThirdInitValue,
		validationSchema:
			StartupOnBoardingValidation.LookingForFundingThirdSchema,
		onSubmit: async (values) => {
			const attachments = [];
			if (files.length > 0) {
				for (let file of files) {
					const path = `startup/${user.uid}/looking-for-funding/attachments/${file.file.name}`;
					attachments.push(path);
				}
			}
			await formikFirst.setFieldValue("attachments", attachments);
			setSteps(steps + 1);
		},
	});*/
	const [steps, setSteps] = React.useState(0);
	const [formFistData, setFormFistData] = React.useState();
	const [formSecondData, setFormSecondData] = React.useState();
	const [formThirdData, setFormThirdData] = React.useState();
	const [portfolioIndex, setPortfolioIndex] = React.useState();
	const [formEightData, setFormEightData] = React.useState();
	const [formNineData, setFormNineData] = React.useState();
	const [formTenData, setFormTenData] = React.useState();

	const auth = getAuth()




	const [portfolios, setPortfolios] = React.useState([]);
	const [lookingForFundingSecondData, setLookingForFundingSecondData] =
		React.useState();
	const [founderProfileFirstData, setFounderProfileFirstData] =
		React.useState();
	const [founderProfileSecondData, setFounderProfileSecondData] =
		React.useState();
	const navigator = useNavigate();
	const startupPost = (startup) => {
		return addDoc(collection(DB, "startups"), {
			...startup,
			uid: user?.uid,
			updatedAt: serverTimestamp(),
			createdAt: serverTimestamp(),
		});
	};
	/*

	const postProfile = (startup, areYouAStartupInvestor) => {
		return setDoc(
			userRef,
			{
				startup,
				updatedAt: new Date().getTime(),
				areYouAStartupInvestor,
				onboard: true,
			},
			{ merge: true }
		);
	};
	const startupPost = (startup) => {
		return addDoc(collection(DB, "startups"), {
			...startup,
			uid: user?.uid,
			updatedAt: serverTimestamp(),
			createdAt: serverTimestamp(),
		});
	};
	const lookingForFundingRequest = (request) => {
		return addDoc(collection(DB, "help-requests"), {
			...request,
			/!* name,
      description,
      foundingDate,
      uid: user?.uid,
      location,
      startupId: startupId.id,
      type: 'startup',
      logo: profileImage?.path ? profileImage.path : '',
      requestFor: 'Looking for funding',
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      ...capitalRaisedDetails*!/
		});
	};*/

	/*const post = async (founder) => {
		debugger;
		const startup = {
			...formFistData,
			...formSecondData,
			...formThirdData,
			...lookingForFundingFirstData,
			...lookingForFundingSecondData,
			...formikFirst.values,
			founder: {
				...founderProfileFirstData,
				...founder,
				...founderProfileSecondData,
			},
		};
		try {
			await postProfile(startup, founder?.areYouAStartupInvestor);
			const startupID = await startupPost(startup);
			const userID = await getDoc(userRef);
			NotificationEmail({
				type: "post-startup",
				email: userID.data().email,
			});
			PostNotification({
				type: "post-startup",
				imageURL: startup?.logoURL ? startup?.logoURL : "",
				message: `Great News! Your startup ${startup?.startupName} is now LIVE and ready to be funded.`,
				startupId: startupID.id,
				name: startup?.startupName,
				uid: user.uid,
			}).then();
			await setProfile(userID.data());
			if (formThirdData?.lookingForFunding === true) {
				const helpID = await lookingForFundingRequest({
					...lookingForFundingFirstData,
					...lookingForFundingSecondData,
					...formikFirst.values,
					name: startup.startupName,
					description: startup.description,
					foundingDate: startup.foundingDate || "",
					uid: user?.uid,
					location: startup.location,
					startupId: startupID.id,
					type: "startup",
					logo: startup?.logoURL ? startup?.logoURL : "",
					requestFor: "Looking for funding",
					createdAt: serverTimestamp(),
					updatedAt: serverTimestamp(),
					creatorData: { id: userID?.id, ...userID.data() },
				});
			}
			navigator("/startup/timeline");
			/!*
			 * TODO: Set the Profile data*!/
		} catch (e) {}
	};*/

	/*
  *
  *
   const userRef = doc(DB, 'users', user.uid);
        const startup = {
          ...values,
          logo: profileImage?.path ? profileImage.path : '',
          focusVertical,
          location: locations,
          market: markets,
          topPriorities: priorities
        };

        await setDoc(userRef, {startup, updatedAt: new Date().getTime(), createdAt: new Date().getTime()}, {merge: true});

        let startupId = await addDoc(collection(DB, "startups"), {...startup, uid: user?.uid,updatedAt: serverTimestamp(),createdAt: serverTimestamp()});

        // await setDoc(startupsRef, {...startup, uid: user?.uid,updatedAt: serverTimestamp(),createdAt: serverTimestamp()});
        let helpId;
        if (values.capitalRaisedDetails
          && values.capitalRaisedDetails?.round !== ''
          && values.capitalRaisedDetails?.amount !== 0
          && values.capitalRaisedDetails?.currency !== ''
          && values.capitalRaisedDetails?.valuation !== ''
        ) {
          const {name,
            description,
            foundingDate,
            capitalRaisedDetails, location} = startup;
         helpId = await addDoc(collection(DB, "help-requests"), {
            name,
            description,
            foundingDate,
            uid: user?.uid,
            location,
            startupId: startupId.id,
            type: 'startup',
            logo: profileImage?.path ? profileImage.path : '',
            requestFor: 'Looking for funding',
            createdAt: new Date().getTime(),
            updatedAt: new Date().getTime(),
            ...capitalRaisedDetails
          });
        }

        if (values.openToCofounder === true) {
          navigator('/on-boarding/open-to-cofounder', {state: {startup, startupId:startupId.id}})
        } else {
          navigator('/on-boarding/founder-profile-setup', {state: {startupName: values.name, startupId:startupId.id}})
        }
  *
  * */

	const submitData = async (values) => {
		try {
			const userRef = doc(DB, "users", user.uid);
			const copyPortfolio = []
			if (portfolios.length > 0) {
				for (let portfolio of portfolios) {
					const attachments =[]
					if (portfolio.attachments.length > 0) {
						for (let file of portfolio.attachments) {
							const path = `investors/${user.uid}/attachments/${file.file.name}`
							attachments.push(path)
						}
					}
					copyPortfolio.push({...portfolio, attachments})
				}
			}
			const copyMember = []
			if (teamMembers.length > 0) {
				for (let experience of teamMembers) {

					copyMember.push({...experience})
				}
			}
			const copyValue =
				{
					...values, investorDetails: {...values?.investorDetails,portfolio: copyPortfolio
					}
				}

			const startup = {
				...copyValue,
				teamMembers: copyMember,
				photoURL: values?.photoURL
					? values.photoURL
					: "",
			};
			await setDoc(
				userRef,
				{
					photoURL: values?.photoURL
						? values.photoURL
						: "",
					startup,
					onboard: true,
				},
				{ merge: true }
			);
			updateProfile(
				auth.currentUser,
				{photoURL:  values?.founder?.photoURL
					? values?.founder?.photoURL
					: ""
				}).then()
			const getdoc = await getDoc(userRef);
			const startupID = await startupPost(startup);
			NotificationEmail({
				type: "post-startup",
				email: getdoc.data().email,
			}).then();
			PostNotification({
				type: "post-startup",
				imageURL: startup?.logoURL ? startup?.logoURL : "",
				message: `Great News! Your startup ${startup?.startupName} is now LIVE.`,
				startupId: startupID.id,
				name: startup?.startupName,
				uid: user.uid,
			}).then();
			await setProfile(getdoc.data());
			navigator("/m/startup/timeline");
		} catch (e) {
			debugger;
			console.log(e);
		}
	}

	return (
		<>
			<Container maxWidth={"xl"} sx={{ marginY: 5 }}>
				<Stack
					direction={"row"}
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					width={"100%"}>
					<IconButton
						onClick={() =>
							steps === 0
								? navigator(-1)
								: steps ===3 ? setSteps(steps - 2) : setSteps(steps - 1)
						}>
						<ArrowBackIcon sx={{ fontSize: 40 }} />
					</IconButton>
					<Typography
						variant={"h5"}
						sx={{
							textAlign: "left",
							lineHeight: 1,
							fontWeight: 600,
						}}>
						{(steps < 4 || steps === 6 || steps === 7) && 'Startups'}
						{(steps === 4 || steps === 5 || steps === 8 || steps === 9 || steps === 10 || steps === 11 ) && 'Founder'}
						{ steps===12 && 'Investment Portfolio'}


						<br />
						<small style={{ fontSize: 14, color: "#8F8F8F" }}>
							{
								steps < 2 && 'Startup profile'
							}
							{
								steps === 2 && 'Team'
							}
							{
								steps === 3 && 'Expectations'
							}
							{
								(steps === 4 ) && 'Founder profile'
							}
							{
								//(steps === 5 || steps === 6) && 'Team members'
							}
							{
								steps === 5 && 'Founder profile'
							}
							{
								(steps === 6 || steps === 7)&& 'Your details an Investor'
							}
							{
								steps === 8 && 'Investment portfolio'
							}
							{
								steps === 9 && 'Add portfolio'
							}
						</small>
					</Typography>
				</Stack>
			</Container>
			<Container maxWidth={"lg"} sx={{ mb: 5 }}>
				{steps === 0 && (
					<StartupProfileFirstForm
						data={formFistData}
						cbBack={() => navigator(-1)}
						cbNext={(data) => {
							debugger;
							setFormFistData({ ...data });
							setSteps(steps + 1);
						}}
					/>
				)}
				{steps === 1 && (
					<StartupProfileSecondForm
						data={formSecondData}
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data) => {
							setFormSecondData({ ...data });
							setSteps(steps + 2);
						}}
					/>
				)}

				{/* {steps === 2 && (
					<LookingForFundingSecondForm
						data={lookingForFundingSecondData}
						cbNext={(data) => {
							setLookingForFundingSecondData({ ...data });
							setSteps(steps + 1);
						}}
						cbBack={() => setSteps(steps - 1)}
					/>
				)} */}
				{steps === 3 && (
					<TopPriorities
						cbBack={() => setSteps(steps - 2)}
						cbNext={(data) => {
							setFormThirdData({ ...data });
							setSteps(
								steps + 1
							);
						}}
						data={formThirdData}

					/>
				)}

			{/*	{steps === 4 && (
					<LookingForFundingFirstForm
						data={lookingForFundingFirstData}
						cbNext={(data) => {
							setLookingForFundingFirstData({ ...data });
							setSteps(steps + 1);
						}}
						cbBack={() => setSteps(steps - 1)}
					/>
				)}*/}

				{steps === 4 && (
					<FounderProfileFirstForm
						data={founderProfileFirstData}
						startupName={formFistData?.startupName}
						cbNext={(data) => {
							setFounderProfileFirstData({ ...data });
							setSteps(steps + 1);
						}}
						cbBack={() => {
							setSteps(
								steps - 1
							);
						}}
					/>
				)}
				{/* {steps === 5 && (
					<FounderProfileSecondForm
						data={founderProfileSecondData}
						startupName={formFistData?.startupName}
						cbNext={(data) => {
							setFounderProfileSecondData({ ...data });
							setSteps(steps + 1);
						}}
						cbBack={() => {
							setSteps(steps - 1);
						}}
					/>
				)} */}
				{/* {steps === 5 && (
					<TeamListView
						cbBack={() => setSteps(steps - 1)}
						add={(data) => {
							setSteps(steps + 1)
						}}
						remove={(ind) => {
							const experiences_copy =   [...teamMembers]
							experiences_copy.splice(ind, 1)
							setTeamMembers(experiences_copy)
						}}
						members={teamMembers}
						submit={() => {
							setSteps(steps + 2)
							debugger
							// console.log('submit')
						}}
					/>
				)} */}
				{/* {
					steps === 6 && (
						<MemberForm
							cbBack={() => setSteps(steps - 1)}
							cbNext={(data, index) => {
								const members = [...teamMembers]
								if (index !== undefined && index > -1) {
									members.splice(index, 1, { ...data})
								} else {
									members.push({ ...data })
								}
								setTeamMembers([...members])
								setSteps(steps - 1);
							}}
							data={undefined}
						/>
					)
				} */}
				{
					steps === 5 && (
						<InvestorDetails
							data={formEightData}
							cbBack={() => setSteps(steps - 2)}
							cbNext={(data) => {
								setFormEightData({ ...data });
								if (data?.areYouAnAngelInvestor === true) {
									setSteps(steps + 1);
								} else {
									submitData({
										investorDetails: {
											...formEightData
										},
										founder:{
											...founderProfileFirstData,
											//...founderProfileSecondData,
										},
										...lookingForFundingSecondData,
										...formThirdData,
										...formFistData,
										...formSecondData,



									}).then()
								}

							}}
						/>)
				}
				{steps === 6 && (

					<InvestorThirdForm
						data={formNineData}
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data) => {
							setFormNineData({ ...data });
							setSteps(steps + 1);
						}}
					/>
				)}
				{steps === 7 && (

					<InvestorFourForm
						data={formTenData}
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data) => {
							debugger
							setFormTenData({ ...data });
							setSteps(steps + 1);
						}}
					/>
				)}
				{
					steps === 8 && (<PortfoliosListView
							cbBack={() => setSteps(steps - 1)}
							add={(data) => {
								setPortfolioIndex();
								setSteps(steps + 1);
							}}
							edit={(ind) => {
								setPortfolioIndex(ind);
								setSteps(steps + 1);
							}}
							remove={(ind) => {
								debugger
								const port = [...portfolios]
								port.splice(ind, 1)
								setPortfolios([...port])
							}}
							portfolios={portfolios}
							submit={() => {
								submitData({
									investorDetails: {
										...formEightData,
										...formNineData,
										...formTenData,
									},
									founder:{
										...founderProfileFirstData,
										//...founderProfileSecondData,
									},
									...lookingForFundingSecondData,
									...formThirdData,
									...formFistData,
									...formSecondData,

								}).then()
								// console.log('submit')
							}}
						/>
					)
				}

				{steps === 9 && (
					<InvestorPortfolioForm
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data, index) => {
							const port = [...portfolios]
							if (index !== undefined && index > -1) {
								port.splice(index, 1, { ...data})
							} else {
								port.push({ ...data})
							}
							setPortfolios([...port])
							setSteps(steps - 1);
							setPortfolioIndex(undefined);

						}}
						data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}

						index={portfolioIndex}
					/>)


				}
			</Container>
		</>
	);
}
