import {Box, Button, IconButton,Stack, Typography} from "@mui/material";
import React from "react";
import {EditSharp} from "@mui/icons-material";
import AboutBusinessDeveloperComponent from "./edit/business-developer/about-business-developer-component";
import { BUSINESS_DEVELOPER } from "../../../utils/enums/labels.enum";
const styleFont = {
  marginRight: "5px",
  backgroundColor: '#F6F8FA', 
  padding: '8px 15px',
  borderRadius: '5px', 
  fontSize: 14}

export default function BusinessDeveloperAbout({profile, cb}) {
  const [open,setOpen] = React.useState(false)
  const [seeMore, setSeeMore] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const profileRegex = /profile|admin\/user-details/

  return (

    <>


      {profile.userType  === 'business-developer' &&
        <>
        <Stack
                direction={"column"}
                spacing={3}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}>
            <Stack direction={"row"} spacing={2} >
              <Typography fontSize={20} fontWeight={600} style={{flex: 1}}>About {BUSINESS_DEVELOPER.SINGULAR_LABEL}</Typography>
              {profileRegex.test(window.location.pathname) &&  <IconButton onClick={() => setOpen(true)}>
                <EditSharp />
              </IconButton>}

            </Stack>
            {/* <Grid container  gap={12}> */}
            <Stack direction={"column"} spacing={4}>
              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Expertise<br/>{profile?.businessDeveloper?.expertise && profile?.businessDeveloper?.expertise.length > 0 &&
                    profile?.businessDeveloper?.expertise.map((ex, i) =>
                      <font style={styleFont}>{ex}</font>

                    )}

                  </Typography>
                  <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
              
              {console.log(profile)}
              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Sector<br/>
                    {profile?.businessDeveloper?.sector &&
                      profile?.businessDeveloper?.sector.length > 0 &&
                      profile?.businessDeveloper?.sector.map((element,index)=>(
                          <font style={styleFont}>{element} </font>
                        )

                      )}
                    {/* <font style={styleFont}>{profile?.businessDeveloper?.sector} </font> */}
                  </Typography>
                  <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
              
              
                {profile?.businessDeveloper?.additionalDetails &&
                      profile?.businessDeveloper?.additionalDetails.length > 0 &&
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Additional Details<br/>
                    {profile?.businessDeveloper?.additionalDetails &&
                      profile?.businessDeveloper?.additionalDetails.length > 0 &&
                      profile?.businessDeveloper?.additionalDetails.map(
                        (ex, i) => (
                          <font style={styleFont}>
                            {ex}


                          </font>
                        )
                      )}

                    {/* <font style={styleFont}>{profile?.businessDeveloper?.additionalDetails} </font> */}
                  </Typography>
                </Stack>}
              
              
                {profile?.businessDeveloper?.geographies &&
                  <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Geographies<br/>
                    {profile?.businessDeveloper?.geographies.map((element,index)=>(
                        <font style={styleFont}>{element} </font>
                      )

                    ) }
                  </Typography>
                  <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>}

                {profile?.businessDeveloper?.languages &&
                  <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Languages<br/>
                    {profile?.businessDeveloper?.languages?.map((element,index)=>(
                        <font style={styleFont}>{element} </font>
                      )

                    ) }
                  </Typography>
                  <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>}
              
            </Stack>
            {/* <Grid container  gap={12} sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}> */}
            <Stack direction={"column"} spacing={4}>

              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Relevant years of experience<br/> <font style={styleFont}>{profile?.businessDeveloper?.relevantYearOfExperience} </font>
                  </Typography>
                  <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
              
              
                {seeMore ?
                <>
                  <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Support startup at stage<br/>
                    {profile?.businessDeveloper?.supportStage  && Array.isArray(profile?.businessDeveloper?.supportStage) &&
                      profile?.businessDeveloper?.supportStage.length > 0 &&
                      profile?.businessDeveloper?.supportStage.map((element,index)=>(
                        <font style={styleFont}>{element}</font>
                        )
                        
                        )}
                  </Typography>
                  <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
                </Stack>
              
              
                <Stack direction={'column'} spacing={2}>
                  <Typography variant={'h6'} sx={{lineHeight:2}}>
                    Happy to provide service for some share of the equity?<br/> <font style={styleFont}>{
                      profile?.businessDeveloper?.happyToProvideServiceForSomeShareOfTheEquity ? 'Yes': 'No' }
                  </font>
                  </Typography>
                </Stack>
                <Stack alignItems={'center'}>
                          <Box>
                          <Button size={"small"} variant='outlined' color="secondary"   sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See Less</Button>
                          </Box>
			                   </Stack> 
                        </> 
                        :
                        <Stack alignItems={'center'}>
                          <Box>
                          <Button size={"small"} variant='outlined' color="secondary"  sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See More</Button>
                          </Box>
			                   </Stack> 
                }
             </Stack> 
          </Stack>
            
          <AboutBusinessDeveloperComponent profile={profile?.businessDeveloper} businessDeveloperProfile={profile} callback={(data) => {
            cb(data);
            handleClose()
          }}  open={open} handleClose={handleClose} />

        </>
      }


    </>
  )
}
