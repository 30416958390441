import { ArrowCircleUp, KeyboardArrowUp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';


const ScrollToTopButton = ({web}) => {
  const [isVisible, setIsVisible] = useState(false);

  // Show or hide the button based on the scroll position
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTop > 900);
  };

  // Scroll to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    
        <IconButton sx={{ 
            backgroundColor:'#2841fc !important',
            opacity:isVisible ? 1 : 0,
            visibility:isVisible ? 'visible' :'hidden',
            width: isVisible ? '40px' : '20px', height:isVisible ? '40px' : '20px',
            transition:"opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out, bottom 0.1s, right 0.1s",
            position: "fixed", bottom: web ? "12%" :"5%", right:web? "12%" : "8%", 
            '&hover':{
                backgroundColor:'#2841fc'
            }
            }} onClick={scrollToTop}>
        <KeyboardArrowUp sx={{width:"32px", color:"white"}} fontSize='large'/>
        </IconButton>
    
  );
};

export default ScrollToTopButton;