import React from "react";
import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
} from "firebase/storage";
import {
	Button,
	Container,
	Stack,
	Typography,
	IconButton,
	TextField,
	Slider,
	FormGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	Switch,
	Avatar,
	styled,
	CircularProgress,
	Link,
	Badge,
	Autocomplete,
	Chip,
	MenuItem,
	Box,
	Grid,
	useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import { useFormik, FieldArray, getIn, FormikProvider } from "formik";
import InvestorProfileValidation from "../../validations/investor/investor-profile";
import {doc, getDoc, serverTimestamp, setDoc, addDoc,
	collection} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFile from "../../utils/upload-file";
import { async } from "@firebase/util";
import GetCitiesData from "../../api/get-cities-data";
import InvestorFirstForm from "./onboarding-forms/investor-first-form";
import InvestorSecondForm from "./onboarding-forms/investor-second-form";
import InvestorThirdForm from "./onboarding-forms/investor-third-form";
import InvestorFourForm from "./onboarding-forms/investor-four-form";
import PortfoliosListView from "./onboarding-forms/portfolios-list-view";
import InvestorPortfolioForm from "./onboarding-forms/portfolio-form";
import InvestorOrganizationForm from "./onboarding-forms/investor-organization-form";
function valuetext(value) {
	return `$${value}`;
	
}
export default function InvestorProfile() {
	const Input = styled("input")({
		display: "none",
	});
	const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
	const { user, setProfile } = React.useContext(AuthUserContext);
	const [clicked, setClicked] = React.useState(false);
	const [formFirstData, setFormFirstData] = React.useState();
	const [formSecondData, setFormSecondData] = React.useState();
	const [formThirdData, setFormThirdData] = React.useState();
	const [formFourData, setFormFourData] = React.useState();
	const [formFiveData, setFormFiveData] = React.useState();

	const [loading, setLoading] = React.useState(false);
	const [isPortfolio, setPortfolio] = React.useState(false);
	const [steps, setSteps] = React.useState(0);
	const [portfolioIndex, setPortfolioIndex] = React.useState();
	const [markets, setMarkets] = React.useState([]);
	const [focusVertical, setFocusVertical] = React.useState([]);
	const [portfolios, setPortfolios] = React.useState([]);

	const [profileImage, setProfileImage] = React.useState({
		src: user.photoURL ? user.photoURL : null,
		photoURL: user.photoURL,
	});

	function intToString(num) {
		num = num.toString().replace(/[^0-9.]/g, "");
		if (num < 1000) {
			return num;
		}
		let si = [
			{ v: 1e3, s: "K" },
			{ v: 1e6, s: "M" },
			{ v: 1e9, s: "B" },
			{ v: 1e12, s: "T" },
			{ v: 1e15, s: "P" },
			{ v: 1e18, s: "E" },
		];
		let index;
		for (index = si.length - 1; index > 0; index--) {
			if (num >= si[index].v) {
				break;
			}
		}
		return (
			(num / si[index].v)
				.toFixed(2)
				.replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s
		);
	}

	const navigator = useNavigate();

	const submitData = async (values) => {
		try {
			debugger
			const userRef = doc(DB, "users", user.uid);

			const copyPortfolio = []
			if (portfolios.length > 0) {
				for (let portfolio of portfolios) {
					const attachments =[]
					if (portfolio.attachments.length > 0) {
						for (let file of portfolio.attachments) {
							const path = `investors/${user.uid}/attachments/${file.file.name}`
							attachments.push(path)
						}
					}
					copyPortfolio.push({...portfolio, attachments})
				}
			}
			const investor = {
				...values,
				portfolio: copyPortfolio,
				photoURL: values?.photoURL
					? values.photoURL
					: "",
			};
			await setDoc(
				userRef,
				{
					photoURL: values?.photoURL
						? values.photoURL
						: "",
					investor,
					onboard: true,
					
					profileStatus: 'complete',
					remainderStatus:''
				},
				{ merge: true }
			);
			if (formFirstData?.investorType !== 'Angel Investor') {
				const add = await addDoc(collection(DB, "organisations"),
					{
						...formFirstData,
						uid: user?.uid,
						createdAt: serverTimestamp(),
						updatedAt:serverTimestamp()
					});
				await addDoc(collection(DB, "organisationUsers"),
					{
						organisationId: add.id,
						organisation: {...formFirstData},
						uid: user?.uid,
						createdAt: serverTimestamp(),
						updatedAt:serverTimestamp(),
						role: formSecondData?.role
					});
			}
			const getdoc = await getDoc(userRef);
			await setProfile(getdoc.data());
			navigator("/investor/timeline");
		} catch (e) {
			debugger;
			console.log(e);
		}
	}

	const cities = ['London, United Kingdom']
	const [filter, setFilter] = React.useState([...cities]);
	const [searchLoaction, setSearchLoaction] = React.useState('');
	React.useEffect(() => {
		if (searchLoaction !== '') {

			GetCitiesData().then((res) => {
				const te = [...new Set(res?.data?.cities)]
				setFilter([
					...te.filter((post) => {
						if (searchLoaction !== '') {
							const reg = new RegExp(searchLoaction);
							return reg.test(post) &&  post
						}
					})
				])
				/*debugger
				setcities(te)*/
			})
		} else {
			setFilter([...cities])
		}
	}, [searchLoaction])

	return (
		<>
			<Container maxWidth={"xl"} sx={{ marginY: 5 }}>
				<Stack
					direction={"row"}
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					width={"100%"}>
					<IconButton
						onClick={() =>
							isPortfolio
								? setPortfolio(false)
								: steps === 0
								? navigator(-1) : steps === .5 ? setSteps(steps - .5)
										: steps === 1 && formFirstData && formFirstData?.investorType !== 'Angel Investor' ? setSteps(steps - .5)
								: setSteps(steps - 1)
						}>
						<ArrowBackIcon sx={{ fontSize: 40 }} />
					</IconButton>
					<Typography
						variant={"h5"}
						sx={{ textAlign: "left", lineHeight: 1, fontWeight:"600"  }}>
						
						{
							steps === 4 ? 'Investor':
							steps === 5 ? 'Investment Portfolio':
							'Investor'
						// isPortfolio ? (
						// 	<>Investment Portfolio</>
						// ) : (
						// 	<>Investor </>
						// )
						}
						<br />{" "}
						<small style={{ fontSize: 14, color: "#8F8F8F" }}>
							{ steps === 2 || steps === 3  ? 'Investment Thesis' : 
							steps === 4 ? 'Investment Portfolio':
								steps === 5 ? 'Add portfolio':
									'Investor Profile'}
						</small>
					</Typography>
				</Stack>
			</Container>
			<Container maxWidth={"lg"} sx={{ mb: 5 }}>
				{steps === 0 && (
					<InvestorFirstForm
						data={formFirstData}
						cbBack={() => navigator(-1)}
						cbNext={(data) => {
							setFormFirstData({ ...data });
							setSteps(data?.investorType === 'Angel Investor' ? steps + 1 : steps + .5);

						}}
					/>)

				}
				{steps === .5 && (
					<InvestorOrganizationForm
						data={formFirstData}
						cbBack={() => setSteps(steps - .5)}
						cbNext={(data) => {
							setFormFirstData({ ...formFirstData, ...data });
							setSteps( steps + .5);

						}}
					/>) }
				{steps === 1 && (
					<InvestorSecondForm
						isVC={formFirstData && formFirstData?.investorType !== 'Angel Investor'}
						data={formSecondData}
						cbBack={() => setSteps(formFirstData?.investorType !== 'Angel Investor' ? steps - .5 : steps -1)}
						cbNext={(data) => {
							debugger
							setFormSecondData({ ...data });
							setSteps(steps + 1);
						}}
					/>)


				}
				{steps === 2 && (

					<InvestorThirdForm
						data={formThirdData}
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data) => {
							setFormThirdData({ ...data });
							setSteps(steps + 1);
						}}
					/>
				)}
				{steps === 3 && (

					<InvestorFourForm
						data={formFourData}
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data) => {
							debugger
							setFormFourData({ ...data });
							setSteps(steps + 1);
						}}
					/>
				)}
				{steps === 4 && (

					<PortfoliosListView
					
						cbBack={() => setSteps(steps - 1)} 
						
						add={(data) => {
							setPortfolioIndex();
							setSteps(steps + 1);
						}}
						edit={(ind) => {
							setPortfolioIndex(ind);
							setSteps(steps + 1);
						}}
						remove={(ind) => {
							debugger
							const port = [...portfolios]
							port.splice(ind, 1)
							setPortfolios([...port])
						}}
						portfolios={portfolios}
						submit={() => {
							debugger
							submitData({
								...formFirstData,
								...formSecondData,
								...formThirdData,
								...formFourData
							}).then()
							console.log('submit')
						}}
					/>
				)}
				{steps === 5 && (
					<InvestorPortfolioForm
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data, index) => {
							const port = [...portfolios]
							if (index !== undefined && index > -1) {
								port.splice(index, 1, { ...data})
							} else {
								port.push({ ...data })
							}
							setPortfolios([...port])
							setSteps(steps - 1);
							setPortfolioIndex(undefined);

						}}
						data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}

						index={portfolioIndex}
					/>)


				}

			</Container>
		</>
	);
}
