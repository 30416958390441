import * as React from "react";
import {
  Stack, Badge, styled,
  Avatar, Typography
} from "@mui/material";
import {DB} from "../../../../../config/firebase/firebase";
import {collection, setDoc, doc, getDoc, deleteDoc} from "firebase/firestore";
import { USER_TYPES_TO_NAME } from "../../../../../utils/enums/labels.enum";

export default function LikeListItem({like}) {
  const [user, setUser] = React.useState()
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: '#fff'
  }));
  React.useEffect(() => {
    const userRef = doc(DB, "users", like.id);
    getDoc(userRef).then((doc) => setUser(doc?.data()));
  }, [like])
  
  return (<Stack direction={"row"} spacing={2} alignItems={"center"}>
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <SmallAvatar alt={like?.impression} src={`/assets/likes-icons-set/${like?.impression !== 'Yeah!' ? like?.impression : 'Punch'}.png`} />
      }
    >
      <Avatar alt={user && user?.displayName} src={user && user?.photoURL} sx={{height:60, width:60}} />
    </Badge>
    <Stack direction={"column"} spacing={0}>
      <Typography variant={"body1"} color={'#051E40'} textTransform={"capitalize"}>{user?.displayName}</Typography>
      <Typography variant={"caption"} textTransform={'uppercase'} color={'#000'}>{USER_TYPES_TO_NAME[user?.userType]}</Typography>
    </Stack>
  
  </Stack>)
}