import * as React from "react";

import {
  Box,
  Stack,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider,
  Button,
  Portal,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AuthUserContext } from "../../../../../providers/auth-provider";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { DB } from "../../../../../config/firebase/firebase";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { Support } from "@mui/icons-material";
import { USER_TYPES } from "../../../../../utils/enums/labels.enum";
import {
  BUSINESS_DEVELOPER,
  USER_TYPES_TO_NAME,
} from "../../../../../utils/enums/labels.enum";
import GetOffers from "../../../../../api/get-filter-offers";

const styles = {
    container: {
      maxWidth: '200px', // Adjust the width as needed
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };

export default function OffersRightPanel(screen, widthSize = false) {
  const { user, profile } = React.useContext(AuthUserContext);
    console.log('panel received', screen)
  const [offers, setOffers] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    const fetchOffersData = async () => {
      const today = new Date();
      const todayString = today.toISOString().split("T")[0];
      const filters = { key: "isActive", operand: "==", value: true };
      const userType = profile?.userType
      const data = await GetOffers(filters, userType, screen);
      console.log('offerData', data)
      setOffers(data);
    };

    fetchOffersData().catch(console.error);
  }, [screen]);
  const navigator = useNavigate();
  return (
    <>
     <Stack
        position={"static"}
        sx={{
          boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
          background: "#fff",
          borderRadius: "15px",
          height: "fit-content",
        }}
        direction={"column"}
        justifyContent={"flex-start"}
        spacing={1}
      >
        {/* <Typography variant={"h6"} sx={{ px: 2, pt: 1 }}>
          {screen.screen === 'offer-details'? 'Other Available Offers' : 'Offers'}
        </Typography>

        <List sx={{ width: "100%", py: 0, my: 0 }}>
          {offers.length > 0 &&
            offers.map((offer, i) => (
              offer.isActive &&
              <>
                <ListItem
                  alignItems="flex-start"
                  sx={{ px: 3 }}
                  onClick={() => {
                    navigator(
                      `/m/${profile?.userType}/offers/details/${offer?.id}`
                    );
                  }}
                >
                  <ListItemAvatar sx={{ height: 50, width: 50 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        offer?.offerImage
                          ? offer?.offerImage
                          : "/assets/offer-default-image.png"
                      }
                      sx={{ height: 45, width: 45 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={{ maxWidth: 110, fontSize: 16 }}
                          noWrap={true}
                        >
                          {offer?.companyName}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography variant="body1" component="div" style={styles.container}>
                          {offer?.name}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem> */}
                {/* {<Divider variant="fullWidth" component="li" />} */}
              {/* </>
            ))}
        </List> */}
{/* 
        <Button
          color={"secondary"}
          size={"small"}
          fullWidth
          sx={{ justifyContent: "left", px: 1.8, fontSize: 12 }}
          onClick={() => navigator(`/m/${profile?.userType}/offers/list`)}
        >
          View all our new Offers{" "}
          <ArrowForwardIcon sx={{ fontSize: 16, ml: 1 }} />
        </Button> */}
      </Stack>
    </>
  );
}
