import React from "react";
import {
	Avatar,
	Grid,
	Paper,
	Stack,
	styled,
	Typography,
	Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import { AuthUserContext } from "../../providers/auth-provider";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ListItemStartConversation from "./list-item-start-conversation";
import MemberFavourite from '../../screens/common/MemberFav'

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "center",
	backgroundColor: "#fff",
	color: theme.palette.text.secondary,
	boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
	borderRadius: "15px",
	minHeight: 348,
}));
export default function OrganisationInvestorListItem({ data, href }) {
	const navigator = useNavigate();
	const { profile } = React.useContext(AuthUserContext);
	

	return (
		<Grid
			item
			lg={4}
			md={4}
			sm={6}
			xs={12}

			wrap={"nowrap"}>
			<Item>
				<Stack direction={"column"} spacing={2} sx={{ width: "100%" }}
					   justifyContent="center"
					   alignItems="center"
				><Stack sx={{ ml: "90%", mb:"-15%", mt:"-2.5%" }}>
					<MemberFavourite data={{ ...data,card: "investor-organization" }} />
				</Stack>
					<Box
					sx={{
							position: "relative",
							top:"40px",
							bottom : "20px",
							width: "280px",
							height: "200px",
							background: "#F6F8FA",
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px"
						}}
					>
					<Stack
						direction={"column"}
						spacing={2}
						justifyContent="center"
						alignItems="center"  style={{cursor:"pointer"}}	onClick={() => navigator(href,  {
						state:
							{
								profile:data,
								organisation: {
									organizationDescription: data?.investor?.organizationDescription,
									investorType: data?.investor?.investorType,
									website: data?.investor?.website,
									nameOfOrganization:  data?.investor?.nameOfOrganization,
									logoURL:
										data?.investor?.logoURL,
								},
							},
					})}>
						<Box
							sx={{
								border: "1px solid #204488",
								p: 0.75,
								borderRadius: "50%",
								marginTop : "-40px"
							}}>
							<Avatar
								alt={data?.investor?.nameOfOrganization}
								src={data?.investor?.logoURL}
								sx={{ width: 92, height: 92 }} imgProps={{style: {objectFit: 'contain', backgroundColor: '#fff'}}}>
								<Typography variant={"h5"}>
									{data?.investor?.nameOfOrganization &&
										data?.investor?.nameOfOrganization.charAt(0)}
								</Typography>
							</Avatar>
						</Box>
						<Typography
							variant={"h6"}
							sx={{ fontWeight: 600, mb: 0, lineHeight: 1.2 }}>
							{data?.investor?.nameOfOrganization} <br />
							<font size={3} style={{ fontWeight: 300 }}>
								Investor - {'Organisation'}
							</font>
						</Typography>
						<Box />
					</Stack>
					{
						(data.uid !== profile?.uid) ?  (
							<ListItemStartConversation data={{...data, creatorData: data}}/>

						):<Button size={'small'} variant={"outlined"} color={'secondary'} sx={{px: 2, width:120}} 
						onClick={() => navigator(href,   {
							state:
								{
									profile:data,
									organisation: {
										organizationDescription: data?.investor?.organizationDescription,
										investorType: data?.investor?.investorType,
										website: data?.investor?.website,
										nameOfOrganization:  data?.investor?.nameOfOrganization,
										logoURL:
										data?.investor?.logoURL,
									},
								},
						})}
								>My profile</Button>
					}
					</Box>
					
					<Box />
					<Box />
					<Box />
					<Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
						{data?.investor && data?.investor?.investorType && (
							<Stack  direction={"row"} spacing={2} sx={{width: '100%'}}>
								<Typography
									variant={"body2"}
									sx={{
										fontWeight: "bold",
										flex: 1,
										textAlign: "left",
										width:"30%"
									}}>
									Investor Type
								</Typography>
								<Typography variant={"body2"} >
									{data?.investor?.investorType}
								</Typography>
							</Stack>
						)}
					</Stack>
						{
						data?.investor?.website &&
						<>
					<Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
						<Typography
						variant={"body2"}
						sx={{
							fontWeight: "bold",
							flex: 1,
							textAlign: "left",
						}}>
							Website
						</Typography>
						<Typography
						variant={"body2"}
						sx={{ width: 'fit-content', textAlign: "right" }}
						noWrap={true}>
							<a href={data?.investor?.website} style={{color: 'rgba(0, 0, 0, 0.6)'}} target={"_blank"} rel="noreferrer">{data?.investor?.website}</a>
						</Typography>
					</Stack>
							</>
							}
							
				</Stack>
			</Item>
		</Grid>
	);
}
