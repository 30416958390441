import * as React from 'react';
import {Box, List, Skeleton, Stack} from "@mui/material";
import {collection, onSnapshot, query, where, orderBy, doc, getDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
import {AuthUserContext} from "../../../providers/auth-provider";
import RoomListItem from "./room-list-item";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
// DoneAllOutlinedIcon


export default function RoomsList({callbackRightPanel}) {
  const {user, roomData, setRoomData, roomId, setRoomId, profile} = React.useContext(AuthUserContext);
  const [rooms, setRooms] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const[inputbtn,setinputbtn]=React.useState();
  const [search, setSearch] = React.useState("");
  const set_title = async (rooms) => {
    const list = []
    setLoading(true)
    for(let room of rooms) {
      const snapshot = await getDoc(doc(DB, "users", room?.participates.find((f) => f !== profile.uid)));
      if (snapshot?.exists()) {
        if (room.title !== 'Support' && room.type !== 'support') {
          room.title = snapshot?.data().displayName || ''
        } else {
          console.log(room.title )
        }
       
  
        list.push(room);
      }
      
    }
    setLoading(false)
  
    setRooms(list)
  }
  React.useEffect(() => {
    const q = query(
      collection(DB, "rooms"),
      orderBy("updatedAt", "desc"),
      where("participates", "array-contains", user?.uid));
    
    const unSubs = onSnapshot(q, (querySnapshot) => {
      // debugger
      if (querySnapshot.size > 0) {
        const list = []
        querySnapshot.forEach((doc) => {
          if (roomId === doc.id) {
            setRoomId(doc.id)
            setRoomData({id: doc.id, ...doc.data()})
            
          }
          list.push({id: doc.id, ...doc.data()})
        });
        setRooms(list)
  
        list?.length > 0 && set_title(list).then()
        /*   querySnapshot.docChanges().forEach((change) => {
             debugger
             if (!change.doc.metadata.hasPendingWrites && change.type === "added") {
               console.log(`RoomID Add ${roomId}`, change.doc.data());
             }
             if (!change.doc.metadata.hasPendingWrites && change.type === "modified") {
               console.log(`RoomID update  ${roomId}`, change.doc.data());
             }
           });*/
      }
    });
    return () => unSubs;
  }, [])
  return(
    
    <Stack direction={'column'} alignItems={'center'} sx={{flex:.25, height:'calc(100vh - 150px)', overflowY: 'scroll' , backgroundColor: '#fff', borderRadius: '15px 15px 0px 0px',
      boxShadow:'0px 0px 13px -2px rgba(0, 0, 0, 0.15)', scrollbarColor: "#cdcdcd #FFFFFF",
      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: "#FFFFFF",
      },}} spacing={2}>
      <Box  onSubmit={(event) => event.preventDefault()}
            component="form"
            sx={{
              p: "5px 10px",
              display: "flex",
              alignItems: "center",
              width: '95%',
              borderRadius: "12px",
              backgroundColor: "#E4EAF5",
              mt: 2
            }}>
        <IconButton
          type="button"
          aria-label="search"
          onClick={(e) => setSearch(inputbtn.replaceAll('\\', ''))}
        >
          <SearchIcon sx={{ color: "#051E40BF" }} />
        </IconButton>
        <InputBase
          sx={{  flex: 1, color: "#051E40BF" }}
          placeholder="Search"
          inputProps={{ "aria-label": "search post" }}
          onChange={(e) => setinputbtn(e.target.value)}
          value={inputbtn}
          onKeyDown={(e)=>{
            if(e.code==="Enter"){
              setSearch(inputbtn.replaceAll('\\', ''))
            }
          }}
        />
      </Box>
      <List sx={{ width: '100%', px: 1}}>
        
        
        {
          rooms.length > 0
          &&
          
          rooms
            .filter((rom) => {
              if (search !== "") {
                debugger
                const reg = new RegExp(search?.toString()?.toLowerCase());
                if (reg.test(rom.title?.toString()?.toLowerCase())){
                  return rom;
                };
              } else {
                return rom;
                
              }
            })
            .map((room, i) =>{
              
              // debugger
              return  ( <>
                  <RoomListItem  data={room} key={room.id} callbackRightPanel={() => callbackRightPanel(false)}
                  />
                </>
              )
              
            })
        }
      </List>
    
    </Stack>
  
  )
}
