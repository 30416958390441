import React from "react";
import {Avatar, Grid, Paper, Stack, styled, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import ListItemStartConversationC from "./list-item-start-conversation copy";
import MemberFavourite from "../../common/MemberFav";
import ListItemStartConversation from "./list-item-start-conversation";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: '#fff',
  color: theme.palette.text.secondary,
  boxShadow: '0px 0px 13px -2px rgba(0, 0, 0, 0.15)',
  borderRadius: "10px",
	height: 250,
}));
export default function TalentListItem({data, href,itemWidth}){
  const navigator = useNavigate();

  return(

    <Grid item  lg={4} md={4} sm={6} xs={6}  wrap={'nowrap'}>
      <Item style={{ width: itemWidth }} >
        <Stack direction={"column"} spacing={2} sx={{width: '100%'}}
               justifyContent="center"
               alignItems="center">
                 <Stack sx={{ ml: "90%", mb:"-15%", mt:"-2.5%" }}>
            <MemberFavourite data={{ ...data,card: "talent" }} />
          </Stack>
          <Box
					sx={{
							position: "relative",
							top:"40px",
							bottom : "20px",
							width: "280px",
							height: "200px",
							background: "#F6F8FA",
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px"
						}}
					>
          <Stack direction={"column"} spacing={1}
                 justifyContent="center"
						style={{cursor:"pointer"}}
                 alignItems="center"   onClick={() => navigator(href)} >
            <Box sx={{border: '1px solid #204488', p:.75, borderRadius:'50%' , marginTop : "-40px"}}>
              <Avatar
                alt="Remy Sharp"
                src={data?.photoURL}
                sx={{ width: 40, height: 40 }}
              >
                <Typography  style={{fontSize:"12px",overflow:"hidden"}}>{data?.displayName && data?.displayName.charAt(0)}</Typography>
              </Avatar>
            </Box>
            <Typography style={{fontSize:"12px",overflow:"hidden"}}
							sx={{ fontWeight: 650, mb: 0, lineHeight: 1.2,color:"black" }}>
              {data?.displayName} <br/>
              <font size={3} style={{fontSize:"10px",overflow:"hidden",fontWeight:"300"}}>
                Talent
              </font>
            </Typography>

            <Box/>
          </Stack>
          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}
            >
          <ListItemStartConversation data={{...data, creatorData: data}} />
          </Box>
          </Box>
          <Box/>
          <Box/>
          <Box/>
          {data?.talent && data?.talent?.skills && data?.talent?.skills.length > 0 &&
            <Stack direction={"row"} spacing={0} sx={{width: '100%'}}  style={{marginBottom:"-10px",marginTop:"-1px"}}>

              <Typography style={{fontSize:"10px"}}
								sx={{
                  fontWeight: "600",
									textAlign: "left",
									width:"60%"
								}}>Skills</Typography>
              <Typography style={{fontSize:"8px",overflow:"hidden"}} noWrap={true}>{data?.talent?.skills.map((d, i) => d !== data?.talent?.skills[data?.talent?.skills.length -1] ? `${d}, ` : d ) }</Typography>
            </Stack>
          }


          {data?.talent && data?.talent?.supportStage && data?.talent?.supportStage.length > 0 &&
            <Stack direction={"row"} spacing={2}  sx={{width: '100%'}}>
              <Typography variant={'body2'} sx={{fontWeight: 'bold', flex: 1, textAlign:'left'}}>Support Startup at Stage</Typography>
              <Typography variant={'body2'} sx={{width: 130, textAlign:'right'}} noWrap={true}>{data?.talent?.supportStage.map((d, i) => d !== data?.talent?.supportStage[data?.talent?.supportStage.length -1] ? `${d}, ` : d ) }</Typography>

            </Stack>
          }
        </Stack>
      </Item>
    </Grid>

  )
}