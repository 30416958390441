import * as React from 'react';
import {IconButton, Typography, Toolbar, Stack, CircularProgress} from "@mui/material";
import {Close, InsertDriveFile} from "@mui/icons-material";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import {AuthUserContext} from "../../../../../providers/auth-provider";
import UploadFile from "../../../../../utils/upload-file";

import {DB} from "../../../../../config/firebase/firebase";
import {addDoc, collection, doc, serverTimestamp, setDoc} from "firebase/firestore";
export default function FilesPreviews({files, callback}) {
  console.log(files)
  const {roomData, user} = React.useContext(AuthUserContext);
  const [selected, setSelected] = React.useState(0)
  const [mediaPreview, setMediaPreview] = React.useState()
  const [loading, setLoading] = React.useState(false)

  const imagePattern = /.png|.jpg|.jpeg|.gif/
  const moviePattern = /.mov|.mp4/
  const postFile = (file) => new Promise((resolve, reject) => {
    const path = `chat/${roomData?.title}/${roomData?.id}/${file.name}`
    UploadFile(file, path)
      .then(() => {
        debugger
        resolve({
          path,
          fileName: file.name,
          ext: file
            .name
            .split('.')[file
            .name
            .split('.')
            .length - 1
            ]
        })
      })
      .catch((err) =>  reject(err));
  });
  const postMessage = (path, fileName, ext) => new Promise(async (resolve, reject) =>{
    try {

      let message = await addDoc(collection(DB, "messages"),
        {
          type: imagePattern.test(files[selected].name) || moviePattern.test(files[selected].name) ? 'media' : 'document',
          ext,
          fileName,
          path,
          text: '',
          sender: user?.uid,
          receiver: roomData.participates.find((d) => d !== user?.uid),
          createdAt:serverTimestamp(),
          roomId: roomData?.id,
          read:false,
          status: 'send'
        });
      const room = doc(DB, 'rooms', roomData?.id)
      await setDoc(room, { lastMsg: {
          text: '',
          type: 'document',
          ext,
          fileName,
          status: 'send'
        }, updatedAt: serverTimestamp()}, {merge: true}
      )
      resolve(true)
    } catch (e) {
      reject(e)
    }
  })

  React.useEffect(()=>{
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[selected]);
    fileReader.addEventListener("load", async function () {
      debugger
      await setMediaPreview(
       this.result
        );
    })
  },[selected])
  return(
    <Stack direction={'column'} spacing={1} sx={{flex:1, backgroundColor:'#e5e5e5'}}>

      <Toolbar sx={{mt: 1, }}
               children={
                 <Stack direction={"row"} spacing={2} justifyContent="center"
                        alignItems="center">
                   <IconButton onClick={() => callback([])}>
                     <Close/>
                   </IconButton>
                   <Typography>
                     {files[selected].name}
                   </Typography>
                 </Stack>
               }>



      </Toolbar>
      <Stack direction={'column'} justifyContent="center"
             alignItems="center" spacing={2} sx={{flex:1, width:1}}>
        {imagePattern.test(files[selected].name) ? <img src = {mediaPreview} style={{objectFit: 'contain' , height: '50vh'}}/> :
          moviePattern.test(files[selected].name) ? <video src = {mediaPreview} style={{objectFit: 'contain' , height: '50vh'}} controls={true}/> :
            <InsertDriveFile color={'primary'} sx={{fontSize: 124, opacity: .5}}></InsertDriveFile> }


        <Typography variant={"body2"} color={'secondary'}>
          {files[selected].name}
        </Typography>
        <Typography variant={"body2"} color={'secondary'}>
          {(
            files[selected].size /
            1024 /
            1024
          ).toFixed(0) === '0'
            ? (
            files[selected].size / 1024
          ).toFixed(0) + " KB"
            : (
            files[selected].size /
            1024 /
            1024
          ).toFixed(0) + " MB"}
        </Typography>


      </Stack>

      <Stack direction={"row"} spacing={2} justifyContent="flex-end"
             alignItems="center">


        <IconButton >
          <AddIcon sx={{fontSize: 30}}/>
        </IconButton>
        <Stack sx={{flex: 1, overflowX: 'scroll'}} direction={"row"} spacing={2} justifyContent="center"
               alignItems="center">

          {files.length > 0 && files.map((file, i) => (
            <Stack direction={'column'} key={i} justifyContent="center"
                   alignItems="center" spacing={1} sx={{p: 1 , borderRadius: '5px', backgroundColor: selected === i ? '#cccccc90':'transparant'}} onClick={() => setSelected(i)}>
              <InsertDriveFile color={'primary'} sx={{fontSize: 36, opacity: .5}}></InsertDriveFile>

              <Typography variant={"caption"} color={'secondary'} noWrap={true} paragraph={true} sx={{maxWidth: 75}}>
                {file.name}
              </Typography>
            </Stack>
          ))}



        </Stack>
        {loading ? <CircularProgress/> : <IconButton color={'primary'} onClick={ async () => {
          try{
            setLoading(true)
            for(let file of files) {
              const pf = await postFile(file);
              const pm = await postMessage(pf.path, pf.fileName, pf.ext);
            }
            setLoading(false)
            callback([]);

          } catch (e) {
          }
        }}>
          <SendIcon sx={{fontSize: 30}}></SendIcon>
        </IconButton>}


      </Stack>
    </Stack>
  )
}