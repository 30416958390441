import React, { useState } from "react";
import {
	Autocomplete,
	Button,
	Container,
	InputAdornment,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import LocationValidation from "../../../validations/auth/location-validation";
import { doc, setDoc } from "firebase/firestore";
import { auth, DB } from "../../../config/firebase/firebase";
import SendIcon from "@mui/icons-material/Send";
import { style, textTransform } from "@mui/system";
import dataJson from "../../../const/data.json";


export default function Location() {
	const [locations, setLocations] = React.useState([]);
	async function populateData() {
		await fetch("https://restcountries.com/v3.1/all")
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				let locs = [];
				data.map((d) => {
					locs.push(d["name"]["common"]);
				});
				setLocations(locs);
			})
			.catch((err) => console.log(err));
	}
	const navigator = useNavigate();
	const [Send, setSend] = useState(false);
	const [value, setValue] = useState("");
	const handleClick = () => {
		if (value != "") {
			setSend(true);
			// console.log(value);
			setValue("");
		}
	};

	const formik = useFormik({
		initialValues: LocationValidation.initialValues,
		validationSchema: LocationValidation.schema,
		onSubmit: async (values) => {
			// if (values.country === "uk") {
				// console.log("working fine");
				navigator("/m/auth/signup", {state: {country: values.country}});
			// }
		},
	});
	useState(() => {
		// console.log(value);
		if (locations.length == 0) {
			populateData();
		}
	}, []);
	return (
		<>
			<Container maxWidth={"sm"} sx={{ mt: Send ? 5 : 20 }}>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="flex-start"
					spacing={5}
					component="form"
					onSubmit={(e) => formik.handleSubmit(e)}>
					<Typography
						variant={"h4"}
						sx={{ textAlign: "center", width: "100%",  fontFamily: "Poppins", fontWeight:"400" }}>
						Let’s get some basic details...
						<Typography variant={"body1"} sx={{ mt: 2 }}>
							Where are you located?
						</Typography>
					</Typography>
					{/* <Autocomplete
						clearOnBlur={false}
						select
						value={value}
						onChange={(event, newValue) => {
							setValue(newValue);
							formik.setFieldValue("country", newValue);
						}}
						disablePortal
						name={"country"}
						options={locations}
						fullWidth
						sx={{ textAlign: "left" }}
						renderInput={(params) => (
							<TextField
								{...params}
								value={formik.values.country}
								onChange={formik.handleChange}
								label="Country/Region"
								error={
									formik.touched.country &&
									Boolean(formik.errors.country)
								}
								helperText={
									formik.touched.country &&
									formik.errors.country
								}
							/>
						)}
					/> */}

					<Autocomplete
						multiple={false}
						required
						fullWidth
						options={[...dataJson.cities].map((option) => option)}
						freeSolo
						onChange={(e, newValue) =>
							formik.setFieldValue("country", newValue)
						}
						disableClearable={true}
						value={formik.values?.country}
						renderInput={(params) => (
							<TextField
								{...params}
								name={'country'}
								onChange={formik?.handleChange}
								// variant={'standard'}
								error={Boolean(formik.errors.country)}
								helperText={
									formik.errors.country
										? "Country is required"
										: ""
								}
								label={
									<>
										Location*

									</>
								}
								placeholder="Country/Region"
								sx={{
									transition: "0.1s linear",
								}}
							/>
						)}
					/>
					{/*<TextField
						name={"country"}
						value={formik.values.country}
						onChange={formik.handleChange}

						error={
							formik.touched.country &&
							Boolean(formik.errors.country)
						}
						helperText={
							formik.touched.country && formik.errors.country
						}
						// variant={"outlined"}
						select
						label={"Country/Region"}
						fullWidth
						sx={{ textAlign: "left" }}>
						<MenuItem value={"usa"}>United States</MenuItem>
						<MenuItem value={"uk"}>United Kingdom</MenuItem>
						<MenuItem value={"europe"}>Europe</MenuItem>
					</TextField>*/}
					<Button
						disabled={formik?.values.country === ''}
						fullWidth
						sx={{ height: "48px" }}
						variant={"contained"}
						color={"primary"}
						type={"submit"}>
						Next
					</Button>
				{/*	{(formik.values.country == "usa" ||
						formik.values.country == "europe") && (
						<Stack
							direction="column"
							justifyContent="center"
							alignItems="center"
							sx={{ width: "100%" }}>
							<Typography
								variant={"body1"}
								sx={{ textAlign: "center", width: "100%" }}>
								Sorry, we are currently not available in {""}
								{formik.values.country == "usa" ? (
									<span
										style={{ textTransform: "Uppercase" }}>
										{formik.values.country}.
									</span>
								) : (
									""
								)}
								{formik.values.country == "europe" ? (
									<span
										style={{ textTransform: "capitalize" }}>
										{formik.values.country}.
									</span>
								) : (
									""
								)}
							</Typography>
							<Typography
								variant={"body1"}
								sx={{
									textAlign: "center",
									width: "100%",
									mb: 4,
								}}>
								Please enter your email below to get an update from us.
							</Typography>
							<TextField
								fullWidth
								value={value}
								onChange={(e) => {
									setValue(e.target.value);
								}}
								id="outlined-email-input"
								label="Email"
								type="email"
								name="email"
								autoComplete="email"
								variant="outlined"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Button
												startIcon={<SendIcon />}
												onClick={handleClick}></Button>
										</InputAdornment>
									),
								}}
							/>
							{Send && (
								<Typography
									variant={"body1"}
									sx={{
										textAlign: "center",
										width: "100%",
										mt: 4,
										mb: 6,
									}}>
									Thank you for your email. <br />
									You will be notified once our platform will
									be available in your region.
								</Typography>
							)}
						</Stack>
					)}*/}
				</Stack>
			</Container>
		</>
	);
}
