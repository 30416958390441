import * as React from "react";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {Fab, IconButton, Menu, Tooltip, Stack,styled} from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
const Input = styled('input')({
  display: 'none',
})
export default function AttachmentButton({callback}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return(
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
           top: -100
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'

          },
        }}
      >
          <Stack direction={'column'} spacing={1} sx={{m: .5}}>
            <Tooltip title="Document" placement="right">
              <label htmlFor={'attachment-document'}>
                <Input id={'attachment-document'} type={'file'} onChange={(e) => {
                  const list = []
                  for(let file of e.target.files) {
                    list.push(file);
                  }
                  callback(list);
                }} accept={'.pdf,.doc,.docx,.csv,.xls,.xlsx'} multiple={true}/>
                <Fab size={"small"}   color={'warning'}  sx={{color: '#fff'}} component={'span'}>
                  <InsertDriveFileIcon></InsertDriveFileIcon>
                </Fab>
              </label>

            </Tooltip>
            <Tooltip title="Photo / Video"  placement="right">
              <label htmlFor={'attachment-media'}>
                <Input id={'attachment-media'} type={'file'} onChange={(e) => {
                  const list = []
                  for(let file of e.target.files) {
                    list.push(file);
                  }
                  callback(list);
                }} accept={'.jpg,.jpeg,.png,.gif,.mov,.mp4'} multiple={true}/>
                <Fab size={"small"}   color={'info'}  sx={{color: '#fff'}} component={'span'}>
                  <InsertPhotoIcon></InsertPhotoIcon>
                </Fab>
              </label>
            </Tooltip>
          </Stack>

      </Menu>
      <IconButton color={'primary'} id="long-button"
                  aria-controls="long-menu"
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}>
        <img src={'/assets/attachment-icon.png'}/>
      </IconButton>

    </>

  )

}