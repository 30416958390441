import * as yup from "yup";
import {
  DEFAULT_LANGUAGE,
  DEFAULT_MARKET,
} from "../../utils/enums/labels.enum";

const ExpertOnBoardingProfileSchema = yup.object({
  photoURL: yup.string("Photo URL"),
  description: yup
    .string("Description")
    .required("Description required.")
    .test("wordCount", "Word count should be less than 150", function (value) {
      return value.split(" ").length <= 150;
    }),
  expertise: yup.array("Expertise").of(yup.string("")).nullable(),
  supportStage: yup
    .array("Support Stage")
    .of(yup.string("Support startup at stage")),
});
const ExpertOnBoardingProfileInitValue = {
  photoURL: "",
  description: "",
  expertise: [],
  supportStage: [],
};
const ExpertOnBoardingProfileSecondSchema = yup.object({
  socialProof: yup
    .array("Social Proof (References for specific kinds of expertise)")
    .of(yup.string(""))
    .nullable(),
  links: yup.array("Additional links").of(yup.string("")).nullable(),
  assessment: yup.boolean("Keen for startup assessment"),
  reachable: yup.boolean("Reachable to startups on chat"),
  angels: yup.boolean("Angel"),
});
const ExpertOnBoardingProfileSecondInitValue = {
  socialProof: [],
  links: [""],
  assessment: false,
  reachable: false,
  angels: false,
};
const ExpertOnBoardingProfileThirdSchema = yup.object({
  doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue: yup.boolean(
    "Do you want to help startups with Business Development to increase revenue? "
  ),
  happyToProvideServiceForSomeShareOfTheEquity: yup.boolean(
    "Happy to provide service for some share of the equity?"
  ),
});
const ExpertOnBoardingProfileThirdInitValue = {
  doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue: false,
  happyToProvideServiceForSomeShareOfTheEquity: false,
};

const ExpertFirstForm = yup.object({
  photoURL: yup.string("photoURL"),
  displayName: yup.string("Display name").required("Your Name is required").min(3, 'Name should be of minimum 3 characters length')
  .max(35, 'Name should be of maximum 35 characters length'),
  description: yup.string("Tell us about yourself").max(
    150,
    "Character Limit Exceeded i.e. 150 Characters"
  ),
  linkedinURL: yup
    .string("LinkedIn URL")
    .matches("linkedin.com", "Please enter valid Linkedin url"),
});
const ExpertFirstFormValues = {
  photoURL: "",
  displayName: "",
  description: "",
  linkedinURL: "",
};
const ExpertSecondForm = yup.object({
  expertise: yup
    .array("Expertise")
    .of(yup.string(""))
    .required("Expertise*")
    .min(1, "Expertise is required"),
  sector: yup
    .array("Sector")
    .of(yup.string(""))
    .required("Sector is required")
    .min(1, "Sector is a required field"),
  geographies: yup
    .array("Geographies")
    .of(yup.string(""))
    .required("Geographies is required")
    .min(1, "Geographies is required"),
  languages: yup
    .array("languages")
    .of(yup.string(""))
    .required("Languages is required")
    .min(1, "Languages is required"),
  relevantYearOfExperience: yup
    .string("Relevant Years of Experience")
    .required("Relevant years of experience is a required field*"),
  additionalDetails: yup.array("Additional details").of(yup.string("")),
});
const ExpertSecondFormValues = {
  expertise: ["Growth Expert", "Sales", "Pricing"],
  sector: [],
  geographies: [DEFAULT_MARKET],
  languages: [DEFAULT_LANGUAGE],
  relevantYearOfExperience: "",
};
const ExperienceForm = yup.object({
  companyName: yup.string("Company name").required("Company name*"),
  website: yup.string("Website"),
  role: yup.string("Role").required("Role*"),
  currentlyWorkingInThisRole: yup.boolean(
    "I am currently working in this role"
  ),
  startDate: yup.date("Start date"),
  endDate: yup
    .date("End date")
    .when(
      "startDate",
      (startDate, yup) =>
        startDate && yup.min(startDate, "End date cannot be before Start date")
    ),
  experience: yup.string("End date").required("Experience*"),
  //socialProof: yup.string('Social proof').required('socialProof*'),
  link: yup.string("Additional links"),
});
const ExperienceFormValues = {
  companyName: "",
  website: "",
  role: "",
  currentlyWorkingInThisRole: false,
  startDate: "",
  endDate: "",
  experience: "",
  socialProof: "",
  link: "",
};

const ExperienceGrowthExpertForm = yup.object({
  companyName: yup.string("Company name").required("Company name*"),
  website: yup.string("Website"),
  companyCurrentStage: yup
    .array("companyCurrentStage")
    .of(yup.string(""))
    .required("Company Current Stage*")
    .min(1, "Company Current Stage is required"),
  role: yup.string("Role").required("Role*"),
  currentlyWorkingInThisRole: yup
    .boolean()
    .required("Please indicate if you are currently working in this role"),
  typeOfRole: yup
    .array()
    .of(yup.string())
    .when("role", (role, schema) => {
      return role ? schema.required("Type of Role is required") : schema;
    })
    .min(1, "Type of Role is required"),
  roleDescription: yup
    .array()
    .of(yup.string())
    .when("role", (role, schema) => {
      return role ? schema.required("Role description is required") : schema;
    })
    .min(1, "Role description is required"),
  startDate: yup.date("Start date"),
  endDate: yup
    .date("End date")
    .when(
      "startDate",
      (startDate, yup) =>
        startDate && yup.min(startDate, "End date cannot be before Start date")
    ),
  experience: yup.string("Experience").required("Experience*"),
  companyStageDuringEmployment: yup
    .array("companyStageDuringEmployment")
    .of(yup.string(""))
    .required("Company Stage during your employment*")
    .min(1, "Company Stage during you employment is required"),
  productDescription: yup
    .string("Product")
    .required("Product description is required*"),
  businessModel: yup
    .array("businessModel")
    .of(yup.string(""))
    .required("Business Model*")
    .min(1, "Business Model is required"),
  whoDoYouSellTo: yup.string("Product").required("Who do you sell to*"),
  whereDoYouSell: yup
    .array("whereDoYouSell")
    .of(yup.string(""))
    .required("Where Do You Sell!")
    .min(1, "Where Do You Sell is required"),
  link: yup.string("Additional links"),
});
const ExperienceFormGrowthExpertValues = {
  companyName: "",
  website: "",
  companyCurrentStage: [],
  role: "",
  currentlyWorkingInThisRole: false,
  typeOfRole: [],
  roleDescription: [],
  startDate: "",
  endDate: "",
  experience: "",
  companyStageDuringEmployment: [],
  productDescription: "",
  businessModel: [],
  whoDoYouSellTo: "",
  whereDoYouSell: [DEFAULT_MARKET],
  link: "",
};
const EditForm = yup.object({
  expertise: yup
    .array("Expertise")
    .of(yup.string(""))
    .required("Expertise*")
    .min(1, "Please select an Expertise"),
  sector: yup
    .array("Sector")
    .of(yup.string(""))
    .required("Sector*")
    .min(1, "Please select a Sector"),
  geographies: yup.array("Geographies").of(yup.string("")),
  languages: yup
    .array("Languages")
    .of(yup.string(""))
    .required("Languages*")
    .min(1, "Languages*"),
  relevantYearOfExperience: yup
    .string("Relevant Years of Experience")
    .required("Relevant years of experience is a required field*"),
  additionalDetails: yup
    .array("Additional details")
    .of(yup.string(""))
    .nullable(),
  supportStage: yup
    .array("Support Startup at Stage")
    .of(yup.string())
    .required("Support Startup at Stage*")
    .min(1),
  happyToProvideServiceForSomeShareOfTheEquity: yup.boolean(
    "Happy to provide service for some share of the equity?"
  ),
});
const ExpectationsForm = yup.object({
  supportStage: yup
    .array("Support Startup at Stage")
    .of(yup.string())
    .required("Support Startup at Stage*")
    .min(1),
  happyToProvideServiceForSomeShareOfTheEquity: yup.boolean(
    "Happy to provide service for some share of the equity?"
  ),
});
const ExpectationsFormValues = {
  supportStage: ["Pre-seed", "Seed"],
  happyToProvideServiceForSomeShareOfTheEquity: false,
};
const InvestorDetailsForm = yup.object({
  areYouAnAngelInvestor: yup.boolean("Are you an Angel Investor?"),
});
const InvestorDetailsFormValues = {
  areYouAnAngelInvestor: false,
};
const ExportOnboardingValidation = {
  ExpertOnBoardingProfileSchema,
  ExpertOnBoardingProfileInitValue,
  ExpertOnBoardingProfileSecondSchema,
  ExpertOnBoardingProfileSecondInitValue,
  ExpertOnBoardingProfileThirdSchema,
  ExpertOnBoardingProfileThirdInitValue,
  ExpertFirstForm,
  ExpertFirstFormValues,
  ExpertSecondForm,
  ExpertSecondFormValues,
  ExperienceForm,
  ExperienceFormValues,
  ExperienceGrowthExpertForm,
  ExperienceFormGrowthExpertValues,
  ExpectationsForm,
  ExpectationsFormValues,
  InvestorDetailsForm,
  InvestorDetailsFormValues,
  EditForm,
};

export default ExportOnboardingValidation;
