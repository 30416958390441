import React from "react";
import { AuthUserContext } from "../../../../providers/auth-provider";
import { Container, Grid, Stack, Typography, Box, Skeleton, styled, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import GetOffers from "../../../../api/get-filter-offers";
import OfferClaimCard from "./offer-claim-card";
export default function OffersList() {
  const [offers, setOffers] = React.useState([]);
  const navigator = useNavigate();
  const { profile } = React.useContext(AuthUserContext);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [searchQuery, setsearchQuery] = React.useState("");
  React.useEffect(() => {
    setLoading(true)
    const fetchOffersData = async () => {
      const today = new Date();
      const todayString = today.toISOString().split("T")[0];
      const filters = { key: "isActive", operand: "==", value: true }
      const userType = "startup";
      const data = await GetOffers(filters, userType);
      setOffers(data);
      setLoading(false)
    };
    fetchOffersData().catch(console.error);
   
  }, []);
  return (
    <React.Fragment>
      {/* header container */}
      <Container maxWidth={"xl"} sx={{ my: 5, textAlign: "left" }}> 
      <Stack direction={'column'} spacing={3} sx={{width: '100%', mb: 5}}>
          <div style={{flex: 1}}>
            <Typography style={{fontSize:"18px"}} sx={{fontWeight: 700}}>Hello {profile?.displayName} &nbsp; 👋</Typography>
            <Typography style={{fontSize:"12px"}} color={'secondary'}>Discover amazing deals and exclusive offers just for you!</Typography>
          </div>
         
          <Box   onSubmit={(event) => event.preventDefault()}
            component="form"
            sx={{ p: '2px 10px', display: 'flex', alignItems: 'center', width: "100%", height: 50, borderRadius: '12px',backgroundColor: '#E4EAF5' }}
            >
            <IconButton type="button" sx={{ p: '10px', }} aria-label="search"
            	onClick={(e) => setSearch(searchQuery)}
              >
              <SearchIcon sx={{ color:'#051E40BF'}}/>
            </IconButton>
            <InputBase
            onChange={(e) => setsearchQuery(e.target.value)}
            value={searchQuery}
            onKeyDown={(e)=>{
              if(e.code==="Enter"){
                setSearch(searchQuery)
              }
            }}
            
            sx={{ ml: 1, flex: 1 ,color:'#051E40BF', input:{
              "&::placeholder":{
                opacity:1
              }
              } }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search post' }}
              // disabled={true}
              />
          </Box>
         
        </Stack>
      </Container>

      <Container
        maxWidth={"lg"}
        sx={{ my: 5, textAlign: "left"}}
      >
        <Grid container spacing={1.5}>
        {(offers &&  offers?.length === 0) &&
          <Stack
          direction="row" 
          justifyContent="center"
          alignItems="center"
          spacing={1}
          minWidth={"100%"}
          mt="15%"
        >
            <img src="/assets/blanks/blank_offer.svg" width={180} alt="blank" />
    
            <Typography variant="h7" >
              <font style={{fontWeight:700}}>
            Get Ready, <br/>
            Big Things Are Coming Your Way!<br/></font>
            Keep your eyes glued to this space <br/>
            because exciting offers will be arriving soon. <br/>
            Stay tuned for the excitement!
            </Typography>
       
        </Stack>
          }
          {
            offers.map((offer, i) => {
              const searchString = `${offer.name} ${offer.headingOne} ${offer.headingTwo} ${offer.companyName}`;
              return  (
              searchString.toLowerCase().includes(search.toLowerCase())
              ?
              <OfferClaimCard
                    offer={offer}
                    callback={(e) => {
                      e.stopPropagation();
                    }}
                    remove={(offerId) => {}}
                    loading = {loading}
                  />
              :
              null)
            })
          }
         
        </Grid>
      </Container>
    </React.Fragment>
  );
}
