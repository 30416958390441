import * as yup from 'yup';

const TalentDetailsSchema = yup.object({
  skills: yup.array('Skills').of(
    yup.string()
  ).nullable(),
  supportsStages:yup.array('Supports startups at stage').of(
    yup.string()
  ).nullable(),
  focusVerticals: yup.array('Focus Verticals').of(
    yup.string()
  ).nullable(),
  socialProof: yup.array('Social Proof (upload any supporting documents.)').of(
    yup.string()
  ).nullable(),
  spocName: yup.string('SPOC Name'),
  docLink: yup.string('Document link (google docs, docsend, dropbox, etc) '),
  availableForStartups: yup.boolean('Available for Startups'),
  happyToRespondToEnquiries: yup.boolean('Happy to respond to enquiries'),
  happyToProvideServiceForSomeShareOfEquity: yup.boolean('Happy to provide service for some share of equity?'),
  reachableToStartupsChat: yup.boolean('Reachable to Startups on chat'),
  helpStartupsIncreaseRevenue:  yup.boolean('Do you want to help startups with Business Development to increase revenue?'),
/*  socialProof: yup.array().of(
    yup.string()
  ).nullable()*/

});
const initialValues = {

  skills: [],
  supportsStages: [],
  focusVerticals: [],
  socialProof: [],
  spocName: '',
  docLink: '',
  availableForStartups: false,
  happyToRespondToEnquiries: false,
  happyToProvideServiceForSomeShareOfEquity: false,
  reachableToStartupsChat: false,
  helpStartupsIncreaseRevenue: false,
};


const TalentFirstFormSchema = yup.object({
  photoURL: yup.string('Photo URL'),
  description: yup.string('Tell us about yourself').max(150, 'should be of max 150 characters length'),
  skills:yup.array('Skills').of(
    yup.string()
  ).nullable(),
  supportStage:yup.array('Support startup at stage').of(
    yup.string()
  ).nullable()
})
const TalentFirstFormInitialValues = {
  description: '',
  photoURL: '',
  skills: ['Graphic Designer', 'Digital Marketing', 'Strategy'],
  supportStage: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Later Stage'],
}
const TalentSecondFormSchema = yup.object({
  socialProofNotes: yup.string('Social proof note').max(150, 'should be of max 150 characters length'),
  socialProof:yup.array('attachments').of(
    yup.string()
  ).nullable(),
  links:yup.array('Additional links').of(
    yup.string()
  ).nullable(),
  focusVertical:yup.array('Focus vertical').of(
    yup.string()
  ).nullable()
})
const TalentSecondFormInitialValues = {

  socialProofNotes:'',
  socialProof: [],
  links: [''],
  focusVertical: ['Climate'],
}
const TalentThirdFormSchema = yup.object({
  assesment:  yup.boolean('Keen for startup assessment'),
  doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue: yup.boolean('Do you want to help startups with Business Development to increase revenue? '),
  reachable:  yup.boolean('Reachable to startups on chat')
})
const TalentThirdFormInitialValues = {
  assesment : false,
  doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue: false,
  reachable: false,
}
const TalentDetailsValidation = {
  initialValues,
  schema : TalentDetailsSchema,
  TalentFirstFormSchema,
  TalentFirstFormInitialValues,
  TalentSecondFormSchema,
  TalentSecondFormInitialValues,
  TalentThirdFormSchema,
  TalentThirdFormInitialValues
};



export default  TalentDetailsValidation;