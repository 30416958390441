import * as React from 'react';
import {Autocomplete, Button, Chip, Container, MenuItem, Stack, TextField, Typography, CircularProgress} from "@mui/material";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useFormik} from "formik";
import RequestAssistanceValidation from "../../../validations/common/request-assistance";
import CloseIcon from "@mui/icons-material/Close";
import {addDoc, collection, doc, serverTimestamp, setDoc, getDoc} from "firebase/firestore";
import {auth, DB} from "../../../config/firebase/firebase";
import {AuthUserContext} from "../../../providers/auth-provider";
import {useNavigate, useParams} from "react-router-dom";
export default function RequestFundingScreen(){
  const {requestId} = useParams();
  const { profile} = React.useContext(AuthUserContext);
  const [loading, setLoading] = React.useState(false)
  const navigator = useNavigate()
  const formik = useFormik({
    initialValues:RequestAssistanceValidation.requestFundingInitialValues,
    validationSchema:RequestAssistanceValidation.requestFundingSchema,
    onSubmit: async (values) => {
      setLoading(true)
      // console.log(JSON.stringify(values))


      await lookingForFundingRequest({...values,type:'looking-for-funding',  creatorData: profile})
      setLoading(false)
      navigator(`/${profile?.userType}/open-requests`);
    },
  });
  const lookingForFundingRequest = (request) => {
    if (requestId) {
      const requestRef = doc(DB, 'help-requests', requestId);
      return setDoc(requestRef, {...request, updatedAt: serverTimestamp(),}, {merge: true})
    } else {
      return  addDoc(collection(DB, "help-requests"), {
        ...request,
        createdAt: serverTimestamp(),
        uid: profile?.uid,
      });
    }

  }
  React.useEffect(() => {
    if (requestId) {
     const reqRef =  doc(DB, 'help-requests', requestId);
     getDoc(reqRef).then((doc) => {
       formik.setValues({
         ...doc.data()
       })
     })
    }
  }, [])

  return(
    <Container maxWidth={'md'} sx={{py: 5}}>
      <Stack direction={'column'} spacing={4} sx={{width: '100%'}} component="form" onSubmit={e => formik.handleSubmit(e)}>
        <Typography variant={'h4'} sx={{textAlign:'center'}} fontWeight={600}>
          Request For Funding
        </Typography>

        <Stack direction={'column'} spacing={1} sx={{width: '100%'}}>
          <Typography variant={"body1"} textAlign={'left'}>Write a little details about the funding request</Typography>
          <TextField
            placeholder={'Type here...'}
            name={'requestFor'}
            value={formik.values.requestFor}
            onChange={formik.handleChange}
            error={formik.touched.requestFor && Boolean(formik.errors.requestFor)}
            helperText={formik.touched.requestFor && formik.errors.requestFor}
          />
        </Stack>

        <Stack direction={"row"} spacing={3} >
          <Stack direction={'column'} spacing={1}  sx={{flex: 1}}>
            <Typography variant={"body1"} textAlign={'left'}>
              Round
            </Typography>
            <TextField  name={'round'}
                       value={formik.values.round}
                       onChange={formik.handleChange}
                       error={formik.touched.round && Boolean(formik.errors.round)}
                       helperText={formik.touched.round && formik.errors.round}
                        select
                        fullWidth
                        sx={{ textAlign: "left" }}>
              <MenuItem value={"Pre-seed"}>Pre-seed</MenuItem>
              <MenuItem value={"Seed"}>Seed</MenuItem>
              <MenuItem value={"Series A"}>Series A</MenuItem>
              <MenuItem value={"Series B"}>Series B</MenuItem>
              <MenuItem value={"Series C"}>Series C</MenuItem>
              <MenuItem value={"Series D"}>Series D</MenuItem>
              <MenuItem value={"IPO"}>IPO</MenuItem>
            </TextField>
          </Stack>
          <Stack direction={'column'} spacing={1}  sx={{flex: 1}}>
            <Typography variant={"body1"} textAlign={'left'}>
              Revenue Model
            </Typography>
            <TextField fullWidth  name={'revenueModel'}
                       value={formik.values.revenueModel}
                       onChange={formik.handleChange}
                       error={formik.touched.revenueModel && Boolean(formik.errors.revenueModel)}
                       helperText={formik.touched.revenueModel && formik.errors.revenueModel}/>
          </Stack>
        </Stack>

        <Stack direction={"row"} spacing={3} >
          <Stack direction={'column'} spacing={1}  sx={{flex: 1}}>
            <Typography variant={"body1"} textAlign={'left'}>
              Amount to raise
            </Typography>
            <Stack direction={"row"} spacing={3} >
              <TextField  name={'currency'}
                          value={formik.values.currency}
                          onChange={formik.handleChange}
                          error={formik.touched.currency && Boolean(formik.errors.currency)}
                          helperText={formik.touched.currency && formik.errors.currency}
                          select
                          fullWidth
                          sx={{ textAlign: "left" , width: 100}}>
                <MenuItem value={"USD"}>USD</MenuItem>
                <MenuItem value={"EUR"}>EUR</MenuItem>
                <MenuItem value={"GBP"}>GBP</MenuItem>
              </TextField>
              <TextField
                name={"amount"}
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={
                  formik.touched.amount &&
                  Boolean(formik.errors.amount)
                }
                helperText={
                  formik.touched.amount &&
                  formik.errors.amount
                }
                fullWidth
                type={"number"}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={3} >
          <Stack direction={'column'} spacing={1}  sx={{flex: 1}}>
            <Typography variant={"body1"} textAlign={'left'}>
              Valuation
            </Typography>
            <Stack direction={"row"} spacing={3} >
              <TextField  name={'valuationCurrency'}
                          value={formik.values.valuationCurrency}
                          onChange={formik.handleChange}
                          error={formik.touched.valuationCurrency && Boolean(formik.errors.valuationCurrency)}
                          helperText={formik.touched.valuationCurrency && formik.errors.valuationCurrency}
                          select
                          fullWidth
                          sx={{ textAlign: "left" , width: 100}}>
                <MenuItem value={"USD"}>USD</MenuItem>
                <MenuItem value={"EUR"}>EUR</MenuItem>
                <MenuItem value={"GBP"}>GBP</MenuItem>
              </TextField>
              <TextField
                name={"valuation"}
                value={formik.values.valuation}
                onChange={formik.handleChange}
                error={
                  formik.touched.valuation &&
                  Boolean(formik.errors.valuation)
                }
                helperText={
                  formik.touched.valuation &&
                  formik.errors.valuation
                }
                fullWidth
                type={"number"}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack direction={'column'} spacing={1} sx={{width: '100%'}}>
          <Typography variant={"body1"} textAlign={'left'}>Additional information
          </Typography>
          <TextField
            placeholder={'Type here...'} multiline={true} rows={3}
            name={'description'}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </Stack>

        <Stack direction={"row"} spacing={3} >
          <Stack direction={'column'} spacing={1}  sx={{flex: 1}}>
            <Typography variant={"body1"} textAlign={'left'}>
              Female in founding team
            </Typography>
            <TextField  name={'femaleInFoundingTeam'}
                        value={formik.values.femaleInFoundingTeam}
                        onChange={formik.handleChange}
                        error={formik.touched.femaleInFoundingTeam && Boolean(formik.errors.femaleInFoundingTeam)}
                        helperText={formik.touched.femaleInFoundingTeam && formik.errors.femaleInFoundingTeam}
                        select
                        fullWidth
                        sx={{ textAlign: "left" }}>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Stack>
          <Stack direction={'column'} spacing={1}  sx={{flex: 1}}>
            <Typography variant={"body1"} textAlign={'left'}>
              Ethnic Minority in founding team
            </Typography>
            <TextField  name={'ethnicMinorityInFundingTeam'}
                        value={formik.values.ethnicMinorityInFundingTeam}
                        onChange={formik.handleChange}
                        error={formik.touched.ethnicMinorityInFundingTeam && Boolean(formik.errors.ethnicMinorityInFundingTeam)}
                        helperText={formik.touched.ethnicMinorityInFundingTeam && formik.errors.ethnicMinorityInFundingTeam}
                        select
                        fullWidth
                        sx={{ textAlign: "left" }}>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={3} >
          <Stack direction={'column'} spacing={1}  sx={{flex: 1}}>
            <Typography variant={"body1"} textAlign={'left'}>
              Immigrant in founding team
            </Typography>
            <TextField  name={'immigrantInFundingTeam'}
                        value={formik.values.immigrantInFundingTeam}
                        onChange={formik.handleChange}
                        error={formik.touched.immigrantInFundingTeam && Boolean(formik.errors.immigrantInFundingTeam)}
                        helperText={formik.touched.immigrantInFundingTeam && formik.errors.immigrantInFundingTeam}
                        select
                        fullWidth
                        sx={{ textAlign: "left" }}>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          </Stack>
          <Stack direction={'column'} spacing={1}  sx={{flex: 1}}>
            <Typography variant={"body1"} textAlign={'left'}>
              Funding close date
            </Typography>
            <TextField  name={'fundingCloseDate'}
						InputProps={{inputProps: { min: "1900-01-00", max: "2150-12-31"} }}
                        value={formik.values.fundingCloseDate}
                        onChange={formik.handleChange}
                        error={formik.touched.fundingCloseDate && Boolean(formik.errors.fundingCloseDate)}
                        helperText={formik.touched.fundingCloseDate && formik.errors.fundingCloseDate}

                        fullWidth
                        sx={{ textAlign: "left" }} type={"date"}/>
          </Stack>
        </Stack>

        {
          !requestId ?
            <Stack direction={"row"} spacing={3} justifyContent={"center"} alignItems={"center"}>

              <Button variant={"contained"} sx={{px: 2}} type={"submit"} endIcon={
                loading ? <CircularProgress size={20} sx={{color:'#fff'}}/> :
                  <ArrowForwardIcon></ArrowForwardIcon>
              }>
                Submit
              </Button>
            </Stack>
            : <Stack direction={"row"} spacing={3} justifyContent={"flex-end"} alignItems={"center"}>
              <Button variant={"outlined"} onClick={() => navigator(-1)}>Back</Button>
              <Button variant={"contained"} sx={{px: 2}} type={"submit"} endIcon={
                loading && <CircularProgress size={20} sx={{color:'#fff'}}/>
              }>
                Save & update
              </Button>
            </Stack>

        }
      </Stack>
    </Container>
  )
}