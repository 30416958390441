import React from "react";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  Chip,
  Autocomplete,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import ExportOnboardingValidation from "../../../validations/expert/onboarding-profile";
import CloseIcon from "@mui/icons-material/Close";
import { doc, getDoc } from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";

export default function Expectations({ cbBack, cbNext, data, cbDraft, uid }) {
  const isAdmin = /admin/;

  const formikFirst = useFormik({
    initialValues: data
      ? data
      : ExportOnboardingValidation.ExpectationsFormValues,
    validationSchema: ExportOnboardingValidation.ExpectationsForm,
    onSubmit: async (values) => {
      cbNext({
        ...values,
      });
    },
  });
  React.useEffect(() => {
    if (uid) {
      setTimeout(() => {
        getDoc(doc(DB, "users", uid)).then((document) => {
          if (document?.data()?.expectation) {
            formikFirst.setValues(document?.data()?.expectation);
          }
        });
      }, 100);
    }
  }, [data]);
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}
    >
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>
          <Stack direction={"column"} spacing={5}>
            <Stack direction={"column"} spacing={1.5}>
              <Autocomplete
                multiple
                required
                options={[
                  "Pre-Seed",
                  "Seed",
                  "Seed+",
                  "Series A",
                  "Series A+",
                  "Series B",
                  "Series B+",
                  "Series C and above",
                ].map((option) => option)}
                value={formikFirst.values?.supportStage}
                onChange={(e, newValue) =>
                  formikFirst.setFieldValue("supportStage", newValue)
                }
                renderTags={(value, getTagProps) =>
                  formikFirst.values?.supportStage &&
                  formikFirst.values?.supportStage.length > 0 &&
                  formikFirst.values?.supportStage.map((option, index) => (
                    <Chip
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginBottom: "10px",
                        borderRadius: "10px",
                      }}
                      deleteIcon={<CloseIcon />}
                      onDelete={() =>
                        formikFirst.setFieldValue(
                          "supportStage",
                          formikFirst?.values?.supportStage?.filter(
                            (f) => f !== option
                          )
                        )
                      }
                      label={option}
                      {...getTagProps({
                        index,
                      })}
                    ></Chip>
                  ))
                }
                renderInput={(params) => (
                  <>
                    <Typography
                      fontWeight={400}
                      fontSize={"16px"}
                      textAlign="start"
                    >
                      Support Startup at Stage *
                    </Typography>
                    <TextField
                      {...params}
                      // variant={'standard'}
                      //label="Support Startup at Stage*"
                      name={"supportStage"}
                      error={Boolean(formikFirst.errors.supportStage)}
                      helperText={
                        formikFirst.errors.supportStage
                          ? "Support Startup at Stage is required"
                          : ""
                      }
                      //placeholder="Add"
                      sx={{
                        transition: "0.1s linear",
                      }}
                    />
                  </>
                )}
              />
            </Stack>
          </Stack>

          <Stack direction={"column"} spacing={5}>
            <Stack direction={"column"} spacing={1.5}>
              <Typography
                variant={"body2"}
                sx={{
                  textAlign: "left",
                }}
              >
                Happy to provide service for some share of the equity?
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>No</Typography>
                <FormControlLabel
                  label={""}
                  control={
                    <Switch
                      name={"happyToProvideServiceForSomeShareOfTheEquity"}
                      checked={
                        formikFirst.values
                          .happyToProvideServiceForSomeShareOfTheEquity
                      }
                      onChange={formikFirst.handleChange}
                      error={
                        formikFirst.touched
                          .happyToProvideServiceForSomeShareOfTheEquity &&
                        Boolean(
                          formikFirst.errors
                            .happyToProvideServiceForSomeShareOfTheEquity
                        )
                      }
                    />
                  }
                />
                <Typography>Yes</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems="center"
        spacing={3}
        width={"100%"}
      >
        {isAdmin.test(window.location.pathname) && (
          <Button
            variant={"outlined"}
            color={"primary"}
            sx={{ px: 5 }}
            onClick={() => cbDraft(formikFirst?.values)}
          >
            Save draft
          </Button>
        )}
        <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 5 }}
          onClick={cbBack}
        >
          Back
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{ px: 5 }}
          type={"submit"}
        >
          Next
        </Button>
        
      </Stack>
    </Stack>
  );
}
