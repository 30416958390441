import * as React from "react";

import {
  Avatar,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  List,
  Box,
  Skeleton,
  Chip,
  Dialog, DialogContent, Button, Portal, DialogTitle, DialogActions, Tooltip, Grid
} from "@mui/material";
import {
  // collection,
  doc,
  deleteDoc,
  query,
  collection,
  where,
  onSnapshot,
  serverTimestamp,
  addDoc,
} from "firebase/firestore";
import Popover from '@mui/material/Popover';
import { AuthUserContext } from "../../../../../providers/auth-provider";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from "@mui/material/IconButton";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useNavigate } from "react-router-dom";
// import {type} from "@testing-library/user-event/dist/type";
import { DownloadOutlined, InsertDriveFile, SentimentSatisfiedAlt } from "@mui/icons-material";
import DownloadFileHelper from "../../../../../utils/download-file-helper";
import CloseIcon from "@mui/icons-material/Close";
import { DB } from "../../../../../config/firebase/firebase";
import ItemText from "./item-text";
import { RePostIcon, CommentIcon } from "../../../../../utils/svg-icons";
import LikeMenu from "./like-menu";
import LikesCountComponent from "./likes-count-compnent";
import PostFavorite from "./post-fav";
import PostComments from "./post-comments";
import { ResizableTextarea } from "../resizableTextField"
import moment from "moment/moment";
import styled from "@emotion/styled";
import { stringAvatar, stringToColor } from "../../../../../utils/avatarGenerator";
import PostNotification from "../../../../../api/post-notification";
import { USER_TYPES_TO_NAME, USER_TYPES_TO_NAMEC } from "../../../../../utils/enums/labels.enum";
import LinkWithThumbnail from "../../../../../components/common/link-embed-thumbnail";
export default function PostItem({ data, callback }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openn, setOpen] = React.useState(false);
  const [openRepost, setOpenRepost] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); 
  };
  const handleClose = () => { 
    setAnchorEl(null);
  };
  const { profile } = React.useContext(AuthUserContext);
  const [loading, setLoading] = React.useState(false);
  const [image, setImage] = React.useState('')
  const [commentsCount, setCommentsCount] = React.useState(0)
  const [show, setShow] = React.useState(false)
  const [video, setVideo] = React.useState('')
  const [post, setPost] = React.useState();
  const [likesCount, setLikesCount] = React.useState();
  const [latestAvatar, setLatestAvatar] = React.useState("")
  const [logo, setLogo] = React.useState("")
  const [name, setName] = React.useState(data?.creatorData?.displayName)
  const [rePost, setRePost] = React.useState(null)
  const [caption, setCaption] = React.useState("") //for repost
  const [inProgress, setInProgress] = React.useState(false)
  const [reposterAvatar, setReposterAvatar] = React.useState("")
  const [reposterName, setReposterName] = React.useState("")
  const [reposterLogo, setReposterLogo] = React.useState("")
  const [openImage, setOpenImage] = React.useState(false)
  const Input = styled("input")({
    display: "none",
  });
  const storage = getStorage();
  const navigator = useNavigate();
  const cretorUID = data?.creatorData?.uid
  let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const fileNameOverflow = (v) => {
    let value = v.replaceAll('-', '_')
    
    if (value?.length > 60) {
      return `${value.slice( 0, 10 )}...${value.slice( value.length - 10)}`
      
    } else return value
  }

  React.useEffect(() => {
    setPost(data);
    if (data?.type !== '' && data?.type !== 'file') {

      setLoading(true)
      getDownloadURL(ref(storage, data?.type === 'image' ? data?.image : data?.video))
        .then((url) => {
          data?.type === 'image' ? setImage(url) : setVideo(url);

          setLoading(false)

        })
        .catch((error) => {
          setLoading(false)
          // Handle any errors
        });
    }
  }, [data])

  // this effect fetches updated pfp of older posts.
  React.useEffect(() => {
    const q = query(collection(DB, "users"), where("uid", "==", data?.creatorData?.uid));
    // const querySnapshot = await getDocs(q);
    const unSubs = onSnapshot(q, (querySnap) => {
      if (querySnap.size > 0) {
        setName(querySnap?.docs[0].data()?.displayName)
        setLatestAvatar(querySnap?.docs[0]?.data()?.photoURL)
        if (querySnap?.docs[0]?.data()?.userType === 'startup') {
          setLatestAvatar(querySnap?.docs[0]?.data()?.startup?.founder?.photoURL)
          const url = querySnap?.docs[0]?.data()?.startup?.logoURL || ''
          setLogo(url)
        }

      }

    })
    return () => unSubs
  }, [data?.id])

  React.useEffect(() => {
    if (data && data?.reposterData && data?.reposterData?.uid){

      const q = query(collection(DB, "users"), where("uid", "==", data?.reposterData?.uid));
      // const querySnapshot = await getDocs(q);
      const unSubs = onSnapshot(q, (querySnap) => {
        if (querySnap.size > 0) {
          setReposterName(querySnap?.docs[0].data()?.displayName)
          setReposterAvatar(querySnap?.docs[0]?.data()?.photoURL)
          if (querySnap?.docs[0]?.data()?.userType === 'startup') {
            setReposterName(querySnap?.docs[0]?.data()?.startup?.founder?.founderName)
            // setReposterAvatar(querySnap?.docs[0]?.data()?.startup?.founder?.photoURL)
            const url = querySnap?.docs[0]?.data()?.startup?.logoURL || ''
            setReposterLogo(url)
          }

        }

      })
      return () => unSubs
    }
  }, [data?.id])

  const resetAll = async () => {
    debugger
    await setCaption("")
    await setRePost(null)
    await setInProgress(false)
  }

  return (
    <Stack position={"static"} sx={{
      boxShadow: '0px 0px 13px -2px rgba(0, 0, 0, 0.15)',
      background: '#fff',
      borderRadius: '15px',
      width: '100%',
      
      
     

    }}  >

      {/* {console.log(data)} */}
      {/*
      TODO: avatar and more icon button
      */}

      {
        data?.reposterData &&
        <>
          {

            <Stack direction={'column'}  style={{boxShadow: '0px 0px 13px -2px rgba(0, 0, 0, 0.15)',marginBottom:"-6.5%"}}>


            <Stack direction={'row'} spacing={1} justifyContent="space-around" alignItems="flex-start"
         sx={{  px: data?.reposterData ? -2 : -2,marginBottom:"-3%" }}>
        <Stack direction={'row'} alignItems={'center'} style={{marginRight:"25%"}}>
                  <Avatar sx={{ height: 40, width: 40, cursor: "pointer",marginLeft:"-5%", bgcolor: reposterAvatar ? 'transparent' : stringToColor(reposterName), fontSize: "1.5rem" }} {...stringAvatar(reposterName)} imgProps={{ sx: { objectFit: 'cover' } }} src={
                    reposterAvatar

                  } onClick={
                    () => {
                      let userDetails;
                      data?.reposterData?.userType === "business-developer" ? userDetails = "business-developer-details" : data?.reposterData?.userType === "service-provider" ? userDetails = "service-provider-details" : data?.reposterData?.userType === "investor" ? userDetails = "investor-details" : data?.reposterData?.userType === "expert" ? userDetails = "expert-details" : data?.reposterData?.userType === "talent" ? userDetails = "talent-details" : userDetails = null
                      // : data.reposterData?.userType === "startup" ? userDetails = "startup-details"
                      if (data?.reposterData?.userType === "startup") {
                        navigator(`/m/${profile?.userType}/startup-founder-profile`,
                          {
                            state: {
                              profile: {
                                ...data?.reposterData,
                                startup: {
                                  ...data?.reposterData.startup,
                                  logoURL: reposterLogo,
                                },
                                photoURL: reposterAvatar,
                              },
                              founder: {
                                ...data.reposterData?.startup.founder,
                                linkedIn: data?.reposterData?.startup.founder?.linkedIn,
                                website: data?.reposterData?.startup.founder?.website,
                                twitter: data?.reposterData?.startup.founder?.twitter,
                                repeatFounder: data?.reposterData?.startup.founder?.repeatFounder,
                                startupName:
                                  data.reposterData?.startup.founder?.startupName !== ""
                                    ? data.reposterData?.startup.founder?.startupName
                                    : data.reposterData?.startup?.startupName,
                                photoURL:
                                  reposterAvatar
                              },
                            },
                          }

                        )
                      } else {
                        navigator(`/m/${profile?.userType}/${userDetails}/${data?.reposterData?.uid}`)

                      }
                    }}>
                  </Avatar>
                  <List sx={{ py: 0, my: 0 }}>
                    <ListItem alignItems="flex-start" sx={{  cursor: "pointer" }}>

                      <ListItemText
                        onClick={
                          () => {
                            let userDetails;
                            data?.reposterData?.userType === "business-developer" ? userDetails = "business-developer-details" : data?.reposterData?.userType === "service-provider" ? userDetails = "service-provider-details" : data?.reposterData?.userType === "investor" ? userDetails = "investor-details" : data?.reposterData?.userType === "expert" ? userDetails = "expert-details" : data?.reposterData?.userType === "talent" ? userDetails = "talent-details" : userDetails = null
                            // : data.reposterData?.userType === "startup" ? userDetails = "startup-details"
                            if (data?.reposterData?.userType === "startup") {
                              navigator(`/m/${profile?.userType}/startup-founder-profile`,
                                {
                                  state: {
                                    profile: {
                                      ...data?.reposterData,
                                      startup: {
                                        ...data?.reposterData.startup,
                                        logoURL: reposterLogo,
                                      },
                                      photoURL: latestAvatar,
                                    },
                                    founder: {
                                      ...data.reposterData?.startup.founder,
                                      linkedIn: data?.reposterData?.startup.founder?.linkedIn,
                                      website: data?.reposterData?.startup.founder?.website,
                                      twitter: data?.reposterData?.startup.founder?.twitter,
                                      repeatFounder: data?.reposterData?.startup.founder?.repeatFounder,
                                      startupName:
                                        data.reposterData?.startup.founder?.startupName !== ""
                                          ? data.reposterData?.startup.founder?.startupName
                                          : data.reposterData?.startup?.startupName,
                                      photoURL:
                                        reposterAvatar
                                    },
                                  },
                                }

                              )
                            } else {
                              navigator(`/m/${profile?.userType}/${userDetails}/${data.reposterData?.uid}`)

                            }
                          }}
                        primary={
                          <React.Fragment>
                            <Typography
                              fontWeight={500}
                              component="span"
                              style={{fontSize:"10px"}}
                              color="text.primary"

                            >
                              {reposterName}
                            </Typography></React.Fragment>
                        }
                        secondary={
                          <Stack direction={'column'}>
                            <font
                              style={{ fontSize: 8, fontWeight: 300 }}>
                              {data.reposterData?.userType === 'startup' ? 'Founder at ' + (post?.reposterData?.startup?.startupName) : USER_TYPES_TO_NAMEC[data.reposterData?.userType]}
                            </font>
                            <Typography variant={"caption"} sx={{ fontSize: 8, color: "#8F8F8F", fontWeight: 300 }} color="secondary">
                              {post?.createdAt?.seconds && (
                                moment().diff(moment(post.createdAt.seconds * 1000), 'hours') < 18
                                  ? moment(post.createdAt.seconds * 1000).fromNow(true) + ' ago'
                                  : moment(post.createdAt.seconds * 1000).format('DD MMMM YYYY')
                              )}
                            </Typography>
                          </Stack>
                        }
                      />
                    </ListItem>
                  </List>
                </Stack>
                <Stack sx={{ m: 5 }}>
                  {
                    profile?.uid === data?.uid &&
                    <Stack sx={{ alignItems: "flex-end" }}>
                      <IconButton
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}

                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Stack>
                  }
                  <PostFavorite data={data} />
                </Stack>
              </Stack>
              {data?.repostText &&
                <Typography variant={"body2"}>
                  <ItemText limit={400} text={data?.repostText} />
                </Typography>
              }
            </Stack>
          }

          {/* </Stack> */}
          <hr style={{ opacity: 0.3 }} />
        </>
      }


      <Stack direction={'row'} spacing={1}
        alignItems="center" sx={{  px: data?.reposterData ?  5: 5 }}>
        <Stack direction={'row'} alignItems={'center'} spacing={-1} style={{marginRight:"13%"}}>

          <Avatar sx={{ height: 40, width: 40, cursor: "pointer",marginLeft:"-10%", bgcolor: latestAvatar ? 'transparent' : stringToColor(name), fontSize: "1.5rem" }} {...stringAvatar(name)} imgProps={{ sx: { objectFit: 'cover' } }} src={
            latestAvatar
          } onClick={
            () => {
              let userDetails;
              data?.creatorData?.userType === "business-developer" ? userDetails = "business-developer-details" : data?.creatorData?.userType === "service-provider" ? userDetails = "service-provider-details" : data?.creatorData?.userType === "investor" ? userDetails = "investor-details" : data?.creatorData?.userType === "expert" ? userDetails = "expert-details" : data?.creatorData?.userType === "talent" ? userDetails = "talent-details" : userDetails = null
              // : data.creatorData?.userType === "startup" ? userDetails = "startup-details"
              if (data?.creatorData?.userType === "startup") {
                navigator(`/m/${profile?.userType}/startup-founder-profile`,
                  {
                    state: {
                      profile: {
                        ...data?.creatorData,
                        startup: {
                          ...data?.creatorData.startup,
                          logoURL: logo,
                        },
                        photoURL: latestAvatar,
                      },
                      founder: {
                        ...data.creatorData?.startup.founder,
                        linkedIn: data?.creatorData?.startup.founder?.linkedIn,
                        website: data?.creatorData?.startup.founder?.website,
                        twitter: data?.creatorData?.startup.founder?.twitter,
                        repeatFounder: data?.creatorData?.startup.founder?.repeatFounder,
                        startupName:
                          data.creatorData?.startup.founder?.startupName !== ""
                            ? data.creatorData?.startup.founder?.startupName
                            : data.creatorData?.startup?.startupName,
                        photoURL:
                          latestAvatar
                      },
                    },
                  }

                )
              } else {
                navigator(`/m/${profile?.userType}/${userDetails}/${data?.creatorData?.uid}`)

              }
            }}>
          </Avatar>
          <List sx={{ py: 0, my: 0 }}>
            <ListItem alignItems="flex-start" sx={{ cursor: "pointer", width: "180px" }} >

              <ListItemText
                onClick={
                  () => {
                    let userDetails;
                    data?.creatorData?.userType === "business-developer" ? userDetails = "business-developer-details" : data?.creatorData?.userType === "service-provider" ? userDetails = "service-provider-details" : data?.creatorData?.userType === "investor" ? userDetails = "investor-details" : data?.creatorData?.userType === "expert" ? userDetails = "expert-details" : data?.creatorData?.userType === "talent" ? userDetails = "talent-details" : userDetails = null
                    // : data.creatorData?.userType === "startup" ? userDetails = "startup-details"
                    if (data?.creatorData?.userType === "startup") {
                      navigator(`/m/${profile?.userType}/startup-founder-profile`,
                        {
                          state: {
                            profile: {
                              ...data?.creatorData,
                              startup: {
                                ...data?.creatorData.startup,
                                logoURL: logo,
                              },
                              photoURL: latestAvatar,
                            },
                            founder: {
                              ...data.creatorData?.startup.founder,
                              linkedIn: data?.creatorData?.startup.founder?.linkedIn,
                              website: data?.creatorData?.startup.founder?.website,
                              twitter: data?.creatorData?.startup.founder?.twitter,
                              repeatFounder: data?.creatorData?.startup.founder?.repeatFounder,
                              startupName:
                                data.creatorData?.startup.founder?.startupName !== ""
                                  ? data.creatorData?.startup.founder?.startupName
                                  : data.creatorData?.startup?.startupName,
                              photoURL:
                                latestAvatar
                            },
                          },
                        }

                      )
                    } else {
                      navigator(`/m/${profile?.userType}/${userDetails}/${data.creatorData?.uid}`)

                    }
                  }}
                primary={
                  <React.Fragment>
                    <Typography
                      fontWeight={400}
                      component="span"
                      style={{fontSize:"10px"}}
                      color="text.primary"

                    >
                      {name}
                    </Typography></React.Fragment>
                }
                secondary={
                  <Stack direction={'column'}>
                    <font
                      style={{ fontSize: 8, fontWeight: 300 }}>
                      {data.creatorData?.userType === 'startup' ? 'Founder at ' + (post?.creatorData?.startup?.startupName) : data.creatorData?.userType === 'expert' ? 'Industry Expert' : USER_TYPES_TO_NAMEC[data.creatorData?.userType]}
                    </font>
                    {!data?.reposterData &&
                      <Typography variant={"caption"} sx={{ fontSize: 8, color: "#8F8F8F", fontWeight: 300 }} color="secondary">
                      {post?.createdAt?.seconds && (
                          moment().diff(moment(post.createdAt.seconds * 1000), 'hours') < 18
                            ? moment(post.createdAt.seconds * 1000).fromNow(true) + ' ago'
                            : moment(post.createdAt.seconds * 1000).format('DD MMMM YYYY')
                        )}
                        {post?.updatedAt?.seconds ? " • Edited" : null}
                      </Typography>}
                  </Stack>
                }
              />
            </ListItem>
          </List>
        </Stack>
        <Grid container direction="column" >
  {!data?.reposterData && (
    <Grid item>
      {profile?.uid === data?.uid && (
        <IconButton
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ marginRight: { xs: 0, sm: '8px' } }}
        >
          <MoreHorizIcon />
        </IconButton>
      )}
      <PostFavorite data={data} sx={{ marginRight: { xs: 0, sm: '8px' } }} />
    </Grid>
  )}
</Grid>


        <Popover
  id="basic-menu"
  anchorEl={anchorEl}
  open={open}
  onClose={handleClose}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  sx={{mt:-1.5}}
>
  <List sx={{ minWidth: 80, }}>
    <ListItem
      style={{ fontSize: '10px', padding: '4px 8px', margin: 0 }}
      onClick={() => {
        setOpen(!openn);
        handleClose();
      }}
    >
      Delete post
    </ListItem>
    <ListItem
      style={{ fontSize: '10px', padding: '4px 8px', margin: 0 }}
      onClick={() => {
        callback(data, image);
        handleClose();
        console.log(data);
      }}
    >
      Edit post
    </ListItem>
  </List>
</Popover>


      </Stack>
      {/*
      TODO: Image OR video container
      */}
      {
        loading ? <Skeleton variant="rectangular" sx={{ width: '100%', height: 300, }} /> :
          post?.type !== '' && post?.type === 'image' ? <img src={image} style={{ width: '100%', maxHeight: 300, objectFit: 'cover' }} onClick={()=>{setOpenImage(true)}} alt="none" /> :
            post?.type !== '' && post?.type === 'video' && <video src={video} alt={post?.text} controls style={{ objectFit: "cover", width: '100%' }} height={300} />

      }
      {post?.type === "" &&
                  <LinkWithThumbnail data={data}/>
                  }
      {/*
      TODO: Tags and text container
      */}
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={1.5}
        sx={{ px: data?.reposterData ? 2.5 : 2.5,py:1  }}
      >
        <Typography  style={{fontSize:"12px",marginLeft:"-2%"}} id={post?.id}
          sx={{
            whiteSpace: 'pre-line'
          }}>
          <ItemText limit={400} text={post?.text}></ItemText>

        </Typography>
        {
          post?.tags && post?.tags?.length > 0 &&
          <Box sx={{ width: '100%'}}>
            {


              post?.tags?.map(
                (t) =>
                (<Chip variant="contained"
                  sx={{
                    backgroundColor: '#E0E3FF',
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginBottom: "10px",
                    maxWidth: 250,
                    fontSize:"12px",
                    
                  }} component={"p"} label={t} />))


            }
            {/* <p>{chosenEmoji.emoji}</p> */}

            {

              /*<Box
              sx={{
                py:2, px: 3, mr: 1, display: 'inline-block', backgroundColor:'#E0E3FF', borderRadius:'15px'}}
            >
            </Box>*/}
          </Box>

        }

      </Stack>
      {
        post?.type === 'file' &&
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ py: 2 }}
        >
          <InsertDriveFile sx={{ fontSize: 60 }} />
          <Typography variant={"caption"}>{post?.metadata?.name && fileNameOverflow(post?.metadata?.name)}</Typography>
          <IconButton onClick={() => DownloadFileHelper(post?.otherFiles)}>
            <DownloadOutlined />
          </IconButton>
        </Stack>
      }
      
      <Stack direction="row" spacing={2} alignItems="center" >
  {post && (
    <LikesCountComponent
      data={post}
      key={'like-count' + post?.id}
      cb={(data) => setLikesCount(data)}
    />
  )}
  <div style={{ flex: 1 }}></div>
  {commentsCount > 0 && (
    <Typography
      color="#888888"
      noWrap
      onClick={() => setShow(true)}
      paragraph
      width={100}
      textAlign="right"
      style={{ marginRight: "2%", fontSize: "10px" }}
    >
      {`${commentsCount} Comments`}
    </Typography>
  )}
</Stack>
      <Stack direction={"row"} sx={{ width: '100%', borderTop: '0.5px solid #8F8F8F', borderBottom: show ? '0.5px solid #8F8F8F' : 'none' }}>
        {post && <LikeMenu data={post} key={'menu-like' + post?.id} likesCount={likesCount} />}

        <Button color={'secondary'} variant={"text"} onClick={() => setShow(!show)} startIcon={<CommentIcon colorIcon={'#8F8F8F'} />} sx={{ flex: 1, borderRight: '0.5px solid #8F8F8F', color: '#8F8F8F', borderRadius: '0px', fontSize: "10px" }}>
          Comment
        </Button>
        <Button color={'secondary'} startIcon={<RePostIcon colorIcon={'#8F8F8F'} />} variant={"text"} sx={{ flex: 1, borderRadius: '0px', color: '#8F8F8F', borderBottomRightRadius: '15px', fontSize: "10px" }}
          onClick={() => { setRePost({ ...post, avatar: latestAvatar, nameOP: name, reposterData: profile }); setOpenRepost(true); }}
        >
          Repost
        </Button>

      </Stack>
      {post && <PostComments data={post} key={'comment-' + post?.id}
        countCallback={(count) => {
          setCommentsCount(count)
          setShow(count > 0)
        }}
        show={show}
        media={post?.type === 'image' ? image : post?.type === 'video' && video}

      />}


      {/* delete post portal */}
      <Portal>
        <Dialog
          open={openn}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"sm"}>
          <DialogContent
            sx={{  padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end">
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              spacing={2}>
              <Stack
                direction={"column"}
                sx={{ width: "100%", }} alignItems={"center"}>
                <img src={"/assets/delete-confirmation.png"} height={74} width={80} alt="none"></img>
              </Stack>
              <Typography
                component="span"
                variant="h6"
                color="text.primary"
                sx={{
                  textAlign: "center",
                  flex: 1,
                  padding: 0,
                  fontWeight: 500,
                }}>
                Delete Post
              </Typography>
              <Typography variant={"body2"} sx={{ textAlign: 'center' }}>
                &nbsp;&nbsp;Are you sure you want to delete this post?&nbsp;&nbsp;<br />
                This action cannot be undone.
              </Typography>
              <br />
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="center" spacing={2}>
                <Button
                  variant={"outlined"}
                  sx={{ px: 5, width: "100px", height: "40px", fontSize: "12px", fontWeight: "500" }}
                  onClick={() => {
                    setOpen(false)
                  }}>
                  No
                </Button>
                <Button
                  variant={"contained"}
                  sx={{ px: 5, width: "100px", height: "40px", fontSize: "12px", fontWeight: "500" }}
                  onClick={() => {
                    /*  remove()*/
                    setOpen(false)
                    const timelineRef = doc(DB, "timelines", data?.id);
                    deleteDoc(timelineRef).then((doc) => console.log(doc))
                  }}>
                  yes
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>


      {/* repost portal */}
      <Dialog
        
        aria-modal='true'
          open={openRepost}
          // onClose={() => {
          //   // setOpenRepost(false);
          //   resetAll();
          // }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={'sm'}
          sx={{"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#FFFFFF",
          },}}
          PaperProps={{
            style: {
              width: 600, // Customize the maximum width of the dialog content
              maxHeight: 600, // Customize the maximum height of the dialog content
              margin: '0 auto', // Center the dialog horizontally
            },
          }}
          >
            {/* {console.log(openRepost,caption)} */}
          <DialogTitle>
            <Stack
              direction={"row"}
              justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} spacing={3} justifyContent="flex-start"
							alignItems="center"
							sx={{ pt: 0, pb: 0, px: 0 }}>
                <Avatar
								sx={{ height: 50, width: 50, bgcolor: profile?.photoURL ? 'transparent' :  stringToColor(profile?.displayName), fontSize:"1.5rem"}} {...stringAvatar(profile?.displayName)}
								imgProps={{ sx: { objectFit: "cover" } }}
								src={
									profile?.photoURL || profile?.startup?.founder?.photoURL
								}>
									<Typography variant={"h5"}>
										{ profile.userType === 'startup' ? profile?.startup?.founder?.founderName?.charAt(0) || profile?.displayName?.charAt(0) : profile?.displayName?.charAt(0)}
									</Typography>
							</Avatar>
					
              <Typography
                component="span"
                sx={{fontWeight:500, fontSize:16}}
                color="text.primary">
                {" "}
                {profile.userType === 'startup' ? profile?.startup?.founder?.founderName || profile?.displayName : profile?.displayName}

              </Typography>
            </Stack>
            <Stack>

								<IconButton
									onClick={() => {setOpenRepost(false)
									}}>
									<CloseIcon />
								</IconButton>
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent 
          sx={{padding:0}}
          >
            <Stack style={{fontSize:14}} mb={2}  paddingLeft="30px" >
            <ResizableTextarea placeholder="What do you want to talk about?" 
            value={caption}
            onChange={(e)=>setCaption(e.target.value)}/>
            </Stack>
            <Stack sx={{
              // boxShadow: "0px 0px 9.56638px -1.47175px rgba(0, 0, 0, 0.15)", 
              outline:"0.5px solid #d2d2d2",
              // borderRadius:"15px"
            }}>


            <Stack direction={'column'} spacing={2} sx={{ mt: 4, }}>

            <Stack direction={'row'} spacing={3} pl={3}>
            <Avatar sx={{height: 50, width: 50, bgcolor: rePost?.avatar ? 'transparent' : stringToColor(rePost?.nameOP || profile?.displayName), fontSize:"1.5rem"}} 
								imgProps={{ sx: { objectFit: "cover" } }}
								src={rePost?.avatar || null}
                  >
									<Typography variant={"h5"}>
										{ rePost?.nameOP.charAt(0)}
									</Typography>
							</Avatar>
              <Stack direction={'column'}>

              <Typography
                component="span"
                sx={{fontWeight:500, fontSize:16}}
                color="text.primary">
                {" "}
                { rePost?.nameOP || null}

              </Typography>
              <Typography sx={{fontSize:12}} textTransform={'capitalize'}>
                {rePost?.creatorData?.userType}
              </Typography>
              </Stack>
              
            </Stack>
            <Stack sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            {
              loading ? <Skeleton variant="rectangular" sx={{width: '90%', height: 200,}} /> :
              rePost?.type !== '' && rePost?.type === 'image' ?  <img src={image} style={{width: '90%', maxHeight: 400, objectFit:'cover'}} alt="none"/> :
              rePost?.type !== '' && rePost?.type === 'video' &&  <video src={video} alt={rePost?.text} controls style={{objectFit: "cover",width: '90%'}} height={500}/>
              
            }
            </Stack>
            </Stack>
            <Stack
                direction="column"
                justifyContent="center"
                spacing={2}
                sx={{px: 4, py:2}}
              >

              {rePost !== null &&
                <Typography color={'secondary'} id={rePost?.id} fontSize={14} fontWeight={400}
                  sx={{
                    whiteSpace: 'pre-line'
                  }}>

                  <ItemText limit={400} text={rePost?.text}></ItemText>

                </Typography>
              }
              {
                rePost?.tags && rePost?.tags?.length > 0 &&
                <Box sx={{ width: '100%' }}>
                  {


                        rePost?.tags?.map(
                          (t) =>
                          ( <Chip variant="contained"
                                                      sx={{
                                                        backgroundColor:'#E0E3FF',
                                                        marginLeft:"5px",
                                                        marginRight:"5px",
                                                        marginBottom:"10px",
                                                        maxWidth: 250
                                                      }} component={"p"}  label={t}/>) )
                      }
                      </Box>
                      }
                
               
                {
                  rePost?.type === 'file' &&
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{py:2}}
                  >
                    <InsertDriveFile sx={{fontSize:60}}/>
                    <Typography variant={"caption"}>{rePost?.metadata?.name}</Typography>
                    <IconButton onClick={() => DownloadFileHelper(rePost?.otherFiles)}>
                      <DownloadOutlined />
                    </IconButton>
                  </Stack>
                }
                {post?.type === "" &&
                  <LinkWithThumbnail data={data}/>
                  }
                

              </Stack>
              
              </Stack>
          </DialogContent>
          <hr style={{opacity:0.3, width:"100%"}}/>
          <DialogActions sx={{ px: 2}}>
						<Tooltip
							title={<h3 style={{ color: '#E4EAF5' }}>Coming soon</h3>}
							placement="bottom"
						>
						<IconButton onClick={() => {}}>
							<SentimentSatisfiedAlt style={{width:"20px"}} 
							/>
						</IconButton>
						</Tooltip>

         

          {/* {state?	<Picker onEmojiClick={(emojiData, event)=> setChosenEmoji(emojiData) && setState(false)} disableAutoFocus={true} native />:null}
						{chosenEmoji? <p>Symbol: {chosenEmoji.emoji}</p>:null} */}
						<Box sx={{ flex: 1 }} />
            
            {/* <FormGroup>
              <FormControlLabel control={<Checkbox  inputProps={{ 'aria-label': 'controlled' }} />} label='Share on LinkedIn' />
            </FormGroup> */}


          <Button
            sx={{ paddingX: 3, paddingY: 1 }}
            variant={"contained"}
            disabled={inProgress}
            onClick={async () => {
              setInProgress(true);
              await addDoc(
                collection(DB, "timelines"),
                {
                  current: { likes: 0, comments: 0 },
                  previous: { likes: 0, comments: 0 },
                  text: rePost.text,
                  uid: profile?.uid,
                  creatorData: rePost.creatorData,
                  createdAt: serverTimestamp(),
                  type: rePost.type,
                  metadata: rePost.metadata,
                  reposterData: rePost.reposterData,
                  repostText: caption,
                  image: rePost.image || "",
									video: rePost.video || "",
									otherFiles: rePost.otherFiles || "",
									tags: rePost.tags,
                  opid : data?.opid ? data?.opid : data?.id,
              })
              .then(()=>{
                // PostNotification({
                //   type: 'post-share',
                //   imageURL: profile?.photoURL,
                //   message: '',
                //   displayName: profile?.displayName,
                //   uid: data?.uid,
                //   postId: data?.id
                // })
                setInProgress(false);
                setRePost(null);
                setCaption("");
                setOpenRepost(false);
              })
              }
            }
          >
            POST
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openImage} PaperProps={{
          style: {
            width: 900, // Customize the maximum width of the dialog content
            maxHeight: 900, // Customize the maximum height of the dialog content
            margin: '0 auto', // Center the dialog horizontally
          },
        }}  maxWidth={'md'}>
        <DialogContent>
          <Stack direction={'column'}>
            <Stack alignItems={'flex-end'}>
            <IconButton onClick={()=>setOpenImage(false)}>
            <CloseIcon/>
          </IconButton>
            </Stack>
            <img src={image} alt="none"/>

          </Stack>
        </DialogContent>

      </Dialog>      

    </Stack>
  )


}

