import React from "react";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField, useMediaQuery, CircularProgress
} from "@mui/material";
import {useFormik} from "formik";
import * as yup from 'yup';

export default function EditInvestorOrganizationOtherForm({ cbBack, cbNext, data, isLoading }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const formikFirst = useFormik({
    initialValues: data,
    validationSchema: yup.object({
      investorType: yup
        .string("Investor type")
        .required("Please select investor type."),
      website: yup.string("Website").url('Please enter valid website url'),
    }),
    onSubmit: async (values) => {
      debugger
      cbNext({
        ...values,
      });
    }
  });
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>

          <TextField
            name={"investorType"}
            value={formikFirst.values.investorType}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.investorType &&
              Boolean(formikFirst.errors.investorType)
            }
            helperText={
              formikFirst.touched.investorType &&
              formikFirst.errors.investorType
            }
            label={"Investor type *"}
            select
            sx={{ textAlign: "left" }}
          >
            <MenuItem value={"Venture Capital Fund"}>
              Venture Capital Fund
            </MenuItem>
            <MenuItem value={"CVC"}>CVC</MenuItem>
            <MenuItem value={"PE Fund"}>PE Fund</MenuItem>
            <MenuItem value={"Family Office"}>Family Office</MenuItem>
          </TextField>

          <TextField
            name={"website"}
            value={formikFirst.values.website}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.website &&
              Boolean(formikFirst.errors.website)
            }
            helperText={
              formikFirst.touched.website && formikFirst.errors.website
            }
            label={"Website"}
          />


        </Stack>
      </Container>
      {
        matches ? <Stack
          direction={"row"}
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          width={"100%"}>
          <Button
            variant={"outlined"}
            color={"primary"}
            sx={{ px: 5 }}
            onClick={cbBack}>
            Back
          </Button>
          {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{ px: 5 }}
            type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
            save
          </Button>
        </Stack> :
          <Stack
            direction={"row"}
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
            width={"100%"}
            sx={{px: !matches ? 2 : ''}}
          >
            {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{ px: 5 }}
              type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
              save
            </Button>
          </Stack>
      }


    </Stack>
  );
}
