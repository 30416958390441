import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { AuthUserContext } from "../../../providers/auth-provider";
import { useLocation, useNavigate } from "react-router-dom";
import ExpertCard from "./best-match-card";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GetAdminSuuggestedProfilesForAdmin from "../../../api/get-all-admin-suggest-for-admin";
import { SUGGESTED_STATUS } from "../../../utils/enums/suggestions-state";
export const BestMatchesList = () => {
  const [search, setSearch] = React.useState("");
  const [lastDoc, setLastDoc] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { profile } = React.useContext(AuthUserContext);
  const location = useLocation();
  const navigator = useNavigate();
  const [suggestedProfiles, setSuggestedProfiles] = React.useState();
  const { request, members } = location?.state;
  React.useEffect(() => {
    const fetchProfileMatchesData = async () => {
      const data = await GetAdminSuuggestedProfilesForAdmin(
        request?.id,
        request?.creatorData?.uid
      );
      setSuggestedProfiles(data);
    };
    fetchProfileMatchesData().catch(console.error);
  }, []);
  let filteredSearch;
  const MemberSearch = ({ array }) => {
    const { profile } = React.useContext(AuthUserContext);
    //   const [search, setSearch] =React.useState('')
    filteredSearch = array
      .filter((member) => {
        if (search !== "") {
          const reg = new RegExp(search.toLowerCase());
          return reg.test(member?.displayName?.toLowerCase()) && member;
        } else {
          return member;
        }
      })
      .sort((a, b) => b?.createdAt - a?.createdAt);
    if (filteredSearch.length === 0) {
      return (
        <Stack
          direction={"row"}
          sx={{ width: "100%" }}
          py={5}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4" mt={10}>
            No results
          </Typography>
        </Stack>
      );
    } else {
      return filteredSearch.map(
        (record) =>
          record?.requestsSuggested?.status !== SUGGESTED_STATUS.DECLINED &&
          request.creatorData.uid != record.uid && (
            <ExpertCard
              data={record}
              key={record?.uid}
              request={request}
              href={`/${profile?.userType}/user/details/${record?.uid}`}
            />
          )
      );
    }
  };

  return (
    <>
      <Container maxWidth={"xl"} sx={{ my: 5, textAlign: "left" }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}
        >
          <IconButton onClick={() => navigator(-1)}>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography variant={"h5"} sx={{ textAlign: "left", lineHeight: 1 }}>
            Best Matches
          </Typography>
          <Box flex={1}></Box>
          <Button
            sx={{ borderRadius: "6px" }}
            variant={"contained"}
            color={"primary"}
            onClick={() =>
              navigator(`/${profile?.userType}/request/suggest/user/list`, {
                state: { request: request },
              })
            }
          >
            + Add More Suggestions
          </Button>
        </Stack>
      </Container>
      <Container maxWidth={"lg"} sx={{ my: 5, textAlign: "left" }}>
        <Grid container spacing={5}>
          {members && members.length > 0 && <MemberSearch array={members} />}
        </Grid>
        {loading && (
          <Grid container spacing={5} my={5}>
            <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
          </Grid>
        )}

        {lastDoc && (
          <Stack
            direction={"row"}
            sx={{ width: "100%" }}
            py={5}
            justifyContent="center"
            alignItems="center"
          >
            <Button size={"small"}>Load more</Button>
          </Stack>
        )}
      </Container>

      <Container maxWidth={"xl"} sx={{ my: 5, textAlign: "left" }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}
        >
         <IconButton onClick={() => navigator(-1)}>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography variant={"h5"} sx={{ textAlign: "left", lineHeight: 1 }}>
            Suggested by Admin
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={"lg"} sx={{ my: 5, textAlign: "left" }}>
        <Grid container spacing={5}>
          {suggestedProfiles && suggestedProfiles.length > 0 ? (
            <MemberSearch array={suggestedProfiles} />
          ) : (
            <Stack direction={"row"} sx={{ mx: 5, my: 2, textAlign: "left" }}>
              <Typography
                variant={"h6"}
                alignItems={"center"}
                justifyContent={"center"}
                fullWidht={true}
              >
                No users suggested by admin yet!
              </Typography>
            </Stack>
          )}
        </Grid>
        {loading && (
          <Grid container spacing={5} my={5}>
            <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
              <Skeleton
                variant="rectangular"
                width={357}
                sx={{ borderRadius: "12px" }}
                height={348}
              />
            </Grid>
          </Grid>
        )}

        {lastDoc && (
          <Stack
            direction={"row"}
            sx={{ width: "100%" }}
            py={5}
            justifyContent="center"
            alignItems="center"
          >
            <Button size={"small"}>Load more</Button>
          </Stack>
        )}
      </Container>
    </>
  );
};
