import React from "react";
import {Stack, Typography} from "@mui/material";

export default function TalentDescription({profile}) {

  return (
    <>
      <Stack direction={'row'} spacing={1}  sx={{ position: 'relative',}}>
          {profile.countryState ? <Typography variant={'body2'} color={"grey"}>
              {profile.countryState}
          </Typography> : null}
      </Stack>

      {profile?.talent && profile?.talent.description &&
        <Typography variant={'body1'} color={'secondary'} sx={{width: 'calc(100% - 10%)'}}>

        {profile?.talent.description}
      </Typography>}



    </>
  )
}