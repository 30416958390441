import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton, Portal, useMediaQuery} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import AboutEditFormExpert from "../../../../../screens/expert/edit/about-edit-form";
import AboutEditStartupForm from "../../../../../screens/startups/edit/about-edit-startup-form";
import EditStartupFounderDetailsForm from "../../../../../screens/startups/edit/edit-startup-founder-details-form";
import {useNavigate} from "react-router-dom";
import EditStartupFounderAboutDetailsForm
  from "../../../../../screens/startups/edit/edit-startup-founder-about-details-form";
import {ArrowBack} from "@mui/icons-material";
import PostNotification from "../../../../../api/post-notification";

export default function AboutStartupFounderComponent({profile,open, handleClose, cb }) {
  const regexAdmin = /admin\/startup\/startup-founder-profile/

  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  
  const copyProfile = regexAdmin.test(window.location.pathname) ? profile : {...context.profile}
  
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? profile.uid : user.uid);

  const navigator = useNavigate()
    const [isLoading, setLoading] = React.useState(false);
  return (
    <Portal>
        <Dialog
            fullScreen={!matches ? true: false}
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
                {!matches && <IconButton onClick={() => handleClose()}>
                    <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
                </IconButton>}
                {"Edit About Founder"}
            </DialogTitle>
            <DialogContent sx={{px: !matches ? 0 : ''}}>
          <br/>
          <EditStartupFounderAboutDetailsForm
            data={{
              website: profile?.startup?.founder?.website || '',
              linkedIn: profile?.startup?.founder?.linkedIn || '',
              twitter: profile?.startup?.founder?.twitter || '',
              repeatFounder: profile?.startup?.founder?.repeatFounder || false,
            }}
            cbBack={handleClose}
            cbNext={async (data) => {
              await setDoc(
                userRef,
                {
                  startup: {...profile?.startup, founder: {...profile?.startup?.founder,...data }},
                updatedById:context?.user?.uid,
                updatedBy: regexAdmin.test(window.location.pathname) ? 'admin' : 'user',
                updatedAt: new Date().getTime()
                },
                { merge: true }
              )
              if (regexAdmin.test(window.location.pathname)) {
                PostNotification({
                  type: 'admin-update-profile',
                  imageURL: '/assets/system-noti-icon.png',
                  section: 'Founder',
                  message: `information has been updated by the Admin.`,
                  uid: profile.uid,
                  createdBy: user?.uid
    
                }).then()
                cb({...copyProfile, startup: {...profile?.startup, founder: {...profile?.startup?.founder,...data }}})
              } else {
                await setProfile({...copyProfile, startup: {...profile?.startup, founder: {...profile?.startup?.founder,...data }}})
                handleClose()
                navigator(`${!matches ? '/m':'' }/${profile?.userType}/startup-founder-profile`, {
                  state: {
                    profile: {...profile, startup: {...profile?.startup, founder: {...profile?.startup?.founder,...data }}},
                    founder: {...profile?.startup?.founder,...data }
                  }
                })
              }
             
            }}
            isLoading={isLoading}
          />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
