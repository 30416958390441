import React from "react";
import {IconButton, Stack, Typography} from "@mui/material";
import {EditSharp} from "@mui/icons-material";
import EditStartupDetails from "../../../../../components/common/profile/edit/startup/edit-startup-details";

export default function StartupDescription({profile}) {
    const [open,setOpen] = React.useState(false)
    const profileRegex = /profile/
  return (
    <>

        <Stack direction={"row"} spacing={2}  justifyContent="flex-end"
               alignItems="center" sx={{width: '100%'}}>
            {profile.startup.location ? <Typography variant={'body2'} color={"grey"} sx={{flex: 1}}>
                {profile.startup.location}
            </Typography> : null}
            {profileRegex.test(window.location.pathname) &&  <IconButton onClick={() => setOpen(true)}>
                <EditSharp />
            </IconButton>}
        </Stack>

      {profile?.startup && profile?.startup.description &&
        <Typography variant={'body1'} color={'secondary'} sx={{width: 'calc(100% - 10%)', whiteSpace:"pre-line"}}>

        {profile?.startup.description}
      </Typography>}


        <EditStartupDetails profile={profile} open={open} handleClose={() => setOpen(false)}/>
    </>
  )
}