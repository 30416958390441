import * as yup from 'yup';

const ExperiencesFormSchema = yup.object({
  jobTitle: yup.string('Job title').required('Job title is required.'),
  expertise : yup.string('expertise').required("expertise is Required")

});
const initialValues = {
  jobTitle: '',
  expertise: '',
};

const ExperiencesFormValidation = {
  initialValues,
  schema : ExperiencesFormSchema
};

export default  ExperiencesFormValidation;