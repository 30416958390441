import React, { useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage, FieldArray, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { getFieldErrorNames } from './get-field-errors-name'
export const ScrollToFieldError = () => {
    const { submitCount, isValid, errors } = useFormikContext()
  
    React.useEffect(() => {
      if (isValid) return
  
      const fieldErrorNames = getFieldErrorNames(errors)
      if (fieldErrorNames.length <= 0) return
  
      const element = document.querySelector(
        `input[name='${fieldErrorNames[0]}']`
      )
      if (!element) return
  
      // Scroll to first known error into view
      element.scrollIntoView({ behavior: "smooth", block: "start" })
    }, [submitCount]) // eslint-disable-line react-hooks/exhaustive-deps
  
    return null
  }