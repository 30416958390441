import axios from "axios";


export default function GetCitiesData() {

  return axios.get(
    '/assets/locations.json',
    {headers: {'Content-Type': 'application/json'}}
  )

}
