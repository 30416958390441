import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  doc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { AuthUserContext } from "../../../../../providers/auth-provider";
import { DB } from "../../../../../config/firebase/firebase";
import RoomListItem from "./room-list-item";
import TextMessageComponent from "./message/TextMessageComponent";
import FileMessageComponent from "./message/FileMessageComponent";
import ImageMessageComponent from "./message/ImageMessageComponent";
import SystemGeneratedMessageComponent from "../../../../../components/common/chat/message/SystemGeneratedMessageComponent";
import ContractDocumentComponent from "../../../../../components/common/chat/message/ContractDocumentComponent";
// DoneAllOutlinedIcon

export default function MessageList({ roomId, callback, callbackSignContract }) {
  const { profile } = React.useContext(AuthUserContext);
  const [messages, setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (roomId) {
      // debugger
      setLoading(true);
      const q = query(
        collection(DB, "messages"),
        orderBy("createdAt", "desc"),
        where("roomId", "==", roomId)
      );

      const unSubs = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.size > 0) {
          const list = [];
          querySnapshot.forEach((document) => {
            list.push({ id: document.id, ...document.data() });
          });
          setMessages(list);
          debugger;
          callback(list);
        }
      });
      return () => unSubs;
    }
  }, [roomId]);
  return (
    <Stack
      direction={"column-reverse"}
      spacing={0.5}
      sx={{
        width: "100%",
        height: "calc(100vh - 290px)",
        p: 2,
        overflowY: "scroll",
        backgroundColor: "#fff",
      }}
      id={"msg-container"}
    >
      {messages.length > 0 &&
        messages.map((msg, i) => {
          if (msg?.type === "system-generated") {
            return <SystemGeneratedMessageComponent msg={msg} key={i} />;
          } else if (msg?.type === "text") {
            if (msg?.sender === profile.uid) {
              return <TextMessageComponent key={i} msg={msg} type={"r"} />;
            } else return <TextMessageComponent key={i} msg={msg} type={"l"} />;
          } else if (msg?.type === "document") {
            if (msg?.sender === profile.uid) {
              return <FileMessageComponent key={i} msg={msg} type={"r"} />;
            } else return <FileMessageComponent key={i} msg={msg} type={"l"} />;
          } else if (msg?.type === "contract-document" || msg?.type === "contract-document-signed") {
            if (msg?.sender === profile.uid) {
              return <ContractDocumentComponent mobile={true} key={i} msg={msg} type={"r"} />;
            } else return <ContractDocumentComponent key={i} mobile={true} msg={msg} type={"l"} callback={(bool) => callbackSignContract(bool)}/>;
          } else if (msg?.type === "media") {
            if (msg?.sender === profile.uid) {
              return <ImageMessageComponent key={i} msg={msg} type={"r"} />;
            } else
              return <ImageMessageComponent key={i} msg={msg} type={"l"} />;
          }
        })}
    </Stack>
  );
}
