import React from "react";
import {IconButton, Stack, Typography} from "@mui/material";
import {EditSharp} from "@mui/icons-material";
import EditBusinessDeveloperDetails
  from "../../../../../components/common/profile/edit/business-developer/edit-business-developer-details";

export default function BusinessDeveloperDescription({profile}) {
  const [open,setOpen] = React.useState(false)
  const profileRegex = /profile/
  return (
    <>
      <Stack direction={"row"} spacing={2}  justifyContent="flex-end"
             alignItems="center" sx={{width: '100%'}}>
        {profile.countryState ? <Typography variant={'body2'} color={"grey"} sx={{flex: 1}}>
          {profile.countryState}
        </Typography> : null}
        {profileRegex.test(window.location.pathname) &&  <IconButton onClick={() => setOpen(true)}>
          <EditSharp />
        </IconButton>}
      </Stack>

      {profile?.businessDeveloper && profile?.businessDeveloper.description &&
        <Typography variant={'body1'} color={'secondary'} sx={{width: 'calc(100% - 10%)', fontSize:'12px'}}>

        {profile?.businessDeveloper.description}
      </Typography>}

      <EditBusinessDeveloperDetails profile={profile} open={open} handleClose={() => setOpen(false)}/>


    </>
  )
}

// import React from "react";
// import {Stack, Typography} from "@mui/material";

// export default function BusinessDeveloperDescription({profile}) {

//   return (
//     <>
//       <Stack direction={'row'} spacing={1}  sx={{ position: 'relative'}}>
//           {profile.countryState ? <Typography variant={'body2'} color={"grey"}>
//               {profile.countryState}
//           </Typography> : null}
//       </Stack>

//       {profile?.businessDeveloper && profile?.businessDeveloper.description &&
//         <Typography variant={'body1'} color={'secondary'} sx={{width: 'calc(100% - 10%)'}}>

//         {profile?.businessDeveloper.description}
//       </Typography>}



//     </>
//   )
// }