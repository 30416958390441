import React, { useEffect } from "react";
import {
  
  serverTimestamp,
  addDoc,
  collection, deleteDoc, doc,
} from "firebase/firestore";
import { getIdToken} from "firebase/auth";
import {
  Container,
  Stack,
  Typography,
  IconButton, Backdrop,CircularProgress
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import StartupProfileFirstForm from "./../../startups/startup-profile-first-form";
import StartupProfileSecondForm from "./../../startups/startup-profile-second-form";

import FounderProfileFirstForm from "./../../startups/founder-profile-first-form";
import { DB } from "../../../config/firebase/firebase";
import TopPriorities from "./../../startups/top-priorities";
import InvestorDetails from "./../../expert/onboarding-forms/investor-details";
import InvestorThirdForm from "./../../investor/onboarding-forms/investor-third-form";
import InvestorFourForm from "./../../investor/onboarding-forms/investor-four-form";
import FounderCard from "./founder-card";
import PostProfileSuccessDialog from "./post-profile-success-dialog";
import AdminPostOnboardProfileData from "../../../api/admin-post-onboard-profile-data";
import SaveAsDraft from "../../../api/save-as-draft";
export default function FillStartupProfile() {
  const { user, setProfile } = React.useContext(AuthUserContext);
  const {state} = useLocation()
  const {uid} = useParams()
  
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [steps, setSteps] = React.useState(0);
  const [formFirstData, setFormFistData] = React.useState();
  const [formSecondData, setFormSecondData] = React.useState();
  const [formThirdData, setFormThirdData] = React.useState();
  const [formEightData, setFormEightData] = React.useState();
  const [formNineData, setFormNineData] = React.useState();
  const [formTenData, setFormTenData] = React.useState();
  
  
  
  
  
  const [lookingForFundingSecondData, setLookingForFundingSecondData] =
    React.useState();
  const [founderProfileFirstData, setFounderProfileFirstData] =
    React.useState();
  const navigator = useNavigate();
  const startupPost = (startup) => {
    return addDoc(collection(DB, "startups"), {
      ...startup,
      uid: user?.uid,
      updatedAt: serverTimestamp(),
      createdAt: serverTimestamp(),
    });
  };
  const submitData = async (values) => {
    debugger
    try {
      setLoading(true)
      const copyValue =
        {
          ...values, investorDetails: {...values?.investorDetails,portfolio: []
          }
        }
      const {linkedinURL} = state.contactData
      const startup = {
        ...copyValue,
        teamMembers: [],
        linkedinURL
      };
      
      const postData = {
        createdById:user?.uid,
        createdBy:'admin',
        type: state?.type,
        ...state.contactData,
        photoURL: values?.founder?.photoURL
          ? values?.founder?.photoURL
          : "",
        startup,
        onboard: true,
        profileStatus: 'complete',
        remainderStatus:'',
        searchName: state.contactData?.displayName.toLowerCase()
      }
      const idToken = await getIdToken(user);
      await AdminPostOnboardProfileData(postData, idToken)
      setLoading(false)
      setOpen(true)
  
    } catch (e) {
      setLoading(false)
  
    }
    
  }
  
  return (
    <>
      <Container maxWidth={"full-width"} sx={{ marginY: 5 }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}>
          <IconButton
            onClick={() =>
              steps === 0
                ? navigator(uid ? `/admin/on-boarding/contact-info/${uid}`:'/admin/on-boarding/contact-info', {state})
                :steps === 8 &&  !formEightData?.areYouAnAngelInvestor ? setSteps(steps - 3):  steps === 3 ? setSteps(steps - 2) : setSteps(steps - 1)
            }>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography
            variant={"h5"}
            sx={{
              textAlign: "left",
              lineHeight: 1,
              fontWeight: 600,
            }}>
            {(steps < 4 || steps === 6 || steps === 7) && 'Startups'}
            {(steps === 4 || steps === 5  || steps === 10 || steps === 11 ) && 'Founder'}
            {(steps === 8 ) && 'Final step'}
            { steps===12 && 'Investment Portfolio'}
            
            
            <br />
            <small style={{ fontSize: 14, color: "#8F8F8F" }}>
              {
                steps < 2 && 'Startup profile'
              }
              {
                steps === 2 && 'Team'
              }
              {
                steps === 3 && 'Expectations'
              }
              {
                (steps === 4 ) && 'Founder profile'
              }
              {
                //(steps === 5 || steps === 6) && 'Team members'
              }
              {
                steps === 5 && 'Founder profile'
              }
              {
                (steps === 6 || steps === 7)&& 'Your details an Investor'
              }
              {
                steps === 8 && 'Send profile details via email'
              }
              {
                steps === 9 && 'Add portfolio'
              }
            </small>
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={"lg"} sx={{ mb: 5 }}>
        {steps === 0 && (
          <StartupProfileFirstForm
            data={formFirstData}
            cbBack={() => {
              navigator(uid ? `/admin/on-boarding/contact-info/${uid}`:'/admin/on-boarding/contact-info', {state})
            }}
            cbNext={(data) => {
              setFormFistData({ ...data });
              setSteps(steps + 1);
              debugger
              uid && SaveAsDraft({...state,formFirstData: data}, uid, user)
                .then()
            }}
            uid={uid}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
            }}
          />
        )}
        {steps === 1 && (
          <StartupProfileSecondForm
            data={formSecondData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormSecondData({ ...data });
              setSteps(steps + 2);
              uid && SaveAsDraft({...state,formFirstData, formSecondData: data}, uid, user)
                .then()
            }}
            uid={uid}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData, formSecondData: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
            }}
          />
        )}
        
     
        {steps === 3 && (
          <TopPriorities
            cbBack={() => setSteps(steps - 2)}
            cbNext={(data) => {
              setFormThirdData({ ...data });
              setSteps(
                steps + 1
              );
              uid && SaveAsDraft({...state, formFirstData, formSecondData, formThirdData: data}, uid, user)
                .then()
            }}
            uid={uid}
            cbDraft={(data) => {
              SaveAsDraft({...state, formFirstData, formSecondData, formThirdData: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
            }}
            data={formThirdData}
            
          
          />
        )}
        
   
        
        {steps === 4 && (
          <FounderProfileFirstForm
            data={founderProfileFirstData}
            startupName={formFirstData?.startupName}
            cbNext={(data) => {
              setFounderProfileFirstData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state, formFirstData, formSecondData, formThirdData, founderProfileFirstData: data}, uid, user)
                .then()
            }}
            uid={uid}
            cbDraft={(data) => {
              SaveAsDraft({...state, formFirstData, formSecondData, formThirdData, founderProfileFirstData: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
            }}
            cbBack={() => {
              setSteps(
                steps - 1
              );
            }}
          />
        )}
       
        {
          steps === 5 && (
            <InvestorDetails
              data={formEightData}
              cbBack={() => setSteps(steps - 1)}
              cbNext={(data) => {
                setFormEightData({ ...data });
                if (data?.areYouAnAngelInvestor === true) {
                  setSteps(steps + 1);
                } else {
                  setSteps(steps + 3);
  
                  /*submitData({
                    investorDetails: {
                      ...formEightData
                    },
                    founder:{
                      ...founderProfileFirstData,
                      //...founderProfileSecondData,
                    },
                    ...lookingForFundingSecondData,
                    ...formThirdData,
                    ...formFirstData,
                    ...formSecondData,
                    
                    
                    
                  }).then()*/
                }
  
                uid && SaveAsDraft({...state, formFirstData, formSecondData, formThirdData, founderProfileFirstData, investorFormOne: data}, uid, user)
                  .then()
              }}
              uid={uid}
              cbDraft={(data) => {
                SaveAsDraft({...state, formFirstData, formSecondData, formThirdData, founderProfileFirstData, investorFormOne: data}, uid, user)
                  .then(() => navigator('/admin/draft-profiles'))
              }}
            />)
        }
        {steps === 6 && (
          
          <InvestorThirdForm
            data={formNineData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormNineData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state, formFirstData, formSecondData, formThirdData, founderProfileFirstData,
                investorFormOne: formEightData,
                investorFormTwo: data
              }, uid, user)
                .then()
            }}
            uid={uid}
            cbDraft={(data) => {
              SaveAsDraft({...state, formFirstData, formSecondData, formThirdData, founderProfileFirstData,
                investorFormOne: formEightData,
                investorFormTwo: data
              }, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
            }}
          />
        )}
        {steps === 7 && (
          
          <InvestorFourForm
            data={formTenData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              debugger
              setFormTenData({ ...data });
              /*submitData({
                investorDetails: {
                  ...formEightData,
                  ...formNineData,
                  ...formTenData,
                },
                founder:{
                  ...founderProfileFirstData,
                  //...founderProfileSecondData,
                },
                ...lookingForFundingSecondData,
                ...formThirdData,
                ...formFirstData,
                ...formSecondData,
    
              }).then()*/
              setSteps(steps + 1);
              uid && SaveAsDraft({...state, formFirstData, formSecondData, formThirdData, founderProfileFirstData,
                investorFormOne: formEightData,
                investorFormTwo: formNineData,
                investorFormThree: data
              }, uid, user)
                .then()
            }}
            uid={uid}
            cbDraft={(data) => {
              SaveAsDraft({...state, formFirstData, formSecondData, formThirdData, founderProfileFirstData,
                investorFormOne: formEightData,
                investorFormTwo: formNineData,
                investorFormThree: data
              }, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
            }}
          />
        )}
  
        {steps === 8 && (<FounderCard cbBack={() => setSteps(formEightData?.areYouAnAngelInvestor ? steps-1 :steps -3)} send={() => {
          uid && deleteDoc(doc(DB, 'users', uid)).then();
  
          submitData({
                investorDetails: {
                  ...formEightData,
                  ...formNineData,
                  ...formTenData,
                },
                founder:{
                  ...founderProfileFirstData,
                  //...founderProfileSecondData,
                },
                ...lookingForFundingSecondData,
                ...formThirdData,
                ...formFirstData,
                ...formSecondData,
    
              }).then()
          
        }} data={
          {
            investorDetails: {
              ...formEightData,
              ...formNineData,
              ...formTenData,
            },
            founder:{
              ...founderProfileFirstData,
              //...founderProfileSecondData,
            },
            ...lookingForFundingSecondData,
            ...formThirdData,
            ...formFirstData,
            ...formSecondData,
          }
          
        }/>)}
      {/*  {/admin/.test(window.location.pathname) ? '' : <>
          {
            steps === 8 && (<PortfoliosListView
                cbBack={() => setSteps(steps - 1)}
                add={(data) => {
                  setPortfolioIndex();
                  setSteps(steps + 1);
                }}
                edit={(ind) => {
                  setPortfolioIndex(ind);
                  setSteps(steps + 1);
                }}
                remove={(ind) => {
                  debugger
                  const port = [...portfolios]
                  port.splice(ind, 1)
                  setPortfolios([...port])
                }}
                portfolios={portfolios}
                submit={() => {
                  submitData({
                    investorDetails: {
                      ...formEightData,
                      ...formNineData,
                      ...formTenData,
                    },
                    founder:{
                      ...founderProfileFirstData,
                      //...founderProfileSecondData,
                    },
                    ...lookingForFundingSecondData,
                    ...formThirdData,
                    ...formFirstData,
                    ...formSecondData,
            
                  }).then()
                  console.log('submit')
                }}
              />
            )
          }
  
          {steps === 9 && (
            <InvestorPortfolioForm
              cbBack={() => setSteps(steps - 1)}
              cbNext={(data, index) => {
                const port = [...portfolios]
                if (index !== undefined && index > -1) {
                  port.splice(index, 1, { ...data})
                } else {
                  port.push({ ...data})
                }
                setPortfolios([...port])
                setSteps(steps - 1);
                setPortfolioIndex(undefined);
        
              }}
              data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}
      
              index={portfolioIndex}
            />)
    
    
          }
        
        </>}*/}
  
        {
          open && <PostProfileSuccessDialog open={open} setOpen={(bool) => {
            
            setOpen(bool)
            navigator('/admin/dashboard')
          }} />
        }
        {
          loading &&
          <Backdrop  open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        
        
        
      </Container>
    </>
  );
}
