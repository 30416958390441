import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {DialogActions, DialogTitle, Grid, Stack, TextField, Typography} from "@mui/material";
import DragDropFiles, {DragDropItem} from "./drag-drop-files";
import {AuthUserContext} from "../../providers/auth-provider";
import {addDoc, collection, serverTimestamp} from "firebase/firestore";
import {DB} from "../../config/firebase/firebase";
import PostNotification from "../../api/post-notification";

export default function ProposalRequestDialog({request, open, cb}) {
  const {profile} = React.useContext(AuthUserContext);
  const [openSuccess, setOpenSuccess] = React.useState(false)
  
  const fileTypes = [
    "JPG",
    "JPEG",
    "PNG",
    "GIF",
    "DOC",
    "DOCX",
    "PDF",
    "MOV",
    "XLS",
    "CSV",
  ];
  const [files, setFiles] = React.useState( []);
  const [text, setText] = React.useState( '');
  
  
  const submitProposal = async () => {
    if (text !== '' && files.length > 0) {
      const attachments = [];
      for (let file of files) {
        const path = `applicants/${request.id}/${profile?.displayName.replaceAll(' ', '-')}/attachments/${file.file.name}`
        attachments.push(path)
      }
      debugger
     await addDoc(collection(DB, "applicants"), {
       additionalNotes: text,
       attachments,
       applicantUid: profile?.uid,
       requestId: request?.id,
       requestCreatorUid: request?.uid,
       updatedAt: serverTimestamp(),
       createdAt: serverTimestamp(),
      });
      PostNotification({
        type: 'apply-applicant',
        imageURL: '/assets/system-proposal.png',
        // message: `Your Application is Denied from ${profile?.displayName}`,
        message: `Your have received a proposal from ${profile?.displayName}`,
        uid: request?.uid,
        requestFor: request?.requestFor,
        request: request?.id
      }).then()
    }
    setFiles([])
    setText('')
    cb(false)
    setOpenSuccess(true)
  }
  
  /*{`applicants/${request.id}/${profile?.displayName.replaceAll(' ', '-')}/attachments/${file.file.name}`}*/
  
  const handleClose = () => {
    setFiles([])
    setText('')
    cb(false);
  };
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent>
          <Stack direction={"column"} spacing={2} p={5}>
            <Stack direction={"column"} spacing={2}>
              <Typography sx={{ textAlign: "left", fontSize:24 }} fontWeight={400}>
                Attachments{" "}
                <font style={{ fontWeight: 300 }}>
                  <i> (References, Recommendations etc.)</i>
                </font>
              </Typography>
          
              <DragDropFiles
                label={{
                  text: "Drop here or choose from files",
                  caption:
                    "(max file size: 50MB)",
                }}
                multiple={true}
                limit={50}
                types={fileTypes}
                onChangeCallback={(fileList) => {
                  let list = [];
                  if (files.length > 0) {
                    list = [...files];
                  }
                  for (let file of fileList) {
                    list.push({ file });
                  }
                  setFiles([...list]);
                }}
              />
              <span />
              <Grid container
                    gap={2}>
                {files.length > 0 &&
                  files.map((file, i) => (
                    <Grid sx={{width:'48.5%'}}>
                      <DragDropItem
                        file={file}
                        i={i}
                        key={i}
                        path={`applicants/${request.id}/${profile?.displayName.replaceAll(' ', '-')}/attachments/${file.file.name}`}
                        remove={(i) => {
                          setFiles([...files.filter((d, ind) => ind !== i)])
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Stack>
            <Stack direction={"column"} spacing={2}>
              <Typography sx={{ textAlign: "left", fontSize:24 }} fontWeight={400}>
                Additional Information
              </Typography>
              <TextField multiline rows={4} variant={'outlined'} inputProps={{style: {backgroundColor:'#F6F8FA'}}} sx={{backgroundColor:'#F6F8FA'}} name={'additional-information'} fullWidth
                         value={text}
                         onChange={(e) => setText(e.target.value) }
          
          
              />
            </Stack>
            <Stack/>
            <Stack/>
        
            <Stack direction={"row"} spacing={2}   justifyContent="flex-end"
                   alignItems="center">
              <Button onClick={handleClose} variant={"outlined"}>Cancel</Button>
              <Button onClick={submitProposal} variant={"contained"}  disabled={!(text !== '') || files.length === 0}>Submit</Button>
            </Stack>
      
      
          </Stack>
    
    
    
        </DialogContent>
      </Dialog>
      
      {/*
     Successfully Applied
      */}
  
      <Dialog
        open={openSuccess}
        onClose={() => {
          setOpenSuccess(false)
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle sx={{boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', p:3,}} fontSize={20} fontWeight={500}>
          Successfully Applied
        </DialogTitle>
        <DialogContent>
          <Stack direction={"column"} alignItems={"center"} spacing={2} p={5}>
            <img src={'/assets/successfully-applied.png'} width={145} height={145}/>
            <Typography variant={"body1"} fontWeight={600} textAlign={"center"} >
              Congratulations on applying for {request?.searchFor.toString().replaceAll(',', ', ')}! We have received your application and are thrilled to review it. Good luck!
            </Typography>
          </Stack>
          
          
          
        </DialogContent>
        <DialogActions sx={{boxShadow:'0px -3px 4px rgba(0, 0, 0, 0.1)', p: 3}} >
          <Button variant={"contained"} onClick={() => {
            setOpenSuccess(false)
          }}>Continue</Button>
        </DialogActions>
      </Dialog>
      
    </>
  );
}
