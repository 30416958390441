import React from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import {Stack, Avatar, Button, Box, Typography,Portal,Dialog, DialogContent} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function PortfolioCard({data, i, edit, remove}) {
  const [open, setOpen] = React.useState(false);
  function currencyToSymbol(currency){
    if(currency == "USD"){
      return "$"
    }
    else if (currency == "GBP"){
      return "£"
    }
    else{
      return "€"
    }
  }
  return (
  <Grid 
  sx={{width: "100%",  backgroundColor: '#F6F8FA', border: '0.5px solid #000000', borderRadius: '5px', p:2, px: 2,
   marginBottom:"22px"
  }}>

    <Stack direction={'column'} spacing={.5} sx={{width:'100%', }} >
      <Stack direction={'row'} spacing={1} alignItems={"flex-start"} >
        <Avatar
          sx={{
            width: 50,
            height: 50,
            border: "1px solid #E0E3FF",
            position:
              "relative",
            top: -40,
          }}
          src={
            data?.photoURL
              ? data?.photoURL
              : "/assets/startup-placeholder-image.png"
          }/>

          {console.log('FIRST')}
        <Box sx={{flex:1,}}/>
        <Button variant={"text"} size={'small'} color={"secondary"} onClick={edit} sx={{color:'#8F8F8F',  top: -10,}}>
          edit
        </Button>
        <Button variant={"text"} size={'small'} color={"secondary"} sx={{color:'#8F8F8F',  top: -10,}} onClick={() => setOpen(true)}>
          delete
        </Button>
      </Stack>
      <Stack direction={'column'} spacing={.5} sx={{width:'100%',  position:
          "relative",
       }}>

        <Typography variant={'body1'}  sx={{textAlign:'left', marginTop:"-39px", fontWeight:"500",
        marginBottom:"-7px"
      }}
      >
          {
            data?.name
          }
        </Typography>
        <span/>
        <Typography  variant={'caption'}  sx={{ textAlign:'left'}}>
          <a href={
            data?.website
          } target={'_blank'}>{
            data?.website.length < 30 ? data?.website : `${data?.website.slice(0 , 30)}...`
          }</a>
        </Typography>
        <span/>
        <span style={{flex:1}}/>
        <Typography  variant={'caption'} paragraph={true} sx={{textAlign:'left', color:'#8F8F8F', width: 275, overflowY: 'hidden'}}>
          {
            data?.description.length < 200 ? data?.description :  `${data?.description.slice(0 , 200)}...`
          }
        </Typography>
        <span/>
        <span style={{flex:1}}/>
        <br></br>
        <Stack direction={'row'} spacing={.5} alignItems={"flex-start"}>
          <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
            Invested date:
          </Typography>
          <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
            {moment(data?.investmentDate).format("DD-MM-YY")}
          </Typography>
          <span style={{flex:1}}/>
          <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
            Amount
          </Typography>
          <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
            {currencyToSymbol(data?.currency)} 
            {data?.amount}
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={.5} alignItems={"flex-start"}>
          <Typography  variant={'caption'}  sx={{textAlign:'left',fontWeight:"500"}}>
            Invested stage:
          </Typography>
          <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
            {data?.investmentStage}
          </Typography>
          <span style={{flex:1}}/>
          <Typography variant={'caption'}  sx={{textAlign:'left',fontWeight:"500"}}>
            Pre-val:
          </Typography>
          <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
            {currencyToSymbol(data?.valuationCurrency)}
             {data?.valuation}
          </Typography>
        </Stack>
      </Stack>



    </Stack>
    <Portal>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-achive"
        aria-describedby="alert-dialog-achive"
        maxWidth={"sm"}
        >
        <DialogContent
          sx={{ minWidth: 144, padding: "0px 0px", mt: 2 , width:"305px"}}>
          <Stack
            direction={"column"}
            sx={{ width: "100%" }}
            justifyContent="center"
            alignItems="flex-end">
            <IconButton
              onClick={() => {
                setOpen(false);
              }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction={"column"}
            sx={{ width: "100%" }}
            spacing={2}>
            <Stack
              direction={"column"}
              sx={{ width: "100%", }} alignItems={"center"}>
              <img src={"/assets/delete-confirmation.png"} height={46} width={50}></img>
            </Stack>
            <Typography
              component="span"
              variant="h6"
              color="text.primary"
              sx={{
                textAlign: "center",
                flex: 1,
                padding: 0,
                fontWeight: 500,
                fontSize:"15px"
              }}>
              Delete Portfolio
            </Typography>
            <Typography variant={"body2"} sx={{textAlign:'center',
          fontWeight: 400,
          fontSize:"11px"
          }}>
              Are you sure you want to delete this portfolio? <br/>
              This action cannot be undone.
            </Typography>
            <br/>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              
              justifyContent="center"
              alignItems="center" spacing={2}>
              <Button
                variant={"outlined"}
                sx={{px: 5 , width:"100px",height:"40px",fontSize:"12px",fontWeight:"500" }}
                onClick={() => {
                  setOpen(false)
                }}>
               No
              </Button>
              <Button
                variant={"contained"}
                sx={{ px: 5 ,width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
                onClick={() => {
                  remove()
                  setOpen(false)
                }}>
                yes
              </Button>
            </Stack>

            <Box />
          </Stack>
        </DialogContent>
      </Dialog>
    </Portal>
  </Grid>)
}