import React from "react";
import {
  Button,
  Container,
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Typography, useMediaQuery, CircularProgress, createFilterOptions
} from "@mui/material";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import ServiceProviderDetailsValidation from "../../../validations/service-provider/provider-profile";
import { Add } from "@mui/icons-material";
const filter = createFilterOptions()

export default function EditServiceProviderOrganisationAboutDetailsForm({ cbBack, cbNext, data, isLoading }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const formikFirst = useFormik({
    initialValues: data
      ? data
      : ServiceProviderDetailsValidation.EditOrganisationAboutFormValues,
    validationSchema: ServiceProviderDetailsValidation.EditOrganisationAboutForm,
    onSubmit: async (values) => {
      debugger
      cbNext({
        ...values,
      });
    }
  });
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>
          <TextField
            name={'website'}
            value={formikFirst.values.website}
            onChange={formikFirst.handleChange}
            error={formikFirst.touched.website && Boolean(formikFirst.errors.website)}
            helperText={formikFirst.touched.website && formikFirst.errors.website}
            fullWidth label={'Website URL'} />
          <Stack direction={"column"} spacing={5}>
					<Autocomplete
						multiple
						options={[
							"Law Firm",
							"Advertising Agency"
						].map(
							(option) => option
						)}
						value={formikFirst.values?.serviceType}
						freeSolo
						onChange={(
							e,
							newValue
						) =>
							formikFirst.setFieldValue('serviceType', newValue)
						}
						renderTags={(
							value,
							getTagProps
						) =>
							formikFirst.values?.serviceType &&
							formikFirst.values?.serviceType.length > 0
							&&	formikFirst.values?.serviceType.map(
								(
									option,
									index
								) => (
									<Chip
										variant="contained"
										sx={{
											backgroundColor:
												"#E3E8F0",
											marginLeft:
												"5px",
											marginRight:
												"5px",
											marginBottom:
												"10px",
										}}
										deleteIcon={
											<CloseIcon />
										}
										onDelete={() =>
											formikFirst.setFieldValue('serviceType',
												formikFirst.values?.serviceType.filter(
													(
														f
													) =>
														f !==
														option
												)
											)
										}
										label={
											option
										}
										{...getTagProps(
											{
												index,
											}
										)}></Chip>
								)
							)
						}

						renderInput={(
							params
						) => (
							<><Stack direction={'column'} spacing={0}  >
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Service Type *
							  </Typography>
							  <TextField
								{...params}
								// variant={'standard'}
								fullWidth
								// required={formikFirst.values.serviceType.length === 0 }
								error={
									formikFirst.touched.serviceType &&
									Boolean(formikFirst.errors.serviceType)
								}

								helperText={
									formikFirst.touched.serviceType &&
									formikFirst.errors.serviceType
								}
								//label="Service Type *"
								//placeholder="Add"
								sx={{
									transition:
										"0.1s linear",
								}}
							/>
							</Stack>
							</>
						
						)}
					/>

</Stack>
            <Stack direction={'row'} spacing={5}>
							<Autocomplete
								multiple
								fullWidth
								options={[
								"Adtech",
								"Advanced manufacturing",
								"Agtech",
								"Artificial intelligence and machine learning (AI/ML)",
								"Audiotech",
								"Augmented reality (AR)",
								"Autonomous cars",
								"B2B payments",
								"Beauty",
								"Big Data",
								"Cannabis",
								"Carsharing",
								"Cleantech",
								"Climate tech",
								"Cloudtech and DevOps",
								"Construction technology",
								"Cryptocurrency and blockchain",
								"Cybersecurity",
								"Digital health",
								"Ecommerce",
								"Edtech",
								"Ephemeral content",
								"eSports",
								"Femtech",
								"Fintech",
								"Foodtech",
								"Gaming",
								"Healthtech",
								"HRtech",
								"Impact investing",
								"Industrials",
								"Infrastructure",
								"Insurtech",
								"Internet of Things (IoT)",
								"Legal tech",
								"Life sciences",
								"Lifestyles of Health and Sustainability (LOHAS) and wellness",
								"Manufacturing",
								"Marketing tech",
								"Micro-mobility",
								"Mobile",
								"Mobile commerce",
								"Mobility tech",
								"Mortgage tech",
								"Nanotechnology",
								"Oil and gas",
								"Oncology",
								"Pet tech",
								"Real estate tech",
								"Restaurant tech",
								"Ridesharing",
								"Robotics and drones",
								"Software as a service (SaaS)",
								"Space tech",
								"Supply chain technology",
								"Technology, media and telecommunications (TMT)",
								"Virtual reality (VR)",
								"Wearables and quantified self",
								"3D printing"		
								].map(
									(option) => option
								)}
								value={formikFirst.values?.sector}
								freeSolo
								filterOptions={(options, params) => {
									const filtered = filter(options, params);
							
									const { inputValue } = params;
									// Suggest the creation of a new value
									const isExisting = options.some((option) => inputValue === option);
									if (inputValue !== '' && !isExisting) {
										filtered.push('Add ' + inputValue);
									}
							
									return filtered;
								  }}
								getOptionLabel={(option) => {
									// Value selected with enter, right from the input
									if (typeof option === 'string') {
									  return option
									}
									// Add "xxx" option created dynamically
									if (option.value) {
										return option.value;
									  }
									  // Regular option
									return option
								  }}
								renderOption={(props, option) => 
								<li {...props} style={{alignItems:"center"}}>
									{option.includes("Add ") 
									? 
									<><Add fontSize="medium"/>{option}</> 
									: option}
									</li>}
								onChange={(
									e,
									newValue
								) =>{
									if(newValue.length!==0)
										{const newOption = newValue.slice(-1)[0].replace("Add ", "")
										newValue.splice(-1,1,newOption)}
									  
									formikFirst.setFieldValue('sector', newValue)
								}
								}
								renderTags={(
									value,
									getTagProps
								) =>
									formikFirst.values?.sector &&
									formikFirst.values?.sector.length > 0
									&&	formikFirst.values?.sector.map(
										(
											option,
											index
										) => (
											<Chip
												variant="contained"
												sx={{
													backgroundColor:
														"#E3E8F0",
													marginLeft:
														"5px",
													marginRight:
														"5px",
													marginBottom:
														"10px",
												}}
												deleteIcon={
													<CloseIcon />
												}
												onDelete={() =>
													formikFirst.setFieldValue('sector',
														formikFirst.values?.sector.filter(
															(
																f
															) =>
																f !==
																option.value ? option.value : option
														)
													)
												}
												label={
													option.value ? option.value : option
												}
												{...getTagProps(
													{
														index,
													}
												)}></Chip>
										)
									)
								}
								renderInput={(
									params
								) => (<><Stack direction={'column'} spacing={0}  >
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Sector *
							  </Typography>
									<TextField
										{...params}
										// variant={'standard'}

										fullWidth
										// required={formikFirst.values.sector.length === 0 }
										error={
											formikFirst.touched.sector &&
											Boolean(formikFirst.errors.sector)
										}

										helperText={
											formikFirst.touched.sector &&
											formikFirst.errors.sector
										}
										//label="Sector *"
										//placeholder="Add"
										sx={{
											transition:
												"0.1s linear",
										}}
									/>
									</Stack></>
								)}
							/>

					</Stack>
        </Stack>
      </Container>
      {
        matches ? <Stack
                direction={"row"}
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
                width={"100%"}>
              <Button
                  variant={"outlined"}
                  color={"primary"}
                  sx={{ px: 5 }}
                  onClick={cbBack}>
                Back
              </Button>
              <Button
                  variant={"contained"}
                  color={"primary"}
                  sx={{ px: 5 }}
                  type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
                save
              </Button>
            </Stack> :
            <Stack
                direction={"row"}
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
                width={"100%"}
                sx={{px: !matches ? 2 : ''}}
            >
              <Button
                  variant={"contained"}
                  color={"primary"}
                  sx={{ px: 5 }}
                  type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
                save
              </Button>
            </Stack>
      }
    </Stack>
  );
}
