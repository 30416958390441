import * as yup from 'yup';

const AddAdminSchema = yup.object({
  email: yup
    .string('Email')
    .email('Enter a valid email')
    .required('Email is required')

  ,
  password: yup
    .string('Enter your password')
    .matches(/(?=.*?[A-Z])/, 'Your password must have at least one uppercase.')
    .matches(/(?=.*?[a-z])/, 'Your password must have at least one lowercase.')
    .matches(/(?=.*?[0-9])/, 'Your password must have at least one number.')
    .matches(/(?=.*?[#?!@$%^&*-])/, 'Your password must have atleast one special character')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required')
  ,
  displayName: yup
    .string('Name')
    .required('Name is required')
    .min(3, 'Name should be of minimum 3 characters length')
    .max(35, 'Name should be of maximum 35 characters length')
  ,
  userType: yup
    .string('Select user type')
    .required('User type is required'),

});
const initialValues = {
  email: '',
  password: '',
  displayName: '',
  userType: ''
};

const addUserSchema = yup.object({
  email: yup
    .string('User email address')
    .email('Enter a valid email')
    .required('Email is required'),
  countryState: yup.string('Location'),
  displayName: yup
    .string('User full name')
    .required('User full name is required')
    .min(3, 'Name should be of minimum 3 characters length')
    .max(35, 'Name should be of maximum 35 characters length'),
  linkedinURL: yup
    .string("LinkedIn URL")
    .matches("linkedin.com", "Please enter valid Linkedin url")
  ,
  phoneNumber: yup
    .string('Phone number')
  
})
const addUserValues = {
  email: '',
  countryState: '',
  displayName: '',
  phoneNumber: '+44',
  linkedinURL: ''
};
const AddAdminValidation = {
  initialValues,
  AddAdminSchema,
  addUserSchema,
  addUserValues
};



export default  AddAdminValidation;
