import React from "react";
import {
	Avatar,
	Grid,
	Paper,
	Stack,
	styled,
	Typography,
	Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import ListItemStartConversation from "./list-item-start-conversation";
import MemberFavourite from '../../screens/common/MemberFav'


const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "center",
	backgroundColor: "#fff",
	color: theme.palette.text.secondary,
	boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
	borderRadius: "15px",
	minHeight: 348,
}));
export default function ServiceProviderListItem({ data, href }) {
	const { profile } = React.useContext(AuthUserContext);
	const navigator = useNavigate();

	return (
		<Grid
			item
			lg={4}
			md={4}
			sm={6}
			xs={12}
			wrap={"nowrap"}>
			<Item>
				<Stack direction={"column"} spacing={2} sx={{ width: "100%" }}
					   justifyContent="center"
					   alignItems="center">
					<Stack sx={{ ml: "90%", mb:"-15%", mt:"-2.5%" }}>
						<MemberFavourite data={{ ...data,card: "service-provider" }} />
					</Stack>
					<Box
					sx={{
							position: "relative",
							top:"40px",
							bottom : "20px",
							width: "280px",
							height: "200px",
							background: "#F6F8FA",
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px"
						}}
					>
					<Stack
						direction={"column"}
						spacing={2}
						justifyContent="center"
						style={{cursor:"pointer"}}
						alignItems="center" 			onClick={() => data.uid === profile.uid ? navigator(`/${profile?.userType}/profile`): navigator(href)}
					>
						<Box
							sx={{
								border: "1px solid #204488",
								p: 0.75,
								borderRadius: "50%",
								marginTop : "-40px"
							}}>
							<Avatar
								alt={data?.displayName}
								src={
									data?.userType === "service-provider" &&
									data?.photoURL !== ""
										? data?.photoURL
										: data?.serviceProvider?.photoURL
								}
								sx={{ width: 92, height: 92 }}>
								<Typography variant={"h5"}>
									{data?.displayName &&
										data?.displayName.charAt(
											0
										)}
								</Typography>
							</Avatar>
						</Box>
						<Typography
							variant={"h6"}
							sx={{ fontWeight: 600, mb: 0, lineHeight: 1.2 }}>
							{data?.serviceProvider?.displayName} <br />
							<font size={3} style={{ fontWeight: 300 ,textTransform: "capitalize"}}>
								Service Provider - Individual
							</font>
						</Typography>


						<Box/>
					</Stack>
					{
						(data.uid !== profile?.uid) ?  (
							<ListItemStartConversation data={{...data, creatorData: data}}/>

						):<Button size={'small'} variant={"outlined"} color={'secondary'} sx={{px: 2, width:120}} onClick={() => {navigator(`/${profile?.userType}/profile`)
								}}>My profile</Button>
					}
					</Box>
					<Box />
					<Box />
					<Box />
					{data?.serviceProvider && data?.serviceProvider?.organisationType && (
						<Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
							<Typography
								variant={"body2"}
								sx={{
									fontWeight: "bold",
									flex: 1,
									textAlign: "left",
								}}>
								Service Provider Type
							</Typography>
							<Typography variant={"body2"} sx={{}}>
								{data?.serviceProvider?.organisationType}
							</Typography>
						</Stack>
					)}
					{data?.serviceProvider &&
						data?.serviceProvider?.serviceType&&
						data?.serviceProvider?.serviceType.length > 0 &&  (
						<Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
							<Typography
								variant={"body2"}
								sx={{
									fontWeight: "bold",
									flex: 1,
									textAlign: "left",
								}}>
								Service Type
							</Typography>
							<Typography variant={"body2"} sx={{ width: 130, textAlign: "right" }}
								noWrap={true}>
								{/* {data?.serviceProvider?.serviceType} */}
								{data?.serviceProvider?.serviceType.map((d, i) =>
									d !==
									data?.serviceProvider?.serviceType[
										data?.serviceProvider?.serviceType.length - 1
									]
										? `${d}, `
										: d
								)}
							</Typography>
						</Stack>
					)}
{/* 
					{data?.serviceProvider &&
						data?.serviceProvider.supportStage && (
							<Stack direction={"row"} spacing={2}sx={{width: '100%'}}>
								<Typography
									variant={"body2"}
									sx={{
										fontWeight: "bold",
										flex: 1,
										textAlign: "left",
									}}>
									Support Startup at Stage
								</Typography>
								<Typography variant={"body2"} sx={{}}>
									{data?.serviceProvider?.supportStage}
								</Typography>
							</Stack>
						)} */}
				</Stack>
			</Item>
		</Grid>
	);
}
