import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../../providers/auth-provider";
import React from "react";
import fireIcon from "./Fire.png"; // Import your custom SVG icon
import {
  Grid,
  Stack,
  Typography,
  Box,
  styled,
  Paper,
  Button,
  Select,
  MenuItem,
  Portal,
  Dialog,
  DialogContent,
  Skeleton,
  Chip,
} from "@mui/material";
import Avatar from "@mui/material/Avatar/Avatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  increment,
  getDoc,
} from "firebase/firestore";
import { DB } from "../../../../config/firebase/firebase";
import IconButton from "@mui/material/IconButton";
import RemainingHoursOrDate from "./offer-date-format";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OfferFavorite from "./offer-fav";
import { black } from "material-ui/styles/colors";

export default function OfferClaimCardC({
  offer,
  callback,
  remove,
  details = false,
  loading 
}) {
  const navigator = useNavigate();
  const { profile } = React.useContext(AuthUserContext);
  const [claimed, setClaimed] = React.useState(false);
  const [claimedPopUp, setClaimedPopUp] = React.useState(false);
  const claim = () => {
    const colRef = doc(DB, "offers", offer?.id, "users", profile?.uid);
    setDoc(
      colRef,
      {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      },
      { merge: true }
    )
      .then(async () => {
        await updateDoc(doc(DB, "offers", offer?.id), {
          claimed: increment(1),
        });
        setClaimedPopUp(true);
        setClaimed(true);
      })
      .catch(() => {
        debugger;
      });
  };
  const Item = styled(Paper)(({ theme }) => ({
    // ...theme.typography.body2,
    // padding: theme.spacing(5),
    // textAlign: "center",
    // backgroundColor: "#fff",
    // color: theme.palette.text.secondary,
    boxShadow: "none",
    // borderRadius: "15px",
    minHeight: 325,
    border:"1px solid white",
    width:300,
    background: "#F6F8FA",

  }));
  React.useEffect(() => {
    console.log(offer?.users);
    if (offer?.users?.includes(profile.uid)) setClaimed(true);
  }, []);
  React.useEffect(() => {
    setClaimed(false);
    let unsubs;
    unsubs = getDoc(doc(DB, "offers", offer?.id, "users", profile?.uid)).then(
      (getdoc) => {
        if (getdoc.exists()) {
          setClaimed(true);
        }
      }
    );
    return () => unsubs;
  }, [offer?.id]);
  return (
    <Grid item   
     sm={6} xs={6} wrap={"nowrap"} 
     >
     
                 
      <Item  
      sx={{ mt: 3}} 
      >
        <Stack
          direction={"column"}
          spacing={1}
          sx={{ width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction={"row"}
            spacing={0}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            {(Math.floor((new Date().getTime() -  new Date(offer?.from).getTime())/(1000 * 60 *60)))<=24 && //to check if the offer is live for 24 hours
            <Chip
            variant="contained"
            sx={{
              my: 0,
              backgroundColor: "#E0E3FF",
              px: 0.5,
              maxWidth: 150,
              height:20,
              borderRadius: "5px",
              marginTop:"5%",
              marginLeft:"4%"
              
            }}
            label={"New"}
          />
          }
            <OfferFavorite  data={offer} />
          </Stack>
          <Box
            textOverflow={"ellipsis"}
            onClick={() => {
              navigator(`/m/${profile.userType}/offers/details/${offer.id}`);
            }}
            sx={{
              position: "relative",
              top: "35px",
              // bottom: "20px",
              width: "145px",
              height: "94px",
            
              boxShadow: "none",
              borderRadius: "15px",
            }}
          >
            <Stack
              direction={"column"}
              spacing={1}
              justifyContent="center"
              alignItems="center"
              onClick={() => navigator()}
            >
              <Box
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                sx={{
                  border: "1px solid #204488",
                  p: 0.75,
                  borderRadius: "50%",
                  marginTop: "-40px",
                }}
              >
                {loading ? <Skeleton animation={'wave'} variant="circular" sx={{width:70, height:70}}/> :
                <Avatar
                  alt={"Offer Image"}
                  src={
                    offer.offerImage
                      ? offer.offerImage
                      : "/assets/offer-default-image.png"
                  }
                  sx={{ width: 70, height: 70 }}
                ></Avatar>}
              </Box>
             {loading ? <>
              <Skeleton animation={'wave'} variant="rounded" width={"50%"}/> 
             </>
              : 
             <Typography
                style={{fontSize:"12px"}}
                sx={{
                  fontWeight: 600, mb: 0, lineHeight: 1.2, width: 100, overflow: "hidden", color:"black",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "12ch",
                  textAlign: "center",
                }}
              >
                {offer?.companyName?.slice(0, 20) +
                  (offer?.companyName?.length > 20 ? "..." : "")}
                <br />
                <font size={1} style={{ fontWeight: 300 }}>
                  {offer?.name?.slice(0, 20) +
                    (offer?.name?.length > 20 ? "..." : "")}
                </font>
              </Typography>
              
              
              }
              {!claimed  && !loading && (
                <Button
                 
                  style={{fontSize:"10px"}}
                  color={"primary"}
                  sx={{
                    color: "black",
                    fontWeight: "400",
                    padding: "2px 2px",
                    backgroundColor: "#E0E3FF",
                    "&:hover": {
                      backgroundColor: "#233DFF",
                      color: "white",
                    },
                  }}
                  onClick={(e) => {
                    
                    e.stopPropagation();
                    claim();
                  }}
                >
                  {" "}
                  Claim{" "}
                </Button>
              )}
              {claimed && !loading && (
                <Typography style={{fontSize:"10px",marginBottom:"-10px",marginTop:"-2px"}} sx={{ fontWeight: 500}}>
                  Coupon:{" "}
                  {offer?.couponCode?.slice(0, 20) +
                    (offer?.couponCode?.length > 20 ? "..." : "")}
                </Typography>
              )}
              <Box />
              <Stack
                sx={{ top: "-10" }}
                direction={"column"}
                spacing={0}
                justifyContent="flex-start"
                alignItems="flex-start"
                width={"100%"}
              >
                <Stack
                 sx={{ top: "-10" }}
                  direction={"column"}
                  spacing={0}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width={"100%"}
                >
                  {loading ? <Skeleton animation={'wave'} sx={{mt:1}} variant="rounded" width={"50%"}/> :<>
                  <Typography sx={{
                    fontSize:10,
                    fontWeight: 600, mb: 0, lineHeight: 1.2, width: 500, overflow: "hidden", color:'black',
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "50ch",
                  }} style={{marginLeft:"-2px"}} >
                    
                    {/* {offer?.headingOne.slice(0, 25) +
                      (offer?.headingOne?.length > 25 ? "..." : "")} */}
                  </Typography>
                  <Typography style={{fontSize:"10px"}} sx={{ fontWeight: 500, mb: 0,width: 400, overflow: "hidden", color:'black',
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "80ch", }}>
                    {/* {offer?.headingTwo.slice(0, 30) +
                      (offer?.headingTwo?.length > 30 ? "..." : "")} */}
                  </Typography>
                      </>
                    }
                </Stack>
                {details ? (
                  <>
                    <Stack
                      direction="column"
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <Stack
                        direction={"column"}
                        spacing={0}
                    
                        justifyContent="center"
                        alignItems="center"
                        width={"100%"}
                      >
                        <Typography
                          color={'#000000'}
                          style={{fontSize:"12px"}}
                          sx={{fontWeight: 600, mt: 0.3 }}
                        >
                          Additional link
                        </Typography>
                        <a  
                          // style={{
                          //   borderRadius: "19px",
                          //   backgroundColor: "#E0E3FF",
                          //   padding: "3px 8px",
                          // }}
                          style={{
                            fontSize:"12px",
                            textDecoration:'none',
                            color: '#000000',
                            display: "inline-block",
                            borderRadius: "19px",
                            backgroundColor: "#E0E3FF",
                            padding: "3px 8px",
                            // maxWidth: "98%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "25ch",
                            
                          }}
                          href={offer?.offerLink}
                          target={"_blank"} rel="noreferrer"
                        >
                          {offer?.offerLink}
                        </a>
                        <Box sx={{mt:1.5}} flex={1} />
                  <RemainingHoursOrDate dateString={offer.to} />
                 
                      </Stack>
                    </Stack>  {offer.isHotOffer &&
                  <IconButton color="primary" style={{marginLeft:"177px",marginTop:"-2px"}}>
                        <img src={'/assets/Fire.png'} alt="Custom Icon" />
                  </IconButton>
              }
                  </>
                ) : (
                  <>
                    {loading ? <Skeleton animation={'wave'} variant="rounded" sx={{width:"100%"}}/> : 
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <RemainingHoursOrDate dateString={offer.to} />

                      {offer.isHotOffer ? (
                        <IconButton  style={{padding:"none"}} color="primary">
                          <img style={{ width: 20, height: 20  }} src={fireIcon} alt="Custom Icon" />
                        </IconButton>
                      ) : (
                        <div style={{ width: 30, height: 30  }} /> // Placeholder with the same width as IconButton
                      )}
                    </Stack>}
                  </>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Item>
      <Portal>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={claimedPopUp}
          onClose={() => setClaimedPopUp(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ p: 3 }}>
            <Stack justifyContent="flex-start" alignItems="flex-start">
              <Typography sx={{ mb: 3 }} variant={"h6"} fontWeight={600}>
                Successfully Claimed the Offer
              </Typography>
            </Stack>
            <Stack
              direction={"column"}
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >

              <img
                src={"/assets/successfully-applied.png"}
                height={92}
                width={92}
              />

              {offer.couponCode && (
                <>
                  <Typography variant={"body2"} textAlign={"center"}>
                    <font size={3} style={{ fontWeight: 300 }}>
                      Here`s your promo code for future usage
                    </font>
                  </Typography>
                  <Typography variant={"h6"} textAlign={"center"}>
                    {offer && offer.couponCode}
                  </Typography>
                </>
              )}

              <Typography variant={"body2"} textAlign={"center"} 
              style={{ fontWeight: 600 }}>
                Congratulations on claiming this offer! Feel free to check out{" "}
                <br />
                other exciting offers available on our platform.
              </Typography>


            </Stack>
            <Stack
              direction={"row"}
              spacing={5}
              justifyContent="flex-end"
              sx={{ mt: 3 }}
            >
              <Button
                variant={"contained"}
                // sx={{ width: "100%" }}
                onClick={() => {
                  setClaimedPopUp(false);
                  navigator(`/m/${profile?.userType}/offers/list`);
                }}
              >
                Continue
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </Grid>
  );
}
