import React from "react";
import { AuthUserContext } from "../../providers/auth-provider";
import { useNavigate } from "react-router-dom";
import { DB } from "../../config/firebase/firebase";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  deleteDoc,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { Container, Grid, Stack, Typography, Box, Button } from "@mui/material";
import OfferCard from "../../components/common/offer/offer-card";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
export default function OffersListCards() {
  const [offers, setOffers] = React.useState([]);
  const navigator = useNavigate();
  const { profile } = React.useContext(AuthUserContext);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [inputbtn, setinputbtn] = React.useState();

  React.useEffect(() => {
    const q = query(collection(DB, "offers"), orderBy('createdAt', 'desc'));
    setLoading(true);
    const unSubs = onSnapshot(q, (querySnap) => {
      if (querySnap.size > 0) {
        const list = [];
        querySnap.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });

        setOffers(list);
      }
      setLoading(false);
    });
    return () => unSubs;
  }, []);
  const update = async (e, id) => {
    const offersRef = doc(DB, "offers", id);
    await setDoc(
      offersRef,
      { isActive: e.target.value, updatedAt: serverTimestamp() },
      { merge: true }
    );
  };
  const deleteOffer = (offerId) => {
    debugger;
    const offerRef = doc(DB, "offers", offerId);
    deleteDoc(offerRef).then((doc) => {
      console.log(doc);
      setOffers((prevItems) => prevItems.filter((item) => item.id !== offerId));
    });
  };

  return (
    <React.Fragment>
      <Container maxWidth={"xl"} sx={{ my: 5, textAlign: "left" }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}
        > 
          <IconButton onClick={() => navigator(-1)}>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography variant={"h5"} sx={{ textAlign: "left", lineHeight: 1 }}>
            Active Offers
          </Typography>
          <Box flex={1} />
          <Button
            sx={{ borderRadius: "8px" }}
            variant={"contained"}
            color={"primary"}
            onClick={() => navigator("/admin/offers/add-offer")}
          >
            + Add New offer
          </Button>

          <Container
            maxWidth={"full-width"}
            sx={{
              height: 1,
              p: "2px 10px",
              display: "flex",
              alignItems: "right",
              width: 337,
              height: 43,
              borderRadius: "12px",
              backgroundColor: "#E4EAF5",
            }}
          >
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={(e) => setSearch(inputbtn)}
            >
              <SearchIcon sx={{ color: "#051E40BF" }} />
            </IconButton>
            <InputBase
              onChange={(e) => setinputbtn(e.target.value)}
              value={inputbtn}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  setSearch(inputbtn);
                }
              }}
              sx={{ ml: 1, flex: 1, color: "#051E40BF" }}
              placeholder="Search"
              inputProps={{ "aria-label": "search post" }}
            />
          </Container>
        </Stack>
      </Container>

      <Container
        maxWidth={"lg"}
        sx={{ my: 5, textAlign: "left", height: "75vh" }}
      >
        <Grid container spacing={5}>
          {offers.length > 0 ? (
            offers.map((offer, i) => (
              <OfferCard
                offer={offer}
                callback={(e) => {
                  e.stopPropagation();
                  update(e, offer.id)
                }}
                remove={(offerId) => {
                  deleteOffer(offerId);
                }}
              />
            ))
          ) : <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ mb: 5, mx: "auto", height: "50vh" }}
        >
          <Stack
            sx={{ border: "1px dashed rgba(0, 0, 0, 0.45)" }}
            p={5}
            direction={"row"}
            spacing={2}
          >
            <Typography variant={"h5"}>
              You have not added any <b>Offers</b> yet.{" "}
              <b>
                Add
                <br /> Now
              </b>{" "}
              to treat your users with exciting deals <br />
              and discounts!
            </Typography>
          </Stack>
          </Stack>
          }
        </Grid>
      </Container>
    </React.Fragment>
  );
}
