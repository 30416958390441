import { Typography } from '@mui/material'
import React from 'react'
import parse from 'html-react-parser';
import {AuthUserContext} from "../../../../../providers/auth-provider";
const ItemText = ({limit, text}) => {
  let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const [readMore, setReadMore] = React.useState(false)

  return (
    <div>
      

      {text && (
  <>
    {readMore ? (
      <div style={{ maxWidth: "100%", overflowWrap: "break-word" }}>
        {parse(
          text.replace(urlRegex, function (url) {
            let hyperlink = url;
            if (!hyperlink.match('^https?://')) {
              hyperlink = 'http://' + hyperlink;
            }
            return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
          })
        )}
      </div>
    ) : (
      <div style={{ maxWidth: "100%", overflowWrap: "break-word" }}>
        {parse(
          text.substr(0, limit).replace(urlRegex, function (url) {
            let hyperlink = url;
            if (!hyperlink.match('^https?://')) {
              hyperlink = 'http://' + hyperlink;
            }
            return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
          })
        )}
      </div>
    )}
  </>
)}

    

      {text && text.length !== text.substr(0, limit).length &&
            <>
            {!readMore && "..."}
            <Typography variant='body2' sx={{color:"#3647fc", cursor:"pointer", userSelect:"none"}} onClick={()=>setReadMore(!readMore)}>
               {!readMore ? "View more" : "View less"}
            </Typography> 
            </>}
      
      
    </div>
  )
}




export default ItemText
export function ItemMentioned({limit, post}){
  const {profile} = React.useContext(AuthUserContext);
  let text = post?.text
  return (
    <div>
      
      
      {post?.text &&
        <>
          {
            post?.mentioned && post?.mentioned?.length > 0 &&
            post?.mentioned?.map((d) => (
              text = text.replace(d.displayName, `<a style="color: #000; text-decoration: none" href=/${profile?.userType}/${d?.userType}-details/${d?.uid}  rel="noopener noreferrer"><b style="font-weight: 600">${d.displayName}</b></a>` )
            )) && parse(text)
          }
        </>
      }
    
    
    </div>
  )
}