import * as yup from 'yup';

const SigninSchema = yup.object({
  email: yup
    .string('Email')
    .email('Enter a valid email')
    .required('Email is required')

  ,
  password: yup
    .string('Enter your password')
    .matches(/(?=.*?[A-Z])/, 'Your password must have at least one uppercase.')
    .matches(/(?=.*?[a-z])/, 'Your password must have at least one lowercase.')
    .matches(/(?=.*?[0-9])/, 'Your password must have at least one number.')
    .matches(/(?=.*?[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/, 'Your password must have atleast one special character')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required')
  ,

});
const initialValues = {
  email: '',
  password: '',
};

const SignInValidation = {
  initialValues,
  schema : SigninSchema
};

export default  SignInValidation;
