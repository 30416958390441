import { Avatar, Grid, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useState } from "react";


export default function BusinessDeveloperWorkExperience({ profile, setIndex , setK }) {
  function intToString(num) {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: "K" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    );
  }
  return (
    <>
              {profile?.userType === "business-developer" &&
          profile?.businessDeveloper &&
          profile?.businessDeveloper.experiences &&
          profile?.businessDeveloper.experiences.length > 0 &&(
        <Typography fontSize={20} fontWeight={600}>Work Experience</Typography>
      )} 

      {profile?.userType === "business-developer" &&
          profile?.businessDeveloper &&
          profile?.businessDeveloper.experiences &&
          profile?.businessDeveloper.experiences.length  === 0  ? <>
        <Typography fontSize={20} fontWeight={600} >Work Experience</Typography>

          <Stack direction="row" justifyContent="center">

            <Typography>There are no work experience to be shown</Typography>
          </Stack></>:null 
        }
          {profile?.userType === "business-developer" &&
          profile?.businessDeveloper &&
          profile?.businessDeveloper.experiences &&
          profile?.businessDeveloper.experiences.length > 0 &&
          profile?.businessDeveloper.experiences.map((p, i) => (
            <>

<Box key = {i} 
  onClick={() => {
  setIndex(i+1);
  setK(i)
  }}
sx={{width: "30%",
 backgroundColor: '#F6F8FA',
  border: '0.5px solid #000000',
   borderRadius: '5px',
    p:5,
    px: 2 }}>

<Stack direction={'column'} spacing={.5} sx={{width:'100%'}}>
  <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
    <Box sx={{flex:1}}/>

  </Stack> 
  <Stack direction={'column'} spacing={.5} sx={{width:'100%'}}>

    <Typography variant={'body1'}  sx={{textAlign:'left',marginTop:"-32px", fontWeight:"600", fontSize:"16px"}}>
    {
            p?.companyName < 10 ? p?.companyName : `${p?.companyName.slice(0 , 10)}...`
          }
    {/* {p?.companyName ? p?.companyName : ""} */}
    </Typography>
    <Typography  variant={'caption'}  sx={{textAlign:'left'}}>

      <a href={
        p?.website
      } target={'_blank'}>{
        p?.website.length < 30 ? p?.website : `${p?.website.slice(0 , 30)}...`
      }</a>
    </Typography>
    <span/>
    <span/>
    <span/>
    <span/>
    <span style={{flex:1}}/>
    <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
      <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500", fontSize:"14px"}}>
        {p?.role}
      </Typography>
      <span style={{flex:1}}/>
      <Typography  variant={'caption'}  sx={{textAlign:'left'}}>
        {moment(p?.startDate).format("MMM Do")} - {p?.currentlyWorkingInThisRole ? "Present" : moment(p?.endDate).format("MMM Do")}
      </Typography>
    </Stack>
    <Typography  variant={'caption'} paragraph={true} sx={{textAlign:'left', color:'#8F8F8F',width: 275, overflowY: 'hidden'}}>
      {
        p?.experience.length < 200 ? p?.experience :  `${p?.experience.slice(0 , 200)}...`
      }
    </Typography>
  </Stack>
</Stack>

</Box>
</>
          ))}
    </>
  );
}

      {/* {profile?.userType === "business-developer" && (
        <Typography fontSize={20} fontWeight={600} sx={{fontWeight:'500'}}>Work Experience</Typography>
      )} 

      <Grid container gap={3}>
        {profile?.userType === "business-developer" &&
          profile?.businessDeveloper &&
          profile?.businessDeveloper.experiences &&
          profile?.businessDeveloper.experiences.length > 0 &&
          profile?.businessDeveloper.experiences.map((p, i) => (
            <Grid key={i}>
              {p?.companyName && (
                <>
                  <Box
                    onClick={() => {
                      setIndex(i+1);
                    }}
                    sx={{
                      width: "320px",
                      backgroundColor: "#F6F8FA",
                      padding: 2,
                      borderRadius: "5px",
                      border: "0.5px solid black",
                    }}
                    key={i}
                  >
                

                    {console.log(profile)}

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant={"h6"}
                        style={{
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        {p?.companyName ? p?.companyName : ""}
                      </Typography>
                    </Stack>
                    <Typography
                        variant={"p"}
                        style={{
                          color: "blue", textDecoration:'underline'
                        }}
                      >
                        {p?.website ? p?.website : ""}
                      </Typography>
                      <br></br><br></br>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant={"h6"}
                        style={{
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                        nowrap={false}
                      >
                        {p?.role ? p?.role : ""}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color={"#363636"}
                        style={{
                          fontSize: "14px",
                        }}
                        nowrap={false}
                      >
                        {p?.role ? p?.role : ""}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="subtitle1"
                      color={"grey"}
                      style={{
                        marginTop: "12px",
                        textAlign: "left",
                        fontSize: " 16px",
                      }}
                      nowrap={false}
                    >
                    {p.experience ? p.experience : ''}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
          ))}
      </Grid> */}