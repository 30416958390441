import React from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
import {Container, IconButton, Stack, Typography} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import PostItem from "../../../components/common/timeline/post-item";
// import PostItem from "../../../screens/mobile/components/common/timeline/post-item";

export default function PostDetails() {
 const {postId} = useParams();
 const [post,setPost] = React?.useState()
 const navigate = useNavigate()
 React?.useEffect(() => {
 
  const timelinesRef = doc(DB, "timelines", postId);
   getDoc(timelinesRef)
     .then((doc) => setPost({id: postId, ...doc.data()}) );
 }, [postId])
 return (
   <>
    <Container maxWidth={'xl'} sx={{p : 5}}>
  
     <Stack
       direction={"row"} spacing={2} alignItems={"center"}
     >
      <IconButton onClick={() => navigate(-1)} color={'secondary'}>
       <ArrowBack sx={{fontSize:30}}/>
      </IconButton>
      <Typography variant={'h5'} fontWeight={500}>Post</Typography>
     </Stack>
 
    </Container>
    <Container maxWidth={'md'} sx={{p : 5, textAlign:"left"}}>
 
     {post && <PostItem
     mobile={true}
       data={post}
     />}
    </Container>

   </>
   
 )
}