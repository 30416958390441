import React from 'react';
import {Button, Container, Stack,  Typography, Grid, Paper, Box, Tooltip, IconButton} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {useNavigate} from "react-router-dom";
import {doc, setDoc} from "firebase/firestore";
import {DB, auth} from "../../../config/firebase/firebase";
import { ACCOUNT_CREATION_NOTE, BUSINESS_DEVELOPER, EXPERT } from '../../../utils/enums/labels.enum';
import Spinner from '../../../components/common/spinner';
// import ButtonAppBar from './navbar';


const types = [


  {
    title: 'Startups & Founders',
    id: 'startup',
    text:'',
    tooltiptext:"A startup is a company that's in the initial stage of business."

  },
  {
    title: BUSINESS_DEVELOPER.NAME,
    id: 'business-developer',
    text:"",
    tooltiptext:`Growth Expert, Sales, Strategic Partnerships, Branding, etc.
    `
    
  },
  {
		title: EXPERT.NAME,
		id: "expert",
		text: "",
		tooltiptext:
			`Scientists, Researchers, etc.`,
	},
  {
    title: 'Investors',
    id: 'investor',
    // text: "VCs / CVCs",
    tooltiptext:"Angel Investor, Venture Capital Fund, PE Fund, CVC, Family Office, etc."

  },
 
  {
    title: 'Service Providers',
    id: 'service-provider',
    text:"",
    tooltiptext:"Lawyers, Designers, Accountants, etc."

  },
 
  // {
  //   title: 'Talents',
  //   id: 'talent',
  //   text:"",
  //   textcomingsoon: "Coming Soon",
  //   // tooltiptext:"Talents have skills and want to work on your project."

  // },
  // {
  //   title: 'Distribution',
  //   id: 'distribution',
  //   text:"",
  //   textcomingsoon:"Coming Soon"
  //   // tooltiptext:"The distributor helps spread your product throughout the marketplace such that a large number of people."

  // },
  
];
export default function OnboardingStep1() {

    const [selected, setSelected] = React.useState();
    const [show, setShow] = React.useState(true);
  const navigator = useNavigate();
  // const data = location.state.formData;

  const handleNext = async () => {
    const userRef = doc(DB, 'users', auth.currentUser.uid);
    await setDoc(userRef, {userType: selected}, {merge: true});
    if (selected === 'expert') {
      navigator('/m/on-boarding/expert-profile');
    } else if (selected === 'startup') {
      navigator('/m/on-boarding/startup-profile-setup', {state: {type: selected}});
    } else if (selected === 'investor') {
      navigator('/m/on-boarding/investor-profile-setup', {state: {type: selected}});
    } else if (selected === 'business-developer') {
      navigator('/m/on-boarding/business-developer-profile-setup', {state: {type: selected}});
    } else if (selected === 'service-provider') {
      navigator('/m/on-boarding/service-provider-profile-setup', {state: {type: selected}});
    } else if (selected === 'talent') {
      navigator('/m/on-boarding/talent-profile-setup', {state: {type: selected}});
    }

  }
  React.useEffect(() => {
		setTimeout(() => setShow(false), 1500)
	}, [])
	if (show) return (<Spinner />)
    return (
      <>
        <Container maxWidth={"lg"} sx={{ mt: 15, pb: 5 }}>
          <Stack
    
             direction="column"
             justifyContent="center"
             alignItems="center"
             spacing={5}
           
           >
             <Typography variant={'h6'} sx={{textAlign:'center', width: '100%',fontWeight:"bold"}}>
             {ACCOUNT_CREATION_NOTE}
             </Typography>
           
              <Grid container style={{marginLeft:"-5%"}} spacing={2} >
                {types.map((tier, i) =>
                  // Enterprise card is full width at sm breakpoint
                  tier.id !== "distribution" && tier.id !==  "talent" ? (
                    <Grid
                              item
                              key={tier.title}
                              xs={6}
                              sm={tier.title === 'Enterprise' ? 12 : 6}
                              md={4}
                              
                    
                            >
                              <Paper elevation={4} style={{width:"100%",border:`${tier.id !== selected ? 
                                '1px solid white'
                                // '#ffffff'
                                : '1px solid #233DFF' }`}} >
                    
                                <Box onClick={() => setSelected(tier.id)}
                                     sx={{
                                       display: "flex",
                                       justifyContent: 'center',
                                       alignItems: 'center',
                                       flexDirection:'column',
                                       mb: 2,
                                       pb:4,
                                       height: 130, backgroundColor: `${tier.id !== selected ? 
                                        '#ffffff': '#ffffff' }`, borderRadius: '10px'
                                     }}
                                >
                         <div style={{width: '90%', textAlign:'right', color: `${tier.id !== selected ? '#A9ABAE' : '#ffffff' }`, position:'relative'}}>
           {tier.id !== selected ? (
           <RadioButtonUncheckedIcon style={{fontSize:25 ,color: tier.id !== selected ? '#A9ABAE': '#233DFF',marginTop:"0px" }}/> ) : (
                  <CheckCircleIcon style={{fontSize:25 ,color: tier.id !== selected ? '#A9ABAE': '#233DFF',marginTop:"0px" }}/>
                )}



              </div>
              <br></br>

              {/*<img src={tier.id !== selected ? `/assets/${tier.title}-grey.png`: `/assets/${tier.title}-grey.png`} height={70} style={{marginBottom: '20px'}} alt=""/>*/}
              <Typography  variant="h6"  component="p" color={tier.id !== selected ? 'secondary': '#233DFF'} sx={{mt: 0 , textAlign:'center',fontSize:"13px", fontWeight: "bold" }} >
                {tier.title}
                
            
              </Typography>
             
              <Typography variant={'h6'} style={{fontSize:"10px",marginTop:"-45px", textAlign:'right' ,fontWeight: "bold", }} color={tier.id !== selected ? 'secondary': '#233DFF'}>{tier.text}</Typography>
              <Typography style={{fontSize:"14px",marginTop:"10px"}}>{tier.textcomingsoon}</Typography>

              {/* <Typography style={{fontSize:"12px",marginTop:"-5px",textAlign:"center",marginTop:"5px",marginLeft:"10px",marginRight:"10px"}}color={tier.id !== selected ? 'secondary': '#233DFF'}>{tier.tooltiptext}</Typography> */}
              <div
													style={{
														width: "90%",
														textAlign: "right",
														color: `${
															tier.id !== selected
																? "#A9ABAE"
																: "#ffffff"
														}`,
														position: "relative",
														top: "60%",
													}}>
													<Tooltip 
                          arrow placement="top" enterTouchDelay={0} 
														componentsProps={{
															tooltip: 
                               {
																sx: {
                                  
																	color: "white",
																	maxWidth:
																		"200px",
																	paddingTop: 2,
																	height: 75,
																	fontSize: 10,
																	textAlign:
																		"start",
																	marginRight:
																		"2%",

																	bgcolor:
																		"#233DFF",
																	"& .MuiTooltip-arrow":
																		{
                                      fontSize:"15px",
																			color: "#233DFF",
																		},
																},
															},
														}}
														title={tier.tooltiptext}
														sx={{
															width: "40px",
															marginLeft: "100px",
														}}>
														<IconButton >
															<HelpOutlineIcon
																style={{
																	fontSize: 20,
																	marginLeft:
																		"10px",
																	color:
																		tier.id !==
																		selected
																			? "#A9ABAE"
																			: "#233DFF",
																}}
															/>
														</IconButton>
													</Tooltip>
												</div>
            </Box>
          </Paper>
        </Grid>
                  ) : (
                    <Grid
                      item
                      key={tier.title}
                      xs={6}
                      sm={
                        tier.title === "Enterprise" ? 12 : 6
                      }
                      md={4}>
                      <Paper elevation={4} style={{width:"100%",border:`${tier.id !== selected ? 
                      '1px solid white'
                      // '#ffffff'
                      : '1px solid #233DFF' }`,
												opacity: 0.6,}} >
                      <Box sx={{
                                       display: "flex",
                                       justifyContent: 'center',
                                       alignItems: 'center',
                                       flexDirection:'column',
                                       mb: 2,
                                       pb:4,
                                       height: 130, backgroundColor: `${tier.id !== selected ? '#ffffff': '#ffffff' }`, borderRadius: '10px'
                                     }}
                                >
                         <div style={{width: '90%', textAlign:'right', color: `${tier.id !== selected ? '#A9ABAE' : '#ffffff' }`, position:'relative'}}>
                            {tier.id !== selected ? (
                              <RadioButtonUncheckedIcon
                                style={{
                                  fontSize: 20, marginTop:"33px",
                                  marginRight:
                                    "5px",
                                  color:
                                    tier.id !==
                                    selected
                                      ? "#A9ABAE"
                                      : "#233DFF;",
                                }}
                              />
                            ) : (
                              <CheckCircleIcon
                                style={{
                                  fontSize: 20,
                                  marginRight:
                                    "5px",
                                  color:
                                    tier.id !==
                                    selected
                                      ? "#A9ABAE"
                                      : "#233DFF"
                                }}
                              />
                            )}
                          </div>
  
                          {/*<img src={tier.id !== selected ? `/assets/${tier.title}-grey.png`: `/assets/${tier.title}-grey.png`} height={70} style={{marginBottom: '20px'}} alt=""/>*/}
                          <Typography
                            variant="h6"
                            component="p"
                            color={"#aeaeae"}
                            // color={"#000000"}
                            sx={{fontWeight: "bold", fontSize:"13px", marginTop:"5px", }}
                            >
                            {tier.title}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              marginTop: "10px",
                              fontWeight: "bold",
                              // color: "#aeaeae",
                            }}>
                            {tier.textcomingsoon}
                          </Typography>
                          {/* <div
													style={{
														width: "90%",
														textAlign: "right",
														color: `${
															tier.id !== selected
																? "#A9ABAE"
																: "#ffffff"
														}`,
														position: "relative",
														top: "12%",
													}}>
													<Tooltip 
                          
                           arrow placement="left-end" enterTouchDelay={0} 
														componentsProps={{
															tooltip: {
																sx: {
																	color: "white",
																	maxWidth:
																		"250px",
																	paddingTop: 2,
																	height: 75,
																	fontSize: 10,
																	textAlign:
																		"start",
																	marginRight:
																		"20%",

																	bgcolor:
																		"#233DFF",
																	"& .MuiTooltip-arrow":
																		{
																			color: "#E3E8F0",
																		},
																},
															},
														}}
														title={tier.tooltiptext}
														
														sx={{
															width: "40px",
															marginLeft: "100px",
														}}>
														<IconButton>
															<HelpOutlineIcon
																style={{
																	fontSize: 20,
																	marginLeft:
																		"10px",
																	
																}}
															/>
														</IconButton>
													</Tooltip>
												</div> */}
                        </Box>
                      </Paper>
                    </Grid>
                  )
                )}
              </Grid>
            </Stack>
  <br></br>
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{ margin: "auto", px: 5, marginLeft:"34%" }}
              onClick={() => handleNext()}
              disabled={!selected}>
              Next
            </Button>
          
        </Container>
      </>
    );
  }
  
//        <>
//         <ButtonAppBar />
//   <Container maxWidth={'lg'} sx={{mt: 10, pb: 5}}>

// <Stack
//   direction="column"
//   justifyContent="center"
//   alignItems="center"
//   spacing={5}

// >
//   <Typography variant={'h6'} sx={{textAlign:'center', width: '100%',fontWeight:"bold"}}>
//   Exploring the open network
//   </Typography>

//   <Grid container style={{marginLeft:"-5%"}} spacing={2} >
//       {types.map((tier, i) => 
//       (
        
//         // Enterprise card is full width at sm breakpoint
//         <Grid
//           item
//           key={tier.title}
//           xs={6}
//           sm={tier.title === 'Enterprise' ? 12 : 6}
//           md={4}
          

//         >
//           <Paper elevation={4} style={{width:"100%",border:`${tier.id !== selected ? '#ffffff': '1px solid #233DFF' }`}} >

//             <Box onClick={() => setSelected(tier.id)}
//                  sx={{
//                    display: "flex",
//                    justifyContent: 'center',
//                    alignItems: 'center',
//                    flexDirection:'column',
//                    mb: 2,
//                    pb:4,
//                    height: 150, backgroundColor: `${tier.id !== selected ? '#ffffff': '#ffffff' }`, borderRadius: '10px'
//                  }}
//             >
//               <div style={{width: '90%', textAlign:'right', color: `${tier.id !== selected ? '#A9ABAE' : '#ffffff' }`, position:'relative'}}>

//                 {tier.id !== selected ?<RadioButtonUncheckedIcon style={{fontSize:25 ,color: tier.id !== selected ? '#A9ABAE': '#233DFF',marginTop:"0px" }}/> :
//                   <CheckCircleIcon style={{fontSize:25 ,color: tier.id !== selected ? '#A9ABAE': '#233DFF',marginTop:"0px" }}/>
//                 }



//               </div>
//               <br></br>

//               {/*<img src={tier.id !== selected ? `/assets/${tier.title}-grey.png`: `/assets/${tier.title}-grey.png`} height={70} style={{marginBottom: '20px'}} alt=""/>*/}
//               <Typography  variant="h6"  component="p" color={tier.id !== selected ? 'secondary': '#233DFF'} sx={{mt: 0 , textAlign:'center',fontSize:"14px"}} >
//                 {tier.title}
                
            
//               </Typography>
             
//               <Typography style={{fontSize:"10px",marginTop:"-5px", textAlign:'right'}}color={tier.id !== selected ? 'secondary': '#233DFF'}>{tier.text}</Typography>
//               <Typography style={{fontSize:"14px",marginTop:"10px"}}>{tier.textcomingsoon}</Typography>

//               <Typography style={{fontSize:"12px",marginTop:"-5px",textAlign:"center",marginTop:"5px",marginLeft:"10px",marginRight:"10px"}}color={tier.id !== selected ? 'secondary': '#233DFF'}>{tier.tooltiptext}</Typography>



//             </Box>
//           </Paper>
//         </Grid>
//       ))}
//     </Grid>


//   <Button  variant={"contained"} color={'primary'} sx={{margin: 'auto', px: 5}} onClick={() => handleNext()} disabled={!selected}>
//     Next
//   </Button>
// </Stack>
// </Container>
//        </>
//     );
// }
