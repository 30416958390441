import * as React from "react";

import {
  Box,
  Stack,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider,
  Button,
  Portal,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AuthUserContext } from "../../../providers/auth-provider";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  serverTimestamp, setDoc, doc,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { Support } from "@mui/icons-material";
import { USER_TYPES } from "../../../utils/enums/userTypes.enum";
import { BUSINESS_DEVELOPER, USER_TYPES_TO_NAME } from "../../../utils/enums/labels.enum";

export default function RightPanel() {
  const { user, profile } = React.useContext(AuthUserContext);

  const [discovers, setDiscovers] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const create = async () => {
    const getAdminSnaps = await getDocs(query(
      collection(DB, 'users'),
      where('userType', '==', 'admin'),
      orderBy('createdAt', 'asc')
    ))
    debugger
    const uid = getAdminSnaps?.docs[0].id;
    debugger;
    setLoading(true);
    const userType = "";
    try {
      const q = query(
        collection(DB, "rooms"),
        where("participates", "in", [
          [uid, profile?.uid],
          [profile?.uid, uid],
        ])
      );
      const getRoom = await getDocs(q);
      let room
      if(getRoom.size > 0){
        room= {id: getRoom.docs[0].id, ...getRoom.docs[0].data()}
      }
      else{
        await addDoc(collection(DB, "rooms"), {
          title: "Support",
          type:'support',
          subTitle: `${"Admin"}, ${profile?.displayName}`,
          participates: [uid, user.uid],
          uid: user?.uid,
          logo: "/assets/support-icon.png",
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
          lastMsg: {
            text: "Please go ahead and start conversation",
            status: "send",
          },
        });
        room={
          title: "Support",
          type:'support',
          subTitle: `${"Admin"}, ${profile?.displayName}`,
          participates: [uid, user.uid],
          uid: user?.uid,
          logo: "/assets/support-icon.png",
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
          lastMsg: {
            text: "Please go ahead and start conversation",
            status: "send",
          },
        }
      }
      // await setRoomId(room.id)
      if (getRoom.size === 0) {
        let message = await addDoc(collection(DB, "messages"), {
          type: "system-generated",
          text: "Please go ahead and start conversation",
          sender: user.uid,
          receiver: uid,
          createdAt: serverTimestamp(),
          roomId: room?.id,
          read: false,
          status: "send",
        });
      } else {
        await setDoc(doc(DB, "rooms", getRoom.docs[0].id), {    title: "Support",
          type:'support',updatedAt: serverTimestamp()}, {merge:true})
      }
      setLoading(false);
      setOpen(!open);
      navigator(`/${profile.userType}/chat`);
    } catch (e) {
      setLoading(false);
    }
  };
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    debugger;
    const q = query(
      collection(DB, "users"),
      orderBy("createdAt", "desc"),
      where("onboard", "==", true),
      where("createdAt", ">", 1673624154791),
      limit(3)
    );

    const unSubs = onSnapshot(q, (querySnap) => {
      // debugger
      if (querySnap.size > 0) {
        const list = [];
        querySnap.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });

        setDiscovers(list);
      }
    });
    return () => unSubs;
  }, []);
  const navigator = useNavigate();
  return (
    <>
      <Stack
        position={"static"}
        sx={{
          boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
          background: "#fff",
          borderRadius: "15px",
          height: "fit-content",
        }}
        direction={"column"}
        spacing={1}
      >
        <Typography variant={"h6"} sx={{ px: 2, pt: 1 }}>
          Discover
        </Typography>

        <List sx={{ width: "100%", py: 0, my: 0 }}>
          {discovers.length > 0 &&
            discovers.map((discover, i) => discover.hasOwnProperty("lastLoginAt") && (
              <>
                <ListItem
                  alignItems="flex-start"
                  sx={{ px: 3 }}
                  onClick={() => {
                    let userDetails;
                    // console.log(discover?.userType);
                    discover?.userType === "business-developer"
                      ? (userDetails = "business-developer-details")
                      : discover?.userType === "service-provider"
                      ? (userDetails = "service-provider-details")
                      : discover?.userType === "startup"
                      ? (userDetails = "startup-details")
                      : discover?.userType === "investor"
                      ? (userDetails = "investor-details")
                      : discover?.userType === "expert"
                      ? (userDetails = "expert-details")
                      : discover?.userType === "talent"
                      ? (userDetails = "talent-details")
                      : (userDetails = null);
                    navigator(
                      `/${profile?.userType}/${userDetails}/${discover?.id}`
                    );
                  }}
                >
                  <ListItemAvatar sx={{ height: 50, width: 50 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        discover?.photoURL
                          ? discover?.photoURL
                          : "/assets/default-avatar.png"
                      }
                      sx={{ height: 45, width: 45 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={{ maxWidth: 110, fontSize: 16 }}
                          noWrap={true}
                        >
                          {discover?.displayName}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="body2"
                          color="secondary"
                          noWrap={true}
                          sx={{
                            display: "inline",
                            fontSize: 12,
                            textTransform: "capitalize",
                            minWidth: 110,
                          }}
                        >
                          New {USER_TYPES_TO_NAME[discover?.userType]}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {/* {<Divider variant="fullWidth" component="li" />} */}
              </>
            ))}
        </List>

        <Button
          color={"secondary"}
          size={"small"}
          fullWidth
          sx={{ justifyContent: "left", px: 1.8, fontSize: 12 }}
          onClick={() => navigator(`/${profile?.userType}/members-list`)}
        >
          View all our new members{" "}
          <ArrowForwardIcon sx={{ fontSize: 16, ml: 1 }} />
        </Button>
      </Stack>
      <Portal>
        {profile && profile !== "admin" && profile?.onboard && (
          <>
            <IconButton
              sx={{ position: "fixed", bottom: "10%", right: "7%" }}
              onClick={() => setOpen(!open)}
              disabled={isLoading}
            >
              <img src={"/assets/support-icon.png"} />
            </IconButton>
          </>
        )}
      </Portal>
      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"md"}
        >
          <DialogContent sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "95%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={5}>
              <Typography
                component="span"
                variant="h5"
                color="text.primary"
                sx={{
                  textAlign: "left",
                  flex: 1,
                  px: 5,
                  fontWeight: 700,
                }}
              >
                Need help?
              </Typography>
              <Typography
                variant={"body1"}
                sx={{ lineHeight: 1.4, textAlign: "left", px: 5 }}
              >
                Whether you need help with your account, have a technical issue,
                or just need some advice, we're here to help.
                <br /> <br />
                Chat with AstraNova Support or email at{" "}
                <b>hello@astranova.io</b>.
              </Typography>
              <Stack
                direction={"column"}
                sx={{ width: "96%" }}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Button variant={"contained"} sx={{ px: 3 }} onClick={create}>
                  Chat
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </>
  );
}
