import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import React from "react";
import {
  Grid,
  Stack,
  Typography,
  Box,
  styled,
  Paper,
  Button,
  Select,
  MenuItem,
  Portal,
  Dialog,
  DialogContent,
} from "@mui/material";
import Avatar from "@mui/material/Avatar/Avatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { doc, deleteDoc } from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

export default function OfferCard({ offer, callback, remove }) {
  const navigator = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { profile } = React.useContext(AuthUserContext);
  debugger;
  const [status, setStatus] = React.useState(offer?.isActive);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "#fff",
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    minHeight: 400,
  }));

  return (
    <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
      <Item>
        <Stack
          direction={"column"}
          spacing={1}
          sx={{ width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction={"row"}
            justifyContent="flex-end"
            alignItems="flex-start"
            sx={{ width: "100%" }}
            spacing={0}
          >
            <Button
              variant={"text"}
              size={"small"}
              color={"secondary"}
              sx={{ color: "#8F8F8F" }}
              onClick={() => navigator(
                `/${profile.userType}/offers/edit/${offer.id}`
              )}
            >
              Edit
            </Button>
            <Button
              variant={"text"}
              size={"small"}
              color={"secondary"}
              sx={{ color: "#8F8F8F" }}
              onClick={() => setOpen(true)}
            >
              Delete
            </Button>
          </Stack>
          <Box
            onClick={() => {
              debugger;
              navigator(`/${profile.userType}/offers/details/${offer.id}`);
            }}
            sx={{
              position: "relative",
              top: "40px",
              bottom: "20px",
              width: "280px",
              height: "200px",
              background: "#F6F8FA",
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
            }}
          >
            <Stack
              direction={"column"}
              spacing={2} 
              justifyContent="center"
              alignItems="center"
              onClick={() => navigator()}
            >
              <Box
                sx={{
                  border: "1px solid #204488",
                  p: 0.75,
                  borderRadius: "50%",
                  marginTop: "-40px",
                }}
              >
                <Avatar
                  alt={"Offer Image"}
                  src={
                    offer.offerImage
                      ? offer.offerImage
                      : "/assets/offer-default-image.png"
                  }
                  sx={{ width: 92, height: 92 }}
                >
                  <Typography variant={"h5"}>{offer?.companyName?.slice(0, 20) +
                  (offer?.companyName?.length > 20 ? "..." : "")}</Typography>
                </Avatar>
              </Box>
              <Typography
                variant={"h6"}
                sx={{ fontWeight: 600, mb: 0, lineHeight: 1.2,width: 250, overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "31ch", }}
              >
                {offer?.companyName?.slice(0, 20) +
                    (offer?.companyName?.length > 20 ? "..." : "")}
                <br />
                <font size={3} style={{ fontWeight: 300 }}>
                {offer?.name?.slice(0, 20) +
                    (offer?.name?.length > 20 ? "..." : "")}
                </font>
              </Typography>
              <Select
                size={"small"}
                value={status}
                style={{
                  color: "#000",
                  borderRadius: "30px",
                  backgroundColor: "#eeeeee10",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  height: "40px",
                  fontSize: 14,
                }}
                onClick={(e)=> e.stopPropagation()}
                onChange={(e) => {
                  setStatus(e.target.value);
                  callback(e);
                }}
                IconComponent={() => <ArrowDropDownIcon />}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Pause</MenuItem>
              </Select>
              <Box />
              <Typography variant={"body2"} sx={{ fontWeight: 600, mb: 0, width: 300, overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "31ch", }}>
              {offer?.headingOne.slice(0, 30) +
                      (offer?.headingOne?.length > 30 ? "..." : "")}
              </Typography>
              <Typography variant={"body2"} sx={{ fontWeight: 400, mb: 0 , width: 300, overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "31ch",}}>
              {offer?.headingTwo.slice(0, 30) +
                      (offer?.headingTwo?.length > 30 ? "..." : "")}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Item>
      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"sm"}
        >
          <DialogContent sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={2}>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                alignItems={"center"}
              >
                <img
                  src={"/assets/delete-confirmation.png"}
                  height={74}
                  width={80}
                ></img>
              </Stack>
              <Typography
                component="span"
                variant="h6"
                color="text.primary"
                sx={{
                  textAlign: "center",
                  flex: 1,
                  padding: 0,
                  fontWeight: 500,
                }}
              >
                Delete Offer
              </Typography>
              <Typography variant={"body2"} sx={{ textAlign: "center" }}>
                Are you sure you want to delete this offer? <br />
                This action cannot be undone.
              </Typography>
              <br />
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Button
                  variant={"outlined"}
                  sx={{
                    px: 5,
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  variant={"contained"}
                  sx={{
                    px: 5,
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    remove(offer.id);
                    setOpen(false);
                  }}
                >
                  yes
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </Grid>
  );
}
