import React, { useEffect } from "react";
import { AuthUserContext } from "../../../providers/auth-provider";
import {
  Container,
  Typography,
  Stack,
  Avatar,
  styled,
  Badge,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  useMediaQuery,
  Portal,
  List,
  ListItem,
  ListItemText,
  TextField,
  Chip,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Tooltip from "@material-ui/core/Tooltip";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UploadFile from "../../../utils/upload-file";
import { getAuth, updateProfile } from "firebase/auth";
import {
  a11yProps,
  TabPanel,
} from "../../../screens/mobile/components/common/tabs-custom";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  updateDoc,
  setDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";

import { useNavigate } from "react-router-dom";
import InvestorDescription from "../../../screens/mobile/components/common/profile/investor-description";
import ExpertDescription from "../../../screens/mobile/components/common/profile/expert-description";
import InvestorPortfolio from "../../../screens/mobile/components/common/profile/investor-portfolio";
import ExpertSocialProof from "../../../screens/mobile/components/common/profile/expert-social-proof";
import InvestorAbout from "../../../screens/mobile/components/common/profile/investor-about";
import ExpertAbout from "../../../screens/mobile/components/common/profile/expert-about";
import BusinessDeveloperAbout from "../../../screens/mobile/components/common/profile/business-developer-about";
import BusinessDeveloperDescription from "../../../screens/mobile/components/common/profile/business-developer-description";
import BusinessDeveloperSocialProof from "../../../screens/mobile/components/common/profile/business-developer-social-proof";
import BusinessDeveloperPortfolio from "../../../screens/mobile/components/common/profile/business-developer-portfolio";
import ServiceProviderDescription from "../../../screens/mobile/components/common/profile/service-provider-description";
import ServiceProviderAbout from "../../../screens/mobile/components/common/profile/service-provider-about";
import ServiceProviderSocialProof from "../../../screens/mobile/components/common/profile/service-provider-social-proof";
import StartupDescription from "../../../screens/mobile/components/common/profile/startup-description";
import StartupAbout from "../../../screens/mobile/components/common/profile/startup-about";
import FinanceAbout from "../../../screens/mobile/components/common/profile/finance-about";
import StartupAttachment from "../../../screens/mobile/components/common/profile/startup-attachment";
import TalentSocialProof from "../../../screens/mobile/components/common/profile/talent-social-proof";
import TalentAbout from "../../../screens/mobile/components/common/profile/talent-about";
import TalentDescription from "../../../screens/mobile/components/common/profile/talent-description";
import PostNotification from "../../../api/post-notification";
import CommonInvestorDetails from "../components/common/profile/common-investor-details";
import StartupTeam from "../components/common/profile/startup-team";
import InvestmentPortfolio from "../components/common/profile/investment-portfolio";
import ExpertWorkExperience from "../components/common/profile/expert-work-experience";
import BusinessDeveloperWorkExperience from "../components/common/profile/business-developer-work-experience";
import ServiceProviderWorkExperience from "../components/common/profile/service-provider-work-experience";
import StartupTopPriorities from "../components/common/profile/startup-top-priorities";
import InvestorPortfolioForm from "../../investor/onboarding-forms/portfolio-form";
import ExperienceForm from "../expert/onboarding-forms/experience-form";
import ExperienceListView from "../expert/onboarding-forms/experience-list-view";
import ReferralAvatar from "../../../screens/mobile/components/common/profile/referal-avatar";
import PortfoliosListView from "../investor/onboarding-forms/portfolios-list-view";
import { USER_TYPES } from "../../../utils/enums/userTypes.enum";
import {
  BUSINESS_DEVELOPER,
  USER_TYPES_TO_NAME,
} from "../../../utils/enums/labels.enum";
import PostItem from "../../../components/common/timeline/post-item";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import InputBase from "@mui/material/InputBase";
import { shortenURL } from "../../../utils/shortenURL";

const StyledTabs = styled((props) => (
  <Tabs
    sx={{ position: "relative", left: 0 }}
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "none",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    borderRadius: "30px",
    color: "#000",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#233DFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function ProfileScreen() {
  const userContext = React.useContext(AuthUserContext);

  const { profile, setProfile, setUser, user } =
    React.useContext(AuthUserContext);
  const [value, setValue] = React.useState(0);
  const [posts, setPosts] = React.useState([]);
  const profileImage = React.useRef();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [imageURL, setImageURL] = React.useState();
  const confirmed = React.useRef(false);
  const [steps, setSteps] = React.useState(0);
  const [portfolioIndex, setPortfolioIndex] = React.useState();
  const userRef = doc(DB, "users", user.uid);
  const [open, setOpen] = React.useState(false);
  const [postDeleted, setPostDeleted] = React.useState(false);
  // const [postDialogueOpen, setPostDialogueOpen] = React.useState(false);
  const [portfolios, setPortfolios] = React.useState(
    profile?.userType === "investor"
      ? profile?.investor?.portfolio
      : profile[
          profile?.userType === "service-provider"
            ? "serviceProvider"
            : profile?.userType === "business-developer"
            ? "businessDeveloper"
            : profile?.userType
        ]?.investorDetails?.portfolio
  );
  const [experiences, setExperiences] = React.useState(
    profile?.userType == "investor" &&
      profile?.userType == "startup" &&
      profile?.userType == "talent"
      ? []
      : profile[
          profile?.userType === "service-provider"
            ? "serviceProvider"
            : profile?.userType === "business-developer"
            ? "businessDeveloper"
            : profile?.userType
        ]?.experiences || []
  );
  const [experienceIndex, setExperienceIndex] = React.useState();
  const [postData, setPostData] = React.useState();
  const [text, setText] = React.useState("");
  const [image, setImage] = React.useState("");
  const [video, setVideo] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [file, setFile] = React.useState();
  const [otherFiles, setOtherFiles] = React.useState("");
  const [URL, setURL] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const arr = ["Success! Your Post has been Uploaded."];
  const arr2 = ["Success! Your Post has been Edited."];
  const [tag, setTag] = React.useState("");
  const [posted, setPosted] = React.useState(false);
  const [imageDimensions, setImageDimensions] = React.useState({});

  const storage = getStorage();
  const resetAll = async () => {
    await setImage("");
    await setVideo("");
    await setFile();
    await setURL();
    await setLoading(false);
    await setOtherFiles("");
    await setPostData();
    // window.document.getElementById('attachment-media')
    // window.document.getElementById('attachment-video').empty()
    // window.document.getElementById('attachment-file').empty()
  };
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    setDialogOpen(false);
  }
  const navigator = useNavigate();
  const Input = styled("input")({
    display: "none",
  });
  async function updateProfilePicture(url) {
    try {
      debugger;
      let startup = profile["startup"];
      const auth = getAuth();
      // console.log(url);
			const displayName = profile?.displayName;
			if(profile?.userType !== "startup") {
				await updateProfile(auth.currentUser, {
					displayName,
					photoURL: url,
				});
			}
			const data = auth.currentUser.toJSON();
				setUser({ ...user });
			const userRef = doc(DB, "users", auth.currentUser.uid);
			let dat;

      if (profile?.userType === "startup") {
        dat = await updateDoc(userRef, {
          "startup.logoURL": url,
        });
        setUser({ ...user });

        setProfile({
          ...profile,
          startup: { ...startup, logoURL: url },
        });
        setImageURL("");
      } else {
        dat = await updateDoc(userRef, {
          photoURL: data?.photoURL ? data.photoURL : " ",
        });
        setUser({ ...user, photoURL: url });
        setProfile({ ...profile, photoURL: url });
        setImageURL("");
      }
      PostNotification({
        type: "update-profile-picture",
        imageURL: url,
        message: `Your profile picture has been updated`,
        uid: user.uid,
      }).then();
      confirmed.current = true;
    } catch (err) {
      console.log(err.message);
    }
  }
  useEffect(() => {
    // console.log(confirmed.current);
	});
	function UploadProfile() {
		return (
			<div>
				<Dialog
					open={dialogOpen}
					onClose={handleDialogClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth={"sm"}
					fullWidth={true}
				>
					<DialogTitle id="alert-dialog-title">
						{shortenURL(profile?.userType === "startup"
              ? profile?.startup?.startupName
              : profile?.displayName,20)}
          </DialogTitle>
          <DialogContent sx={{ height: 400 }}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 300 }}
            >
              <Avatar
                key={imageURL}
                src={
                  imageURL
                    ? imageURL
                    : profile?.userType === "startup"
                    ? profile?.startup?.logoURL !== ""
                      ? profile?.startup?.logoURL
                      : ""
                    : profile?.photoURL
                }
                alt={
                  profile?.userType === "startup"
                    ? profile?.startup?.startupName
                    : profile?.displayName
                }
                sx={{
                  width: 250,
                  height: 250,
                }}
                component={"span"}
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "start", alignItems: "center", height: 75 }}
          >
            <IconButton variant="outlined">
              <label htmlFor={"profile-image"}>
                <Stack direction={"column"}>
                  <CameraAltIcon
                    sx={{ cursor: "pointer" }}
                    fontSize={"large"}
                  />
                  <Input
                    accept="image/*"
                    id="profile-image"
                    multiple={false}
                    type="file"
                    onChange={(e) => {
                      confirmed.current = false;
                      const fileReader = new FileReader();
                      fileReader.readAsDataURL(e.target.files[0]);
                      fileReader.addEventListener("load", async function () {
                        const file = e.target.files[0];

                        const path = `profile-image/${file.name}`;
                        const imageObject = {
                          progress: 0,
                          src: this.result,
                          path,
                          file: e.target.files[0],
                          size: (e.target.files[0]?.size / 1024 / 1024).toFixed(
                            2
                          ),
                        };
                        profileImage.current = imageObject;
                        UploadFile(file, path).then((url) => {
                          console.log(url);
                          setImageURL(url);
                        });
                      });
                    }}
                  />
                </Stack>
              </label>
            </IconButton>
            <IconButton
              variant="outlined"
              onClick={() => {
                profileImage.current = "";
                setImageURL(" ");

                //updateProfilePicture("");
                confirmed.current = true;
                //handleDialogClose();
              }}
            >
              <DeleteIcon fontSize={"large"} color={"warning"} />
            </IconButton>
            <Box sx={{ flex: 1 }} />
            <Button
              sx={{ px: 2 }}
              size={"small"}
              onClick={() => {
                profileImage.current = {
                  ...profileImage,
                  photoURL: imageURL,
                };

                updateProfilePicture(imageURL);

                profileImage.current = "";

                handleDialogClose();
                //updateProfilePicture("");
              }}
              variant="contained"
            >
              Save Photo
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSteps(0);
  };
  React.useEffect(() => {
    if (!imageURL) {
      setImageURL(
        imageURL
          ? imageURL
          : profile?.userType === "startup"
          ? profile?.startup?.logoURL !== ""
            ? profile?.startup?.logoURL
            : ""
          : profile?.photoURL
      );
    }

    const q = query(
      collection(DB, "timelines"),
      where("uid", "==", profile?.uid)
    );
    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });

          // console.log(doc.id, " => ", doc.data());
				});
				setPosts(list);
			}
		});
	}, [open]);
  const deletedPost = () => {
    const q = query(
      collection(DB, "timelines"),
      where("uid", "==", profile?.uid)
    );
    getDocs(q).then((querySnapshot) => {
      const list = [];
      if (querySnapshot.size > 0) {
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          console.log(doc.id, " => ", doc.data());
        });
      }
      setPosts(list);
    });
  };
  return (
    <Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
      <Stack direction={"column"} spacing={5}>
        <Stack
          direction={"column"}
          spacing={0.5}
          sx={{
            border: "0.5px solid #000000",

            boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
            borderRadius: "15px",
            backgroundColor: "#fff",
            minHeight: 100,
            p: 2,
            pb: 0,
          }}
        >
          <Box
            sx={{
              height: 100,
              width: "100%",
              backgroundColor: "#F6F8FA",
              borderRadius: "15px",
            }}
          />
          <Box
            sx={{
              border: "1px solid #204488",
              p: 0.75,
              borderRadius: "50%",
              width: "fit-content",
              position: "relative",
              left: 30,
              top: -50,
            }}
          >
            <label>
              <Badge
                overlap="circular"
                sx={{ width: 92, height: 92 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  <Button
                    onClick={handleDialogOpen}
                    sx={{ ml: 1, mt: 1 }}
                    color={"primary"}
                    component={"span"}
                  >
                    <AddCircleIcon />
                  </Button>
                }
              >
                <UploadProfile />
                <Avatar
                  key={imageURL}
                  src={
                    imageURL && confirmed.current
                      ? imageURL
                      : profile?.userType === "startup"
                      ? profile?.startup?.logoURL !== ""
                        ? profile?.startup?.logoURL
                        : ""
                      : profile?.photoURL
                  }
                  alt={
                    profile?.userType === "startup"
                      ? profile?.startup?.startupName
                      : profile?.displayName
                  }
                  sx={{
                    width: 92,
                    height: 92,
                    cursor: "pointer",
                  }}
                  component={"span"}
                />
              </Badge>
            </label>
          </Box>

          <Stack
            direction={"row"}
            spacing={3}
            sx={{ position: "relative", top: -40 }}
            alignItems={"flex-start"}
          >
            <div />
            <Stack direction={"column"} spacing={2}>
              <Stack direction={"row"} spacing={3}>
                <Typography variant={"h5"}>
                  {shortenURL(profile?.userType === "startup"
                    ? profile?.startup?.startupName
                    : profile?.displayName, 20)}
                </Typography>
              </Stack>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    backgroundColor: "#E0E3FF",
                    px: 1,
                    py: 1,
                    marginRight: "7px",
                    borderRadius: "19.5px",
                  }}
                >
                  {/* Usertype  */}
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontWeight: 500,
                      textTransform: "Uppercase",
                    }}
                  >
                    {USER_TYPES_TO_NAME[profile?.userType]}
                  </Typography>
                </Box>

                {profile[
                  profile?.userType === "service-provider"
                    ? "serviceProvider"
                    : profile?.userType === "business-developer"
                    ? "businessDeveloper"
                    : profile?.userType
                ]?.investorDetails?.areYouAnAngelInvestor && (
                  <Box
                    sx={{
                      backgroundColor: "#FFE0CA",
                      px: 1,
                      py: 1,
                      marginRight: "7px",
                      borderRadius: "19.5px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "13px",
                        fontWeight: 500,
                        textTransform: "Uppercase",
                      }}
                    >
                      {"Investor"}
                    </Typography>
                  </Box>
                )}
              </Box>
              {profile?.userType === "investor" && (
                <InvestorDescription profile={profile} />
              )}
              {profile?.userType === "expert" && (
                <ExpertDescription profile={profile} />
              )}
              {profile?.userType === "business-developer" && (
                <BusinessDeveloperDescription profile={profile} />
              )}
              {profile?.userType === "service-provider" && (
                <ServiceProviderDescription profile={profile} />
              )}
              {profile?.userType === "startup" && (
                <StartupDescription profile={profile} />
              )}
              {profile?.userType === "talent" && (
                <TalentDescription profile={profile} />
              )}
            </Stack>

            <div style={{ flex: 1 }} />
          </Stack>

          {profile?.userType === "service-provider" &&
            profile?.serviceProvider?.organisationType === "Organisation" &&
            profile?.serviceProvider?.organisationDetails && (
              <>
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent="flex-start"
                  sx={{
                    position: "relative",
                    left: 20,
                    minWidth: 120,
                    cursor: "pointer",
                    marginTop: "-13px",
                  }}
                  alignItems="flex-start"
                  onClick={() =>
                    //setVal(true)
                    navigator(
                      profile && profile.userType === "admin"
                        ? `/m/${profile?.userType}/organisation-profile`
                        : userContext &&
                          userContext.profile &&
                          userContext.profile.uid === profile.uid
                        ? `/m/${profile?.userType}/organisation-profile`
                        : `/m/${userContext?.profile?.userType}/organisation-profile`,
                      {
                        state: {
                          profile,
                          organisation: {
                            ...profile?.serviceProvider.organisationDetails,
                            nameOfOrganisation:
                              profile?.serviceProvider.organisationDetails
                                ?.nameOfOrganization !== ""
                                ? profile?.serviceProvider.organisation
                                    ?.nameOfOrganization
                                : profile?.serviceProvider?.organisationDetails
                                    ?.nameOfOrganization,
                            logoURL:
                              profile?.serviceProvider.organisationDetails
                                .logoURL !== ""
                                ? profile?.serviceProvider.organisationDetails
                                    .logoURL
                                : "",
                          },
                        },
                      }
                    )
                  }
                >
                  <Box
                    sx={{
                      border: "1px solid #204488",
                      p: 0.75,
                      borderRadius: "50%",
                      width: "fit-content",
                    }}
                  >
                    <Avatar
                      alt={
                        profile?.serviceProvider?.organisationDetails
                          .nameOfOrganization
                      }
                      src={
                        profile.userType === "service-provider" &&
                        profile?.serviceProvider?.organisationDetails
                          ?.logoURL !== ""
                          ? profile?.serviceProvider?.organisationDetails
                              ?.logoURL
                          : ""
                      }
                      sx={{ width: 48, height: 48 }}
                    >
                      <Typography variant={"h4"}>
                        {profile?.serviceProvider?.organisationDetails.nameOfOrganization.charAt(
                          0
                        )}
                      </Typography>
                    </Avatar>
                  </Box>
                  <Stack direction="column">
                    <Typography variant={"h6"}>
                      {
                        profile?.serviceProvider?.organisationDetails
                          .nameOfOrganization
                      }
                    </Typography>
                    <Typography
                      variant={"body2"}
                      sx={{ marginTop: "5px!important" }}
                    >
                      <font
                        size={2}
                        style={{
                          backgroundColor: "#F6F8FA",
                          padding: "5px 10px",
                          borderRadius: "6px",
                          fontWeight: 300,
                        }}
                      >
                        Organisation
                      </font>
                    </Typography>
                  </Stack>
                </Stack>
              </>
            )}
          {profile?.userType === "startup" && profile?.startup?.founder && (
            <>
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="flex-start"
                sx={{
                  position: "relative",
                  left: 20,
                  minWidth: 120,
                  cursor: "pointer",
                  marginTop: "-13px",
                }}
                alignItems="flex-start"
                onClick={() =>
                  //setVal(true)
                  navigator(
                    profile && profile.userType === "admin"
                      ? `/admin/${profile?.userType}/startup-founder-profile`
                      : `/m/${userContext?.profile?.userType}/profile/founder`,

                    {
                      state: {
                        profile,
                        founder: {
                          ...profile?.startup.founder,
                          linkedIn: profile?.startup.founder?.linkedIn,
                          website: profile?.startup.founder?.website,
                          twitter: profile?.startup.founder?.twitter,
                          repeatFounder:
                            profile?.startup.founder?.repeatFounder,

                          startupName:
                            profile?.startup.founder?.startupName !== ""
                              ? profile?.startup.founder?.startupName
                              : profile?.startup?.startupName,
                          photoURL:
                            profile?.startup.founder.photoURL !== ""
                              ? profile?.startup.founder.photoURL
                              : profile?.photoURL,
                        },
                      },
                    }
                  )
                }
              >
                <Box
                  sx={{
                    border: "1px solid #204488",
                    p: 0.75,
                    borderRadius: "50%",
                    width: "fit-content",
                  }}
                >
                  <Avatar
                    alt={profile?.startup?.founder.founderName}
                    src={
                      profile.userType === "startup" && profile?.photoURL !== ""
                        ? profile?.photoURL
                        : profile?.startup?.founder?.photoURL !== ""
                        ? profile?.startup?.founder?.photoURL
                        : profile?.photoURL
                    }
                    sx={{ width: 48, height: 48 }}
                  >
                    <Typography variant={"h4"}>
                      {profile?.startup?.founder.founderName.charAt(0)}
                    </Typography>
                  </Avatar>
                </Box>
                <Stack direction="column">
                  <Typography variant={"h6"}>
                    {profile?.startup?.founder.founderName}
                  </Typography>
                  <Typography
                    variant={"body2"}
                    sx={{ marginTop: "5px!important" }}
                  >
                    <font
                      size={2}
                      style={{
                        backgroundColor: "#F6F8FA",
                        padding: "5px 10px",
                        borderRadius: "6px",
                        fontWeight: 300,
                      }}
                    >
                      Founder
                    </font>
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
          {profile?.userType === "investor" &&
            profile?.investor?.investorType !== "Angel Investor" && (
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  position: "relative",
                  left: 20,
                  minWidth: 120,
                  cursor: "pointer",
                  marginTop: 1,
                }}
                onClick={() => {
                  const path = `/m/${userContext?.profile?.userType}/my-organisation-profile`;
                  navigator(path, {
                    state: {
                      profile: profile,
                      organisation: {
                        organizationDescription:
                          profile?.investor?.organizationDescription,
                        investorType: profile?.investor?.investorType,
                        website: profile?.investor?.website,
                        nameOfOrganization:
                          profile?.investor?.nameOfOrganization,
                        logoURL: profile?.logoURL,
                      },
                    },
                  });
                }}
              >
                <Avatar
                  sx={{ width: 57, height: 57, border: "1px solid #f5f5f5" }}
                  src={profile?.logoURL}
                  alt={"profile-pic"}
                  imgProps={{
                    style: { objectFit: "contain", backgroundColor: "#fff" },
                  }}
                >
                  <Typography variant={"h3"}>
                    {profile?.investor?.nameOfOrganization}
                  </Typography>
                </Avatar>
                <Stack direction={"column"}>
                  <Typography variant={"h6"}>
                    <font>{profile?.investor?.nameOfOrganization}</font>
                  </Typography>
                  <Typography variant={"body2"}>
                    <font
                      size={2}
                      style={{
                        backgroundColor: "#F6F8FA",
                        padding: "5px 10px",
                        borderRadius: "6px",
                        fontWeight: 300,
                      }}
                    >
                      Organization
                    </font>
                  </Typography>
                </Stack>
              </Stack>
            )}
          <Stack style={{ marginBottom: "26px", marginTop: "13px" }}>
            <ReferralAvatar profile={profile} />
          </Stack>

          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="styled tabs example"
          >
            <StyledTab
              label="ABOUT"
              {...a11yProps(0)}
              sx={{ fontSize: "14px" }}
            />
            {profile?.userType === "startup" && (
              <StyledTab
                label={"TEAM"}
                {...a11yProps(1)}
                sx={{ fontSize: "14px" }}
              />
            )}
            {profile?.userType !== "investor" &&
              profile?.userType !== "startup" && (
                <StyledTab
                  label={"EXPERIENCE"}
                  {...a11yProps(1)}
                  sx={{ fontSize: "14px" }}
                />
              )}
            {profile?.userType === "expert" &&
            profile?.expert.investorDetails.areYouAnAngelInvestor &&
            profile?.expert.investorDetails.areYouAnAngelInvestor === true ? (
              <StyledTab
                label={"INVESTMENT PORTFOLIO"}
                {...a11yProps(2)}
                sx={{ fontSize: "14px" }}
              />
            ) : profile?.userType === "business-developer" &&
              profile?.businessDeveloper.investorDetails
                .areYouAnAngelInvestor &&
              profile?.businessDeveloper.investorDetails
                .areYouAnAngelInvestor === true ? (
              <StyledTab
                label={"INVESTMENT PORTFOLIO"}
                {...a11yProps(2)}
                sx={{ fontSize: "14px" }}
              />
            ) : profile?.userType === "service-provider" &&
              profile?.serviceProvider.investorDetails.areYouAnAngelInvestor &&
              profile?.serviceProvider.investorDetails.areYouAnAngelInvestor ===
                true ? (
              <StyledTab
                label={"INVESTMENT PORTFOLIO"}
                {...a11yProps(2)}
                sx={{ fontSize: "14px" }}
              />
            ) : profile?.userType === "investor" ? (
              <StyledTab
                label={"INVESTMENT PORTFOLIO"}
                {...a11yProps(2)}
                sx={{ fontSize: "14px" }}
              />
            ) : null}

            {profile?.userType !== "startup" && (
              <StyledTab
                label="POST"
                {...a11yProps(profile?.userType !== "investor" ? 3 : 2)}
                sx={{ fontSize: "14px" }}
              />
            )}
          </StyledTabs>
          <Box sx={{ height: 20 }} />
        </Stack>
        <TabPanel index={0} value={value}>
          {profile?.userType === "investor" ? (
            <InvestorAbout profile={profile} />
          ) : profile?.userType === "expert" ? (
            <ExpertAbout profile={profile} />
          ) : profile?.userType === "business-developer" ? (
            <BusinessDeveloperAbout profile={profile} />
          ) : profile?.userType === "service-provider" ? (
            <ServiceProviderAbout profile={profile} />
          ) : profile?.userType === "startup" ? (
            <StartupAbout profile={profile} />
          ) : profile?.userType === "talent" ? (
            <TalentAbout profile={profile} />
          ) : (
            ""
          )}
        </TabPanel>

        {profile?.userType !== "investor" &&
          profile?.userType !== "startup" &&
          profile[
            profile?.userType === "service-provider"
              ? "serviceProvider"
              : profile?.userType === "business-developer"
              ? "businessDeveloper"
              : profile?.userType
          ]?.investorDetails?.areYouAnAngelInvestor && (
            <TabPanel index={0} value={value}>
              <Stack
                direction={"column"}
                spacing={3}
                sx={{
                  border: "0.5px solid #000000",
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 3,
                  py: 3,
                }}
              >
                <CommonInvestorDetails profile={profile} />
              </Stack>
            </TabPanel>
          )}

        <TabPanel
          index={profile?.userType === "startup" ? 1 : null}
          value={value}
        >
          <Stack
            direction={"column"}
            spacing={3}
            sx={{
              border: "0.5px solid #000000",
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
              backgroundColor: "#fff",
              minHeight: 100,
              px: 3,
              py: 3,
            }}
          >
            {profile?.userType === "startup" ? (
              <StartupTeam profile={profile} />
            ) : null}
          </Stack>
        </TabPanel>

        <TabPanel
          index={
            profile?.userType === "investor"
              ? 1
              : profile[
                  profile?.userType === "service-provider"
                    ? "serviceProvider"
                    : profile?.userType === "business-developer"
                    ? "businessDeveloper"
                    : profile?.userType
                ]?.investorDetails?.areYouAnAngelInvestor
              ? 2
              : -1
          }
          value={value}
        >
          <Stack
            direction={"column"}
            spacing={6}
            sx={{
              border: "0.5px solid #000000",

              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
              backgroundColor: "#fff",
              minHeight: 100,
              px: 3,
              py: 3,
            }}
          >
            {steps === 1 ? (
              <>
                {portfolioIndex !== undefined && portfolioIndex > -1 ? (
                  <Typography variant={"h5"}>Update portfolio</Typography>
                ) : (
                  <Typography variant={"h5"}>Add portfolio</Typography>
                )}
                <InvestorPortfolioForm
                  cbBack={() => {
                    setSteps(0);
                    setPortfolioIndex();
                    setOpen(false);
                  }}
                  cbNext={(data, index) => {
                    const port = [...portfolios];
                    if (index !== undefined && index > -1) {
                      port.splice(index, 1, { ...data });
                    } else {
                      port.push({ ...data });
                    }
                    const copyPortfolio = [];
                    debugger;
                    if (port.length > 0) {
                      for (let portfolio of port) {
                        const attachments = [];
                        if (portfolio.attachments.length > 0) {
                          for (let file of portfolio.attachments) {
                            if (file?.file) {
                              const path = `investors/${user.uid}/attachments/${file.file.name}`;
                              attachments.push(path);
                            } else {
                              attachments.push(file);
                            }
                          }
                        }
                        copyPortfolio.push({ ...portfolio, attachments });
                      }
                    }
                    const objectUpdate = {};
                    if (profile?.userType == "service-provider") {
                      objectUpdate["serviceProvider"] = {
                        ...profile["serviceProvider"],
                      };
                    } else if (profile?.userType == "business-developer") {
                      objectUpdate["businessDeveloper"] = {
                        ...profile["businessDeveloper"],
                      };
                    } else {
                      debugger;
                      objectUpdate[profile?.userType] = {
                        ...profile[profile?.userType],
                      };
                    }
                    if (profile?.userType !== "investor") {
                      debugger;
                      objectUpdate[
                        profile?.userType === "service-provider"
                          ? "serviceProvider"
                          : profile?.userType === "business-developer"
                          ? "businessDeveloper"
                          : profile?.userType
                      ].investorDetails.portfolio = [...copyPortfolio];
                    } else {
                      debugger;
                      objectUpdate[profile?.userType].portfolio = [
                        ...copyPortfolio,
                      ];
                    }
                    setPortfolios([...copyPortfolio]);
                    setDoc(userRef, objectUpdate, { merge: true }).then(() => {
                      setProfile({ ...profile, ...objectUpdate });
                    });

                    setSteps(0);

                    setOpen(false);

                    setPortfolioIndex();
                  }}
                  data={
                    portfolioIndex !== undefined && portfolioIndex > -1
                      ? portfolios[portfolioIndex]
                      : undefined
                  }
                  index={portfolioIndex}
                  open={open}
                  handleClose={() => setOpen(false)}
                />
              </>
            ) : (
              <>
                <Typography fontSize={20} fontWeight={600}>
                  Investment portfolio
                </Typography>

                <PortfoliosListView
                  add={(data) => {
                    setPortfolioIndex();
                    setSteps(1);
                    setOpen(true);
                  }}
                  edit={async (ind) => {
                    debugger;
                    await setPortfolioIndex(ind);
                    setSteps(1);
                    setOpen(true);
                  }}
                  remove={(ind) => {
                    const port = [...portfolios];
                    port.splice(ind, 1);
                    setPortfolios([...port]);
                    const objectUpdate = {};
                    if (profile?.userType == "service-provider") {
                      objectUpdate["serviceProvider"] = {
                        ...profile["serviceProvider"],
                      };
                    } else if (profile?.userType == "business-developer") {
                      objectUpdate["businessDeveloper"] = {
                        ...profile["businessDeveloper"],
                      };
                    } else {
                      debugger;
                      objectUpdate[profile?.userType] = {
                        ...profile[profile?.userType],
                      };
                    }
                    if (profile?.userType !== "investor") {
                      debugger;
                      objectUpdate[
                        profile?.userType === "service-provider"
                          ? "serviceProvider"
                          : profile?.userType === "business-developer"
                          ? "businessDeveloper"
                          : profile?.userType
                      ].investorDetails.portfolio = [...port];
                    } else {
                      debugger;
                      objectUpdate[profile?.userType].portfolio = [...port];
                    }
                    setDoc(userRef, objectUpdate, { merge: true }).then(() => {
                      setProfile({ ...profile, ...objectUpdate });
                    });
                    setSteps(0);
                  }}
                  portfolios={portfolios}
                />
              </>
            )}
          </Stack>
        </TabPanel>
        {profile?.userType !== "investor" && profile?.userType !== "startup" ? (
          <TabPanel index={1} value={value}>
            <Stack
              direction={"column"}
              spacing={6}
              sx={{
                border: "0.5px solid #000000",
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                px: 3,
                py: 3,
              }}
            >
              {steps === 1 ? (
                <>
                  {experienceIndex !== undefined && experienceIndex > -1 ? (
                    <Typography variant={"h5"}>Update Experience</Typography>
                  ) : (
                    <Typography variant={"h5"}>Add Experience</Typography>
                  )}
                  <ExperienceForm
                    cbBack={() => {
                      setOpen(false);
                      setSteps(0);
                    }}
                    cbNext={(data, index) => {
                      debugger;
                      const port = [...experiences];
                      if (index !== undefined && index > -1) {
                        port.splice(index, 1, { ...data });
                      } else {
                        port.push({ ...data });
                      }
                      setExperiences([...port]);

                      const copyExperiences = [];
                      if (port.length > 0) {
                        for (let experience of port) {
                          const attachments = [];
                          if (experience.attachments.length > 0) {
                            for (let file of experience.attachments) {
                              if (file?.file) {
                                const path = `experts/${user.uid}/experiences/attachments/${file.file.name}`;
                                attachments.push(path);
                              } else {
                                attachments.push(file);
                              }
                            }
                          }
                          copyExperiences.push({ ...experience, attachments });
                        }
                      }
                      const objectUpdate = {};
                      if (profile?.userType == "service-provider") {
                        objectUpdate["serviceProvider"] = {
                          ...profile["serviceProvider"],
                        };
                      } else if (profile?.userType == "business-developer") {
                        objectUpdate["businessDeveloper"] = {
                          ...profile["businessDeveloper"],
                        };
                      } else {
                        debugger;
                        objectUpdate[profile?.userType] = {
                          ...profile[profile?.userType],
                        };
                      }
                      objectUpdate[
                        profile?.userType == "service-provider"
                          ? "serviceProvider"
                          : profile?.userType == "business-developer"
                          ? "businessDeveloper"
                          : profile?.userType
                      ].experiences = [...copyExperiences];
                      setDoc(userRef, objectUpdate, { merge: true }).then(
                        () => {
                          setProfile({ ...profile, ...objectUpdate });
                        }
                      );
                      setSteps(0);
                      setOpen(false);
                      setExperienceIndex(undefined);
                    }}
                    data={
                      experienceIndex !== undefined && experienceIndex > -1
                        ? experiences[experienceIndex]
                        : undefined
                    }
                    index={experienceIndex}
                    open={open}
                    handleClose={() => setOpen(false)}
                  />
                </>
              ) : (
                <>
                  <Typography variant={"h6"} sx={{ fontWeight: "600" }}>
                    Work Experience
                  </Typography>
                  <ExperienceListView
                    add={(data) => {
                      setExperienceIndex(undefined);
                      setSteps(1);
                      setOpen(true);
                      /*		setPortfolioIndex();
                                                    setSteps(steps + 1);*/
                    }}
                    edit={(ind) => {
                      setExperienceIndex(ind);
                      setSteps(1);
                      setOpen(true);

                      /*	setPortfolioIndex(ind);
                                                setSteps(steps + 1);*/
                    }}
                    remove={(ind) => {
                      const experiences_copy = [...experiences];
                      experiences_copy.splice(ind, 1);
                      setExperiences(experiences_copy);
                      const objectUpdate = {};
                      if (profile?.userType == "service-provider") {
                        objectUpdate["serviceProvider"] = {
                          ...profile["serviceProvider"],
                        };
                      } else if (profile?.userType == "business-developer") {
                        objectUpdate["businessDeveloper"] = {
                          ...profile["businessDeveloper"],
                        };
                      } else {
                        debugger;
                        objectUpdate[profile?.userType] = {
                          ...profile[profile?.userType],
                        };
                      }
                      debugger;
                      objectUpdate[
                        profile?.userType == "service-provider"
                          ? "serviceProvider"
                          : profile?.userType == "business-developer"
                          ? "businessDeveloper"
                          : profile?.userType
                      ].experiences = [...experiences_copy];
                      setDoc(userRef, objectUpdate, { merge: true }).then(
                        () => {
                          setProfile({ ...profile, ...objectUpdate });
                        }
                      );

                      /*	debugger
                                                const port = [...portfolios]
                                                port.splice(ind, 1)
                                                setPortfolios([...port])*/
                    }}
                    experiences={experiences}
                  />
                </>
              )}
            </Stack>
          </TabPanel>
        ) : null}
        <TabPanel
          index={
            profile?.userType === "investor"
              ? 2
              : profile[
                  profile?.userType === "service-provider"
                    ? "serviceProvider"
                    : profile?.userType === "business-developer"
                    ? "businessDeveloper"
                    : profile?.userType
                ]?.investorDetails?.areYouAnAngelInvestor
              ? 3
              : 2
          }
          value={value}
        >
          <Stack direction={"column"} spacing={5}>
            {posts.length === 0 && (
              <Stack
                direction={"column"}
                spacing={6}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                }}
              >
                {/* <Stack direction="row" justifyContent="center"> */}

                <Typography>There are no posts to be shown</Typography>
                {/* </Stack> */}
              </Stack>
            )}
            <Stack direction={"column"} spacing={5}>
              {posts.length > 0 && (
                <Stack
                  direction={"column"}
                  spacing={5}
                  sx={{
                    border: "0.5px solid #000000",
                    boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                    borderRadius: "15px",
                    backgroundColor: "#fff",
                    minHeight: 100,
                    px: 0,
                    py: 0,
                  }}
                >
                  {posts.map((post, i) => (
                    <PostItem
                      mobile={true}
                      data={post}
                      key={i}
                      callback={(data, image) => {
                        debugger;
                        if (data == "deleted") {
                          deletedPost();
                        } else {
                          setPostData(data);
                          setTags([...data?.tags]);
                          setImage(data?.image);
                          setVideo(data?.video);
                          setText(data?.text);
                          setOtherFiles(data?.otherFiles);
                          setTags(data?.tags);
                          setFile(data?.metadata);
                          console.log("image is ", image);

                          if (data?.type !== "" && data?.type !== "file") {
                            getDownloadURL(
                              ref(
                                storage,
                                data?.type === "image"
                                  ? data?.image
                                  : data?.video
                              )
                            )
                              .then((url) => {
                                data?.type === "image"
                                  ? setURL(url)
                                  : setVideo(url);
                              })
                              .catch((error) => {
                                // Handle any errors
                              });
                          }
                          setOpen(true);
                        }
                      }}
                    />
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        </TabPanel>

        {profile && profile?.userType === "startup" && value === 0 && (
          <StartupTopPriorities profile={profile} />
        )}
      </Stack>
      <Portal>
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
          open={posted}
        >
          <DialogTitle>
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
              sx={{ pt: 0, pb: 0, px: 0 }}
            >
              <Typography sx={{ fontWeight: "500" }}>
                {updated
                  ? arr2[(Math.random() * arr.length) | 0]
                  : arr[(Math.random() * arr.length) | 0]}
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack
              direction={"column"}
              justifyContent={"centre"}
              alignItems={"center"}
              sx={{ pt: 1, pb: 1 }}
            >
              <img src="/assets/post_success.png" alt="none" width={"90"} />
            </Stack>

            <Typography
              type={"h5"}
              textAlign={"center"}
              sx={{ fontWeight: "600", pl: 1, pr: 1 }}
            >
              {updated
                ? "Thanks for that edit! We’re glad you took the time to improve the post even more."
                : "Thank you for taking the time to share your thoughts and ideas with us."}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack sx={{ pt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  setPosted(false);
                  setUpdated(false);
                }}
              >
                Continue
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Portal>
      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setFile();
            setOpen(false);
            resetAll();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
              sx={{ pt: 0, pb: 0, px: 0 }}
            >
              <Avatar
                sx={{ height: 70, width: 70 }}
                imgProps={{ sx: { objectFit: "cover" } }}
                src={
                  profile.userType === "startup" &&
                  profile?.startup?.founder?.photoURL !== ""
                    ? profile?.startup?.founder?.photoURL
                    : profile?.photoURL
                }
              >
                <Typography variant={"h3"}>
                  {profile.userType === "startup"
                    ? profile?.startup?.founder?.founderName?.charAt(0) ||
                      profile?.displayName?.charAt(0)
                    : profile?.displayName?.charAt(0)}
                </Typography>
              </Avatar>
              <List sx={{ width: "100%", py: 0, my: 0 }}>
                <ListItem alignItems="flex-start" sx={{ pb: 2 }}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body1"
                          color="text.primary"
                        >
                          {" "}
                          {shortenURL(profile.userType === "startup"
                            ? profile?.startup?.founder?.founderName ||
                              profile?.displayName
                            : profile?.displayName,20)}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
              <Stack
                direction={"column"}
                sx={{ height: 83 }}
                justifyContent="flex-start"
                alignItems="center"
              >
                <IconButton
                  onClick={() => {
                    setFile();
                    setTag("");
                    setTags([]);
                    setText("");
                    setURL("");
                    setOpen(false);
                    resetAll();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <TextField
              variant={"standard"}
              fullWidth
              rows={8}
              multiline={true}
              onChange={(e) => setText(e.target.value)}
              sx={{
                minWidth: 500,
                borderBottom: "none !important",
              }}
              placeholder={"What do you want to talk about?"}
              defaultValue={postData && postData.text}
            />

            {/*<InputBase
              sx={{ ml: 1, width: 600 ,color:'#051E40BF',  }}
              placeholder="What do you want to talk about?"
              inputProps={{ 'aria-label': 'search post' }}
            />*/}
            <Stack direction={"row"} sx={{ pt: 3 }} spacing={3}>
              {file && file?.name && file?.type.includes("image") ? (
                <Stack direction={"row"}>
                  <img
                    alt="none"
                    src={URL}
                    style={{ width: "100%", height: "100%" }}
                  />

                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      width: 20,
                      height: 20,
                      ml: -4,
                      mt: 1,
                      mr: 1,
                      padding: 1.5,
                      color: "black",
                    }}
                    onClick={() => {
                      setFile();
                      setURL("");
                      resetAll();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              ) : file?.type.includes("video") ? (
                <Stack direction={"row"}>
                  <CardMedia component="video" image={URL} controls />
                  {/* <video
										controls
										src={URL}
										width="500"
										height="250"
									/> */}
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      width: 20,
                      height: 20,
                      ml: -4,
                      mt: 1,
                      mr: 1,
                      padding: 1.5,
                    }}
                    onClick={() => {
                      setFile();
                      setURL("");
                      resetAll();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              ) : (
                <></>
              )}{" "}
              {loading && <CircularProgress size={20} />}
            </Stack>
            <br></br>
            <div style={{ width: "100%" }}>
              {tags.length > 0 &&
                tags.map((option, index) => (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor: "#E0E3FF",
                      marginLeft: "5px",
                      marginRight: "5px",
                      marginBottom: "10px",
                    }}
                    onDelete={() =>
                      setTags(
                        tags.filter((f) =>
                          f !== option.value ? option.value : option
                        )
                      )
                    }
                    label={option.value ? option.value : option}
                  />
                ))}

              <InputBase
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    tag !== "" &&
                    !tags.find((tg) => tg === tag)
                  ) {
                    setTags([...tags, `#${tag}`]);
                    setTag("");
                  } else if (
                    e.key === " " &&
                    tag !== "" &&
                    !tags.find((tg) => tg === tag)
                  ) {
                    setTags([...tags, `#${tag}`]);
                    setTag("");
                  }
                }}
                size="medium"
                placeholder={tags.length > 0 ? null : "Add hashtag"}
                sx={{
                  transition: "0.1s linear",
                  border: "none!important",
                  outline: "none!important",
                  outlineColor: "#fff",
                  width: "100%",
                }}
              />

              {otherFiles !== "" && file && (
                <Typography variant={"caption"}>{file?.name}</Typography>
              )}
            </div>
          </DialogContent>
          <DialogActions sx={{ px: 2, py: 2 }}>
            <label htmlFor={"attachment-media"}>
              <Input
                id={"attachment-media"}
                type={"file"}
                onClick={(e) => (e.target.value = null)}
                accept={"image/*"}
                // accept={".jpg,.jpeg,.png,.gif,.webp"}
                onChange={async (e) => {
                  const list = [];
                  setLoading(true);
                  for (let file of e.target.files) {
                    console.log(file);
                    setFile(file);
                    const url = await UploadFile(
                      file,
                      `/m/timeline/${profile?.uid}/${file?.name}`
                    );
                    const image = new Image();
                    image.src = url;
                    console.log(image);
                    image.onload = function () {
                      console.log(this.width);
                      console.log(this.height);
                      setImageDimensions({
                        width:
                          this.width > 500
                            ? this.width / 2 < 300
                              ? 300
                              : 500
                            : this.width,
                        height:
                          this.height > 250
                            ? this.height / 2 < 125
                              ? 125
                              : 250
                            : this.height,
                      });
                    };
                    // console.log(URL);
                    setURL(url);
                    setLoading(false);
                    console.log(file);
                    list.push(`/m/timeline/${profile?.uid}/${file?.name}`);
                  }
                  setImage(list[0]);
                  console.log(imageDimensions);
                }}
              />
              <IconButton component={"span"}>
                <img src={"/assets/Vector (3).png"} />
              </IconButton>
            </label>

            <label htmlFor={"attachment-video"}>
              <Input
                id={"attachment-video"}
                type={"file"}
                accept={"video/*"}
                // accept={".mov,.mp4"}
                onClick={(e) => (e.target.value = null)}
                onChange={async (e) => {
                  const list = [];
                  setLoading(true);
                  for (let file of e.target.files) {
                    setFile(file);
                    const url = await UploadFile(
                      file,
                      `/m/timeline/${profile?.uid}/${file?.name}`
                    );
                    setURL(url);
                    setLoading(false);
                    console.log(file);

                    list.push(`/m/timeline/${profile?.uid}/${file?.name}`);
                  }

                  setVideo(list[0]);
                }}
              />
              <IconButton component={"span"}>
                <img src={"/assets/Vector (4).png"} />
              </IconButton>
            </label>

            <label htmlFor={"attachment-files"}>
              <Input
                id={"attachment-files"}
                accept={".pdf, .doc, .docx, .csv"}
                type={"file"}
                onClick={(e) => (e.target.value = null)}
                onChange={async (e) => {
                  const list = [];
                  setLoading(true);
                  for (let file of e.target.files) {
                    setFile(file);
                    const url = await UploadFile(
                      file,
                      `/m/timeline/${profile?.uid}/${file?.name}`
                    );
                    setURL(url);
                    setLoading(false);
                    console.log(file);

                    list.push(`/m/timeline/${profile?.uid}/${file?.name}`);
                  }

                  setOtherFiles(list[0]);
                }}
              />
              <IconButton component={"span"}>
                <AttachFileIcon />
              </IconButton>
            </label>
            {/* <IconButton>
							<img src={"/assets/Group 1193.png"} />
						</IconButton> */}
            <Tooltip
              title={<h3 style={{ color: "#E4EAF5" }}>Coming soon</h3>}
              placement="bottom"
            >
              <IconButton>
                <SentimentSatisfiedAltIcon onClick={() => {}} />
              </IconButton>
            </Tooltip>

            {/* {state?	<Picker onEmojiClick={(emojiData, event)=> setChosenEmoji(emojiData) && setState(false)} disableAutoFocus={true} native />:null}
						{chosenEmoji? <p>Symbol: {chosenEmoji.emoji}</p>:null} */}
            <Box sx={{ flex: 1 }} />
            <Button
              variant={"contained"}
              onClick={async () => {
                if (!postData) {
                  await addDoc(collection(DB, "timelines"), {
                    current: { likes: 0, comments: 0 },
                    previous: { likes: 0, comments: 0 },
                    text: text,
                    uid: profile?.uid,
                    creatorData: profile,
                    createdAt: serverTimestamp(),
                    type:
                      image !== ""
                        ? "image"
                        : video !== ""
                        ? "video"
                        : otherFiles !== ""
                        ? "file"
                        : "",
                    metadata: {
                      name:
                        image !== "" || video !== "" || otherFiles !== ""
                          ? file?.name
                          : "",
                      type:
                        image !== "" || video !== "" || otherFiles !== ""
                          ? file?.type
                          : "",
                    },
                    image,
                    video,
                    otherFiles,
                    tags: tags,
                    // chosenEmoji:chosenEmoji.emoji
                  });
                } else {
                  await updateDoc(
                    doc(collection(DB, "timelines"), postData.id),
                    {
                      current: { likes: 0, comments: 0 },
                      previous: { likes: 0, comments: 0 },
                      text: text,
                      uid: profile?.uid,
                      creatorData: profile,
                      updatedAt: serverTimestamp(),
                      type:
                        image !== ""
                          ? "image"
                          : video !== ""
                          ? "video"
                          : otherFiles !== ""
                          ? "file"
                          : "",
                      metadata: {
                        name:
                          image !== "" || video !== "" || otherFiles !== ""
                            ? file?.name
                            : "",
                        type:
                          image !== "" || video !== "" || otherFiles !== ""
                            ? file?.type
                            : "",
                      },
                      image,
                      video,
                      otherFiles,
                      tags: tags,
                      // chosenEmoji:chosenEmoji.emoji
                    },
                    { merge: true }
                  );
                  setUpdated(true);
                }
                setImage("");
                setVideo("");
                setText("");
                setTags([]);
                setFile();
                setPosted(true);
                setOpen(false);
                resetAll();
              }}
              disabled={text === "" && image == "" && video === ""}
            >
              {postData ? "UPDATE" : "POST"}
            </Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </Container>
  );
}
