import React from "react";

import {
  Box,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Select, Button, Avatar, styled, Paper, Portal, Dialog, DialogContent, IconButton,
} from "@mui/material";
import {
  collection, deleteDoc, doc, limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import { Close } from "@mui/icons-material";

export default function DraftProfiles() {
  const { profile } = React.useContext(AuthUserContext);
  

  
  const navigator = useNavigate()
  const [users, setUsers] = React.useState([])

  
  React.useEffect(() => {
    const q = query(
      collection(DB, "users"),
      where("onboard", "==", false),
      orderBy("createdAt", "desc"), limit(30)
    );
    const unSubs = onSnapshot(q, (querySnap) => {
      // debugger
      if (querySnap.size > 0) {
        const list = [];
        querySnap.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        
        setUsers(list);
      }
    });
    return () => unSubs;
  }, []);
  
  return (
    <Container
      maxWidth={"full-width"}
      sx={{ height: 1, py: 2}}>
    <Stack direction={"column"} spacing={5} alignItems={"start"} alignContent={"space-between"} width={'100%'} pb={5}>
      <Typography variant={"h4"} fontWeight={500}>Drafts <sup><font size={3}>({users?.length})</font></sup></Typography>
      <Grid container gap={5} justifyContent="flex-start"
            alignItems="center" px={5}>
  
        {users?.length > 0 && users?.map((d) => {
          let object = {}
          for (let prop in d){
            typeof d[prop] === 'object' ?
            object = {...object,  ...d[prop]  } : object[prop] = d[prop]
          }
          
          return (
            <GridItem data={{...object, id: d?.id}}
                      cbEdit={() => navigator(`/admin/on-boarding/type/${d?.id}`)}
                      cbDelete={() => deleteDoc(doc(DB, 'users', d?.id)).then()}
            />
          )
          
        })}
      
      </Grid>
    </Stack>
    
    </Container>
  );
}
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  px: theme.spacing(3),
  textAlign: "center",
  backgroundColor: "#fff",
  color: theme.palette.text.secondary,
  minHeight:"370px",
  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
  borderRadius: "15px",
  width: 350
}));
const GridItem = ({data, cbEdit, cbDelete}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
   
    <Grid
      item>
      <Item>
        <Stack direction={"column"} spacing={2} sx={{ width: "100%" }}
               justifyContent="center"
               alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0}
            width={'100%'}
          >
            <Button size={"small"} variant={"text"} color={"secondary"} sx={{color: '#8F8F8F'}} onClick={cbEdit}>
              Edit
            </Button>
            <Button size={"small"} variant={"text"} color={"secondary"} sx={{color: '#8F8F8F'}} onClick={()=>setOpen(true)}>
              Delete
            </Button>
          </Stack>
          <Box
            sx={{
              position: "relative",
              top:"40px",
              bottom : "20px",
              width: "100%",
              height: "150px",
              background: "#F6F8FA",
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px"
            }}
          >
            <Stack
              direction={"column"}
              spacing={2}
              justifyContent="center"
              alignItems="center">
              <Box
                sx={{
                  border: "1px solid #204488",
                  p: 0.75,
                  borderRadius: "50%",
                  marginTop : "-40px"
                }}>
                <Avatar
                  alt={data?.displayName}
                  src={data?.photoURL}
                  sx={{ width: 70, height: 70 }}>
                  <Typography variant={"h5"}>
                    {data?.displayName &&
                      data?.displayName.charAt(0)}
                  </Typography>
                </Avatar>
              </Box>
              <Typography
                variant={"h6"}
                sx={{ fontWeight: 600, mb: 0, lineHeight: 1.2 }}>
                {data?.displayName} <br />
                <font size={3} style={{ fontWeight: 300, textTransform: 'capitalize' }}>
                  {data?.type === 'startup' ? `founder${data?.areYouAnAngelInvestor ? ' | investor': ''}` : data?.type === 'business-developer' ? 'Growth Expert' : data?.type === 'expert' ? 'Industry Expert' : data?.type.replace('-', ' ')}
                </font>
              </Typography>
              <Box />
            </Stack>
          </Box>
        
          <Box />
          <Box />
          <Box />
          {data && data?.type === 'investor' && data?.investorType && (
            <Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
              <Typography
                variant={"body2"}
                sx={{
                  fontWeight: "bold",
                  flex: 1,
                  textAlign: "left",
                  width:"30%"
                }}>
                Investor Type
              </Typography>
              <Typography variant={"body2"} >
                {data?.investorType}
              </Typography>
            </Stack>
          )}
          {data && data?.type === 'investor'&&
            data?.investmentStage&&
            data?.investmentStage.length > 0 &&  (
              <Stack  direction={"row"} spacing={2} sx={{width: '100%'}}>
                <Typography
                  variant={"body2"}
                  sx={{
                    fontWeight: "bold",
                    flex: 1,
                    textAlign: "left",
                  
                  
                  }}>
                  Investor Stage
                </Typography>
                <Typography variant={"body2"} sx={{ width: 130, textAlign: 'right' }}
                            noWrap={true}>
                  {/* {data?.investor?.investmentStage} */}
                  {data?.investmentStage.map((d, i) =>
                    d !==
                    data?.investmentStage[
                    data?.investmentStage.length - 1
                      ]
                      ? `${d}, `
                      : d
                  )}
                </Typography>
              </Stack>
            )}
  
  
          {data && (data?.type === 'business-developer' || data?.type === 'expert') &&
            data?.expertise &&
            data?.expertise.length > 0 && (
              <Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
                <Typography
                  variant={"body2"}
                  sx={{
                    fontWeight: "bold",
                    flex: 1,
                    textAlign: "left",
                  }}>
                  Expertise
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{ width: 130 }}
                  noWrap={true}>
                  {data?.expertise.map((d, i) =>
                    d !==
                    data?.expertise[
                    data?.expertise.length - 1
                      ]
                      ? `${d}, `
                      : d
                  )}
                </Typography>
              </Stack>
            )}
  
          {data && (data?.type === 'business-developer' || data?.type === 'expert') &&
            data?.supportStage &&
            data?.supportStage.length > 0 && data?.supportStage?.map && (
              <Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
                <Typography
                  variant={"body2"}
                  sx={{
                    fontWeight: "bold",
                    flex: 1,
                    textAlign: "left",
            
            
                  }}>
                  Support Startup at Stage
                </Typography>
                <Typography variant={"body2"} sx={{ width: 130 }}
                            noWrap={true}>
                  {/* {data?.expert?.supportStage} */}
                  {data?.supportStage
                    .map((d, i) =>
                      d !==
                      data?.supportStage[
                      data?.supportStage.length - 1
                        ]
                        ? `${d}, `
                        : d
                    )}
                </Typography>
              </Stack>
            )}
  
          {data && data?.type === 'service-provider' && data?.organisationType && (
            <Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
              <Typography
                variant={"body2"}
                sx={{
                  fontWeight: "bold",
                  flex: 1,
                  textAlign: "left",
                }}>
                Service Provider Type
              </Typography>
              <Typography variant={"body2"} sx={{}}>
                {data?.organisationType}
              </Typography>
            </Stack>
          )}
          {data && data?.type === 'service-provider' &&
            data?.serviceType&&
            data?.serviceType.length > 0 &&  (
              <Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
                <Typography
                  variant={"body2"}
                  sx={{
                    fontWeight: "bold",
                    flex: 1,
                    textAlign: "left",
                  }}>
                  Service Type
                </Typography>
                <Typography variant={"body2"} sx={{ width: 130, textAlign: "right" }}
                            noWrap={true}>
                  {/* {data?.serviceProvider?.serviceType} */}
                  {data?.serviceType.map((d, i) =>
                    d !==
                    data?.serviceType[
                    data?.serviceType?.length - 1
                      ]
                      ? `${d}, `
                      : d
                  )}
                </Typography>
              </Stack>
            )}
  
  
          {data && data?.type === 'startup' &&
            <>
              {data && data?.areYouAnAngelInvestor &&
                data?.sector &&
                data?.sector?.length > 0 &&  (
                  <Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
                    <Typography
                      variant={"body2"}
                      sx={{
                        fontWeight: "bold",
                        flex: 1,
                        textAlign: "left",
                      }}>
                      Sector
                    </Typography>
                    <Typography
                      variant={"body2"}
                      sx={{ width: 130, textAlign: "right" }}
                      title={data?.sector.map((d, i) =>
                        d !==
                        data?.sector[
                        data?.sector?.length - 1
                          ]
                          ? `${d}, `
                          : d
                      )}
                      noWrap={true}>
                      {/* {data?.startup?.sector} */}
                      {data?.sector.map((d, i) =>
                        d !==
                        data?.sector[
                        data?.sector.length - 1
                          ]
                          ? `${d}, `
                          : d
                      )}
                    </Typography>
                  </Stack>
                )}
              {data && data?.areYouAnAngelInvestor &&
                data?.investmentStage &&
                data?.investmentStage?.length > 0 &&  (
                  <Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
                    <Typography
                      variant={"body2"}
                      sx={{
                        fontWeight: "bold",
                        flex: 1,
                        textAlign: "left",
                      }}>
                      Investor stage
                    </Typography>
                    <Typography
                      variant={"body2"}
                      sx={{ width: 130, textAlign: "right" }}
                      title={data?.investmentStage.map((d, i) =>
                        d !==
                        data?.investmentStage[
                        data?.investmentStage?.length - 1
                          ]
                          ? `${d}, `
                          : d
                      )}
                      noWrap={true}>
                      {/* {data?.startup?.sector} */}
                      {data?.investmentStage.map((d, i) =>
                        d !==
                        data?.investmentStage[
                        data?.investmentStage.length - 1
                          ]
                          ? `${d}, `
                          : d
                      )}
                    </Typography>
                  </Stack>
                )}
            
            </>
          }
          
        </Stack>
      </Item>
    </Grid>
    <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"sm"}
        >
          <DialogContent sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Close/>
              </IconButton>
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={2}>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                alignItems={"center"}
              >
                <img
                  src={"/assets/delete-confirmation.png"}
                  height={74}
                  width={80} alt="delete"
                ></img>
              </Stack>
              <Typography
                component="span"
                variant="h6"
                color="text.primary"
                sx={{
                  textAlign: "center",
                  flex: 1,
                  padding: 0,
                  fontWeight: 500,
                }}
              >
                Delete Profile Draft
              </Typography>
              <Typography variant={"body2"} sx={{ textAlign: "center" }}>
                Are you sure you want to delete this profile draft? <br />
                This action cannot be undone.
              </Typography>
              <br />
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Button
                  variant={"outlined"}
                  sx={{
                    px: 5,
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  variant={"contained"}
                  sx={{
                    px: 5,
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={()=>{setOpen(false);cbDelete()}}
                >
                  Yes
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </>
  )
}
