import axios from "axios";


export default function ResetPasswordEmailApi(data) {

  return axios.post(
    'https://us-central1-astranovaapp.cloudfunctions.net/api/reset-password-email',
    // 'http://localhost:5001/systema-nova/us-central1/api/reset-password-email',
    {...data},
    {headers: {'Content-Type': 'application/json'}}
  )

}
