import {IconButton, Box, Button, Stack, Typography} from "@mui/material";
import React from "react";
import {EditSharp} from "@mui/icons-material";
import AboutServiceProviderComponent
	from "../../../../../components/common/profile/edit/service-provider/about-service-provider-component";
const styleFont = {
	backgroundColor: "#F6F8FA",
	padding: "6px 10px",
	marginLeft:'3px',
	borderRadius: "5px",
	fontSize: 12
};

export default function ServiceProviderAbout({ profile }) {
	const [open,setOpen] = React.useState(false)
	const [seeMore, setSeeMore] = React.useState(false)
	const profileRegex = /profile/
	return ( 
		<>
		{console.log(profile?.serviceProvider)}


			{profile.userType  === 'service-provider' &&
		<>
		<Stack
                direction={"column"}
                spacing={3}
                sx={{
					// border: '0.5px solid #000000',
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 3,
                  py: 3,
                }}>
			<Stack direction={"row"} spacing={2}  justifyContent="flex-end"
				   alignItems="center" sx={{width: '100%'}}>
				<Typography variant="h6" fontWeight={600} sx={{flex:1}}>About Service Provider</Typography>
				{profileRegex.test(window.location.pathname) &&  <IconButton onClick={() => setOpen(true)}>
					<EditSharp />
				</IconButton>}
			</Stack>
			<Stack direction={"column"} spacing={4}>
			  { profile.serviceProvider.organisationType === 'Organisation' && 
			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Role<br/> <font style={styleFont}>
				  {profile?.serviceProvider?.role}
				  </font>
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>}
			{/* <Grid container  gap={1} sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}> */}
			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Service type<br/>
				  {profile?.serviceProvider?.serviceType.map((element,index)=>(
					<font style={styleFont}>{element} </font>
				  )

				  ) }
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
			{console.log(profile.serviceProvider.additionalDetails)}
	
			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Sector<br/> 
				  {profile?.serviceProvider?.sector &&
					profile?.serviceProvider?.sector.length > 0 &&
					profile?.serviceProvider?.sector.map((element,index)=>(
					<font style={styleFont}>{element} </font>
				  )

				  ) }
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>

			  {profile?.serviceProvider?.additionalDetails.length!==0 &&
			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				Additional Details<br/> 
				{profile?.serviceProvider?.additionalDetails &&
					profile?.serviceProvider?.additionalDetails.length > 0 &&
					profile?.serviceProvider?.additionalDetails.map(
											(ex, i) => (
												<font style={styleFont}>
												{ex}


												</font>
											)
										)}

				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
}
			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Geographies<br/> 
				  {profile?.serviceProvider?.geographies.map((element,index)=>(
					<font style={styleFont}>{element} </font>
				  )

				  ) }
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
	
			</Stack>
			{/* <Grid container  gap={1} sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}> */}
			<Stack direction={"column"} spacing={4}>
			
			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Relevant years of experience<br/> <font style={styleFont}>
				  {profile?.serviceProvider?.relevantYearOfExperience} </font>
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>
			
			  {seeMore ?
				<>
				<Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Support startup at stage<br/> 
				  {profile?.serviceProvider?.supportStage  &&
					profile?.serviceProvider?.supportStage.length > 0 &&
					profile?.serviceProvider?.supportStage.map((element,index)=>(
						<font style={styleFont}>{element}</font>
						)
						
						) }
				</Typography>
              <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
			  </Stack>

			  <Stack direction={'column'} spacing={1}>
				<Typography variant={'h6'} sx={{lineHeight:2}}>
				  Happy to provide service for some share of the equity?<br/> <font style={styleFont}>{
					  profile?.serviceProvider?.happyToProvideServiceForSomeShareOfTheEquity ? 'Yes': 'No' }
				</font>
				</Typography>
             
			  </Stack>
			  <Stack alignItems={'center'}>
				<Box>
				<Button size={"small"} variant='outlined' color="secondary"  sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See Less</Button>
				</Box>
			  </Stack> 
			</>
						:
			<Stack alignItems={'center'}>
				<Box>
				<Button size={'small'} variant='outlined' color="secondary"  sx={{borderRadius:"5px"}} onClick={()=> setSeeMore(!seeMore)}>See More</Button>
				</Box>
			</Stack> 
			  }

			</Stack>
			</Stack>
			<AboutServiceProviderComponent profile={profile?.serviceProvider} open={open} handleClose={() => setOpen(false)}/>
		  </>
		  }
		</>
	);
}

