import React, { useState } from "react";
import {
  Button,
  Container,
  Stack,
  TextField,
  Typography, useMediaQuery,
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {collection, doc, getDocs, updateDoc, where, query, setDoc} from "firebase/firestore";
import {auth, DB} from "../../config/firebase/firebase";
import {getAuth} from "firebase/auth";
import NotificationEmail from "../../api/notification-email";
import PostNotification from "../../api/post-notification";
import GenerateReferralCode from "../../api/generate-referral-code";

export default function LoginWithSocialReferralScreen() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const navigator = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  const {type, data} = location?.state || {type: 'google', data: {photoURL: ''}}
  const formik = useFormik({
    initialValues: {referralCode: ''},
    validationSchema:  yup.object({
      referralCode: yup.string('Referral code').required('Referral code is required.'),

    }),
    onSubmit: async (values) => {
      const userRef = doc(DB, "users", auth.currentUser.uid);
      const q = query(
        collection(DB, "users"),
        where("referralCode", "==", values?.referralCode)
      );
      debugger;
      const querySnapshot = await getDocs(q);
      if (querySnapshot?.size > 0) {
        verifyReferral(data?.displayName).then()
        await setDoc(
          userRef,
          {
            ...data
          },
          { merge: true }
        );
        navigator(`${matches ? '/': '/m/'}on-boarding/type`);

      } else {

        formik.setFieldError(
          "referralCode",
          "Please enter the valid referral code."
        );
      }
    },
  });
 /* GenerateReferralCode({uid:auth.currentUser.uid, displayName})
    .then()*/
  const verifyReferral = async (displayName) => {
    const q = query(
      collection(DB, "users"),
      where("referralCode", "==", formik?.values?.referralCode)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot?.size > 0) {
      const auth = getAuth();
      const doco = {
        ...querySnapshot?.docs[0]?.data(),
        id: querySnapshot?.docs[0]?.id,
      };
      const userRef = doc(DB, "users", doco.id);
      const earning = doco?.earning ? doco?.earning + 50 : 50;
      const referList = doco?.referList
        ? [...doco?.referList, auth?.currentUser?.uid]
        : [auth?.currentUser?.uid];
      await updateDoc(userRef, {
        earning,
        referList,
        updatedAt: new Date().getTime(),
      });
      GenerateReferralCode({uid:auth.currentUser.uid, displayName})
        .then()
      await NotificationEmail({
        type: "earning",
        email: doco.email,
        name: displayName,
      });
      PostNotification({
        type: "earned-points",
        imageURL: data?.photoURL ? data.photoURL : "",
        message: `Congrats! You earned 50 points since your friend ${displayName} just joined AstraNova.`,
        uid: doco.id,
        name: displayName,
      }).then();
    }
  };
  return (
    <>
      <Container maxWidth={"sm"} sx={{ mt: 10}}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={5}
          component="form"
          onSubmit={(e) => formik.handleSubmit(e)}>
          <Typography
            variant={matches ? "h4" : 'h6'}
            lineHeight={1.5}
            sx={{ textAlign: "center", width: "100%",  fontFamily: "Poppins"}}>
            Please enter the referral code to Sign up using {type !== 'google' ? 'LinkedIn' : 'Google' }
          </Typography>

          <img src={type !== 'google' ? '/assets/linkedin-logo.png' : '/assets/google-logo.png'} height={type !== 'google' ? 50 : 70} style={{display: "block", margin: '30px auto 10px'  }}/>
          <Typography variant={"body1"} >
            Please note that a referral code is required to proceed
            with the registration process.
          </Typography>
          <TextField
						name={"referralCode"}
						value={formik.values.referralCode}
						onChange={formik.handleChange}

						error={
							formik.touched.referralCode &&
							Boolean(formik.errors.referralCode)
						}
						helperText={
							formik.touched.referralCode && formik.errors.referralCode
						}
						// variant={"outlined"}
						label={"Referral code"}
						fullWidth
						sx={{ textAlign: "left" }}/>
          <Button
            disabled={formik?.values.referralCode === ''}
            fullWidth
            sx={{ height: "48px" }}
            variant={"contained"}
            color={"primary"}
            type={"submit"}>
            Join now
          </Button>
        </Stack>
      </Container>
    </>
  );
}
