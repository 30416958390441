import * as React from "react";
import {
  Container,
  Stack,
  Typography,
  Box,
  Avatar,
  Button,
  Portal,
  List,
  ListItem,
  ListItemText,
  TextField,
  styled,
  CircularProgress,
  Chip,
  Autocomplete,
  Switch, IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";
import {AuthUserContext} from "../../providers/auth-provider";

export default function SettingScreen() {
  const { profile } =
    React.useContext(AuthUserContext);
  const [isInvestor ,setInvestor] = React.useState(false)
  const [isExpert ,setExpert] = React.useState(false)
  const [isBusinessDev ,setBusinessDev] = React.useState(false)
  const [isTalent ,setTalent] = React.useState(false)
  const [isServiceProvider ,setServiceProvider] = React.useState(false)
  const navigator = useNavigate()
  return(
    <React.Fragment>
      <Container
        maxWidth={"xl"}
        sx={{ textAlign: "left", paddingY: 5}}>
        <Stack direction={"column"} spacing={5}
        >
          <Stack
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            width={"100%"}>
            <IconButton onClick={() => navigator(-1)}>
              <ArrowBackIcon sx={{ fontSize: 36 , color: '#000'}} />
            </IconButton>
            <Typography
              variant={"h4"}
            >
              Setting
            </Typography>

          </Stack>
          <Box sx={{backgroundColor: '#F6F8FA', border: '1px solid #000', borderRadius: '8px', p: 3, width: '100%', cursor: 'pointer'}} onClick={() => navigator(`/${profile?.userType}/change-password`) }>
            <Typography
              variant={"h6"}
            >
              Change password
            </Typography>
          </Box>
        
        </Stack>

      </Container>
    </React.Fragment>
  )
}