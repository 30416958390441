import * as yup from "yup";
import { DEFAULT_LANGUAGE, DEFAULT_MARKET } from "../../utils/enums/labels.enum";

const ServiceProviderOnBoardingProfileSchema = yup.object({
	photoURL: yup.string("Photo URL"),
	description: yup
		.string("Description")
		.required("Description required.")
		.test(
			"wordCount",
			"Word count should be less than 150",
			function (value) {
				return value.split(" ").length <= 150;
			}
		),
	providerName: yup.string("Name").required("Name required."),
	type: yup.string("Type").required("Type required."),
});
const ServiceProviderOnBoardingProfileInitValue = {
	photoURL: "",
	description: "",
	providerName: "",
	type: "",
};
const ServiceProviderOnBoardingProfileSecondSchema = yup.object({
	socialProof: yup
		.array("Social Proof (References for specific kinds of expertise)")
		.of(yup.string(""))
		.nullable(),
	links: yup.array("Additional links").of(yup.string("")).nullable(),
	supportStage: yup.array("Support Stage").of(yup.string("")),
	spoc: yup.string("SPOC"),
});
const ServiceProviderOnBoardingProfileSecondInitValue = {
	socialProof: [],
	links: [""],
	supportStage: [],
	spoc: "",
};
const ServiceProviderOnBoardingProfileThirdSchema = yup.object({
	availableForStartups: yup.boolean("Available for startups"),
	availableToNova: yup.boolean("Available to Nova"),
	happyToRespondToEnquiries: yup.boolean("Happy to respond to enquiries?"),
	happyToProvideServiceForSomeShareOfTheEquity: yup.boolean(
		"Happy to provide service for some share of the equity?"
	),
});
const ServiceProviderOnBoardingProfileThirdInitValue = {
	availableForStartups: false,
	availableToNova: false,
	happyToRespondToEnquiries: false,
	happyToProvideServiceForSomeShareOfTheEquity: false,
};


const ProviderFirstForm = yup.object({
	organisationType: yup
		.string("Service Provider Type")
		.required("Service Provider Type*")
});
const ProviderFirstFormValues = {
	organisationType: '',
}

const ProviderOrganizationForm = yup.object({
	logoURL: yup.string("logo URL"),
	organisationType: yup
		.string("Service Provider Type")
		.required("Service Provider Type*"),
	nameOfOrganization: yup.string("Name of organization").when('organisationType',(organisationType, field)=> organisationType==="Individual" ? field : field.required("Please provide name of organization") ),
	website: yup.string("Website").url('Please enter valid URL'),
	organizationDescription: yup.string("Organization description").max(
		150,
		"Character Limit Exceeded i.e. 150 Characters"
	).when('organisationType',(organisationType, field)=> organisationType==="Individual" ? field : field.required("Description of organization is required") ),
});
const ProviderOrganizationFormValues = {
	logoURL:'',
	organisationType: '',
	nameOfOrganization: '',
	website: '',
	organizationDescription: '',
}
const ProviderSecondForm = yup.object({
	photoURL: yup.string("Photo URL"),
	role: yup.string("Role"),
	displayName: yup.string("Name of organization").required("Your name is required").min(3, 'Name should be of minimum 3 characters length')
    .max(35, 'Name should be of maximum 35 characters length'),
	linkedinURL: yup
		.string("Linkedin URL")
		.matches(
			"^(http(s)?://)?([w]+.)?linkedin.com/(pub|in|profile)",
			"Invalid Linkedin URL"
		),
	description: yup.string("Description").max(
		150,
		"Character Limit Exceeded i.e. 150 Characters"
	),
})
const ProviderSecondFormValues = {
	photoURL: "",
	displayName: "",
	linkedinURL: "",
	description: "",
	role: "",
};

const ProviderThirdForm = yup.object({
	serviceType:  yup
		.array('Service Type')
		.of(yup.string(''))
		.required('Service Type*').min(1, "Please select service type"),
	sector:  yup
		.array('Sector')
		.of(yup.string(''))
		.required('Sector*').min(1, "Sector*"),
	geographies:  yup
		.array('Geographies')
		.of(yup.string(''))
		.required('Geographies*').min(1, "Geographies*"),
	relevantYearOfExperience: yup.string('Relevant Years of Experience').required('Relevant years of experience is a required field*'),
	additionalDetails: yup.array('Additional Details')
		.of(yup.string('')).nullable(),
	languages: yup.array('Languages').of(yup.string('')).required('Languages*').min(1, "Languages*"),
})
const ProviderThirdFormValue = {
	serviceType: ["Law Firm",
		"Advertising Agency"],
	sector: [],
	geographies: [DEFAULT_MARKET],
	languages: [DEFAULT_LANGUAGE],
	relevantYearOfExperience: '',
	additionalDetails: [],
}

const EditAboutProviderForm = yup.object({
	// organisationType: yup
	// 	.string("Service Provider Type")
	// 	.required("Service Provider Type*"),
	// nameOfOrganization: yup.string("Name of organization"),
	// website: yup.string("Website").url('Please enter valid URL'),
	// organizationDescription: yup.string("Organization description"),
	serviceType:  yup
		.array('Service Type')
		.of(yup.string(''))
		.required('Service Type*'),
	sector:  yup
		.array('Sector')
		.of(yup.string(''))
		.required('Sector*'),
	geographies:  yup
		.array('Geographies')
		.of(yup.string(''))
		.required('Geographies*'),
	languages:  yup
		.array('Languages')
		.of(yup.string(''))
		.required('Languages*'),
	relevantYearOfExperience: yup.string('Relevant Years of Experience').required('Relevant years of experience is a required field*'),
	additionalDetails: yup.array('Additional Details')
		.of(yup.string('')).nullable(),
	role: yup.string("Role"),
})
const EditAboutProviderFormValue = {
	// organisationType: '',
	// nameOfOrganization: '',
	// website: '',
	// organizationDescription: '',
	serviceType: '',
	sector: '',
	geographies: '',
	languages: '',
	relevantYearOfExperience: '',
	additionalDetails: '',
	role: '',
}
const EditOrganisationDetailsForm = yup.object({
	nameOfOrganization: yup.string("Organisation name"),
	organizationDescription: yup.string("Description"),
	countryState: yup.string("Location")
});
const EditOrganisationDetailsFormValues = {
	nameOfOrganization: '',
	organizationDescription: '',
	countryState: ''
};
const EditOrganisationAboutForm = yup.object({
	website: yup.string("Website"),
	serviceType:  yup
		.array('Service Type')
		.of(yup.string(''))
		.required('Service Type*').min(1, "Please select service type"),
	sector:  yup
		.array('Sector')
		.of(yup.string(''))
		.required('Sector*').min(1, "Sector*"),
});
const EditOrganisationAboutFormValues = {
	website: '',
	serviceType: ["Law Firm",
		"Advertising Agency"],
	sector: [],
};
const ServiceProviderDetailsValidation = {
	ServiceProviderOnBoardingProfileSchema,
	ServiceProviderOnBoardingProfileInitValue,
	ServiceProviderOnBoardingProfileSecondSchema,
	ServiceProviderOnBoardingProfileSecondInitValue,
	ServiceProviderOnBoardingProfileThirdSchema,
	ServiceProviderOnBoardingProfileThirdInitValue,
	ProviderFirstForm,
	ProviderFirstFormValues,
	ProviderOrganizationForm,
	ProviderOrganizationFormValues,
	ProviderSecondForm,
	ProviderSecondFormValues,
	ProviderThirdForm,
	ProviderThirdFormValue,
	EditAboutProviderForm,
	EditAboutProviderFormValue,
	EditOrganisationDetailsForm,
	EditOrganisationDetailsFormValues,
	EditOrganisationAboutForm,
	EditOrganisationAboutFormValues,
};

export default ServiceProviderDetailsValidation;
