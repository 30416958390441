import React, { useEffect } from "react";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Tooltip, Typography, FormControlLabel, Switch, CircularProgress, useMediaQuery
} from "@mui/material";
import { useFormik } from "formik";
import ExportOnboardingValidation from "../../../validations/expert/onboarding-profile";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';
import dataJson from '../../../const/data.json';
export default function AboutEditFormExpert({ cbBack, cbNext, data, isLoading }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const formikFirst = useFormik({
    initialValues: data
      ? data
      : {...ExportOnboardingValidation.ExpectationsFormValues,...ExportOnboardingValidation.ExpertSecondFormValues},
    validationSchema:
    ExportOnboardingValidation.EditForm,
    onSubmit: async (values) => {

      cbNext({
        ...values
      });
    },
  });

  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>
          <Autocomplete
            multiple
            options={[
              "Growth Expert",
              "Sales",
              "Pricing"
            ].map(
              (option) => option
            )}
            value={formikFirst.values?.expertise}
            freeSolo
            onChange={(
              e,
              newValue
            ) =>
              formikFirst.setFieldValue('expertise', newValue)
            }
            renderTags={(
              value,
              getTagProps
            ) =>
              formikFirst.values?.expertise &&
              formikFirst.values?.expertise.length > 0
              &&	formikFirst.values?.expertise.map(
                (
                  option,
                  index
                ) => (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor:
                        "#E3E8F0",
                      marginLeft:
                        "5px",
                      marginRight:
                        "5px",
                      marginBottom:
                        "10px",
                    }}
                    deleteIcon={
                      <CloseIcon />
                    }
                    onDelete={() =>
                      formikFirst.setFieldValue('expertise',
                        formikFirst.values?.expertise.filter(
                          (
                            f
                          ) =>
                            f !==
                            option
                        )
                      )
                    }
                    label={
                      option
                    }
                    {...getTagProps(
                      {
                        index,
                      }
                    )}></Chip>
                )
              )
            }

            renderInput={(
              params
            ) => (
              <TextField
                {...params}
                // variant={'standard'}
                fullWidth
                name={'expertise'}
                // required={formikFirst.values.expertise.length === 0 }
                error={Boolean(formikFirst.errors.expertise)}
								helperText={
									formikFirst.errors.expertise
										? "Expertise is required"
										: ""
								}
                
                label="Expertise *"
                placeholder="Add"
                sx={{
                  transition:
                    "0.1s linear",
                }}
              />
            )}
          />


          <Stack direction={{xs:'column',sm:'column', md: 'row', lg: 'row'}} spacing={5}>
            <Autocomplete
              multiple
              fullWidth
              options={[
                "Adtech",
                "Advanced manufacturing",
                "Agtech",
                "Artificial intelligence and machine learning (AI/ML)",
                "Audiotech",
                "Augmented reality (AR)",
                "Autonomous cars",
                "B2B payments",
                "Beauty",
                "Big Data",
                "Cannabis",
                "Carsharing",
                "Cleantech",
                "Climate tech",
                "Cloudtech and DevOps",
                "Construction technology",
                "Cryptocurrency and blockchain",
                "Cybersecurity",
                "Digital health",
                "Ecommerce",
                "Edtech",
                "Ephemeral content",
                "eSports",
                "Femtech",
                "Fintech",
                "Foodtech",
                "Gaming",
                "Healthtech",
                "HRtech",
                "Impact investing",
                "Industrials",
                "Infrastructure",
                "Insurtech",
                "Internet of Things (IoT)",
                "Legal tech",
                "Life sciences",
                "Lifestyles of Health and Sustainability (LOHAS) and wellness",
                "Manufacturing",
                "Marketing tech",
                "Micro-mobility",
                "Mobile",
                "Mobile commerce",
                "Mobility tech",
                "Mortgage tech",
                "Nanotechnology",
                "Oil and gas",
                "Oncology",
                "Pet tech",
                "Real estate tech",
                "Restaurant tech",
                "Ridesharing",
                "Robotics and drones",
                "Software as a service (SaaS)",
                "Space tech",
                "Supply chain technology",
                "Technology, media and telecommunications (TMT)",
                "Virtual reality (VR)",
                "Wearables and quantified self",
                "3D printing",
              ].map(
                (option) => option
              )}
              value={formikFirst.values?.sector}
              freeSolo
              onChange={(
                e,
                newValue
              ) =>
                formikFirst.setFieldValue('sector', newValue)
              }
              renderTags={(
                value,
                getTagProps
              ) =>
                formikFirst.values?.sector &&
                formikFirst.values?.sector.length > 0
                &&	formikFirst.values?.sector.map(
                  (
                    option,
                    index
                  ) => (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor:
                          "#E3E8F0",
                        marginLeft:
                          "5px",
                        marginRight:
                          "5px",
                        marginBottom:
                          "10px",
                      }}
                      deleteIcon={
                        <CloseIcon />
                      }
                      onDelete={() =>
                        formikFirst.setFieldValue('sector',
                          formikFirst.values?.sector.filter(
                            (
                              f
                            ) =>
                              f !==
                              option.value ? option.value : option
                          )
                        )
                      }
                      label={
                        option.value ? option.value : option
                      }
                      {...getTagProps(
                        {
                          index,
                        }
                      )}></Chip>
                  )
                )
              }
              renderInput={(
                params
              ) => (
                <TextField
                  {...params}
                  // variant={'standard'}
                  name={'sector'}
                  fullWidth
                  // required={formikFirst.values.sector.length === 0 }
                  error={
                    formikFirst.touched.sector &&
                    Boolean(formikFirst.errors.sector)
                  }
                  helperText={
                    formikFirst.touched.sector &&
                    formikFirst.errors.sector
                  }
								
                  label="Sector *"
                  placeholder="Add"
                  sx={{
                    transition:
                      "0.1s linear",
                  }}
                />
              )}
            />


            {/* <Autocomplete
              multiple
              required
              fullWidth
              freeSolo
              options={
                [
                  // " "
                ]
                  .map((option) => option)}
              onChange={(e, newValue) =>
                formikFirst.setFieldValue(
                  "additionalDetails",
                  newValue
                )
              }
              value={formikFirst.values?.additionalDetails}
              renderTags={(value, getTagProps) =>
                formikFirst.values?.additionalDetails &&
                formikFirst.values?.additionalDetails.length >
                0 &&
                formikFirst.values?.additionalDetails.map(
                  (option, index) => (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E3E8F0",
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginBottom: "10px",
                      }}
                      deleteIcon={<CloseIcon />}
                      onDelete={() =>
                        formikFirst.setFieldValue(
                          "additionalDetails",
                          formikFirst.values?.additionalDetails.filter(
                            (f) => f !== option
                          )
                        )
                      }
                      label={option}
                      {...getTagProps({
                        index,
                      })}></Chip>
                  )
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // variant={'standard'}

                  label="Additional Details"
                  name={"additionalDetails"}
                  placeholder="Add"
                  sx={{
                    transition: "0.1s linear",
                  }}
                />
              )}
            /> */}

            {/* <TextField
							name={"additionalDetails"}
							value={formikFirst.values.additionalDetails}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.additionalDetails &&
								Boolean(formikFirst.errors.additionalDetails)
							}
							fullWidth
							helperText={
								formikFirst.touched.additionalDetails &&
								formikFirst.errors.additionalDetails
							}
							label={"Additional details"}
						/> */}


          </Stack>

          <Autocomplete
            multiple
            required
            fullWidth
            options={[
              ...dataJson.markets
            ].map(
              (option) => option
            )}
            onChange={(
              e,
              newValue
            ) =>
              formikFirst.setFieldValue('geographies', newValue)
            }
            value={formikFirst.values?.geographies}
            renderTags={(
              value,
              getTagProps
            ) =>
              formikFirst.values?.geographies &&
              formikFirst.values?.geographies.length > 0
              &&	formikFirst.values?.geographies.map(
                (
                  option,
                  index
                ) => (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor:
                        "#E3E8F0",
                      marginLeft:
                        "5px",
                      marginRight:
                        "5px",
                      marginBottom:
                        "10px",
                    }}
                    deleteIcon={
                      <CloseIcon />
                    }
                    onDelete={() =>
                      formikFirst.setFieldValue('geographies',
                        formikFirst.values?.geographies.filter(
                          (
                            f
                          ) =>
                            f !==
                            option
                        )
                      )
                    }
                    label={
                      option
                    }
                    {...getTagProps(
                      {
                        index,
                      }
                    )}></Chip>
                )
              )
            }
            renderInput={(
              params
            ) => (
              <TextField
                {...params}
                // variant={'standard'}
                label={
                  <>
                    Geographies
                    <Tooltip title="Countries and regions" arrow placement="top-start">

                      <InfoIcon  fontSize="small" style={{ marginLeft:"6px",color:"#D9D9D9"}}/></Tooltip>
                  </>
                }
                placeholder="Add"
                sx={{
                  transition:
                    "0.1s linear",
                }}
              />
            )}
          />

<Autocomplete
            multiple
            required
            fullWidth
            options={[
              ...dataJson.languages
            ].map(
              (option) => option
            )}
            onChange={(
              e,
              newValue
            ) =>
              formikFirst.setFieldValue('languages', newValue)
            }
            value={formikFirst.values?.languages}
            renderTags={(
              value,
              getTagProps
            ) =>
              formikFirst.values?.languages &&
              formikFirst.values?.languages.length > 0
              &&	formikFirst.values?.languages.map(
                (
                  option,
                  index
                ) => (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor:
                        "#E3E8F0",
                      marginLeft:
                        "5px",
                      marginRight:
                        "5px",
                      marginBottom:
                        "10px",
                    }}
                    deleteIcon={
                      <CloseIcon />
                    }
                    onDelete={() =>
                      formikFirst.setFieldValue('languages',
                        formikFirst.values?.languages.filter(
                          (
                            f
                          ) =>
                            f !==
                            option
                        )
                      )
                    }
                    label={
                      option
                    }
                    {...getTagProps(
                      {
                        index,
                      }
                    )}></Chip>
                )
              )
            }
            renderInput={(
              params
            ) => (
              <TextField
                {...params}
                // variant={'standard'}
                error={Boolean(formikFirst.errors.languages)}
								helperText={
									formikFirst.errors.languages
										? "Language is required"
										: ""
								}
                label={
                  <>
                    Languages *
                    <Tooltip title="Countries and regions" arrow placement="top-start">

                      <InfoIcon  fontSize="small" style={{ marginLeft:"6px",color:"#D9D9D9"}}/></Tooltip>
                  </>
                }
                placeholder="Add"
                sx={{
                  transition:
                    "0.1s linear",
                }}
              />
            )}
          />
          
          <TextField
            name={"relevantYearOfExperience"}
            value={formikFirst.values.relevantYearOfExperience}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.relevantYearOfExperience &&
              Boolean(formikFirst.errors.relevantYearOfExperience)
            }
            fullWidth
            helperText={
              formikFirst.touched.relevantYearOfExperience &&
              formikFirst.errors.relevantYearOfExperience
            }
            label={"Relevant Years of Experience *"}
            sx={{textAlign: 'left'}}
            type={'number'}
          />
          <Stack direction={"column"} spacing={5}>
            <Stack
              direction={"column"}
              spacing={1.5}>
              <Autocomplete
                multiple
                required
                options={[
                  "Pre-Seed",
                  "Seed",
                  "Seed+",
                  "Series A",
                  "Series A+",
                  "Series B",
                  "Series B+",
                  "Series C and above",
                ].map((option) => option)}
                value={formikFirst.values?.supportStage}
                onChange={(e, newValue) =>
                  formikFirst.setFieldValue(
                    "supportStage",
                    newValue
                  )
                }
                renderTags={(value, getTagProps) =>
                  formikFirst.values?.supportStage &&
                  formikFirst.values?.supportStage.length > 0 &&
                  formikFirst.values?.supportStage.map(
                    (option, index) => (
                      <Chip
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginLeft: "5px",
                          marginRight: "5px",
                          marginBottom: "10px",
                          borderRadius: "10px"
                        }}
                        deleteIcon={<CloseIcon />}
                        onDelete={() =>
                          formikFirst.setFieldValue(
                            "supportStage",
                            formikFirst.values?.supportStage.filter(
                              (f) => f !== option
                            )
                          )
                        }
                        label={option}
                        {...getTagProps({
                          index,
                        })}></Chip>
                    )
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // variant={'standard'}
                    label="Support Startup at Stage*"
                    name={"supportStage"}
                    error={Boolean(
                      formikFirst.errors.supportStage
                    )}
                    helperText={
                      formikFirst.errors.supportStage
                        ? "Support Startup at Stage is required"
                        : ""
                    }
                    placeholder="Add"
                    sx={{
                      transition: "0.1s linear",
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack direction={"column"} spacing={5}>
            <Stack
              direction={"column"}
              spacing={1.5}>
              <Typography
                variant={
                  "body2"
                }
                sx={{
                  textAlign:
                    "left",
                }}>
                Happy to provide service for some share of the equity?
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center">
                <Typography>
                  No
                </Typography>
                <FormControlLabel
                  label={""}
                  control={
                    <Switch
                      name={
                        "happyToProvideServiceForSomeShareOfTheEquity"
                      }
                      checked={
                        formikFirst
                          .values
                          .happyToProvideServiceForSomeShareOfTheEquity
                      }
                      onChange={
                        formikFirst.handleChange
                      }
                      error={
                        formikFirst
                          .touched
                          .happyToProvideServiceForSomeShareOfTheEquity &&
                        Boolean(
                          formikFirst
                            .errors
                            .happyToProvideServiceForSomeShareOfTheEquity
                        )
                      }
                    />
                  }
                />
                <Typography>
                  Yes
                </Typography>
              </Stack>
            </Stack>
          </Stack>

        </Stack>
      </Container>
      {
        matches ? <Stack
            direction={"row"}
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
            width={"100%"}>
            <Button
              variant={"outlined"}
              color={"primary"}
              sx={{ px: 5 }}
              onClick={cbBack}>
              Back
            </Button>
            {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{ px: 5 }}
              type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
              save
            </Button>
          </Stack> :
          <Stack
            direction={"row"}
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
            width={"100%"}
            sx={{px: !matches ? 2 : ''}}
          >
            {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{ px: 5 }}
              type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
              save
            </Button>
          </Stack>
      }
    </Stack>
  );
}
