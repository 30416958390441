import React from "react";
import {Autocomplete, Button, Container, IconButton, Stack, TextField, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import dataJson from "../../../const/data.json";
import {useFormik} from "formik";
import AddAdminValidation from "../../../validations/add-admin";
import {AuthUserContext} from "../../../providers/auth-provider";
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
import SaveAsDraft from "../../../api/save-as-draft";
import {getAuth, fetchSignInMethodsForEmail} from "firebase/auth";
import MuiPhoneNumber from "material-ui-phone-number";


export default function ContactInformation(){
  const {uid} = useParams()
  const { user } = React.useContext(AuthUserContext);
  const {state} =useLocation()
  const {contactData} = state
  const navigator =useNavigate()
  const auth = getAuth();
  const formik = useFormik({
    initialValues: contactData ? contactData : AddAdminValidation.addUserValues,
    validationSchema: AddAdminValidation.addUserSchema,
    onSubmit: async (values) => {
      const signInMethods = await fetchSignInMethodsForEmail(auth, values?.email);
      if (signInMethods?.length === 0) {
        uid && SaveAsDraft({...state, contactData: formik.values}, uid, user).then()
        navigator(uid ? `/admin/on-boarding/${state?.type}/profile/${uid}` : `/admin/on-boarding/${state?.type}/profile`, {state: {type: state?.type, contactData: {...values}}})
      } else {
        formik.setFieldError('email', 'Your email address is already exists.')
      }
      
    },
  })
  React.useEffect(() => {
    if (uid) {
      getDoc(doc(DB, 'users', uid))
        .then((document) => {
          if (document?.data()?.contactData) {
            formik?.setValues({...document?.data()?.contactData})
          }
        })
    }
  }, [uid])
  return(
    <>
      <Container maxWidth={'full-width'} sx={{py: 5}}>
        <Stack direction={'row'} justifyContent="flex-start"
               alignItems="center"
               spacing={2} width={'100%'} >
          <IconButton onClick={() => navigator('/admin/on-boarding/type', {state})}>
            <ArrowBackIcon sx={{fontSize: 40}} />
          </IconButton>
          <Typography variant={'h5'} sx={{textAlign:'left', lineHeight: 1}}>
            Contact Details <br/> <small style={{fontSize: 14, color: '#8F8F8F'}}>Enter User Contact Details</small>
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={"md"} sx={{textAlign: 'left'}} component="form" onSubmit={(e) => formik.handleSubmit(e)}>
        <Container maxWidth={"sm"} sx={{textAlign: 'left', mb:5}}>
          <Stack direction={'column'} spacing={5}
                 >
            {/*value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}*/}
            <TextField
              name={'displayName'}
              fullWidth label={'User Full Name'}
              value={formik.values.displayName}
              onChange={formik.handleChange}
              error={formik.touched.displayName && Boolean(formik.errors.displayName)}
              helperText={formik.touched.displayName && formik.errors.displayName}
            />
            <TextField
              name={'email'}
              fullWidth label={'User Email Address'}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            
            />
            <TextField
              name={'linkedinURL'}
              fullWidth label={'Linkedin URL'}
              value={formik.values.linkedinURL}
              onChange={formik.handleChange}
              error={formik.touched.linkedinURL && Boolean(formik.errors.linkedinURL)}
              helperText={formik.touched.linkedinURL && formik.errors.linkedinURL}
            />
            <MuiPhoneNumber
              variant={'outlined'}
              name={'phoneNumber'}
              fullWidth label={'Phone number'}
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            
            />
        
            <Autocomplete
              multiple={false}
              required
              fullWidth
              options={[...dataJson.cities].map((option) => option)}
              freeSolo
              onChange={(e, newValue) => {
                formik.setFieldValue("countryState", newValue)
                }
              }
              disableClearable={true}
              value={formik.values?.countryState}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={'countryState'}
                  onChange={formik?.handleChange}
                  error={Boolean(formik.errors.countryState)}
                  helperText={
                    formik.errors.countryState
                      ? "Location is required"
                      : ""
                  }
                  label={
                    <>
                      Country/Region
          
                    </>
                  }
                  placeholder="Country/Region"
                  sx={{
                    transition: "0.1s linear",
                  }}
                />
              )}
            />
            
            
          </Stack>
        </Container>
        <Stack
          direction={"row"}
          justifyContent="center"
          alignItems="center"
          spacing={3}
          width={"100%"}>
          <Button variant={'outlined'} color={'primary'} sx={{px: 5}} onClick={() => {
    
            SaveAsDraft({...state, contactData: formik.values}, uid, user)
              .then(() => {
                navigator('/admin/draft-profiles')
              })
    
          }}>
            Save draft
          </Button>
          <Button variant={'outlined'} color={'primary'} sx={{px: 5}} onClick={() =>   navigator(uid ? `/admin/on-boarding/type/${uid}`: '/admin/on-boarding/type', {state})}>
            Back
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{ margin: "auto", px: 5 }}
            type={"submit"}
          >
            Next
          </Button>
  
          
  
  
         
        </Stack>
  
  {/*
        {
          JSON?.stringify(formik?.values)
          
        }*/}
        
        
      </Container>
    </>
    
   
  )
  
}
