import React from "react";
import {
	styled,
	Container,
	Stack,
	Typography,
	Avatar,
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogContent, DialogActions, DialogContentText, Badge, Grid
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import {AuthUserContext} from "../../providers/auth-provider";
import {getAuth, getIdToken, updateProfile} from "firebase/auth";
import {useLocation, useNavigate} from "react-router-dom";
import {
	collection,
	getDocs,
	where,
	query,
	doc,
	getDoc,
	setDoc, updateDoc
} from "firebase/firestore";
import {EditSharp} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthUserContext } from "../../../../providers/auth-provider";
import PostNotification from "../../../../api/post-notification";
import StartChat from "../../../../components/common/start-chat";
import EditOrganisationDetailsComponent from "../../../../components/common/profile/edit/service-provider/edit-organisation-details-component";
import OrganisationAbout from "../../../../components/common/profile/organisation-about";
import UploadFile from "../../../../utils/upload-file";
import { DB } from "../../../../config/firebase/firebase";
import { a11yProps, TabPanel } from "../../components/common/tabs-custom";
import InvestorDescription from "../../components/common/profile/investor-description";
import ExpertDescription from "../../components/common/profile/expert-description";
import BusinessDeveloperDescription from "../../components/common/profile/business-developer-description";
import ServiceProviderDescription from "../../components/common/profile/service-provider-description";
import StartupDescription from "../../components/common/profile/startup-description";
import TalentDescription from "../../components/common/profile/talent-description";
import ReferralAvatar from "../../components/common/profile/referal-avatar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledTabs = styled((props) => (
	<Tabs
		sx={{ position: "relative", left: 0, top: 15 }}
		{...props}
		TabIndicatorProps={{
			children: <span className="MuiTabs-indicatorSpan" />,
		}}
	/>
))({
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
	},
	"& .MuiTabs-indicatorSpan": {
		maxWidth: 40,
		width: "100%",
		backgroundColor: "none",
	},
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: "none",
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(16),
		marginRight: theme.spacing(1),
		borderRadius: "30px",
		color: "#000",
		"&.Mui-selected": {
			color: "#fff",
			backgroundColor: "#233DFF",
		},
		"&.Mui-focusVisible": {
			backgroundColor: "rgba(100, 95, 228, 0.32)",
		},
	})
);
export default function CommonOrganisationProfile({ data, href }) {
	const styleFont = {
		backgroundColor: "#F6F8FA",
		padding: "8px 15px",
		borderRadius: "5px",
		fontSize: 14,
	};
	const userContext = React.useContext(AuthUserContext);
	const { setProfile, setUser, user } =
		React.useContext(AuthUserContext);
	const contextUser = React.useContext(AuthUserContext)
	const { organisation, profile } = useLocation().state;
	debugger
	const [portfolioIndex, setPortfolioIndex] = React.useState();
	const [imageURL, setImageURL] = React.useState();
	const confirmed = React.useRef(false);
	const profileImage = React.useRef();
	const [open, setOpen] = React.useState(false);
	const [steps, setSteps] = React.useState(0);
	const userRef = doc(DB, "users", user.uid);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [portfolios, setPortfolios] = React.useState(
		profile?.userType === 'investor' ? profile?.investor?.portfolio : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
			: profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.portfolio
	);
	const [posts, setPosts] = React.useState([]);
	const [index, setIndex] = React.useState(-1);
	// const [profile, setProfile] = React.useState(null);
	const [value, setValue] = React.useState(0);

	function handleDialogOpen() {
		setDialogOpen(true);
	}
	function handleDialogClose() {
		setDialogOpen(false);
	}
	const navigator = useNavigate()

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
		<IconButton onClick={() => navigator(-1)}>
						<ArrowBackIcon sx={{ fontSize: 40 }} />
					</IconButton>
		{organisation && (
				<Stack direction={"column"} spacing={5}>
				
    
	
					<Stack
						direction={"column"}
						spacing={0}
						sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                p: 2,
                pb: 0,
              }}
            >
              <Box
                sx={{
                  height: 100,
                  width: "100%",
                  backgroundColor: "#F6F8FA",
                  borderRadius: "15px",
                }}
              />


						{/* Startup Picture */}

						<Box
							sx={{
								border: "1px solid #204488",
								p: 0.75,
								borderRadius: "50%",
								width: "fit-content",
								position: "relative",
								left: 30,
								top: -50,
							}}
						>
							<Avatar
								alt={
									profile?.investor?.nameOfOrganization
								}
								src={
									profile?.investor?.logoURL
								}
								sx={{
									width: 48,
									height: 48
								}}
								imgProps={{style: {objectFit: 'contain', backgroundColor: '#fff'}}}
							>
								<Typography variant={"h6"}>
									{/* {profile?.investor?.nameOfOrganization} */}
								</Typography>
							</Avatar>
						</Box>


						<Stack
							direction={"row"}
							spacing={3}
							sx={{ position: "relative", top: -40 }}
							alignItems={"flex-start"}
						>
							<div />
							<Stack direction={"column"} spacing={2}>
								<Stack direction={"row"}  sx={{width: '100%'}}>

									{/* Startup Name */}

									<Typography sx={{fontFamily: 'Poppins',
										fontStyle: 'normal',
										fontWeight: 600,
										fontSize: '22px',
										marginTop:"2.5px",
										width:'60%'

									}} >
										{profile?.investor?.nameOfOrganization}
									</Typography>

								</Stack>

								<Box sx={{display:'flex'}}>



									<Box
										sx={{
											backgroundColor: "#E0E3FF",
											px: 1,
											py: 1,
											marginRight:'7px',
											borderRadius: "19.5px",
										}}>
										<Typography
											style={{
												fontSize:'13px',
												fontWeight: 500,
												textTransform: "Uppercase"
											}}>
											{"Investor"}
										</Typography>
									</Box>
								</Box>



								<span style={{flex: 1}}/>
								{profile?.investor && profile?.investor.organizationDescription &&
									<Typography variant={'body1'} color={'secondary'} sx={{width: 'calc(100% - 10%)', fontSize:'12px'}}>

										{profile?.investor.organizationDescription}
									</Typography>}
								<br/>
								<Stack
									direction={"row"}
									spacing={1}
									// justifyContent="center"
									sx={{ position: "relative", minWidth: 120, maxWidth: 130,cursor: 'pointer'
										}}
									alignItems="flex-start"
                                    style={{marginLeft:"0px"}}
									onClick={() => navigator(`/m/${userContext?.profile?.userType}/investor-details/${profile?.uid}`)
									}
								>
									<Box
										sx={{
											border: "1px solid #204488",
											p: 0.75,
											borderRadius: "50%",
											width: "fit-content",
										}}
									>
										<Avatar
											alt={profile?.displayName}
											src={
												profile?.photoURL !== ""
													? profile?.photoURL
													: profile.photoURL
											}
											sx={{ width: 48, height: 48 }}
										>
											<Typography variant={"h4"} >
												{profile?.displayName.charAt(0)}
											</Typography>
										</Avatar>
									</Box>
									<Stack  direction="column" >
									<Typography variant={"h6"} sx={{ textAlign: "flex-start" }}>
										{profile?.displayName}
										<br />
										<font
											size={2}
											style={{
												backgroundColor: "#F6F8FA",
												padding: "5px 10px",
												borderRadius: "6px",
												fontWeight: 300,
											}}
										>
											{profile?.investor?.role}
										</font>
									</Typography>
									</Stack>
								</Stack>
							</Stack>

							<div style={{ flex: 2 }} />


						</Stack>





						<Box sx={{ maxWidth: { xs: 320, sm: 480 },marginTop:'16px', marginLeft: 2 }}>
							<StyledTabs
								value={value}
								onChange={handleChange}
								variant="scrollable"
								scrollButtons="auto"
								aria-label="styled tabs example"
							>
								<StyledTab label="ABOUT" {...a11yProps(0)} sx={{fontSize:'14px'}}/>
							</StyledTabs>
						</Box>

						<Box sx={{ height: 30 }} />
					</Stack>
					
					<TabPanel index={0} value={value}>
					<Stack
                direction={"column"}
                spacing={3}
                sx={{
                //   border: '0.5px solid #000000',
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 3,
                  py: 6,
                }}>
						<Grid container gap={3}>
							<Grid>
								<Stack direction={"column"} spacing={2}>
									<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
										Website
										<br />{" "}
										<font style={styleFont}>
											{!organisation?.website || organisation?.website === '' ? 'N/A' : organisation?.website }{" "}
										</font>
									</Typography>
								</Stack>
							</Grid>
							<Grid>
								<Stack direction={"column"} spacing={2}>
									<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
										Investor type
										<br />{" "}
										<font style={styleFont}>
											{!organisation?.investorType || organisation?.investorType === '' ? 'N/A' : organisation?.investorType }{" "}
										</font>
									</Typography>
								</Stack>
							</Grid>
						</Grid>
								 
		
              </Stack>
            </TabPanel>
		
				</Stack>
			)}




			
		</Container>
	);
}
