import React from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import {Stack, Avatar, Button, Box, Typography, Portal, Dialog, DialogContent, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function PortfolioCard({data, i, edit, remove}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [open, setOpen] = React.useState(false);
  function currencyToSymbol(currency){
    if(currency == "USD"){
      return "$"
    }
    else if (currency == "GBP"){
      return "£"
    }
    else{
      return "€"
    }
  }
  return (<Grid sx={{width: !matches ? "100%" : '30%', backgroundColor: '#F6F8FA', border: '0.5px solid #000000', borderRadius: '5px', p:2, px: 2 }}>

    <Stack direction={'column'} spacing={.5} sx={{width:'100%'}}>
      <Stack direction={'row'} spacing={1} alignItems={"flex-start"}>
        <Avatar
          sx={{
            width: 70,
            height: 70,
            border: "1px solid #E0E3FF",
            position:
              "relative",
            top: -40
            , marginBottom:"-9px",
            marginLeft:"8px"
          }}
          src={
            data?.photoURL
              ? data?.photoURL
              : "/assets/startup-placeholder-image.png"
          }/>
        <Box sx={{flex:1}}/>
        <Button variant={"text"} size={'small'} color={"secondary"} onClick={edit} sx={{color:'#8F8F8F', top: -15}}>
          edit
        </Button>
        <Button variant={"text"} size={'small'} color={"secondary"} sx={{color:'#8F8F8F', top: -15}} onClick={() => setOpen(true)}>
          delete
        </Button>
      </Stack>
      <Stack direction={'column'} spacing={.5} sx={{width:'100%',  position:
          "relative", 
        top: -23}}>

        <Typography variant={'body1'}  sx={{textAlign:'left', fontWeight:"500", fontSize:"16px", top: "-14px"  }}>
          {
            data?.name
          }
        </Typography>
        <Typography  variant={'caption'}  sx={{textAlign:'left',}}>
          <a href={
            data?.website
          } target={'_blank'}>{
            data?.website?.length < 30 ? data?.website : `${data?.website?.slice(0 , 30)}...`
          }</a>
        </Typography>
        <span/>
        <span/>
        <span style={{flex:1}}/>
        <Typography  variant={'caption'} paragraph={true} sx={{textAlign:'left', color:'#8F8F8F', width: 275, overflowY: 'hidden'}}>
          {
            data?.description?.length < 200 ? data?.description :  `${data?.description?.slice(0 , 200)}...`
          }
        </Typography>
        <span style={{flex:1}}/>
        <span style={{flex:1}}/>
        <Stack direction={!matches ? 'column':'row'} spacing={1} alignItems={"flex-start"}>
          <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
            Invested date:
          </Typography>
          <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
          {moment(data?.investmentDate).format("DD-MM-YY")}
          </Typography>
          <span style={{flex:1}}/>
          <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
            Amount
          </Typography>
          <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
          {currencyToSymbol(data?.currency)} 
          { data?.amount.length < 6 ? data?.amount : `${data?.amount.slice(0 , 6)}...` } 

          </Typography>
        </Stack>
        <Stack direction={!matches ? 'column':'row'}  spacing={1} alignItems={"flex-start"}>
          <Typography  variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
            Invested stage:
          </Typography>
          <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
          { data?.investmentStage?.length < 6 ? data?.investmentStage : `${data?.investmentStage?.slice(0 , 6)}...` }

          </Typography>
          <span style={{flex:1}}/>
          <Typography variant={'caption'}  sx={{textAlign:'left', fontWeight:"500"}}>
            Pre-val:
          </Typography>
          <Typography  variant={'caption'}  sx={{textAlign:'left', color:'#8F8F8F'}}>
          {currencyToSymbol(data?.currency)}  
          { data?.valuation?.length < 6 ? data?.valuation : `${data?.valuation?.slice(0 , 6)}...` }
          </Typography>
        </Stack>
      </Stack>



    </Stack>
    <Portal>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-achive"
        aria-describedby="alert-dialog-achive"
        maxWidth={"sm"}>
        <DialogContent
          sx={{ minWidth: !matches ? 250 : 487, padding: "0px 0px", mt: 2 }}>
          <Stack
            direction={"column"}
            sx={{ width: "100%" }}
            justifyContent="center"
            alignItems="flex-end">
            <IconButton
              onClick={() => {
                setOpen(false);
              }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction={"column"}
            sx={{ width: "100%" }}
            spacing={2}>
            <Stack
              direction={"column"}
              sx={{ width: "100%", }} alignItems={"center"}>
              <img src={"/assets/delete-confirmation.png"} height={74} width={80}></img>
            </Stack>
            <Typography
              component="span"
              variant="h6"
              color="text.primary"
              sx={{
                textAlign: "center",
                flex: 1,
                padding: 0,
                fontWeight: 500,
              }}>
             Delete Portfolio
            </Typography>
            <Typography variant={"body2"} sx={{textAlign:'center'}}>
              Are you sure you want to delete this portfolio? <br/>
              This action cannot be undone.
            </Typography>
            <br/>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="center" spacing={3}>
              <Button
                variant={"outlined"}
                sx={{ px: 5,  width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"
                  // width: !matches ? 100 : 210
                }}
                onClick={() => {
                  setOpen(false)
                }}>
               No
              </Button>
              <Button
                variant={"contained"}
                sx={{ px: 5,
                  width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"
                  //  width: !matches ? 100 : 210
                  }}
                onClick={() => {
                  remove()
                  setOpen(false)
                }}>
                yes
              </Button>
            </Stack>

            <Box />
          </Stack>
        </DialogContent>
      </Dialog>
    </Portal>
  </Grid>)
}