import React from "react";
import { onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { auth, DB, logout } from "./config/firebase/firebase";

import Timer from "./components/counter";
import Portal from "@mui/material/Portal";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import "./App.css";
import { AuthUserContext } from "./providers/auth-provider";
import AuthRouter from "./routes/auth-router";
import { useLocation, useNavigate } from "react-router-dom";
import TopNavbar from "./components/common/top-navbar";
import OnBoardingRouter from "./routes/on-boarding";
import Spinner from "./components/common/spinner";
import ExpertRouter from "./routes/expert-router";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import StartupRouter from "./routes/startup-router";
import InvestorRouter from "./routes/investor-router";
import BusinessDeveloperRouter from "./routes/business-developer-router";
import ServiceProviderRouter from "./routes/service-provider-router";
import TalentRouter from "./routes/talent-router";
import referralCodeGenerator from "referral-code-generator";
import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import MobileStartupRouter from "./routes/mobile/startup-router";
import MobileOnBoardingRouter from "./routes/mobile/on-boarding";
import MobileExpertRouter from "./routes/mobile/expert-router";
import MobileBusinessDeveloperRouter from "./routes/mobile/business-developer-router";
import MobileTalentRouter from "./routes/mobile/talent-router";
import MobileServiceProviderRouter from "./routes/mobile/service-provider-router";
import MobileAuthRouter from "./routes/mobile/auth-router";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AdminRoutes from "./routes/admin-route";
import ButtonAppBar from "./screens/mobile/navbar";
import TopNavbarMobile from "./screens/mobile/navbar-mobile";
import NavBarMobile from "./screens/mobile/topbar-mobile";
import MobileInvestorRouter from "./routes/mobile/investor-router";
import { USER_TYPES } from "./utils/enums/userTypes.enum";
import ScrollToTopButton from "./screens/mobile/components/common/scroll-to-top-button";
import moment from "moment";

moment.updateLocale('en', {
    relativeTime : {
        future: "in %s",
        past:   "%s ago",
        s  : 'a few seconds',
        ss : '%d s',
        m:  "a minute",
        mm: "%dm",
        h:  "an hour",
        hh: "%dh",
        d:  "1d",
        dd: "%dd",
        w:  "1w",
        ww: "%dw",
        M:  "1mo",
        MM: "%dmo",
        y:  "a year",
        yy: "%d years"
    }
});
function App() {
	const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
	const { profile, user, setUser, setProfile, userBlock } =
		React.useContext(AuthUserContext);
	const navigate = useNavigate();
	const [timer, setTimer] = React.useState(false);
	const [isLoading, setLoading] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [userinit, setUserInit] = React.useState(null);
	const location = useLocation();
	React.useEffect(() => {
		const reg = new RegExp("/m/");
		if (matches) {
			if (reg.test(location?.pathname)) {
				navigate({pathname:`/${location.pathname.split("/m/")[1]}`,search: location?.search});
			}
		} else {
			navigate({pathname:
				`${!reg.test(location?.pathname) ? "m" : ""}${location.pathname}`, search: location?.search
				}
			);
		}
	}, [matches]);
	React.useEffect(() => {
		setLoading(true);
		const unsubscribeAuth = onAuthStateChanged(auth, async (authUser) => {
			if (authUser) {
				await setUserInit(authUser);
				if (authUser && authUser.emailVerified === true) {
					await (authUser ? setUser(authUser) : setUser(null));
					const userRef = doc(DB, "users", authUser.uid);
					const getdoc = await getDoc(userRef);
					if (getdoc?.data() && getdoc?.data()?.onboard) {
						if (!getdoc?.data()?.referralCode) {
							const referralCode = referralCodeGenerator.custom(
								"uppercase",
								4,
								2,
								getdoc?.data()?.displayName
							);
							await updateDoc(userRef, {
								referralCode,
							});
							await setProfile({
								...getdoc.data(),
								referralCode,
							});
						} else {
							await setProfile(getdoc.data());
						}
						if (window.location.pathname === "/") {
							navigate(`/${getdoc.data()?.userType}/timeline`);
						} else  if(window.location.pathname === "/m/"){
							navigate(`/m/${getdoc.data()?.userType}/timeline`);
						}
						if (window.location.pathname === '/common/chat') {
							navigate(`/${getdoc.data()?.userType}/chat`);
						}
					}
					else {
						if (window.location.pathname === "/") {
							navigate(`/on-boarding/type`);
						} else  if(window.location.pathname === "/m/"){
							navigate(`/m/on-boarding/type`);
						}
					}
				} else {
					//setOpen(true);
				}



				// ...
			} else {
				if (window.location.pathname === "/") {
					navigate("/auth/signin");
				} else if (window.location.pathname === "/m/"){
					navigate("/m/auth/splashscreen")
				} else {
					if(window.location.pathname === '/auth/reset-password') {
					
					} else {
						if (matches) {
							navigate(`/auth/signin`);
						} else navigate(`/m/auth/signin`);
					}
				
				}
			}
			setLoading(false);
		});
		return unsubscribeAuth;
	}, []);
	if (isLoading) return <Spinner />;
	return (
		<>
			{matches ? (
				<div className="App">
					<TopNavbar />
					{!user || location?.pathname === '/auth/referral' ? (
						<AuthRouter />
					) : !profile || !profile.onboard ? (
						<OnBoardingRouter />
					) : profile?.userType === USER_TYPES.EXPERT ? (
						<ExpertRouter />
					) : profile?.userType === USER_TYPES.STARTUP ? (
						<StartupRouter />
					) : profile.userType === USER_TYPES.BUSINESS_DEVELOPER ? (
						<BusinessDeveloperRouter />
					) : profile.userType === USER_TYPES.SERVICE_PROVIDER ? (
						<ServiceProviderRouter />
					) : profile.userType === USER_TYPES.TALENT ? (
						<TalentRouter />
					) : profile.userType === USER_TYPES.ADMIN ? (
						<AdminRoutes />
					) : (
						<InvestorRouter />
					)}
			{profile && profile.onboard &&
			<ScrollToTopButton web/>}
				</div>
			) : (
				<div className="app">
					<NavBarMobile/>
					{!user || location?.pathname === '/m/auth/referral' ? (
						<MobileAuthRouter />
					) : !profile || !profile.onboard ? (
						<MobileOnBoardingRouter />
					) : profile?.userType === "expert" ? (
						<MobileExpertRouter />
					) : profile?.userType === "startup" ? (
						<MobileStartupRouter />
					) : profile.userType === "business-developer" ? (
						<MobileBusinessDeveloperRouter />
					) : profile.userType === "service-provider" ? (
						<MobileServiceProviderRouter />
					) : profile.userType === "talent" ? (
						<MobileTalentRouter />
					) : (
						<MobileInvestorRouter />
					)}
					{profile && profile.onboard &&
			<ScrollToTopButton />}
				</div>
			)}
			
			<Portal>
				<Dialog
					open={open}
					aria-labelledby="alert-dialog-achive"
					aria-describedby="alert-dialog-achive"
					maxWidth={"sm"}>
					<DialogContent
						sx={{ minWidth: 300, padding: "0px 0px", mt: 2 }}>
						<Stack
							direction={"column"}
							sx={{ width: "99%" }}
							justifyContent="center"
							alignItems="flex-end">
							<IconButton
								disabled={userBlock}
								onClick={() => {
									debugger
									setProfile(null);
									setUser(null);
									setOpen(false);
									if (matches) {
										navigate("/auth/signin");
									} else {
										navigate(`/m/auth/signin`);
									}
								}}>
								<CloseIcon />
							</IconButton>
						</Stack>
						<Stack
							direction={"column"}
							sx={{ width: "100%" }}
							alignItems="center"
							spacing={3}>
							<Typography
								component="span"
								variant="h5"
								color="text.primary"
								sx={{
									textAlign: "center",
									flex: 1,
									padding: 0,
									px: 5,
									lineHeight: 1.3,
									fontWeight: 700,
								}}>
								Confirm your email to activate your account{" "}
								<br />
								<font size={2} style={{ fontWeight: 500 }}>
									We sent the verification email to{" "}
									<font color={"#233DFF"}>
										{userinit && userinit?.email}
									</font>
								</font>
							</Typography>
							<Typography
								variant={"body2"}
								sx={{ lineHeight: 1.3, textAlign: "center" }}>
								<>
									Didn’t receive the email?
									<br />{" "}
									<Button
										variant={"text"}
										style={{
											textDecoration: "underline",
											fontWeight: 600,
											cursor: "pointer",
										}}
										onClick={() => {
											sendEmailVerification(userinit, {
												url: `${window.location.origin}/auth/signin`,
											})
												.then(setTimer(true))
												.catch((err) => {});
										}}>
										{" "}
										resend email.
									</Button>
								</>

								{timer && (
									<Typography
										sx={{
											textAlign: "center",
											fontSize: "14px",
											width: "100%",
										}}>
										Resend email in{" "}
										<b>
											{" "}
											00 : <Timer max={60} />
										</b>
									</Typography>
								)}
							</Typography>

							<Stack
								direction={"column"}
								sx={{ width: "100%", px: 5 }}
								justifyContent="center"
								alignItems="center">
								{/* <Button variant={"contained"} fullWidth size={"small"} sx={{px: 2}}  onClick={() => {
                  logout().then(() => {
                    setProfile(null)
                    setUser(null)
                    setOpen(false)
                    {
                      matches ? navigate('/auth/signin') : navigate('/m/auth/signin')
                    }
                   
                  })
                  // setOpenRewards(!openRewards)
                }} disabled={userBlock} endIcon={userBlock && <CircularProgress size={20}/>}>
                  Verify
                </Button> */}
							</Stack>

							<Box />
						</Stack>
					</DialogContent>
				</Dialog>
			</Portal>
		</>
	);
}

export default App;
