import React from "react";
import {doc, getDoc, serverTimestamp, setDoc, addDoc,
	collection} from "firebase/firestore";
import {
	Button,
	Container,
	Stack,
	Typography,
	IconButton,
	TextField,
	FormControlLabel,
	FormControl,
	Switch,
	Avatar,
	styled,
	CircularProgress,
	Link,
	Badge,
	Autocomplete,
	Chip,
	MenuItem,
	Box,
} from "@mui/material";
import { getStorage, ref, deleteObject } from "firebase/storage";
import CancelIcon from "@mui/icons-material/Cancel";
import { FieldArray, FormikProvider, getIn, useFormik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate , useLocation } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import CloseIcon from "@mui/icons-material/Close";
import { DB } from "../../config/firebase/firebase";
import ServiceProviderDetailsValidation from "../../validations/service-provider/provider-profile";
import DragDropFiles, {
	DragDropItem,
} from "../../components/common/drag-drop-files";
import UploadFile from "../../utils/upload-file";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ServiceProviderSecondForm from "./onboarding-forms/service-provider-second-form";
import ServiceProviderThirdForm from "./onboarding-forms/service-provider-third-form";
import ServiceProviderFirstForm from "./onboarding-forms/service-provider-first-form";
import ExperienceListView from "../expert/onboarding-forms/experience-list-view";
import ExperienceForm from "../expert/onboarding-forms/experience-form";
import Expectations from "../expert/onboarding-forms/expectations";
import InvestorDetails from "../expert/onboarding-forms/investor-details";
import InvestorThirdForm from "../investor/onboarding-forms/investor-third-form";
import InvestorFourForm from "../investor/onboarding-forms/investor-four-form";
import PortfoliosListView from "../investor/onboarding-forms/portfolios-list-view";
import InvestorPortfolioForm from "../investor/onboarding-forms/portfolio-form";
import ServiceProviderOrganisationForm from "./onboarding-forms/service-provider-organisation";
import { SERVICE_PROVIDER } from "../../utils/enums/labels.enum";
export default function ServiceProviderProfile() {
	const Input = styled("input")({
		display: "none",
	});
	const { user, setProfile } = React.useContext(AuthUserContext);
	const selectedUser = useLocation();
	const userType = selectedUser.state.type
	console.log(userType)
	const [loading, setLoading] = React.useState(false);
	const [steps, setSteps] = React.useState(0);
	const [hasOrganisation, setHasOrganisation] = React.useState(false);
	const fileTypes = [
		"JPG",
		"JPEG",
		"PNG",
		"GIF",
		"DOC",
		"DOCX",
		"PDF",
		"MOV",
		"XLS",
		"CSV",
	];
	const [files, setFiles] = React.useState([]);

	const [profileImage, setProfileImage] = React.useState({
		src: user.photoURL ? user.photoURL : null,
		photoURL: user.photoURL,
	});
	const [formFirstData,setFormFirstData] = React.useState()
	const [formSecondData,setFormSecondData] = React.useState()
	const [formOrganisationData,setFormOrganisationData] = React.useState()
	const [formThirdData,setFormThirdData] = React.useState()
	const [formFourData,setFormFourData] = React.useState()
	const [formFiveData,setFormFiveData] = React.useState()
	const [formSixData,setFormSixData] = React.useState()
	const [formSevenData,setFormSevenData] = React.useState()

	const [experiences, setExperiences] = React.useState([]);
	const [experienceIndex, setExperienceIndex] = React.useState();
	const [portfolioIndex, setPortfolioIndex] = React.useState();
	const [portfolios, setPortfolios] = React.useState([]);

	const navigator = useNavigate();

/*
	const formikFirst = useFormik({
		initialValues:
			ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileInitValue,
		validationSchema:
			ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileSchema,
		onSubmit: async (values) => {
			try {
				debugger;
				const userRef = doc(DB, "users", user.uid);
				const serviceProvider = {
					...values,
					photoURL: profileImage?.photoURL
						? profileImage.photoURL
						: "",
				};
				await setDoc(
					userRef,
					{
						photoURL: profileImage?.photoURL
							? profileImage.photoURL
							: "",
						serviceProvider,
					},
					{ merge: true }
				);
				setSteps(1);
			} catch (e) {
				debugger;
				console.log(e);
			}
		},
	});
	const formikSecond = useFormik({
		initialValues:
			ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileSecondInitValue,
		validationSchema:
			ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileSecondSchema,
		onSubmit: async (values) => {
			try {
				const socialProof = [];
				if (files.length > 0) {
					for (let file of files) {
						const path = `service-providers/${user.uid}/social-proofs/${file.file.name}`;
						socialProof.push(path);
					}
				}
				formikSecond.setFieldValue("socialProof", socialProof);
				const userRef = doc(DB, "users", user.uid);
				await setDoc(
					userRef,
					{
						serviceProvider: {
							...formikFirst.values,
							values,
							socialProof,
						},
					},
					{ merge: true }
				);
				setSteps(2);
			} catch (e) {
				debugger;
				console.log(e);
			}
		},
	});
	const formikThird = useFormik({
		initialValues:
			ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileThirdInitValue,
		validationSchema:
			ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileThirdSchema,
		onSubmit: async (values) => {
			try {
				debugger;
				const userRef = doc(DB, "users", user.uid);
				await setDoc(
					userRef,
					{
						serviceProvider: {
							...formikSecond.values,
							...formikFirst.values,
							...values,
							photoURL: profileImage?.photoURL
								? profileImage.photoURL
								: "",
						},
						onboard: true,
					},
					{ merge: true }
				);
				const getdoc = await getDoc(userRef);
				await setProfile(getdoc.data());
				navigator("/service-provider/timeline");
				// setSteps(2);
			} catch (e) {
				debugger;
				console.log(e);
			}
		},
	});
*/

	const submitData = async (values) => {
		try {
			const userRef = doc(DB, "users", user.uid);
			const copyPortfolio = []
			if (portfolios.length > 0) {
				for (let portfolio of portfolios) {
					const attachments =[]
					if (portfolio.attachments.length > 0) {
						for (let file of portfolio.attachments) {
							const path = `investors/${user.uid}/attachments/${file.file.name}`
							attachments.push(path)
						}
					}
					copyPortfolio.push({...portfolio, attachments})
				}
			}
			const copyExperiences = []
			if (experiences.length > 0) {
				for (let experience of experiences) {
					const attachments =[]
					if (experience.attachments.length > 0) {
						for (let file of experience.attachments) {
							const path = `experts/${user.uid}/experiences/attachments/${file.file.name}`
							attachments.push(path);
						}
					}
					copyExperiences.push({...experience, attachments})
				}
			}
			debugger
			const copyValue = {...values, organisationDetails: {...values?.organisationDetails}, investorDetails: {...values?.investorDetails,portfolio: copyPortfolio}}

			debugger
			const serviceProvider = {
				...copyValue,
				experiences: copyExperiences,
				photoURL: values?.photoURL
					? values.photoURL
					: "",
			};
			if (formFirstData?.organisationType === 'Organisation') {
				const add = await addDoc(collection(DB, "organisations"),
					{
						...formFirstData,
						uid: user?.uid,
						createdAt: serverTimestamp(),
						updatedAt:serverTimestamp()
					});
				await addDoc(collection(DB, "organisationUsers"),
					{
						organisationId: add.id,
						organisation: {...formFirstData},
						uid: user?.uid,
						createdAt: serverTimestamp(),
						updatedAt:serverTimestamp(),
						role: formSecondData?.role
					});
			}
			await setDoc(
				userRef,
				{
					photoURL: values?.photoURL
						? values.photoURL
						: "",
					serviceProvider,
					onboard: true,
					profileStatus: 'complete',
					remainderStatus:''
				},
				{ merge: true }
			);
			const getdoc = await getDoc(userRef);
			await setProfile(getdoc.data());
			navigator("/service-provider/timeline");
		} catch (e) {
			debugger;
			console.log(e);
		}
	}
	return (
		<>
			<Container maxWidth={"xl"} sx={{ marginY: 5 }}>
				<Stack
					direction={"row"}
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
					width={"100%"}>
					<IconButton
						onClick={() => 
							steps === 0 ? navigator(-1) : steps === 2 && !hasOrganisation ? setSteps(steps - 2) : setSteps(steps - 1) 
						
						}>
						<ArrowBackIcon sx={{ fontSize: 40 }} />
					</IconButton>
					<Typography
						variant={"h5"}
						sx={{ textAlign: "left", lineHeight: 1 }}>
						{SERVICE_PROVIDER.SINGULAR_LABEL}
						<br />{" "}
						<small style={{ fontSize: 14, color: "#8F8F8F" }}>
							{steps >= 0 && steps < 4 &&  SERVICE_PROVIDER.SERVICE_PROVIDER_PROFILE}
							{/* {steps === 4 &&  'Experience'}
							{steps === 5 &&  'Add experience'} */}
							{steps === 4 &&  SERVICE_PROVIDER.EXPECTATIONS}
							{steps > 5 && steps < 8 &&  SERVICE_PROVIDER.INVESTOR_DETAILS}
							{steps === 8 &&  SERVICE_PROVIDER.INVESTMENT_PORTFOLIO}
							{steps === 9 &&  SERVICE_PROVIDER.ADD_PORTFOLIO}

						</small>
					</Typography>
				</Stack>
			</Container>
			<Container maxWidth={"lg"} sx={{ mb: 5 }}>
				{steps === 0 && (
					<ServiceProviderFirstForm
						data={formFirstData}
						cbBack={() => navigator(-1)}
						cbNext={(data) => {
							debugger
							data.organisationType === 'Organisation' ? setHasOrganisation(true)  : setHasOrganisation(false)
							setFormFirstData({ ...data });
							data.organisationType !=='Organisation' ? setSteps(steps + 2) : setSteps(steps + 1);
						}}
					/>
				)}
				{steps === 1  && (
					<ServiceProviderOrganisationForm
						isVC={formFirstData && formFirstData?.organisationType === 'Organisation'}
						data={formOrganisationData}
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data) => {
							debugger
							setFormOrganisationData({ ...data });
							setSteps(steps + 1);
						}}
					/>
				)}
				{steps === 2 && (
					<ServiceProviderSecondForm
						isVC={formFirstData && formFirstData?.organisationType === 'Organisation'}
						data={formSecondData}
						cbBack={() => {formFirstData?.organisationType ==='Organisation' ? setSteps(steps - 1): setSteps(steps - 2)}}
						cbNext={(data) => {
							debugger
							setFormSecondData({ ...data });
							setSteps(steps + 1);
						}}
					/>
				)}

				{steps === 3 && (
					<ServiceProviderThirdForm
						data={formThirdData}
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data) => {
							setFormThirdData({ ...data });
							setSteps(steps + 1);
						}}
					/>
				)}

				{/*steps === 4 && (
					<ExperienceListView
						userType ={ userType}
						cbBack={() => setSteps(steps - 1)}
						add={(data) => {
							setExperienceIndex(undefined)
							setSteps(steps + 1)*/
							/*		setPortfolioIndex();
                                    setSteps(steps + 1);*/
						/*}}
						edit={(ind) => {
							setExperienceIndex(ind)
							setSteps(steps + 1)*/
							/*	setPortfolioIndex(ind);
                                setSteps(steps + 1);*/
						/*}}
						remove={(ind) => {
							const experiences_copy =   [...experiences]
							experiences_copy.splice(ind, 1)
							setExperiences(experiences_copy)*/

							/*	debugger
                                const port = [...portfolios]
                                port.splice(ind, 1)
                                setPortfolios([...port])*/
						/*}}
						experiences={experiences}
						submit={() => {
							setSteps(steps + 2)
							debugger
							console.log('submit')
						}}
					/>)
					*/}

				{/* {
					steps === 5 && (
						<ExperienceForm
							cbBack={() => setSteps(steps - 1)}
							cbNext={(data, index) => {
								const port = [...experiences]
								if (index !== undefined && index > -1) {
									port.splice(index, 1, { ...data})
								} else {
									port.push({ ...data })
								}
								setExperiences([...port])
								setSteps(steps - 1);
								setExperienceIndex(undefined);
							}}
							data={(experienceIndex !== undefined && experienceIndex > -1)  ? experiences[experienceIndex] : undefined}
							index={experienceIndex}
						/>
					)
				} */}
				{
					steps === 4 && (
						<Expectations
							data={formFourData}
							cbBack={() => setSteps(steps - 1)}
							cbNext={(data) => {
								setFormFourData({ ...data });
								setSteps(steps + 1);
							}}
						/>)
				}
				{
					steps === 5 && (
						<InvestorDetails
							data={formFiveData}
							cbBack={() => setSteps(steps - 1)}
							cbNext={(data) => {
								setFormFiveData({ ...data });
								if (data?.areYouAnAngelInvestor === true) {
									setSteps(steps + 1);
								} else {
									submitData({
										organisationDetails: {
											...formOrganisationData
										},
										investorDetails: {
											...formFiveData,
										},
										...formFirstData,
										...formSecondData,
										...formThirdData,
										...formFourData
									}).then()
								}

							}}
						/>)
				}
				{steps === 6 && (

					<InvestorThirdForm
						data={formSixData}
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data) => {
							setFormSixData({ ...data });
							setSteps(steps + 1);
						}}
					/>
				)}
				{steps === 7 && (

					<InvestorFourForm
						data={formSevenData}
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data) => {
							debugger
							setFormSevenData({ ...data });
							setSteps(steps + 1);
						}}
					/>
				)}
				{
					steps === 8 && (<PortfoliosListView
							cbBack={() => setSteps(steps - 1)}
							add={(data) => {
								setPortfolioIndex();
								setSteps(steps + 1);
							}}
							edit={(ind) => {
								setPortfolioIndex(ind);
								setSteps(steps + 1);
							}}
							remove={(ind) => {
								debugger
								const port = [...portfolios]
								port.splice(ind, 1)
								setPortfolios([...port])
							}}
							portfolios={portfolios}
							submit={() => {
								submitData({
									organisationDetails: {
										...formOrganisationData
									},
									investorDetails: {
										...formFiveData,
										...formSixData,
										...formSevenData,
									},
									...formFirstData,
									...formSecondData,
									...formThirdData,
									...formFourData
								}).then()
							}}
						/>
					)
				}
				{steps === 9 && (
					<InvestorPortfolioForm
						cbBack={() => setSteps(steps - 1)}
						cbNext={(data, index) => {
							const port = [...portfolios]
							if (index !== undefined && index > -1) {
								port.splice(index, 1, { ...data})
							} else {
								port.push({ ...data })
							}
							setPortfolios([...port])
							setSteps(steps - 1);
							setPortfolioIndex(undefined);

						}}
						data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}

						index={portfolioIndex}
					/>)


				}
			</Container>
		</>
	);
}
