import * as React from "react";

import {
  Box,
  Stack,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider,
  Button,
  Portal,
  Grid, Paper,  styled,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { Support } from "@mui/icons-material";
import { AuthUserContext } from "../../../../providers/auth-provider";
import { USER_TYPES } from "../../../../utils/enums/userTypes.enum";
import MemberFavourite from "../../common/MemberFav";
import ListItemStartConversation from "./list-item-start-conversation";
import StartupListItem from "./startup-list-item";
import InvestorListItem from "./investor-list-item";
import ServiceProviderInvestorListItem from "./service-provider-investor-list-item";
import ServiceProviderListItem from "./service-provider-list-item";
import ExpertListItem from "./expert-list-item";
import BusinessDeveloperListItem from "./business-developer-list-item";
import TalentListItem from "./talent-list-item";
import OrganisationInvestorListItem from "./organisation-investor-list-item";
import OrganisationListItem from "./organisation-list-item";
import {
  BUSINESS_DEVELOPER,
  USER_TYPES_TO_NAME,
} from "../../../../utils/enums/labels.enum";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: '#fff',
  color: theme.palette.text.secondary,
  boxShadow: '0px 0px 13px -2px rgba(0, 0, 0, 0.15)',
  borderRadius: "10px",
  height: 250,
  width:175
}));
export default function AdminSuggestedMatches({
  matches,
  moreMatches,
  suggestedUserId,
  itemWidth,
}) {
  const { user, profile } = React.useContext(AuthUserContext);
  debugger;
  const [isLoading, setLoading] = React.useState(false);
  const length = moreMatches.length > 5 ? 5 : moreMatches.length;
  console.log("length", moreMatches.length, length);
  let matchedProfiles = JSON.parse(JSON.stringify(moreMatches));
  matchedProfiles = matchedProfiles.slice(0, length);
  console.log("matched length", matchedProfiles.length);

  const [open, setOpen] = React.useState(false);
  const navigator = useNavigate();
  return (
    <>
    
     {matchedProfiles?.length > 0 && (
       matchedProfiles?.map((record) => (
<>    
        
				{record.userType === "startup" ? (
                  <StartupListItem
                    data={record}
                    key={record?.uid}
                    href={`/m/${profile?.userType}/startup-details/${record?.uid}`}
					itemWidth={itemWidth}
                  />
                ) : record.userType === "investor" ? (
                  <>
                    {record?.investor?.investorType !== "Angel Investor" && (
                      <OrganisationInvestorListItem
                        data={record}
                        key={record?.uid + record?.createdAt}
                        href={`/m/${profile?.userType}/common-organisation-profile`}
						itemWidth={itemWidth}
						
                      />
                    )}
                    <InvestorListItem
                      data={record}
                      key={record?.uid}
                      href={`/m/${profile?.userType}/investor-details/${record?.uid}`}
					  itemWidth={itemWidth}
                    />
                  </>
                ) : record.userType === "expert" ? (
                  <ExpertListItem
                    data={record}
                    key={record?.uid}
                    href={`/m/${profile?.userType}/expert-details/${record?.uid}`}
					itemWidth={itemWidth}
                  />
                ) : record.userType === "talent" ? (
                  <TalentListItem
                    data={record}
                    key={record?.uid}
                    href={`/m/${profile?.userType}/talent-details/${record?.uid}`}
					itemWidth={itemWidth}
                  />
                ) : record.userType === "service-provider" ? (
                  <>
                    <ServiceProviderListItem
                      data={record}
                      key={record?.uid}
                      href={`/m/${profile?.userType}/service-provider-details/${record?.uid}`}
					  itemWidth={itemWidth}
                    />
                    {record?.serviceProvider?.organisationType === "Organisation" &&
                      record?.serviceProvider?.organisationDetails && (
                        <OrganisationListItem
                          data={record}
                          key={record?.uid + "organisation"}
                          href={`/m/${profile?.userType}/organisation-profile`}
						  itemWidth={itemWidth}
                        />
                      )}
                  </>
                ) : record.userType === "business-developer" ? (
                  <BusinessDeveloperListItem
                    data={record}
                    key={record?.uid}
                    href={`/m/${profile?.userType}/business-developer-details/${record?.uid}`}
					itemWidth={itemWidth}
                  />
                ) : (
                  false
                )}
				
		
   
    </>    ))
      )}
    

    </>
  );
}
