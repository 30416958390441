import * as React from "react";
import Typography from "@mui/material/Typography/Typography";
import Stack from "@mui/material/Stack/Stack";
import moment from "moment/moment";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import {
	Box,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
	DownloadDoneOutlined,
	DownloadingOutlined,
	DownloadOutlined,
	InsertDriveFile,
} from "@mui/icons-material";
import { AuthUserContext } from "../../../../providers/auth-provider";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { DB } from "../../../../config/firebase/firebase";
import DownloadFileHelper from "../../../../utils/download-file-helper";
export default function SystemGeneratedMessageComponent({ msg, type }) {
	const { profile } =
		React.useContext(AuthUserContext);

	React.useEffect(() => {
		debugger
		console.log(msg);
		if (msg && msg.receiver === profile?.uid && msg.read === false) {
			const msgRef = doc(DB, "messages", msg.id);
			setDoc(
				msgRef,
				{
					read: true,
					status: "seen",
					updatedAt: serverTimestamp(),
				},
				{ merge: true }
			).then();
		}
	}, [msg]);
	return(

		<Box
			sx={{
				width: "100%",
				p: 2,
				borderRadius: "5px",
				textAlign: "center",
			}}>
			<Typography
				variant={"caption"}
				color={"deepskyblue"}>
				{msg?.text}
			</Typography>
		</Box>
	)
}
