import React from "react";
import {addDoc, collection, serverTimestamp} from "firebase/firestore";
import {DB} from "../config/firebase/firebase";


const PostNotification = (data) => new Promise(async (resolve, reject) => {
  
  try {
    let add = await addDoc(collection(DB, "notifications"),
      {
        ...data,
        createdAt:serverTimestamp(),
        updatedAt:serverTimestamp(),
        read:false,
      });
      console.log('notification sent', add)
    resolve(add.id)
  } catch (e) {
    console.log('notification rejected',e)
    reject()
  }
})

export default PostNotification;
