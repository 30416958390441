import React from "react";
import {Avatar, Grid, Paper, Stack, styled, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import ListItemStartConversation from "./list-item-start-conversation";
import MemberFavourite from '../../screens/common/MemberFav'


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: '#fff',
  color: theme.palette.text.secondary,
  boxShadow: '0px 0px 13px -2px rgba(0, 0, 0, 0.15)',
  borderRadius: '15px',
  minHeight: 348
}));
export default function TalentListItem({data, href, key}){
  const navigator = useNavigate();

  return(

    <Grid item  lg={4} md={4} sm={6} xs={12}  wrap={'nowrap'} key={key}>
      <Item >
        <Stack direction={"column"} spacing={2} sx={{width: '100%'}}
               justifyContent="center"
               alignItems="center">
          <Stack sx={{ ml: "90%", mb:"-15%", mt:"-2.5%" }}>
            <MemberFavourite data={{ ...data,card: "talent" }} />
          </Stack>
          <Box
					sx={{
							position: "relative",
							top:"40px",
							bottom : "20px",
							width: "280px",
							height: "200px",
							background: "#F6F8FA",
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px"
						}}
					>
          <Stack direction={"column"} spacing={2}
                 justifyContent="center"
						style={{cursor:"pointer"}}
                 alignItems="center"   onClick={() => navigator(href)} >
            <Box sx={{border: '1px solid #204488', p:.75, borderRadius:'50%' , marginTop : "-40px"}}>
              <Avatar
                alt="Remy Sharp"
                src={data?.photoURL}
                sx={{ width: 92, height: 92}}
              >
                <Typography variant={'h5'}>{data?.displayName && data?.displayName.charAt(0)}</Typography>
              </Avatar>
            </Box>
            <Typography variant={'h6'} sx={{fontWeight: 600, mb: 0, lineHeight: 1.2}}>
              {data?.displayName} <br/>
              <font size={3} style={{fontWeight: 300}}>
                Talent
              </font>
            </Typography>

            <Box/>
          </Stack>
          <ListItemStartConversation data={{...data, creatorData: data}} />
          </Box>
          <Box/>
          <Box/>
          <Box/>
          {data?.talent && data?.talent?.skills && data?.talent?.skills.length > 0 &&
            <Stack direction={"row"} spacing={2} sx={{width: '100%'}}>

              <Typography variant={'body2'} sx={{fontWeight: 'bold', flex: 1, textAlign:'left'}}>Skills</Typography>
              <Typography variant={'body2'} sx={{width: 200,textAlign:'right'}} noWrap={true}>{data?.talent?.skills.map((d, i) => d !== data?.talent?.skills[data?.talent?.skills.length -1] ? `${d}, ` : d ) }</Typography>
            </Stack>
          }


          {data?.talent && data?.talent?.supportStage && data?.talent?.supportStage.length > 0 &&
            <Stack direction={"row"} spacing={2}  sx={{width: '100%'}}>
              <Typography variant={'body2'} sx={{fontWeight: 'bold', flex: 1, textAlign:'left'}}>Support Startup at Stage</Typography>
              <Typography variant={'body2'} sx={{width: 130, textAlign:'right'}} noWrap={true}>{data?.talent?.supportStage.map((d, i) => d !== data?.talent?.supportStage[data?.talent?.supportStage.length -1] ? `${d}, ` : d ) }</Typography>

            </Stack>
          }
        </Stack>
      </Item>
    </Grid>

  )
}