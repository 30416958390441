import React from "react";
import {
  doc, getDoc, serverTimestamp, setDoc, addDoc,
  collection, deleteDoc
} from "firebase/firestore";
import {
  Container,
  Stack,
  Typography,
  IconButton,
  styled, Backdrop, CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import { DB } from "../../../config/firebase/firebase";

import ServiceProviderSecondForm from "./../../service-provider/onboarding-forms/service-provider-second-form";
import ServiceProviderThirdForm from "./../../service-provider/onboarding-forms/service-provider-third-form";
import ServiceProviderFirstForm from "./../../service-provider/onboarding-forms/service-provider-first-form";
import Expectations from "./../../expert/onboarding-forms/expectations";
import InvestorDetails from "./../../expert/onboarding-forms/investor-details";
import InvestorThirdForm from "./../../investor/onboarding-forms/investor-third-form";
import InvestorFourForm from "./../../investor/onboarding-forms/investor-four-form";
import PortfoliosListView from "./../../investor/onboarding-forms/portfolios-list-view";
import InvestorPortfolioForm from "./../../investor/onboarding-forms/portfolio-form";
import ServiceProviderOrganisationForm from "./../../service-provider/onboarding-forms/service-provider-organisation";
import {EXPERT, SERVICE_PROVIDER} from "../../../utils/enums/labels.enum";
import ExpertCard from "./expert-card";
import PostProfileSuccessDialog from "./post-profile-success-dialog";
import ServiceProviderCard from "./service-provider-card";
import {getIdToken} from "firebase/auth";
import AdminPostOnboardProfileData from "../../../api/admin-post-onboard-profile-data";
import SaveAsDraft from "../../../api/save-as-draft";
export default function FillServiceProviderProfile() {
  const Input = styled("input")({
    display: "none",
  });
  const {uid} = useParams()
  const { user, setProfile } = React.useContext(AuthUserContext);
  const selectedUser = useLocation();
  const userType = selectedUser.state.type
  const {state} = useLocation()
  
  console.log(userType)
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [steps, setSteps] = React.useState(0);
  const [hasOrganisation, setHasOrganisation] = React.useState(false);


  const [formFirstData,setFormFirstData] = React.useState()
  const [formSecondData,setFormSecondData] = React.useState()
  const [formOrganisationData,setFormOrganisationData] = React.useState()
  const [formThirdData,setFormThirdData] = React.useState()
  const [formFourData,setFormFourData] = React.useState()
  const [formFiveData,setFormFiveData] = React.useState()
  const [formSixData,setFormSixData] = React.useState()
  const [formSevenData,setFormSevenData] = React.useState()
  
  const [portfolioIndex, setPortfolioIndex] = React.useState();
  const [portfolios, setPortfolios] = React.useState([]);
  
  const navigator = useNavigate();
  
  /*
      const formikFirst = useFormik({
          initialValues:
              ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileInitValue,
          validationSchema:
              ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileSchema,
          onSubmit: async (values) => {
              try {
                  debugger;
                  const userRef = doc(DB, "users", user.uid);
                  const serviceProvider = {
                      ...values,
                      photoURL: profileImage?.photoURL
                          ? profileImage.photoURL
                          : "",
                  };
                  await setDoc(
                      userRef,
                      {
                          photoURL: profileImage?.photoURL
                              ? profileImage.photoURL
                              : "",
                          serviceProvider,
                      },
                      { merge: true }
                  );
                  setSteps(1);
              } catch (e) {
                  debugger;
                  console.log(e);
              }
          },
      });
      const formikSecond = useFormik({
          initialValues:
              ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileSecondInitValue,
          validationSchema:
              ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileSecondSchema,
          onSubmit: async (values) => {
              try {
                  const socialProof = [];
                  if (files.length > 0) {
                      for (let file of files) {
                          const path = `service-providers/${user.uid}/social-proofs/${file.file.name}`;
                          socialProof.push(path);
                      }
                  }
                  formikSecond.setFieldValue("socialProof", socialProof);
                  const userRef = doc(DB, "users", user.uid);
                  await setDoc(
                      userRef,
                      {
                          serviceProvider: {
                              ...formikFirst.values,
                              values,
                              socialProof,
                          },
                      },
                      { merge: true }
                  );
                  setSteps(2);
              } catch (e) {
                  debugger;
                  console.log(e);
              }
          },
      });
      const formikThird = useFormik({
          initialValues:
              ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileThirdInitValue,
          validationSchema:
              ServiceProviderDetailsValidation.ServiceProviderOnBoardingProfileThirdSchema,
          onSubmit: async (values) => {
              try {
                  debugger;
                  const userRef = doc(DB, "users", user.uid);
                  await setDoc(
                      userRef,
                      {
                          serviceProvider: {
                              ...formikSecond.values,
                              ...formikFirst.values,
                              ...values,
                              photoURL: profileImage?.photoURL
                                  ? profileImage.photoURL
                                  : "",
                          },
                          onboard: true,
                      },
                      { merge: true }
                  );
                  const getdoc = await getDoc(userRef);
                  await setProfile(getdoc.data());
                  navigator("/service-provider/timeline");
                  // setSteps(2);
              } catch (e) {
                  debugger;
                  console.log(e);
              }
          },
      });
  */
  
  const submitData = async (values) => {
    setLoading(true)
  
    const copyValue = {...values, organisationDetails: {...values?.organisationDetails}, investorDetails: {...values?.investorDetails,portfolio: []}}
  
    const {linkedinURL} = state.contactData
    const serviceProvider = {
      ...copyValue,
      experiences: [],
      photoURL: values?.photoURL
        ? values.photoURL
        : "",
      linkedinURL
    };
    const postData = {
      createdById:user?.uid,
      createdBy:'admin',
      type: state?.type,
      ...state.contactData,
      photoURL:values?.photoURL
        ? values.photoURL
        : "",
      serviceProvider,
      onboard: true,
      profileStatus: 'complete',
      remainderStatus:'',
      searchName: state.contactData?.displayName.toLowerCase()
    
    }
    const idToken = await getIdToken(user);
    await AdminPostOnboardProfileData(postData, idToken)
    setLoading(false)
    setOpen(true)
  }
  
 
  return (
    <>
      <Container maxWidth={"full-width"} sx={{ marginY: 5 }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}>
          <IconButton
            onClick={() =>
              steps === 0 ? navigator(uid ? `/admin/on-boarding/contact-info/${uid}`:'/admin/on-boarding/contact-info', {state}) : steps === 2 && !hasOrganisation ? setSteps(steps - 2) : setSteps(steps - 1)
              
            }>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography
            variant={"h5"}
            sx={{ textAlign: "left", lineHeight: 1 }}>
            {steps !== 8 ? SERVICE_PROVIDER.SINGULAR_LABEL : 'Final step'}
            <br />{" "}
            <small style={{ fontSize: 14, color: "#8F8F8F" }}>
              {steps >= 0 && steps < 4 &&  SERVICE_PROVIDER.SERVICE_PROVIDER_PROFILE}
              {/* {steps === 4 &&  'Experience'}
							{steps === 5 &&  'Add experience'} */}
              {steps === 4 &&  SERVICE_PROVIDER.EXPECTATIONS}
              {steps > 5 && steps < 7 &&  SERVICE_PROVIDER.INVESTOR_DETAILS}
              {steps === 8 &&  'send profile details via email'}
            
            </small>
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={"lg"} sx={{ mb: 5 }}>
        {steps === 0 && (
          <ServiceProviderFirstForm
            data={formFirstData}
            uid={uid}
            cbBack={() => navigator(uid ? `/admin/on-boarding/contact-info/${uid}`:'/admin/on-boarding/contact-info', {state})}
            cbNext={(data) => {
              
              data.organisationType === 'Organisation' ? setHasOrganisation(true)  : setHasOrganisation(false)
              setFormFirstData({ ...data });
              data.organisationType !=='Organisation' ? setSteps(steps + 2) : setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData: data}, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
              
            }}
          />
        )}
        {steps === 1  && (
          <ServiceProviderOrganisationForm
            uid={uid}
  
            isVC={formFirstData && formFirstData?.organisationType === 'Organisation'}
            data={formOrganisationData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              
              setFormOrganisationData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData, formOrganisationData : data}, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({
                ...state,
                formFirstData,
                formOrganisationData: data
              }, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />
        )}
        {steps === 2 && (
          <ServiceProviderSecondForm
            uid={uid}
            isVC={formFirstData && formFirstData?.organisationType === 'Organisation'}
            data={formSecondData}
            cbBack={() => {formFirstData?.organisationType ==='Organisation' ? setSteps(steps - 1): setSteps(steps - 2)}}
            cbNext={(data) => {
              debugger
              setFormSecondData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData,  formSecondData: data}, uid, user)
                .then()
              
            }}
            cbDraft={(data) => {
              SaveAsDraft({
                ...state,
                formFirstData,
                formSecondData: data
              }, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />
        )}
        
        {steps === 3 && (
          <ServiceProviderThirdForm
            uid={uid}
            data={formThirdData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormThirdData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData,  formSecondData, formThirdData: data}, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({
                ...state,formFirstData,  formSecondData, formThirdData: data
              }, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />
        )}
        {
          steps === 4 && (
            <Expectations
              uid={uid}
  
              data={formFourData}
              cbBack={() => setSteps(steps - 1)}
              cbNext={(data) => {
                setFormFourData({ ...data });
                setSteps(steps + 1);
                uid && SaveAsDraft({...state,formFirstData,  formSecondData, formThirdData, expectation: data}, uid, user)
                  .then()
              }}
              cbDraft={(data) => {
                SaveAsDraft({...state,formFirstData,  formSecondData, formThirdData, expectation: data}, uid, user)
                  .then(() => navigator('/admin/draft-profiles'))
  
              }}
            />)
        }
        {
          steps === 5 && (
            <InvestorDetails
              uid={uid}
              data={formFiveData}
              cbBack={() => setSteps(steps - 1)}
              cbNext={(data) => {
                setFormFiveData({ ...data });
                if (data?.areYouAnAngelInvestor === true) {
                  setSteps(steps + 1);
                } else {
                  setSteps(steps + 3);
                }
                uid && SaveAsDraft({...state,formFirstData,  formSecondData, formThirdData, expectation:formFourData, investorFormOne: data}, uid, user)
                  .then()
                
              }}
              cbDraft={(data) => {
                SaveAsDraft({...state,formFirstData,  formSecondData, formThirdData, expectation:formFourData, investorFormOne: data}, uid, user)
                  .then(() => navigator('/admin/draft-profiles'))
  
              }}
             
            />)
        }
        {steps === 6 && (
          
          <InvestorThirdForm
            uid={uid}
            data={formSixData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormSixData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData,  formSecondData, formThirdData, expectation:formFourData,
                investorFormOne: formFiveData,investorFormTwo : data }, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData,  formSecondData, formThirdData, expectation:formFourData,
                investorFormOne: formFiveData,investorFormTwo : data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />
        )}
        {steps === 7 && (
          
          <InvestorFourForm
            uid={uid}
            data={formSevenData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormSevenData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData,  formSecondData, formThirdData, expectation:formFourData,
                investorFormOne: formFiveData,investorFormTwo : formSixData, investorFormThree: data }, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData,  formSecondData, formThirdData, expectation:formFourData,
                investorFormOne: formFiveData,investorFormTwo : formSixData, investorFormThree: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />
        )}
  
        {steps === 8  &&
          <ServiceProviderCard
            data={{
              organisationDetails: {
                ...formOrganisationData
              },
              ...formFiveData,
              ...formFirstData,
              ...formSecondData,
              ...formThirdData,
              ...formFourData,
              }}
            cbBack={() => formFiveData?.areYouAnAngelInvestor === true ? setSteps(steps - 1) : setSteps(steps - 3)}
            send={() => {
              uid && deleteDoc(doc(DB, 'users', uid)).then()
              submitData({
                organisationDetails: {
                  ...formOrganisationData
                },
                investorDetails: {
                  ...formFiveData,
                  ...formSixData,
                  ...formSevenData,
                },
                ...formFirstData,
                ...formSecondData,
                ...formThirdData,
                ...formFourData
              }).then()
             
            }}
          />}
  
        {
          open && <PostProfileSuccessDialog open={open} setOpen={(bool) => {
      
            setOpen(bool)
            navigator('/admin/dashboard')
          }} />
        }
        {
          loading &&
          <Backdrop  open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        
      {/*  {
          steps === 8 && (<PortfoliosListView
              cbBack={() => setSteps(steps - 1)}
              add={(data) => {
                setPortfolioIndex();
                setSteps(steps + 1);
              }}
              edit={(ind) => {
                setPortfolioIndex(ind);
                setSteps(steps + 1);
              }}
              remove={(ind) => {
                debugger
                const port = [...portfolios]
                port.splice(ind, 1)
                setPortfolios([...port])
              }}
              portfolios={portfolios}
              submit={() => {
                submitData({
                  organisationDetails: {
                    ...formOrganisationData
                  },
                  investorDetails: {
                    ...formFiveData,
                    ...formSixData,
                    ...formSevenData,
                  },
                  ...formFirstData,
                  ...formSecondData,
                  ...formThirdData,
                  ...formFourData
                }).then()
              }}
            />
          )
        }
        {steps === 9 && (
          <InvestorPortfolioForm
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data, index) => {
              const port = [...portfolios]
              if (index !== undefined && index > -1) {
                port.splice(index, 1, { ...data})
              } else {
                port.push({ ...data })
              }
              setPortfolios([...port])
              setSteps(steps - 1);
              setPortfolioIndex(undefined);
              
            }}
            data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}
            
            index={portfolioIndex}
          />)
          
          
        }*/}
      </Container>
    </>
  );
}
