import {Grid, IconButton, Link, Stack, Typography} from "@mui/material";
import React from "react";
import {EditSharp} from "@mui/icons-material";
import TopPrioritiesStartupComponent
  from "../../../../../components/common/profile/edit/startup/top-priorities-startup-component";
import { BUSINESS_DEVELOPER } from "../../../../../utils/enums/labels.enum";


const styleFont = {
  backgroundColor: "#F6F8FA",
  padding: "8px 15px",
  borderRadius: "5px",
  fontSize: 12,
};
export default function StartupTopPriorities({ profile }) {
  const [open,setOpen] = React.useState(false)
  const profileRegex = /profile/
  return (
      <>

      {

  <>
  <Stack
      direction={"column"}
      spacing={3}
      sx={{
        // border: '0.5px solid #000000',
        // boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
        borderRadius: "15px",
        backgroundColor: "#fff",
        minHeight: 100,
        px: 5.5,
        py: 6,
        px: 2,
        py: 3,

      }}
  >
    <Stack direction={"row"} spacing={2} justifyContent="flex-start"
           alignItems="center" sx={{width: '100%'}}>
      <Typography variant={"h6"} sx={{fontWeight:"600"}}>Top Priorities</Typography>
      {profileRegex.test(window.location.pathname) && <IconButton onClick={() => setOpen(true)}>
        <EditSharp/>
      </IconButton>}
    </Stack>
    <Grid container gap={2} sx={{display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)'}}>
      <Grid>
        <Stack direction={'column'} spacing={2}>
          <Typography variant={'p'} sx={{lineHeight: 2}}>
            Looking for {BUSINESS_DEVELOPER.SINGULAR_LABEL}<br/>
            <font style={styleFont}>
              {profile?.startup?.lookingForBusinessDeveloper ? 'Yes' : 'NO'}
            </font>
          </Typography>
        </Stack>
      </Grid>
      <Grid>
        <Stack direction={'column'} spacing={2}>
          <Typography variant={'p'} sx={{lineHeight: 2}}>
            Looking for Experts<br/>
            <font style={styleFont}>
              {profile?.startup?.lookingForExperts ? 'Yes' : 'NO'}
            </font>
          </Typography>
        </Stack>
      </Grid>
      <Grid>
        <Stack direction={'column'} spacing={2}>
          <Typography variant={'p'} sx={{lineHeight: 2}}>
            Looking for Service Provider<br/>
            <font style={styleFont}>
              {profile?.startup?.lookingForServiceProvider ? 'Yes' : 'NO'}
            </font>
          </Typography>
        </Stack>
      </Grid>
      <Grid>
        <Stack direction={'column'} spacing={2}>
          <Typography variant={'p'} sx={{lineHeight: 2}}>
            Looking for Funding<br/>
            <font style={styleFont}>
              {profile?.startup?.lookingForFunding ? 'Yes' : 'NO'}
            </font>
          </Typography>
        </Stack>
      </Grid>
    </Grid>


  </Stack>
  </>
}
        <TopPrioritiesStartupComponent profile={profile?.startup} open={open} handleClose={() => setOpen(false)} />
</>
  )
}

// import { Grid, Link, Stack, Typography } from "@mui/material";
// import React from "react";


// const styleFont = {
//   backgroundColor: "#F6F8FA",
//   padding: "8px 15px",
//   borderRadius: "5px",
//   fontSize: 14,
// };
// export default function StartupTopPriorities({ profile }) {
//   return (

//     <Stack
//       direction={"column"}
//       spacing={3}
//       sx={{


//         borderRadius: "15px",
//         backgroundColor: "#fff",
//         minHeight: 100,

//       }}
//     >
//       <Typography variant={"h5"}>Top Priorities</Typography>
//       <Grid container gap={8}>
//         <Grid >
//           <Stack direction={'column'} spacing={1}>
//             <Typography variant={'h6'} sx={{lineHeight:2}}>
//               Looking for Business Developer<br/>
//               <font style={styleFont}>
//                 {profile?.startup?.lookingForBusinessDeveloper ? 'Yes' : 'NO'}
//               </font>
//             </Typography>
//           </Stack>
//         </Grid>
//         <Grid >
//           <Stack direction={'column'} spacing={1}>
//             <Typography variant={'h6'} sx={{lineHeight:2}}>
//               Looking for Experts<br/>
//               <font style={styleFont}>
//                 {profile?.startup?.lookingForExperts ? 'Yes' : 'NO'}
//               </font>
//             </Typography>
//           </Stack>
//         </Grid>
//         <Grid >
//           <Stack direction={'column'} spacing={1}>
//             <Typography variant={'h6'} sx={{lineHeight:2}}>
//               Looking for Service Provider<br/>
//               <font style={styleFont}>
//                 {profile?.startup?.lookingForServiceProvider ? 'Yes' : 'NO'}
//               </font>
//             </Typography>
//           </Stack>
//         </Grid>
//         <Grid >
//           <Stack direction={'column'} spacing={1}>
//             <Typography variant={'h6'} sx={{lineHeight:2}}>
//               Looking for Funding<br/>
//               <font style={styleFont}>
//                 {profile?.startup?.lookingForFunding ? 'Yes' : 'NO'}
//               </font>
//             </Typography>
//           </Stack>
//         </Grid>
//       </Grid>

//     </Stack>
//   )
// }            