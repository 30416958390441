import React from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import {
  Stack,
  Avatar,
  Button,
  Box,
  Typography,
  Portal,
  Dialog,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function ExperienceCard({ data, i, edit, remove }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [open, setOpen] = React.useState(false);
  const startDate = moment(data?.startDate);
  const endDate = data?.currentlyWorkingInThisRole
    ? moment()
    : moment(data?.endDate);
  const duration = moment.duration(endDate.diff(startDate));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  let formattedDuration = "";
  if (years > 0) {
    formattedDuration += years === 1 ? `${years} year` : `${years} years`;
    if (months > 0) {
      formattedDuration +=
        months === 1 ? `, ${months} month` : `, ${months} months`;
    }
  } else if (months > 0) {
    formattedDuration += months === 1 ? `${months} month` : `${months} months`;
  } else if (days > 0) {
    formattedDuration += days === 1 ? `${days} day` : `${days} days`;
  }
  return (
    <Grid
      sx={{
        width: !matches ? "100%" : "33%",
        backgroundColor: "#F6F8FA",
        border: "0.5px solid #000000",
        borderRadius: "5px",
        p: 2,
        px: 2,
      }}
    >
      <Stack direction={"column"} spacing={0.5} sx={{ width: "100%" }}>
        <Stack direction={"row"} spacing={1} alignItems={"flex-start"}>
          <Box sx={{ flex: 1 }} />
          <Button
            variant={"text"}
            size={"small"}
            color={"secondary"}
            onClick={edit}
            sx={{ color: "#8F8F8F" }}
          >
            edit
          </Button>
          <Button
            variant={"text"}
            size={"small"}
            color={"secondary"}
            sx={{ color: "#8F8F8F" }}
            onClick={() => setOpen(true)}
          >
            delete
          </Button>
        </Stack>
        <Stack direction={"column"} spacing={0.5} sx={{ width: "100%" }}>
          <Typography
            variant={"body1"}
            sx={{
              textAlign: "left",
              marginTop: "-32px",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {data?.companyName < 10
              ? data?.companyName
              : `${data?.companyName.slice(0, 10)}...`}
          </Typography>
          <Typography variant={"caption"} sx={{ textAlign: "left" }}>
            <a href={data?.website} target={"_blank"}>
              {data?.website.length < 30
                ? data?.website
                : `${data?.website.slice(0, 30)}...`}
            </a>
          </Typography>
          <span />
          <span />
          <span />
          <span style={{ flex: 1 }} />
          <Stack direction={"row"} spacing={1} alignItems={"flex-start"}>
            <Typography
              variant={"caption"}
              sx={{ textAlign: "left", fontWeight: "500", fontSize: "14px" }}
            >
              {data?.role}
            </Typography>
            <span style={{ flex: 1 }} />
            <Typography variant={"caption"} sx={{ textAlign: "left" }}>
              {startDate.format("MMM Do")} -{" "}
              {data?.currentlyWorkingInThisRole
                ? "Present"
                : endDate.format("MMM Do")}
              {formattedDuration && ` (${formattedDuration})`}
            </Typography>
          </Stack>
          <Typography
            variant={"caption"}
            paragraph={true}
            sx={{
              textAlign: "left",
              color: "#8F8F8F",
              width: 275,
              overflowY: "hidden",
            }}
          >
            {data?.experience.length < 200
              ? data?.experience
              : `${data?.experience.slice(0, 200)}...`}
            {/* {
            data?.experience
          } */}
          </Typography>
        </Stack>
      </Stack>
      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"xs"}
        >
          <DialogContent sx={{ minWidth: 144 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={2}>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                alignItems={"center"}
              >
                <img
                  src={"/assets/delete-confirmation.png"}
                  height={46}
                  width={50}
                  alt="delete_bin"
                ></img>
              </Stack>
              <Typography
                component="span"
                variant="h6"
                color="text.primary"
                sx={{
                  textAlign: "center",
                  flex: 1,
                  padding: 1,
                  fontWeight: 500,
                }}
              >
                Delete Work Experience
              </Typography>
              <Typography variant={"body2"} sx={{ textAlign: "center" }}>
                Are you sure you want to delete this work experience? This
                action can not be undone.
              </Typography>
              <br />
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Button
                  variant={"outlined"}
                  sx={{ px: 5 }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  variant={"contained"}
                  sx={{ px: 5 }}
                  onClick={() => {
                    remove();
                    setOpen(false);
                  }}
                >
                  yes
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </Grid>
  );
}
