import * as React from "react";
import {
  Container,
  Stack,
  Typography,
  Box,
  Avatar,
  Button,
  Portal,
  List,
  ListItem,
  ListItemText,
  TextField,
  styled,
  CircularProgress,
  Chip,
  Autocomplete,
  CardMedia,
  Paper,
  ListItemButton,
  ListItemAvatar,
} from "@mui/material";
import referralCodeGenerator from "referral-code-generator";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { AuthUserContext } from "../../providers/auth-provider";
import PersonIcon from "@mui/icons-material/Person";
import StartPost from "../../components/common/timeline/start-post";
import LeftPanel from "../../components/common/timeline/left-panel";
import RightPanel from "../../components/common/timeline/right-panel";
import PostItem from "../../components/common/timeline/post-item";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Tooltip from "@material-ui/core/Tooltip";
import {
  AddAPhoto,
  EmailOutlined,
  EmailRounded,
  Send,
  SettingsInputAntennaTwoTone,
} from "@mui/icons-material";
import UploadFile from "../../utils/upload-file";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
  limit,
  startAfter,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";

//import Picker from 'emoji-picker-react';
import { DB } from "../../config/firebase/firebase";
import OffersRightPanel from "../../components/common/timeline/offer-right-panel";
import ScrollToTopButton from "../mobile/components/common/scroll-to-top-button";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { USER_TYPES_TO_NAME } from "../../utils/enums/labels.enum";
import NotificationEmail from "../../api/notification-email";
import PostNotification from "../../api/post-notification";
import { shortenURL } from "../../utils/shortenURL";
const Input = styled("input")({
  display: "none",
});
export default function TimelineScreen() {
  const navigator = useNavigate();
  const [URL, setURL] = React.useState();
  const [imageDimensions, setImageDimensions] = React.useState({});
  const [chosenEmoji, setChosenEmoji] = React.useState(null);
  const { profile } = React.useContext(AuthUserContext);
  const myprofile = { profile };
  const [state, setState] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [tag, setTag] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openMentionBox, setOpenMentionBox] = React.useState(false);
  const [posted, setPosted] = React.useState(false);
  const [text, setText] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [searchUser, setSearchUser] = React.useState("");
  const [image, setImage] = React.useState("");
  const [video, setVideo] = React.useState("");
  const [otherFiles, setOtherFiles] = React.useState("");
  const [file, setFile] = React.useState();
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [inputbtn, setinputbtn] = React.useState();
  const [lastDoc, setLastDoc] = React.useState();
  const [postData, setPostData] = React.useState();
  const [updated, setUpdated] = React.useState(false);
  const arr = ["Success! Your Post has been Uploaded."];
  const arr2 = ["Success! Your Post has been Edited."];
  const [selected, setSelected] = React.useState([]);
  const storage = getStorage();
  const loadMore = async () => {
    setLoading(true);
    const q = query(
      collection(DB, "timelines"),
      orderBy("createdAt", "desc"),
      startAfter(lastDoc),
      limit(20)
    );
    const querySnap = await getDocs(q);
    if (querySnap.size > 0) {
      const list = [...posts];
      querySnap.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      console.log(list);
      setPosts(list);
      querySnap.size < 20
        ? setLastDoc(undefined)
        : setLastDoc(querySnap.docs[querySnap.docs.length - 1]);
    } else {
      setLastDoc(undefined);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    const q = query(
      collection(DB, "timelines"),
      orderBy("createdAt", "desc"),
      limit(20)
    );

    const unSubs = onSnapshot(q, (querySnap) => {
      if (querySnap.size > 0) {
        const list = [];
        querySnap.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        querySnap.size < 20
          ? setLastDoc(undefined)
          : setLastDoc(querySnap.docs[querySnap.docs.length - 1]);
        setPosts(list);
      } else {
        setLastDoc(undefined);
      }
    });
    return () => unSubs;
  }, []);

  const resetAll = async () => {
    await setImage("");
    await setVideo("");
    await setFile();
    await setURL();
    await setLoading(false);
    await setOtherFiles("");
    await setPostData();
    await setSelected([]);
    // window.document.getElementById('attachment-media')
    // window.document.getElementById('attachment-video').empty()
    // window.document.getElementById('attachment-file').empty()
  };
  return (
    <React.Fragment>
      <Container
        maxWidth={"xl"}
        sx={{ textAlign: "left", paddingY: 5, height: "90vh" }}
      >
        <Stack direction={"row"} spacing={5} sx={{ width: "100%", mb: 5 }}>
          <div style={{ flex: 1 }}>
            <Typography variant={"h4"} sx={{ fontWeight: 700 }}>
              Hello {shortenURL(profile?.displayName, 20)} &nbsp; 👋
            </Typography>
            <Typography variant={"body1"} color={"secondary"}>
              Welcome to ASTRANOVA
            </Typography>
          </div>
          <div style={{ flex: 2 }}>
            {/*<Button*/}
            {/*	sx={{*/}
            {/*		border: "0.5px solid #233DFF",*/}
            {/*		borderRadius: "6px",*/}
            {/*		margin: " 0px 24px",*/}
            {/*		width: "77px",*/}
            {/*	}}>*/}
            {/*	Filter*/}
            {/*</Button>*/}
            <Button
              onClick={() => navigator(`/${profile.userType}/open-requests`)}
              sx={{
                background: "#233DFF",
                color: "#FFFFFF",
                borderRadius: "6px",
                "&:hover": {
                  backgroundColor: "#233DFF",
                  color: "#FFFFFF",
                },
              }}
            >
              Post a request
            </Button>
          </div>

          <Box
            onSubmit={(event) => event.preventDefault()}
            component="form"
            sx={{
              p: "2px 10px",
              display: "flex",
              alignItems: "center",
              width: 337,
              height: 43,
              borderRadius: "12px",
              backgroundColor: "#E4EAF5",
            }}
          >
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={(e) => setSearch(inputbtn)}
            >
              <SearchIcon sx={{ color: "#051E40BF" }} />
            </IconButton>
            <InputBase
              onChange={(e) => setinputbtn(e.target.value)}
              value={inputbtn}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  setSearch(inputbtn);
                }
              }}
              sx={{
                ml: 1,
                flex: 1,
                color: "#051E40BF",
                input: {
                  "&::placeholder": {
                    opacity: 1,
                  },
                },
              }}
              placeholder="Search"
              inputProps={{ "aria-label": "search post" }}
            />
          </Box>
        </Stack>
        {console.log(postData)}
        <Stack direction={"row"} spacing={15} sx={{ width: "100%", mb: 5 }}>
          <LeftPanel />
          <Stack direction={"column"} spacing={5} sx={{ flex: 1, mb: 5 }}>
            <StartPost callback={() => setOpen(true)} />
            {posts.length > 0 &&
              posts
                .filter((post) => {
                  if (search !== "") {
                    const reg = new RegExp(
                      search.replaceAll("\\", "").toLowerCase()
                    );
                    // console.log(post)
                    return (
                      reg.test(post.text.toLowerCase()) ||
                      reg.test(post.creatorData?.displayName.toLowerCase()) ||
                      (reg.test(
                        post.tags.map((item) => {
                          return item.toLowerCase();
                        })
                      ) &&
                        post)
                    );
                  } else {
                    return post;
                  }
                })
                .map((post, i) => (
                  <PostItem
                    data={post}
                    key={i}
                    callback={(data, image) => {
                      if (data != "deleted") {
                        setPostData(data);
                        if (data?.reposterData) {
                          setText(data?.repostText);
                        } else {
                          setTags([...data?.tags]);
                          setImage(data?.image);
                          setVideo(data?.video);
                          setText(data?.text);
                          setOtherFiles(data?.otherFiles);
                          setTags(data?.tags);
                          setFile(data?.metadata);
                        }
                        console.log("image is ", image);

                        if (data?.type !== "" && data?.type !== "file") {
                          getDownloadURL(
                            ref(
                              storage,
                              data?.type === "image" ? data?.image : data?.video
                            )
                          )
                            .then((url) => {
                              data?.type === "image"
                                ? setURL(url)
                                : setVideo(url);
                            })
                            .catch((error) => {
                              // Handle any errors
                            });
                        }
                        setOpen(true);
                      }
                    }}
                    // myprofile={myprofile}
                  />
                ))}
            {lastDoc && (
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                py={5}
                justifyContent="center"
                alignItems="center"
              >
                <Button size={"small"} onClick={loadMore}>
                  Load more
                </Button>
              </Stack>
            )}
            <div />
          </Stack>
          <Stack direction="column" spacing={2} sx={{ width: 230 }}>
            <RightPanel />
            <OffersRightPanel screen="timeline" />
          </Stack>
        </Stack>
      </Container>
      <Portal>
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
          open={posted}
        >
          <DialogTitle>
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
              sx={{ pt: 0, pb: 0, px: 0 }}
            >
              <Typography sx={{ fontWeight: "500" }}>
                {updated
                  ? arr2[(Math.random() * arr.length) | 0]
                  : arr[(Math.random() * arr.length) | 0]}
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack
              direction={"column"}
              justifyContent={"centre"}
              alignItems={"center"}
              sx={{ pt: 7 }}
            >
              <img src="/assets/post_success.png" alt="none" width={"156"} />
            </Stack>

            <Typography
              type={"h3"}
              textAlign={"center"}
              sx={{ fontWeight: "600", pl: 10, pr: 10 }}
            >
              {updated
                ? "Thanks for that edit! We’re glad you took the time to improve the post even more."
                : "Thank you for taking the time to share your thoughts and ideas with us."}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack sx={{ pt: 7 }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  setPosted(false);
                  setUpdated(false);
                }}
              >
                Continue
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Portal>
      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setFile();
            setOpen(false);
            resetAll();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
              sx={{ pt: 0, pb: 0, px: 0 }}
            >
              <Avatar
                sx={{ height: 70, width: 70 }}
                imgProps={{ sx: { objectFit: "cover" } }}
                src={profile?.photoURL || profile?.startup?.founder?.photoURL}
              >
                <Typography variant={"h3"}>
                  {profile.userType === "startup"
                    ? profile?.startup?.founder?.founderName?.charAt(0) ||
                      profile?.displayName?.charAt(0)
                    : profile?.displayName?.charAt(0)}
                </Typography>
              </Avatar>
              <List sx={{ width: "100%", py: 0, my: 0 }}>
                <ListItem alignItems="flex-start" sx={{ pb: 2 }}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body1"
                          color="text.primary"
                        >
                          {" "}
                          {profile.userType === "startup"
                            ? profile?.startup?.founder?.founderName ||
                              profile?.displayName
                            : profile?.displayName}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
              <Stack
                direction={"column"}
                sx={{ height: 83 }}
                justifyContent="flex-start"
                alignItems="center"
              >
                <IconButton
                  onClick={() => {
                    setFile();
                    setTag("");
                    setTags([]);
                    setText("");
                    setURL("");
                    setOpen(false);
                    resetAll();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <TextField
              variant={"standard"}
              fullWidth
              rows={8}
              value={text}
              multiline={true}
              onChange={(e) => {
                const reg = /(?<=(^|\s))(?!@{2,})@\w+/g;
                const matches = e.target.value.match(reg);

                if (!matches || matches.length === 0) {
                  setOpenMentionBox(false);
                } else {
                  if (!openMentionBox) setOpenMentionBox(true);
                  const lastMatch = matches[matches.length - 1];
                  setSearchUser(lastMatch.slice(1));
                  /* TODO: value store after @ */
                }
                setText(e.target.value);
              }}
              sx={{
                minWidth: 500,
                borderBottom: "none !important",
              }}
              placeholder={"What do you want to talk about?"}
              defaultValue={text}
            />
            <AutoCompleteList
              style={{ marginTop: "0px!important" }}
              openMentionBox={openMentionBox}
              cb={(user) => {
                setOpenMentionBox(false);

                // Get the index of the last mentioned username
                const lastIndex = text.lastIndexOf("@");

                // Replace only the last mentioned username with the selected user's display name
                setText(
                  text.slice(0, lastIndex) +
                    user.displayName +
                    text.slice(lastIndex + searchUser.length + 1)
                );

                setSelected([...selected, user]);
              }}
              searchUser={searchUser}
            />
            {/*<InputBase
              sx={{ ml: 1, width: 600 ,color:'#051E40BF',  }}
              placeholder="What do you want to talk about?"
              inputProps={{ 'aria-label': 'search post' }}
            />*/}
            <Stack direction={"row"} sx={{ pt: 3 }} spacing={3}>
              {file && file?.name && file?.type.includes("image") ? (
                <Stack direction={"row"}>
                  <img
                    alt="none"
                    src={URL}
                    style={{ width: "100%", height: "100%" }}
                  />

                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      width: 20,
                      height: 20,
                      ml: -4,
                      mt: 1,
                      mr: 1,
                      padding: 1.5,
                      color: "black",
                    }}
                    onClick={() => {
                      setFile();
                      setURL("");
                      setImage("");
                      setLoading(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              ) : file?.type.includes("video") ? (
                <Stack direction={"row"}>
                  <CardMedia component="video" image={URL} controls />
                  {/* <video
										controls
										src={URL}
										width="500"
										height="250"
									/> */}
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      width: 20,
                      height: 20,
                      ml: -4,
                      mt: 1,
                      mr: 1,
                      padding: 1.5,
                    }}
                    onClick={() => {
                      setFile();
                      setURL("");
                      setVideo("");
                      setLoading(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              ) : (
                <></>
              )}{" "}
              {loading && <CircularProgress size={20} />}
            </Stack>
            <br></br>
            <div style={{ width: "100%" }}>
              {tags.length > 0 &&
                tags.map((option, index) => (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor: "#E0E3FF",
                      marginLeft: "5px",
                      marginRight: "5px",
                      marginBottom: "10px",
                    }}
                    onDelete={() =>
                      setTags(
                        tags.filter((f) =>
                          f !== option.value ? option.value : option
                        )
                      )
                    }
                    label={option.value ? option.value : option}
                  />
                ))}

              <InputBase
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    tag !== "" &&
                    !tags.find((tg) => tg === tag)
                  ) {
                    setTags([...tags, `#${tag}`]);
                    setTag("");
                  } else if (
                    e.key === " " &&
                    tag !== "" &&
                    !tags.find((tg) => tg === tag)
                  ) {
                    setTags([...tags, `#${tag}`]);
                    setTag("");
                  }
                }}
                size="medium"
                placeholder={tags.length > 0 ? null : "Add hashtag"}
                sx={{
                  transition: "0.1s linear",
                  border: "none!important",
                  outline: "none!important",
                  outlineColor: "#fff",
                  width: "100%",
                }}
              />

              {otherFiles !== "" && file && (
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography variant={"subtitle1"}>{file?.name}</Typography>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      width: 20,
                      height: 20,
                      ml: -4,
                      mt: 1,
                      mr: 1,
                      padding: 1.5,
                    }}
                    onClick={() => {
                      setFile();
                      setURL("");
                      setOtherFiles("");
                      setLoading(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              )}
            </div>
          </DialogContent>
          <DialogActions sx={{ px: 2, py: 2 }}>
            {!postData && (
              <>
                <label htmlFor={"attachment-media"}>
                  <Input
                    id={"attachment-media"}
                    type={"file"}
                    onClick={(e) => (e.target.value = null)}
                    accept={"image/*"}
                    // accept={".jpg,.jpeg,.png,.gif,.webp"}
                    onChange={async (e) => {
                      const list = [];
                      setLoading(true);
                      for (let file of e.target.files) {
                        console.log(file);
                        setFile(file);
                        const url = await UploadFile(
                          file,
                          `timeline/${profile?.uid}/${file?.name}`
                        );
                        const image = new Image();
                        image.src = url;
                        console.log(image);
                        image.onload = function () {
                          console.log(this.width);
                          console.log(this.height);
                          setImageDimensions({
                            width:
                              this.width > 500
                                ? this.width / 2 < 300
                                  ? 300
                                  : 500
                                : this.width,
                            height:
                              this.height > 250
                                ? this.height / 2 < 125
                                  ? 125
                                  : 250
                                : this.height,
                          });
                        };
                        // console.log(URL);
                        setURL(url);
                        setLoading(false);
                        console.log(file);
                        list.push(`timeline/${profile?.uid}/${file?.name}`);
                      }
                      setImage(list[0]);
                      console.log(imageDimensions);
                    }}
                  />
                  <IconButton component={"span"}>
                    <img src={"/assets/Vector (3).png"} />
                  </IconButton>
                </label>

                <label htmlFor={"attachment-video"}>
                  <Input
                    id={"attachment-video"}
                    type={"file"}
                    accept={"video/*"}
                    // accept={".mov,.mp4"}
                    onClick={(e) => (e.target.value = null)}
                    onChange={async (e) => {
                      const list = [];
                      setLoading(true);
                      for (let file of e.target.files) {
                        setFile(file);
                        const url = await UploadFile(
                          file,
                          `timeline/${profile?.uid}/${file?.name}`
                        );
                        setURL(url);
                        setLoading(false);
                        console.log(file);

                        list.push(`timeline/${profile?.uid}/${file?.name}`);
                      }

                      setVideo(list[0]);
                    }}
                  />
                  <IconButton component={"span"}>
                    <img src={"/assets/Vector (4).png"} />
                  </IconButton>
                </label>

                <label htmlFor={"attachment-files"}>
                  <Input
                    id={"attachment-files"}
                    accept={".pdf, .doc, .docx, .csv"}
                    type={"file"}
                    onClick={(e) => (e.target.value = null)}
                    onChange={async (e) => {
                      const list = [];
                      setLoading(true);
                      for (let file of e.target.files) {
                        setFile(file);
                        const url = await UploadFile(
                          file,
                          `timeline/${profile?.uid}/${file?.name}`
                        );
                        setURL(url);
                        setLoading(false);
                        console.log(file);

                        list.push(`timeline/${profile?.uid}/${file?.name}`);
                      }

                      setOtherFiles(list[0]);
                    }}
                  />
                  <IconButton component={"span"}>
                    <AttachFileIcon />
                  </IconButton>
                </label>
              </>
            )}
            {/* <IconButton>
							<img src={"/assets/Group 1193.png"} />
						</IconButton> */}
            <Tooltip
              title={<h3 style={{ color: "#E4EAF5" }}>Coming soon</h3>}
              placement="bottom"
            >
              <IconButton onClick={() => {}}>
                <SentimentSatisfiedAltIcon />
              </IconButton>
            </Tooltip>

            {/* {state?	<Picker onEmojiClick={(emojiData, event)=> setChosenEmoji(emojiData) && setState(false)} disableAutoFocus={true} native />:null}
						{chosenEmoji? <p>Symbol: {chosenEmoji.emoji}</p>:null} */}
            <Box sx={{ flex: 1 }} />
            <Button
              variant={"contained"}
              onClick={async () => {
                if (!postData) {
                  const postObj = {
                    current: { likes: 0, comments: 0 },
                    previous: { likes: 0, comments: 0 },
                    text: text,
                    uid: profile?.uid,
                    creatorData: profile,
                    createdAt: serverTimestamp(),
                    type:
                      image !== ""
                        ? "image"
                        : video !== ""
                        ? "video"
                        : otherFiles !== ""
                        ? "file"
                        : "",
                    metadata: {
                      name:
                        image !== "" || video !== "" || otherFiles !== ""
                          ? file?.name
                          : "",
                      type:
                        image !== "" || video !== "" || otherFiles !== ""
                          ? file?.type
                          : "",
                    },
                    image,
                    video,
                    otherFiles,
                    tags: tags,
                    // chosenEmoji:chosenEmoji.emoji
                  };
                  if (selected && selected.length > 0) {
                    const mentioned = selected.map((s) => {
                      const { displayName, uid, photoURL, email, userType } = s;
                      return { displayName, uid, photoURL, email, userType };
                    });
                    postObj["mentioned"] = mentioned;
                  }
                  const resultObj = await addDoc(
                    collection(DB, "timelines"),
                    postObj
                  );
                  setUpdated(false);
                  if (postObj.mentioned && postObj.mentioned.length > 0) {
                    for (let mention of postObj?.mentioned) {
                      NotificationEmail({
                        type: "mentioned",
                        displayName: profile?.displayName,
                        photoURL: profile?.photoURL,
                        uid: profile?.uid,
                        userType: profile?.userType,
                        mentionedInfo: {
                          ...mention,
                          url: `${mention?.userType}/post-details/${resultObj?.id}`,
                        },
                        postInfo: {
                          ...postObj,
                        },
                      }).then();

                      PostNotification({
                        type: "mentioned-social-post",
                        imageURL: profile?.photoURL,
                        message: "",
                        displayName: profile?.displayName,
                        uid: mention?.uid,
                        postId: resultObj?.id,
                        ...postObj?.current,
                      }).then();
                    }
                  }
                } else {
                  let postObj = {
                    current: { likes: 0, comments: 0 },
                    previous: { likes: 0, comments: 0 },
                    text: text,
                    updatedAt: serverTimestamp(),
                    type:
                      image !== ""
                        ? "image"
                        : video !== ""
                        ? "video"
                        : otherFiles !== ""
                        ? "file"
                        : "",
                    metadata: {
                      name:
                        image !== "" || video !== "" || otherFiles !== ""
                          ? file?.name
                          : "",
                      type:
                        image !== "" || video !== "" || otherFiles !== ""
                          ? file?.type
                          : "",
                    },
                    image: image,
                    video: video,
                    otherFiles: otherFiles,
                    tags: tags,
                    // chosenEmoji:chosenEmoji.emoji
                  };
                  if (postData.reposterData) {
                    postObj = {
                      repostText: text,
                      updatedAt: serverTimestamp(),
                    };
                  }

                  if (selected && selected.length > 0) {
                    debugger;
                    if (
                      postData?.mentioned &&
                      postData?.mentioned?.length > 0
                    ) {
                      const mentioned = selected
                        .filter(
                          (se) =>
                            !postData.mentioned.some((x) => x.uid == se.uid)
                        )
                        .map((s) => {
                          const {
                            displayName,
                            uid,
                            photoURL,
                            email,
                            userType,
                          } = s;
                          return {
                            displayName,
                            uid,
                            photoURL,
                            email,
                            userType,
                          };
                        });
                      debugger;
                      postObj["mentioned"] = [
                        ...postData.mentioned,
                        ...mentioned,
                      ];
                    } else {
                      debugger;
                      const mentioned = selected.map((s) => {
                        const { displayName, uid, photoURL, email, userType } =
                          s;
                        return { displayName, uid, photoURL, email, userType };
                      });
                      postObj["mentioned"] = mentioned;
                    }
                  }

                  await updateDoc(
                    doc(collection(DB, "timelines"), postData.id),
                    postObj,
                    { merge: true }
                  );

                  setUpdated(true);
                  if (postObj.mentioned && postObj.mentioned.length > 0) {
                    for (let mention of postObj?.mentioned) {
                      NotificationEmail({
                        type: "mentioned-social-post",
                        displayName: profile?.displayName,
                        photoURL: profile?.photoURL,
                        uid: profile?.uid,
                        userType: profile?.userType,
                        mentionedInfo: {
                          ...mention,
                          url: `${mention?.userType}/post-details/${postData?.id}`,
                        },
                        postInfo: {
                          ...postObj,
                        },
                      }).then();

                      PostNotification({
                        type: "mentioned-social-post",
                        imageURL: profile?.photoURL,
                        message: "",
                        displayName: profile?.displayName,
                        uid: mention?.uid,
                        postId: postData?.id,
                        ...postData?.current,
                      }).then();
                    }
                  }
                }

                setImage("");
                setVideo("");
                setText("");
                setTags([]);
                setFile();
                setPosted(true);
                setOpen(false);
                resetAll();
              }}
              disabled={
                text === "" && image == "" && video === "" && otherFiles === ""
              }
            >
              {postData ? "UPDATE" : "POST"}
            </Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </React.Fragment>
  );
}
function AutoCompleteList({ openMentionBox, cb, searchUser }) {
  const [users, setUsers] = React.useState([]);
  React.useEffect(() => {
    if (searchUser !== "") {
      const q = query(
        collection(DB, "users"),
        where(
          "searchName",
          ">=",
          searchUser.toLowerCase() /* capitalizeWord(search)       TODO: capitalize word space issue */
        ),
        where("onboard", "==", true),
        limit(15)
      );
      getDocs(q).then((snapshots) => {
        const list = [];
        if (snapshots?.size > 0) {
          snapshots.forEach((d) => list.push({ ...d.data() }));
          setUsers([...list]);
        } else setUsers([]);
      });
    } else setUsers([]);
  }, [searchUser]);

  React.useEffect(() => {
    if (!openMentionBox) setUsers([]);
  }, [openMentionBox]);

  if (openMentionBox)
    return (
      <Box px={3}>
        {users.length > 0 && (
          <List
            dense
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              height: "500px",
              overflowY: "scroll",
            }}
            component={Paper}
          >
            {users.map((value) => {
              const labelId = `checkbox-list-secondary-label-${value?.uid}`;
              return (
                <ListItem key={value?.uid} disablePadding>
                  <ListItemButton onClick={() => cb(value)}>
                    <ListItemAvatar>
                      <Avatar
                        alt={`Avatar n°${value?.displayName}`}
                        src={value?.photoURL}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      primary={`${value?.displayName}`}
                      secondary={USER_TYPES_TO_NAME[
                        value?.userType
                      ].toUpperCase()}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    );
}
