import React from "react";
import {
  Button,
  Container,
  Stack,
  Typography,
  Grid,
  Paper,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { ACCOUNT_CREATION_NOTE, BUSINESS_DEVELOPER, EXPERT } from "../../../utils/enums/labels.enum";
import SaveAsDraft from "../../../api/save-as-draft";
import {AuthUserContext} from "../../../providers/auth-provider";
import {getDoc, doc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";

const types = [
  {
    title: "Startups and Founders",
    id: "startup",
    text: "",
    tooltiptext:
      "A startup is a company that's in the initial stage of business.",
  },
  {
    title: BUSINESS_DEVELOPER.NAME,
    id: "business-developer",
    text: "",
    tooltiptext: `Business Developer, Sales, Strategic Partnerships, Branding, etc.`
  },
  
  {
    title: EXPERT.NAME,
    id: "expert",
    text: "",
    tooltiptext:
      `Scientists, Researchers, etc.`,
  },
  {
    title: "Investors",
    id: "investor",
    // text: "Angel, Angel Fund, Venture Capital Fund, CVC, PE Fund, Family Office",
    tooltiptext: " Angel Investor, Venture Capital Fund, PE Fund, CVC, Family Office, etc.",
  },
  {
    title: "Service Providers",
    id: "service-provider",
    text: "",
    tooltiptext:
      "Lawyers, Designers, Accountants, etc.",
  },
  
];
export default function SelectUserType() {
  const {state} =useLocation()
  const {uid} = useParams()
  const { user } = React.useContext(AuthUserContext);
  
  const [selected, setSelected] = React.useState(state && state?.type ? state?.type : null);
  const navigator = useNavigate();
  
  const handleNext = async () => {
    navigator(uid ? `/admin/on-boarding/contact-info/${uid}` :'/admin/on-boarding/contact-info', {
      state: { ...state, type: selected },
    })
  };
  React.useEffect(() => {
    if (uid) {
      getDoc(doc(DB, 'users', uid))
        .then((document) => {
          setSelected(document?.data()?.type)
      })
    }
  }, [uid])
 
  return (
    <>
      <Container maxWidth={"lg"} sx={{ mt: 10, pb: 5 }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={5}>
          <Stack direction="row">
            <Typography
              variant={"h4"}
              sx={{ textAlign: "center", width: "100%" }}>
              {'Select User Type'}
            </Typography>
            <br></br>
            <br></br>
            <br></br>
          </Stack>
          <Stack direction="row" style={{ marginLeft: "10%" }}>
            <Grid container spacing={3} alignItems="center">
              {types.map((tier, i) =>
                // Enterprise card is full width at sm breakpoint
                tier.id !== "distribution" && tier.id !==  "talent" ? (
                  <Grid
                    item
                    key={tier.title}
                    xs={12}
                    sm={
                      tier.title === "Enterprise" ? 12 : 6
                    }
                    md={3.5}>
                    <Paper
                      elevation={4}
                      style={{
                        width: "250px",
                        height: "167px",
                        border: `${
                          tier.id !== selected
                            ? "#ffffff"
                            : "1px solid #233DFF"
                        }`,
                      }}>
                      <Box
                        onClick={() =>
                          setSelected(tier.id)
                        }
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          mb: 2,
                          
                          height: 167,
                          backgroundColor: `${
                            tier.id !== selected
                              ? "#ffffff"
                              : "#fffff"
                          }`,
                          borderRadius: "10px",
                        }}>
                        <div
                          style={{
                            width: "90%",
                            textAlign: "right",
                            color: `${
                              tier.id !== selected
                                ? "#A9ABAE"
                                : "#ffffff"
                            }`,
                            position: "relative",
                            bottom: "8%",
                          }}>
                          {tier.id !== selected ? (
                            <RadioButtonUncheckedIcon
                              style={{
                                fontSize: 20,
                                marginRight:
                                  "5px",
                                color:
                                  tier.id !==
                                  selected
                                    ? "#A9ABAE"
                                    : "#233DFF;",
                              }}
                            />
                          ) : (
                            <CheckCircleIcon
                              style={{
                                fontSize: 20,
                                marginRight:
                                  "5px",
                                color:
                                  tier.id !==
                                  selected
                                    ? "#A9ABAE"
                                    : "#233DFF",
                              }}
                            />
                          )}
                        </div>
                        
                        {/*<img src={tier.id !== selected ? `/assets/${tier.title}-grey.png`: `/assets/${tier.title}-grey.png`} height={70} style={{marginBottom: '20px'}} alt=""/>*/}
                        <Typography
                          variant="h6"
                          component="p"
                          color={
                            tier.id !== selected
                              ? "secondary"
                              : "#233DFF"
                          }>
                          {tier.title}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "10px",
                            marginTop: "-4px",
                            marginLeft:"30px",
                            marginRight:"30px"
                          }}
                          color={
                            tier.id !== selected
                              ? "secondary"
                              : "#233DFF"
                          }>
                          {tier.text}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            marginTop: "10px",
                            fontWeight: "bold",
                          }}>
                          {tier.textcomingsoon}
                        </Typography>
                        
                        <div
                          style={{
                            width: "90%",
                            textAlign: "right",
                            color: `${
                              tier.id !== selected
                                ? "#A9ABAE"
                                : "#ffffff"
                            }`,
                            position: "relative",
                            top: "12%",
                          }}>
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: "white",
                                  maxWidth:
                                    "250px",
                                  paddingTop: 2,
                                  height: 75,
                                  fontSize: 10,
                                  textAlign:
                                    "start",
                                  marginRight:
                                    "20%",
                                  
                                  bgcolor:
                                    "#233DFF",
                                  "& .MuiTooltip-arrow":
                                    {
                                      color: "#E3E8F0",
                                    },
                                },
                              },
                            }}
                            title={tier.tooltiptext}
                            arrow
                            sx={{
                              width: "40px",
                              marginLeft: "100px",
                            }}>
                            <IconButton>
                              <HelpOutlineIcon
                                style={{
                                  fontSize: 20,
                                  marginLeft:
                                    "10px",
                                  color:
                                    tier.id !==
                                    selected
                                      ? "#A9ABAE"
                                      : "#233DFF",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Box>
                    </Paper>
                  </Grid>
                ) : (
                  <Grid
                    item
                    key={tier.title}
                    xs={12}
                    sm={
                      tier.title === "Enterprise" ? 12 : 6
                    }
                    md={3.5}>
                    <Paper
                      elevation={4}
                      style={{
                        width: "250px",
                        height: "167px",
                        border: `${
                          tier.id !== selected
                            ? "#ffffff"
                            : "1px solid #233DFF"
                        }`,
                        opacity: 0.6,
                      }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          mb: 2,
                          
                          height: 167,
                          backgroundColor: `${
                            tier.id !== selected
                              ? "#ffffff"
                              : "#fffff"
                          }`,
                          borderRadius: "10px",
                        }}>
                        <div
                          style={{
                            width: "90%",
                            textAlign: "right",
                            color: `${
                              tier.id !== selected
                                ? "#A9ABAE"
                                : "#ffffff"
                            }`,
                            position: "relative",
                            bottom: "8%",
                          }}>
                          {tier.id !== selected ? (
                            <RadioButtonUncheckedIcon
                              style={{
                                fontSize: 20,
                                marginRight:
                                  "5px",
                                color:
                                  tier.id !==
                                  selected
                                    ? "#A9ABAE"
                                    : "#233DFF;",
                              }}
                            />
                          ) : (
                            <CheckCircleIcon
                              style={{
                                fontSize: 20,
                                marginRight:
                                  "5px",
                                color:
                                  tier.id !==
                                  selected
                                    ? "#A9ABAE"
                                    : "#233DFF",
                              }}
                            />
                          )}
                        </div>
                        
                        {/*<img src={tier.id !== selected ? `/assets/${tier.title}-grey.png`: `/assets/${tier.title}-grey.png`} height={70} style={{marginBottom: '20px'}} alt=""/>*/}
                        <Typography
                          variant="h6"
                          component="p"
                          // color={"#aeaeae"}
                          color={"#000000"}
                        >
                          {tier.title}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            marginTop: "10px",
                            fontWeight: "bold",
                            // color: "#aeaeae",
                          }}>
                          {tier.textcomingsoon}
                        </Typography>
                        
                        <div
                          style={{
                            width: "90%",
                            textAlign: "right",
                            color: `${
                              tier.id !== selected
                                ? "#A9ABAE"
                                : "#ffffff"
                            }`,
                            position: "relative",
                            top: "12%",
                          }}>
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: "white",
                                  maxWidth:
                                    "250px",
                                  paddingTop: 2,
                                  height: 75,
                                  fontSize: 10,
                                  textAlign:
                                    "start",
                                  marginRight:
                                    "20%",
                                  
                                  bgcolor:
                                    "#233DFF",
                                  "& .MuiTooltip-arrow":
                                    {
                                      color: "#E3E8F0",
                                    },
                                },
                              },
                            }}
                            title={tier.tooltiptext}
                            arrow
                            sx={{
                              width: "40px",
                              marginLeft: "100px",
                            }}>
                            <IconButton>
                              <HelpOutlineIcon
                                style={{
                                  fontSize: 20,
                                  marginLeft:
                                    "10px",
                                  color:
                                    tier.id !==
                                    selected
                                      ? "#A9ABAE"
                                      : "#233DFF",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Box>
                    </Paper>
                  </Grid>
                )
              )}
            </Grid>
          </Stack>
          <Stack direction={'row'} justifyContent="center"
                 alignItems="center"
                 spacing={3} width={'100%'} >
            <Button variant={'outlined'} color={'primary'} sx={{px: 5}} onClick={() => navigator('/admin/dashboard')}>
              Back
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{ margin: "auto", px: 5 }}
              onClick={() => handleNext()}
              disabled={!selected}>
              Next
            </Button>
            {/* <Button variant={'outlined'} color={'primary'} sx={{px: 5}} onClick={() => {
              if (selected) {
                SaveAsDraft({type: selected}, uid, user)
                  .then(() => {
                    navigator('/admin/draft-profiles')
                  })
              } else {
                navigator('/admin/dashboard')
              }
            }}>
              Save to draft
            </Button> */}
          </Stack>
         
        </Stack>
      </Container>
    </>
  );
}
