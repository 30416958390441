import React from "react";
import {collection, getDocs, onSnapshot, orderBy, query, where} from "firebase/firestore";
import  {DB} from "../../../../config/firebase/firebase";

import {Box, Container, Grid, Stack, Typography} from "@mui/material";
import InvestorListItem from "../../components/common/investor-list-item";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import {AuthUserContext} from "../../../../providers/auth-provider";
import {useNavigate} from "react-router-dom";


export const InvestorList = () => {
  const [investors, setInvestor] = React.useState([])
  const [search, setSearch] =React.useState('')
  const {profile} = React.useContext(AuthUserContext);
	const[inputbtn,setinputbtn]=React.useState();

  React.useEffect(() => {
    debugger
    const q = query(
      collection(DB, "users"),
      orderBy("createdAt", "desc"),
      where('userType', '==', 'investor'),
      where('onboard', '==', true));

    const unSubs = onSnapshot(q, async (querySnap) => {
      // debugger
      if (querySnap.size > 0) {

        const q = query(
          collection(DB, "users"),
          orderBy("createdAt", "desc"),
          where('userType', '==', 'startup'),
          where('onboard', '==', true),
          where('areYouAStartupInvestor', '==', true));

        const datadocs = await getDocs(q)
        let snap = querySnap.docs;

        if (datadocs?.size > 0) {
          snap = snap.concat(datadocs.docs)
        }

        const list = []
        snap.forEach((doc) => {
         /* doc?.data().userType !== 'investor' && console.log(doc?.id, 'startup-founder')*/
          list.push({id: doc.id, ...doc.data()})
        });

        setInvestor(list)
      }
    });
    return () => unSubs;
  }, [])
  return(
    <>
      <Container maxWidth={'xl'}  sx={{my: 5, textAlign: 'left'}} >
        <Stack direction={'row'} spacing={5} sx={{width: '100%', mb: 5}}>
          <div style={{flex: 1}}>
            <Typography variant={'h4'} sx={{fontWeight: 700}}>Hello {profile?.displayName} &nbsp; 👋</Typography>
            <Typography variant={"body1"} color={'secondary'}>Welcome to  ASTRANOVA</Typography>
          </div>
          <Box  onSubmit={(event) => event.preventDefault()}
            component="form"
            sx={{ p: '2px 10px', display: 'flex', alignItems: 'center', width: 337, height: 50, borderRadius: '12px',backgroundColor: '#E4EAF5' }}
          >
            <IconButton type="button" sx={{ p: '10px', }}
            onClick={(e) => setSearch(inputbtn)}
             aria-label="search">
              <SearchIcon sx={{ color:'#051E40BF'}}/>
            </IconButton>
            <InputBase
                onChange={(e) => setinputbtn(e.target.value)}
            value={inputbtn}
							onKeyDown={(e)=>{
								if(e.code==="Enter"){
									setSearch(inputbtn)
								}
							}}
              sx={{ ml: 1, flex: 1 ,color:'#051E40BF',  }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search post' }}
            />
          </Box>
        </Stack></Container>
      <Container maxWidth={'lg'}  sx={{my: 5, textAlign: 'left'}} >

        <Grid container spacing={5}>
          {investors.length > 0 &&
            investors.length > 0 && investors.filter((post) => {
              if (search !== '') {
                const reg = new RegExp(search);
                return reg.test(post.displayName) &&  post
              } else {
                return post
              }

            }).sort((a, b) => b.createdAt - a.createdAt).map( (investor) =>  (  <InvestorListItem data={investor} key={investor?.id} href={`/${profile?.userType}/investor-details/${investor?.id}`}/>

            ))

          }
        </Grid>
      </Container>

    </>
  )

}