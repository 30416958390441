import React from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  Button,
  Container,
  Stack,
  Typography,
  IconButton,
  TextField,
  Slider,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Switch,
  Avatar,
  styled,
  CircularProgress,
  Link,
  Badge,
  Autocomplete,
  Chip,
  MenuItem,
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import { useFormik, FieldArray, getIn, FormikProvider } from "formik";
import InvestorProfileValidation from "../../../validations/investor/investor-profile";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFile from "../../../utils/upload-file";
import { async } from "@firebase/util";
import GetCitiesData from "../../../api/get-cities-data";
import InvestorFirstForm from "../investor/onboarding-forms/investor-first-form";
import InvestorSecondForm from "../investor/onboarding-forms/investor-second-form";
import InvestorThirdForm from "../investor/onboarding-forms/investor-third-form";
import InvestorFourForm from "../investor/onboarding-forms/investor-four-form";
import PortfoliosListView from "../investor/onboarding-forms/portfolios-list-view";
import InvestorPortfolioForm from "../investor/onboarding-forms/portfolio-form";
import InvestorOrganizationForm from "../../investor/onboarding-forms/investor-organization-form";
function valuetext(value) {
  return `$${value}`;
}
export default function InvestorProfile() {
  const Input = styled("input")({
    display: "none",
  });
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user, setProfile } = React.useContext(AuthUserContext);
  const [clicked, setClicked] = React.useState(false);
  const [formFirstData, setFormFirstData] = React.useState();
  const [formSecondData, setFormSecondData] = React.useState();
  const [formThirdData, setFormThirdData] = React.useState();
  const [formFourData, setFormFourData] = React.useState();
  const [formFiveData, setFormFiveData] = React.useState();

  const [loading, setLoading] = React.useState(false);
  const [isPortfolio, setPortfolio] = React.useState(false);
  const [steps, setSteps] = React.useState(0);
  const [portfolioIndex, setPortfolioIndex] = React.useState();
  const [markets, setMarkets] = React.useState([]);
  const [focusVertical, setFocusVertical] = React.useState([]);
  const [portfolios, setPortfolios] = React.useState([]);

  const [profileImage, setProfileImage] = React.useState({
    src: user.photoURL ? user.photoURL : null,
    photoURL: user.photoURL,
  });

  function intToString(num) {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: "K" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    );
  }

  const navigator = useNavigate();

  const submitData = async (values) => {
    try {
      debugger;
      const userRef = doc(DB, "users", user.uid);

      const copyPortfolio = [];
      if (portfolios.length > 0) {
        for (let portfolio of portfolios) {
          const attachments = [];
          if (portfolio.attachments.length > 0) {
            for (let file of portfolio.attachments) {
              const path = `investors/${user.uid}/attachments/${file.file.name}`;
              attachments.push(path);
            }
          }
          copyPortfolio.push({ ...portfolio, attachments });
        }
      }
      const investor = {
        ...values,
        portfolio: copyPortfolio,
        photoURL: values?.photoURL ? values.photoURL : "",
      };
      await setDoc(
        userRef,
        {
          photoURL: values?.photoURL ? values.photoURL : "",
          investor,
          onboard: true,
        },
        { merge: true }
      );
      if (formFirstData?.investorType !== "Angel Investor") {
        const add = await addDoc(collection(DB, "organisations"), {
          ...formFirstData,
          uid: user?.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        await addDoc(collection(DB, "organisationUsers"), {
          organisationId: add.id,
          organisation: { ...formFirstData },
          uid: user?.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          role: formSecondData?.role,
        });
      }
      const getdoc = await getDoc(userRef);
      await setProfile(getdoc.data());
      navigator("/m/investor/timeline");
    } catch (e) {
      debugger;
      console.log(e);
    }
  };

  const cities = ["London, United Kingdom"];
  const [filter, setFilter] = React.useState([...cities]);
  const [searchLoaction, setSearchLoaction] = React.useState("");
  React.useEffect(() => {
    if (searchLoaction !== "") {
      GetCitiesData().then((res) => {
        const te = [...new Set(res?.data?.cities)];
        setFilter([
          ...te.filter((post) => {
            if (searchLoaction !== "") {
              const reg = new RegExp(searchLoaction);
              return reg.test(post) && post;
            }
          }),
        ]);
        /*debugger
				setcities(te)*/
      });
    } else {
      setFilter([...cities]);
    }
  }, [searchLoaction]);

  return (
    <>
      <Container maxWidth={"xl"} sx={{ marginY: 5 }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}
        >
          <IconButton
            onClick={() =>
              isPortfolio
                ? setPortfolio(false)
                : steps === 0
                  ? navigator(-1) : steps === .5 ? setSteps(steps - .5)
                    : steps === 1 && formFirstData && formFirstData?.investorType !== 'Angel Investor' ? setSteps(steps - .5)
                      : setSteps(steps - 1)
            }
          >
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          {/* <Typography
						variant={"h6"}
						sx={{ textAlign: "left", lineHeight: 1, fontWeight:"600", fontSize: 18
						 }}>
						{isPortfolio ? (
							<>Investment Portfolio</>
						) : (
							<>Investment Portfolio</>
						)}
						<br />{" "}
						<small style={{ fontSize: 14, color: "#8F8F8F" }}>
							{ steps === 2 || steps === 3  ? 'Investment Thesis' : steps === 4 ? 'Investment Portfolio':
								steps === 5 ? 'Add portfolio':

									'Investor Profile'}
						</small>
					</Typography> */}

          <Typography
            variant={"h6"}
            sx={{ textAlign: "left", lineHeight: 1, fontWeight: "600" }}
          >
            {
              steps === 4
                ? "Investor"
                : steps === 5
                ? "Investment Portfolio"
                : "Investor"
              // isPortfolio ? (
              // 	<>Investment Portfolio</>
              // ) : (
              // 	<>Investor </>
              // )
            }
            <br />{" "}
            <small style={{ fontSize: 14, color: "#8F8F8F" }}>
              {steps === 2 || steps === 3
                ? "Investment Thesis"
                : steps === 4
                ? "Investment Portfolio"
                : steps === 5
                ? "Add portfolio"
                : "Investor Profile"}
            </small>
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={"lg"} sx={{ mb: 5 }}>
        {steps === 0 && (
          <InvestorFirstForm
            data={formFirstData}
            cbBack={() => navigator(-1)}
            cbNext={(data) => {
              setFormFirstData({ ...data });
              setSteps(data?.investorType === 'Angel Investor' ? steps + 1 : steps + .5);

            }}
          />)

        }
        {steps === .5 && (
          <InvestorOrganizationForm
            data={formFirstData}
            cbBack={() => setSteps(steps - .5)}
            cbNext={(data) => {
              setFormFirstData({ ...formFirstData, ...data });
              setSteps( steps + .5);

            }}
          />) }
        {steps === 1 && (
          <InvestorSecondForm
            isVC={
              formFirstData &&
              formFirstData?.investorType !== "Angel Investor"
            }
            data={formSecondData}
            cbBack={() => setSteps(formFirstData?.investorType !== 'Angel Investor' ? steps - .5 : steps -1)}
            cbNext={(data) => {
              debugger;
              setFormSecondData({ ...data });
              setSteps(steps + 1);
            }}
          />
        )}
        {steps === 2 && (
          <InvestorThirdForm
            data={formThirdData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormThirdData({ ...data });
              setSteps(steps + 1);
            }}
          />
        )}
        {steps === 3 && (
          <InvestorFourForm
            data={formFourData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              debugger;
              setFormFourData({ ...data });
              setSteps(steps + 1);
            }}
          />
        )}
        {steps === 4 && (
          <PortfoliosListView
            cbBack={() => setSteps(steps - 1)}
            add={(data) => {
              setPortfolioIndex();
              setSteps(steps + 1);
            }}
            edit={(ind) => {
              setPortfolioIndex(ind);
              setSteps(steps + 1);
            }}
            remove={(ind) => {
              debugger;
              const port = [...portfolios];
              port.splice(ind, 1);
              setPortfolios([...port]);
            }}
            portfolios={portfolios}
            submit={() => {
              debugger;
              submitData({
                ...formFirstData,
                ...formSecondData,
                ...formThirdData,
                ...formFourData,
              }).then();
              console.log("submit");
            }}
          />
        )}
        {steps === 5 && (
          <InvestorPortfolioForm
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data, index) => {
              const port = [...portfolios];
              if (index !== undefined && index > -1) {
                port.splice(index, 1, { ...data });
              } else {
                port.push({ ...data, logo: profileImage?.photoURL });
              }
              setPortfolios([...port]);
              setSteps(steps - 1);
              setPortfolioIndex(undefined);
            }}
            data={
              portfolioIndex !== undefined && portfolioIndex > -1
                ? portfolios[portfolioIndex]
                : undefined
            }
            index={portfolioIndex}
          />
        )}

        {/*<FormikProvider value={formik}>
					<Stack
						direction={"column"}
						spacing={5}
						component="form"
						onSubmit={(e) => formik.handleSubmit(e)}>
						{!isPortfolio && (
							<>
								{steps === 0 && (
									<>
										<Container
											maxWidth={"md"}
											sx={{ mb: 5 }}>
											<Stack
												direction={"column"}
												spacing={5}>
												<Stack
													direction="column"
													justifyContent="center"
													alignItems="center"
													spacing={1}
													sx={{ mb: 5 }}>
													<label htmlFor="profile-image">
														<Badge
															overlap="circular"
															anchorOrigin={{
																vertical:
																	"bottom",
																horizontal:
																	"right",
															}}
															badgeContent={
																<IconButton
																	color={
																		"primary"
																	}
																	component={
																		"span"
																	}>
																	<AddCircleIcon />
																</IconButton>
															}>
															{matches ? (
																<Avatar
																	alt={
																		"startup-picture"
																	}
																	src={
																		profileImage &&
																		profileImage?.src
																			? profileImage?.src
																			: "/assets/default-avatar.png"
																	}
																	sx={{
																		width: 128,
																		height: 128,
																		cursor: "pointer",
																		backgroundColor:
																			"transparent",
																		border: "1px solid #E0E3FF",
																	}}
																	component={
																		"span"
																	}
																/>
															) : (
																<Avatar
																	alt={
																		"startup-picture"
																	}
																	src={
																		profileImage &&
																		profileImage?.src
																			? profileImage?.src
																			: "/assets/default-avatar.png"
																	}
																	sx={{
																		width: 100,
																		height: 100,
																		cursor: "pointer",
																		backgroundColor:
																			"transparent",
																		border: "1px solid #E0E3FF",
																	}}
																	component={
																		"span"
																	}
																/>
															)}
														</Badge>
														<Input
															accept="image/*"
															id="profile-image"
															multiple={false}
															type="file"
															onChange={(e) => {
																const fileReader =
																	new FileReader();
																fileReader.readAsDataURL(
																	e.target
																		.files[0]
																);
																fileReader.addEventListener(
																	"load",
																	async function () {
																		setLoading(
																			true
																		);
																		const file =
																			e
																				.target
																				.files[0];
																		const path = `profile-image/${user.uid}/${file.name}`;
																		const imageObject =
																			{
																				progress: 0,
																				src: this
																					.result,
																				path,
																				file: e
																					.target
																					.files[0],
																				size: (
																					e
																						.target
																						.files[0]
																						?.size /
																					1024 /
																					1024
																				).toFixed(
																					2
																				),
																			};
																		setProfileImage(
																			imageObject
																		);
																		UploadFile(
																			file,
																			path
																		).then(
																			(
																				url
																			) => {
																				setProfileImage(
																					{
																						...imageObject,
																						photoURL:
																							url,
																					}
																				);
																				setLoading(
																					false
																				);
																			}
																		);
																	}
																);
															}}
														/>
													</label>
													{loading && (
														<CircularProgress
															sx={{ ml: 2 }}
															size={20}
															color="primary"
														/>
													)}
													{profileImage &&
														profileImage?.src && (
															<Link
																color={
																	"secondary"
																}
																onClick={() =>
																	setProfileImage(
																		undefined
																	)
																}>
																Remove picture
															</Link>
														)}
												</Stack>

												<TextField
													required
													multiline={true}
													rows={3}
													name={"description"}
													value={
														formik.values
															.description
													}
													onChange={
														formik.handleChange
													}
													error={
														formik.errors
															.description
													}
													helperText={
														formik.values
															.description
															.length === 0
															? "Description is Required"
															: ""
													}
													label={
														"Tell us about yourself"
													}
												/>
												 <Typography align="right" sx={{color:"#818488"}}>{150-formik.values.description.length}/150</Typography>
												<TextField
													required
													name={"investorType"}
													value={
														formik.values
															.investorType
													}
													onChange={
														formik.handleChange
													}
													error={
														formik.errors
															.investorType
													}
													helperText={
														formik.values
															.investorType
															.length === 0
															? "Type is Required"
															: ""
													}
													select
													label={"Investor Type"}
													sx={{ textAlign: "left" }}>
													<MenuItem value={"Angel"}>
														Angel
													</MenuItem>
													<MenuItem
														value={"VC Funds"}>
														VC Funds
													</MenuItem>
													<MenuItem
														value={
															"Incubator/Accelerator"
														}>
														Incubator/Accelerator
													</MenuItem>
													<MenuItem
														value={
															"Private Equity Fund"
														}>
														Private Equity Fund
													</MenuItem>
													<MenuItem
														value={"Family Office"}>
														Family Office
													</MenuItem>
													<MenuItem
														value={"Hedge Funds"}>
														Hedge Funds
													</MenuItem>
												</TextField>
												<TextField
													name={"investorStage"}
													value={
														formik.values
															.investorStage
													}
													onChange={
														formik.handleChange
													}
													error={
														formik.touched
															.investorStage &&
														Boolean(
															formik.errors
																.investorStage
														)
													}
													helperText={
														formik.touched
															.investorStage &&
														formik.errors
															.investorStage
													}
													select
													label={"Investor Stage"}
													sx={{ textAlign: "left" }}>
													<MenuItem
														value={"Pre-Seed"}>
														Pre-Seed
													</MenuItem>
													<MenuItem value={"Seed"}>
														Seed
													</MenuItem>
													<MenuItem
														value={"Series A"}>
														Series A
													</MenuItem>
													<MenuItem
														value={"Series B"}>
														Series B
													</MenuItem>
													<MenuItem
														value={"Series C+"}>
														Series C+
													</MenuItem>
												</TextField>
											</Stack>
										</Container>

										<Stack
											direction={"row"}
											justifyContent="center"
											alignItems="center"
											spacing={3}
											width={"100%"}>
											<Button
												variant={"outlined"}
												color={"primary"}
												sx={{ px: 5 }}
												onClick={() => navigator(-1)}>
												Back
											</Button>
											<Button
												variant={"contained"}
												color={"primary"}
												sx={{ px: 5 }}
												onClick={() => {
													if (
														steps === 0 &&
														(formik.values
															.description ===
															"" ||
															formik.values
																.investorType
																.length === 0)
													) {
														return;
													}
													setSteps(steps + 1);
												}}>
												Next
											</Button>
										</Stack>
									</>
								)}

								{steps === 1 && (
									<>
										<Container
											maxWidth={"md"}
											sx={{ mb: 5 }}>
											<Stack
												direction={"column"}
												spacing={5}>
												<FormControl
													component="fieldset"
													variant="standard">
													<FormLabel
														component="legend"
														sx={{
															textAlign: "left",
															mb: 2,
														}}>
														Lead Rounds
													</FormLabel>
													<Stack
														direction="row"
														spacing={1}
														alignItems="center">
														<Typography>
															No
														</Typography>
														<FormControlLabel
															control={
																<Switch
																	name={
																		"leadRounds"
																	}
																	checked={
																		formik
																			.values
																			.leadRounds
																	}
																	onChange={
																		formik.handleChange
																	}
																	error={
																		formik
																			.touched
																			.leadRounds &&
																		Boolean(
																			formik
																				.errors
																				.leadRounds
																		)
																	}
																/>
															}
														/>
														<Typography>
															Yes
														</Typography>
													</Stack>
												</FormControl>

												<Stack
													direction={"column"}
													justifyContent="flex-start"
													spacing={5}
													sx={{ width: "100%" }}>
													<Autocomplete
														multiple
														required
														id="tags-filled"
														options={[
															...filter
														].map(
															(option) => option
														)}
														freeSolo
														onChange={(
															e,
															newValue
														) =>
															setMarkets(newValue)
														}
														renderTags={(
															value,
															getTagProps
														) =>
															markets.map(
																(
																	option,
																	index
																) => (
																	<Chip
																		variant="contained"
																		sx={{
																			backgroundColor:
																				"#E3E8F0",
																			marginLeft:
																				"5px",
																			marginRight:
																				"5px",
																			marginBottom:
																				"10px",
																		}}
																		deleteIcon={
																			<CloseIcon />
																		}
																		onDelete={() =>
																			setMarkets(
																				markets.filter(
																					(
																						f
																					) =>
																						f !==
																						option
																				)
																			)
																		}
																		label={
																			option
																		}
																		{...getTagProps(
																			{
																				index,
																			}
																		)}></Chip>
																)
															)
														}
														renderInput={(
															params
														) => (
															<TextField
																{...params}
																onChange={(e) => setSearchLoaction(e.target.value)}
																// variant={'standard'}
																label="Market Geographies"
																placeholder="Add"
																sx={{
																	transition:
																		"0.1s linear",
																}}
															/>
														)}
													/>
												</Stack>

												<Stack
													direction={"column"}
													justifyContent="flex-start"
													spacing={5}
													sx={{ width: "100%" }}>
													<Autocomplete
														multiple
														required
														id="tags-filled"
														options={[
															"Fintech",
															"Climate",
															"Edtech",
														].map(
															(option) => option
														)}
														freeSolo
														onChange={(
															e,
															newValue
														) =>
															setFocusVertical(
																newValue
															)
														}
														renderTags={(
															value,
															getTagProps
														) =>
															focusVertical.map(
																(
																	option,
																	index
																) => (
																	<Chip
																		variant="contained"
																		sx={{
																			backgroundColor:
																				"#E3E8F0",
																			marginLeft:
																				"5px",
																			marginRight:
																				"5px",
																			marginBottom:
																				"10px",
																		}}
																		deleteIcon={
																			<CloseIcon />
																		}
																		onDelete={() =>
																			setFocusVertical(
																				focusVertical.filter(
																					(
																						f
																					) =>
																						f !==
																						option
																				)
																			)
																		}
																		label={
																			option
																		}
																		{...getTagProps(
																			{
																				index,
																			}
																		)}></Chip>
																)
															)
														}
														renderInput={(
															params
														) => (
															<TextField
																{...params}
																// variant={'standard'}
																label="Focus Vertical"
																placeholder="Add"
																sx={{
																	transition:
																		"0.1s linear",
																}}
															/>
														)}
													/>
												</Stack>
												<Typography
													color={"secondary"}
													sx={{ textAlign: "left" }}>
													Ticket Size
												</Typography>
												<Stack
													direction={"row"}
													justifyContent="start"
													alignItems="center"
													spacing={matches ? 5 : 1}
													width={"100%"}>
													<TextField
														name={"ticketCurrency"}
														value={
															formik.values
																.ticketCurrency
														}
														onChange={
															formik.handleChange
														}
														error={
															formik.touched
																.ticketCurrency &&
															Boolean(
																formik.errors
																	.ticketCurrency
															)
														}
														helperText={
															formik.touched
																.ticketCurrency &&
															formik.errors
																.ticketCurrency
														}
														fullWidth
														label={"Currency"}
														select
														sx={{
															textAlign: "left",
														}}>
														<MenuItem value={"USD"}>
															USD
														</MenuItem>
														<MenuItem value={"GBP"}>
															GBP
														</MenuItem>
														<MenuItem value={"EUR"}>
															EUR
														</MenuItem>
													</TextField>
													<TextField
														name={
															"ticketStartingAt"
														}
														value={
															formik.values
																.ticketStartingAt
														}
														onChange={
															formik.handleChange
														}
														error={
															formik.touched
																.ticketStartingAt &&
															Boolean(
																formik.errors
																	.ticketStartingAt
															)
														}
														helperText={
															formik.touched
																.ticketStartingAt &&
															formik.errors
																.ticketStartingAt
														}
														fullWidth
														label={"Starting at"}
														type={"number"}
													/>
													<TextField
														name={"ticketUpto"}
														value={
															formik.values
																.ticketUpto
														}
														onChange={
															formik.handleChange
														}
														error={
															formik.touched
																.ticketUpto &&
															Boolean(
																formik.errors
																	.ticketUpto
															)
														}
														helperText={
															formik.touched
																.ticketUpto &&
															formik.errors
																.ticketUpto
														}
														fullWidth
														label={"Upto"}
														type={"number"}
													/>
												</Stack>
											</Stack>
										</Container>

										<Stack
											direction={"row"}
											justifyContent="center"
											alignItems="center"
											spacing={5}
											width={"100%"}>
											<Button
												variant={"outlined"}
												color={"primary"}
												sx={{ px: 5 }}
												onClick={() =>
													setSteps(steps - 1)
												}>
												Back
											</Button>
											<Button
												variant={"contained"}
												color={"primary"}
												sx={{ px: 5 }}
												onClick={() =>
													setSteps(steps + 1)
												}>
												Next
											</Button>
										</Stack>
									</>
								)}
							</>
						)}

						{steps === 2 && (
							<>
								<Container maxWidth={"md"} sx={{ mb: 5 }}>
									<Stack direction={"column"} spacing={5}>
										{!isPortfolio && (
											<>
												<FormControl
													component="fieldset"
													variant="standard">
													<FormLabel
														component="legend"
														sx={{
															textAlign: "left",
															mb: 2,
														}}>
														Other Criteria
													</FormLabel>
													<FormGroup>
														<Stack
															direction={"column"}
															spacing={1.5}>
															<Typography
																variant={
																	"body2"
																}
																sx={{
																	textAlign:
																		"left",
																}}>
																Post-revenue
															</Typography>
															<Stack
																direction="row"
																spacing={1}
																alignItems="center">
																<Typography>
																	No
																</Typography>
																<FormControlLabel
																	label={""}
																	control={
																		<Switch
																			name={
																				"postRevenue"
																			}
																			checked={
																				formik
																					.values
																					.postRevenue
																			}
																			onChange={
																				formik.handleChange
																			}
																			error={
																				formik
																					.touched
																					.postRevenue &&
																				Boolean(
																					formik
																						.errors
																						.postRevenue
																				)
																			}
																		/>
																	}
																/>
																<Typography>
																	Yes
																</Typography>
															</Stack>

															<Typography
																variant={
																	"body2"
																}
																sx={{
																	textAlign:
																		"left",
																}}>
																Pre-product
															</Typography>
															<Stack
																direction="row"
																spacing={1}
																alignItems="center">
																<Typography>
																	No
																</Typography>
																<FormControlLabel
																	label={""}
																	control={
																		<Switch
																			name={
																				"preProduct"
																			}
																			checked={
																				formik
																					.values
																					.preProduct
																			}
																			onChange={
																				formik.handleChange
																			}
																			error={
																				formik
																					.touched
																					.preProduct &&
																				Boolean(
																					formik
																						.errors
																						.preProduct
																				)
																			}
																		/>
																	}
																/>
																<Typography>
																	Yes
																</Typography>
															</Stack>

															<Typography
																variant={
																	"body2"
																}
																sx={{
																	textAlign:
																		"left",
																}}>
																Product LIVE
															</Typography>
															<Stack
																direction="row"
																spacing={1}
																alignItems="center">
																<Typography>
																	No
																</Typography>
																<FormControlLabel
																	label={""}
																	control={
																		<Switch
																			name={
																				"productLive"
																			}
																			checked={
																				formik
																					.values
																					.productLive
																			}
																			onChange={
																				formik.handleChange
																			}
																			error={
																				formik
																					.touched
																					.productLive &&
																				Boolean(
																					formik
																						.errors
																						.productLive
																				)
																			}
																		/>
																	}
																/>
																<Typography>
																	Yes
																</Typography>
															</Stack>

															<Typography
																variant={
																	"body2"
																}
																sx={{
																	textAlign:
																		"left",
																}}>
																PMF
															</Typography>
															<Stack
																direction="row"
																spacing={1}
																alignItems="center">
																<Typography>
																	No
																</Typography>
																<FormControlLabel
																	label={""}
																	control={
																		<Switch
																			name={
																				"PMF"
																			}
																			checked={
																				formik
																					.values
																					.PMF
																			}
																			onChange={
																				formik.handleChange
																			}
																			error={
																				formik
																					.touched
																					.PMF &&
																				Boolean(
																					formik
																						.errors
																						.PMF
																				)
																			}
																		/>
																	}
																/>
																<Typography>
																	Yes
																</Typography>
															</Stack>

															<Typography
																variant={
																	"body2"
																}
																sx={{
																	textAlign:
																		"left",
																}}>
																Sole Founder
															</Typography>
															<Stack
																direction="row"
																spacing={1}
																alignItems="center">
																<Typography>
																	No
																</Typography>
																<FormControlLabel
																	label={""}
																	control={
																		<Switch
																			name={
																				"soleFounder"
																			}
																			checked={
																				formik
																					.values
																					.soleFounder
																			}
																			onChange={
																				formik.handleChange
																			}
																			error={
																				formik
																					.touched
																					.soleFounder &&
																				Boolean(
																					formik
																						.errors
																						.soleFounder
																				)
																			}
																		/>
																	}
																/>
																<Typography>
																	Yes
																</Typography>
															</Stack>
														</Stack>
													</FormGroup>
												</FormControl>
												<hr></hr>
												{isPortfolio ? (
													<>
														<Typography
															style={{
																marginBottom:
																	"12px",
																textAlign:
																	"left",
															}}>
															Investment Portfolio
														</Typography>
													</>
												) : (
													<></>
												)}

												<Grid container gap={3}>
													{formik.values.portfolio
														.length > 0 &&
														formik.values.portfolio.map(
															(p, i) => (
																<>
																	<Grid
																		key={i}>
																		{p?.name && (
																			<Box
																				sx={{
																					width: "320px",
																					backgroundColor:
																						"#E4EAF5",
																					padding: 2,
																					borderRadius:
																						"5px",
																					border: "0.5px solid black",
																				}}
																				key={
																					i
																				}>
																				<Stack
																					direction="row"
																					justifyContent="space-between"
																					alignItems="center">
																					<Avatar
																						sx={{
																							width: 72,
																							height: 72,
																							border: "3px solid #E0E3FF",
																							position:
																								"relative",
																							top: -50,
																						}}
																						src={
																							p?.src
																								? p?.src
																								: "/assets/startup-placeholder-image.png"
																						}></Avatar>

																					<Stack
																						direction="row"
																						justifyContent="space-between"
																						alignItems="center"
																						spacing={
																							2
																						}>
																						<Button
																							sx={{
																								top: -25,
																							}}
																							style={{
																								color: "#8F8F8F",
																								boxShadow:
																									clicked
																										? "none"
																										: "",
																							}}
																							onClick={() => {
																								setPortfolio(
																									true
																								);
																								setClicked(
																									true
																								);
																							}}>
																							{" "}
																							Edit
																						</Button>

																						<Button
																							sx={{
																								top: -25,
																							}}
																							style={{
																								color: "#8F8F8F",
																							}}>
																							{" "}
																							Delete
																						</Button>
																					</Stack>
																				</Stack>

																				<Stack
																					direction="row"
																					justifyContent="space-between"
																					alignItems="flex-start">
																					<Typography
																						variant={
																							"h6"
																						}
																						style={{
																							fontWeight:
																								"500",
																							textTransform:
																								"capitalize",
																						}}>
																						{p?.name
																							? p?.name
																							: ""}
																					</Typography>
																					<Typography
																						variant={
																							"h6"
																						}
																						style={{
																							color: "#051E40",
																							textTransform:
																								"capitalize",
																						}}>
																						{p?.stage
																							? p?.stage
																							: ""}
																					</Typography>
																				</Stack>
																				<Typography
																					variant="subtitle1"
																					color={
																						"grey"
																					}
																					style={{
																						marginTop:
																							"12px",
																						textAlign:
																							"left",
																						fontSize:
																							" 16px",
																					}}
																					nowrap={
																						false
																					}>
																					{p?.description
																						? p
																								?.description
																								.length >=
																						  100
																							? `${p?.description.substring(
																									0,
																									100
																							  )} ....`
																							: p?.desciption
																						: ""}
																				</Typography>
																				<Stack
																					direction="row"
																					justifyContent="flex-end"
																					alignItems="center">
																					<Typography
																						variant="subtitle1"
																						color={
																							"#363636"
																						}
																						style={{
																							fontSize:
																								"14px",
																						}}
																						nowrap={
																							false
																						}>
																						{p?.date
																							? p?.date
																							: ""}
																					</Typography>
																				</Stack>

																				<Stack
																					direction="row"
																					justifyContent="space-between"
																					alignItems="flex-start">
																					<Typography
																						variant={
																							"body2"
																						}
																						style={{
																							fontWeight:
																								"500",
																						}}>
																						{p?.currency
																							? p?.currency
																							: ""}
																						&nbsp;
																						{p?.amount
																							? intToString(
																									p?.amount
																							  )
																							: ""}{" "}
																						Invested
																					</Typography>
																					<Typography
																						variant={
																							"body2"
																						}
																						sx={{
																							flex: 1,
																							textAlign:
																								"right",
																						}}
																						style={{
																							fontWeight:
																								"500",
																						}}>
																						{p?.valuation
																							? intToString(
																									p?.valuation
																							  )
																							: ""}{" "}
																						Valuation
																					</Typography>
																				</Stack>

																				 <Stack
                                    direction={"column"}
                                    spacing={1}
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    sx={{ position: "relative", top: -20 }}
                                  >
																				 <Stack direction="row" spacing={12}>
                                      <Typography
                                        variant={"h6"}
                                        style={{
                                          fontWeight: "500",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {p?.name ? p?.name : ""}
                                      </Typography>
                                      <Typography
                                        variant={"body1"}
                                        sx={{ flex: 1, textAlign: "left" }}
                                        style={{
                                          fontWeight: "500",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {p?.stage ? p?.stage : ""}
                                      </Typography>
                                    </Stack>
																				 <Typography
                                      variant="subtitle1"
                                      color={"grey"}
                                      style={{ textAlign: "start" }}
                                      nowrap={false}
                                    >
                                      {p?.description ? p?.description : ""}
                                    </Typography>
																				 <Typography
                                      variant="subtitle1"
                                      color={"#363636"}
                                      style={{
                                        marginLeft: "200px",
                                        fontSize: "14px",
                                      }}
                                      nowrap={false}
                                    >
                                      {p?.date ? p?.date : ""}
                                    </Typography>
																				 </Stack>
																				 <Stack
                                    direction={"row"}
                                    sx={{ marginTop: "-24px" }}
                                  >
                                    <Stack>
                                      <Typography
                                        variant={"body2"}
                                        sx={{ flex: 1, textAlign: "right" }}
                                        style={{ fontWeight: "500" }}
                                      >
                                        {p?.currency ? p?.currency : ""}&nbsp;
                                        {p?.amount ? p?.amount : ""}
                                      </Typography>
                                    </Stack>
                                    <Typography
                                      variant={"body2"}
                                      sx={{ flex: 1, textAlign: "right" }}
                                      style={{ fontWeight: "500" }}
                                    >
                                      {p?.valuation ? p?.valuation : ""}
                                    </Typography>
                                  </Stack>
																			</Box>
																		)}
																	</Grid>
																</>
															)
														)}
												</Grid>
											</>
										)}

										{isPortfolio && (
											<FieldArray
												name={"portfolio"}
												render={(arrayHelpers) => {
													return (
														<>
															{arrayHelpers?.form.values.portfolio.map(
																(p, i) => (
																	<Stack
																		direction={
																			"column"
																		}
																		spacing={
																			5
																		}
																		key={i}>
																		{arrayHelpers
																			?.form
																			.values
																			.portfolio
																			.length >
																			1 && (
																			<Stack
																				direction={
																					"row"
																				}
																				spacing={
																					5
																				}
																				alignItems={
																					"center"
																				}
																				justifyContent={
																					"flex-end"
																				}>
																				<Button
																					variant={
																						"outlined"
																					}
																					size={
																						"small"
																					}
																					color={
																						"warning"
																					}
																					onClick={() =>
																						arrayHelpers.remove(
																							i
																						)
																					}
																					endIcon={
																						<DeleteIcon />
																					}>
																					Delete
																				</Button>
																			</Stack>
																		)}
																		<Stack
																			direction="column"
																			justifyContent="center"
																			alignItems="center"
																			spacing={
																				1
																			}
																			sx={{
																				mb: 5,
																			}}>
																			<label
																				htmlFor={`logo${i}`}>
																				<Badge
																					overlap="circular"
																					anchorOrigin={{
																						vertical:
																							"bottom",
																						horizontal:
																							"right",
																					}}
																					badgeContent={
																						<IconButton
																							color={
																								"primary"
																							}
																							component={
																								"span"
																							}>
																							<AddCircleIcon />
																						</IconButton>
																					}>
																					<Avatar
																						alt={
																							"startup-picture"
																						}
																						src={
																							p &&
																							p?.src
																								? p?.src
																								: "/assets/startup-placeholder-image.png"
																						}
																						sx={{
																							width: 128,
																							height: 128,
																							cursor: "pointer",
																							backgroundColor:
																								"transparent",
																							border: "1px solid #E0E3FF",
																						}}
																						component={
																							"span"
																						}
																						imgProps={{
																							sx: {
																								objectFit:
																									p &&
																									p?.src
																										? "cover"
																										: "scale-down",
																							},
																						}}
																					/>
																				</Badge>
																				<Input
																					accept="image/*"
																					id={`logo${i}`}
																					multiple={
																						false
																					}
																					type="file"
																					onChange={(
																						e
																					) => {
																						const fileReader =
																							new FileReader();
																						fileReader.readAsDataURL(
																							e
																								.target
																								.files[0]
																						);
																						fileReader.addEventListener(
																							"load",
																							async function () {
																								const file =
																									e
																										.target
																										.files[0];
																								const path = `investors/${user.uid}/portfolio/${file.name}`;
																								// uploadFile(file, path)
																								const obj =
																									{
																										...arrayHelpers
																											.form
																											.values
																											.portfolio[
																											i
																										],
																										src: this
																											.result,
																										logo: path,
																									};
																								arrayHelpers.replace(
																									i,
																									obj
																								);
																								UploadFile(
																									file,
																									path
																								).then(
																									(
																										url
																									) => {
																										arrayHelpers.replace(
																											i,
																											{
																												...obj,
																												logoURL:
																													url,
																											}
																										);
																									}
																								);
																							}
																						);
																					}}
																				/>
																			</label>
																		</Stack>

																		<TextField
																			required
																			fullWidth
																			label={
																				"Startup name"
																			}
																			name={`portfolio.${i}.name`}
																			value={
																				formik
																					.values
																					.portfolio[
																					i
																				]
																					.name
																			}
																			onChange={
																				formik.handleChange
																			}
																			error={
																				getIn(
																					formik.touched,
																					`portfolio.${i}.name`
																				) &&
																				getIn(
																					formik.errors,
																					`portfolio.${i}.name`
																				)
																			}
																			helperText={
																				getIn(
																					formik.touched,
																					`portfolio.${i}.name`
																				) &&
																				getIn(
																					formik.errors,
																					`portfolio.${i}.name`
																				) &&
																				formik
																					.errors
																					.portfolio[
																					i
																				]
																					.name
																			}
																		/>
																		<Stack
																			direction={
																				"row"
																			}
																			spacing={
																				2
																			}>
																			 <TextField
                                        fullWidth
                                        label={"Stage"}
                                        name={`portfolio.${i}.stage`}
                                        value={formik.values.portfolio[i].stage}
                                        onChange={formik.handleChange}
                                        error={
                                          getIn(
                                            formik.touched,
                                            `portfolio.${i}.stage`
                                          ) &&
                                          getIn(
                                            formik.errors,
                                            `portfolio.${i}.stage`
                                          )
                                        }
                                        helperText={
                                          getIn(
                                            formik.touched,
                                            `portfolio.${i}.stage`
                                          ) &&
                                          getIn(
                                            formik.errors,
                                            `portfolio.${i}.stage`
                                          ) &&
                                          formik.errors.portfolio[i].stage
                                        }
                                      />
																			<TextField
																				required
																				fullWidth
																				name={`portfolio.${i}.stage`}
																				value={
																					formik
																						.values
																						.portfolio[
																						i
																					]
																						.stage
																				}
																				onChange={
																					formik.handleChange
																				}
																				error={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.stage`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.stage`
																					)
																				}
																				helperText={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.stage`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.stage`
																					) &&
																					formik
																						.errors
																						.portfolio[
																						i
																					]
																						.stage
																				}
																				select
																				label={
																					"Stage"
																				}
																				sx={{
																					textAlign:
																						"left",
																				}}>
																				<MenuItem
																					value={
																						"Pre-Seed"
																					}>
																					Pre-Seed
																				</MenuItem>
																				<MenuItem
																					value={
																						"Seed"
																					}>
																					Seed
																				</MenuItem>
																				<MenuItem
																					value={
																						"Series A"
																					}>
																					Series
																					A
																				</MenuItem>
																				<MenuItem
																					value={
																						"Series B"
																					}>
																					Series
																					B
																				</MenuItem>
																				<MenuItem
																					value={
																						"Series C+"
																					}>
																					Series
																					C+
																				</MenuItem>
																			</TextField>
																			<TextField
																				fullWidth
																				label={
																					"Date"
																				}
																				type="date"
																				InputLabelProps={{
																					shrink: true,
																				}}
																				name={`portfolio.${i}.date`}
																				value={
																					formik
																						.values
																						.portfolio[
																						i
																					]
																						.date
																				}
																				onChange={
																					formik.handleChange
																				}
																				error={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.date`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.date`
																					)
																				}
																				helperText={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.date`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.date`
																					) &&
																					formik
																						.errors
																						.portfolio[
																						i
																					]
																						.date
																				}
																			/>
																		</Stack>

																		<TextField
																			fullWidth
																			label={
																				"Description"
																			}
																			name={`portfolio.${i}.description`}
																			value={
																				formik
																					.values
																					.portfolio[
																					i
																				]
																					.description
																			}
																			onChange={
																				formik.handleChange
																			}
																			error={
																				getIn(
																					formik.touched,
																					`portfolio.${i}.description`
																				) &&
																				getIn(
																					formik.errors,
																					`portfolio.${i}.description`
																				)
																			}
																			helperText={
																				getIn(
																					formik.touched,
																					`portfolio.${i}.desciption`
																				) &&
																				getIn(
																					formik.errors,
																					`portfolio.${i}.description`
																				) &&
																				formik
																					.errors
																					.portfolio[
																					i
																				]
																					.description
																			}
																		/>

																		 <TextField
                                      fullWidth
                                      label={"Valuation"}
                                      name={`portfolio.${i}.valuation`}
                                      value={
                                        formik.values.portfolio[i].valuation
                                      }
                                      onChange={formik.handleChange}
                                      error={
                                        getIn(
                                          formik.touched,
                                          `portfolio.${i}.valuation`
                                        ) &&
                                        getIn(
                                          formik.errors,
                                          `portfolio.${i}.valuation`
                                        )
                                      }
                                      helperText={
                                        getIn(
                                          formik.touched,
                                          `portfolio.${i}.valuation`
                                        ) &&
                                        getIn(
                                          formik.errors,
                                          `portfolio.${i}.valuation`
                                        ) &&
                                        formik.errors.portfolio[i].valuation
                                      }
                                      select
                                      sx={{ textAlign: "left" }}
                                    >
                                      <MenuItem value={"1 Million or less"}>
                                        1 Million or less
                                      </MenuItem>
                                      <MenuItem value={"5 Million or less"}>
                                        5 Million or less
                                      </MenuItem>
                                      <MenuItem value={"15 Million or less"}>
                                        15 Million or less
                                      </MenuItem>
                                    </TextField>

																		<Stack
																			direction={
																				"row"
																			}
																			spacing={
																				2
																			}>
																			<TextField
																				label={
																					"Currency"
																				}
																				name={`portfolio.${i}.currency`}
																				value={
																					formik
																						.values
																						.portfolio[
																						i
																					]
																						.currency
																				}
																				onChange={
																					formik.handleChange
																				}
																				error={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.currency`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.currency`
																					)
																				}
																				helperText={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.currency`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.currency`
																					) &&
																					formik
																						.errors
																						.portfolio[
																						i
																					]
																						.currency
																				}
																				select
																				sx={{
																					textAlign:
																						"left",
																					width: {
																						xs: "60%",
																						sm: "25%",
																					},
																				}}>
																				<MenuItem
																					value={
																						"USD"
																					}>
																					USD
																				</MenuItem>
																				<MenuItem
																					value={
																						"GBP"
																					}>
																					GBP
																				</MenuItem>
																				<MenuItem
																					value={
																						"EUR"
																					}>
																					EUR
																				</MenuItem>
																			</TextField>
																			<TextField
																				fullWidth
																				label={
																					matches
																						? "Valuation"
																						: "Pre Money Valuation"
																				}
																				name={`portfolio.${i}.valuation`}
																				value={
																					formik
																						.values
																						.portfolio[
																						i
																					]
																						.valuation
																				}
																				onChange={
																					formik.handleChange
																				}
																				error={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.valuation`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.valuation`
																					)
																				}
																				helperText={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.valuation`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.valuation`
																					) &&
																					formik
																						.errors
																						.portfolio[
																						i
																					]
																						.valuation
																				}
																			/>
																		</Stack>

																		<Stack
																			direction={
																				"row"
																			}
																			spacing={
																				2
																			}>
																			<TextField
																				label={
																					"Currency"
																				}
																				name={`portfolio.${i}.currencyInvestment`}
																				value={
																					formik
																						.values
																						.portfolio[
																						i
																					]
																						.currencyInvestment
																				}
																				onChange={
																					formik.handleChange
																				}
																				error={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.currencyInvestment`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.currencyInvestment`
																					)
																				}
																				helperText={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.currencyInvestment`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.currencyInvestment`
																					) &&
																					formik
																						.errors
																						.portfolio[
																						i
																					]
																						.currencyInvestment
																				}
																				select
																				sx={{
																					textAlign:
																						"left",
																					width: {
																						xs: "60%",
																						sm: "25%",
																					},
																				}}>
																				<MenuItem
																					value={
																						"USD"
																					}>
																					USD
																				</MenuItem>
																				<MenuItem
																					value={
																						"GBP"
																					}>
																					GBP
																				</MenuItem>
																				<MenuItem
																					value={
																						"EUR"
																					}>
																					EUR
																				</MenuItem>
																			</TextField>
																			<TextField
																				fullWidth
																				label={
																					matches
																						? "Investment"
																						: "Amount Invested"
																				}
																				name={`portfolio.${i}.amount`}
																				value={
																					formik
																						.values
																						.portfolio[
																						i
																					]
																						.amount
																				}
																				onChange={
																					formik.handleChange
																				}
																				error={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.amount`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.amount`
																					)
																				}
																				helperText={
																					getIn(
																						formik.touched,
																						`portfolio.${i}.amount`
																					) &&
																					getIn(
																						formik.errors,
																						`portfolio.${i}.amount`
																					) &&
																					formik
																						.errors
																						.portfolio[
																						i
																					]
																						.amount
																				}
																			/>
																		</Stack>
																	</Stack>
																)
															)}

															<Button
																sx={{
																	width: 150,
																}}
																size={"small"}
																color={
																	"success"
																}
																variant={
																	"contained"
																}
																startIcon={
																	<AddCircleIcon />
																}
																onClick={() =>
																	arrayHelpers.push(
																		{
																			logo: "",
																			name: "",
																			focusVertical:
																				[],
																			stage: "",
																			valuation:
																				"",
																			currency:
																				"",
																			amount: "",
																		}
																	)
																}>
																Add portfolio
															</Button>
														</>
													);
												}}
											/>
										)}
										{!isPortfolio && (
											<Button
												onClick={() =>
													setPortfolio(true)
												}
												sx={{ width: 150 }}
												size={"small"}
												color={"success"}
												variant={"contained"}
												startIcon={<AddCircleIcon />}>
												Add portfolio
											</Button>
										)}
									</Stack>
								</Container>

								{!isPortfolio && (
									<Stack
										direction={"row"}
										justifyContent="center"
										alignItems="center"
										spacing={5}
										width={"100%"}>
										<Button
											variant={"outlined"}
											color={"primary"}
											sx={{ px: 5 }}
											onClick={() => setSteps(steps - 1)}>
											Back
										</Button>
										<Button
											variant={"contained"}
											color={"primary"}
											sx={{ px: 5 }}
											type={"submit"}>
											Submit
										</Button>
									</Stack>
								)}
								{isPortfolio && (
									<Stack
										direction={"row"}
										justifyContent="flex-end"
										alignItems="center"
										spacing={5}
										width={"100%"}>
										<Button
											variant={"outlined"}
											color={"primary"}
											sx={{ px: 5 }}
											onClick={() => setPortfolio(false)}>
											Back
										</Button>
										<Button
											variant={"contained"}
											color={"primary"}
											sx={{ px: 5 }}
											type={"submit"}
											onClick={() => {
												for (
													let i = 0;
													i <
													formik.values.portfolio
														.length;
													i++
												) {
													if (
														!formik.values
															.portfolio[i]
															.name ||
														!formik.values
															.portfolio[i].stage
													) {
														return;
													}
												}
												setPortfolio(false);
											}}>
											Submit
										</Button>
									</Stack>
								)}
							</>
						)}
					</Stack>
				</FormikProvider>*/}
      </Container>
    </>
  );
}
