import * as React from "react";
import {collection, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
import {AuthUserContext} from "../../../providers/auth-provider";
import {Badge} from "@mui/material";


export function ChatBadge(props) {
  const {profile} = React.useContext(AuthUserContext);
  const [count, setCounts] = React.useState(0)
  React.useEffect(() => {
    if (profile?.uid) {
      // debugger
      const q = query(
        collection(DB, "messages"),
        orderBy("createdAt", "desc"),
        where("receiver", "==", profile?.uid),
        where("read", "==", false)
      );

      const unSubs = onSnapshot(q, (querySnapshot) => {
        setCounts(querySnapshot?.size);
      });
      return () => unSubs;
    }
  }, [])

  return(
    <Badge sx={{
      '& .MuiBadge-badge': {
        fontSize: 10,
        minWidth: 16, 
        height: 16, 
      },
    }} badgeContent={count} max={99} color={count > 0 ? 'primary' : 'default'}>
    {props.children}
    </Badge>
  )

}
export function NotificationBadge(props) {
  const {profile} = React.useContext(AuthUserContext);
  const [count, setCounts] = React.useState(0)
  React.useEffect(() => {
    if (profile?.uid) {
      // debugger
      const q = query(
        collection(DB, "notifications"),
        orderBy("createdAt", "desc"),
        where("uid", "==", profile?.uid),
        where("read", "==", false)
      );

      const unSubs = onSnapshot(q, (querySnapshot) => {
        setCounts(querySnapshot?.size);
      });
      return () => unSubs;
    }
  }, [])

  return(
    <Badge sx={{
      '& .MuiBadge-badge': {
        fontSize: 10, // Adjust the font size for the badge
        minWidth: 16, // Adjust the width of the badge container
        height: 16, // Adjust the height of the badge container
      },
    }} 
    badgeContent={count} max={99} color={count > 0 ? 'primary' : 'default'}>
      {props.children}
    </Badge>
  )

}