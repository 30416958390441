import React from "react";
import {Avatar, Stack, Typography, useMediaQuery} from "@mui/material";
import {collection, getDocs, limit, query, where } from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {useNavigate} from "react-router-dom";


export default function ReferralAvatar({profile}){
  const navigator = useNavigate()
  const userContext = React.useContext(AuthUserContext)
  const [referalDetails, setReferDetails] = React.useState()

  React.useEffect(() => {

    const q = query(

      collection(DB, "users"),
      limit(1),
      where('referList', 'array-contains', profile?.uid)
    );
    getDocs(q).then((data) => {
      data?.size > 0 ? setReferDetails({...data?.docs[0].data()}) : setReferDetails()
    });

  }, [profile])
  return(
    <>
      {referalDetails &&

        <Stack direction={"row"} spacing={1} justifyContent="flex-start" alignItems="center" 
        sx={{ position: "relative",left:20, minWidth: 120, cursor: 'pointer' , marginTop:1}}
        // sx={{cursor:'pointer' ,marginTop:1}}

               onClick={() => {
                 const path = `/m/${userContext?.profile?.userType}/${referalDetails?.userType}-details/${referalDetails?.uid}`
                 if (referalDetails?.userType !== 'admin') {
                   navigator(path);
                 }
                 else {
                  window.open('https://astranova.io/');
                 }
               }}>
          <Avatar sx={{width: 61, height:61}} src={referalDetails?.photoURL} alt={'profile-pic'}>
            <Typography variant={"h3"} >{referalDetails?.displayName}</Typography>
          </Avatar>
          <Stack direction="column">
          <Typography variant={"h6"}>Referral by</Typography>
          
          <Typography variant={"body2"}  sx={{marginTop:'5px!important'}}>
          <font
                          size={2}
                          style={{
                            backgroundColor: "#F6F8FA",
                            padding: "5px 10px",
                            borderRadius: "6px",
                            fontWeight: 300,
                          }}
                        >
          {referalDetails?.displayName}
          </font>
          </Typography>
          </Stack>
        </Stack>
      }
    </>


  )
}