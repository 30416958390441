import * as React from "react";
import { Button, IconButton, Menu, Portal, Stack, Typography } from '@mui/material'
import {CelebrateIcon, FunnyIcon, InsightFulIcon, LikeIcon, PunchIcon} from "../../../../../utils/svg-icons";
import {DB} from "../../../../../config/firebase/firebase";
import {collection, setDoc, doc, getDoc, deleteDoc, serverTimestamp} from "firebase/firestore";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {useEffect} from "react";
import FavoriteBorderSharpIcon from '@mui/icons-material/FavoriteBorderSharp';
import FavoriteSharpIcon from '@mui/icons-material/FavoriteSharp';
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'


export default function PostFavorite({data}) {
  const {profile} = React.useContext(AuthUserContext);
  const [favorite, setFavorite] = React.useState(false)
  const [favoriteDoc, setFavoriteDoc] = React.useState()
  const [remove, setRemove] = React.useState(false);
  const colors = {
    'undefined': '#8F8F8F',
    'Like':'#233DFF',
    'Punch':'#F85353',
    'Celebrate':'#02C875',
    'LOL':'#FF7A00',
    'Insightful':'#CE41F6',
    'Puke':'#AAC15A'
  }


  const favoriteCallback = () => {
debugger
    const colRef = doc(DB, "users", profile?.uid, "favoritePosts", data?.id);
    setDoc(colRef, {
      createdAt: serverTimestamp()
    }, {merge: true}).then(()=> {
      setFavorite(true)
      setFavoriteDoc({id: data?.id});
    }).catch(() => {
      debugger
    });
  }
  useEffect(() => {
    setFavorite(false)
    setFavoriteDoc(undefined)
   let unsubs;
    unsubs =  getDoc(doc(DB, "users", profile?.uid, "favoritePosts", data?.id))
      .then((getdoc) => {
        if (getdoc.exists() ) {
          setFavorite(true);
          setFavoriteDoc({id : data?.id,...getdoc.data() })
          console.log('id', data?.id + JSON.stringify(getdoc.data()))
        }
        
      });
    return() => unsubs
  }, [data?.id])
  return(

    <>
      <IconButton onClick={() => {
        if (favoriteDoc) {
          setRemove(true)
        } else {
          favoriteCallback();
        }
      }}>
        {favoriteDoc ? <FavoriteSharpIcon sx={{color:'#FF3C3C'}} /> : <FavoriteBorderSharpIcon color={'disabled'} />}
      </IconButton>
      <Portal>
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
          open={remove}
        >
          <DialogContent>
            <Stack
              direction={"column"}
              justifyContent={"centre"}
              alignItems={"center"}
              sx={{ pt: 1 }}
              // spacing={3}
            >
              <FavoriteSharpIcon sx={{color:'#FF3C3C', height:"10%", width:"10%"}} />
            </Stack>

            <Typography
          variant={"h6"}
              textAlign={"center"}
              sx={{ fontWeight: "600",pt:"2",pb:"3" }}
            >
              Remove from favorites?
            </Typography>
            <Typography
                type={"h5"}
              variant={"subtitle2"}

              textAlign={"center"}
              sx={{ pt:2 }}
            >
              Are you sure you want to remove from favorites?
              This action can not be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack direction="row"
                   justifyContent="center"
                   alignItems="center"
                   spacing={2}
                   sx={{width:"100%", pb:2}}>
              <Button
                variant="outlined"
                color="primary"
                sx={{ textTransform: "none", width:"25%" }}
                onClick={() => {setRemove(false)}}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none", width:"25%" }}
                onClick={() => {
                  const colRef = doc(DB, "users", profile?.uid, "favoritePosts", data?.id);
                  deleteDoc(colRef).then(()=> {
                    setFavorite(false)
                    setFavoriteDoc(undefined);
                    setRemove(false)
                  })}}
              >
                Yes
              </Button>

            </Stack>
          </DialogActions>
        </Dialog>
      </Portal>
    </>
  )
}