import React from "react";
import {collection, getDocs, onSnapshot, orderBy, query, where} from "firebase/firestore";
import  {DB} from "../../../../config/firebase/firebase";

import {Box, Container, Grid, Stack, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import {AuthUserContext} from "../../../../providers/auth-provider";
import ServiceProviderListItem from "../../../../screens/mobile/components/common/service-provider-list-item";
import StartupListItem from "../../../../screens/mobile/components/common/startup-list-item";


export const StartupList = () => {
  const [startup, setStartup] = React.useState([])
  const [search, setSearch] =React.useState('')
	const[inputbtn,setinputbtn]=React.useState();

  const {profile} = React.useContext(AuthUserContext);
  React.useEffect(() => {
    debugger
    const q = query(
      collection(DB, "users"),
      where('userType', '==', 'startup'),
      where('onboard', '==', true),
      orderBy("createdAt", "desc")
    );
    const unSubs = onSnapshot(q, (querySnap) => {
      debugger
      if (querySnap.size > 0) {
        const list = []
        querySnap.forEach((doc) => {
          list.push({id: doc.id, ...doc.data()})
        });

        setStartup(list)
      }
    });
    return () => unSubs;
  }, [])
  return(
    <>
      <Container maxWidth={'xl'}  sx={{my: 5, textAlign: 'left'}} >
        <Stack direction={'row'} spacing={5} sx={{width: '100%', mb: 5}}>
          <div style={{flex: 1}}>
            <Typography variant={'h4'} sx={{fontWeight: 700}}>Hello {profile?.displayName} &nbsp; 👋</Typography>
            <Typography variant={"body1"} color={'secondary'}>Welcome to  ASTRANOVA</Typography>
          </div>
          <Box   onSubmit={(event) => event.preventDefault()}
            component="form"
            sx={{ p: '2px 10px', display: 'flex', alignItems: 'center', width: 337, height: 50, borderRadius: '12px',backgroundColor: '#E4EAF5' }}
          >
            <IconButton type="button" sx={{ p: '10px', }} aria-label="search" 
            	onClick={(e) => setSearch(inputbtn)}
              >
              <SearchIcon sx={{ color:'#051E40BF'}}/>
            </IconButton>
            <InputBase
            onChange={(e) => setinputbtn(e.target.value)}
            value={inputbtn}
							onKeyDown={(e)=>{
								if(e.code==="Enter"){
									setSearch(inputbtn)
								}
							}}
          
              sx={{ ml: 1, flex: 1 ,color:'#051E40BF',  }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search post' }}
            />
          </Box>
        </Stack></Container>
      <Container maxWidth={'lg'}  sx={{my: 5, textAlign: 'left'}} >

        <Grid container spacing={5}>
          {startup.length > 0 &&
            startup.length > 0 && startup.filter((post) => {
              if (search !== "") {
                const reg = new RegExp(search);
                // console.log(post.startup);
                return (reg.test(post?.startup?.startupName) || reg.test(post?.startup?.location?.toString())
                 || reg.test(post?.startup?.focusVertical?.toString()))  &&  post
              } else {
                return post
              }

            }).sort((a, b) => b.createdAt - a.createdAt).map( (record) => (        <StartupListItem data={record} key={record?.id} href={`/m/${profile?.userType}/startup-details/${record?.id}`}/>

            ))

          }
        </Grid>
      </Container>

    </>
  )

}