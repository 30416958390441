import * as yup from "yup";

const addOfferinitialValues = {
  section: [
    {
      title: "",
      description: "",
    },
  ],
  companyName: "",
  name: "",
  headingOne: "",
  headingTwo: "",
  from: "",
  to: "",
  hasCoupon: false,
  couponCode: "",
  claimCount: "",
  offerLink: "",
  claimLink: "",
  isHotOffer: false,
  isActive: false,
};

const OfferSectionSchema = yup.object({
  title: yup
    .string("Title")
    .required("Title is required."),
  description: yup
    .string("Description")
    .required("Description is required.")
});
const AddOfferSchema = yup.object({
  companyName: yup
    .string("Company Name")
    .required("company Name is required.")
    .max(100, "Company name should be of maximum 100 words"),
  name: yup
    .string("offer Name")
    .required("Offer Name is required.")
    .max(150, "offer name should be of maximum 150 words"),
  headingOne: yup
    .string("offer Heading 1")
    .required("Offer Heading is required.")
    .max(150, "offer heading should be of maximum 150 words"),
  headingTwo: yup
    .string("offer Heading 2")
    .required("Offer Heading is required.")
    .max(150, "offer heading should be of maximum 150 words"),
  section: yup
    .array("offer sections")
    .of(OfferSectionSchema)
    .required("Section is required")
    .min(1),
  from: yup.date("Validity From ").required("From is a required field").min(new Date(-1), "From Date can not be earlier than today"),
  to: yup.date('Validity to').required('To is a required field').when("from",
  (from, yup) => from && yup.min(from, "To date cannot be before Start date")),
  hasCoupon: yup.boolean("has coupon"),
  couponCode: yup
    .string("Coupon Code").required('Coupon Code is required'),
  claimCount: yup
    .number("Claim Count"),
  offerLink: yup.string("offer Link").url("The offer link must be a valid url"),
  claimLink: yup.string("Claim Link").url("The claim link must be a valid url"),
  isHotOffer: yup.boolean("Hot offer"),
  isActive: yup.boolean("Live"),
});

const AddOfferValidations = {
  addOfferinitialValues,
  schema: AddOfferSchema,
};

export default AddOfferValidations;
