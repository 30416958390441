import * as React from "react";
import {
  InputBase,
  IconButton,
  Stack,
  ListItem,
  ListItemButton,
  Box,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Paper,
} from "@mui/material";

import { DB } from "../../../config/firebase/firebase";
import {
  collection,
  FieldValue,
  serverTimestamp,
  query,
  onSnapshot,
  increment,
  addDoc,
  orderBy,
  where,
  getDocs,
  updateDoc,
  doc,
  limit,
  startAfter,
} from "firebase/firestore";
import { AuthUserContext } from "../../../providers/auth-provider";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PostCommentItem from "./post-comment-item";
import PostNotification from "../../../api/post-notification";
import Typography from "@mui/material/Typography";
import NotificationEmail from "../../../api/notification-email";
import { stringToColor } from "../../../utils/avatarGenerator";
import { USER_TYPES_TO_NAME } from "../../../utils/enums/labels.enum";
export default function PostComments({
  data,
  countCallback,
  show,
  media,
  mobile,
}) {
  const { profile } = React.useContext(AuthUserContext);
  const [comments, setComments] = React.useState([]);
  const [text, setText] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [lastComment, setLastComment] = React.useState([]);
  const queryLimit = 2; // initial comment render limit
  const loadMoreLimit = 10; // how much comments to load manually

  const postComment = async () => {
    const commentText = "" + text;
    setText("");
    if (commentText !== "") {
      const addRef = collection(DB, "timelines", data?.id, "comments");
      let add;

      if (selected && selected.length > 0) {
        const mentioned = selected.map((s) => {
          const { displayName, uid, photoURL, email, userType } = s;
          return { displayName, uid, photoURL, email, userType };
        });
        add = await addDoc(addRef, {
          uid: profile?.uid,
          text: commentText,
          createdAt: serverTimestamp(),
          mentioned,
        });
        for (let mention of mentioned) {
          NotificationEmail({
            type: "mentioned",
            displayName: profile?.displayName,
            photoURL: profile?.photoURL,
            uid: profile?.uid,
            userType: profile?.userType,
            mentionedInfo: {
              ...mention,
              url: `${mention?.userType}/post-details/${data?.id}#${add?.id}`,
            },
            postInfo: {
              ...data,
              media,
            },
          }).then();

          PostNotification({
            type: "mentioned-comment",
            imageURL: profile?.photoURL,
            message: "",
            mentionedCommentId: add.id,
            displayName: profile?.displayName,
            uid: mention?.uid,
            postId: data?.id,
            ...data?.current,
          }).then();
        }
      } else {
        add = await addDoc(addRef, {
          uid: profile?.uid,
          text: commentText,
          createdAt: serverTimestamp(),
        });
      }

      await updateDoc(doc(DB, "timelines", data?.id), {
        "current.comments": increment(1),
      });
      const q = query(
        collection(DB, "notifications"),
        where("uid", "==", data?.uid),
        where("type", "==", "post-comment-notification"),
        where("postId", "==", data?.id),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      //  const commentID = query(
      //   collection(DB, "timelines", data?.id, "comments"),
      //   where("uid", "==", data?.uid),
      //  )
      try {
        //debugger
        const notifications = await getDocs(q);
        if (data?.uid !== profile?.uid) {
          if (notifications.size > 0) {
            await updateDoc(
              doc(DB, "notifications", notifications.docs[0].id),
              {
                displayName: `
             
                ${
                  count === 0
                    ? `${profile?.displayName}`
                    : count > 1
                    ? `${profile?.displayName}, and ${count} others`
                    : `${profile?.displayName}, and ${count} other`
                }
            `,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                imageURL: profile?.photoURL,
                read: false,
              }
            );
          } else {
            PostNotification({
              type: "post-comment-notification",
              imageURL: profile?.photoURL,
              message: "",
              displayName: profile?.displayName,
              uid: data?.uid,
              postId: data?.id,
            }).then();
          }
        }
        console.log("add comment", add?.id);
      } catch (e) {
        //debugger
      }
      setText("");
      const lc = query(
        collection(DB, "timelines", data?.id, "comments"),
        orderBy("createdAt", "desc"),
        limit(1)
      );

      const unsub = onSnapshot(lc, (querySnap) => {
        const list = [...comments];
        querySnap.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setComments([...comments, { id: doc.id, ...doc.data() }]);
          console.log(comments);
        });
        if (count === comments.length) {
          //if all comments are loaded
          setLastComment(undefined);
        }
      });
      return () => unsub;
    }
  };

  const loadMore = async () => {
    const q = query(
      collection(DB, "timelines", data?.id, "comments"),
      orderBy("createdAt", "asc"),
      startAfter(lastComment),
      limit(loadMoreLimit)
    );
    const querySnap = await getDocs(q);
    if (querySnap.size > 0) {
      const list = [...comments];
      querySnap.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      // console.log(list);
      setComments(list);
      querySnap.size < loadMoreLimit
        ? setLastComment(undefined)
        : setLastComment(querySnap.docs[querySnap.docs.length - 1]);
    } else {
      setLastComment(undefined);
    }
  };

  // use effect for length of all comments
  React.useEffect(() => {
    const q = query(collection(DB, "timelines", data?.id, "comments"));
    const unsub = onSnapshot(q, (querySnap) => {
      setCount(querySnap.size);
      countCallback(querySnap?.size);
    });
    return () => unsub;
  }, [data?.id]);

  //use effect for rendering limited comments
  React.useEffect(() => {
    const q = query(
      collection(DB, "timelines", data?.id, "comments"),
      orderBy("createdAt", "asc"),
      limit(queryLimit)
    );
    const unsub = onSnapshot(q, (snapshots) => {
      if (snapshots.size > 0) {
        const list = [];
        snapshots.forEach((doc) => {
          list.push({ id: doc?.id, ...doc?.data() });
        });
        // console.log(snapshots.docs.slice(-1))
        // console.log()
        snapshots.size < queryLimit
          ? setLastComment(undefined)
          : setLastComment(snapshots.docs[snapshots.docs.length - 1]);
        setComments(list);
        // showCallback(snapshots?.size > 0 ? true : false)
        // countCallback(snapshots?.size)
      } else {
        setLastComment(undefined);
      }
    });
    return () => unsub;
  }, []);

  if (show)
    return (
      <Stack
        direction={"column"}
        sx={{ width: "100%", px: !mobile && 3 }}
        style={{ paddingBottom: "5.5%" }}
        spacing={2}
      >
        {/* {console.log(comments)} */}
        <List>
          {comments?.length > 0 &&
            comments?.map((comment, i) => (
              <>
                <PostCommentItem
                  mobile={mobile}
                  comment={comment}
                  timelineId={data?.id}
                  postuid={data?.uid}
                  key={comment?.id}
                />
                {/* {i !== comments.length - 1 &&  <Divider variant="inset" component="li" />
            }*/}
              </>
            ))}
        </List>
        {comments.length !== count && lastComment && (
          <Box
            sx={{ display: "flex" }}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
          >
            <Typography
              onClick={loadMore}
              fontSize={mobile ? 12 : 14}
              sx={{
                px: 2,
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Load more comments
            </Typography>
          </Box>
        )}
        <Stack px={mobile && 3}>
          <Box
            sx={{
              p: mobile ? "25px 10px" : "30px 10px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: mobile ? 20 : 25,
              borderRadius: "25px",
              border: ".5px solid #c7c7c7",
            }}
          >
            {/*  {selected && <Typography variant={'caption'} fontWeight={600}>
          {selected?.displayName}
        </Typography>}*/}
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                fontSize: mobile ? 12 : 14,
                input: {
                  "&::placeholder": {
                    opacity: 1,
                    color: "#8F8F8F",
                    fontSize: mobile ? 12 : 14,
                  },
                },
              }}
              value={text}
              onChange={(e) => {
                const reg = /@/;
                if (reg.test(e.target.value)) {
                  if (!open) setOpen(!open);
                  setSearch(e.target.value.split("@")[1]);
                  /*TODO: value store after @ */
                } else {
                  setOpen(false);
                }
                setText(e.target.value);
              }}
              onKeyDown={(e) => {
                //debugger
                if (e.key === "Enter") {
                  //debugger
                  postComment().then();
                } else {
                  if ((e.which == 8 || e.which === 46) && text == "")
                    setSelected([]);
                }
              }}
              placeholder="Comment"
              inputProps={{ "aria-label": "Comment" }}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="Comment"
              onClick={postComment}
            >
              <img src="/assets/send_icon.png" width={mobile && 24} alt="ico" />
            </IconButton>
          </Box>
        </Stack>
        <AutoCompleteList
          style={{ marginTop: "0px!important" }}
          open={open}
          cb={(value) => {
            setOpen(!open);
            setText(`${text.split("@")[0]} ${value?.displayName}`);
            setSelected([...selected, value]);
          }}
          search={search}
        />

        {/*
      <TextField type={'text'} placeholder={'Comment'}
               
                 variant={"outlined"} value={text}/>*/}
      </Stack>
    );
}
const capitalizeWord = (text) => {
  if (text !== "") {
    const sentence = text;

    const words = sentence.split(" ");
    if (words.length > 1) {
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
    } else {
      words[0] = words[0][0].toUpperCase();
    }

    if (sentence.split(" ").length > 1) {
      return words.join(" ");
    } else return words.join("");
  } else return text;
};
function AutoCompleteList({ open, cb, search }) {
  const [users, setUsers] = React.useState([]);
  React.useEffect(() => {
    if (search !== "") {
      const q = query(
        collection(DB, "users"),
        where(
          "searchName",
          ">=",
          search.toLowerCase() /* capitalizeWord(search)       TODO: capitalize word space issue */
        ),
        where("onboard", "==", true),
        limit(15)
      );
      getDocs(q).then((snapshots) => {
        const list = [];
        if (snapshots?.size > 0) {
          snapshots.forEach((d) => list.push({ ...d.data() }));
          setUsers([...list]);
        } else setUsers([]);
      });
    } else setUsers([]);
  }, [search]);

  React.useEffect(() => {
    if (!open) setUsers([]);
  }, [open]);

  if (open)
    return (
      <Box px={3}>
        {users.length > 0 && (
          <List
            dense
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              height: "500px",
              overflowY: "scroll",
            }}
            component={Paper}
          >
            {users.map((value) => {
              const labelId = `checkbox-list-secondary-label-${value?.uid}`;
              return (
                <ListItem key={value?.uid} disablePadding>
                  <ListItemButton onClick={() => cb(value)}>
                    <ListItemAvatar>
                      <Avatar
                        alt={`Avatar n°${value?.displayName}`}
                        src={value?.photoURL}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      primary={`${value?.displayName}`}
                      secondary={USER_TYPES_TO_NAME[
                        value?.userType
                      ].toUpperCase()}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    );
}
