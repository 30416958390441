import React from "react";
import {addDoc, collection, serverTimestamp, setDoc, doc} from "firebase/firestore";
import {DB} from "../config/firebase/firebase";
import {AuthUserContext} from "../providers/auth-provider";


const SaveAsDraft = (data, id, user) => new Promise(async (resolve, reject) => {
  debugger
  try {
    if ( id ) {
      const userRef = doc(DB, 'users', id) ;
      await setDoc(userRef, {
        ...data,
        updatedById:user?.uid,
        updatedBy:  'admin' ,
        updatedAt: new Date().getTime(),
      }, {merge:true})
      resolve(id)
    } else {
      let add = await addDoc(collection(DB, "users"),
        {
          createdById:user?.uid,
          createdBy:'admin',
          ...data,
          onboard:false,
          createdAt: new Date().getTime(),
          updatedById:user?.uid,
          updatedBy:  'admin' ,
          updatedAt: new Date().getTime(),
        });
      resolve(add.id)
    }
  } catch (e) {
    console.log('User rejected',e)
    reject()
  }
})

export default SaveAsDraft;
