import * as React from "react";
import {
  InputBase,
  IconButton,
  Stack,
  TextField,
  Typography,
  Box,
  List,
  Divider,
} from "@mui/material";

import { DB } from "../../../../../../config/firebase/firebase";
import {
  collection,
  serverTimestamp,
  query,
  onSnapshot,
  addDoc,
  orderBy,
  where,
  limit,
  getDocs, 
  updateDoc,
  doc,
} from "firebase/firestore";
import { AuthUserContext } from "../../../../../../providers/auth-provider";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PostCommentItem from "./../post-comment-item";
import CommentReplyItem from "./comment-reply-item";
import PostNotification from "../../../../../../api/post-notification";
export default function CommentReplies({
  comment,
  timelineId,
  countCallback,
  show,
  postuid,
  commentsCount,
}) {
  const { profile } = React.useContext(AuthUserContext);
  const [comments, setComments] = React.useState([]);
  const [text, setText] = React.useState("");
  const [count, setCount] = React.useState(0);
  const postComment = async () => {
    if (text !== "") {
      const addRef = collection(
        DB,
        "timelines",
        timelineId,
        "comments",
        comment?.id,
        "replies"
      );
      const add = await addDoc(addRef, {
        uid: profile?.uid,
        text,
        createdAt: serverTimestamp(),
      });
      const q = query(
        collection(DB, "notifications"),
        where("uid", "==", postuid),
        where("type", "==", "post-comment-notification"),
        where("postId", "==", timelineId),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      const notifications = await getDocs(q);
      if (profile?.uid !== comment?.user?.uid) {
        if (notifications.size > 0) {
          await updateDoc(doc(DB, "notifications", notifications.docs[0].id), {
            displayName: `${profile?.displayName} replied to ${comment?.user?.displayName}’s, and ${count} others `,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            imageURL: profile?.photoURL,
            read: false,
          });
        } else {
          PostNotification({
            type: "post-comment-notification",
            imageURL: profile?.photoURL,
            message: "",
            displayName: `${profile?.displayName} replied to ${comment?.user?.displayName}’s `,
            uid: postuid,
            postId: timelineId,
          }).then();
        }
      }
      console.log("add comment", add?.id);
      setText("");
    }
  };
  React.useEffect(() => {
    let unsub;
    const q = query(
      collection(
        DB,
        "timelines",
        timelineId,
        "comments",
        comment?.id,
        "replies"
      ),
      orderBy("createdAt", "asc")
    );
    unsub = onSnapshot(q, (snapshots) => {
      const list = [];
      snapshots.forEach((doc) => {
        list.push({ id: doc?.id, ...doc?.data() });
      });
      setComments(list);
      // showCallback(snapshots?.size > 0 ? true : false)
      countCallback(snapshots?.size);
      setCount(snapshots?.size);
    });
    return () => unsub;
  }, [comment?.id]);
  if (show)
    return (
      <Stack direction={"column"} sx={{ width: "100%", p: 0 }} spacing={2}>
        <List>
          {comments?.length > 0 &&
            comments?.map((reply, i) => (
              <>
                <CommentReplyItem
                  reply={reply}
                  timelineId={timelineId}
                  key={reply?.id}
                  postuid={postuid}
                  commentId={comment?.id}
                  count={count}
                />
                {/* {i !== comments.length - 1 &&  <Divider variant="inset" component="li" />
            }*/}
              </>
            ))}
        </List>

        <Box
          sx={{
            p: "17px 10px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: 20,
            borderRadius: "30px",
            border: ".5px solid #8F8F8F",
          }}
        >
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
              color: "#8F8F8F",
              input: {
                "&::placeholder": {
                  fontSize: "11px", // Set the font size to 13px
                  opacity: 1,
                },
              },
            }}
            value={text}
            autoFocus={true}
            onChange={(e) => {
              setText(e.target.value);
            }}
            onKeyDown={(e) => e.key === "Enter" && postComment()} 
            placeholder="Reply..."
            inputProps={{ "aria-label": "send-button" }}
          />

          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="send-button"
            onClick={postComment}
          >
            <img src="/assets/send_icon.png" alt="ico" />
          </IconButton>
        </Box>

        {/*
      <TextField type={'text'} placeholder={'Comment'}
               
                 variant={"outlined"} value={text}/>*/}
      </Stack>
    );
}
