import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
  Button,
  Container, FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton, MenuItem,
  Stack,
  Switch, TextField,
  Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useFormik} from "formik";
import OpenToCofounderValidation from "../../../validations/startup/open-for-cofounder";
import {addDoc, collection, doc, serverTimestamp, setDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
import {AuthUserContext} from "../../../providers/auth-provider";

export default function OpenToCoFounder(){
  const { user} = React.useContext(AuthUserContext);
  const location = useLocation()
  const [locations] = React.useState(location)

  const navigator = useNavigate()
  // console.log('StartupId::::', location.state.startupId)
  const formik = useFormik({
    validationSchema: OpenToCofounderValidation.schema,
    initialValues: OpenToCofounderValidation.initialValues,
    onSubmit:async (values) => {

      try{
        const {
          name,
          description,
          foundingDate,
          location} = locations.state.startup
        const userRef = doc(DB, 'users', user.uid);
        const helpId = await addDoc(collection(DB, "help-requests"), {
          name,
          description,
          foundingDate,
          uid: user?.uid,
          displayName: user.displayName || '',
          location,
          startupId: locations.state.startupId,
          type: 'startup',
          requestFor: 'Open to co-founder',
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          ...values
        });
        await setDoc(userRef, {onboard: true}, {merge: true});
        navigator('/startup/home');

      } catch (e) {
        debugger
        // console.log('ERROR :::: : :::: ::',e)
      }

    }
  })
  return(<>
    <Container maxWidth={'full-width'} sx={{marginY: 5}}>
      <Stack direction={'row'} justifyContent="flex-start"
             alignItems="center"
             spacing={2} width={'100%'} >
        <IconButton onClick={() => navigator(-1)}>
          <ArrowBackIcon sx={{fontSize: 40}} />
        </IconButton>
        <Typography variant={'h5'} sx={{textAlign:'left', lineHeight: 1}}>
          Open to Co-founder <br/> <small style={{fontSize: 14, color: '#8F8F8F'}}>Please fill in additional information</small>
        </Typography>
      </Stack>
    </Container>
    <Container maxWidth={'md'} sx={{marginY: 5}}>
      <Stack direction={'column'} spacing={5} component="form" onSubmit={e => formik.handleSubmit(e)} >
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend"  sx={{textAlign: 'left', mb: 2}}>Are you investing? </FormLabel>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>No</Typography>
            <FormControlLabel
              control={
                <Switch   name={'areYouInvesting'}
                          // checked={formik.values.areYouInvesting}
                          // onChange={formik.handleChange}
                />
              }


            />
            <Typography>Yes</Typography>
            {/*<FormHelperText*/}
            {/*  error={formik.touched.areYouInvesting && Boolean(formik.errors.areYouInvesting)}*/}
            {/*  children={formik.touched.areYouInvesting && formik.errors.areYouInvesting}*/}

            {/*/>*/}
          </Stack>

        </FormControl>

        <TextField
          label={'How much are you investing?'}
          name={'howMuchAreYouInvesting'}
          select
          sx={{textAlign:'left'}}
          value={formik.values.howMuchAreYouInvesting}
          onChange={formik.handleChange}
          error={formik.touched.howMuchAreYouInvesting && Boolean(formik.errors.howMuchAreYouInvesting)}
          helperText={formik.touched.howMuchAreYouInvesting && formik.errors.howMuchAreYouInvesting}
        >
          <MenuItem value={'1 Million or less'}>1 Million or less</MenuItem>
          <MenuItem value={'5 Million or less'}>5 Million or less</MenuItem>
          <MenuItem value={'15 Million or less'}>15 Million or less</MenuItem>
        </TextField>

        <TextField
          label={'On what terms are you investing?'}
          name={'onWhatTermsAreYouInvesting'}
          value={formik.values.onWhatTermsAreYouInvesting}
          onChange={formik.handleChange}
          error={formik.touched.onWhatTermsAreYouInvesting && Boolean(formik.errors.onWhatTermsAreYouInvesting)}
          helperText={formik.touched.onWhatTermsAreYouInvesting && formik.errors.onWhatTermsAreYouInvesting}/>

        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend"  sx={{textAlign: 'left', mb: 2}}>Are you leading? </FormLabel>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>No</Typography>
            <FormControlLabel
              control={
                <Switch   name={'areYouLeading'}
                          checked={formik.values.areYouLeading}
                          onChange={formik.handleChange}
                />
              }


            />
            <Typography>Yes</Typography>
            <FormHelperText
              error={formik.touched.areYouLeading && Boolean(formik.errors.areYouLeading)}
              children={formik.touched.areYouLeading && formik.errors.areYouLeading}

            />
          </Stack>

        </FormControl>

        <Stack direction={'row'} spacing={5}>
          <Stack direction={'row'} justifyContent="center"
                 alignItems="center"
                 spacing={5} width={'100%'} >

            <Button variant={'contained'} color={'primary'} sx={{px: 5}} type={"submit"}>
              Post
            </Button>
          </Stack>
        </Stack>
      </Stack>

    </Container>
    </>)
}