import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton, Portal, useMediaQuery} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import AboutEditFormExpert from "../../../../../screens/expert/edit/about-edit-form";
import AboutEditStartupForm from "../../../../../screens/startups/edit/about-edit-startup-form";
import EditStartupFounderDetailsForm from "../../../../../screens/startups/edit/edit-startup-founder-details-form";
import {useNavigate} from "react-router-dom";
import EditStartupFounderAboutDetailsForm
  from "../../../../../screens/startups/edit/edit-startup-founder-about-details-form";
import {ArrowBack} from "@mui/icons-material";
import EditServiceProviderOrganisationAboutDetailsForm from '../../../../../screens/service-provider/edit/edit-service-provider-organisation-about-details-form';
import PostNotification from "../../../../../api/post-notification";

export default function AboutServiceProviderOrganisationComponent({profile,open, handleClose , callback}) {
    debugger
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  const regexAdmin = /admin\/organisation-profile/
  const copyProfile = regexAdmin.test(window.location.pathname) ? profile : {...context.profile}
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? profile.uid : user.uid);
  const navigator = useNavigate()
    const [isLoading, setLoading] = React.useState(false);
  return (
    <Portal>
        <Dialog
            fullScreen={!matches ? true: false}
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
                {!matches && <IconButton onClick={() => handleClose()}>
                    <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
                </IconButton>}
                {"Edit About Organisation"}
            </DialogTitle>
            <DialogContent sx={{px: !matches ? 0 : ''}}>
          <br/>
          <EditServiceProviderOrganisationAboutDetailsForm
            data={{
              website: profile?.serviceProvider?.organisationDetails?.website || '',
              serviceType: profile?.serviceProvider?.serviceType || '',
              sector: profile?.serviceProvider?.sector || '',
            }}
            cbBack={handleClose}
            cbNext={async (data) => {
              await setDoc(
                userRef,
                {
                  serviceProvider: {...profile?.serviceProvider, sector: data.sector, serviceType: data.serviceType, organisationDetails: {...profile?.serviceProvider?.organisationDetails,website: data.website }}
                },
                { merge: true }
              )
              if (regexAdmin.test(window.location.pathname)) {
                callback({...copyProfile, serviceProvider: {...profile?.serviceProvider, sector: data.sector, serviceType: data.serviceType, organisationDetails: {...profile?.serviceProvider?.organisationDetails,website: data.website }}})
                setLoading(false)
    
                PostNotification({
                  type: 'admin-update-profile',
                  imageURL: '/assets/system-noti-icon.png',
                  section: 'Organization',
                  message: `information has been updated by the Admin.`,
                  uid: profile.uid,
                  createdBy: user?.uid
                }).then()
    
              } else {
                await setProfile({...copyProfile, serviceProvider: {...profile?.serviceProvider, sector: data.sector, serviceType: data.serviceType, organisationDetails: {...profile?.serviceProvider?.organisationDetails,website: data.website }}})
                handleClose()
                navigator(`${!matches ? '/m':'' }/${profile?.userType}/organisation-profile`, {
                  state: {
                    profile: {...profile, serviceProvider: {...profile?.serviceProvider, sector: data.sector, serviceType: data.serviceType, organisationDetails: {...profile?.serviceProvider?.organisationDetails,website: data.website }}},
                    organisation: {...profile?.serviceProvider?.organisationDetails}
                  }
                })
              }
           
            }}
            isLoading={isLoading}
          />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
