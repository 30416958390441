import React from "react";
import {
	Avatar,
	Grid,
	Paper,
	Stack,
	styled,
	Typography,
	Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ListItemStartConversationC from "./list-item-start-conversation copy";
import { AuthUserContext } from "../../../../providers/auth-provider";
import { EXPERT } from "../../../../utils/enums/labels.enum";
import MemberFavourite from "../../common/MemberFav";
import ListItemStartConversation from "./list-item-start-conversation";

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "center",
	backgroundColor: "#fff",
	color: theme.palette.text.secondary,
	boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
	borderRadius: "10px",
	height: 250,
}));
export default function ExpertListItem({ data, href,itemWidth }) {
	const navigator = useNavigate();
	const { profile } = React.useContext(AuthUserContext);


	return (
		<Grid
		item
		lg={4}
		md={4}
		sm={6}
		xs={6}

		wrap={"nowrap"}>
		<Item style={{ width: itemWidth }}>
			<Stack direction={"column"} spacing={2} sx={{ width: "100%" }}
				   justifyContent="center"
				   alignItems="center">
				   	<Stack sx={{ ml: "90%", mb:"-15%", mt:"-2.5%" }}>
						<MemberFavourite data={{ ...data,card: "expert" }} />
					</Stack>
				<Box
				sx={{
					position: "relative",
							top:"40px",
							width: "150px",
							height: "110px",
							background: "#F6F8FA",
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px"
					}}
				>
				<Stack
					direction={"column"}
					spacing={1}
					justifyContent="center"
					alignItems="center" 
					style={{cursor:"pointer"}}
					onClick={() => navigator(href)}>
					<Box
						sx={{
							border: "1px solid #204488",
							p: 0.75,
							borderRadius: "50%",
							marginTop : "-40px"
						}}>
						<Avatar
							alt="Remy Sharp"
							src={data?.photoURL}
							sx={{ width: 40, height: 40 }}>
							<Typography  style={{fontSize:"12px",overflow:"hidden"}}>
								{data?.displayName &&
									data?.displayName.charAt(0)}
							</Typography>
						</Avatar>
					</Box>
					<Typography
						style={{fontSize:"12px",overflow:"hidden",width:"100px"}}
							sx={{ fontWeight: 650, mb: 0, lineHeight: 1.2,color:"black"}}>
						{(data?.displayName && data.displayName.length > 14) ? `${data.displayName.substr(0, 14)}...` : data?.displayName} <br />
						<font size={3} style={{fontSize:"10px",overflow:"hidden",fontWeight:"300"}}>
							{EXPERT.SINGULAR_LABEL}
						</font>
					</Typography>


					<Box />
				</Stack>
				<Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}
            >
				{
					(data.uid !== profile?.uid) ?  (
						<ListItemStartConversation data={{...data, creatorData: data}}/>

					):<Button size={'small'} variant={"outlined"} color={'secondary'} sx={{px: 2, width:120}} onClick={() => {navigator(`/m/${profile?.userType}/profile`)
							}}>My profile</Button>
				}
				</Box>
				</Box>
				
				<Box />
				<Box />
				<Box />
				{data?.expert &&
					data?.expert?.expertise &&
					data?.expert?.expertise.length > 0 && (
						<Stack direction={"row"} spacing={0} sx={{width: '100%'}} style={{marginBottom:"-10px",marginTop:"-1px"}}>
							<Typography
							style={{fontSize:"10px"}}
								sx={{
									fontWeight: "600",
									textAlign: "left",
									width:"100%"
								}}>
								Expertise
							</Typography>
							<Typography
								style={{fontSize:"8px",overflow:"hidden"}}
								noWrap={true}>
								{data?.expert?.expertise.map((d, i) =>
									d !==
									data?.expert?.expertise[
										data?.expert?.expertise.length - 1
									]
										? `${d}, `
										: d
								)}
							</Typography>
						</Stack>
					)}

				{data?.expert &&
					data?.expert?.supportStage&&
					data?.expert?.supportStage.length > 0 && data?.expert?.supportStage?.map && (
					<Stack direction={"row"} spacing={0} sx={{width: '100%'}}>
						<Typography
							style={{fontSize:"10px"}}
								sx={{
									fontWeight: "600",
									textAlign: "left",
									width:"100%",
									lineHeight:"1"
								}}>
							Support Startup at Stage
						</Typography>
						<Typography style={{fontSize:"8px",overflow:"hidden"}}
								noWrap={true}>
							{/* {data?.expert?.supportStage} */}
							{data?.expert?.supportStage
							.map((d, i) =>
								d !==
								data?.expert?.supportStage[
									data?.expert?.supportStage.length - 1
								]
									? `${d}, `
									: d
							)}
						</Typography>
					</Stack>
				)}
			</Stack>
		</Item>
	</Grid>
	);
}
