import * as React from "react";

import { Avatar, Button, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { AuthUserContext } from "../../../providers/auth-provider";

export default function StartPost({ callback }) {
  const { profile } = React.useContext(AuthUserContext);

  return (
    <Stack
      sx={{
        boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
        background: "#fff",
        borderRadius: "15px",
        width: "100%",
        height: "180px",
        p: 5,
      }}
      spacing={2}
    >
      {" "}
      <Stack
        direction={"row"}
        spacing={5}
        justifyContent="space-around"
        alignItems="center"
      >
        <Avatar
          sx={{ height: 70, width: 70 }}
          imgProps={{ sx: { objectFit: "cover" } }}
          src={ profile.userType !=='startup' ? profile?.photoURL : (profile?.photoURL !== '' ? profile?.photoURL : (profile?.startup?.founder?.photoURL !== '' ? profile?.startup?.founder?.photoURL : profile?.photoURL))
            // profile.userType === 'startup' && profile?.photoURL !=='' 
            // ? profile?.photoURL
            // : profile?.startup?.founder?.photoURL !== ''
            //       ?  profile?.startup?.founder?.photoURL
            //       : profile?.photoURL
          }
                  >
        
          <Typography variant={"h4"}>
            {" "}
            {profile.userType === "startup"
              ? profile?.startup?.founder?.founderName?.charAt(0) ||
                profile?.displayName?.charAt(0)
              : profile?.displayName?.charAt(0)}
          </Typography>
        </Avatar>
        <Stack sx={{ flex: 1 }} direction={"column"} spacing={3}>
          <Button
            variant={"outlined"}
            fullWidth
            color={"secondary"}
            sx={{
              justifyContent: "left",
              py: 1.5,
              px: 3,
              textTransform: "none",
              color: "#051E40",
              borderColor: "#051E40",
            }}
            onClick={callback}
          >
            Start a post
          </Button>
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        spacing={10}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          sx={{ color: "#051E40" }}
          startIcon={
            <img
              src={"/assets/photo-icon.png"}
              style={{ width: 25, height: 18 }}
            />
          }
          onClick={callback}
        >
          Photo
        </Button>

        <Button
          sx={{ color: "#051E40" }}
          startIcon={
            <img
              src={"/assets/video-icon.png"}
              style={{ width: 25, height: 18 }}
            />
          }
          onClick={callback}
        >
          Video
        </Button>

        {/* <Button sx={{color:'#051E40'}}  
        startIcon={<img src={'/assets/person.jpeg'} style={{width: 30, height: 25}}/>}
         onClick={callback}>
          Tag
        </Button> */}

        <Button
          sx={{ color: "#051E40" }}
          startIcon={<img src={"/assets/document.png"} />}
          onClick={callback}
        >
          Document
        </Button>
      </Stack>
    </Stack>
  );
}
