import React from "react";
import {
  Button,
  Container, MenuItem,
  Stack,
  TextField, Typography, useMediaQuery,
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import PortfolioCard from "../../../components/common/portfolio-card";
import Grid from "@mui/material/Grid";
export default function PortfoliosListView({portfolios, add, cbBack, submit, edit, remove}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return(
    <Container maxWidth={"lg"}>
      <Stack direction={"column"} spacing={5} sx={{width: '100%'}}  justifyContent="center"
             alignItems="center">

        {

          portfolios.length === 0 ?
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{width: '696px', height: 252, border:'1px dashed rgba(0, 0, 0, 0.45)', marginX: 'auto', boxSizing: 'border-box15'}}
            >
              <Typography variant={'h5'} fontWeight={500} color={'#6E6E6E'}>
                <font color={'#A9ABAE'}>You have not added any</font>
                <br />Investment Portfolio<br />
                <font color={'#A9ABAE'}>items yet.</font>
              </Typography>
            </Stack> : portfolios?.length > 0 &&
            <Grid  container gap={ !matches ? 5 : 2} >
              {portfolios.map((po, i) => (
                <PortfolioCard data={po} key={i} edit={() => edit(i)} remove={() => remove(i)}/>

              ))}</Grid>
        }

         <Button variant={"contained"} color={"success"} startIcon={<AddIcon />} onClick={add}>Add Portfolio</Button>

        <span />
        <span />
        {cbBack &&   <Stack
          direction={"row"}
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          width={"100%"}>
          <Button
            variant={"outlined"}
            color={"primary"}
            sx={{ px: 5 }}
            onClick={cbBack}>
            Back
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{ px: 5 }} onClick={() => submit()}>
            Submit
          </Button>
        </Stack>}

      </Stack>
    </Container>




    /*

    <Stack direction={"column"} spacing={2}>
										<Typography sx={{ textAlign: "left" }}>
											Social Proof{" "}
											<font style={{ fontWeight: 300 }}>
												<i>
													(References for specific
													kinds of expertise)
												</i>
											</font>
										</Typography>

										<DragDropFiles
											label={{
												text: "Drop here or choose from files",
												caption:
													"(max file size: 50MB)",
											}}
											multiple={true}
											limit={50}
											types={fileTypes}
											onChangeCallback={(fileList) => {
												let list = [];
												if (files.length > 0) {
													list = [...files];
												}
												for (let file of fileList) {
													list.push({ file });
												}
												setFiles([...list]);
											}}
										/>
										<Stack
											direction={"column-reverse"}
											spacing={2}>
											{files.length > 0 &&
												files.map((file, i) => (
													<>
														<DragDropItem
															file={file}
															i={i}
															key={i}
															path={`experts/${user.uid}/social-proofs/${file.file.name}`}
														/>
														<Button
															onClick={() => {
																let path = `experts/${user.uid}/social-proofs/${file.file.name}`;
																const storage =
																	getStorage();
																const storageRef =
																	ref(
																		storage,
																		path
																	);
																deleteObject(
																	storageRef
																)
																	.then(
																		() => {
																			if (
																				files.length ===
																				1
																			) {
																				setFiles(
																					[]
																				);
																			} else {
																				setFiles(
																					(
																						current
																					) =>
																						current.filter(
																							(
																								f
																							) =>
																								f !==
																								file
																						)
																				);
																			}
																		}
																	)
																	.catch(
																		(
																			error
																		) => {
																			console.log(
																				error.message
																			);
																		}
																	);
															}}>
															<CancelIcon />
														</Button>
													</>
												))}
										</Stack>
									</Stack>


									*/
  )
}