import { useFormik, FieldArray, getIn, FormikProvider } from "formik";
import AddOfferValidations from "../../../validations/admin/offers/add-offer";
import React from "react";
import {
  Container,
  Grid,
  Stack,
  CircularProgress,
  Link,
  Badge,
  Typography,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Portal,
  Dialog,
  DialogContent,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UploadFile from "../../../utils/upload-file";
import Avatar from "@mui/material/Avatar/Avatar";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { AuthUserContext } from "../../../providers/auth-provider";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { DB } from "../../../config/firebase/firebase";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { ScrollToFieldError } from "./scroll-to-field-error";

export default function AddOffer() {
  const { offerId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [offerImage, setOfferImage] = React.useState(null);
  const { profile } = React.useContext(AuthUserContext);
  const [hiredOpen, setHiredOpen] = React?.useState(false);

  const navigator = useNavigate();
  const Input = styled("input")({
    display: "none",
  });
  
  const formik = useFormik({
    initialValues: AddOfferValidations.addOfferinitialValues,
    validationSchema: AddOfferValidations.schema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        debugger;
        await offerAddUpdate({
          ...values,
          type: "offer-to-claim",
          createdBy: profile.uid,
          offerImage: offerImage?.photoURL
            ? offerImage.photoURL
            : offerImage
            ? offerImage
            : "",
        });
        setLoading(false);
        setHiredOpen(true);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    },
  });

  React.useEffect(() => {
    if (offerId) {
      const offerRef = doc(DB, "offers", offerId);
      getDoc(offerRef).then((doc) => {
        const offer = doc.data();
        formik.setValues({
          ...offer,
        });
        debugger;
        setOfferImage(offer.offerImage);
      });
    }
  }, []);

  const offerAddUpdate = (offer) => {
    if (offerId) {
      debugger;
      const offerRef = doc(DB, "offers", offerId);
      return setDoc(
        offerRef,
        { ...offer, updatedAt: serverTimestamp() },
        { merge: true }
      );
    } else {
      const newOffer = addDoc(collection(DB, "offers"), {
        ...offer,
        createdAt: serverTimestamp(),
      });
      return newOffer;
    }
  };
  // const CreateOffer = (offer) => {
  //   debugger;
  //   const savedOffer = addDoc(collection(DB, "offers"), {
  //     ...offer,
  //     offerImage: offerImage?.photoURL ? offerImage.photoURL : "",
  //     createdAt: serverTimestamp(),
  //   });
  //   return savedOffer;
  // };
  // const handleToggleChange = (event) => {
  //   debugger;
  //   const { checked } = event.target;

  //   if (!checked) {
  //     // Reset text field values when toggle is false
  //     formik.setValues({
  //       ...formik.values,
  //       couponCode: "",
  //     });
  //   }

  //   formik.handleChange(event);
  // };
  return (
    <Container
      maxWidth={"full-width"}
      sx={{ height: "calc(100vh - 100px)", overflowY: "scroll" }}
    >
      <Stack
        direction={"row"}
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        width={"100%"}
      >
        <IconButton onClick={() => navigator(-1)}>
          <ArrowBackIcon sx={{ fontSize: 40 }} />
        </IconButton>
        {!offerId ? (
          <Typography variant={"h5"} sx={{ textAlign: "left", lineHeight: 1 }}>
            New Offer
            <br />{" "}
            <small style={{ fontSize: 14, color: "#8F8F8F" }}>
              Add offer details
            </small>
          </Typography>
        ) : (
          <Typography variant={"h5"} sx={{ textAlign: "left", lineHeight: 1 }}>
            Edit Offer
            <br />{" "}
            <small style={{ fontSize: 14, color: "#8F8F8F" }}>
              Update offer details
            </small>
          </Typography>
        )}
      </Stack>
      <Container maxWidth={"md"}>
        <Grid container>
          <Grid sx={{ width: "100%" }}>
            <FormikProvider value={formik}>
            <ScrollToFieldError/>
              <Stack
                direction="column"
                spacing={2}
                component="form"
                onSubmit={(e) => formik.handleSubmit(e)}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  sx={{ mb: 5, mx: "auto" }}
                >
                  <label htmlFor="offer-image">
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <IconButton color={"primary"} component={"span"}>
                          <AddCircleIcon />
                        </IconButton>
                      }
                    >
                      <Avatar
                        alt={"offer-picture"}
                        src={
                          offerImage && offerImage?.src
                            ? offerImage?.src
                            : offerImage
                            ? offerImage
                            : "/assets/offer-default-image.png"
                        }
                        sx={{
                          padding: "2px",
                          width: 128,
                          height: 128,
                          cursor: "pointer",
                          backgroundColor: "transparent",
                          border: "1px solid #E0E3FF",
                        }}
                        imgProps={{
                          sx: { objectFit: "scale-down" },
                        }}
                        component={"span"}
                      />
                    </Badge>
                    <Input
                      accept="image/*"
                      id="offer-image"
                      multiple={false}
                      type="file"
                      onChange={(e) => {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(e.target.files[0]);
                        fileReader.addEventListener("load", async function () {
                          setLoading(true);
                          const file = e.target.files[0];
                          const path = `offer/image/${file.name}`;
                          const imageObject = {
                            progress: 0,
                            src: this.result,
                            path,
                            file: e.target.files[0],
                            size: (
                              e.target.files[0]?.size /
                              1024 /
                              1024
                            ).toFixed(2),
                          };
                          setOfferImage(imageObject);
                          UploadFile(file, path).then((url) => {
                            setOfferImage({
                              ...imageObject,
                              photoURL: url,
                            });
                            setLoading(false);
                          });
                        });
                      }}
                    />
                  </label>
                  {loading && (
                    <CircularProgress
                      sx={{ ml: 2 }}
                      size={20}
                      color="primary"
                    />
                  )}
                  {offerImage && offerImage?.src && (
                    <Link
                      color={"secondary"}
                      onClick={() => setOfferImage(undefined)}
                    >
                      Remove picture
                    </Link>
                  )}
                </Stack>

                <Stack direction={"column"} spacing={1}>
                  <Typography
                    fontWeight={400}
                    fontSize={"16px"}
                    textAlign="start"
                  >
                    Company Name *
                  </Typography>
                  <TextField
                    fullWidth
                    name={"companyName"}
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.companyName &&
                      Boolean(formik.errors.companyName)
                    }
                    helperText={
                      formik.touched.companyName && formik.errors.companyName
                    }
                  />
                </Stack>
                <Stack direction={"column"} spacing={1}>
                  <Typography
                    fontWeight={400}
                    fontSize={"16px"}
                    textAlign="start"
                  >
                    Offer Name *
                  </Typography>
                  <TextField
                    fullWidth
                    name={"name"}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Stack>
                <Stack direction={"column"} spacing={1}>
                  <Typography
                    fontWeight={400}
                    fontSize={"16px"}
                    textAlign="start"
                  >
                    Offer Heading 1 *
                  </Typography>
                  <TextField
                    fullWidth
                    name={"headingOne"}
                    value={formik.values.headingOne}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.headingOne &&
                      Boolean(formik.errors.headingOne)
                    }
                    helperText={
                      formik.touched.headingOne && formik.errors.headingOne
                    }
                  />
                </Stack>
                <Stack direction={"column"} spacing={1}>
                  <Typography
                    fontWeight={400}
                    fontSize={"16px"}
                    textAlign="start"
                  >
                    Offer Heading 2 *
                  </Typography>
                  <TextField
                    fullWidth
                    name={"headingTwo"}
                    value={formik.values.headingTwo}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.headingTwo &&
                      Boolean(formik.errors.headingTwo)
                    }
                    helperText={
                      formik.touched.headingTwo && formik.errors.headingTwo
                    }
                  />
                </Stack>
                <Stack
                  sx={{ border: "1px solid #A9ABAE", borderRadius: "9px" }}
                  p={2}
                  direction={"column"}
                  spacing={1}
                >
                  <FieldArray
                    name={"section"}
                    render={(arrayHelpers) => {
                      return (
                        <Stack direction={"column"} spacing={2}>
                          {arrayHelpers?.form.values.section.map((p, i) => (
                            <Stack key={i} direction={"column"} spacing={2}>
                              {arrayHelpers?.form.values.section.length > 1 && (
                                <Stack
                                  direction={"row"}
                                  spacing={5}
                                  alignItems={"center"}
                                  justifyContent={"flex-end"}
                                >
                                  <Button
                                    variant={"text"}
                                    size={"small"}
                                    color={"error"}
                                    onClick={() => arrayHelpers.remove(i)}
                                    endIcon={<DeleteIcon />}
                                  ></Button>
                                </Stack>
                              )}
                              <Typography
                                fontWeight={400}
                                fontSize={"16px"}
                                textAlign="start"
                              >
                                Title
                              </Typography>
                              <TextField
                                fullWidth
                                name={`section.${i}.title`}
                                value={formik.values.section[i].title}
                                onChange={formik.handleChange}
                                error={
                                  getIn(formik.touched, `section.${i}.title`) &&
                                  getIn(formik.errors, `section.${i}.title`)
                                }
                                helperText={
                                  getIn(formik.touched, `section.${i}.title`) &&
                                  getIn(formik.errors, `section.${i}.title`) &&
                                  formik.errors.section[i].title
                                }
                              />
                              <Typography
                                fontWeight={400}
                                fontSize={"16px"}
                                textAlign="start"
                              >
                                Description
                              </Typography>
                              <TextField
                                fullWidth
                                multiline
                                paragraph={true}
                                sx={{ textAlign: "left", overflowY: "hidden" }}
                                name={`section.${i}.description`}
                                value={formik.values.section[i].description}
                                onChange={formik.handleChange}
                                error={
                                  getIn(
                                    formik.touched,
                                    `section.${i}.description`
                                  ) &&
                                  getIn(
                                    formik.errors,
                                    `section.${i}.description`
                                  )
                                }
                                helperText={
                                  getIn(
                                    formik.touched,
                                    `section.${i}.description`
                                  ) &&
                                  getIn(
                                    formik.errors,
                                    `section.${i}.description`
                                  ) &&
                                  formik.errors.section[i].description
                                }
                              />
                            </Stack>
                          ))}
                          <Box />
                          <Stack direction={"column"} alignItems={"flex-start"}>
                            <Button
                              sx={{
                                boxShadow: "none",
                              }}
                              size={"small"}
                              variant={"text"}
                              startIcon={<AddIcon />}
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add more information
                            </Button>
                          </Stack>
                        </Stack>
                      );
                    }}
                  />
                </Stack>

                <Stack direction={"row"} spacing={3}>
                  <TextField
                    fullWidth
                    type={"date"}
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      },
                      max: "2150-12-31",
                    }}
                    label={"From"}
                    InputLabelProps={{ shrink: true }}
                    name={"from"}
                    value={formik.values.from}
                    onChange={formik.handleChange}
                    error={formik.touched.from && Boolean(formik.errors.from)}
                    helperText={formik.touched.from && formik.errors.from}
                  />
                  <TextField
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      },
                      max: "2150-12-31",
                    }}
                    type={"date"}
                    label={"To"}
                    InputLabelProps={{ shrink: true }}
                    name={"to"}
                    value={formik.values.to}
                    onChange={formik.handleChange}
                    error={formik.touched.to && Boolean(formik.errors.to)}
                    helperText={formik.touched.to && formik.errors.to}
                  />
                </Stack>
                {/* <Stack
                  direction={"column"}
                  spacing={2}
                  alignItems={"flex-start"}
                >
                  <Typography fullWidth>Do you have a promo code?</Typography>
                  <FormControlLabel
                    fullWidth
                    control={
                      <Switch
                        onChange={handleToggleChange}
                        checked={formik?.values?.hasCoupon}
                        name="hasCoupon"
                      />
                    }
                  />
                </Stack> */}
                <Stack direction={"row"} spacing={2} fullWidth>
                  {/* {formik.values.hasCoupon && (
                    <> */}
                  <TextField
                    fullWidth
                    placeholder={"Coupon Code"}
                    name={"couponCode"}
                    value={formik.values.couponCode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.couponCode &&
                      Boolean(formik.errors.couponCode)
                    }
                    helperText={
                      formik.touched.couponCode && formik.errors.couponCode
                    }
                  />
                  {/* </>
                  )} */}
                  {/* <TextField
                        fullWidth
                        placeholder={"Claim Count"}
                        name={"claimCount"}
                        value={formik.values.claimCount}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.claimCount &&
                          Boolean(formik.errors.claimCount)
                        }
                        helperText={
                          formik.touched.claimCount && formik.errors.claimCount
                        }
                        type="number"
                      /> */}
                </Stack>
                <Stack direction={"column"} spacing={1}>
                  <Typography
                    fontWeight={400}
                    fontSize={"16px"}
                    textAlign="start"
                  >
                    Offer Link
                  </Typography>
                  <TextField
                    fullWidth
                    name={"offerLink"}
                    value={formik.values.offerLink}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.offerLink &&
                      Boolean(formik.errors.offerLink)
                    }
                    helperText={
                      formik.touched.offerLink && formik.errors.offerLink
                    }
                  />
                </Stack>
                <Stack direction={"column"} spacing={1}>
                  <Typography
                    fontWeight={400}
                    fontSize={"16px"}
                    textAlign="start"
                  >
                    Claim Link
                  </Typography>
                  <TextField
                    fullWidth
                    name={"claimLink"}
                    value={formik.values.claimLink}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.claimLink &&
                      Boolean(formik.errors.claimLink)
                    }
                    helperText={
                      formik.touched.claimLink && formik.errors.claimLink
                    }
                  />
                </Stack>
                <Stack
                  direction={"column"}
                  spacing={2}
                  alignItems={"flex-start"}
                  fullWidth
                >
                  <Typography fullWidth>Hot Offer?</Typography>
                  <FormControlLabel
                    fullWidth
                    control={
                      <Switch
                        name={"isHotOffer"}
                        onChange={formik?.handleChange}
                        checked={formik?.values?.isHotOffer}
                      />
                    }
                  />
                </Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"isActive"}
                      onChange={formik?.handleChange}
                      checked={formik?.values?.isActive}
                    />
                  }
                  label="Do you want this offer to be visible to users"
                />
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Button
                    variant={"outlined"}
                    sx={{ px: 2 }}
                    type={"submit"}
                    endIcon={loading ? <CircularProgress size={20} /> : ""}
                    disabled={loading}
                  >
                    Back
                  </Button>
                  <Button
                    variant={"contained"}
                    sx={{ px: 2 }}
                    type={"submit"}
                    endIcon={loading ? <CircularProgress size={20} /> : ""}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </FormikProvider>
          </Grid>
        </Grid>
        
      </Container>

      <Portal>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={hiredOpen}
          onClose={() => setHiredOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ p: 3 }}>
            <Stack
              direction={"column"}
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={"/assets/offer-created-smiley.png"}
                height={92}
                width={92}
              />
              {!offerId ? (
                <>
                  <Typography variant={"h5"} fontWeight={600}>
                    Offer Created Successfully
                  </Typography>
                  <Typography variant={"body2"} textAlign={"center"}>
                    Congratulations! Your new offer has been created
                    successfully.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant={"h5"} fontWeight={600}>
                    Offer Updated Successfully
                  </Typography>
                  <Typography variant={"body2"} textAlign={"center"}>
                    Great news! The offer has been updated successfully with{" "}
                    <br />
                    exciting new features
                  </Typography>
                </>
              )}
              <Stack direction={"row"} spacing={5}>
                <Button
                  variant={"contained"}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    setHiredOpen(false);
                    navigator(`/${profile?.userType}/offers/list`);
                  }}
                >
                  Continue
                </Button>
              </Stack>
            </Stack>
          </DialogContent>

          {/*   <DialogActions>
          <Button onClick={() => setDecline(false)}>Disagree</Button>
          <Button onClick={() => setDecline(false)} autoFocus>
            Agree
          </Button>
        </DialogActions>*/}
        </Dialog>
      </Portal>
    </Container>
  );
}
