import React, { useEffect } from "react";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Tooltip, Typography, FormControlLabel, Switch
} from "@mui/material";
import { useFormik } from "formik";
import StartupOnBoardingValidation from "../../../validations/startup/startup-profile";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';
import dataJson from '../../../const/data.json';
export default function EditStartupDetailsForm({ cbBack, cbNext, data }) {

  const formikFirst = useFormik({
    initialValues: data
      ? data
      : StartupOnBoardingValidation.EditStartupDetailsFormValues,
    validationSchema: StartupOnBoardingValidation.EditStartupDetailsForm,
    onSubmit: async (values) => {
      debugger
      cbNext({
        ...values,
      });
    }
  });
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>

          <TextField fullWidth
                     required
                     name={"startupName"}
                     value={formikFirst.values.startupName}
                     onChange={formikFirst.handleChange}
                     error={
                       formikFirst.touched.startupName &&
                       Boolean(formikFirst.errors.startupName)
                     }
                     helperText={
                       formikFirst.touched.startupName &&
                       formikFirst.errors.startupName
                     }
                     label={"Startup name"}
          />

          <Autocomplete
            multiple={false}
            required
            fullWidth
            options={[...dataJson.cities].map((option) => option)}
            freeSolo
            onChange={(e, newValue) =>
              formikFirst.setFieldValue("countryState", newValue)
            }
            disableClearable={true}
            value={formikFirst.values?.countryState}
            renderInput={(params) => (
              <TextField
                {...params}
                name={'country'}
                onChange={formikFirst?.handleChange}
                // variant={'standard'}
                error={Boolean(formikFirst.errors.countryState)}
                helperText={
                  formikFirst.errors.countryState
                    ? "Location is required"
                    : ""
                }
                label={
                  <>
                    Location*

                  </>
                }
                placeholder="Location"
                sx={{
                  transition: "0.1s linear",
                }}
              />
            )}
          />

          <TextField
            //required
            name={"description"}
            value={formikFirst.values.description}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.description &&
              Boolean(formikFirst.errors.description)
            }
            helperText={
              formikFirst.touched.description &&
              formikFirst.errors.description
            }
            label={"Tell us about yourself"}
            multiline={true}
            rows={3}
          />


        </Stack>
      </Container>
      <Stack
        direction={"row"}
        justifyContent="flex-end"
        alignItems="center"
        spacing={3}
        width={"100%"}>
        <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 5 }}
          onClick={cbBack}>
          Back
        </Button>
        {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{ px: 5 }}
          type={"submit"}>
          save
        </Button>
      </Stack>
    </Stack>
  );
}
