import React from "react";
import {
  Button,
  Container,
  Stack,
  Typography,
  IconButton,
  styled,
  useMediaQuery, Backdrop, CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import GetCitiesData from "../../../api/get-cities-data";
import InvestorFirstForm from "../../investor/onboarding-forms/investor-first-form";
import InvestorSecondForm from "../../investor/onboarding-forms/investor-second-form";
import InvestorThirdForm from "../../investor/onboarding-forms/investor-third-form";
import InvestorFourForm from "../../investor/onboarding-forms/investor-four-form";
import PortfoliosListView from "../../investor/onboarding-forms/portfolios-list-view";
import InvestorPortfolioForm from "../../investor/onboarding-forms/portfolio-form";
import InvestorOrganizationForm from "../../investor/onboarding-forms/investor-organization-form";
import PostProfileSuccessDialog from "./post-profile-success-dialog";
import InvestorCard from "./investor-card";
import {getIdToken} from "firebase/auth";
import AdminPostOnboardProfileData from "../../../api/admin-post-onboard-profile-data";
import SaveAsDraft from "../../../api/save-as-draft";
import {deleteDoc, doc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";
function valuetext(value) {
  return `$${value}`;
  
}
export default function FillInvestorProfile() {
  const Input = styled("input")({
    display: "none",
  });
  const {uid} = useParams()
  
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user } = React.useContext(AuthUserContext);
  const [formFirstData, setFormFirstData] = React.useState();
  const [formSecondData, setFormSecondData] = React.useState();
  const [formThirdData, setFormThirdData] = React.useState();
  const [formFourData, setFormFourData] = React.useState();
  const {state} = useLocation()
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isPortfolio, setPortfolio] = React.useState(false);
  const [steps, setSteps] = React.useState(0);
  

  
  
  const navigator = useNavigate();
  
  const submitData = async (values) => {
    setLoading(true)
    const {linkedinURL} = state.contactData
    const investor = {
      ...values,
      portfolio: [],
      photoURL: values?.photoURL
        ? values.photoURL
        : "",
      linkedinURL
    };
  
    const postData = {
      createdById:user?.uid,
      createdBy:'admin',
      type: state?.type,
      ...state.contactData,
      photoURL:values?.photoURL
        ? values.photoURL
        : "",
      investor,
      onboard: true,
      profileStatus: 'complete',
      remainderStatus:'',
      searchName: state.contactData?.displayName.toLowerCase()
      
    }
    const idToken = await getIdToken(user);
    await AdminPostOnboardProfileData(postData, idToken)
    setLoading(false)
    setOpen(true)
   /* try {
      debugger
      const userRef = doc(DB, "users", user.uid);
      
      const copyPortfolio = []
      if (portfolios.length > 0) {
        for (let portfolio of portfolios) {
          const attachments =[]
          if (portfolio.attachments.length > 0) {
            for (let file of portfolio.attachments) {
              const path = `investors/${user.uid}/attachments/${file.file.name}`
              attachments.push(path)
            }
          }
          copyPortfolio.push({...portfolio, attachments})
        }
      }
      const investor = {
        ...values,
        portfolio: copyPortfolio,
        photoURL: values?.photoURL
          ? values.photoURL
          : "",
      };
      await setDoc(
        userRef,
        {
          photoURL: values?.photoURL
            ? values.photoURL
            : "",
          investor,
          onboard: true,
          
          profileStatus: 'complete',
          remainderStatus:''
        },
        { merge: true }
      );
      if (formFirstData?.investorType !== 'Angel Investor') {
        const add = await addDoc(collection(DB, "organisations"),
          {
            ...formFirstData,
            uid: user?.uid,
            createdAt: serverTimestamp(),
            updatedAt:serverTimestamp()
          });
        await addDoc(collection(DB, "organisationUsers"),
          {
            organisationId: add.id,
            organisation: {...formFirstData},
            uid: user?.uid,
            createdAt: serverTimestamp(),
            updatedAt:serverTimestamp(),
            role: formSecondData?.role
          });
      }
      const getdoc = await getDoc(userRef);
      await setProfile(getdoc.data());
      navigator("/investor/timeline");
    } catch (e) {
      debugger;
      console.log(e);
    }*/
  }
  
  const cities = ['London, United Kingdom']
  const [filter, setFilter] = React.useState([...cities]);
  const [searchLoaction, setSearchLoaction] = React.useState('');
  React.useEffect(() => {
    if (searchLoaction !== '') {
      
      GetCitiesData().then((res) => {
        const te = [...new Set(res?.data?.cities)]
        setFilter([
          ...te.filter((post) => {
            if (searchLoaction !== '') {
              const reg = new RegExp(searchLoaction);
              return reg.test(post) &&  post
            }
          })
        ])
        /*debugger
        setcities(te)*/
      })
    } else {
      setFilter([...cities])
    }
  }, [searchLoaction])
  
  return (
    <>
      <Container maxWidth={'full-width'} sx={{ marginY: 5 }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}>
          <IconButton
            onClick={() =>
              isPortfolio
                ? setPortfolio(false)
                : steps === 0
                  ?navigator(uid ? `/admin/on-boarding/contact-info/${uid}`:'/admin/on-boarding/contact-info', {state}) : steps === .5 ? setSteps(steps - .5)
                    : steps === 1 && formFirstData && formFirstData?.investorType !== 'Angel Investor' ? setSteps(steps - .5)
                      : setSteps(steps - 1)
            }>
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography
            variant={"h5"}
            sx={{ textAlign: "left", lineHeight: 1, fontWeight:"600"  }}>
            
            {
              steps === 4 ? 'Final step':
                steps === 5 ? 'Investment Portfolio':
                  'Investor'
              // isPortfolio ? (
              // 	<>Investment Portfolio</>
              // ) : (
              // 	<>Investor </>
              // )
            }
            <br />{" "}
            <small style={{ fontSize: 14, color: "#8F8F8F" }}>
              { steps === 2 || steps === 3  ? 'Investment Thesis' :
                steps === 4 ? 'send profile details via email':
                  steps === 5 ? 'Add portfolio':
                    'Investor Profile'}
            </small>
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={"lg"} sx={{ mb: 5 }}>
        {steps === 0 && (
          <InvestorFirstForm
            uid={uid}
            data={formFirstData}
            cbBack={() => navigator(uid ? `/admin/on-boarding/contact-info/${uid}`:'/admin/on-boarding/contact-info', {state})}
            cbNext={(data) => {
              setFormFirstData({ ...data });
              setSteps(data?.investorType === 'Angel Investor' ? steps + 1 : steps + .5);
              uid && SaveAsDraft({...state,formFirstData: data}, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />)
          
        }
        {steps === .5 && (
          <InvestorOrganizationForm
            data={formFirstData}
            cbBack={() => setSteps(steps - .5)}
            cbNext={(data) => {
              setFormFirstData({ ...formFirstData, ...data });
              setSteps( steps + .5);
              uid && SaveAsDraft({...state,formFirstData: {...formFirstData, ...data}}, uid, user)
                .then()
            }}
            uid={uid}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData: {...formFirstData, ...data}}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />) }
        {steps === 1 && (
          <InvestorSecondForm
            uid={uid}
            isVC={formFirstData && formFirstData?.investorType !== 'Angel Investor'}
            data={formSecondData}
            cbBack={() => setSteps(formFirstData?.investorType !== 'Angel Investor' ? steps - .5 : steps -1)}
            cbNext={(data) => {
              debugger
              setFormSecondData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData, formSecondData: data}, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData, formSecondData: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />)
          
          
        }
        {steps === 2 && (
          
          <InvestorThirdForm
            uid={uid}
            data={formThirdData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormThirdData({ ...data });
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData, formSecondData, investorFormTwo : data }, uid, user)
                .then()
            }}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData, formSecondData, investorFormTwo : data }, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />
        )}
        {steps === 3 && (
          
          <InvestorFourForm
            data={formFourData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              
              setFormFourData({ ...data });
             /* submitData({
                ...formFirstData,
                ...formSecondData,
                ...formThirdData,
                ...formFourData
              }).then()*/
              setSteps(steps + 1);
              uid && SaveAsDraft({...state,formFirstData,  formSecondData,investorFormTwo: formThirdData, investorFormThree: data}, uid, user)
                .then()
            }}
            uid={uid}
            cbDraft={(data) => {
              SaveAsDraft({...state,formFirstData,  formSecondData,investorFormTwo: formThirdData, investorFormThree: data}, uid, user)
                .then(() => navigator('/admin/draft-profiles'))
  
            }}
          />
        )}
        {steps === 4  &&
        <InvestorCard
          data={{
          ...formFirstData,
          ...formSecondData,
          ...formThirdData,
          ...formFourData}}
          cbBack={() => setSteps(steps - 1)}
          send={() => {
            uid && deleteDoc(doc(DB, 'users', uid)).then()
            submitData({
              ...formFirstData,
              ...formSecondData,
              ...formThirdData,
              ...formFourData
            }).then()
          }}
        />}
  
        {
          open && <PostProfileSuccessDialog open={open} setOpen={(bool) => {
      
            setOpen(bool)
            navigator('/admin/dashboard')
          }} />
        }
        {
          loading &&
          <Backdrop  open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        
      {/*  {steps === 4 && (
          
          <PortfoliosListView
            
            cbBack={() => setSteps(steps - 1)}
            
            add={(data) => {
              setPortfolioIndex();
              setSteps(steps + 1);
            }}
            edit={(ind) => {
              setPortfolioIndex(ind);
              setSteps(steps + 1);
            }}
            remove={(ind) => {
              debugger
              const port = [...portfolios]
              port.splice(ind, 1)
              setPortfolios([...port])
            }}
            portfolios={portfolios}
            submit={() => {
              debugger
              submitData({
                ...formFirstData,
                ...formSecondData,
                ...formThirdData,
                ...formFourData
              }).then()
              console.log('submit')
            }}
          />
        )}
        {steps === 5 && (
          <InvestorPortfolioForm
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data, index) => {
              const port = [...portfolios]
              if (index !== undefined && index > -1) {
                port.splice(index, 1, { ...data})
              } else {
                port.push({ ...data })
              }
              setPortfolios([...port])
              setSteps(steps - 1);
              setPortfolioIndex(undefined);
              
            }}
            data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}
            
            index={portfolioIndex}
          />)
          
          
        }*/}
      
      </Container>
    </>
  );
}
