import {Button, Dialog, DialogContent, Portal, Stack, Typography} from "@mui/material";
import React from "react";


export default function PostProfileSuccessDialog({open, setOpen}){
  
  return(
  
    <Portal>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{p:3}}>
          <Stack direction={"column"} spacing={3} justifyContent="center"
                 alignItems="center">
            <img src={'/assets/image 33.png'} height={92} width={92}/>
            <Typography variant={"h5"} fontWeight={500}>Hooray!</Typography>
            <Typography variant={"body2"} textAlign={"center"}>Another star joins our constellation of users, igniting<br/>boundless possibilities and extraordinary<br/>connections!</Typography>
            <Stack direction={"row"} spacing={5} sx={{width: '90%'}}>
              
              <Button variant={"contained"} fullWidth onClick={() => {
                setOpen(false)
              }}>continue</Button>
            </Stack>
          </Stack>
        </DialogContent>
        
      </Dialog>
    </Portal>
    
  )
  
}