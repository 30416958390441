import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Portal, useMediaQuery, IconButton} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import EditInvestorDetailsForm from "../../../../../screens/investor/edit/edit-investor-details-form";
import {getAuth, updateProfile} from "firebase/auth";
import NavBarMobile from "../../../../../screens/mobile/topbar-mobile";
import {ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import PostNotification from "../../../../../api/post-notification";

export default function EditInvestorOrganizationDetails({profile,open, handleClose, callback }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  const regexAdmin = /admin\/organisation-profile-investor/
  const copyProfile = regexAdmin.test(window.location.pathname) ? profile : {...context.profile}
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? profile.uid : user.uid);
  const auth = getAuth();
  const [isLoading, setLoading] = React.useState(false);
  const navigator = useNavigate()
  return (
    <Portal>
      <Dialog
        fullScreen={!matches ? true: false}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >

        <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
          {!matches && <IconButton onClick={() => handleClose()}>
            <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
          </IconButton>}
          {"Edit Organization Details"}
        </DialogTitle>
        <DialogContent sx={{px: !matches ? 0 : ''}}>
          {/*<NavBarMobile sx={{position: 'absolute', width: '100%', top: 0, left:0}}/>*/}

          <br/>
          <EditInvestorDetailsForm
            data={{
              displayName: profile?.investor?.nameOfOrganization,
              description: profile?.investor?.organizationDescription,
              countryState: profile?.countryState
          }}
            cbBack={handleClose}
            cbNext={async (data) => {
              setLoading(true);
              const {displayName, description, countryState} = data
              await setDoc(
                userRef,
                {
                  investor: {...profile?.investor , nameOfOrganization: displayName, organizationDescription: description},
                  countryState
                },
                { merge: true }
              )
              if (regexAdmin.test(window.location.pathname)) {
                callback({
                  ...copyProfile,
                  investor: {...profile?.investor , nameOfOrganization: displayName, organizationDescription: description},
                  countryState})
                setLoading(false)
  
                PostNotification({
                  type: 'admin-update-profile',
                  imageURL: '/assets/system-noti-icon.png',
                  section: 'Organization',
                  message: `information has been updated by the Admin.`,
                  uid: profile.uid,
                  createdBy: user?.uid
                }).then()
  
              }
              else {
                await setProfile({
                  ...copyProfile,
                  investor: {...profile?.investor , nameOfOrganization: displayName, organizationDescription: description},
                  countryState})
                setLoading(false);
                handleClose()
                const path = `${!matches ? '/m/' : '/'}${profile?.userType}/my-organisation-profile`
                navigator(path, {state:{
                    profile:{
                      ...copyProfile,
                      investor: {...profile?.investor , nameOfOrganization: displayName, organizationDescription: description},
                      countryState},
                    organisation: {
                      organizationDescription: description,
                      investorType: profile?.investor?.investorType,
                      website: profile?.investor?.website,
                      nameOfOrganization: displayName,
                      logoURL:
                      profile?.investor?.logoURL,
                    },
                  }});
              }
           
            }}
            isLoading={isLoading}
          />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
