import * as React from "react";
import { Tabs, Tab, Box, Typography, Stack } from "@mui/material";
import SidePannelFileMessageComponent from "./side-panel/SidePannelFileMessageComponent";
import SidePannelImageMessageComponent from "./side-panel/SidePannelImageMessageComponent";
import { AuthUserContext } from "../../../providers/auth-provider";

export default function SidePanelRight({ messages }) {
  const [value, setValue] = React.useState("media");
  const { profile } = React.useContext(AuthUserContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const extractLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = text.match(urlRegex) || [];
    console.log("link", matches);
    return [...new Set(matches)];
  };

  const renderClickableLinks = (text) => {
    const links = extractLinks(text);

    if (links.length === 0) {
      return <Typography>No links found.</Typography>;
    }

    const linkSegments = links.map((link, index) => (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        key={index}
        style={{
          display: "block",
          backgroundColor: "blue",
          color: "white",
          textDecoration: "underline",
          padding: "4px 8px",
          margin: "4px",
          width: "300px",
          wordWrap: "break-word",
          borderRadius: "10px",
        }}
      >
        {link}
      </a>
    ));

    return <div>{linkSegments}</div>;
  };
  const renderLinksFromMessages = () => {
    const allLinks = messages.reduce((links, msg) => {
      if (msg?.type === "text") {
        const msgLinks = extractLinks(msg?.text);
        return [...links, ...msgLinks];
      }
      return links;
    }, []);

    const uniqueLinks = [...new Set(allLinks)];

    if (uniqueLinks.length === 0) {
      return <Typography>No links found.</Typography>;
    }

    return (
      <div>
        {uniqueLinks.map((link, index) => (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
            style={{
              display: "block",
              backgroundColor: "blue",
              color: "white",
              textDecoration: "underline",
              padding: "4px 8px",
              margin: "4px",
              width: "300px",
              wordWrap: "break-word",
              borderRadius: "10px",
            }}
          >
            {link}
          </a>
        ))}
      </div>
    );
  };

  return (
    <>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <Tab value="media" label="Media files" color="primary" />
        <Tab value="documents" label="Documents" />
        <Tab value="links" label="Links" />
      </Tabs>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexFlow: "row wrap",
        }}
        className="testmedia"
      >
        {messages.length > 0 &&
          messages.map((msg, i) => {
            if (msg?.type === "media" && value === "media") {
              if (msg?.sender === profile.uid) {
                return (
                  <SidePannelImageMessageComponent
                    key={i}
                    msg={msg}
                    type={"r"}
                  />
                );
              } else {
                return (
                  <SidePannelImageMessageComponent
                    key={i}
                    msg={msg}
                    type={"l"}
                  />
                );
              }
            }
          })}
      </div>
      {value === "links" && (
        <div
          style={{
            height: "calc(100vh - 330px)",
            width: "100%",
            overflowY: "scroll",
            paddingLeft: "5px",
          }}
        >
          <Stack spacing={2}>{renderLinksFromMessages()}</Stack>
        </div>
      )}
      {messages.length > 0 &&
        messages.map((msg, i) => {
          if (msg?.type === "document" && value === "documents") {
            if (msg?.sender === profile.uid) {
              return (
                <SidePannelFileMessageComponent key={i} msg={msg} type={"r"} />
              );
            } else {
              return (
                <SidePannelFileMessageComponent key={i} msg={msg} type={"l"} />
              );
            }
          }
        })}
    </>
  );
}
