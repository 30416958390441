import React from "react";
import {IconButton, Stack, Typography} from "@mui/material";
import EditInvestorDetails from "../../../../../components/common/profile/edit/investor/edit-investor-details";
import {EditSharp} from "@mui/icons-material";
import EditExpertDetails from "../../../../../components/common/profile/edit/expert/edit-expert-details";

export default function ExpertDescription({profile}) {
  const [open,setOpen] = React.useState(false)
  const profileRegex = /profile/
  return (
    <>
      <Stack direction={"row"} spacing={2}  justifyContent="flex-end"
             alignItems="center" sx={{width: '100%'}}>
        {profile.countryState ? <Typography variant={'body2'} color={"grey"} sx={{flex: 1}}>
          {profile.countryState}
        </Typography> : null}
        {profileRegex.test(window.location.pathname) &&  <IconButton onClick={() => setOpen(true)}>
          <EditSharp />
        </IconButton>}
      </Stack>

      {profile?.expert && profile?.expert.description &&
        <Typography variant={'body1'} color={'secondary'} sx={{width: 'calc(100% - 10%)', fontSize:'12px', whiteSpace:"pre-line"}}>

        {profile?.expert.description}
      </Typography>}

      <EditExpertDetails  profile={profile} open={open} handleClose={() => setOpen(false)}/>



    </>
  )
}