import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import {Button, Stack, Box} from "@mui/material";
import moment from "moment";
import CommentLikeMenu from "./comment/comment-like-menu";
import CommentsLikesCountCompnent from "./comment/comments-likes-count-compnent";
import CommentReplies from "./comment/comment-replies";
import {ItemMentioned} from "./item-text";
import ItemText from './item-text';
import LinkWithThumbnail from '../../../../../components/common/link-embed-thumbnail'
export default function PostCommentItem({comment, timelineId, postuid}) {
  const [user, setUser] = React.useState()
  const [commentsCount, setCommentsCount] = React.useState(0)
  const [likesCount, setLikesCount] = React.useState();
  
  const [show, setShow] = React.useState(false)
  React.useEffect(() => {
    const userRef = doc(DB, "users", comment?.uid);
    getDoc(userRef).then((doc) => setUser({
      id: doc?.id, 
      ...doc?.data()
    }));
  }, [comment?.uid])
  return (

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={user?.displayName} src={user?.photoURL} />
        </ListItemAvatar>
        <ListItemText
          primary={ <React.Fragment>
            <Typography
              variant="h6"
              color="#051E40" sx={{ fontSize: "13px", fontWeight: 500 }}
            >
              {user?.displayName}
            </Typography>
      
          </React.Fragment>}
          secondary={
            <React.Fragment>
              <Stack direction={"column"} spacing={0}>
              <Typography
                sx={{ display: 'block', marginTop:'10px',borderRadius:'5px', p:'10px', backgroundColor:'#e8eef991', fontSize:"12px"}}
                variant="body2"
                color="#333333"
              >
                               {comment?.mentioned && comment?.mentioned?.length > 0  ? <ItemMentioned post={comment} limit={0} /> : <ItemText limit={150} text={comment?.text} />}
              
              </Typography> 
              <Stack sx={{width:"100%"}}>
              <LinkWithThumbnail data={comment} limit={50} small/>
              </Stack>
              <Stack direction={"row"} spacing={-2.1} alignItems={"center"}>
                <CommentLikeMenu  data={{...comment, user}} timelineId={timelineId} postuid={postuid} likesCount={likesCount}/>
                <CommentsLikesCountCompnent data={{...comment, user}} timelineId={timelineId} postuid={postuid} cb={(data) => setLikesCount(data)}/>
                <Button style={{fontSize:"12px"}} size={"small"} color={"secondary"} sx={{color: '#8F8F8F'}} onClick={() => setShow(!show)}> Reply</Button>
                {/*<Button variant={"text"} size={"small"} sx={{color:'#8F8F8F'}}>Like</Button>
                <Button variant={"text"} size={"small"} sx={{color:'#8F8F8F'}}>Reply</Button>*/}
                {/* <Box sx={{flex:1}} /> */}
                {commentsCount > 0 && <Typography style={{fontSize:"12px",marginLeft:"-2%",marginTop:"2px"}} onClick={() => setShow(!show)} color={'#888888'} noWrap={true} paragraph={true} textAlign={"right"} pr={0}>{`${commentsCount} ${commentsCount === 1 ? "Reply" : "Replies"}`}</Typography>}
             
                <Typography variant={"caption"}>
                  {/* {comment?.createdAt?.seconds && moment(comment?.createdAt?.seconds * 1000).fromNow(true)} ago */}
                  {/*{ moment(new Date(comment?.seconds * 1000)).max(new Date())}*/}
                </Typography>
              </Stack>
              </Stack>
              {comment && <CommentReplies commentsCount={commentsCount} comment={{...comment, user}} timelineId={timelineId} postuid={postuid} key={'comment-reply' + comment?.id}
                               countCallback={(count) => {
                                 setCommentsCount(count)
                                 // setShow(count > 0)
                               }}
                               show={show}/>}
              
            </React.Fragment>
          }
        />
      </ListItem>

  );
}