import * as React from "react";
import { AuthUserContext } from "../../providers/auth-provider";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  Typography,
  Box,
  styled,
  Portal,
  Dialog,
  DialogContent,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import {
  doc,
  getDoc,
  deleteDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckIcon from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import RemainingHoursOrDate from "../../components/common/offer/offer-date-format";
import OfferClaimCard from "../../components/common/offer/offer-claim-card";

const FilledCircleCheckedIcon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 21px;
  border-radius: 50%;
  background-color: blue;
`;

export default function OfferDetails() {
  const { profile } = React.useContext(AuthUserContext);
  const [open, setOpen] = React.useState(false);
  const { offerId } = useParams();
  const [offer, setOffer] = React?.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigator = useNavigate();
  const [selectedOption, setSelectedOption] = React.useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedOption(offer && offer?.isActive ? "active" : "close");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    update(option, offer?.id);
    setSelectedOption(option);
    handleClose();
  };
  const update = async (option, offerId) => {
    const offersRef = doc(DB, "offers", offerId);
    await setDoc(
      offersRef,
      {
        isActive: option == "active" ? true : false,
        updatedAt: serverTimestamp(),
      },
      { merge: true }
    );
    offer.isActive = option == "active" ? true : false;
  };
  const deleteOffer = (offerId) => {
    debugger;
    const offerRef = doc(DB, "offers", offerId);
    deleteDoc(offerRef).then((doc) => {
      console.log(doc);
      navigator(-1);
    });
  };
  React?.useEffect(() => {
    getOffers(offerId);
  }, [offerId]);

  const getOffers = async (offerId) => {
    const offerRef = doc(DB, "offers", offerId);
    const myOffer = await getDoc(offerRef);
    await setOffer({ id: offerId, ...myOffer.data() });
    debugger;
    setSelectedOption(offer && offer?.isActive ? "active" : "close");
  };
  return (
    <>
      <Container sx={{ height: "calc(100vh - 100px)", overflowY: "scroll" }}>
        <Container>
          <Stack
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <IconButton onClick={() => navigator(-1)}>
              <ArrowBackIcon sx={{ fontSize: 40 }} />
            </IconButton>
            <Typography
              variant={"h5"}
              sx={{ textAlign: "left", lineHeight: 1 }}
            >
              {offer && offer?.companyName}
            </Typography>
            <Box flex={1} />
            {/* <Stack 
            directon="row"
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            > */}
            {profile.userType === "admin" && (
              <>
                <Button
                  sx={{ px: 5 }}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() =>
                    navigator(`/${profile.userType}/offers/edit/${offer?.id}`)
                  }
                >
                  Edit
                </Button>
                <Button
                  sx={{ px: 5 }}
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => setOpen(true)}
                >
                  Delete
                </Button>
                <IconButton
                  edge="end"
                  aria-label="menu"
                  size="large"
                  aria-controls="dropdown-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="inherit"
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="dropdown-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Typography px={2}>Status</Typography>
                  <MenuItem
                    onClick={() => handleOptionClick("active")}
                    sx={{
                      color: selectedOption === "active" ? "blue" : "inherit",
                    }}
                  >
                    Activiate{" "}
                    <ListItemIcon sx={{ marginLeft: "auto", marginRight: 0 }}>
                      {selectedOption === "active" && (
                        <FilledCircleCheckedIcon fontSize="small">
                          <CheckIcon
                            fontSize="small"
                            sx={{
                              color: "white",
                            }}
                          />
                        </FilledCircleCheckedIcon>
                      )}
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleOptionClick("close")}
                    sx={{
                      color: selectedOption === "close" ? "blue" : "inherit",
                    }}
                  >
                    Close
                    <ListItemIcon sx={{ marginLeft: "auto", marginRight: 0 }}>
                      {selectedOption === "close" && (
                        <FilledCircleCheckedIcon fontSize="small">
                          <CheckIcon
                            sx={{
                              color: "white",
                            }}
                          />
                        </FilledCircleCheckedIcon>
                      )}
                    </ListItemIcon>
                  </MenuItem>
                </Menu>
              </>
            )}
            {/* </Stack> */}
          </Stack>
        </Container>
        <Container justifyContent="flex-start" alignItems="flex-start">
          
          <Stack
            mt={4}
            direction={"row"}
            spacing={1}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Stack direction={"column"} alignItems={"flex-start"}>
              <Typography fontWeight={600}>{offer?.headingOne}</Typography>
              <Typography fontWeight={400}>{offer?.headingTwo}</Typography>
            </Stack>
            <Box flex={1}/>
            {Math.floor(
              (new Date().getTime() - new Date(offer?.from).getTime()) /
                (1000 * 60 * 60)
            ) <= 24 && ( //to check if the offer is live for 24 hours
              <Chip
                variant="contained"
                sx={{
                  my: 0,
                  backgroundColor: "#E0E3FF",
                  px: 0.5,
                  maxWidth: 125,
                  height: 24,
                  borderRadius: "5px",
                }}
                label={"New"}
              />
            )}
            {offer?.isHotOffer && (
              <IconButton color="primary" sx={{ padding: 0 }}>
                <img src={"/assets/Fire.png"} alt="Custom Icon" />
              </IconButton>
            )}
          </Stack>
          <Stack
            direction={"row"}
            pt={2}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Stack
            justifyContent="flex-start"
            alignItems="flex-start"
                        direction={"column"}
                       
                      >
                        <Typography variant={"body2"}  sx={{fontWeight: 600, mt: 1.5 }}
                        >
                          Additional links
                        </Typography>
                        <a
                         
                        href={offer?.offerLink                        }
                          target={"_blank"} rel="noreferrer"
                        >
                          Offer Link
                        </a>
                        <a
                         
                        href={offer?.claimLink                        }
                          target={"_blank"} rel="noreferrer"
                        >
                          Claim Link
                        </a>
                      </Stack>
                      <Box flex={1}></Box>
            <RemainingHoursOrDate dateString={offer?.to} />
          </Stack>
          <Stack
            direction={"column"}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {offer &&
              offer?.section &&
              offer?.section.map((sec, i) => (
                <>
                  <Stack
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    py={3}
                  >
                    <Stack
                      direction={"column"}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      py={1}
                    >
                      <Typography sx={{ textAlign: "left" }} variant={"h5"}>
                        {sec.title}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"column"}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      py={1}
                    >
                      <Typography
                        component="pre"
                        sx={{
                          textAlign: "left",
                          wordBreak: "break-word",
                          display: "inline-block",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {sec.description}
                      </Typography>
                    </Stack>
                  </Stack>
                  {i !== offer?.section?.length - 1 ? (
                    <Box
                      sx={{ borderBottom: "0.5px solid #000000;" }}
                      flex={1}
                      maxWidth={"full-width"}
                      width={"100%"}
                    ></Box>
                  ) : (
                    ""
                  )}
                </>
              ))}
          </Stack>
        </Container>
      </Container>

      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"sm"}
        >
          <DialogContent sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={2}>
              <Stack
                direction={"column"}
                sx={{ width: "100%" }}
                alignItems={"center"}
              >
                <img
                  src={"/assets/delete-confirmation.png"}
                  height={74}
                  width={80}
                ></img>
              </Stack>
              <Typography
                component="span"
                variant="h6"
                color="text.primary"
                sx={{
                  textAlign: "center",
                  flex: 1,
                  padding: 0,
                  fontWeight: 500,
                }}
              >
                Delete Offer
              </Typography>
              <Typography variant={"body2"} sx={{ textAlign: "center" }}>
                Are you sure you want to delete this offer? <br />
                This action cannot be undone.
              </Typography>
              <br />
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Button
                  variant={"outlined"}
                  sx={{
                    px: 5,
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  variant={"contained"}
                  sx={{
                    px: 5,
                    width: "100px",
                    height: "40px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    deleteOffer(offer?.id);
                    setOpen(false);
                  }}
                >
                  yes
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </>
  );
}
