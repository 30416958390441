import React from "react";
import {
  Button,
  Container, MenuItem,
  Stack,
  TextField,Switch,FormControlLabel, Typography
} from "@mui/material";
import { useFormik } from "formik";
import ExportOnboardingValidation from "../../../validations/expert/onboarding-profile";
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";


export default function InvestorDetails({ cbBack, cbNext, data, cbDraft, uid }) {
  const isAdmin = /admin/;


  const formikFirst = useFormik({
    initialValues: data
      ? data
      : ExportOnboardingValidation.InvestorDetailsFormValues,
    validationSchema:
    ExportOnboardingValidation.InvestorDetailsForm,
    onSubmit: async (values) => {

      cbNext({
        ...values
      });
    },
  });
  React.useEffect(() => {
    if (uid) {
      setTimeout(() => {
        getDoc(doc(DB, 'users', uid))
          .then((document) => {
            if (document?.data()?.investorFormOne) {
              formikFirst.setValues(document?.data()?.investorFormOne);
            }
          })
      }, 100 )
    }
    
  }, [data])
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>

        <Stack direction={"column"} spacing={5}>
        {/* {console.log(areYouAnAngelInvestor)} */}

          <Stack direction={"column"} spacing={5}>
              <Stack
                direction={"column"}
                spacing={1.5}>
                <Typography
                  variant={
                    "body2"
                  }
                  sx={{
                    textAlign:
                      "left",
                  }}>
                  Are you an Angel Investor?
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center">
                  <Typography>
                    No
                  </Typography>
                  <FormControlLabel
                    label={""}
                    control={
                      <Switch
                        name={
                          "areYouAnAngelInvestor"
                        }
                        checked={
                          formikFirst
                            .values
                            .areYouAnAngelInvestor
                        }
                        onChange={
                          formikFirst.handleChange
                        }
                        error={
                          formikFirst
                            .touched
                            .areYouAnAngelInvestor &&
                          Boolean(
                            formikFirst
                              .errors
                              .areYouAnAngelInvestor
                          )
                        }
                      />
                    }
                  />
                  <Typography>
                    Yes
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
        </Stack>


      </Container>
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems="center"
        spacing={3}
        width={"100%"}>
        {isAdmin.test(window.location.pathname) &&
          <Button
            variant={"outlined"}
            color={"primary"}
            sx={{ px: 5 }}
            onClick={() =>  cbDraft(formikFirst?.values)}>
            Save draft
          </Button>
        }
        <Button
          variant={"outlined"}
          color={"primary"}
          sx={{ px: 5 }}
          onClick={cbBack}>
          Back
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{ px: 5 }}
          type={"submit"}>
          {formikFirst.values.areYouAnAngelInvestor ? 'Next' : 'Submit'}
        </Button>
        
      </Stack>
    </Stack>
  );
}
