import * as React from "react";
import {Button, IconButton, Menu, Stack, Typography} from "@mui/material";
import {CelebrateIcon, FunnyIcon, InsightFulIcon, LikeIcon, PunchIcon} from "../../../../../utils/svg-icons";
import {DB} from "../../../../../config/firebase/firebase";
import {
  collection,
  setDoc,
  doc,
  getDoc,
  deleteDoc,
  serverTimestamp,
  query,
  orderBy,
  where,
  getDocs, updateDoc, limit, increment
} from "firebase/firestore";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {useEffect} from "react";
import PostNotification from "../../../../../api/post-notification";

export default function LikeMenu({data, likesCount}) {
  const {profile} = React.useContext(AuthUserContext);
  const [liked, setLiked] = React.useState(false)
  const [likedDoc, setLikedDoc] = React.useState()
  const colors = {
    'undefined': '#8F8F8F',
    'Like':'#233DFF',
    'Yeah!':'#F85353',
    'Celebrate':'#02C875',
    'LOL':'#FF7A00',
    'Insightful':'#CE41F6',
    'Puke':'#AAC15A'
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const like = (text) => {
    
    const colRef = doc(DB, "timelines", data?.id, "likes", profile?.uid);
    setDoc(colRef, {
      impression: text,
      createdAt: serverTimestamp()
    }, {merge: true}).then(async ()=> {
      await updateDoc(doc(DB, "timelines", data?.id), {
        'current.likes':increment(1)
      });
      setLiked(true)
      setLikedDoc({id: profile?.uid, impression: text});
      const q = query(
        collection(DB, "notifications"),
        where("uid", "==", data?.uid),
        where("postId", "==", data?.id),
        where("react", "==", text),
        orderBy("createdAt", "desc"),
        limit(1),

      );
      const notifications = await getDocs(q)
      if (notifications.size > 0) {
        await updateDoc(doc(DB, "notifications", notifications.docs[0].id), {
          displayName: `${profile?.displayName}, and ${likesCount} others`,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          imageURL: profile?.photoURL,
          read: false
        })
      } else {
        PostNotification({
          type: 'reaction-time-line-post',
          imageURL: profile?.photoURL,
          message: '',
          displayName: profile?.displayName,
          react:text,
          uid: data?.uid,
          postId:  data?.id
        }).then()
      }
      
      
    
      handleClose();
    }).catch(() => {
      debugger
    });
  }
  useEffect(() => {
    setLiked(false)
    setLikedDoc(undefined)
   let unsubs;
    unsubs =  getDoc(doc(DB, "timelines", data?.id, "likes", profile?.uid))
      .then((getdoc) => {
        if (getdoc.exists()) {
          setLiked(true);
          setLikedDoc({id : profile?.uid,...getdoc.data() })
          console.log('id', data?.id + JSON.stringify(getdoc.data()))
        }
        
      });
    return() => unsubs
  }, [data?.id])
  return(

    <>
      <Menu
        id="like-menu"
        MenuListProps={{
          'aria-labelledby': 'like-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{top: -67}}
        
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {

            borderRadius: '5px'
          },
        }}
      >
        <Stack  direction={"row"} spacing={-2.5}   >
            <Button sx={{borderRadius: '5px'}} onClick={() => {
              like('Like')
            }}>

              <Stack direction={"column"} spacing={.2} alignItems={"center"}>
                <Stack sx={{ mr: -1.5}}>
                <img src={'/assets/likes-icons-set/Like.png'} height={20}/>
                <Typography variant={"body2"} color={'primary'} sx={{fontSize:"10px"}}>Like</Typography>
              </Stack>
              </Stack>
            </Button>
            <Button sx={{borderRadius: '5px'}} onClick={() => like('Yeah!')}>

              <Stack direction={"column"} spacing={.2} alignItems={"center"}>
                <img src={'/assets/likes-icons-set/Punch.png'} height={20}/>
                <Typography variant={"body2"} color={'#F85353'}  sx={{fontSize:"10px"}}>Yeah!</Typography>
              </Stack>
            </Button>
            <Button sx={{borderRadius: '5px'}} onClick={() => like('Celebrate')}>

              <Stack direction={"column"} spacing={.2} alignItems={"center"}>
                <img src={'/assets/likes-icons-set/Celebrate.png'} height={20} />

                <Typography variant={"body2"} color={'#02C875'} sx={{fontSize:"10px"}}>Celebrate</Typography>
              </Stack>
            </Button>
            <Button sx={{borderRadius: '5px'}} onClick={() => like('LOL')}>

              <Stack direction={"column"} spacing={.2} alignItems={"center"}>
                <img src={'/assets/likes-icons-set/LOL.png'}  height={20}/>
                <Typography variant={"body2"} color={'#FF7A00'} sx={{fontSize:"10px"}} >LOL</Typography>
              </Stack>
            </Button>
            <Button sx={{borderRadius: '5px'}} onClick={() => like('Insightful')}>

              <Stack direction={"column"} spacing={.2} alignItems={"center"}>
                <img src={'/assets/likes-icons-set/Insightful.png'}  height={20}/>
                <Typography variant={"body2"} color={'#CE41F6'} sx={{fontSize:"10px"}}>Insightful</Typography>
              </Stack>
            </Button>
            <Button sx={{borderRadius: '5px'}} onClick={() => like('Puke')}>
          
              <Stack direction={"column"} spacing={.2} alignItems={"center"}>
                <img src={'/assets/likes-icons-set/Puke.png'}  height={20}/>
                <Typography variant={"body2"} color={'#AAC15A'} sx={{fontSize:"10px"}}>Puke</Typography>
              
              </Stack>
            </Button>

        </Stack>

      </Menu>
      <Button style={{fontSize:"10px"}}  startIcon={
        liked && likedDoc ?
          likedDoc?.impression === 'Like' ? 
          <img src={'/assets/likes-icons-set/Like.png'} height={24}/>
            :likedDoc?.impression === 'Punch'||likedDoc?.impression === 'Yeah!' ?  <img src={'/assets/likes-icons-set/Punch.png'} height={24}/>
              : likedDoc?.impression === 'Celebrate' ?  <img src={'/assets/likes-icons-set/Celebrate.png'} height={24}/>
                :  likedDoc?.impression === 'LOL' ?  <img src={'/assets/likes-icons-set/LOL.png'} height={24}/>
                  : likedDoc?.impression === 'Insightful' ?  <img src={'/assets/likes-icons-set/Insightful.png'} height={24}/>
                    : likedDoc?.impression === 'Puke' ?  <img src={'/assets/likes-icons-set/Puke.png'} height={24}/>
                      :<LikeIcon colorIcon={'#8F8F8F'} />
          : <LikeIcon colorIcon={'#8F8F8F'} />


        /*<LikeIcon colorIcon={colors[likedDoc?.impression]} />
        : likedDoc?.impression === 'Insightful' ? <InsightFulIcon />
          :  likedDoc?.impression === 'Funny' ? <FunnyIcon />
            :likedDoc?.impression === 'Celebrate' ? <CelebrateIcon />
              :  <PunchIcon />
                : <LikeIcon colorIcon={'#8F8F8F'} />*/

      } color={'secondary'} variant={"text"} sx={{flex: 1, borderRight: '0.5px solid #8F8F8F',color:
          liked ?  likedDoc && colors[likedDoc?.impression]:'#8F8F8F', borderRadius: '0px', borderBottomLeftRadius: '15px'}}
               id="like-button"
               aria-controls="like-menu"
               aria-expanded={open ? 'true' : undefined}
               aria-haspopup="true"
               onClick={ (event) => {
                 debugger
                 if (likedDoc) {
                 const colRef = doc(DB, "timelines", data?.id, "likes", profile?.uid);
                 deleteDoc(colRef).then(()=> {
                    updateDoc(doc(DB, "timelines", data?.id), {
                     'current.likes':increment(-1),
                     'previous.likes':increment(-1),
                     
                   }).then();
                 setLiked(false)
                 setLikedDoc(undefined);
               });
               } else {
                   handleClick(event)
               }
  
               }}
      >
        {likedDoc && likedDoc?.impression } {(likedDoc === undefined || !likedDoc || !likedDoc?.impression) && 
        <Typography
        sx={{fontSize:"13px"}}
        >Like</Typography>
         }
      </Button>
      
    </>
  )
}