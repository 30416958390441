
const firebaseConfig = {
  apiKey: "AIzaSyCHu3lKeTqE1SAB_Zf_d2Bg2oiZmR3P7bA",
  authDomain: "astranovaapp.firebaseapp.com",
  projectId: "astranovaapp",
  storageBucket: "astranovaapp.appspot.com",
  messagingSenderId: "913061072569",
  appId: "1:913061072569:web:eeb65b8388382c875c5568",
  measurementId: "G-39VPMB14QT"
};
export default firebaseConfig;
