import React from "react";
import {
	Avatar,
	Grid,
	Paper,
	Stack,
	styled,
	Typography,
	Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import { AuthUserContext } from "../../providers/auth-provider";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ListItemStartConversation from "./list-item-start-conversation";
import MemberFavourite from '../../screens/common/MemberFav'

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "center",
	backgroundColor: "#fff",
	color: theme.palette.text.secondary,
	boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
	borderRadius: "15px",
	minHeight: 348,
}));
export default function FounderListItem({ data, href }) {
	const navigator = useNavigate();
	const { profile } = React.useContext(AuthUserContext);
	

	return (
		<Grid
			item
			lg={4}
			md={4}
			sm={6}
			xs={12}

			wrap={"nowrap"}>
			<Item>
				<Stack direction={"column"} spacing={2} sx={{ width: "100%" }}
					   justifyContent="center"
					   alignItems="center"
				>
					<Stack sx={{ ml: "90%", mb:"-15%", mt:"-2.5%" }}>
						<MemberFavourite data={{ ...data, card: "founder" }}  />
					</Stack>
					<Box
					sx={{
							position: "relative",
							top:"40px",
							bottom : "20px",
							width: "280px",
							height: "200px",
							background: "#F6F8FA",
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px"
						}}
					>
					<Stack
						direction={"column"}
						spacing={2}
						justifyContent="center"
						alignItems="center" style={{cursor:"pointer"}}	onClick={() => navigator(href,  {
						state:
							{
								profile:data,
								founder: {
									...data?.startup.founder,
									linkedIn: data?.startup.founder?.linkedIn,
									website: data?.startup.founder?.website,
									twitter:data?.startup.founder?.twitter,
									repeatFounder:data?.startup.founder?.repeatFounder,

									startupName:
										data?.startup.founder?.startupName !== ""
											? data?.startup.founder?.startupName
											: data?.startup?.startupName,
									photoURL:
										data?.startup.founder.photoURL !== ""
											? data?.startup.founder.photoURL
											: data?.photoURL,
								},
							},
					})}>
						<Box
							sx={{
								border: "1px solid #204488",
								p: 0.75,
								borderRadius: "50%",
								marginTop : "-40px"
							}}>
							<Avatar
								alt={data?.startup?.founder?.founderName}
								src={data?.startup?.founder?.photoURL}
								sx={{ width: 92, height: 92 }}>
								<Typography variant={"h5"}>
									{data?.startup?.founder?.founderName &&
										data?.startup?.founder?.founderName.charAt(0)}
								</Typography>
							</Avatar>
						</Box>
						<Typography
							variant={"h6"}
							sx={{ fontWeight: 600, mb: 0, lineHeight: 1.2 }}>
							{data?.startup?.founder?.founderName} <br />
							<font size={3} style={{ fontWeight: 300 }}>
								Founder {data?.startup?.investorDetails?.areYouAnAngelInvestor && '| Investor'}
							</font>
						</Typography>
						<Box />
					</Stack>
					{
						(data.uid !== profile?.uid) ?  (
							<ListItemStartConversation data={{...data, creatorData: data}}/>

						):<Button size={'small'} variant={"outlined"} color={'secondary'} sx={{px: 2, width:120}} 
						onClick={() => navigator(href,  {
							state:
								{
									profile:data,
									founder: {
										...data?.startup.founder,
										linkedIn: data?.startup.founder?.linkedIn,
										website: data?.startup.founder?.website,
										twitter:data?.startup.founder?.twitter,
										repeatFounder:data?.startup.founder?.repeatFounder,
	
										startupName:
											data?.startup.founder?.startupName !== ""
												? data?.startup.founder?.startupName
												: data?.startup?.startupName,
										photoURL:
											data?.startup.founder.photoURL !== ""
												? data?.startup.founder.photoURL
												: data?.photoURL,
									},
								},
						})}
								>My profile</Button>
					}
					</Box>
					
					<Box />
					<Box />
					<Box />
						{
						data?.startup?.founder?.website &&
						<>
					<Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
						<Typography
						variant={"body2"}
						sx={{
							fontWeight: "bold",
							flex: 1,
							textAlign: "left",
						}}>
							Website
						</Typography>
						<Typography
						variant={"body2"}
						sx={{ width: 'fit-content', textAlign: "right" }}
						noWrap={true}>
							<a href={data?.startup?.founder?.website} style={{color: 'rgba(0, 0, 0, 0.6)'}} target={"_blank"} rel="noreferrer">{data?.startup?.founder?.website}</a>
						</Typography>
					</Stack>
							</>
							}
					<Stack direction={"row"} spacing={2} sx={{width: '100%'}}>
						<Typography
							variant={"body2"}
							sx={{
								fontWeight: "bold",
								flex: 1,
								textAlign: "left",
							}}>
							Repeat Founder
						</Typography>
						<Typography
							variant={"body2"}
							sx={{ width: 130, textAlign: "right" }}
							noWrap={true}>
							{data?.startup?.founder?.repeatFounder ? 'Yes' : 'No'}
						</Typography>
					</Stack>





				</Stack>
			</Item>
		</Grid>
	);
}
