import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Portal} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import EditInvestorDetailsForm from "../../../../../screens/investor/edit/edit-investor-details-form";
import {getAuth, updateProfile} from "firebase/auth";
import EditStartupDetailsForm from "../../../../../screens/startups/edit/edit-startup-details-form";
import PostNotification from "../../../../../api/post-notification";

export default function EditStartupDetails({profile,open, handleClose, callback }) {
  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  const regexAdmin = /admin\/user-details/
  const copyProfile = regexAdmin.test(window.location.pathname) ? profile : {...context.profile}
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? profile.uid : user.uid);
  
  const auth = getAuth();

  return (
    <Portal>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle id="alert-dialog-title">
          {"Edit startup Details"}
        </DialogTitle>
        <DialogContent>
          <br/>
          <EditStartupDetailsForm
            data={{
              startupName: profile?.startup?.startupName || '',
              description: profile?.startup?.description || '',
              countryState: profile?.startup?.location || ''
          }}
            cbBack={handleClose}
            cbNext={async (data) => {
              const {startupName, description, countryState} = data
              await setDoc(
                userRef,
                {
                  startup: {...profile?.startup , startupName, description, location:countryState},
                  updatedById:auth?.currentUser?.uid,
                  updatedBy: regexAdmin.test(window.location.pathname) ? 'admin' : 'user',
                  updatedAt: new Date().getTime(),
                },
                { merge: true }
              )
              if (!regexAdmin.test(window.location.pathname)) {
                await setProfile({
                  ...copyProfile,
                  startup: {...profile?.startup , startupName, description, location:countryState}})
                handleClose()
              } else {
                callback({
                  ...copyProfile,
                  startup: {...profile?.startup , startupName, description, location:countryState}})
                PostNotification({
                  type: 'admin-update-profile',
                  imageURL: '/assets/system-noti-icon.png',
                  section: 'Startup',
                  message: `information has been updated by the Admin.`,
                  uid: profile.uid,
                  createdBy: user?.uid
    
                }).then()
              }
              
            }}
          />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
