import * as React from 'react';

import {Button, CircularProgress} from '@mui/material';
import {
  addDoc,
  collection,
  doc, getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where
} from "firebase/firestore";
import {DB} from "../../../../config/firebase/firebase";
import { AuthUserContext } from "../../../../providers/auth-provider";
import {useNavigate} from "react-router-dom";

export default function ListItemStartConversationC({data}) {
  const { user, setRoomId, profile, setRoomData} = React.useContext(AuthUserContext);
  const navigator =  useNavigate()
  const [isLoading, setLoading] = React.useState(false)
  const create = async () => {
    setLoading(true)
      debugger
    const userType =data.creatorData.userType;
    try {
      debugger
      const q = query(
        collection(DB, "rooms"),
        where('participates', 'in', [[data?.uid, profile?.uid], [profile?.uid,data?.uid]])
      );
      debugger
      const getRoom = await getDocs(q);
      await setRoomData(null)
      await setRoomId(null)
      let room = getRoom.size > 0 ? {id: getRoom.docs[0].id} :
        await addDoc(collection(DB, "rooms"),
        {
          title: userType === 'startup' ? data.creatorData?.startup?.startupName : userType === 'service-provider'  ? data.creatorData?.serviceProvider?.displayName: data.creatorData?.displayName,
          subTitle: `${data?.displayName}, ${profile?.displayName}`,
          participates:[data?.uid, user.uid],
          uid: user?.uid,
          logo: userType === 'startup' ? data.creatorData?.startup?.logoURL : userType === 'service-provider'  ? data.creatorData?.serviceProvider?.logoURL || data.creatorData?.serviceProvider?.photoURL :  data.creatorData?.photoURL,
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
          lastMsg: {
            text: 'Please go ahead and start conversation',
            status: 'send'
          }
        });
      // await setRoomId(room.id)
      if (getRoom.size === 0) {
        let message = await addDoc(collection(DB, "messages"),
          {
            type: 'system-generated',
            text: 'Please go ahead and start conversation',
            sender:  user.uid,
            receiver: data?.uid,
            createdAt:serverTimestamp(),
            roomId: room?.id,
            read:false,
            status: 'send'
          });
        const newRoom = await getDoc(doc(DB, "rooms",  room?.id))
        
        setRoomData({id: room?.id, ...newRoom?.data()})
      } else {
        setRoomData({id: room?.id, ...getRoom?.docs[0]?.data()})
      }
      setLoading(false)
      setRoomId(room?.id)
      navigator(`/m/${profile.userType}/chatscreen1`)
    } catch (e) {
      console.log(e)
      setLoading(false)

    }

    

  }
  return (
   <Button  size={'small'} style={{fontSize:"12px"}} color={'secondary'} sx={{px: 2, width:50, backgroundColor:"#E0E3FF"}} onClick={create} endIcon= {isLoading && <CircularProgress size={10}/>}>
     CHAT
   </Button>
  );
}
