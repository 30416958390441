import React from "react";
import {
	Button,
	Container, MenuItem,
	Stack,
	TextField,
	Chip, Autocomplete, Tooltip,Typography, createFilterOptions
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useFormik } from "formik";
import ServiceProviderDetailsValidation from "../../../../validations/service-provider/provider-profile";

import CloseIcon from "@mui/icons-material/Close";
import dataJson from "../../../../const/data.json";
import { Add } from "@mui/icons-material";
const filter = createFilterOptions()
export default function ServiceProviderThirdForm({ cbBack, cbNext, data }) {


	const formikFirst = useFormik({
		initialValues: data
			? data
			: ServiceProviderDetailsValidation.ProviderThirdFormValue,
		validationSchema:
		ServiceProviderDetailsValidation.ProviderThirdForm,
		onSubmit: async (values) => {

			cbNext({
				...values
			});
		},
	});

	return (
		<Stack
			direction={"column"}
			spacing={10}
			component="form"
			onSubmit={(e) => formikFirst.handleSubmit(e)}>
			<Container maxWidth={"md"}>
				<Stack direction={"column"} spacing={2}>
					<Autocomplete
						multiple
						options={[
							"Law Firm",
							"Advertising Agency"
						].map(
							(option) => option
						)}
						value={formikFirst.values?.serviceType}
						freeSolo
						onChange={(
							e,
							newValue
						) =>
							formikFirst.setFieldValue('serviceType', newValue)
						}
						renderTags={(
							value,
							getTagProps
						) =>
							formikFirst.values?.serviceType &&
							formikFirst.values?.serviceType.length > 0
							&&	formikFirst.values?.serviceType.map(
								(
									option,
									index
								) => (
									<Chip
										variant="contained"
										sx={{
											backgroundColor:
												"#E0E3FF",
											marginLeft:
												"5px",
											marginRight:
												"5px",
											marginBottom:
												"10px",
										}}
										deleteIcon={
											<CloseIcon />
										}
										onDelete={() =>
											formikFirst.setFieldValue('serviceType',
												formikFirst.values?.serviceType.filter(
													(
														f
													) =>
														f !==
														option
												)
											)
										}
										label={
											option
										}
										{...getTagProps(
											{
												index,
											}
										)}></Chip>
								)
							)
						}

						renderInput={(
							params
						) => (
							<><Stack direction={'column'} spacing={0}  >
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Service Type *
							  </Typography>
							<TextField
								{...params}
								// variant={'standard'}
								fullWidth
								// required={formikFirst.values.serviceType.length === 0 }
								error={
									formikFirst.touched.serviceType &&
									Boolean(formikFirst.errors.serviceType)
								}

								helperText={
									formikFirst.touched.serviceType &&
									formikFirst.errors.serviceType
								}
								//label="Service Type *"
								//placeholder="Add"
								sx={{
									transition:
										"0.1s linear",
								}}
							/>
							</Stack>
							</>
						)}
					/>


						<Stack direction={'column'} spacing={2}>
							<Autocomplete
								multiple
								fullWidth
								options={[
									"Adtech",
								"Advanced manufacturing",
								"Agtech",
								"Artificial intelligence and machine learning (AI/ML)",
								"Audiotech",
								"Augmented reality (AR)",
								"Autonomous cars",
								"B2B payments",
								"Beauty",
								"Big Data",
								"Cannabis",
								"Carsharing",
								"Cleantech",
								"Climate tech",
								"Cloudtech and DevOps",
								"Construction technology",
								"Cryptocurrency and blockchain",
								"Cybersecurity",
								"Digital health",
								"Ecommerce",
								"Edtech",
								"Ephemeral content",
								"eSports",
								"Femtech",
								"Fintech",
								"Foodtech",
								"Gaming",
								"Healthtech",
								"HRtech",
								"Impact investing",
								"Industrials",
								"Infrastructure",
								"Insurtech",
								"Internet of Things (IoT)",
								"Legal tech",
								"Life sciences",
								"Lifestyles of Health and Sustainability (LOHAS) and wellness",
								"Manufacturing",
								"Marketing tech",
								"Micro-mobility",
								"Mobile",
								"Mobile commerce",
								"Mobility tech",
								"Mortgage tech",
								"Nanotechnology",
								"Oil and gas",
								"Oncology",
								"Pet tech",
								"Real estate tech",
								"Restaurant tech",
								"Ridesharing",
								"Robotics and drones",
								"Software as a service (SaaS)",
								"Space tech",
								"Supply chain technology",
								"Technology, media and telecommunications (TMT)",
								"Virtual reality (VR)",
								"Wearables and quantified self",
								"3D printing"		
								].map(
									(option) => option
								)}
								value={formikFirst.values?.sector}
								freeSolo
								onChange={(
									e,
									newValue
								) =>{
									if(newValue.length!==0)
										{const newOption = newValue.slice(-1)[0].replace("Add ", "")
										newValue.splice(-1,1,newOption)}
									  
									formikFirst.setFieldValue('sector', newValue)
								}
								}
								filterOptions={(options, params) => {
									const filtered = filter(options, params);
							
									const { inputValue } = params;
									// Suggest the creation of a new value
									const isExisting = options.some((option) => inputValue === option);
									if (inputValue !== '' && !isExisting) {
										filtered.push('Add ' + inputValue);
									}
							
									return filtered;
								  }}
								getOptionLabel={(option) => {
									// Value selected with enter, right from the input
									if (typeof option === 'string') {
									  return option
									}
									// Add "xxx" option created dynamically
									if (option.value) {
										return option.value;
									  }
									  // Regular option
									return option
								  }}
								renderOption={(props, option) => 
								<li {...props} style={{alignItems:"center"}}>
									{option.includes("Add ")
									? 
									<><Add fontSize="medium"/>{option}</> 
									: option}
									</li>}
								renderTags={(
									value,
									getTagProps
								) =>
									formikFirst.values?.sector &&
									formikFirst.values?.sector.length > 0
									&&	formikFirst.values?.sector.map(
										(
											option,
											index
										) => (
											<Chip
												variant="contained"
												sx={{
													backgroundColor:
														"#E0E3FF",
													marginLeft:
														"5px",
													marginRight:
														"5px",
													marginBottom:
														"10px",
												}}
												deleteIcon={
													<CloseIcon />
												}
												onDelete={() =>
													formikFirst.setFieldValue('sector',
														formikFirst.values?.sector.filter(
															(
																f
															) =>
																f !==
																option.value ? option.value : option
														)
													)
												}
												label={
													option.value ? option.value : option
												}
												{...getTagProps(
													{
														index,
													}
												)}></Chip>
										)
									)
								}
								renderInput={(
									params
								) => (
									<><Stack direction={'column'} spacing={0}  >
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Sector *
							  </Typography>
									<TextField
										{...params}
										// variant={'standard'}

										fullWidth
										// required={formikFirst.values.sector.length === 0 }
										error={
											formikFirst.touched.sector &&
											Boolean(formikFirst.errors.sector)
										}

										helperText={
											formikFirst.touched.sector &&
											formikFirst.errors.sector
										}
										//label="Sector *"
										//placeholder="Add"
										sx={{
											transition:
												"0.1s linear",
										}}
									/>
									</Stack>
									</>
								)}
								
								
							/>


{/* <Autocomplete
							multiple
							required
							fullWidth
							freeSolo
							options={[
								// " "
						].map((option) => option)}
							onChange={(e, newValue) =>
								formikFirst.setFieldValue(
									"additionalDetails",
									newValue
								)
							}
							value={formikFirst.values?.additionalDetails}
							renderTags={(value, getTagProps) =>
								formikFirst.values?.additionalDetails &&
								formikFirst.values?.additionalDetails.length >
									0 &&
								formikFirst.values?.additionalDetails.map(
									(option, index) => (
										<Chip
											variant="contained"
											sx={{
												backgroundColor: "#E0E3FF",
												marginLeft: "5px",
												marginRight: "5px",
												marginBottom: "10px",
												borderRadius: "10px"
											}}
											deleteIcon={<CloseIcon />}
											onDelete={() =>
												formikFirst.setFieldValue(
													"additionalDetails",
													formikFirst.values?.additionalDetails.filter(
														(f) => f !== option
													)
												)
											}
											label={option}
											{...getTagProps({
												index,
											})}></Chip>
									)
								)
							}
							renderInput={(params) => (
								<><Stack direction={'column'} spacing={0}  >
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Additional Details
							  </Typography>
								<TextField
									{...params}
									// variant={'standard'}

									//label="Additional Details"
									name={"additionalDetails"}
									//placeholder="Add"
									sx={{
										transition: "0.1s linear",
									}}
								/>
								</Stack>
								</>
							)}
						/> */}


{/* 
						<TextField
							name={"additionalDetails"}
							value={formikFirst.values.additionalDetails}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.additionalDetails &&
								Boolean(formikFirst.errors.additionalDetails)
							}
							fullWidth
							helperText={
								formikFirst.touched.additionalDetails &&
								formikFirst.errors.additionalDetails
							}
							label={"Additional details"}
						/> */}


					</Stack>
					<Stack direction={"column"} spacing={2}>
					<Autocomplete
						multiple
						required
						fullWidth
						options={[
							...dataJson.markets
						].map(
							(option) => option
						)}
						freeSolo
						onChange={(
							e,
							newValue
						) =>
							formikFirst.setFieldValue('geographies', newValue)
						}
						value={formikFirst.values?.geographies}
						renderTags={(
							value,
							getTagProps
						) =>
							formikFirst.values?.geographies &&
							formikFirst.values?.geographies.length > 0
							&&	formikFirst.values?.geographies.map(
								(
									option,
									index
								) => (
									<Chip
										variant="contained"
										sx={{
											backgroundColor:
												"#E0E3FF",
											marginLeft:
												"5px",
											marginRight:
												"5px",
											marginBottom:
												"10px",
										}}
										deleteIcon={
											<CloseIcon />
										}
										onDelete={() =>
											formikFirst.setFieldValue('geographies',
												formikFirst.values?.geographies.filter(
													(
														f
													) =>
														f !==
														option
												)
											)
										}
										label={
											option
										}
										{...getTagProps(
											{
												index,
											}
										)}></Chip>
								)
							)
						}
						renderInput={(
							params
						) => (
							<><Stack direction={'column'} spacing={0}  >
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Geographies *
										<Tooltip title="Countries and regions" arrow placement="top-start" enterTouchDelay={0}>

									<InfoIcon  fontSize="small" style={{ marginLeft:"6px",color:"#D9D9D9"}}/></Tooltip>
							  </Typography>
							<TextField
								{...params}
								// variant={'standard'}
								// label="Geographies"
								error={Boolean(formikFirst.errors.geographies)}
								helperText={
									formikFirst.errors.geographies
										? "Geography is required"
										: ""
								}
								// label={
								// 	<>
								// 		Geographies*
								// 		<Tooltip title="Countries and regions" arrow placement="top-start" enterTouchDelay={0}>

								// 		<InfoIcon color="#D9D9D9" fontSize="small" style={{ marginLeft:"6px"}}/></Tooltip>
								// 	</>
								// }

								//placeholder="Add"
								sx={{
									transition:
										"0.1s linear",
								}}
							/>
							</Stack>
							</>
						)}
					/>
					</Stack>

					<Stack direction={"column"} spacing={2}>
					<Autocomplete
						multiple
						required
						fullWidth
						options={[
							...dataJson.languages
						].map(
							(option) => option
						)}
						freeSolo
						onChange={(
							e,
							newValue
						) =>
							formikFirst.setFieldValue('languages', newValue)
						}
						value={formikFirst.values?.languages}
						renderTags={(
							value,
							getTagProps
						) =>
							formikFirst.values?.languages &&
							formikFirst.values?.languages.length > 0
							&&	formikFirst.values?.languages.map(
								(
									option,
									index
								) => (
									<Chip
										variant="contained"
										sx={{
											backgroundColor:
												"#E0E3FF",
											marginLeft:
												"5px",
											marginRight:
												"5px",
											marginBottom:
												"10px",
										}}
										deleteIcon={
											<CloseIcon />
										}
										onDelete={() =>
											formikFirst.setFieldValue('languages',
												formikFirst.values?.languages.filter(
													(
														f
													) =>
														f !==
														option
												)
											)
										}
										label={
											option
										}
										{...getTagProps(
											{
												index,
											}
										)}></Chip>
								)
							)
						}
						renderInput={(
							params
						) => (
							<><Stack direction={'column'} spacing={0}  >
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Fluent Languages *
										
							  </Typography>
							<TextField
								{...params}
								// variant={'standard'}
								// label="Languages"
								error={Boolean(formikFirst.errors.languages)}
								helperText={
									formikFirst.errors.languages
										? "Language is required"
										: ""
								}
								// label={
								// 	<>
								// 		languages*
								// 		<Tooltip title="Countries and regions" arrow placement="top-start" enterTouchDelay={0}>

								// 		<InfoIcon color="#D9D9D9" fontSize="small" style={{ marginLeft:"6px"}}/></Tooltip>
								// 	</>
								// }

								//placeholder="Add"
								sx={{
									transition:
										"0.1s linear",
								}}
							/>
							</Stack>
							</>
						)}
					/>
					</Stack>

<Stack direction={'column'} spacing={0}  >
					<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Relevant Years of Experience *
							  </Typography>
					<TextField
					// required
						name={"relevantYearOfExperience"}
						value={formikFirst.values.relevantYearOfExperience}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.relevantYearOfExperience &&
							Boolean(formikFirst.errors.relevantYearOfExperience)
						}
						fullWidth
						helperText={
							formikFirst.touched.relevantYearOfExperience &&
							formikFirst.errors.relevantYearOfExperience
						}
						//label={"Relevant Years of Experience *"}
						sx={{textAlign: 'left'}}
						type={'number'}
					/>
					</Stack>


				</Stack>
			</Container>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3}
				width={"100%"}>
				<Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
					onClick={cbBack}>
					Back
				</Button>
				<Button
					variant={"contained"}
					color={"primary"}
					sx={{ px: 5 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
					type={"submit"}>
					Next
				</Button>
			</Stack>
		</Stack>
	);
}
