import React from "react";
import {collection, getDocs, onSnapshot, orderBy, query, where, limit, startAfter, getDoc} from "firebase/firestore";
import  {DB} from "../../config/firebase/firebase";
import {Box, Button, Container, Grid, Stack, Typography, Skeleton,Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import {AuthUserContext} from "../../providers/auth-provider";
import StartupListItem from "../../components/common/startup-list-item";
import InvestorListItem from "../../components/common/investor-list-item";
import ServiceProviderListItem from "../../components/common/service-provider-list-item";
import ExpertListItem from "../../components/common/expert-list-item";
import BusinessDeveloperListItem from "../../components/common/business-developer-list-item";
import TalentListItem from "../../components/common/talent-list-item";
import FounderListItem from "../../components/common/founder-list-item";
import OrganisationListItem from "../../components/common/organisation-list-item";
import ServiceProviderInvestorListItem from "../../components/common/service-provider-investor-list-item";
import OrganisationInvestorListItem from "../../components/common/organisation-investor-list-item";
import moment from "moment";
const MemberSearch = ({array, search}) => {
  const [filteredSearch, setFilteredSearch] = React.useState([]);
  
  const {profile} = React.useContext(AuthUserContext);
  //   const [search, setSearch] =React.useState('')
  /*
   .filter((member)=>{

    if (search !== "" ) {
      const reg = new RegExp(search.toLowerCase());
       return (reg.test(member?.displayName?.toLowerCase())
       )  &&  member;
     }
     else {
       return member
     }
    
  })
  * */
  
  React.useEffect(() => {
    setFilteredSearch([...array.filter((member)=>{
  
      if (search !== "" ) {
        const reg = new RegExp(search.toLowerCase());
        return (reg.test(member?.displayName?.toLowerCase())
          // || reg.test(member?.countryState?.toString().toLowerCase())
        )  &&  member;
      }
      else {
        return member
      }
  
    }).sort((a, b) => b?.createdAt - a?.createdAt)])
  }, [array])
  
  if(filteredSearch.length === 0){
    return(
      <Stack direction={"row"} sx={{width: '100%'}} py={5}  justifyContent="center"
             alignItems="center">
        
        <Typography variant="h4" mt={10}>
          No results
        </Typography>
      </Stack>
    )
  }
  else {
    return (
      filteredSearch.map( (record) => (
        record.userType === 'startup'? <>
            <StartupListItem data={record} key={record?.id} href={`/${profile?.userType}/startup-details/${record?.id}`}/>
            {
              record?.startup?.founder &&
              <FounderListItem data={record} key={record?.id+record?.createdAt} href={`/${profile?.userType}/startup-founder-profile`}/>
              
            }
          
          </> :
          
          record.userType === 'investor' ? <>
              {record?.investor?.investorType !== 'Angel Investor' &&
                <OrganisationInvestorListItem data={record} key={record?.id+record?.createdAt} href={`/${profile?.userType}/common-organisation-profile`}/>
              }
              
              <InvestorListItem data={record} key={record?.id} href={`/${profile?.userType}/investor-details/${record?.id}`}/>
            </> :
            
            record.userType === 'expert' ? <ExpertListItem data={record} key={record?.id} href={`/${profile?.userType}/expert-details/${record?.id}`}/> :
              
              record.userType === 'talent' ? <TalentListItem data={record} key={record?.id} href={`/${profile?.userType}/talent-details/${record?.id}`}/> :
                
                record.userType === 'service-provider' ? <>
                    <ServiceProviderListItem data={record} key={record?.id} href={`/${profile?.userType}/service-provider-details/${record?.id}`}/>
                    {
                      record?.serviceProvider?.organisationType === "Organisation" && record?.serviceProvider?.organisationDetails &&
                      <OrganisationListItem data={record} key={record?.id+'organisation'} href={`/${profile?.userType}/organisation-profile`}/>
                    }
                    {
                      // record?.serviceProvider?.investorDetails?.areYouAnAngelInvestor &&
                      // <ServiceProviderInvestorListItem data={record} key={record?.id+'investor'} href={`/${profile?.userType}/service-provider-details/${record?.id}`}/>
                    }
                  </>:
                  
                  record.userType === 'business-developer' ? <BusinessDeveloperListItem data={record} key={record?.id} href={`/${profile?.userType}/business-developer-details/${record?.id}`}/> : false
      )))
    
  }
}

export const MembersList = () => {
  const [members, setMembers] = React.useState([])
  const [search, setSearch] =React.useState('')
  const[inputbtn,setinputbtn]=React.useState('');
  const[lastDoc, setLastDoc]=React.useState();
  const[loading, setLoading]=React.useState(false);
  const {profile} = React.useContext(AuthUserContext);
  const capitalizeWord = (text) => {
    if (text!== '') {
      const sentence = text;
  
      const words = sentence.split(" ");
  
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      if (sentence.split(" ").length > 1) {
        return words.join(" ");
    
      } else return words.join('');
    } else return text
  
  }

  

  const loadMore = async () => {
    setLoading(true)
    const q = query(
      collection(DB, "users"),
      where('onboard', '==', true),
      orderBy("createdAt", "desc"),
      startAfter(lastDoc), 
      limit(30)
    );
    const querySnap = await getDocs(q);
    if (querySnap.size > 0) {
      const list = [...members]
      querySnap.docs.forEach((doc) => {
        list.push({id: doc.id, ...doc.data()})
      });
      // console.log(list)
      setMembers(list)
      querySnap.size < 30
        ?  setLastDoc(undefined): setLastDoc(querySnap.docs[querySnap.docs.length-1])

    } else {
      setLastDoc(undefined)
    }
    setLoading(false)

  }
  const textSearch = async (text) => {
    setLoading(true)
    const cap = capitalizeWord(text)
    debugger
    const q = text !== '' ?query(
      collection(DB, "users"),
      where('displayName', '>=', capitalizeWord(text)),
      where('onboard', '==', true),
    ):query(
      collection(DB, "users"),
      where('onboard', '==', true),
      limit(30),
      orderBy("createdAt", "desc"),
    );
    const querySnap = await getDocs(q);
    const list = []
    if (querySnap.size > 0) {
   
      querySnap.docs.forEach((doc) => {
        list.push({id: doc.id, ...doc.data()})
      });
      // console.log(list)
      setMembers(list)
      setLastDoc(text !== '' ? undefined : querySnap.docs[querySnap.docs.length-1])
    } else {
      setMembers([...list])
      setLastDoc(undefined)
    }
    setSearch(text.replaceAll('\\', ''));
    setLoading(false)

  }
  const textSearchLoadMore = async () => {

    setLoading(true)

    const q = query(

      collection(DB, "users"),
      where('onboard', '==', true),
      where('displayName', '>=', search),
      where('displayName', '<=', search),
      startAfter(lastDoc),
    );
    const querySnap = await getDocs(q);
    if (querySnap.size > 0) {
      const list = [...members]
      querySnap.docs.forEach((doc) => {
        list.push({id: doc.id, ...doc.data()})
      });
      // console.log(list)
      setMembers(list)
      setLastDoc(undefined)
    } else {
      setLastDoc(undefined)
    }
    setLoading(false)

  }
  React.useEffect(() => {

/*
    // Query the first page of docs
    const first = query(collection(db, "cities"), orderBy("population"), limit(25));
    const documentSnapshots = await getDocs(first);

// Get the last visible document
    const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
    console.log("last", lastVisible);

// Construct a new query starting at this document,
// get the next 25 cities.
    const next = query(collection(db, "cities"),
      orderBy("population"),
      startAfter(lastVisible),
      limit(25));*/
    const q = query(
      collection(DB, "users"),
      where('onboard', '==', true),
      limit(33),
      orderBy("createdAt", "desc"),
    );
    setLoading(true)

    const unSubs = onSnapshot(q, (querySnap) => {

      debugger
      if (querySnap.size > 0) {
        const list = []
        querySnap.forEach((doc) => {
          list.push({id: doc.id, ...doc.data()})
        });
        console.log(list)
        setMembers(list)
        setLastDoc(querySnap.docs[querySnap.docs.length-1])
      }
      setLoading(false)
    });
    return () => unSubs;
  }, [])
/*  const [scrollTop, setScrollTop] = React.useState(0);

  React.useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);*/
  return(
    <>
      <Container maxWidth={'xl'}  sx={{my: 5, textAlign: 'left'}} >
        <Stack direction={'row'} spacing={5} sx={{width: '100%', mb: 5}}>
          <div style={{flex: 1}}>
            <Typography variant={'h4'} sx={{fontWeight: 700}}>Hello {profile?.displayName} &nbsp; 👋</Typography>
            <Typography variant={"body1"} color={'secondary'}>Welcome to  ASTRANOVA </Typography>
          </div>
          {/* <Tooltip
						title={<h3 style={{ color: '#E4EAF5' }}>Coming soon</h3>}
						placement="bottom"
					> */}
          <>
          <Box   onSubmit={(event) => event.preventDefault()}
            component="form"
            sx={{ p: '2px 10px', display: 'flex', alignItems: 'center', width: 337, height: 50, borderRadius: '12px',backgroundColor: '#E4EAF5' }}
            >
            <IconButton type="button" sx={{ p: '10px', }} aria-label="search"
            	onClick={(e) => textSearch(inputbtn)}
              >
              <SearchIcon sx={{ color:'#051E40BF'}}/>
            </IconButton>
            <InputBase
            onChange={(e) => setinputbtn(e.target.value)}
            value={inputbtn}
            onKeyDown={(e)=>{
              if(e.code==="Enter"){
                textSearch(inputbtn)
              }
            }}
            
            sx={{ ml: 1, flex: 1 ,color:'#051E40BF', input:{
              "&::placeholder":{
                opacity:1
              }
              } }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search post' }}
              // disabled={true}
              />
          </Box>
          </>
          {/* </Tooltip> */}
        </Stack></Container>
      <Container maxWidth={'lg'}  sx={{my: 5, textAlign: 'left'}} >
        

        <Grid container spacing={5}>
          {members.length>0 && members?.filter((member)=>{
            if (search !== "" ) {
              const regstr = `${search.toLowerCase()}|${ capitalizeWord(search)}`
              const reg = new RegExp(regstr);
              return (reg.test(member?.displayName)
              )  &&  member;
            }
            else {
              return member
            }
  
          }).length > 0 ? <>
              {members?.filter((member)=>{
                
                if (search !== "" ) {
                  const regstr = `${search.toLowerCase()}|${ capitalizeWord(search)}`
                  const reg = new RegExp(regstr);
                  return (reg.test(member?.displayName)
                  )  &&  member.hasOwnProperty("lastLoginAt");
                }
                else {
                  return member.hasOwnProperty("lastLoginAt")
                }
  
              }).sort((a, b) => b?.createdAt - a?.createdAt).map( (record) => (
                record.userType === 'startup'? <>
                    <StartupListItem data={record} key={record?.id} href={`/${profile?.userType}/startup-details/${record?.id}`}/>
                    {
                      record?.startup?.founder &&
                      <FounderListItem data={record} key={record?.id+record?.createdAt} href={`/${profile?.userType}/startup-founder-profile`}/>
        
                    }
    
                  </> :
    
                  record.userType === 'investor' ? <>
                      {record?.investor?.investorType !== 'Angel Investor' &&
                        <OrganisationInvestorListItem data={record} key={record?.id+record?.createdAt} href={`/${profile?.userType}/common-organisation-profile`}/>
                      }
        
                      <InvestorListItem data={record} key={record?.id} href={`/${profile?.userType}/investor-details/${record?.id}`}/>
                    </> :
      
                    record.userType === 'expert' ? <ExpertListItem data={record} key={record?.id} href={`/${profile?.userType}/expert-details/${record?.id}`}/> :
        
                      record.userType === 'talent' ? <TalentListItem data={record} key={record?.id} href={`/${profile?.userType}/talent-details/${record?.id}`}/> :
          
                        record.userType === 'service-provider' ? <>
                            <ServiceProviderListItem data={record} key={record?.id} href={`/${profile?.userType}/service-provider-details/${record?.id}`}/>
                            {
                              record?.serviceProvider?.organisationType === "Organisation" && record?.serviceProvider?.organisationDetails &&
                              <OrganisationListItem data={record} key={record?.id+'organisation'} href={`/${profile?.userType}/organisation-profile`}/>
                            }
                            {
                              // record?.serviceProvider?.investorDetails?.areYouAnAngelInvestor &&
                              // <ServiceProviderInvestorListItem data={record} key={record?.id+'investor'} href={`/${profile?.userType}/service-provider-details/${record?.id}`}/>
                            }
                          </>:
            
                          record.userType === 'business-developer' ? <BusinessDeveloperListItem data={record} key={record?.id} href={`/${profile?.userType}/business-developer-details/${record?.id}`}/> : false
              ))}
            </> :
          // <MemberSearch array={members} search={inputbtn}/> :
            <Stack direction={"row"} sx={{width: '100%'}} py={5}  justifyContent="center"
                   alignItems="center">
    
              <Typography variant="h4" mt={10}>
                No results
              </Typography>
            </Stack>
          }
          
          {/* {members.length > 0 &&
            members.length > 0 && members
            .filter((member) => {
              if (search !== "") {
                 const reg = new RegExp(search);
                return (reg.test(member?.displayName.toLowerCase()) 
                || reg.test(member?.location?.toString())
                || reg.test(member?.userType.toString().toLowerCase())
                // || reg.test(member?.serviceProvider.serviceType().toLowerCase())
                )  &&  member;
              } 
              else {
                return member
              }

            }).map( (record) => (
                record.userType === 'startup' ? <>
                    <StartupListItem data={record} key={record?.id} href={`/${profile?.userType}/startup-details/${record?.id}`}/>
                    {
                      record?.startup?.founder &&
                      <FounderListItem data={record} key={record?.id} href={`/${profile?.userType}/startup-founder-profile`}/>

                    }

                  </> :
                    record.userType === 'investor' ? <InvestorListItem data={record} key={record?.id} href={`/${profile?.userType}/investor-details/${record?.id}`}/> :
                        record.userType === 'expert' ? <ExpertListItem data={record} key={record?.id} href={`/${profile?.userType}/expert-details/${record?.id}`}/> :
                            record.userType === 'talent' ? <TalentListItem data={record} key={record?.id} href={`/${profile?.userType}/talent-details/${record?.id}`}/> :
                                record.userType === 'service-provider' ? <ServiceProviderListItem data={record} key={record?.id} href={`/${profile?.userType}/service-provider-details/${record?.id}`}/>:
                                    record.userType === 'business-developer' ? <BusinessDeveloperListItem data={record} key={record?.id} href={`/${profile?.userType}/business-developer-details/${record?.id}`}/> : false
            ))

          } */}
        </Grid>
        {
          loading && (<Grid container spacing={5} my={5}>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              wrap={"nowrap"}>
              <Skeleton variant="rectangular" width={357} sx={{borderRadius: '12px'}} height={348} />
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              wrap={"nowrap"}>
              <Skeleton variant="rectangular" width={357} sx={{borderRadius: '12px'}} height={348} />
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              wrap={"nowrap"}>
              <Skeleton variant="rectangular" width={357} sx={{borderRadius: '12px'}} height={348} />
            </Grid>

          </Grid>)
        }

        {lastDoc &&
          <Stack direction={"row"} sx={{width: '100%'}} py={5}  justifyContent="center"
                 alignItems="center">

            <Button size={"small"} onClick={search !== '' ? textSearchLoadMore :loadMore}>
              Load more
            </Button>
          </Stack>
        }
      </Container>

    </>
  )

}
