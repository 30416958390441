import * as React from "react";

import {
  Box,
  Stack,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider,
  Button,
  Portal,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { Support } from "@mui/icons-material";
import { AuthUserContext } from "../../providers/auth-provider";
import { USER_TYPES } from "../../utils/enums/userTypes.enum";
import {
  BUSINESS_DEVELOPER,
  USER_TYPES_TO_NAME,
} from "../../utils/enums/labels.enum";

export default function AdminSuggestedMatches({
  matches,
  moreMatches,
  suggestedUserId,
}) {
  const { user, profile } = React.useContext(AuthUserContext);
  debugger;
  const [isLoading, setLoading] = React.useState(false);
  const length = moreMatches.length > 5 ? 5 : moreMatches.length;
  console.log("length", moreMatches.length, length);
  let matchedProfiles = JSON.parse(JSON.stringify(moreMatches));
  matchedProfiles = matchedProfiles.slice(0, length);
  console.log("matched length", matchedProfiles.length);

  const [open, setOpen] = React.useState(false);
  const navigator = useNavigate();
  return (
    <>
      {matchedProfiles?.length > 0 && (
        <Stack
          position={"static"}
          sx={{
            boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
            background: "#fff",
            borderRadius: "15px",
            height: "fit-content",
            width: 230,
          }}
          direction={"column"}
          spacing={1}
        >
          <Typography variant={"h6"} align={"left"} sx={{ px: 2, pt: 1 }}>
            Suggested By Admin
          </Typography>

          <List sx={{ width: "100%", py: 0, my: 0 }}>
            {matchedProfiles?.length > 0 &&
              matchedProfiles?.map((discover, i) => (
                <>
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      px: 3,
                      backgroundColor:
                        discover.uid === suggestedUserId
                          ? "#f9f9f9"
                          : "transparent",
                    }}
                    onClick={() => {
                      let userDetails;
                      console.log(discover?.userType);
                      discover?.userType === "business-developer"
                        ? (userDetails = "business-developer-details")
                        : discover?.userType === "service-provider"
                        ? (userDetails = "service-provider-details")
                        : discover?.userType === "startup"
                        ? (userDetails = "startup-details")
                        : discover?.userType === "investor"
                        ? (userDetails = "investor-details")
                        : discover?.userType === "expert"
                        ? (userDetails = "expert-details")
                        : discover?.userType === "talent"
                        ? (userDetails = "talent-details")
                        : (userDetails = null);
                      navigator(
                        `/${profile?.userType}/${userDetails}/${discover?.uid}`
                      );
                    }}
                  >
                    <ListItemAvatar sx={{ height: 50, width: 50 }}>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          discover?.photoURL
                            ? discover?.photoURL
                            : "/assets/default-avatar.png"
                        }
                        sx={{ height: 45, width: 45 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{ maxWidth: 110, fontSize: 16 }}
                            noWrap={true}
                          >
                            {discover?.displayName}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              fontSize: 12,
                              textTransform: "capitalize",
                              minWidth: 110,
                            }}
                            variant="body2"
                            color="secondary"
                            noWrap={true}
                          >
                            {USER_TYPES_TO_NAME[discover?.userType]}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {/* {<Divider variant="fullWidth" component="li" />} */}
                </>
              ))}
          </List>

          <Button
            color={"secondary"}
            size={"small"}
            fullWidth
            sx={{ justifyContent: "left", px: 1.8, fontSize: 12 }}
            onClick={() =>
              navigator(`/${profile?.userType}/request-matches-list`, {
                state: { members: matches, adminSuggested: moreMatches },
              })
            }
          >
            View all suggestions <ArrowForwardIcon sx={{ fontSize: 16, ml: 1 }} />
          </Button>
        </Stack>
      )}
      <Portal>
        {profile && profile !== "admin" && profile?.onboard && (
          <>
            <IconButton
              sx={{ position: "fixed", bottom: "3%", right: "8%" }}
              onClick={() => setOpen(!open)}
              disabled={isLoading}
            >
              <img src={"/assets/support-icon.png"} />
            </IconButton>
          </>
        )}
      </Portal>
      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-achive"
          aria-describedby="alert-dialog-achive"
          maxWidth={"md"}
        >
          <DialogContent sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
            <Stack
              direction={"column"}
              sx={{ width: "95%" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={5}>
              <Typography
                component="span"
                variant="h5"
                color="text.primary"
                sx={{
                  textAlign: "left",
                  flex: 1,
                  px: 5,
                  fontWeight: 700,
                }}
              >
                Need help?
              </Typography>
              <Typography
                variant={"body1"}
                sx={{ lineHeight: 1.4, textAlign: "left", px: 5 }}
              >
                Whether you need help with your account, have a technical issue,
                or just need some advice, we're here to help.
                <br /> <br />
                Chat with AstraNova Support or email at{" "}
                <b>hello@astranova.io</b>.
              </Typography>
              <Stack
                direction={"column"}
                sx={{ width: "96%" }}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Button variant={"contained"} sx={{ px: 3 }}>
                  Chat
                </Button>
              </Stack>

              <Box />
            </Stack>
          </DialogContent>
        </Dialog>
      </Portal>
    </>
  );
}
