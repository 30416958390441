import * as React from "react";
import {
  Avatar,
  Stack,
  Typography,
  Box,
  ListItemButton,
  Button,
  useScrollTrigger,
  AppBar
} from "@mui/material";
import Link from "@mui/material/Link";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import { logout } from "../../config/firebase/firebase";
import { makeStyles } from "@material-ui/core/styles";
import {
  HomeIcon,
  InvestorIcon,
  StartupIcon,
  MessageIconMobi,
  NotificationIconMobi,
  OffersIcon
} from "../../utils/svg-icons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  ChatBadge,
  NotificationBadge,
} from "../../components/common/chat/chat-bedge";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
import { USER_TYPES_TO_NAME } from "../../utils/enums/labels.enum";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
}));

const NavBarMobile = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openmodal, setOpenmodal] = React.useState(false);

  // const handleClickOpenmodal = () => {
  //   setOpenmodal(true);
  // };
  const offersPattern = /offers/;
  const handleClosemodal = () => {
    setOpenmodal(false);
  };
  const signinPattern = /signin/,
    signupPattern =
      /signup|auth\/type|auth\/where-are-you-located|auth\/your-working-experiences/;
  const disablePattern =
    /\/type|\/where-are-you-located|\/your-working-experiences/;
  const location = useLocation();
  const navigator = useNavigate();
  const { user, setUser, setProfile, profile, setRoomData, setRoomId } =
    React.useContext(AuthUserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const homePattern = /timeline/;
  const startupPattern =
    /members-list|startup-details|startup-founder-profile|investor-details|expert-details|business-developer-details|open-requests-details|talent-details|service-provider-details|distribution-details/;
  const investorPattern = /investor-list|investor-details/;
  const expertPattern = /expert-list|expert-details/;
  const businessDeveloperPattern =
    /business-developer-list|business-developer-details/;
  const messagePattern = /chat|chatscreen1/;
  const morePattern =
    /service-provider-list|talent-list|open-requests|distribution-list|service-provider-details|talent-details|open-requests-details|distribution-details/;
  const openRequestsPattern = /open-requests/;
  const telentPattern = /talent-list|talent-details/;
  const serviceProviderPattern =
    /service-provider-list|service-provider-details/;
  const distributionPattern = /distribution-list|distribution-details/;
  const notificationPattern = /notification/;
  const match = useLocation();
  React.useEffect(() => {
    console.log(profile);
    console.log(user);
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };
  const HamStyle = {
    backgroundColor: "#000000",
    marginTop: "1px",
    marginBottom: "1px",
    width: "25px",
    height: "4px",
    borderRadius: "30px",
    transition: "0.25s",
  }

  return (
    <div>
      <div>
        <AppBar position={'fixed'} className={classes.root} sx={{ bgcolor: "#FFFFFF", borderBottom: "0.5px solid #D2D2D2" }}>
          <Toolbar>
            <Box>
              {
                profile?.userType === "admin" ?
                  <Link style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator(`/admin/dashboard`)
                    }
                    }
                  >
                    <img
                      src={"/assets/nova-logo.png"}
                      aria-label={"logo"}
                      style={{ height: "40px" }}
                    />
                  </Link> : profile?.userType === "startup" ? <Link style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator(`/m/${profile?.userType}/timeline`)
                    }
                    }
                  >
                    <img
                      src={"/assets/nova-logo.png"}
                      aria-label={"logo"}
                      style={{ height: "40px" }}
                    />
                  </Link>
                    : profile?.userType === "expert" ? <Link style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigator(`/m/${profile?.userType}/timeline`)
                      }
                      }
                    >
                      <img
                        src={"/assets/nova-logo.png"}
                        aria-label={"logo"}
                        style={{ height: "40px" }}
                      />
                    </Link>
                      : profile?.userType === "business-developer" ? <Link style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator(`/m/${profile?.userType}/timeline`)
                        }
                        }
                      >
                        <img
                          src={"/assets/nova-logo.png"}
                          aria-label={"logo"}
                          style={{ height: "40px" }}
                        />
                      </Link>
                        : profile?.userType === "service-provider" ? <Link style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigator(`/m/${profile?.userType}/timeline`)
                          }
                          }
                        >
                          <img
                            src={"/assets/nova-logo.png"}
                            aria-label={"logo"}
                            style={{ height: "40px" }}
                          />
                        </Link>
                          : profile?.userType === "investor" ? <Link style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigator(`/m/${profile?.userType}/timeline`)
                            }
                            }
                          >
                            <img
                              src={"/assets/nova-logo.png"}
                              aria-label={"logo"}
                              style={{ height: "40px" }}
                            />
                          </Link>
                            : (match.pathname !== '/m/auth/splashscreen' &&
                              <img
                                src={"/assets/nova-logo.png"}
                                aria-label={"logo"}
                                style={{ height: "40px" }}
                              />)
              }
            </Box>
            <div style={{ flex: 1 }} />
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >

              {profile?.userType && profile?.onboard && <IconButton onClick={() => {
                setRoomData(null);
                setRoomId(null);
                navigator(`/m/${profile?.userType}/chat`);
              }}>


                <ChatBadge>
                  <MessageIconMobi colorIcon={'#000000'}></MessageIconMobi>
                </ChatBadge>

              </IconButton>}

              <Stack sx={{ cursor: "pointer", mr: 2 }} onClick={toggleDrawer(true)}>
                <Box sx={drawerOpen ? { ...HamStyle, transform: "translate(0, 7px) rotate(-45deg)" } : HamStyle} />
                <Box sx={drawerOpen ? { ...HamStyle, opacity: "0" } : HamStyle} />
                <Box sx={drawerOpen ? { ...HamStyle, transform: "translate(0, -5px) rotate(45deg)" } : HamStyle} />
              </Stack>
            </Stack>
            {/* <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          >
          <MenuIcon />
        </IconButton> */}
          </Toolbar>
        </AppBar>
        {/* <hr style={{opacity:0.3, width:"100%"}}/> */}
        <div style={{ marginBottom: messagePattern.test(window.location.pathname) ? 20 : 90 }} />
      </div>


      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Stack
          direction={"row"}
          justifyContent="flex-end"
          alignItems="center"
          py={1.5}
          sx={{ width: "300px !important" }}
        >
          <CloseIcon
            sx={{ marginRight: "10px" }}
            onClick={toggleDrawer(false)}
          />
        </Stack>
        {profile && profile.onboard && (
          <Box
            sx={{
              background: "#F6F8FA",
              height: "95px",
              boxShadow: "0px 0px 6.8135px -1.04823px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
              marginBottom: "50px",
              marginX: "10px",
            }}
          >
            <Avatar
              alt={user.displayName}
              src={user.photoURL}
              key={user.photoURL}
              sx={{
                width: 70,
                height: 70,
                marginTop: "55px",
                marginLeft: "15px",
              }}
              onClick={() => {
                setDrawerOpen(open);

                navigator(`/m/${profile?.userType}/profile`);
              }}
            >
              <Typography variant={"h5"}>
                {user.displayName && user.displayName.charAt(0)}
              </Typography>
            </Avatar>

          </Box>
        )}
        {
          profile?.userType === "admin" ?
            <Link style={{
              marginLeft: "6%",
              border: "1px solid #233DFF",
              color: "white",
              backgroundColor: "#233DFF",
              borderRadius: "5px",
              // width: "85px",
              width: "fit-content",
              fontSize: "12px",
              textDecoration: "none",
              // paddingLeft: "3px",
              display: "inline-block",
              textAlign: "center",
              padding: "5px 10px"
            }} onClick={() => {
              setDrawerOpen(open);

              navigator(`/m/${profile?.userType}/profile`);
            }}>View Profile <span><b>

            </b>
              </span></Link> : profile?.userType === "startup" ? <Link style={{
                marginLeft: "6%",
                border: "1px solid #233DFF",
                color: "white",
                backgroundColor: "#233DFF",
                borderRadius: "5px",
                // width: "85px",
                width: "fit-content",
                fontSize: "12px",
                textDecoration: "none",
                // paddingLeft: "3px",
                display: "inline-block",
                textAlign: "center",
                padding: "5px 10px"
              }} onClick={() => {
                setDrawerOpen(open);

                navigator(`/m/${profile?.userType}/profile`);
              }}>View Profile <span><b>

              </b>
                </span></Link>
              : profile?.userType === "expert" ? <Link style={{
                marginLeft: "6%",
                border: "1px solid #233DFF",
                color: "white",
                backgroundColor: "#233DFF",
                borderRadius: "5px",
                // width: "85px",
                width: "fit-content",
                fontSize: "12px",
                textDecoration: "none",
                // paddingLeft: "3px",
                display: "inline-block",
                textAlign: "center",
                padding: "5px 10px"
              }} onClick={() => {
                setDrawerOpen(open);

                navigator(`/m/${profile?.userType}/profile`);
              }}>View Profile <span><b>

              </b>
                </span></Link>
                : profile?.userType === "business-developer" ? <Link style={{
                  marginLeft: "6%",
                  border: "1px solid #233DFF",
                  color: "white",
                  backgroundColor: "#233DFF",
                  borderRadius: "5px",
                  // width: "85px",
                  width: "fit-content",
                  fontSize: "12px",
                  textDecoration: "none",
                  // paddingLeft: "3px",
                  display: "inline-block",
                  textAlign: "center",
                  padding: "5px 10px"
                }} onClick={() => {
                  setDrawerOpen(open);

                  navigator(`/m/${profile?.userType}/profile`);
                }}>View Profile <span><b>

                </b>
                  </span></Link>
                  : profile?.userType === "service-provider" ? <Link style={{
                    marginLeft: "6%",
                    border: "1px solid #233DFF",
                    color: "white",
                    backgroundColor: "#233DFF",
                    borderRadius: "5px",
                    // width: "85px",
                    width: "fit-content",
                    fontSize: "12px",
                    textDecoration: "none",
                    // paddingLeft: "3px",
                    display: "inline-block",
                    textAlign: "center",
                    padding: "5px 10px"
                  }} onClick={() => {
                    setDrawerOpen(open);

                    navigator(`/m/${profile?.userType}/profile`);
                  }}>View Profile <span><b>

                  </b>
                    </span></Link>
                    : profile?.userType === "investor" ? <Link style={{
                      marginLeft: "6%",
                      border: "1px solid #233DFF",
                      color: "white",
                      backgroundColor: "#233DFF",
                      borderRadius: "5px",
                      // width: "85px",
                      width: "fit-content",
                      fontSize: "12px",
                      textDecoration: "none",
                      // paddingLeft: "3px",
                      display: "inline-block",
                      textAlign: "center",
                      padding: "5px 10px"
                    }} onClick={() => {
                      setDrawerOpen(open);

                      navigator(`/m/${profile?.userType}/profile`);
                    }}>View Profile <span><b>

                    </b>
                      </span></Link>
                      : ""
        }


        <List sx={{ fontWeight: 700, pl: 0.5, py: 5, fontFamily: "Poppins" }}>
          {!user ? (
            <>
              <ListItemButton
                onClick={() => {
                  setDrawerOpen(open);

                  navigator("/m/auth/signin");
                }}
              >
                <Typography variant={"body"} fontWeight={500}>
                  Sign In
                </Typography>
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setDrawerOpen(open);
                  navigator("/m/auth/where-are-you-located");
                }}
              >

                <Typography variant={"body"} fontWeight={500}>
                  Sign Up
                </Typography>

              </ListItemButton>
            </>
          ) : profile && profile !== "admin" ? (
            profile?.onboard && (
              <>
                <ListItemButton
                  onClick={() => {
                    setDrawerOpen(open);
                    navigator(`/m/${profile?.userType}/timeline`);
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <HomeIcon
                      color={"primary"}
                      colorIcon={
                        homePattern.test(window.location.pathname)
                          ? "#233DFF"
                          : "#A9ABAE"
                      }
                    ></HomeIcon>
                    <Typography
                      variant={"body"}
                      sx={{
                        color: homePattern.test(window.location.pathname)
                          ? "#233DFF"
                          : "#A9ABAE",
                        fontWeight: 400,
                      }}
                    >
                      Home
                    </Typography>
                  </Stack>
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setDrawerOpen(open);
                    navigator(`/m/${profile?.userType}/open-requests`);
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <InvestorIcon
                      color={"primary"}
                      colorIcon={
                        openRequestsPattern.test(window.location.pathname)
                          ? "#233DFF"
                          : "#A9ABAE"
                      }
                    ></InvestorIcon>
                    <Typography
                      variant={"body"}
                      sx={{
                        color: openRequestsPattern.test(
                          window.location.pathname
                        )
                          ? "#233DFF"
                          : "#A9ABAE",
                        fontWeight: 400,
                      }}
                    >
                      Marketplace
                    </Typography>
                  </Stack>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    setDrawerOpen(open);
                    navigator(`/m/${profile?.userType}/offers/list`);
                  }}
                >

                  <Stack
                    direction={"row"}
                    justifyContent="start"
                    alignItems="start"
                    spacing={2}
                    sx={{ ml: -0.1 }}
                  >
                    <OffersIcon
                      color={"primary"}
                      colorIcon={
                        offersPattern.test(
                          window.location.pathname
                        )
                          ? "#233DFF"
                          : "#A9ABAE"
                      }></OffersIcon>
                    <Typography
                      variant={"body"}
                      sx={{
                        color: offersPattern.test(
                          window.location.pathname
                        )
                          ? "#233DFF"
                          : "#A9ABAE",
                        fontWeight: 400,
                      }}>Offers
                    </Typography>
                  </Stack>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    // navigator(`/m/${profile?.userType}/open-requests`)
                    setDrawerOpen(open);
                    navigator(`/m/${profile?.userType}/members-list`);
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <StartupIcon
                      color={"primary"}
                      colorIcon={
                        startupPattern.test(window.location.pathname)
                          ? "#233DFF"
                          : "#A9ABAE"
                      }
                    ></StartupIcon>
                    <Typography
                      variant={"body"}
                      sx={{
                        color: startupPattern.test(window.location.pathname)
                          ? "#233DFF"
                          : "#A9ABAE",
                        fontWeight: 400,
                      }}
                    >
                      Members
                    </Typography>
                  </Stack>
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    // navigator(`/m/${profile?.userType}/open-requests`)
                    setDrawerOpen(open);
                    navigator(`/m/${profile?.userType}/notification`);
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  // sx={{ marginLeft: "-10px" }}
                  >
                    <NotificationBadge>
                      <NotificationIconMobi
                        NotificationIcon
                        color={"primary"}
                        colorIcon={
                          notificationPattern.test(window.location.pathname)
                            ? "#233DFF"
                            : "#A9ABAE"
                        }
                        style={{ width: "100px" }}
                      >




                      </NotificationIconMobi>
                    </NotificationBadge>
                    <Typography
                      variant={"body"}
                      sx={{
                        color: notificationPattern.test(
                          window.location.pathname
                        )
                          ? "#233DFF"
                          : "#A9ABAE",
                        fontWeight: 400,
                      }}
                    >
                      Notification
                    </Typography>
                  </Stack>
                </ListItemButton>

              </>
            )
          ) : (
            <></>
          )}
        </List>

        <div style={{ flex: 1 }} />


        {user && (

          <List>
            {/* <ListItemButton
              onClick={() => {
                setDrawerOpen(open);

                navigator(`/m/${profile?.userType}/profile`);
              }}
            >
              <ListItemText
                primary={
                  <Typography variant={"body"} fontWeight={500}>
                    My profile
                  </Typography>
                }
              />
            </ListItemButton> */}
            {profile?.userType && profile?.onboard && <ListItemButton
              sx={{ width: 300 }}
              style={{
                color: "black",
                marginTop: "10px",

              }} onClick={() => {
                setDrawerOpen(open);
                navigator(`/m/${profile?.userType}/my-favorites`)
              }}

            >
              <Typography variant={"body"} fontWeight={400} >
                My favorites
              </Typography>

            </ListItemButton>}
            {profile?.userType && profile?.onboard && <ListItemButton
              onClick={() => {
                setDrawerOpen(open);

                navigator(`/m/${profile?.userType}/settings`);
              }}
            >
              <Typography variant={"body"} fontWeight={400} >
                Setting
              </Typography>
            </ListItemButton>}
            <ListItemButton>
              <Typography variant={"body"} fontWeight={400} >
                <a
                  onClick={() => setDrawerOpen(open)}
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://astranova.io/terms-of-use/"
                  target="_blank" rel="noreferrer"
                >
                  Terms of Service
                </a>
              </Typography>
            </ListItemButton>
            <ListItemButton>
              <Typography variant={"body"} fontWeight={400}>
                <a
                  onClick={() => setDrawerOpen(open)}
                  style={{ textDecoration: "none", color: "black" }}
                  href="https://astranova.io/privacy-policy/"
                  target="_blank" rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </Typography>
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                navigator("/m/auth/signin");
                logout().then(() => {
                  setDrawerOpen(open);
                  handleClose();
                  setUser(null);
                  setProfile(null);
                  navigator("/m/auth/signin");
                });
              }}
            >
              <Typography variant={"body"} fontWeight={400}>
                Sign out
              </Typography>
            </ListItemButton>
          </List>
        )}

        {/*  {!user ? (
          <>

            <Box
              sx={{
                height: "115px",
              }}
            >
              <Stack direction="column" sx={{ paddingTop: "30px" }}>
                <MenuItem
                  style={{
                    color: "black",
                    marginTop: "10px",
                  }}
                  onClick={() => navigator("/m/auth/signin")}
                >
                  Sign In
                </MenuItem>
                <MenuItem
                  cstyle={{ color: "black" }}
                  onClick={() => navigator("/m/auth/where-are-you-located")}
                >
                  Sign Up
                </MenuItem>
              </Stack>
            </Box>
          </>
        ) : profile && profile.userType === "admin" ? (
          <>
          <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <CloseIcon
                    sx={{ marginRight: "10px" }}
                    fontSize="large"
                    onClick={toggleDrawer(false)}
                  />
                </Stack>
            <Box
                  sx={{
                    background: "#F6F8FA",
                    margin: "30px !important",
                    height: "115px",
                    boxShadow:
                      "0px 0px 6.8135px -1.04823px rgba(0, 0, 0, 0.15)",
                    borderRadius: "15px",
                    marginBottom: "64px",
                  }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={user.photoURL}
                    key={user.photoURL}
                    sx={{
                      width: 92,
                      height: 92,
                      marginTop: "64px",
                      marginLeft: "32px",
                    }}
                    
                  >
                    <Typography variant={"h5"} >
                      {user.displayName && user.displayName.charAt(0)}
                    </Typography>
                  </Avatar>
                </Box>
                <div style={{ flex: 1 }} />
            <Stack
              direction="column"
              sx={{ paddingLeft: "30px", paddingTop: "30px" }}
            >
              <MenuItem
                style={{
                  color: "black",
                  marginTop: "10px",
                }}
                onClick={() => {
                  navigator(`/m/${profile?.userType}/settings`);
                }}
              >
                Settings
              </MenuItem>
              <MenuItem
                cstyle={{ color: "black" }}
                onClick={handleClickOpenmodal}
              >
                Terms of Services & Privacy Policy
              </MenuItem>

              <MenuItem
                sx={{
                  marginBottom: "5px",
                  color: "black",
                }}
                onClick={() => {
                  navigator("/m/auth/signin");
                  logout().then(() => {
                    handleClose();
                    setUser(null);
                    setProfile(null);
                    navigator("/m/auth/signin");
                  });
                }}
              >
                Sign Out
              </MenuItem>
            </Stack>
          </>
        ) : (
          <>
            {profile && profile?.onboard && (
              <Stack direction={"column"} spacing={4}>
                <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <CloseIcon
                    sx={{ marginRight: "10px" }}
                    fontSize="large"
                    onClick={toggleDrawer(false)}
                  />
                </Stack>

                <Box
                  sx={{
                    background: "#F6F8FA",
                    margin: "30px !important",
                    height: "115px",
                    boxShadow:
                      "0px 0px 6.8135px -1.04823px rgba(0, 0, 0, 0.15)",
                    borderRadius: "15px",
                    marginBottom: "64px",
                  }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={user.photoURL}
                    key={user.photoURL}
                    sx={{
                      width: 92,
                      height: 92,
                      marginTop: "64px",
                      marginLeft: "32px",
                    }}
                    onClick={() => {
                    navigator(`/m/${profile?.userType}/implementing-functionality`);
                       
                    }}>
                  
                    <Typography variant={"h5"}>
                      {user.displayName && user.displayName.charAt(0)}
                    </Typography>
                  </Avatar>
                </Box>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ paddingLeft: "30px", paddingTop: "30px" }}
                >
                  <Button
                    color={"secondary"}
                    variant={"text"}
                    sx={{ px: 2 }}
                    onClick={() => navigator(`/m/${profile?.userType}/timeline`)}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <HomeIcon
                        color={"primary"}
                        colorIcon={
                          homePattern.test(window.location.pathname)
                            ? "#233DFF"
                            : "#A9ABAE"
                        }
                      ></HomeIcon>
                      <Typography
                        variant={"body"}
                        sx={{
                          color: homePattern.test(window.location.pathname)
                            ? "#233DFF"
                            : "#A9ABAE",
                          fontWeight: 400,
                        }}
                      >
                        Home
                      </Typography>
                    </Stack>
                  </Button>

                  <Button
                    color={"secondary"}
                    variant={"text"}
                    sx={{ px: 2 }}
                    onClick={() =>
                      navigator(`/m/${profile?.userType}/open-requests`)
                     //navigator(`/m/${profile?.userType}/implementing-functionality`)

                    }
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <InvestorIcon
                        color={"primary"}
                        colorIcon={
                          openRequestsPattern.test(window.location.pathname)
                            ? "#233DFF"
                            : "#A9ABAE"
                        }
                      ></InvestorIcon>
                      <Typography
                        variant={"body"}
                        sx={{
                          color: openRequestsPattern.test(
                            window.location.pathname
                          )
                            ? "#233DFF"
                            : "#A9ABAE",
                          fontWeight: 400,
                        }}
                      >
                        Marketplace
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    color={"secondary"}
                    variant={"text"}
                    sx={{ px: 2 }}
                    onClick={() =>
                      navigator(`/m/${profile?.userType}/members-list`)
                     // navigator(`/m/${profile?.userType}/implementing-functionality`)
                    }
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <StartupIcon
                        color={"primary"}
                        colorIcon={
                          startupPattern.test(window.location.pathname)
                            ? "#233DFF"
                            : "#A9ABAE"
                        }
                      ></StartupIcon>
                      <Typography
                        variant={"body"}
                        sx={{
                          color: startupPattern.test(window.location.pathname)
                            ? "#233DFF"
                            : "#A9ABAE",
                          fontWeight: 400,
                        }}
                      >
                        Members
                      </Typography>
                    </Stack>
                  </Button>

                  <Button
                    color={"secondary"}
                    variant={"text"}
                    sx={{ px: 2 }}
                    onClick={() => navigator(`/m/${profile?.userType}/chat`)}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      sx={{ marginLeft: "-10px" }}
                    >
                      <Stack
                        direction={"row"}
                        spacing={1}
                        sx={{ width: "100%" }}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <ChatBadge />
                      </Stack>
                      <MessageIcon
                        color={"primary"}
                        colorIcon={
                          messagePattern.test(window.location.pathname)
                            ? "#233DFF"
                            : "#A9ABAE"
                        }
                      ></MessageIcon>
                      <Typography
                        variant={"body"}
                        sx={{
                          color: messagePattern.test(window.location.pathname)
                            ? "#233DFF"
                            : "#A9ABAE",
                          fontWeight: 400,
                        }}
                      >
                        Message
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    color={"secondary"}
                    variant={"text"}
                    sx={{ px: 2 }}
                    onClick={() =>
                      navigator(`/m/${profile?.userType}/notification`)
                    }
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      sx={{ marginLeft: "-10px" }}
                    >
                      <Stack
                        direction={"row"}
                        spacing={1}
                        sx={{ width: "100%" }}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <NotificationBadge />
                      </Stack>
                      <NotificationIcon
                        color={"primary"}
                        colorIcon={
                          notificationPattern.test(window.location.pathname)
                            ? "#233DFF"
                            : "#A9ABAE"
                        }
                      ></NotificationIcon>
                      <Typography
                        variant={"body"}
                        sx={{
                          color: notificationPattern.test(
                            window.location.pathname
                          )
                            ? "#233DFF"
                            : "#A9ABAE",
                          fontWeight: 400,
                        }}
                      >
                        Notification
                      </Typography>
                    </Stack>
                  </Button>
                </Stack>
              </Stack>
            )}

            <div style={{ flex: 1 }} />
            <Stack
              direction="column"
              sx={{ paddingLeft: "30px", paddingTop: "30px" }}
            >
              <MenuItem
                sx={{ width: 380 }}
                style={{
                  color: "black",
                  marginTop: "10px",
                }}
                onClick={() => {
                  navigator(`/m/${profile?.userType}/settings`);
                }}
              >
                Settings
              </MenuItem>
              <MenuItem
                sx={{ width: 380 }}
                cstyle={{ color: "black" }}
                // onClick={handleClickOpenmodal}
              >
                	<a style={{textDecoration:'none' , color:'black'}}
									href="https://astranova.io/terms-of-use/" target="_blank">
									Terms of Service</a>
              </MenuItem>
              <MenuItem
                sx={{ width: 380 }}
                cstyle={{ color: "black" }}
                // onClick={handleClickOpenmodal}
              >
               <a style={{textDecoration:'none' , color:'black'}}
								href="https://astranova.io/privacy-policy/" target="_blank">
								Privacy Policy</a>
              </MenuItem>

              <MenuItem
                sx={{
                  width: 380,
                  marginBottom: "5px",
                  color: "black",
                }}
                onClick={() => {
                  navigator("/m/auth/signin");
                  logout().then(() => {
                    handleClose();
                    setUser(null);
                    setProfile(null);
                    navigator("/m/auth/signin");
                  });
                }}
              >
                Sign Out
              </MenuItem>
            </Stack>
          </>
        )}*/}
      </Drawer>
      <Dialog open={openmodal} onClose={handleClosemodal}>
        <DialogActions
          style={{
            border: "10px solid #233DFF",
            borderBottom: "none",
          }}
        >
          <IconButton
            style={{ marginRight: "10px" }}
            onClick={handleClosemodal}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent
          style={{
            border: "10px solid #233DFF",
            borderTop: "none",
            marginTop: "-53px",
          }}
        >
          <DialogContentText>
            <Typography
              variant="h6"
              style={{ fontWeight: "700", color: "black" }}
            >
              Terms of Service
            </Typography>
            <Typography style={{ color: "black", fontSize: "15px" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum. Sed
              ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
              velit, sed quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
              veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur.
            </Typography>
            <br></br>
            <Typography
              variant="h6"
              style={{ fontWeight: "700", color: "black" }}
            >
              Privacy Policy
            </Typography>
            <Typography style={{ color: "black", fontSize: "15px" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum. Sed
              ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
              velit, sed quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
              veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NavBarMobile;
