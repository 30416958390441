import React from "react";
import {
    Avatar,
    Badge,
    Button, CircularProgress,
    Container, IconButton, Input, Link, MenuItem,
    Stack, styled,
    TextField, Typography
} from "@mui/material";
import { useFormik } from "formik";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFile from "../../../utils/upload-file";
import {AuthUserContext} from "../../../providers/auth-provider";
import ServiceProviderDetailsValidation from "../../../validations/service-provider/provider-profile";
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";

export default function ServiceProviderOrganisationForm({ cbBack, cbNext, data, isVC, cbDraft, uid }) {
    const isAdmin = /admin/;
    
    const Input = styled("input")({
        display: "none",
    });
    const { user } = React.useContext(AuthUserContext);

    const [profileImage, setProfileImage] = React.useState({
        src: data && data?.logoURL !== "" ? data?.logoURL : null,
        logoURL:data &&  data?.logoURL !== '' ? data?.logoURL : null,
    });
    const [loading, setLoading] = React.useState(false);
    const formikFirst = useFormik({
        initialValues: data
            ? data
            : {...ServiceProviderDetailsValidation.ProviderOrganizationFormValues},
        validationSchema:
        ServiceProviderDetailsValidation.ProviderOrganizationForm,
        onSubmit: async (values) => {
            await formikFirst.setFieldValue(
                "logoURL",
                profileImage?.logoURL ? profileImage?.logoURL : ""
            );
            cbNext({
                ...values,
                logoURL: profileImage?.logoURL ? profileImage?.logoURL : "",
            });
        },
    });
    React.useEffect(() => {
        if (uid) {
            setTimeout(() => {
                getDoc(doc(DB, 'users', uid))
                  .then((document) => {
                      if (document?.data()?.formOrganisationData) {
                          formikFirst.setValues(document?.data()?.formOrganisationData);
                          setProfileImage({
                              src: document?.data() && document?.data()?.formOrganisationData?.logoURL !== "" ? document?.data()?.formOrganisationData?.logoURL : null,
                              logoURL:document?.data() &&  document?.data()?.formOrganisationData?.logoURL !== '' ? document?.data()?.formOrganisationData?.logoURL : null,
                          })
                      }
                  })
            }, 500 )
        }
        
    }, [data])

    return (
        <Stack
            direction={"column"}
            spacing={10}
            component="form"
            onSubmit={(e) => formikFirst.handleSubmit(e)}>
            <Container maxWidth={"md"}>
                <Stack direction={"column"} spacing={5}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        sx={{ mb: 5 }}>
                        <label htmlFor="profile-image">
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                badgeContent={
                                    <IconButton
                                        color={"primary"}
                                        component={"span"}>
                                        <AddCircleIcon />
                                    </IconButton>
                                }>
                                <Avatar
                                    alt={"organisation-picture"}
                                    src={
                                        profileImage && profileImage?.src
                                            ? profileImage?.src
                                            :
                                            "/assets/startup-placeholder-image.png"
                                    }
                                    sx={{
                                        width: 128,
                                        height: 128,
                                        cursor: "pointer",
                                        backgroundColor: "transparent",
                                        border: "1px solid #E0E3FF",
                                    }}
                                    imgProps={{
                                        sx: { objectFit: "cover" },
                                    }}
                                    component={"span"}
                                />
                            </Badge>
                            <Typography fontWeight={500}
                                          textAlign="center"
                                            style={{ fontSize: 14, color: "#8F8F8F" }}
                                          sx={{marginBottom:"4px"}}> Upload Logo</Typography>
                            <Input
                                accept="image/*"
                                id="profile-image"
                                multiple={false}
                                type="file"
                                onChange={(e) => {
                                    const fileReader = new FileReader();
                                    fileReader.readAsDataURL(e.target.files[0]);
                                    fileReader.addEventListener(
                                        "load",
                                        async function () {
                                            setLoading(true);
                                            const file = e.target.files[0];
                                            const path = isAdmin.test(window.location.pathname) ? `admin/${user.uid}/service-providers/organisations/logo/${file.name}` : `service-providers/organisations/${user.uid}/logo/${file.name}`;
                                            const imageObject = {
                                                progress: 0,
                                                src: this.result,
                                                path,
                                                file: e.target.files[0],
                                                size: (
                                                    e.target.files[0]?.size /
                                                    1024 /
                                                    1024
                                                ).toFixed(2),
                                            };
                                            setProfileImage(imageObject);
                                            UploadFile(file, path).then(
                                                (url) => {
                                                    setProfileImage({
                                                        ...imageObject,
                                                        logoURL: url,
                                                    });
                                                    setLoading(false);
                                                }
                                            );
                                        }
                                    );
                                }}
                            />
                        </label>
                        {loading && (
                            <CircularProgress
                                sx={{ ml: 2 }}
                                size={20}
                                color="primary"
                            />
                        )}
                        {profileImage && profileImage?.src && (
                            <Link
                                color={"secondary"}
                                onClick={() => setProfileImage(undefined)}>
                                Remove picture
                            </Link>
                        )}
                    </Stack>
                    <Stack direction={'column'}>
                        <Typography
                            fontWeight={400}
                            fontSize={"16px"}
                            textAlign="start"
                            sx={{marginBottom:"4px"}}
                        >Service Provider Type
                        </Typography>
                        <TextField
                            // required
                            name={"organisationType"}
                            value={formikFirst.values.organisationType = 'Organisation'}
                            onChange={formikFirst.handleChange}
                            error={
                                formikFirst.touched.organisationType &&
                                Boolean(formikFirst.errors.organisationType)
                            }
                            helperText={
                                formikFirst.touched.organisationType &&
                                formikFirst.errors.organisationType
                            }
                            //label={"Service Provider Type"}
                            select
                            disabled
                            sx={{textAlign: 'left'}}
                        >
                            <MenuItem value={'Organisation'} >
                                Organisation
                            </MenuItem>
                        </TextField>
                    </Stack>
                    <Stack direction={'column'}>
                        <Typography
                            fontWeight={400}
                            fontSize={"16px"}
                            textAlign="start"
                            sx={{marginBottom:"4px"}}
                        >Name of organisation *
                        </Typography>
                        <TextField
                            // required={formikFirst?.values?.organisationType === 'Organisation'}
                            name={"nameOfOrganization"}
                            value={formikFirst.values.nameOfOrganization}
                            onChange={formikFirst.handleChange}
                            error={
                                formikFirst.touched.nameOfOrganization &&
                                Boolean(formikFirst.errors.nameOfOrganization)
                            }
                            helperText={
                                formikFirst.touched.nameOfOrganization &&
                                formikFirst.errors.nameOfOrganization
                            }
                            //label={"Name of organisation *"}
                        />
                    </Stack>
                    <Stack direction={'column'}
                    >
                        <Typography
                            fontWeight={400}
                            fontSize={"16px"}
                            textAlign="start"
                            sx={{marginBottom:"4px"}}
                        >Website
                        </Typography>
                        <TextField
                            name={"website"}
                            value={formikFirst.values.website}
                            onChange={formikFirst.handleChange}
                            error={
                                formikFirst.touched.website &&
                                Boolean(formikFirst.errors.website)
                            }
                            helperText={
                                formikFirst.touched.website &&
                                formikFirst.errors.website
                            }
                            //label={"Website"}
                        />
                    </Stack>
                    <Stack direction={'column'}
                    >
                        <Typography
                            fontWeight={400}
                            fontSize={"16px"}
                            textAlign="start"
                            sx={{marginBottom:"4px"}}
                        >Description of Organisation *
                        </Typography>
                        <TextField
                            // required
                            name={"organizationDescription"}
                            value={formikFirst.values.organizationDescription}
                            onChange={formikFirst.handleChange}
                            error={
                                formikFirst.touched.organizationDescription &&
                                Boolean(formikFirst.errors.organizationDescription)
                            }
                            helperText={
                                formikFirst.touched.organizationDescription &&
                                formikFirst.errors.organizationDescription
                            }
                            //label={"Description of Organisation *"}
                            multiline={true}
                            rows={3}
                        />
                    </Stack>
                </Stack>
            </Container>
            <Stack
                direction={"row"}
                justifyContent="center"
                alignItems="center"
                spacing={3}
                width={"100%"}>
                {isAdmin.test(window.location.pathname) &&  <Button
                  variant={"outlined"}
                  color={"primary"}
                  sx={{ px: 5 }}
                  onClick={() => cbDraft({...formikFirst?.values,
                      logoURL: profileImage?.logoURL ? profileImage?.logoURL : ''})}>
                    Save draft
                </Button>}
                <Button
                    variant={"outlined"}
                    color={"primary"}
                    sx={{ px: 5 }}
                    onClick={cbBack}>
                    Back
                </Button>
                
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={{ px: 5 }}
                    type={"submit"}>
                    Next
                </Button>
               
               
            </Stack>
        </Stack>
    );
}
