import React from "react";
import {
  Avatar,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../providers/auth-provider";
import { USER_TYPES_TO_NAMEC } from "../../../utils/enums/labels.enum";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  backgroundColor: "#fff",
  color: theme.palette.text.secondary,
  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
  borderRadius: "15px",
  minHeight: 348,
}));
export default function ApplicantCard({ data, key, request, href }) {
  const navigator = useNavigate();
  const { profile } = React.useContext(AuthUserContext);

  return (
    <Grid item lg={4} md={4} sm={6} xs={12} wrap={"nowrap"}>
      <Item>
        <Stack
          direction={"column"}
          spacing={2}
          sx={{ width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              position: "relative",
              top: "40px",
              bottom: "20px",
              width: "280px",
              height: "200px",
              background: "#F6F8FA",
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
            }}
          >
            <Stack
              direction={"column"}
              spacing={2}
              justifyContent="center"
              style={{ cursor: "pointer" }}
              alignItems="center"
              onClick={() => navigator(href)}
            >
              <Box
                sx={{
                  border: "1px solid #204488",
                  p: 0.75,
                  borderRadius: "50%",
                  marginTop: "-40px",
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={data?.photoURL}
                  sx={{ width: 92, height: 92 }}
                >
                  <Typography variant={"h5"}>
                    {data?.displayName && data?.displayName.charAt(0)}
                  </Typography>
                </Avatar>
              </Box>
              <Typography
                variant={"h6"}
                sx={{ fontWeight: 600, mb: 0, lineHeight: 1.2 }}
              >
                {data?.displayName} <br />
                <font size={3} style={{ fontWeight: 300 }}>
                  {USER_TYPES_TO_NAMEC[data.userType]}
                </font>
              </Typography>

              <Box />
            </Stack>
            <Button
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              sx={{ px: 2, width: 120 }}
              onClick={() => navigator(href)}
            >
              My Profile
            </Button>
          </Box>

          <Box />
          <Box />
          <Box />
        </Stack>
      </Item>
    </Grid>
  );
}
