import * as React from "react";
import Typography from "@mui/material/Typography/Typography";
import Stack from "@mui/material/Stack/Stack";
import moment from "moment/moment";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { DB } from "../../../../config/firebase/firebase";
import { AuthUserContext } from "../../../../providers/auth-provider";
import { Avatar, Badge, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Circle } from "@mui/icons-material";
import { stringAvatar, stringToColor } from "../../../../utils/avatarGenerator";
export default function NotificationItem({ notification }) {
  const { profile } = React.useContext(AuthUserContext);
  const navigator = useNavigate();

  React.useEffect(() => {
    debugger;
    if (
      notification &&
      notification.uid === profile?.uid &&
      notification.read === false
    ) {
      const msgRef = doc(DB, "notifications", notification.id);
      setDoc(
        msgRef,
        {
          read: true,
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      ).then();
    }
  }, [notification]);

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={1.5}
      >
        <Avatar
          src={notification?.imageURL}
          alt={"System notification"}
          sx={{ border: "1px solid #ccc", height: 45, width: 45 }}
        />
        <Stack
          direction={"column"}
          color={"secondary"}
          sx={{ flex: 2, fontSize: "12px" }}
        >
          <div>
            {notification?.type === "admin-update-profile" && (
              <>
                <b style={{ fontWeight: 600 }}>{`${notification?.section}`}</b>
                &nbsp;{notification?.message}
                <Button
                  variant={"text"}
                  sx={{ fontSize: 12, color: "#233DFF" }}
                  size={"small"}
                  onClick={() => navigator(`/m/${profile?.userType}/profile`)}
                >
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: 600,
                      cursor: "pointer",
                      color: "#0000FF",
                    }}
                  >
                    {" "}
                    Click to View
                  </span>
                </Button>
              </>
            )}

            {/*  for all notifications */}
            {notification?.message !== "" &&
              (notification?.type !== "reaction-time-line-post" ||
                notification?.type !== "post-comment-notification" ||
                notification?.type !== "mentioned-comment" ||
                notification?.type !== "mentioned-social-post") &&
              notification?.type !== "admin-update-profile" && (
                <>
                  {!(
                    notification?.type === "decline-applicant" ||
                    notification?.type === "accept-applicant"
                  ) && (
                    <b
                      style={{ fontWeight: 600 }}
                    >{`${profile?.displayName}`}</b>
                  )}
                  &nbsp;
                  {notification?.message}
                  {notification?.type === "unread-message" && (
                    <Button
                      variant={"text"}
                      sx={{ fontSize: 12, color: "#233DFF" }}
                      size={"small"}
                      onClick={() => navigator(`/m/${profile?.userType}/chat`)}
                    >
                      <span
                        style={{
                          textDecoration: "underline",
                          fontWeight: 600,
                          cursor: "pointer",
                          color: "#0000FF",
                        }}
                      >
                        {" "}
                        Click to View
                      </span>
                    </Button>
                  )}
                </>
              )}
            {/* for post engagement */}
            {notification?.message === "" &&
              (notification?.type === "reaction-time-line-post" ||
                notification?.type === "post-comment-notification") && (
                <>
                  {notification?.type === "post-comment-notification" ? (
                    <>
                      <b
                        style={{ fontWeight: 600 }}
                      >{`${notification?.displayName}`}</b>
                      &nbsp; commented on your post. &nbsp;
                      <Button
                        variant={"text"}
                        sx={{ fontSize: 12, color: "#233DFF" }}
                        size={"small"}
                        onClick={() =>
                          navigator(
                            `/m/${profile?.userType}/post-details/${notification?.postId}`
                          )
                        }
                      >
                        <span
                          style={{
                            textDecoration: "underline",
                            fontWeight: 600,
                            cursor: "pointer",
                            color: "#0000FF",
                          }}
                        >
                          {" "}
                          Click to View
                        </span>
                      </Button>
                    </>
                  ) : (
                    <>
                      <b
                        style={{ fontWeight: 600 }}
                      >{`${notification?.displayName} `}</b>
                      &nbsp; reacted with &nbsp;
                      {notification?.react === "Like" ? (
                        <img
                          src={"/assets/likes-icons-set/Like.png"}
                          height={20}
                          width={20}
                        />
                      ) : notification?.react === "Punch" ||
                        notification?.react === "Yeah!" ? (
                        <img
                          src={"/assets/likes-icons-set/Punch.png"}
                          height={20}
                          width={20}
                        />
                      ) : notification?.react === "Celebrate" ? (
                        <img
                          src={"/assets/likes-icons-set/Celebrate.png"}
                          height={20}
                          width={20}
                        />
                      ) : notification?.react === "LOL" ? (
                        <img
                          src={"/assets/likes-icons-set/LOL.png"}
                          height={20}
                          width={20}
                        />
                      ) : notification?.react === "Insightful" ? (
                        <img
                          src={"/assets/likes-icons-set/Insightful.png"}
                          height={20}
                          width={20}
                        />
                      ) : (
                        <img
                          src={"/assets/likes-icons-set/Puke.png"}
                          height={20}
                          width={20}
                        />
                      )}
                      &nbsp;
                      <b style={{ fontWeight: 600 }}>{notification?.react}</b>
                      &nbsp;
                      {`on your post. `}
                      <Button
                        variant={"text"}
                        sx={{ fontSize: 12, color: "#233DFF" }}
                        size={"small"}
                        onClick={() =>
                          navigator(
                            `/m/${profile?.userType}/post-details/${notification?.postId}`
                          )
                        }
                      >
                        <span
                          style={{
                            textDecoration: "underline",
                            fontWeight: 600,
                            cursor: "pointer",
                            color: "#0000FF",
                          }}
                        >
                          {" "}
                          Click to View{" "}
                        </span>
                      </Button>
                    </>
                  )}
                </>
              )}
            {notification?.message === "" &&
              notification?.type === "mentioned-comment" && (
                <>
                  <b
                    style={{ fontWeight: 600 }}
                  >{`${notification?.displayName}`}</b>
                  &nbsp;tagged you in a comment.
                  <Button
                    variant={"text"}
                    sx={{ fontSize: 12, color: "#233DFF" }}
                    size={"small"}
                    onClick={() =>
                      navigator(
                        `/m/${profile?.userType}/post-details/${notification?.postId}`
                      )
                    }
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        fontWeight: 600,
                        cursor: "pointer",
                        color: "#0000FF",
                      }}
                    >
                      {" "}
                      Click to View
                    </span>
                  </Button>
                </>
              )}
            {/* for mentioned in social post*/}
            {notification?.message === "" &&
              notification?.type === "mentioned-social-post" && (
                <>
                  <b
                    style={{ fontWeight: 600 }}
                  >{`${notification?.displayName}`}</b>
                  &nbsp;tagged you in a social post.
                  <Button
                    variant={"text"}
                    sx={{ fontSize: 12, color: "#233DFF" }}
                    size={"small"}
                    onClick={() =>
                      navigator(
                        `/m/${profile?.userType}/post-details/${notification?.postId}`
                      )
                    }
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        fontWeight: 600,
                        cursor: "pointer",
                        color: "#0000FF",
                      }}
                    >
                      {" "}
                      Click to View
                    </span>
                  </Button>
                </>
              )}
            {/* for application received*/}

            {notification?.type === "apply-applicant" && (
              <>
                <Button
                  variant={"text"}
                  color="secondary"
                  style={{
                    color: "#233DFF",
                    textDecoration: "underline",
                    fontWeight: 500,
                    fontSize: 12,
                    cursor: "pointer",
                  }}
                  size={"small"}
                  onClick={() =>
                    navigator(
                      `/m/${profile.userType}/applicants/${notification?.request}`
                    )
                  }
                >
                  View Now
                </Button>
              </>
            )}
            {/* for profile matches */}

            {notification?.type === "request-profile-matches" && (
              <>
                <Button
                  variant={"text"}
                  sx={{ fontSize: 12, fontWeight: 500 }}
                  color="secondary"
                  size={"small"}
                  onClick={() =>
                    navigator(`/m/${profile?.userType}/request-matches-list`, {
                      state: { members: notification?.matches },
                    })
                  }
                >
                  {`${notification.matches[0].displayName} and ${
                    notification.matches.length > 1
                      ? `${notification.matches.length} others`
                      : `${notification.matches.length} other`
                  }.`}
                  &nbsp;
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: 600,
                      cursor: "pointer",
                      color: "#0000FF",
                    }}
                  >
                    {" "}
                    Click to View
                  </span>
                </Button>
              </>
            )}
            {notification?.type === "suggest-request" && (
              <>
                <Button
                  variant={"text"}
                  sx={{ fontSize: 12, color: "#233DFF" }}
                  size={"small"}
                  onClick={() =>
                    navigator(
                      `/m/${profile.userType}/open-requests-details/${notification?.request}`
                    )
                  }
                >
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: 600,
                      cursor: "pointer",
                      color: "#0000FF",
                    }}
                  >
                    {" "}
                    Click to View
                  </span>
                </Button>
              </>
            )}
            {notification?.type === "accept-suggest-request" && (
              <>
                <Button
                  variant={"text"}
                  sx={{ fontSize: 12, color: "#233DFF" }}
                  size={"small"}
                  onClick={() =>
                    navigator(
                      `/m/${profile.userType}/open-requests-details/${notification?.request}?suggestedUserId=${notification.suggestedUserId}`
                    )
                  }
                >
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: 600,
                      cursor: "pointer",
                      color: "#0000FF",
                    }}
                  >
                    {" "}
                    Click to View
                  </span>
                </Button>
              </>
            )}
          </div>
          <Typography variant={"caption"} fontSize={10} color={"secondary"}>
            {moment(notification?.createdAt?.seconds * 1000).format(
              "MMM DD, YYYY"
            )}{" "}
            at{" "}
            {moment(notification?.createdAt?.seconds * 1000).format("h:mm A")}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
