import axios from "axios";


export default function NotificationEmail(data) {

  return axios.post(
    'https://us-central1-astranovaapp.cloudfunctions.net/api/email-notification',
    // 'http://localhost:5001/systema-nova/us-central1/api/email-notification',
    {...data},
    {headers: {'Content-Type': 'application/json'}}
  )

}