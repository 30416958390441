import React from "react";
import {
	styled,
	Container,
	Stack,
	Typography,
	Avatar,
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogContent, DialogActions, DialogContentText, Badge
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import {AuthUserContext} from "../../providers/auth-provider";
import {getAuth, getIdToken, updateProfile} from "firebase/auth";
import {useLocation, useNavigate} from "react-router-dom";
import {
	collection,
	getDocs,
	where,
	query,
	doc,
	getDoc,
	setDoc, updateDoc
} from "firebase/firestore";
import {EditSharp} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthUserContext } from "../../../../providers/auth-provider";
import PostNotification from "../../../../api/post-notification";
import StartChat from "../../../../components/common/start-chat";
import EditOrganisationDetailsComponent from "../../../../components/common/profile/edit/service-provider/edit-organisation-details-component";
import OrganisationAbout from "../../../../components/common/profile/organisation-about";
import UploadFile from "../../../../utils/upload-file";
import { DB } from "../../../../config/firebase/firebase";
import { a11yProps, TabPanel } from "../../components/common/tabs-custom";

const StyledTabs = styled((props) => (
	<Tabs
		sx={{ position: "relative", left: 0, top: 15 }}
		{...props}
		TabIndicatorProps={{
			children: <span className="MuiTabs-indicatorSpan" />,
		}}
	/>
))({
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
	},
	"& .MuiTabs-indicatorSpan": {
		maxWidth: 40,
		width: "100%",
		backgroundColor: "none",
	},
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: "none",
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(16),
		marginRight: theme.spacing(1),
		borderRadius: "30px",
		color: "#000",
		"&.Mui-selected": {
			color: "#fff",
			backgroundColor: "#233DFF",
		},
		"&.Mui-focusVisible": {
			backgroundColor: "rgba(100, 95, 228, 0.32)",
		},
	})
);
export default function ServiceProviderOrganisationProfile({ data, href }) {
	debugger;
	const userContext = React.useContext(AuthUserContext);
	const { setProfile, setUser, user } =
		React.useContext(AuthUserContext);
	const contextUser = React.useContext(AuthUserContext)
	const { organisation, profile } = useLocation().state;
	debugger
	const [portfolioIndex, setPortfolioIndex] = React.useState();
	const [imageURL, setImageURL] = React.useState();
	const confirmed = React.useRef(false);
	const profileImage = React.useRef();
	const [open, setOpen] = React.useState(false);
	const [steps, setSteps] = React.useState(0);
	const userRef = doc(DB, "users", user.uid);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [portfolios, setPortfolios] = React.useState(
		profile?.userType === 'investor' ? profile?.investor?.portfolio : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
			: profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.portfolio
	);
	const [posts, setPosts] = React.useState([]);
	const [index, setIndex] = React.useState(-1);
	// const [profile, setProfile] = React.useState(null);
	const [value, setValue] = React.useState(0);

	function handleDialogOpen() {
		setDialogOpen(true);
	}
	function handleDialogClose() {
		setDialogOpen(false);
	}
	const navigator = useNavigate()
	const Input = styled("input")({
		display: "none",
	});
	async function updateProfilePicture(url) {
		try {
			const auth = getAuth();
			console.log(url);
			const displayName = organisation?.nameOfOrganization;
			await updateProfile(auth.currentUser, {
				displayName: organisation.nameOfOrganization,
				logoURL: url
			});
			const data = auth.currentUser.toJSON();
			debugger
			setUser({ ...user });
			const userRef = await doc(DB, "users", auth.currentUser.uid);
			let dat;
			dat = await updateDoc(userRef, {
				"serviceProvider.organisationDetails.logoURL": url
			});
			Object.assign(organisation, {...organisation, logoURL: url})
			Object.assign(profile, { ...profile, serviceProvider:{...profile.serviceProvider, organisationDetails: {...profile.serviceProvider.organisationDetails,logoURL: url}} })
			await setProfile({ ...profile, serviceProvider:{...profile.serviceProvider, organisationDetails: {...profile.serviceProvider.organisationDetails,logoURL: url}} });
			PostNotification({
				type: 'update-profile-picture',
				imageURL: url,
				message: `Your profile picture has been updated`,
				uid: user.uid
			}).then()
			confirmed.current = true;
		} catch (err) {
			console.log(err.message);
		}
	}
	function UploadProfile() {
		return (
			<div>
				<Dialog
					open={dialogOpen}
					onClose={handleDialogClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth={"sm"}
					fullWidth={true}
				>
					<DialogTitle id="alert-dialog-title">
						{organisation?.nameOfOrganization}
					</DialogTitle>
					<DialogContent sx={{ height: 400,  }}>
						<Stack direction={"row"} spacing={2}  justifyContent="center"
							   alignItems="center" sx={{width: '100%', height: 300}}>
							<Avatar
								key={imageURL}
								src={imageURL ? imageURL : organisation?.logoURL}
								alt={organisation?.organisationName}
								sx={{
									width: 250,
									height: 250
								}}
								component={"span"}
							/>
						</Stack>

					</DialogContent>
					<DialogActions
						sx={{ justifyContent: "start", alignItems:'center', height: 75 }}>

						<IconButton variant="outlined">
							<label htmlFor={"profile-image"}>
								<Stack direction={"column"}>
									<CameraAltIcon
										sx={{  cursor: "pointer" }}
										fontSize={"large"}
									/>
									<Input
										accept="image/*"
										id="profile-image"
										multiple={false}
										type="file"
										onChange={(e) => {
											confirmed.current = false;
											const fileReader = new FileReader();
											fileReader.readAsDataURL(
												e.target.files[0]
											);
											fileReader.addEventListener(
												"load",
												async function () {
													const file =
														e.target.files[0];

													const path = `profile-image/${file.name}`;
													const imageObject = {
														progress: 0,
														src: this.result,
														path,
														file: e.target.files[0],
														size: (
															e.target.files[0]
																?.size /
															1024 /
															1024
														).toFixed(2),
													};
													profileImage.current =
														imageObject;
													UploadFile(file, path).then(
														(url) => {
															console.log(url);
															setImageURL(url);
														}
													);
												}
											);
										}}
									/>
								</Stack>
							</label>
						</IconButton>
						<IconButton

							variant="outlined"
							onClick={() => {
								//profileImage.current = "";
								setImageURL(" ");

								//updateProfilePicture("");
								confirmed.current = true;
								//handleDialogClose();
							}}>
							<DeleteIcon
								fontSize={"large"}
								color={"warning"}

							/>
						</IconButton>
						<Box sx={{flex: 1}}/>
						<Button
							sx={{px: 2}}
							size={"small"}
							onClick={() => {
								// profileImage.current = {
								// 	...profileImage,
								// 	photoURL: imageURL,
								// };

								updateProfilePicture(imageURL);

								//profileImage.current = "";

								handleDialogClose();
								//updateProfilePicture("");

							}}
							variant="contained">
							Save Photo
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	React.useEffect(() => {
		debugger
		if (!imageURL) {
			setImageURL(imageURL ? imageURL : organisation?.logoURL !=='' ? organisation.logoURL : profile.serviceProvider.organisationDetails.logoURL );
		}

			const q =
				query(
				collection(DB, "timelines"),
				where("uid", "==", profile?.uid));

			getDocs(q).then((querySnapshot) => {
				if (querySnapshot.size > 0) {
					const list = [];
					querySnapshot.forEach((doc) => {
						list.push({ id: doc.id, ...doc.data() });

						console.log(doc.id, " => ", doc.data());
					});
					setPosts(list);
				}
			});

	}, []);
	return (
		<Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
		{organisation && (
				<Stack direction={"column"} spacing={5}>
					<Stack
						direction={"column"}
						spacing={0.5}
						sx={{
							border: '0.5px solid #000000',
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                //   px: 3,
                //   py: 6,
							// boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							// borderRadius: "15px",
							// backgroundColor: "#fff",
							// minHeight: 100,
							p: 0,
							pb: 0,
						}}>
						<Box
							sx={{
								height: 100,
								width: "100%",
								backgroundColor: "#F6F8FA",
								borderRadius: "15px",
							}}
						/>
						{console.log('TESTING')}
						<Box
							sx={{
								border: "1px solid #204488",
								p: 0.75,
								borderRadius: "50%",
								width: "fit-content",
								position: "relative",
								left: 30,
								top: -50,
							}}>
							<label>
								<Badge
									overlap="circular"
									sx={{ width: 92, height: 92 }}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
									badgeContent={
										profile?.uid === user?.uid &&
										<Button
											onClick={handleDialogOpen}
											sx={{ ml: 1, mt: 1 }}
											color={"primary"}
											component={"span"}>
											<AddCircleIcon />
										</Button>
									}>
									{profile?.uid === user?.uid &&
										<UploadProfile/>
									}
							<Avatar
								alt={organisation?.nameOfOrganization}
								src={imageURL? imageURL: organisation?.logoURL}
								sx={{ width: 92,
                     height: 92  }}>
								<Typography variant={"h3"}>
									{organisation?.nameOfOrganization.charAt(0)}
								</Typography>
							</Avatar>
								</Badge>
							</label>
						</Box>
						<Stack
							direction={"row"}
							spacing={3}
							sx={{ position: "relative", top: -40 }}>
							<div />
							<Stack direction={"column"} spacing={2}>
								<Stack direction={"row"} spacing={3}>
									<Typography
										variant={"h5"}
										sx={{ marginTop: "2.5px" }}>
										{organisation?.nameOfOrganization}
									</Typography>
								</Stack>
								<br></br>
								<Stack
									direction={"row"}
									spacing={1}
									sx={{ position: "relative", top: -10 }}>
								<Box
										sx={{
											backgroundColor: "#E0E3FF",
											px: 1,
											py: 1,
											borderRadius: "19.5px",
										}}>
										<Typography
											style={{
												fontWeight: 500,
												textTransform: "capitalize",
											}}>
											{"Organisation"}
										</Typography>
									</Box>
									</Stack>
								<Stack
									direction={"row"}
									spacing={1}
									sx={{ position: "relative", top: -10 }}>
									<Typography variant={'body2'} color={"grey"} style={{flex: 1}}>
										{profile?.countryState}
									</Typography>
									{profile?.uid === user?.uid  && 	<IconButton onClick={() => setOpen(true)}>
										<EditSharp />
									</IconButton>
									}


								</Stack>

								{organisation && organisation?.organizationDescription && (
									<Typography
										variant={"body1"}
										color={"secondary"}
										sx={{
											width: "calc(100% - 10%)",
											position: "relative",
											top: -10,
										}}>
										{organisation?.organizationDescription}
									</Typography>
								)}
								{ profile?.serviceProvider?.organisationType === "Organisation" && profile?.serviceProvider?.organisationDetails && (
					<Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent="flex-start"
                      sx={{ position: "relative", minWidth: 120, cursor: 'pointer' , marginTop:'-13px'}}
                      alignItems="flex-start"

                      onClick={() => {
						if (userContext.profile.uid !== profile.uid) navigator(`/m/${userContext.profile.userType}/service-provider-details/${profile?.uid}`);
							else {
								debugger
									navigator(`/m/${profile?.userType}/profile`, {
										state: {
											profile:data,
											organisation: {
												...data?.serviceProvider.organisationDetails,
												linkedIn: data?.serviceProvider.organisationDetails?.linkedIn,
												website: data?.serviceProvider.organisationDetails?.website,
												nameOfOrganization:
													data?.serviceProvider.organisationDetails?.nameOfOrganization !== ""
														? data?.serviceProvider.organisationDetails?.nameOfOrganization
														: data?.startup?.serviceProvider,
												logoURL:
													data?.serviceProvider.organisationDetails.logoURL !== ""
														? data?.serviceProvider.organisationDetails.logoURL
														: '',
											},
										},
									});
							}
						}
				
                      }
                    >
                      <Box
                        sx={{
                          border: "1px solid #204488",
                          p: 0.75,
                          borderRadius: "50%",
                          width: "fit-content",
                        }}
                      >
                        <Avatar
                          alt={   profile?.displayName  }
                          src={
                            profile?.serviceProvider?.photoURL 
                          }
                          sx={{ width: 51, height: 51 }}
                        >
                          <Typography variant={"h4"} >
                            {profile?.displayName.charAt(0)}
                          </Typography>
                        </Avatar>
                      </Box>
		
                      <Typography variant={"h6"}>
					  {
                        profile?.displayName  }
                        {/* {profile?.startup?.founder.founderName} */}
                        <br />
                        <font
                          size={2}
                          style={{
                            backgroundColor: "#F6F8FA",
                            padding: "5px 10px",
                            borderRadius: "6px",
                            fontWeight: 300,
                          }}
                        >
                          {profile?.serviceProvider.role}
                        </font>
                      </Typography>
                    </Stack>
								)}
								<EditOrganisationDetailsComponent profile={profile} open={open} handleClose={() => setOpen(false)} />
                                <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
								<StyledTabs
								    variant="scrollable"
                                    scrollButtons="auto"
									value={value}
									onChange={handleChange}
									aria-label="styled tabs example">
									<StyledTab
										label="ABOUT"
										{...a11yProps(0)} sx={{fontSize:'14px'}}
									/>
								</StyledTabs>
								</Box>
							</Stack>
						</Stack>
					</Stack>
					
					<TabPanel index={0} value={value}>
					<Stack
                direction={"column"}
                spacing={3}
                sx={{
                  border: '0.5px solid #000000',
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 3,
                  py: 6,
                }}>
                  <OrganisationAbout profile={profile} organisation={organisation} />
								 
		
              </Stack>
            </TabPanel>
		
				</Stack>
			)}




			
		</Container>
	);
}
