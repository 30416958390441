import React from 'react';
import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {HomeComponent} from "../screens/default-screen";
import TimelineScreen from "../screens/common/timeline-screen";
import {InvestorList} from "../screens/common/investor/investor-list";
import ProfileScreen from "../screens/common/profile-screen";
import CommonDetails from "../screens/common/common-details";
import ChatScreen from "../screens/common/chat-screen";
import {ExpertList} from "../screens/common/expert/expert-list";
import {BusinessDeveloperList} from "../screens/common/business-developer/business-developer-list";
import {ServiceProviderList} from "../screens/common/service-provider/service-provider-list";
import {StartupList} from "../screens/common/startup/startup-list";
import StartupFounderProfile from "../screens/common/startup/founder-profile";
import {TalentList} from "../screens/common/talent/talent-list";
import RequestAssistanceScreen from "../screens/common/request-assistance-screen";
import RequestDetails from "../screens/common/request-details";
import MyRequests from "../screens/common/my-requests";
import NotificationScreen from "../screens/common/notification-screen";
import SettingScreen from "../screens/common/setting-screen";
import ChangePasswordScreen from "../screens/common/change-password-screen";
import WorkExperience from '../components/common/profile/work-experience-view';
import InvestmentPortfolioView from '../components/common/profile/investor-portfolio-view';
import {MembersList} from "../screens/common/members-list";
import ServiceProviderOrganisationProfile from "../screens/common/service-provider/organisation-profile"
import CommonOrganisationProfile from "../screens/common/common-organisation-profile";
import { RequestMatchesList } from '../screens/common/reques-matches-list';
import PostDetails from "../screens/common/post-details";
import MyFavorites from "../screens/common/my-favorites";
import Applicants from "../screens/common/applicants";
import OffersList from '../screens/common/offers/offers-list-users';
import OfferDetailsClaim from '../screens/common/offer-details-claim';
import {RequestMatchesUser} from "../screens/common/request-matches-user";




const BusinessDeveloperRouter = () => {
  return(
    <Routes>
      <Route
        path="*"
        element={<Navigate to="/business-developer/timeline" replace />}
      />
      <Route  path='business-developer'  element={<Outlet />}>
        <Route  path={'post-details/:postId'} element={<PostDetails/>}/>
        <Route  path={'my-favorites'} element={<MyFavorites/>}/>
        <Route  path={'applicants/:requestId'} element={<Applicants/>}/>
  
        <Route  path={'open-requests'} element={<HomeComponent/>}/>
        <Route  path={'open-requests-post'} element={<RequestAssistanceScreen/>}/>
        <Route  path={'open-requests-details'} element={<RequestDetails/>}/>
        <Route  path={'open-requests-details/:requestId'} element={<RequestDetails/>}/>
        <Route  path={'notification'} element={<NotificationScreen/>}/>
        <Route  path={'matches-user/:requestId'} element={<RequestMatchesUser/>}/>

        <Route  path={'open-requests-update/:requestId'} element={<RequestAssistanceScreen/>}/>
        <Route  path={'timeline'} element={<TimelineScreen/>}/>
        <Route  path={'investor-list'} element={<InvestorList/>}/>
        <Route  path={'profile'} element={<ProfileScreen/>}/>
        <Route  path={'work-experience'} element={<WorkExperience/>}/>
        <Route  path={'investment-portfolio-view'} element={<InvestmentPortfolioView/>}/>
        <Route  path={'investor-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'chat'} element={<ChatScreen/>}/>
        <Route  path={'expert-list'} element={<ExpertList/>}/>
        <Route  path={'expert-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'business-developer-list'} element={<BusinessDeveloperList/>}/>
        <Route  path={'business-developer-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'service-provider-list'} element={<ServiceProviderList/>}/>
        <Route  path={'service-provider-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'startup-list'} element={<StartupList/>}/>
        <Route  path={'startup-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'startup-founder-profile'} element={<StartupFounderProfile/>}/>
        <Route  path={'investor-list/founder-profile'} element={<StartupFounderProfile/>}/>
        <Route  path={'startup-list/startup-founder-profile'} element={<StartupFounderProfile/>}/>
        <Route  path={'talent-list'} element={<TalentList/>}/>
        <Route  path={'my-requests'} element={<MyRequests/>}/>
        <Route  path={'talent-details/:uid'} element={<CommonDetails/>}/>
        <Route  path={'members-list'} element={<MembersList/>}/>

        <Route  path={'settings'} element={<SettingScreen/>}/>
        <Route  path={'change-password'} element={<ChangePasswordScreen />}/>
        <Route  path={'organisation-profile'} element={<ServiceProviderOrganisationProfile/>}/>
        <Route  path={'common-organisation-profile'} element={<CommonOrganisationProfile/>}/>
        <Route  path={'request-matches-list'} element={<RequestMatchesList/>}/>
        <Route  path={'offers/list'} element={<OffersList />}/>
        <Route  path={'offers/details/:offerId'} element={<OfferDetailsClaim />}/>
      </Route>
    </Routes>

  )
}


export default BusinessDeveloperRouter;
