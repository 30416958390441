import * as React from "react";
import Typography from "@mui/material/Typography/Typography";
import Stack from "@mui/material/Stack/Stack";
import moment from "moment/moment";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import {
	Box, Button,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
	DownloadDoneOutlined,
	DownloadingOutlined,
	DownloadOutlined,
	InsertDriveFile,
} from "@mui/icons-material";
import { AuthUserContext } from "../../../../providers/auth-provider";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { DB } from "../../../../config/firebase/firebase";
import DownloadFileHelper from "../../../../utils/download-file-helper";
export default function ContractDocumentComponent({ msg, type, callback, mobile }) {
	const { profile } = React.useContext(AuthUserContext);

	React.useEffect(() => {
		console.log(msg);
		debugger;
		if (msg && msg.receiver === profile?.uid && msg.read === false) {
			const msgRef = doc(DB, "messages", msg.id);
			setDoc(
				msgRef,
				{
					read: true,
					status: "seen",
					updatedAt: serverTimestamp(),
				},
				{ merge: true }
			).then();
		}
	}, [msg]);
	if (type === "r") {
		return (
			<Stack direction={"column"} spacing={1}>
				<Stack direction={"row-reverse"} spacing={1}>
					<Stack
						direction={"row"}
						spacing={1}
						sx={{
							p: 1,
							backgroundColor: "none",
							color: "#8F8F8F",
							borderRadius: "12px 0px 12px 12px",
							maxWidth: !mobile ?"70%":"100%",
							minWidth: "70%",
							border: '.3px solid #8f8f8f6e'
						}}
						justifyContent="flex-end"
						alignItems="center">
						<Stack
							direction={"row"}
							spacing={2}
							color={"secondary"} width={'100%'}>
							<Box sx={{display:"flex" ,justifyContent:'center',alignItems:'center',height: mobile? 48 : 128, borderRadius:'10px', width: mobile? 48: 123,color:'#fff', backgroundColor: '#F95655',fontSize: 24,textTransform:'uppercase',  textAlign: 'center'}}>
								<img src="/assets/fa6-solid_file-contract.png" height={mobile?32:46	} alt=""/>
							</Box>
						
							<Stack direction={"column"} spacing={0.5} sx={{width: !mobile ? 'calc(100% - 135px)': "calc(100% - 100px)"}} >
								<Typography  variant={mobile? "body2" : "body2"} sx={{color:'#051E40'}}>{msg?.fileName}</Typography>
								<Typography   variant={"caption"} sx={{color:'#8F8F8F'}}> {msg?.text}</Typography>
								<Box flex={1}></Box>
								<Stack direction={"row"} spacing={1}>
								<Button variant={"contained"}  size={"small"} sx={{fontSize:mobile && 10,width:"150px"}} onClick={() => {
								console.log(msg.path);
								msg.path &&
								msg.path !== "" &&
								DownloadFileHelper(msg.path);
							}}>View contract</Button>
								
								</Stack>
							</Stack>
							
						</Stack>
					{/*	<IconButton
							onClick={() => {
								console.log(msg.path);
								msg.path &&
									msg.path !== "" &&
									DownloadFileHelper(msg.path);
							}}>
							<DownloadOutlined sx={{ color: "#fff" }} />
						</IconButton>*/}
					</Stack>
				</Stack>
				<Stack
					direction={"row"}
					spacing={0.5}
					alignItems="center"
					justifyContent="flex-end">
					<Typography sx={{ fontSize: "12px" }}>
						{msg.createdAt &&
							moment(msg.createdAt.seconds * 1000).format(

								`${moment(msg.createdAt.seconds * 1000).isSame(new Date(), "day") ? 'hh:mm a' : 'D-M-YYYY | hh:mm a'}`
							)}
					</Typography>

					{msg.read === true ? (
						<DoneAllOutlinedIcon
							style={{ color: "#4acfee", fontSize: 18 }}
						/>
					) : (
						<DoneAllOutlinedIcon
							style={{ color: "#ccc", fontSize: 18 }}
						/>
					)}
				</Stack>
			</Stack>
		);
	} else {
		return (
			<Stack direction={"column"} spacing={1}>
				<Stack direction={"row"} spacing={1}>
					<Stack
						direction={"row"}
						spacing={1}
						sx={{
							p: 1,
							backgroundColor: "#F6F8FA",
							borderRadius: "0px 12px 12px 12px",
							maxWidth: !mobile ? "70%" :"100%",
							minWidth: "70%",
							border: '.3px solid #8f8f8f6e'
						}}
						justifyContent="flex-end"
						alignItems="center">
						<Stack
							direction={"row"}
							spacing={2}
							color={"secondary"} width={'100%'}>
							<Box sx={{display:"flex" ,justifyContent:'center',alignItems:'center',height: mobile? 48 : 128, borderRadius:'10px', width: mobile? 48: 123,color:'#fff', backgroundColor: '#F95655',fontSize: 24,textTransform:'uppercase',  textAlign: 'center'}}>
								<img src="/assets/fa6-solid_file-contract.png" height={mobile?32:46	} alt=""/>
							</Box>

							
							<Stack direction={"column"} spacing={0.5} sx={{width: !mobile ? 'calc(100% - 135px)': "calc(100% - 100px)"}} >
								<Typography  variant={mobile? "body2" : "body2"} sx={{color:'#051E40'}}>{msg?.fileName}</Typography>
								<Typography   variant={"caption"} sx={{color:'#8F8F8F'}}> {msg?.text}</Typography>
								<Box flex={1}></Box>
							
							<Stack direction={'row'} spacing={1} width={"100%"}>
								
							<Button variant={"contained"} size={"small"} sx={{fontSize:mobile && 10,width:"150px"}} onClick={() => {
								console.log(msg.path);
								msg.path &&
								msg.path !== "" &&
								DownloadFileHelper(msg.path);
							}}>View contract</Button>
								
									
							{msg?.type === "contract-document" && <Button variant={"outlined"}  size={"small" } sx={{fontSize:mobile && 10, width:"150px"}} onClick={() => callback(true)}>
										Sign and upload
									</Button>}
								</Stack>
								
							
							</Stack>
						
						</Stack>
						
					</Stack>
				</Stack>

				<Stack
					direction={"row"}
					spacing={0.5}
					alignItems="center"
					justifyContent="flex-start">
					<Typography sx={{ fontSize: "12px" }} color={"secondary"}>
						{msg.createdAt &&
							moment(msg.createdAt.seconds * 1000).format(

								`${moment(msg.createdAt.seconds * 1000).isSame(new Date(), "day") ? 'hh:mm a' : 'D-M-YYYY | hh:mm a'}`
							)}
					</Typography>
				</Stack>
			</Stack>
		);
	}
}
