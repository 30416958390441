import React from "react";
import {Stack, Typography, IconButton} from "@mui/material";
import {EditSharp} from "@mui/icons-material";
import EditServiceProviderDetails
  from "../../../../../components/common/profile/edit/service-provider/edit-service-provider-details";

export default function ServiceProviderDescription({profile}) {
    const [open,setOpen] = React.useState(false)
  const profileRegex = /profile/
  return (
    <>
        <Stack direction={"row"} spacing={1}  justifyContent="flex-end"
             alignItems="center" sx={{width: '100%'}}>
        {profile.countryState ? <Typography variant={'body2'} color={"grey"} sx={{flex: 1}}>
          {profile.countryState}
        </Typography> : null}
        {profileRegex.test(window.location.pathname) &&  <IconButton onClick={() => setOpen(true)}>
          <EditSharp />
        </IconButton>}
      </Stack>

      {profile?.serviceProvider && profile?.serviceProvider?.description &&
        <Typography variant={'body1'} color={'secondary'} sx={{width: 'calc(100% - 10%)', fontSize:'12px', whiteSpace:"pre-line"}}>

        {profile?.serviceProvider?.description}
      </Typography>}

      <EditServiceProviderDetails profile={profile} open={open} handleClose={() => setOpen(false)}/>


    </>
  )
}