import React from "react";
import {
	Button,
	Container,
	Stack,
	TextField,
	Autocomplete,
	Chip,
	FormLabel,
	FormControlLabel,
	FormHelperText,
	FormControl,
	Typography,
	Switch,
} from "@mui/material";
import { useFormik } from "formik";
import StartupOnBoardingValidation from "../../validations/startup/startup-profile";
import CloseIcon from "@mui/icons-material/Close";
import { BUSINESS_DEVELOPER } from "../../utils/enums/labels.enum";
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../config/firebase/firebase";

export default function TopPriorities({ cbBack, cbNext, data, uid, cbDraft }) {
	const formikFirst = useFormik({
		initialValues: data
			? data
			: StartupOnBoardingValidation.TopPrioritiesValues,
		validationSchema:
			StartupOnBoardingValidation.TopPrioritiesSchema,
		onSubmit: async (values) => {
			cbNext({ ...values });
		},
	});
	React.useEffect(() => {
		if (uid) {
			setTimeout(() => {
				getDoc(doc(DB, 'users', uid))
					.then((document) => {
						if (document?.data()?.formThirdData) {
							formikFirst.setValues(document?.data()?.formThirdData);
						}
					})
			}, 300 )
		}
		
	}, [data])
	const isAdmin = /admin/;
	
	return (
		<Stack
			direction={"column"}
			spacing={10}
			component="form"
			onSubmit={(e) => formikFirst.handleSubmit(e)}>
			<Container maxWidth={"md"}>
				<Stack direction={"column"} spacing={5}>

					<Typography sx={{ textAlign: "left", fontWeight:"500", fontSize:"24px" }}>Top Priorities</Typography>

					<FormControl component="fieldset" variant="standard">
						<FormLabel
							component="legend"
							sx={{ textAlign: "left", mb: 2 }}>
							Looking for Growth Experts ({BUSINESS_DEVELOPER.NAME}, Sales, Partnership, Marketing, Branding…)?
						</FormLabel>
						<Stack direction="row" spacing={1} alignItems="center">
							<Typography>No</Typography>
							<FormControlLabel
								control={
									<Switch
										name={"lookingForBusinessDeveloper"}
										checked={
											formikFirst.values
												.lookingForBusinessDeveloper
										}
										onChange={formikFirst.handleChange}
									/>
								}
							/>
							<Typography>Yes</Typography>

							<FormHelperText
								error={
									formikFirst.touched.lookingForBusinessDeveloper &&
									Boolean(
										formikFirst.errors.lookingForBusinessDeveloper
									)
								}
								children={
									formikFirst.touched.lookingForBusinessDeveloper &&
									formikFirst.errors.lookingForBusinessDeveloper
								}
							/>
						</Stack>
					</FormControl>
					<FormControl component="fieldset" variant="standard">
						<FormLabel
							component="legend"
							sx={{ textAlign: "left", mb: 2 }}>
							Looking for Industry Experts (Scientists, Researchers…)?
						</FormLabel>
						<Stack direction="row" spacing={1} alignItems="center">
							<Typography>No</Typography>
							<FormControlLabel
								control={
									<Switch
										name={"lookingForExperts"}
										checked={
											formikFirst.values
												.lookingForExperts
										}
										onChange={formikFirst.handleChange}
									/>
								}
							/>
							<Typography>Yes</Typography>

							<FormHelperText
								error={
									formikFirst.touched
										.lookingForExperts &&
									Boolean(
										formikFirst.errors
											.lookingForExperts
									)
								}
								children={
									formikFirst.touched
										.lookingForExperts &&
									formikFirst.errors
										.lookingForExperts
								}
							/>
						</Stack>
					</FormControl>
					<FormControl component="fieldset" variant="standard">
						<FormLabel
							component="legend"
							sx={{ textAlign: "left", mb: 2 }}>
							Looking for Service Providers (Lawyers, Designers, Accountants…)?
						</FormLabel>
						<Stack direction="row" spacing={1} alignItems="center">
							<Typography>No</Typography>
							<FormControlLabel
								control={
									<Switch
										name={"lookingForServiceProvider"}
										checked={
											formikFirst.values
												.lookingForServiceProvider
										}
										onChange={formikFirst.handleChange}
									/>
								}
							/>
							<Typography>Yes</Typography>

							<FormHelperText
								error={
									formikFirst.touched
										.lookingForServiceProvider &&
									Boolean(
										formikFirst.errors
											.lookingForServiceProvider
									)
								}
								children={
									formikFirst.touched
										.lookingForServiceProvider &&
									formikFirst.errors.lookingForServiceProvider
								}
							/>
						</Stack>
					</FormControl>
					<FormControl component="fieldset" variant="standard">
						<FormLabel
							component="legend"
							sx={{ textAlign: "left", mb: 2 }}>
							Looking for funding?
						</FormLabel>
						<Stack direction="row" spacing={1} alignItems="center">
							<Typography>No</Typography>
							<FormControlLabel
								control={
									<Switch
										name={"lookingForFunding"}
										checked={
											formikFirst.values.lookingForFunding
										}
										onChange={formikFirst.handleChange}
									/>
								}
							/>
							<Typography>Yes</Typography>

							<FormHelperText
								error={
									formikFirst.touched.lookingForFunding &&
									Boolean(formikFirst.errors.lookingForFunding)
								}
								children={
									formikFirst.touched.lookingForFunding &&
									formikFirst.errors.lookingForFunding
								}
							/>
						</Stack>
					</FormControl>
				</Stack>
			</Container>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3}
				width={"100%"}>
				{isAdmin.test(window.location.pathname) &&
					<Button
						variant={"outlined"}
						color={"primary"}
						sx={{ px: 5 }}
						onClick={() =>  cbDraft({
							...formikFirst?.values
						})}>
						Save draft
					</Button>
				}
				<Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Back
				</Button>
				<Button
					variant={"contained"}
					color={"primary"}
					sx={{ px: 5 }}
					type={"submit"}>
					Next
				</Button>
				
			</Stack>
		</Stack>
	);
}
