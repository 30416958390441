import React, { useEffect } from "react";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Tooltip, Typography, FormControlLabel, Switch, FormControl, FormLabel, FormHelperText, useMediaQuery, CircularProgress
} from "@mui/material";
import { useFormik } from "formik";
import StartupOnBoardingValidation from "../../../validations/startup/startup-profile";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';
import dataJson from '../../../const/data.json';
export default function EditStartupFounderAboutDetailsForm({ cbBack, cbNext, data, isLoading }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const formikFirst = useFormik({
    initialValues: data
      ? data
      : StartupOnBoardingValidation.FounderProfileSecondInitialValues,
    validationSchema: StartupOnBoardingValidation.FounderProfileSecondSchema,
    onSubmit: async (values) => {
      debugger
      cbNext({
        ...values,
      });
    }
  });
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>


          <TextField
            name={'website'}
            value={formikFirst.values.website}
            onChange={formikFirst.handleChange}
            error={formikFirst.touched.website && Boolean(formikFirst.errors.website)}
            helperText={formikFirst.touched.website && formikFirst.errors.website}
            fullWidth label={'Website URL'} />
          <TextField
            name={'linkedIn'}
            value={formikFirst.values.linkedIn}
            onChange={formikFirst.handleChange}
            error={formikFirst.touched.linkedIn && Boolean(formikFirst.errors.linkedIn)}
            helperText={formikFirst.touched.linkedIn && formikFirst.errors.linkedIn}
            fullWidth label={'LinkedIn URL'} />
          <TextField
            name={'twitter'}
            value={formikFirst.values.twitter}
            onChange={formikFirst.handleChange}
            error={formikFirst.touched.twitter && Boolean(formikFirst.errors.twitter)}
            helperText={formikFirst.touched.twitter && formikFirst.errors.twitter}
            fullWidth label={'Twitter Handle'} />

          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend"  sx={{textAlign: 'left', mb: 2}}>Repeat Founder</FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <FormControlLabel
                control={
                  <Switch    name={'repeatFounder'}
                             checked={formikFirst.values.repeatFounder}
                             onChange={formikFirst.handleChange} />
                }
              />
              <Typography>Yes</Typography>

              <FormHelperText
                error={formikFirst.touched.repeatFounder && Boolean(formikFirst.errors.repeatFounder)}
                children={formikFirst.touched.repeatFounder && formikFirst.errors.repeatFounder}
              />
            </Stack>

          </FormControl>
        </Stack>
      </Container>
      {
        matches ? <Stack
                direction={"row"}
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
                width={"100%"}>
              <Button
                  variant={"outlined"}
                  color={"primary"}
                  sx={{ px: 5 }}
                  onClick={cbBack}>
                Back
              </Button>
              {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
              <Button
                  variant={"contained"}
                  color={"primary"}
                  sx={{ px: 5 }}
                  type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
                save
              </Button>
            </Stack> :
            <Stack
                direction={"row"}
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
                width={"100%"}
                sx={{px: !matches ? 2 : ''}}
            >
              {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
              <Button
                  variant={"contained"}
                  color={"primary"}
                  sx={{ px: 5 }}
                  type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
                save
              </Button>
            </Stack>
      }
    </Stack>
  );
}
