import React from "react";
import {
	Button,
	Container,
	Stack,
	IconButton,
	TextField,
	Avatar,
	CircularProgress,
	Link,
	Badge,
	styled,
	Typography,
} from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import UploadFile from "../../../utils/upload-file";
import ExportOnboardingValidation from "../../../validations/expert/onboarding-profile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AuthUserContext } from "../../../providers/auth-provider";
import {useLocation} from "react-router-dom";
import {doc, getDoc} from "firebase/firestore";
import {DB} from "../../../config/firebase/firebase";

export default function ExpertFirstForm({ cbBack, cbNext, data, cbDraft, uid }) {
	const isAdmin = /admin/;
	const location = useLocation()
	const Input = styled("input")({
		display: "none",
	});
	const { user } = React.useContext(AuthUserContext);
	
	const [profileImage, setProfileImage] = React.useState({
		src: data && data?.photoURL !== "" ? data?.photoURL :isAdmin.test(window.location.pathname) ? '' : user?.photoURL || null,
		photoURL:data &&  data?.photoURL !== '' ? data?.photoURL :isAdmin.test(window.location.pathname) ? '' : user?.photoURL || "",
	});
	React.useEffect(() => {
		if (uid) {
			setTimeout(() => {
				getDoc(doc(DB, 'users', uid))
					.then((document) => {
						if (document?.data()?.formFirstData) {
							formikFirst.setValues(document?.data()?.formFirstData);
							setProfileImage({
								src: document?.data() && document?.data()?.formFirstData?.photoURL !== "" ? document?.data()?.formFirstData?.photoURL : null,
								photoURL:document?.data() &&  document?.data()?.formFirstData?.photoURL !== '' ? document?.data()?.formFirstData?.photoURL : null,
							})
						}
					})
			}, 500 )
		}
		
	}, [data])
	const [loading, setLoading] = React.useState(false);
	const formikFirst = useFormik({
		initialValues: data
			? data
			: {...ExportOnboardingValidation.ExpertFirstFormValues,
				displayName: isAdmin.test(window.location.pathname) ? location?.state?.contactData?.displayName :  user?.displayName},
		validationSchema:
		ExportOnboardingValidation.ExpertFirstForm,
		onSubmit: async (values) => {
			await formikFirst.setFieldValue(
				"photoURL",
				profileImage?.photoURL ? profileImage.photoURL : ""
			);
			cbNext({
				...values,
				photoURL: profileImage?.photoURL ? profileImage.photoURL : "",
			});
		},
	});

	return (
			<Stack
				direction={"column"}
				spacing={10}
				component="form"
				onSubmit={(e) => formikFirst.handleSubmit(e)}>
				<Container maxWidth={"md"}>
					<Stack direction={"column"} spacing={3}>
						<Stack
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={1}
							sx={{ mb: 5 }}>
							<label htmlFor="profile-image">
								<Badge
									overlap="circular"
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
									badgeContent={
										<IconButton
											color={"primary"}
											component={"span"}>
											<AddCircleIcon />
										</IconButton>
									}>
									<Avatar
										alt={"startup-picture"}
										src={
											profileImage && profileImage?.src
												? profileImage?.src
												: "/assets/default-avatar.png"
										}
										sx={{
											width: 128,
											height: 128,
											cursor: "pointer",
											backgroundColor: "transparent",
											border: "1px solid #E0E3FF",
										}}
										imgProps={{
											sx: { objectFit: "cover" },
										}}
										component={"span"}
									/>
								</Badge>
								<Input
									accept="image/*"
									id="profile-image"
									multiple={false}
									type="file"
									onChange={(e) => {
										const fileReader = new FileReader();
										fileReader.readAsDataURL(e.target.files[0]);
										fileReader.addEventListener(
											"load",
											async function () {
												setLoading(true);
												const file = e.target.files[0];
												
												const path =  isAdmin.test(window.location.pathname) ? `admin/${user.uid}/expert/profile/${file.name}` : `expert/${user.uid}/logo/${file.name}`;
												const imageObject = {
													progress: 0,
													src: this.result,
													path,
													file: e.target.files[0],
													size: (
														e.target.files[0]?.size /
														1024 /
														1024
													).toFixed(2),
												};
												setProfileImage(imageObject);
												UploadFile(file, path).then(
													(url) => {
														setProfileImage({
															...imageObject,
															photoURL: url,
														});
														setLoading(false);
													}
												);
											}
										);
									}}
								/>
							</label>
							{loading && (
								<CircularProgress
									sx={{ ml: 2 }}
									size={20}
									color="primary"
								/>
							)}
							{profileImage && profileImage?.src && (
								<Link
									color={"secondary"}
									onClick={() => setProfileImage(undefined)}>
									Remove picture
								</Link>
							)}
						</Stack>
						
						<Stack direction={'column'} spacing={1} >
						<Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  textAlign="start"
				
                >
                  Your full name *
                </Typography>
							<TextField fullWidth

								name={"displayName"}
								value={formikFirst.values.displayName}
								onChange={formikFirst.handleChange}
								error={
									formikFirst.touched.displayName &&
									Boolean(formikFirst.errors.displayName)
								}
								helperText={
									formikFirst.touched.displayName &&
									formikFirst.errors.displayName
								}
								//label={"Your full name *"}
							/>
						</Stack>
						<Stack direction={'column'} spacing={1} >
						<Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  textAlign="start"
				
                >
                 Tell us about yourself
                </Typography>
						<TextField
							name={"description"}
							value={formikFirst.values.description}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.description &&
								Boolean(formikFirst.errors.description)
							}
							helperText={
								formikFirst.touched.description &&
								formikFirst.errors.description
							}
							//label={"Tell us about yourself"}
							multiline={true}
							inputProps={{maxLength:150}}
							rows={3}
						/>
						<Typography align="right" sx={{color:"#233DFF"}}>{150-formikFirst.values.description.length}/150</Typography>
						</Stack>
						{	!isAdmin.test(window.location.pathname) && <Stack direction={'column'} spacing={0}  >
							<Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							>LinkedIn URL *
							</Typography>
							
							<TextField fullWidth
								//    required
									   name={"linkedinURL"}
									   value={formikFirst.values.linkedinURL}
									   onChange={formikFirst.handleChange}
									   error={
										   formikFirst.touched.linkedinURL &&
										   Boolean(formikFirst.errors.linkedinURL)
									   }
									   helperText={
										   formikFirst.touched.linkedinURL &&
										   formikFirst.errors.linkedinURL
									   }
								//label={"LinkedIn URL"}
							/>
						</Stack>}
					</Stack>
				</Container>
				<Stack
					direction={"row"}
					justifyContent="center"
					alignItems="center"
					spacing={3}
					width={"100%"}>
					{isAdmin.test(window.location.pathname) &&
						<Button
							variant={"outlined"}
							color={"primary"}
							sx={{ px: 5 }}
							onClick={() =>  cbDraft({
								...formikFirst?.values,
								photoURL:profileImage?.photoURL ? profileImage.photoURL : ""
							})}>
							Save draft
						</Button>
					}
					<Button
						variant={"outlined"}
						color={"primary"}
						sx={{ px: 5 }}
						onClick={cbBack}>
						Back
					</Button>
					<Button
						variant={"contained"}
						color={"primary"}
						sx={{ px: 5 }}
						type={"submit"}>
						Next
					</Button>
					
				</Stack>
			</Stack>
	);
}
