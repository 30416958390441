import React, { useEffect } from "react";
import { Stack, Badge, Grid, Chip, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import StartConversationList from "../start-conversation-listview";
import { AuthUserContext } from "../../../providers/auth-provider";
import { useNavigate } from "react-router-dom";
import RequestUserAvatar from "../request-user-avatar";
import { USERS_NAME_MAPPING } from "../../../utils/enums/labels.enum";

export default function OpenRequestListItem({ request, searchQuery }) {
	const { profile } = React.useContext(AuthUserContext);
	const limit = 7;
	let mappedElements =0;
	// const counter = React.useRef(0);
	const navigator = useNavigate();
	// useEffect(() => {
	// 	 counter.current = 0;
	// 	 mappedElements = 0
	// }, [request]);
	return (
		<>
			{request?.requestFor?.toLowerCase().includes(searchQuery) ||
			request?.type?.toLowerCase().includes(searchQuery) ||
			request?.skills?.find((a) =>
				a.toLowerCase().includes(searchQuery.toLowerCase())
			) !== undefined ||
			request?.searchFor?.find((a) =>
				a.toLowerCase().includes(searchQuery.toLowerCase())
			) !== undefined ? (
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="flex-start"
					spacing={1}
					sx={{
						py: 1.5,
						px: 3,
						width: "100%",
						minHeight: 100,
						opacity: request.stage === "Closed" ||  request.stage === "Deactivated"  ? '0.5' : "1",
						backgroundColor: request.uid === profile?.uid ? "#e5f5fb" : "#f7f8fa",
						border: request.uid === profile?.uid ?  "0.5px solid #c4ced2" : "none",
						borderRadius: "8px",
						boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 2px 5px",
					}}
						>
					<Stack direction={'row'}>		
					<Stack>
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={5}>
						<Typography
							variant={"body1"}
							noWrap={true}
							onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated") &&
							navigator(
								`/${profile.userType}/open-requests-details`,
								{
									state: { request },
								}
							)
						}
							sx={{
								width: 400,
								lineHeight: 1.3,
								cursor: "pointer",
							}}>
							{request.requestFor} <br />
							<font color={"#8F8F8F"} size={2}>
								{request?.location
									? request?.location
									: request?.creatorData?.startup &&
									  request?.creatorData?.startup.location}
							</font>
						</Typography>
						<Stack
							direction={"column"}
							spacing={1}
							onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated")  &&
							navigator(
								`/${profile.userType}/open-requests-details`,
								{
									state: { request },
								}
							)
						}
							sx={{
								textAlign: "left",
								cursor: "pointer",
								width: 85,
							}}>
							<Typography variant={"body2"} fontWeight={500}>
								Status
							</Typography>
							<Stack
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
								spacing={3}
								sx={{ color: "#8F8F8F", fontSize: 14 }}>
								<Badge
									variant={"dot"}
									invisible={false}
									color={
										!request.stage ||
										request.stage === "activate"
											? "primary"
											: request.stage === "closed"
											? "error"
											: "warning"
									}
								/>
								&nbsp;&nbsp;&nbsp;
								{request.stage && request.stage === "activate"
									? "Open"
									: !request.stage
									? "Open"
									: request.stage}
							</Stack>
						</Stack>
						<Stack
							direction={"column"}
							spacing={1}
							onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated")  &&
							navigator(
								`/${profile.userType}/open-requests-details`,
								{
									state: { request },
								}
							)
						}
							sx={{
								textAlign: "left",
								width: 120,
								cursor: "pointer",
							}}>
							<Typography variant={"body2"} fontWeight={500}>
								{request?.requestFor ===
									"Looking for funding" ||
								request.type === "looking-for-funding"
									? "Amount to raise"
									: "Budget"}
							</Typography>
							<Typography
								variant={"body2"}
								sx={{ color: "#8F8F8F" }}>
								{request?.requestFor ===
									"Looking for funding" ||
								request.type === "looking-for-funding"
									? `${request?.currency} ${request?.amount}`
									: `${request?.currency} ${request?.minBudget} - ${request?.currency} ${request?.maxBudget}`}
							</Typography>
						</Stack>
						<Stack
							direction={"column"}
							spacing={1}
							onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated") &&
							navigator(
								`/${profile.userType}/open-requests-details`,
								{
									state: { request },
								}
							)
						}
							sx={{
								textAlign: "left",
								width: 120,
								cursor: "pointer",
							}}>
							<Typography variant={"body2"} fontWeight={500}>
								Due Date
							</Typography>
							<Typography
								variant={"body2"}
								sx={{ color: "#8F8F8F" }}>
								{request?.requestFor ===
									"Looking for funding" ||
								request.type === "looking-for-funding"
									? moment(request.fundingCloseDate).format(
											"MMM D, YYYY"
									  )
									: moment(request.deadline).format(
										"D MMM YYYY"
								  )}
							</Typography>
						</Stack>

						<Stack
							direction={"column"}
							spacing={1}
							onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated")  &&
							navigator(
								`/${profile.userType}/open-requests-details`,
								{
									state: { request },
								}
							)
						}
							sx={{
								textAlign: "left",
								width: 100,
								cursor: "pointer",
							}}>
							<Typography variant={"body2"} fontWeight={500}>
								{request?.requestFor ===
									"Looking for funding" ||
								request.type === "looking-for-funding"
									? "Round"
									: "Job type"}
							</Typography>
							<Typography
								variant={"body2"}
								sx={{ color: "#8F8F8F", textTransform: 'capitalize' }}>
								{request?.requestFor ===
									"Looking for funding" ||
								request.type === "looking-for-funding"
									? request?.round
									: request?.typeOfJob}
							</Typography>
						</Stack>
						

					</Stack>

					<Grid
						container
						onClick={() =>(request.stage!=="Closed" && request.stage!=="Deactivated")  &&
							navigator(
								`/${profile.userType}/open-requests-details`,
								{
									state: { request },
								}
							)
						}
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						gap={1}>
						{(request?.requestFor === "Looking for funding" ||
							request?.type === "looking-for-funding") && (
							<Grid item>
								<Chip
									variant="contained"
									sx={{
										my: 0,
										backgroundColor: "#B2FFFA",
										px: 0.5,
										maxWidth: 250,
										borderRadius: "5px",
									}}
									component={"p"}
									label={"Looking for funding"}
								/>
							</Grid>
						)}
						{request?.searchFor &&
							request?.searchFor.length > 0 &&
							request?.searchFor?.map((s, i) => {
								// counter.current++;
								if (mappedElements <= limit) {
									mappedElements+=1
									return (
										<Grid item key={i}>
											<Chip
												variant="contained"
												sx={{
													my: 0,
													backgroundColor: "#B8FC3C",
													px: 0.5,
													maxWidth: 250,
													borderRadius: "5px",
												}}
												component={"p"}
												label={USERS_NAME_MAPPING[s]}
												key={i}
											/>
										</Grid>
									);
								} else {
									return <></>;
								}
							})}
						{request?.skills &&
							request?.skills.length > 0 &&
							request?.skills?.map((s, i) => {
								// counter.current++;
								if (mappedElements <= limit) {
									mappedElements+=1
									return (
										<Grid item key={i}>
											<Chip
												variant="contained"
												sx={{
													my: 0,
													backgroundColor: "#5CE5D5",
													px: 0.5,
													maxWidth: 250,
													borderRadius: "5px",
												}}
												component={"p"}
												label={s}
												key={i}
											/>
										</Grid>
									);
								} else {
									return <></>;
								}
							})}
						{request?.jobType && <Grid item key={request?.jobType}>
							<Chip
								variant="contained"
								sx={{
									my: 0,
									backgroundColor: "#5ce5a1",
									px: 0.5,
									maxWidth: 250,
									borderRadius: "5px",
								}}
								component={"p"}
								label={request?.jobType}
								key={request?.jobType}
							/>
						</Grid>}
						{request?.languages &&
							request?.languages.length > 0 &&
							request?.languages?.map((s, i) => {
								// counter.current++;
								if (mappedElements <= limit) {
									mappedElements+=1
									return (
										<Grid item key={i}>
											<Chip
												variant="contained"
												sx={{
													my: 0,
													backgroundColor: "#5cbce5",
													px: 0.5,
													maxWidth: 250,
													borderRadius: "5px",
												}}
												component={"p"}
												label={s}
												key={i}
											/>
										</Grid>
									);
								} else {
									return <></>;
								}
							})}
					{request?.skills && request?.searchFor && request?.languages && request?.skills.length + request?.searchFor.length + request?.languages.length - mappedElements !==0 &&
						<Typography sx={{fontWeight:500, fontSize:13, color:"#8F8F8F"}}>
							+  {String((request?.skills.length + request?.searchFor.length+ request?.languages.length) - mappedElements)}  more
						</Typography>}
					</Grid>
					</Stack>
					<Stack direction={'column'} justifyContent={'start'}>
						
						<RequestUserAvatar request={request} key={'avatar-'+request?.id}/>
						{ request.uid ===profile?.uid &&
							<Stack direction={"column"} alignItems={"center"}> <Button
								onClick={()=>{
									if (
										request.type === "looking-for-funding" ||
										request.requestFor === "Looking for funding"
									) {
										navigator(
											`/${profile.userType}/update-funding-request/${request.id}`
										);
									} else {
										navigator(
											`/${profile.userType}/open-requests-update/${request.id}`
										);
									}
								}}
								size={"small"}
								variant={"contained"}
								color={"primary"}
								sx={{width: 95}}
								// sx={{ px: 2, color:"blue", boxShadow:"none", backgroundColor:"white", "&:hover" :{backgroundColor:"white"} }}
							>
								Edit
							</Button></Stack>}

						{/*{
							// request.stage !== "Closed" &&
							// request.stage !== "Deactivated" &&
							request.uid !== profile?.uid &&
							<StartConversationList
							data={request}
							key={request.id}
							/>
						}*/}
						
					</Stack>
					</Stack>
				</Stack>
			) : (
				<></>
			)}
		</>
	);
}
