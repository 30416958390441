import React from "react";
import {Button, Container, Autocomplete, Chip, Stack, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";

import ExperiencesFormValidation from "../../../validations/auth/experiences-form-validation";
import {useNavigate} from "react-router-dom";
import {doc, setDoc, getDoc} from "firebase/firestore";
import {auth, DB} from "../../../config/firebase/firebase";
import {AuthUserContext} from "../../../providers/auth-provider";
const preTags = [
  'Action',
  'Adult',
  'Adventure',
  'Animation',
  'Anime',
  'Biography',
  'Cdrama',
  'Comedy',
  'Crime',
  'Documentary',
  'Drama',
  'Family',
  'Fantasy',
  'Game-Show',
  'History',
  'Horror',
  'Indie Shorts',
  'Kdrama',
  'Music',
  'Musical',
  'Mystery',
  'News',
  'Reality-Tv',
  'Romance',
  'Sci-Fi',
  'Short',
  'Short Film',
  'Sport',
  'Superhero',
  'Talk-Show',
  'Thriller',
  'War',
  'Western',
];
export default function ExpertsOnboardingStep3() {
  const {setProfile} = React.useContext(AuthUserContext);

  const navigator = useNavigate()
  const [tags, setTags] = React.useState([]);
  const formik = useFormik({
    initialValues: ExperiencesFormValidation.initialValues,
    validationSchema: ExperiencesFormValidation.schema,
    onSubmit: async (values) => {
      const userRef = doc(DB, 'users', auth.currentUser.uid);
      await setDoc(userRef, {...values, skills: tags, onboard: true}, {merge: true});
      const getdoc = await getDoc(userRef);
      await setProfile(getdoc.data());
      navigator('/expert/home')
    }
  })
  return (
    <>
      <Container maxWidth={'sm'} sx={{mt: 10}}>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={5}
          component="form" onSubmit={e => formik.handleSubmit(e)}
        >
          <Typography variant={'h4'} sx={{textAlign:'center', width: '100%'}}>
            Tell us about your working experiences.
            <Typography variant={'body1'} sx={{mt:2}}>
              This will help others recognise you.
            </Typography>
          </Typography>


          <TextField
            name={'jobTitle'}
            value={formik.values.jobTitle}
            onChange={formik.handleChange}
            error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
            helperText={formik.touched.jobTitle && formik.errors.jobTitle}
            variant={'outlined'} type={"text"} label={'Your last job title'} fullWidth/>





          <Stack
            direction={"column"}
            justifyContent="flex-start"
            spacing={5}
            sx={{ width: "100%" }}>
            <Autocomplete
              multiple
              required
              id="tags-filled"
              options={preTags.map((option) => option)}
              freeSolo

              onChange={(e, newValue)=>
                setTags(newValue)}
              renderTags={(value, getTagProps) =>
                tags.map((option, index) => (
                  <Chip variant="contained"
                        sx={{
                          backgroundColor:"#E3E8F0",
                          marginLeft:"5px",
                          marginRight:"5px",
                          marginBottom:"10px",
                        }}
                        onDelete={() =>
                          setTags(
                            tags.filter(
                              (f) => f !== option
                            )
                          )
                        }
                        label={option} {...getTagProps({ index })} >
                  </Chip>
                ))

              }

              renderInput={(params) => (
                <TextField
                  {...params}
                  // variant={'standard'}
                  label="Skills"
                  placeholder="Add"
                  sx={{

                    transition:"0.1s linear",


                  }}
                />
              )}
            />
          </Stack>





          <TextField
            name={'expertise'}
            value={formik.values.expertise}
            onChange={formik.handleChange}
            error={formik.touched.expertise && Boolean(formik.errors.expertise)}
            helperText={formik.touched.expertise && formik.errors.expertise}
            variant={'outlined'} type={"text"} placeholder={'Areas of expertise'} label={'Areas of expertise'} fullWidth/>


          <Button fullWidth variant={"contained"} color={'primary'} type={'submit'} disabled={tags.length === 0}>
            Next
          </Button>
        </Stack>
      </Container>
      </>

  )
}