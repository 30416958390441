import React from 'react';
import {Avatar, Stack, Typography,Box} from "@mui/material";
import { useNavigate } from 'react-router-dom';


const Splashscreen = () => {
  const [fade, setFade] = React.useState(false);
  const navigate = useNavigate();

  
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFade(true);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate('/m/auth/signin')
    }, 3600);
    return () => clearTimeout(timeoutId);
  }, []);  

  return (
       <>
      <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="90vh"
        >
       <img
              src={"/assets/nova-logo.png"}
              aria-label={"logo"}
              style={{ height: "80px", opacity: fade ? 0 : 1, transition: "opacity 0.5s ease-out" }}
            />
       </Box>
      
       </>
    );
}

export default Splashscreen;
