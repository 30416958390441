import React, { useEffect } from "react";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Tooltip, Typography, FormControlLabel, Switch, useMediaQuery, CircularProgress
} from "@mui/material";
import { useFormik } from "formik";
import StartupOnBoardingValidation from "../../../validations/startup/startup-profile";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';
import dataJson from '../../../const/data.json';
import ServiceProviderDetailsValidation from "../../../validations/service-provider/provider-profile";
export default function EditServiceProviderOrganisationDetailsForm({ cbBack, cbNext, data, isLoading }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const formikFirst = useFormik({
    initialValues: data
      ? data
      : ServiceProviderDetailsValidation.EditOrganisationDetailsFormValues,
    validationSchema: ServiceProviderDetailsValidation.EditOrganisationDetailsForm,
    onSubmit: async (values) => {
      debugger
      cbNext({
        ...values,
      });
    }
  });
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component="form"
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>

          <TextField fullWidth
                     required
                     name={"nameOfOrganization"}
                     value={formikFirst.values.nameOfOrganization}
                     onChange={formikFirst.handleChange}
                     error={
                       formikFirst.touched.nameOfOrganization &&
                       Boolean(formikFirst.errors.nameOfOrganization)
                     }
                     helperText={
                       formikFirst.touched.nameOfOrganization &&
                       formikFirst.errors.nameOfOrganization
                     }
                     label={"Organisation name"}
          />

          <Autocomplete
            multiple={false}
            required
            fullWidth
            options={[...dataJson.cities].map((option) => option)}
            freeSolo
            onChange={(e, newValue) =>
              formikFirst.setFieldValue("countryState", newValue)
            }
            disableClearable={true}
            value={formikFirst.values?.countryState}
            renderInput={(params) => (
              <TextField
                {...params}
                name={'country'}
                onChange={formikFirst?.handleChange}
                // variant={'standard'}
                error={Boolean(formikFirst.errors.countryState)}
                helperText={
                  formikFirst.errors.countryState
                    ? "Location is required"
                    : ""
                }
                label={
                  <>
                    Location*

                  </>
                }
                placeholder="Location"
                sx={{
                  transition: "0.1s linear",
                }}
              />
            )}
          />

          <TextField
            //required
            name={"organizationDescription"}
            value={formikFirst.values.organizationDescription}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.organizationDescription &&
              Boolean(formikFirst.errors.organizationDescription)
            }
            helperText={
              formikFirst.touched.organizationDescription &&
              formikFirst.errors.organizationDescription
            }
            label={"Tell us about yourself"}
            multiline={true}
            rows={3}
          />


        </Stack>
      </Container>
      {
        matches ? <Stack
                direction={"row"}
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
                width={"100%"}>
              <Button
                  variant={"outlined"}
                  color={"primary"}
                  sx={{ px: 5 }}
                  onClick={cbBack}>
                Back
              </Button>
              {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
              <Button
                  variant={"contained"}
                  color={"primary"}
                  sx={{ px: 5 }}
                  type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
                save
              </Button>
            </Stack> :
            <Stack
                direction={"row"}
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
                width={"100%"}
                sx={{px: !matches ? 2 : ''}}
            >
              {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
              <Button
                  variant={"contained"}
                  color={"primary"}
                  sx={{ px: 5 }}
                  type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
                save
              </Button>
            </Stack>
      }
    </Stack>
  );
}
