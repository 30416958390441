import React from "react";
import {
  Button,
  Container,
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Tooltip,CircularProgress, useMediaQuery, createFilterOptions
} from "@mui/material";
import { useFormik } from "formik";
import ServiceProviderDetailsValidation from "../../../validations/service-provider/provider-profile";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';
import dataJson from '../../../const/data.json';
import { Add } from "@mui/icons-material";
const filter = createFilterOptions()

export default function ProviderAboutDetailsForm({ cbBack, cbNext, data, isLoading }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const formikFirst = useFormik({
    initialValues: data
			? data
			: ServiceProviderDetailsValidation.ProviderThirdFormValue,
		validationSchema:
		ServiceProviderDetailsValidation.ProviderThirdForm,
    onSubmit: async (values) => {
      debugger
      cbNext({
        ...values,
      });
    }
  });
  return (
    <Stack
      direction={"column"}
      spacing={10}
      component='form'
      onSubmit={(e) => formikFirst.handleSubmit(e)}>
      <Container maxWidth={"md"}>
        <Stack direction={"column"} spacing={5}>
          {/* <TextField
            required
            name={"organisationType"}
            value={formikFirst.values.organisationType}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.organisationType &&
              Boolean(formikFirst.errors.organisationType)
            }
            helperText={
              formikFirst.touched.organisationType &&
              formikFirst.errors.organisationType
            }
            label={"Service Provider Type"}
            select
            sx={{textAlign: 'left'}}
          >
            <MenuItem value={'Organisation'} >
              Organisation
            </MenuItem>
            <MenuItem value={'Individual'} >
              Individual
            </MenuItem>
          </TextField> */}
          {/* {formikFirst?.values?.organisationType === 'Organisation' && <>


            <TextField
              required={formikFirst?.values?.organisationType === 'Organisation'}
              name={"nameOfOrganization"}
              value={formikFirst.values.nameOfOrganization}
              onChange={formikFirst.handleChange}
              error={
                formikFirst.touched.nameOfOrganization &&
                Boolean(formikFirst.errors.nameOfOrganization)
              }
              helperText={
                formikFirst.touched.nameOfOrganization &&
                formikFirst.errors.nameOfOrganization
              }
              label={"Name of organisation"}
            />
            <TextField
              name={"website"}
              value={formikFirst.values.website}
              onChange={formikFirst.handleChange}
              error={
                formikFirst.touched.website &&
                Boolean(formikFirst.errors.website)
              }
              helperText={
                formikFirst.touched.website &&
                formikFirst.errors.website
              }
              label={"Website"}
            />
            <TextField
              required
              name={"organizationDescription"}
              value={formikFirst.values.organizationDescription}
              onChange={formikFirst.handleChange}
              error={
                formikFirst.touched.organizationDescription &&
                Boolean(formikFirst.errors.organizationDescription)
              }
              helperText={
                formikFirst.touched.organizationDescription &&
                formikFirst.errors.organizationDescription
              }
              label={"Description of Organisation"}
              multiline={true}
              rows={3}
            />

          </>} */}
          {/* {
            formikFirst?.values?.organisationType === 'Organisation' &&


            <TextField fullWidth
                       required={formikFirst?.values?.organisationType === 'Organisation'}
                       name={"role"}
                       value={formikFirst.values.role}
                       onChange={formikFirst.handleChange}
                       error={
                         formikFirst.touched.role &&
                         Boolean(formikFirst.errors.role)
                       }
                       helperText={
                         formikFirst.touched.role &&
                         formikFirst.errors.role
                       }
                       label={"Role"}
            />

          } */}
          <Autocomplete
            multiple
            options={[
              "Law Firm",
              "Advertising Agency"
            ].map(
              (option) => option
            )}
            value={formikFirst.values?.serviceType}
            freeSolo
            onChange={(
              e,
              newValue
            ) =>
              formikFirst.setFieldValue('serviceType', newValue)
            }
            renderTags={(
              value,
              getTagProps
            ) =>
              formikFirst.values?.serviceType &&
              formikFirst.values?.serviceType.length > 0
              &&	formikFirst.values?.serviceType.map(
                (
                  option,
                  index
                ) => (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor:
                        "#E3E8F0",
                      marginLeft:
                        "5px",
                      marginRight:
                        "5px",
                      marginBottom:
                        "10px",
                    }}
                    deleteIcon={
                      <CloseIcon />
                    }
                    onDelete={() =>
                      formikFirst.setFieldValue('serviceType',
                        formikFirst.values?.serviceType.filter(
                          (
                            f
                          ) =>
                            f !==
                            option
                        )
                      )
                    }
                    label={
                      option
                    }
                    {...getTagProps(
                      {
                        index,
                      }
                    )}></Chip>
                )
              )
            }

            renderInput={(
              params
            ) => (
              <TextField
                {...params}
                // variant={'standard'}
                fullWidth
                // required={formikFirst.values.serviceType.length === 0 }
                error={
                  formikFirst.touched.serviceType &&
                  Boolean(formikFirst.errors.serviceType)
                }

                helperText={
                  formikFirst.touched.serviceType &&
                  formikFirst.errors.serviceType
                }
                label="Service Type *"
                placeholder="Add"
                sx={{
                  transition:
                    "0.1s linear",
                }}
              />
            )}
          />


          <Stack direction={{lg:'row', xs:'column'}} spacing={5}>
            <Autocomplete
              multiple
              fullWidth
              options={[...dataJson.sector].map(
                (option) => option
              )}
              value={formikFirst.values?.sector}
              freeSolo
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
            
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                  filtered.push('Add ' + inputValue);
                }
            
                return filtered;
                }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option
                }
                // Add "xxx" option created dynamically
                if (option.value) {
                  return option.value;
                  }
                  // Regular option
                return option
                }}
              renderOption={(props, option) => 
              <li {...props} style={{alignItems:"center"}}>
                {option.includes("Add ") 
                ? 
                <><Add fontSize="medium"/>{option}</> 
                : option}
                </li>}
              onChange={(
                e,
                newValue
              ) =>{
                if(newValue.length!==0)
                  {const newOption = newValue.slice(-1)[0].replace("Add ", "")
                  newValue.splice(-1,1,newOption)}
                  
                formikFirst.setFieldValue('sector', newValue)
              }
              }
              renderTags={(
                value,
                getTagProps
              ) =>
                formikFirst.values?.sector &&
                formikFirst.values?.sector.length > 0
                &&	formikFirst.values?.sector.map(
                  (
                    option,
                    index
                  ) => (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor:
                          "#E3E8F0",
                        marginLeft:
                          "5px",
                        marginRight:
                          "5px",
                        marginBottom:
                          "10px",
                      }}
                      deleteIcon={
                        <CloseIcon />
                      }
                      onDelete={() =>
                        formikFirst.setFieldValue('sector',
                          formikFirst.values?.sector.filter(
                            (
                              f
                            ) =>
                              f !==
                              option.value ? option.value : option
                          )
                        )
                      }
                      label={
                        option.value ? option.value : option
                      }
                      {...getTagProps(
                        {
                          index,
                        }
                      )}></Chip>
                  )
                )
              }
              renderInput={(
                params
              ) => (
                <TextField
                  {...params}
                  // variant={'standard'}
                  fullWidth
                  // required={formikFirst.values.sector.length === 0 }
                  error={
                    formikFirst.touched.sector &&
                    Boolean(formikFirst.errors.sector)
                  }

                  helperText={
                    formikFirst.touched.sector &&
                    formikFirst.errors.sector
                  }
                  label="Sector"
                  placeholder="Add"
                  sx={{
                    transition:
                      "0.1s linear",
                  }}
                />
              )}
            />
            {/* <Autocomplete
              multiple
              required
              fullWidth
              freeSolo
              options={[
                // " "
              ].map((option) => option)}
              onChange={(e, newValue) =>
                formikFirst.setFieldValue(
                  "additionalDetails",
                  newValue
                )
              }
              value={formikFirst.values?.additionalDetails}
              renderTags={(value, getTagProps) =>
                formikFirst.values?.additionalDetails &&
                formikFirst.values?.additionalDetails.length >
                0 &&
                formikFirst.values?.additionalDetails.map(
                  (option, index) => (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#F6F8FA",
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginBottom: "10px",
                        borderRadius: "10px"
                      }}
                      deleteIcon={<CloseIcon />}
                      onDelete={() =>
                        formikFirst.setFieldValue(
                          "additionalDetails",
                          formikFirst.values?.additionalDetails.filter(
                            (f) => f !== option
                          )
                        )
                      }
                      label={option}
                      {...getTagProps({
                        index,
                      })}></Chip>
                  )
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // variant={'standard'}

                  label="Additional Details"
                  name={"additionalDetails"}
                  placeholder="Add"
                  sx={{
                    transition: "0.1s linear",
                  }}
                />
              )}
            /> */}



            {/* <TextField
							name={"additionalDetails"}
							value={formikFirst.values.additionalDetails}
							onChange={formikFirst.handleChange}
							error={
								formikFirst.touched.additionalDetails &&
								Boolean(formikFirst.errors.additionalDetails)
							}
							fullWidth
							helperText={
								formikFirst.touched.additionalDetails &&
								formikFirst.errors.additionalDetails
							}
							label={"Additional details"}
						/> */}


          </Stack>

          <Autocomplete
            multiple
            required
            fullWidth
            options={[
              ...dataJson.markets
            ].map(
              (option) => option
            )}
            freeSolo
            onChange={(
              e,
              newValue
            ) =>
              formikFirst.setFieldValue('geographies', newValue)
            }
            value={formikFirst.values?.geographies}
            renderTags={(
              value,
              getTagProps
            ) =>
              formikFirst.values?.geographies &&
              formikFirst.values?.geographies.length > 0
              &&	formikFirst.values?.geographies.map(
                (
                  option,
                  index
                ) => (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor:
                        "#E3E8F0",
                      marginLeft:
                        "5px",
                      marginRight:
                        "5px",
                      marginBottom:
                        "10px",
                    }}
                    deleteIcon={
                      <CloseIcon />
                    }
                    onDelete={() =>
                      formikFirst.setFieldValue('geographies',
                        formikFirst.values?.geographies.filter(
                          (
                            f
                          ) =>
                            f !==
                            option
                        )
                      )
                    }
                    label={
                      option
                    }
                    {...getTagProps(
                      {
                        index,
                      }
                    )}></Chip>
                )
              )
            }
            renderInput={(
              params
            ) => (
              <TextField
                {...params}
                // variant={'standard'}
                // label="Geographies"
                error={Boolean(formikFirst.errors.geographies)}
								helperText={
									formikFirst.errors.geographies
										? "Geography is required"
										: ""
								}
                label={
                  <>
                    Geographies *
                    <Tooltip title="Countries and regions" arrow placement="top-start">

                      <InfoIcon  fontSize="small" style={{ marginLeft:"6px",color:"#D9D9D9"}}/></Tooltip>
                  </>
                }

                placeholder="Add"
                sx={{
                  transition:
                    "0.1s linear",
                }}
              />
            )}
          />

<Autocomplete
            multiple
            required
            fullWidth
            options={[
              ...dataJson.languages
            ].map(
              (option) => option
            )}
            freeSolo
            onChange={(
              e,
              newValue
            ) =>
              formikFirst.setFieldValue('languages', newValue)
            }
            value={formikFirst.values?.languages}
            renderTags={(
              value,
              getTagProps
            ) =>
              formikFirst.values?.languages &&
              formikFirst.values?.languages.length > 0
              &&	formikFirst.values?.languages.map(
                (
                  option,
                  index
                ) => (
                  <Chip
                    variant="contained"
                    sx={{
                      backgroundColor:
                        "#E3E8F0",
                      marginLeft:
                        "5px",
                      marginRight:
                        "5px",
                      marginBottom:
                        "10px",
                    }}
                    deleteIcon={
                      <CloseIcon />
                    }
                    onDelete={() =>
                      formikFirst.setFieldValue('languages',
                        formikFirst.values?.languages.filter(
                          (
                            f
                          ) =>
                            f !==
                            option
                        )
                      )
                    }
                    label={
                      option
                    }
                    {...getTagProps(
                      {
                        index,
                      }
                    )}></Chip>
                )
              )
            }
            renderInput={(
              params
            ) => (
              <TextField
                {...params}
                // variant={'standard'}
                // label="languages"
                error={Boolean(formikFirst.errors.languages)}
								helperText={
									formikFirst.errors.languages
										? "Language is required"
										: ""
								}
                label={
                  <>
                    Fluent Languages *
                    <Tooltip title="Countries and regions" arrow placement="top-start">

                      <InfoIcon  fontSize="small" style={{ marginLeft:"6px",color:"#D9D9D9"}}/></Tooltip>
                  </>
                }

                placeholder="Add"
                sx={{
                  transition:
                    "0.1s linear",
                }}
              />
            )}
          />
          <TextField
            name={"relevantYearOfExperience"}
            value={formikFirst.values.relevantYearOfExperience}
            onChange={formikFirst.handleChange}
            error={
              formikFirst.touched.relevantYearOfExperience &&
              Boolean(formikFirst.errors.relevantYearOfExperience)
            }
            fullWidth
            helperText={
              formikFirst.touched.relevantYearOfExperience &&
              formikFirst.errors.relevantYearOfExperience
            }
            label={"Relevant Years of Experience"}
            sx={{textAlign: 'left'}}
            type={'number'}
          />
        </Stack>
      </Container>
      {
        matches ? <Stack
            direction={"row"}
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
            width={"100%"}>
            <Button
              variant={"outlined"}
              color={"primary"}
              sx={{ px: 5 }}
              onClick={cbBack}>
              Back
            </Button>
            {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{ px: 5 }}
              type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
              save
            </Button>
          </Stack> :
          <Stack
            direction={"row"}
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
            width={"100%"}
            sx={{px: !matches ? 2 : ''}}
          >
            {/* <Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					onClick={cbBack}>
					Skip and Submit
				</Button> */}
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{ px: 5 }}
              type={"submit"} disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} />}>
              save
            </Button>
          </Stack>
      }
    </Stack>
  );
}
