import * as React from "react";
import {
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { DB } from "../../../config/firebase/firebase";
import {
  Avatar,
  Button,
  Portal,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { AuthUserContext } from "../../../providers/auth-provider";
import { useNavigate } from "react-router-dom";
import RequestMatchesProfiles from "../../../api/request-matches-profiles";

export default function RequestItemAvatar({ request }) {
  const { profile } = React.useContext(AuthUserContext);
  const navigator = useNavigate();
  const [user, setUser] = React?.useState();
  const [loading, setLoading] = React?.useState(false);
  const [open, setOpen] = React?.useState(false);
  const [applied, setApplied] = React?.useState(false);
  const [counts, setCounts] = React?.useState(0);
  const [members, setMembers] = React.useState();

  React.useEffect(() => {
    if (request) {
      const fetchProfileMatchesData = async () => {
        const data = await RequestMatchesProfiles(request);
        setMembers(data);
      };
      fetchProfileMatchesData().catch(console.error);
    }

    setLoading(true);
    const userRef = doc(DB, "users", request?.uid);
    getDoc(userRef).then((doc) => {
      setUser(doc?.data());
      setLoading(false);
    });
  }, [request?.id]);
  React.useEffect(() => {
    setLoading(true);
    const q = query(
      collection(DB, "applicants"),
      where("requestId", "==", request?.id),
      orderBy("createdAt", "desc")
    );
    let unsub;
    unsub = onSnapshot(q, (snapshot) => {
      setCounts(snapshot?.size);
      snapshot.forEach((doc) => {
        doc?.data()?.applicantUid === profile?.uid && setApplied(true);
      });
    });
    return () => unsub;
  }, [request?.id]);
  return (
    <React.Fragment>
      {!loading ? (
        <Stack
          direction={"column"}
          spacing={0.5}
          width={150}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Avatar
            aria-label={user?.displayName}
            src={user?.photoURL}
            sx={{ width: 51, height: 51 }}
          >
            <Typography variant={"h5"}>
              {user?.displayName?.charAt(0)}
            </Typography>
          </Avatar>
          <Typography
            variant={"body1"}
            fontWeight={400}
            color={"#363636"}
            maxWidth={97}
            noWrap={true}
          >
            {user?.displayName}
          </Typography>

          {request.uid !== profile?.uid && !applied && (
            <Button
              variant={"contained"}
              size={"small"}
              sx={{ px: 2 }}
              onClick={() =>
                navigator(`/${profile.userType}/request/details`, {
                  state: {request, members },
                })
              }
            >
              View
            </Button>
          )}
          {applied === true && (
            <Button
              variant={"contained"}
              color={"secondary"}
              size={"small"}
              sx={{ px: 2 }}
              onClick={() => setOpen(!open)}
              disabled
            >
              Applied
            </Button>
          )}
          <Typography variant={"caption"} color={"#8F8F8F"}>
            {counts > 0 &&
              `${counts === 1 ? "1 applicant" : `Over ${counts} applicants`}`}
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction={"column"}
          spacing={0.5}
          width={150}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Skeleton variant={"circular"} height={51} width={51} />
          <Skeleton variant={"text"} />
        </Stack>
      )}
    </React.Fragment>
  );
}
