import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Typography,
  Grid,
  Chip,
} from "@mui/material";
import moment from "moment";
import StartConversation from "../../components/common/start-conversation";
import { AuthUserContext } from "../../providers/auth-provider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { fontWeight } from "@mui/system";
import { DB } from "../../config/firebase/firebase";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import RequestMatches from "../../components/common/request-matches";
import RequestMatchesProfiles from "../../api/request-matches-profiles";
import {
  USERS_NAME_MAPPING,
  USER_TYPES_TO_NAMEC,
} from "../../utils/enums/labels.enum";
import IconButton from "@mui/material/IconButton";
import { SUGGESTED_STATUS } from "../../utils/enums/suggestions-state";
import PostNotification from "../../api/post-notification";
import NotificationEmail from "../../api/notification-email";
import GetAdminSuuggestedProfiles from "../../api/get-admin-suggested-profiles";
import AdminSuggestedMatches from "../../components/common/admin-suggested";

export default function RequestDetails() {
  const { profile } = React.useContext(AuthUserContext);
  const [matches, setMatches] = React.useState(null);
  const [request, setRequest] = React.useState();
  const [suggested, setSuggested] = React.useState();
  const [suggestedProfiles, setSuggestedProfiles] = React.useState();
  const location = useLocation();
  const navigator = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const suggestedUserId = searchParams.get("suggestedUserId");
  console.log("suggestedUserId", suggestedUserId);
  debugger
  const req = location?.state?.request;
  const { requestId } = useParams();

  const getUniqueAndSortedMatches = (matches, sortByKey) => {
    const uniqueMatches = [];
    const sortedMatches = [];

    // Remove duplicates based on a specific key
    matches.forEach((match) => {
      const existingMatch = uniqueMatches.find(
        (item) => item.email === match.email
      );
      if (!existingMatch) {
        uniqueMatches.push(match);
      }
    });

    // Sort the array by the key (if it exists in the objects)
    if (sortByKey) {
      sortedMatches.push(
        ...uniqueMatches.filter((item) => item.hasOwnProperty(sortByKey))
      );
      const unsortedMatches = uniqueMatches.filter(
        (item) => !item.hasOwnProperty(sortByKey)
      );
      sortedMatches.push(
        ...unsortedMatches.sort((a, b) => {
          if (a[sortByKey] < b[sortByKey]) {
            return -1;
          } else if (a[sortByKey] > b[sortByKey]) {
            return 1;
          }
          return 0;
        })
      );
    } else {
      sortedMatches.push(...uniqueMatches);
    }

    return sortedMatches;
  };

  const handleReload = (status) => {
    if (status == SUGGESTED_STATUS.DECLINED) navigator(`/${profile.userType}/open-requests`)
    else window.location.reload();
  };
  const upateSuggestStatus = (reqId, userId, status) => {
    console.log(reqId, userId, status);
    const colRef = doc(DB, "help-requests", reqId, "users-suggested", userId);
    setDoc(
      colRef,
      {
        status: SUGGESTED_STATUS[status],
        updatedAt: serverTimestamp(),
      },
      { merge: true }
    ).then(async () => {
      const colRef = doc(DB, "users", userId, "requests-suggested", reqId);
      setDoc(
        colRef,
        {
          status: SUGGESTED_STATUS[status],
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      ).then(() => {
        if (status == SUGGESTED_STATUS.ACCEPTED) {
          PostNotification({
            type: "accept-suggest-request",
            imageURL: "/assets/system-noti-icon.png",
            message: `An admin has proposed a candidate for your job request.`,
            uid: request?.creatorData?.uid,
            suggestedUserId: userId,
            requestFor: request?.requestFor,
            request: reqId,
          }).then();
        }
        handleReload(status);
      });
    });
  };
  React.useEffect(() => {
    if (req) setRequest(req);
    if (!request && requestId) {
      const reqRef = doc(DB, "help-requests", requestId);
      getDoc(reqRef).then((doc) => {
        setRequest({ id: requestId, ...doc.data() });
      });
    }
  }, [requestId]);
  React.useEffect(() => {
    if (requestId && request?.creatorData?.uid !== profile?.uid) {
      let unsubs = getDoc(
        doc(DB, "help-requests", requestId, "users-suggested", profile?.uid)
      ).then((getdoc) => {
        if (getdoc.exists()) {
          setSuggested({ id: profile?.uid, ...getdoc.data() });
          console.log("suggested user data", JSON.stringify(getdoc.data()));
        }
      });
      return () => unsubs;
    }
  }, [requestId]);
  React.useEffect(() => {
    if (request?.creatorData?.uid === profile?.uid) {
      const fetchProfileMatchesData = async () => {
        const data = await RequestMatchesProfiles(request);
        setMatches(data);
      };
      fetchProfileMatchesData().catch(console.error);
    }
  }, [request]);
  React.useEffect(() => {
    if (request?.creatorData?.uid === profile?.uid) {
      const fetchProfileMatchesData = async () => {
        const data = await RequestMatchesProfiles(request);
        setMatches(data);
      };
      fetchProfileMatchesData().catch(console.error);
    }
  }, [request]);
  React.useEffect(() => {
    console.log("requestId 3", request?.id);
    if (request?.creatorData?.uid === profile?.uid && request?.id) {
      const fetchProfileMatchesData = async () => {
        const data = await GetAdminSuuggestedProfiles(request?.id);
        setSuggestedProfiles(data);
      };
      fetchProfileMatchesData().catch(console.error);
    }
  }, [request]);
  function currencyToSymbol(currency) {
    if (currency == "USD") {
      return "$";
    } else if (currency == "GBP") {
      return "£";
    } else {
      return "€";
    }
  }
  // var postRequestUser = Object.entries(request);
  // postRequestUser = postRequestUser[16][1];
  // console.log(postRequestUser);

  return (
    <Container maxWidth={"xl"} sx={{ py: 5 }}>
      {suggested && suggested.status == SUGGESTED_STATUS.PENDING && (
        <Stack py={2} direction="row" spacing={10} sx={{ flex: 0.7 }}>
          <Box maxWidth={250} />
          <Box
            sx={{
              flex: 0.74,
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              background: "#fff",
              borderRadius: "15px",
              height: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            <Typography variant="body2" fontWeight={"500"}>
              Do you want to accept this request suggested by the Admin?
            </Typography>
            <Stack direction="row" spacing={2}>
              <IconButton
                onClick={() =>
                  upateSuggestStatus(
                    requestId,
                    profile?.uid,
                    SUGGESTED_STATUS.DECLINED
                  )
                }
              >
                <HighlightOffRoundedIcon fontSize={"medium"} color="primary" />
              </IconButton>
              <IconButton
                onClick={() =>
                  upateSuggestStatus(
                    requestId,
                    profile?.uid,
                    SUGGESTED_STATUS.ACCEPTED
                  )
                }
              >
                <CheckCircleRoundedIcon fontSize={"medium"} color="primary" />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      )}
      {request && (
        <Stack direction={"row"} spacing={10} justifyContent={"center"}>
          <Box maxWidth={230} />
          <Box
            sx={{
              flex: 1,
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              background: "#fff",
              borderRadius: "15px",
            }}
          >
            <Stack direction={"column"} sx={{ flex: 0.7 }}>
              <Stack
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  minHeight: 80,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
                direction={"row"}
                spacing={4}
              >
                <ArrowBackIcon
                  onClick={() => navigator(-1)}
                  sx={{ fontSize: 40 }}
                />
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Typography variant={"h6"}>{request.requestFor}</Typography>
                  <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                    Posted{" "}
                    {moment()
                      .from(request?.createdAt.seconds * 1000)
                      .slice(2)}{" "}
                    ago
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  minHeight: 140,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
              >
                <Typography
                  variant={"h6"}
                  color={"primary"}
                  sx={{ fontWeight: "500" }}
                >
                  {request?.requestFor === "Looking for funding" ||
                    request.type === "looking-for-funding"
                    ? "Round"
                    : "Searching For"}
                </Typography>
                <Box container>
                  {!(
                    request?.requestFor === "Looking for funding" ||
                    request.type === "looking-for-funding"
                  ) ? (
                    request?.searchFor &&
                    request?.searchFor.map((t) => (
                      <Chip
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginRight: "8px",
                          marginBottom: "10px",
                          maxWidth: 250,
                          borderRadius: "10px",
                          fontWeight: "500",
                        }}
                        component={"p"}
                        label={USERS_NAME_MAPPING[t]}
                      />
                    ))
                  ) : (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginRight: "5px",
                        marginBottom: "10px",
                        maxWidth: 250,
                      }}
                      component={"p"}
                      label={request?.round}
                    />
                  )}
                </Box>
              </Stack>
              {/* Stack for location, job type, due date */}
              {request?.creatorData?.uid === profile?.uid && (
                <Stack
                  sx={{
                    minHeight: 70,
                    width: "100%",
                    borderBottom: "1px solid #ccc",
                    p: 1,
                    px: 3,
                  }}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography variant={"body2"} sx={{ color: "#363636" }}>
                    {request?.location}
                  </Typography>
                  <span />

                  <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                    Job Type
                  </Typography>

                  <Typography variant={"body2"} sx={{ color: "#363636" }}>
                    {request?.jobType}
                  </Typography>

                  <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                    Due Date:
                  </Typography>

                  <Typography variant={"body2"} sx={{ color: "#363636" }}>
                    {moment(request?.deadline).format("D MMM YYYY")}
                  </Typography>
                </Stack>
              )}
              <Stack
                sx={{
                  minHeight: 150,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                  whiteSpace: "pre-line"
                }}
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography
                  variant={"body2"}
                  textAlign={"justify"}
                  sx={{ color: "#363636" }}
                >
                  {request.description}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  minHeight: 70,
                  width: "100%",
                  borderBottom: "1px solid #ccc",
                  p: 1,
                  px: 3,
                }}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <img src={"/assets/budget-req.png"} />
                <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                  {request?.requestFor === "Looking for funding" ||
                    request.type === "looking-for-funding"
                    ? "Amount to raise"
                    : "Budget"}{" "}
                </Typography>
                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {request?.requestFor === "Looking for funding" ||
                    request.type === "looking-for-funding"
                    ? `${request?.currency} ${request?.amount}`
                    : `${currencyToSymbol(request?.currency)} ${request?.minBudget
                    } - ${currencyToSymbol(request?.currency)} ${request?.maxBudget
                    }`}
                </Typography>
                <span />
                {request?.requestFor === "Looking for funding" ||
                  (request.type === "looking-for-funding" && (
                    <>
                      {" "}
                      <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                        Valuation
                      </Typography>
                      <Typography variant={"body2"} sx={{ color: "#363636" }}>
                        {request?.valuationCurrency} ${request?.valuation}
                      </Typography>
                      <span />
                    </>
                  ))}

                <img src={"/assets/timeline-cal.png"} />
                <Typography variant={"body2"} sx={{ color: "#8F8F8F" }}>
                  {request?.requestFor === "Looking for funding" ||
                    request.type === "looking-for-funding"
                    ? "Close Date"
                    : "Timeline"}
                </Typography>

                <Typography variant={"body2"} sx={{ color: "#363636" }}>
                  {request?.requestFor === "Looking for funding" ||
                    request.type === "looking-for-funding"
                    ? moment(request.fundingCloseDate).format("MMM D, YYYY")
                    : `${moment(request.from).format("MMM D, YYYY")} - ${moment(
                      request.to
                    ).format("MMM D, YYYY")}`}
                </Typography>
              </Stack>
              <Stack
                sx={{ minHeight: 150, width: "100%", p: 1, px: 3 }}
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                <Typography variant={"h6"} sx={{ fontWeight: "500" }}>
                  {request?.requestFor === "Looking for funding" ||
                    request.type === "looking-for-funding"
                    ? "Revenue Model"
                    : "Skills and expertise"}{" "}
                </Typography>
                <Box container>
                  {request?.requestFor === "Looking for funding" ||
                    request.type === "looking-for-funding" ? (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginRight: "5px",
                        marginBottom: "10px",
                        maxWidth: 250,
                      }}
                      component={"p"}
                      label={request?.revenueModel}
                    />
                  ) : (
                    request?.skills &&
                    request?.skills.map((t) => (
                      <Chip
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginRight: "8px",
                          marginBottom: "10px",
                          maxWidth: 250,
                          borderRadius: "10px",
                          fontWeight: "500",
                        }}
                        component={"p"}
                        label={t}
                      />
                    ))
                  )}
                </Box>
              </Stack>

              <Stack
                sx={{ minHeight: 150, width: "100%", p: 1, px: 3 }}
                direction={"column"}
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                <Typography variant={"h6"} sx={{ fontWeight: "500" }}>
                  Languages
                </Typography>
                <Box container>
                  {
                    request?.languages &&
                    request?.languages?.map((t) => (
                      <Chip
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginRight: "8px",
                          marginBottom: "10px",
                          maxWidth: 250,
                          borderRadius: "10px",
                          fontWeight: "500",
                        }}
                        component={"p"}
                        label={t}
                      />
                    ))}
                </Box>
              </Stack>
            </Stack>
            </Box>
            <Stack direction={"column"}  spacing={3} width={230} alignItems={"center"}
          >
            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems="center"
              sx={{
                minHeight: 220,
                width: "100%",
              }}
              sapcing={2}
            >
              <Stack
                sx={{
                  marginBottom: "10px",
                }}
              >
                {suggestedProfiles && suggestedProfiles.length > 0 && (
                  <AdminSuggestedMatches
                    matches={matches ? matches : []}
                    moreMatches={suggestedProfiles ? suggestedProfiles : []}
                    suggestedUserId={suggestedUserId}
                  />
                )}
              </Stack>
              {
                matches && matches.length === 0 ?
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{
                      width: "80%"
                      , height: 250, border: '1px solid #000', padding: 2, marginX: 'auto', boxSizing: 'border-box15'
                    }}
                  >
                    <Typography variant={'h6'} fontWeight={500} color={'#000'} textAlign={'center'}>
                      <font color={'#000'}>There are no</font>
                      <br />Best Matches
                      <font color={' #000'}> yet.</font>
                    </Typography>
                  </Stack> :
                  <Stack
                    sx={{
                      marginBottom: "0px",
                    }}
                  >
                    {matches && matches.length > 0 && (
                      <RequestMatches
                        matches={matches ? matches : []}
                        moreMatches={suggestedProfiles ? suggestedProfiles : []}
                      />
                    )}
                  </Stack>}
              {request?.creatorData?.uid !== profile?.uid && (
                <>
                  <Stack
                    position={"static"}
                    sx={{
                      boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                      background: "#fff",
                      borderRadius: "15px",
                      height: "fit-content",
                      width: 250,
                      py: 1,
                    }}
                    direction={"column"}
                    spacing={1}
                  >
                    <Stack
                      alignItems="center"
                      sx={{
                        marginBottom: "16px",
                      }}
                    >
                      {request?.creatorData?.uid !== profile?.uid ? (
                        <>
                          <Avatar
                            sx={{ height: 70, width: 70 }}
                            imgProps={{ sx: { objectFit: "cover" } }}
                            src={
                              request?.creatorData?.userType ===
                                "service-provider"
                                ? request?.creatorData?.serviceProvider
                                  ?.photoURL
                                : request?.creatorData?.userType === "startup"
                                  ? request?.creatorData?.startup?.logoURL
                                  : request?.creatorData?.photoURL
                            }
                          >
                            {request?.creatorData?.userType ===
                              "service-provider" ? (
                              <Typography variant={"h4"}>
                                {request.creatorData?.serviceProvider
                                  ?.providerName &&
                                  request.creatorData?.serviceProvider?.providerName.charAt(
                                    0
                                  )}
                              </Typography>
                            ) : request?.creatorData?.userType === "startup" ? (
                              <Typography variant={"h4"}>
                                {request.creatorData?.startup?.startupName &&
                                  request.creatorData?.startup?.startupName.charAt(
                                    0
                                  )}
                              </Typography>
                            ) : (
                              <Typography variant={"h4"}>
                                {request.creatorData?.displayName &&
                                  request.creatorData?.displayName.charAt(0)}
                              </Typography>
                            )}
                          </Avatar>
                        </>
                      ) : (
                        <Avatar
                          sx={{ height: 70, width: 70 }}
                          imgProps={{ sx: { objectFit: "cover" } }}
                          src={
                            request?.creatorData?.userType ===
                              "service-provider"
                              ? request?.creatorData?.serviceProvider?.photoURL
                              : request?.creatorData?.userType === "startup"
                                ? request?.creatorData?.startup?.founder?.photoURL
                                : request?.creatorData?.photoURL
                          }
                        ></Avatar>
                      )}
                    </Stack>

                    <Stack
                      alignItems="center"
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      {request.uid !== profile?.uid &&
                        request?.creatorData?.userType === "service-provider" ? (
                        <Typography>
                          {request.creatorData?.serviceProvider?.providerName &&
                            request.creatorData?.serviceProvider?.providerName}
                        </Typography>
                      ) : request?.creatorData?.userType === "startup" ? (
                        <Typography>
                          {request.creatorData?.startup?.startupName &&
                            request.creatorData?.startup?.startupName}
                        </Typography>
                      ) : (
                        <Typography>
                          {request.creatorData?.displayName &&
                            request.creatorData?.displayName}
                        </Typography>
                      )}
                    </Stack>

                    <Stack
                      alignItems="center"
                      sx={{
                        width: "100%",
                        marginBottom: "10px",
                      }}
                    >
                      {request.uid !== profile?.uid && (
                        <StartConversation data={request} key={request.id} />
                      )}
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        minHeight: 80,
                        width: "100%",
                        borderBottom: "1px solid #ccc",
                      }}
                      spacing={2}
                    >
                      <Typography variant={"h6"}>
                        {request?.location
                          ? request?.location
                          : request?.creatorData?.startup &&
                          request?.creatorData?.startup.location}
                      </Typography>
                    </Stack>

                    <Stack
                      direction={"column"}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{ minHeight: 140, width: "100%", p: 2 }}
                      spacing={2}
                    >
                      {request?.requestFor === "Looking for funding" ||
                        request.type === "looking-for-funding" ? (
                        <>
                          <Stack
                            direction={"row"}
                            spacing={3}
                            sx={{ width: "100%" }}
                          >
                            <Typography
                              variant={"body2"}
                              fontWeight={500}
                              sx={{ flex: 1, textAlign: "left" }}
                            >
                              Female in founding team
                            </Typography>
                            <Typography
                              variant={"body2"}
                              sx={{ color: "#8F8F8F" }}
                            >
                              {request?.femaleInFoundingTeam ? "Yes" : "No"}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{ width: "100%" }}
                          >
                            <Typography
                              variant={"body2"}
                              fontWeight={500}
                              sx={{ flex: 1, textAlign: "left" }}
                            >
                              Ethnic Minority in founding team
                            </Typography>
                            <Typography
                              variant={"body2"}
                              sx={{ color: "#8F8F8F" }}
                            >
                              {request?.ethnicMinorityInFundingTeam
                                ? "Yes"
                                : "No"}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{ width: "100%" }}
                          >
                            <Typography
                              variant={"body2"}
                              fontWeight={500}
                              sx={{ flex: 1, textAlign: "left" }}
                            >
                              Immigrant in founding team
                            </Typography>
                            <Typography
                              variant={"body2"}
                              sx={{ color: "#8F8F8F" }}
                            >
                              {request?.immigrantInFundingTeam ? "Yes" : "No"}
                            </Typography>
                          </Stack>
                        </>
                      ) : (
                        <>
                          <Typography>
                            <b>Job type : </b>
                            {request?.jobType}
                          </Typography>
                          <Typography>
                            <b>Due Date : </b>{" "}
                            {moment(request.deadline).format("D MMM YYYY")}
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
            <Stack>
              {request?.creatorData?.uid === profile?.uid && (
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: 250 }}
                >
                  <Button
                    size={"small"}
                    variant={"contained"}
                    sx={{ px: 2 }}
                    onClick={() =>
                      navigator(
                        `/${profile.userType}/open-requests-update/${request.id}`
                      )
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    size={"small"}
                    variant={"contained"}
                    sx={{ px: 2 }}
                    onClick={() =>
                      navigator(`/${profile.userType}/applicants/${request.id}`)
                    }
                  >
                    View applicants
                  </Button>
                </Stack>
              )}
  
            </Stack>
          </Stack>
        </Stack>
      )}
    </Container>
  );
}
