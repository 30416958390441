import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {Box, Stack, Tooltip} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const types = [


  {
    title: 'Request for Assistance',
    id: 'requestForAssistance',
    tooltiptext:"A request for assistance is a formal way of asking for help or support."

  },
  {
    title: 'Request for Funding',
    id: 'requestForFunding',
    tooltiptext:"It is a formal way of asking for financial support to launch and grow a new business."

  }
];

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, borderBottom: '1px solid #ccc'}} {...other} >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PostRequestDialog({data, callback}) {

  const [open, setOpen] = React.useState(false);

  const [selected, setSelected] = React.useState();

  const handleClose = () => {
    setOpen(false);
    callback({close: !open, selected})
  };
  React.useEffect( () => {
    setOpen(data);
  }, [data])

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography variant={'h5'} fontWeight={600}>Post a Request</Typography>
      </BootstrapDialogTitle>
      <DialogContent>


        <Stack direction={'column'} spacing={1} sx={{width: '100%'}}>
          <Typography sx={{mt: 4}} variant={'body2'} fontWeight={600}>
            Select type of post
          </Typography>
          <Grid container spacing={2} alignItems="center">
            {
              types
                .map((tier, i) => (
                  <Grid
                    item
                    key={tier.title}

                  >

                      <Box onClick={() => setSelected(tier.id)}
                           sx={{
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                             flexDirection:'column',
                             mb: 2,
                             width:250,
                             height: 167, backgroundColor: `${tier.id !== selected ? '#ffffff': '#E0E3FF' }`,
                             borderRadius: '10px'
                             ,border:`${tier.id === selected ? '#ffffff': '1px solid #233DFF' }`
                           }}>
                        <div style={{width: '90%', textAlign:'right', color: `${tier.id !== selected ? '#A9ABAE' : '#ffffff' }`, position:'relative', bottom: '8%'}}>
                          {tier.id !== selected ?<RadioButtonUncheckedIcon style={{fontSize:20 ,marginRight:"5px",color: tier.id !== selected ? '#A9ABAE': '#204488;' }}/> :
                            <CheckCircleIcon style={{fontSize:20 ,marginRight:"5px",color: tier.id !== selected ? '#A9ABAE': '#204488' }}/>
                          }
                        </div>
                        <Typography  variant="h6"  component="p" color={tier.id !== selected ? '#000': '#204488'}  fontWeight={600}>
                          {tier.title}

                        </Typography>
                        <div style={{
                          width: '90%',
                          textAlign:'right',
                          color: `${tier.id !== selected ? '#A9ABAE' : '#ffffff' }`,
                          position:'relative',
                          top: '12%'
                        }}>
                          <Tooltip  componentsProps={{
                            tooltip: {

                              sx: {
                                color: 'white',
                                maxWidth: "250px",
                                paddingTop: 2 ,
                                height: 75,
                                fontSize:10,
                                textAlign: 'start',
                                marginRight:"20%",


                                bgcolor: '#204488',
                                '& .MuiTooltip-arrow': {
                                  color: '#E3E8F0',
                                },
                              },
                            },
                          }} title={tier.tooltiptext } arrow sx={{width:"40px",marginLeft:"100px"}}  >
                            <IconButton>
                              <HelpOutlineIcon style={{fontSize:20 ,marginLeft:"10px" ,color: tier.id !== selected ? '#A9ABAE': '#204488' }}/>
                            </IconButton>
                          </Tooltip>


                        </div>
                      </Box>



                  </Grid>
                ))
            }
          </Grid>
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button autoFocus variant={"contained"}  sx={{my: 1, mx: 1,  px: 3}} onClick={handleClose}>
          PROCEED
        </Button>
      </DialogActions>
    </BootstrapDialog>

  );
}