import React from "react";
import { getStorage, ref, deleteObject } from "firebase/storage";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Badge,
  IconButton,
  Avatar,
  useMediaQuery,
  styled,
  CircularProgress,
  Link,
  Grid,
  Portal,
  Dialog,
  DialogContent
} from "@mui/material";
import { useFormik, FieldArray, getIn, FormikProvider } from "formik";
import InvestorProfileValidation from "../../../validations/investor/investor-profile";
import DragDropFiles, {
  DragDropItem,
} from "../../../components/common/drag-drop-files";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthUserContext } from "../../../providers/auth-provider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFile from "../../../utils/upload-file";
import DialogTitle from "@mui/material/DialogTitle";
import {ArrowBack} from "@mui/icons-material";

export default function InvestorPortfolioForm({ cbBack, cbNext, data, index, open, handleClose, uid }) {

  const { user } = React.useContext(AuthUserContext);
  
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const Input = styled("input")({
    display: "none",
  });

  const fileTypes = [
    "JPG",
    "JPEG",
    "PNG",
    "GIF",
    "DOC",
    "DOCX",
    "PDF",
    "MOV",
    "XLS",
    "CSV",
  ];
  const [files, setFiles] = React.useState(
    data && data?.attachments ? data?.attachments : []
  );

  const formikFirst = useFormik({
    initialValues: data
      ? data
      : InvestorProfileValidation.InvestorFiveFormValues,
    validationSchema: InvestorProfileValidation.InvestorFiveForm,
    onSubmit: async (values) => {
      debugger;
      cbNext(
        {
          ...values,
          photoURL: profileImage.photoURL,
          attachments: files.length > 0 ? files : [],
        },
        index
      );
    },
  });
  const [profileImage, setProfileImage] = React.useState({
    src: data?.photoURL || "",
    photoURL: data?.photoURL || "",
  });
  const [loading, setLoading] = React.useState(false);
  function commaAmount(amount) {
    let result = "";
    let counter = 0;
    for (let i = amount.length - 1; i >= 0; i -= 1) {
      if (counter != 0 && counter % 3 === 0) {
        result = `,${result}`;
      }
      counter += 1;
      result = `${amount[i]}${result}`;
    }
    return result;
  }
  if (matches) {
    return (
      <FormikProvider value={formikFirst}>
        <Stack
          direction={"column"}
          spacing={10}
          component="form"
          onSubmit={(e) => formikFirst.handleSubmit(e)}
        >
          <Container maxWidth={"md"}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{mb: 5}}
              >
                <label htmlFor="profile-image">
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <IconButton color={"primary"} component={"span"}>
                        <AddCircleIcon/>
                      </IconButton>
                    }
                  >
                    <Avatar
                      alt={"startup-picture"}
                      src={
                        profileImage && profileImage?.src
                          ? profileImage?.src
                          : "/assets/startup-placeholder-image.png"
                      }
                      sx={{
                        width: 128,
                        height: 128,
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        border: "1px solid #E0E3FF",
                      }}
                      imgProps={{
                        sx: {objectFit: "scale-down"},
                      }}
                      component={"span"}
                    />
                  </Badge>
                  <Input
                    accept="image/*"
                    id="profile-image"
                    multiple={false}
                    type="file"
                    onChange={(e) => {
                      const fileReader = new FileReader();
                      fileReader.readAsDataURL(e.target.files[0]);
                      fileReader.addEventListener("load", async function () {
                        setLoading(true);
                        const file = e.target.files[0];
                        const path = uid ?`investors/${uid}/portfolio/${file.name}`: `investors/${user.uid}/portfolio/${file.name}`;
                        const imageObject = {
                          progress: 0,
                          src: this.result,
                          path,
                          file: e.target.files[0],
                          size: (e.target.files[0]?.size / 1024 / 1024).toFixed(
                            2
                          ),
                        };
                        setProfileImage(imageObject);
                        UploadFile(file, path).then((url) => {
                          setProfileImage({
                            ...imageObject,
                            photoURL: url,
                          });
                          setLoading(false);
                        });
                      });
                    }}
                  />
                </label>
                {loading && (
                  <CircularProgress sx={{ml: 2}} size={20} color="primary"/>
                )}
                {profileImage && profileImage?.src && (
                  <Link
                    color={"secondary"}
                    onClick={() => setProfileImage(undefined)}
                  >
                    Remove picture
                  </Link>
                )}
                {/* <Typography color="GrayText">Upload Logo</Typography> */}
                {profileImage && profileImage?.src ? ""
                  : <Typography color="GrayText">Upload Logo</Typography>}
              </Stack>

              <Stack
                direction={"column"}
                spacing={1}
                alignItems={"flex-start"}
                sx={{width: "100%"}}
              >
                <Typography variant={"body1"}>Startup Name*</Typography>
                <TextField
                  name={"name"}
                  value={formikFirst.values.name}
                  onChange={formikFirst.handleChange}
                  error={
                    formikFirst.touched.name && Boolean(formikFirst.errors.name)
                  }
                  helperText={formikFirst.touched.name && formikFirst.errors.name}
                  variant={"outlined"}
                  fullWidth
                />
              </Stack>

              <Stack
                direction={"column"}
                spacing={1}
                alignItems={"flex-start"}
                sx={{width: "100%"}}
              >
                <Typography variant={"body1"}>Startup website URL</Typography>
                <TextField
                  variant={"outlined"}
                  name={"website"}
                  fullWidth
                  value={formikFirst.values.website}
                  onChange={formikFirst.handleChange}
                  error={
                    formikFirst.touched.website &&
                    Boolean(formikFirst.errors.website)
                  }
                  helperText={
                    formikFirst.touched.website && formikFirst.errors.website
                  }
                />
              </Stack>

            <Stack
              direction={"column"}
              spacing={1}
              // alignItems={"flex-start"}
              align="left"
              sx={{ width: "100%" }}
            >
              <Typography variant={"body1"}>Description</Typography>
              <TextField
                multiline
                rows={4}
                variant={"outlined"}
                name={"description"}
                fullWidth
                value={formikFirst.values.description}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.description &&
                  Boolean(formikFirst.errors.description)
                }
                helperText={
                  formikFirst.touched.description &&
                  formikFirst.errors.description
                }
                inputProps={{maxLength:150}}
              />
            <Typography align="right" sx={{color:"#233DFF"}}>{150-formikFirst.values.description.length}/150</Typography>
            </Stack>
            <Stack direction={!matches ? 'column' :"row"} spacing={3} sx={{ width: "100%" }}>
              <Stack
                direction={"column"}
                spacing={1}
                alignItems={"flex-start"}
                sx={{ width: "100%" }}
              >
                <Typography variant={"body1"}>Investment stage*</Typography>
                <TextField
                  variant={"outlined"}
                  name={"investmentStage"}
                  fullWidth
                  value={formikFirst.values.investmentStage}
                  onChange={formikFirst.handleChange}
                  error={
                    formikFirst.touched.investmentStage &&
                    Boolean(formikFirst.errors.investmentStage)
                  }
                  helperText={
                    formikFirst.touched.investmentStage &&
                    formikFirst.errors.investmentStage
                  }
                  select
                  sx={{ textAlign: "left" }}
                >
                  <MenuItem value={"Pre-Seed"}>Pre-Seed</MenuItem>
                  <MenuItem value={"Seed"}>Seed</MenuItem>
                  <MenuItem value={"Seed+"}>Seed+</MenuItem>
                  <MenuItem value={"Series A"}>Series A</MenuItem>
                  <MenuItem value={"Series A+"}>Series A+</MenuItem>
                  <MenuItem value={"Series B"}>Series B</MenuItem>
                  <MenuItem value={"Series B+"}>Series B+</MenuItem>
                  <MenuItem value={"Series C and above"}>
                    Series C and above
                  </MenuItem>
                </TextField>
              </Stack>
              <Stack
                direction={"column"}
                spacing={1}
                alignItems={"flex-start"}
                sx={{ width: "100%" }}
              >
                <Typography variant={"body1"}>Investment date*</Typography>
                <TextField
                  variant={"outlined"}
                  name={"investmentDate"}
                  fullWidth
                  type={"date"}
						InputProps={{inputProps: { min: "1900-01-00", max: "2150-12-31"} }}
                  value={formikFirst.values.investmentDate}
                  onChange={formikFirst.handleChange}
                  error={
                    formikFirst.touched.investmentDate &&
                    Boolean(formikFirst.errors.investmentDate)
                  }
                  helperText={
                    formikFirst.touched.investmentDate &&
                    formikFirst.errors.investmentDate
                  }
                />
              </Stack>
            </Stack>

              <Stack direction={!matches ? 'column' : "row"} spacing={3} sx={{width: "100%"}}>
                <Stack
                  direction={"column"}
                  spacing={1}
                  alignItems={"flex-start"}
                  sx={{width: matches ? 147 : '100%'}}
                >
                  <Typography variant={"body1"}>Currency</Typography>
                  <TextField
                    variant={"outlined"}
                    name={"valuationCurrency"}
                    fullWidth
                    select
                    sx={{textAlign: "left"}}
                    value={formikFirst.values.valuationCurrency}
                    onChange={formikFirst.handleChange}
                    error={
                      formikFirst.touched.valuationCurrency &&
                      Boolean(formikFirst.errors.valuationCurrency)
                    }
                    helperText={
                      formikFirst.touched.valuationCurrency &&
                      formikFirst.errors.valuationCurrency
                    }
                  >
                    <MenuItem value={"USD"}>USD</MenuItem>
                    <MenuItem value={"GBP"}>GBP</MenuItem>
                    <MenuItem value={"EUR"}>EUR</MenuItem>
                  </TextField>
                </Stack>
                <Stack
                  direction={"column"}
                  spacing={1}
                  alignItems={"flex-start"}
                  sx={{width: "100%"}}
                >
                  <Typography variant={"body1"}>Pre-money Valuation</Typography>
                  <TextField
                    variant={"outlined"}
                    name={"valuation"}
                    fullWidth
                    value={commaAmount(formikFirst.values.valuation.toString())}
                    onChange={(e) => {
                      let tar = e.target.value;
                      let result = "";
                      for (let i = tar.length - 1; i >= 0; i -= 1) {
                        if (tar[i] != ",") {
                          result = `${tar[i]}${result}`;
                        }
                      }
                      if (tar.length == 0) {
                        e.target.value = "";
                      } else {
                        e.target.value = parseInt(result);
                      }

                      formikFirst.handleChange(e);
                    }}
                    error={
                      formikFirst.touched.valuation &&
                      Boolean(formikFirst.errors.valuation)
                    }
                    helperText={
                      formikFirst.touched.valuation &&
                      formikFirst.errors.valuation
                    }
                    onInput={(e) => {
                      let tar = e.target.value;
                      let result = "";
                      for (let i = tar.length - 1; i >= 0; i -= 1) {
                        if (tar[i] != ",") {
                          result = `${tar[i]}${result}`;
                        }
                      }
                      if (tar.length == 0) {
                        e.target.value = "";
                      } else {
                        e.target.value = parseInt(result);
                      }

                      formikFirst.handleChange(e);
                    }}
                  />
                </Stack>
              </Stack>

              <Stack direction={!matches ? 'column' : "row"} spacing={3} sx={{width: "100%"}}>
                <Stack
                  direction={"column"}
                  spacing={1}
                  alignItems={"flex-start"}
                  sx={{width: matches ? 147 : '100%'}}
                >
                  <Typography variant={"body1"}>Currency</Typography>
                  <TextField
                    variant={"outlined"}
                    name={"currency"}
                    fullWidth
                    select
                    sx={{textAlign: "left"}}
                    value={formikFirst.values.currency}
                    onChange={formikFirst.handleChange}
                    error={
                      formikFirst.touched.currency &&
                      Boolean(formikFirst.errors.currency)
                    }
                    helperText={
                      formikFirst.touched.currency && formikFirst.errors.currency
                    }
                  >
                    <MenuItem value={"USD"}>USD</MenuItem>
                    <MenuItem value={"GBP"}>GBP</MenuItem>
                    <MenuItem value={"EUR"}>EUR</MenuItem>
                  </TextField>
                </Stack>
                <Stack
                  direction={"column"}
                  spacing={1}
                  alignItems={"flex-start"}
                  sx={{width: "100%"}}
                >
                  <Typography variant={"body1"}>Investment Amount</Typography>
                  <TextField
                    variant={"outlined"}
                    name={"amount"}
                    fullWidth
                    value={commaAmount(formikFirst.values.amount.toString())}
                    onChange={(e) => {
                      let tar = e.target.value;
                      let result = "";
                      for (let i = tar.length - 1; i >= 0; i -= 1) {
                        if (tar[i] != ",") {
                          result = `${tar[i]}${result}`;
                        }
                      }
                      if (tar.length == 0) {
                        e.target.value = "";
                      } else {
                        e.target.value = parseInt(result);
                      }

                      formikFirst.handleChange(e);
                    }}
                    error={
                      formikFirst.touched.amount &&
                      Boolean(formikFirst.errors.amount)
                    }
                    helperText={
                      formikFirst.touched.amount && formikFirst.errors.amount
                    }
                    onInput={(e) => {
                      let tar = e.target.value;
                      let result = "";
                      for (let i = tar.length - 1; i >= 0; i -= 1) {
                        if (tar[i] != ",") {
                          result = `${tar[i]}${result}`;
                        }
                      }
                      if (tar.length == 0) {
                        e.target.value = "";
                      } else {
                        e.target.value = parseInt(result);
                      }

                      formikFirst.handleChange(e);
                    }}
                  />
                </Stack>
              </Stack>

              <Stack
                direction={"column"}
                spacing={1}
                alignItems={"flex-start"}
                sx={{width: "100%"}}
              >
                <Typography variant={"body1"}>Social Proof</Typography>
                <TextField
                  multiline
                  rows={4}
                  variant={"outlined"}
                  name={"socialProof"}
                  fullWidth
                  value={formikFirst.values.socialProof}
                  onChange={formikFirst.handleChange}
                  error={
                    formikFirst.touched.socialProof &&
                    Boolean(formikFirst.errors.socialProof)
                  }
                  helperText={
                    formikFirst.touched.socialProof &&
                    formikFirst.errors.socialProof
                  }
                />
              </Stack>

              {/*attachment*/}
              <Stack direction={"column"} sx={{width: "100%"}} spacing={5}>
                <Stack direction={"column"} spacing={2}>
                  <Typography sx={{textAlign: "left"}}>
                    Attachments{" "}
                    <font style={{fontWeight: 300}}>
                      <i> (References, Recommendations etc.)</i>
                    </font>
                  </Typography>

                  <DragDropFiles
                    label={{
                      text: "Drop here or choose from files",
                      caption: "(max file size: 50MB)",
                    }}
                    multiple={true}
                    limit={50}
                    types={fileTypes}
                    onChangeCallback={(fileList) => {
                      let list = [];
                      if (files.length > 0) {
                        list = [...files];
                      }
                      for (let file of fileList) {
                        list.push({file});
                      }
                      setFiles([...list]);
                    }}
                  />
                  <span/>
                  <Grid container gap={2}>
                    {files.length > 0 &&
                      files.map((file, i) => (
                        <Grid sx={{width: !matches ? '100%' : "48.5%"}}>
                          <DragDropItem
                            file={file}
                            i={i}
                            key={i}
                            path={file?.file ? `investors/${uid ? uid : user.uid}/attachments/${file.file.name}` : file}
                            remove={(i) => {
                              setFiles([...files.filter((d, ind) => ind !== i)]);
                              console.log(i);
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Stack>
              </Stack>

              <Stack
                direction={"column"}
                spacing={1}
                alignItems={"flex-start"}
                sx={{width: "100%"}}
              >
                <Typography variant={"body1"}>Additional links</Typography>
                <TextField
                  variant={"outlined"}
                  name={"link"}
                  fullWidth
                  value={formikFirst.values.link}
                  onChange={formikFirst.handleChange}
                  error={
                    formikFirst.touched.link && Boolean(formikFirst.errors.link)
                  }
                  helperText={formikFirst.touched.link && formikFirst.errors.link}
                />
              </Stack>
            </Stack>

            {/*
          <FieldArray
            name={"portfolio"}
            render={(arrayHelpers) => {
              return (
                <>
                  {arrayHelpers?.form.values.portfolio.map(
                    (p, i) => (
                      <Stack
                        direction={
                          "column"
                        }
                        spacing={
                          5
                        }
                        key={i}>
                        {arrayHelpers
                            ?.form
                            .values
                            .portfolio
                            .length >
                          1 && (
                            <Stack
                              direction={
                                "row"
                              }
                              spacing={
                                5
                              }
                              alignItems={
                                "center"
                              }
                              justifyContent={
                                "flex-end"
                              }>
                              <Button
                                variant={
                                  "outlined"
                                }
                                size={
                                  "small"
                                }
                                color={
                                  "warning"
                                }
                                onClick={() =>
                                  arrayHelpers.remove(
                                    i
                                  )
                                }
                                endIcon={
                                  <DeleteIcon />
                                }>
                                Delete
                              </Button>
                            </Stack>
                          )}
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={
                            1
                          }
                          sx={{
                            mb: 5,
                          }}>
                          <label
                            htmlFor={`logo${i}`}>
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical:
                                  "bottom",
                                horizontal:
                                  "right",
                              }}
                              badgeContent={
                                <IconButton
                                  color={
                                    "primary"
                                  }
                                  component={
                                    "span"
                                  }>
                                  <AddCircleIcon />
                                </IconButton>
                              }>
                              <Avatar
                                alt={
                                  "startup-picture"
                                }
                                src={
                                  p &&
                                  p?.src
                                    ? p?.src
                                    : "/assets/startup-placeholder-image.png"
                                }
                                sx={{
                                  width: 128,
                                  height: 128,
                                  cursor: "pointer",
                                  backgroundColor:
                                    "transparent",
                                  border: "1px solid #E0E3FF",
                                }}
                                component={
                                  "span"
                                }
                                imgProps={{
                                  sx: {
                                    objectFit:
                                      p &&
                                      p?.src
                                        ? "cover"
                                        : "scale-down",
                                  },
                                }}
                              />
                            </Badge>
                            <Input
                              accept="image/*"
                              id={`logo${i}`}
                              multiple={
                                false
                              }
                              type="file"
                              onChange={(
                                e
                              ) => {
                                const fileReader =
                                  new FileReader();
                                fileReader.readAsDataURL(
                                  e
                                    .target
                                    .files[0]
                                );
                                fileReader.addEventListener(
                                  "load",
                                  async function () {
                                    const file =
                                      e
                                        .target
                                        .files[0];
                                    const path = `investors/${user.uid}/portfolio/${file.name}`;
                                    // uploadFile(file, path)
                                    const obj =
                                      {
                                        ...arrayHelpers
                                          .form
                                          .values
                                          .portfolio[
                                          i
                                          ],
                                        src: this
                                          .result,
                                        logo: path,
                                      };
                                    arrayHelpers.replace(
                                      i,
                                      obj
                                    );
                                    UploadFile(
                                      file,
                                      path
                                    ).then(
                                      (
                                        url
                                      ) => {
                                        arrayHelpers.replace(
                                          i,
                                          {
                                            ...obj,
                                            logoURL:
                                            url,
                                          }
                                        );
                                      }
                                    );
                                  }
                                );
                              }}
                            />
                          </label>
                        </Stack>

                        <TextField
                          required
                          fullWidth
                          label={
                            "Startup name"
                          }
                          name={`portfolio.${i}.name`}
                          value={
                            formikFirst
                              .values
                              .portfolio[
                              i
                              ]
                              .name
                          }
                          onChange={
                            formikFirst.handleChange
                          }
                          error={
                            getIn(
                              formikFirst.touched,
                              `portfolio.${i}.name`
                            ) &&
                            getIn(
                              formikFirst.errors,
                              `portfolio.${i}.name`
                            )
                          }
                          helperText={
                            getIn(
                              formikFirst.touched,
                              `portfolio.${i}.name`
                            ) &&
                            getIn(
                              formikFirst.errors,
                              `portfolio.${i}.name`
                            ) &&
                            formikFirst
                              .errors
                              .portfolio[
                              i
                              ]
                              .name
                          }
                        />


                        <TextField
                          fullWidth
                          label={
                            "Description"
                          }
                          name={`portfolio.${i}.description`}
                          value={
                            formikFirst
                              .values
                              .portfolio[
                              i
                              ]
                              .description
                          }
                          onChange={
                            formikFirst.handleChange
                          }
                          error={
                            getIn(
                              formikFirst.touched,
                              `portfolio.${i}.description`
                            ) &&
                            getIn(
                              formikFirst.errors,
                              `portfolio.${i}.description`
                            )
                          }
                          helperText={
                            getIn(
                              formikFirst.touched,
                              `portfolio.${i}.desciption`
                            ) &&
                            getIn(
                              formikFirst.errors,
                              `portfolio.${i}.description`
                            ) &&
                            formikFirst
                              .errors
                              .portfolio[
                              i
                              ]
                              .description
                          }
                        />

                        <Stack
                          direction={
                            "row"
                          }
                          spacing={
                            2
                          }>
                          <TextField
                            required
                            fullWidth
                            name={`portfolio.${i}.stage`}
                            value={
                              formikFirst
                                .values
                                .portfolio[
                                i
                                ]
                                .stage
                            }
                            onChange={
                              formikFirst.handleChange
                            }
                            error={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.stage`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.stage`
                              )
                            }
                            helperText={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.stage`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.stage`
                              ) &&
                              formikFirst
                                .errors
                                .portfolio[
                                i
                                ]
                                .stage
                            }
                            select
                            label={
                              "Stage"
                            }
                            sx={{
                              textAlign:
                                "left",
                            }}>
                            <MenuItem
                              value={
                                "Pre-Seed"
                              }>
                              Pre-Seed
                            </MenuItem>
                            <MenuItem
                              value={
                                "Seed"
                              }>
                              Seed
                            </MenuItem>
                            <MenuItem
                              value={
                                "Series A"
                              }>
                              Series
                              A
                            </MenuItem>
                            <MenuItem
                              value={
                                "Series B"
                              }>
                              Series
                              B
                            </MenuItem>
                            <MenuItem
                              value={
                                "Series C+"
                              }>
                              Series
                              C+
                            </MenuItem>
                          </TextField>
                          <TextField
                            fullWidth
                            label={
                              "Date"
                            }
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name={`portfolio.${i}.date`}
                            value={
                              formikFirst
                                .values
                                .portfolio[
                                i
                                ]
                                .date
                            }
                            onChange={
                              formikFirst.handleChange
                            }
                            error={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.date`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.date`
                              )
                            }
                            helperText={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.date`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.date`
                              ) &&
                              formikFirst
                                .errors
                                .portfolio[
                                i
                                ]
                                .date
                            }
                          />
                        </Stack>

                        <Stack
                          direction={
                            "row"
                          }
                          spacing={
                            2
                          }>
                          <TextField
                            label={
                              "Currency"
                            }
                            name={`portfolio.${i}.currency`}
                            value={
                              formikFirst
                                .values
                                .portfolio[
                                i
                                ]
                                .currency
                            }
                            onChange={
                              formikFirst.handleChange
                            }
                            error={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.currency`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.currency`
                              )
                            }
                            helperText={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.currency`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.currency`
                              ) &&
                              formikFirst
                                .errors
                                .portfolio[
                                i
                                ]
                                .currency
                            }
                            select
                            sx={{
                              textAlign:
                                "left",
                              width: {
                                xs: "60%",
                                sm: "25%",
                              },
                            }}>
                            <MenuItem
                              value={
                                "USD"
                              }>
                              USD
                            </MenuItem>
                            <MenuItem
                              value={
                                "GBP"
                              }>
                              GBP
                            </MenuItem>
                            <MenuItem
                              value={
                                "EUR"
                              }>
                              EUR
                            </MenuItem>
                          </TextField>
                          <TextField
                            fullWidth
                            label={
                              matches
                                ? "Valuation"
                                : "Pre Money Valuation"
                            }
                            name={`portfolio.${i}.valuation`}
                            value={
                              formikFirst
                                .values
                                .portfolio[
                                i
                                ]
                                .valuation
                            }
                            onChange={
                              formikFirst.handleChange
                            }
                            error={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.valuation`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.valuation`
                              )
                            }
                            helperText={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.valuation`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.valuation`
                              ) &&
                              formikFirst
                                .errors
                                .portfolio[
                                i
                                ]
                                .valuation
                            }
                          />
                        </Stack>

                        <Stack
                          direction={
                            "row"
                          }
                          spacing={
                            2
                          }>
                          <TextField
                            label={
                              "Currency"
                            }
                            name={`portfolio.${i}.valuationCurrency`}
                            value={
                              formikFirst
                                .values
                                .portfolio[
                                i
                                ]
                                .valuationCurrency
                            }
                            onChange={
                              formikFirst.handleChange
                            }
                            error={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.valuationCurrency`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.valuationCurrency`
                              )
                            }
                            helperText={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.valuationCurrency`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.valuationCurrency`
                              ) &&
                              formikFirst
                                .errors
                                .portfolio[
                                i
                                ]
                                .valuationCurrency
                            }
                            select
                            sx={{
                              textAlign:
                                "left",
                              width: {
                                xs: "60%",
                                sm: "25%",
                              },
                            }}>
                            <MenuItem
                              value={
                                "USD"
                              }>
                              USD
                            </MenuItem>
                            <MenuItem
                              value={
                                "GBP"
                              }>
                              GBP
                            </MenuItem>
                            <MenuItem
                              value={
                                "EUR"
                              }>
                              EUR
                            </MenuItem>
                          </TextField>
                          <TextField
                            fullWidth
                            label={
                              matches
                                ? "Investment"
                                : "Amount Invested"
                            }
                            name={`portfolio.${i}.amount`}
                            value={
                              formikFirst
                                .values
                                .portfolio[
                                i
                                ]
                                .amount
                            }
                            onChange={
                              formikFirst.handleChange
                            }
                            error={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.amount`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.amount`
                              )
                            }
                            helperText={
                              getIn(
                                formikFirst.touched,
                                `portfolio.${i}.amount`
                              ) &&
                              getIn(
                                formikFirst.errors,
                                `portfolio.${i}.amount`
                              ) &&
                              formikFirst
                                .errors
                                .portfolio[
                                i
                                ]
                                .amount
                            }
                          />
                        </Stack>
                      </Stack>
                    )
                  )}

                  <Button
                    sx={{
                      width: 150,
                    }}
                    size={"small"}
                    color={
                      "success"
                    }
                    variant={
                      "contained"
                    }
                    startIcon={
                      <AddCircleIcon />
                    }
                    onClick={() =>
                      arrayHelpers.push(
                        {
                          logo: "",
                          name: "",
                          focusVertical:
                            [],
                          stage: "",
                          valuation:
                            "",
                          currency:
                            "",
                          amount: "",
                        }
                      )
                    }>
                    Add portfolio
                  </Button>
                </>
              );
            }}
          />*/}
          </Container>
          <Stack
            direction={"row"}
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
            width={"100%"}
          >
            <Button
              variant={"outlined"}
              color={"primary"}
              sx={{px: 5}}
              onClick={cbBack}
            >
              Back
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{px: 5}}
              type={"submit"}
            >
              save
            </Button>
          </Stack>
        </Stack>
      </FormikProvider>
    );
  }
  else{
    return (
      <Portal>
        <Dialog
          fullScreen={!matches ? true: false}
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >

          <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
            {!matches && <IconButton onClick={() => {
              cbBack()
              handleClose()
            }}>
              <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
            </IconButton>}
            {`${data ? 'Edit' : 'Add'} Portfolio`}
          </DialogTitle>
          <DialogContent sx={{px: !matches ? 0 : ''}}>
            <FormikProvider value={formikFirst}>
              <Stack
                direction={"column"}
                spacing={10}
                component="form"
                onSubmit={(e) => formikFirst.handleSubmit(e)}
              >
                <Container maxWidth={"md"}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      sx={{mb: 5}}
                    >
                      <label htmlFor="profile-image">
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            <IconButton color={"primary"} component={"span"}>
                              <AddCircleIcon/>
                            </IconButton>
                          }
                        >
                          <Avatar
                            alt={"startup-picture"}
                            src={
                              profileImage && profileImage?.src
                                ? profileImage?.src
                                : "/assets/startup-placeholder-image.png"
                            }
                            sx={{
                              width: 128,
                              height: 128,
                              cursor: "pointer",
                              backgroundColor: "transparent",
                              border: "1px solid #E0E3FF",
                            }}
                            imgProps={{
                              sx: {objectFit: "scale-down"},
                            }}
                            component={"span"}
                          />
                        </Badge>
                        <Input
                          accept="image/*"
                          id="profile-image"
                          multiple={false}
                          type="file"
                          onChange={(e) => {
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(e.target.files[0]);
                            fileReader.addEventListener("load", async function () {
                              setLoading(true);
                              const file = e.target.files[0];
                              const path = uid ? `investors/${uid}/portfolio/${file.name}` :`investors/${user.uid}/portfolio/${file.name}`;
                              const imageObject = {
                                progress: 0,
                                src: this.result,
                                path,
                                file: e.target.files[0],
                                size: (e.target.files[0]?.size / 1024 / 1024).toFixed(
                                  2
                                ),
                              };
                              setProfileImage(imageObject);
                              UploadFile(file, path).then((url) => {
                                setProfileImage({
                                  ...imageObject,
                                  photoURL: url,
                                });
                                setLoading(false);
                              });
                            });
                          }}
                        />
                      </label>
                      {loading && (
                        <CircularProgress sx={{ml: 2}} size={20} color="primary"/>
                      )}
                      {profileImage && profileImage?.src && (
                        <Link
                          color={"secondary"}
                          onClick={() => setProfileImage(undefined)}
                        >
                          Remove picture
                        </Link>
                      )}
                      {/* <Typography color="GrayText">Upload Logo</Typography> */}
                      {profileImage && profileImage?.src ? ""
                        : <Typography color="GrayText">Upload Logo</Typography>}
                    </Stack>

                    <Stack
                      direction={"column"}
                      spacing={1}
                      alignItems={"flex-start"}
                      sx={{width: "100%"}}
                    >
                      <Typography variant={"body1"}>Startup Name*</Typography>
                      <TextField
                        name={"name"}
                        value={formikFirst.values.name}
                        onChange={formikFirst.handleChange}
                        error={
                          formikFirst.touched.name && Boolean(formikFirst.errors.name)
                        }
                        helperText={formikFirst.touched.name && formikFirst.errors.name}
                        variant={"outlined"}
                        fullWidth
                      />
                    </Stack>

                    <Stack
                      direction={"column"}
                      spacing={1}
                      alignItems={"flex-start"}
                      sx={{width: "100%"}}
                    >
                      <Typography variant={"body1"}>Startup website URL</Typography>
                      <TextField
                        variant={"outlined"}
                        name={"website"}
                        fullWidth
                        value={formikFirst.values.website}
                        onChange={formikFirst.handleChange}
                        error={
                          formikFirst.touched.website &&
                          Boolean(formikFirst.errors.website)
                        }
                        helperText={
                          formikFirst.touched.website && formikFirst.errors.website
                        }
                      />
                    </Stack>

                    <Stack
                      direction={"column"}
                      spacing={1}
                      // alignItems={"flex-start"}
                      align="left"
                      sx={{width: "100%"}}
                    >
                      <Typography variant={"body1"}>Description</Typography>
                      <TextField
                        multiline
                        rows={4}
                        variant={"outlined"}
                        name={"description"}
                        fullWidth
                        value={formikFirst.values.description}
                        onChange={formikFirst.handleChange}
                        error={
                          formikFirst.touched.description &&
                          Boolean(formikFirst.errors.description)
                        }
                        helperText={
                          formikFirst.touched.description &&
                          formikFirst.errors.description
                        }
                      />
                    </Stack>

                    <Stack direction={!matches ? 'column' : "row"} spacing={3} sx={{width: "100%"}}>
                      <Stack
                        direction={"column"}
                        spacing={1}
                        alignItems={"flex-start"}
                        sx={{width: "100%"}}
                      >
                        <Typography variant={"body1"}>Investment stage*</Typography>
                        <TextField
                          variant={"outlined"}
                          name={"investmentStage"}
                          fullWidth
                          value={formikFirst.values.investmentStage}
                          onChange={formikFirst.handleChange}
                          error={
                            formikFirst.touched.investmentStage &&
                            Boolean(formikFirst.errors.investmentStage)
                          }
                          helperText={
                            formikFirst.touched.investmentStage &&
                            formikFirst.errors.investmentStage
                          }
                          select
                          sx={{textAlign: "left"}}
                        >
                          <MenuItem value={"Pre-Seed"}>Pre-Seed</MenuItem>
                          <MenuItem value={"Seed"}>Seed</MenuItem>
                          <MenuItem value={"Seed+"}>Seed+</MenuItem>
                          <MenuItem value={"Series A"}>Series A</MenuItem>
                          <MenuItem value={"Series A+"}>Series A+</MenuItem>
                          <MenuItem value={"Series B"}>Series B</MenuItem>
                          <MenuItem value={"Series B+"}>Series B+</MenuItem>
                          <MenuItem value={"Series C and above"}>
                            Series C and above
                          </MenuItem>
                        </TextField>
                      </Stack>
                      <Stack
                        direction={"column"}
                        spacing={1}
                        alignItems={"flex-start"}
                        sx={{width: "100%"}}
                      >
                        <Typography variant={"body1"}>Investment date*</Typography>
                        <TextField
                          variant={"outlined"}
                          name={"investmentDate"}
                          fullWidth
                          type={"date"}
                          InputProps={{inputProps: {min: "1900-01-00", max: "2150-12-31"}}}
                          value={formikFirst.values.investmentDate}
                          onChange={formikFirst.handleChange}
                          error={
                            formikFirst.touched.investmentDate &&
                            Boolean(formikFirst.errors.investmentDate)
                          }
                          helperText={
                            formikFirst.touched.investmentDate &&
                            formikFirst.errors.investmentDate
                          }
                        />
                      </Stack>
                    </Stack>

                    <Stack direction={!matches ? 'column' : "row"} spacing={3} sx={{width: "100%"}}>
                      <Stack
                        direction={"column"}
                        spacing={1}
                        alignItems={"flex-start"}
                        sx={{width: matches ? 147 : '100%'}}
                      >
                        <Typography variant={"body1"}>Currency</Typography>
                        <TextField
                          variant={"outlined"}
                          name={"valuationCurrency"}
                          fullWidth
                          select
                          sx={{textAlign: "left"}}
                          value={formikFirst.values.valuationCurrency}
                          onChange={formikFirst.handleChange}
                          error={
                            formikFirst.touched.valuationCurrency &&
                            Boolean(formikFirst.errors.valuationCurrency)
                          }
                          helperText={
                            formikFirst.touched.valuationCurrency &&
                            formikFirst.errors.valuationCurrency
                          }
                        >
                          <MenuItem value={"USD"}>USD</MenuItem>
                          <MenuItem value={"GBP"}>GBP</MenuItem>
                          <MenuItem value={"EUR"}>EUR</MenuItem>
                        </TextField>
                      </Stack>
                      <Stack
                        direction={"column"}
                        spacing={1}
                        alignItems={"flex-start"}
                        sx={{width: "100%"}}
                      >
                        <Typography variant={"body1"}>Pre-money Valuation</Typography>
                        <TextField
                          variant={"outlined"}
                          name={"valuation"}
                          fullWidth
                          value={commaAmount(formikFirst.values.valuation.toString())}
                          onChange={(e) => {
                            let tar = e.target.value;
                            let result = "";
                            for (let i = tar.length - 1; i >= 0; i -= 1) {
                              if (tar[i] != ",") {
                                result = `${tar[i]}${result}`;
                              }
                            }
                            if (tar.length == 0) {
                              e.target.value = "";
                            } else {
                              e.target.value = parseInt(result);
                            }

                            formikFirst.handleChange(e);
                          }}
                          error={
                            formikFirst.touched.valuation &&
                            Boolean(formikFirst.errors.valuation)
                          }
                          helperText={
                            formikFirst.touched.valuation &&
                            formikFirst.errors.valuation
                          }
                          onInput={(e) => {
                            let tar = e.target.value;
                            let result = "";
                            for (let i = tar.length - 1; i >= 0; i -= 1) {
                              if (tar[i] != ",") {
                                result = `${tar[i]}${result}`;
                              }
                            }
                            if (tar.length == 0) {
                              e.target.value = "";
                            } else {
                              e.target.value = parseInt(result);
                            }

                            formikFirst.handleChange(e);
                          }}
                        />
                      </Stack>
                    </Stack>

                    <Stack direction={!matches ? 'column' : "row"} spacing={3} sx={{width: "100%"}}>
                      <Stack
                        direction={"column"}
                        spacing={1}
                        alignItems={"flex-start"}
                        sx={{width: matches ? 147 : '100%'}}
                      >
                        <Typography variant={"body1"}>Currency</Typography>
                        <TextField
                          variant={"outlined"}
                          name={"currency"}
                          fullWidth
                          select
                          sx={{textAlign: "left"}}
                          value={formikFirst.values.currency}
                          onChange={formikFirst.handleChange}
                          error={
                            formikFirst.touched.currency &&
                            Boolean(formikFirst.errors.currency)
                          }
                          helperText={
                            formikFirst.touched.currency && formikFirst.errors.currency
                          }
                        >
                          <MenuItem value={"USD"}>USD</MenuItem>
                          <MenuItem value={"GBP"}>GBP</MenuItem>
                          <MenuItem value={"EUR"}>EUR</MenuItem>
                        </TextField>
                      </Stack>
                      <Stack
                        direction={"column"}
                        spacing={1}
                        alignItems={"flex-start"}
                        sx={{width: "100%"}}
                      >
                        <Typography variant={"body1"}>Investment Amount</Typography>
                        <TextField
                          variant={"outlined"}
                          name={"amount"}
                          fullWidth
                          value={commaAmount(formikFirst.values.amount.toString())}
                          onChange={(e) => {
                            let tar = e.target.value;
                            let result = "";
                            for (let i = tar.length - 1; i >= 0; i -= 1) {
                              if (tar[i] != ",") {
                                result = `${tar[i]}${result}`;
                              }
                            }
                            if (tar.length == 0) {
                              e.target.value = "";
                            } else {
                              e.target.value = parseInt(result);
                            }

                            formikFirst.handleChange(e);
                          }}
                          error={
                            formikFirst.touched.amount &&
                            Boolean(formikFirst.errors.amount)
                          }
                          helperText={
                            formikFirst.touched.amount && formikFirst.errors.amount
                          }
                          onInput={(e) => {
                            let tar = e.target.value;
                            let result = "";
                            for (let i = tar.length - 1; i >= 0; i -= 1) {
                              if (tar[i] != ",") {
                                result = `${tar[i]}${result}`;
                              }
                            }
                            if (tar.length == 0) {
                              e.target.value = "";
                            } else {
                              e.target.value = parseInt(result);
                            }

                            formikFirst.handleChange(e);
                          }}
                        />
                      </Stack>
                    </Stack>

                    <Stack
                      direction={"column"}
                      spacing={1}
                      alignItems={"flex-start"}
                      sx={{width: "100%"}}
                    >
                      <Typography variant={"body1"}>Social Proof</Typography>
                      <TextField
                        multiline
                        rows={4}
                        variant={"outlined"}
                        name={"socialProof"}
                        fullWidth
                        value={formikFirst.values.socialProof}
                        onChange={formikFirst.handleChange}
                        error={
                          formikFirst.touched.socialProof &&
                          Boolean(formikFirst.errors.socialProof)
                        }
                        helperText={
                          formikFirst.touched.socialProof &&
                          formikFirst.errors.socialProof
                        }
                      />
                    </Stack>

                    {/*attachment*/}
                    <Stack direction={"column"} sx={{width: "100%"}} spacing={5}>
                      <Stack direction={"column"} spacing={2}>
                        <Typography sx={{textAlign: "left"}}>
                          Attachments{" "}
                          <font style={{fontWeight: 300}}>
                            <i> (References, Recommendations etc.)</i>
                          </font>
                        </Typography>

                        <DragDropFiles
                          label={{
                            text: "Drop here or choose from files",
                            caption: "(max file size: 50MB)",
                          }}
                          multiple={true}
                          limit={50}
                          types={fileTypes}
                          onChangeCallback={(fileList) => {
                            let list = [];
                            if (files.length > 0) {
                              list = [...files];
                            }
                            for (let file of fileList) {
                              list.push({file});
                            }
                            setFiles([...list]);
                          }}
                        />
                        <span/>
                        <Grid container gap={2}>
                          {files.length > 0 &&
                            files.map((file, i) => (
                              <Grid sx={{width: !matches ? '100%' : "48.5%"}}>
                                <DragDropItem
                                  file={file}
                                  i={i}
                                  key={i}
                                  path={file?.file ? `investors/${uid ? uid : user.uid}/attachments/${file.file.name}` : file}
                                  remove={(i) => {
                                    setFiles([...files.filter((d, ind) => ind !== i)]);
                                    console.log(i);
                                  }}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Stack>
                    </Stack>

                    <Stack
                      direction={"column"}
                      spacing={1}
                      alignItems={"flex-start"}
                      sx={{width: "100%"}}
                    >
                      <Typography variant={"body1"}>Additional links</Typography>
                      <TextField
                        variant={"outlined"}
                        name={"link"}
                        fullWidth
                        value={formikFirst.values.link}
                        onChange={formikFirst.handleChange}
                        error={
                          formikFirst.touched.link && Boolean(formikFirst.errors.link)
                        }
                        helperText={formikFirst.touched.link && formikFirst.errors.link}
                      />
                    </Stack>
                  </Stack>

                </Container>
                <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={3}
                  width={"100%"}
                  sx={{pr:2}}
                >
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={{px: 5}}
                    type={"submit"}
                  >
                    save
                  </Button>
                </Stack>
              </Stack>
            </FormikProvider>
          </DialogContent>
        </Dialog>
      </Portal>
    );
  }
}
