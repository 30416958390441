import * as React from 'react';

import {Button, CircularProgress} from '@mui/material';
import {addDoc, collection, doc, getDocs, query, serverTimestamp, setDoc, where} from "firebase/firestore";
import { DB } from '../../../../config/firebase/firebase';
import { AuthUserContext } from '../../../../providers/auth-provider';
import {useNavigate} from "react-router-dom";

export default function ListItemStartConversation({data}) {
  const { user, setRoomId, profile} = React.useContext(AuthUserContext);
  const navigator =  useNavigate()
  const [isLoading, setLoading] = React.useState(false)
  const create = async () => {
    setLoading(true)
    const userType =data.creatorData.userType;
    if (data?.uid !== profile?.uid) {
      try {
        const q = query(
          collection(DB, "rooms"),
          where('participates', 'in', [[data?.uid, profile?.uid], [profile?.uid,data?.uid]])
        );
        const getRoom = await getDocs(q);
        let room = getRoom.size > 0 ? {id: getRoom.docs[0].id, ...getRoom.docs[0].data()} :
          await addDoc(collection(DB, "rooms"),
            {
              title: userType === 'startup' ? data.creatorData?.startup?.startupName : userType === 'service-provider'  ? data.creatorData?.serviceProvider?.displayName: data.creatorData?.displayName,
              subTitle: `${data?.displayName}, ${profile?.displayName}`,
              participates:[data?.uid, user.uid],
              uid: user?.uid,
              logo: userType === 'startup' ? data.creatorData?.startup?.logoURL : userType === 'service-provider'  ? data.creatorData?.serviceProvider?.photo || data.creatorData?.serviceProvider?.photoURL :  data.creatorData?.photoURL,
              updatedAt: serverTimestamp(),
              createdAt: serverTimestamp(),
              lastMsg: {
                text: 'Please go ahead and start conversation',
                status: 'send'
              }
            });
        // await setRoomId(room.id)
        if (getRoom.size === 0) {
          let message = await addDoc(collection(DB, "messages"),
            {
              type: 'system-generated',
              text: 'Please go ahead and start conversation',
              sender:  user.uid,
              receiver: data?.uid,
              createdAt:serverTimestamp(),
              roomId: room?.id,
              read:false,
              status: 'send'
            });
        } else {
          await setDoc(doc(DB, "rooms", getRoom.docs[0].id), {updatedAt: serverTimestamp()}, {merge:true})
        }
        setLoading(false)

        navigator(`/m/${profile.userType}/chat`)
      } catch (e) {

        setLoading(false)

      }
    } else{
      setLoading(false)
    }




  }
  if(data.applicant) return (
   <Button  size={"small"} variant={"outlined"} sx={{width: 50,px:6}} onClick={create} endIcon= {isLoading && <CircularProgress size={20}/>}>
     Message
   </Button>
  );
  
  else return (<Button   size={'small'} style={{fontSize:"12px"}} color={'secondary'} sx={{px: 2, width:50, backgroundColor:"#E0E3FF"}} onClick={create} endIcon= {isLoading && <CircularProgress size={20}/>}>
     CHAT
   </Button>)
  
}
