import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
} from "firebase/firestore";
import { DB } from "../config/firebase/firebase";

const RequestMatchesProfiles = (request) =>
  new Promise(async (resolve, reject) => {
    try {
      const matches = await getMatches(request);
      resolve(matches);
    } catch (e) {
      reject(e);
    }
  });

const getMatches = async (request) => {
  request.searchFor = request.searchFor.map(
    (data) =>
      (data =
        data === "Industry Expert"
          ? "Expert"
          : data === "Growth Expert"
          ? "Business Developer"
          : data)
  );
  const searchFor = request?.searchFor.map((search) =>
    search.toLowerCase().replace(" ", "-")
  );

  const q = query(
    collection(DB, "users"),
    where("onboard", "==", true),
    where("uid", "!=", request?.creatorData?.uid),
    where("userType", "in", searchFor)
  );
  const users = await getDocs(q);
  const list = [];
  users.forEach((user) => {
    list.push(user.data());
  });
  const obj = [];
  const serviceProviders = list.filter(
    (data) => data.userType === "service-provider"
  );
  const startups = list.filter((data) => data.userType === "startups");
  const businessDeveloper = list.filter(
    (data) => data.userType === "business-developer"
  );
  const experts = list.filter((data) => data.userType === "expert");
  const investors = list.filter((data) => data.userType === "investor");
  //filter Service Provider
  if (serviceProviders) {
    let matchedServiceProviders = serviceProviders?.filter((user) =>
      user?.serviceProvider?.serviceType?.every((skill) =>
        request?.skills.includes(skill)
      )
    );
    if (!matchedServiceProviders)
      matchedServiceProviders = serviceProviders?.filter((user) =>
        user?.serviceProvider?.sector?.every((skill) =>
          request?.skills.includes(skill)
        )
      );
    if (!matchedServiceProviders)
      matchedServiceProviders = serviceProviders?.filter((user) =>
        user?.serviceProvider?.serviceType?.some((skill) =>
          request?.skills.includes(skill)
        )
      );
    if (matchedServiceProviders) obj.push(...matchedServiceProviders);
  }
  if (experts) {
    let matchedExpert = experts?.filter((user) =>
      user?.expert?.expertise?.every((skill) => request?.skills.includes(skill))
    );
    if (!matchedExpert)
      matchedExpert = experts?.filter((user) =>
        user?.expert?.expertise?.some((skill) =>
          request?.skills.includes(skill)
        )
      );
    if (matchedExpert) obj.push(...matchedExpert);
  }
  if (businessDeveloper) {
    let matchedBusinessDevelopers = businessDeveloper?.filter((user) =>
      user?.businessDeveloper?.expertise?.every((skill) =>
        request?.skills.includes(skill)
      )
    );
    if (!matchedBusinessDevelopers)
      matchedBusinessDevelopers = businessDeveloper?.filter((user) =>
        user?.businessDeveloper?.expertise?.some((skill) =>
          request?.skills.includes(skill)
        )
      );
    if (matchedBusinessDevelopers) obj.push(...matchedBusinessDevelopers);
  }
  if (investors) {
    let matchedInvestors = investors?.filter((user) =>
      user?.investor?.sector?.every((skill) => request?.skills.includes(skill))
    );
    if (!matchedInvestors)
      matchedInvestors = investors?.filter((user) =>
        user?.investor?.sector?.some((skill) => request?.skills.includes(skill))
      );
    if (matchedInvestors?.length > 0) {
      obj.push(...matchedInvestors);
    } else {
      const inv = investors.slice(0, 3);
      obj.push(...inv);
    }
  }

  const requestsSuggestedPromises = obj.map(async (user) => {
    const userDocRef = doc(
      DB,
      "users",
      user?.uid,
      "requests-suggested",
      request?.id
    );
    const getdoc = await getDoc(userDocRef);
    if (getdoc.data()) {
      return { ...user, requestsSuggested: getdoc.data() };
    } else {
      return user;
    }
  });

  // Wait for all promises to resolve
  const requestsSuggestedResults = await Promise.all(requestsSuggestedPromises);

  // Append requestsSuggested to obj
  const updatedObj = requestsSuggestedResults.map((result) => {
    const { requestsSuggested, ...user } = result;
    return { ...user, requestsSuggested: requestsSuggested || null };
  });

  return updatedObj;
};

export default RequestMatchesProfiles;
