import React from "react";
import {
  Container,
  Typography,
  Stack,
  Button,
  Avatar,
  styled,
  TextField,Portal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress
} from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps, TabPanel } from "../../components/common/tabs-custom";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  getDoc,
} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import PostItem from "../../components/common/timeline/post-item";
import { useNavigate, useParams } from "react-router-dom";
import InvestorDescription from "../../components/common/profile/investor-description";
import ExpertDescription from "../../components/common/profile/expert-description";
import InvestorAbout from "../../components/common/profile/investor-about";
import ExpertAbout from "../../components/common/profile/expert-about";
import InvestorPortfolio from "../../components/common/profile/investor-portfolio";
import ExpertSocialProof from "../../components/common/profile/expert-social-proof";
import BusinessDeveloperDescription from "../../components/common/profile/business-developer-description";
import BusinessDeveloperAbout from "../../components/common/profile/business-developer-about";
import BusinessDeveloperPortfolio from "../../components/common/profile/business-developer-portfolio";
import BusinessDeveloperWorkExperience from "../../components/common/profile/business-developer-work-experience";
import ExpertPortfolio from "../../components/common/profile/expert-portfolio";
import BusinessDeveloperSocialProof from "../../components/common/profile/business-developer-social-proof";
import ServiceProviderDescription from "../../components/common/profile/service-provider-description";
import ServiceProviderAbout from "../../components/common/profile/service-provider-about";
import ServiceProviderSocialProof from "../../components/common/profile/service-provider-social-proof";
import StartupDescription from "../../components/common/profile/startup-description";
import StartupAbout from "../../components/common/profile/startup-about";
import FinanceAbout from "../../components/common/profile/finance-about";
import StartupAttachment from "../../components/common/profile/startup-attachment";
import TalentDescription from "../../components/common/profile/talent-description";
import TalentAbout from "../../components/common/profile/talent-about";
import TalentSocialProof from "../../components/common/profile/talent-social-proof";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {getAuth, getIdToken} from "firebase/auth";
import DeleteUserApi from "../../api/get-users";
import {AuthUserContext} from "../../providers/auth-provider";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InvestmentPortfolioView from "../../components/common/profile/investor-portfolio-view";
import WorkExperience from "../../components/common/profile/expert-work-experience";
import ExpertWorkExperience from "../../components/common/profile/expert-work-experience";
import WorkExperienceView from "../../components/common/profile/work-experience-view";
import ServiceProviderWorkExperience from "../../components/common/profile/service-provider-work-experience";
import StartupTeam from "../../components/common/profile/startup-team";
import ServiceProviderPortfolio from "../../components/common/profile/service-provider-portfolio";
import StartChat from "../../components/common/start-chat";
import CloseIcon from "@mui/icons-material/Close";
import StartupTopPriorities from "../../components/common/profile/startup-top-priorities";
import CommonInvestorDetails from "../../components/common/profile/common-investor-details";
import ReferralAvatar from "../../components/common/profile/referal-avatar";
import { USER_TYPES } from "../../utils/enums/userTypes.enum";
import { BUSINESS_DEVELOPER, USER_TYPES_TO_NAME } from "../../utils/enums/labels.enum";

const StyledTabs = styled((props) => (
  <Tabs
    sx={{ position: "relative", left: 30 }}
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "none",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    borderRadius: "30px",
    color: "#000",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#233DFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
export default function CommonDetails() {
  const userContext = React.useContext(AuthUserContext);
  const [val,setVal] = React.useState(false)

  const { uid } = useParams();
  const [removeAdminLoading,setLoadingRemove] = React.useState(false);
  const [open,setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [key , setKey] = React.useState();
  const [ k , setK ] = React.useState();
  const [index, setIndex] = React.useState(-1);
  const [profile, setProfile] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [posts, setPosts] = React.useState([]);
	const [search, setSearch] = React.useState("");
  const [start , setStart ] = React.useState(false)

  const navigator = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const deleteUser = async () =>
  {
    try {
      setLoadingRemove(true)
      const auth = getAuth();
      const idToken = await getIdToken(auth.currentUser);
      console.log(idToken, 'ID - TOKEn')
      const data = await DeleteUserApi(profile, idToken);
      setLoadingRemove(false)

      await setOpen(!open)

      navigator('/admin/dashboard');
    } catch (e) {
      setLoadingRemove(false)

      debugger
    }
  }
  React.useEffect(() => {
    console.log(userContext && userContext.profile);
    if (uid) {
      const userRef = doc(DB, "users", uid);
      getDoc(userRef).then((doc) => {
        setProfile({...doc?.data(), id : uid, uid});
      });
      const q = query(collection(DB, "timelines"), where("uid", "==", uid));

      getDocs(q).then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          const list = [];
          querySnapshot.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });

            console.log(doc.id, " => ", doc.data());
          });
          setPosts(list);
        }
      });
    }
  }, [uid]);
  if (index === -1) {
    return (
      <Container maxWidth={"lg"} sx={{ textAlign: "left" }}>
       <Stack direction="row" alignItems="center" justifyContent="space-between">
       <Stack direction="column" >
       <IconButton onClick={() => navigator(-1)}>
						<ArrowBackIcon sx={{ fontSize: 40 }} />
					</IconButton>
       </Stack>
   
    
          </Stack>
          <br></br>
        {/*<Stack direction={'row'} spacing={5} sx={{width: '100%', mb: 5}}>
        <div style={{flex: 1}}>
          <Typography variant={'h4'} sx={{fontWeight: 700}}>Hello {profile?.displayName} &nbsp; 👋</Typography>
          <Typography variant={"body1"} color={'secondary'}>Welcome to NOVA</Typography>
        </div>
      </Stack>*/}

        {profile && (
          <Stack direction={"column"} spacing={5}>
            <Stack
              direction={"column"}
              spacing={0.5}
              sx={{
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                p: 2,
                pb: 0,
              }}
            >
              <Box
                sx={{
                  height: 100,
                  width: "100%",
                  backgroundColor: "#F6F8FA",
                  borderRadius: "15px",
                }}
              />


              {/* Startup Picture */}

              <Box
                sx={{
                  border: "1px solid #204488",
                  p: 0.75,
                  borderRadius: "50%",
                  width: "fit-content",
                  position: "relative",
                  left: 30,
                  top: -50,
                }}
              >
                <Avatar
                  alt={
                    profile?.userType === "startup"
                      ? profile?.startup?.startupName
                      : profile?.displayName
                  }
                  src={
                    profile?.userType === "startup"
                      ? profile?.startup?.logoURL
                      : profile?.photoURL
                  }
                  sx={{ width: 92, height: 92 }}
                >
                  <Typography variant={"h5"}>
                    {profile?.userType === "startup"
                      ? profile?.startup?.startupName &&
                        profile?.startup?.startupName.charAt(0)
                      : profile?.displayName && profile?.displayName.charAt(0)}
                  </Typography>
                </Avatar>
              </Box>


              <Stack
                direction={"row"}
                spacing={3}
                sx={{ position: "relative", top: -40 }}
                alignItems={"flex-start"}
              >
                <div />
                <Stack direction={"column"} spacing={2}>
                  <Stack direction={"row"} spacing={3} sx={{width: '100%'}}>

                  {/* Startup Name */}

                    <Typography variant={"h5"} sx={{ marginTop:"2.5px" }}>
                      {profile?.userType === "startup"
                        ? profile?.startup?.startupName
                        : profile?.displayName}
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: "#E0E3FF",
                        px: 3,
                        py: 1,
                        borderRadius: "19.5px",
                      }}
                    >
                    {/* Usertype  */}
                      <Typography
                        style={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {USER_TYPES_TO_NAME[profile?.userType]}
                      </Typography>
                    </Box>


                    {
                      profile?.userType !== 'startup' && profile[profile?.userType === 'service-provider' ? 'serviceProvider'
                        : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.areYouAnAngelInvestor &&

                      <Box
                        sx={{
                          backgroundColor: "#B8FC3C",
                          px: 3,
                          py: 1,
                          borderRadius: "19.5px",
                        }}>
                        <Typography
                          style={{
                            fontWeight: 500,
                            textTransform: "capitalize",
                          }}>
                          {"Investor"}
                        </Typography>
                      </Box>
                    }
                    <StyledTabs
                sx={{marginTop:'-1px',
                display: 'grid',
                justifyContent: 'right',
                position: 'absolute' ,
                right: 0

              
                }}

              >
                    {profile?.uid  !== userContext.user?.uid && profile?.userType !== "startup" &&   <StartChat
                  sx={{color:'white',backgroundColor: "#233DFF",
                  display: 'grid',
                justifyContent: 'right',
                position: 'absolute' ,
                right: 0
                  }}
                  data={{...profile, creatorData:profile }} props={{...a11yProps(4)}}/>
                }
                </StyledTabs>



                    <span style={{flex: 1}}/>
                    {userContext.profile.userType === 'admin' && <Button variant={'outlined'} color={'warning'} onClick={() => setOpen(!open)}>Delete user</Button>}
                  </Stack>
                  {profile?.userType === "investor" && (
                    <InvestorDescription profile={profile} />
                  )}
                  {profile?.userType === "expert" && (
                    <ExpertDescription profile={profile} />
                  )}
                  {profile?.userType === "business-developer" && (
                    <BusinessDeveloperDescription profile={profile} />
                  )}
                  {profile?.userType === "service-provider" && (
                    <ServiceProviderDescription profile={profile} />
                  )}
                  {profile?.userType === "startup" && (
                    <StartupDescription profile={profile} />
                  )}
                  {profile?.userType === "talent" && (
                    <TalentDescription profile={profile} />
                  )}
                </Stack>

                {/* Side Founder part */}


                {/* <div style={{ flex: 1 }} />
                {profile?.userType === "startup" &&
                  profile?.startup?.founder && (
                    <Stack
                      direction={"column"}
                      spacing={1}
                      justifyContent="center"
                      sx={{ position: "relative", right: 15, minWidth: 120, cursor: 'pointer' }}
                      alignItems="center"

                      onClick={() =>
                      // setVal(true)
                        navigator(
                          profile && profile.userType === 'admin'
                            ? `/admin/${profile?.userType}/startup-founder-profile`
                            : userContext && userContext.profile &&  userContext.profile.uid === profile.uid
                            ? `/${profile?.userType}/startup-founder-profile`
                            : `/${userContext?.profile?.userType}/startup-founder-profile`,

                          {
                            state: 
                            {
                              profile,
                              founder: {
                                ...profile?.startup.founder,
                                linkedIn: profile?.startup.founder?.linkedIn,
                                website: profile?.startup.founder?.website,
                                twitter:profile?.startup.founder?.twitter,
                                repeatFounder:profile?.startup.founder?.repeatFounder,

                                startupName:
                                  profile?.startup.founder?.startupName !== ""
                                    ? profile?.startup.founder?.startupName
                                    : profile?.startup?.startupName,
                                photoURL:
                                  profile?.startup.founder.photoURL !== ""
                                    ? profile?.startup.founder.photoURL
                                    : profile?.photoURL,
                              },
                            },
                          }
                        )
                      }
                    >
                      <Box
                        sx={{
                          border: "1px solid #204488",
                          p: 0.75,
                          borderRadius: "50%",
                          width: "fit-content",
                        }}
                      >
                        <Avatar
                          alt={profile?.startup?.founder.founderName}
                          src={
                            profile?.startup?.founder.photoURL !== ""
                              ? profile?.startup?.founder.photoURL
                              : profile?.photoURL
                          }
                          sx={{ width: 51, height: 51 }}
                        >
                          <Typography variant={"h4"} >
                            {profile?.startup?.founder.founderName.charAt(0)}
                          </Typography>
                        </Avatar>
                      </Box>
                      <Typography variant={"h6"} sx={{ textAlign: "center" }}>
                        {profile?.startup?.founder.founderName}
                        <br />
                        <font
                          size={2}
                          style={{
                            backgroundColor: "#F6F8FA",
                            padding: "5px 10px",
                            borderRadius: "6px",
                            fontWeight: 300,
                          }}
                        >
                          Founder
                        </font>
                      </Typography>
                    </Stack>
                  )} */}
              </Stack>
              <Stack direction={"row"}
              
               sx={{width:'100%'}} justifyContent="center"
                     alignItems="flex-end">
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
              >
                <StyledTab label="ABOUT" {...a11yProps(0)} />
                {profile?.userType === "startup" && (
                  <StyledTab label={"TEAM"} {...a11yProps(1)} />
                )}
                {profile?.userType !== "investor" && profile?.userType !== "startup" &&(
                  <StyledTab label={"EXPERIENCE"} {...a11yProps(1)} />
                )}
                {
                (profile?.userType === "expert" 
                && profile?.expert?.investorDetails?.areYouAnAngelInvestor &&
                 profile?.expert.investorDetails.areYouAnAngelInvestor === true)  ?
                 (<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />)   :


                 (profile?.userType === "business-developer" 
                && profile?.businessDeveloper?.investorDetails?.areYouAnAngelInvestor &&
                 profile?.businessDeveloper.investorDetails.areYouAnAngelInvestor === true) ?
                 (<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />)   :

                 (profile?.userType === "service-provider" 
                && profile?.serviceProvider?.investorDetails?.areYouAnAngelInvestor &&
                 profile?.serviceProvider.investorDetails.areYouAnAngelInvestor === true)  ?
                 (<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />)   :

                 (profile?.userType === "investor") ?
                 (<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(2)} />)   :
                 null
                 
                }

                {
                  profile?.userType !== "startup" &&
                  <StyledTab label="POST" {...a11yProps( profile?.userType !== 'investor' ? 3: 2)} />
                }

                {/* <StartChat data={{...profile, creatorData:profile }} props={{...a11yProps(3)}}/> */}


                {/* {profile?.uid  !== userContext.user?.uid && profile?.userType !== "startup" &&   <StartChat
                  sx={{marginLeft: 'auto' , marginRight: '5%',color:'white',backgroundColor: "#233DFF"}}
                  data={{...profile, creatorData:profile }} props={{...a11yProps(4)}}/>
                } */}


              </StyledTabs>
              <div style={{ flex: 10}} />
                {profile?.userType === "startup" &&
                  profile?.startup?.founder && (
                    <Stack
                      direction={"column"}
                      spacing={1}
                      justifyContent="right"
                      sx={{ position: "relative", minWidth: 120, cursor: 'pointer'  }}
                      alignItems="center"

                      onClick={() =>
                      // setVal(true)
                        navigator(
                          profile && profile.userType === 'admin'
                            ? `/admin/${profile?.userType}/startup-founder-profile`
                            : userContext && userContext.profile &&  userContext.profile.uid === profile.uid
                            ? `/${profile?.userType}/startup-founder-profile`
                            : `/${userContext?.profile?.userType}/startup-founder-profile`,

                          {
                            state: 
                            {
                              profile,
                              founder: {
                                ...profile?.startup.founder,
                                linkedIn: profile?.startup.founder?.linkedIn,
                                website: profile?.startup.founder?.website,
                                twitter:profile?.startup.founder?.twitter,
                                repeatFounder:profile?.startup.founder?.repeatFounder,

                                startupName:
                                  profile?.startup.founder?.startupName !== ""
                                    ? profile?.startup.founder?.startupName
                                    : profile?.startup?.startupName,
                                photoURL:
                                  profile?.startup.founder.photoURL !== ""
                                    ? profile?.startup.founder.photoURL
                                    : profile?.photoURL,
                              },
                            },
                          }
                        )
                      }
                    >
                      <Box
                        sx={{
                          border: "1px solid #204488",
                          p: 0.75,
                          borderRadius: "50%",
                          width: "fit-content",
                        }}
                      >
                        <Avatar
                          alt={profile?.startup?.founder.founderName}
                          src={
                            profile?.startup?.founder.photoURL !== ""
                              ? profile?.startup?.founder.photoURL
                              : profile?.photoURL
                          }
                          sx={{ width: 43, height: 43 }}
                        >
                          <Typography variant={"h4"}>
                            {profile?.startup?.founder.founderName.charAt(0)}
                          </Typography>
                        </Avatar>
                      </Box>
                      <Typography variant={"h6"} 
                      // sx={{ textAlign: "center"}}
                      >
                        {profile?.startup?.founder.founderName}
                        </Typography>
                   
                        <Typography variant={"body2"}>  
                        <font
                          size={2}
                          style={{
                            backgroundColor: "#F6F8FA",
                            padding: "5px 10px",
                            borderRadius: "6px",
                            fontWeight: 300,
                          }}
                        >
                          Founder
                        </font>
                      </Typography>
                    </Stack>
                  )}
                <Box sx={{flex: 1}}/>
                
                {profile?.userType === "service-provider" &&
								profile?.serviceProvider?.organisationType === "Organisation" && profile?.serviceProvider?.organisationDetails && (
									<Stack
										direction={"column"}
										spacing={1}
										justifyContent="center"
										sx={{
											position: "relative",
											right: 15,
											minWidth: 120,
											cursor: 'pointer'
										}}
										alignItems="center">
										<Box
											sx={{
												border: "1px solid #204488",
												p: 0.75,
												borderRadius: "50%",
												width: "fit-content",
											}}
											onClick={() =>
												navigator(
													`/${userContext?.profile?.userType}/organisation-profile`,
													{
														state: {
															profile,
															organisation: {
																...profile?.serviceProvider
																	.organisationDetails,
																nameOfOrganisation:
																	profile?.serviceProvider
																		.organisationDetails
																		?.nameOfOrganization!==
																	""
																		? profile
																			?.serviceProvider
																			.organisation
																			?.nameOfOrganization
																		: profile
																			?.serviceProvider
																			?.organisationDetails
																			?.nameOfOrganization,
																logoURL:
																	profile?.serviceProvider
																		.organisationDetails
																		.logoURL !==
																	""
																		? profile
																			?.serviceProvider
																			.organisationDetails
																			.logoURL
																		: '',
															},
														},
													}
												)
											}>
											<Avatar
												alt={
													profile?.serviceProvider?.organisationDetails
														.nameOfOrganization
												}
												src={profile.userType === 'service-provider' && profile?.serviceProvider?.organisationDetails?.logoURL !== '' ?  profile?.serviceProvider?.organisationDetails?.logoURL: ''}
												sx={{ width: 43 , height: 43 }}>
												<Typography variant={"h4"}>
													{profile?.serviceProvider?.organisationDetails.nameOfOrganization.charAt(
														0
													)}
												</Typography>
											</Avatar>
										</Box>
										<Typography
											variant={"h6"}
											// sx={{ textAlign: "center" }}
											>
											{profile?.serviceProvider?.organisationDetails.nameOfOrganization}
											</Typography>
											<Typography variant={'body2'}>
						
											<font
												size={2}
												style={{
													backgroundColor: "#F6F8FA",
													padding: "5px 10px",
													borderRadius: "6px",
													fontWeight: 300,
												}}>
												Organisation
											</font>
										</Typography>
									</Stack>
								)
                }



                { profile?.userType === 'investor' && profile?.investor?.investorType !== 'Angel Investor' &&


                  <Stack direction={"column"} spacing={1} justifyContent="center" alignItems="center"
                         sx={{position: "relative", minWidth: 120,cursor:'pointer'  }}

                         onClick={() => {
                           const path = `/${userContext?.profile?.userType}/common-organisation-profile`
                           navigator(path, {state:{
                               profile:profile,
                               organisation: {
                                 organizationDescription: profile?.investor?.organizationDescription,
                                 investorType: profile?.investor?.investorType,
                                 website: profile?.investor?.website,
                                 nameOfOrganization:  profile?.investor?.nameOfOrganization,
                                 logoURL:
                                 profile?.investor?.logoURL,
                               },
                             }});

                         }}>
                    <Avatar sx={{width: 57, height:57, border: '1px solid #f5f5f5' }} src={profile?.investor?.logoURL} alt={'profile-pic'}  imgProps={{style: {objectFit: 'contain', backgroundColor: '#fff'}}}>
                      <Typography variant={"h3"}>{profile?.investor?.nameOfOrganization}</Typography>
                    </Avatar>
                    <Typography variant={"h6"}
                    >
                      <font

                      >
                        {profile?.investor?.nameOfOrganization}
                      </font>
                    </Typography>
                    <Typography variant={"body2"}
                    >
                      <font
                        size={2}
                        style={{
                          backgroundColor: "#F6F8FA",
                          padding: "5px 10px",
                          borderRadius: "6px",
                          fontWeight: 300,
                        }}
                      >Organization</font></Typography>
                  </Stack>
                }

                <ReferralAvatar profile={profile}/>

              </Stack>
    
              <Box sx={{ height: 20 }} />
            </Stack>

            <TabPanel index={0} value={value}>
                {profile?.userType === "investor" ? (
                  <InvestorAbout profile={profile} />
                ) : profile?.userType === "expert" ? (
                  <ExpertAbout profile={profile} />
                ) : profile?.userType === "business-developer" ? (
                  <BusinessDeveloperAbout profile={profile} />
                ) : profile?.userType === "service-provider" ? (
                  <ServiceProviderAbout profile={profile} />
                ) : profile?.userType === "startup" ? (
                  <StartupAbout profile={profile} />
                ) : profile?.userType === "talent" ? (
                  <TalentAbout profile={profile} />
                ) : (
                  ""
                )}
        
            </TabPanel>

            { profile?.userType !== 'investor' &&
             profile?.userType !== 'startup' &&
            profile[profile?.userType === 'service-provider' ? 'serviceProvider'
              : profile?.userType === 'business-developer' ? 'businessDeveloper' 
              : profile?.userType ]?.investorDetails?.areYouAnAngelInvestor  
              ? <TabPanel index={0} value={value}>
              <Stack
                direction={"column"}
                spacing={3}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                   py: 6,
                }}>

                <CommonInvestorDetails profile={profile}/>

              </Stack>
            </TabPanel>
            : null 
            }

            <TabPanel index={profile?.userType === "startup" ? 1 : null} value={value}>
              <Stack
                direction={"column"}
                spacing={3}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}
              >
                {profile?.userType === "startup" ? (
                  <StartupTeam profile={profile} />
                ) : null
                }
              </Stack>
            </TabPanel>

            <TabPanel
              index={profile?.userType === 'investor'? 1 : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
                : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.areYouAnAngelInvestor ? 2 : -1}
              value={value}
            >
              <Stack
                direction={"column"}
                spacing={6}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}
              >
                 <InvestorPortfolio profile={profile} setIndex={setIndex} setKey={setKey}/>
              </Stack>
            </TabPanel>
            {profile?.userType !== 'investor' &&  profile?.userType !== 'startup'?
            <TabPanel
              index={1}
              value={value}
            >
              <Stack
                direction={"column"}
                spacing={6}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}
              >
                  {profile?.userType === "expert" ? (
                  <ExpertWorkExperience profile={profile} setIndex={setIndex} setK={setK}/>
                ) : profile?.userType === "business-developer" ? (
                  <BusinessDeveloperWorkExperience profile={profile} setIndex={setIndex} setK={setK} />
                ) :profile?.userType === "service-provider" ? (
                  <ServiceProviderWorkExperience profile={profile} setIndex={setIndex} setK={setK}/>
                ):null
                }

              </Stack>
            </TabPanel> :
            null}
            {console.log(profile)}
            <TabPanel
              index={profile?.userType === 'investor'? 2 :  profile[profile?.userType === 'service-provider' ? 'serviceProvider'
                : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.areYouAnAngelInvestor ? 3 : 2}
              value={value}
            >
               <Stack >
                <Stack direction={"column"} spacing={5}>
                {posts.length === 0  ? <>
                  <Stack
                direction={"column"}
                spacing={6}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                  textAlign:"center"
                  
                }}
              >

          {/* <Stack direction="row" justifyContent="center"> */}

              <Typography>There are no posts to be shown</Typography>
          {/* </Stack> */}
          </Stack>
          </>:null 
        }
                  {posts.length > 0 &&
                    posts.map((post, i) => <PostItem data={post} key={i} />)}
                </Stack>
             </Stack>
            </TabPanel>

            {profile && profile?.userType === "startup" && value === 0 && (
              <Stack
                direction={"column"}
                spacing={3}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}>
                <StartupTopPriorities profile={profile} />
              </Stack>
            )}
          </Stack>
        )}
        <Portal>
      <Dialog
        open={open}
              onClose={handleClose}
        aria-labelledby="alert-dialog-achive"
        aria-describedby="alert-dialog-achive"
        maxWidth={"sm"}>
        <DialogContent
          sx={{ minWidth: 487, padding: "0px 0px", mt: 2 }}>
          <Stack
            direction={"column"}
            sx={{ width: "100%" }}
            justifyContent="center"
            alignItems="flex-end">
            <IconButton
              onClick={() => {
                setOpen(false);
              }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction={"column"}
            sx={{ width: "100%" }}
            spacing={2}>
            <Stack
              direction={"column"}
              sx={{ width: "100%", }} alignItems={"center"}>
              <img src={"/assets/delete-confirmation.png"} height={74} width={80}></img>
            </Stack>
            <Typography
              component="span"
              variant="h6"
              color="text.primary"
              sx={{
                textAlign: "center",
                flex: 1,
                padding: 0,
                fontWeight: 500,
              }}>
              Delete User
            </Typography>
            <Typography variant={"body2"} sx={{textAlign:'center'}}>
              Are you sure you want to delete this user? <br/>
              This action cannot be undone.
            </Typography>
            <br/>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems="center" spacing={2}>
              <Button
                variant={"outlined"}
                sx={{ px: 5,width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
                onClick={handleClose}>
               No
              </Button>
              <Button
                variant={"contained"}
                sx={{ px: 5,width:"100px",height:"40px",fontSize:"12px",fontWeight:"500"}}
                onClick={()=> {
                  deleteUser().then();

                }}>
                yes
              </Button>
            </Stack>

            <Box />
          </Stack>
        </DialogContent>
      </Dialog>
    </Portal>
        {/* <Portal>
          <div>

            <Dialog
              open={open}
              onClose={handleClose}
              fullWidth={true}
              maxWidth={'sm'}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" color={'primary'}>
                Before you move forward...
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This action will permanently remove the user.
                  Do you want to proceed with this action?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Go back</Button>
                <Button onClick={()=> {
                  deleteUser().then();

                }} variant={'contained'} color={'warning'} size={'small'} sx={{px: 3}} endIcon={removeAdminLoading ? <CircularProgress size={20}/> : '' } disabled={removeAdminLoading}>
                  Remove user
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Portal> */}
      </Container>
    );
  } 
  else{
    return(
      <>
      {
          profile?.userType === "business-developer" &&
          profile?.businessDeveloper &&
          profile?.businessDeveloper.investorDetails.portfolio &&
          profile?.businessDeveloper.investorDetails.portfolio.length > 0 &&
          profile?.businessDeveloper.investorDetails.portfolio.map((p, i) => (
            (i === key) ? (<InvestmentPortfolioView profile={profile} i = {i}/> ) : null
        
        ))
      }

      {
          profile?.userType === "service-provider" &&
          profile?.serviceProvider &&
          profile?.serviceProvider.investorDetails.portfolio &&
          profile?.serviceProvider.investorDetails.portfolio.length > 0 &&
          profile?.serviceProvider.investorDetails.portfolio.map((p, i) => (
            (i === key) ? (<InvestmentPortfolioView profile={profile} i = {i}/> ) : null
        
        ))
      }

      {
          profile?.userType === "expert" &&
          profile?.expert &&
          profile?.expert.investorDetails.portfolio &&
          profile?.expert.investorDetails.portfolio.length > 0 &&
          profile?.expert.investorDetails.portfolio.map((p, i) => (
            (i === key) ? (<InvestmentPortfolioView profile={profile} i = {i}/> ) : null
        
        ))
      }

      {
          profile?.userType === "investor" &&
          profile?.investor &&
          profile?.investor.portfolio &&
          profile?.investor.portfolio.length > 0 &&
          profile?.investor.portfolio.map((p, i) => (
            (i === key) ? (<InvestmentPortfolioView profile={profile} i = {i}/> ) : null
        
        ))
      }
      {
          profile?.userType === "business-developer" &&
          profile?.businessDeveloper &&
          profile?.businessDeveloper.experiences &&
          profile?.businessDeveloper.experiences.length > 0 &&
          profile?.businessDeveloper.experiences.map((p, i) => (
            (i === k) ? (<WorkExperienceView profile={profile} i = {i}/> ) : null
        
        ))
      }

      {
          profile?.userType === "service-provider" &&
          profile?.serviceProvider &&
          profile?.serviceProvider.experiences &&
          profile?.serviceProvider.experiences.length > 0 &&
          profile?.serviceProvider.experiences.map((p, i) => (
            (i === k) ? (<WorkExperienceView profile={profile} i = {i}/> ) : null
        
        ))
      }

      {
          profile?.userType === "expert" &&
          profile?.expert &&
          profile?.expert.experiences &&
          profile?.expert.experiences.length > 0 &&
          profile?.expert.experiences.map((p, i) => (
            (i === k) ? (<WorkExperienceView profile={profile} i = {i}/> ) : null
        
        ))
      }
      
      </>
    )
  }
}
