import * as yup from 'yup';

const OpenToCofounderSchema = yup.object({
  areYouInvesting: yup.boolean('Are you investing?'),
  areYouLeading: yup.boolean('Are you leading?'),
  howMuchAreYouInvesting: yup.string('How much are you investing?'),
  onWhatTermsAreYouInvesting: yup.string('On what terms are you investing?'),

});
const initialValues = {
  areYouInvesting: false,
  areYouLeading: false,
  howMuchAreYouInvesting: '',
  onWhatTermsAreYouInvesting: ''
};

const OpenToCofounderValidation = {
  initialValues,
  schema : OpenToCofounderSchema
};

export default  OpenToCofounderValidation;