import * as React from 'react';
import {Box, List, Stack,Tooltip} from "@mui/material";
import {collection, onSnapshot, query, where, orderBy} from "firebase/firestore";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {DB} from "../../../../../config/firebase/firebase";
import RoomListItem from "./room-list-item";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from '@mui/material/Typography';

// DoneAllOutlinedIcon


export default function RoomsList() {
  const {user, roomData, setRoomData, setRoomId, roomId} = React.useContext(AuthUserContext);
  const [rooms, setRooms] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const[inputbtn,setinputbtn]=React.useState();
	const [search, setSearch] = React.useState("");
  const navigator = useNavigate();

  React.useEffect(() => {
    const q = query(
      collection(DB, "rooms"),
      orderBy("updatedAt", "desc"),
      where("participates", "array-contains", user?.uid));

    const unSubs = onSnapshot(q, (querySnapshot) => {
      // debugger
      if (querySnapshot.size > 0) {
        const list = []
        querySnapshot.forEach((doc) => {
          if (roomId === doc.id) {
            setRoomId(doc.id)
            setRoomData({id: doc.id, ...doc.data()})

          }
          list.push({id: doc.id, ...doc.data()})
        });
        setRooms(list)
     /*   querySnapshot.docChanges().forEach((change) => {
          debugger
          if (!change.doc.metadata.hasPendingWrites && change.type === "added") {
            console.log(`RoomID Add ${roomId}`, change.doc.data());
          }
          if (!change.doc.metadata.hasPendingWrites && change.type === "modified") {
            console.log(`RoomID update  ${roomId}`, change.doc.data());
          }
        });*/
      }
    });
    return () => unSubs;
  }, [])
  return(

    <Stack direction={'column'}  sx={{flex:0.25, overflowY: 'scroll' , backgroundColor: 'white', borderRadius: '15px 15px 0px 0px'}} spacing={2}>
        <Stack direction="row" spacing={.5} style={{marginTop:"15%"}}>
        <IconButton onClick={() => navigator(-1)}>
            <ArrowBackIcon sx={{ fontSize: 30, color: '#000' }} />
          </IconButton>
      <Box  onSubmit={(event) => event.preventDefault()}
        component="form"
        sx={{
          p: "5px 10px",
          display: "flex",
          alignItems: "center",
          width: "100%",

          borderRadius: "12px",
          backgroundColor: "#E4EAF5",
          mt: 2
        }}>
        <IconButton
          type="button"
          aria-label="search"
          onClick={(e) => setSearch(inputbtn)}
          >
          <SearchIcon sx={{ color: "#051E40BF" }} />
        </IconButton>
        <InputBase
          sx={{  flex: 1, color: "#051E40BF" }}
          placeholder="Search"
          inputProps={{ "aria-label": "search post" }}
          onChange={(e) => setinputbtn(e.target.value)}
							value={inputbtn}
							onKeyDown={(e)=>{
								if(e.code==="Enter"){
									setSearch(inputbtn)
								}
							}}
        />
      </Box>
      <Tooltip title="Coming Soon" arrow placement="top-start" enterTouchDelay={0}>
         <IconButton>
        <img src={"/assets/message.png"}></img>
          </IconButton></Tooltip>
     
        </Stack>
        {
          rooms.length === 0 ?
          <Stack
								direction={"column"}
								sx={{
									height: "100vh",
									width: "100%",
									backgroundColor: "#fff",
									paddingX: 5,
									textAlign: "center",
									borderRadius: "15px 15px 0px 0px",
								}}
								justifyContent="center"
								alignItems="center"
								spacing={1}>
								<img
									src={"/assets/nova-logo.png"}
									aria-label={"logo"}
									style={{ height: 65, width: 214 }}
								/>

								<Typography variant={"body1"} color={"gray"}>
									Now send and receive messages.
									<br />
								</Typography>
								<Typography variant={"body2"} color={"gray"}>
									Welcome to the AstraNova Chatroom <br />
									You can message those contactable here. Go
									ahead give it a whirl.
								</Typography>
							</Stack> 
          : null
        }
        
      <List sx={{ width: '100%', px: 1}}>
        {
         rooms.length > 0
          &&
           
          rooms
          .filter((rooms) => {
									if (search !== "") {
										const reg = new RegExp(search);
										console.log(rooms);
										return reg.test(rooms.title?.toString())  
										 && rooms;
									} else {
										return rooms;
										
									}
								})
          .map((room, i) =>{
           
            // debugger
            return  ( <>
              <RoomListItem  data={room} key={room.id} />
            </>
            )

          })
        }
      </List>

    </Stack>

  )
}
