import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton, Portal, useMediaQuery} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import AboutEditFormExpert from "../../../../../screens/expert/edit/about-edit-form";
import AboutEditStartupForm from "../../../../../screens/startups/edit/about-edit-startup-form";
import EditStartupDetailsForm from "../../../../../screens/startups/edit/edit-startup-details-form";
import EditStartupTopPrioritiesDetailsForm
  from "../../../../../screens/startups/edit/edit-startup-top-priorities-details-form";
import {ArrowBack} from "@mui/icons-material";
import PostNotification from "../../../../../api/post-notification";

export default function TopPrioritiesStartupComponent({profile,open, handleClose, startupProfile, callback }) {
    const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  const regexAdmin = /admin\/user-details/
  const copyProfile = regexAdmin.test(window.location.pathname) ? startupProfile : {...context.profile}
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? startupProfile.uid : user.uid);
  
  const [isLoading, setLoading] = React.useState(false);
  return (
    <Portal>
        <Dialog
            fullScreen={!matches ? true: false}
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
                {!matches && <IconButton onClick={() => handleClose()}>
                    <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
                </IconButton>}
                {"Edit Top Priorities Details"}
            </DialogTitle>
            <DialogContent sx={{px: !matches ? 0 : ''}}>
          <br/>
          <EditStartupTopPrioritiesDetailsForm
            data={profile}
            cbBack={handleClose}
            cbNext={async (data) => {
              await setDoc(
                userRef,
                {
                  startup: {...profile,...data},
                updatedById:context?.user?.uid,
                updatedBy: regexAdmin.test(window.location.pathname) ? 'admin' : 'user',
                updatedAt: new Date().getTime()
                },
                { merge: true }
              )
              if (!regexAdmin.test(window.location.pathname)) {
                await setProfile({...copyProfile, startup  : {...profile,...data}})
                handleClose()
              } else {
                /*Todo: FOR ADMIN*/
                callback({...copyProfile, startup  : {...profile,...data}})
                PostNotification({
                  type: 'admin-update-profile',
                  imageURL: '/assets/system-noti-icon.png',
                  section: 'Top Priorities',
                  message: `information has been updated by the Admin.`,
                  uid: profile.uid,
                  createdBy: user?.uid
    
                }).then()
              }
            }}
            isLoading={isLoading}
          />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
