
import React, {useEffect} from "react";
import {
	styled,
	Container,
	Stack,
	Typography,
	Avatar,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent, DialogActions, Button, Badge
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import {AuthUserContext} from '../../../../providers/auth-provider'
import InvestorDescription from "../../../../screens/mobile/components/common/profile/investor-description";
import ExpertDescription from "../../../../screens/mobile/components/common/profile/expert-description";
import BusinessDeveloperDescription from "../../../../screens/mobile/components/common/profile/business-developer-description";
import ServiceProviderDescription from "../../../../screens/mobile/components/common/profile/service-provider-description";
import StartupDescription from "../../../../screens/mobile/components/common/profile/startup-description";
import { a11yProps, TabPanel } from "../../../../screens/mobile/components/common/tabs-custom";
import FounderAbout from "../../../../screens/mobile/components/common/profile/founder-about";
import InvestmentPortfolio from "../../components/common/profile/investment-portfolio";
import CommonInvestorDetails from "../../components/common/profile/common-investor-details";
import { useNavigate, useParams } from "react-router-dom";
import { DB } from "../../../../config/firebase/firebase";
import InvestmentPortfolioView from "../../components/common/profile/investor-portfolio-view";
import {EditSharp} from "@mui/icons-material";
import EditFounderDetailsComponent
	from "../../../../components/common/profile/edit/startup/edit-founder-details-component";
import InvestorPortfolioForm from "../../../investor/onboarding-forms/portfolio-form";
import {collection, doc, getDocs, query, setDoc, updateDoc, where} from "firebase/firestore";
import PortfoliosListView from "../../investor/onboarding-forms/portfolios-list-view";
import InvestorPortfolio from "../../../../components/common/profile/investor-portfolio";
import {getAuth, updateProfile} from "firebase/auth";
import PostNotification from "../../../../api/post-notification";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import UploadFile from "../../../../utils/upload-file";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PostItem from "../../components/common/timeline/post-item";


const StyledTabs = styled((props) => (
	<Tabs
		sx={{ position: "relative", left: 0, top: 15 }}
		{...props}
		TabIndicatorProps={{
			children: <span className="MuiTabs-indicatorSpan" />,
		}}
	/>
))({
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
	},
	"& .MuiTabs-indicatorSpan": {
		maxWidth: 40,
		width: "100%",
		backgroundColor: "none",
	},
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: "none",
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(16),
		marginRight: theme.spacing(1),
		borderRadius: "30px",
		color: "#000",
		"&.Mui-selected": {
			color: "#fff",
			backgroundColor: "#233DFF",
		},
		"&.Mui-focusVisible": {
			backgroundColor: "rgba(100, 95, 228, 0.32)",
		},
	}) 
); 
export default function StartupFounderProfile() { 

	const [open, setOpen] = React.useState(false);
	const [openn, setOpenn] = React.useState(false);
	const {user,setUser, setProfile, setFounder} = React.useContext(AuthUserContext);
	const contextUser = React.useContext(AuthUserContext);
	const userRef = doc(DB, "users", user.uid);
	const { founder, profile } = useLocation().state;
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [imageURL, setImageURL] = React.useState();
	const confirmed = React.useRef(false);
	const profileImage = React.useRef();
	const [key , setKey] = React.useState();
	const [index, setIndex] = React.useState(-1);
	const [value, setValue] = React.useState(0);
	const [posts, setPosts] = React.useState([]);
	const navigator = useNavigate();
	const [steps, setSteps] = React.useState(0);
	const [portfolioIndex, setPortfolioIndex] = React.useState();
	const [portfolios, setPortfolios] = React.useState(

		profile?.userType === 'investor' ? profile?.investor?.portfolio : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
			: profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.portfolio
	);
	const regexMatch = new RegExp('/startup/profile/founder')

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	function handleDialogOpen() {
		setDialogOpen(true);
	}
	function handleDialogClose() {
		setDialogOpen(false);
	}
	const Input = styled("input")({
		display: "none",
	});
	async function updateProfilePicture(url) {
		try {
			const auth = getAuth();
			let asdf = await updateProfile(auth.currentUser, {
				displayName: founder.founderName,
				photoURL: url
			});
			debugger
			const data = auth.currentUser.toJSON();
			setUser({ ...user });
			const userRef = doc(DB, "users", auth.currentUser.uid);
			let dat;
			dat = await updateDoc(userRef, {
				"startup.founder.photoURL": url,
			});
			setUser({ ...user, photoURL: url });
			setProfile({ ...profile, photoURL: url });
			Object.assign(profile, {founder: {...founder,photoURL: url}})
			Object.assign(profile,{photoURL: url})
			setProfile({ ...profile, photoURL: url });
			setImageURL("")
			PostNotification({
				type: 'update-profile-picture',
				imageURL: url,
				message: `Your profile picture has been updated`,
				uid: user.uid
			}).then()
			confirmed.current = true;
		} catch (err) {
			console.log(err.message);
		}
	}
	React.useEffect(() => {
		debugger
		if (!imageURL) {
			setImageURL(imageURL ? imageURL : profile?.photoURL!==''? profile.photoURL : founder?.photoURL );
		}

			const q =
				query(
				collection(DB, "timelines"),
				where("uid", "==", profile?.uid));

			getDocs(q).then((querySnapshot) => {
				if (querySnapshot.size > 0) {
					const list = [];
					querySnapshot.forEach((doc) => {
						list.push({ id: doc.id, ...doc.data() });

						console.log(doc.id, " => ", doc.data());
					});
					setPosts(list);
				}
			});

	}, []);
	useEffect(() => {
		console.log(confirmed.current);
	});
	function UploadProfile() {
		return (
			<div>
				<Dialog
					open={dialogOpen}
					onClose={handleDialogClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth={"sm"}
					fullWidth={true}
				>
					<DialogTitle id="alert-dialog-title">
						{founder.founderName}
					</DialogTitle>
					<DialogContent sx={{ height: 400,  }}>
						<Stack direction={"row"} spacing={2}  justifyContent="center"
							   alignItems="center" sx={{width: '100%', height: 300}}>
							<Avatar
								key={imageURL}
								src={imageURL ? imageURL : founder?.photoURL}
								alt={founder?.founderName}
								sx={{
									width: 250,
									height: 250
								}}
								component={"span"}
							/>
						</Stack>

					</DialogContent>
					<DialogActions
						sx={{ justifyContent: "start", alignItems:'center', height: 75 }}>

						<IconButton variant="outlined">
							<label htmlFor={"profile-image"}>
								<Stack direction={"column"}>
									<CameraAltIcon
										sx={{  cursor: "pointer" }}
										fontSize={"large"}
									/>
									<Input
										accept="image/*"
										id="profile-image"
										multiple={false}
										type="file"
										onChange={(e) => {
											confirmed.current = false;
											const fileReader = new FileReader();
											fileReader.readAsDataURL(
												e.target.files[0]
											);
											fileReader.addEventListener(
												"load",
												async function () {
													const file =
														e.target.files[0];

													const path = `profile-image/${file.name}`;
													const imageObject = {
														progress: 0,
														src: this.result,
														path,
														file: e.target.files[0],
														size: (
															e.target.files[0]
																?.size /
															1024 /
															1024
														).toFixed(2),
													};
													profileImage.current =
														imageObject;
													UploadFile(file, path).then(
														(url) => {
															console.log(url);
															setImageURL(url);
														}
													);
												}
											);
										}}
									/>
								</Stack>
							</label>
						</IconButton>
						<IconButton

							variant="outlined"
							onClick={() => {
								profileImage.current = "";
								setImageURL(" ");

								//updateProfilePicture("");
								confirmed.current = true;
								//handleDialogClose();
							}}>
							<DeleteIcon
								fontSize={"large"}
								color={"warning"}

							/>
						</IconButton>
						<Box sx={{flex: 1}}/>
						<Button
							sx={{px: 2}}
							size={"small"}
							onClick={() => {
								profileImage.current = {
									...profileImage,
									photoURL: imageURL,
								};

								updateProfilePicture(imageURL);

								profileImage.current = "";

								handleDialogClose();
								//updateProfilePicture("");

							}}
							variant="contained">
							Save Photo
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
	if(index=== -1){

	return (
		<Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
		  <IconButton onClick={() => navigator(-1)} style={{marginBottom:"15px"}}>
						<ArrowBackIcon sx={{ fontSize: 40,color: '#000' }} />
					</IconButton>
			{founder && (
				<Stack direction={"column"} spacing={5}>
					<Stack
						direction={"column"}
						spacing={0.5}
						sx={{
							// border: '0.5px solid #000000',
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                p: 0,
                pb: 0,
						}}
						>
						<Box
						sx={{
                
                  p: 0.75,
                  borderRadius: "50%",
                  width: "fit-content",
                  position: "relative",
                  left: 30,
                  top: -50,
                }}
						/>
						{console.log('TESTING')}
						<Box
							sx={{
								border: "1px solid #204488",
								p: 0.75,
								borderRadius: "50%",
								width: "fit-content",
								position: "relative",
								left: 30,
								top: -50,
							}}>
							<label>
								<Badge
									overlap="circular"
									sx={{ width: 92, height: 92 }}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
									badgeContent={
										profile?.uid === user?.uid &&
										<Button
											onClick={handleDialogOpen}
											sx={{ ml: 1, mt: 1 }}
											color={"primary"}
											component={"span"}>
											<AddCircleIcon />
										</Button>
									}>
									{profile?.uid === user?.uid &&
										<UploadProfile/>
									}
							<Avatar
								alt={founder?.founderName}
								src={imageURL? imageURL: founder?.photoURL}
								sx={{ width: 92,
                     height: 92  }}>
								<Typography variant={"h3"}>
									{founder?.founderName.charAt(0)}
								</Typography>
							</Avatar>
								</Badge>
							</label>
						</Box>
						<Stack
							direction={"row"}
							spacing={3}
							sx={{ position: "relative", top: -40 }}>
							<div />
							<Stack direction={"column"} spacing={2}>
								<Stack direction={"row"} spacing={3}>
									<Typography
										variant={"h5"}
										sx={{ marginTop: "2.5px" }}>
										{founder?.founderName}
									</Typography>
								</Stack>
								<br></br>
								<Stack
									direction={"row"}
									spacing={1}
									sx={{ position: "relative", top: -10 }}>
								<Box
										sx={{
											backgroundColor: "#E0E3FF",
											px: 1,
											py: 1,
											borderRadius: "19.5px",
										}}>
										<Typography
											style={{
												fontWeight: 500,
												textTransform: "capitalize",
											}}>
											{"Founder"}
										</Typography>
									</Box>
									</Stack>
								<Stack
									direction={"row"}
									spacing={0}
									sx={{ position: "relative", top: -10 }}>
									<Typography variant={'body2'} color={"grey"} >
										{profile?.countryState}
									</Typography>
									{ regexMatch.test(window.location.pathname) && profile?.uid === user?.uid  && 	<IconButton style={{marginTop:"-7px"}}   onClick={() => setOpen(true)}>
										<EditSharp />
									</IconButton>
									}


								</Stack>

								{founder && founder?.description && (
									<Typography
										variant={"body1"}
										color={"secondary"}
										sx={{
											width: "calc(100% - 10%)",
											position: "relative",
											top: -10,
										    whiteSpace:"pre-line"
										}}>
										{founder?.description}
									</Typography>
								)}
								{profile?.startup?.founder && (
					<Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent="flex-start"
                      sx={{ position: "relative", minWidth: 120, cursor: 'pointer' , marginTop:'-13px'}}
                      alignItems="flex-start"

                      onClick={() => navigator( regexMatch.test(window.location.pathname) ? -1 : `/m/${contextUser?.profile?.userType}/startup-details/${profile?.uid}`)
                      }
                    >
                      <Box
                        sx={{
                          border: "1px solid #204488",
                          p: 0.75,
                          borderRadius: "50%",
                          width: "fit-content",
                        }}
                      >
                        <Avatar
                          alt={   profile?.startup?.startupName  }
                          src={
                            profile?.startup?.logoURL 
                          }
                          sx={{ width: 51, height: 51 }}
                        >
                          <Typography variant={"h4"} >
                            {profile?.startup?.founder.founderName.charAt(0)}
                          </Typography>
                        </Avatar>
                      </Box>
		
                      <Typography variant={"h6"}>
					  {profile?.startup?.startupName  }
                        {/* {profile?.startup?.founder.founderName} */}
                        <br />
                        <font
                          size={2}
                          style={{
                            backgroundColor: "#F6F8FA",
                            padding: "5px 10px",
                            borderRadius: "6px",
                            fontWeight: 300,
                          }}
                        >
                          Startup
                        </font>
                      </Typography>
                    </Stack>
								)}
								<EditFounderDetailsComponent profile={profile} open={open} handleClose={() => setOpen(false)} />
                                <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
								<StyledTabs
								    variant="scrollable"
                                    scrollButtons="auto"
									value={value}
									onChange={handleChange}
									aria-label="styled tabs example">
									<StyledTab
										label="ABOUT"
										{...a11yProps(0)} sx={{fontSize:'14px'}}
									/>
									<StyledTab
										label="INVESTMENT-PORTFOLIO"
										{...a11yProps(1)} sx={{fontSize:'14px'}}
									/>
									<StyledTab
										label="POST"
										{...a11yProps(2)} sx={{fontSize:'14px'}}
									/>
								</StyledTabs>
								</Box>
							</Stack>
						</Stack>
					</Stack>
					{/* <Stack
						direction={"column"}
						spacing={3}
						sx={{
							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
							borderRadius: "15px",
							backgroundColor: "#fff",
							minHeight: 100,
							px: 5.5,
							py: 6,
						}}>
						<FounderAbout founder={founder} />
					</Stack> */}
					<TabPanel index={0} value={value}>
					<Stack
                direction={"column"}
                spacing={3}
                sx={{
                  border: '0.5px solid #000000',
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 3,
                  py: 3,
                }}>
                  <FounderAbout profile={profile} founder= {founder} />
		
              </Stack>
            </TabPanel>

<TabPanel index={0} value={value}>
              <Stack
                direction={"column"}
                spacing={3}
                sx={{
                  border: '0.5px solid #000000',
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 3,
                  py: 6,
                }}>

                <CommonInvestorDetails profile={profile}/>

              </Stack>
            </TabPanel>


            <TabPanel
              index={ 1 }
              value={value}
            >
				<Stack
					direction={"column"}
					spacing={6}
					sx={{							border: '0.5px solid #000000',

						boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
						borderRadius: "15px",
						backgroundColor: "#fff",
						minHeight: 100,
						px: 2,
						py: 3,
					}}
				>
					{regexMatch.test(window.location.pathname) && profile?.uid === user?.uid ? <>

						{steps === 1 ?<>
								{(portfolioIndex !== undefined && portfolioIndex > -1) ?
									<Typography variant={"h5"}>Update portfolio</Typography> :  <Typography variant={"h5"}>Add portfolio</Typography>}
								<InvestorPortfolioForm
									cbBack={() => {
										setSteps(0)
										setPortfolioIndex()
										setOpenn(false)

									}}
									cbNext={(data, index) => {
										const port = [...portfolios]
										if (index !== undefined && index > -1) {
											port.splice(index, 1, { ...data})
										} else {
											port.push({ ...data })
										}
										const copyPortfolio = []
										debugger
										if (port.length > 0) {
											for (let portfolio of port) {
												const attachments =[]
												if (portfolio.attachments.length > 0) {
													for (let file of portfolio.attachments) {
														if (file?.file) {
															const path = `investors/${user.uid}/attachments/${file.file.name}`
															attachments.push(path)
														} else {
															attachments.push(file)
														}
													}
												}
												copyPortfolio.push({...portfolio, attachments})
											}
										}
										const objectUpdate = {}
										if (profile?.userType == 'service-provider') {
											objectUpdate['serviceProvider'] = {...profile['serviceProvider']}
										} else if (profile?.userType == 'business-developer'){
											objectUpdate['businessDeveloper'] = {...profile['businessDeveloper']}
										} else {
											debugger
											objectUpdate[profile?.userType] = {...profile[profile?.userType]}
										}
										if (profile?.userType !== 'investor') {
											debugger
											objectUpdate[
												profile?.userType === 'service-provider' ? 'serviceProvider'
													: profile?.userType === 'business-developer' ? 'businessDeveloper'
														: profile?.userType].investorDetails.portfolio = [...copyPortfolio];
										} else {
											debugger
											objectUpdate[profile?.userType].portfolio = [...copyPortfolio] ;
										}
										setPortfolios([...copyPortfolio])
										setDoc(
											userRef,
											objectUpdate,
											{ merge: true }
										).then(() => {
											setProfile({...profile,...objectUpdate })
										});
										navigator('/m/startup/startup-founder-profile', {state: {profile:{...profile,...objectUpdate }, founder }})

										setSteps(0)

										setOpenn(false)

										setPortfolioIndex();

									}}
									data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}

									index={portfolioIndex}
									open={openn}
									handleClose={() => setOpenn(false)}
								/>
							</>
							: <>
								<Typography fontSize={20} fontWeight={600}>Investment portfolio</Typography>

								<PortfoliosListView

									add={(data) => {
										setPortfolioIndex();
										setSteps(1);
										setOpenn(true)

									}}
									edit={async (ind) => {
										debugger
										await setPortfolioIndex(ind);
										setSteps(1);
										setOpenn(true)


									}}
									remove={(ind) => {
										const port = [...portfolios]
										port.splice(ind, 1)
										setPortfolios([...port])
										const objectUpdate = {}
										if (profile?.userType == 'service-provider') {
											objectUpdate['serviceProvider'] = {...profile['serviceProvider']}
										} else if (profile?.userType == 'business-developer'){
											objectUpdate['businessDeveloper'] = {...profile['businessDeveloper']}
										} else {
											debugger
											objectUpdate[profile?.userType] = {...profile[profile?.userType]}
										}
										if (profile?.userType !== 'investor') {
											debugger
											objectUpdate[
												profile?.userType === 'service-provider' ? 'serviceProvider'
													: profile?.userType === 'business-developer' ? 'businessDeveloper'
														: profile?.userType].investorDetails.portfolio = [...port];
										} else {
											debugger
											objectUpdate[profile?.userType].portfolio = [...port] ;
										}
										setDoc(
											userRef,
											objectUpdate,
											{ merge: true }
										).then(() => {
											setProfile({...profile,...objectUpdate })

										});
										navigator('/m/startup/startup-founder-profile', {state: {profile:{...profile,...objectUpdate }, founder }})
										setSteps(0);

									}}
									portfolios={portfolios}
								/>
							</>

						}

					</> : <InvestorPortfolio profile={profile} setIndex={setIndex} setKey={setKey}/>}

				</Stack>

            </TabPanel>
            {console.log(profile)}
            <TabPanel
            index={ 2 }
			value={value}
            >
              
                <Stack direction={"column"} spacing={5} sx={{
                    width:"100%",
                    border: '0.5px solid #000000',
                    boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                    borderRadius: "15px",
                    backgroundColor: "#fff",
                    minHeight: 100,
                    px: 0,
                    py: 0,
                  }}
				>
                  {posts.length > 0 ?
                    posts.map((post, i) => <PostItem mobile={true} data={post} key={i} />)
					: <Typography>There are no posts to be shown</Typography>}
                </Stack>
              
            </TabPanel>

					
				</Stack>
			)}
		</Container>
	);
		}

		else{
			return( 
				
				        profile?.userType === 'startup' && 
						profile?.startup && 
						profile?.startup.investorDetails.portfolio &&
					profile?.startup.investorDetails.portfolio.map((p, i) => (
						  (i === key) ? (<InvestmentPortfolioView profile={profile} i = {i}/> ) : null
					  
					  ))
				
			)

} 
}


// import React from "react";
// import { DB } from "../../../config/firebase/firebase";
// import { styled, Container, Stack, Typography, Avatar } from "@mui/material";
// import Tabs from "@mui/material/Tabs";
// import { a11yProps, TabPanel } from "../../../components/common/tabs-custom";
// import Tab from "@mui/material/Tab";
// import Box from "@mui/material/Box";
// import PostItem from "../../../components/common/timeline/post-item";
// // import {AuthUserContext} from "../../providers/auth-provider";
// import {getAuth, getIdToken} from "firebase/auth";
// import {useLocation, useNavigate} from "react-router-dom";
// import {AuthUserContext} from "../../../providers/auth-provider";
// import InvestmentPortfolio from "../../components/common/profile/investment-portfolio";
// import StartupAbout from "../../components/common/profile/startup-about";
// import InvestorDescription from "../../components/common/profile/investor-description";
// import ExpertDescription from "../../components/common/profile/expert-description";
// import BusinessDeveloperDescription from "../../components/common/profile/business-developer-description";
// import ServiceProviderDescription from "../../components/common/profile/service-provider-description";
// import StartupDescription from "../../components/common/profile/startup-description";
// import FounderAbout from "../../components/common/profile/founder-about";
// import {
// 	collection,
// 	getDocs,
// 	where,
// 	query,
// 	doc,
// 	getDoc,
// 	setDoc
//   } from "firebase/firestore";
// import StartChat from "../../../../components/common/start-chat";
// import FounderInvestorAbout from "../../../components/common/profile/founder-investor-about";
// import InvestorPortfolioForm from "../../investor/onboarding-forms/portfolio-form";
// import PortfoliosListView from "../../investor/onboarding-forms/portfolios-list-view";

// const StyledTabs = styled((props) => (
// 	<Tabs
// 		sx={{ position: "relative", left: 0, top: 15 }}
// 		{...props}
// 		TabIndicatorProps={{
// 			children: <span className="MuiTabs-indicatorSpan" />,
// 		}}
// 	/>
// ))({
// 	"& .MuiTabs-indicator": {
// 		display: "flex",
// 		justifyContent: "center",
// 		backgroundColor: "transparent",
// 	},
// 	"& .MuiTabs-indicatorSpan": {
// 		maxWidth: 40,
// 		width: "100%",
// 		backgroundColor: "none",
// 	},
// });
// const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
// 	({ theme }) => ({
// 		textTransform: "none",
// 		fontWeight: theme.typography.fontWeightRegular,
// 		fontSize: theme.typography.pxToRem(16),
// 		marginRight: theme.spacing(1),
// 		borderRadius: "30px",
// 		color: "#000",
// 		"&.Mui-selected": {
// 			color: "#fff",
// 			backgroundColor: "#233DFF",
// 		},
// 		"&.Mui-focusVisible": {
// 			backgroundColor: "rgba(100, 95, 228, 0.32)",
// 		},
// 	})
// );
// export default function StartupFounderProfile({}) {
// 	const {user, setProfile} = React.useContext(AuthUserContext);

// 	const { founder,profile } = useLocation().state;
// 	const [portfolioIndex, setPortfolioIndex] = React.useState();
// 	const [steps, setSteps] = React.useState(0);
// 	const userRef = doc(DB, "users", user.uid);

// 	const [portfolios, setPortfolios] = React.useState(

// 		profile?.userType === 'investor' ? profile?.investor?.portfolio : profile[profile?.userType === 'service-provider' ? 'serviceProvider'
// 			: profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails?.portfolio
// 	);
// 	const [posts, setPosts] = React.useState([]);
// 	const [index, setIndex] = React.useState(-1);
// 	// const [profile, setProfile] = React.useState(null);
// 	const [value, setValue] = React.useState(0);
// 	const navigator = useNavigate()
// 	const handleChange = (event, newValue) => {
// 		setValue(newValue);
// 	};
// 	React.useEffect(() => {


// 			const q =
// 				query(
// 				collection(DB, "timelines"),
// 				where("uid", "==", profile?.uid));

// 			getDocs(q).then((querySnapshot) => {
// 				if (querySnapshot.size > 0) {
// 					const list = [];
// 					querySnapshot.forEach((doc) => {
// 						list.push({ id: doc.id, ...doc.data() });

// 						console.log(doc.id, " => ", doc.data());
// 					});
// 					setPosts(list);
// 				}
// 			});

// 	}, []);
// 	return (
// 		<Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
// 			{/* {console.log(startup)} */}
// 			{founder && (
// 				<Stack direction={"column"} spacing={5}>
// 					<Stack
// 						direction={"column"}
// 						spacing={0.5}
// 						sx={{
// 							boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
// 							borderRadius: "15px",
// 							backgroundColor: "#fff",
// 							minHeight: 100,
// 							p: 2,
// 							pb: 0,
// 						}}>
// 						<Box
// 							sx={{
// 								height: 120,
// 								width: "100%",
// 								backgroundColor: "#F6F8FA",
// 								borderRadius: "15px",
// 							}}
// 						>
// 							<Box
// 								sx={{
// 									border: "1px solid #204488",
// 									p: 0.75,
// 									borderRadius: "50%",
// 									width: "fit-content",
// 									position: "relative",
// 									left: 35,
// 									top: 60,
// 								}}>
// 								<Avatar
// 									alt={founder?.founderName}
// 									src={founder?.photoURL}
// 									sx={{ width: 92, height: 92 }}>
// 									<Typography variant={"h3"}>
// 										{founder?.founderName.charAt(0)}
// 									</Typography>
// 								</Avatar>
// 							</Box>
// 						</Box>
// 						{console.log('TEST')}

// 						<Stack
// 							direction={"row"}
// 							spacing={3}
// 							sx={{ position: "relative", top: 50 }}>
// 							<div />
// 							<Stack direction={"column"} spacing={2} sx={{width: '100%'}}>
// 								<Stack direction={"row"} spacing={3}>
// 									<Typography
// 										variant={"h5"}
// 										sx={{ marginTop: "2.5px" }}>
// 										{founder?.founderName}
// 									</Typography>
// 									<Box
// 										sx={{
// 											backgroundColor: "#E0E3FF",
// 											px: 3,
// 											py: 1,
// 											borderRadius: "19.5px",
// 										}}>
// 										<Typography
// 											style={{
// 												fontWeight: 500,
// 												textTransform: "capitalize",
// 											}}>
// 											{"Founder"}
// 										</Typography>
// 									</Box>
// 									{
// 										profile?.startup?.investorDetails?.areYouAnAngelInvestor &&

// 										<Box
// 											sx={{
// 												backgroundColor: "#B8FC3C",
// 												px: 3,
// 												py: 1,
// 												borderRadius: "19.5px",
// 											}}>
// 											<Typography
// 												style={{
// 													fontWeight: 500,
// 													textTransform: "capitalize",
// 												}}>
// 												{"Investor"}
// 											</Typography>
// 										</Box>
// 									}
// 								</Stack>
// 								<Stack
// 									direction={"row"}
// 									spacing={1}
// 									sx={{ position: "relative", top: -10 }}>
// 									{profile.countryState ? <Typography variant={'body2'} color={"grey"}>
// 										{profile.countryState}
// 									</Typography> : null}
// 								</Stack>

// 								{founder && founder?.description && (
// 									<Typography
// 										variant={"body1"}
// 										color={"secondary"}
// 										sx={{
// 											width: "calc(100% - 10%)",
// 											position: "relative",
// 											top: -10,
// 										}}>
// 										{founder?.description}
// 									</Typography>
// 								)}


// 							</Stack>
// 							<div style={{flex: 1}}/>
// 							<Stack
// 								direction={"column"}
// 								spacing={1}
// 								justifyContent="center"
// 								sx={{ position: "relative", right: 15, minWidth: 120, cursor: 'pointer' }}
// 								alignItems="center"

// 								onClick={() => navigator(-1)
// 								}
// 							>
// 								<Box
// 									sx={{
// 										border: "1px solid #204488",
// 										p: 0.75,
// 										borderRadius: "50%",
// 										width: "fit-content",
// 									}}
// 								>
// 									<Avatar
// 										alt={profile?.startup?.startupName}
// 										src={
// 											profile?.startup?.logoURL !== ""
// 												? profile?.startup?.logoURL
// 												: profile?.photoURL
// 										}
// 										sx={{ width: 51, height: 51 }}
// 									>
// 										<Typography variant={"h4"} >
// 											{profile?.startup?.startupName.charAt(0)}
// 										</Typography>
// 									</Avatar>
// 								</Box>
// 								<Typography variant={"h6"} sx={{ textAlign: "center" }}>
// 									{profile?.startup?.startupName}
// 									<br />
// 									<font
// 										size={2}
// 										style={{
// 											backgroundColor: "#F6F8FA",
// 											padding: "5px 10px",
// 											borderRadius: "6px",
// 											fontWeight: 300,
// 										}}
// 									>
// 										Startup
// 									</font>
// 								</Typography>
// 							</Stack>
// 						</Stack>
// 						<Box sx={{p: 2, marginTop: '50px!important'}}>

// 							<StyledTabs
// 								value={value}
// 								onChange={handleChange}
// 								aria-label="styled tabs example"
// 							>
// 								<StyledTab label="ABOUT" {...a11yProps(0)} />
// 								<StyledTab label={"INVESTMENT PORTFOLIO"} {...a11yProps(1)} />)
// 								<StyledTab label="POST" {...a11yProps(2)} />
// 								{profile?.uid !== user?.uid &&   <StartChat
// 									sx={{marginLeft: 'auto' , marginRight: '5%',color:'white',backgroundColor: "#233DFF"}}
// 									data={{...profile, creatorData:profile }} props={{...a11yProps(4)}}/>
// 								}


// 							</StyledTabs>
// 						</Box>
// 						<Box sx={{height: 30}}/>
// 					</Stack>

// 					<TabPanel index={0} value={value}>
// 						<Stack
// 							direction={"column"}
// 							spacing={3}
// 							sx={{
// 								boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
// 								borderRadius: "15px",
// 								backgroundColor: "#fff",
// 								minHeight: 100,
// 								px: 5.5,
// 								py: 6,
// 							}}
// 						>
// 							{ profile?.userType === "startup" ?
// 								<FounderAbout profile={profile} founder={founder} /> :
// 								null }

// 						</Stack>
// 					</TabPanel>

// 					<TabPanel
// 						index={ 1 }
// 						value={value}
// 					>
// 						<Stack
// 							direction={"column"}
// 							spacing={6}
// 							sx={{
// 								boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
// 								borderRadius: "15px",
// 								backgroundColor: "#fff",
// 								minHeight: 100,
// 								px: 5.5,
// 								py: 6,
// 							}}
// 						>
// 							{profile?.uid === user?.uid ? <>

// 							{steps === 1 ?<>
// 									{(portfolioIndex !== undefined && portfolioIndex > -1) ?
// 										<Typography variant={"h5"}>Update portfolio</Typography> :  <Typography variant={"h5"}>Add portfolio</Typography>}
// 									<InvestorPortfolioForm
// 										cbBack={() => {
// 											setSteps(0)
// 											setPortfolioIndex()
// 										}}
// 										cbNext={(data, index) => {
// 											const port = [...portfolios]
// 											if (index !== undefined && index > -1) {
// 												port.splice(index, 1, { ...data})
// 											} else {
// 												port.push({ ...data })
// 											}
// 											const copyPortfolio = []
// 											debugger
// 											if (port.length > 0) {
// 												for (let portfolio of port) {
// 													const attachments =[]
// 													if (portfolio.attachments.length > 0) {
// 														for (let file of portfolio.attachments) {
// 															if (file?.file) {
// 																const path = `investors/${user.uid}/attachments/${file.file.name}`
// 																attachments.push(path)
// 															} else {
// 																attachments.push(file)
// 															}
// 														}
// 													}
// 													copyPortfolio.push({...portfolio, attachments})
// 												}
// 											}
// 											const objectUpdate = {}
// 											if (profile?.userType == 'service-provider') {
// 												objectUpdate['serviceProvider'] = {...profile['serviceProvider']}
// 											} else if (profile?.userType == 'business-developer'){
// 												objectUpdate['businessDeveloper'] = {...profile['businessDeveloper']}
// 											} else {
// 												debugger
// 												objectUpdate[profile?.userType] = {...profile[profile?.userType]}
// 											}
// 											if (profile?.userType !== 'investor') {
// 												debugger
// 												objectUpdate[
// 													profile?.userType === 'service-provider' ? 'serviceProvider'
// 														: profile?.userType === 'business-developer' ? 'businessDeveloper'
// 															: profile?.userType].investorDetails.portfolio = [...copyPortfolio];
// 											} else {
// 												debugger
// 												objectUpdate[profile?.userType].portfolio = [...copyPortfolio] ;
// 											}
// 											setPortfolios([...copyPortfolio])
// 											setDoc(
// 												userRef,
// 												objectUpdate,
// 												{ merge: true }
// 											).then(() => {
// 												setProfile({...profile,...objectUpdate })
// 											});
// 											navigator('/startup/startup-founder-profile', {state: {profile:{...profile,...objectUpdate }, founder }})

// 											setSteps(0)



// 											setPortfolioIndex();

// 										}}
// 										data={(portfolioIndex !== undefined && portfolioIndex > -1)  ? portfolios[portfolioIndex] : undefined}

// 										index={portfolioIndex}
// 									/>
// 								</>
// 								: <>
// 									<Typography variant={'h5'}>Investment portfolio</Typography>

// 									<PortfoliosListView

// 										add={(data) => {
// 											setPortfolioIndex();
// 											setSteps(1);
// 										}}
// 										edit={async (ind) => {
// 											debugger
// 											await setPortfolioIndex(ind);
// 											setSteps(1);

// 										}}
// 										remove={(ind) => {
// 											const port = [...portfolios]
// 											port.splice(ind, 1)
// 											setPortfolios([...port])
// 											const objectUpdate = {}
// 											if (profile?.userType == 'service-provider') {
// 												objectUpdate['serviceProvider'] = {...profile['serviceProvider']}
// 											} else if (profile?.userType == 'business-developer'){
// 												objectUpdate['businessDeveloper'] = {...profile['businessDeveloper']}
// 											} else {
// 												debugger
// 												objectUpdate[profile?.userType] = {...profile[profile?.userType]}
// 											}
// 											if (profile?.userType !== 'investor') {
// 												debugger
// 												objectUpdate[
// 													profile?.userType === 'service-provider' ? 'serviceProvider'
// 														: profile?.userType === 'business-developer' ? 'businessDeveloper'
// 															: profile?.userType].investorDetails.portfolio = [...port];
// 											} else {
// 												debugger
// 												objectUpdate[profile?.userType].portfolio = [...port] ;
// 											}
// 											setDoc(
// 												userRef,
// 												objectUpdate,
// 												{ merge: true }
// 											).then(() => {
// 												setProfile({...profile,...objectUpdate })

// 											});
// 											navigator('/startup/startup-founder-profile', {state: {profile:{...profile,...objectUpdate }, founder }})
// 											setSteps(0);

// 										}}
// 										portfolios={portfolios}
// 									/>
// 								</>

// 							}

// 						</> : <InvestmentPortfolio profile={profile} setIndex={setIndex} />}

// 						</Stack>
// 					</TabPanel>
// 					<TabPanel
// 						index={ 0 }
// 						value={value}
// 					>
// 						<Stack
// 							direction={"column"}
// 							spacing={6}
// 							sx={{
// 								boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
// 								borderRadius: "15px",
// 								backgroundColor: "#fff",
// 								minHeight: 100,
// 								px: 5.5,
// 								py: 6,
// 							}}
// 						>
// 							<FounderInvestorAbout profile={profile}  />
// 						</Stack>
// 					</TabPanel>
// 					<TabPanel
// 						index={2}
// 						value={value}
// 					>
// 						<Container maxWidth={"md"}>
// 							<Stack direction={"column"} spacing={5}>
// 								{posts.length > 0 &&
// 									posts.map((post, i) => <PostItem data={post} key={i} />)}
// 							</Stack>
// 						</Container>
// 					</TabPanel>
// 					{/* <FounderAbout founder={founder} /> */}

// 				</Stack>
// 			)}
// 		</Container>
// 	);
// }