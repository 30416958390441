import React from "react";
import {
	Button,
	Container, MenuItem,
	Stack,
	TextField,Typography
} from "@mui/material";
import { useFormik } from "formik";
import ServiceProviderDetailsValidation from "../../../../validations/service-provider/provider-profile";

export default function ServiceProviderFirstForm({ cbBack, cbNext, data }) {


	const formikFirst = useFormik({
		initialValues: data
			? data
			: ServiceProviderDetailsValidation.ProviderFirstFormValues,
		validationSchema:
		ServiceProviderDetailsValidation.ProviderFirstForm,
		onSubmit: async (values) => {

			cbNext({
				...values
			});
		},
	});

	return (
		<Stack
			direction={"column"}
			spacing={10}
			component="form"
			onSubmit={(e) => formikFirst.handleSubmit(e)}>
			<Container maxWidth={"md"}>
				<Stack direction={"column"} spacing={2}>
				<Stack direction={"column"} spacing={1}>
                <Typography
								fontWeight={400}
								fontSize={"16px"}
								textAlign="start"
								sx={{marginBottom:"4px"}}
							  >Service Provider Type *
							  </Typography>
					<TextField
						// required
						name={"organisationType"}
						value={formikFirst.values.organisationType}
						onChange={formikFirst.handleChange}
						error={
							formikFirst.touched.organisationType &&
							Boolean(formikFirst.errors.organisationType)
						}
						helperText={
							formikFirst.touched.organisationType &&
							formikFirst.errors.organisationType
						}
						//label={"Service Provider Type"}
						select
						sx={{textAlign: 'left'}}
					>
						<MenuItem value={'Organisation'} >
							Organisation
						</MenuItem>
						<MenuItem value={'Individual'} >
							Individual
						</MenuItem>
					</TextField>
					</Stack>
				</Stack>
			</Container>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="center"
				spacing={3}
				width={"100%"}>
				<Button
					variant={"outlined"}
					color={"primary"}
					sx={{ px: 5 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
					onClick={cbBack}>
					Back
				</Button>
				<Button
					variant={"contained"}
					color={"primary"}
					sx={{ px: 5 }}
					style={{paddingLeft:"15%",paddingRight:"15%"}}
					type={"submit"}>
					Next
				</Button>
			</Stack>
		</Stack>
	);
}
