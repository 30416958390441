import * as React from "react";

import { Button, CircularProgress } from "@mui/material";
import {
	addDoc,
	collection, doc,
	getDocs,
	query,
	serverTimestamp, setDoc,
	where,
} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import { AuthUserContext } from "../../providers/auth-provider";
import { useNavigate } from "react-router-dom";

export default function StartConversation({ data }) {
	const { user, setRoomId, profile } = React.useContext(AuthUserContext);
	const navigator = useNavigate();
	const [isLoading, setLoading] = React.useState(false);
	const create = async () => {
		debugger;
		setLoading(true);
		/*   const un = /-/;
    let title= '' ;
    let logo = '';
    const userType =data.creatorData.userType;
    if (un.test(userType)) {
      if (userType === 'service-provider') {
      } else {
        /!*businessDeveloper*!/
      }
    } else {
      if (userType === 'startup') {

      } else if(userType === 'investor') {

      } else if (userType === 'expert') {

      }  else{
        /!*talent*!/
      }

    }*/
		const userType = data.creatorData.userType;
		if (data?.uid !== profile?.uid) {
			try {
				/*
          * const getRoom = await roomRef
                    .where("projectId", "==", projectId)
                    .where("participates", "array-contains", profile?.uid)
                    .get();
          * */
				debugger;
				const q = query(
					collection(DB, "rooms"),
					where('participates', 'in', [[data?.uid, profile?.uid], [profile?.uid,data?.uid]]),
				);
				const getRoom = await getDocs(q);
				let room =
					getRoom.size > 0
						? {id: getRoom.docs[0].id, ...getRoom.docs[0].data()}
						: await addDoc(collection(DB, "rooms"), {
							requestId: data.id,
							title:
								userType === "startup"
									? data.creatorData?.startup?.startupName
									: userType === "service-provider"
										? data.creatorData?.serviceProvider
											?.providerName
										: data.creatorData?.displayName,
							subTitle: data?.requestFor,
							participates: [data?.uid, user.uid],
							uid: user?.uid,
							logo:
								userType === "startup"
									? data.creatorData?.startup?.logoURL
									: userType === "service-provider"
										? data.creatorData?.serviceProvider?.logoURL
										: data.creatorData?.photoURL,
							updatedAt: serverTimestamp(),
							createdAt: serverTimestamp(),
							lastMsg: {
								text: "Please go ahead and start conversation",
								status: "send",
							},
						});
				// await setRoomId(room.id);
				if (getRoom.size === 0) {
					let message = await addDoc(collection(DB, "messages"), {
						type: "system-generated",
						text: "Please go ahead and start conversation",
						sender: user.uid,
						receiver: data?.uid,
						createdAt: serverTimestamp(),
						roomId: room?.id,
						read: false,
						status: "send",
					});
				} else {
					await setDoc(doc(DB, "rooms", getRoom.docs[0].id), {updatedAt: serverTimestamp()}, {merge:true})
				}
				setLoading(false);

				navigator(`/${profile.userType}/chat`);
			} catch (e) {
				debugger;
				setLoading(false);
			}
		} else {

			setLoading(false)

		}

	};
	return (
		<Button
			size={"small"}
			variant={"contained"}
			color={"primary"}
			sx={{ px: 4 }}
			onClick={create}
			endIcon={isLoading && <CircularProgress size={20} />}>
			CHAT NOW
		</Button>
	);
}
