import * as React from "react";
import {collection, doc, getDoc, getDocs, onSnapshot, query} from "firebase/firestore";
import {DB} from "../../../../../../config/firebase/firebase";
import {
  Avatar, AvatarGroup, Button, Portal, Stack, Typography, Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText, Box,
  Tabs,
  Tab,
  DialogActions, Paper, IconButton
} from "@mui/material";
import {useState} from "react";
import LikeListItem from "./../like-list-item";
import {CloseSharp} from "@mui/icons-material";
function GetNameAvatar ({data}) {
  const [name, setName] = useState('')
  React.useEffect(() => {
    const colRef = getDoc(doc(DB, "users",data?.id)).then((doc) => setName(doc?.data()?.displayName))
  },  [])
  return name;
}
export default function ReplyLikesCountCompnent({data, timelineId, commentId, cb}) {
  const [count, setCount] = React.useState(0);
  const [likes, setLikes] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
    setValue(0)
  };
  const [typeCount, setTypeCount] = React.useState({
    'Like':0,
    'Yeah!':0,
    'Celebrate':0,
    'LOL':0,
    'Insightful':0,
    'Puke':0,
    'Funny':0
  })
  React.useEffect(() => {
    setLikes([])
    setCount(0)
    setTypeCount({
      'Like':0,
      'Yeah!':0,
      'Celebrate':0,
      'LOL':0,
      'Insightful':0,
      'Puke':0,
      'Funny':0
    })
    let unsub ;
      const q = query(
        collection(DB, "timelines", timelineId, 'comments',commentId,'replies', data?.id ,"likes")
      )
     unsub = onSnapshot(q, (snapshots) => {
       const updateObject = {    ...typeCount};
       const list = []
       snapshots.forEach((doc) => {
         list.push({id: doc?.id, ...doc?.data()})
         updateObject[doc?.data()?.impression] = updateObject[doc?.data()?.impression] + 1
         setTypeCount({
           ...updateObject
         })
       })
     
       cb(snapshots?.size)
       setLikes(list)
       setCount(snapshots?.size);
     })
     
     return () =>  unsub;
  
    
  }, [data?.id])
  if (likes.length > 0) {
    return (
      <>
        {
          likes?.length > 0 && <Button variant={"text"} size={"small"} onClick={handleClickOpen} key={'comp'+data?.id}>
            <Stack direction={"row"} sx={{}} spacing={1} alignItems={"center"} key={'comp'+data?.id}>
              <AvatarGroup componentsProps={{additionalAvatar: {sx: {display:'none'}}}}  spacing={10} total={count}>
                {Object.keys(typeCount)
                  ?.sort((a, b) => typeCount[b] - typeCount[a])
                  ?.filter((d) => typeCount[d] > 0)
                  ?.map((like)=> (  <Avatar alt={like} title={typeCount[like]} src={`/assets/likes-icons-set/${like !== 'Yeah!' ? like : 'Punch'}.png`}
                                            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.0)', height: 20, width: 20}}/>))}
              </AvatarGroup>
              <Typography variant={"caption"} color={'#8F8F8F'} >{count}</Typography>
  
  
             


            </Stack>
          
          </Button>
        }
        <Portal>
          <Dialog
            fullWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent sx={{p:0}}>
              <Box height={10} />
              <IconButton sx={{float:'right'}} onClick={handleClose}>
                <CloseSharp />
              </IconButton>
              <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label={ <Typography variant={"body1"}>All</Typography>} {...a11yProps(0)} />
                  {
                    Object.keys(typeCount)
                      ?.sort((a, b) => typeCount[b] - typeCount[a])
                      ?.filter((d) => typeCount[d] > 0)
                      ?.map((like, i)=>(
                        likes.filter((d) => d.impression === like)?.length > 0 ?
                          <Tab label={<Stack direction={"row"} spacing={1}>
                            <Avatar alt={like} title={typeCount[like]} src={`/assets/likes-icons-set/${like !== 'Yeah!' ? like : 'Punch'}.png`}
                                    sx={{backgroundColor: '#fff', height: 25, width: 25}}/>
                            <Typography variant={"body1"}>{likes.filter((d) => d.impression === like)?.length}</Typography></Stack> } {...a11yProps(i+1)} /> : null
                
                      ))
                  }
                </Tabs>
              </Box>
              <Stack direction={"column"} spacing={2} p={2}>
                {value !== 0 ?likes
                  ?.filter((d) => d?.impression === Object.keys(typeCount)?.sort((a, b) => typeCount[b] - typeCount[a])?.filter((d) => typeCount[d] > 0)[value-1])
                  .map((lk, i) => (
                    <LikeListItem like={lk}   key={lk?.id} />
                  )) : likes
                  .map((lk, i) => (
                    <LikeListItem like={lk}   key={lk?.id} />
                  ))}
              </Stack>
            </DialogContent>
          </Dialog>
        </Portal>
      </>
    )
  }
}

