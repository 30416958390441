import * as yup from "yup";
import ExportOnboardingValidation from "../expert/onboarding-profile";
import moment from "moment";
import { BUSINESS_DEVELOPER, DEFAULT_MARKET } from "../../utils/enums/labels.enum";

const StartupOnBoardingProfileSchema = yup.object({
	logoURL: yup.string("logo URL"),
	startupName: yup.string("Startup Name").required("Startup Name is required"),
	description: yup
		.string("Description") 
		.required("Description is required.")
		.max(
			150,
			"Character Limit Exceeded i.e. 150 Characters"
		),
	website: yup.string("Website URL").url("The link must be a valid url"),
/*	foundingDate: yup.date().required("Founding Date is required").nullable(),
	registeredDate: yup.date().when("foundingDate",
	(foundingDate, yup) => foundingDate && yup.min(foundingDate, "Registered date cannot be before Founding date")),*/
});
const StartupOnBoardingProfileInitValue = {
	photoURL: "",
	startupName: '',
	description: '',
	website: '',/*
	foundingDate: new Date(),
	registeredDate: new Date(),*/
};
const StartupOnBoardingProfileSecondSchema = yup.object({
	sector: yup.array("Sector").of(yup.string("")).required("Sector is a required field").min(1),
	location: yup.string("Location (HQ)").required("Location is required"),
	market: yup
		.array("Market (Where are your customers?)")
		.of(yup.string(""))
		.required("Market is required").min(1),
	additionalDetails:  yup.array("Additional details").of(yup.string("")).nullable(),
	businessType: yup
		.string("Select type of business")
		.required("Select type of business required."),
});
const StartupOnBoardingProfileSecondInitValue = {
	sector: [],
	location: "",
	market: [DEFAULT_MARKET],
	additionalDetails: [],
	businessType: "",
};
const StartupOnBoardingProfileThirdSchema = yup.object({
	topPriorities: yup.array("Top Priorities").of(yup.string("")).nullable(),
	distributedTeam: yup.boolean("Distributed Team"),
	lookingForFunding: yup.boolean("lookingForFunding"),
});
const StartupOnBoardingProfileThirdInitValue = {
	topPriorities: [],
	distributedTeam: false,
	lookingForFunding: false,
};
const StartupOnBoardingProfileFourSchema = yup.object({ 
	investmentTerms: yup.string("Investment Terms"),
	stage: yup.array("Stage").of(yup.string("")).nullable(),
	currency: yup.string("Currency"),
	startingAt: yup.number("Starting at"),
	upto: yup.number("Upto").moreThan(yup.ref("startingAt"), "Invalid Currency Range"),
	sector: yup.array("Sector").of(yup.string("")).nullable(),
	geographies: yup.array("Geographies").of(yup.string("")).nullable(),
	areYouAStartupInvestor: yup.boolean("Are you a startup investor"),
	doYouLeadInvestmentRounds: yup.boolean("Do you lead investment rounds?"),
});
const StartupOnBoardingProfileFourInitValue = {
	investmentTerms: "",
	stage: [],
	currency: "GBP",
	startingAt: 0,
	upto: 0,
	sector: [],
	geographies: [],
	areYouAStartupInvestor: false,
	doYouLeadInvestmentRounds: false,
};
const LookingForFundingSchema = yup.object({
	round: yup.string("Round"),

	currency: yup.string("Currency"),
	amount: yup
		.number("Starting at")
		.moreThan(0, "Please Enter a number greater than 0"),
	valuation: yup.string("valuation"),

	revenueModel: yup.string("RevenueModel"),
});
const LookingForFundingInitValue = {
	round: "",
	currency: "GBP",
	amount: "",
	valuation: "",
	revenueModel: "",
};
const LookingForFundingSecondSchema = yup.object({
	femaleInFoundingTeam: yup.boolean("Female in founding team"),
	ethnicMinorityInFundingTeam: yup.boolean("ethnic minority in funding team"),
	immigrantInFundingTeam: yup.boolean("immigrant in funding team"),
	distributedTeam: yup.boolean("Distributed Team"),

});
const TopPrioritiesSchema = yup.object({
	lookingForBusinessDeveloper: yup.boolean(`Looking for ${BUSINESS_DEVELOPER.SINGULAR_LABEL}?`),
	lookingForExperts: yup.boolean("Looking for Experts?"),
	lookingForServiceProvider: yup.boolean("Looking for Service Provider?"),
	lookingForFunding: yup.boolean("Looking for Funding?"),
});
const TopPrioritiesValues = {
	lookingForBusinessDeveloper: false,
	lookingForExperts: false,
	lookingForServiceProvider: false,
	lookingForFunding: false
};
const LookingForFundingSecondInitValue = {
	femaleInFoundingTeam: false,
	ethnicMinorityInFundingTeam: false,
	immigrantInFundingTeam: false,
	distributedTeam: false
};
const LookingForFundingThirdSchema = yup.object({
	documentURL: yup.string(
		"Document link (google docs, docsend, dropbox, etc)"
	),
	attachments: yup
		.array(
			"Attachments (upload any supporting documents like a deck, revenue model etc.)"
		)
		.of(yup.string(""))
		.nullable(),
});
const LookingForFundingThirdInitValue = {
	documentURL: "",
	attachments: [],
};
const FounderProfileSchema = yup.object({
	founderName: yup
		.string("Founder’s Name")
		.required("*Founder’s Name is required.")
		.min(3)
		.max(30),
	email: yup.string("Email").email('Email').required("*Email is required."),
	description: yup.string("Tell us about your self").required("*Founder’s description is required."),
	startupName: yup.string("Startup name"),
	photoURL: yup.string("Photo URL"),
	role: yup.string("Role"),
	linkedIn: yup
		.string("LinkedIn URL")
		.matches("linkedin.com", "Please enter valid Linkedin url")
		,
	// role: yup.string("Role").required('Role*'),
});
const FounderProfileInitialValues = {
	founderName: "",
	email: "",
	description: "",
	startupName: "",
	photoURL: "",
	role: "",
	linkedIn: "",
	repeatFounder: false,
	
};
const FounderProfileSecondSchema = yup.object({
	website: yup.string("Website URL").url("The link must be a valid url"),
	linkedIn: yup
		.string("LinkedIn URL")
		.matches("linkedin.com", "Please enter valid Linkedin url")
		,
	twitter: yup
		.string("Twitter Handle")
		.matches("twitter.com", "Please enter valid Twitter Handle")
		,
	repeatFounder: yup.boolean("Repeat Founder"),
});
const FounderProfileSecondInitialValues = {
	website: "",
	linkedIn: "",
	twitter: "",
	repeatFounder: false,
};
const TeamMemberSchema = yup.object({
	name: yup
		.string("Name")
		.required('Name*'),
	role: yup
		.string("Role")
		.required('Role*'),
	email: yup
		.string("Email")
		.email()
		.required('Email*')
});
const TeamMemberInitialValues = {
	name: "",
	role: "",
	email: ""
};
const EditStartupDetailsForm = yup.object({
	startupName: yup.string("Startup name"),
	description: yup.string("Description"),
	countryState: yup.string("Location")
});
const EditStartupDetailsFormValues = {
	startupName: '',
	description: '',
	countryState: ''
};
const EditStartupFounderDetailsForm = yup.object({
	founderName: yup.string("Founder name"),
	description: yup.string("Description"),
	countryState: yup.string("Location")
});
const EditStartupFounderDetailsFormValues = {
	founderName: '',
	description: '',
	countryState: ''
};
const EditStartupAboutDetailsFormValues = {
	...StartupOnBoardingProfileSecondInitValue,
	...LookingForFundingSecondInitValue,
	foundingDate: '',
	registeredDate: '',
	website: '',

};
const EditStartupAboutDetailsForm = yup.object({
	sector: yup.array("Sector").of(yup.string("")).nullable(),
	location: yup.array("Location (HQ)").of(yup.string("")).nullable(),
	market: yup
		.array("Market (Where are your customers?)")
		.of(yup.string(""))
		.nullable(),
	additionalDetails:  yup.array("Additional details").of(yup.string("")).nullable(),
	businessType: yup
		.string("Select type of business")
		.required("Select type of business required."),
	femaleInFoundingTeam: yup.boolean("Female in founding team"),
	ethnicMinorityInFundingTeam: yup.boolean("ethnic minority in funding team"),
	immigrantInFundingTeam: yup.boolean("immigrant in funding team"),
	distributedTeam: yup.boolean("Distributed Team"),
	foundingDate: yup.date("Founding Date"),
	registeredDate: yup.date("Formally registered date").when("foundingDate",
	(foundingDate, yup) => foundingDate && yup.min(foundingDate, "Registered date cannot be before Founding date")),
});

const StartupOnBoardingValidation = {
	StartupOnBoardingProfileSchema,
	StartupOnBoardingProfileInitValue,
	StartupOnBoardingProfileSecondSchema,
	StartupOnBoardingProfileSecondInitValue,
	StartupOnBoardingProfileThirdSchema,
	StartupOnBoardingProfileThirdInitValue,
	StartupOnBoardingProfileFourSchema,
	StartupOnBoardingProfileFourInitValue,
	LookingForFundingSchema,
	LookingForFundingInitValue,
	LookingForFundingSecondSchema,
	LookingForFundingSecondInitValue,
	LookingForFundingThirdSchema,
	LookingForFundingThirdInitValue,
	FounderProfileSchema,
	FounderProfileInitialValues,
	FounderProfileSecondSchema,
	FounderProfileSecondInitialValues,
	TopPrioritiesSchema,
	TopPrioritiesValues,
	TeamMemberSchema,
	TeamMemberInitialValues,
	EditStartupDetailsForm,
	EditStartupDetailsFormValues,
	EditStartupAboutDetailsFormValues,
	EditStartupAboutDetailsForm,
	EditStartupFounderDetailsForm,
	EditStartupFounderDetailsFormValues
};

export default StartupOnBoardingValidation;
