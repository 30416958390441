import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton, Portal, useMediaQuery} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import {ArrowBack} from "@mui/icons-material";
import PostNotification from "../../../../../api/post-notification";

export default function AboutInvestorForm({profile,open, handleClose, investorProfile, callback }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  const regexAdmin = /admin\/user-details/
  const copyProfile = regexAdmin.test(window.location.pathname) ? investorProfile : {...context.profile}
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? investorProfile.uid : user.uid);
  const [isLoading, setLoading] = React.useState(false);
  return (
    <Portal>
      <Dialog
        fullScreen={!matches ? true: false}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >

        <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4}}>
          {!matches && <IconButton onClick={() => handleClose()}>
            <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
          </IconButton>}
          {"Edit Investor Details"}
        </DialogTitle>
        <DialogContent sx={{px: !matches ? 0 : ''}}>
          <br/>
            <AboutEditForm
              data={profile}
              cbBack={handleClose}
              cbNext={async (data) => {
                setLoading(true)
                await setDoc(
                  userRef,
                  {
                    investor: {...profile,...data},
                  updatedById:context?.user?.uid,
                  updatedBy: regexAdmin.test(window.location.pathname) ? 'admin' : 'user',
                  updatedAt: new Date().getTime()
                  },
                  { merge: true }
                )
                if (!regexAdmin.test(window.location.pathname)) {
                  await setProfile({...copyProfile, investor: {...profile, ...data}})
                  setLoading(false)
                  handleClose()
                } else {
                  /*TODO: FOR ADMIN*/
                  setLoading(false)
                  callback({...copyProfile, investor: {...profile,...data}})
                  PostNotification({
                    type: 'admin-update-profile',
                    imageURL: '/assets/system-noti-icon.png',
                    section: 'Investor',
                    message: `information has been updated by the Admin.`,
                    uid: investorProfile.uid,
                    createdBy: user?.uid
    
                  }).then()
                }

              
              }}
              isLoading={isLoading}
            />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
