import React from "react";
import { getStorage, ref, deleteObject } from "firebase/storage";
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Badge,
  IconButton,
  FormControlLabel,
  useMediaQuery,
  styled,
  CircularProgress,
  Link,
  Grid,
  Checkbox,
  Autocomplete,
  Chip,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik, FieldArray, getIn, FormikProvider } from "formik";
import ExportOnboardingValidation from "../../../validations/expert/onboarding-profile";
import DragDropFiles, {
  DragDropItem,
} from "../../../components/common/drag-drop-files";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthUserContext } from "../../../providers/auth-provider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFile from "../../../utils/upload-file";
import dataJson from "../../../const/data.json";
import InfoIcon from "@mui/icons-material/Info";

export default function ExperienceFormGrowthExpert({
  cbBack,
  cbNext,
  data,
  index,
}) {
  const { user } = React.useContext(AuthUserContext);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const Input = styled("input")({
    display: "none",
  });

  const fileTypes = [
    "JPG",
    "JPEG",
    "PNG",
    "GIF",
    "DOC",
    "DOCX",
    "PDF",
    "MOV",
    "XLS",
    "CSV",
  ];
  const [files, setFiles] = React.useState(
    data && data?.attachments ? data?.attachments : []
  );

  const formikFirst = useFormik({
    initialValues: data
      ? data
      : ExportOnboardingValidation.ExperienceFormGrowthExpertValues,
    validationSchema: ExportOnboardingValidation.ExperienceGrowthExpertForm,
    onSubmit: async (values) => {
      cbNext(
        {
          ...values,
          attachments: files.length > 0 ? files : [],
        },
        index
      );
    },
  });

  return (
    <FormikProvider value={formikFirst}>
      <Stack
        direction={"column"}
        spacing={10}
        component="form"
        onSubmit={(e) => formikFirst.handleSubmit(e)}
      >
        <Container maxWidth={"md"}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
            >
              <Typography variant={"body1"}>Company Name *</Typography>
              <TextField
                name={"companyName"}
                value={formikFirst.values.companyName}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.companyName &&
                  Boolean(formikFirst.errors.companyName)
                }
                helperText={
                  formikFirst.touched.companyName &&
                  formikFirst.errors.companyName
                }
                variant={"outlined"}
                fullWidth
              />
            </Stack>

            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
            >
              <Typography variant={"body1"}>Company website URL</Typography>
              <TextField
                variant={"outlined"}
                name={"website"}
                fullWidth
                value={formikFirst.values.website}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.website &&
                  Boolean(formikFirst.errors.website)
                }
                helperText={
                  formikFirst.touched.website && formikFirst.errors.website
                }
              />
            </Stack>
            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
              fullWidth
            >
              <Autocomplete
                fullWidth="true"
                multiple
                required
                options={[...dataJson.stages].map((option) => option)}
                value={formikFirst.values?.companyCurrentStage}
                onChange={(e, newValue) =>
                  formikFirst.setFieldValue("companyCurrentStage", newValue)
                }
                renderTags={(value, getTagProps) =>
                  formikFirst.values?.companyCurrentStage &&
                  formikFirst.values?.companyCurrentStage.length > 0 &&
                  formikFirst.values?.companyCurrentStage.map(
                    (option, index) => (
                      <Chip
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginLeft: "5px",
                          marginRight: "5px",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                        deleteIcon={<CloseIcon />}
                        onDelete={() =>
                          formikFirst.setFieldValue(
                            "companyCurrentStage",
                            formikFirst.values?.companyCurrentStage?.filter(
                              (f) => f !== option
                            )
                          )
                        }
                        label={option}
                        {...getTagProps({
                          index,
                        })}
                      ></Chip>
                    )
                  )
                }
                renderInput={(params) => (
                  <>
                    <Typography
                      fontWeight={400}
                      fontSize={"16px"}
                      textAlign="start"
                    >
                      Company Current Stage*
                    </Typography>
                    <TextField
                      {...params}
                      name={"companyCurrentStage"}
                      error={Boolean(formikFirst.errors.companyCurrentStage)}
                      helperText={
                        formikFirst.touched.companyCurrentStage &&
                        formikFirst.errors.companyCurrentStage
                      }
                      //placeholder="Add"
                      sx={{
                        transition: "0.1s linear",
                      }}
                    />
                  </>
                )}
              />
            </Stack>

            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
            >
              <Typography variant={"body1"}>Role*</Typography>
              <TextField
                variant={"outlined"}
                name={"role"}
                fullWidth
                value={formikFirst.values.role}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.role && Boolean(formikFirst.errors.role)
                }
                helperText={formikFirst.touched.role && formikFirst.errors.role}
              />
            </Stack>

            {/*PUT condtional fields below*/}
            {formikFirst?.values?.role && formikFirst?.values?.role !== "" && (
              <>
                <Stack
                  direction={"column"}
                  spacing={1}
                  alignItems={"flex-start"}
                  sx={{ width: "100%" }}
                  fullWidth
                >
                  <Typography variant={"body1"}>Type of role*</Typography>
                  <Autocomplete
                    fullWidth="true"
                    multiple="false"
                    required
                    options={[...dataJson.typeOfRoles].map((option) => option)}
                    value={formikFirst.values?.typeOfRole}
                    onChange={(e, newValue) =>
                      formikFirst.setFieldValue("typeOfRole", newValue)
                    }
                    renderTags={(value, getTagProps) =>
                      formikFirst.values?.typeOfRole &&
                      formikFirst.values?.typeOfRole.length > 0 &&
                      formikFirst.values?.typeOfRole.map((option, index) => (
                        <Chip
                          style={{ textTransform: "capitalize" }}
                          variant="contained"
                          sx={{
                            backgroundColor: "#E0E3FF",
                            marginLeft: "5px",
                            marginRight: "5px",
                            marginBottom: "10px",
                            borderRadius: "10px",
                          }}
                          deleteIcon={<CloseIcon />}
                          onDelete={() =>
                            formikFirst.setFieldValue(
                              "typeOfRole",
                              formikFirst.values?.typeOfRole?.filter(
                                (f) => f !== option
                              )
                            )
                          }
                          label={option}
                          {...getTagProps({
                            index,
                          })}
                        ></Chip>
                      ))
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          name={"typeOfRole"}
                          error={Boolean(formikFirst.errors.typeOfRole)}
                          helperText={
                            formikFirst.touched.typeOfRole &&
                            formikFirst.errors.typeOfRole
                          }
                          sx={{
                            transition: "0.1s linear",
                          }}
                        />
                      </>
                    )}
                  />
                </Stack>

                <Stack
                  direction={"column"}
                  spacing={1}
                  alignItems={"flex-start"}
                  sx={{ width: "100%" }}
                  fullWidth
                >
                  <Autocomplete
                    fullWidth="true"
                    multiple="false"
                    required
                    options={[...dataJson.rolesDescriptions].map(
                      (option) => option
                    )}
                    value={formikFirst.values?.roleDescription}
                    onChange={(e, newValue) =>
                      formikFirst.setFieldValue("roleDescription", newValue)
                    }
                    renderTags={(value, getTagProps) =>
                      formikFirst.values?.roleDescription &&
                      formikFirst.values?.roleDescription.length > 0 &&
                      formikFirst.values?.roleDescription.map(
                        (option, index) => (
                          <Chip
                            style={{ textTransform: "capitalize" }}
                            variant="contained"
                            sx={{
                              backgroundColor: "#E0E3FF",
                              marginLeft: "5px",
                              marginRight: "5px",
                              marginBottom: "10px",
                              borderRadius: "10px",
                            }}
                            deleteIcon={<CloseIcon />}
                            onDelete={() =>
                              formikFirst.setFieldValue(
                                "roleDescription",
                                formikFirst.values?.roleDescription?.filter(
                                  (f) => f !== option
                                )
                              )
                            }
                            label={option}
                            {...getTagProps({
                              index,
                            })}
                          ></Chip>
                        )
                      )
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          name={"roleDescription"}
                          error={Boolean(formikFirst.errors.roleDescription)}
                          helperText={
                            formikFirst.touched.roleDescription &&
                            formikFirst.errors.roleDescription
                          }
                          sx={{
                            transition: "0.1s linear",
                          }}
                        />
                      </>
                    )}
                  />
                </Stack>
              </>
            )}
            {/*Condition end*/}
            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name={"currentlyWorkingInThisRole"}
                    onChange={formikFirst.handleChange}
                    checked={formikFirst?.values?.currentlyWorkingInThisRole}
                  />
                }
                label="I am currently working in this role"
                disabled={formikFirst?.values?.endDate}
                required
              />
              <Typography variant={"body1"}>Start date</Typography>
              <TextField
                variant={"outlined"}
                name={"startDate"}
                fullWidth
                InputProps={{
                  inputProps: { min: "1900-01-00", max: "2150-12-31" },
                }}
                value={formikFirst.values.startDate}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.startDate &&
                  Boolean(formikFirst.errors.startDate)
                }
                helperText={
                  formikFirst.touched.startDate && formikFirst.errors.startDate
                }
                type={"date"}
                required
              />
            </Stack>

            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
            >
              <Typography variant={"body1"}>End date</Typography>
              <TextField
                variant={"outlined"}
                name={"endDate"}
                fullWidth
                InputProps={{
                  inputProps: {
                    min: formikFirst.values.startDate,
                    max: "2150-12-31",
                  },
                }}
                value={formikFirst.values.endDate}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.endDate &&
                  Boolean(formikFirst.errors.endDate)
                }
                helperText={
                  formikFirst.touched.endDate && formikFirst.errors.endDate
                }
                type={"date"}
                disabled={formikFirst?.values?.currentlyWorkingInThisRole}
                required
              />
            </Stack>
            {console.log(formikFirst?.values?.currentlyWorkingInThisRole)}

            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
            >
              <Typography variant={"body1"}>Experience *</Typography>
              <TextField
                multiline
                rows={4}
                variant={"outlined"}
                name={"experience"}
                placeholder="Tell us about your experience"
                fullWidth
                value={formikFirst.values.experience}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.experience &&
                  Boolean(formikFirst.errors.experience)
                }
                helperText={
                  formikFirst.touched.experience &&
                  formikFirst.errors.experience
                }
              />
            </Stack>

            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
              fullWidth
            >
              <Autocomplete
                fullWidth="true"
                multiple
                required
                options={[...dataJson.stages].map((option) => option)}
                value={formikFirst.values?.companyStageDuringEmployment}
                onChange={(e, newValue) =>
                  formikFirst.setFieldValue(
                    "companyStageDuringEmployment",
                    newValue
                  )
                }
                renderTags={(value, getTagProps) =>
                  formikFirst.values?.companyStageDuringEmployment &&
                  formikFirst.values?.companyStageDuringEmployment.length > 0 &&
                  formikFirst.values?.companyStageDuringEmployment.map(
                    (option, index) => (
                      <Chip
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                        sx={{
                          backgroundColor: "#E0E3FF",
                          marginLeft: "5px",
                          marginRight: "5px",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                        deleteIcon={<CloseIcon />}
                        onDelete={() =>
                          formikFirst.setFieldValue(
                            "companyStageDuringEmployment",
                            formikFirst.values?.companyStageDuringEmployment?.filter(
                              (f) => f !== option
                            )
                          )
                        }
                        label={option}
                        {...getTagProps({
                          index,
                        })}
                      ></Chip>
                    )
                  )
                }
                renderInput={(params) => (
                  <>
                    <Typography
                      fontWeight={400}
                      fontSize={"16px"}
                      textAlign="start"
                    >
                      Company Stage during your employment
                    </Typography>
                    <TextField
                      {...params}
                      name={"companyStageDuringEmployment"}
                      error={Boolean(
                        formikFirst.errors.companyStageDuringEmployment
                      )}
                      helperText={
                        formikFirst.touched.companyStageDuringEmployment &&
                        formikFirst.errors.companyStageDuringEmployment
                      }
                      //placeholder="Add"
                      sx={{
                        transition: "0.1s linear",
                      }}
                    />
                  </>
                )}
              />
            </Stack>

            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
            >
              <Typography variant={"body1"}>Product Description*</Typography>
              <TextField
                multiline
                rows={4}
                variant={"outlined"}
                name={"productDescription"}
                placeholder="Tell us about product"
                fullWidth
                value={formikFirst.values.productDescription}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.productDescription &&
                  Boolean(formikFirst.errors.productDescription)
                }
                helperText={
                  formikFirst.touched.productDescription &&
                  formikFirst.errors.productDescription
                }
              />
            </Stack>
            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
              fullWidth
            >
              <Autocomplete
                fullWidth="true"
                multiple
                required
                options={[...dataJson.businessModels].map((option) => option)}
                value={formikFirst.values?.businessModel}
                onChange={(e, newValue) =>
                  formikFirst.setFieldValue("businessModel", newValue)
                }
                renderTags={(value, getTagProps) =>
                  formikFirst.values?.businessModel &&
                  formikFirst.values?.businessModel.length > 0 &&
                  formikFirst.values?.businessModel.map((option, index) => (
                    <Chip
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginBottom: "10px",
                        borderRadius: "10px",
                      }}
                      deleteIcon={<CloseIcon />}
                      onDelete={() =>
                        formikFirst.setFieldValue(
                          "businessModel",
                          formikFirst.values?.businessModel?.filter(
                            (f) => f !== option
                          )
                        )
                      }
                      label={option}
                      {...getTagProps({
                        index,
                      })}
                    ></Chip>
                  ))
                }
                renderInput={(params) => (
                  <>
                    <Typography
                      fontWeight={400}
                      fontSize={"16px"}
                      textAlign="start"
                    >
                      Business Model*
                    </Typography>
                    <TextField
                      {...params}
                      name={"businessModel"}
                      error={Boolean(formikFirst.errors.businessModel)}
                      helperText={
                        formikFirst.touched.businessModel &&
                        formikFirst.errors.businessModel
                      }
                      //placeholder="Add"
                      sx={{
                        transition: "0.1s linear",
                      }}
                    />
                  </>
                )}
              />
            </Stack>
            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
            >
              <Typography variant={"body1"}>Who do you sell to*</Typography>
              <TextField
                name={"whoDoYouSellTo"}
                value={formikFirst.values.whoDoYouSellTo}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.whoDoYouSellTo &&
                  Boolean(formikFirst.errors.whoDoYouSellTo)
                }
                helperText={
                  formikFirst.touched.whoDoYouSellTo &&
                  formikFirst.errors.whoDoYouSellTo
                }
                variant={"outlined"}
                fullWidth
              />
            </Stack>

            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
              fullWidth
            >
              <Autocomplete
                multiple
                required
                fullWidth
                options={[...dataJson.markets].map((option) => option)}
                freeSolo
                onChange={(e, newValue) =>
                  formikFirst.setFieldValue("whereDoYouSell", newValue)
                }
                value={formikFirst.values?.whereDoYouSell}
                renderTags={(value, getTagProps) =>
                  formikFirst.values?.whereDoYouSell &&
                  formikFirst.values?.whereDoYouSell.length > 0 &&
                  formikFirst.values?.whereDoYouSell.map((option, index) => (
                    <Chip
                      variant="contained"
                      sx={{
                        backgroundColor: "#E0E3FF",
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginBottom: "10px",
                        borderRadius: "10px",
                      }}
                      deleteIcon={<CloseIcon />}
                      onDelete={() =>
                        formikFirst.setFieldValue(
                          "whereDoYouSell",
                          formikFirst.values?.whereDoYouSell?.filter(
                            (f) => f !== option
                          )
                        )
                      }
                      label={option}
                      {...getTagProps({
                        index,
                      })}
                    ></Chip>
                  ))
                }
                renderInput={(params) => (
                  <>
                    <Typography
                      fontWeight={400}
                      fontSize={"16px"}
                      textAlign="start"
                      sx={{ marginBottom: "4px" }}
                    >
                      <>
                        Where do you sell to? *
                        <Tooltip title="Countries" arrow placement="top-start">
                          <InfoIcon
                            fontSize="small"
                            style={{ marginLeft: "5px", color: "#D9D9D9" }}
                          />
                        </Tooltip>
                      </>
                    </Typography>
                    <TextField
                      {...params}
                      error={Boolean(formikFirst.errors.whereDoYouSell)}
                      helperText={
                        formikFirst.touched.whereDoYouSell &&
                        formikFirst.errors.whereDoYouSell
                      }
                      sx={{
                        transition: "0.1s linear",
                      }}
                    />{" "}
                  </>
                )}
              />
            </Stack>
            {/*attachment*/}
            <Stack direction={"column"} sx={{ width: "100%" }} spacing={5}>
              <Stack direction={"column"} spacing={2}>
                <Typography sx={{ textAlign: "left" }}>
                  Attachments{" "}
                  <font style={{ fontWeight: 300 }}>
                    <i> (References, Recommendations etc.)</i>
                  </font>
                </Typography>

                <DragDropFiles
                  label={{
                    text: "Drop here or choose from files",
                    caption: "(max file size: 50MB)",
                  }}
                  multiple={true}
                  limit={50}
                  types={fileTypes}
                  onChangeCallback={(fileList) => {
                    let list = [];
                    if (files.length > 0) {
                      list = [...files];
                    }
                    for (let file of fileList) {
                      list.push({ file });
                    }
                    setFiles([...list]);
                  }}
                />
                <span />
                <Grid container gap={2}>
                  {files.length > 0 &&
                    files.map((file, i) => (
                      <Grid sx={{ width: "48.5%" }}>
                        <DragDropItem
                          file={file}
                          i={i}
                          key={i}
                          path={
                            file?.file
                              ? `experts/${user.uid}/experiences/attachments/${file.file.name}`
                              : file
                          }
                          remove={(i) => {
                            setFiles([...files?.filter((d, ind) => ind !== i)]);
                            console.log(i);
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Stack>
            </Stack>

            <Stack
              direction={"column"}
              spacing={1}
              alignItems={"flex-start"}
              sx={{ width: "100%" }}
            >
              <Typography variant={"body1"}>Additional links</Typography>
              <TextField
                variant={"outlined"}
                name={"link"}
                fullWidth
                value={formikFirst.values.link}
                onChange={formikFirst.handleChange}
                error={
                  formikFirst.touched.link && Boolean(formikFirst.errors.link)
                }
                helperText={formikFirst.touched.link && formikFirst.errors.link}
              />
            </Stack>
          </Stack>
        </Container>
        <Stack
          direction={"row"}
          justifyContent="center"
          alignItems="center"
          spacing={3}
          width={"100%"}
        >
          <Button
            variant={"outlined"}
            color={"primary"}
            sx={{ px: 5 }}
            onClick={cbBack}
          >
            Back
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{ px: 5 }}
            type={"submit"}
          >
            save
          </Button>
        </Stack>
      </Stack>
    </FormikProvider>
  );
}
