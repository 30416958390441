import React from "react";
import { Typography } from "@mui/material";

const RemainingHoursOrDate = ({ dateString }) => {
  const currentDate = new Date();
  const selectedDate = new Date(dateString);

  const isToday = selectedDate.toDateString() === currentDate.toDateString();
  if (isToday) {
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59); // Set the time to the end of the day (23:59:59)

    const timeDiff = endOfDay.getTime() - currentDate.getTime();
    let remainingHours
    let unit
    if(timeDiff > 3600000){
      remainingHours = Math.ceil(timeDiff / (1000 * 60 * 60)); // Convert milliseconds to hours
      unit = remainingHours===1 ? 'hour' : 'hours'
    }
    else
    {
      remainingHours = Math.ceil(timeDiff / (1000 * 60))
      unit = remainingHours===1 ? 'minute' : 'minutes'
    }
    return (
      <Typography variant="body1" textAlign="left">
      Expires in <span style={{ color: "red" }}>{remainingHours} {unit}</span>
    </Typography>
    );
  }

  const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  const isTomorrow =
    selectedDate.toDateString() === tomorrowDate.toDateString();

  if (isTomorrow) {
    return (
      <Typography textAlign="left">
      Expires <span style={{ color: "red" }}>Tomorrow</span>
    </Typography>
    );
  }

  const timeDiff = selectedDate.getTime() - currentDate.getTime();
  // const remainingDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

  // if (remainingDays <= 1) {
  //   return (
  //     <Typography textAlign="left" variant="body1" sx={{ color: "red" }}>
  //       Expires <font>{isTomorrow ? "Tomorrow" : ""}</font>
  //     </Typography>
  //   );
  // }

  const formattedDate = selectedDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return (
    <Typography variant="body1" textAlign="left">
      Valid till {formattedDate}
    </Typography>
  );
};

export default RemainingHoursOrDate;
