import React from "react";
import {
  styled,
  Container,
  Stack,
  Typography,
  Avatar,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Badge,
  Grid,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import {AuthUserContext} from "../../providers/auth-provider";
import { getAuth, getIdToken, updateProfile } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { EditSharp } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthUserContext } from "../../../../providers/auth-provider";
import PostNotification from "../../../../api/post-notification";
import StartChat from "../../../../components/common/start-chat";
import EditOrganisationDetailsComponent from "../../../../components/common/profile/edit/service-provider/edit-organisation-details-component";
import OrganisationAbout from "../../../../components/common/profile/organisation-about";
import UploadFile from "../../../../utils/upload-file";
import { DB } from "../../../../config/firebase/firebase";
import { a11yProps, TabPanel } from "../../components/common/tabs-custom";
import InvestorDescription from "../../components/common/profile/investor-description";
import ExpertDescription from "../../components/common/profile/expert-description";
import BusinessDeveloperDescription from "../../components/common/profile/business-developer-description";
import ServiceProviderDescription from "../../components/common/profile/service-provider-description";
import StartupDescription from "../../components/common/profile/startup-description";
import TalentDescription from "../../components/common/profile/talent-description";
import ReferralAvatar from "../../components/common/profile/referal-avatar";
import EditInvestorOrganizationOtherDetails from "../../../../components/common/profile/edit/investor/edit-investor-organization-other-details";
import EditInvestorOrganizationDetails from "../../../../components/common/profile/edit/investor/edit-investor-organization-details";

const StyledTabs = styled((props) => (
  <Tabs
    sx={{ position: "relative", left: 0, top: 15 }}
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "none",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    borderRadius: "30px",
    color: "#000",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#233DFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
export default function MyOrganisationProfile({ data, href }) {
  const styleFont = {
    backgroundColor: "#F6F8FA",
    padding: "8px 15px",
    borderRadius: "5px",
    fontSize: 14,
  };
  const userContext = React.useContext(AuthUserContext);
  const { setUser, user } = React.useContext(AuthUserContext);
  const contextUser = React.useContext(AuthUserContext);
  //const { organisation, profile } = useLocation().state;
  const location = useLocation();
  const { state } = location;
  const { organisation } = state;
  const [profile, setProfile] = React.useState(state?.profile);
  debugger;
  const [portfolioIndex, setPortfolioIndex] = React.useState();
  const [imageURL, setImageURL] = React.useState();
  const confirmed = React.useRef(false);
  const profileImage = React.useRef();
  const [openOtherDetails, setOpenOtherDetails] = React.useState(false);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [steps, setSteps] = React.useState(0);
  const userRef = doc(DB, "users", user.uid);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [portfolios, setPortfolios] = React.useState(
    profile?.userType === "investor"
      ? profile?.investor?.portfolio
      : profile[
          profile?.userType === "service-provider"
            ? "serviceProvider"
            : profile?.userType === "business-developer"
            ? "businessDeveloper"
            : profile?.userType
        ]?.investorDetails?.portfolio
  );
  const [posts, setPosts] = React.useState([]);
  const [index, setIndex] = React.useState(-1);
  // const [profile, setProfile] = React.useState(null);
  const [value, setValue] = React.useState(0);

  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    setDialogOpen(false);
  }
  const navigator = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Input = styled("input")({
    display: "none",
  });
  async function updateProfilePicture(url) {
    try {
      const auth = getAuth();
      let asdf = await updateProfile(auth.currentUser, {
        logoURL: url,
      });
      const userRef = doc(DB, "users", auth.currentUser.uid);
      let dat;
      dat = await updateDoc(userRef, {
        logoURL: url,
      });
      setProfile({ ...profile, logoURL: url });
      userContext.setProfile({ ...profile, logoURL: url });
      setImageURL("");
      PostNotification({
        type: "update-profile-picture",
        imageURL: url,
        message: `Your organisation profile picture has been updated`,
        uid: user.uid,
      }).then();
      confirmed.current = true;
    } catch (err) {
      console.log(err.message);
    }
  }
  function UploadProfile() {
    return (
      <div>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            {organisation.nameOfOrganization}
          </DialogTitle>
          <DialogContent sx={{ height: 400 }}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: 300 }}
            >
              <Avatar
                key={imageURL}
                src={imageURL ? imageURL : profile?.logoURL}
                alt={organisation?.nameOfOrganization}
                sx={{
                  width: 250,
                  height: 250,
                }}
                component={"span"}
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "start", alignItems: "center", height: 75 }}
          >
            <IconButton variant="outlined">
              <label htmlFor={"profile-image"}>
                <Stack direction={"column"}>
                  <CameraAltIcon
                    sx={{ cursor: "pointer" }}
                    fontSize={"large"}
                  />
                  <Input
                    accept="image/*"
                    id="profile-image"
                    multiple={false}
                    type="file"
                    onChange={(e) => {
                      confirmed.current = false;
                      const fileReader = new FileReader();
                      fileReader.readAsDataURL(e.target.files[0]);
                      fileReader.addEventListener("load", async function () {
                        const file = e.target.files[0];

                        const path = `profile-image/${file.name}`;
                        const imageObject = {
                          progress: 0,
                          src: this.result,
                          path,
                          file: e.target.files[0],
                          size: (e.target.files[0]?.size / 1024 / 1024).toFixed(
                            2
                          ),
                        };
                        profileImage.current = imageObject;
                        UploadFile(file, path).then((url) => {
                          console.log(url);
                          setImageURL(url);
                        });
                      });
                    }}
                  />
                </Stack>
              </label>
            </IconButton>
            <IconButton
              variant="outlined"
              onClick={() => {
                profileImage.current = "";
                setImageURL(" ");

                //updateProfilePicture("");
                confirmed.current = true;
                //handleDialogClose();
              }}
            >
              <DeleteIcon fontSize={"large"} color={"warning"} />
            </IconButton>
            <Box sx={{ flex: 1 }} />
            <Button
              sx={{ px: 2 }}
              size={"small"}
              onClick={() => {
                profileImage.current = {
                  ...profileImage,
                  logoURL: imageURL,
                };

                updateProfilePicture(imageURL);

                profileImage.current = "";

                handleDialogClose();
                //updateProfilePicture("");
              }}
              variant="contained"
            >
              Save Photo
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return (
    <Container maxWidth={"lg"} sx={{ textAlign: "left", my: 5 }}>
      {organisation && (
        <Stack direction={"column"} spacing={5}>
          <Stack
            direction={"column"}
            spacing={0}
            sx={{
              // border: '0.5px solid #000000',
              boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
              backgroundColor: "#fff",
              minHeight: 100,
              p: 0,
              pb: 0,
            }}
          >
            <IconButton
              sx={{
                display: "grid",
                justifyContent: "right",
                position: "absolute",
                right: 25,
                marginTop: 14,
              }}
              onClick={() => {
                setOpenDetails(true);
              }}
            >
              <EditSharp />
            </IconButton>
            <EditInvestorOrganizationDetails
              profile={profile}
              open={openDetails}
              handleClose={() => setOpenDetails(false)}
            />
            <Box
              sx={{
                height: 100,
                width: "100%",
                backgroundColor: "#F6F8FA",
                borderRadius: "15px",
              }}
            />

            {/* Startup Picture */}

            <Box
              sx={{
                p: 0.75,
                borderRadius: "50%",
                width: "fit-content",
                position: "relative",
                left: 30,
                top: -50,
              }}
            />
            <Box
              sx={{
                border: "1px solid #204488",
                p: 0.75,
                borderRadius: "50%",
                width: "fit-content",
                position: "relative",
                left: 30,
                top: -50,
              }}
            >
              <label>
                <Badge
                  overlap="circular"
                  sx={{ width: 92, height: 92 }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    profile?.uid === user?.uid && (
                      <Button
                        onClick={handleDialogOpen}
                        sx={{ ml: 1, mt: 1 }}
                        color={"primary"}
                        component={"span"}
                      >
                        <AddCircleIcon />
                      </Button>
                    )
                  }
                >
                  {profile?.uid === user?.uid && <UploadProfile />}
                  <Avatar
                    alt={organisation?.nameOfOrganization}
                    src={profile?.logoURL}
                    sx={{ width: 92, height: 92 }}
                  >
                    <Typography variant={"h3"}>
                      {organisation?.nameOfOrganization.charAt(0)}
                    </Typography>
                  </Avatar>
                </Badge>
              </label>
            </Box>

            <Stack
              direction={"row"}
              spacing={3}
              sx={{ position: "relative", top: -40 }}
              alignItems={"flex-start"}
            >
              <div />
              <Stack direction={"column"} spacing={2}>
                <Stack direction={"row"} sx={{ width: "100%" }}>
                  {/* Startup Name */}

                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "22px",
                      marginTop: "2.5px",
                      width: "60%",
                    }}
                  >
                    {profile?.investor?.nameOfOrganization}
                  </Typography>
                </Stack>

                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      backgroundColor: "#E0E3FF",
                      px: 1,
                      py: 1,
                      marginRight: "7px",
                      borderRadius: "19.5px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "13px",
                        fontWeight: 500,
                        textTransform: "Uppercase",
                      }}
                    >
                      {"Investor"}
                    </Typography>
                  </Box>
                </Box>
                {profile?.countryState && (
                  <Typography variant={"body2"} color={"grey"} sx={{ flex: 1 }}>
                    {profile?.countryState}
                  </Typography>
                )}

                <span style={{ flex: 1 }} />
                {profile?.investor &&
                  profile?.investor.organizationDescription && (
                    <Typography
                      variant={"body1"}
                      color={"secondary"}
                      sx={{ width: "calc(100% - 10%)", fontSize: "12px" }}
                    >
                      {profile?.investor.organizationDescription}
                    </Typography>
                  )}
                <br />
                <Stack
                  direction={"column"}
                  spacing={1}
                  justifyContent="center"
                  sx={{
                    position: "relative",
                    minWidth: 120,
                    maxWidth: 130,
                    cursor: "pointer",
                  }}
                  alignItems="center"
                  onClick={() =>
                    navigator(`/m/${userContext?.profile?.userType}/profile`)
                  }
                >
                  <Box
                    sx={{
                      border: "1px solid #204488",
                      p: 0.75,
                      borderRadius: "50%",
                      width: "fit-content",
                    }}
                  >
                    <Avatar
                      alt={profile?.displayName}
                      src={
                        profile?.photoURL !== ""
                          ? profile?.photoURL
                          : profile.photoURL
                      }
                      sx={{ width: 51, height: 51 }}
                    >
                      <Typography variant={"h4"}>
                        {profile?.displayName.charAt(0)}
                      </Typography>
                    </Avatar>
                  </Box>
                  <Typography variant={"h6"} sx={{ textAlign: "center" }}>
                    {profile?.displayName}
                    <br />
                    <font
                      size={2}
                      style={{
                        backgroundColor: "#F6F8FA",
                        padding: "5px 10px",
                        borderRadius: "6px",
                        fontWeight: 300,
                      }}
                    >
                      {profile?.investor?.role}
                    </font>
                  </Typography>
                </Stack>
              </Stack>

              <div style={{ flex: 2 }} />
            </Stack>

            <Box
              sx={{
                maxWidth: { xs: 320, sm: 480 },
                marginTop: "16px",
                marginLeft: 2,
              }}
            >
              <StyledTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="styled tabs example"
              >
                <StyledTab
                  label="ABOUT"
                  {...a11yProps(0)}
                  sx={{ fontSize: "14px" }}
                />
              </StyledTabs>
            </Box>

            <Box sx={{ height: 30 }} />
          </Stack>

          <TabPanel index={0} value={value}>
            <Stack
              direction={"column"}
              spacing={3}
              sx={{
                border: "0.5px solid #000000",
                boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                minHeight: 100,
                px: 3,
                py: 6,
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <Typography
                  variant={"h6"}
                  sx={{ fontWeight: "600" }}
                  style={{ flex: 1 }}
                >
                  About Organization
                </Typography>
                <IconButton onClick={() => setOpenOtherDetails(true)}>
                  <EditSharp />
                </IconButton>
              </Stack>
              <EditInvestorOrganizationOtherDetails
                profile={profile}
                open={openOtherDetails}
                handleClose={() => setOpenOtherDetails(false)}
              />
              <Stack direction={"column"} spacing={4}>
                <Stack direction={"column"} spacing={2}>
                  <Typography variant={"h6"} sx={{ lineHeight: 2 }}>
                    Website
                    <br />{" "}
                    <font style={styleFont}>
                      <a
                        href={
                          !organisation?.website || organisation?.website === ""
                            ? "javascript:;"
                            : organisation?.website
                        }
                      >
                        {!organisation?.website || organisation?.website === ""
                          ? "N/A"
                          : organisation?.website}
                      </a>
                    </font>
                  </Typography>
                  <hr
                    style={{ border: "1px solid rgba(175, 175, 175, 0.4)" }}
                  />
                </Stack>

                <Stack direction={"column"} spacing={2}>
                  <Typography variant={"h6"} sx={{ lineHeight: 2 }}>
                    Investor type
                    <br />{" "}
                    <font style={styleFont}>
                      {!organisation?.investorType ||
                      organisation?.investorType === ""
                        ? "N/A"
                        : organisation?.investorType}{" "}
                    </font>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </TabPanel>
        </Stack>
      )}
    </Container>
  );
}
