import { Grid, Link, Stack, Typography } from "@mui/material";
import React from "react";
const styleFont = {
	backgroundColor: "#F6F8FA",
	padding: "8px 15px",
	borderRadius: "5px",
	fontSize: 14,
};

export default function TalentAbout({ profile }) {
	return (
		<>
		<Stack
                direction={"column"}
                spacing={3}
                sx={{
                  boxShadow: "0px 0px 13px -2px rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  minHeight: 100,
                  px: 5.5,
                  py: 6,
                }}>
			<Typography variant={"h5"}>About</Typography>

			{profile.userType === "talent" && (
				<Grid container gap={3}>
					<Grid>
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Expertise
								<br />{" "}
								<font style={styleFont}>
									{profile?.talent?.skills &&
										profile?.talent?.skills.length > 0 &&
										profile?.talent?.skills.map((ex, i) =>
											profile?.talent?.skills[
												profile?.talent?.skills.length -
													1
											] !== ex
												? ex + ", "
												: ex
										)}
								</font>
							</Typography>
						</Stack>
					</Grid>
					<Grid>
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Support startup at stage
								<br />{" "}
								<font style={styleFont}>
									{profile?.talent?.supportStage &&
										profile?.talent?.supportStage &&
										profile?.talent?.supportStage.length >
											0 &&
										profile?.talent?.supportStage.map(
											(ex, i) =>
												profile?.talent?.supportStage[
													profile?.talent
														?.supportStage.length -
														1
												] !== ex
													? ex + ", "
													: ex
										)}
								</font>
							</Typography>
						</Stack>
					</Grid>
					<Grid>
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Focus Vertical
								<br />{" "}
								<font style={styleFont}>
									{profile?.talent?.focusVertical &&
										profile?.talent?.focusVertical &&
										profile?.talent?.focusVertical.length >
											0 &&
										profile?.talent?.focusVertical.map(
											(ex, i) =>
												profile?.talent?.focusVertical[
													profile?.talent
														?.focusVertical.length -
														1
												] !== ex
													? ex + ", "
													: ex
										)}
								</font>
							</Typography>
						</Stack>
					</Grid>
					<Grid>
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Additional Links
								<br />{" "}
								<font style={styleFont}>
									{profile?.talent?.links &&
										profile?.talent?.links.length > 0 &&
										profile?.talent?.links.map((ex, i) => (
											<>
												<Link
													href={ex}
													target={"_blank"}>
													{ex}
												</Link>
												{profile?.talent?.links[
													profile?.talent?.links
														.length - 1
												] !== ex && ", "}
											</>
										))}{" "}
								</font>
							</Typography>
						</Stack>
					</Grid>

					<Grid>
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Reachable to startups on chat
								<br />{" "}
								<font style={styleFont}>
									{profile?.talent?.reachable ? "Yes" : "No"}
								</font>
							</Typography>
						</Stack>
					</Grid>
					<Grid>
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Do you want to help startups with Business
								Development to increase revenue?
								<br />{" "}
								<font style={styleFont}>
									{profile?.talent
										?.doYouWantToHelpStartupsWithBusinessDevelopmentToIncreaseRevenue
										? "Yes"
										: "No"}
								</font>
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			)}
			</Stack>
		</>
	);
}
