import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton, Portal, useMediaQuery} from "@mui/material";
import AboutEditForm from "../../../../../screens/investor/edit/about-edit-form";
import {AuthUserContext} from "../../../../../providers/auth-provider";
import {doc, setDoc} from "firebase/firestore";
import {DB} from "../../../../../config/firebase/firebase";
import AboutEditStartupForm from "../../../../../screens/startups/edit/about-edit-startup-form";
import CommonInvestorAboutForm from "../../../../../screens/common/edit/common-investor-about-form";
import {useNavigate} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import PostNotification from "../../../../../api/post-notification";

export default function ComonInvestorAboutComponent({profile,open, handleClose, callback }) {

  const { user, setProfile } = React.useContext(AuthUserContext);
  const context =  React.useContext(AuthUserContext)
  
  const navigator = useNavigate()
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const regexAdmin = /admin\/user-details|admin\/startup\/startup-founder-profile/

  const [isLoading, setLoading] = React.useState(false);

  const copyProfile = regexAdmin.test(window.location.pathname) ? profile : {...context.profile}
  const userRef = doc(DB, "users", regexAdmin.test(window.location.pathname) ? profile.uid : user.uid);
  const investorDetails = profile[profile?.userType === 'service-provider' ? 'serviceProvider'
    : profile?.userType === 'business-developer' ? 'businessDeveloper' : profile?.userType ]?.investorDetails;

  return (
    <Portal>
      <Dialog
        fullScreen={!matches ? true: false}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{px: !matches ? 0 : 4, textTransform: 'capitalize'}}>
          {!matches && <IconButton onClick={() => handleClose()}>
            <ArrowBack sx={{fontSize: 30}} color={'#000'}/>
          </IconButton>}
          {`Edit Investor Details`}
        </DialogTitle>
        <DialogContent  sx={{px: !matches ? 0 : ''}}>
          <br/>
          <CommonInvestorAboutForm

            data={investorDetails?.investmentStage ? investorDetails : null }
            cbBack={handleClose}
            cbNext={async (data) => {
              setLoading(true)
              const object = {...profile}
              object[
                profile?.userType === 'service-provider' ?
                  'serviceProvider' : profile?.userType === 'business-developer' ?
                    'businessDeveloper' : profile?.userType].investorDetails = {...object[
                  profile?.userType === 'service-provider' ?
                    'serviceProvider' : profile?.userType === 'business-developer' ?
                      'businessDeveloper' : profile?.userType].investorDetails, ...data}
              await setDoc(
                userRef,
                {
                 ...object,
                updatedById:context?.user?.uid,
                updatedBy: regexAdmin.test(window.location.pathname) ? 'admin' : 'user',
                updatedAt: new Date().getTime()
                },
                { merge: true }
              )
              if (!regexAdmin.test(window.location.pathname)) {
                debugger
                await setProfile({...object})
                if (profile?.userType === 'startup') {
                  navigator(`${!matches? '/m': ''}/${profile?.userType}/startup-founder-profile`, {
                    state: {
                      profile: {...object},
                      founder:  {
                        ...object?.startup?.founder
                      }
                    }
                  })
                }
              } else{
                
                /*TODO: FOR ADMIN*/
                callback({...object})
                PostNotification({
                  type: 'admin-update-profile',
                  imageURL: '/assets/system-noti-icon.png',
                  section: 'Investor',
                  message: `information has been updated by the Admin.`,
                  uid: profile.uid,
                  createdBy: user?.uid
    
                }).then()
                
              }
          
              setLoading(false)

              handleClose()
            }}
            isLoading={isLoading}
          />
        </DialogContent>

      </Dialog>
    </Portal>
  );
}
