import * as yup from 'yup';

const LocationSchema = yup.object({
 country: yup.string('Location').required('Location is required.'),

});
const initialValues = {
  country: '',
};

const LocationValidation = {
  initialValues,
  schema : LocationSchema
};

export default  LocationValidation;