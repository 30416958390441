import React from "react";
import {collection, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import {
	Container,
	Stack,
	Typography,
	Button,
	Box,
	Portal, Autocomplete, TextField,
	Chip
} from "@mui/material";
import styled from "styled-components";
import { DB } from "../../config/firebase/firebase";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import PostRequestDialog from "../mobile/components/post-request-dialog";
import OpenRequestListItem from "../mobile/components/common/request/open-request-list-item";
import dataJson from "../../const/data.json"
import PersonIcon from "@mui/icons-material/Person";
import FilterDrawer from "./components/common/filter-drawer";

const CustomScrollableStack = styled(Stack)`
  min-width: 320px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
export const filterButtonStyle ={
	border:"none", boxShadow: "0 0 0 0.5px #8F8F8F !important", borderRadius: '30px', fontSize:'12px', fontWeight:400, minWidth:"115px", textTransform:"none" 
}
export const chipStyle ={
    borderRadius: "24px",
    height:"35px",
    width:"100px",cursor: 'pointer',  fontSize:14, fontWeight:300,
}
export const HomeComponent = () => {
	const { profile } = React.useContext(AuthUserContext);
	const [searchQuery, setSearchQuery] = React.useState("");
	const [requests, setRequests] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [inputbtn, setinputbtn] = React.useState();
	const [search, setSearch] = React.useState("");
	const [lastDoc, setLastDoc]=React.useState();
	const [loading, setLoading]=React.useState(false);
	const navigator = useNavigate();
	const [jobTypes, setJobTypes] = React.useState([]);
	const [onsites, setOnsites] = React.useState([]);
	const [userType, setUserType] = React.useState([]);
	const [locations, setLocations] = React.useState([]);
	const [languages, setLanguages] = React.useState([]);
	const [sectors, setSectors] = React.useState([]);
	const [markets, setMarkets] = React.useState([]);
	const [experiences, setExperiences] = React.useState([]);
	const [freeSearch, setFreeSearch] = React.useState([]);
	const [isFilters, setFilters] = React.useState(false)

	const [openDrawer, setOpenDrawer] = React.useState(false)
	const [selected, setSelected] = React.useState({})
	let selectedFilters= React.useRef([])
	const clearFilter = () => {
		setJobTypes([]);
		setOnsites([]);
		setUserType([]);
		setLocations([]);
		setLanguages([]);
		setFreeSearch([]);
	}
	const filters = () => {
		setRequests([])
		debugger
		setFreeSearch([...new Set([
			...userType.map((type) => type === 'Growth Expert' ? 'Business Developer' : type === 'Industry Expert' ? 'Expert' : type),
			...locations,
			...onsites,
			...jobTypes,
			...languages,
			...experiences,
			...sectors,
			...markets
			
			])])
	}
	const loadMore = async () => {
		setLoading(true)
		
		let q;
		if (freeSearch?.length > 0) {
			q = query(
				
				collection(DB, "help-requests"),
				where('free_search', 'array-contains-any', [...freeSearch]),
				orderBy('createdAt', 'desc'),
				
				startAfter(lastDoc),
				limit(10)
			);
		} else {
			q = query(
				
				collection(DB, "help-requests"),
				orderBy('createdAt', 'desc'),
				
				startAfter(lastDoc),
				limit(10)
			);
		}
		
		const querySnap = await getDocs(q);
		if (querySnap.size > 0) {
			const list = [...requests]
			querySnap.docs.forEach((doc) => {
				list.push({id: doc.id, ...doc.data()})
			});
			// console.log(list)
			setRequests(list)
			querySnap.size < 10 ? setLastDoc(undefined) :setLastDoc(querySnap.docs[querySnap.docs.length-1]);
		} else {
			setLastDoc(undefined)
		}
		setLoading(false)

	}
	const load = () => {
		setLastDoc(undefined)
		
		let q;
		if (freeSearch?.length > 0) {
			 q = query(
				
				collection(DB, "help-requests"),
				where('free_search', 'array-contains-any', [...freeSearch]),
				 orderBy('createdAt', 'desc'),
				
				 limit(30)
			);
		} else {
			q = query(
				collection(DB, "help-requests"),
				orderBy('createdAt', 'desc'),
				
				limit(30)
			)
		}
		setLoading(true)
		getDocs(
			q
		).then((querySnapshot) => {
			if (querySnapshot.size > 0) {
				const list = [];
				querySnapshot.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });
					
					// console.log(doc.id, " => ", doc.data());
				});
				setRequests(list);
				
				setLastDoc(querySnapshot?.size < 30 ? undefined :   querySnapshot.docs[querySnapshot.docs.length-1])
				setLoading(false)
				
				
			} else {
				setLoading(false)
				setRequests([])
			}
			
		});
	}
	React.useEffect(() => {
		load()

	}, [freeSearch]);
	React.useEffect(() => {
		filters()
		selectedFilters.current = userType.concat(jobTypes, onsites, locations, languages, sectors, markets, experiences)
	}, [userType, jobTypes, onsites, locations, languages, sectors, markets, experiences]);
	React.useEffect(()=>{
		if(openDrawer===false){
			setSelected({})
		}
	}, [openDrawer])
  return (
    <Container maxWidth={"lg"} sx={{textAlign: "left" }}>
      <Stack direction={"column"} spacing={2} >
	    <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
		  
        >
          <IconButton onClick={() => navigator(-1)}>
            <ArrowBackIcon sx={{ fontSize: 40 ,color:"#000"}} />
          </IconButton>

          <Typography
            variant={"h4"}
            style={{ fontWeight: "600", fontSize: "20px" }}
          >
            {" "}
            Open requests and responses
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          spacing={0}
          justifyContent="space-between"
          alignItems="center"
		  
        >
          <>
					<Box onSubmit={(event) => event.preventDefault()}
						component="form"
						sx={{
              p: "2px 10px",
							display: "flex",
							alignItems: "center",
							width: "70%",
							height: 50,
							borderRadius: "12px",
							backgroundColor: "#E4EAF5",
						}}>

						<IconButton
							type="button"
							sx={{ p: "10px" }}
							aria-label="search"
							onClick={(e) => setSearch(inputbtn)}
							disabled={true}
							>
							<SearchIcon sx={{ color: "#051E40BF" }} />
						</IconButton>
            				<Autocomplete
						freeSolo
						disableClearable
						sx={{flex:1,"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
							border: 'none',
						  },
			  }}
						value={inputbtn}
						options={[...dataJson.searchQueries].map((option) => option)}
						onChange={(event, newValue) => {
						  setinputbtn(newValue);
						}}
						onInputChange={(event, value) => {
							if (event.type === 'change') {
								setinputbtn(value);
							}
						}}
						onKeyDown={(e)=>{
							if(e.code==="Enter"){
								setSearch(inputbtn)
							}
						}}
						renderInput={(params) => (
							<TextField
							{...params}
							sx={{ flex: 1, color: "#051E40BF", input:{
								"&::placeholder":{
								  opacity:1
								}
							  }, }}
							placeholder='Search'
							
						/>
						)}
						/>
					
					</Box>
          </>
				
          <IconButton
						onClick={() => {
							setFilters(!isFilters)
						}}
						variant={"outlined"}
						sx={{ px: 1, py: .5, borderRadius: "6px" }}
						size={"small"} endIcon={isFilters ?  <img src={"/assets/filter.svg"} style={{ height: "20px" }} alt="none"></img> :  <img src={"/assets/filter.svg"} style={{ height: "20px" }} alt="none"></img>}>
					<img src={"/assets/filter.svg"} style={{ height: "20px" }} alt="none"></img> 
		 </IconButton>
        
			
          <IconButton
            variant={"contained"}
            sx={{ px: 1, py: 1, borderRadius: "6px" }}
            size={"small"}
            onClick={() =>
							profile?.userType === "startup" || profile?.userType==="expert" || profile?.userType==="business-developer" ||profile?.userType==="service-provider" ||profile?.userType==="investor"
								? navigator(
										`/m/${profile.userType}/open-requests-post`
								  )
								: setOpen(true)
						}
          >
            <img src={"/assets/add.svg"} style={{ height: "20px" }} alt="none"></img>
          </IconButton>

          <IconButton
            variant={"contained"}
            sx={{ px: 1, py: 1, borderRadius: "6px" }}
            size={"small"}
            onClick={() => navigator(`/m/${profile.userType}/my-requests`)}
          >
            <PersonIcon
              style={{ fontSize: "29px", color: "#A9ABAE" }}
            ></PersonIcon>
          </IconButton>
        </Stack>
		<FilterDrawer data={selected} open={openDrawer} cbDrawer={setOpenDrawer} selectedFilters={selectedFilters.current}/>
        <CustomScrollableStack direction="row" >
		{isFilters && <Stack direction={"row"}
						spacing={1}
						m={0.2}
						justifyContent="flex-start"
						alignItems="center">
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle, whiteSpace:'nowrap',  color:userType.length > 0 ? "#FFF":"#8F8F8F"}} variant={userType.length > 0 ? "contained" : "outlined"}  
				onClick={()=>
					{
						setSelected({
							title:'User Type',
							options: ['Industry Expert', 'Investor', 'Service provider', 'Growth Expert', 'Startup'],
							value: userType,
							
							cbValue: setUserType,
						})
						setOpenDrawer(true);
					}}>
					User Type</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle, color:markets.length > 0 ? "#FFF":"#8F8F8F"}} variant={markets.length > 0 ? "contained" : "outlined"} onClick={()=>
				{
					setSelected({
						title:'Market',
						options: [...dataJson.markets],
						value: markets,
						cbValue: setMarkets,
					})
					setOpenDrawer(true);
				}}>Market</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle, color:sectors.length > 0 ? "#FFF":"#8F8F8F"}} variant={sectors.length > 0 ? "contained" : "outlined"} onClick={()=>
				{
					setSelected({
						title:'Sector',
						options: [...dataJson.sector],
						value: sectors,
						cbValue: setSectors
					})
					setOpenDrawer(true);
				}}>Sector</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle,color:locations.length > 0 ? "#FFF":"#8F8F8F"}} variant={locations.length>0 ? "contained" : "outlined"} onClick={()=>
				{
					setSelected({
						title:'Location',
						options: [...dataJson.cities],
						value: locations,
						cbValue: setLocations
					})
					setOpenDrawer(true);
				}}>Location</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle,color:experiences.length>0 ? "#FFF":"#8F8F8F"}} variant={experiences.length>0 ? "contained" : "outlined"} onClick={()=>
				{
					setSelected({
						title:'Experience',
						options: [.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
						value: experiences,
						cbValue: setExperiences
					})
					setOpenDrawer(true);
				}}>Experience</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle, color:languages.length>0  ? "#FFF":"#8F8F8F"}} variant={languages.length>0  ? "contained" : "outlined"} onClick={()=>
				{
					setSelected({
						title:'Languages',
						options: [...dataJson.languages],
						value: languages,
						cbValue: setLanguages
					})
					setOpenDrawer(true);
				}}>Languages</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle,color:jobTypes.length > 0 ? "#FFF":"#8F8F8F"}} variant={jobTypes.length > 0 ? "contained" : "outlined"} onClick={()=>
				{
					setSelected({
						title:'Job Type',
						options: ["part-time", "full-time", "contract"],
						value: jobTypes,
						cbValue: setJobTypes
					})
					setOpenDrawer(true);
				}}>Job Type</Button>
				<Button endIcon={<KeyboardArrowDownSharpIcon fontSize={'small'}/>} 
				sx={{...filterButtonStyle, color:onsites.length > 0 ? "#FFF":"#8F8F8F", textOverflow:"ellipsis"}} variant={onsites.length > 0 ? "contained" : "outlined"} onClick={()=>
				{
					setSelected({
						title:'On-site/Remote',
						options: ["Onsite", "Hybrid", "Remote"],
						value: onsites,
						cbValue: setOnsites
					})
					setOpenDrawer(true);
				}}>Onsite/Remote</Button>
				</Stack>
				}
		
		</CustomScrollableStack>
		{selectedFilters.current.length>0 && <div style={{ display: 'flex', width:"100%",gap: 8,marginBottom: 1, flexWrap:'wrap'}}>
          { selectedFilters.current.map((option)=>( <Chip key={option} sx={{...chipStyle, background:"#2841fc", color:"#FFF",}} label={option} 
                   />)) }
          
        </div>}
        {requests &&
          requests.length > 0 &&
          requests
		  .filter((requests) => {
			  if (search !== "") {
				  const reg = new RegExp(search.toLowerCase());
				  return (reg.test(requests.searchFor?.toString().toLowerCase()) ||
				  reg.test(requests.skills?.toString().toLowerCase()) || 
				  reg.test(requests.requestFor?.toLowerCase()))  && requests;
				} else {
					return requests;
				}
            })
            .sort(
				(a, b) => b.createdAt.seconds * 1000 - a.createdAt.seconds * 1000
				)
				.map((request, i) => ( 
					<OpenRequestListItem
					request={request}
					key={i}
                searchQuery={searchQuery}
              />
            ))}
        {lastDoc &&
					<Stack direction={"row"} sx={{width: '100%'}} py={5}  justifyContent="center"
						   alignItems="center">

						<Button size={"small"} onClick={loadMore}>
							Load more
						</Button>
					</Stack>
				}
       
      </Stack>
      <Portal>
        <PostRequestDialog
          data={open}
          callback={(res) => {
            setOpen(res.close);
            if (res.selected && res.selected !== "requestForFunding") {
              navigator(`/m/${profile.userType}/open-requests-post`);
            } else if (res.selected && res.selected === "requestForFunding") {
              navigator(`/m/${profile.userType}/post-funding-request`);
            }
            // console.log("callback", res);
          }}
        />
      </Portal>
    </Container>
  );
};
