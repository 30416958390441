import {Grid, IconButton, Link, Stack, Typography} from "@mui/material";
import React from "react";
import moment from "moment";
import {EditSharp} from "@mui/icons-material";
import AboutStartupFounderComponent
	from "../../../../../components/common/profile/edit/startup/about-startup-founder-component";
import {AuthUserContext} from "../../../../../providers/auth-provider";
const styleFont = {
	backgroundColor: "#F6F8FA",
	padding: "8px 15px",
	borderRadius: "5px",
	fontSize: 14,
};

export default function FounderAbout({profile, founder }) {
	const {user} = React.useContext(AuthUserContext);
	const [open, setOpen] = React.useState(false);
	const profileRegex = new RegExp('/startup/profile/founder')
	return (
		<>
			<Stack direction={"row"} spacing={2}  justifyContent="flex-start"
				   alignItems="center" sx={{width: '100%'}}>
				<Typography variant="h6" fontWeight={600} style={{flex: 1}}>About Founder</Typography>
				{
					profile?.uid === user?.uid && profileRegex.test(window.location.pathname) &&
					<IconButton onClick={() => setOpen(true)}>
						<EditSharp />
					</IconButton>
				}
			</Stack>

			{founder && (
				<Stack direction={"column"} spacing={4}>
					
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Website
								<br />{" "}
								<font style={styleFont}>
									{!founder?.website || founder?.website === '' ? 'N/A' : founder?.website }{" "}
								</font>
							</Typography>
						<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>
					
					
						<Stack direction={"column"} spacing={2}>
							<Typography
								variant={"h6"}
								sx={{ lineHeight: 2 }}>
								Linkedin URL
								<br />{" "}
								<font style={styleFont}>
									{!founder?.linkedIn || founder?.linkedIn === '' ? 'N/A' : founder?.linkedIn }{" "}
								</font>
							</Typography>
							<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>
					
					
						<Stack direction={"column"} spacing={2}>
							<Typography
								variant={"h6"}
								sx={{ lineHeight: 2 }}>
								Twitter Handle
								<br />{" "}
								<font style={styleFont}>
									{!founder?.twitter || founder?.twitter === '' ? 'N/A' : founder?.twitter }{" "}
								</font>
							</Typography>
							<hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
						</Stack>
					
					
						<Stack direction={"column"} spacing={2}>
							<Typography variant={"h6"} sx={{ lineHeight: 2 }}>
								Repeat Founder
								<br />{" "}
								<font style={styleFont}>
									{founder?.repeatFounder ? "Yes" : "No"}{" "}
								</font>
							</Typography>
							
						</Stack>
					
				</Stack>
			)}
			<AboutStartupFounderComponent profile={profile} open={open} handleClose={() => setOpen(false)} />
		</>
	);
}
