import React from "react";
import { Container, Stack, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../providers/auth-provider";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import ExpertFirstForm from "../expert/onboarding-forms/expert-first-form";
import ExpertSecondForm from "../expert/onboarding-forms/expert-second-form";
import ExperienceListView from "../expert/onboarding-forms/experience-list-view";
import ExperienceForm from "../expert/onboarding-forms/experience-form";
import Expectations from "../expert/onboarding-forms/expectations";
import InvestorDetails from "../expert/onboarding-forms/investor-details";
import InvestorThirdForm from "../investor/onboarding-forms/investor-third-form";
import InvestorFourForm from "../investor/onboarding-forms/investor-four-form";
import PortfoliosListView from "../investor/onboarding-forms/portfolios-list-view";
import InvestorPortfolioForm from "../investor/onboarding-forms/portfolio-form";
import { BUSINESS_DEVELOPER } from "../../utils/enums/labels.enum";
import ExperienceListViewGrowthExpert from "./onboarding-forms/experience-list-view-growth-expert";
import ExperienceFormGrowthExpert from "./onboarding-forms/experience-form-growth-expert";

export default function BusinessDeveloperOnboard() {
  const { user, setProfile } = React.useContext(AuthUserContext);

  const selectedUser = useLocation();
  const userType = selectedUser.state.type;
  console.log(userType);

  const [loading, setLoading] = React.useState(false);
  const [formFirstData, setFormFirstData] = React.useState();
  const [formSecondData, setFormSecondData] = React.useState();
  const [experiences, setExperiences] = React.useState([]);
  const [experienceIndex, setExperienceIndex] = React.useState();
  const [formFiveData, setFormFiveData] = React.useState();
  const [formThirdData, setFormThirdData] = React.useState();
  const [formFourData, setFormFourData] = React.useState();

  const [formSixData, setFormSixData] = React.useState();
  const [portfolioIndex, setPortfolioIndex] = React.useState();

  const [portfolios, setPortfolios] = React.useState([]);

  const [steps, setSteps] = React.useState(0);
  const [files, setFiles] = React.useState([]);

  const [profileImage, setProfileImage] = React.useState({
    src: user.photoURL ? user.photoURL : null,
    photoURL: user.photoURL,
  });

  const navigator = useNavigate();
  const submitData = async (values) => {
    try {
      const userRef = doc(DB, "users", user.uid);
      const copyPortfolio = [];
      if (portfolios.length > 0) {
        for (let portfolio of portfolios) {
          const attachments = [];
          if (portfolio.attachments.length > 0) {
            for (let file of portfolio.attachments) {
              const path = `investors/${user.uid}/attachments/${file.file.name}`;
              attachments.push(path);
            }
          }
          copyPortfolio.push({ ...portfolio, attachments });
        }
      }
      const copyExperiences = [];
      if (experiences.length > 0) {
        for (let experience of experiences) {
          const attachments = [];
          if (experience.attachments.length > 0) {
            for (let file of experience.attachments) {
              const path = `experts/${user.uid}/experiences/attachments/${file.file.name}`;
              attachments.push(path);
            }
          }
          copyExperiences.push({ ...experience, attachments });
        }
      }
      const copyValue = {
        ...values,
        investorDetails: {
          ...values?.investorDetails,
          portfolio: copyPortfolio,
        },
      };

      const businessDeveloper = {
        ...copyValue,
        experiences: copyExperiences,
        photoURL: values?.photoURL ? values.photoURL : "",
      };
      await setDoc(
        userRef,
        {
          photoURL: values?.photoURL ? values.photoURL : "",
          businessDeveloper,
          onboard: true,
          profileStatus: "complete",
          remainderStatus: "",
        },
        { merge: true }
      );
      const getdoc = await getDoc(userRef);
      await setProfile(getdoc.data());
      navigator("/business-developer/timeline");
    } catch (e) {
      debugger;
      console.log(e);
    }
  };

  return (
    <>
      <Container maxWidth={"xl"} sx={{ marginY: 5 }}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width={"100%"}
        >
          <IconButton
            onClick={() => (steps === 0 ? navigator(-1) : setSteps(steps - 1))}
          >
            <ArrowBackIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Typography variant={"h5"} sx={{ textAlign: "left", lineHeight: 1 }}>
            {BUSINESS_DEVELOPER.SINGULAR_LABEL}
            <br />{" "}
            <small style={{ fontSize: 14, color: "#8F8F8F" }}>
              {steps === 0 && BUSINESS_DEVELOPER.BUSINESS_DEVELOPER_PROFILE}
              {steps === 1 && BUSINESS_DEVELOPER.EXPERTISE}
              {steps === 2 && BUSINESS_DEVELOPER.EXPERIENCE}
              {steps === 3 && BUSINESS_DEVELOPER.ADD_EXPERIENCE}
              {steps === 4 && BUSINESS_DEVELOPER.EXPECTATIONS}
              {steps > 4 && steps < 8 && BUSINESS_DEVELOPER.INVESTOR_DETAILS}
              {steps === 8 && BUSINESS_DEVELOPER.INVESTMENT_PORTFOLIO}
              {steps === 9 && BUSINESS_DEVELOPER.ADD_PORTFOLIO}
            </small>
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth={"lg"} sx={{ mb: 5 }}>
        {steps === 0 && (
          <ExpertFirstForm
            data={formFirstData}
            cbBack={() => navigator(-1)}
            cbNext={(data) => {
              debugger;
              setFormFirstData({ ...data });
              setSteps(steps + 1);
            }}
          />
        )}
        {steps === 1 && (
          <ExpertSecondForm
            data={formSecondData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              debugger;
              setFormSecondData({ ...data });
              setSteps(steps + 1);
            }}
          />
        )}
        {steps === 2 && (
          <ExperienceListViewGrowthExpert
            userType={userType}
            cbBack={() => setSteps(steps - 1)}
            add={(data) => {
              setExperienceIndex(undefined);
              setSteps(steps + 1);
              setPortfolioIndex();
              setSteps(steps + 1);
            }}
            edit={(ind) => {
              setExperienceIndex(ind);
              setSteps(steps + 1);
              setPortfolioIndex(ind);
              setSteps(steps + 1);
            }}
            remove={(ind) => {
              const experiences_copy = [...experiences];
              experiences_copy.splice(ind, 1);
              setExperiences(experiences_copy);
              const port = [...portfolios];
              port.splice(ind, 1);
              setPortfolios([...port]);
            }}
            data={experiences}
            submit={() => {
              setSteps(steps + 2);
              console.log("submit");
            }}
          />
        )}

        {steps === 3 && (
          <ExperienceFormGrowthExpert
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data, index) => {
              const port = [...experiences];
              if (index !== undefined && index > -1) {
                port.splice(index, 1, { ...data });
              } else {
                port.push({ ...data });
              }
              setExperiences([...port]);
              setSteps(steps - 1);
              setExperienceIndex(undefined);
            }}
            data={
              experienceIndex !== undefined && experienceIndex > -1
                ? experiences[experienceIndex]
                : undefined
            }
            index={experienceIndex}
          />
        )}
        {steps === 4 && (
          <Expectations
            data={formFiveData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormFiveData({ ...data });
              setSteps(steps + 1);
            }}
          />
        )}
        {steps === 5 && (
          <InvestorDetails
            data={formSixData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormSixData({ ...data });
              if (data?.areYouAnAngelInvestor === true) {
                setSteps(steps + 1);
              } else {
                submitData({
                  investorDetails: {
                    ...formSixData,
                  },
                  ...formFirstData,
                  ...formSecondData,
                  ...formFiveData,
                }).then();
              }
            }}
          />
        )}
        {steps === 6 && (
          <InvestorThirdForm
            data={formThirdData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              setFormThirdData({ ...data });
              setSteps(steps + 1);
            }}
          />
        )}
        {steps === 7 && (
          <InvestorFourForm
            data={formFourData}
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data) => {
              debugger;
              setFormFourData({ ...data });
              setSteps(steps + 1);
            }}
          />
        )}
        {steps === 8 && (
          <PortfoliosListView
            cbBack={() => setSteps(steps - 1)}
            add={(data) => {
              setPortfolioIndex();
              setSteps(steps + 1);
            }}
            edit={(ind) => {
              setPortfolioIndex(ind);
              setSteps(steps + 1);
            }}
            remove={(ind) => {
              debugger;
              const port = [...portfolios];
              port.splice(ind, 1);
              setPortfolios([...port]);
            }}
            portfolios={portfolios}
            submit={() => {
              debugger;
              submitData({
                investorDetails: {
                  ...formSixData,
                  ...formThirdData,
                  ...formFourData,
                },
                ...formFirstData,
                ...formSecondData,
                ...formFiveData,
              }).then();
              console.log("submit");
            }}
          />
        )}

        {steps === 9 && (
          <InvestorPortfolioForm
            cbBack={() => setSteps(steps - 1)}
            cbNext={(data, index) => {
              const port = [...portfolios];
              if (index !== undefined && index > -1) {
                port.splice(index, 1, { ...data });
              } else {
                port.push({ ...data });
              }
              setPortfolios([...port]);
              setSteps(steps - 1);
              setPortfolioIndex(undefined);
            }}
            data={
              portfolioIndex !== undefined && portfolioIndex > -1
                ? portfolios[portfolioIndex]
                : undefined
            }
            index={portfolioIndex}
          />
        )}
      </Container>
    </>
  );
}
