import {Grid, IconButton, Link, Stack, Typography} from "@mui/material";
import React from "react";
import {EditSharp} from "@mui/icons-material";
import TopPrioritiesStartupComponent from "./edit/startup/top-priorities-startup-component";
import { BUSINESS_DEVELOPER } from "../../../utils/enums/labels.enum";


const styleFont = {
  backgroundColor: "#F6F8FA",
  padding: "8px 15px",
  borderRadius: "5px",
  fontSize: 14,
};
export default function StartupTopPriorities({ profile, cb }) {
  const [open,setOpen] = React.useState(false)
  const profileRegex = /profile|admin\/user-details/
  return (

    <Stack
      direction={"column"}
      spacing={3}
      sx={{


        borderRadius: "15px",
        backgroundColor: "#fff",
        minHeight: 100,

      }}
    >
      <Stack direction={"row"} spacing={4}>
        <Typography fontSize={20} fontWeight={600} style={{flex: 1}}>Top Priorities</Typography>   {profileRegex.test(window.location.pathname) &&  <IconButton onClick={() => setOpen(true)}>
        <EditSharp />
      </IconButton>}
      </Stack>
      <Stack direction={'column'} spacing={4}>
        
          <Stack direction={'column'} spacing={1}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Looking for {BUSINESS_DEVELOPER.SINGULAR_LABEL}<br/>
              <font style={styleFont}>
                {profile?.startup?.lookingForBusinessDeveloper ? 'Yes' : 'NO'}
              </font>
            </Typography>
                  <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
        
          <Stack direction={'column'} spacing={1}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Looking for Experts<br/>
              <font style={styleFont}>
                {profile?.startup?.lookingForExperts ? 'Yes' : 'NO'}
              </font>
            </Typography>
                  <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
        
          <Stack direction={'column'} spacing={1}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Looking for Service Provider<br/>
              <font style={styleFont}>
                {profile?.startup?.lookingForServiceProvider ? 'Yes' : 'NO'}
              </font>
            </Typography>
                  <hr style={{border:"1px solid rgba(175, 175, 175, 0.4)"}}/>
          </Stack>
        
        
          <Stack direction={'column'} spacing={1}>
            <Typography variant={'h6'} sx={{lineHeight:2}}>
              Looking for Funding<br/>
              <font style={styleFont}>
                {profile?.startup?.lookingForFunding ? 'Yes' : 'NO'}
              </font>
            </Typography>
          </Stack>
        
      </Stack>

      <TopPrioritiesStartupComponent profile={profile?.startup} startupProfile={profile} callback={(data) => {
        cb(data);
        setOpen(false)}} open={open} handleClose={() => setOpen(false)} />
    </Stack>
  )
}
