import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Badge, Container, Stack, TextField, MenuItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { DB } from "../../config/firebase/firebase";
import { AuthUserContext } from "../../providers/auth-provider";
import moment from "moment/moment";
import MyRequestListItem from "../../components/common/request/my-request-list-item";
import { CleaningServices } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`my-requests-tabpanel-${index}`}
      aria-labelledby={`my-requests-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `my-requests-tab-${index}`,
    "aria-controls": `my-requests-tabpanel-${index}`,
  };
}

export default function MyRequests() {
  const [value, setValue] = React.useState(0);
  const [requests, setRequests] = React.useState([]);
  const navigator = useNavigate();
  const { profile } = React.useContext(AuthUserContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    const q = query(
      collection(DB, "help-requests"),
      orderBy("createdAt", "desc"),
      where("uid", "==", profile.uid)
    );

    const unSubs = onSnapshot(q, (querySnap) => {
      // debugger
      if (querySnap.size > 0) {
        const list = [];
        querySnap.forEach((doc) => {
          console.log("requestId", doc.id);
          list.push({ id: doc.id, ...doc.data() });
        });
        setRequests(list);
      }
    });
    return () => unSubs;
  }, []);

  const update = async (e, id) => {
    debugger;
    const requestRef = doc(DB, "help-requests", id);
    await setDoc(
      requestRef,
      { stage: e.target.value, updatedAt: serverTimestamp() },
      { merge: true }
    );
  };
  const updateViewNavigate = (request) => {
    if (
      request.type === "looking-for-funding" ||
      request.requestFor === "Looking for funding"
    ) {
      navigator(`/${profile.userType}/update-funding-request/${request.id}`);
    } else {
      navigator(`/${profile.userType}/open-requests-details`, {
        state: { request },
      });
    }
  };

  return (
    <Container maxWidth={"lg"} sx={{ py: 5 }}>
      <Stack
        direction={"column"}
        sx={{ width: "100%", textAlign: "left" }}
        spacing={5}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <IconButton onClick={() => navigator(-1)}>
            <ArrowBackIcon sx={{ fontSize: 40, color: "#000" }} />
          </IconButton>

          <Typography variant={"h4"}>My Requests</Typography>
        </Stack>
        <Stack
          direction={"column"}
          sx={{ width: "100%", textAlign: "left" }}
          spacing={5}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Activated" {...a11yProps(0)} />
              <Tab label="Deactivated" {...a11yProps(1)} />
              <Tab label="Closed" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Stack
              direction={"column"}
              sx={{ width: "100%", textAlign: "left" }}
              spacing={5}
            >
              {requests.length > 0 &&
              requests.filter(
                (d) => d.stage !== "Closed" && d.stage !== "Deactivated"
              ).length > 0 ? (
                requests
                  .filter(
                    (d) => d.stage !== "Closed" && d.stage !== "Deactivated"
                  )
                  .map((request, i) => (
                    <MyRequestListItem
                      request={request}
                      callback={(e) => update(e, request.id)}
                      callbackNav={() => updateViewNavigate(request)}
                    />
                  ))
              ) : (
                <Typography>There is no request posted by you.</Typography>
              )}
            </Stack>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Stack
              direction={"column"}
              sx={{ width: "100%", textAlign: "left" }}
              spacing={5}
            >
              {requests.length > 0 &&
              requests.filter((d) => d.stage === "Deactivated").length > 0 ? (
                requests
                  .filter((d) => d.stage === "Deactivated")
                  .map((request, i) => (
                    <MyRequestListItem
                      request={request}
                      callback={(e) => update(e, request.id)}
                      callbackNav={() => updateViewNavigate(request)}
                    />
                  ))
              ) : (
                <Typography>There is no deactivated request by you.</Typography>
              )}
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Stack
              direction={"column"}
              sx={{ width: "100%", textAlign: "left" }}
              spacing={5}
            >
              {requests.length > 0 &&
              requests.filter((d) => d.stage === "Closed").length > 0 ? (
                requests
                  .filter((d) => d.stage === "Closed")
                  .map((request, i) => (
                    <MyRequestListItem
                      request={request}
                      callback={(e) => update(e, request.id)}
                      callbackNav={() => updateViewNavigate(request)}
                    />
                  ))
              ) : (
                <Typography>There is no closed request by you.</Typography>
              )}
            </Stack>
          </TabPanel>
        </Stack>
      </Stack>
    </Container>
  );
}
